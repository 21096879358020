import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import LicenseService from '../../../Services/Licenses/LicenseService';
import { toast } from 'react-toastify';
import { displayDateFormate } from '../../../Helpers/DateFormat';
import { convertToCurrency } from '../../../Helpers/Currency';
import MessageModal from '../../../Components/BoilerPlate/MessageModal';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';

class RenewLicenses extends React.Component { 

    constructor(props) {
        super(props)
        this.state = {
            licenses: [],
            renewal_total_price: 0,
            selected_licenses: [],
            validation_alert: false,
            select_all: false,
            is_dirty: false,
            closed: true
        }
    }

    componentDidMount() {
        const renewData = JSON.parse(localStorage.getItem('renew_order_data'));
        
        if (localStorage.getItem('renew_order_data')) {
            this.setState({
                licenses: renewData.licenses,
                renewal_total_price: renewData.renewal_total_price,
                selected_licenses: renewData.selected_licenses,
                validation_alert: renewData.validation_alert,
                select_all: renewData.select_all
            })
        } else {
            this.getLicenseList();
        }
    }

    getLicenseList = () => {
        this.props.dispatch(setAppilcationLoder(true));
        LicenseService.getLicenseList()
        .then(data => {
            if (data.response_code === 200) {
               
                data.result.forEach(function (element) {
                    element.remaining_days = element.expires_in.replace(/.days/, '');
                    if(element.remaining_days == 0) {
                        element.expires_in = 'Today';
                    } else if (element.remaining_days == 1) {
                        element.expires_in = 'Tomorrow';
                    }
                    element.checked = false;
                });
               
                this.setState({licenses: data.result})
            } else {
                toast.error(data.message);
            }
            this.props.dispatch(setAppilcationLoder(false));
        })
    }

    dataFormatAssignedTo = (cell, row) => {
        return <span className={row.remaining_days <= 15 && row.assigned_to ? "red-highlight-td" : ""}>{row.assigned_to ? row.assigned_to : '-'}</span>;
    }

    dataFormatExpiresIn = (cell, row) => { 
        return <span className={row.remaining_days <= 15 ? "red-highlight-td" : ""}>{row.expires_in}</span>;
    }

    dataFormatAmount = (cell) => { 
        return '₹'+cell;
    }

    selectForRenewal = (data) => {
        data.checked = !data.checked;
    }

    handleRenewalCheckboxChange = (data) => { 
        data.checked = !data.checked;
        let newArray = [...this.state.selected_licenses, data];

        console.log(newArray);
        if (this.state.selected_licenses.some(item => item.account_license_id === data.account_license_id)){
        //if (this.state.selected_licenses.includes(data)) { alert('incude')
            newArray = newArray.filter(newdata => newdata.account_license_id !== data.account_license_id);
        } 

        console.log(newArray);

        this.setState({
            selected_licenses: newArray,
            is_dirty: true
        });

        const finalPrice = newArray.reduce(
            (prevValue, currentValue) => prevValue + currentValue.amount,
            0
        );

        this.setState({renewal_total_price: finalPrice})
    };
    

    dataFormatCheckbox = (cell, row) => { 
        let isChecked = (row.checked == true ) ? 1 : 0

        return <div className="check-box">
            <input className="styled-checkbox" id={`select-${row.account_license_id}`} checked={isChecked} type="checkbox"  onChange={() => this.handleRenewalCheckboxChange(row)}/>
            <label for={`select-${row.account_license_id}`} className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
        </div>
    }

    numericSortFunc = (a, b, order) => {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;

        var aA = a.licence_name.replace(reA, "");
        var bA = b.licence_name.replace(reA, "");

        if (order == 'desc') {
            if (aA === bA) {
                var aN = parseInt(a.licence_name.replace(reN, ""), 10);
                var bN = parseInt(b.licence_name.replace(reN, ""), 10);
                return aN === bN ? 0 : aN < bN ? 1 : -1;
            } else {
                return aA < bA ? 1 : -1;
            }
        } else {
            if (aA === bA) {
                var aN = parseInt(a.licence_name.replace(reN, ""), 10);
                var bN = parseInt(b.licence_name.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
    }

    getCaret = (direction) => {
        switch(direction){
            case 'asc':
                return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
            case 'desc':
                return (<span className="icon-right-arrow"></span>);
            default :
                return (<span className="icon-Group-4449 sort-both-icon"></span>);
        }
    }

    goToLicenseListingPage = () => {
        this.setState({is_dirty: false}, function() {
            localStorage.removeItem("order_data");
            localStorage.removeItem("upgrade_order_data")
            localStorage.removeItem("renew_order_data")
            localStorage.removeItem("bill_to_data")
            
            this.props.history.push("/licenses");
        })
    }

    goToOrderSummaryPage = () => {

        if(this.state.selected_licenses.length == 0) {
            this.toggleMessageModal();
        } else {
            this.setState({is_dirty: false}, function() {
                localStorage.setItem('renew_order_data', JSON.stringify(this.state));

                this.props.history.push({
                    pathname: '/licenses/order-summary',
                    state: {order_data: this.state.selected_licenses, type: "renew"} 
                }) 
            })
        }         
    }

    toggleMessageModal = () => {
        this.setState({validation_alert : !this.state.validation_alert})
    }

    selectDeselectAll = () => {
        this.setState({select_all : !this.state.select_all, is_dirty: true}, () => {
            if(this.state.select_all){
                
                this.state.licenses.forEach(function (license) {
                    license.checked = true;
                });

                this.setState({selected_licenses: this.state.licenses}, () => {
                    const finalPrice = this.state.selected_licenses.reduce(
                        (prevValue, currentValue) => prevValue + currentValue.amount,
                        0
                    );
                    this.setState({renewal_total_price: finalPrice})
                }) 

            } else {
                this.state.licenses.forEach(function (license) {
                    license.checked = false;
                });

                this.setState({selected_licenses: []}, () => {
                    const finalPrice = this.state.selected_licenses.reduce(
                        (prevValue, currentValue) => prevValue + currentValue.amount,
                        0
                    );
                    this.setState({renewal_total_price: finalPrice})
                }) 
            }
        })
    }

    goToProfileManagement = () => {
        this.setState({is_dirty: false})
        this.props.history.push("/profile-management/entities/"); 
    }

    closeAction = () => {
		this.setState({closed: true})
	}

    render() {

        const options = {
            noDataText: "No items to display",
        };

        return (
            <div className="content-wrapper">
                <RouteLeavingGuard
					when={this.state.is_dirty}
					closed={this.state.closed}
                    closeAction={this.closeAction}
				/>
                <div className="container-fluid">
                    <div className="row bg-w bg-rounded">
                        <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/home'}>Home</Link>
                                </li>
                                <li onClick={this.goToProfileManagement} style={{cursor: "pointer"}} className="breadcrumb-item">Profile Management</li>
                                <li onClick={this.goToLicenseListingPage} style={{cursor: "pointer"}} className="breadcrumb-item">My Licenses</li>
                                <li className="breadcrumb-item">Renew Licenses</li>
                            </ol>
                            <div className="clearfix main-title-btn-sec">
                                <div className="head-title pull-left">
                                    <h2 className="s-sbold blu-color m-0">Renew Licenses</h2>
                                </div>
                            </div>
                            <div className="main-evidence-sms-sec">
                                <MessageModal showModal={this.state.validation_alert} content={"Please select atleast one License to renew"} toggleMessageModal={this.toggleMessageModal} />
                                <div className="main-evidence-sms-table-sec">
                                    <div className="">
                                        <div className="main-table-sec renew-licenses-table mb-0 bbr0">
                                            <BootstrapTable 
                                                ref='table' 
                                                data={this.state.licenses} 
                                                table-bordered
                                                options={options}
                                                responsive
                                            > 
                                                <TableHeaderColumn width="80px" headerAlign='center' dataAlign='center' dataFormat={(cell, row) => this.dataFormatCheckbox(cell, row)}>
                                                    <div className="check-box">
                                                        <input className="styled-checkbox" id="select-all" type="checkbox" checked={this.state.select_all} value={this.state.select_all} onChange={this.selectDeselectAll}/>
                                                        <label for="select-all" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                                                    </div>
                                                </TableHeaderColumn>
                                                <TableHeaderColumn isKey hidden={true} dataField='account_license_id'>
                                                </TableHeaderColumn>
                                                {/* <TableHeaderColumn headerAlign='left' dataAlign='left' dataField='order_id' dataSort={true} caretRender={this.getCaret}>
                                                    Order ID
                                                </TableHeaderColumn> */}
                                                <TableHeaderColumn width="150px" headerAlign='left' dataAlign='left' dataField='licence_name' dataSort sortFunc={ this.numericSortFunc } caretRender={this.getCaret}>
                                                    License
                                                </TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ minWidth: '35%' }} tdStyle={{ minWidth: '35%' }} headerAlign='left' dataAlign='left' dataField='billed_to' field_name="Billed To" dataSort={true} caretRender={this.getCaret}>
                                                    Billed To
                                                </TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ minWidth: '35%' }} tdStyle={{ minWidth: '35%' }} headerAlign='left' dataAlign='left' dataField='assigned_to' dataFormat={(cell, row) => this.dataFormatAssignedTo(cell, row)} dataSort={true} caretRender={this.getCaret}>
                                                    Assigned To
                                                </TableHeaderColumn>
                                                {/* <TableHeaderColumn headerAlign='center' dataAlign='center'  dataField='order_date' dataFormat={(cell, row) => displayDateFormate(cell)} dataSort={true} caretRender={this.getCaret}>
                                                    Order Date
                                                </TableHeaderColumn> */}
                                                <TableHeaderColumn width="150px" headerAlign='center' dataAlign='center' dataField='valid_till' dataFormat={(cell, row) => displayDateFormate(cell)} dataSort={true} caretRender={this.getCaret}>
                                                    Valid To
                                                </TableHeaderColumn>
                                                <TableHeaderColumn width="10px" headerAlign='right' dataAlign='right' dataFormat={(cell, row) => this.dataFormatExpiresIn(cell, row)} dataField='expires_in' dataSort={true} caretRender={this.getCaret}>
                                                    Expires In
                                                </TableHeaderColumn>
                                                {/* <TableHeaderColumn headerAlign='left' dataAlign='left' dataField='status' dataSort={true} caretRender={this.getCaret}>
                                                    Status
                                                </TableHeaderColumn> */}
                                                {/* <TableHeaderColumn headerAlign='right' dataAlign='right' dataFormat={(cell) => this.dataFormatAmount(cell)} dataField='amount' dataSort={true} caretRender={this.getCaret}>
                                                    Amount
                                                </TableHeaderColumn> */}
                                            </BootstrapTable>
                                        </div>
                                        <div className="renewal-total-sec clearfix">
                                            <div className="inner-renewal-total-sec pull-right">
                                                <p className="total-text-sec white-color ft-sz-26 s-sbold">Renewal Total</p>
                                                <p className="total-text-sec white-color ft-sz-26 s-bold">₹{convertToCurrency(this.state.renewal_total_price)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-save-btn clearfix">                   
                                        <button type="button" className="btn save-btn s-bold greenbtn pull-right" onClick={this.goToOrderSummaryPage}>Next</button>
                                        <button type="button" className="btn save-btn s-bold pull-right graybtn mr-3" onClick={this.goToLicenseListingPage}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="scroll-to-top rounded" href="#page-top">
                        <span className="icon-right-arrow white-icon fs10"></span>
                    </a>
                </div>
            </div>     
        )
    } 
}
    
export default connect()(withRouter(RenewLicenses));    