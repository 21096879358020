import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class TagsList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	displayItemsList = (itemList,list_length = 7) => {
		if (itemList !== null && itemList.length !== 0) {
			let list = itemList.filter((tag, index) => {
				if (tag.length <= list_length) {
					list_length = list_length - tag.length
					return tag
				}
			})
			if (itemList.length !== list.length) {
				return <span>
					{(list.length === 0 && itemList.length !== 0) ? null : list.map((list, index) => {
						return <span key={index} className="tag-td">{list}</span>
					})}
					<OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled" className="tbl-user-ent">
						<ul style={{ listStyleType: 'none' }} >
							{itemList.map((tag, index) => {
								return <li key={index}>{tag}</li>
							})}
						</ul>
					</Tooltip>}>
						<a className="td-more-text s-sbold font-colr">
							<span> {list.length === 0 && itemList.length !== 0 ?
								`${itemList.length - list.length} tags` : `${itemList.length - list.length} more`}
							</span>
						</a>
					</OverlayTrigger>
				</span>
			} else
				return list.map((list, index) => {
					return <span key={index} className="tag-td">{list}</span>
				})
		}

	}

	render() {
		return (
			<span onClick={(e) => {
				e.stopPropagation()
			}}>{this.displayItemsList(this.props.itemList,this.props.list_length)}</span>
		)
	}
}

export default TagsList;