import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ActiveUser extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <React.Fragment>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                        <span className="icon-td icon-Group-3216"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    </OverlayTrigger>
                </React.Fragment>
            </>
        )
    }
}

export default ActiveUser;


