import React from 'react';
import { displayDateFormate } from '../../Helpers/DateFormat';

class Summary extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    render(){
      let summary = this.props.summay
        return(
          <div className="col-lg-12 col-md-8 col-sm-12 col-xs-12">
            <div className="inner-summery-sec">
              <div className="head-sec clearfix">
                <p className="ft-sz-22 s-sbold blu-color pull-left">Summary</p>
                <p className="lable-input font-colr pull-right">Link Status : {summary.status == 1 ? 'Linked': 'Unlinked'} </p>
              </div>

              <div className="row">
                <div className="col-lg-2 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Upload Date</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.statement_upload_date} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Start Date</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.start_date} />
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">End Date</p>
                    <input type="text" className="form-control input-bg-gray" value={summary.end_date} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">No. Of Record</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.transaction_count} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Source</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.statement_file_name} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Opening Balance</p>
                    <input type="text" readOnly className="form-control input-bg-gray text-right" value={summary.opening_balance} />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Closing Balance</p>
                    <input type="text" readOnly className="form-control input-bg-gray text-right" value={summary.closing_balance} />
                  </div>
                </div>
              </div>

            </div>
          </div>


      /* <div className="col-lg-12 col-md-8 col-sm-12 col-xs-12">
            <div className="inner-summery-sec">
              <div className="head-sec clearfix">
                <p className="ft-sz-25 s-sbold blu-color pull-left">Summary</p>
                <p className="ft-sz-15 s-reg font-colr pull-right">Link Status : {summary.status} </p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Upload Date</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.statement_upload_date} />
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Source</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.statement_file_name} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Start Date</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.start_date} />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">End Date</p>
                    <input type="text" className="form-control input-bg-gray" value={summary.end_date} />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">No. Of Record</p>
                    <input type="text" readOnly className="form-control input-bg-gray" value={summary.transaction_count} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Opening Balance</p>
                    <input type="text" readOnly className="form-control input-bg-gray text-right" value={summary.opening_balance} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <div className="form-group">
                    <p className="lable-input font-colr">Closing Balance</p>
                    <input type="text" readOnly className="form-control input-bg-gray text-right" value={summary.closing_balance} />
                  </div>
                </div>
              </div>
            </div>
          </div> */
       
        )
    }
}

export default Summary;