import React from 'react'
import { Link } from 'react-router-dom';

function BreadCrum(props) {
	const pathname = window.location.pathname;
	let path_array = pathname.split('/')
	let breadcrum_array = [];
	let url = "";
	const level = !!props.level ? props.level : path_array.length;
	for (let i = 0; i < level; i++) {
		if (!!path_array[i]) {
			url = url + "/" + path_array[i];
			path_array[i] = path_array[i].replace(/-/g, " ");
			let bread_crum = { title: path_array[i], url: url }
			breadcrum_array.push(bread_crum);
		}
	}

	return (
		<>
			{/* <React.Fragment>
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to={'/home'}>Home</Link>
					</li>
					{
						breadcrum_array.map((path, index) => {
							return <li key={index} className="breadcrumb-item text-capitalize"><Link to={path.url} prevPath={pathname}>{path.title}</Link></li>
						})
					}
				</ol>
			</React.Fragment> */}

			<React.Fragment>
				<div className="breadcrumb-header-new">
					<ol className="breadcrumb">
						<li className="breadcrumb-item-new">
							<Link to={'/home'}>Home</Link>
						</li>
						{
							breadcrum_array.map((path, index) => {
								return <li key={index} className="breadcrumb-item-new cap">
									<Link to={path.url} prevPath={pathname}>{path.title}</Link>
								</li>
							})
						}
					</ol>
				</div>
			</React.Fragment>
		</>
	);
}

export default BreadCrum;