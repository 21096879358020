import React from 'react'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader,

  TheHeaderNew,
  TheSidebarNew

} from './index';

import { connect } from "react-redux";
import StorageData from '../../Helpers/StorageData'
import MasterService from '../../Services/MasterService'
import { setAppilcationLoder } from "../../Redux/Actions/App";
import {
  setAccountPermission, setEntityPermission, setEntityList, setModulePermission, setUserDetail,
  setDefaultEntity, setSystemParamenter, setEntityConfigParam
} from "../../Redux/Actions/Session";
import SessionManagement from '../../Utils/SessionManagement';
import { setNotificationCount } from '../../Redux/Actions/Notification';
import { toast } from 'react-toastify';


class TheLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      // branch_new:''
    };
  }

  getRequiredDetails = async () => {
    const user_detail = SessionManagement.getUserDetail();
    this.props.dispatch(setUserDetail(user_detail))
    this.props.dispatch(setAppilcationLoder(true));
    await MasterService.getUserEntities().then(data => {
      if (data.response_code === 200) {
        const current_user_details = data.result.user_details || user_detail;
        this.props.dispatch(setUserDetail(current_user_details))
        this.props.dispatch(setSystemParamenter(data.result.system_parameters))


        const lic_entities = data.result.entities.filter(entity => (entity.status != 1));
        const entities = [...lic_entities, ...data.result.unlicensed_entities];

        const account_permission = data.result.account_permissions;
        this.props.dispatch(setEntityList(entities));
        this.props.dispatch(setAccountPermission(account_permission));

        if (entities.length != 0) {
          let default_entity_detail = StorageData.getDefaultEntity() || {};
          let entity_detail = entities.find(entity => (entity.id == default_entity_detail.id && (entity.status == 3 || entity.status == 2)));

          if (!!!entity_detail) {
            entity_detail = entities.find(entity => (entity.status == 3 || entity.status == 2));
            !!entity_detail ? StorageData.setDefaultEntity(entity_detail) : "";
          }

          if (!!entity_detail) {
            this.props.dispatch(setDefaultEntity(entity_detail));
            this.getEntityPermission(entity_detail.id);
          } else {
            this.setState({ is_loading: false })
            this.props.dispatch(setAppilcationLoder(false));
          }
        } else {

          this.setState({ is_loading: false })
          this.props.dispatch(setAppilcationLoder(false));
        }
      } else if (data.response_code === 500) {
        this.props.dispatch(setAppilcationLoder(false));
        toast.error(data.message);
      }
      else {
        /* to solve issue occur in uat is= added is_loading false */
        this.setState({ is_loading: false })
        this.props.dispatch(setAppilcationLoder(false));
      }
    })
  }

  getEntityPermission = async (entity_id) => {
    this.props.dispatch(setAppilcationLoder(true));
    await MasterService.getEntityPermission(entity_id).then(data => {
      if (data.response_code === 200) {
        const module_permissions = data.result.modules;
        const features_permissions_list = data.result.permissions;
        const entity_config_param = data.result.configuration;
        this.props.dispatch(setEntityPermission(features_permissions_list));
        this.props.dispatch(setModulePermission(module_permissions));
        this.props.dispatch(setEntityConfigParam(entity_config_param));

        this.setState({ is_loading: false })
      }
      this.props.dispatch(setAppilcationLoder(false));
    });

    await MasterService.getNotificationCount().then(data => {
      if (data.response_code === 200) {
        this.props.dispatch(setNotificationCount(data.result.count));
      }
    });



  }

  componentDidMount() {
    this.getRequiredDetails();
  }

  render() {
    if (this.state.is_loading) {
      return true;
    }
    const title_array = window.location.pathname.split("/");
    let title = title_array.find(title => title != "");
    if (!!title) {
      title = title.replace("-", " ")
    }



    // let branch_new;
    // if (window.location.pathname == '/task-manager') {
    //   branch_new  =  <><div className="" id="page-top">
    //                   <title className="text-capitalize">Buktec : {title}</title>        
    //                   <TheHeaderNew />
    //                   <TheSidebarNew />
    //                   <TheContent />
    //                   <TheFooter />
    //                 </div></>;
    // } else {
    //   branch_new = <><div className="main-body fixed-nav bg-dark-gray sidenav-toggled" id="page-top">
    //                   <title className="text-capitalize">Buktec : {title}</title>
    //                   <TheHeader />
    //                   <TheSidebar />
    //                   <TheContent />
    //                   <TheFooter />
    //                 </div></>;
    // }

    return (
      <>
        {/* <div className="" id="page-top"> */}
        {/* <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top"> */}
        <div className="main-body fixed-nav bg-dark-gray sidenav-toggled" id="page-top">
          <title className="text-capitalize">Buktec : {title}</title>
          <TheHeaderNew />
          <TheSidebarNew />
          <TheContent />
          <TheFooter />
        </div>


        {/* <div className="main-body fixed-nav bg-dark-gray sidenav-toggled" id="page-top">
          <title className="text-capitalize">Buktec : {title}</title>
          <TheHeader />
          <TheSidebar />
          <TheContent />
          <TheFooter />
        </div> */}

        {/* console.log(window.location.pathname); //yields: "/js" (where snippets run)
          console.log(window.location.href); */}

        {/* <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
            <title className="text-capitalize">Buktec : {title}</title>
            <TheHeader />
            <TheSidebar />
            <TheContent />
            <TheFooter />
          </div>  */}

        {/* {branch_new} */}

        {/* <div className="" id="page-top">
            <title className="text-capitalize">Buktec : {title}</title>        
            <TheHeader />
            <TheSidebar />
            <TheContent />
            <TheFooter />
          </div> */}
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
  };
}
export default connect(mapStateToProps)(TheLayout);
