import React from 'react'
import { Button, Modal } from 'react-bootstrap';


class ConfirmPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <Modal
                show={this.props.toggleConfirmPopup}
                backdrop="static"
                keyboard={false}
                centered
                className="popup-model error-popup"
            >
                <Modal.Body className="clearfix ">
                    <p className="pmsg-text pmsg-gray-center">
                        {this.props.message ? this.props.message : "Do you want to remove Item?"}
                    </p>
                    <div className="center_apply_btn_new">
                        <Button variant="outline-success reset_btn_new" onClick={() => this.props.confirmRemove(false)}>No</Button>
                        <Button variant="success apply_btn_new" onClick={() => this.props.confirmRemove(true)}>Yes</Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ConfirmPopup;