import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { showRoundOffAmount } from '../../../Helpers/Currency';


export default class DoughnutsReimCharts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      amount: [],
      approved: 0,
      rejected: 0,
      pending: 0,
      totalAmount: [],
      showmodal: false,
      approved_count: 0,
      rejected_count: 0,
      pending_count: 0,
    }
  }

  componentDidMount = () => {
    let count = 0;
    let amount = [];
    let approved = 0;
    let rejected = 0;
    let pending = 0;
    let totalAmount = [];
    let approved_count = 0;
    let rejected_count = 0;
    let pending_count = 0;

    this.props.data.map((item) => {
      if (item.status == 1) {
        approved = item.status_amount
        amount.push(item.status_amount)
        count = count + item.count
        approved_count = item.count;
      } else if (item.status == 2) {
        rejected = item.total_amount
        amount.push(item.total_amount)
        count = count + item.count;
        rejected_count = item.count;
      } else {
        pending = item.total_amount
        amount.push(item.total_amount)
        count = count + item.count;
        pending_count = item.count;
      }

      return (
        totalAmount.push(item.total_amount)
      )
    })

    this.setState({ totalAmount: totalAmount, count: count, amount: amount, approved: approved, pending: pending, rejected: rejected, approved_count, rejected_count, pending_count })

  }

  render() {
    let cnt = this.state.count;

    const plugins = [{
      beforeDraw: function (chart) {
        let x = chart.tooltip._data.labels
        var width = chart.width,
          height = chart.height,

          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";

        let text = chart.tooltip._data.labels,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        let hashtextX = Math.round((width - ctx.measureText('#').width) / 2)
        ctx.fillText(text, textX, textY);
        ctx.fillText('#', hashtextX, textY - 18);
        ctx.save();
      }
    }]

    const pluginsPopup = [{
      beforeDraw: function (chart) {
        let x = cnt
        var width = chart.width,
          height = chart.height,

          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";

        let text = x,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        let hashtextX = Math.round((width - ctx.measureText('#').width) / 2)
        ctx.fillText(cnt, textX, textY);
        ctx.fillText('#', hashtextX, textY - 45);
        ctx.save();
      }
    }]

    var a = [this.state.approved, this.state.rejected, this.state.pending]
    var seriesData = a;
    var total = seriesData.reduce((a, v) => a + v);
    var inPercent = seriesData.map(v => v ? Math.max(v / total * 100, 1) : v)
    let labels = this.props.labels

    return (
      <>
        {/* <div className="chart-box-new"> */}
        <div className="chart-box-new  d-flex flex-column justify-content-between">
          <h4 className="chart-box-heading">{this.props.headings}</h4>
          
          {/* row mt-4 */}
          <div className="row ">
            {/* <div className="chart-big-left-col"> */}
            <div className="col-xl-6 col-lg-8 col-md-6">
              <div className="chart-div" onClick={() => this.setState({ showmodal: true })}>
                <Doughnut
                  data={{
                    labels: this.state.count,
                    datasets: [{
                      data: inPercent,
                      backgroundColor: [
                        this.props.color1_background,
                        this.props.color2_background,
                        this.props.color3_background,
                      ],
                    }],
                  }}
                  plugins={plugins}
                  options={{
                    legend: false,
                    tooltips: {
                      enabled: true,
                      mode: 'nearest',
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var value = a[tooltipItem.index];
                          var label = labels[tooltipItem.index];
                          return `${label}: ${value}`;
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>

            {/* <div className="chart-big-right-col"> */}
            <div className="col-xl-6 col-lg-4 col-md-6">
              <div className="chart-content chart-content-big">
                <div class="row">
                  <div className="ch-bigin-rt-col-12">
                    <p className="chart-lable">Total Applied ({this.state.count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.totalAmount.reduce((partial_sum, a) => partial_sum + a, 0))}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.totalAmount.reduce((partial_sum, a) => partial_sum + a, 0))}</span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color1_background }}></span> Approved ({this.state.approved_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.approved)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.approved)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> Rejected ({this.state.rejected_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.rejected)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.rejected)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Pending ({this.state.pending_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.pending)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.pending)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
          <div></div>
          <div></div>
        </div>

        <Modal
          show={this.state.showmodal}
          size="lg"
          onHide={() => this.setState({ showmodal: false })}
          // dialogClassName="chart-model-width"
          aria-labelledby="example-custom-modal-styling-title"
          backdrop="static"
          centered
          className="popup-model chart-box-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.headings}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="chart-box-new model-chart-box-new">
              <div className="row">
                <div className="model-chart-big-left-col">
                  <div className="chart-div">
                    <Doughnut
                      data={{
                        labels: ['Approved', 'Rejected', 'Pending'],
                        datasets: [{
                          //label: ['Inflow'],
                          data: inPercent,
                          backgroundColor: [
                            this.props.color1_background,
                            this.props.color2_background,
                            this.props.color3_background,
                          ],
                        }],
                      }}
                      plugins={pluginsPopup}
                      options={{
                        legend: false, tooltips: {
                          enabled: true,
                          mode: 'nearest',
                          callbacks: {
                            label: function (tooltipItem, data) {
                              var value = a[tooltipItem.index];
                              var label = labels[tooltipItem.index];
                              return `${label}: ${value}`;
                            }
                          }
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="model-chart-big-right-col">
                  <div className="chart-content big-model-chart-padding">
                    <div class="row">
                      <div className="ch-bigin-rt-col-12">
                        <p className="chart-lable">Total Applied ({this.state.count})</p>
                        <p className="chart-cp model-chart-cp">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.totalAmount.reduce((partial_sum, a) => partial_sum + a, 0))}</Tooltip>}>
                            <span className='chart-price'>{showRoundOffAmount(this.state.totalAmount.reduce((partial_sum, a) => partial_sum + a, 0))}</span>
                          </OverlayTrigger>
                        </p>
                      </div>

                      <div className="ch-bigin-rt-col">
                        <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color1_background }}></span> Approved ({this.state.approved_count})</p>
                        <p className="chart-cp model-chart-cp">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.approved)}</Tooltip>}>
                            <span className='chart-price'>{showRoundOffAmount(this.state.approved)} </span>
                          </OverlayTrigger>
                        </p>
                      </div>

                      <div className="ch-bigin-rt-col">
                        <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> Rejected ({this.state.rejected_count})</p>
                        <p className="chart-cp model-chart-cp">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.rejected)}</Tooltip>}>
                            <span className='chart-price'>{showRoundOffAmount(this.state.rejected)} </span>
                          </OverlayTrigger>
                        </p>
                      </div>

                      <div className="ch-bigin-rt-col">
                        <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Pending ({this.state.pending_count})</p>
                        <p className="chart-cp model-chart-cp">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.pending)}</Tooltip>}>
                            <span className='chart-price'>{showRoundOffAmount(this.state.pending)} </span>
                          </OverlayTrigger>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}