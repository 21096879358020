import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import { showRoundOffAmount } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class SnapshotMoneyLinkBarChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chart_data: null,
      show_modal: false,
      total_amt: 0,
      total_count: 0,
      draft: 0,
      ready: 0,
      pending: 0,
      accounted: 0,
      synced: 0,
      linked: 0,
      draft_count: 0,
      ready_count: 0,
      pending_count: 0,
      accounted_count: 0,
      synced_count: 0,
      linked_count: 0,
      series_data: []
    }
  }

  componentDidMount = () => {
    this.setData()
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.data !== this.props.data) {
      this.setData()
    }
  }

  setData = () => {
    let total_amt = 0;
    let total_count = 0;

    this.props.data.forEach((data) => {
      if (data.status == 1) {
        total_amt = total_amt + data.amount;
        total_count = total_count + data.count;
        this.setState({ draft: data.amount, draft_count: data.count })
      } else if (data.status == 2) {
        total_amt = total_amt + data.amount;
        total_count = total_count + data.count;
        this.setState({ ready: data.amount, ready_count: data.count })
      } else if (data.status == 3) {
        this.setState({ pending: data.amount, pending_count: data.count })
      } else if (data.status == 4) {
        this.setState({ accounted: data.amount, accounted_count: data.count })
      } else if (data.status == 5) {
        this.setState({ synced: data.amount, synced_count: data.count })
      } else if (data.status == 6) {
        this.setState({ linked: data.amount, linked_count: data.count })
      }
    })

    this.setState({ total_amt, total_count })

    let seriesData = this.props.data.map((item) => {
      return Math.round(item.amount);
    });

    this.setState({ series_data: seriesData })
    let total = seriesData.reduce((a, v) => a + v);
    let inPercent = seriesData.map(v => v ? Math.max(v / total * 100, 1) : v)

    let chart_data = {
      labels: this.props.labels.map((item) => {
        return item;
      }),
      datasets: [{
        data: seriesData,
        backgroundColor: [this.props.color1_background, this.props.color2_background, this.props.color2_background, this.props.color3_background, this.props.color3_background, this.props.color3_background, this.props.color4_background],
      }]
    }

    this.setState({ chart_data: chart_data })
  }

  chartComponent = () => {
    let series_data = this.state.series_data;
    let labels = this.props.labels;


    //console.log(series_data)
    let options = {
      plugins: {
        legend: false
      },
      scales: {
        yAxes: [{
          //display: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 6,

          },
        }],
        xAxes: [
          {
            ticks: {
              callback: (label, index, labels) => {
                return label && label.length > 8 ? label.substr(0, 5) + '...' : label;
              },
              //fontSize: 10
            },
          }
        ]
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function (tooltipItem, data) {
            var value = series_data[tooltipItem.index];
            var label = labels[tooltipItem.index];
            return `${label}: ${showRoundOffAmount(value)}`;
          }
        }
      },
    }
    //console.log(this.state.chart_data)
    return (
        <div className="chart-box-new">
          <h4 className="chart-box-heading">{this.props.title}</h4>

          {/* row */}
          <div className="row">
            <div className="chart-big-left-col">
              <div className="chart-div" onClick={() => this.setState({ show_modal: true })}>
                <Bar data={this.state.chart_data} options={options} width={100} height={60}></Bar>
              </div>
            </div>

            <div className="chart-big-right-col">
              <div className="chart-content chart-content-big">
                <div class="row">
                  <div className="ch-bigin-rt-col-12">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color1_background }}></span> Total ({this.state.total_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.total_amt)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.total_amt)}</span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> Draft ({this.state.draft_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.draft)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.draft)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> Ready ({this.state.ready_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.ready)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.ready)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Pending ({this.state.pending_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.pending)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.pending)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Accounted ({this.state.accounted_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.accounted)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.accounted)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Synced ({this.state.synced_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.synced)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.synced)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>

                  <div className="ch-bigin-rt-col">
                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color4_background }}></span> Linked ({this.state.linked_count})</p>
                    <p className="chart-cp">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.linked)}</Tooltip>}>
                        <span className='chart-price'>{showRoundOffAmount(this.state.linked)} </span>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
    )
  }


  // Model Popup 

  popupChartComponent = () => {
    let series_data = this.state.series_data;
    let labels = this.props.labels;

    //console.log(series_data)
    let options = {
      plugins: {
        legend: false
      },
      scales: {
        yAxes: [{
          //display: false,
          ticks: {
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 6,

          },
        }],
        xAxes: [
          {
            ticks: {
              callback: (label, index, labels) => {
                return label && label.length > 8 ? label.substr(0, 5) + '...' : label;
              },
              //fontSize: 10
            },
          }
        ]
      },
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function (tooltipItem, data) {
            var value = series_data[tooltipItem.index];
            var label = labels[tooltipItem.index];
            return `${label}: ${showRoundOffAmount(value)}`;
          }
        }
      },
    }
    //console.log(this.state.chart_data)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="chart-box-new model-chart-box-new">
            <div className="row">
              <div className="model-chart-big-left-col">
                <div className="chart-div">
                  <Bar data={this.state.chart_data} options={options} width={100} height={60} ></Bar>
                </div>
              </div>

              <div className="model-chart-big-right-col">
                <div className="chart-content big-model-chart-padding">
                  <div class="row">
                    <div className="ch-bigin-rt-col-12">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color1_background }}></span> Total ({this.state.total_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.total_amt)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.total_amt)}</span>
                        </OverlayTrigger>
                      </p>
                    </div>

                    <div className="ch-bigin-rt-col">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> Draft ({this.state.draft_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.draft)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.draft)} </span>
                        </OverlayTrigger>
                      </p>
                    </div>

                    <div className="ch-bigin-rt-col">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> Ready ({this.state.ready_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.ready)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.ready)} </span>
                        </OverlayTrigger>
                      </p>
                    </div>

                    <div className="ch-bigin-rt-col">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Pending ({this.state.pending_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.pending)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.pending)} </span>
                        </OverlayTrigger>
                      </p>
                    </div>

                    <div className="ch-bigin-rt-col">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Accounted ({this.state.accounted_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.accounted)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.accounted)} </span>
                        </OverlayTrigger>
                      </p>
                    </div>

                    <div className="ch-bigin-rt-col">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color3_background }}></span> Synced ({this.state.synced_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.synced)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.synced)} </span>
                        </OverlayTrigger>
                      </p>
                    </div>

                    <div className="ch-bigin-rt-col">
                      <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color4_background }}></span> Linked ({this.state.linked_count})</p>
                      <p className="chart-cp model-chart-cp">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.linked)}</Tooltip>}>
                          <span className='chart-price'>{showRoundOffAmount(this.state.linked)} </span>
                        </OverlayTrigger>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    )
  }

  render() {
    return (
      <>
        {this.chartComponent()}
        <Modal
          show={this.state.show_modal}
          // size="lg"
          onHide={() => this.setState({ show_modal: false })}
          // dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          backdrop="static"
          centered
          className="popup-model chart-box-popup"
        >
          {/* <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            { this.popupChartComponent() }
          </Modal.Body> */}

          {this.popupChartComponent()}

        </Modal>
      </>
    )
  }
}