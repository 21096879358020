import { SET_INVOICE_FILTER_DATA,INVOICE_WIDGET_DATA,SET_TOTAL_COUNT,SET_PAGE_NO,SET_PER_PAGE_ITEM,SET_IS_INVOICE_LOADING,SET_TASK_STAR, SET_VIEW_INVOICE } from "./Types";

export const setInvoiceFilterData = (filter_data) => ({
  type: SET_INVOICE_FILTER_DATA,
  payload: filter_data,
});
  
export const setInvoiceWidgets = (widget_count) => ({
  type: INVOICE_WIDGET_DATA,
  payload: widget_count,
});
  
export const setTotalCount = (total_count) => ({
  type: SET_TOTAL_COUNT,
  payload: total_count,
});

export const setPageNo = (page_no) => ({
  type: SET_PAGE_NO,
  payload: page_no,
});
  
export const setPagePerItem = (per_page_item) => ({
  type: SET_PER_PAGE_ITEM,
  payload: per_page_item,
});
  
export const setInvoiceLoadingStatus = (is_invoice_loading) => ({
  type: SET_IS_INVOICE_LOADING,
  payload: is_invoice_loading,
});
  

