import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function TaskIcon(props) {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Communication bar</Tooltip>}>
        
        <span class="icon-Group-4507 taskMessage-icon icon-td "><span className={"path1 " +(props.active ? "skyblues":" graylight")}></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>
        
        {/* <span class={"icon-Group-4507 taskMessage-icon icon-td "+(props.active ? " ":" gray-st-icon")}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span> */}

        {/* <span className={"icon-td icon-tasks-icon  "+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
        {/* <span className="icon-tasks-icon gray-st-icon icon-td"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
        {/* <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}

    </OverlayTrigger>
}
export default TaskIcon;