import { SET_EARLIER_NOTIFICATION,SET_NOTIFICATION_COUNT } from "../Actions/Types";


const initialState = {
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        count: payload
      }
      
    default:
      return state;
  }
}