import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import { showRoundOffAmount } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const BarCharts = (props) => {
    const [open, setOpen] = React.useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const [buktecSummary3, buktecsetShow3] = React.useState(false);
    //const chart = props.data.result ? props.data?.result.financial_institutes : [];
    const chart = props.data


    const onOpenModal = (i) => {
        setOpen(true);
        setSelectedData(i)
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    let inflowAmt = 0;
    let outflowAmt = 0;
    let split_type = props.split_type ? 'month' : 'week';

    chart.map((obj, i) => {
        let u = obj.data.map((item) => {
            return item.entries.map((item) => {
                if (item.type == 1) {
                    inflowAmt = inflowAmt + item.amount
                    return item.amount
                }
            })
        })
    })

    return (
        <>
            <Modal
                show={open}
                // size="lg"
                onHide={() => onCloseModal()}
                // dialogClassName="chart-model-width"
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
                centered
                className="popup-model chart-box-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="">{selectedData && selectedData.financial_institute}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="chart-box-new model-chart-box-new">
                        <div className="row">
                            {/* <div className="col-xl-10 col-lg-10 col-md-9"> */}
                            <div className="model-chart-left-col">
                                <div className="chart-div">
                                    <Bar
                                        data={{
                                            labels: selectedData && selectedData.data.map((item) => {
                                                return item[split_type]
                                            }),
                                            datasets: [
                                                {
                                                    label: 'Inflow',
                                                    data: selectedData && selectedData.data.map((item) => {
                                                        return item.entries.map((item) => {
                                                            if (item.type == 1) {
                                                                inflowAmt = inflowAmt + item.amount
                                                                return item.amount
                                                            }
                                                        }).filter(function (el) {
                                                            return el != null || el != undefined;
                                                        }).toString();
                                                    }),
                                                    backgroundColor: props.color1_background

                                                },
                                                {
                                                    label: 'Outflow',
                                                    data: selectedData && selectedData.data.map((item) => {
                                                        return item.entries.map((item) => {
                                                            if (item.type == 2) {
                                                                outflowAmt = outflowAmt + item.amount
                                                                return item.amount
                                                            }
                                                        }).filter(function (el) {
                                                            return el != null || el != undefined;
                                                        }).toString();
                                                    }),
                                                    backgroundColor: props.color2_background

                                                }
                                            ],

                                        }}
                                        options={
                                            {
                                                plugins: {
                                                    legend: false
                                                },
                                                scales: {
                                                    xAxes: [
                                                        {
                                                            barPercentage: 0.6,
                                                            ticks: {
                                                                callback: (label, index, labels) => {
                                                                    return label.length > 8 ? label.substr(0, 8) + '...' : label;
                                                                },
                                                            }
                                                        },
                                                    ],
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true
                                                        }
                                                    }]
                                                },

                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="model-chart-right-col">
                                <div className="chart-content model-chart-padding">
                                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color1_background }}></span> Inflow</p>
                                    <p className="chart-cp model-chart-cp">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(inflowAmt)}</Tooltip>}>
                                            <span className='chart-price'>{showRoundOffAmount(inflowAmt)}</span>
                                        </OverlayTrigger>
                                        <span class="icon-Green-arrow chart-price-icon"></span>
                                    </p>

                                    <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color2_background }}></span> Outflow</p>
                                    <p className="chart-cp model-chart-cp">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(outflowAmt)}</Tooltip>}>
                                            <span className='chart-price'>{showRoundOffAmount(outflowAmt)}</span>
                                        </OverlayTrigger>
                                        <span class="icon-Red-arrow chart-price-icon"></span>
                                    </p>

                                    <p className="chart-lable">Balance ({selectedData && selectedData.latest_date})</p>
                                    <p className="chart-cp model-chart-cp">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(selectedData && selectedData.balance)}</Tooltip>}>
                                            <span className='chart-price'>{showRoundOffAmount(selectedData && selectedData.balance)}</span>
                                        </OverlayTrigger>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>




            {chart.map((obj, i) => {
                let inflowAmt = 0
                let outflowAmt = 0

                let a = obj.data.map((item) => {
                    return item[split_type]
                })

                return (
                    <>
                        <div className="col-xl-8 col-lg-10 col-md-12">
                            <div className="chart-box-new">
                                <h4 className="chart-box-heading">{obj.financial_institute}</h4>

                                {/* row */}
                                <div className="row">
                                    {/* <div className="col-xl-10 col-lg-10 col-md-9"> */}
                                    <div className="chart-left-col">
                                        <div className="chart-div" onClick={() => onOpenModal(obj)}>
                                            <Bar
                                                data={{
                                                    labels: obj.data.map((item) => {
                                                        return item[split_type]
                                                    }),
                                                    datasets: [
                                                        {
                                                            label: 'Inflow',
                                                            data: obj.data.map((item) => {
                                                                return item.entries.map((item) => {
                                                                    if (item.type == 1) {
                                                                        inflowAmt = inflowAmt + item.amount
                                                                        return item.amount
                                                                    }
                                                                }).filter(function (el) {
                                                                    return el != null || el != undefined;
                                                                }).toString();
                                                            }),
                                                            backgroundColor: props.color1_background

                                                        },
                                                        {
                                                            label: 'Outflow',
                                                            data: obj.data.map((item) => {
                                                                return item.entries.map((item) => {
                                                                    if (item.type == 2) {
                                                                        outflowAmt = outflowAmt + item.amount
                                                                        return item.amount
                                                                    }
                                                                }).filter(function (el) {
                                                                    return el != null || el != undefined;
                                                                }).toString();
                                                            }),
                                                            backgroundColor: props.color2_background

                                                        }
                                                    ],

                                                }}
                                                options={
                                                    {
                                                        plugins: {
                                                            legend: false
                                                        },
                                                        scales: {
                                                            xAxes: [
                                                                {
                                                                    barPercentage: 0.4,
                                                                    ticks: {
                                                                        callback: (label, index, labels) => {
                                                                            return !!label && label.length > 8 ? label.substr(0, 8) + '...' : label;
                                                                        },
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="chart-right-col">
                                        <div className="chart-content">
                                            <p className="chart-lable"><span className="chart-tag-color" style={{background: props.color1_background }}></span> Inflow</p>
                                            <p className="chart-cp">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(inflowAmt)}</Tooltip>}>
                                                    <span className='chart-price'>{showRoundOffAmount(inflowAmt)}</span>
                                                </OverlayTrigger>
                                                <span class="icon-Green-arrow chart-price-icon"></span>
                                            </p>

                                            <p className="chart-lable"><span className="chart-tag-color" style={{background: props.color2_background }}></span> Outflow</p>
                                            <p className="chart-cp">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(outflowAmt)}</Tooltip>}>
                                                    <span className='chart-price'>{showRoundOffAmount(outflowAmt)} </span>
                                                </OverlayTrigger>
                                                <span class="icon-Red-arrow chart-price-icon"></span>
                                            </p>

                                            <p className="chart-lable">Balance ({obj.latest_date})</p>
                                            <p className="chart-cp">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(obj.balance)}</Tooltip>}>
                                                    <span className='chart-price'>{showRoundOffAmount(obj.balance)}</span>
                                                </OverlayTrigger>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                            </div>
                            {/* End bar-chart-box-new */}
                        </div>
                    </>
                )
            },)
            }
        </>
    )
}

export default BarCharts
