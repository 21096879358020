import React from 'react'

class ChequeBottomSection extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show_comments: false,
      show_notes: false,
      show_tasks: false,
      show_privatemessage: false,
    }
  }

  toggleComment = () => {
    if (this.state.show_comments)
      this.setState({ show_comments: false })
    else
      this.setState({ show_comments: true, show_notes: false, show_tasks: false, show_privatemessage: false })
  }

  toggleNotes = () => {
    if (this.state.show_notes)
      this.setState({ show_notes: false })
    else
      this.setState({ show_notes: true, show_comments: false, show_tasks: false, show_privatemessage: false })
  }

  toggleTasks = () => {
    if (this.state.show_tasks)
      this.setState({ show_tasks: false })
    else
      this.setState({ show_tasks: true, show_notes: false, show_comments: false, show_privatemessage: false })
  }

  togglePrivateMessage = () => {
    if (this.state.show_privatemessage)
      this.setState({ show_privatemessage: false })
    else
      this.setState({ show_privatemessage: true, show_notes: false, show_tasks: false, show_comments: false })
  }

  render() {
    return (
      <div className="bottom-message-sec">
        <div className="main-bottom-comments-note-sec">
          {
            this.state.show_comments ?
              <div className="bottom-comments-sec">
                <div className="head-sec">
                  <span className="ico-div"><span className="icon-chat"></span></span>
                  <span className="ft-sz-32 s-sbold">Messages</span>
                </div>
                <div className="inner-comment-scroll">
                  <div className="inner-comment-sec clearfix">
                    <div className="first-letter blu-bg s-sbold">
                      <span>A</span>
                    </div>
                    <div className="comment-text">
                      <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="gren-color">Shubham Gupta</span>  please check the image for this evidence</p>
                      <div className="day-reply-sec">
                        <p className="ft-sz-16 s-sbold">2 days ago</p>
                        <p className="ft-sz-16 s-sbold">Reply</p>
                      </div>
                    </div>
                  </div>
                  <div className="inner-comment-sec clearfix">
                    <div className="first-letter green-bg s-sbold">
                      <span>S</span>
                    </div>
                    <div className="comment-text">
                      <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                      <div className="day-reply-sec">
                        <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                        <p className="ft-sz-16 s-sbold">Reply</p>
                      </div>
                    </div>
                  </div>
                  <div className="inner-comment-sec clearfix">
                    <div className="first-letter blu-bg s-sbold">
                      <span>A</span>
                    </div>
                    <div className="comment-text">
                      <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                      <div className="day-reply-sec">
                        <p className="ft-sz-16 s-sbold">just now</p>
                        <p className="ft-sz-16 s-sbold">Reply</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : null
          }

          {
            this.state.show_notes ?
              <div className="bottom-note-sec">
                <div className="head-sec">
                  <span className="ico-div"><span className="icon-Group-2546"></span></span>
                  <span className="ft-sz-32 s-sbold">Notes</span>
                </div>
                <div className="inner-note-sec clearfix">
                  <div className="cross"><span>X</span></div>
                  <div className="first-letter yellow-bg s-sbold">
                    <span>Y</span>
                  </div>
                  <div className="note-text">
                    <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                  </div>
                </div>
                <div className="inner-note-sec clearfix">
                  <div className="cross"><span>X</span></div>
                  <div className="first-letter yellow-bg s-sbold">
                    <span>Y</span>
                  </div>
                  <div className="note-text">
                    <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                  </div>
                </div>
              </div> : null
          }

          {
            this.state.show_tasks ?
              <div className="bottom-task-sec">
                <div className="head-sec">
                  <span className="ico-div"><span className="icon-Group-2516"></span></span>
                  <span className="ft-sz-32 s-sbold">Tasks</span>
                </div>
                <div className="inner-task-sec clearfix">
                  <div className="cross"><span>X</span></div>
                  <div className="check-box">
                    <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                    <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                  </div>
                  <div className="task-text">
                    <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                    <div className="day-reply-sec">
                      <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                      <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                      <p className="ft-sz-18 s-reg red-bg white-color color-div">Overdue</p>
                    </div>
                  </div>
                </div>
                <div className="inner-task-sec clearfix">
                  <div className="cross"><span>X</span></div>
                  <div className="check-box">
                    <input className="styled-checkbox" id="styled-checkbox-24" type="checkbox" value="value011" />
                    <label for="styled-checkbox-24" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                  </div>
                  <div className="task-text">
                    <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                    <div className="day-reply-sec">
                      <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                      <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                      <p className="ft-sz-18 s-reg yellow-bg white-color color-div">Due in 2 days</p>
                    </div>
                  </div>
                </div>
                <div className="inner-task-sec clearfix">
                  <div className="cross"><span>X</span></div>
                  <div className="check-box">
                    <input className="styled-checkbox" id="styled-checkbox-142" type="checkbox" value="value011" />
                    <label for="styled-checkbox-142" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                  </div>
                  <div className="task-text">
                    <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                    <div className="day-reply-sec">
                      <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                      <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                    </div>
                  </div>
                </div>
              </div> : null
          }

          {
            this.state.show_privatemessage ?
              <div className="bottom-comments-sec">
                <div className="head-sec">
                  <span className="ico-div"><span className="icon-chat"></span></span>
                  <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                </div>
                <div className="inner-comment-scroll">
                  <div className="inner-comment-sec clearfix">
                    <div className="first-letter blu-bg s-sbold">
                      <span>A</span>
                    </div>
                    <div className="comment-text">
                      <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                      <div className="day-reply-sec">
                        <p className="ft-sz-16 s-sbold">2 days ago</p>
                        <p className="ft-sz-16 s-sbold">Reply</p>
                      </div>
                    </div>
                  </div>
                  <div className="inner-comment-sec clearfix">
                    <div className="first-letter red-bg s-sbold">
                      <span>S</span>
                    </div>
                    <div className="comment-text">
                      <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                      <div className="day-reply-sec">
                        <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                        <p className="ft-sz-16 s-sbold">Reply</p>
                      </div>
                    </div>
                  </div>
                  <div className="inner-comment-sec clearfix">
                    <div className="first-letter blu-bg s-sbold">
                      <span>A</span>
                    </div>
                    <div className="comment-text">
                      <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                      <div className="day-reply-sec">
                        <p className="ft-sz-16 s-sbold">just now</p>
                        <p className="ft-sz-16 s-sbold">Reply</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : null
          }

        </div>

        <form>
          <div className="input-group">
            <div className="message-sec clearfix">
              <div className="input-group">
                <input type="text" className="form-control s-sbold ft-sz-22 font-colr pl-3" placeholder="@mention a user" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <div className="input-group-append icon-message-sec">
                  <a role="button" className="active" onClick={() => this.toggleComment()}><span className="icon-chat"></span></a>
                  <a role="button" onClick={() => this.toggleTasks()}><span className="icon-Group-2516"></span></a>
                  <a role="button" onClick={() => this.toggleNotes()}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                  <a role="button" className="" onClick={() => this.togglePrivateMessage()}><span className="icon-chat-1"></span></a>
                </div>
              </div>
              <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Comment</button>
              <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Note</button>
              <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Task</button>
            </div>
          </div>
        </form>
      </div>

    )
  }
}

export default ChequeBottomSection;