function validateUser(data)
{
  let errors = {};

  if (!data.full_name) {
    errors.full_name = 'Full Name is required';
  }

  if (!data.username) {
    errors.username = 'Username is required';
  }

  if(!validateEmail(data.email) && (!data.phone_number)){
    errors.email = 'Invalid email id';
  }

  if (!data.phone_number && (!data.email)) {
    errors.phone_number = 'Phone number is required';
  }else if (data.phone_number.length != 10 && data.phone_number) {
    errors.phone_number = 'Invalid Phone number';
  }
  // else if(!/^(\(?[1-9]{3}\)?)((\s|\-){1})?[0-9]{3}((\s|\-){1})?[0-9]{4}$/.test(data.phone_number) && data.phone_number){
  //   errors.phone_number = 'Invalid phone number';
  // }

  if(data.old_password || data.new_password || data.confirm_password){
    if(!data.old_password){
      errors.old_password = 'Old Password required';
    }
    if(!data.new_password){
      errors.new_password = 'New Password required';
    }else if(data.new_password.length < 6){
      errors.new_password = 'New Password should be min 6 character';
    }
    if(!data.confirm_password){
      errors.confirm_password = 'Confirm password required';
    }else if(data.new_password != data.confirm_password){
      errors.confirm_password = 'Password does not match';
    }
  }

  
  return errors;
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export {
    validateUser
}