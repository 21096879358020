import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
function evidencesms(){

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span className="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span className="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><i class="fa fa-bell-o" aria-hidden="true"></i></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span className="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4408"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span className="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-lightbulb"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4402"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5305"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span className="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-to-do-list"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-chat1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4403"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4404"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4405"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Evidence</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Evidence</h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-date-sec">
                  <div className="clearfix">
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec">
                          <option value="1" disabled selected>Current Financial Year</option>
                          <option value="2">Last Financial Year</option>
                          <option value="3">This Month </option>
                          <option value="4">Last 3 Months</option>
                          <option value="5">Since Beginning</option>
                          <option value="6">Custom Date Range</option>
                        </select>             
                      </div>
                    </div>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <hr/>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">End Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <div className="pull-right list-top-right-sec">
                      <button className="btn ico-refresh-sec">
                        <span className="icon-refresh"></span>    {/* add class 'cog' for icon rotation */}
                      </button>
                    </div>
                  </div> 
                </div>

                <div className="">
                  <div className="tab-inner-sec">
                    <div className="row">
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box active text-center">
                          <p className="title ft-sz-28 m-0">Total</p>
                          <div className="count-char s-bold">47</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Not Ready</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Ready</p>
                          <div className="count-char s-bold">42</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Linked </p>
                          <div className="count-char s-bold">40</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Processed</p>
                          <div className="count-char s-bold">2</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Synched</p>
                          <div className="count-char s-bold">40</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg col-sm-3 col-md-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">For Approval</p>
                          <div className="count-char s-bold">40</div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="col-xs-12">
                      <p className="count-bottim-text ft-sz-14 font-colr mb-0 mt-3">lorem ipsum text</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="btn-group btn-toggle left-on-off-btn paid-by-toggle m-0 pull-left"> 
                      <button className="btn btn-lg btn-default active">Evidence </button>
                      <button className="btn btn-lg btn-primary ">SMS</button>
                    </div>
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec">
                        <input type="text" placeholder="Search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter">
                      Filter <span className="icon-funnel"></span>
                      </div>
                      <button className="add-new-btn btn ft-sz-20 white-color s-bold"><span className="icon-plus1"></span> Add New</button>
                    </div>
                  </div>
                    <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Star</option>
                                <option>Yes</option>
                                <option>No</option>
                                <option>Both</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                          {/* <div className="form-group">
                            <div className="form-control clearfix">
                              <label className="pull-left">Star</label>
                              <div className="check-box pull-right">
                                <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Type</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="" value="Company" />
                          </div>
                        </div><div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Payment Method</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Created by</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Tags</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-lg-1 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" value="ID" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Category</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Payment status</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                          <div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec">
                            <div className="col">
                              <p className="mt-2 ft-sz-17 s-sbold mb-0">Amount</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control text-right" placeholder="₹ 0"/>
                                  </div>
                                </div>
                            </div>
                            <div className="col">
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control text-right" placeholder="₹ 0"/>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="From Amount" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="To Amount" />
                          </div>
                        </div> */}
                      </div>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>

                 {/* SMS Table list */} 
                 <div className="main-table-sec">
                    <div className="financial-insti-sec">
                      <div className="form-group mb-2">
                        <select className="form-control inner-financial-sec">
                          <option value="1" disabled selected>Financial Institute</option>
                          <option value="2">HDFC</option>
                          <option value="3">ICICI</option>
                          <option value="4">All Business</option>
                          <option value="5">Personal</option>
                          <option value="6">All</option>
                        </select>             
                      </div>
                    </div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <select className="form-control inner-all-drop-sec">
                              <option value="1" disabled selected>All</option>
                              <option value="2">Yes</option>
                              <option value="3">No</option>
                            </select>
                          </th>
                          <th className="pl-5 pr-5">Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="w-32">SMS <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">Amount <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">Financial Institute <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">Classification <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="sms-action">Action <span className="icon-arrow-down-sign-to-navigate"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                              <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td>11-11-2020</td>
                          <td>Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 548.00 on 29-11-2020 08:51 and credited to a/c no. XXXXXXXX5290</td>
                          <td className="text-center">₹245</td>
                          <td className="text-center s-sbold sky-blu-color s-sbold">Personal</td>
                          <td className="text-center s-sbold">Evidence Required</td>
                          <td><button class="create-evid-btn btn ft-sz-14 white-color s-bold">Create Evidence</button></td>
                        </tr>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                              <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td>11-11-2020</td>
                          <td>Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 548.00 on 29-11-2020 08:51 and credited to a/c no. XXXXXXXX5290</td>
                          <td className="text-center">₹245</td>
                          <td className="text-center s-sbold">SBI XXXX</td>
                          <td className="text-center s-sbold">Evidence Required</td>
                          <td><button class="create-evid-btn btn ft-sz-14 white-color s-bold">Create Evidence</button></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>  
                  
                  {/* Evidence Table list */}
                  <div className="main-table-sec">
                    <div className="not-create-sec d-none"><p className="ft-sz-32 s-sbold blu-color m-0">No Evidences created</p></div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star</th>
                          <th>Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Type <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Company <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Amount <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Payment method <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Category <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Tags <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Payment Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850 <span class="icon-Green-arrow"></span></td>
                          <td className="sky-blu-color s-bold">Reimbursement</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850 <span class="icon-Red-arrow"></span></td>
                          <td>ICIC #2648, cash</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold sky-blu-color">Ready</Tooltip>}>
                            <span className="icon-td icon-Group-25071 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>
                          
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Not Ready</Tooltip>}>
                            <span class="icon-td icon-Group-2509 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td></td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td></td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td></td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td></td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Recipt</td>
                          <td>Narayan Plaza</td>
                          <td>₹1,850</td>
                          <td>ICIC #2648</td>
                          <td>Mobile</td>
                          <td>
                            <span className="tag-td">#Mobile</span>
                          </td>
                          <td>Paid</td>
                          <td></td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Attachments</Tooltip>}>
                          <span className="icon-td icon-Group-3284"><span className="path1"></span><span className="path2"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">TDS</Tooltip>}>
                          <span className="icon-td icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">GST</Tooltip>}>
                          <span className="icon-td icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="main-pagination clearfix">
                    <nav aria-label="Page navigation example">
                      <div class="pull-left">
                        <button type="button" class="btn sms-delete-btn white-color drk-gry-bg">Delete </button>
                        <div className="main-delete-feature-sec clearfix d-none">
                          <div className=""><span class="icon-Group-3589 ft-sz-28 mr-2"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span></div>
                          <div className="ft-sz-12 s-reg font-color">Delete feature is available for only personal SMS. You have selected Business SMS, Change Selection & try again.</div>
                        </div>
                      </div>
                      <ul class="pagination pull-right mb-0">
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                        <li class="page-item item-count"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li>
                        <li class="page-item prev-sec"><a class="page-link ft-sz-20 s-reg" href="#"><span class="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li class="page-item active"><a class="page-link ft-sz-20 s-reg" href="#">1</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">3</a></li>
                        <li class="page-item next-sec"><a class="page-link ft-sz-20 s-reg" href="#">Next <span class="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>
              
              
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default evidencesms;