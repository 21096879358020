import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPageNo, setPagePerItem, setStarTask } from '../../Redux/Actions/Cheque';
import SortingIcon from '../Icon/SortingIcon';
import ChequeService from '../../Services/Cheques/ChequeService';
import { toast } from 'react-toastify';
import OpenStatus from '../Icon/OpenStatus';
import FinishedStatus from '../Icon/FinishedStatus';
import OverdueStatus from '../Icon/OverdueStatus';

class ChequeList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			cheque_list: this.props.cheque_list,
			selected_rows: []
		}
	}
	makeRemoveStar = (e, row) => {
		e.stopPropagation();
		let data = {
			id: row.id,
			is_star: row.is_star == 0 ? 1 : 0
		}
		this.props.dispatch(setStarTask(row.id, data.is_star))
		ChequeService.makeRemoveStar(data).then(
			(data) => {
				if (data.response_code == 200) {
					this.makeRemoveStar()
				} else {
					this.props.dispatch(setStarTask(row.id, row.is_star))
					toast.error(data.message);
				}
			});
	}

	dataFormatStar = (cell, row) => {
		switch (cell) {
			case 1:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>

			case 0:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
		}
	}

	dataFormatAmount = (cell, row) => {
		return <span> &#8377; {cell} {row.type === 1 ?
			<i class="fa fa-long-arrow-up gren-color" aria-hidden="true"></i>
			:
			<i class="fa fa-long-arrow-down red-color" aria-hidden="true"></i>
		} </span>
	}

	dataFormatStatus = (cell, row) => {
		switch (cell) {
			case 1:
				return <OpenStatus />

			case 2:
				return <FinishedStatus />

			case 3:
				return <OverdueStatus />

		}
	}

	msgTaskStatus = (cell, row) => {
		return <div className="row">
			<span className="icon-chat col"></span>
			<span className="icon-Group-2516 col"></span>
		</div>
	}

	_setNoDataFoundOption() {
		if (!this.props.is_cheque_loading) {
			return "No Cheque found";
		} else {
			return (
				<p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
			);
		}
	}

	onSortClick = (sort_order, sort_by) => {
		console.log('sort_order   ', sort_order)
		console.log('sort_by    ', sort_by)
		this.props.onSortClick(sort_order, sort_by)
	}

	goToEditView = (row) => {
		this.props.history.push("/cheque/create/" + row.id)
	}

	handlePageChange = (page, sizePerPage) => {
		this.props.dispatch(setPageNo(page))
	}

	handleSizePerPageChange = (sizePerPage) => {
		this.props.dispatch(setPagePerItem(sizePerPage))
	}
	renderShowsTotal(start, to, total) {
		return (
			<p className="main-pagi-show">
				<span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
			</p>
		);
	}
	render() {
		const options = {
			onPageChange: this.handlePageChange,
			onSizePerPageList: this.handleSizePerPageChange,
			page: this.props.page_no,  // which page you want to show as default
			sizePerPage: this.props.per_page_items,  // which size per page you want to locate as default
			pageStartIndex: 1, // where to start counting the pages
			paginationSize: 5,  // the pagination bar size.
			prePage: 'Prev', // Previous page button text
			nextPage: 'Next', // Next page button text
			firstPage: 'First', // First page button text
			lastPage: 'Last', // Last page button text
			paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
			hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
			onRowClick: (row) => {
				this.goToEditView(row);
			},
			noDataText: this._setNoDataFoundOption(),
		}
		return (
			<div>
				<div className="main-table-sec">
					{this.props.cheque_list.length === 0 && this.props.is_cheque_loading === false ?
						<div className="not-create-sec"><p className="ft-sz-32 s-sbold blu-color m-0">No Cheques</p></div>
						:
						<BootstrapTable ref='table' data={this.props.cheque_list} responsive table-bordered
							pagination={true}
							options={options}
							remote
							fetchInfo={{ dataTotalSize: this.props.total_count }}
							remote
							pagination
							striped
							hover
							condensed
							keyField='id'>
							<TableHeaderColumn width="100" dataField='is_star' dataFormat={(cell, row) => this.dataFormatStar(cell, row)} >
								<SortingIcon onSortClick={this.onSortClick} field_name="Star" field_number="2"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
							</TableHeaderColumn>
							<TableHeaderColumn width="150" dataField='date' >
								<SortingIcon onSortClick={this.onSortClick} field_name="Cheque Date" field_number="3"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
							</TableHeaderColumn>
							<TableHeaderColumn dataField='bank_name' >
								<SortingIcon onSortClick={this.onSortClick} field_name="Bank" field_number="4"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
							<TableHeaderColumn dataField='cheque_identifier' >
								<SortingIcon onSortClick={this.onSortClick} field_name="Cheque#" field_number="5"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
							<TableHeaderColumn dataField='payer' >
								<SortingIcon onSortClick={this.onSortClick} field_name="Payer" field_number="6"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
							<TableHeaderColumn dataField='payee' >
								<SortingIcon onSortClick={this.onSortClick} field_name="Payee" field_number="7"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
							<TableHeaderColumn dataField='amount' dataFormat={(cell, row) => this.dataFormatAmount(cell, row)} >
								<SortingIcon onSortClick={this.onSortClick} field_name="Amount" field_number="8"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order}
								/></TableHeaderColumn>
							<TableHeaderColumn dataField='status'
								dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>
								<SortingIcon onSortClick={this.onSortClick} field_name="Status" field_number="9"
									sort_by={this.props.sort_by} sort_order={this.props.sort_order}
								/>
							</TableHeaderColumn>
							<TableHeaderColumn dataField='status' dataFormat={(cell, row) => this.msgTaskStatus(cell, row)}></TableHeaderColumn>
						</BootstrapTable>
					}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.cheque
	const total_count = all_value.total_count;
	const page_no = all_value.page_no;
	const per_page_items = all_value.per_page_items;
	const is_cheque_loading = all_value.is_cheque_loading;
	const cheque_id = all_value.cheque_id;
	const changed_star = all_value.changed_star;
	return {
		total_count, page_no, per_page_items, is_cheque_loading, cheque_id, changed_star
	};
}


export default connect(mapStateToProps)(withRouter(ChequeList));