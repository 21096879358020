import Cookies from 'js-cookie';
import StorageData from '../Helpers/StorageData';

class SessionManagement {

    setUserDetail(user_detail){
        //var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
        Cookies.set('buktec_user_detail', JSON.stringify(user_detail), { expires: 60 });
    }

    updateUserDetail(update_user_detail){
        let user_detail=this.getUserDetail();
        user_detail.full_name=update_user_detail.full_name;
        user_detail.email=update_user_detail.email;
        user_detail.phone_number=update_user_detail.dialcode+""+update_user_detail.phone_number;
        user_detail.qualified_url=update_user_detail.qualified_url;
        this.setUserDetail(user_detail);
    }

    getUserDetail(){
        try{
            // console.group('Get Cookies value ---- ', Cookies.get('buktec_user_detail'));
            let user_detail = Cookies.get('buktec_user_detail');
            return JSON.parse(user_detail);
        }
        catch(e){
            console.log('Errors Occured --- So Redirect to Login',e);
            this.RemoveAll();
            StorageData.deleteAllStorageValue();
            window.location.href = '/login/';
         //   window.location.reload();
        }
    }

    setRememberMe(remeberMe=true){
        Cookies.set('access_token',remeberMe, { expires: 60 });
    }

    getRememberMe(){
        return Cookies.get('access_token');
    }

    setAccessToken(access_token){
        if(this.getRememberMe() != "false")
            Cookies.set('access_token',access_token, { expires: 60 });
        else
            Cookies.set('access_token',access_token);
    }

    getAccessToken(){
        return Cookies.get('access_token');
    }

    setRefreshToken(refresh_token){
        if(this.getRememberMe() != "false")
            Cookies.set('refresh_token',refresh_token, { expires: 60 });
        else
            Cookies.set('refresh_token',refresh_token);
    }

    getRefreshToken(){
        return Cookies.get('refresh_token');
    }

    isLogin(){
        let access_token=this.getAccessToken();
        return !!access_token;
    }

    RemoveAll(){
        Object.keys(Cookies.get()).forEach(function(cookieName) {
            var neededAttributes = {
              // Here you pass the same attributes that were used when the cookie was created
              // and are required when removing the cookie
            };
            Cookies.remove(cookieName, neededAttributes);
          });
    }

    setLastUrl(last_url){
        sessionStorage.setItem("last_url",last_url);
    }

    getLastUrl(is_delete=true){
        let last_url = sessionStorage.getItem("last_url");
        if(is_delete)
            sessionStorage.removeItem("last_url");
        return last_url;
    }
    
    removeLastUrl(){
        let last_url = sessionStorage.getItem("last_url");
        sessionStorage.removeItem("last_url");
        return last_url;
    }
}

export default new SessionManagement();