import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function ReadyStatus(props) {
    return <div  >
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="yellow-color">Ready</Tooltip>}>
            <span className="icon-ready-icon ft-sz-30 icon-td"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
            {/* <span className="icon-Group-25071 ft-sz-30 icon-td"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span> */}
        </OverlayTrigger>
        </div>
}
export default ReadyStatus;