import React from 'react'
import { connect } from 'react-redux';
import { LINKING_CODE } from '../../Helpers/Constants';
import { displayDateFormate } from '../../Helpers/DateFormat';
import LinkedStatus from '../IcoonNew/LinkedStatus';
import NotReadyStatus from '../IcoonNew/NotReadyStatus';
import ProcessedStatus from '../IcoonNew/ProcessedStatus';
import ReadyStatus from '../IcoonNew/ReadyStatus';
import SyncedStatus from '../IcoonNew/SyncedStatus';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import { ArrowUpDownIcon } from '../IcoonNew';
import { showAmount } from '../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  getStatus = (status) => {
    switch (status) {
      case 1:
        return <NotReadyStatus />
      case 2:
        return <ReadyStatus />
      case 4:
        return <ProcessedStatus />
      case 5:
        return <SyncedStatus />
      default:
        return "--"
    }
  }

  lastThreeDigits = (account_number) => {
    if (account_number.length > 3) {
      return account_number.substring(account_number.length - 3)
    }
    else {
      return account_number
    }
  }

  render() {
    let transaction_detail = this.props.transaction_details
    //console.log(transaction_detail)
    // if(transaction_detail.status == 3 || transaction_detail.status == 5) {
    //   var editable = false;
    // } else {
    //   var editable = true;
    // }

    if (this.props.read_only) {
      var editable = false;
    } else {
      var editable = true;
    }

    let bank_name = "";

    if (transaction_detail.account_number) {
      bank_name = transaction_detail.financial_institute + " " + (transaction_detail.is_credit_card == 0 ? "- Acct" : "- Card") + " XX" + this.lastThreeDigits(transaction_detail.account_number);
    }
    // console.log(this.props)
    return (
      // <div className="main-top-link-tran-sec tran-table transdetail_moneyin">
      <div className="form-content-sec-new ml-tran-table-sticky">
        <div className="form-sec-inner-new transdetail_moneyin">
          <div className="table-responsive money-link-tran-table">
            <table className="trans-table">
              <colgroup>
                <col width="110px" />
                <col width="250px" />
                <col width="" />
                <col width="100px" className="text-right" />
                <col width="100px" className="text-center" />
                <col width="60px" className="text-center" />
              </colgroup>

              <tr>
                <td scope="col" className="lable-input-trans">Date</td>
                <td scope="col" className="lable-input-trans">Financial Institute</td>
                <td scope="col" className="lable-input-trans">Narration</td>
                <td scope="col" className="lable-input-trans text-right">Amount</td>
                <td scope="col" className="lable-input-trans text-center">Personal</td>
                <td scope="col" className="lable-input-trans text-center">Status</td>
              </tr>

              <tr>
                <td scope="col" className="form-control-trans">{displayDateFormate(transaction_detail.date)}</td>
                <td scope="col" className="form-control-trans">{bank_name}</td>
                <td scope="col" className="form-control-trans">{transaction_detail.transaction_narration}</td>

                <td scope="col" className="form-control-trans text-right">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className={transaction_detail.type === 1 ? "green-color" : "red-color"}>{showAmount(Math.abs(transaction_detail.amount)) || 0}</span></Tooltip>}>
                    <span className={"ellips line-h-auto mr-1 " + (transaction_detail.type === 1 ? "green-color" : "red-color")}>{showAmount(Math.abs(transaction_detail.amount)) || 0}</span>
                  </OverlayTrigger>

                  <ArrowUpDownIcon expense={transaction_detail.type === 1 ? false : true} />
                </td>

                <td scope="col" className="form-control-trans text-center">
                    <div className="sup-cust-check lable-input justify-content-center" style={{marginTop: "3px"}}>
                      <input
                        className="styled-checkbox"
                        id="personal-check"
                        type="checkbox"
                        value={this.props.is_personal}
                        checked={this.props.is_personal === 1}
                        onClick={() => {
                          if (this.props.features_permissions_list.includes(LINKING_CODE) && editable)
                            this.props.markAsPersonal()
                        }}
                        disabled={(!this.props.features_permissions_list.includes(LINKING_CODE)) || !editable}
                      /><label class="" for="personal-check"></label>
                  </div>
                </td>

                <td scope="col" className="text-center"><div style={{marginTop: '2px'}}>{this.getStatus(this.props.transaction_status)}</div></td>
              </tr>              
            </table>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const is_personal = all_value.is_personal;
  const no_supporting_document = all_value.no_supporting_document;
  const transaction_details = all_value.transaction_details
  const transaction_status = all_value.transaction_status

  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];
  return {
    is_personal, no_supporting_document, transaction_details, features_permissions_list, transaction_status
  };
}

export default connect(mapStateToProps)(TransactionDetails);