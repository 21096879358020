import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import { showRoundOffAmount } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class BarChartDoubleBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chart_data: null,
      show_modal: false,
      type1_total_amount: 0,
      type2_total_amount: 0,
    }
  }

  componentDidMount = () => {

    let split_type = this.props.split_type ? 'month' : 'week';

    let chart_data = {
      labels: this.props.data.map((item) => {
        return item[split_type]
      }),
      datasets: [{
        label: this.props.type1_label,
        data: this.props.data.map((item) => {
          return item.entries.map((item) => {
            if (item.type == 1) {
              this.setState(prevstate => ({ type1_total_amount: prevstate.type1_total_amount + item.amount }));
              return item.amount
            }
          }).filter(function (el) {
            return el != null || el != undefined;
          }).toString();
        }),
        backgroundColor: this.props.color1_background,
      },
      {
        label: this.props.type2_label,
        data: this.props.data.map((item) => {
          return item.entries.map((item) => {
            if (item.type == 2) {
              this.setState(prevstate => ({ type2_total_amount: prevstate.type2_total_amount + item.amount }));
              return item.amount
            }
          }).filter(function (el) {
            return el != null || el != undefined;
          }).toString();
        }),
        backgroundColor: this.props.color2_background
      }]
    }
    this.setState({ chart_data: chart_data })


  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data != prevProps.data) {
      let split_type = this.props.split_type ? 'month' : 'week';

      let chart_data = {
        labels: this.props.data.map((item) => {
          return item[split_type]
        }),
        datasets: [{
          label: this.props.type1_label,
          data: this.props.data.map((item) => {
            return item.entries.map((item) => {
              if (item.type == 1) {
                this.setState(prevstate => ({ type1_total_amount: prevstate.type1_total_amount + item.amount }));
                return item.amount
              }
            }).filter(function (el) {
              return el != null || el != undefined;
            }).toString();
          }),
          backgroundColor: this.props.color1_background,
        },
        {
          label: this.props.type2_label,
          data: this.props.data.map((item) => {
            return item.entries.map((item) => {
              if (item.type == 2) {
                this.setState(prevstate => ({ type2_total_amount: prevstate.type2_total_amount + item.amount }));
                return item.amount
              }
            }).filter(function (el) {
              return el != null || el != undefined;
            }).toString();
          }),
          backgroundColor: this.props.color2_background
        }]
      }
      this.setState({ chart_data: chart_data })
    }

  }

  chartComponent = () => {
    let options = {
      responsive: true,
      plugins: {
        legend: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6
          },
        }]
      }
    }

    return (
      <div className="chart-box-new">
        <h4 className="chart-box-heading">{this.props.title}</h4>

        {/* row */}
        <div className="row">
          {/* <div className="chart-left-col"> */}
          <div className="col-xl-9 col-lg-9 col-md-9 pr-0">
            <div className="chart-div" onClick={() => this.setState({ show_modal: true })}>
              <Bar data={this.state.chart_data} options={options}></Bar>
            </div>
          </div>

          {/* <div className="chart-right-col"> */}
          <div className="col-xl-3 col-lg-3 col-md-3">
            <div className="chart-content">
              <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color1_background }}></span> {this.props.type1_label}</p>
              <p className="chart-cp">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.type1_total_amount)}</Tooltip>}>
                  <span className='chart-price'>{showRoundOffAmount(this.state.type1_total_amount)}</span>
                </OverlayTrigger>
                <span class="icon-Green-arrow chart-price-icon"></span>
              </p>

              <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> {this.props.type2_label}</p>
              <p className="chart-cp">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.type2_total_amount)}</Tooltip>}>
                  <span className='chart-price'>{showRoundOffAmount(this.state.type2_total_amount)}</span>
                </OverlayTrigger>
                <span class="icon-Red-arrow chart-price-icon"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  popupChartComponent = () => {
    let options = {
      responsive: true,
      plugins: {
        legend: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6
          },
        }]
      }
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="chart-box-new model-chart-box-new">
            <div className="row">
              <div className="col-xl-10 col-lg-10 col-md-9 pr-0">
                <div className="chart-div">
                  <Bar data={this.state.chart_data} options={options}></Bar>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-md-3">
                <div className="chart-content model-chart-padding">
                  <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color1_background }}></span> {this.props.type1_label}</p>
                  <p className="chart-cp model-chart-cp">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.type1_total_amount)}</Tooltip>}>
                      <span className='chart-price'>{showRoundOffAmount(this.state.type1_total_amount)}</span>
                    </OverlayTrigger>
                    <span class="icon-Green-arrow chart-price-icon"></span>
                  </p>

                  <p className="chart-lable"><span className="chart-tag-color" style={{ background: this.props.color2_background }}></span> {this.props.type2_label}</p>
                  <p className="chart-cp model-chart-cp">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(this.state.type2_total_amount)}</Tooltip>}>
                      <span className='chart-price'>{showRoundOffAmount(this.state.type2_total_amount)} </span>
                    </OverlayTrigger>
                    <span class="icon-Red-arrow chart-price-icon"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    )
  }

  render() {
    return (
      <>
        {this.chartComponent()}
        <Modal
          show={this.state.show_modal}
          // size="lg"
          onHide={() => this.setState({ show_modal: false })}
          // dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          backdrop="static"
          centered
          className="popup-model chart-box-popup"
        >
          {this.popupChartComponent()}
        </Modal>
      </>
    )
  }
}