import React from 'react'
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import FinancialYear from '../../Components/BoilerPlate/FinancialYear';
import { changeDateFormate, displayDateFormate } from '../../Helpers/DateFormat';
import { setFilterData, setSmsForEvidence, setSmsListData, smsSetPageNo, smsSetPagePerItem } from '../../Redux/Actions/Snapshot';
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import SortingIcon from '../Icon/SortingIcon';
import SMSFilter from './SMSFilter';

/* static ui elements */
const td_style = { /*whiteSpace: 'normal', wordWrap: 'break-word'*/ };

class SMSView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search_text: "",
			financial_institute_id: null,
			show_filter: false,
			active_input: false,
			delete_sms_list: [],
			show_delete_popup: false,
			check_all: false,
			classification: null

		}
	}

	componentDidMount() {
		if (typeof this.props.sms_list === 'undefined') {
			this.props.getSmsList()
		}
	}
	handleChange = (e) => {
		const { value, name } = e.target;
		this.setState({ [name]: value });
	}

	handleClassfication = (e) => {
		let { value, name } = e.target;
		this.setState({ [name]: value });
		value = value === 'null' ? null : value;
		this.props.handleClassification(value)
	}

	handleCheckBoxChange = (e, id) => {
		const { name } = e.target, deleteSmsList = this.state.delete_sms_list;
		if (e.target.checked) {
			deleteSmsList.push(id)
		} else {
			let index = deleteSmsList.indexOf(id);
			deleteSmsList.splice(index, 1);
		}
		this.setState({
			[name]: e.target.checked, delete_sms_list: deleteSmsList
		});
	}

	toggleShowFilter = () => {
		this.setState({ show_filter: !this.state.show_filter })
	}

	onSortClick = (sort_order, sort_by) => {
		this.props.onSmsSortClick(sort_order, sort_by)
	}

	displayAmount = (cell, row) => {
		return <span>{`₹${cell} `} </span>
	}

	displayClassification = (cell, row) => {
		if (cell === 1) {
			return "Evidence Required"
		} else
			return "Evidence Not Required"
	}

	displayCheckBox = (cell, row) => {
		return <div className="check-box">
			<input disabled={row.type === 1} className="styled-checkbox" id={`styled-checkbox-${row.id}`} type="checkbox"
				name={`delete${row.id}`} value={`delete${row.id}`} onChange={(e) => this.handleCheckBoxChange(e, row.id)} />
			<label htmlFor={`styled-checkbox-${row.id}`} className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
		</div>
	}

	displayActionButton = (cell, row) => {
		return <Link to={`/snapshot/sms/create/${row.id}`} className="add-new-btn btn ft-sz-20 white-color s-bold">
			<button onClick={() => {
				// this.props.dispatch(setSmsForEvidence(row))

			}} className="create-evid-btn btn ft-sz-15 white-color s-bold">Create Evidence</button>
		</Link>
	}

	handlePageChange = (page, sizePerPage) => {
		this.props.dispatch(smsSetPageNo(page))
		this.props.setSMSPageNo(page)

	}

	handleSizePerPageChange = (sizePerPage) => {
		this.props.dispatch(smsSetPagePerItem(sizePerPage))
	}

	renderShowsTotal(start, to, total) {
		return (
			<p className="main-pagi-show">
				<span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
			</p>
		);
	}

	handleFinancialInstituteChange = (e) => {
		let filter_data = {
			from_amount: null,
			to_amount: null,
			financial_institute_id: e.target.value,
		}
		this.props.applySMSFilter("apply", filter_data);
	}

	deleteSms = () => {
		let detail = {
			"id": this.state.delete_sms_list
		}
		SnapshotService.deleteMultipleSMS(detail).then((data) => {
			if (data.response_code === 200) {
				toast.success('SMS deleted')
				this.props.getSmsList()
			} else {
				toast.error(data.message)
			}
		})
		this.toggleDeletePopup()


	}

	toggleDeletePopup = () => {
		this.setState({ show_delete_popup: !this.state.show_delete_popup })
	}

	render() {
		const options = {
			onPageChange: this.handlePageChange,
			onSizePerPageList: this.handleSizePerPageChange,
			page: this.props.sms_page_no,  // which page you want to show as default
			sizePerPage: this.props.sms_per_page_items,  // which size per page you want to locate as default
			pageStartIndex: 1, // where to start counting the pages
			paginationSize: 5,  // the pagination bar size.
			prePage: 'Prev', // Previous page button text
			nextPage: 'Next', // Next page button text
			firstPage: 'First', // First page button text
			lastPage: 'Last', // Last page button text
			paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
			hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
			onRowClick: (row) => {
			},
			// noDataText: this._setNoDataFoundOption(),
		};


		return (
			<div className="content-wrapper">
				<DeletePopUp
					toggleDeletePopUp={this.toggleDeletePopup}
					showModal={this.state.show_delete_popup}
					deleteItem={this.deleteSms} />
				<div className="container-fluid">
					<div className="row bg-w bg-rounded">
						<div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
							<BreadCrum />
							{/* <ol className="breadcrumb">
								<li className="breadcrumb-item">
								Menu
																</li>
								<li className="breadcrumb-item">Snapshot</li> 
							</ol> */}
							<div className="clearfix main-title-btn-sec">
								<div className="head-title pull-left">
									<h2 className="s-sbold blu-color m-0">SMS</h2>
								</div>
							</div>
							<div className="main-evidence-sms-sec">
								<FinancialYear
									financialYearChanged={this.props.smsFinancialYearChanged}
									refreshItem={this.props.smsRefreshItem}
								/>
								<div className="main-evidence-sms-table-sec">
									<div className="toggle-search-sec clearfix">
										<div className="btn-group btn-toggle left-on-off-btn paid-by-toggle m-0 pull-left">
											<button className={"btn btn-lg btn-default "} onClick={this.props.handleTabChange}
												name="tab" value="1">Evidence </button>
											<button className={"btn btn-lg btn-primary active"} onClick={this.props.handleTabChange}
												name="tab" value="2">SMS</button>
										</div>
										<div className="search-filter-sec pull-right">
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">
												SMS, Amount
        										</Tooltip>}>
												<form className="search-sec">
													<input type="text" className={"form-control"+(this.state.active_input?" active-search":"")} placeholder="Search" value={this.state.search_text}
														name="search_text" onFocus={()=>this.setState({active_input:true})} onBlur={()=>this.setState({active_input:false})} onChange={(e) => this.handleChange(e)} />
													<button className={this.state.active_input?"active-search-icon":""} onClick={(e) => {
														e.preventDefault()
														this.props.handleSearch(this.state.search_text)
													}} >
														<span className="icon-loupe"  ></span>
													</button>
												</form>
											</OverlayTrigger>
											<div className={this.state.show_filter?"filter active-class":"filter"} onClick={this.toggleShowFilter}>
												Filter <span className="icon-funnel"></span>
											</div>
										</div>
									</div>
									{this.state.show_filter ? <SMSFilter
										financial_institute_list={this.props.financial_institute_list}
										applyFilter={this.props.applySMSFilter} /> : null}
									{/* SMS Table list */}

									{/* <div className="financial-insti-sec">
										<div className="form-group mb-2">
											<select className="form-control inner-financial-sec" name="classification" onChange={this.handleClassfication} >
												<option value='null' >All</option>
												<option value={1}>Business</option>
												<option value={2}>Personal</option>
											</select>
										</div>
										<div className="form-group mb-2">
											<select className="form-control inner-financial-sec"
												onChange={this.handleFinancialInstituteChange}
												value={this.state.financial_institute_id}
												name="financial_institute_id" >
												<option value={null} selected>Financial Institute</option>
												{this.props.financial_institute_list.map((institute, index) => {
													return <option value={institute.id}>{institute.bank_name}</option>
												})}
											</select>
										</div>
									</div> */}
									<div className="main-table-sec">
										{/* <div className="financial-insti-sec">
											<div className="form-group mb-2">
												<select className="form-control inner-financial-sec" name="classification" onChange={this.handleClassfication} >
													<option value='null' >All</option>
													<option value={1}>Business</option>
													<option value={2}>Personal</option>
												</select>
											</div>
											<div className="form-group mb-2">
												<select className="form-control inner-financial-sec"
													onChange={this.handleFinancialInstituteChange}
													value={this.state.financial_institute_id}
													name="financial_institute_id" >
													<option value={null} selected>Financial Institute</option>
													{this.props.financial_institute_list.map((institute, index) => {
														return <option value={institute.id}>{institute.bank_name}</option>
													})}
												</select>
											</div>
										</div> */}
										{this.props.sms_list.length===0?						        <div className="not-create-sec "><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>: 
										<BootstrapTable ref='table' data={this.props.sms_list} responsive table-bordered
											pagination={true}
											options={options}
											remote
											fetchInfo={{ dataTotalSize: this.props.sms_total_count }}
											remote
											pagination
											striped
											hover
											condensed
										>
											<TableHeaderColumn width="40" tdStyle={td_style} dataField='' dataFormat={(cell, row) => this.displayCheckBox(cell, row)}>

											</TableHeaderColumn>
											<TableHeaderColumn width="130" tdStyle={td_style} thStyle={{ textAlign: 'center' }} tdStyle={{ textAlign: 'center' }} isKey dataField='date' dataFormat={(cell, row) => displayDateFormate(cell)} >
												<SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="2"
													sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
											</TableHeaderColumn>
											<TableHeaderColumn  className="xx" tdStyle={td_style} dataField='sms' >
												<SortingIcon onSortClick={this.onSortClick} field_name="SMS" field_number="3"
													sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
											<TableHeaderColumn width="170" tdStyle={td_style} thStyle={{ textAlign: 'right' }} tdStyle={{ textAlign: 'right' }} dataField='amount' dataFormat={(cell, row) => this.displayAmount(cell, row)} >
												<SortingIcon onSortClick={this.onSortClick} field_name="Amount" field_number="4" 
													sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
											<TableHeaderColumn width="200" tdStyle={td_style} thStyle={{ textAlign: 'left' }} tdStyle={{ textAlign: 'left' }} dataField='financial_institute' >
												<SortingIcon onSortClick={this.onSortClick} field_name="Financial Institute" field_number="5"
													sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
											<TableHeaderColumn width="200" tdStyle={td_style} thStyle={{ textAlign: 'left' }} tdStyle={{ textAlign: 'left' }} dataField='classification' dataFormat={(cell, row) => this.displayClassification(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Classification" field_number="6"
													sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="170" tdStyle={td_style} thStyle={{ textAlign: 'center' }} tdStyle={{ textAlign: 'center', overflow:'inherit', whiteSpace:'initial', textOverflow:'inherit' }} dataField='' dataFormat={(cell, row) => this.displayActionButton(cell, row)}>Action</TableHeaderColumn>
										</BootstrapTable>}

									</div>

									<div className="ft-sz-16 s-reg font-color mt-3"><b>Note:</b> Delete feature is available for only non registered financial institute.</div>

									<div className="main-pagination clearfix mt-4">
										<nav aria-label="Page navigation example">										
											<div className="pull-left">
												<button type="button" disabled={this.state.delete_sms_list.length === 0 ? true : false} className="btn sms-delete-btn white-color drk-gry-bg"
													onClick={this.toggleDeletePopup}>Delete </button>
												{/* <div className="main-delete-feature-sec clearfix ">
													<div className=""><span className="icon-Group-3589 ft-sz-28 mr-2"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></div>
													<div className="ft-sz-12 s-reg font-color">Delete feature is available for only personal SMS.</div>
												</div> */}
											</div>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
			</div>

		)
	}
}


function mapStateToProps(state) {
	const all_value = state.Snapshot
	const sms_page_no = all_value.sms_page_no;
	const sms_per_page_items = all_value.sms_per_page_items;

	return {
		sms_page_no, sms_per_page_items
	};
}

export default connect(mapStateToProps)(SMSView);