import React from 'react';
import welcome from '../../Assets/img/welcomelogo.png';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { propTypes } from 'react-bootstrap/esm/Image';



function WelcomeView(props){ 
    const user_detail=props.user_detail;

    //fix BU1-I287 : check for active entities only
    let entity_detail = props.entity_list.find(entity => (entity.status == 3 || entity.status == 2));
    
    if(!entity_detail && user_detail.account_type == 1 && user_detail.is_owner == 1){
      props.history.push("/profile-management/entities")
    }else{
      props.history.push("/home")
    }


    const [show,setShow]=React.useState(false) 

    return (
    // <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
        <div className="content-wrapper" id="page-top">
          <div className="container-fluid">
            <div className="row bg-w bg-rounded">
              <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
                <div className="main-home-sec">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 homepage">
                      <div className="text-center" >
                        <img src={welcome} className="img-fluid" />
                        <h1 className="s-bold blu-color mt-4 mb-4 d-none">Welcome To Buktec</h1>
                      </div>
                    </div>
                 </div>
                </div>
              </div>
            </div>

            <footer className="sticky-footer d-none">
              <div className="container">
                <div className="text-center">
                  <small>Copyright © Buktec 2021</small>
                </div>
              </div>
            </footer>
            <a className="scroll-to-top rounded" href="#page-top">
              <span className="icon-right-arrow white-icon fs10"></span>
            </a>
          </div>
        </div>     
      // </div>  
      );
}

function mapStateToProps(state) {
  const all_value = state.Session || {};
  const entity_list = all_value.entity_list || [];
  const account_permissions = all_value.account_permissions || [];
  const module_permissions = all_value.module_permissions || [];
  const user_detail = all_value.user_detail || {}
  return {
    entity_list, account_permissions, module_permissions,user_detail
  };
}
export default connect(mapStateToProps)(withRouter(WelcomeView));