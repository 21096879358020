import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function InactiveUser(props) {
    return <React.Fragment >
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Inactive</Tooltip>}>
            <span className="icon-td icon-Group-3218 inactiveuser"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
            </OverlayTrigger>
    </React.Fragment>
}
export default InactiveUser;