import React, { Component } from 'react'
import EntitiesBoard from '../../Components/Dashboard/CABoard/EntitiesBoard';
import UserWiseTask from '../../Components/Dashboard/CABoard/UserWiseTask';
import TransActivities from '../../Components/Dashboard/CABoard/TransActivities';
import LicensesBoard from '../../Components/Dashboard/CABoard/LicensesBoard';

export default class CABoard extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  entityInvites = () => {
    const ca_board_data = this.props.ca_board_data;

    return (
      ca_board_data.entities.entity_invites.length != 0 && ca_board_data.sequence.includes(1) &&
      <div className="col-xl-12 dash-card-mb">
        <EntitiesBoard data={ca_board_data.entities} />
      </div>
    )
  }

  userWiseTasks = () => {
    const ca_board_data = this.props.ca_board_data;

    return (
      ca_board_data.user_wise_tasks.length != 0 && ca_board_data.sequence.includes(2) &&
      <div className="col-xl-6 dash-card-mb">
        <UserWiseTask data={ca_board_data.user_wise_tasks} />
      </div>
    )
  }

  transactionsActivities = () => {
    const ca_board_data = this.props.ca_board_data;

    return (
      ca_board_data.transactions_activities.length != 0 && ca_board_data.sequence.includes(3) &&
      <div className="col-xl-6 dash-card-mb">
        {/* ------- */}
        <TransActivities
          data={ca_board_data.transactions_activities}
          color1_background={this.props.color1_background}
          color2_background={this.props.color2_background} />
      </div>
    )
  }

  licensesPaid = () => {
    const ca_board_data = this.props.ca_board_data;
    return (
      ca_board_data.licenses.licenses_paid.length != 0 && ca_board_data.sequence.includes(4) &&
      <div className="col-xl-12 dash-card-mb">
        <LicensesBoard
          data={ca_board_data.licenses}
          color1_background={this.props.color1_background}
          color2_background={this.props.color2_background}
          color3_background={this.props.color3_background}
        />
      </div>
    )
  }

  render() {
    const ca_board_data = this.props.ca_board_data;
    const sequence = this.props.ca_board_data.sequence;
    // const order = {1: this.entityInvites(), 2: this.userWiseTasks(), 3: this.transactionsActivities(), 4: this.licensesPaid()}
    const order = { 1: this.entityInvites(), 2: this.licensesPaid(), 3: this.userWiseTasks() }

    return (
      <>
        <div className="buktec-summary-new">
          {(ca_board_data.entities.entity_invites.length == 0 && ca_board_data.user_wise_tasks.length == 0 && ca_board_data.transactions_activities.length == 0 && ca_board_data.licenses.licenses_paid.length == 0) && <h2 className="no-item-list">No items to display</h2>}

          <div className="row">
            {this.props.ca_board_data.sequence && sequence.map((seq, index) => {
              return order.hasOwnProperty(seq) ? order[seq] : null
            })}
          </div>
        </div>

        {/* <div className="my-board chart-scroll-sec ">
          {(ca_board_data.entities.entity_invites.length == 0 && ca_board_data.user_wise_tasks.length == 0 && ca_board_data.transactions_activities.length == 0 && ca_board_data.licenses.licenses_paid.length == 0) && <div className="not-create-sec"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>}
          <div className="row main-row">
            {this.props.ca_board_data.sequence && sequence.map((seq, index) => {
              return order.hasOwnProperty(seq) ? order[seq] : null
            })}
          </div>
        </div> */}
      </>
    )
  }
}