import React, { useState } from 'react'
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import gplay from '../../Assets/img/gplay.png';
import appstore from '../../Assets/img/appstore.png';
import googleplay from '../../Assets/img/googleplay.png';
import accountp from '../../Assets/img/accountp.png';
import downloadicon from '../../Assets/img/downloadicon.svg';
import { Dropdown } from 'react-bootstrap';
import { logout } from "../../Redux/Actions/Auth";
import SetEntity from '../../Components/BoilerPlate/SetEntityModal';
import SessionManagement from '../../Utils/SessionManagement';
import StorageData from '../../Helpers/StorageData';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import Button from 'react-bootstrap/Button';

import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Notification from './Notification';
import { toast } from 'react-toastify';

import Spinner from 'react-bootstrap/Spinner';
import pdficont from '../../Assets/img/pdficont.png';
import testimg from '../../Assets/img/profile.png';

// import { Button, Modal, Table } from 'react-bootstrap';



import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TaskManagerService from '../../Services/TaskManager/TaskManagerService';
import { setStarTask, setPageNo, setPagePerItem, setTaskLoadingStatus } from "../../Redux/Actions/TaskManager";
import SortingIcon from '../Icon/SortingIcon';
import OpenStatus from '../Icon/OpenStatus';
import FinishedStatus from '../Icon/FinishedStatus';
import OverdueStatus from '../Icon/OverdueStatus';
import DataTableCheckBox from '../../Components/BoilerPlate/DataTableCheckBox';
import { displayDateFormate } from '../../Helpers/DateFormat';
import _ from "lodash";



import BreadCrum from '../../Components/BoilerPlate/BreadCrum';



function TestPage() {
    return <>
        <div className="" id="page-top">
            {/* Navbar */}
            {/* <nav className="navbar navbar-expand-lg navbar-light gray-bg fixed-top" id="mainNav"></nav> */}
            {/* <nav className="navbar navbar-expand-lg navbar-light gray-bg"> */}
            <nav className="navbar navbar-expand-lg navbar-light gray-bg sticky-top" id="mainNav">
                {/* <div className="row no-gutters w-100"> */}
                <div className="row no-gutters w-100">
                    <div className="col-xl-2 col-lg-2 col-md-2 d-flex align-items-center">
                        <Link className="" to="/home">
                            <img src={logo_dash} className="img-fluid d-none d-sm-block" />
                            <img src={logo_fevi} className="img-fluid d-block d-sm-none" />
                        </Link>
                    </div>

                    {/* <div className="col-xl-8 "> */}
                    <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-center">
                        <h2 className="m-0 company-name d-flex align-items-baseline justify-content-center w-100">
                            <span className="ellipsisc ellipsisc-line-1 cname">MAXIMESS</span>
                            {/* <span className="license ml-1 red-text ">License Expired</span> */}
                            {/* <i className="license ml-1 red-text">License Expired</i> */}
                            <i className="license ml-1">(Superb)</i>
                        </h2>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-2 pr-0 header-dropdown d-flex align-items-center justify-content-end ">
                        <ul className="d-flex align-items-center mb-0">
                            {/* Gift Dropdown */}
                            {/* <li>
                            <Dropdown>                                
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <span className="icon-gift1 gray-icon"></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li> */}


                            {/* Bell Dropdown */}
                            <li className="notification">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="h-icons">
                                        <div className="position-relative">
                                            <span className="icon-bell gray-icon"></span>
                                            <span className="bell-notifi-count">15</span>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="noti-drp-width">
                                        {/* <div className="row align-items-center mb-3">
                                        <div className="col">
                                            <p className="size-xxiv blue-text s-sbold mb-0">Notifications</p>
                                        </div>
                                        <div className="col text-right">
                                            <p className="size-xvi s-sbold mb-0 cpointer blue-green-hlink">Mark all as read</p>
                                        </div>
                                    </div>
                                    <div className="noti-list-drp">
                                        <div className="row align-items-center mb-3">
                                            <div className="col">
                                                <p className="size-xxiv blue-text s-sbold mb-0">Snapshot</p>
                                            </div>
                                            <div className="col text-right">
                                                <p className="size-xvi s-sbold mb-0 cpointer green-blue-hlink">See all</p>
                                            </div>
                                        </div>
                                    </div> */}
                                        <ul className="noti-drp-list p-0">
                                            <li className="d-flex align-items-center justify-content-between mb-2">
                                                {/* <div className="d-flex align-items-center justify-content-between mb-3"> */}
                                                <p className="size-xxiv blue-text s-sbold mb-0">Notifications</p>
                                                <p className="size-xvi s-sbold mb-0 cpointer blue-green-hlink">Mark all as read</p>
                                                {/* </div> */}
                                            </li>
                                        </ul>

                                        {/* All */}
                                        <ul className="noti-drp-list p-0">
                                            <li className="d-flex align-items-center justify-content-between mb-2">
                                                <p className="size-xx blue-text s-sbold mb-0">
                                                    <span className="icon-Slider-arrow2 size-xvi mr-3"></span>
                                                    Snapshot
                                                </p>
                                                <p className="s-sbold mb-0 d-inline float-right cpointer blue-green-hlink">Mark all as read</p>
                                            </li>
                                        </ul>

                                        <ul className="noti-drp-list p-0">
                                            {/* Snapshot */}
                                            <li className="d-flex align-items-end justify-content-between mb-3">
                                                <p className="size-xxiv blue-text s-sbold mb-0">Snapshot</p>
                                                <p className="size-xvi s-sbold mb-0 cpointer green-blue-hlink">See all</p>
                                            </li>
                                            <li className="noti-drp-li d-flex align-items-start active">
                                                <div className="noti-user">
                                                    <img className="noti-user-img" src={testimg} />
                                                    <div className="noti-user-letter s-sbold d-flex align-items-center justify-content-center cap">p</div>
                                                    <span className="icon-Group-3182 noti-file-icon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                                </div>

                                                <div className="ml-4">
                                                    <p className="noti-p mb-1"><b>prod</b> has completed the task assigned by you.</p>
                                                    <div className="noti-date">
                                                        <p className="s-sbold green-text mb-0 d-inline">25-01-2023 11:13</p>
                                                        <p className="s-sbold mb-0 d-inline float-right cpointer blue-green-hlink">Mark as read</p>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* End Snapshot */}
                                        </ul>



                                        <Spinner animation="border" variant="secondary" className="h-loader" />

                                        <p className="s-sbold blue-text mt-2 mb-0 text-center cpointer">Load more</p>


                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>

                            {/* User Dropdown */}
                            <li className="main-h-profile">
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className='user-drp d-flex align-items-center justify-content-center'>
                                        <img className="profileh-img" src={testimg} />
                                        {/* <span className="profileh-name">T</span> */}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="main-h-profile gray-bg">
                                        <div className="user-h-detail">
                                            <div className="d-flex justify-content-between">
                                                <span className="mr-3">
                                                    <img className="profileh-drp-img" src={testimg} />
                                                </span>
                                                <div className="blue-text mr-3" style={{ marginTop: '-7px' }}>
                                                    <span className="d-block size-xx">Pratyush J. Accountant</span>
                                                    <span className="d-block size-xiv">contact@pratyushjanghel.com</span>
                                                    <span className="d-block size-xiv">+919617952219</span>
                                                </div>
                                                <span className="icon-view-details size-xxviii blue-icon"></span>
                                            </div>
                                            <div className="text-right">
                                                <Button variant="success" className="h-green-btn">Logout</Button>
                                            </div>
                                        </div>

                                        <div className="h-profile-entities-list mt-2">
                                            <p className="entities-header s-sbold mb-0">Entities</p>
                                            <ul className="p-0 h-ul-entities-list white-bg">
                                                {/* <li className="pl-4 pr-3">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-xs-3 d-flex align-items-center">
                                                        <span className="drp-entity-letter cap s-sbold d-flex align-items-center justify-content-center">t</span>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-xs-7 d-flex align-items-center">
                                                        <span className="drp-entity-name d-block ellipsisc ellipsisc-line-1">test225test225test225test225test225</span>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-xs-2 d-flex align-items-center justify-content-end">
                                                        <span className="drp-entity-count">0</span>
                                                    </div>
                                                </div>
                                            </li> */}
                                                {/* <li className="d-flex align-items-center justify-content-between">
                                                <div className="mr-5 pl-4">
                                                    <span className="drp-entity-letter cap s-sbold d-flex align-items-center justify-content-center">t</span>
                                                </div>
                                                <div className="mr-5 drp-entity-name s-sbold blue-text d-block ellipsisc ellipsisc-line-1">
                                                    test225test225test225test225test225
                                                </div>
                                                <div className="pr-3">
                                                    <span className="drp-entity-count d-flex align-items-center justify-content-center">0</span>
                                                </div>
                                            </li> */}

                                                <li className="d-flex align-items-center justify-content-between active">
                                                    <div className="drp-entity-letter cap s-sbold d-flex align-items-center justify-content-center">d</div>
                                                    <div className="drp-entity-name s-sbold blue-text d-block ellipsisc ellipsisc-line-1">
                                                        assdsd
                                                    </div>
                                                    <div className="">
                                                        <span className="drp-entity-count d-flex align-items-center justify-content-center">0</span>
                                                    </div>
                                                </li>

                                                <li className="d-flex align-items-center justify-content-between ">
                                                    <div className="drp-entity-letter cap s-sbold d-flex align-items-center justify-content-center">d</div>
                                                    <div className="drp-entity-name s-sbold blue-text d-block ellipsisc ellipsisc-line-1">
                                                        assdsd
                                                    </div>
                                                    <div className="">
                                                        <span className="drp-entity-count d-flex align-items-center justify-content-center">0</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>

                            {/* Menu Dropdown */}
                            <li className='hamburger-menu'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="h-icons h-btn">
                                        <span className="icon-menu"></span>
                                    </Dropdown.Toggle>

                                    {/* <Dropdown.Menu className='d-flex gap-50  padding-menu'> */}
                                    <Dropdown.Menu >
                                        <div className="d-flex menu-gap padding-menu">
                                            {/* <Dropdown.Item href="#/action-1">Menu 3</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Menu 3</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Menu 3</Dropdown.Item> */}
                                            {/* <div className='row'>
                                        <div className='col-md-6'>
                                        </div>
                                        <div className='col-md-6'>
                                        </div>
                                    </div> */}

                                            {/* Left */}
                                            <div>
                                                <h6 className="menu-title">Main</h6>
                                                <ul className="p-0 mb-2 menu-list">
                                                    <li>
                                                        <Dropdown.Item href="/home" className="menu-link">
                                                            <span className="icon-Group-2453 h-menu-icon iconsc"></span><span className="menu-name">Home</span>
                                                        </Dropdown.Item>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* Right */}
                                            <div>
                                                <h6 className="menu-title">Tools</h6>
                                                <ul className="p-0 mb-2 menu-list">
                                                    <li>
                                                        <Dropdown.Item href="/home" className="menu-link">
                                                            <span className="icon-Group-2453 h-menu-icon iconsc"></span><span className="menu-name">Profile Management</span>
                                                        </Dropdown.Item>
                                                    </li>
                                                </ul>

                                                {/* AC Partner */}
                                                <div className="text-center mb-3">
                                                    <p className="blue-text s-sbold mb-0">Accounting Partner</p>
                                                    <img className="img-fluid ac-partner-img" src={testimg} />
                                                    <p className="ac-partner-h-name mb-0">T</p>
                                                    <p className="blue-text s-sbold mb-0">Test Name</p>
                                                </div>

                                                {/* App Logos */}
                                                <div className="d-flex mt-2">
                                                    <a href="https://play.google.com/store/apps/details?id=com.buktec&pli=1" target="_blank" className="s-sbold font-colr">
                                                        <img className="img-fluid applogo mr-3" src={googleplay} />
                                                    </a>
                                                    <a href="https://apps.apple.com/in/app/buktec/id1603235288" target="_blank" className="s-sbold font-colr">
                                                        <img className="img-fluid applogo" src={appstore} />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                        {/* End ul li */}
                    </div>
                </div>
            </nav>
            {/* Navbar */}

            {/* Sidebar */}
            <div className="quick-menu">
                <ul className="p-0">
                    <li>
                        <Link to="/dashboard" className="text-decoration-none d-flex align-items-center">
                            <span className="quick-icons d-flex justify-content-center"><span className="icon-Dashboard"><span className="path1"></span><span className="path2"></span></span></span>
                            <span className="quick-link-text">Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/snapshot/evidence/create"} className="text-decoration-none d-flex align-items-center">
                            <span className="quick-icons d-flex justify-content-center"><span className="icon-plus"></span></span>
                            <span className="quick-link-text">Create Snapshot</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/snapshot/evidence"} className="text-decoration-none d-flex align-items-center">
                            <span className="quick-icons d-flex justify-content-center"><span className="icon-Group-2520"></span></span>
                            <span className="quick-link-text">Snapshot</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/financial-institutes" className="text-decoration-none d-flex align-items-center">
                            <span className="quick-icons d-flex justify-content-center"><span className="icon-FI"><span className="path1"></span><span className="path2"></span></span></span>
                            <span className="quick-link-text">Banking</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/money-link" className="text-decoration-none d-flex align-items-center">
                            <span className="quick-icons d-flex justify-content-center"><span className="icon-Link-icon"></span></span>
                            <span className="quick-link-text">Money Link</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/reports" className="text-decoration-none d-flex align-items-center">
                            <span className="quick-icons d-flex justify-content-center"><span className="icon-Exports"><span className="path1"></span><span className="path2"></span></span></span>
                            <span className="quick-link-text">Reports</span>
                        </Link>
                    </li>
                </ul>
            </div>
            {/* End Sidebar */}


            {/* Content Wrapper */}
            <main className="content-wrapper d-none">
                <div className="container-fluid p-0">

                    {/* Breadcrumb */}
                    {/* <div className="row">
                        <div className="col-12">                            
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/home">Home</a></li>
                                    <li className="breadcrumb-item cap"><a prevpath="/task-manager" href="/task-manager">task manager</a></li>
                                </ol>
                                <h3 className="breadcrumb-title">Task Manager</h3>
                                <hr className="breadcrumb-hr"></hr>
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-header">
                                <BreadCrum level={2} />
                                <h3 className="breadcrumb-title">
                                    Task Manager
                                    <span className="help-icon">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon"></span></OverlayTrigger>
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrumb */}

                    {/* Filter */}
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between top-filter">
                                <div>
                                    Left
                                </div>
                                <div>
                                    Right
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Filter */}

                    {/* Card Filter */}
                    <div className="row widget-filter">
                        <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                            <div className="widget-block active">
                                <p className="wtitle">Total</p>
                                <p className="wcounter">5</p>
                            </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                            <div className="widget-block">
                                <p className="wtitle">Open</p>
                                <p className="wcounter">5</p>
                            </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                            <div className="widget-block">
                                <p className="wtitle">Overdue</p>
                                <p className="wcounter">5</p>
                            </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                            <div className="widget-block">
                                <p className="wtitle">Done</p>
                                <p className="wcounter">5</p>
                            </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                            <div className="widget-block">
                                <p className="wtitle">Assigned to me</p>
                                <p className="wcounter">5</p>
                            </div>
                        </div>
                        <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6">
                            <div className="widget-block">
                                <p className="wtitle">Assigned by me</p>
                                <p className="wcounter">5</p>
                            </div>
                        </div>
                    </div>
                    {/* End Card Filter */}

                </div>{/* End container-fluid */}




                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-12 p-5">
                            <h3 className="red">----- Hedding -----</h3>
                            <h1>Welcome back!</h1>
                            <h2>Welcome back!</h2>
                            <h3>Welcome back!</h3>
                            <h4>Welcome back!</h4>
                            <h5>Welcome back!</h5>
                            <h6>Welcome back!</h6>

                            <br></br>
                            <h3 className="red">----- P -----</h3>
                            <p>Welcome back! see what we got since your last visit</p>
                            <small>Welcome back! see what we got since your last visit</small>


                            <br></br><br></br>
                            <h3 className="red">----- Button -----</h3>
                            <Button variant="outline-primary">Primary</Button>{' '}
                            <Button variant="primary">Primary</Button>
                        </div>
                    </div>
                </div>

            </main>{/* End Main */}
            {/* End Content Wrapper */}




            {/* Task Manager Form */}
            <main className="content-wrapper-inner-new">
                <div className="container-fluid container-padding-new">
                    <div className="row">
                        {/* Sidebar List */}
                        {/* <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 sidebar-sec">
                            <div className="sidebar-sec">
                                <div className="breadcrumb-header">
                                    <BreadCrum level={2} />
                                </div>
                            </div>
                        </div> */}


                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 sidebar-listing">
                            <div className="sidebar-fixed">
                                {/* <div className="row">
							    <div className="col-sm-12"> */}
                                <div className="sidebar-new-header">
                                    <div className="breadcrumb-header">
                                        <BreadCrum level={2} />
                                    </div>
                                    <h3 className="sidebar-icons">
                                        <div className="sidebar-evidence-filter">
                                            <span className="icon-filter green-icon s-filter-icon"></span>
                                        </div>
                                        {/* {!this.VIEW_ONLY && <AddNew addNew={this.newTask} />} */}
                                        <span className="icon-plus s-add-icon"></span>
                                    </h3>

                                    {/* <div className="sbleft">
                                        <h3 className="heading-shi">Task Manager </h3>
                                    </div>
                                    <div className="sright">
                                        <h3 className="heading-shi mb-0">
                                            <div className="sidebar-evidence-filter" onClick={() => this.toggleFilter()}>
                                                <span className="icon-filter green-icon"></span>
                                            </div>
                                            {!this.VIEW_ONLY && <AddNew addNew={this.newTask} />}
                                        </h3>
                                    </div> */}
                                </div>
                                {/* </div>
						        </div> */}

                                <div className="sidebar-sec">
                                    {/* Filter Form */}
                                    <div className="side-filter">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Narration</Tooltip>}>
                                                    <form className="search-sec ml-lg-auto form-group">
                                                        <input type="text" className="form-control" placeholder="Search" name="search" />
                                                        <Button className="active-search-icon">
                                                            <span className="icon-loupe"></span>
                                                        </Button>
                                                        {/* <Button className="active-search-icon crosebtn">
												<span class="icon-cancel"></span>
											</Button> */}
                                                    </form>
                                                </OverlayTrigger>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <select className="form-control" name="is_star" >
                                                        <option selected value="" >Starred</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                        {/* <option value="">All</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Filter Form */}

                                    {/* Tags */}
                                    <div className="sidebar-tag-list">
                                        <span className="sidebar-tagname">Current Financial Year</span>
                                    </div>
                                    {/* End Tags */}


                                    {/* Cards */}
                                    {/* {this.state.task_id == 0 && */}
                                    <div className="sidebar-cards active">
                                        <h3 className="new-evidance">New Task</h3>
                                    </div>
                                    {/* } */}

                                    <div className="sidebar-cards">
                                        <h3 className="new-evidance">No items to display</h3>
                                    </div>

                                    <div className="sidebar-cards active">
                                        <div className="d-flex">
                                            <p className="titlesc ellipsisc">Test task</p>
                                            <div className="iconsc">
                                                icon
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <p className="flex-6 namesc ellipsisc">Pratyush J. Accountant</p>
                                            <p className="flex-4 datesc">14-09-2022</p>
                                        </div>
                                    </div>
                                    {/* End Cards */}
                                </div>

                            </div>{/* End sidebar-sec */}
                        </div>{/* End Column */}

                        {/* End Sidebar List */}

                        {/* Right side content col-xl-9 */}
                        <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7 main-content-sec-new">
                            <div className="main-content-inner-new">

                                {/* Sub Header Inner */}
                                <div className="sub-header-inner-new">
                                    <div className="row">
                                        <div className="col-md-6 sleft">
                                            <h3 className="heading-shi">
                                                Task Manager
                                                <span className="help-icon">
                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon"></span></OverlayTrigger>
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 sright">
                                            <h3 className="heading-shi">
                                                <Link to={'/task-manager'} className="">
                                                    <span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                {/* End Sub Header Inner */}

                                <div className="form-content-sec-new">
                                    <div className="form-sec-inner-new">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="d-flex form-main-title-new">
                                                    <h4 className="mr-auto form-title-heading-new">
                                                        Task Details <span className="form-date-new">20-01-2022</span>
                                                    </h4>

                                                    <div className="d-flex align-items-center">
                                                        {/* <p className="mb-0 d-flex align-items-center"> */}
                                                        <span className="form-id-new">ID #17</span>
                                                        <span className="icon-log icon-round-info-button form-changelog-new"></span>
                                                        {/* </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <lable className="lable-input">Task<span className="red-star">*</span></lable>
                                                    <textarea className="form-control" rows="3" name="task"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <lable className="lable-input">Reference No.<span className="red-star">*</span></lable>
                                                    <input type="text" className="form-control" name="task" />
                                                </div>
                                            </div>
                                        </div> */}


                                        {/* Task Reference */}
                                        {/* <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-main-title-new mt-3 mb-0">
                                                    <h4 className="mr-auto form-title-heading-new">Task Reference</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Table responsive className="table-reference">
                                                    <tbody>
                                                        <tr>
                                                            <td>Snapshot</td>
                                                            <td>22-01-2022</td>
                                                            <td>ID #48</td>
                                                            <td>Yyttttt</td>
                                                            <td className='text-right'>
                                                                ₹6500000
                                                                ArrowUpDownIcon  
                                                            </td>
                                                            <td className="text-center pt-2">
                                                                getStatus 
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn view-ref-btn d-flex align-items-center"><span className="icon-view-details white-icon mr-1"></span>View</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div> */}
                                        {/* End Task Reference */}

                                    </div> {/* End form-sec-inner */}
                                </div> {/* End form-content-sec */}




                                {/* Communication bar */}
                                <div className="communicationbar">
                                    {/* Messages section --- Comment Popup Box */}
                                    <div className="comment-cmb-box">

                                        <Spinner animation="border" variant="secondary" className="cmb-loader" />

                                        <h3 className="cmb-heading"><span class="icon-chat mr-2"></span>Messages</h3>
                                        {/* messages */}
                                        <div className="msg-sec-cmb">
                                            <div className="messages-cmb msg-send-cmb">
                                                {/* image name date */}
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Pratyush J. Accountant</Tooltip>}>
                                                    <div className="pnd-cmb">
                                                        <div>
                                                            {/* <span className="profile-cmb-letter">P</span> */}
                                                            <img className="profile-cmb-img" src={testimg} />
                                                        </div>
                                                        <div className="nd-cmb">
                                                            <h5 className="profile-cmb-name ellipsisc ellipsisc-line-1">Pratyush J. Accountant</h5>
                                                            <span className="date-cmd">29-03-2023 10:13</span>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                                {/* text */}
                                                <p className="msgtext-cmd"><span className="red-text">test user </span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
                                            </div>
                                        </div>
                                        {/* End messages */}
                                    </div>
                                    {/* End Messages section */}


                                    {/* Private Messages section --- Comment Popup Box */}
                                    <div className="comment-cmb-box">

                                        <Spinner animation="border" variant="secondary" className="cmb-loader" />

                                        <h3 className="cmb-heading red-text"><span class="icon-chat mr-2"></span>Private Messages</h3>
                                        {/* messages */}
                                        <div className="msg-sec-cmb">
                                            <div className="messages-cmb msg-send-cmb">
                                                {/* image name date */}
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Pratyush J. Accountant</Tooltip>}>
                                                    <div className="pnd-cmb">
                                                        <div>
                                                            {/* <span className="profile-cmb-letter">P</span> */}
                                                            <img className="profile-cmb-img" src={testimg} />
                                                        </div>
                                                        <div className="nd-cmb">
                                                            <h5 className="profile-cmb-name ellipsisc ellipsisc-line-1">Pratyush J. Accountant</h5>
                                                            <span className="date-cmd">29-03-2023 10:13</span>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                                {/* text */}
                                                <p className="msgtext-cmd"><span className="red-text">test user </span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.</p>
                                            </div>
                                        </div>
                                        {/* End messages */}
                                    </div>
                                    {/* End Private Messages section */}



                                    {/* Notes section --- Comment Popup Box */}
                                    <div className="comment-cmb-box">
                                    <Spinner animation="border" variant="secondary" className="cmb-loader" />
                                        <h3 className="cmb-heading"><span className="icon-Group-2546 mr-2"></span>Notes</h3>
                                        {/* messages */}
                                        <div className="msg-sec-cmb">
                                            <div className="messages-cmb notes-box">
                                                {/* image name date */}
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Pratyush J. Accountant</Tooltip>}>
                                                    <div className="pnd-cmb">
                                                        <div>
                                                            <span className="profile-cmb-letter">P</span>
                                                            <img className="profile-cmb-img" src={testimg} />
                                                        </div>
                                                        <div className="nd-cmb">
                                                            <h5 className="profile-cmb-name ellipsisc ellipsisc-line-1">Pratyush J. Accountant</h5>
                                                            <span className="date-cmd">29-03-2023 10:13</span>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                                {/* text */}
                                                <div className="notes-msg-box">
                                                    <p className="msgtext-cmd">Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                                                    <div className="notesc-icons">
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Edit</Tooltip>}>
                                                            <span class="icon-edit cpointer mb-2"></span>
                                                        </OverlayTrigger>

                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Change Log</Tooltip>}>
                                                            <span class="icon-round-info-button cpointer"></span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* End messages */}
                                    </div>
                                    {/* End Notes section */}



                                    {/* Button Bar */}
                                    <form>
                                        <div className="bottom-cmb">
                                            {/* Note */}
                                            <lable className="lable-add-note">Add Note<span className="red-star">*</span></lable>

                                            {/* ------ */}
                                            <div className="bottom-inner-cmb">
                                                {/* <div className="user-comment-cmb disable">
                                                    @mention a user
                                                    <p className="click-msg">@mention a user</p>
                                                    <p className="click-msg">Click the on icons on right side to use the communication bar</p>
                                                </div> */}

                                                {/* The one-year Foundation Programme introduces students to the fundamentals of design and helps develop ways of perception, aesthetic sensitivity, understanding of the multidisciplinary nature of design and relationship of design with human senses and emotions, culture, environment and ecology. The one-year Foundation Programme introduces students to the fundamentals of design and helps develop ways of perception, aesthetic sensitivity, understanding of the multidisciplinary nature of design and relationship of design with human senses and emotions, culture, environment and ecology. */}

                                                {/* Note */}
                                                <div className="user-comment-cmb">
                                                    <div className="form-group mb-0 w-100">
                                                        <textarea className="form-control" rows="2" name="task" placeholder=" Enter notes details"></textarea>
                                                    </div>
                                                </div>

                                                {/* ------ */}
                                                <div className="icon-cmb">
                                                    <a role="button" className="active">
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Messages</Tooltip>}>
                                                            <span className="icon-chat"></span>
                                                        </OverlayTrigger>
                                                    </a>
                                                    {/* <a role="button" className="active-msg active-dot"> */}
                                                    {/* <a role="button" className="active-msg">
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tasks</Tooltip>}>
                                                            <span className="icon-Group-2516"></span>
                                                        </OverlayTrigger>
                                                    </a> */}
                                                    <a role="button" className="">
                                                        <span className="active-dot"></span>
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Notes</Tooltip>}>
                                                            <span className="icon-Group-2546"></span>
                                                        </OverlayTrigger>
                                                    </a>
                                                    <a role="button" className="active-msg">
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Private Messages</Tooltip>}>
                                                            <span className="icon-chat"></span>
                                                        </OverlayTrigger>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="right_side_btn_new">
                                                <span className="character-limit">Character limit: 0/1000</span>
                                                <Button variant="success apply_btn_new" >Add Note</Button>
                                                {/* <Button variant="success apply_btn_new" disabled>Send</Button> */}
                                            </div>
                                        </div>
                                    </form>
                                    {/* End Button Bar */}
                                </div>
                                {/* End Communication bar */}


                            </div> {/* End main-content-inner */}
                        </div> {/* End Right side col-xl-9 */}
                    </div>
                </div>
            </main>
            {/* End Task Manager Form */}
        </div>
    </>
}

export default TestPage;