import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function GSTIcon(props) {
    return   <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">GST</Tooltip>}>

        <span className="icon-GST-icon icon-dt-td"><span className={"path1 " +(props.active ? "skyblue-icon":" grayc-light-icons")}></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>

        {/* <span className={"icon-GST-icon  icon-td"+(props.active ? " ":" gray-st-icon")}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span> */}

        {/* <span className={"icon-Group-3283-1  icon-td"+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
      </OverlayTrigger>
}
export default GSTIcon;