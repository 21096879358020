import axios from "axios";
import Header from "./Header";
import { API_URL } from "../Utils/Config";

class RecentActivities {
  getRecentActivities() {
    return axios
      .get(API_URL + "recent-activities", { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("error", error);
        return error;
      });
  }

  getExportDoc(type, startDate, endDate,financial_institute_id) {
    let postData = {
      "date_filter": {
        "start_date": startDate,
        "end_date": endDate
      }
    }
    if(type == "bst"){
      postData.financial_institute_id = financial_institute_id == 0 ? null : financial_institute_id;
    }
    return axios
      .post(API_URL + `export-wizard?report=${type}`, postData, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log("error", error)
        return error
      })
    }
}

export default new RecentActivities();
