import React from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { withRouter, useHistory, Link } from 'react-router-dom';
import LicenseService from '../../../Services/Licenses/LicenseService';
import { toast } from 'react-toastify';
import { convertToCurrency } from '../../../Helpers/Currency';
import MessageModal from '../../../Components/BoilerPlate/MessageModal';
import logo_dash from '../../../Assets/img/logo-dash.jpeg';
import { connect } from 'react-redux';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';
import StorageData from '../../../Helpers/StorageData';
import AccountService from '../../../Services/ProfileManagement/AccountService';
import { confirm } from '../../../Components/BoilerPlate/Confirm';
import { Button } from 'react-bootstrap';

class OrderSummary extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            bill_to_list: [],
            account_details: {},
            final_price: 0,
            final_quantity: 0,
            promo_code_id: null,
            promo_code_error: null,
            bill_to_error: false,
            bill_to_id: "",
            bill_to_name: "",
            discount: 0,
            net_price: 0,
            discounted_price: 0,
            gst: "",
            address: "",
            state_of_supply: "",
            form_submitted: false,
            tnc_check: 0,
            tnc_alert: false,
            order_response: {},
            order_type: this.props.location.state.type,
            licenses: [],
            order_id: null,
            order_detail: [],
            data_ready: false,
            order_summary_type: this.props.location.state.type,
            bill_to_index: "",
            price_change_alert: false,
            is_retry: this.props.location.state.is_retry,
            closed: true,
            is_dirty: false,
            buktec_order_id: null,
            promo_code: this.props.location.state.promo_code || "",
            discount: this.props.location.state.discount,
            //account_license_id: this.props.location.state.account_license_id
            promo_code_disabled: false
        }
    }

    getAccountDetail = () => {
        AccountService.getAccountDetail().then(
            (data) => {
                if (data.response_code == 200) {
                    let newData = { ...data.result }
                    this.setState({ account_details: newData })
                }
            }
        )
    }

    componentDidMount() {
        this.getBillToList();
        this.getAccountDetail();
    }

    getUpgradeLicenseList = (orderData) => {
        this.props.dispatch(setAppilcationLoder(true));

        var OrderId = { order_id: this.state.order_id }
        LicenseService.getOrderSummaryDetail(OrderId)
            .then(data => {
                if (data.response_code === 200) {

                    this.setState({ order_detail: data.result.licences, razorpay_order_id: data.result.razorpay_order_id })

                    let bill_to = this.state.bill_to_list.find(bill_to => (bill_to.id === data.result.bill_to_id && bill_to.bill_to_type === data.result.bill_to_type));

                    if (bill_to !== undefined && !localStorage.getItem('bill_to_data')) {

                        var billToIndex = "";
                        for (var i = 0; i < this.state.bill_to_list.length; i++) {
                            if (this.state.bill_to_list[i].id === bill_to.id && this.state.bill_to_list[i].bill_to_type === data.result.bill_to_type) {
                                billToIndex = i;
                            }
                        }

                        this.setState({
                            gst: bill_to.gst_number,
                            address: bill_to.address,
                            bill_to_id: bill_to.id,
                            bill_to_type: bill_to.bill_to_type,
                            state_of_supply: bill_to.state_of_supply,
                            bill_to_name: bill_to.name,
                            bill_to_index: billToIndex,
                        })
                    } else {
                        const billToData = JSON.parse(localStorage.getItem('bill_to_data'));

                        this.setState({
                            gst: billToData.gst,
                            address: billToData.address,
                            bill_to_id: billToData.bill_to_id,
                            bill_to_type: billToData.bill_to_type,
                            state_of_supply: billToData.state_of_supply,
                            bill_to_name: billToData.bill_to_name,
                            bill_to_index: billToData.bill_to_index
                        })
                    }

                    var finalPrice = 0;
                    data.result.license_detail.forEach(function (license) {
                        license.licence_price = license.prorated_amount;
                        license.total_rate = license.prorated_amount;
                        license.licence_price_discounted = null;
                        finalPrice = license.prorated_amount;

                        var expiryDate = new Date(license.valid_to);
                        var startDate = new Date(license.valid_from);

                        license.valid_from = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + startDate.getFullYear();
                        license.valid_to = ("0" + expiryDate.getDate()).slice(-2) + '-' + ("0" + (expiryDate.getMonth() + 1)).slice(-2) + '-' + expiryDate.getFullYear();
                    });


                    this.setState({
                        licenses: data.result.license_detail,
                        final_price: finalPrice,
                        final_quantity: 1,
                        net_price: finalPrice,
                        data_ready: true
                    })

                } else {
                    toast.error(data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    getLicensePackageDetails = (orderData) => {
        this.props.dispatch(setAppilcationLoder(true));

        LicenseService.getLicensePackageDetails()
            .then(data => {
                if (data.response_code === 200) {

                    if (this.state.order_summary_type == 'upgrade') {

                        var account_license_id = this.props.location.state.account_license_id;

                        var commonLicense = data.result.license.filter(function (license) {
                            return orderData.license_id == license.license_id
                        })

                        var upgrade_details = this.props.location.state.upgrade_details;

                        commonLicense.map(function (license, index) {
                            license.quantity = 1;
                            license.account_license_id = account_license_id;
                            license.total_rate = upgrade_details.amount;
                            license.licence_price = upgrade_details.amount;
                        })

                    } else {
                        var commonLicense = data.result.license.filter(function (license) {
                            return orderData.some(function (order) {
                                return order.licence_name == license.licence_name
                            })
                        })

                        commonLicense.map(function (license, index) {
                            orderData.map(function (order) {
                                license.quantity = order.quantity;
                                if (license.licence_price_discounted) {
                                    license.total_rate = (license.quantity * license.licence_price_discounted);
                                } else {
                                    license.total_rate = (license.quantity * license.licence_price);
                                }
                            })
                        })
                    }

                    this.setState({
                        licenses: commonLicense,
                        offer_text: data.result.offer_text
                    }, () => {
                        this.setValidityDate();
                    })
                } else {
                    toast.error(data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    getOrderSummaryDetail = (orderId) => {
        this.props.dispatch(setAppilcationLoder(true));
        let orderData = { order_id: orderId }

        LicenseService.getOrderSummaryDetail(orderData)
            .then(data => {
                if (data.response_code === 200) {

                    this.setState({ order_detail: data.result.licences, razorpay_order_id: data.result.razorpay_order_id })

                    let bill_to = this.state.bill_to_list.find(bill_to => (bill_to.id === data.result.bill_to_id && bill_to.bill_to_type === data.result.bill_to_type));

                    console.log(JSON.parse(localStorage.getItem('bill_to_data')))
                    if (JSON.parse(localStorage.getItem('bill_to_data')) == null) {

                        var billToIndex = "";
                        for (var i = 0; i < this.state.bill_to_list.length; i++) {
                            if (this.state.bill_to_list[i].id === bill_to.id && this.state.bill_to_list[i].bill_to_type === data.result.bill_to_type) {
                                billToIndex = i;
                            }
                        }

                        this.setState({
                            gst: bill_to.gst_number,
                            address: bill_to.address,
                            bill_to_id: bill_to.id,
                            bill_to_type: bill_to.bill_to_type,
                            state_of_supply: bill_to.state_of_supply,
                            bill_to_name: bill_to.name,
                            bill_to_index: billToIndex
                        })
                    } else {
                        const billToData = JSON.parse(localStorage.getItem('bill_to_data'));

                        this.setState({
                            gst: billToData.gst,
                            address: billToData.address,
                            bill_to_id: billToData.bill_to_id,
                            bill_to_type: billToData.bill_to_type,
                            state_of_supply: billToData.state_of_supply,
                            bill_to_name: billToData.bill_to_name,
                            bill_to_index: billToData.bill_to_index
                        })
                    }

                    if (this.state.order_summary_type !== "upgrade") {
                        this.getLicensePackageDetails(data.result.licences);
                    }

                    if (this.state.order_summary_type == "upgrade" && this.state.is_retry == 1) {
                        this.getUpgradeLicenseList(data.result.licences);
                    }
                } else {
                    toast.error(data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    setValidityDate = () => {
        if (this.state.order_summary_type == 'upgrade') {

            var upgrade_details = this.props.location.state.upgrade_details;
            var currentDate = new Date(upgrade_details.order_date);
            var expiryDate = new Date(upgrade_details.valid_till);
            //}
        } else {
            var expiryDate = new Date();
            var currentDate = new Date();
            expiryDate.setFullYear(expiryDate.getFullYear() + 1);
            expiryDate.setDate(expiryDate.getDate() - 1);
        }

        this.state.licenses.forEach(function (element) {
            element.valid_from = ("0" + currentDate.getDate()).slice(-2) + '-' + ("0" + (currentDate.getMonth() + 1)).slice(-2) + '-' + currentDate.getFullYear();
            element.valid_to = ("0" + expiryDate.getDate()).slice(-2) + '-' + ("0" + (expiryDate.getMonth() + 1)).slice(-2) + '-' + expiryDate.getFullYear();
        });

        const finalPrice = this.state.licenses.reduce(
            (prevValue, currentValue) => prevValue + currentValue.total_rate,
            0
        );

        const finalQuantity = this.state.licenses.reduce(
            (prevValue, currentValue) => prevValue + currentValue.quantity,
            0
        );

        this.setState({
            final_price: finalPrice,
            final_quantity: finalQuantity,
            net_price: finalPrice,
            data_ready: true
        })
    }

    setRenewData = () => {
        this.props.dispatch(setAppilcationLoder(true));

        LicenseService.getLicensePackageDetails()
            .then(package_data => {
                if (package_data.response_code === 200) {

                    if (this.state.is_retry == 1) {
                        let orderId = { order_id: this.props.location.state.order_id };

                        LicenseService.getOrderSummaryDetail(orderId)
                            .then(data => {
                                if (data.response_code === 200) {

                                    this.setState({ order_detail: data.result.license_detail, razorpay_order_id: data.result.razorpay_order_id })

                                    var orderData = data.result.license_detail;

                                    var commonLicense = package_data.result.license;

                                    commonLicense.map(function (license, index) {
                                        orderData.map(function (order) {
                                            if (license.license_id == order.license_id) {
                                                order.quantity = 1;
                                                if (license.licence_price_discounted) {
                                                    order.total_rate = (order.quantity * license.licence_price_discounted);
                                                } else {
                                                    order.total_rate = (order.quantity * license.licence_price);
                                                }

                                                order.licence_name = order.license_name_1;

                                                order.licence_price = license.licence_price;
                                                order.licence_price_discounted = license.licence_price_discounted;

                                                var orderDate = new Date(order.valid_from);
                                                order.valid_from = ("0" + orderDate.getDate()).slice(-2) + '-' + ("0" + (orderDate.getMonth() + 1)).slice(-2) + '-' + orderDate.getFullYear();


                                                var expiryDate = new Date(order.valid_to);
                                                order.valid_to = ("0" + expiryDate.getDate()).slice(-2) + '-' + ("0" + (expiryDate.getMonth() + 1)).slice(-2) + '-' + expiryDate.getFullYear();
                                            }
                                        })
                                    })

                                    const finalPrice = orderData.reduce(
                                        (prevValue, currentValue) => prevValue + currentValue.total_rate,
                                        0
                                    );

                                    const finalQuantity = orderData.reduce(
                                        (prevValue, currentValue) => prevValue + currentValue.quantity,
                                        0
                                    );

                                    this.setState({
                                        licenses: data.result.license_detail,
                                        final_price: finalPrice,
                                        final_quantity: 1,
                                        net_price: finalPrice,
                                        data_ready: true
                                    })

                                } else {
                                    toast.error(package_data.message);
                                }
                                this.props.dispatch(setAppilcationLoder(false));
                            })

                    } else {

                        var orderData = this.props.location.state.order_data;
                        var commonLicense = package_data.result.license;

                        commonLicense.map(function (license, index) {
                            orderData.map(function (order) {
                                if (license.license_id == order.license_id) {
                                    order.quantity = 1;
                                    if (license.licence_price_discounted) {
                                        order.total_rate = (order.quantity * license.licence_price_discounted);
                                    } else {
                                        order.total_rate = (order.quantity * license.licence_price);
                                    }

                                    order.licence_price = license.licence_price;
                                    order.licence_price_discounted = license.licence_price_discounted;

                                    var orderDate = new Date(order.valid_till);
                                    orderDate.setDate(orderDate.getDate() + 1);
                                    order.valid_from = ("0" + orderDate.getDate()).slice(-2) + '-' + ("0" + (orderDate.getMonth() + 1)).slice(-2) + '-' + orderDate.getFullYear();


                                    var expiryDate = new Date(orderDate);
                                    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
                                    expiryDate.setDate(expiryDate.getDate() - 1);
                                    order.valid_to = ("0" + expiryDate.getDate()).slice(-2) + '-' + ("0" + (expiryDate.getMonth() + 1)).slice(-2) + '-' + expiryDate.getFullYear();
                                }
                            })
                        })

                        const finalPrice = orderData.reduce(
                            (prevValue, currentValue) => prevValue + currentValue.total_rate,
                            0
                        );

                        const finalQuantity = orderData.reduce(
                            (prevValue, currentValue) => prevValue + currentValue.quantity,
                            0
                        );

                        this.setState({
                            licenses: orderData,
                            final_price: finalPrice,
                            final_quantity: finalQuantity,
                            net_price: finalPrice,
                            data_ready: true
                        })
                    }

                } else {
                    toast.error(package_data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    getBillToList = () => {
        LicenseService.getBillToList()
            .then(data => {
                if (data.response_code === 200) {
                    let masterIdx = data.result.findIndex((item) => item.bill_to_type == 1)
                    // console.log(masterIdx)
                    let masterAccount = data.result.find((item) => item.bill_to_type == 1)
                    data.result.splice(masterIdx, 1)
                    let newBillList = data.result
                    newBillList.sort(function (a, b) {
                        var nameA = a.name.toUpperCase();
                        var nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    newBillList.unshift(masterAccount)
                    //console.log(newBillList)
                    this.setState({ bill_to_list: newBillList }, () => {
                        if (this.state.is_retry == 1 && this.state.order_summary_type == 'upgrade') {

                            this.setState({ order_id: this.props.location.state.order_id }, () => {
                                this.getUpgradeLicenseList();
                            });

                        } else if (this.state.order_summary_type == 'upgrade') {

                            this.getLicensePackageDetails(this.props.location.state.order_data)

                            let order_id = this.props.location.state.upgrade_details.order_id.replace('A-', '');
                            this.getOrderSummaryDetail(order_id);

                        } else if (this.state.order_summary_type == 'renew') {
                            this.setState({ licenses: this.props.location.state.order_data }, () => { this.setRenewData(); });
                        } else if (this.state.is_retry == 1 && (this.state.order_summary_type == 'new' || this.state.order_summary_type == 'renew')) {
                            this.setState({ order_id: this.props.location.state.order_id }, () => {
                                //this.getUpgradeLicenseList();
                                this.getOrderSummaryDetail(this.props.location.state.order_id);
                            });
                        } else {
                            this.setState({ licenses: this.props.location.state.order_data }, () => {
                                this.setValidityDate();
                                this.applyPromoCode()
                            });
                        }

                        if (localStorage.getItem('bill_to_data')) {
                            const billToData = JSON.parse(localStorage.getItem('bill_to_data'));

                            this.setState({
                                gst: billToData.gst,
                                address: billToData.address,
                                bill_to_id: billToData.bill_to_id,
                                state_of_supply: billToData.state_of_supply,
                                bill_to_type: billToData.bill_to_type,
                                bill_to_name: billToData.bill_to_name,
                                bill_to_index: billToData.bill_to_index
                            })
                        }
                    })
                } else {
                    toast.error(data.message);
                }
            })
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value, is_dirty: true });
    }

    selectBillTo = (e) => {
        const { name, value } = e.target;
        // console.log("name:----",name ,"value:-----", value)
        // this.setState({ [name]: value, is_dirty: true });
         this.setState({is_dirty:true})

        if (value == null || value == "") {
            // this.setState({ bill_to_error: true })

        } else {
            this.setState({ bill_to_error: false })
            var bill_to = this.state.bill_to_list[value];
            
            this.setState({
                gst: bill_to.gst_number,
                address: bill_to.address,
                bill_to_id: bill_to.id,
                bill_to_type: bill_to.bill_to_type,
                state_of_supply: bill_to.state_of_supply,
                bill_to_name: bill_to.name == "Master Account" ? `${bill_to.name} (${this.state.account_details.full_name})` : bill_to.name,
                bill_to_index: value
            }, () => {
                if (this.state.order_summary_type == 'new' || 'renew' || 'upgrade') {
                    localStorage.setItem('bill_to_data', JSON.stringify({
                        gst: bill_to.gst_number,
                        address: bill_to.address,
                        bill_to_id: bill_to.id,
                        bill_to_type: bill_to.bill_to_type,
                        state_of_supply: bill_to.state_of_supply,
                        bill_to_name: bill_to.name == "Master Account" ? `${bill_to.name} (${this.state.account_details.full_name})` : bill_to.name,
                        bill_to_index: value
                    }));
                }
            })
        }
    }

    applyPromoCode = () => {
        let promoCode = this.state.promo_code;

        if (promoCode != "") {
            LicenseService.applyPromoCode(promoCode)
                .then(data => {
                    if (data.response_code === 200) {
                        if (data.result.promo_code_id !== null) {
                            //console.log(Math.abs(((this.state.final_price/100))*data.result.discount).toFixed(0))
                            this.setState({
                                promo_code_id: data.result.promo_code_id,
                                discount: data.result.discount,
                                // discounted_price: Math.abs(((this.state.final_price/100))*data.result.discount).toFixed(0),
                                // net_price: (this.state.final_price - Math.abs(((this.state.final_price/100))*data.result.discount)).toFixed(0),
                                discounted_price: (this.state.final_price / 100) * data.result.discount,
                                net_price: Math.round(this.state.final_price - ((this.state.final_price / 100) * data.result.discount)),
                                promo_code_error: null,
                                promo_code_disabled: true,
                                promo_code: this.state.promo_code + ' (' + data.result.discount.toFixed(2).replace(/\.?0+$/, "") + '%)'
                            });
                        } else {
                            this.setState({ promo_code_id: null, net_price: this.state.final_price, promo_code_error: data.reason.promo_code });
                        }
                    } else {
                        toast.error(data.message);
                        this.setState({ promo_code_id: null, net_price: this.state.final_price, promo_code_error: data.reason.promo_code });
                    }
                })
        }
    }

    createOrder = async () => {
        this.setState({ form_submitted: true })

        if (this.state.bill_to_id === "") {
            this.setState({ bill_to_error: true })
            return true;
        } else {
            this.setState({ bill_to_error: false })
        }

        if (this.state.tnc_check === false || this.state.tnc_check === 0) {
            this.toggleMessageModal();
            return true;
        }

        if (!!!this.state.gst && !!!this.state.state_of_supply) {
            await confirm({
                confirmation: 'Please add Gst number or select state for selected entity to procced further.',
                options: { type: "alert" }
            });
            return true;
        }


        const licenses = [];

        this.state.licenses.map((license, index) => {
            if (license.licence_price_discounted) {
                var perUnitAmt = license.licence_price_discounted;
            } else {
                var perUnitAmt = license.licence_price;
            }

            if (license.account_license_id != undefined) {
                var account_license_id = license.account_license_id;
            } else {
                var account_license_id = null;
            }

            licenses.push({
                "license_id": license.license_id,
                "count": license.quantity,
                "perunitamount": perUnitAmt,
                "account_license_id": account_license_id
            })
        })
        var razorpay_order_id = null;
        if (this.state.is_retry === 1 || this.state.order_summary_type === 'upgrade') {
            var razorpay_order_id = this.state.razorpay_order_id;
        } else {
            var razorpay_order_id = null;
        }


        const details = {
            "grand_total_amount": this.state.final_price,
            "promo_code_id": this.state.promo_code_id,
            "promo_code": this.state.promo_code,
            "net_total_amount": parseInt(this.state.net_price),
            // "total_license_count": this.state.final_quantity,
            "discount": this.state.discount,
            "licenses": licenses,
            "billing_address": this.state.address,
            "bill_to_type": this.state.bill_to_type,
            "bill_to_gst": this.state.gst,
            "bill_to_id": this.state.bill_to_id,
            "tnc_check": this.state.tnc_check,
            "bill_to_name": this.state.bill_to_name,
            // "razorpay_order_id": razorpay_order_id,
            "type": "new",
            "state_of_supply": this.state.state_of_supply
        }

        // if(this.state.order_summary_type == 'upgrade') {
        //     details.upgrade_flag = 1;
        //     details.type = "upgrade";
        //     details.is_retry = 0;
        // }

        // if(this.state.order_summary_type == 'renew') {
        //     details.upgrade_flag = 0;
        //     details.type = 'renew';
        //     details.is_retry = 0;
        // }

        // if(this.state.order_summary_type == 'new') {
        //     details.upgrade_flag = 0;
        //     details.type = 'new';
        //     details.is_retry = 0;
        // }

        if (this.state.is_retry == 1) {
            details.order_id = this.props.location.state.order_id;
            details.is_retry = 1;
        }
        console.log(details);
        //return;
        LicenseService.createOrder(details)
        .then(data => {
            if (data.response_code === 200) {
                console.log(":::::::::::",this.state.order_summary_type)
                if (data.result.buktec_order_id != undefined){
                    if(data.result.razorpay_order_id === null && data.result.razorpay_key === null && (data.result.amount === 0.0 || data.result.amount === 0)){
                            console.log("::::>>When a user has 100% discount>>>>>>::::::::")
                            this.setState({is_dirty: false})
                            toast.success("Licenses purchased successfully!");
                            this.props.history.push({
                                        pathname: "/licenses",
                                        scroll: true,
                                        buktec_order_id: data.result.buktec_order_id,
                                        order_summary_type: this.state.order_summary_type
                                    }) 
                    }
                    else{
                            this.setState({order_response: data.result, buktec_order_id: data.result.buktec_order_id}, () => {
                                this.displayRazorpay();
                            });

                    }
                   
                } else if(data.message == 'Licence Price Mismatch'){
                    this.togglePriceMessageModal();
                    this.state.licenses.forEach(function (license) {
                        if (typeof data.result[license.license_id] !== "undefined" ) {
                            license.licence_price = data.result[license.license_id];
                            license.total_rate = data.result[license.license_id] * license.quantity;
                        }
                    });
                    this.setValidityDate();
                } else {
                    if (!!data.reason.promo_code) {
                        this.setState({ promo_code_error: data.reason.promo_code });
                    }

                    toast.error(data.message);
                }
            }})
    }

    toggleMessageModal = () => {
        this.setState({ tnc_alert: !this.state.tnc_alert })
    }

    togglePriceMessageModal = () => {
        this.setState({ price_change_alert: !this.state.price_change_alert })
    }

    handleSuccessPaymentResponse = (response) => {
        localStorage.removeItem("order_data");
        localStorage.removeItem("upgrade_order_data")
        localStorage.removeItem("renew_order_data")
        localStorage.removeItem("bill_to_data")

        this.setState({ is_dirty: false })

        if (this.state.order_summary_type === 'new') {
            toast.success("Licenses purchased successfully!");

            this.props.history.push({
                pathname: "/licenses",
                scroll: true,
                buktec_order_id: this.state.buktec_order_id,
                //buktec_order_id: null,
                order_summary_type: this.state.order_summary_type
            })

        } else if (this.state.order_summary_type === "upgrade") {
            toast.success("License upgraded successfully!");
        } else {
            toast.success("License renewed successfully!");
        }

        this.props.history.push({
            pathname: "/licenses",
            scroll: true,
            buktec_order_id: this.state.buktec_order_id,
            order_summary_type: this.state.order_summary_type
        })
    }

    displayRazorpay = () => {

        const { amount, razorpay_order_id, razorpay_key, description, company_name, buktec_order_id } = this.state.order_response;

        const options = {
            key: razorpay_key,
            amount: amount.toString(),
            currency: "INR",
            name: company_name,
            description: description,
            image: logo_dash,
            order_id: razorpay_order_id,
            handler: this.handleSuccessPaymentResponse,
            theme: {
                color: "#61dafb",
            },
            modal: {
                ondismiss: function (response) {

                }
            },
            retry: false
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

        paymentObject.on('payment.failed', (err) => {
            //paymentObject.close();
            //alert(err.error.description); 
            localStorage.removeItem("order_data");
            localStorage.removeItem("upgrade_order_data")
            localStorage.removeItem("renew_order_data")
            localStorage.removeItem("bill_to_data")

            //document.getElementsByClassName('razorpay-container')[0].remove()

            // var frame = document.getElementsByClassName("razorpay-container"),
            // frameDoc = frame[0].contentDocument || frame[0].contentWindow.document;
            // frameDoc.documentElement.innerHTML = "";

            toast.error("Payment failed! please try again");
            this.setState({ is_dirty: false }, function () {
                //this.props.history.push("/licenses/order-history");
                window.location.href = "/licenses/order-history"
            })
        });
    }

    goToProfileManagement = () => {
        this.setState({ is_dirty: false }, function () {
            this.props.history.push("/profile-management/entities/");
        })
    }

    goToLicenseListingPage = () => {
        this.setState({ is_dirty: false }, function () {
            this.props.history.push("/licenses");
        })
    }

    closeAction = () => {
        this.setState({ closed: true })
    }

    removePromoCode = () => {
        this.setState({
            promo_code_disabled: false,
            promo_code: "",
            promo_code_id: null,
            discounted_price: 0,
            net_price: this.state.final_price.toFixed(0),
        })
    }

    render() {
        if (this.state.order_summary_type === "upgrade") {
            var orderType = "Select License for Upgrading";
        } else if (this.state.order_summary_type === "renew") {
            var orderType = "Select Licenses for Renewing";
        } else {
            var orderType = "Select Licenses for Buying";
        }
        console.log(this.props)
        console.log(this.state)
    
        return (
            <main className="content-wrapper-new">
                <div className="container-fluid container-padding-new">

                    <RouteLeavingGuard
                        when={this.state.is_dirty}
                        closed={this.state.closed}
                        closeAction={this.closeAction}
                    />

                    {/* Breadcrumb */}
                    <div className="row">
                        <div className="col-12">
                            <div className="main-sub-header-new">
                                <div className="breadcrumb-header-new">
                                    <div className="row">
                                        <div className="col-6 col-xl-5 pr-0">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item-new"><Link to={'/home'}>Home</Link></li>
                                                <li className="breadcrumb-item-new" onClick={this.goToProfileManagement}>Profile Management</li>
                                                <li className="breadcrumb-item-new" onClick={this.goToLicenseListingPage}>My Licenses</li>
                                                <li className="breadcrumb-item-new">Order Summary</li>
                                            </ol>
                                            <h2 className="breadcrumb-title-new">Order Summary</h2>
                                        </div>

                                        <div className="col-6 col-xl-7 pr-0 align-self-center text-right">
                                            <ul className="process_step_bar">
                                                <li className="active_process">Select Licenses for Buying</li>
                                                <li className="active_process">Order Summary</li>
                                                <li className="">Make Payment</li>
                                                {/* <li className="dot_process">Assign</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrumb */}


                    {/* Message Modal Popup */}
                    <MessageModal showModal={this.state.tnc_alert} content={"Please accept Terms & Conditions"} toggleMessageModal={this.toggleMessageModal} />

                    <MessageModal showModal={this.state.price_change_alert} content={"License prices has been changed! Please have a look at new price"} toggleMessageModal={this.togglePriceMessageModal} />
                    {/* Message Modal Popup */}


                    {/* Table */}
                    <div className="row sec-mb">
                        <div className="col-12">
                            <Table className="table-order" responsive table-bordered >
                                {/* <colgroup>
                                    <col width="" />
                                    <col width="20%" />
                                    <col width="15%" />
                                    <col width="15%" />
                                </colgroup> */}
                                <thead>
                                    <tr className="">
                                        <th className="">License</th>
                                        <th className="text-center">Valid From</th>
                                        <th className="text-center">Valid To</th>
                                        <th className="">Remark</th>
                                        <th className="text-right" width="150px">Rate</th>
                                        <th className="text-center" width="190px">Quantity</th>
                                        <th className="text-right" width="150px">Total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.data_ready && this.state.licenses.filter(license => license.quantity !== 0).map((license, index) => {
                                        return <tr key={index}>
                                            <td>{license.licence_name}</td>
                                            <td className="text-center">{license.valid_from}</td>
                                            <td className="text-center">{license.valid_to}</td>
                                            {(this.state.order_summary_type == "new") && <td>New License</td>}
                                            {this.state.order_summary_type == "upgrade" && <td>{"Upgrade License ( - )"}</td>}
                                            {this.state.order_summary_type == "renew" && <td>Renew License</td>}
                                            {this.state.order_summary_type != "upgrade" && <td className="text-right">₹{license.licence_price_discounted ? convertToCurrency(license.licence_price_discounted) : convertToCurrency(license.licence_price)}</td>}

                                            {this.state.order_summary_type == "upgrade" && <td className="text-right">₹{convertToCurrency(license.licence_price)}</td>}

                                            <td className="text-center">{license.quantity}</td>
                                            <td className="text-right">₹{convertToCurrency(license.total_rate)}</td>
                                        </tr>
                                    })}
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td className="empty-td" colSpan={4}></td>
                                        <td className="tfoot-first-child">Grand Total</td>
                                        <td className="text-center">{this.state.final_quantity}</td>
                                        <td className="text-right">₹{convertToCurrency(this.state.final_price)}</td>
                                    </tr>
                                    <tr>
                                        <td className="empty-td" colSpan={4}></td>
                                        <td className="tfoot-first-child">Promo Code</td>
                                        <td className="text-center">
                                            <div class="input-group input-group-border">
                                                <input type="text" className="form-control" name="promo_code"
                                                    placeholder="Enter Code Here"
                                                    value={this.state.promo_code}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.promo_code_disabled}
                                                />
                                                <div className="input-group-prepend">
                                                    {/* <span className="input-group-text">@</span> */}
                                                    {!this.state.promo_code_disabled ?
                                                        <div className="input-gp-btn" onClick={() => this.applyPromoCode()}><span className="icon-check white-icon cpointer"></span></div>
                                                        :
                                                        <div className="input-gp-btn" onClick={() => this.removePromoCode()}><span className="icon-cross--grey white-icon cpointer"></span></div>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div class="invalid-feedback">Example invalid input group feedback</div> */}
                                            {this.state.promo_code_error && <small className="error_right">{this.state.promo_code_error}</small>}
                                        </td>

                                        <td className="text-right">
                                            {this.state.promo_code_id ?
                                                <> - ₹{!convertToCurrency(this.state.discounted_price) ? convertToCurrency(Math.round(this.state.discounted_price)) : convertToCurrency(Math.round(this.state.discounted_price))} </>
                                                : <> - ₹0 </>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="empty-td" colSpan={4}></td>
                                        <td className="tfoot-first-child td-green-bg">Net Total</td>
                                        <td className="td-green-bg"></td>
                                        <td className="text-right td-green-bg">₹{convertToCurrency(Math.round(this.state.net_price))}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>

                        <div className="col-sm-12 text-right">
                            <p className="p-big mb-0">* Prices inclusive of GST</p>
                        </div>
                    </div>
                    {/* End Table */}


                    {/* Billing Information */}
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Billing Information</h2>
                            <hr className="hr-blue-line my-2" />
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-sm-4">
                            <div className="d-flex align-items-top gap-20">
                                <h3 className="text-nowrap mb-0">Bill to<span className="red-star">*</span></h3>
                                <div className="form-group mb-0 w-75">
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                                            <Form.Control as="select" name="bill_to_id" value={this.state.bill_to_index} onChange={this.selectBillTo} ref={(input) => this.myinput = input}>
                                                <option value="">Select</option>
                                                {this.state.bill_to_list.map((item, index) => {
                                                    return <option key={index} value={index}>{item.name == "Master Account" ? `${item.name} (${this.state.account_details.full_name}) ` : item.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                    {(this.state.bill_to_error && this.state.form_submitted) && <small className="error_right">Select Bill to</small>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            {this.state.bill_to_id &&
                                <ul class="licence-list-row">
                                    <li class=""><b>GST#:</b> {!!this.state.gst ? this.state.gst : "None"}</li>
                                    <li class=""><b>Address:</b> {!!this.state.address ? this.state.address : "None"}</li>
                                </ul>
                            }
                        </div>
                    </div>

                    <div className="row sec-mt">
                        <div className="col-sm-12">
                            <p className="p-big mb-0">
                                <b>Note :</b> The information provided here such as Billing Name, GSTIN and Address will be used for invoice generation.
                            </p>
                            <hr className="hr-blue-line my-2" />
                        </div>
                    </div>
                    {/* End Billing Information */}


                    {/* Terms & Conditions */}
                    <div className="row sec-my">
                        <div className="col-sm-12">
                            <h2>Terms & Conditions</h2>
                            <hr className="hr-blue-line my-2" />
                        </div>

                        <div className="col-sm-12">
                            <div className="term-and-condition">
                                <div className="tnc-content">
                                    <p>Sampatanand Tech Private Limited (the <b>“Company”</b>) operates a web/mobile application, <b>“Buktec”</b> (the <b>“App”</b>) available on Google Play Store, iOS and other similar platforms and also operates a website <a href="https://thebuktec.com/" target="_blamk">www.thebuktec.com</a> (the <b>“Website”</b>) . The App and the Website shall be together referred to as the <b>“Platform”</b>. These terms and conditions (<b>“Terms”</b>) govern the use of or access to the Platform and the Services (as defined below).</p>
                                    <p>These Terms constitute a binding and enforceable legal contract between the Company and a User (as defined below) or any end user of the Services (collectively, <b>“you”</b>). You represent and warrant that you (a) have full legal capacity and authority to agree and bind yourself to these Terms, (b) are 18 (eighteen) years of age or older, and (c) are an Indian resident. If you represent an entity, organization, or any other legal person, you confirm and represent that you have the necessary power and authority to bind such entity, organization, or legal person to these Terms.</p>
                                    <p>These Terms also include our privacy policy, available at (“Privacy Policy”) and any internal guidelines, supplementary terms, policies, or disclaimers made available or issued by us from time to time. By continuing to access or use the Platform, or any Service on the Platform, you signify your acceptance of the Terms. Further, you understand that the Platform is intended for the use of Indian residents only and by your continuing access and/use of the Platform shall be construed as a deemed declaration that you are an Indian resident.</p>
                                    <p>The Company reserves the right to make changes to these Terms by posting the new/updated version and your continued use and/or non-deletion of the Platform shall indicate your agreement to such changes. Accordingly, we encourage you to kindly continue to review the Terms whenever accessing or using the Platform so as to be abreast with the changes that we may be carrying out to these Terms.</p>
                                    <p>By using the Services, you agree that you have read, understood, and are bound by these Terms, and that you comply with the requirements listed herein. If you do not agree to all of these Terms or comply with the requirements herein, please do not access the Platform or use the Services.</p>

                                    <h3>Services</h3>
                                    <p>The Platform is a cloud and mobile based SaaS platform which brings business owners and their accountants, (collectively, “Users”) on the same platform. It uses Artificial intelligence and Machine learning to simplify, digitize and automate back-office accounting processes and all other related activities like invoicing, payment collection etc. (collectively, “Services”). For the purposes of this clause, Services would include any other future services the Company provides/proposes to provide.</p>

                                    <h3>On-Boarding</h3>
                                    <p>To avail the Services, a User would be required to create a profile/sign-up on the Platform (“Profile”) using his/her email ID and phone number among other details. In addition to setting up username and password to create the Profile, the User will be required to furnish certain details, including but not limited to phone numbers and details of its customers and businesses. The User warrants that all information furnished in connection with its Profile is and shall remain accurate and true in all respects. The User further agrees and undertakes to promptly update its details on the Platform in the event of any change or modification of such details.</p>
                                    <p>The User is solely responsible for maintaining the security and confidentiality of its username and password and agrees to immediately notify the Company unauthorized use of its Profile or any other breach of security with respect to its Profile.</p>
                                    <p>The User expressly agrees to be liable and accountable for all activities that take place through its Profile in furtherance of the use of Service or otherwise. The Company expressly excludes any liability for any unauthorized access to a User’s Profile.</p>
                                    <p>The User agrees to receive communications from the Company regarding: (i) information relating to transactions recorded on the Platform; (ii) requests for payment; (iii) information about the Company and the Services; (iv) promotional offers and services from the Company and its third party partners, and (v) any other matter in relation to the Services.</p>

                                    <h3>Know Your Customer Policy</h3>
                                    <p>The Company may require Users to upload certain information and documents that may be necessary to ascertain their eligibility to use certain features of the Services including but not limited to their identification documents (“KYC Documents”).</p>
                                    <p>The User hereby authorizes the Company and any third-party service provider it may engage with or interact with in connection with using the Platform to process KYC Documents and ascertain the User’s eligibility. Any processing undertaken by the Company shall be in accordance with its Privacy Policy and these Terms. It is hereby clarified that as far as the permission of the KYC Documents by a third-party service provider is concerned, the same shall be governed by the privacy policy of such third party service provider.</p>
                                    <p>The User agrees that it may be required to submit additional documents as and when required by the Company or any of its third party service providers and if, in such an event, any additional information, data, or documentation is required (collectively, “Top-Up Documents”), the User hereby agrees to share such Top-Up Documents promptly upon request, and further, authorizes the Company to process such Top-Up Documents.</p>
                                    <p>The User agrees and warrants to provide valid, true, complete, and up-to-date KYC Documents and Top-Up Documents. The User further acknowledges that any incorrect or misleading information provided shall constitute a material breach of these Terms, and the User’s access to certain features of the Services may be limited or denied in such event.</p>
                                    <p>The list of the KYC Documents and Top-Up Documents may be provided to the User at the time of creating the Profile/signing-up or at a later stage.</p>

                                    <h3>Transaction Information</h3>
                                    <p>The Users may upload information relating to their businesses, including expenses, the sale of goods or services, costs, amounts paid and payable, and details of goods and services, on the Platform (such information is referred to as “Transaction Information”). Transaction Information may be exchanged between the Users and their customers through telephonic calls, text message, WhatsApp, email, or other electronic mediums that would depend on the contact details of Users and their customers provided on the Platform.</p>

                                    <h3>Third Party Services</h3>
                                    <p>The Services may include services, content, documents, and information owned by, licensed to, or otherwise made available by a third party (“Third Party Services”) or contain links to Third Party Services. Users understand that Third Party Services are the responsibility of the third party that created or provided it and acknowledge that use of such Third-Party Services is solely at their own risk.</p>
                                    <p>The Company makes no representations and hereby expressly excludes all warranties and liabilities arising out of or pertaining to such Third-Party Services, including their accuracy or completeness. Further, all intellectual property rights in and to Third Party Services are the property of the respective third parties.</p>
                                    <p>The Company may payments via payment service providers (“PSP”) partners and the User should take care not to share his personal UPI pin or OTP with any third party intentionally or unintentionally. The Company never solicits information such as UPI pin or OTP over a call or otherwise. The Company shall not be liable for any fraud due to the sharing of such details by the User. The providers providing Third Party Services / PSP partners shall not be liable for any fraud due to sharing of such details by the User.</p>

                                    <h3>User Responsibilities</h3>
                                    <p>The User hereby represents and warrants that all information that is provided by the User through or in relation to the Services is valid, complete, true, and correct on the date of agreeing to these Terms and shall continue to be valid, complete, true, and correct throughout the duration of the User’s use of the Platform. The Company does not accept any responsibility or liability for any loss or damage the User may suffer or incur if any information, documentation, material, or data provided to avail the Services is incorrect, incomplete, inaccurate, or misleading, or if the User fails to disclose any material fact.</p>
                                    <p>The User shall be solely responsible for ensuring compliance with applicable laws and shall be solely liable for any liability that may arise due to a breach of its obligations in this regard.</p>
                                    <p>The User shall extend all cooperation to the Company in its defending of any proceedings that may be initiated against it due to a breach of the User’s obligations or covenants under these Terms.</p>
                                    <p>While the Company uses commercially reasonable efforts to provide Users with a daily backup of their Transaction Information, Users should regularly and independently save, backup, and archive such Transaction Information.</p>
                                    <p>The User shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, the User may not:</p>
                                    <p>Infringe either directly or indirectly any third-party proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets, of any party;</p>
                                    <p>except as may be provided hereunder, use in any manner including copying, displaying, distributing, modifying, publishing, reproducing, storing, transmitting, posting, translating, creating any derivative works from, or license the Services;</p>
                                    <p>use the Services to transmit any data or send or upload any material that contains viruses, trojan horses or any other harmful programmes or similar computer code designed to adversely affect the operation of any computer software or hardware;</p>
                                    <p>use any robot, spider, other automated device, or manual process to monitor or copy the Platform or Services or any portion thereof;</p>
                                    <p>use the Services in furtherance of / to engage in any activity which may be grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
                                    <p>engage in the systematic retrieval of content from the Platform or Services to create or compile, directly or indirectly, a collection, compilation, database or directory; or</p>
                                    <p>violate applicable laws in any manner.</p>
                                    <p>Users who are expected to conduct proper research to ensure that the goods and services they send payment links for are in compliance with all applicable laws and we encourage users to cross-check before generating payment link for prohibited content may result in the suspension or removal of user’s account.</p>
                                    <p>The User shall not use the Services for / in furtherance of any sale or supply of prohibited products or services including but not limited to:</p>
                                    <p>sale of regulated goods;</p>
                                    <p>sale of counterfeit, replicas and pirated goods and goods infringing any intellectual property rights including pirated recordings or copies of unauthorized copyrighted materials whether in electronic or physical form or any other medium which may not be in existence at present;</p>
                                    <p>sale of tobacco and cigarettes which includes cigarettes, cigars, chewing tobacco, and related products;</p>
                                    <p>sale of liquor or any narcotic drugs and psychotropic substances;</p>
                                    <p>sale / supply of any good or service which may not be in public interest;</p>
                                    <p>gambling or other prohibited activities; and</p>
                                    <p>sale / supply of good or service which results in non-compliance of any applicable law.</p>
                                    <p>The User shall be solely responsible for compliance with all the applicable laws including without limitation the Prevention of Money Laundering Act, 2002 and the rules made thereunder. The Company shall not be responsible for any claims or liability or losses that may arise due to non-compliance of the anti-money laundering laws in India.</p>

                                    <h3>Intellectual Property</h3>
                                    <p>All rights, title, and interest in and to the Platform and Services, including all intellectual property rights arising out of the Platform and Services, are owned by or otherwise lawfully licensed by the Company. Subject to compliance with these Terms, the Company grants the User a non-exclusive, non-transferable, non-sub licensable, royalty-free, revocable, and limited licence to use the Platform and Services in accordance with these Terms and its written instructions issued from time to time.</p>
                                    <p>The User should assume that everything the User sees or reads on the Platform is protected under the Indian Copyright Act, 1957 and other intellectual property laws of India and may not be used except with the prior written permission of the Company.</p>
                                    <p>The Company may freely use, copy, disclose, publish, display, distribute without any payment of royalty, acknowledgement, prior consent, or any other form of restriction arising out of the User’s intellectual property rights.</p>
                                    <p>Except as stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to the Company’s or any third party’s intellectual rights.</p>
                                    <p>The contents of this Platform, including but not limited to the text and images herein and their arrangements, unless otherwise noted, are copyright-protected in the whole and every part of this Platform and the same belongs to the Company and may not be used, sold, licensed, copied or reproduced in whole or in part in any manner or form or in or on any media to any person without the prior written consent of the Company.</p>

                                    <h3>Term and Termination</h3>
                                    <p>These Terms shall remain in effect unless terminated in accordance with the terms hereunder.</p>
                                    <p>The Company may terminate a User’s access to or use of the Services, or any portion thereof, immediately and at any point, at its sole discretion, if the User violates or breaches any of its obligations, responsibilities, or covenants under these Terms.</p>
                                    <p>Upon termination these Terms shall terminate, except for those clauses that expressly or are intended to survive termination or expiry.</p>
                                    <p>Notwithstanding anything to the contrary contained in the Terms, upon termination of a User’s access to or use of the Services, all amounts or outstanding monies due by you in relation to your use of or access to the Services shall become immediately payable.</p>

                                    <h3>Disclaimers and Warranties</h3>
                                    <p>The use of the Services is at your sole risk.</p>
                                    <p>You acknowledge and agree that the Company is not engaged in the provision, grant, or disbursement of any financial product. The Company is not and will not be responsible for any claim or for any damages suffered, whether by the Users, the customers of the Users or any other person or party, that are related, directly or indirectly, to or arise out of the same including any payments made by the User or by the customers of the User using the payment link generated using the Platform. The User further agrees and undertakes to retain proof of sale documentation (in electronic or physical form) in connection with each payment link it generates or sends to customers.</p>
                                    <p>To the extent permitted by applicable law, the Services are provided on an “as is” and “as available” basis. The Company does not warrant that operation of the Services will be uninterrupted or error free or that the functions contained in the Services will meet your requirements.</p>
                                    <p>To the fullest extent permissible under applicable law, the Company expressly disclaims all warranties of any kind, express or implied, arising out of the Services, including warranties of merchantability, fitness for a particular purpose, satisfactory quality, accuracy, title and non-infringement, compatibility, applicability, usability, appropriateness, and any warranty that may arise out of course of performance, course of dealing, or usage of trade.</p>
                                    <p>You hereby accept full responsibility for any consequences that may arise from your use of the Services, and expressly agree and acknowledge that the Company shall have absolutely no liability with respect to the same.</p>
                                    <p>To the fullest extent permissible by law, the Company, its affiliates, and its related parties each disclaim all liability to you for any loss or damage arising out of or due to: </p>
                                    <p>your use of, inability to use, or availability or unavailability of the Services, including any Third Party Services;</p>
                                    <p>the occurrence or existence of any defect, interruption, or delays in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction or unauthorized access to the Company’s records, programmes, services, server, or other infrastructure relating to the Services; or</p>
                                    <p>the failure of the Services to remain operational for any period of time.</p>
                                    <p>Notwithstanding anything to the contrary contained herein, neither the Company nor any of its affiliates or related parties shall have any liability to you or any third party for any indirect, incidental, special or consequential damages or any loss of revenue or profits arising under, directly or indirectly, or relating, in any manner whatsoever, to these Terms or the Services. To the maximum extent permitted by law, you agree to waive, release, discharge, and hold harmless the Company, its affiliated and subsidiary companies, its parent companies, and each of their directors, officers, employees, and agents, from any and all claims, losses, damages, liabilities, expenses and causes of action arising out of the Services.</p>
                                    <p>Buktec currently is not a payment aggegator or bank account aggregator or payment gateway-it may enable payments for its users, pull bank account statements or receive payment via third-party service providers. Any charges levied by us, if any, for usage are service charges.</p>

                                    <h3>Indemnity</h3>
                                    <p>You shall indemnify, defend at the Company’s option, and hold the Company, its parent companies, subsidiaries, affiliates, and their officers, associates successors, assigns, licensors, employees, directors, agents, and representatives, harmless from and against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages and costs (including, without limitation, from all damages, liabilities, settlements, costs and attorneys’ fees) due to or arising out of your access to the Services, use of the Services, violation of these Terms or any infringement by any third party who may use your account with the Company, of these Terms.</p>

                                    <h3>Consent To Use Data</h3>
                                    <p>You agree that the Company and any third-party service providers it engages, may, in accordance with its Privacy Policy, collect and use your information and technical data and related information.</p>
                                    <p>The Company may use information and data pertaining to your use of the Services for analytics, trends’ identification, and purposes of statistics to further enhance the effectiveness and efficiency of the Platform.</p>
                                    <p>Subject to applicable laws, the Company may be directed by law enforcement agencies or the government and related bodies to disclose data in relation to Users in connection with criminal proceedings. You understand and agree that in such instances, the Company shall have the right to share such data with relevant agencies or bodies.</p>

                                    <h3>Fees/Charges and Refund Policy</h3>
                                    <p>User will pay Company the Subscription Fees as set forth in the invoice. All fees are non-refundable. The Company reserves the right to charge convenience fee for other Services and non-payment may result in denial of Services. </p>

                                    <h3>Modification</h3>
                                    <p>The Company reserves the right at any time to add, modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without cause. The Company shall not be liable for any such addition, modification, suspension or discontinuation of the Services.</p>

                                    <h3>Jurisdiction, Governing Laws, and Dispute Resolution</h3>
                                    <p>These Terms shall be governed by and construed and enforced in accordance with the laws of India. Subject to other provisions in this Clause, courts in Mumbai shall have exclusive jurisdiction over all issues arising out of these Terms or the use of the Services. </p>
                                    <p>Any controversies, conflicts, disputes, or differences arising out of these Terms shall be resolved by arbitration in Mumbai in accordance with the Arbitration and Conciliation Act, 1996 for the time being in force, which is deemed to be incorporated by reference in this Clause. The tribunal shall consist of 1 (one) arbitrator appointed by the Company. The language of the arbitration shall be English. </p>
                                    <p>The parties to the arbitration shall keep the arbitration confidential and not disclose to any person, other than on a need to basis or to legal advisors, unless required to do so by law. The decision of the arbitrator shall be final and binding on all the Parties hereto.</p>
                                    <p>Each party to the arbitration shall bear its own costs with respect to any dispute. </p>

                                    <h3>Miscellaneous Provisions</h3>
                                    <p><b>Modification -</b> The Company reserves the right at any time to modify these Terms and to add new or additional terms or conditions on use of the Services. Such modifications and additional terms and conditions will be communicated to you and, unless expressly rejected (in which these Terms shall terminate), will be effective immediately and will be incorporated into these Terms. In the event you refuse to accept such changes, these Terms will terminate.</p>
                                    <p><b>Severability -</b> If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue in effect. If any unlawful or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).</p>
                                    <p><b>Assignment -</b> You shall not license, sell, transfer or assign your rights, obligations, or covenants under these Terms in any manner without the Company’s prior written consent. The Company may grant or withhold this consent in its sole discretion and subject to any conditions it deems appropriate. The Company may assign its rights to any of its affiliates, subsidiaries, or parent companies, or to any successor in interest of any business associated with the Services without any prior notice to you.</p>
                                    <p><b>Third Party Rights -</b> No third party shall have any rights to enforce any terms contained herein.</p>
                                    <p><b>Translations -</b> The Company may provide you with translated versions of these Terms solely to assist you with understanding these Terms in greater detail. The English version of these Terms shall be controlling in all respects. In the event of any inconsistency between the English version of these Terms and any translated version, the terms of the English version shall prevail.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Terms & Conditions */}


                    {/* Button Bar */}
                    <div className="row ">
                        <div className="col-sm-12 sec-mb">
                            <div className="sup-cust-check sup-cust-check-lg i-accept-check justify-content-end">
                                <input className="styled-checkbox" id="styled-checkbox-11" type="checkbox" checked={this.state.tnc_check} name="tnc_check" onChange={this.handleChange} />
                                <label for="styled-checkbox-11" className="">I accept the Terms and Conditions</label>
                                {/* <label for="styled-checkbox-11" className="label-text"><span className='p-big'>I accept the Terms and Conditions</span></label> */}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <Button type="button" variant="outline-success reset_btn_new" onClick={this.props.history.goBack}>Back</Button>
                        </div>
                        <div className="col-sm-6 text-right">
                            <Button type="button" variant="success apply_btn_new" onClick={this.createOrder}>Make Payment</Button>
                        </div>
                    </div>
                    {/* Button Bar */}

                    {/* <div class="icon-relative form-group mb-0">
                        <input
                            type="text"
                            className="form-control"
                            name="promo_code"
                            value={this.state.promo_code}
                            placeholder="Enter Code Here"
                            onChange={this.handleChange}
                            disabled={this.state.promo_code_disabled}
                        />
                        {!this.state.promo_code_disabled ? <button type="button" className="icon-absolute" onClick={() => this.applyPromoCode()}>
                            <span class="icon-check iconwhite"></span>
                        </button> :
                            <button type="button" className="icon-absolute" onClick={() => this.removePromoCode()}>
                                <span class="icon-cross--grey iconwhite"></span>
                            </button>}
                    </div>
                    {this.state.promo_code_error && <small className="form-text text-muted" style={{ position: "relative" }}>{this.state.promo_code_error}</small>} */}
                </div>
            </main>
        )
    }
}

export default connect()(withRouter(OrderSummary));