import React from 'react'
import MessageBox from './Messages';
import NotesBox from './Notes';
import PrivateMessageBox from './PrivateMessages';
import TaskBox from './Tasks';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import firebase from '../../Utils/firebase'
import 'firebase/firestore';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import axios from 'axios';
import CommunicationBar from '../../Services/CommunicationBar/CommunicationBar';


const db = firebase.firestore();
class CommentSection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showPrivateMsg: false,
			showMessage: false,
			showNotes: false,
			showTask: false,
			public_messages: false,
			private_messages: false,
			general_msg_status: false,
			private_msg_status: false,
			is_task_available: false,
			is_notes_available: false,
			toggleSaveChangePopoup: false,
			next_tab: false
		}
	}

	onTabChange = (tab) => {
		this.setState({ next_tab: tab })

		if (this.props.communication_bar_tasks_dirty || this.props.communication_bar_notes_dirty || this.props.communication_bar_msgs_dirty || this.props.communication_bar_private_msgs_dirty) {
			this.setState({ toggleSaveChangePopoup: true })
		} else {

			if (tab == 'notes') {
				this.props.update_communication_bar_notes_dirty(false)
				this.toggleShowNotes()
			} else if (tab == 'private_msgs') {
				this.props.update_communication_bar_private_msgs_dirty(false)
				this.toggleShowPrivateMsg()
			} else if (tab == 'msgs') {
				this.props.update_communication_bar_msgs_dirty(false)
				this.toggleShowMessage()
			} else if (tab == 'tasks') {
				this.props.update_communication_bar_tasks_dirty(false)
				this.toggleShowTasks()
			}
		}
	}

	toggleShowMessage = () => {
		if (this.state.showMessage)
			this.setState({ showMessage: false })
		else
			this.setState({ showMessage: true, showNotes: false, showPrivateMsg: false, showTask: false })
	}
	toggleShowPrivateMsg = () => {
		if (this.state.showPrivateMsg)
			this.setState({ showPrivateMsg: false })
		else
			this.setState({ showPrivateMsg: true, showNotes: false, showMessage: false, showTask: false })
	}
	toggleShowNotes = () => {
		if (this.state.showNotes)
			this.setState({ showNotes: false })
		else
			this.setState({ showNotes: true, showMessage: false, showPrivateMsg: false, showTask: false })
	}
	toggleShowTasks = () => {
		if (this.state.showTask)
			this.setState({ showTask: false })
		else
			this.setState({ showTask: true, showNotes: false, showPrivateMsg: false, showMessage: false })
	}

	closeComunicationBar = () => {
		this.setState({ showTask: false, showNotes: false, showPrivateMsg: false, showMessage: false })
	}

	setPublicMessages = () => this.setState({ public_messages: true })
	setPrivateMessages = () => this.setState({ private_messages: true })
	setTasks = (value) => this.setState({ is_task_available: value })
	setNotes = (value) => this.setState({ is_notes_available: value })

	checkForMessenges = async (context_id) => {


		this.group_reference = db.collection("context_group");
		const data = this.group_reference
			// .orderBy("created_at", "desc")
			.where("members", 'array-contains', this.props.user_detail.id)
			.limit(10)
			.get();
		data.then(
			(data) => {
				let messages = [];
				data.docs.forEach((doc) => {
					if (!doc.data().readers.includes(this.props.user_detail.id))
						messages.push(doc.data());
				});
				this.setState({ messages });
			});


		this.groupRefRead = db.collection("context_group");
		await this.groupRefRead.where("context_id", '==', this.props.context_id)
			.where("context_type", '==', "public_context")
			// .where("members", 'array-contains', this.props.user_detail.id)
			.get()
			.then(
				(data) => {
					data.docs.forEach((doc) => {
						if (!doc.data().readers.includes(this.props.user_detail.id))
							this.setPublicMessages();
					});
				});

		await this.groupRefRead.where("context_id", '==', this.props.context_id)
			.where("context_type", '==', this.props.user_detail.account_type == 1 ? "private_context_buss" : "private_context_ca")
			// .where("members", 'array-contains', this.props.user_detail.id)
			.get()
			.then(
				(data) => {
					data.docs.forEach((doc) => {
						if (!doc.data().readers.includes(this.props.user_detail.id))
							this.setPrivateMessages();
					});
				});





		// let public_context = await db.collection("public_context").doc(this.props.context_name).collection(context_id + "").get();
		// let private_context = await db.collection(this.props.user_detail.account_type == 1 ? "private_context_buss" : "private_context_ca").doc(this.props.context_name).collection(context_id + "").get();
		// if (!!public_context.docs.length) {
		// 	this.setPublicMessages();
		// }
		// if (private_context.docs.length) {
		// 	this.setPrivateMessages();
		// }
	}

	checkForTasks = (taskBody) => {
		CommunicationBar.getTaskList(taskBody).then((data) => {
			if (data.response_code == 200) {
				if (data.result.data.length > 0) {
					console.log(data.result.data)
					this.setState({ is_task_available: true })
				} else {
					this.setState({ is_task_available: false })
				}
			}
		})
	}

	checkForNotes = (notesBody) => {
		CommunicationBar.getNotesList(notesBody).then((data) => {
			if (data.length > 0) {
				this.setState({ is_notes_available: true })
			}
			else {
				this.setState({ is_notes_available: false })
			}
		})
	}

	componentDidMount() {
		let dataBody = {
			ref_id: this.props.context_id,
			mod_type: this.props.context_no,
		}
		this.checkForMessenges(this.props.context_id);
		this.getCommunicationbarStatus(this.props.context_id);
		this.checkForTasks(dataBody)
		this.checkForNotes(dataBody)
		this.props.onRef(this);
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.context_id !== this.props.context_id)) {
			let dataBody = {
				ref_id: nextProps.context_id,
				mod_type: this.props.context_no,
			}
			this.setState({ public_messages: false, private_messages: false, is_task_available: false, is_notes_available: false })
			this.checkForMessenges(nextProps.context_id);
			this.getCommunicationbarStatus(nextProps.context_id);
			this.closeComunicationBar();
			this.checkForTasks(dataBody)
			this.checkForNotes(dataBody)
		}
	}

	saveData = (module, nextRoute) => {
		if (module == 'tasks') {
			this.tasks.taskSubmitHandler(nextRoute)
		} else if (module == 'notes') {
			this.notes.commentsSectionSubmitHandler(nextRoute)
		} else if (module == 'msgs' || module == 'private_msgs') {
			this.msgs.sendMessage(nextRoute)
		}
	}

	onCancel = () => {
		this.setState({ toggleSaveChangePopoup: false })
	}

	onDontSave = () => {
		this.setState({ toggleSaveChangePopoup: false })

		this.props.update_communication_bar_tasks_dirty(false)
		this.props.update_communication_bar_notes_dirty(false)
		this.props.update_communication_bar_msgs_dirty(false)
		this.props.update_communication_bar_private_msgs_dirty(false)

		setTimeout(() => {
			this.onTabChange(this.state.next_tab)
		}, 800);
	}

	onConfirm = () => {
		this.setState({ toggleSaveChangePopoup: false })

		if (this.props.communication_bar_tasks_dirty) {
			this.props.update_communication_bar_tasks_dirty(false)
			this.tasks.taskSubmitHandler(this.state.next_tab)

		} else if (this.props.communication_bar_notes_dirty) {
			this.props.update_communication_bar_notes_dirty(false)
			this.notes.commentsSectionSubmitHandler(this.state.next_tab)

		} else if (this.props.communication_bar_msgs_dirty) {
			this.props.update_communication_bar_msgs_dirty(false)
			this.msgs.sendMessage(this.state.next_tab)

		} else if (this.props.communication_bar_private_msgs_dirty) {
			this.props.update_communication_bar_private_msgs_dirty(false)
			this.msgs.sendMessage(this.state.next_tab)
		}
	}


	getCommunicationbarStatus = (context_id) => {
		let data = {
			"mod_type": this.props.context_no,
			"ref_id": parseInt(context_id)
		}
		CommunicationBar.getCommunicationbarStatus(data).then(
			(data) => {
				if (data.response_code === 200) {
					this.setState({
						general_msg_status: data.result.general_msg_status,
						private_msg_status: data.result.private_msg_status,
						is_task_available: data.result.tasks_status,
						is_notes_available: data.result.notes_status,
					})
				}
			});
	}

	render() {
		if (!!!this.props.context_id) {
			return "";
		}

		return (
			<div className="communicationbar">
				{
					this.state.showMessage ?
						<MessageBox
							context_no={this.props.context_no}
							context_type="public_context"
							view_only={this.props.view_only}
							context_name={this.props.context_name}
							context_id={this.props.context_id}
							setAvailableMsgFlag={this.setPublicMessages}
							title="Messages"
							onTabChange={this.onTabChange}
							update_communication_bar_msgs_dirty={this.props.update_communication_bar_msgs_dirty}
							onRef={ref => (this.msgs = ref)}
							hide_task_manager={this.props.hide_task_manager}
						/>
						:
						null
				}
				{
					!this.props.hide_task_manager && 
						this.state.showTask ? <TaskBox
						context_no={this.props.context_no}
						context_type="tasks"
						view_only={this.props.view_only}
						context_name={this.props.context_name}
						context_id={this.props.context_id}
						setAvailableTaskFlag={this.setTasks}
						onTabChange={this.onTabChange}
						update_communication_bar_tasks_dirty={this.props.update_communication_bar_tasks_dirty}
						onRef={ref => (this.tasks = ref)}
						hide_task_manager={this.props.hide_task_manager}
					/> : null
				}
				{this.state.showNotes ? <NotesBox
					context_no={this.props.context_no}
					userDetail={this.props.user_detail}
					context_type="notes"
					view_only={this.props.view_only}
					context_name={this.props.context_name}
					context_id={this.props.context_id}
					setAvailableNotesFlag={this.setNotes}
					onTabChange={this.onTabChange}
					update_communication_bar_notes_dirty={this.props.update_communication_bar_notes_dirty}
					onRef={ref => (this.notes = ref)}
					hide_task_manager={this.props.hide_task_manager}
				/> : null}
				{this.state.showPrivateMsg ?
					<MessageBox
						context_no={this.props.context_no}
						context_type={this.props.user_detail.account_type == 1 ? "private_context_buss" : "private_context_ca"}
						context_name={this.props.context_name}
						context_id={this.props.context_id}
						setAvailableMsgFlag={this.setPrivateMessages}
						onTabChange={this.onTabChange}
						update_communication_bar_private_msgs_dirty={this.props.update_communication_bar_private_msgs_dirty}
						onRef={ref => (this.msgs = ref)}
						title="Private Messages"
						view_only={this.props.view_only}
						hide_task_manager={this.props.hide_task_manager}
					/>
					: null}
				{/* </div> */}

				{/* Button Bar */}
				<form>
					{/* <div className="bottom-cmb"> */}
					<div className={"bottom-cmb " + ((this.state.showMessage || this.state.showTask || this.state.showNotes || this.state.showPrivateMsg) ? "d-none" : "")}>

						{/* Note */}
						{/* <lable className="lable-add-note">Add Note<span className="red-star">*</span></lable> */}
						{/* End Note */}

						{/* ------ */}
						<div className="bottom-inner-cmb">
							<div className="user-comment-cmb disable">
								{/* @mention a user */}
								{/* <p className="click-msg">@mention a user</p> */}
								<p className="click-msg">Click the on icons on right side to use the communication bar</p>
							</div>

							{/* The one-year Foundation Programme introduces students to the fundamentals of design and helps develop ways of perception, aesthetic sensitivity, understanding of the multidisciplinary nature of design and relationship of design with human senses and emotions, culture, environment and ecology. The one-year Foundation Programme introduces students to the fundamentals of design and helps develop ways of perception, aesthetic sensitivity, understanding of the multidisciplinary nature of design and relationship of design with human senses and emotions, culture, environment and ecology. */}

							{/* Note */}
							{/* <div className="user-comment-cmb">
								<div className="form-group mb-0 w-100">
									<textarea className="form-control" rows="2" name="task" placeholder=" Enter notes details"></textarea>
								</div>
							</div> */}
							{/* End Note */}

							{/* ------ */}
							<div className="icon-cmb">
								{/* <a role="button" className="active-msg active-dot"> */}
								<a role="button"
									className={(this.state.showMessage ? " active " : "") + (this.state.general_msg_status ? " active-msg " : "")}
									onClick={() => this.onTabChange('msgs')}
								>
									{this.state.public_messages ? <span className="active-dot"></span> : ""}
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Messages</Tooltip>}>
										<span className="icon-chat"></span>
									</OverlayTrigger>
								</a>

								{
									!this.props.hide_task_manager &&
									<a role="button"
										className={(this.state.showTask ? " active " : "") + (this.state.is_task_available ? " active-msg " : "")}
										onClick={() => this.onTabChange('tasks')}
									>
										{!!this.state.is_task_available ? <span className=""></span> : ""}
										<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tasks</Tooltip>}>
											<span className="icon-Group-2516"></span>
										</OverlayTrigger>
									</a>
								}

								<a role="button"
									className={(this.state.showNotes ? " active " : "") + (this.state.is_notes_available ? " active-msg " : "")}
									onClick={() => this.onTabChange('notes')}
								>
									{!!this.state.is_notes_available ? <span className=""></span> : ""}
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Notes</Tooltip>}>
										<span className="icon-Group-2546"></span>
									</OverlayTrigger>
								</a>

								<a role="button"
									className={(this.state.showPrivateMsg ? " active " : "") + (this.state.private_msg_status ? " active-msg " : "")}
									onClick={() => this.onTabChange('private_msgs')}
								>
									{this.state.private_messages ? <span className="active-dot"></span> : ""}
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Private Messages</Tooltip>}>
										<span className="icon-chat"></span>
									</OverlayTrigger>
								</a>
							</div>
						</div>

						{/* <div className="right-side-btn">
							<span className="character-limit">Character limit: 0/1000</span>
							<Button variant="success apply_btn_new" >Add Note</Button>
							<Button variant="success apply_btn_new" disabled>Send</Button>
						</div> */}
					</div>
				</form>
				{/* End Button Bar */}

				<Modal
					show={this.state.toggleSaveChangePopoup}
					backdrop="static"
					keyboard={false}
					centered
					className="popup-model error-popup"
				>
					<Modal.Body className="clearfix ">
						<p className="pmsg-text pmsg-gray-center">
						Do you want to save the changes?
						</p>
						<div className="center_apply_btn_new">
							<Button variant="success apply_btn_new" onClick={() => this.onConfirm()}>Yes</Button>
							<Button variant="success apply_btn_new" onClick={() => this.onDontSave()}>No</Button>
							<Button variant="outline-success reset_btn_new" onClick={() => this.onCancel()}>Cancel</Button>
						</div>
					</Modal.Body>

					{/* <Modal.Footer className='bottom-save-btn'>
						<Button onClick={() => this.onConfirm()} variant="" className="btn save-btn greenbtn model-save-btn">Yes</Button>
						<Button onClick={() => this.onDontSave()} variant="" className="btn save-btn greenbtn model-save-btn">No</Button>
						<Button onClick={() => this.onCancel()} variant="" className="btn model-save-btn save-btn graybtn">Cancel</Button>
					</Modal.Footer> */}

					{/* <Modal.Body className="clearfix row">
						<div className="col-1 mr-2">
							<span className="icon-exclamation-red-sign popicon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
						</div>
						<div className="col d-flex align-items-center">
							<p className="ft-sz-18 s-reg font-colr mb-0">Do you want to save the changes?</p> 
						</div>
					</Modal.Body> */}
					{/* <Modal.Footer>
						<Button onClick={() => this.onConfirm()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Yes</Button>
						<Button onClick={() => this.onDontSave()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">No</Button>
						<Button onClick={() => this.onCancel()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
					</Modal.Footer> */}
				</Modal>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const entity_list = all_value.entity_list || [];
	const account_permissions = all_value.account_permissions || [];
	const module_permissions = all_value.module_permissions || [];
	const user_detail = all_value.user_detail || {}
	const communication_bar_dirty_state = all_value.communication_bar_dirty_state || "";
	return {
		entity_list, account_permissions, module_permissions, user_detail, communication_bar_dirty_state
	};
}
export default connect(mapStateToProps)(withRouter(CommentSection));
