import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function FinishedStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Done</Tooltip>}>
            <span className="icon-tasks-icon green-white-icon icon-dt-td iconsc"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
            {/* <span className="icon-td icon-Group-3182 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
        </OverlayTrigger>
    </>
}
export default FinishedStatus;