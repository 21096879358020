function validateCreateTask(data)
 {
  let errors = {};
console.log('valid  ',data.assigned_to.length)
  if (data.assigned_to.length === 0) {
    errors.assigned_to = 'Assigned To field is required';
  }

  if (!data.task) {
    errors.task = 'Task is required';
  }
  if (!data.due_date) {
    errors.due_date = 'Due date is required';
  }
  return errors;
}
export {
    validateCreateTask
}