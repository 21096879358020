import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Pagination from '../../Components/BoilerPlate/Pagination';
import { displayDateFormate } from '../../Helpers/DateFormat';

class SideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_id: this.props.match.params.account_statement_id
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.match.params.account_statement_id !== this.props.match.params.account_statement_id)) {
      if (nextProps.match.params.account_statement_id) {
        this.setState({
          active_id: nextProps.match.params.account_statement_id
        })
      }
    }
  }

  componentDidMount() {
    //this.setState({active_id: this.props.account_statement_list[0].finanacial_institute_statement_id})
  }

  selectedStatement = (id) => {
    this.setState({active_id: id})
    this.props.history.push(`/financial-institutes/view/${this.props.match.params.financial_institute_id}/account-statements/${id}`)
  }

  getAccountStatementList = (page_no) => {
    this.props.setPageNo(page_no, this.props.fi_id)
  }


  render() {
    return (
      <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec"> 
        <div className="sidebar-sec-fixed">

          <div className="clearfix sidebar-header side-breadcrumbs">
            <div className="row">
            <div className="col-12 d-flex align-items-center">
              <ol className="breadcrumb">
								<li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                                                </li>
								<li className="breadcrumb-item"><Link to='/financial-institutes'>Banking</Link></li>
							</ol>
              {/* <ol className="breadcrumbs">
                <li className="breadcrumb-items">
                  Menu
                  </li>
                <li className="breadcrumb-items">Banking</li>
              </ol> */}
            </div>
            </div>
          </div>

        {/* <div className="recipt-sidebar-sec fi-account-sidebar"> */}
        <div className="recipt-sidebar-sec account-sidebar">
          {this.props.match.params.account_statement_id == 'new' && 
          <div className="recipt-inner-div invoice-side-list empty-div active">
            <div className=""></div>
            <div className="row">
              <p className="m-0 ft-sz-25 p-1 font-colr s-sbold">New Account Statement</p>
            </div>
          </div>
          }
          {this.props.account_statement_list.map((statement, index) => {
            return <div key={index} className={"recipt-inner-div invoice-side-list"+(this.state.active_id==statement.finanacial_institute_statement_id?" active":"")}
              onClick={() => this.selectedStatement(statement.finanacial_institute_statement_id)} >
                
              <div className="d-flex flex-row align-items-center side-card">
                <div className="mr-3">
                  <p className="tans-id font-gray mb-1">Start Date</p>
                  <p className="amnts s-sbold blu-color mb-0">{displayDateFormate(statement.start_date)}</p>
                </div>
                <div className="mr-2">
                  <p className="tans-id font-gray mb-1">End Date</p>
                  <p className="amnts s-sbold blu-color mb-0">{displayDateFormate(statement.end_date)}</p>
                </div>

                <div className="ml-auto">
                 <div className="evi-icon">
                    <div className="color-icon">
                      <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg col-md-5 col-xs-12">
                  <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                  <p className="ft-sz-17 s-sbold blu-color mb-0">{displayDateFormate(statement.start_date)}</p>
                </div>
                <div className="col-lg col-md-5 col-xs-12">
                  <p className="ft-sz-13 s-sbold font-colr mb-0">End Date</p>
                  <p className="ft-sz-17 s-sbold blu-color mb-0">{displayDateFormate(statement.end_date)}</p>
                </div>
                <div className="col-lg-1 col-md-2 col-xs-12">
                  <div className="evi-icon">
                    <div className="color-icon">
                      <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>


            // <div className="recipt-inner-div invoice-side-list"
            //   onClick={() => this.selectedStatement(statement.finanacial_institute_statement_id)} >
            //   <div className="row">
            //     <div className="col-xl-9 col-md-9 col-xs-12">
            //       <p className="ft-sz-17 s-sbold blu-color mb-0">Satement{displayDateFormate(statement.upload_date)}</p>
            //       <p className="ft-sz-17 s-sbold blu-color">#{statement.finanacial_institute_statement_id}</p>
            //     </div>
            //     <div className="col-xl-3 col-md-3 col-xs-12">
            //       <div className="evi-icon">
            //         <div className="color-icon">
            //           <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="row">
            //     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            //       <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
            //       <p className="ft-sz-17 s-sbold blu-color mb-0">{displayDateFormate(statement.start_date)}</p>
            //     </div>
            //     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            //       <p className="ft-sz-13 s-sbold font-colr mb-0">End Date</p>
            //       <p className="ft-sz-17 s-sbold blu-color mb-0">{displayDateFormate(statement.end_date)}</p>
            //     </div>
            //     <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            //       <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
            //       <p className="ft-sz-17 s-sbold blu-color mb-0">{statement.number_of_record}</p>
            //     </div>
            //   </div>
            // </div>

          })}
        </div>
        <Pagination
          getList={this.getAccountStatementList}
          has_previous={this.props.has_previous}
          has_next={this.props.has_next}
          page_no={this.props.page_no}
          per_page_items={this.props.per_page_items}
          total_pages={this.props.total_pages}
        />
      </div>
      </div>
    )
  }
}

export default withRouter(SideList);