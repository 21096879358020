import { SET_APPLICATION_LOADER } from "../Actions/Types";

const initialState = {
    is_app_loading: false
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case SET_APPLICATION_LOADER:
            return {
                ...state,
                is_app_loading: payload.is_app_loading,
                text: payload.text,
            }

        default:
            return state;
    }
}