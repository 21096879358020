import {
  SET_ENTITY_LIST_DATA,
  SET_SELECTED_ENTITY_LIST,
  SET_FEATURE_LIST,
  SET_DEFAULT_ROLES,
  SET_PERMISSION_ID,
} from "../Actions/Types";

const initialState = {
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ENTITY_LIST_DATA:
      return {
        ...state,
        available_entities: payload
      }
    case SET_SELECTED_ENTITY_LIST:
      return {
        ...state,
        selected_entities: payload
      }
    case SET_FEATURE_LIST:
    return {
        ...state,
        feature_list: payload
    }
    case SET_DEFAULT_ROLES:
        return {
        ...state,
        default_roles: payload
        }
    case SET_PERMISSION_ID:
        return {
        ...state,
        permission_ids: payload
        }
      
    default:
      return state;
  }
}