import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import FinancialInstitutesList from './FinancialInstitutesList';
import FIDetailPage from './FIDetailPage';
import AccountStatementDetailView from './AccountStatementDetailView';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';
import { toast } from 'react-toastify';
import { setFeatureList } from '../../Redux/Actions/Users';
import UserService from '../../Services/Users/UserService'
import { FI_CREATE_CODE,FI_UPLOAD_ACCOUNT_STATEMENT_CODE, FI_VIEW_ACCOUNT_STATEMENT_CODE } from '../../Helpers/Constants';
import StorageData from "../../Helpers/StorageData";
import { changeDateFormate, dateWithMonthName } from '../../Helpers/DateFormat';
import { setFinancialYearName, setFilterDateRange } from "../../Redux/Actions/FinancialInstitute";
import { setAppilcationLoder } from "../../Redux/Actions/App";
import ScrollPage from '../../Components/BoilerPlate/ScrollPage';
import { toTitleCase } from '../../Helpers/Capitalization';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';

class FinancialInstitutes extends React.Component {

    constructor(props) {
        super(props)

        let default_yr = StorageData.getDefaultFinancialYear();

        this.state = {
            yodleedata: {},
            FIList: [],
            bank_name_list: [],
            fi_details: {},
            //account_statement_list: [],
            sort_by: "1",
            sort_order: 2,
            per_page_items: 0,
            page_no: 1,
            transaction_list: [],
            year_type: default_yr.year_type,
            start_date: default_yr.start_date,
            end_date: default_yr.end_date,
            financial_institute_id: 0,
            has_next: false,
            has_previous: false,
            financial_institute_info: {},
            card_details: {},
            ledgerNameList : []
        }
    }

    componentDidMount() {
        this.props.dispatch(setAppilcationLoder(true));
        this.getFinancialInstituteList()
        this.getLedgerNameList();
        if(this.props.features_permissions_list.includes(FI_CREATE_CODE)){
            this.getBankNameList()
        }
    }
    componentWillReceiveProps(nextProps) {
        console.log("finacial institute ----------------",this.props , nextProps);
        if (this.props.location.pathname != nextProps.location.pathname){
            console.log("finacial institute true ----------------",this.props , nextProps);
            this.getFinancialInstituteList()
            this.getLedgerNameList();
            if(this.props.features_permissions_list.includes(FI_CREATE_CODE)){
                this.getBankNameList()
            }
        }
    }

    updateList = (card, action) => {
        console.log(card)
        let list = this.state.FIList
        if (action === 'update') {
            let index = this.state.FIList.findIndex(institute => (institute.financial_institute_id || institute.id) == (card.financial_institute_id || card.id))
            if (index !== -1) {
                list[index] = card
            } else {
                toast.warning("List not updated")
            }
        }
        if (action === "add") {
            list.push(card)
        }
        if (action === "delete") {
            console.log(card)
            let index = this.state.FIList.findIndex(institute => (institute.financial_institute_id || institute.id) == card)
            if (index !== -1) {
                list.splice(index, 1)
            } else {
                toast.warning("List not updated")
            }
        }
        this.setState({ FIList: list })
    }

    getBankNameList = () => {
        // FinancialInstituteService.getBankNameList().then(data => {
        //     if (data.response_code == 200) {
        //         data.result.push({bank_code: null, bank_name: "Any Other"})
        //         this.setState({ bank_name_list: data.result })
        //     }
        // })
        FinancialInstituteService.getSupportedBanks().then(data => {
            if (data.response_code == 200) {
                //data.result.push({bank_code: null, bank_name: "Any Other"})
                this.setState({ bank_name_list: [...data.result, {id: null, bank_name: "Any Other"}] })
            }
        })
    }

    getLedgerNameList = async () => {
		await MoneyLinkService.getLedgerNameList().then(data => {
			if (data.response_code == 200) {
				this.setState({ ledgerNameList: data.result })
			}
		})
	}

    getFinancialInstituteList = () => {
        
        FinancialInstituteService.getFinancialInstituteList().then(data => {
            if (data.response_code == 200) {
                this.setState({ FIList: data.result })
            }
            this.props.dispatch(setAppilcationLoder(false));
        })
    }

    getFinancialInstituteDetails = (id) => {
        let details = {
            "financial_institute_id": id
        }
        FinancialInstituteService.getFinancialInstituteDetails(details).then(
            (data) => {
                if (data.response_code == 200) { 
                    this.setState({
                        fi_details: data.result
                    });
                } else {
                    toast.error(data.message);
                }
        });

        FinancialInstituteService.getFIData(id).then(data => {
            if (data.response_code === 200) {
                let details = {
                    is_editable: data.result.is_editable,
                    is_credit_card: data.result.is_credit_card,
                    id: data.result.id
                }
                this.setState({
                    card_details: details
                });
            } else {
                toast.error(data.message);
            }
        })
    }

    onSortClick = (sort_order, sort_by) => {
        this.setState({
            sort_order,
            sort_by,
            page_no: 1
        }, () => this.getAccountStatementTransactions(this.state.financial_institute_id))
    }

    setPageNo = (page_no, financial_institute_id) => {
        this.setState({ page_no: page_no }, () =>
            this.getAccountStatementList(financial_institute_id))
    }

    refreshFIList = () => {
        this.getFinancialInstituteList()
    }

    refreshTransactionsList = (financial_institute_id) => {
        this.getAccountStatementTransactions(financial_institute_id)
    }

    getAccountStatementTransactions = (financial_institute_id, page_no = null, is_export = 0) => {
        if(page_no){
            this.setState({page_no: 1})
        } else {
            page_no = this.state.page_no
        }
        this.props.dispatch(setAppilcationLoder(true));
        this.setState({financial_institute_id: financial_institute_id})
        let transactions_data = {
            //"financial_institute_id":financial_institute_id,
            "id":financial_institute_id,
            "is_export":is_export,
            "per_page_items":this.state.per_page_items,
            "page_no":page_no,
            "sort_order": this.state.sort_order,
            "sort_by": this.state.sort_by,
            "date_filter": {
                "start_date": changeDateFormate(this.state.start_date),
                "end_date": changeDateFormate(this.state.end_date)
            }
        }

        let financial_institute_filter = {
            //"financial_institute_id":financial_institute_id,
            "id":financial_institute_id,
            "date_filter": {
                "start_date": changeDateFormate(this.state.start_date),
                "end_date": changeDateFormate(this.state.end_date)
            }
        }

        FinancialInstituteService.getFinancialInstituteInfo(financial_institute_filter).then(data => {
            if(data.response_code == 200){
                this.setState({
                    financial_institute_info: data.result,
                })
            } else{
                toast.error(data.message)
            }
        })

        FinancialInstituteService.getFinancialInstituteStatements(transactions_data).then(data => {
            //START - EXPORT LIST CODE
            if(is_export == 1){
                //check if data response is null i.e if bytelength is or less than 5
                if (data.byteLength > 5) {
                    var dataa = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(dataa);
                    let a = document.createElement('a');
                    a.href = url;
                    let entityName = toTitleCase(this.props.entity_name)
                    a.download = "Buktec_"+entityName+"_BANK_"+this.state.fi_details.bank_name+"_XX"+this.state.fi_details.account_number.slice(-4)+"_"+dateWithMonthName(this.state.start_date)+"-"+dateWithMonthName(this.state.end_date)+".xlsx";
                    a.click();
                } else {
                    toast.error("No statement found");
                }
            }else{
            //END - EXPORT LIST CODE
        
                if(data.response_code == 200){
                
                    this.setState({
                        transaction_list: data.result.transactions,
                        page_no: data.result.page_no,
                        per_page_items: data.result.per_page_items,
                        total_count: data.result.total_count,
                        total_pages: data.result.total_pages,
                        has_next: data.result.has_next,
                        has_previous: data.result.has_previous
                    })
                } else{
                    toast.error(data.message)
                }
            }
            this.props.dispatch(setAppilcationLoder(false));
        })
    }

    handlePageChange = (page) => {
        this.setState({
            page_no: page
        }, () => this.getAccountStatementTransactions(this.state.financial_institute_id))
    }

    financialYearChanged = (year_type, start_date, end_date) => {
        let financial_institute_id = this.state.financial_institute_id
        if (!(this.state.year_type == year_type && this.state.start_date == start_date, this.state.end_date == end_date)) {
          this.setState({
            year_type,
            start_date,
            end_date,
            page_no: 1
          }, () => {
            this.getAccountStatementTransactions(financial_institute_id)
          })
          let default_yr = StorageData.getFinancialYear();
          let name = default_yr ? default_yr.find(x => x.id == year_type).name : "";
          this.props.dispatch(setFinancialYearName(name));
          this.props.dispatch(setFilterDateRange(start_date, end_date));
        }
    }
    
    refreshTransactionsList = () => {
        this.getAccountStatementTransactions(this.state.financial_institute_id);
    }

    updateFinancialInstituteInfo = (response_status, errors, updated_at) => {
        let financial_institute_info = this.state.financial_institute_info;
        financial_institute_info.response_status = response_status;
        financial_institute_info.errors = errors;
        financial_institute_info.updated_at = updated_at;
        this.setState({financial_institute_info})
    }

    render() {
        return (
            <div>
                <ScrollPage />
                <Switch>
                    {(this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) 
                    || this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE)) &&
                        <Route exact path="/financial-institutes/view/:financial_institute_id?"
                            render={() => {
                                return <FIDetailPage yodleedata={this.state.yodleedata}
                                    getFinancialInstituteDetails={this.getFinancialInstituteDetails}
                                    fi_details={this.state.fi_details}
                                    card_details={this.state.card_details}
                                    FIList={this.state.FIList}
                                    account_statement_list={this.state.account_statement_list}
                                    onSortClick={this.onSortClick}
                                    //getAccountStatementList={this.getAccountStatementList}
                                    handlePageChange={this.handlePageChange}
                                    updateList={this.updateList}
                                    bank_name_list={this.state.bank_name_list}

                                    transaction_list={this.state.transaction_list}
                                    getAccountStatementTransactions={this.getAccountStatementTransactions}
                                    refreshTransactionsList={this.refreshTransactionsList}
                                    total_count={this.state.total_count}
                                    total_pages={this.state.total_pages}
                                    page_no={this.state.page_no}
                                    per_page_items={this.state.per_page_items}
                                    sort_by={this.state.sort_by}
                                    sort_order={this.state.sort_order}
                                    financialYearChanged={this.financialYearChanged}
                                    has_next={this.state.has_next}
                                    has_previous={this.state.has_previous}
                                    financial_institute_info={this.state.financial_institute_info}
                                    updateFinancialInstituteInfo={this.updateFinancialInstituteInfo}
                                    ledgerNameList = {this.state.ledgerNameList}
                                />
                            }}
                        />
                    }
                    <Route exact path="/financial-institutes"
                        render={() => {
                            return <FinancialInstitutesList
                                ledgerNameList = {this.state.ledgerNameList}
                                bank_name_list={this.state.bank_name_list}
                                FIList={this.state.FIList}
                                updateList={this.updateList}
                                refreshFIList={this.refreshFIList}
                                getFinancialInstituteDetails={this.getFinancialInstituteDetails}

                            />
                        }}
                    />
                    {(this.props.features_permissions_list.includes(FI_VIEW_ACCOUNT_STATEMENT_CODE)
                     || this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE)) &&
                        <Route exact path="/financial-institutes/view/:financial_institute_id/account-statements/:account_statement_id?"
                            render={() => {
                                return <AccountStatementDetailView
                                    fi_details={this.state.fi_details}
                                    account_statement_list={this.state.account_statement_list}    

                                    has_next={this.state.has_next}
                                    has_previous={this.state.has_previous}
                                    page_no={this.state.page_no}
                                    per_page_items={this.state.per_page_items}
                                    total_pages={this.state.total_pages}
                                    setPageNo={this.setPageNo}

                                    getFinancialInstituteDetails={this.getFinancialInstituteDetails}
                                    getAccountStatementList={this.getAccountStatementList}
                                    refreshAcountStatementList={this.refreshAcountStatementList}

                                />
                            }} />
                    }
                      <Redirect to="/dashboard" />
                </Switch>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const yodleedata = state.yodleedata || {};
    const all_value = state.Session || {};
    const features_permissions_list = all_value.features_permissions_list || [];
    const entity_name = all_value.default_entity_detail.name || {};
    return { yodleedata, features_permissions_list, entity_name };
}


export default connect(mapStateToProps)(FinancialInstitutes);
