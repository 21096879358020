import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import hdfc_bnk_fevi from '../../Assets/img/hdfc-fevicon.png';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
function accoutstatement(){
  const [sidfltr,setSidfltr]=React.useState(false)
  const [lgShow, setLgShow] =React.useState(false);
  const [creditcard, setCreditCard] =React.useState(false);

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold m-0 font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li className="">
                  <Dropdown>
                  <img src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded d-none">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Account Statements <span className="icon-Information green-icon green-icon-head"></span></h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-account-statment-sec">  
                  <div className="cross" onClick={handleShow}>
                    <span>X</span>
                  </div>
                  <div className="bank-detail-sec">
                     <div className="row">
                        <div className="col-lg-2 col-md-2 col-xs-12">
                          <img className="img-fluid mx-auto d-block" src={hdfc_bnk_fevi} />
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12">
                           <div className="row">
                              <div className="col-lg-6 col-xs-6">
                                <p className="ft-sz-18 s-sbold font-colr mb-0">Financial Institute</p>
                                <p className="ft-sz-18 s-sbold font-colr mb-0">Account Number</p>
                              </div>
                              <div className="col-lg-6 col-xs-6">
                              <p className="ft-sz-25 s-sbold blu-color mb-0">HDFC Bank</p>
                              <p className="ft-sz-18 s-sbold blu-color mb-0">3524599847548</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12">
                           <div className="row">
                              <div className="col-lg-6 col-xs-6">
                                <p className="ft-sz-18 s-sbold font-colr mb-0">Last Updated Date:</p>
                                <p className="ft-sz-18 s-sbold font-colr mb-0">Closing Balance</p>
                              </div>
                              <div className="col-lg-6 col-xs-6">
                              <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                              <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12">
                        <p><span className="ft-sz-18 s-sbold font-colr mr-4">Status:</span><span className="ft-sz-25 s-sbold blu-color">Active</span></p>
                        </div>
                     </div>
                     <div className="edit-sec"><span className="icon-edit"></span></div>
                  </div>  
                  <div className="main-yodlee-sec">
                    <div className="bank-img-sec">
                      <img className="img-fluid" src={bank_img} />
                    </div>
                    <p className="s-reg ft-sz-20 blu-color mb-0">Please enter HDFC Bank internet banking credencial</p>
                    <div className="row">
                      <div className="col-lg-4 col-xs-12">
                        <div className="yodlee-input-sec">
                          <div className="form-group">
                            <p className="lable-input font-colr">Username </p>
                            <input type="text" className="form-control"/>             
                          </div>
                          <div className="form-group">
                            <p className="lable-input font-colr">Password</p>
                            <input type="password" className="form-control"/>             
                          </div>
                        </div>
                        <div className="form-group yodlee-btn-sec mb-0">
                          <button type="button" className="yodlee-bottom-btn btn save-btn white-color s-bold">Submit</button> 
                          <button type="button" className="yodlee-bottom-btn btn save-btn white-color s-bold drk-gry-bg">Back</button>
                        </div>
                        <p className="ft-sz-20 s-reg font-color yodlee-or-sec">OR</p>
                        <div className="form-group yodlee-btn-sec">
                          <button type="file" className="yodlee-upload-btn btn save-btn s-sbold font-colr">Upload account statement</button>  
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-evidence-sms-table-sec add-statemant-sec">
                    <div className="toggle-search-sec clearfix">
                      <div className="search-filter-sec pull-right">
                        <button className="add-new-btn btn ft-sz-20 white-color s-bold">
                          {/* <span className="icon-plus1"></span>  */}
                          Upload PDF
                        </button>
                      </div>
                    </div>
                     <div className="main-table-sec">
                           <div className="not-create-sec d-none"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>
                           <Table bordered responsive className="">
                           <thead>
                              <tr>
                              <th>Upload Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Filename <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Start Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>End Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Opening Balance <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Closing Balance <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>No. Of Records <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Synched</Tooltip>}>
                                  <span className="icon-td icon-Group-3275 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                                  <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold sky-blu-color">Ready</Tooltip>}>
                                  <span className="icon-td icon-Group-25071 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Linked</Tooltip>}>
                                  <span className="icon-td icon-Group-2510 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Not Ready</Tooltip>}>
                                  <span className="icon-td icon-Group-2509 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                                  <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                                  <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td>15-10-2020</td>
                                <td>Satement12-10-2020 #11248 </td>
                                <td>1-8-2020</td>
                                <td>31-9-2020</td>
                                <td className="text-center">₹ 250</td>
                                <td className="text-center">₹ 550 </td>
                                <td className="text-center">34 </td>
                                <td>
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                                  <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                           </tbody>
                           </Table>
                     </div>

                    <div className="main-pagination clearfix">
                        <nav aria-label="Page navigation example">
                        <ul className="pagination pull-right mb-0">
                            <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                            <li className="page-item item-count"><a className="page-link ft-sz-20 s-reg" href="#">2</a></li>
                            {/* <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li> */}
                            <li className="page-item prev-sec"><a className="page-link ft-sz-20 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                            <li className="page-item active"><a className="page-link ft-sz-20 s-reg" href="#">1</a></li>
                            <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">2</a></li>
                            <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">3</a></li>
                            <li className="page-item next-sec"><a className="page-link ft-sz-20 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                        </ul>
                        </nav>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

           {/* Edit view  */}
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
              <div className="clearfix">
                <ol className="breadcrumb pull-left">
                  <li className="breadcrumb-item">
                  <Link to={'/home'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item"><Link to='/financial-institutes'>Banking</Link></li>
                  {/* <li className="breadcrumb-item">Financial Institute</li> */}
                </ol>
              </div>
              <hr/>

                <div className="recipt-sidebar-sec fi-account-sidebar">
                  <div className="recipt-inner-div invoice-side-list empty-div active">
                    <div className=""></div>
                    <div className="row">
                     <p className="m-0 ft-sz-28 font-colr s-sbold">New Account Statement</p>
                    </div>
                  </div>
                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>
                
                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-2509"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>

                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>

                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>

                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>
                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>
                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>
                  <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                      <div className="col-xl-9 col-md-9 col-xs-12">
                          <p className="ft-sz-17 s-sbold blu-color mb-0">Satement12-10-2020</p>
                          <p className="ft-sz-17 s-sbold blu-color">#11248</p>
                      </div>
                      <div className="col-xl-3 col-md-3 col-xs-12">
                          <div className="evi-icon">
                          <div className="color-icon">
                          <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">Start Date</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <p className="ft-sz-13 s-sbold font-colr mb-0">No. Records</p>
                          <p className="ft-sz-17 s-sbold blu-color mb-0">1-8-2020</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="main-pagination clearfix sidebar-pagination d-none">
                  <nav aria-label="Page navigation example">
                    {/* <div className="per-page-sec clearfix">
                      <ul className="pagination pull-right mb-0">
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                        <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                      </ul>
                    </div> */}
                    <ul className="pagination pull-right mb-0">  
                      <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                      <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                      <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                      <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                      <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
              <div className="inner-content-sec">
              <p className="head m-0 s-sbold blu-color">Account Statement <OverlayTrigger placement={'right'} overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
              <span className="icon-Information green-icon help"></span></OverlayTrigger></p>
                <div className="clearfix"></div>
                <div className="bank-detail-sec">
                  <div className="row">
                    <div className="col-lg-2 col-md-2 col-xs-12">
                      <img className="img-fluid mx-auto d-block" src={hdfc_bnk_fevi} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12">
                        <div className="row">
                          <div className="col-lg-6 col-xs-6">
                            <p className="ft-sz-18 s-sbold font-colr mb-0">Financial Institute</p>
                            <p className="ft-sz-18 s-sbold font-colr mb-0">Account Number</p>
                          </div>
                          <div className="col-lg-6 col-xs-6">
                          <p className="ft-sz-25 s-sbold blu-color mb-0">HDFC Bank</p>
                          <p className="ft-sz-18 s-sbold blu-color mb-0">3524599847548</p>
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12">
                        <div className="row">
                          <div className="col-lg-6 col-xs-6">
                            <p className="ft-sz-18 s-sbold font-colr mb-0">Last Updated Date:</p>
                            <p className="ft-sz-18 s-sbold font-colr mb-0">Closing Balance</p>
                          </div>
                          <div className="col-lg-6 col-xs-6">
                          <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                          <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-xs-12">
                    <p><span className="ft-sz-18 s-sbold font-colr mr-4">Status:</span><span className="ft-sz-25 s-sbold blu-color">Active</span></p>
                    </div>
                  </div>
                  <div className="edit-sec"><span className="icon-edit"></span></div>
                </div>
              <div className="main-file-item-sec">
                  <div className="cross" onClick={handleShow}>
                  <span>X</span>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div className="main-file-state-upload-sec">
                        <div className="inner-file-state-upload-sec">
                          <p className="ft-sz-18 s-reg font-colr mb-0">No file on record data retrieved using real-time access. </p>
                        </div>
                        <p className="ft-sz-15 s-reg font-colr mb-0 mt-3 text-center d-none">Click on file to view</p>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                      <div className="inner-summery-sec">
                        <div className="head-sec clearfix">
                          <p className="ft-sz-22 s-sbold blu-color pull-left">Summary</p>
                          {/* <p className="ft-sz-15 s-reg font-colr pull-right">Link Status : </p> */}
                          <p className="lable-input font-colr pull-right">Link Status : </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Upload Date</p>
                              <input type="email" className="form-control input-bg-gray" value="15-10-2020"/>             
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-8 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Filename</p>
                              <input type="email" className="form-control input-bg-gray" value="Satement12-10-2020 #11248 "/>             
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Start Date</p>
                              <input type="email" className="form-control input-bg-gray" value="1-8-2020"/>             
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">End Date</p>
                              <input type="email" className="form-control input-bg-gray" value="31-9-2020"/>             
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">No. Of Record</p>
                              <input type="email" className="form-control input-bg-gray" value="34"/>             
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Opening Balance</p>
                              <input type="email" className="form-control input-bg-gray text-right" value="86,668"/>             
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-xs-12">
                            <div className="form-group">
                              <p className="lable-input font-colr">Closing Balance</p>
                              <input type="email" className="form-control input-bg-gray text-right" value="1,15,684"/>             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-account-statement-table">
                    <p className="ft-sz-22 s-sbold blu-color">Account Statement</p>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>Date</td>
                          <td>Activity</td>
                          <td>Source / Destination </td>
                          <td>Wallet TAX ID</td>
                          <td>Comment</td>
                          <td>Debit </td>
                          <td>Credit</td>
                          <td>Transaction Breakup</td>
                          <td>Status</td>
                        </tr>
                        <tr>
                          <td>15-10-2020</td>
                          <td>Added to wallet</td>
                          <td>Paytm order #12454822214</td>
                          <td>1515154222</td>
                          <td></td>
                          <td> </td>
                          <td>550</td>
                          <td></td>
                          <td>Success</td>
                        </tr>
                        <tr>
                          <td>15-10-2020</td>
                          <td>Added to wallet</td>
                          <td>Paytm order #12454822214</td>
                          <td>1515154222</td>
                          <td></td>
                          <td> </td>
                          <td>550</td>
                          <td></td>
                          <td>Success</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
              </div>
              <div className="bottom-save-btn clearfix">
                <div className="pull-left">
                  <button type="button" className="btn save-btn graybtn mt-1">Delete </button>
                </div>
                <div className="pull-right">
                  <button type="button" className="btn save-btn greenbtn">Save</button>
                </div>
              </div>

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                    <span className="icon-error icon-Group-2598"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Error in file</p> 
                      <p className="ft-sz-18 s-reg font-colr">Error in uploaded file, Please try again. </p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">OK</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i className="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>
                

                <Modal
                  show={clog}
                  onHide={logClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="changelog-model-sec"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color"><span className="icon-round-info-button mr-4"></span> Change Log</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="clearfix">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Created</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Changed to Reimbursement</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Approved</td>
                        <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                      </tr>
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>

            </div>
          </div>
          </div>
         
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

    
}











export default accoutstatement;