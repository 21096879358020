import React, { Component } from "react";
import { Link,Redirect } from 'react-router-dom';
import './ErrorPage.css';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { login } from "../../Redux/Actions/Auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../../Services/AuthService";
import { setAppilcationLoder } from "../../Redux/Actions/App";

import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';
import {RECAPTCHA_SITE_KEY} from '../../Utils/Config';
import SessionManagement from "../../Utils/SessionManagement";

class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {

    return (
        <div id="main">
                <div class="fof">
                        <h1>Error 404</h1>
                        <h4>click here to <Link className="s-bold blu-color" to="/login">Sign in</Link></h4>
                        <h4>click here to <Link className="s-bold blu-color" to="/register">Register</Link></h4>
                </div>
        </div>
   );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(ErrorPage);