import React from 'react'
import { displayDateFormate } from '../../Helpers/DateFormat'
import { ArrowUpDownIcon } from '../../Views/IcoonNew'
import { showAmount } from '../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class SqueezedCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <>
                <div key={this.props.index} id={"div" + this.props.id}
                    className={"sidebar-cards cpointer" + (this.props.card_id == this.props.id ? " active " : "")}
                    onClick={() => this.props.selectedCard(this.props.id)}>
                    <div className="cards-sec-center">
                        <p className="datesc mr-auto ellipsisc">ID #{this.props.id}</p>
                        {/* {this.dataFormatStatus(snapshot.status)} */}
                        {this.props.status}
                    </div>
                    <div className="cards-sec-center">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.narration}</Tooltip>}>
                            <p className="titlesc titlesc-2 ellipsisc">{this.props.name}</p>
                        </OverlayTrigger>
                    </div>
                    <div className="cards-sec-center">
                        <p className="flex-6 datesc text-left">{displayDateFormate(this.props.date)}</p>

                        <div className="flex-4 amountsc">
                            <span className="ellipsisc">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className={this.props.type === 'CR' ? "green-color" : "red-color"}>{showAmount(this.props.amount)}</span></Tooltip>}>
                                        <span className={this.props.type === 'CR' ? "green-color" : "red-color"}>{showAmount(this.props.amount)}</span>
                                </OverlayTrigger>
                            </span>
                            <ArrowUpDownIcon expense={this.props.type === 'CR' ? false : true}/> 
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SqueezedCard;