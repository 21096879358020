import React from 'react'
import { Button, Modal } from 'react-bootstrap';


class MessageModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={() => this.props.toggleMessageModal()}
                backdrop="static"
                keyboard={false}
                centered
                className="popup-model error-popup"
            >
                <Modal.Body className="text-center">
                    <p className="pmsg-blue-center">{this.props.title}</p>
                    <p className="pmsg-text pmsg-gray-center">{this.props.content}</p>

                    <Button className="success apply_btn_new" onClick={() => this.props.toggleMessageModal()}>Okay</Button>
                </Modal.Body>
            </Modal>
        )
    }
}

export default MessageModal;