import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { displayDateFormate } from '../../../Helpers/DateFormat';
import LicenseService from '../../../Services/Licenses/LicenseService';
import { toast } from 'react-toastify';
import DeletePopUp from '../../../Components/BoilerPlate/DeletePopUp';
import { Button } from 'react-bootstrap';

class OrderHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            show_delete: false,
            delete_loading: false,
            delete_order_id: null
        }
    }

    componentDidMount() {
        this.getOrdersList();
    }

    getOrdersList = () => {
        this.props.dispatch(setAppilcationLoder(true));
        LicenseService.getOrderList()
            .then(data => {
                if (data.response_code === 200) {
                    this.setState({ orders: data.result.reverse() })
                } else {
                    toast.error(data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    getCaret = (direction) => {
        switch (direction) {
            case 'asc':
                return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
            case 'desc':
                return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
            default:
                return (<span className="icon-Group-4449 sort-both-icon"></span>);
        }
    }

    downloadInvoice = (row) => {

        const link = document.createElement('a');
        link.href = row.invoice_report;
        link.setAttribute(
            'download',
            `Invoice.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    dataFormatAmount = (cell) => {
        return '₹' + cell;
    }

    dataFormatAction = (cell, row) => {
        // if(row.status === null || row.status === "processing"){
        //     return "In Process";
        // } else if (row.is_paid === 0) {
        //     return <button className="retry-downl-btn btn white-color s-bold red-bg" onClick={() => this.goToOrderSummaryPage(row)}>Retry Payament</button>;
        // } else {
        //     return <button onClick={() => this.downloadInvoice(row)} className="retry-downl-btn btn white-color s-bold">Download Invoice</button>;
        // }
        if (row.status === "captured") {
            // return <button onClick={() => this.downloadInvoice(row)} className="retry-downl-btn btn white-color s-bold">Download Invoice</button>;
            return <div className="td-green-btn" onClick={() => this.downloadInvoice(row)}>Download Invoice</div>
        } else {
            return <span className="text-capitalize">{row.status}</span>;
        }
    }

    dataFormatDeleteButton = (cell, row) => {
        if (row.is_paid === 0 && (row.status !== null && row.status !== "processing")) {
            return <span className="icon-delete" onClick={() => this.setState({ show_delete: true, delete_order_id: row.order_id })}></span>
        }
    }

    displayOrderIdFormat = (cell) => {
        return cell;
    }

    displayTypeFormat = (cell) => {
        if (cell != null) {
            return cell.charAt(0).toUpperCase() + cell.slice(1);
        }
    }

    goToLicenseListingPage = () => {
        this.props.history.push("/licenses");
    }

    goToOrderSummaryPage = (data) => {
        this.props.history.push({
            pathname: '/licenses/order-summary',
            state: { order_id: data.order_id, type: data.type, is_retry: 1, order_data: data }
        })
    }

    deleteOrder = (data) => {
        //this.props.dispatch(setAppilcationLoder(true));
        LicenseService.deleteOrder(this.state.delete_order_id)
            .then(data => {

                if (data.response_code === 200) {

                    this.setState({ delete_loading: false })

                    if (data.message == "Order Deleted") {
                        toast.success(data.message);
                        this.getOrdersList();
                    }
                    this.toggleDeletePopUp();


                } else {
                    toast.error(data.message);
                    this.setState({
                        delete_loading: false
                    });
                }
            })
    }

    toggleDeletePopUp = () => {
        this.setState({ show_delete: !this.state.show_delete })
    }

    goToProfileManagement = () => {
        this.props.history.push("/profile-management/entities/");
    }

    render() {

        const options = {
            noDataText: 'No items to display',
        };

        return (
            <main className="content-wrapper-new">
                <div className="container-fluid container-padding-new">

                    {/* Breadcrumb */}
                    <div className="row">
                        <div className="col-12">
                            <div className="main-sub-header-new">
                                <div className="breadcrumb-header-new">
                                    <div className="row">
                                        <div className="col-12">
                                            {/* <BreadCrum level={3} /> */}
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item-new">
                                                    <Link to={'/home'}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item-new" onClick={this.goToProfileManagement}>Profile Management</li>
                                                <li className="breadcrumb-item-new" onClick={this.goToLicenseListingPage}>My Licenses</li>
                                                <li className="breadcrumb-item-new">Order History</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <h2 className="breadcrumb-title-new">Order History</h2>
                                        </div>
                                        <div className="col-4">
                                            <h2 className="breadcrumb-title-new sub-header-cicon text-right">
                                                <button class="btn ico-refresh-sec" onClick={() => this.getOrdersList()}><span class="icon-refresh"></span></button>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrumb */}

                    {/* Table */}
                    <div className="row main-table-listing listing-icons sec-mb">
                        <div className="col-12">
                            <div className="table-sec pointer-none mb-0">
                                <BootstrapTable
                                    ref='table'
                                    data={this.state.orders}
                                    table-bordered
                                    options={options}
                                    responsive
                                >
                                    <TableHeaderColumn width="110px" isKey={true} headerAlign='center' dataAlign='center' dataField='order_id' dataSort={true} caretRender={this.getCaret} dataFormat={(cell, row) => this.displayOrderIdFormat(cell)}>
                                        Order ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="130px" headerAlign='center' dataAlign='center' dataField='order_date' dataFormat={(cell, row) => displayDateFormate(cell)} dataSort={true} caretRender={this.getCaret}>
                                        Order Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn thStyle={{ minWidth: '35%' }} tdStyle={{ minWidth: '35%' }} headerAlign='left' dataAlign='left' dataField='bill_to' dataSort={true} caretRender={this.getCaret}>
                                        Bill to
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="100px" headerAlign='center' dataAlign='center' dataField='smart' dataSort={true} caretRender={this.getCaret}>
                                        Smart
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="100px" headerAlign='center' dataAlign='center' dataField='superb' dataSort={true} caretRender={this.getCaret}>
                                        Superb
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn width="100px" headerAlign='center' dataAlign='center' dataField='professional' dataSort={true} caretRender={this.getCaret}>
                                                        Uiltimate  
                                                    </TableHeaderColumn> */}
                                    <TableHeaderColumn width="160px" headerAlign='right' dataAlign='right' dataFormat={(cell) => this.dataFormatAmount(cell)} dataField='amount' dataSort={true} caretRender={this.getCaret}>
                                        Amount
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="100px" headerAlign='left' dataAlign='left' dataField='type' dataSort={true} caretRender={this.getCaret} dataFormat={(cell, row) => this.displayTypeFormat(cell)}>
                                        Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="180px" tdStyle={{ overflow: 'inherit', whiteSpace: 'initial', textOverflow: 'inherit' }} headerAlign='center' dataAlign='center' dataFormat={this.dataFormatAction} field_name="status">
                                        Action
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="50px" tdStyle={{ textAlign: 'right' }} dataFormat={(cell, row) => this.dataFormatDeleteButton(cell, row)}>
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                    {/* End Table */}

                    {/* Back Button */}
                    <div className="row">
                        <div className="col-12">
                            <Button variant="success apply_btn_new" type="button" onClick={this.goToLicenseListingPage}>Back</Button>
                        </div>
                    </div>
                    {/* End Back Button */}
                </div>
            </main>
        )
    }
}

export default connect()(withRouter(OrderHistory));    