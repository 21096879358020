import React from 'react'
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import { Button, Modal } from 'react-bootstrap';

const ConfirmModal = ({show, proceed, confirmation, options}) => (
//   <Dialog onHide={() => proceed(false)} show={show}>
//     {confirmation}
//     <button onClick={() => proceed(false)}>CANCEL</button>
//     <button onClick={() => proceed(true)}>OK</button>
//   </Dialog>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        // className="save-model-sec er-pop"
        className="popup-model error-popup"
      >

        {/* <Modal.Body className="clearfix ">
          <p className="popup-blue-text text-center">
            Alert
          </p>
          <p className="popup-gray-text text-center">
            {confirmation}
          </p>
        </Modal.Body>
        
        <Modal.Footer className='bottom-save-btn'>
          <Button onClick={() => proceed(false)} variant="" className={"btn model-save-btn save-btn graybtn " + (!!options && options.type == "alert" ? " d-none " : "")}>No</Button>
          <Button onClick={() => proceed(true)} variant="" className="btn save-btn greenbtn model-save-btn">{(!!options && options.type == "alert" ? "OK" : "Yes")}</Button>
        </Modal.Footer> */}


        <Modal.Body className="clearfix ">           
            <p className="pmsg-blue-center">Alert</p>
            <p className="pmsg-text pmsg-gray-center">{confirmation}</p>          

            <div className="center_apply_btn_new">
                <Button 
                // variant="outline-success reset_btn_new"  
                variant={"outline-success reset_btn_new " + (!!options && options.type == "alert" ? " d-none " : "")}
                onClick={() => proceed(false)}>No</Button>
                <Button 
                    variant="success apply_btn_new" 
                    // variant={"success apply_btn_new " + (!!options && options.type == "alert" ? " d-none " : "")}
                    onClick={() => proceed(true)}
                >{(!!options && options.type == "alert" ? "OK" : "Yes")}</Button>
            </div>
        </Modal.Body>


      </Modal>
)

ConfirmModal.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmModal);