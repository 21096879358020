import React from 'react';
import { connect } from "react-redux";
import { setTaskTempData } from "../../Redux/Actions/TaskManager";
import CreateTaskSideList from './CreateTaskSideList';
import CreateTaskForm from './CreateTaskForm';

import {
	TheHeaderNew,
	TheSidebarNew,  
} from '../Containers/index';

class CreateTask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task_detail: []
        }
    }
    componentDidMount(){
        this.props.dispatch(setTaskTempData({}))
    }
    componentWillUnmount() {
		this.setState({task_detail: []})
	}
    render() {
        return (<>
            {/* <TheHeaderNew />
          	<TheSidebarNew /> */}
            <main className="content-wrapper-inner-new">
                <div className="container-fluid container-padding-new">
                    <div className="row">
                        <CreateTaskSideList
                            isSearchApplied={this.props.isSearchApplied}   //-----> Ram
                            changeSearchStatus={this.props.changeSearchStatus}
                            searchText={this.props.searchText}
                            searchFilter={this.props.searchFilter}
                            filter_by={this.props.filter_by}
                            changeSearchText={this.props.changeSearchText}       //-----> Ram
                            task_list={this.props.task_list}
                            applyFilter={this.props.applyFilter}
                            assigned_by_arr={this.props.assigned_by_arr}
                            assigned_to_arr={this.props.assigned_to_arr}
                            has_next={this.props.has_next}
                            has_previous={this.props.has_previous}
                            per_page_items={this.props.per_page_items}
                            page_no={this.props.page_no}
                            total_pages={this.props.total_pages}
                            task_detail={this.state.task_detail}
                            filter_card={this.props.filter_card}
                        />
                        <CreateTaskForm
                            newTask={this.props.newTask}
                            updateTaskDetail={(data) => {
								console.log(data)
								this.setState({task_detail: data})
							}}
                        />
                    </div>
                </div>
            </main>
            </>
        )
    }
}

function mapStateToProps(state) {
    const { filter_data } = state.TaskManager;
    return {
        filter_data,
    };
}

export default connect(mapStateToProps)(CreateTask);