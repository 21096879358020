import React from "react";
import { OverlayTrigger,Tooltip } from "react-bootstrap";

function LowPriority(props){
    return <>
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Low Priority</Tooltip>}>
    <span className="icon-exclamation-red-sign gray-white-icon icon-dt-td">
				<span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
    </OverlayTrigger>
</>

}

export default LowPriority;