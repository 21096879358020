import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function OverdueStatus(props) {
    return <div  >
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Overdue</Tooltip>}>
            {/* <span className="icon-td icon-icon-clock ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span> */}

            <span className="icon-clocki icon-td ft-sz-38"></span>
        </OverlayTrigger>
    </div>
}
export default OverdueStatus;