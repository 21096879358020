import React, { useState, useEffect } from 'react'
import DoughnutChart from '../../../Views/ui/component/DoughnutChart'
import Form from 'react-bootstrap/Form';
import DoughnutCharts1 from '../../Dashboard/DoughnutCharts/DoughnutCharts1';
import { Doughnut } from 'react-chartjs-2';
import { showRoundOffAmount } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const LicensesBoard = (props) => {

    let self = [];
    let client = []
    let selfcount = []
    let clientcount = []
    let amount = []
    let count = []
    let data = []
    //var label = []
    let license_data = []
    let license_amount = []
    let license_total_amount = 0
    const [selected, setSelected] = useState([])
    let [selectedLicense, setSelectedLicense] = useState([])
    const [licenseAmt, setLicenseAmt] = useState([])
    const [licenseTotalAmt, setLicenseTotalAmt] = useState([])
    const [label, setLabel] = useState([])
    const [type, setType] = useState([])

    const handleChange = (e) => {
        const { value, name } = e.target;
        setSelected(value)

        for (var i in props.data.licenses_status) {
            if (props.data.licenses_status[i].type == value) {
                license_data = props.data.licenses_status[i];
                setSelectedLicense(license_data)

                license_total_amount = 0;
                setLabel([])
                license_amount = []

                license_data.licenses_arr.map((item) => {
                    license_total_amount = license_total_amount + item.amount
                    label.push(item.license)
                    license_amount.push(item.amount)
                })
                setLicenseAmt(license_amount)
                setLicenseTotalAmt(license_total_amount)
                setLabel(label)
            }
        }
    }

    useEffect(() => {
        setSelected(1)
        let type = []
        for (var i in props.data.licenses_status) {
            type.push(props.data.licenses_status[i].type)
        }
        setType(type)

        for (var i in props.data.licenses_status) {
            if (props.data.licenses_status[i].type == Math.min.apply(Math, type)) {
                license_data = props.data.licenses_status[i];
                setSelectedLicense(license_data)
            }
        }



        if (license_data.length != 0) {
            license_data.licenses_arr.map((item) => {
                license_total_amount = license_total_amount + item.amount
                label.push(item.license)
                license_amount.push(item.amount)
                //console.log(label)
            })
            setLabel(label)
            setLicenseAmt(license_amount)
            setLicenseTotalAmt(license_total_amount)
        }
    }, []);




    for (var i in props.data.licenses_paid) {
        data.push(props.data.licenses_paid[i]);
    }

    data.map((item) => {
        amount.push(item.amount)
        count.push(item.count)
        //   label.push(item.license)
        if (item.type == 1) {
            self.push(item.amount)
            selfcount.push(item.count)
        } else {
            client.push(item.amount)
            clientcount.push(item.count)
        }
        //console.log(selfcount, clientcount)
    })

    const plugins = [{
        legend: false,
        beforeDraw: function (chart) {
            let x = count.reduce((partial_sum, a) => partial_sum + a, 0)
            var width = chart.width,
                height = chart.height,

                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 160).toFixed(2);
            ctx.font = fontSize + "em sans-serif";
            ctx.textBaseline = "top";

            var text = x,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.fillText('#', textX, textY - 18);
            ctx.save();
        }
    }]

    const options = {
        plugins: {
            legend: false
        }
    }

    let smart_count = 0;
    let superb_count = 0;

    if (selectedLicense.length != 0) {
        selectedLicense.licenses_arr.map((item) => {
            if (item.license_id == 2)
                smart_count = item.amount;
        })

        selectedLicense.licenses_arr.map((item) => {
            if (item.license_id == 3)
                superb_count = item.amount
        })
    }
    //console.log(smart_count)

    return (
        <div className="chart-box-new">
            <div className="row">
                <div className="col-12">
                    <h4 className="chart-box-heading">Licenses</h4>
                </div>
                <div className="col-xl-6 col-lg-12">
                    {!props.data.licenses_status.length ?
                        <p className="ml-1">Data not available</p>
                        :
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="chart-div">
                                    <Doughnut data={{
                                        labels: label,
                                        datasets: [
                                            {
                                                data: licenseAmt,
                                                backgroundColor: [
                                                    props.color2_background,
                                                    props.color1_background,
                                                    props.color3_background
                                                ],
                                            },
                                        ],
                                    }} plugins={plugins} options={options} />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 pl-0">
                                <div className="chart-content">
                                    <div className="license-new-dropdown w-50 mt-2">
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            {/* className="gray-bg" */}
                                            <Form.Control  multiple={false} as="select" value={selected} onChange={(e) => handleChange(e)}>
                                                {type.includes(1) && <option value="1">Purchased</option>}
                                                {type.includes(2) && <option value="2">Unused</option>}
                                                {type.includes(3) && <option value="3">Blocked</option>}
                                                {type.includes(4) && <option value="4">Allocated</option>}
                                            </Form.Control>
                                        </Form.Group>
                                    </div>

                                    <div class="row mt-3">
                                        <div className="col-lg-12">
                                            <p className="chart-lable">Total</p>
                                            <p className="chart-cp">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(licenseTotalAmt)}</Tooltip>}>
                                                    <span className='chart-price'>{showRoundOffAmount(licenseTotalAmt)}</span>
                                                </OverlayTrigger>
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color2_background }}></span> Smart</p>
                                            <p className="chart-cp">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(smart_count)}</Tooltip>}>
                                                    <span className='chart-price'>{showRoundOffAmount(smart_count)} </span>
                                                </OverlayTrigger>
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p className="chart-lable"><span className="chart-tag-color" style={{ background: props.color1_background }}></span> Superb</p>
                                            <p className="chart-cp">
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(superb_count)}</Tooltip>}>
                                                    <span className='chart-price'>{showRoundOffAmount(superb_count)}</span>
                                                </OverlayTrigger>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="col-xl-6 col-lg-12 mt-xl-0 mt-3">
                    <div className="license-dash ">
                        <h4 className="chart-box-heading">License Paid By</h4>
                        <div className="row no-gutters invitesc">
                            <div className="col-6 green-bg invitesc-card">
                                <p>Self</p>
                                <p>{selfcount.length > 0 ? selfcount : 0}</p>
                            </div>
                            <div className="col-6 orange-bg invitesc-card">
                                <p>Client</p>
                                <p>{clientcount.length > 0 ? clientcount : 0}</p>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-6">
                                <p className="license-count">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{self.length > 0 ? showRoundOffAmount(self) : showRoundOffAmount(0)}</Tooltip>}>
                                        <span>{self.length > 0 ? showRoundOffAmount(self) : showRoundOffAmount(0)}</span>
                                    </OverlayTrigger>
                                </p>
                            </div>
                            <div class="col-6 text-right">
                                <p className="license-count">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(client)}</Tooltip>}>
                                        <span>{showRoundOffAmount(client)}</span>
                                    </OverlayTrigger>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end row */}
        </div>
    )
}

export default LicensesBoard 