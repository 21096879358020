import axios from 'axios';
import StorageData from '../Helpers/StorageData';
import SessionManagement from '../Utils/SessionManagement';
import { API_URL } from '../Utils/Config';
import { confirm } from '../Components/BoilerPlate/Confirm';

// For requests
/*axios.interceptors.request.use(
   (req) => {
      // Add configurations here
      return req;
   },
   (err) => {
      return Promise.reject(err);
   }
);*/

// For response
axios.interceptors.response.use(
   async (res) => {
      //console.log(res)
      let originalRequest = res.config;

      try {
         let arrayBufferConverted = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(res.data)));
         console.log(arrayBufferConverted)
         if(arrayBufferConverted.response_code === 401) {
            res.data.response_code = arrayBufferConverted.response_code;
         }
      } catch {

      }
      //console.log(res.data.response_code)
      // Prevent infinite loops and logout user
      if (res.data.response_code === 401 && originalRequest.url === API_URL + 'access-token') {
         logout();
         return Promise.reject(error);
      }

      // Refresh token logic
      if (res.data.response_code === 401) {
         const refreshToken = SessionManagement.getRefreshToken();
         if (refreshToken) {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

            // exp date in token is expressed in seconds, while now() returns milliseconds:
            const now = Math.ceil(Date.now() / 1000);

            if (tokenParts.exp > now) {
               return axios
                  .post(API_URL+'access-token', { refresh_token: refreshToken })
                  .then(async (response) => {
                     //Set new token and refresh token
                     await SessionManagement.setAccessToken(response.data.result.access_token);
                     await SessionManagement.setRefreshToken(response.data.result.refresh_token);

                     //set new token in header
                     axios.defaults.headers['Authorization'] = response.data.result.access_token;
                     originalRequest.headers['Authorization'] = response.data.result.access_token;

                     return axios(originalRequest);
                  })
                  .catch(err => {
                     console.log(err)
                  });
            } else {
               console.log("Refresh token is expired", tokenParts.exp, now);
               logout();
               // window.location.href = '/login/';
            }
         } else {
            console.log("Refresh token not available.")
            logout();
            // window.location.href = '/login/';
         }

      }

      // Unauthorised user : User Rights remove from user
      if (res.data.response_code === 403) {
         /*await confirm({
           confirmation: 'Your do not have rights to accesss this url?',
           options : {type : "alert"}
         });*/
         // alert(originalRequest.url);
         window.location.href = '/';
      }
      return res;
   },
   (err) => {
      return Promise.reject(err);
   }
);

function logout(){
   SessionManagement.RemoveAll();
   StorageData.deleteAllStorageValue();
   window.location.href = '/login/';
   window.location.reload();
}

/*

const axiosInstance = axios.create({
   // baseURL: baseURL,
   timeout: 5000,
   // headers: {
   //    'Authorization': localStorage.getItem('access_token') ? "JWT " + localStorage.getItem('access_token') : null,
   //    'Content-Type': 'application/json',
   //    'accept': 'application/json'
   // }
});

axiosInstance.interceptors.response.use(
   response => {
      response;
      // alert("areee response");
   },
   error => {
      // alert("areee error");
      const originalRequest = error.config;

      // Prevent infinite loops
      if (error.response.status === 401 && originalRequest.url === API_URL + 'access-token') {
         window.location.href = '/login/';
         return Promise.reject(error);
      }

      if (error.response.data.code === "token_not_valid" &&
         error.response.status === 401 &&
         error.response.statusText === "Unauthorized") {
         const refreshToken = SessionManagement.getRefreshToken(); //localStorage.getItem('refresh_token');

         if (refreshToken) {
            const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

            // exp date in token is expressed in seconds, while now() returns milliseconds:
            const now = Math.ceil(Date.now() / 1000);
            console.log(tokenParts.exp);

            if (tokenParts.exp > now) {
               return axiosInstance
                  .post('access-token', { refresh_token: refreshToken })
                  .then((response) => {

                     localStorage.setItem('access_token', response.data.access);
                     localStorage.setItem('refresh_token', response.data.refresh);

                     axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                     originalRequest.headers['Authorization'] = "JWT " + response.data.access;

                     return axiosInstance(originalRequest);
                  })
                  .catch(err => {
                     console.log(err)
                  });
            } else {
               console.log("Refresh token is expired", tokenParts.exp, now);
               window.location.href = '/login/';
            }
         } else {
            console.log("Refresh token not available.")
            window.location.href = '/login/';
         }
      }


      // specific error handling done elsewhere
      return Promise.reject(error);
   }
);*/