import React from 'react';

class NumberTypeInputBox extends React.Component {
    number_regex = /^[0-9\b]+$/;
    number_regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        let { value, name } = e.target;
        if(value != "00" && (value == "" || this.number_regex.test(value))){
            if(!!this.props.is_amount){
                e.target.value = value == "" ? value : value;
            }
            this.props.onChange(e)
        }
    }

    render() {
        return (<React.Fragment>
            <input {...this.props} type="text" onChange={this.handleChange}/>
        </React.Fragment>)
    }
}

export default NumberTypeInputBox;