import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { setEntityListData, setSelectedEntities, setmoduleList, setDefaultRoles } from "../../../Redux/Actions/Users";
import PermissionComponent from './PermissionComponent';

class ModuleComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      module_checked: this.props.module.permissions.filter(perm => !perm.checked),
      module_nmae: this.props.module_nmae,
      module_index: this.props.module_index,
      selected_entity_index: this.props.selected_entity_index
    }
  }

  componentDidMount() {
  }

  handlemoduleChange = (e, module, module_index, selected_entity_index) => {
    const checked = e.target.checked;
    this.props.module.permissions.map((permission, perm_index) => {
      this.props.handlePermissionChange(checked, perm_index, this.state.module_index);
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="sup-cust-check sup-cust-check-md">
              <input
                className="styled-checkbox"
                type="checkbox"
                value={this.state.selected_entity_index}
                checked={this.state.module_checked.length == 0}
                onChange={(e) => { this.handlemoduleChange(e, this.props.module, this.state.module_index, this.state.selected_entity_index) }}
                id={"module-checkbox-" + this.state.module_index + this.state.selected_entity_index}
                name={"module-checkbox-" + this.state.module_index + this.state.selected_entity_index} />
              <label htmlFor={"module-checkbox-" + this.state.module_index + this.state.selected_entity_index}
                className="card-header-label">{this.props.module.module_name}</label>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              {
                this.props.module.permissions.map((permission, perm_index) => {
                  return <PermissionComponent
                    key={this.state.module_nmae + "_perm_" + this.state.module_index + perm_index + this.state.selected_entity_index}
                    module_index={this.state.module_index}
                    permission={permission}
                    perm_index={perm_index}
                    selected_entity_index={this.state.selected_entity_index}
                    handlePermissionChange={this.props.handlePermissionChange}
                    checked={permission.checked} />
                })
              }
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.User || {};
  const available_entities = all_value.available_entities || [];
  const selected_entities = all_value.selected_entities || [];
  const module_list = all_value.module_list || [];
  const default_roles = all_value.default_roles || [];

  return {
    available_entities, selected_entities, module_list, default_roles,
  };
}

export default connect(mapStateToProps)(withRouter(ModuleComponent));