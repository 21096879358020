import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function LinkedStatus(props) {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Linked</Tooltip>}>

        <span className="icon-linked-icon icon-td "><span className={"path1 " +(props.active ? "skyblues":" graylight")}></span><span className="path2 whitestatus"></span><span className="path3 whitestatus"></span><span className="path4 whitestatus"></span><span className="path5 whitestatus"></span><span className="path6 whitestatus"></span><span className="path7 whitestatus"></span><span className="path8"></span><span className="path9"></span></span>
        
        {/* <span className={"icon-linked-icon icon-td "+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> */}

    </OverlayTrigger>
}
export default LinkedStatus;