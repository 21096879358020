import React from 'react';

function SortingIcon(props) {
	
	const LoadIcon = () => {
		if (props.field_number == props.sort_by && props.sort_order == 1) {
			return <span className="icon-right-arrow-1 sort-topbottom-icon	"></span>;
		} else if (props.field_number == props.sort_by && props.sort_order == 2) {
			return <span className="icon-right-arrow sort-topbottom-icon	"></span>;
		} else {
			return <React.Fragment>
				<span className="icon-Group-4449 sort-both-icon"></span>
			</React.Fragment>
		}
	}

	return <div onClick={() => props.onSortClick((props.sort_order == 1 ? 2 : 1), props.field_number)} >
		{props.field_name}
		<LoadIcon  />
		{/* {(props.field_number == props.sort_by && props.sort_order == 1) ? <span className="icon-arrow-down-sign-to-navigate"></span> : <span className="icon-arrow-down-sign-to-navigate reverse-sort-icon"></span>} */}
	</div>
}
export default SortingIcon;