import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar'
import LineChart from './component/LineChart'
import BarChart from './component/BarChart'
import DoughnutChart from './component/DoughnutChart'
import HorizontalBarChart from './component/HorizontalBarChart'
function myDashboard(){ 
	
	const [show,setShow]=React.useState(false);
	const [buktecSummary,buktecsetShow]=React.useState(false);
	const [buktecSummary2,buktecsetShow2]=React.useState(false);
	const [buktecSummary3,buktecsetShow3]=React.useState(false);

		return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
				<div className="row w-100">
					<div className="col-lg-4 col-sm-4">
						<a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
						<a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
					</div>
					<div className="col-lg-4 col-sm-4 text-center">
						<p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
					</div>
					<div className="col-lg-4 col-sm-4">
						<ul className="top-menu-right">
							<li>
									<a href="#"><span className="icon-loupe"></span></a>
							</li>
							<li className="main-gift-sec">
									<Dropdown>
										<Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
										<a href="#"><span className="icon-gift"></span></a>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{/* <span className="triangle-1"></span> */}
											<p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
											<div className="inner-main-gift-sec">
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															 <span className="icon-sec icon-Mask-Group-4"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-invoice1"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-documents"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-Group-4398"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-Mask-Group-4"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-Mask-Group-4"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-Mask-Group-4"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
												<div className="inner-gift-sec">
													<div className="row">
														<div className="col-lg-2 col-md-2 col-xs-2">
															<span className="icon-sec icon-Mask-Group-4"></span>
														</div>
														<div className="col-lg-10 col-md-10 col-xs-10 p-0">
															<p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
															<p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
														</div>
													</div>
												</div>
											</div>
										</Dropdown.Menu>
									</Dropdown>
							</li>
							<li className="main-noti-sec">
									<Dropdown>
										<Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
										<a href="#"><i class="fa fa-bell-o" aria-hidden="true"></i></a>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{/* <span className="triangle-2"></span> */}
											<p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
											<div className="inner-main-noti-sec">
												<p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
												<ul>
													<li>
														<div className="inner-noti-sec">
															<div className="row">
																<div className="col-lg-3 col-md-3 col-xs-3">
																	<div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
																		<span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
																	</div>
																</div>
																<div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
																	<p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
																	<p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
																</div>
															</div>
														</div>
													</li>
													<li>
														<div className="inner-noti-sec">
															<div className="row">
																<div className="col-lg-3 col-md-3 col-xs-3">
																	<div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
																		<span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
																	</div>
																</div>
																<div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
																	<p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
																	<p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
																</div>
															</div>
														</div>
													</li>
												</ul>
											
												<p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
												<ul>
													<li>
														<div className="inner-noti-sec">
															<div className="row">
																<div className="col-lg-3 col-md-3 col-xs-3">
																	<div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
																		<span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
																	</div>
																</div>
																<div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
																	<p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
																	<p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
																</div>
															</div>
														</div>
													</li>
													<li>
														<div className="inner-noti-sec">
															<div className="row">
																<div className="col-lg-3 col-md-3 col-xs-3">
																	<div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
																		<span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
																	</div>
																</div>
																<div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
																	<p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
																	<p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
																</div>
															</div>
														</div>
													</li>
													<li>
														<div className="inner-noti-sec">
															<div className="row">
																<div className="col-lg-3 col-md-3 col-xs-3">
																	<div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
																		<span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
																	</div>
																</div>
																<div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
																	<p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
																	<p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
																</div>
															</div>
														</div>
													</li>
													<li>
														<div className="inner-noti-sec">
															<div className="row">
																<div className="col-lg-3 col-md-3 col-xs-3">
																	<div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
																		<span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
																	</div>
																</div>
																<div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
																	<p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
																	<p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
																</div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										</Dropdown.Menu>
									</Dropdown>
							</li>
							<li className="profile-menu">
									<Dropdown>
									<img className="profile-small-img" src={user_img}/>
											<Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
											Mahesh Raje
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<span className="triangle-3"></span>
												<div className="main-profile-sec">
													<div className="profile-img-name-sec">
														<div className="row">
															<div className="col-lg-4 col-md-3 col-xs-12">
																<img className="img-fluid mx-auto d-block" src={profile_sm} />
															</div>
															<div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
																<div className="edit-sec">
																	<span className="icon-edit"></span>
																</div>
																<p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
																<p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
																<p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
																<button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
															</div>
														</div>
													</div>
													<form className="search-sec clearfix">
														<input type="text" placeholder="Search Entity" />
														<button type="submit"><span className="icon-loupe"></span></button>
													</form>
													<div className="profile-entities-list">
														<p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
														<ul>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity1} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity2} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity3} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity1} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
																				 <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
																			</OverlayTrigger>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity2} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity3} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity2} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
																		</div>
																	</div>
																</div>
															</li>
															<li>
																<div className="inner-entity-sec">
																	<div className="row">
																		<div className="col-lg-3 col-md-3 col-xs-3">
																			<img className="img-fluid mx-auto d-block" src={entity3} />
																		</div>
																		<div className="col-lg-9 col-md-9 col-xs-9 p-0">
																			<p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
																		</div>
																	</div>
																</div>
															</li>
														</ul>
													</div>
												</div>
											</Dropdown.Menu>
										</Dropdown>
							</li>
							<li className="main-receipts-menu-sec">
									<Dropdown>
										<Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
										 <a href="#"><span className="icon-menu"></span></a>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{/* <span className="triangle-4"></span> */}
											<p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
											<div className="download-mob-sec">
												<p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
												<img className="" src={gplay}/>
											</div>
											<div className="account-partner-sec">
												<p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
												<img className="" src={accountp}/>
												<p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
											</div>
											<hr/>
											<div className="das-link-sec">
												<ul>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-4408"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Dashboard</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="text-name"><span className="icon-Group-5303"></span> Link Evidence</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-lightbulb"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Help</span></a>
													</li>
												</ul>
											</div>
											<hr/>
											<div className="cap-evi-sec">
												<p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
												<ul>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-4402"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Evidences </span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
													</li>
												</ul>
											</div>
											<hr/>
											<div className="master-data-sec">
												<p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
												<ul>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-5305"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
													</li>
												</ul>
											</div>
											<hr/>
											<div className="master-data-sec">
												<p className="ft-sz-22 s-sbold blu-color text-uppercase"><span className="icon-Group-5303 mr-3"></span> Link evidence</p>
											</div>
											<hr/>
											<div className="tools-sec">
												<p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
												<ul>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-to-do-list"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="text-name">Task Manager </span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-chat1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Messenger</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-4403"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Doc Box</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-4404"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Export and Reports</span></a>
													</li>
													<li>
														<a className="s-sbold font-colr"><span className="icon-sec icon-Group-4405"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Profile Management</span></a>
													</li>
												</ul>
											</div>
										</Dropdown.Menu>
									</Dropdown>
							</li>
						</ul>
					</div>
				</div>
			</nav>
			<div>
				<ul className="main-sidebar">
					<li>
						<a href="#" className="s-sbold blu-color active">
						<span className="side-icon icon-dashboard-1"></span>
							<span className="side-link s-sbold d-none">Dashboard</span>
						</a>
					</li>
					<li>
						<a href="#" className="s-sbold blu-color">
						<span className="side-icon icon-Group-2520"></span>
							<span className="side-link s-sbold d-none">Capture</span>
						</a>
					</li>
					<li>
						<a href="#" className="s-sbold blu-color">
						<span className="side-icon icon-Group-2519"></span>
							<span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
						</a>
					</li>
					<li>
						<a href="#" className="s-sbold blu-color">
						<span className="side-icon icon-Group-2518"></span>
							<span className="side-link s-sbold d-none">Money Link</span>
						</a>
					</li>
					<li>
						<a href="#" className="s-sbold blu-color">
						<span className="side-icon icon-XMLID_223"></span>
							<span className="side-link s-sbold d-none">Export and Reports</span>
						</a>
					</li>
				</ul>
			</div>
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="row bg-w bg-rounded">
						<div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
							<div className="clearfix main-title-btn-sec dashboard-top-head-tag">
									<div className="head-title pull-left">
										<h2 className="s-sbold blu-color m-0">Dashboard</h2>
									</div>
									<div className="pull-right">
									{/* <button className="lic-bill-btn btn ft-sz-22 s-bold white-color">Renew Licenses</button>
									<button className="lic-bill-btn btn ft-sz-22 s-bold white-color">Buy Licenses</button> */}
									<ToggleButtonGroup type="radio" name="options" defaultValue={1}>
											<ToggleButton value={1}>Buktec Summary</ToggleButton>
											<ToggleButton value={2}>Business Summary</ToggleButton>
											<ToggleButton value={3}>Business Trends</ToggleButton>
											<ToggleButton value={4}>My board</ToggleButton>
									</ToggleButtonGroup>
									</div>
							</div>
							<div className="main-evidence-sms-sec main-dashboard-chart-sec">
									<div className="main-date-sec">
											<div className="clearfix">
												<div className="last-month-sec">
													<div className="form-group">
															<select className="form-control inner-last-month-sec">
															<option value="1" disabled selected>Current Financial Year</option>
															<option value="2">Last Financial Year</option>
															<option value="3">This Month </option>
															<option value="4">Last 3 Months</option>
															<option value="5">Since Beginning</option>
															<option value="6">Custom Date Range</option>
															</select>             
													</div>
												</div>
												<div className="start-end-date-sec btn">
													<span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span>
												</div>
												<hr/>
												<div className="start-end-date-sec btn">
													<span className="ft-sz-20 s-reg blu-color">End Date</span> <span className="icon-date icon-calendar"></span>
												</div>
												<div className="pull-right d-flex">
													<div className="last-month-sec">
															<div className="form-group">
																<select className="form-control inner-last-month-sec">
																<option value="1" disabled selected>Accounting Data</option>
																<option value="2">Buktec data</option>
																</select>             
															</div>
													</div>
													<div className="last-update-sec">
															<p className="ft-sz-12 s-reg blu-color mb-1">Last updated</p>
															<p className="ft-sz-16 s-bold blu-color mb-0">05-12-2020</p>
													</div>
												</div>
											</div> 
									</div>

									<div className="buktec-summary chart-scroll-sec ">
											<div className="row main-row chart-slider-sec">
                        <a className="carousel-control-prev"><span class="icon-Slider-arrow2"></span></a>
                        <a className="carousel-control-next"><span class="icon-Slider-arrow-1"></span></a>
												<div className="col-xl-5">
													<div className="main-chart-box">
															<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Financial Institute summary</p></div>
															<div className="row">
																		<div className="col-xl-7">
																					<div className="" onClick={() => buktecsetShow3(true)}>
																								<BarChart></BarChart>
																					</div>
																		</div>
																		<div className="col-xl-5">
																					<div className="chart-left-content">
																						<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Inflow</p>
																						<p className="ft-sz-22 s-bold blu-color">₹65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																						<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Outflow</p>
																						<p className="ft-sz-22 s-bold blu-color">₹1,85,450 <span class="icon-Red-arrow ft-sz-25"></span></p>
																						<p className="ft-sz-14 s-sbold font-colr mb-0">Balance (14-02-2021)</p>
																						<p className="ft-sz-22 s-bold blu-color">₹1,20,400 </p>
																					</div>
																		</div>
															</div>
													</div>   
													  <Modal
															show={buktecSummary3}
															size="lg"
															onHide={() => buktecsetShow3(false)}
															dialogClassName="modal-90w"
															aria-labelledby="example-custom-modal-styling-title"
															backdrop="static"
															centered
															className="modal-popup-class"
														>
															<Modal.Header closeButton>
															</Modal.Header>
															<Modal.Body>
																<div className="main-chart-box">
																		<div className="col-xl-12"><p className="ft-sz-30 s-bold blu-color">Financial Institute summary</p></div>
																		<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																										<BarChart></BarChart>
																							</div>
																				</div>
																				<div className="col-xl-5">
																					<div className="chart-left-content">
																						<p className="ft-sz-18 s-sbold font-colr mb-0">Inflow</p>
																						<p className="ft-sz-25 s-bold blu-color">₹65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																						<p className="ft-sz-18 s-sbold font-colr mb-0">Outflow</p>
																						<p className="ft-sz-25 s-bold blu-color">₹1,85,450 <span class="icon-Red-arrow ft-sz-25"></span></p>
																						<p className="ft-sz-18 s-sbold font-colr mb-0">Balance (14-02-2021)</p>
																						<p className="ft-sz-25 s-bold blu-color">₹1,20,400 </p>
																					</div>
																				</div>
																		</div>
																</div> 
															</Modal.Body>
														</Modal>
												</div>
												<div className="col-xl-5">
													<div className="main-chart-box">
															<div className="col-xl-12 clearfix">
                                <p className="ft-sz-22 s-bold blu-color pull-left">HDFC Bank <span className="ft-sz-18 s-sbold">XX123</span></p>
                                <p className="pull-right"><span class="icon-Group-4437 ft-sz-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span></p>
                              </div>
															<div className="row">
																		<div className="col-xl-7">
																					<div className="" onClick={() => buktecsetShow3(true)}>
																								<BarChart></BarChart>
																					</div>
																		</div>
																		<div className="col-xl-5">
																				<div className="chart-left-content">
																					<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Inflow</p>
																					<p className="ft-sz-22 s-bold blu-color">₹65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																					<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Outflow</p>
																					<p className="ft-sz-22 s-bold blu-color">₹1,85,450 <span class="icon-Red-arrow ft-sz-25"></span></p>
																					<p className="ft-sz-14 s-sbold font-colr mb-0">Balance (14-02-2021)</p>
																					<p className="ft-sz-22 s-bold blu-color">₹1,20,400 </p>
																				</div>
																		</div>
															</div>
													</div>
												</div>
												<div className="col-xl-5">
													<div className="main-chart-box">
															<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">HDFC Bank <span className="ft-sz-18 s-sbold">XX123</span></p></div>
															<div className="row">
																		<div className="col-xl-7">
																					<div className="" onClick={() => buktecsetShow3(true)}>
																								<BarChart></BarChart>
																					</div>
																		</div>
																		<div className="col-xl-5">
																				<div className="chart-left-content">
																					<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Inflow</p>
																					<p className="ft-sz-22 s-bold blu-color">₹65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																					<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Outflow</p>
																					<p className="ft-sz-22 s-bold blu-color">₹1,85,450 <span class="icon-Red-arrow ft-sz-25"></span></p>
																					<p className="ft-sz-14 s-sbold font-colr mb-0">Balance (14-02-2021)</p>
																					<p className="ft-sz-22 s-bold blu-color">₹1,20,400 </p>
																				</div>
																		</div>
															</div>
													</div>
												</div>
												<div className="col-xl-5">
													<div className="main-chart-box">
															<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">HDFC Bank <span className="ft-sz-18 s-sbold">XX123</span></p></div>
															<div className="row">
																		<div className="col-xl-7">
																					<div className="" onClick={() => buktecsetShow3(true)}>
																								<BarChart></BarChart>
																					</div>
																		</div>
																		<div className="col-xl-5">
																				<div className="chart-left-content">
																					<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Inflow</p>
																					<p className="ft-sz-22 s-bold blu-color">₹65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																					<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Outflow</p>
																					<p className="ft-sz-22 s-bold blu-color">₹1,85,450 <span class="icon-Red-arrow ft-sz-25"></span></p>
																					<p className="ft-sz-14 s-sbold font-colr mb-0">Balance (14-02-2021)</p>
																					<p className="ft-sz-22 s-bold blu-color">₹1,20,400 </p>
																				</div>
																		</div>
															</div>
													</div>
												</div>
											</div>
											<div className="row main-row">
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Reimbursements</p></div>
																	<div className="row">
																				<div className="col-xl-6">
																							<div className="" onClick={() => buktecsetShow(true)}>
																										<DoughnutChart></DoughnutChart>
																							</div>
																				</div>
																				<div className="col-xl-6">
																					<div className="chart-left-content">
																							<p className="ft-sz-14 s-sbold font-colr mb-0">Total Applied</p>
																							<p className="ft-sz-30 s-bold blu-color">₹45,050</p>
																							<div class="d-flex justify-content-between">
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Approved</p>
																										<p className="ft-sz-22 s-bold blu-color">₹32,000</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Rejected</p>
																										<p className="ft-sz-22 s-bold blu-color">₹96,041</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec blue"></span> Pending</p>
																										<p className="ft-sz-22 s-bold blu-color">₹96,041</p>
																								</div>
																							</div>
																					</div>
																				</div>
																	</div>
															</div>   
															<Modal
																	show={buktecSummary}
																	size="lg"
																	onHide={() => buktecsetShow(false)}
																	dialogClassName="modal-90w"
																	aria-labelledby="example-custom-modal-styling-title"
																	backdrop="static"
																	centered
																	className="modal-popup-class"
																>
																	<Modal.Header closeButton>
																	</Modal.Header>
																	<Modal.Body>
																		<div className="main-chart-box">
																				<div className="col-xl-12"><p className="ft-sz-30 s-bold blu-color">Reimbursements</p></div>
																				<div className="row">
																						<div className="col-xl-7">
																									<div className="">
																										<DoughnutChart></DoughnutChart>
																									</div>
																						</div>
																						<div className="col-xl-5">
																							<div className="chart-left-content">
																								<p className="ft-sz-18 s-sbold font-colr mb-0">Total Applied</p>
																								<p className="ft-sz-30 s-bold blu-color">₹45,050</p>
																								<div class="d-flex justify-content-between">
																									<div className="">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Approved</p>
																										<p className="ft-sz-22 s-bold blu-color">₹32,000</p>
																									</div>
																									<div className="">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Rejected</p>
																										<p className="ft-sz-22 s-bold blu-color">₹96,041</p>
																									</div>
																									<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0">Pending</p>
																										<p className="ft-sz-22 s-bold blu-color">₹96,041</p>
																									</div>
																								</div>
																							</div>
																						</div>
																				</div>
																		</div> 
																	</Modal.Body>
																</Modal>
														</div>
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">My Profile</p></div>
																	<div className="row">
																				<div className="col-xl-4">
                                          <div className="user-circle-sec">
                                            <span class="user-circle-icon ft-sz-28 icon-user"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                            <p className="my-profile-count inner-user-circle s-bold blu-color">26</p>
                                            <p className="ft-sz-18 inner-user-circle s-sbold blu-color">Users</p>
                                          </div>
																				</div>
																				<div className="col-xl-8">
                                          <div className="progress-storage-sec">
                                            <span className="ft-sz-18 s-sbold blu-color pull-left">Entities</span>
                                            <span className="ft-sz-22 s-bold blu-color pull-right">4/10</span> 
                                            <div className="clearfix"></div>
                                            <p className="mb-0"><ProgressBar now={40} /></p>
                                          </div>
                                          <div className="progress-storage-sec">
                                            <span className="ft-sz-18 s-sbold blu-color pull-left">Data Size</span>
                                            <span className="ft-sz-22 s-bold blu-color pull-right">1.8/2GB</span> 
                                            <div className="clearfix"></div>
                                            <p className="mb-0"><ProgressBar now={90} className="red" /></p>
                                          </div>
																				</div>
																	</div>
															</div>   
														</div>
											</div>
											<div className="row main-row">
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Evidences</p></div>
																	<div className="row">
																				<div className="col-xl-6">
																							<div className="" onClick={() => buktecsetShow2(true)}>
																										<DoughnutChart></DoughnutChart>
																							</div>
																				</div>
																				<div className="col-xl-6">
																					<div className="chart-left-content">
																							<p className="ft-sz-14 s-sbold font-colr mb-0">Total Amount</p>
																							<p className="ft-sz-30 s-bold blu-color">₹3,64,010</p>
																							<div class="d-flex justify-content-between">
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Not Ready</p>
																										<p className="ft-sz-22 s-bold blu-color">₹84,000</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Ready</p>
																										<p className="ft-sz-22 s-bold blu-color">₹1,15,050</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec blue"></span> Linked</p>
																										<p className="ft-sz-22 s-bold blu-color">₹1,32,000</p>
																								</div>
																							</div>
																							<div class="d-flex flex-row">
																								<div className="mr-3">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec yellow"></span> Processed</p>
																										<p className="ft-sz-22 s-bold blu-color">₹2,55,000</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec blue"></span> Synched</p>
																										<p className="ft-sz-22 s-bold blu-color">₹59,000</p>
																								</div>
																							</div>
																					</div>
																				</div>
																	</div>
															</div>   
															<Modal
																	show={buktecSummary2}
																	size="lg"
																	onHide={() => buktecsetShow2(false)}
																	dialogClassName="modal-90w"
																	aria-labelledby="example-custom-modal-styling-title"
																	backdrop="static"
																	centered
																	className="modal-popup-class"
																>
																	<Modal.Header closeButton>
																	</Modal.Header>
																	<Modal.Body>
																		<div className="main-chart-box">
																				<div className="col-xl-12"><p className="ft-sz-30 s-bold blu-color">Evidences</p></div>
																				<div className="row">
																						<div className="col-xl-7">
																									<div className="">
																										<DoughnutChart></DoughnutChart>
																									</div>
																						</div>
																						<div className="col-xl-5">
																						<div className="chart-left-content">
																							<p className="ft-sz-18 s-sbold font-colr mb-0">Total Applied</p>
																							<p className="ft-sz-30 s-bold blu-color">₹3,64,010</p>
																							<div class="d-flex flex-row">
																								<div className="mr-5">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Not Ready</p>
																										<p className="ft-sz-22 s-bold blu-color">₹84,000</p>
																								</div>
																								<div className="mr-5">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Ready</p>
																										<p className="ft-sz-22 s-bold blu-color">₹1,15,050</p>
																								</div>
																								<div className="mr-5">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Linked</p>
																										<p className="ft-sz-22 s-bold blu-color">₹1,32,000</p>
																								</div>
																							</div>
																							<div class="d-flex flex-row">
																								<div className="mr-5">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Processed</p>
																										<p className="ft-sz-22 s-bold blu-color">₹2,55,000</p>
																								</div>
																								<div className="mr-5">
																										<p className="ft-sz-18 s-sbold font-colr mb-0">Synched</p>
																										<p className="ft-sz-22 s-bold blu-color">₹59,000</p>
																								</div>
																							</div>
																						</div>
																						</div>
																				</div>
																		</div> 
																	</Modal.Body>
																</Modal>
														</div>
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Link Evidences</p></div>
																	<div className="row">
																				<div className="col-xl-6">
																							<div className="">
																										<DoughnutChart></DoughnutChart>
																							</div>
																				</div>
																				<div className="col-xl-6">
																					<div className="chart-left-content">
																							<p className="ft-sz-14 s-sbold font-colr mb-0">Total Amount</p>
																							<p className="ft-sz-30 s-bold blu-color">₹3,64,010</p>
																							<div class="d-flex justify-content-between">
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Not Ready</p>
																										<p className="ft-sz-22 s-bold blu-color">₹84,000</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Ready</p>
																										<p className="ft-sz-22 s-bold blu-color">₹1,15,050</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec blue"></span> Linked</p>
																										<p className="ft-sz-22 s-bold blu-color">₹1,32,000</p>
																								</div>
																							</div>
																							<div class="d-flex flex-row">
																								<div className="mr-3">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec yellow"></span> Processed</p>
																										<p className="ft-sz-22 s-bold blu-color">₹2,55,000</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec blue"></span> Synched</p>
																										<p className="ft-sz-22 s-bold blu-color">₹59,000</p>
																								</div>
																							</div>
																					</div>
																				</div>
																	</div>
															</div>   
														</div>
											</div>

											<div className="row main-row">
													<div className="col-xl-12">
														<div className="main-chart-box">
																<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Cheques</p></div>
																<div className="row">
																	<div className="col-lg-6">
																		<div className="row">
																			<div className="col-xl-6">
																						<div className="">
																									<DoughnutChart></DoughnutChart>
																						</div>
																			</div>
																			<div className="col-xl-6">
																				<div className="chart-left-content">
																						<p className="ft-sz-18 s-sbold font-colr mb-0">Issued</p>
																						<p className="ft-sz-30 s-bold blu-color">₹1,85,450 <span class="icon-Red-arrow ft-sz-25"></span></p>
																						<div class="d-flex justify-content-between">
																							<div className="">
																									<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Cleared</p>
																									<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Not cleared</p>
																							</div>
																						</div>
																						
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-6">
																		<div className="row">
																			<div className="col-xl-6">
																						<div className="">
																									<DoughnutChart></DoughnutChart>
																						</div>
																			</div>
																			<div className="col-xl-6">
																				<div className="chart-left-content">
																						<p className="ft-sz-18 s-sbold font-colr mb-0">Received</p>
																						<p className="ft-sz-30 s-bold blu-color">₹65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																						<div class="d-flex justify-content-between">
																							<div className="">
																									<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Cleared</p>
																									<p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Not cleared</p>
																							</div>
																						</div>
																						
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
														</div>   
													</div>
											</div>
									</div>

									<div className="business-summary chart-scroll-sec d-none">
											<div className="row main-row">
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Total sales and Receivables Outstanding</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="" onClick={() => setShow(true)}>
																										<BarChart></BarChart>
																							</div>
																				</div>
																				<div className="col-xl-5">
																							<div className="chart-left-content">
																								<p className="ft-sz-18 s-sbold font-colr mb-0"><span className="color-code-sec yellow"></span> Sales</p>
																								<p className="ft-sz-25 s-bold blu-color">₹5,65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																								<p className="ft-sz-18 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Receivables</p>
																								<p className="ft-sz-25 s-bold blu-color">₹8,65,050 <span class="icon-Red-arrow ft-sz-25"></span></p>
																							</div>
																				</div>
																	</div>
															</div>   
															<Modal
																	show={show}
																	size="lg"
																	onHide={() => setShow(false)}
																	dialogClassName="modal-90w"
																	aria-labelledby="example-custom-modal-styling-title"
																	backdrop="static"
																	centered
																	className="modal-popup-class"
																>
																	<Modal.Header closeButton>
																	</Modal.Header>
																	<Modal.Body>
																		<div className="main-chart-box">
																				<div className="col-xl-12"><p className="ft-sz-30 s-bold blu-color">Total sales and Recievables Outstanding</p></div>
																				<div className="row">
																						<div className="col-xl-7">
																									<div className="">
																												<BarChart></BarChart>
																									</div>
																						</div>
																						<div className="col-xl-5">
																									<div className="chart-left-content">
																										<p className="ft-sz-22 s-sbold font-colr mb-0">Sales</p>
																										<p className="ft-sz-30 s-bold blu-color">₹5,65,050 <span class="icon-Green-arrow ft-sz-25"></span></p>
																										<p className="ft-sz-22 s-sbold font-colr mb-0">Recievables</p>
																										<p className="ft-sz-30 s-bold blu-color">₹8,65,050 <span class="icon-Red-arrow ft-sz-25"></span></p>
																									</div>
																						</div>
																				</div>
																		</div> 
																	</Modal.Body>
																</Modal>
														</div>
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Cash and Bank Balance</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																										<DoughnutChart></DoughnutChart>
																							</div>
																				</div>
																				<div className="col-xl-5">
																					<div className="chart-left-content">
																							<p className="ft-sz-18 s-sbold font-colr mb-0">Total (14-02-2021)</p>
																							<p className="ft-sz-30 s-bold blu-color">₹5,18,041</p>
																							<div class="d-flex justify-content-between">
																								<div className="">
																										<p className="ft-sz-18 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Bank Balance</p>
																										<p className="ft-sz-22 s-bold blu-color">₹4,22,000</p>
																								</div>
																								<div className="">
																										<p className="ft-sz-18 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Cash in hand</p>
																										<p className="ft-sz-22 s-bold blu-color">₹96,041</p>
																								</div>
																							</div>
																							
																					</div>
																				</div>
																	</div>
															</div>   
														</div>
											</div>
											<div className="row main-row">
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Top 10 Sales Receivables</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																					<div className="">
																								<BarChart></BarChart>
                                                <p className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec pink"></span> Amount</p>
																					</div>
																				</div>
																				<div className="col-xl-5">
																					<div className="chart-left-list">
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																						<div class="d-flex justify-content-between">
																								<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																								<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																						</div>
																					</div>
																				</div>
																	</div>
															</div>   
														</div>
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Top 10 Purchase Payables</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																										<BarChart></BarChart>
                                                    <p className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec pink"></span> Amount</p>
																							</div>
																				</div>
																				<div className="col-xl-5">
																							<div className="chart-left-list">
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																							</div>
																				</div>
																	</div>
															</div>   
														</div>
											</div>
											<div className="row main-row">
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Top 10 Purchase Payables</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																										<BarChart></BarChart>
																							</div>
																				</div>
																				<div className="col-xl-5">
																							<div className="chart-left-list">
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																							</div>
																				</div>
																	</div>
															</div>   
														</div>
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																								<p className="ft-sz-22 s-bold blu-color">Cash Inflow/Outflow</p>
																										<BarChart></BarChart>
																							</div>
																				</div>
																				<div className="col-xl-5">
																							<div className="chart-left-content">
																								<p className="ft-sz-18 s-sbold font-colr mb-0"><span className="color-code-sec yellow"></span> Total cash Inflow</p>
																								<p className="ft-sz-25 s-bold blu-color">₹6,15,050</p>
																								<p className="ft-sz-18 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Total cash Outflow</p>
																								<p className="ft-sz-25 s-bold blu-color">₹2,18,041</p>
																								<p className="ft-sz-18 s-sbold font-colr mb-0">Net Addition</p>
																								<p className="ft-sz-30 s-bold blu-color">₹3,97,009</p>
																							</div>
																				</div>
																	</div>
															</div>   
														</div>
											</div>
									</div>

									<div className="business-trends chart-scroll-sec d-none">
												<div className="row main-row">
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Merchant wise spend (Top 10)</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																										<BarChart></BarChart>
                                                    <p className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec pink"></span> Amount</p>
																							</div>
																				</div>
																				<div className="col-xl-5">
																							<div className="chart-left-list">
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																							</div>
																				</div>
																	</div>
															</div>   
														</div>
														<div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Categories wise spend(Top 10)</p></div>
																	<div className="row">
																				<div className="col-xl-7">
																							<div className="">
																										<BarChart></BarChart>
                                                    <p className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec pink"></span> Amount</p>
																							</div>
																				</div>
																				<div className="col-xl-5">
																							<div className="chart-left-list">
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																								<div class="d-flex justify-content-between">
																										<div><p className="ft-sz-16 s-reg font-colr mb-2">Joshi Foods</p></div>
																										<div><p className="ft-sz-16 s-sbold blu-color mb-2">₹2,15,050</p></div>
																								</div>
																							</div>
																				</div>
																	</div>
															</div>   
														</div>
												</div>
												<div className="row main-row">
															<div className="col-xl-6">
																	<div className="main-chart-box">
																		<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Total sales and Recievables Outstanding</p></div>
																		<div className="row">
																					<div className="col-xl-12">
																								<div className="">
																											<LineChart></LineChart>
                                                      <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2 mr-4"><span className="color-code-sec pink"></span> Inflow</span>
                                                      <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec blue"></span> Outflow</span>
																								</div>
																					</div>
																		</div>
																	</div>   
															</div>
															<div className="col-xl-6">
																	<div className="main-chart-box">
																		<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Sales trend</p></div>
																		<div className="row">
																					<div className="col-xl-12">
																								<div className="">
																											<LineChart></LineChart>
                                                      <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2 mr-4"><span className="color-code-sec pink"></span> Inflow</span>
                                                      <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec blue"></span> Outflow</span>
																								</div>
																					</div>
																		</div>
																	</div>   
															</div>
												</div>
									</div>

                  <div className="my-board chart-scroll-sec">
                     <div className="row main-row">
                        <div className="col-xl-12">
														<div className="main-chart-box">
																<div className="row">
																	<div className="col-lg-6">
                                    <div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Entities</p></div>
																		<div className="row">
																			<div className="col-xl-6">
																						<div className="">
																									<DoughnutChart></DoughnutChart>
																						</div>
																			</div>
																			<div className="col-xl-6">
																				<div className="chart-accordion-sec">
                                          <Accordion defaultActiveKey="">
                                            <Card>
                                              <Accordion.Toggle as={Card.Header} eventKey="0">
                                                <span className="color-code-sec orange"></span> <span className="ft-sz-18 s-sbold font-colr">#GSTCustomer</span>
                                                <div className="drop-arrow">
                                                  <span className="ft-sz-22 s-sbold blu-color mr-4">39</span>
                                                  <span className="icon-arrow-down-sign-to-navigate"></span>
                                                </div>
                                                <div className="clearfix"></div>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                  <ul>
                                                    <li className="ft-sz-16 s-reg blu-color">Radha Developers</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Rk Pharma</li>
                                                    <li className="ft-sz-16 s-reg blu-color">UEFA</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Agrawal Traders</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Barclays</li>
                                                  </ul>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                              <Accordion.Toggle as={Card.Header} eventKey="1">
                                                <span className="color-code-sec pink"></span> <span className="ft-sz-18 s-sbold font-colr">#LLP</span>
                                                <div className="drop-arrow">
                                                  <span className="ft-sz-22 s-sbold blu-color mr-4">21</span>
                                                  <span className="icon-arrow-down-sign-to-navigate"></span>
                                                </div>
                                                <div className="clearfix"></div>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                  <ul>
                                                    <li className="ft-sz-16 s-reg blu-color">Radha Developers</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Rk Pharma</li>
                                                    <li className="ft-sz-16 s-reg blu-color">UEFA</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Agrawal Traders</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Barclays</li>
                                                  </ul>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                              <Accordion.Toggle as={Card.Header} eventKey="2">
                                                <span className="color-code-sec yellow"></span> <span className="ft-sz-18 s-sbold font-colr">#Medicos</span>
                                                <div className="drop-arrow">
                                                  <span className="ft-sz-22 s-sbold blu-color mr-4">13</span>
                                                  <span className="icon-arrow-down-sign-to-navigate"></span>
                                                </div>
                                                <div className="clearfix"></div>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                  <ul>
                                                    <li className="ft-sz-16 s-reg blu-color">Radha Developers</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Rk Pharma</li>
                                                    <li className="ft-sz-16 s-reg blu-color">UEFA</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Agrawal Traders</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Barclays</li>
                                                  </ul>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                              <Accordion.Toggle as={Card.Header} eventKey="3">
                                                <span className="color-code-sec blue"></span> <span className="ft-sz-18 s-sbold font-colr">#ITService</span>
                                                <div className="drop-arrow">
                                                  <span className="ft-sz-22 s-sbold blu-color mr-4">20</span>
                                                  <span className="icon-arrow-down-sign-to-navigate"></span>
                                                </div>
                                                <div className="clearfix"></div>
                                              </Accordion.Toggle>
                                              <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                  <ul>
                                                    <li className="ft-sz-16 s-reg blu-color">Radha Developers</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Rk Pharma</li>
                                                    <li className="ft-sz-16 s-reg blu-color">UEFA</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Agrawal Traders</li>
                                                    <li className="ft-sz-16 s-reg blu-color">Barclays</li>
                                                  </ul>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        </div>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-6">
                                    <div className="top-invite-data-sec">
                                      <p className="ft-sz-22 s-bold blu-color mb-2">Invites</p>
                                      <div class="row no-gutters">
                                        <div class="col-4">
                                          <div className="inner-blue-sec">
                                            <div class="d-flex justify-content-between">
                                              <div className="ft-sz-22 s-sbold white-color">Sent</div>
                                              <div className="ft-sz-30 s-bold white-color">39</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div className="inner-green-sec top-green-sec">
                                            <div class="d-flex justify-content-between">
                                              <div className="ft-sz-22 s-sbold white-color">Onboarded</div>
                                              <div className="ft-sz-30 s-bold white-color">55</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div className="inner-orange-sec">
                                            <div class="d-flex justify-content-between">
                                              <div className="ft-sz-22 s-sbold white-color pending-text-sec">Pending for action</div>
                                              <div className="ft-sz-30 s-bold white-color">12</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="top-invite-data-sec">
                                      <p className="ft-sz-22 s-bold blu-color mb-2">Data Size</p>
                                      <div className="main-entity-select-sec">
                                        <div class="row no-gutters">
                                          <div class="col-4">
                                            <div className="entity-select-sec license-dropdown">
                                              <Form.Group controlId="exampleForm.ControlSelect1">
                                                <Form.Control as="select">
                                                  <option>Select Entity</option>
                                                  <option>2</option>
                                                  <option>3</option>
                                                  <option>4</option>
                                                  <option>5</option>
                                                </Form.Control>
                                              </Form.Group>
                                            </div>
                                          </div>
                                          <div class="col-8">
                                            <div className="progress-storage-sec top-progress-storage-sec">
                                              <span className="ft-sz-18 s-sbold blu-color pull-left">Data Size</span>
                                              <span className="ft-sz-18 s-sbold blu-color pull-right">N/A</span> 
                                              <div className="clearfix"></div>
                                              <p className="mb-0"><ProgressBar now={0} /></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
																	</div>
																</div>
														</div>   
													</div>
											</div>
                      
											<div className="row main-row">
                        <div className="col-xl-6">
                          <div className="main-chart-box">
                              <div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Users wise Tasks</p></div>
                              <div className="row">
                                    <div className="col-xl-6">
                                      <div className="seaarch-user-task-sec">
                                        <div class="d-flex justify-content-between">
                                          <div className="search-filter-sec">
                                            <form className="search-sec">
                                              <input type="text" placeholder="search user" />
                                            </form>
                                          </div>
                                          <div>
                                            <p className="ft-sz-14 s-reg blu-color mb-0">Tasks</p>
                                          </div>
                                        </div>
                                        <div className="inner-seaarch-user-task-sec">
                                          <ul>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Aniruddha Deshmukh</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">22</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Chaitanya Chune</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">15</p>
                                              </div>
                                            </li>
                                            <li className="active">
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Kartik Paunikar</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">10</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Pankaj Thote</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">21</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Pankaj Thote</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">21</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Pankaj Thote</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">21</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Pankaj Thote</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">21</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Pankaj Thote</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">21</p>
                                              </div>
                                            </li>
                                            <li>
                                              <div class="d-flex justify-content-between">
                                                  <p className="ft-sz-18 s-reg blu-color mb-0">Pankaj Thote</p>
                                                  <p className="ft-sz-22 s-sbold blu-color mb-0">21</p>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-6">
                                      <div className="main-user-task-detail-sec">
                                        <div className="media">
                                          <div className="cap-name-sec blu-bg white-color ft-sz-20 s-reg mr-3">K</div>
                                          <div className="media-body">
                                            <p className="ft-sz-25 s-sbold blu-color mb-0">Kartik Paunikar</p>
                                            <div class="d-flex justify-content-between">
                                              <p className="ft-sz-16 s-sbold red-color">Pending Tasks</p>
                                              <p className="ft-sz-22 s-bold red-color">10</p>
                                            </div>
                                          </div>
                                        </div>
                                        <ul>
                                          <li>
                                            <div class="d-flex justify-content-between">
                                              <p className="ft-sz-18 s-reg blu-color mb-0">Radha Developers</p>
                                              <p className="ft-sz-22 s-bold blu-color mb-0">5</p>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="d-flex justify-content-between">
                                              <p className="ft-sz-18 s-reg blu-color mb-0">RK Pharma</p>
                                              <p className="ft-sz-22 s-bold blu-color mb-0">3</p>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="d-flex justify-content-between">
                                              <p className="ft-sz-18 s-reg blu-color mb-0">UEFA</p>
                                              <p className="ft-sz-22 s-bold blu-color mb-0">2</p>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="d-flex justify-content-between">
                                              <p className="ft-sz-18 s-reg blu-color mb-0">Radha Developers</p>
                                              <p className="ft-sz-22 s-bold blu-color mb-0">5</p>
                                            </div>
                                          </li>
                                          <li>
                                            <div class="d-flex justify-content-between">
                                              <p className="ft-sz-18 s-reg blu-color mb-0">Radha Developers</p>
                                              <p className="ft-sz-22 s-bold blu-color mb-0">1</p>
                                            </div>
                                          </li>
                                        </ul> 
                                      </div>
                                    </div>
                              </div>
                          </div>   
                        </div>
                        <div className="col-xl-6">
															<div className="main-chart-box">
																	<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Activities</p></div>
																	<div className="row">
																				<div className="col-xl-9">
																							<div className="">
                                              <HorizontalBarChart></HorizontalBarChart>
                                              <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2 mr-4"><span className="color-code-sec pink"></span> Linked(Not processed)</span>
                                              <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2"><span className="color-code-sec yellow"></span> Processed(Not synched)</span>
																							</div>
																				</div>
																	</div>
															</div>   
														</div>
											</div>

											<div className="row main-row">
                        <div className="col-xl-12">
														<div className="main-chart-box">
																<div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Licenses</p></div>
																<div className="row">
																	<div className="col-lg-6">
																		<div className="row">
																			<div className="col-xl-6">
																						<div className="">
																									<DoughnutChart></DoughnutChart>
																						</div>
																			</div>
																			<div className="col-xl-6">
                                        <div className="chart-left-content">
                                              <div className="license-dropdown w-50">
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                  <Form.Control as="select">
                                                    <option>Purchased</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                  </Form.Control>
                                                </Form.Group>
                                              </div>
                                            <p className="ft-sz-14 s-sbold font-colr mb-0">Total</p>
                                            <p className="ft-sz-30 s-bold blu-color">₹18,15,050</p>
                                            <div class="d-flex justify-content-between">
                                              <div className="">
                                                  <p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec pink"></span> Basic</p>
                                                  <p className="ft-sz-22 s-bold blu-color">₹6,15,050</p>
                                              </div>
                                              <div className="">
                                                  <p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec orange"></span> Advanced</p>
                                                  <p className="ft-sz-22 s-bold blu-color">₹6,15,050</p>
                                              </div>
                                              <div className="">
                                                  <p className="ft-sz-14 s-sbold font-colr mb-0"><span className="color-code-sec yellow"></span> Professional</p>
                                                  <p className="ft-sz-22 s-bold blu-color">₹6,15,050</p>
                                              </div>
                                            </div>
                                        </div>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-6">
                                    <div className="main-self-client-sec">
                                      <p className="ft-sz-22 s-bold blu-color">License paid by</p>
                                      <div class="row no-gutters">
                                        <div class="col-8">
                                          <div className="inner-green-sec">
                                            <div class="d-flex justify-content-between">
                                              <div className="ft-sz-25 s-bold white-color">Self</div>
                                              <div className="ft-sz-30 s-bold white-color">65</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div className="inner-orange-sec">
                                            <div class="d-flex justify-content-between">
                                              <div className="ft-sz-25 s-bold white-color">Client</div>
                                              <div className="ft-sz-30 s-bold white-color">31</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="d-flex justify-content-between mt-2">
                                        <div className="ft-sz-22 s-bold blu-color">₹6,15,050</div>
                                        <div className="ft-sz-22 s-bold blu-color">₹3,15,050</div>
                                      </div>
                                    </div>
																	</div>
																</div>
														</div>   
													</div>
											</div>
									</div>
							</div>
						</div>
					</div>
				</div>
				<a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
			</div>
		</div>     

	
}











export default myDashboard;