import React from 'react';
import { setEntityListData } from "../../../Redux/Actions/Users";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import ModuleComponent from './ModuleComponent';


class AssignEnityPermissiontoUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_entity_index:0,
      module_list : [],
    }
  }


  getmodules = () =>{
    /*let role = this.props.default_roles[this.state.role_index];

    let selected_entities=this.props.selected_entities;
    let entity=JSON.parse(JSON.stringify(this.props.selected_entities[this.state.selected_entity_index]));
    let module_list = entity.modules.map((module) => {
        module.permissions=module.permissions.map((perm) => {
        perm.checked= !!role ? role.permission_ids.includes(perm.permission_id) : false;
        return perm;
      });
      return module;
    });
    entity.custom_rights=0;
    entity.modules=module_list;
    selected_entities[this.state.selected_entity_index]=entity;

    this.setState({module_list:[]},() => {this.setState({module_list:module_list});});


    if(this.props.same_permission == 1){
      let selected_entities=this.props.selected_entities.map((entity)=>{
        entity.role_id=entity.role_id;
        entity.custom_rights=1;
        entity.modules=module_list;
        return entity;
      });
      this.props.setSelectedEntities(selected_entities);
    }*/
    
  }

  componentDidMount() {
    this.getmodules();
  }

  roleChanged = (e) => {
		const value = e.target.value;
		this.setState({ role_index : value },()=>this.getmodules());
	}  

  
  render() {
    return (
      <div className="main-user-entity-select-sec">
        <Tab.Container id="left-tabs-example" defaultActiveKey="account_permission_tab">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-xs-12">
            <div className="user-entity-select-sec">
              <Nav variant="pills" className="flex-column">
                <p className="s-sbold ft-sz-24 blu-color">Account Level Permissions</p>
                <Nav.Item>
                  <Nav.Link eventKey="account_permission_tab">Account Level Permissions</Nav.Link>
                </Nav.Item>
                <p className="s-sbold ft-sz-28 blu-color mb-1">Entity {`&`} Permissions</p>
                {
                  this.props.step === 1 ?
                    <ul className="user-available-entities">
                      {this.props.available_entities.map((entity, index) => {
                        return <li key={index}>
                          <div className="check-box">
                            <input 
                              className="styled-checkbox" 
                              type="checkbox"
                              checked={entity.checked}
                              onChange={(e) => {this.props.handleSelectEntity(e,entity,index)}}
                              id={"styled-checkbox-8"+index}
                              name={"available_entities"+index}
                            />
                            <label htmlFor={"styled-checkbox-8"+index} className="label-text blu-color s-sbold ft-sz-20 mb-0">{entity.name}</label>
                          </div>
                        </li>
                      })}
                    </ul>
                    :
                    <React.Fragment>
                      {console.log("called",this.props.available_entities)}
                      {this.props.available_entities.map((entity, index) => { 
                        console.log("called1111",entity)
                        // if(entity.checked)
                          return <Nav.Item
                              key={index}
                              // className={ this.props.same_permission == 1 ? " dactive " : (index == this.state.selected_entity_index ? " active " : "" )  } 
                              // onClick={(e) => this.handleSelectEntity(e, entity,index)}
                              >
                              <Nav.Link eventKey={"entity"+entity.id}>{entity.name}</Nav.Link>
                          </Nav.Item>
                      })}
                    </React.Fragment>
                }
              </Nav>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-xs-12">
            <div className="main-user-permission-sec">


            <Tab.Content>
              {
              this.props.available_entities.map((entity, selected_entity_index) => {
              return <Tab.Pane eventKey={"entity"+entity.id}>
                <div className="main-user-permission-sec">
                  <div className="user-copy-custom-sec">
                    <div className="row">
                      <div className="col-lg-4 col-xs-12">
                        <div className="form-group">
                          <p className="lable-input font-colr m-0">User role</p>
                          <select className="form-control"
                              name="role_index"
                              value={this.state.role_index}
                              onChange={this.roleChanged}>
                            {this.props.default_roles.map((roles, index) => {
                              return <option key={roles.id+index} value={index}>{roles.name}</option>
                            })}
                            <option key={0} value="">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-1 col-xs-12 text-center d-flex align-items-center justify-content-center">
                        <p className="lable-input font-colr m-0">OR</p>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-xs-12">
                        <div className="form-group">
                          <p className="lable-input font-colr m-0">Copy From other entity?</p>
                          <select className="form-control"
                              name="copy_from_entity_id"
                              name={this.state.copy_from_entity_id}
                              onChange={this.copyEntitymodule}>
                                <option value="">Select Entity</option>
                            {this.props.selected_entities.map((entity, index) => {
                              if(index != selected_entity_index)
                                return  <option key={index} value={entity.id}>{entity.name}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-4 col-xs-12">
                        <div className="form-group">
                          <p className="lable-input font-colr m-0">Custom Rights</p>
                          {
                            !!entity.custom_rights ? 
                            <span className="custom-right-text s-bold ft-sz-32 gren-color">Yes</span>
                            :
                          <span className="custom-right-text s-bold ft-sz-32 gren-color">No</span>
                          }
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="main-card-checkbox-sec">
                    {
                      this.state.module_list.map((module,module_index) => {             
                        return <ModuleComponent 
                                  key={"module_" + module_index} 
                                  module_nmae="entitypermision"
                                  module={module} 
                                  module_index={module_index} 
                                  role_index={this.state.role_index}
                                  selected_entity_index={this.state.selected_entity_index}
                                  
                                  handlePermissionChange={this.handlePermissionChange}
                                  permissions_ids={this.state.permissions_ids}/>
                      })
                    }

                  </div>
                </div>
              </Tab.Pane>
              })
            }
            </Tab.Content>
              {/*
                this.props.selected_entities.length === 0 ?
                <div className="main-follow-steps-sec">
                  <p className="ft-sz-30 s-bold blu-color">Follow these steps to set up user permissions </p>
                  <div className="step-list-sec">
                    <ol>
                      <li>Assign Entities to user</li>
                      <li>Click on <span className="s-bold">Next</span> to enable permission assignment.</li>
                      <li>Select an Entity to assign role or permissions to user.</li>
                    </ol>
                  </div>
                </div>
                :
                <div className="main-follow-steps-sec">
                  <p className="ft-sz-30 s-bold blu-color">You can add or remove entities for this users.</p>
                  <div className="step-list-sec">
                    <ol>
                      <li>To add entity click on checkbox.</li>
                      <li>To remove entity. Uncheck the selected entity.</li>
                      <li>Click on <span className="s-bold">Next</span> to enable permission assignment.</li>
                    </ol>
                  </div>
                </div>
                */
              }



            </div>
          </div>
        </div>
      </Tab.Container>
      </div>
    )
  }
}

function mapStateToProps(state) {
	const all_value = state.User || {};
	const available_entities = all_value.available_entities || [];
	const selected_entities = all_value.available_entities || [];
  const feature_list = all_value.feature_list || [];
  const default_roles = all_value.default_roles || [];

	return {
		available_entities,selected_entities,feature_list,default_roles
	};
}
export default connect(mapStateToProps)(withRouter(AssignEnityPermissiontoUser));