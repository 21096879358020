import React, { Component } from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import profilem from '../../../Assets/img/profilem.png';
import MyProfileService from '../../../Services/ProfileManagement/MyProfileService';
import { validateUser } from './Validation';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { toast } from 'react-toastify';
import SessionManagement from '../../../Utils/SessionManagement';
import MasterService from '../../../Services/MasterService';
import StorageData from '../../../Helpers/StorageData';
import { PROFILE_MANAEGEMT_FILE_CODE } from '../../../Helpers/Constants';
import ImageUpload from '../../../Components/BoilerPlate/ImageUpload';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';
import { setUserDetail } from "../../../Redux/Actions/Session";
import NumberTypeInputBox from '../../../Components/BoilerPlate/InputNumber';
import BreadCrum from '../../../Components/BoilerPlate/BreadCrum';
import ScrollPage from '../../../Components/BoilerPlate/ScrollPage';
import { Button } from 'react-bootstrap';


class MyProfile extends Component {

  constructor(props) {
    super(props);

    this.USER_DETAIL = SessionManagement.getUserDetail();
    this.state = {
      errors: {},
      is_edit_mode: true,

      logo_files: [],
      profile_pic: "",


      profile_changed: false,
      file_name: "",
      file_type: "",
      warningMsg: "",

      dialcode: "",
      dialcode_id: 1,
      email: "",
      full_name: "",
      phone_number: "",
      username: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      password_changed: false,
      is_dirty: false,
      save_and_redirect: false,
      next_route: false,
      closed: false
    }
  }

  addFile = (file, uploadtype) => {
    this.setState({
      is_dirty: true,
      profile_changed: true,
      logo_files: file.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    });
  };

  removeFile = (uploadtype) => {
    this.setState({
      is_dirty: true,
      logo_files: [],
      profile_pic: "",
    });
  }

  /*onFileChange = event => {
    let file = event.target.files[0];
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/JPG', 'image/PNG'];
    if (!acceptedImageTypes.includes(file.type)) {
      const message = "Please select image file jpeg,jpg,png";
      this.setState({ warningMsg: message });
    } else if (file.size > 1024 * 1000) {
      const message = "Please select file below 1MB";
      this.setState({ warningMsg: message });
      return true
    } else {
      this.setState({ warningMsg: "" });
      // Update the state
      const file_name = file.name;
      const file_type = file.type;
      this.setState({ selected_file: file, file_name, file_type });
    }
  };*/

  toggleEditMode = () => {
    this.setState({
      is_edit_mode: !this.state.is_edit_mode,
      is_dirty: false
    })
    console.log(":::::::::closed")
    this.setState({ password_changed: false })
    this.setState({
      old_password: "",
      new_password: "",
      confirm_password: ""
    })
    this.setState({ errors: '' })
  }

  toggleChangePassword = () => {
    let errors = this.state.errors;
    if (this.state.password_changed) {
      this.setState({
        old_password: "",
        new_password: "",
        confirm_password: ""
      })

      delete errors.old_password;
      delete errors.new_password;
      delete errors.confirm_password;
    }

    this.setState({ password_changed: !this.state.password_changed })
    delete errors.old_password;
    delete errors.new_password;
    delete errors.confirm_password;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, is_dirty: true });
  }

  handleBlur = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();
    this.setState({ [name]: trimmedValue, is_dirty: true });
  }

  getUserDetail = () => {
    this.props.dispatch(setAppilcationLoder(true));
    MyProfileService.getUserDetail().then(data => {
      if (data.response_code === 200) {
        const user_detail = data.result;
        this.setState({
          dialcode: user_detail.dialcode,
          dialcode_id: user_detail.dialcode_id,
          email: user_detail.email,
          full_name: user_detail.full_name,
          phone_number: user_detail.phone_number,
          username: user_detail.username,
          // profile_pic: user_detail.profile_pic,
          // qualified_url: user_detail.qualified_url
        })
        this.setState({
          logo_files: user_detail.qualified_url ? [{ preview: StorageData.getQualifiedFileUrl(user_detail.qualified_url) }] : [],
          profile_pic: user_detail.profile_pic
        })
      }
      this.props.dispatch(setAppilcationLoder(false));
    })
  };

  updateProfile = async (e) => {
    //e.preventDefault();
    this.props.dispatch(setAppilcationLoder(true));
    this.setState({ errors: {}, is_dirty: false });
    let data = {
      "full_name": this.state.full_name,
      "username": this.state.username,
      "email": this.state.email,
      "phone_number": this.state.phone_number,
      "old_password": this.state.old_password,
      "new_password": this.state.new_password,
      "confirm_password": this.state.confirm_password,
      "profile_pic": this.state.profile_pic,
      "dialcode": this.state.dialcode
    }
    let errors = validateUser(data);
    if (Object.keys(errors).length === 0) {

      let updateData = {
        "full_name": this.state.full_name,
        "username": this.state.username,
        "email": this.state.email,
        "dialcode_id": this.state.dialcode_id,
        "phone_number": this.state.phone_number,
        "old_password": !!this.state.old_password ? this.state.old_password : null,
        "new_password": !!this.state.new_password ? this.state.new_password : null,
        "profile_pic": this.state.profile_pic,
      }

      // upload image here
      if (this.state.logo_files.length && this.state.profile_changed) {
        const logo_files = this.state.logo_files[0];
        const file_name = logo_files.name;
        const file_type = logo_files.type;
        const signed_url_data = await MasterService.getSignedUploadFile(logo_files, file_name, file_type, PROFILE_MANAEGEMT_FILE_CODE)
        if (signed_url_data) {
          updateData.profile_pic = signed_url_data.gcp_subpath;
        }
      } else {
        updateData.profile_pic = this.state.profile_pic;
      }


      /*if (!!this.state.selected_file) {
        const signed_url_data = await MasterService.getSignedUploadFile(this.state.selected_file, this.state.file_name, this.state.file_type, PROFILE_MANAEGEMT_FILE_CODE)
        if (signed_url_data) {
          updateData.profile_pic = signed_url_data.gcp_subpath;
        }
      }*/

      MyProfileService.updateProfile(updateData).then(
        (resp_data) => {
          this.props.dispatch(setAppilcationLoder(false));
          this.setState({
            errors: {}
          });
          if (resp_data.response_code == 200) {
            toast.success(resp_data.message);
            data.qualified_url = resp_data.result.qualified_url;
            this.setState({
              qualified_url: data.qualified_url,
              is_edit_mode: true,
              old_password: "",
              new_password: "",
              confirm_password: ""
            })
            SessionManagement.updateUserDetail(data);
            const user_detail = SessionManagement.getUserDetail();
            this.props.dispatch(setUserDetail(user_detail));

            if (this.state.save_and_redirect) {
              this.props.history.push(this.state.next_route)
            }
          } else if (resp_data.response_code == 400) {
            toast.error(resp_data.message);
            this.setState({ errors: resp_data.reason, save_and_redirect: false, next_route: false });
          } else {
            toast.error(resp_data.message);
            this.setState({ save_and_redirect: false, next_route: false });
          }
        });

    } else {
      this.props.dispatch(setAppilcationLoder(false));
      this.setState({
        loading: false,
        errors: errors,
        save_and_redirect: false,
        next_route: false
      });
      toast.error("Please provide valid input")
    }
  }

  componentDidMount() {
    this.getUserDetail()
  }

  saveBeforeLeave = (nextRoute) => {
    this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
      this.updateProfile()
    });
  }

  closeAction = () => {
    this.setState({ closed: false })

  }

  render() {
    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">
          <ScrollPage />
          <RouteLeavingGuard
            when={this.state.is_dirty}
            saveChanges={this.saveBeforeLeave}
            closed={this.state.closed}
            closeAction={this.closeAction}
          />

          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <BreadCrum level={2} />
                  <h2 className="breadcrumb-title-new">My Profile</h2>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

          <div className="form-inner-blue-new mb-1">
            {/* Name Bar */}
            <div className="row">
              <div className="col-12">
                <div className="heading-blue-border d-flex align-items-baseline gap-10">
                  <h2 className="mb-0">{this.state.full_name}</h2>
                  {this.state.is_edit_mode ?
                    <span class="icon-edit size-xxvii cpointer" onClick={() => this.toggleEditMode()}></span>
                    :
                    <span class="icon-Cross-with-grey-circle size-xxvii cpointer" onClick={() => this.toggleEditMode()}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                  }

                  {/* {this.state.is_edit_mode ?
                    <div className="size-xxvii cpointer mb-0" onClick={() => this.toggleEditMode()}><span class="icon-edit"></span></div>
                    :
                    <div className="size-xxvii cpointer mb-0" onClick={() => this.toggleEditMode()}><span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></div>
                  } */}
                </div>
              </div>
            </div>
            {/* End Name Bar */}


            {/* Form */}
            <div className="row my-2">
              {/* Upload Image */}
              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
                <React.Fragment>
                  {/* <div className="d-flex flex-column align-items-center justify-content-center"> */}
                  <div className="d-flex flex-column align-items-center ">
                    <ImageUpload addFile={this.addFile} removeFile={this.removeFile} uploadtype="profile_pic" files={this.state.logo_files} editMode={this.state.is_edit_mode} />
                  </div>
                </React.Fragment>
              </div>
              {/* End Upload Image */}
              
              <div className="col-xl-6 col-lg-7 col-md-7">
                <div className="row">
                  <div className="col-lg col-md">
                    <div className="form-group">
                      <lable className="lable-input">Profile Name<span className="red-star">*</span></lable>
                      <input type="text"
                        className={"form-control " + (this.state.errors.full_name ? " wrong-red " : "")}
                        value={this.state.full_name}
                        disabled={!!this.state.is_edit_mode}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        name="full_name" />
                      {this.state.errors.full_name && <small className="form-error-text">{this.state.errors.full_name}</small>}
                    </div>
                  </div>

                  <div className="col-lg-1 col-md-2"></div>

                  <div className="col-lg col-md">
                    <div className="form-group">
                      <lable className="lable-input">Username<span className="red-star">*</span></lable>
                      <input type="text"
                        className={"form-control " + (this.state.errors.username ? " wrong-red " : "")}
                        value={this.state.username}
                        disabled={!!this.state.is_edit_mode}
                        onChange={this.handleChange}
                        name="username" />
                      {this.state.errors.username && <small className="form-error-text">{this.state.errors.username}</small>}
                    </div>
                  </div>
                </div>

                <div className="row gray-bg py-2 rounded-lg">
                  <div className="col-lg col-md-5">
                    <div className="form-group">
                      <lable className="lable-input">Email<span className="red-star">*</span></lable>
                      <input type="text"
                        className={"form-control " + (this.state.errors.email ? " wrong-red " : "")}
                        value={this.state.email}
                        disabled={!!this.state.is_edit_mode || this.USER_DETAIL.is_owner == 1}
                        onChange={this.handleChange}
                        name="email" />
                      {this.state.errors.email && <small className="form-error-text">{this.state.errors.email}</small>}
                    </div>
                  </div>

                  <div className="col-lg-1 col-md-2 d-flex align-items-center justify-content-center">OR</div>

                  <div className="col-lg col-md-5">
                    <div className="form-group">
                      <lable className="lable-input">Mobile Number<span className="red-star">*</span></lable>
                      <NumberTypeInputBox type="text"
                        className={"form-control " + (this.state.errors.phone_number ? " wrong-red " : "")}
                        value={this.state.phone_number}
                        disabled={!!this.state.is_edit_mode}
                        onChange={this.handleChange}
                        maxLength={10}
                        name="phone_number" />
                      {this.state.errors.phone_number && <small className="form-error-text">{this.state.errors.phone_number}</small>}
                    </div>
                  </div>
                </div>

                {/* Change Password */}
                {!this.state.is_edit_mode &&
                  <>
                    <div className="row mt-3">
                      <div className="col-lg-12">
                        <Button type="button" variant="success apply_btn_new" onClick={() => this.toggleChangePassword()}>Change Password</Button>
                      </div>
                    </div>

                    {this.state.password_changed &&
                      <React.Fragment>
                        <div className="row mt-3 change-password rounded-lg">
                          <span class="ss-cross-md" onClick={() => this.toggleChangePassword()}><span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>

                          <div className="col-lg-4 col-md-6">
                            <div className="form-group mb-0">
                              <lable className="lable-input">Old Password</lable>
                              <input type="password"
                                className={"form-control " + (this.state.errors.old_password ? " wrong-red " : "")}
                                value={this.state.old_password}
                                onChange={this.handleChange}
                                name="old_password" />
                              {this.state.errors.old_password && <small className="form-error-text">{this.state.errors.old_password}</small>}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-xs-12">
                            <div className="form-group mb-0">
                              <lable className="lable-input">New Password</lable>
                              <input type="password"
                                className={"form-control " + (this.state.errors.new_password ? " wrong-red " : "")}
                                value={this.state.new_password}
                                onChange={this.handleChange}
                                name="new_password" />
                              {this.state.errors.new_password && <small className="form-error-text">{this.state.errors.new_password}</small>}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-xs-12">
                            <div className="form-group mb-0">
                              <lable className="lable-input">Confirm Password</lable>
                              <input type="password"
                                className={"form-control " + (this.state.errors.confirm_password ? " wrong-red " : "")}
                                value={this.state.confirm_password}
                                onChange={this.handleChange}
                                name="confirm_password" />
                              {this.state.errors.confirm_password && <small className="form-error-text">{this.state.errors.confirm_password}</small>}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  </>
                }
                {/* End Change Password */}
              </div>
            </div>
             {/* End Form */}
          </div>

          {/* Save */}
          {(!this.state.is_edit_mode || this.state.password_changed) && <div className="row mt-3">
              <div className="col-12 text-right">
                <Button type="button" variant="success apply_btn_new" onClick={this.updateProfile} disabled={!this.state.is_dirty}>Save</Button></div>
            </div>}
          {/* End Save */}
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(MyProfile);