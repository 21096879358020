import React from 'react'
// import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <div>
      {/* <div>
        <a href="https://buktec.co.in" target="_blank" rel="noopener noreferrer">Buktek</a>
        <span className="ml-1">&copy; 2020 Buktec.</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">Powered by</span>
        <a href="https://buktec.co.in" target="_blank" rel="noopener noreferrer">Buktek</a>
      </div> */}
    </div>
  )
}

export default React.memo(TheFooter)
