import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Dropzone from "react-dropzone";
import MasterService from '../../Services/MasterService';
import DocboxService from '../../Services/Docbox/DocboxService';
import { changeDateFormate } from '../../Helpers/DateFormat';
import ProgressBar from '../../Components/BoilerPlate/ProgressBar';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from 'react-toastify';
// import loaderGray from '../../Assets/img/load.gif';
import loaderGray from '../../Assets/img/loader-gray.gif';
import DirtyFieldModal from '../../Components/BoilerPlate/DirtyFieldModal';
import { getFileSize } from '../../Helpers/HelperFunction';
import Button from 'react-bootstrap/Button';

export default class AddFilesPopup extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.system_parameter)
        this.state = {
            uploaded_files: [],
            all_files: [],
            uploading: false,
            is_smart: true,
            uploading_file_percent: 0,
            file_url: "",
            file_uploaded_count: 0,
            file_inprogress_count: 0,
            file_rejected_count: 0,
            uploading_finished: false,
            //max_files: 25,
            //max_file_size: 1024 * 1000 * (10),
            interval_id: "",
            docboxfiles: [],
            dirty_popup_visible: false,
            drag_drop: true,
            max_files: this.props.system_parameter.docbox_upload_file_limit || 25,
            max_file_size: this.props.system_parameter.docbox_file_size || 50000000,
        }

        //console.log(this.props.snapshot_types)
    }

    componentDidMount = () => {
        window.addEventListener("dragover", function (e) {
            e = e || event;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e = e || event;
            e.preventDefault();
        }, false);
    }

    onDrop = async (accepted, rejected) => {
        let all_files = [];

        if (this.state.all_files.length > 0) {
            this.setState({ drag_drop: false })
            //return
        }

        rejected.forEach((file, index) => {
            let error_msg = ""
            file.errors.forEach((error, index) => {
                if (error.code == 'file-too-large') {
                    error_msg = "File is larger than valid file size"
                }
                // if(error.code == 'file-invalid-type') {
                //     if(error_msg == "") {
                //         error_msg = 'Invalid file type'
                //     } else {
                //         error_msg += ', Invalid file type'
                //     }
                // }
                if (error_msg == "") {
                    error_msg = 'Cannot read file'
                }
            })

            all_files.push({
                name: file.file.name,
                size: file.file.size,
                message: error_msg,
                status: 'rejected',
                uploading: false,
                uploading_file_percent: 0,
            })
        });

        accepted.forEach((file, index) => {
            all_files.push({
                name: file.name,
                size: file.size,
                message: 'Waiting...',
                status: 'accepted',
                file: file,
                uploading_file_percent: 0,
                uploading: false
            })
        });

        this.setState({ all_files: [...this.state.all_files, ...all_files], file_rejected_count: rejected.length }, () => {
            this.setState({
                all_files: this.state.all_files.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
            })
        })
    };

    setRejectedCount = async () => {
        var res = this.state.all_files.filter(val => {
            if (val.status == 'rejected') {
                return val
            }
        })
        this.setState({ file_rejected_count: res.length })
    }

    getFileSize = (size) => {
        size = parseInt(size)
        const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
        let i = 0;
        console.log(size)
        while (size > 900) {
            size /= 1000;
            console.log(size)
            i++;
        }
        console.log(i)
        let exact_size = (Math.round(size * 100) / 100) + ' ' + fSExt[i];
        return exact_size;
    }

    clearFiles = () => {
        this.setState({
            uploaded_files: [],
            all_files: [],
            uploading: false,
            is_smart: true,
            uploading_file_percent: 0,
            file_url: "",
            file_uploaded_count: 0,
            file_inprogress_count: 0,
            file_rejected_count: 0,
            drag_drop: true,
            uploading_finished: false,
            docboxfiles: []
        })
    }

    waitforme(milisec) {
        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, milisec);
        })
    }

    startUpload = async () => {
        //console.log(navigator.onLine)
        //return
        this.setState({ uploading: true })

        for (let i = 0; i < this.state.all_files.length; ++i) {
            let isOnline = navigator.onLine

            let files = this.state.all_files[i]
            let file = files.file
            if (files.status == 'accepted') {

                if (isOnline) {
                    files.uploading = true;
                    this.scrollTo('row' + i)

                    const file_type = file.type;
                    let blob = new Blob([file], { type: file_type });
                    let file_url = URL.createObjectURL(blob);
                    const file_data = await MasterService.uploadFileFromBlobUrl(file_url);

                    if (!file_data) {
                        message = "Error 101: Could not upload file";
                        files.status = 'rejected';
                        this.setRejectedCount()
                        files.uploading = false;
                    }

                    //add progress value
                    this.setState({ uploading_file_percent: 20 });

                    let signed_url_data = await MasterService.getSignedUploadFile(file_data, file.name, file.type, 2)
                    console.log(signed_url_data)

                    //add progress value

                    this.setState({ uploading_file_percent: 60 });


                    if (signed_url_data) {
                        if (signed_url_data == 'timeout') {
                            files.status = 'rejected';
                            files.message = "Could not connect to server";
                            files.uploading = false;
                            this.setRejectedCount()
                        } else {
                            let gcp_subpath = signed_url_data.gcp_subpath;
                            file_url = gcp_subpath;

                            await this.saveFilesToDropbox(file_url, i, files)
                        }

                    } else {
                        files.message = "Error 102 : Could not upload file";
                        files.status = 'rejected';
                        this.setRejectedCount()
                        files.uploading = false;
                    }
                } else {
                    files.status = 'rejected';
                    files.message = "Could not connect to server";
                    files.uploading = false;
                    this.setRejectedCount()

                }
            }

            await this.waitforme(1500);
        }

        let intervalId = setInterval(() => {
            this.checkIfFinished()
        }, 2000);

        this.setState({ interval_id: intervalId })

    }

    checkIfFinished = () => {
        if (this.state.all_files.length == (this.state.file_rejected_count + this.state.file_uploaded_count)) {
            this.setState({ uploading_finished: true, uploading: false })
            clearInterval(this.state.interval_id)
        }
    }

    saveFilesToDropbox = async (file_url, index, files) => {


        let detail = {
            "name": files.name,
            "size": files.size,
            "crum_path": this.props.getCrumbPath(),
            "is_folder": 0,
            "file_url": file_url,
            "parent_id": this.props.parent_id
        }
        console.log(detail);
        //return
        DocboxService.createFileFolder(detail)
            .then((data) => {
                let message = "";
                if (data.response_code == 200) {
                    this.setState({ uploading_file_percent: 100, file_uploaded_count: (this.state.file_uploaded_count + 1) });
                    message = "Uploaded";

                    this.setState(prevState => ({
                        docboxfiles: [...prevState.docboxfiles, data.result]
                    }))
                } else if (data.response_code == 400) {
                    files.status = 'rejected';
                    if (!!data.reason.name) {
                        message = data.reason.name;
                    } else {
                        message = "Error 104 : Could not upload file";
                    }


                } else if (data == 'timeout') {
                    files.message = "Could not connect to server";
                    files.status = 'rejected';
                } else {
                    files.message = "Could not connect to server";
                    files.status = 'rejected';
                }

                files.message = message;
                files.uploading = false;
                this.setRejectedCount()

                setTimeout(() => {
                    this.setState({ uploading_file_percent: 0 });
                }, 1000);
            })
    }

    finishUpload = () => {
        this.props.finishFilesUpload(this.state.docboxfiles);
        this.props.toggleBulkUploadPopup();
        this.clearFiles();
    }

    removeFile = (e, index, status) => {
        e.stopPropagation()
        if (this.state.uploading == false || !this.state.uploading_finished) {
            var array = [...this.state.all_files]; // make a separate copy of the array
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ all_files: array });
            }
            if (status == 'rejected') {
                this.setState({ file_rejected_count: (this.state.file_rejected_count - 1) })
            }
        }
    }

    scrollTo = (position) => {
        const project = document.getElementById(position);
        project.scrollIntoView();
    }

    onDontSave = () => {
        this.setState({ dirty_popup_visible: false })
        this.props.toggleBulkUploadPopup();
        this.clearFiles();
    }

    render() {
        console.log(this.state.all_files)
        return (
            <>
                <DirtyFieldModal
                    visible={this.state.dirty_popup_visible}
                    onCancel={() => this.setState({ dirty_popup_visible: false })}
                    onDontSave={this.onDontSave}
                    onClose={() => { }}
                />
                <Modal
                    show={this.props.show_bulk_upload_popup}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="popup-model bulk-popup-new"
                    onHide={() => { 
                        if(this.state.uploading_finished) {
                            this.finishUpload()
                        } else {
                            if(this.state.all_files.length > 0) {
                                this.setState({dirty_popup_visible: true}) 
                            } else {
                                this.props.toggleBulkUploadPopup();
                                this.clearFiles();
                            }
                        }
                    }}
                >
                    <Modal.Header closeButton={this.state.uploading ? false : true}>
                        <Modal.Title>Upload</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row mt-2">
                            <div className="col-8 d-flex align-items-center">
                                {this.state.all_files.length > this.state.max_files ?
                                    <p className="mb-0 red-text">Maximum {this.state.max_files} files can be uploaded at one time</p>
                                    : this.state.uploading_finished ? <p className="mb-0 dark-green-text">File upload completed</p> : <p className="mb-0">Maximum number of files: <b>{this.state.max_files}</b>, Maximum upload file size: <b>{getFileSize(this.state.max_file_size) || '10 MB'}</b></p>
                                }
                            </div>
                            <div className="col-4 text-right">
                                <Button type="button" variant="success apply_btn_new" onClick={this.clearFiles} disabled={this.state.uploading || this.state.all_files.length == 0 || this.state.uploading_finished}>Clear Selection</Button>
                            </div>
                        </div>

                        <div className="bulk-popup-brd">
                            {this.state.all_files.length == 0 &&
                                <>
                                    {/* <div className="bulk-popup-table"> */}
                                    <Dropzone
                                        multiple={true}
                                        //accept="image/jpg,image/jpeg,image/png,application/pdf"
                                        disabled={this.state.uploading || this.state.uploading_finished}
                                        onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                                        maxSize={this.state.max_file_size}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="w-100 cpointer">
                                                <input {...getInputProps()} />
                                                <div className="bulk-popup-table bulk-popup-pluse">
                                                    <p>
                                                        <span className={"icon-plus"}></span><br></br>
                                                        <span className="d-block mt-3 mb-3">Click here to select files</span>
                                                        <span className={"d-block mb-3"}>OR</span>
                                                        <span className={"d-block"}>Drag & drop files here</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {/* </div> */}
                                </>
                            }

                            {this.state.all_files.length != 0 &&
                                <>
                                    <Dropzone
                                        multiple={true}
                                        //accept="image/jpg,image/jpeg,image/png,application/pdf"
                                        disabled={this.state.uploading || this.state.uploading_finished}
                                        onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                                        maxSize={this.state.max_file_size}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="w-100 cpointer">
                                                <input {...getInputProps()} />
                                                <div className="bulk-popup-table" onClick={(e) => e.stopPropagation()}>
                                                    {/* <table class="table table-bordered mb-0"> */}
                                                    <table class="table borderTable mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="50px" className="text-center">#</th>
                                                                <th scope="col">File Name</th>
                                                                <th scope="col" width="110px" className="text-right">Size</th>
                                                                <th scope="col" width="35%">Status</th>
                                                                <th scope="col" width="50px" className="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.all_files.map((file, index) => {
                                                                return <tr className={file.status == 'rejected' ? 'errorbup' : ''} key={index} id={'row' + index}>
                                                                    <td className="text-center">{(index + 1)}</td>
                                                                    <td>{file.name}</td>
                                                                    <td className="text-right">{getFileSize(file.size)}</td>
                                                                    {file.uploading ?
                                                                        <td className="va-middle">
                                                                            <ProgressBar key={index} bgcolor={'#77d7b1'} completed={this.state.uploading_file_percent} />
                                                                        </td>
                                                                        :
                                                                        <td><span>{file.message}</span></td>
                                                                    }
                                                                    <td className="text-center">
                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                            {this.state.uploading_finished || this.state.uploading ?
                                                                                <span className="icon-delete gray-icon cursornot"></span>
                                                                                :
                                                                                <span onClick={(e) => this.removeFile(e, index, file.status)} className="icon-delete red-icon cpointer"></span>
                                                                            }
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </>
                            }
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center">
                                {(this.state.uploading || this.state.uploading_finished) &&
                                    <p className="bulk-upstatus">
                                        {this.state.uploading && <span><img src={loaderGray} className="img-fluid mr-2" style={{ height: '27px' }} /></span>}
                                        <span className="spanupst">Selected: {this.state.all_files.length}, </span>
                                        <span className="spanupst">Uploaded: {this.state.file_uploaded_count}, </span>
                                        <span className="spanupst">In Process: {(this.state.all_files.length - this.state.file_uploaded_count - this.state.file_rejected_count)}, </span>
                                        <span className="spanupst">Error: {this.state.file_rejected_count}</span>
                                    </p>
                                }
                            </div>

                            {/* <div className="col-lg-6 col-md-12 mt-0 mt-lg-0 mt-md-3 text-right d-flex align-items-center justify-content-end bottom-save-btn"> */}
                            <div className="col-lg-6 col-md-12 bulkb-save-btn">
                                <div className="bottom_save_btn ml-3">
                                    {(!this.state.uploading_finished) &&
                                        <Button type="button" variant="success apply_btn_new" onClick={this.startUpload} disabled={this.state.uploading || this.state.all_files.length == this.state.file_rejected_count || this.state.all_files.length > this.state.max_files}>Start Upload</Button>
                                    }

                                    {this.state.uploading_finished &&
                                        <Button type="button" variant="success apply_btn_new" onClick={this.finishUpload} disabled={this.state.uploading}>Finish</Button>
                                    }
                                </div>

                                {/* <div className="main-content-sec pb-0">
                                    <div className="inner-content-sec">
                                        <div className="main-file-item-sec">
                                            <div className="main-toggle-btn-sec mb-0">
                                                <div className="toggle-btn-sec mb-0 border-0">
                                                    <span className="toggle-btn-sec-text s-sbold mr-4">Smart Scan</span>
                                                    
                                                    <span className="d-flex align-items-center">
                                                        <span className="toggle-btn-sec-text s-sbold">No</span>
                                                        <label className="switch">
                                                            <input type="checkbox" value={this.state.is_smart} disabled={this.state.uploading || this.state.all_files.length == 0 || this.state.uploading_finished} checked={this.state.is_smart}
                                                                onChange={(e) => {
                                                                    const target = e.target;
                                                                    this.setState({ is_smart: target.checked});
                                                                }}/>
                                                            <span className={"slider round "+ ((this.state.uploading || this.state.uploading_finished ||  this.state.all_files.length == 0) ? "disable-any disable-opacity" : "")}></span> 
                                                        </label>
                                                        <span className="toggle-btn-sec-text s-sbold">Yes</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
