import React from "react";
import {
  Route,
  Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import StorageData from "../../Helpers/StorageData";
import SessionManagement from "../../Utils/SessionManagement";
import { confirm } from '../BoilerPlate/Confirm';


const PrivateRoute = ({component: Component, ...rest}) => {

  const loginpopup = async () =>{
    await confirm({
      confirmation: 'Please login to continue.',
      options: { type: "alert" }
    });
  }

  const Authentication = SessionManagement.isLogin();
  if(!Authentication) {
    let pathname = window.location.pathname;
    SessionManagement.RemoveAll();
    StorageData.deleteAllStorageValue();
    SessionManagement.setLastUrl(pathname);

    //console.log(pathname)
    loginpopup();
   
  }
  
  return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route {...rest} render={props => (
          SessionManagement.isLogin() ?
              <Component {...props} />
          : <Redirect to="/login" />
      )} />
  );
};

export default PrivateRoute;