import React from 'react';
import { Dropdown,Button } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import badge from '../../Assets/img/badge.png';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";;
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
function myhome(){

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span class="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span class="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4408"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span class="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-lightbulb"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4402"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-5305"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span class="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-to-do-list"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-chat1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4403"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4404"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4405"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
      <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <div className="main-home-sec">
                <div className="row">
                  <div className="col-xl-8 col-md">
                    <div className="media right-profile-img-sec">
                      <img className="profile-big-img mr-3" src={profile_sm} />
                      <div className="media-body right-profile-text">
                        <p className="ft-sz-32 s-bold blu-color mb-1">Hello Mahesh Raje!</p>
                        <p className="ft-sz-22 s-sbold blu-color">Welcome back! see what we got since your last visit</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md">
                    <div className="media cong-sec">
                      <img className="ml-3 mr-4" src={badge} />
                      <div className="media-body right-profile-text">
                        <p className="ft-sz-32 s-bold blu-color mb-1">Congatulations!</p>
                        <p className="ft-sz-22 s-sbold blu-color">All 500 evidences are successfully Synched</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-three-box-sec">
                  <div className="row">
                    <div className="col-xl-4 col-md-12">
                      <div className="inner-box-sec">
                        <p className="title-head ft-sz-32 s-bold blu-color">Messages</p>
                        <div className="message-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                              <p className="ft-sz-16 s-reg blu-color">Have you made TDS Payment?</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title noti-sec s-sbold red-bg white-color">2</p>
                              <p className="ft-sz-14 s-bold gren-color">3:30pm</p>
                            </div>
                          </div>
                        </div>
                        <div className="message-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">Renuka Bhande</p>
                              <p className="ft-sz-16 s-reg blu-color">Can you check Evidence ID #125345678</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title noti-sec s-sbold red-bg white-color">2</p>
                              <p className="ft-sz-14 s-bold gren-color">04-01-2021</p>
                            </div>
                          </div>
                        </div>
                        <div className="message-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                              <p className="ft-sz-16 s-reg blu-color">Have you made TDS Payment?</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title noti-sec s-sbold red-bg white-color">2</p>
                              <p className="ft-sz-14 s-bold gren-color">3:30pm</p>
                            </div>
                          </div>
                        </div>
                        <div className="message-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                              <p className="ft-sz-16 s-reg blu-color">Have you made TDS Payment?</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title noti-sec s-sbold red-bg white-color">2</p>
                              <p className="ft-sz-14 s-bold gren-color">3:30pm</p>
                            </div>
                          </div>
                        </div>
                        <div className="message-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">Renuka Bhande</p>
                              <p className="ft-sz-16 s-reg blu-color">Can you check Evidence ID #125345678</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title noti-sec s-sbold red-bg white-color">2</p>
                              <p className="ft-sz-14 s-bold gren-color">04-01-2021</p>
                            </div>
                          </div>
                        </div>
                        <div className="message-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                              <p className="ft-sz-16 s-reg blu-color">Have you made TDS Payment?</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title noti-sec s-sbold red-bg white-color">2</p>
                              <p className="ft-sz-14 s-bold gren-color">3:30pm</p>
                            </div>
                          </div>
                        </div>
                        <p className="all-text-sec mb-0"><a className="ft-sz-16 s-bold blu-color" href="#">See all</a></p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-12">
                      <div className="inner-box-sec">
                        <p className="title-head ft-sz-32 s-bold blu-color">Tasks</p>
                        <div className="row task-count-box-sec">
                          <div className="col-lg-4 col">
                            <div className="count-box-sec yellow-bg white-color text-center">
                              <p class="count-title ft-sz-18 s-sbold m-0">New</p>
                              <div class="count-char s-bold">47</div>
                            </div>
                          </div>
                          <div className="col-lg-4 col">
                            <div className="count-box-sec drk-blu-bg white-color text-center">
                              <p class="count-title ft-sz-18 s-sbold m-0">Open</p>
                              <div class="count-char s-bold">26</div>
                            </div>
                          </div>
                          <div className="col-lg-4 col">
                            <div className="count-box-sec green-bg white-color text-center">
                              <p class="count-title ft-sz-18 s-sbold m-0">Done</p>
                              <div class="count-char s-bold">55</div>
                            </div>
                          </div>
                        </div>
                        <div className="message-box task-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">new!</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="ft-sz-16 s-reg font-colr">Raj Kumar</div>
                            <div class="ft-sz-16 s-reg font-colr">Due: 22-04-2021</div>
                            <div class="ft-sz-14 s-bold gren-color mt-1">3:30pm</div>
                          </div>
                        </div>
                        <div className="message-box task-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">new!</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="ft-sz-16 s-reg font-colr">Raj Kumar</div>
                            <div class="ft-sz-16 s-reg font-colr">Due: 22-04-2021</div>
                            <div class="ft-sz-14 s-bold gren-color mt-1">3:30pm</div>
                          </div>
                        </div>
                        <div className="message-box task-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">new!</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="ft-sz-16 s-reg font-colr">Raj Kumar</div>
                            <div class="ft-sz-16 s-reg font-colr">Due: 22-04-2021</div>
                            <div class="ft-sz-14 s-bold gren-color mt-1">3:30pm</div>
                          </div>
                        </div>
                        <div className="message-box task-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">new!</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="ft-sz-16 s-reg font-colr">Raj Kumar</div>
                            <div class="ft-sz-16 s-reg font-colr">Due: 22-04-2021</div>
                            <div class="ft-sz-14 s-bold gren-color mt-1">3:30pm</div>
                          </div>
                        </div>
                        <div className="message-box task-box">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="head-title ft-sz-18 s-sbold blu-color">Mihir Joshi</p>
                            </div>
                            <div className="text-right">
                              <p className="head-title ft-sz-18 s-sbold yellow-color">new!</p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="ft-sz-16 s-reg font-colr">Raj Kumar</div>
                            <div class="ft-sz-16 s-reg font-colr">Due: 22-04-2021</div>
                            <div class="ft-sz-14 s-bold gren-color mt-1">3:30pm</div>
                          </div>
                        </div>
                        <p className="all-text-sec mb-0"><a className="ft-sz-16 s-bold blu-color" href="#">See all</a></p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-12">
                      <div className="inner-box-sec">
                        <p className="title-head ft-sz-32 s-bold blu-color">My Recent Activities</p>
                        
                        {/* <div className="recent-head-sec">
                          <div className="d-flex">
                            <div className="mr-auto">
                              <p className="ft-sz-18 s-sbold blu-color">Type</p>
                            </div>
                            <div className="text-right">
                              <p className="ft-sz-18 s-sbold blu-color">Last Activity</p>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="recent-activity-box-sec">
                          <div className="d-flex justify-content-between">
                            <div className="margin-top ft-sz-13 s-sbold"><span className="evi-chq-text">Cheques</span></div>
                            <div className="margin-top ft-sz-17 s-sbold font-colr"><span>Narayana Plaza</span></div>
                            <div className="margin-top ft-sz-18 s-sbold blu-color"><span>₹5000</span> <span class="icon-Red-arrow"></span></div>
                            <div className="margin-top "><span className="icon-Group-2508 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span></div>
                            <div className=""><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></div>
                          </div>
                        </div> */}
                        <div className="recent-activity-box-sec">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th className="ft-sz-18 s-sbold blu-color pt-0">Module</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th className="ft-sz-18 s-sbold blu-color text-right pt-0">Last Activity</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><span className="evi-chq-text">Cheques</span></td>
                                <td><span>Narayana Plaza</span></td>
                                <td><span>₹5000</span> <span class="icon-Red-arrow"></span></td>
                                <td><span className="icon-Group-2508 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr> 
                              <tr>
                                <td><span className="evi-chq-text">Evidence</span></td>
                                <td><span>Joshi Foods</span></td>
                                <td><span>₹5000</span> <span class="icon-Green-arrow"></span></td>
                                <td><span class="icon-Group-2510 ft-sz-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr>
                              <tr>
                                <td><span className="evi-chq-text">Invoice</span></td>
                                <td><span>Narayana Plaza</span></td>
                                <td><span>₹5000</span> <span class="icon-Red-arrow"></span></td>
                                <td><span className="icon-Group-2508 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr>
                              <tr>
                                <td><span className="evi-chq-text">Link Evidence</span></td>
                                <td><span>Narayana Plaza</span></td>
                                <td><span>₹5000</span> <span class="icon-Red-arrow"></span></td>
                                <td><span className="icon-Group-25071 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr>
                              <tr>
                                <td><span className="evi-chq-text">Evidence</span></td>
                                <td><span>Joshi Foods</span></td>
                                <td><span>₹5000</span> <span class="icon-Green-arrow"></span></td>
                                <td><span class="icon-Group-2510 ft-sz-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr>
                              <tr>
                                <td><span className="evi-chq-text">Evidence</span></td>
                                <td><span>Joshi Foods</span></td>
                                <td><span>₹5000</span> <span class="icon-Green-arrow"></span></td>
                                <td><span class="icon-Group-2510 ft-sz-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr>
                              <tr>
                                <td><span className="evi-chq-text">Evidence</span></td>
                                <td><span>Joshi Foods</span></td>
                                <td><span>₹5000</span> <span class="icon-Green-arrow"></span></td>
                                <td><span class="icon-Group-2510 ft-sz-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></td>
                                <td className=" text-right"><p className="mb-0 ft-sz-16 s-reg font-colr">15-02-2020</p><p className="mb-0 ft-sz-16 s-reg font-colr">7:48 pm</p></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="sticky-footer d-none">
          <div className="container">
            <div className="text-center">
              <small>Copyright © Buktec 2021</small>
            </div>
          </div>
        </footer>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}






export default myhome;