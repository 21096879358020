import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setChequeFilter } from '../../Redux/Actions/Cheque';
import DatePicker from "react-datepicker";
import ChequeService from '../../Services/Cheques/ChequeService';


class ChequeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: null,
            to_date: null,
            is_star: null,
            uploaded_by: null,
            cheque_no: null,
            bank_name: null,
            cheque_identifier: null,
            payer: null,
            payee: null,
            from_amount: null,
            to_amount: null,

            uploaded_by_list: []
        }
    }

    componentDidMount() {
        ChequeService.getUploadByList().then(data => {
            if (data.response_code == 200) {
                console.log('up list',data)
                this.setState({ uploaded_by_list: data.result.uploaded_by })
            }
        })
    }

    handleChange = (e) => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    }

    apply = () => {
        let data = {
            from_date: this.state.from_date,
            to_date: this.state.to_date,
            is_star: this.state.is_star,
            uploaded_by: this.state.uploaded_by,
            cheque_no: this.state.cheque_no,
            bank_name: this.state.bank_name,
            cheque_identifier: this.state.cheque_identifier,
            payer: this.state.payer,
            payee: this.state.payee,
            from_amount: this.state.from_amount,
            to_amount: this.state.to_amount
        }
        this.props.dispatch(setChequeFilter(data));
        this.props.applyFilter("apply");
    }

    reset = () => {
        let filter_data = {
            from_date: null,
            to_date: null,
            is_star: null,
            uploaded_by: null,
            cheque_no: null,
            bank_name: null,
            cheque_identifier: null,
            payer: null,
            payee: null,
            from_amount: null,
            to_amount: null
        }
        this.setState({
            from_date: null,
            to_date: null,
            is_star: "",
            uploaded_by: "",
            cheque_no: "",
            bank_name: "",
            cheque_identifier: "",
            payer: "",
            payee: "",
            from_amount: "",
            to_amount: ""
        })
        this.props.dispatch(setChequeFilter(filter_data));
        this.props.applyFilter("reset");
    }

    setDate = (date, key) => {
        this.setState({ [key]: date })
    }
    render() {
        return (
            <div className="main-filter-sec clearfix">
                <div className="row inner-row">
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <Form>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control as="select" name="is_star" value={this.state.is_star} onChange={this.handleChange}  >
                                        <option value="">Starred</option>
                                        <option value="1" >Yes</option>
                                        <option value="0">No</option>
                                        <option value={null}>Both</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <input type="text" className="form-control" name="cheque_identifier"
                                value={this.state.cheque_identifier} placeholder="ID" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <input type="text" className="form-control" value={this.state.bank_name}
                                name="bank_name" placeholder="Bank" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <input type="text" className="form-control" value={this.state.cheque_no}
                                name="cheque_no" placeholder="Cheque#" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-xs-12">
                        <div className="row main-due-date-sec evidence-filter no-gutters">
                            <div className="col">
                                <p className="mt-2 ft-sz-17 s-sbold mb-0 text-right mr-3">Cheque Date</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <div className="form-group date-sec">
                                    <div className="inner-date-sec">
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={date => this.setDate(date, "from_date")}
                                            className="form-control"
                                            selected={this.state.from_date}
                                            autoComplete="off"
                                            placeholderText="From Date"
                                            name="from_date" />
                                        <span className="icon-calendar"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <hr />
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <div className="form-group date-sec">
                                    <div className="inner-date-sec">
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            onChange={date => this.setDate(date, "to_date")}
                                            className="form-control"
                                            selected={this.state.to_date}
                                            autoComplete="off"
                                            placeholderText="To Date"
                                            name="to_date" />
                                        <span className="icon-calendar"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row inner-row">
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <input type="text" className="form-control" value={this.state.payer}
                                name="payer" placeholder="Payer" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <input type="text" className="form-control" value={this.state.payee}
                                name="payee" placeholder="Payee" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-xs-12">
                        <div className="row main-due-date-sec evidence-filter no-gutters">
                            <div className="col">
                                <p className="mt-2 ft-sz-17 s-sbold mb-0 text-right mr-3">Amount</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <div className="form-group date-sec">
                                    <div className="inner-date-sec">
                                        <input type="text" className="form-control text-right" name="from_amount"
                                            value={this.state.from_amount} placeholder="₹ 0" onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <hr />
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <div className="form-group date-sec">
                                    <div className="inner-date-sec">
                                        <input type="text" className="form-control text-right" name="to_amount"
                                            value={this.state.to_amount} placeholder="₹ 0" onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-12">
                        <div className="form-group">
                            <Form>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control as="select" name="uploaded_by" value={this.state.uploaded_by} onChange={this.handleChange}>
                                        <option value={null}>Uploaded By</option>
                                        {this.state.uploaded_by_list.map((item, index) => {
                                            return <option value={item.id}>{item.full_name}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold" onClick={this.apply}>Apply</button>
                <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg" onClick={this.reset}>Reset</button>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const all_value = state.cheque

    return {

    };
}


export default connect(mapStateToProps)(ChequeFilter);