import React from 'react'
import { Accordion, Card, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LINKING_CODE,LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
import { setBusinessNarration } from '../../Redux/Actions/MoneyLink';

class BusinessNarrationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleChange = (e) => {
    this.props.isFormDirty()
    this.props.dispatch(setBusinessNarration(e.target.value))
  }

  handleTabClick = () => {
    let testElements = document.getElementsByClassName('collapse');
    if (testElements[2].classList.length === 2) {
    }else{
      // if(this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)){
      //   document.getElementById("ledgerTab").classList.remove('show');
      // }
      // document.getElementById("docboxTab").classList.remove('show');
      // document.getElementById("searchTab").classList.remove('show');

    }
  }
 
  render() {
    const read_only = this.props.read_only
    return (
      <Card className='buss_nar_moneyin'>
        <Accordion key={2}>
          <Accordion.Toggle as={Card.Header} eventKey="2" 
            onClick={()=>this.handleTabClick()} className={"ft-sz-24 s-sbold blu-color "+(this.props.business_narration != null && this.props.business_narration != ""?"active-accordion":" ")}>
            Business Narration
                <div className="drop-arrow">
              <span className="icon-arrow-down-sign-to-navigate"></span>
            </div>
            <div className="clearfix"></div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2" id="narattionTab">
            <Card.Body>
              {/* <p className="ft-sz-18 s-sbold blu-color pull-left mt-1">Business notes</p> */}
              {/* <p className="lable-input font-colr">Business Notes</p> */}
              <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-1">
                <Form.Control as="textarea" rows={2} onChange={this.handleChange}
                  readOnly={this.props.features_permissions_list.includes(LINKING_CODE) && !read_only ? false : true}
                  name="business_narration" value={this.props.business_narration}
                />
              </Form.Group>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Card>

    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const business_narration = all_value.business_narration;

  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];
  return {
    business_narration, features_permissions_list
  };
}

export default connect(mapStateToProps)(BusinessNarrationTab);
