import React, { Component, ReactDOM } from 'react';
import Button from 'react-bootstrap/Button';

class OtpInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = { otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "", show_error: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(value1, event) {
    this.setState({ [value1]: event.target.value });
  }

  componentWillUnmount() {
    this.setState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "", show_error: false })
  }

  handleSubmit(event) {
    //console.log(this.state);
    event.preventDefault();
    if (this.state.otp1 && this.state.otp2 && this.state.otp3 && this.state.otp4 && this.state.otp5 && this.state.otp6) {
      let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6;

      let regex = /^[0-9]{1,6}$/;
      let otp_valid = otp.match(regex);
      if (otp_valid) {

        this.setState({ show_error: false })
        this.props.getOTP(otp)
      } else {
        this.setState({ show_error: true })
      }
    } else {
      this.setState({ show_error: true })
    }
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus()
      }
    }
  }

  clearOTP = () => {
    this.setState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group otpContainer">
          <input
            name="otp1"
            type="text"
            autoComplete="off"
            className="form-control otpInput"
            value={this.state.otp1}
            onKeyPress={this.keyPressed}
            onChange={e => this.handleChange("otp1", e)}
            tabIndex="1" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
          <input
            name="otp2"
            type="text"
            autoComplete="off"
            className="form-control otpInput"
            value={this.state.otp2}
            onChange={e => this.handleChange("otp2", e)}
            tabIndex="2" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
          <input
            name="otp3"
            type="text"
            autoComplete="off"
            className="form-control otpInput"
            value={this.state.otp3}
            onChange={e => this.handleChange("otp3", e)}
            tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
          <input
            name="otp4"
            type="text"
            autoComplete="off"
            className="form-control otpInput"
            value={this.state.otp4}
            onChange={e => this.handleChange("otp4", e)}
            tabIndex="4" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
          <input
            name="otp5"
            type="text"
            autoComplete="off"
            className="form-control otpInput"
            value={this.state.otp5}
            onChange={e => this.handleChange("otp5", e)}
            tabIndex="5" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
          <input
            name="otp6"
            type="text"
            autoComplete="off"
            className="form-control otpInput"
            value={this.state.otp6}
            onChange={e => this.handleChange("otp6", e)}
            tabIndex="6" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
        </div>

        <div class="clearfix">
          {this.state.show_error && <small className="error_right">Please enter valid OTP</small>}
        </div>

        <div class="d-flex align-items-baseline justify-content-between w-100 mt-3">
          {this.props.gstr2b_error && <Button type="reset" disabled={!this.props.gstr2b_error} variant="outline-success reset_btn_new" onClick={() => {
            this.setState({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "", show_error: false })
            this.props.handleResendOTP()}}>Resend OTP</Button>}

          <div className="reset_apply_btn_new ml-auto">
            <Button type="reset" variant="outline-success reset_btn_new" onClick={this.props.handleCancel}>Cancel</Button>
            <Button type="submit" variant="success apply_btn_new" onClick={this.applyFilter}>Submit</Button>
          </div>
        </div>
      </form>
    );
  }
}

export default OtpInput;