import React, { Component } from "react";
import Select, { components } from "react-select";
import { confirm } from "../BoilerPlate/Confirm";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class MerchantDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_edit: false,
            is_new: this.props.is_new,
            merchant_option_id: null,
            new_value: this.props.new_value,
            option: {},
            selected_option: this.props.selected_option,
            selected_value: this.props.selected_option.value
        };
        // console.log(this.props)
    }

    handleSelectChange = (e) => {
        if (!e.target.value) {
            this.props.onMerchantChanged({ label: null, value: ""});
            return;
        }
        if (e.target.value == -1) {
            this.setState({
                new_value: null,
                is_new: true
            });
            //alert('aa')
            this.props.changeAddNew(true)
            this.props.onMerchantChanged({ label: null, value: "", __isNew__: true });
        } else {
            let merchant = this.props.merchant_list.find(merchant => merchant.value == e.target.value)
            merchant.is_edit = 0;
            merchant.__isNew__ = false;

            this.setState({
                option: merchant,
            });
            
            this.props.onMerchantChanged(merchant);
        }
    }

    handleTypeChange = (e) => {
        var newOption = {
            label: e.target.value,
            value: 0,
            is_edit: 0,
            __isNew__: true
        };

        this.setState({
            option: newOption,
        });
        //console.log(newOption)
        this.props.onMerchantChanged(newOption);
    }

    showSelectDropdown = () => {
        this.setState({ is_new: false })
        this.props.changeAddNew(false)
        this.props.onMerchantChanged({ label: null, value: 0 });
    }

    handleEditChange = (e) => {
        let merchant_option = this.props.selected_option;
        merchant_option.label = e.target.value;
        merchant_option.is_edit = 1;
        merchant_option.__isNew__ = false;
        this.props.onMerchantChanged(merchant_option, true);        
    }

    render() {
        //console.log(this.props)
        let merchant_list = this.props.merchant_list;
         return (
            <>
                {this.props.is_edit ?
                    <>
                        <input
                            type="text"
                            className={"form-control pr-2 " + (this.props.global_preidction_status && (!!this.props.merchant_name_prediction_status && !!this.props.merchant_id_prediction_status) ? " input-bg-green " : "")}
                            value={this.props.selected_option.label}
                            onChange={this.handleEditChange}
                            disabled={this.props.isDisabled}
                            name="merchant"
                            //autoFocus
                            onBlur={() => this.props.onBlur}
                        />
                    </>
                    :
                    !this.props.is_new ?
                        <>
                            {/* <div className="position-relative"> */}
                            <select
                               className={this.props.isExcel?"form-control":("form-control " + (this.props.global_preidction_status && !!this.props.merchant_name_prediction_status ? " input-bg-green " : ""))}
                                value={this.props.selected_value}
                                onChange={this.handleSelectChange}
                                disabled={this.props.isDisabled}
                                name="merchant" onBlur={() => this.props.onBlur}
                            >
                                {!this.props.isHiddenOpt &&
                                <option value="" hidden className="white-bg">{this.state.selected_option.value ? this.props.selected_option.label : 'Select'}</option>
                                }
                                <option value="" className="white-bg">Select</option>
                                <option value="-1" className="green-text white-bg">ADD NEW</option>
                                {
                                    merchant_list.map((merchant, index) => {
                                        return <option key={index} value={merchant.value} className="white-bg">{merchant.label}</option>
                                    })
                                }
                            </select>
                            {/* {this.props.selected_option.value && <span onClick={this.showSelectDropdown} className="icon-Cross-with-grey-circle ft-sz-22 cross-input"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>} */}
                            {/* </div> */}
                        </>
                        :
                        (
                            <div className="d-flex align-items-center">
                                <input
                                    type="text"
                                    className={"form-control pr-4 " + (this.props.global_preidction_status && (!!this.props.merchant_name_prediction_status && !!this.props.merchant_id_prediction_status) ? " input-bg-green " : "")}
                                    //value={this.props.new_value}
                                    value={this.props.selected_option.label}
                                    onChange={this.handleTypeChange}
                                    disabled={this.props.isDisabled}
                                    name="merchant"
                                    //autoFocus
                                    onBlur={() => this.props.onBlur}
                                />
                                <span onClick={this.showSelectDropdown} className="icon-Cross-with-grey-circle cpointer size-xxii"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                            </div>
                        )
                }
            </>
        );
    }
}