function validateCardDetails(data, FIList){
    let errors = {};
    let IFSCregEx = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (!data.account_name.trim()) {
        errors.account_name = 'Account Name is required';
    }

    if (data.yodlee_supported_bank_id == "") {
        errors.bank_id = 'Bank Name is required';
    }

    // if(!data.bank_name){
    //     errors.bank_name = 'Bank Name is required';
    // }

    if (!data.bank_name && data.yodlee_supported_bank_id == null) {
        errors.bank_name = 'Other Bank Name is required';
    }

    if (!data.account_number) {
        errors.account_number = 'Card number is required';
    }

    if (!data.ifsc_code && data.yodlee_supported_bank_id == null) {
        errors.ifsc_code = 'IFSC Code is required';
    }

    if (data.ifsc_code && IFSCregEx.test(data.ifsc_code) === false) {
        errors.ifsc_code = 'Please provide valid IFSC Code';
    }

    let regEx = /^\d{4}$/;

    // if (data.account_number && regEx.test(data.account_number) === false){
    if (!data.account_number){
        errors.account_number = 'Please provide valid Card number';
    } 
    if(data.account_number){
        if(data.account_number.length < 4 ){
            errors.account_number = 'Please enter 4-digit number'
        }
        
    }
    // if (data.account_number && regEx.test(data.account_number) === true){
    //     let is_unique = FIList.some(institute => institute.account_number == data.account_number)
    //     if (is_unique)
    //         errors.account_number = 'Card number already exits.';
    // } 

    return errors;
}


export {
    validateCardDetails
}