
import {
    SET_BUSINESS_NARRATION,
    SET_EVIDENCE_FILTER_DATA,
    SET_EVIDENCE_LIST_DATA,
    SET_FILTER_CARD,
    SET_FILTER_DATE_RANGE,
    SET_FINANCIAL_INSTITUTE_NAME,
    SET_FINANCIAL_YEAR_NAME,
    SET_IS_PERSONAL,
    SET_IS_TRANSACTION_LOADING,
    SET_LINKED_EVIDENCE_LIST,
    SET_NO_SUPPORTING_DOCUMENT,
    SET_TRANSACTION_STATUS,
    SET_ENTRY_STATUS,
    SET_ACCOUNT_STATUS,
    SET_TRANSACTIONS_FILTER_BY,
    SET_TRANSACTION_FILTER_DATA,
    SET_TRANSACTION_PAGE_NO, SET_TEMP_LINKED_EVIDENCE_LIST, SET_VOUCHER_LIST, SET_TEMP_VOUCHER_LIST, SET_TRANSACTION_DETAILS,
    SET_SUGGESTED_EVIDENCE_LIST_DATA,
    SET_SUGGESTED_EVIDENCE_IDS,SET_BULK_TRANSACTION_LIST,SET_COST_CATEGORY_LIST, SET_CR_DR_TYPE,SET_MONEY_LINK_DEFAULT_WIDGET_CARDS
} from "../Actions/Types";

import StorageData from "../../Helpers/StorageData";

const initialState = {
    is_transaction_loading: false,
    page_no: StorageData.getMoneyLinkPageNo(),
    bank_name: '',
    financial_year_name: "",
    filter_start_date: null,
    filter_end_date: null,
    filter_card: 0,
    filter_by: 0,
    linked_evidences: [],
    temp_linked_evidences: [],
    is_personal: 0,
    no_supporting_document: 0,
    transaction_status: 2,
    entry_status:null,
    account_status:null,
    business_narration: '',
    temp_voucher_list: [],
    // voucher_list: [],
    evidence_filter_data: {},
    transaction_details: {},
    suggested_evidence_list: [],
    suggested_evidence_ids: [],
    bulk_transaction_list: [],
    cr_dr_type: 0,
    default_cards: {
        accounting_card: 3,
        synched_card: 5,
        linking_card: 7
      }
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_TRANSACTION_FILTER_DATA:
            return {
                ...state,
                ...payload
            }
        case SET_IS_TRANSACTION_LOADING:
            return {
                ...state,
                is_transaction_loading: payload
            }
        case SET_TRANSACTION_PAGE_NO:
            StorageData.setMoneyLinkPageNo(payload)
            return {
                ...state,
                page_no: payload
            }
        case SET_FINANCIAL_INSTITUTE_NAME:
            return {
                ...state,
                bank_name: payload.bank_name,
                bank_value: payload.bank_value,
                account_number: payload.account_number
            }
        case SET_FINANCIAL_YEAR_NAME:
            return {
                ...state,
                financial_year_name: payload
            }
        case SET_FILTER_DATE_RANGE:
            return {
                ...state,
                filter_start_date: payload.start_date,
                filter_end_date: payload.end_date
            }
        case SET_FILTER_CARD:
            return {
                ...state,
                filter_card: payload
            }
        case SET_EVIDENCE_FILTER_DATA:
            return {
                ...state,
                evidence_filter_data: payload
            }
        case SET_TRANSACTIONS_FILTER_BY:
            return {
                ...state,
                filter_by: payload
            }
        case SET_EVIDENCE_LIST_DATA:
            return {
                ...state,
                ...payload
            }
        case SET_LINKED_EVIDENCE_LIST:
            return {
                ...state,
                linked_evidences: payload
            }
        case SET_TEMP_LINKED_EVIDENCE_LIST:
            return {
                ...state,
                temp_linked_evidences: payload
            }
        case SET_IS_PERSONAL:
            return {
                ...state,
                is_personal: payload
            }
        case SET_NO_SUPPORTING_DOCUMENT:
            return {
                ...state,
                no_supporting_document: payload
            }
        case SET_TRANSACTION_STATUS:
            return {
                ...state,
                transaction_status: payload
            }
        case SET_ENTRY_STATUS:
            return {
                ...state,
                entry_status: payload
            }
        case SET_ACCOUNT_STATUS:
            return {
                ...state,
                account_status: payload
            }
        case SET_BUSINESS_NARRATION:
            return {
                ...state,
                business_narration: payload
            }
        // case SET_VOUCHER_LIST:
        //     return {
        //         ...state,
        //         voucher_list: payload
        //     }
        case SET_TEMP_VOUCHER_LIST:
            return {
                ...state,
                temp_voucher_list: payload
            }
        case SET_TRANSACTION_DETAILS:
            return {
                ...state,
                transaction_details: payload
            }
        case SET_SUGGESTED_EVIDENCE_LIST_DATA:
            return {
                ...state,
                suggested_evidence_list: payload
            }
        case SET_SUGGESTED_EVIDENCE_IDS:
            return {
                ...state,
                suggested_evidence_ids: payload
            }
        case SET_BULK_TRANSACTION_LIST:
            return {
                ...state,
                bulk_transaction_list: payload
            }
        case SET_COST_CATEGORY_LIST:
            return {
                ...state,
                cost_category_list: payload
            }
        case SET_CR_DR_TYPE:
            return {
                ...state,
                cr_dr_type: payload
            }
        case SET_MONEY_LINK_DEFAULT_WIDGET_CARDS:
            return {
                ...state,
                default_cards: payload
            }
                
        default:
            return state;
    }
}