import React from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect, } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import { FI_CREATE_CODE } from '../../Helpers/Constants';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';
import { allowAlphabetsOnly } from '../../Utils/RegaxValidation';
import { AddNew } from '../IcoonNew';
import { validateAddNewBank } from './Validation';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import DirtyFieldModal from '../../Components/BoilerPlate/DirtyFieldModal';
import Select from 'react-select';
import { fuzzyMatch } from '../../Helpers/HelperFunction';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { decodeHtml } from '../../Helpers/HelperFunction';

class AddBankModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_permission_granted: this.props.features_permissions_list.includes(FI_CREATE_CODE),
			show_delete_popup: false,
			deleteLoading: false,
			remove_cards: [],
			card_no_error: '',
			bank_card_id: null,
			is_bank_editable: false,
			account_name: "",
			account_number: "",
			ifsc_code: "",
			bank_name: "",
			bank_code: "",
			branch: "",
			city_name: "",
			is_active: 1,
			show_debit_card_field: false,
			errors: {},
			form_submited: false,
			debit_card_no: "",
			debit_cards: [],
			uneditable_debit_cards: [],
			redirect_to_main_page: false,
			is_dirty: false,
			dirtymodalVisible: false,
			bank_id: "",
			banks: [],
			yodlee_supported_bank_id: "",
			ledger_name: "",
			guid:"",
			ledger_options:[],
			has_billwise_allocation: false,
			ledger_id:"",
			ledger_option:{}
		}
	}

	componentDidMount = () => {
		console.log("in mounted component")
		this.getSupportedBanks()
		//console.log(this.state.is_bank_editable)
		 this.getLedgerData()
		

	}

	getLedgerData = ()=>{
		console.log("::::::inside bank ledger")

		let options = []
		setTimeout(() => {
			let has_billwise_allocation = false;
			if(this.props.ledgerNameList.hasOwnProperty('ledger_list')){
				this.props.ledgerNameList.ledger_list.map((ledger, index) => {
					options.push({ label: decodeHtml(ledger.name), value: ledger.guid })
					if (this.state.guid == ledger.guid) {

						//check if billwise allocation flag is set for already selected ledger
						this.props.ledgerNameList.ledger_list.forEach(opt => {
							if (opt.guid == this.state.guid && opt.has_billwise == 1) {
								has_billwise_allocation = true
							}
						});

						this.setState({ ledger_option: { label: decodeHtml(ledger.name), value: ledger.guid } })
						// this.setState({ledger_id:ledger.id,ledger_name:ledger.name,guid:ledger.guid})
						// let temp_ledger = {
						// 	amount: this.state.amount,
						// 	ledger_id: ledger.id,
						// 	guid: this.state.guid,
						// 	name: ledger.name,
						// 	transaction_voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
						// 	voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
						// 	type: parseInt(this.state.type),
						// 	cost_categories: this.state.cost_categories,
						// 	billwise_allocation: this.props.billwise_allocation
						// }
						//this.props.updateLedger(temp_ledger, this.props.ledger_index, "first_time")

						//console.log(this.state.ledger_option)
					}

				})
				this.setState({ ledger_options: options, has_billwise_allocation })
			}
			
		}, 2000);

	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.card_details !== this.props.card_details)
			if (nextProps.card_details !== null) {
				if (nextProps.card_details.is_editable === 0) {
					this.setState({ is_bank_editable: true })
				} else {
					this.setState({ is_bank_editable: false })
				}
				this.getBankDetails(nextProps.card_details.id || nextProps.card_details.financial_institute_id)
				this.getLedgerData();
			} else {
				this.clearState()
			}
	}
	clearState = () => {
		this.setState({
			is_bank_editable: false,
			bank_name: "", bank_code: "", ifsc_code: "", debit_card_no: "",
			branch: "", city_name: "", ifsc_details_loaded: false, account_number: "",
			account_name: "", cards: [], is_active: 1, form_submited: false,
			debit_cards: [], remove_cards: [], bank_card_id: null, errors: {},
			is_dirty: false,
			bank_id: "",
			yodlee_supported_bank_id: "",
			errors: {},
			ledger_id:"",ledger_name:"",guid:"",ledger_option:{}
		})
	}
	getBankDetails = (id) => {
		this.setState({
			ifsc_details_loaded: false,
			form_submited: false,
			card_no_error: "",
			show_debit_card_field: false
		})
		let details = {
			"financial_institute_id": id
		}

		// FinancialInstituteService.getFinancialInstituteDetails(details).then(data => {
		// 	if (data.response_code === 200) {
		// 		this.setState({
		// 			bank_card_id: data.result.id || data.result.financial_institute_id,
		// 			bank_name: data.result.bank_name,
		// 			bank_code: data.result.bank_code,
		// 			ifsc_code: data.result.ifsc_code,
		// 			branch: data.result.branch,
		// 			city_name: data.result.city_name,
		// 			account_name: data.result.account_name,
		// 			account_number: data.result.account_number,
		// 			is_active: data.result.is_active,
		// 			//debit_cards: data.result.cards,
		// 			//uneditable_debit_cards: data.result.cards,
		// 			ifsc_details_loaded: true,
		// 			bank_id: data.result.id || data.result.financial_institute_id,
		// 		})
		// 	}
		// })
		this.props.dispatch(setAppilcationLoder(true))
		FinancialInstituteService.getFIData(id).then(data => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				console.log(":::::::::::bank data,::::::::",data.result)
				this.setState({
					bank_card_id: data.result.id || data.result.financial_institute_id,
					account_name: data.result.account_name,
					bank_code: ('' + data.result.bank_code),
					card_digits: data.result.account_number,
					is_active: data.result.is_active,
					bank_name: data.result.bank_name,
					card_id: data.result.id,
					yodlee_supported_bank_id: data.result.yodlee_supported_bank_id != null ? data.result.yodlee_supported_bank_id : null,
					debit_cards: data.result.cards,
					account_number: data.result.account_number,
					bank_id: data.result.yodlee_supported_bank_id,
					ifsc_code: data.result.ifsc_code == null ? "" : data.result.ifsc_code,
					guid:data.result.ledger_guid == null ? "":data.result.ledger_guid,
					ledger_id: data.result.ledger_id == null ? "" : data.result.ledger_id,
					ledger_name: data.result.ledger_name == null ? "" : data.result.ledger_name
				})

				if (data.result.is_editable === 0) {
					this.setState({ is_bank_editable: true })
				} else {
					this.setState({ is_bank_editable: false })
				}

				let a = data.result.yodlee_supported_bank_id != null ? data.result.yodlee_supported_bank_id : ""
				//console.log(a)
			}
		})
	}

	addNewBank = async () => {
		// e.preventDefault();
		this.setState({
			loading: false,
			form_submited: true
		});
		let name = this.state.account_name.trim().replace(/\s+/g, ' ');
		let details = {
			account_name: name,
			account_number: this.state.account_number,
			ifsc_code: this.state.ifsc_code,
			// bank_code: this.state.bank_code,
			// branch: this.state.branch,
			// city_name: this.state.city_name,
			cards: this.getCards(),
			is_credit_card: 0,
			is_active: parseInt(this.state.is_active),
			bank_name: this.state.bank_name,
			debit_card_no: this.state.debit_card_no,
			yodlee_supported_bank_id: this.state.yodlee_supported_bank_id,
			//bank_id: this.state.bank_id,
			ledger_guid:this.state.guid == "" ? null : this.state.guid,
			ledger_name:this.state.ledger_name == "" ? null : this.state.ledger_name,
			ledger_id:this.state.ledger_id == "" ? null : this.state.ledger_id

		}
		let errors = validateAddNewBank(details, this.props.FIList);
		delete details.debit_card_no
		if (this.state.bank_card_id !== null) {
			if (errors.account_number == 'Account number already exits.') {
				delete errors.account_number
			}
		}
		//console.log(errors)
		if (Object.keys(errors).length === 0) {
			let match = fuzzyMatch(this.props.default_entity_detail.name.toString().toLowerCase(), this.state.account_name.toString().toLowerCase());
			const averageLength = (this.props.default_entity_detail.name.length + this.state.account_name.length) / 2;
			const stringMatch = match > averageLength ? 1 : match / averageLength;
			const matchPercent = (1 - stringMatch) * 100;

			if (matchPercent <= 30) {
				if (!await confirm({
					confirmation: 'Entity name & Account Name does not match. Do you still want to continue?'
				})) {
					return true;
				}
			}
			//return

			if (this.state.bank_card_id !== null) {
				//details.financial_institute_id = this.state.bank_card_id
				details.id = this.state.bank_card_id
				details.delete_cards = this.getRemoveCards()
				FinancialInstituteService.updateBankAndCard(details).then(data => {
					this.setState({ loading: false, errors: {} });
					if (data.response_code === 200) {
						details.bank_code = this.state.bank_img_code
						this.updateList(details, 'update')
						this.props.getFIDetails(this.state.bank_card_id)
						this.clearState()
						this.props.closeModal()
						toast.success(data.message);
					} else {
						this.setState({
							errors: data.reason
						})
						toast.error(data.message);
					}
				})
			} else
				//console.log(details)
				FinancialInstituteService.addNewBank(details)
					.then((data) => {
						this.setState({ loading: false, errors: {} });
						if (data.response_code === 200) {
							details.financial_institute_id = data.result.financial_institute_id
							details.bank_code = this.state.bank_img_code
							this.updateList(details, 'add')
							toast.success(data.message);
							this.clearState()
							this.props.closeModal()
						} else {
							this.setState({
								errors: data.reason
							})
							toast.error(data.message);
						}
					})
		} else {
			this.setState({
				loading: false,
				errors: errors
			});
		}
	}

	checkForErrors = () => {
		if (this.state.form_submited) {
			let errors = {}
			let data = {
				account_name: this.state.account_name,
				account_number: this.state.account_number,
				ifsc_code: this.state.ifsc_code,
				bank_id: this.state.bank_id,
				bank_name: this.state.bank_name
			}

			errors = validateAddNewBank(data, this.props.FIList);

			if (this.state.bank_card_id !== null) {
				if (errors.account_number == 'Account number already exits.') {
					delete errors.account_number
				}
			}

			if (Object.keys(errors).length != 0) {
				this.setState({ errors: errors });
			} else {
				this.setState({ errors: {} });
			}
		}
	}

	getDetailsByIFSC = () => {
		this.setState({
			bank_name: "", bank_code: "", branch: "", city_name: "",
			ifsc_details_loaded: false
		});
		if (this.state.ifsc_code) {
			FinancialInstituteService.getDetailsByIFSC(this.state.ifsc_code)
				.then((data) => {
					if (data.response_code === 200) {
						this.setState({
							bank_name: data.result.bank_name,
							bank_code: data.result.bank_code,
							branch: data.result.branch,
							city_name: data.result.city_name,
							ifsc_details_loaded: true
						});
					} else {
						this.setState({
							bank_name: "", bank_code: "", branch: "", city_name: "",
							ifsc_details_loaded: true
						});
					}
				})
		}
		this.checkForErrors();
	}

	handleChange = (e,type = null)=>{
		let name;
		let value;
		let ledger_id;
		let l_name;
		if (this.state.is_permission_granted ) {
			if (type == 'ledger') {
				name = 'guid';
				value = e.value;
				console.log('e,vakkdjhg',e.value)
				let ledger = this.props.ledgerNameList.ledger_list.find(x => x.guid == value);

				ledger_id = ledger.id;
				l_name = ledger.name;
				let has_billwise_allocation = ledger.has_billwise == 1 ? true : false;

				this.setState({
					ledger_option: { label: <span dangerouslySetInnerHTML={{ __html: l_name }} />, value: value },
					has_billwise_allocation
				})
				//to hide cost center on production line 199,202 are commented. later we remove required validation
			}
			else{
				name = e.target.name
				value = e.target.value

				if (this.state.guid != "") {
					ledger_id = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid).id;
					l_name = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid).name;
				}
			}

			this.setState({guid:value,ledger_id:ledger_id,ledger_name:l_name,is_dirty:true})

			
		}


	}

	onInputChange = (event) => {
		// if (event.target.name === "account_name" && allowAlphabetsOnly(event.target.value) === false) {
		// 	return
		// }
		if (event.target.name === "ifsc_code") {
			this.setState({
				ifsc_code: event.target.value.toUpperCase(),
				is_dirty: true
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value,
				is_dirty: true
			});
		}
		this.checkForErrors();
	}

	onCardNoChange = (e) => {
		this.setState({card_no_error : ''})
		let errors = this.state.errors
		delete errors.debit_card_no
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			this.setState({ debit_card_no: e.target.value, is_dirty: true })
		}
	}

	validateCardNo = () => {
		let valid = true, is_unique = true;
		if (this.state.debit_card_no.length !== 4) {
			this.setState({ card_no_error: "Last 4 Digits of Card Number is required" })
			valid = false
		} else {
			this.state.debit_cards.forEach(card_no => {
				if (card_no.card_number == this.state.debit_card_no)
					is_unique = false
			});
			if (is_unique)
				this.setState({ card_no_error: "" })
			else {
				this.setState({ card_no_error: "Already exists." })
				valid = false
			}
		}
		return valid
	}
	addDebitcard = () => {
		let errors = this.state.errors
		if (errors.debit_card_no == 'Please, confirm debit card no.') {
			delete errors.debit_card_no
		}
		if (this.validateCardNo()) {
			let new_debit_card = { id: 0, card_number: this.state.debit_card_no }
			var joined = this.state.debit_cards.concat(new_debit_card);
			this.setState({ debit_cards: joined, debit_card_no: "" })

		}
	}

	deleteCard = (card, index) => {
		let remove_cards = this.state.remove_cards
		if (card.id !== 0)
			remove_cards.push(card.id)
		this.setState({
			remove_cards,
			debit_cards: this.state.debit_cards.filter(FI => {
				if (FI.card_number !== card.card_number) {
					return FI
				}
			})
		})
	}

	handleActiveInactiveMode = (e) => {
		this.setState({ is_active: e.target.value ,is_dirty: true});
	}

	toggleDeleteFI = () => {
		this.setState({ show_delete_popup: !this.state.show_delete_popup })
	}

	deleteFI = () => {
		const { dispatch, history } = this.props;
		let details = {
			"financial_institute_id": this.state.bank_card_id
		}
		this.props.dispatch(setAppilcationLoder(true));
		FinancialInstituteService.deleteFinancialInstitute(details).then(data => {
			if (data.response_code === 200) {
				if (data.message == "Financial Institute Deleted") {
					this.props.updateList(parseInt(this.state.bank_card_id), 'delete')
					this.props.closeModal()
					this.toggleDeleteFI()
					toast.success(data.message)

					if (this.props.inside_page) {
						this.setState({ redirect_to_main_page: true })
					}
				} else {
					toast.error(data.message)
					this.toggleDeleteFI()
					this.props.closeModal()
				}
				this.props.dispatch(setAppilcationLoder(false));
			} else {
				toast.error(data.message)
				this.toggleDeleteFI()
				this.props.closeModal()
				this.props.dispatch(setAppilcationLoder(false));
			}
		})
	}

	updateList = (card, action) => {
		if (action === 'update')
			card.is_editable = this.props.card_details.is_editable
		else
			card.is_editable = 1
		this.props.updateList(card, action)
	}

	getCards = () => {
		let to_be_added = this.state.debit_cards.filter((card) => {
			if (card.id === 0)
				return card
		})
		return to_be_added.map(card => {
			// return {
			// 	"card_number": parseInt(card.card_number)
			// }
			return String(card.card_number)
		})
	}

	getRemoveCards = () => {
		return this.state.remove_cards.filter(card_id => {
			if (card_id !== 0) {
				return card_id
			}
		})
	}

	getDeleteIcon = (card, index) => {
		if (this.state.is_permission_granted === false)
			return null
		if (this.state.bank_card_id) {
			let is_editable = this.state.uneditable_debit_cards.some(institute => institute.card_number == card.card_number)
			if (is_editable && !this.state.is_bank_editable) {
				return null
			}
			else
				// return <div className="cross" onClick={() => this.deleteCard(card, index)} ><span>X</span></div>
				return <span class="ss-cross-sm"><span class="icon-Cross-with-grey-circle" onClick={() => this.deleteCard(card, index)}><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>
		} else {
			// return <div className="cross" onClick={() => this.deleteCard(card, index)} ><span>X</span></div>
			return <span class="ss-cross-sm"><span class="icon-Cross-with-grey-circle" onClick={() => this.deleteCard(card, index)}><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>
		}
	}

	closeModal = () => {
		if (this.state.is_dirty) {
			this.setState({ dirtymodalVisible: true })
			return false;
		} else {
			this.setState({show_debit_card_field : false})
			this.setState({
				errors:'',
				card_no_error : '',
				})
			this.props.closeModal();
		}
	}
	dirtyonDontSave = () => {
		this.setState({show_debit_card_field : false})
		this.setState({ dirtymodalVisible: false })
		this.props.closeModal();
		this.clearState()
	}
	closeDirtyModalOnCancel = () => {
		
		this.setState({ dirtymodalVisible: false })
		
	}
	onConfirm = () => {
		this.setState({ dirtymodalVisible: false })
		this.addNewBank();
	}

	getSupportedBanks = () => {
		FinancialInstituteService.getSupportedBanks()
			.then((data) => {
				if (data.response_code == 200) {
					this.setState({
						banks: [...data.result, { id: null, bank_name: "Any other" }]
					});
				}
			});
	}

	render() {
		if (this.state.redirect_to_main_page) {
			return <Redirect to='/financial-institutes' />;
		}

		let bank_name_list = this.state.banks.map(({ id, bank_name }) => ({ label: bank_name, value: id }));
		console.log(bank_name_list)
		console.log("::::::::",this.state.yodlee_supported_bank_id)
	let filter = 	bank_name_list.filter(option => option.value == this.state.yodlee_supported_bank_id)
	console.log("filter",filter)
	console.log("::::::ledger data:::::::::",this.state.ledger_id,this.state.ledger_name,this.state.guid)
		return (
			<React.Fragment>
				<DirtyFieldModal
					visible={this.state.dirtymodalVisible}
					onCancel={this.closeDirtyModalOnCancel}
					onConfirm={this.onConfirm}
					onDontSave={this.dirtyonDontSave} />

				<DeletePopUp
					showModal={this.state.show_delete_popup}
					deleteLoading={this.state.deleteLoading}
					toggleDeletePopUp={this.toggleDeleteFI}
					deleteItem={this.deleteFI} />

				<Modal
					show={this.props.showModal}
					onHide={() => this.closeModal()}
					backdrop="static"
					keyboard={false}
					centered
					className="popup-model add-new-bank-model"
					size="xl"
				// aria-labelledby="example-modal-sizes-title-lg"
				// className="add-bank-model"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{this.state.is_permission_granted ? this.state.bank_card_id !== null ? "Edit Bank Details" : "Add New Bank" : "View Bank Details"}
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<form onSubmit={(e) => { e.preventDefault(); this.addNewBank() }}>
							<div className="row">
								<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">Bank Name<span className="red-star">*</span></lable>

									{/* <Select className="Select-Search card_issued " */}
									<Select
										className="Select-Search typeed-dd "
										classNamePrefix="Select type-select "
										name="bank_code"
										menuPortalTarget={document.body}
										styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
										onChange={(e) => {
											//console.log(e.value)
											//alert(e.target.value)
											if (e.value == "") {
												this.setState({ bank_name: "", bank_id: "", is_dirty: true, yodlee_supported_bank_id: "", bank_img_code: null })
											} else if (e.value === "null" || e.value == null) {
												this.setState({ bank_name: null, bank_id: null, is_dirty: true, yodlee_supported_bank_id: null, bank_img_code: null })
											} else {
												let bank = this.state.banks.filter(function (item) {
													return item.id == e.value
												})
												// console.log(bank)
												this.setState({ bank_name: e.label, bank_id: e.value, is_dirty: true, yodlee_supported_bank_id: e.value, bank_img_code: bank[0].bank_code })
											}
											this.checkForErrors()
										}}
										value={bank_name_list.filter(option => option.value == this.state.yodlee_supported_bank_id)}
										isDisabled={!this.state.is_permission_granted || this.state.is_bank_editable}
										isSearchable={true}
										options={bank_name_list}
										placeholder={'Select Bank'}
										onBlur={this.checkForErrors}
									/>
									{this.state.errors.bank_id && <small className="form-error-text">{this.state.errors.bank_id}</small>}
								</div>

								<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">Account Name<span className="red-star">*</span></lable>
									<input
										type="text"
										className="form-control"
										name="account_name"
										value={this.state.account_name}
										onChange={this.onInputChange}
										onBlur={this.checkForErrors}
										readOnly={this.state.is_bank_editable || !this.state.is_permission_granted}
									/>
									{this.state.errors.account_name && <small className="form-error-text">{this.state.errors.account_name}</small>}
								</div>

								<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">Account Number<span className="red-star">*</span></lable>
									<input
										type="text"
										className="form-control"
										value={this.state.account_number}
										name="account_number"
										maxLength="18"
										onChange={this.onInputChange}
										onBlur={this.checkForErrors}
										readOnly={this.state.is_bank_editable || !this.state.is_permission_granted}
									/>
									{this.state.errors.account_number && <small className="form-error-text">{this.state.errors.account_number}</small>}
								</div>

								{this.state.bank_id == null &&
									<div className="col-xl-4 col-lg-4 col-md-4 form-group">
										<lable className="lable-input">Other Bank Name<span className="red-star">*</span></lable>
										<textarea className="form-control" rows="1" name="bank_name" value={this.state.bank_name}
											onChange={(e) => this.setState({ bank_name: e.target.value, is_dirty: true })}
											readOnly={this.state.is_editable || !this.state.is_permission_granted}
										></textarea>
										{this.state.errors.bank_name && <small className="form-error-text">{this.state.errors.bank_name}</small>}
									</div>
								}

								{this.state.bank_id == null &&
									<div className="col-xl-4 col-lg-4 col-md-4 form-group">
										<lable className="lable-input">IFSC Code<span className="red-star">*</span></lable>
										<input
											type="text"
											className="form-control"
											value={this.state.ifsc_code}
											onChange={this.onInputChange}
											//onBlur={this.getDetailsByIFSC}
											name="ifsc_code"
											readOnly={this.state.is_bank_editable || !this.state.is_permission_granted || (this.state.ifsc_code ? true:false)}
										/>
										{this.state.errors.ifsc_code && <small className="form-error-text">{this.state.errors.ifsc_code}</small>}
									</div>
								}


								<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">Ledger Name<span ></span></lable>

									{/* <Select className="Select-Search card_issued " */}
									<Select
										className="Select-Search typeed-dd "
										classNamePrefix="Select type-select "
										name="guid"
										menuPortalTarget={document.body}
										styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
										value={this.state.ledger_option}
										// isDisabled={!this.state.is_permission_granted || this.state.is_bank_editable}
										onChange = {(e) => this.handleChange(e, 'ledger')}
										isSearchable={true}
										options={this.state.ledger_options}
										placeholder={'Select Ledger'}
										onBlur={this.checkForErrors}
									/>
									{/* {this.state.errors.bank_id && <small className="form-error-text">{this.state.errors.bank_id}</small>} */}
								</div>
							</div>{/* End Row  */}

							{/* Add Debit Card Field */}
							<div className="row mt-2">
								<div className="col-xl-3 col-lg-3 col-md-3">
									{this.state.is_permission_granted &&
										<h5 className="add-debit-card" onClick={() => this.setState({ show_debit_card_field: true })}>
											<AddNew addNew={() => this.setState({ show_debit_card_field: true })} /> <span>Add Debit Card</span>
										</h5>
									}
								</div>

								{this.state.show_debit_card_field ?
									<>
										<div className="col-xl-4 col-lg-4 col-md-4 form-group pl-xl-0">
											<input
												type="text"
												className="form-control w-100"
												value={this.state.debit_card_no}
												maxLength={4}
												minLength={4}
												onChange={this.onCardNoChange}
												placeholder="Last 4 Digits of Card Number"
											/>
											{this.state.errors.debit_card_no && <small className="form-error-text">{this.state.errors.debit_card_no}</small>}
											{this.state.card_no_error && <small className="form-error-text">{this.state.card_no_error}</small>}
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4">
											<Button variant="success apply_btn_new" onClick={this.addDebitcard}>Add</Button>
										</div>
									</>
									: null
								}
							</div>
							{/* Add Debit Card Field */}

							<div className="row">
								{this.state.debit_cards.length != 0 ?
									<div className="col-7 pr-0">
										<div className="bcard-digit-list-scroll">
											{this.state.debit_cards.map((card, index) => {
												return <div className="bcard-digit-list" key={index + card.id}>
													{this.getDeleteIcon(card, index)}
													{/* <span className="icon-credit-list"><span className="icon-credit-card"></span></span> */}
													<span class="icon-Group-4437 bcardicon"><span class="path1 blue-icon"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
													<span className="bcardname">Card ending in {card.card_number}</span>
												</div>
											})}
										</div>
									</div> : null
								}
							</div>

							<div className="row">
								<div className="col-12 text-right">
									<ButtonGroup className="activein_btn_group" aria-label="Basic example" onClick={this.handleActiveInactiveMode}>
										<Button variant={" apply_btn_new activein_btn_new " + (this.state.is_active == 1 ? " success " : " deactivein_btn_new ")} value={1}>Active</Button>
										<Button variant={" apply_btn_new activein_btn_new " + (this.state.is_active == 0 ? " success " : " deactivein_btn_new ")} value={0}>Inactive</Button>
									</ButtonGroup>
								</div>
							</div>

							{this.state.is_permission_granted &&
								<div className="row mt-3">
									<div className="col-6">
										{this.state.bank_card_id &&
											<Button type="button" variant={"outline-success reset_btn_new " + (this.state.is_bank_editable ? "" : "")} onClick={() => { this.toggleDeleteFI() }}>Delete</Button>
										}
									</div>
									<div className="col-6 text-right">
										<Button type="submit" variant={"success apply_btn_new " + (this.state.loading ? "position-relative" : "")} disabled={this.state.loading}>
											{/* {this.state.loading && ( <span className="spinner-border spinner-border-sm"></span> )} */}
											Save</Button>
									</div>

									{this.state.ifsc_details_loaded && this.state.bank_code == null ?
										<div className="col-12">
											<div className="bank-not-support">
												<span class="icon-Group-3553 bnsp-icon mb-1"></span>
												<small>This bank is not currently supported, will add support in next 2-3 days. Please check again.</small>
											</div>
										</div> : null
									}
								</div>
							}
						</form>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const features_permissions_list = all_value.features_permissions_list || [];
	return { features_permissions_list }
}

export default connect(mapStateToProps)(AddBankModal);