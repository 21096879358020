import React from 'react'
import ChequeSideList from './ChequeSideList'
import CreateChequeForm from './CreateChequeForm'

class CreateChequeView extends React.Component {
    render() {
        console.log('in 2222',this.props.cheque_list)
        return (
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <ChequeSideList 
                        cheque_list = {this.props.cheque_list}
                        applyFilter = {this.props.applyFilter}
                        has_next = {this.props.has_next}
                        has_previous = {this.props.has_previous}
                        per_page_items = {this.props.per_page_items}
                        page_no = {this.props.page_no}
                        />
                        <CreateChequeForm 
                        newCheque = {this.props.newCheque}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateChequeView;