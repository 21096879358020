import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Link
} from 'react-router-dom';
import { connect } from "react-redux";
import { CREATE_BUSSINESS_SNAPSHOT_CODE, CREATE_REIMBUSRMENT_SNAPSHOT_CODE } from '../../Helpers/Constants';

const TheSidebar = (props) => {  
  let create_snapshot_permission = false;
  if(props.features_permissions_list.includes(CREATE_BUSSINESS_SNAPSHOT_CODE) || props.features_permissions_list.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE)){
    create_snapshot_permission = true;
  }

  return (
    <div className={ !!props.module_permissions.length ? "" : "d-none"}>
      <ul className="main-sidebar">
        <li className={ !!props.module_permissions.length ? "" : "d-none"}>
          <Link to="/dashboard" className="s-sbold blu-color">
            {/* <span className="side-icon icon-dashboard-1"></span> */}
            {/* <span className="icon-sec icon-Group-4408 side-icon"><span className="path1"></span><span className="path2"></span></span> */}

            <span className="icon-Dashboard side-icon"><span className="path1"></span><span className="path2"></span></span>
            <span className="side-link s-sbold d-none">Dashboard</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/welcome" className="s-sbold blu-color">
            <span className="side-icon icon-dashboard-1"></span>
            <span className="side-link s-sbold d-none">Home</span>
          </Link>
        </li>
        <li>
          <Link to="/profile-management/entities" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
            <span className="side-link s-sbold d-none">Profile Management</span>
          </Link>
        </li>
        <li className={ props.module_permissions.includes(2) ? "" : "d-none"}>
          <Link to="/invoices" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
            <span className="side-link s-sbold d-none">Invoices</span>
          </Link>
        </li>
        <li className={ props.module_permissions.includes(3) ? "" : "d-none"}>
          <Link to="/cheque" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
            <span className="side-link s-sbold d-none">Cheques</span>
          </Link>
        </li>
        <li>
          <Link to="/task-manager" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
            <span className="side-link s-sbold d-none">Task Manager</span>
          </Link>
        </li> */}
        <li className={ props.module_permissions.includes(1) && create_snapshot_permission ? "" : "d-none"}>
          <Link to={"/snapshot/evidence/create"} className="s-sbold blu-color">
            {/* <span className="side-icon icon-Group-2520"></span> */}
            {/* <span className="icon-Evidence-green side-icon"></span> */}
            {/* <span class="icon-plus2 side-icon"></span> */}
            <span class="icon-plus side-icon"></span>
            <span className="side-link s-sbold d-none">Create Snapshot</span>
          </Link>
        </li> 
        <li className={ props.module_permissions.includes(1) ? "" : "d-none"}>
          <Link to={"/snapshot/evidence"} className="s-sbold blu-color">
            <span className="icon-Group-2520 side-icon "></span>
            {/* <span className="icon-Evidence-green side-icon"></span> */}
            <span className="side-link s-sbold d-none">Snapshot</span>
          </Link>
        </li> 
        <li className={ props.module_permissions.includes(4) ? "" : "d-none"}>
          <Link to="/financial-institutes" className="s-sbold blu-color">
            {/* <span className="side-icon icon-Group-2519"></span> */}
            {/* <span className="icon-sec icon-Group-5305 side-icon"><span className="path1"></span><span className="path2"></span></span> */}

            <span className="icon-FI  side-icon"><span className="path1"></span><span className="path2"></span></span>
            {/* <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span> */}
            <span className="side-link s-sbold d-none">Banking</span>
          </Link>
        </li>
        <li className={ props.module_permissions.includes(5) ? "" : "d-none"}>
          <Link to="/money-link" className="s-sbold blu-color">
            {/* <span className="side-icon icon-Group-2518"></span> */}
            {/* <span className="icon-Group-5303 side-icon mlink"></span> */}
            {/* <span className="icon-Link-Evidence side-icon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>  */}
            {/* <span className="side-link s-sbold d-none">Link evidence</span> */}

            <span className="icon-Link-icon side-icon mlink"></span>
            <span className="side-link s-sbold d-none">Money Link</span>
          </Link>
        </li>        
        <li className={ props.module_permissions.includes(10) ? "" : "d-none"}>
          <Link to="/reports" className="s-sbold blu-color">
            {/* <span className="side-icon icon-XMLID_223"></span> */}
            {/* <span className="icon-sec icon-Group-4404 side-icon"><span className="path1"></span><span className="path2"></span></span> */}

            <span className="icon-Exports side-icon"><span className="path1"></span><span className="path2"></span></span>
            {/* <span className="side-link s-sbold d-none">Export and Reports</span> */}
            <span className="side-link s-sbold d-none">Reports</span>
          </Link>
        </li>
        <li>
          <Link to="/docbox"  className={ props.module_permissions.includes(7) ? "s-sbold blu-color" : "d-none"}>
            <span class="icon-Doc-box side-icon side-docbox-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
            <span className="side-link s-sbold d-none">Docbox</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

function mapStateToProps(state) {
  const session_values = state.Session || {};
  const module_permissions = session_values.module_permissions || [];
  const features_permissions_list = session_values.features_permissions_list || [];
  return {
    module_permissions,features_permissions_list
  };
}
export default connect(mapStateToProps)(React.memo(TheSidebar));
