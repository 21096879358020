import React from 'react'
import LinkedTransactionsForm from './LinkedTransactionForm';
import SideListView from './SideList';

class LinkTransactionView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transaction_detail: []
        }
    }

    componentWillUnmount() {
		this.setState({transaction_detail: []})
	}

    render() {
        return (
            <main className="content-wrapper-inner-new">
                <div className="container-fluid container-padding-new">
                    <div className="row">
                        <SideListView
                            filter_by={this.props.filter_by}
                            searchText={this.props.searchText}              //--------Ram
                            changeSearchText={this.props.changeSearchText}  //--------Ram
                            searchApplied={this.props.searchApplied}        //--------Ram
                            changeSearchStatus={this.props.changeSearchStatus}  //--------Ram
                            transaction_list={this.props.transaction_list}
                            per_page_items={this.props.per_page_items}
                            page_no={this.props.page_no}
                            has_next={this.props.has_next}
                            has_previous={this.props.has_previous}
                            modified_by_list={this.props.modified_by_list}
                            applyFilter={this.props.applyFilter}
                            financial_institute={this.props.financial_institute}
                            year_type={this.props.year_type}
                            total_pages={this.props.total_pages}
                            is_search_applied={this.props.is_search_applied}
                            searchFilter={this.props.searchFilter}
                            transaction_detail={this.state.transaction_detail}
                        />
                        <LinkedTransactionsForm
                            transaction_list={this.props.transaction_list}
                            transaction_id={this.props.transaction_id}
                            updateList={this.props.updateList}
                            onCardSelected={this.props.onCardSelected}
                            filter_card={this.props.filter_card}
                            updateListStatus={this.props.updateListStatus}
                            moveToNextPage={this.props.moveToNextPage}
                            updateTransactionDetail={(data) => {
								console.log(data)
								this.setState({transaction_detail: data})
							}}
                            resetSaveAndNext={this.props.resetSaveAndNext}
                        />
                  	</div>
				</div>
			</main>
        )
    }
}

export default LinkTransactionView;