import React from 'react'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import { checkFileExtension } from '../../Helpers/CheckValidation';

/* static ui elements */
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class ImportLedger extends React.Component {
    constructor(props) {
        super(props);
        this.btnRef = React.createRef();

        this.PERMISSION_LIST = this.props.features_permissions_list;
        this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
        this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
        this.VIEW_LINKED_ENTRIES = this.PERMISSION_LIST.includes(VIEW_LINKED_ENTRIES_CODE);
        this.state = {
            per_page_items: 0,
            showFilter: false,
            showSearch: true,
            active_input: false,
            search: '',
            is_permission_granted: (this.props.features_permissions_list.includes(LINKING_CODE) ||
                this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ||
                this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)),
        }
    }

    componentDidMount() {
    }

    upload = async (event) => {
        if (checkFileExtension(event.target.files[0].name)) {

            var XMLParser = require('react-xml-parser');
            event.preventDefault();


            const file = event.target.files[0];
            event.target.value = null;

            const reader = new FileReader();

            reader.readAsText(file);
            var ledgerData = []
            this.props.dispatch(setAppilcationLoder(true));
            reader.onloadend = async (evt) => {

                const readerData = evt.target.result;

                var jsonDataFromXml = new XMLParser().parseFromString(readerData);
                let xml = await jsonDataFromXml.getElementsByTagName('LEDGER')
                xml.forEach((ledger, index) => {
                    var guid = ledger.children.find(function (value) {
                        return value.name === "GUID";
                    });
                    var cost_center = ledger.children.find(function (value) {
                        return value.name === "ISCOSTCENTRESON";
                    });
                    ledgerData.push({ 'name': ledger.attributes.NAME, 'guid': guid.value, "has_cost_centre": cost_center.value == "Yes" ? 1 : 0 })
                })

                if (ledgerData.length == 0) {
                    this.props.dispatch(setAppilcationLoder(false));
                    toast.error("Please upload valid ledgers file");
                } else {
                    var ledgers = { "ledgers": ledgerData }
                    MoneyLinkService.importLedgers(ledgers).then((data) => {
                        if (data.response_code == 200) {
                            toast.success(data.message);
                        } else {
                            toast.error(data.message);
                        }
                        this.props.dispatch(setAppilcationLoder(false));
                    });
                }
            }
        } else {
            toast.error("Please upload valid xml file");
        }

        // reader.onloadend = async (evt) => {

        // 	const readerData = evt.target.result;
        // 	const parser = new DOMParser();
        // 	const xml = parser.parseFromString(readerData, 'text/xml');

        // 	console.log(xml)

        // 	// const output = xml.getElementsByTagName('LEDGER');
        // 	// console.log(output)

        // 	let a = this.xmlToJson(xml)
        // 	console.log(a)
        // 	return
        // 	const output1 = xml.getElementsByTagName("LEDGER");
        // 	// output1.forEach((ledger, index) => {

        // 	// 	//txt += x[i].childNodes[0].nodeValue + "<br>";
        // 	// 	ledgerData.push({'name': output1[index].attributes.NAME})
        // 	// })

        // 	// for (let i = 0; i< output1.length; i++) {
        // 	// 	ledgerData.push({'name': output1[i]})
        // 	// }
        // 	// console.log(ledgerData)


        // 	var x, i, xmlDoc, txt;
        // 	xmlDoc = xml;
        // 	txt = "";
        // 	x = xmlDoc.getElementsByTagName("LEDGER");
        // 	for (i = 0; i< x.length; i++) {
        // 		txt += x[i].childNodes[0].nodeValue + "<br>";
        // 	}
        // 	console.log(txt)
        // 	return;
        // }


    }

    render() {
        const ref_upload_ledger = React.createRef();

        return (<>
            <input
                ref={ref_upload_ledger}
                onChange={this.upload}
                type="file"
                style={{ display: "none" }}
                accept=".xml"
            />

            {/* <button onClick={() => ref_upload_ledger.current.click()} className="btn ft-sz-20 s-bold green-btn mr-3 import_snapin import_moneyin">Import Ledgers</button> */}

            <button onClick={() => ref_upload_ledger.current.click()}>
                <span class="icon-Download action-icon"></span>
                <span>Import Ledgers</span>
            </button> 
        </>

        )
    }
}

function mapStateToProps(state) {
    const all_value = state.MoneyLink || {};
    const is_transaction_loading = all_value.is_transaction_loading;
    const filter_by = all_value.filter_by
    const session_value = state.Session || {};
    const features_permissions_list = session_value.features_permissions_list || [];

    return {
        is_transaction_loading, filter_by, features_permissions_list, all_value
    };
}

export default connect(mapStateToProps)(withRouter(ImportLedger));
