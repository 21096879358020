import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { addCategoryValidation } from '../Snapshot/AddCategoryValidation'

export default class AddCategoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: "",
      error: "",
    };
  }

  addCategoryToList = () => {

    let validate = addCategoryValidation(this.state.category.trim())
    if (validate) {
      this.setState({ error: validate })
    }
    else {
      this.props.addCategory(this.state.category)
      this.props.onHide()
      this.setState({
        category: '',
        error: '',
      })
    }
  };

  cancelHandler = () => {
    this.props.onHide()
    this.setState({ category: '', error: '' })
  }

  dataHandler = (e) => {
    const { name, value } = e.target

    this.setState({
      [name]: value
    })
    this.props.addCategoryHandler(e.target.value)
  }

  render() {
    return (
      <Modal
        // size="lg" 
        show={this.props.show} onHide={this.props.onHide}
        // aria-labelledby="contained-modal-title-vcenter" 
        // aria-labelledby="example-modal-sizes-title-lg"
        centered
        className="popup-model error-popup"
      >
        <Modal.Header closeButton onHide={this.cancelHandler}>
          {/* <Modal.Title id="contained-modal-title-vcenter">Add Category</Modal.Title> */}
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-xs-12 col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  // placeholder="Add Category"
                  // pattern = "[A-Za-z]"
                  name="category"
                  className={"form-control " + (this.state.error ? " wrong-red" : "")}
                  value={this.state.category}
                  onChange={this.dataHandler}
                />
              </div>
              {this.state.error ? <small className="error_left">{this.state.error}</small> : null}
            </div>

            <div className="col-xs-12 col-lg-12 mt-1">
              <div className="reset_apply_btn_new">
                <Button variant="outline-success reset_btn_new" onClick={this.cancelHandler}>Cancel</Button>
                <Button variant="success apply_btn_new" onClick={this.addCategoryToList}>Add</Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}