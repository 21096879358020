import React from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import { displayDateFormate } from '../../Helpers/DateFormat';
import LinkedStatus from '../Icon/LinkedStatus';
import NotReadyStatus from '../Icon/NotReadyStatus';
import ProcessedStatus from '../Icon/ProcessedStatus';
import ReadyStatus from '../Icon/ReadyStatus';
import SortingIcon from '../Icon/SortingIcon';
import SyncedStatus from '../Icon/SyncedStatus';
import NotProcessedStatus from '../Icon/NotProcessedStatus';
import AccountStatementDetailView from './AccountStatementDetailView';
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class AccountStatementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  goToAccountStatement = (row) => {
    // this.props.history.push({
    //   pathName: `/financial-institutes/view/${this.props.match.params.financial_institute_id}/account-statements/${row.finanacial_institute_statement_id}`,
    //   state:{
    //     onSortHandler: this.props.onSortClick,
    //     sort_order: this.props.sort_order,
    //     sort_by: this.props.sort_by
    //   }
    // })
    this.props.history.push(`/financial-institutes/view/${this.props.match.params.financial_institute_id}/account-statements/${row.finanacial_institute_statement_id}`)
  }

  onSortClick = (sort_order, sort_by) => {
    this.props.onSortClick(sort_order, sort_by, this.props.match.params.financial_institute_id)
  }


  dataFormatStatus = (cell, row) => {
    switch (cell) {
      case 1:
        return 'Linked'
      default:
        return 'Unlinked'
    }
  }

  _setNoDataFoundOption() {
    if (!this.props.is_transaction_loading) {
      return "No Transaction found";
    } else {
      return (
        <p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
      );
    }
  }

  handleSizePerPageChange(sizePerPage) {
    // When changing the size per page always navigating to the first page
    // this.setState({
    // 	per_page_items: sizePerPage
    // }, () => this.getInvoiceList(this.state.filter_by))
  }

  handlePageChange = (page_no) => {
    this.props.handlePageChange(page_no, this.props.match.params.financial_institute_id)
  }

  render() {
    const options = {
      onPageChange: this.handlePageChange,
      onSizePerPageList: this.handleSizePerPageChange,
      page: this.props.page_no,  // which page you want to show as default
      sizePerPage: this.props.per_page_items,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
      onRowClick: (row) => {
        this.goToAccountStatement(row);
      },
      // noDataText: this._setNoDataFoundOption(),
    }
    return (
      <div className="main-table-sec table-filter-arrow mt-3">
        {this.props.transactions.length === 0 ?
          <div className="not-create-sec"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>
          : 
          <AccountStatementDetailView transactions={this.props.transactions} financial_institute_id={this.props.match.params.financial_institute_id}/>
        }
      </div>

    )
  }
}

export default withRouter(AccountStatementList);