export const decodeHtml = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const dashboardTooltip = (value) => {
    value = Math.round(value);
    value = value.toString();
    value = value.split(/(?=(?:...)*$)/);
    value = value.join(',');
    return "₹ " +value;
}
export const areGuidsUnique = (objectsArray) => {
  const encounteredIds = new Set();
  
  for (let i = 0; i < objectsArray.length; i++) {
    const id = objectsArray[i].guid;
    if (id === 0 || id === '' || id == null) {
			return false;
		}
    if (encounteredIds.has(id)) {
      return true; // Found a duplicate ID
    }
    encounteredIds.add(id);
  }
 return false; // All IDs are unique
}

export const duplicateLedgerIndexFound = (objectsArray,ind) => {
  if (objectsArray[ind].guid === 0 || objectsArray[ind].guid === '' || objectsArray[ind].guid == null) {
    return false;
  }
   const items = objectsArray.filter(item => item.guid === objectsArray[ind].guid);
  const itemCount = items.length;
  return itemCount>1?true:false; // All IDs are unique
}
export const getFileSize = (size) => {
    const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    let i=0;
    while(size>900){
        size/=1000;
        i++;
    }
    let exact_size = (Math.round(size*100)/100)+' '+fSExt[i];
    return exact_size;
}

export const fuzzyMatch = (str1, str2) => {
    const len1 = str1.length;
    const len2 = str2.length;
  
    let matrix = Array(len1 + 1);
    for (let i = 0; i <= len1; i++) {
      matrix[i] = Array(len2 + 1);
    }
  
    for (let i = 0; i <= len1; i++) {
      matrix[i][0] = i;
    }
  
    for (let j = 0; j <= len2; j++) {
      matrix[0][j] = j;
    }
  
    for (let i = 1; i <= len1; i++) {
      for (let j = 1; j <= len2; j++) {
        if (str1[i - 1] === str2[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j - 1] + 1
          );
        }
      }
    }
  
    return matrix[len1][len2];
}

export const showAliasAcctNo = (is_credit_card, account_number) => {
  let acct_number = is_credit_card == 0 ?"- Acct XX"+ account_number.substring(account_number.length-3) : "- Card XX"+ account_number.substring(account_number.length-2);
  return acct_number
}
