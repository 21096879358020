import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import loaderw from '../../Assets/img/buktec-preloader.gif';

function Loader(props) {

	
	return <div>
        <div className={"main-loader-sec " + (props.is_app_loading ? "" : " d-none ")}>
             <div className="fade modal-backdrop show" style={{zIndex: 100000}}></div>

             <div className="loader-sec">
              <img className="loaderimg" src={loaderw}/>
              <p className="s-reg">{props.text}</p>
             </div>
             
        </div>
	</div>
}

function mapStateToProps(state) {
  const is_app_loading = state.App.is_app_loading || false;
  const text = state.App.text || "";
	return {
		is_app_loading,text
	};
}
export default connect(mapStateToProps)(withRouter(Loader));