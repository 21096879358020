import React from 'react'
import { Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppliedFilterTag from '../../Components/BoilerPlate/AppliedFilterTag';
import Pagination from '../../Components/BoilerPlate/Pagination';
import SqueezedCard from '../../Components/BoilerPlate/SqueezedCard';
import { changeDateFormate, displayDateFormate } from '../../Helpers/DateFormat';
import { setPageNo, setTransactionFilterData } from '../../Redux/Actions/MoneyLink';
import { allowNumbersOnly } from '../../Utils/RegaxValidation';
import LinkedStatus from '../IcoonNew/LinkedStatus';
import NotReadyStatus from '../IcoonNew/NotReadyStatus';
import ProcessedStatus from '../IcoonNew/ProcessedStatus';
import ReadyStatus from '../IcoonNew/ReadyStatus';
import SyncedStatus from '../IcoonNew/SyncedStatus';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import { toast } from 'react-toastify';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import CurrencyInput from 'react-currency-input-field';
import StorageData from "../../Helpers/StorageData";

const sliderDiv = React.createRef()

const executeScroll = () => {
  sliderDiv.current.scrollTo(0, 0);
}

class SideListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      //search: this.props.history.location.state.search?this.props.history.location.state.search:"",
      transaction_id: this.props.match.params.transaction_id,
      page_changed: false
    }
  }
  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter })
    executeScroll()
  }

  handleTransactionIdChange = (e) => {
    const { name, value } = e.target;
    if (allowNumbersOnly(value)) {
      let filter_data = {
        [name]: value
      }
      this.props.dispatch(setTransactionFilterData(filter_data));
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'search') {
      // this.setState({ search: e.target.value })
      this.props.changeSearchText(e.target.value)
    }
    if ((name === "from_amount" || name === "to_amount") && allowNumbersOnly(value) === false) {
      return
    }
    let filter_data = {
      [name]: value
    }
    console.log(filter_data)
    this.props.dispatch(setTransactionFilterData(filter_data));

  }

  amountChange = (name, value) => {
    if (value > 999999999.99) {
      return;
    }
    let filter_data = {
      [name]: value
    }
    this.props.dispatch(setTransactionFilterData(filter_data));
  }

  applyFilter = () => {
    const { transaction_id, type, from_amount, to_amount, modified_by, voucher_status, is_star, is_evidence_linked, is_personal_filter, snapshot_id, messages } = this.props
    if (
      transaction_id === "" && type === "" && from_amount === "" && to_amount === "" && modified_by === "" && voucher_status === "" && is_star === "" && is_evidence_linked === "" && is_personal_filter === "" && snapshot_id === "" && messages === ""
    ) {
      toast.error("Please specify filter criteria")
    }
    else {
      this.props.applyFilter("apply");
      // on filter page changes so apply page changed condition to avoid url redirect condition
      this.setState({ showFilter: false, page_changed: true })
    }
  }


  resetFilter = () => {
    let filter_data = {
      transaction_id: '',
      type: '',
      from_amount: '',
      to_amount: '',
      modified_by: '',
      voucher_status: '',
      is_star: '',
      is_evidence_linked: '',
      is_personal_filter: '',
      snapshot_id: '',
      messages: ''
    }
    this.props.dispatch(setTransactionFilterData(filter_data));
    this.props.applyFilter("reset");

    // on filter page changes so apply page changed condition to avoid url redirect condition
    this.setState({ page_changed: true })
  }


  getStatus = (status) => {
    // switch (status) {
    //   case 1:
    //     return <ReadyStatus />
    //   case 2:
    //     return <NotReadyStatus />
    //   case 3:
    //     return <ProcessedStatus />
    //   case 4:
    //     return <LinkedStatus />
    //   case 5:
    //     return <SyncedStatus />
    //   case 6:
    //     return <NotProcessedStatus />
    //   default:
    //     return "--"
    // }

    switch (status) {
      case 1:
        return <NotReadyStatus />
      case 2:
        return <ReadyStatus />
      case 3:
        return <NotProcessedStatus />
      case 4:
        return <ProcessedStatus />
      case 5:
        return <SyncedStatus />
      default:
        return "--"
    }
  }
  componentWillReceiveProps(nextProps) {
    if ((nextProps.match.params.transaction_id !== this.props.match.params.transaction_id)) {
      if (nextProps.match.params.transaction_id) {
        this.setState({
          transaction_id: nextProps.match.params.transaction_id, page_changed: false
        })
        //console.log(this.props.location.state)
        if (this.props.location.state) {
          if (this.props.location.state.save_and_next) {
            let elem = document.getElementById("div" + this.props.match.params.transaction_id);
            if (!!elem) {
              //document.getElementById("div"+this.props.match.params.transaction_id).scrollIntoView();
            }
          }
        } else {
          setTimeout(() => {

            let elem = document.getElementById("div" + this.props.match.params.transaction_id);
            console.log(elem)
            if (!!elem) {
              //document.getElementById("div"+this.props.match.params.transaction_id).scrollIntoView();
            }
          }, 1800);
        }
      }
    }
  }


  componentDidMount = () => {
    if (this.props.match.params.transaction_id) {
      setTimeout(() => {
        let elem = document.getElementById("div" + this.props.match.params.transaction_id);
        if (!!elem) {
          //document.getElementById("div"+this.props.match.params.transaction_id).scrollIntoView();
        }
      }, 2000);
    }
    this.setState({ page_changed: false })
  }

  getTransactionList = (page_no) => {
    this.props.dispatch(setPageNo(page_no))
    this.setState({ page_changed: true })
  }

  selectedTransaction = (id) => {
    this.props.history.push('/money-link/' + id)
  }

  getModifiedBy = () => {
    if (this.props.modified_by) {
      let index = this.props.modified_by_list.findIndex(modified_by => modified_by.id == this.props.modified_by)
      return this.props.modified_by_list[index].full_name
    }
  }

  getVoucherStatus = () => {
    if (this.props.voucher_status) {
      if (this.props.voucher_status == 1) {
        return "Voucher Available";
      } else if (this.props.voucher_status == 0) {
        return "Voucher Not Available";
      } else if (this.props.voucher_status == 2) {
        return "Sync Voucher Error";
      } else if (this.props.voucher_status == 3) {
        return "Sent to Sync";
      } else if (this.props.voucher_status == 4) {
        return "Advance";
      }
    }
  }

  getTransactionId = () => {
    if (this.props.transaction_id) {
      return '#' + this.props.transaction_id
    }
  }

  getType = () => {
    if (this.props.type)
      return this.props.type == 1 ? "CR" : "DR"
  }

  getStar = () => {
    if (this.props.is_star)
      return this.props.is_star == 1 ? "Star - Yes" : "Star - No"
  }
  getCardFilterName = () => {
    switch (this.props.filter_card) {
      case 2:
        return "Ready"
      case 1:
        return "Not Ready"
      case 3:
        return "Processed"
      case 4:
        return "Synced"
      case 5:
        return "Not Processed"
      default:
        return ""
    }
  }

  getAmountRange = () => {
    if (this.props.from_amount || this.props.to_amount) {
      let from_amount = this.props.from_amount ? ('₹' + this.props.from_amount + ' ') : 'NA '
      let to_amount = this.props.to_amount ? ('₹' + this.props.to_amount) : 'NA'
      return from_amount + '- ' + to_amount
    }
  }

  getDateRange = () => {
    let default_yr = StorageData.getDefaultFinancialYear();
    if (this.props.financial_year_name === "Custom Date Range") {
      return displayDateFormate(default_yr.start_date) + ' to ' + displayDateFormate(default_yr.end_date)
    } else {
      return this.props.financial_year_name
    }
  }

  getIsEvidenceLinked = () => {
    if (this.props.is_evidence_linked)
      return this.props.is_evidence_linked == 1 ? "Linked - Yes" : "Linked - No"
  }

  getIsPersonal = () => {
    if (this.props.is_personal_filter)
      return this.props.is_personal_filter == 1 ? "Personal - Yes" : "Personal - No"
  }

  getIsMessage = () => {
    if (this.props.messages)
      return this.props.messages == 1 ? "Messages - Yes" : "Messages - No"
  }

  getSnapshotId = () => {
    if (this.props.snapshot_id) {
      return '#' + this.props.snapshot_id
    }
  }

  checkForEnterButton = (e) => {
    // e.preventDefault();
    if (e.charCode === 13 && this.props.searchText) {
      this.searchFilter(e)
    } else {
      // this.setState({ search: e.target.value })
      this.props.changeSearchText(e.target.value)
    }
  }

  searchFilter = (e) => {
    e.preventDefault();
    if (this.props.searchText) {
      this.props.searchFilter(this.props.searchText);
      this.props.changeSearchStatus(true)
      this.setState({ showSearch: false, showFilter: false })
    }
    else {
      toast.error("Please specify search criteria")
    }
  }

  searchResetFilter = (e) => {
    e.preventDefault();
    //this.setState({page_changed: true})
    this.props.changeSearchText("")
    this.props.searchFilter('')
    this.props.changeSearchStatus(false)
  }

  getWidgetFilter = () => {
    switch (this.props.filter_card) {
      case 1: return "Draft"
        break;
      case 2: return "Ready"
        break;
      case 3: return "Pending"
        break;
      case 4: return "Accounted"
        break;
      case 5: return "Synced"
        break;
      case 7: return "Linked"
        break;
      case 8: return "Not Linked"
        break;
      default: return "";
    }
  }

  render() {
    console.log(this.props);
    let transaction_id_found = true;

    let transFound = this.props.transaction_list.findIndex(trans => trans.id == this.props.match.params.transaction_id);

    if (transFound == -1) {
      transaction_id_found = false
    } else {
      transaction_id_found = true;
    }

    let elem = document.getElementById("div" + this.props.match.params.transaction_id);
    if (!!elem) {
      document.getElementById("div" + this.props.match.params.transaction_id).scrollIntoView();
    }

    return (
      <>
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 sidebar-listing">
          <div className="sidebar-fixed">
            <div className="sidebar-new-header">
              <div className="breadcrumb-header">
                <BreadCrum level={2} />
              </div>
              <h3 className="sidebar-icons">
                {(transaction_id_found || this.state.page_changed) && <div onClick={() => this.toggleFilter()} className={this.props.filter_by == 2 ? 'filler_moneyin active-filter':'filler_moneyin'}>
                  <span className="icon-filter green-icon s-filter-icon"></span>
                </div>}
              </h3>
            </div>
            
            <div className={"sidebar-new-sec " + (this.state.showFilter ? "remove-new-scroller " : "")} ref={sliderDiv}>
              <h4 className="side-trans-h">Transactions</h4>
              {/* Filter Form */}
              {this.state.showFilter ?
                <>
                  <div className="side-filter">
                    <div className="row">
                      <div className="col-12">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Narration</Tooltip>}>
                          <form className="form-group search-side-sec">
                            <input type="text"
                              className="form-control"
                              onKeyPress={this.checkForEnterButton}
                              placeholder="Search"
                              value={this.props.searchText}
                              onChange={(e) => this.handleChange(e)}
                              name="search"
                            />
                            {this.props.searchApplied === false ?
                              <Button className="active-search-icon" onClick={this.searchFilter}>
                                <span className="icon-loupe"></span>
                              </Button>
                              :
                              <Button className="active-search-icon crosebtn" onClick={this.searchResetFilter}>
                                <span class="icon-cancel"></span>
                              </Button>
                            }
                          </form>
                        </OverlayTrigger>
                      </div>

                      <div className="col-lg-6 col-sm-12 pr-lg-1">
                        <div className="form-group">
                          <input type="text" className="form-control" value={this.props.transaction_id}
                            placeholder="ID" name="transaction_id" onChange={this.handleTransactionIdChange} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 pl-lg-1">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" name="is_star" value={this.props.is_star} onChange={this.handleChange}>
                                <option selected disabled value="" >Starred</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                                {/* <option value="">Both</option> */}
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters">
                      <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                          <CurrencyInput type="text"
                            className="form-control text-right"
                            placeholder="From Amount"
                            decimalsLimit={2}
                            prefix="&#8377; "
                            onValueChange={(value, name) => this.amountChange(name, value)}
                            value={this.props.from_amount}
                            allowNegativeValue={false}
                            name="from_amount" />
                        </div>
                      </div>
                      <div className="col">
                        <hr className="hr-seprator" />
                      </div>
                      <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                          <CurrencyInput type="text"
                            className="form-control text-right"
                            placeholder="To Amount"
                            decimalsLimit={2}
                            prefix="&#8377; "
                            onValueChange={(value, name) => this.amountChange(name, value)}
                            value={this.props.to_amount}
                            allowNegativeValue={false}
                            name="to_amount" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" value={this.props.type}
                                name="type" onChange={this.handleChange}>
                                <option selected disabled value="" >CR/DR</option>
                                <option value={1}>CR</option>
                                <option value={2}>DR</option>
                                {/* <option value="">Both</option> */}
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" name="modified_by" value={this.props.modified_by} onChange={this.handleChange} >
                                <option selected disabled value="" >Last Modified By</option>
                                {this.props.modified_by_list.map((modified_by, index) => {
                                  return <option key={index} value={modified_by.id}>{modified_by.full_name}</option>
                                })}
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" value={this.props.voucher_status}
                                name="voucher_status" onChange={this.handleChange}>
                                <option defaultValue value="" >Voucher Info</option>
                                <option value={4}>Advance</option>
                                <option value={3}>Sent to Sync</option>
                                <option value={2}>Sync Voucher Error</option>
                                <option value={1}>Voucher Available</option>
                                <option value={0}>Voucher Not Available</option>
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12 pr-lg-1">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" value={this.props.is_evidence_linked}
                                name="is_evidence_linked" onChange={this.handleChange}>
                                <option defaultValue disabled value="" >Linked</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 pl-lg-1">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" value={this.props.is_personal_filter}
                                name="is_personal_filter" onChange={this.handleChange}>
                                <option defaultValue value="" >Personal</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12 pr-lg-1">
                        <div className="form-group">
                          <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select" value={this.props.messages}
                                name="messages" onChange={this.handleChange}>
                                <option defaultValue value="" >Messages</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                                <option value={2}>Unread</option>
                              </Form.Control>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <input type="text" className="form-control" value={this.props.snapshot_id} placeholder="Snapshot ID" name="snapshot_id" onChange={this.handleChange} />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="center_apply_btn_new">
                          <Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
                          <Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Filter Form */}
                </>
                :
                <>
                  {/* Tags */}
                  <div className="sidebar-tag-list">
                    {(transaction_id_found || this.state.page_changed) &&
                      <>
                        <AppliedFilterTag tagValue={this.props.bank_name} />
                        <AppliedFilterTag tagValue={this.getDateRange()} />
                        <AppliedFilterTag tagValue={this.getWidgetFilter()} />
                        {
                          (this.props.filter_by == 2) ?
                            <>
                              <AppliedFilterTag tagValue={this.getAmountRange()} />
                              {/* <AppliedFilterTag tagValue={this.getCardFilterName()} /> */}
                              <AppliedFilterTag tagValue={this.getModifiedBy()} />
                              <AppliedFilterTag tagValue={this.getVoucherStatus()} />
                              <AppliedFilterTag tagValue={this.getTransactionId()} />
                              <AppliedFilterTag tagValue={this.getType()} />
                              <AppliedFilterTag tagValue={this.getStar()} />

                              <AppliedFilterTag tagValue={this.getIsEvidenceLinked()} />
                              <AppliedFilterTag tagValue={this.getIsPersonal()} />
                              <AppliedFilterTag tagValue={this.getIsMessage()} />
                              <AppliedFilterTag tagValue={this.getSnapshotId()} />

                            </> :
                            <>
                              <AppliedFilterTag tagValue={this.props.searchText} />
                            </>
                        }
                      </>
                    }
                  </div>
                  {/* End Tags */}
                </>
              }

              {/* Cards */}
              {!transaction_id_found && !this.state.page_changed ? <SqueezedCard
                card_id={this.props.transaction_detail.id}
                index={0}
                id={this.props.transaction_detail.id}
                date={this.props.transaction_detail.date}
                amount={this.props.transaction_detail.amount}
                narration={this.props.transaction_detail.transaction_narration}
                name={this.props.transaction_detail.financial_institute}
                status={this.getStatus(this.props.transaction_detail.accounting_status == 3 ? this.props.transaction_detail.entry_status : this.props.transaction_detail.accounting_status)}
                type={this.props.transaction_detail.type == 1 ? "CR" : "DR"}
                expense={false}
                selectedCard={this.selectedTransaction}
              />
                : this.props.transaction_list.length === 0 && this.props.is_transaction_loading === false ?                  
                  <div className="sidebar-cards">
                    <h3 className="no-item">No Transactions</h3>
                  </div>
                  :
                  this.props.transaction_list.map((transaction, index) => {
                    return <SqueezedCard
                      card_id={this.state.transaction_id}
                      index={index}
                      id={transaction.id}
                      date={transaction.date}
                      amount={transaction.amount}
                      narration={transaction.narration}
                      name={transaction.financial_institute}
                      status={this.getStatus(transaction.status)}
                      type={transaction.type == 1 ? "CR" : "DR"}
                      expense={false}
                      selectedCard={this.selectedTransaction}
                    />
                  })
              }
              {/* End Cards */}
            </div>

            {!transaction_id_found && !this.state.page_changed ? <></>
              : <Pagination
                getList={this.getTransactionList}
                has_previous={this.props.has_previous}
                has_next={this.props.has_next}
                page_no={this.props.page_no}
                per_page_items={this.props.per_page_items}
                total_pages={this.props.total_pages}
              />
            }
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  //const page_no = all_value.page_no;
  const from_amount = all_value.from_amount || '';
  const to_amount = all_value.to_amount || '';
  const modified_by = all_value.modified_by || '';
  const voucher_status = all_value.voucher_status || '';
  const is_star = all_value.is_star || '';
  const transaction_id = all_value.transaction_id || ''
  const type = all_value.type || '';
  const bank_name = all_value.bank_name;
  const financial_year_name = all_value.financial_year_name;
  const filter_start_date = all_value.filter_start_date;
  const filter_end_date = all_value.filter_end_date;
  const filter_card = all_value.filter_card;
  //const filter_by = all_value.filter_by;
  const is_transaction_loading = all_value.is_transaction_loading;
  const searchText = all_value.search

  const is_evidence_linked = all_value.is_evidence_linked || '';
  const is_personal = all_value.is_personal || '';
  const snapshot_id = all_value.snapshot_id || '';

  // change filter name for is_personal as it is conflicting with form is_personal
  const is_personal_filter = all_value.is_personal_filter;
  const messages = all_value.messages || '';
  //console.log(all_value)
  return {
    all_value, is_star, from_amount, to_amount, transaction_id, modified_by, voucher_status, type, bank_name,
    financial_year_name, filter_start_date, filter_end_date, filter_card, is_transaction_loading, is_evidence_linked, is_personal, snapshot_id, is_personal_filter, messages
  };
}

export default connect(mapStateToProps)(withRouter(SideListView));