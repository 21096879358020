import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Link, useLocation
} from 'react-router-dom';
import { connect } from "react-redux";
import StorageData from '../../Helpers/StorageData'
import { CREATE_BUSSINESS_SNAPSHOT_CODE, CREATE_REIMBUSRMENT_SNAPSHOT_CODE } from '../../Helpers/Constants';
import {setEntityPermission, setModulePermission, setEntityConfigParam} from "../../Redux/Actions/Session";
import {setBankGapCount} from "../../Redux/Actions/Banking";
import { setAppilcationLoder } from "../../Redux/Actions/App";
import MasterService from '../../Services/MasterService';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TheSidebarNew = (props) => {
  const location = useLocation();

  let create_snapshot_permission = false;
  if (props.features_permissions_list.includes(CREATE_BUSSINESS_SNAPSHOT_CODE) || props.features_permissions_list.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE)) {
    create_snapshot_permission = true;
  }

  useEffect(() => {
    console.log("Location path ---------------" , location);
    // Function to call the API
    const callApi = async () => {
      let default_entity_detail = StorageData.getDefaultEntity() || {};
console.log("default Entity detail id --------" , default_entity_detail);
      props.dispatch(setAppilcationLoder(true));
      await MasterService.getEntityPermission(default_entity_detail.id).then(data => {
        if (data.response_code === 200) {
          const module_permissions = data.result.modules;
          const features_permissions_list = data.result.permissions;
          const entity_config_param = data.result.configuration;
          props.dispatch(setEntityPermission(features_permissions_list));
          props.dispatch(setModulePermission(module_permissions));
          props.dispatch(setEntityConfigParam(entity_config_param));
          console.log("gap count val ------------------" , entity_config_param.banking_gap_count);
          props.dispatch(setBankGapCount(entity_config_param.banking_gap_count));
         // this.setState({ is_loading: false })
        }
        props.dispatch(setAppilcationLoder(false));
      });
    };

    

    // // Call the API when the route changes
     callApi();
  }, [location.pathname]);

  return (
    <div className="quick-menu">
      <ul className="p-0">
        <li className={!!props.module_permissions.length ? "" : "d-none"}>
          <Link to="/dashboard" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Dashboard"><span className="path1"></span><span className="path2"></span></span></span>
            <span className="quick-link-text">Dashboard</span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(1) && create_snapshot_permission ? "" : "d-none"}>
          <Link to={"/snapshot/evidence/create"} className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span class="icon-plus"></span></span>
            <span className="quick-link-text">Create Snapshot</span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(1) ? "" : "d-none"}>
          <Link to={"/snapshot/evidence"} className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Group-2520 snap-side-icon"></span></span>
            <span className="quick-link-text">Snapshot</span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(4) ? "" : "d-none"}>
          <Link to="/financial-institutes" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-FI"><span className="path1"></span><span className="path2"></span></span></span>
            <span className="quick-link-text">Banking
            
            {
              props.bank_gap_count_val != 0 && props.bank_gap_count_val ?
              // <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.bank_gap_count_val} - Gap{props.bank_gap_count_val>1?'s':''} found in the statement:<span className="gap-count-date">&nbsp;31-02-24</span><span className="gap-count-date">, 26-03-24</span></Tooltip>}>
              <span className="hm-menu-gap-count">{props.bank_gap_count_val}</span>
            // </OverlayTrigger>
            :null
            }
              
            </span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(5) ? "" : "d-none"}>
          <Link to="/money-link" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Link-icon"></span></span>
            <span className="quick-link-text">Money Link
              {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">2 - Gap found in the statement:<span className="gap-count-date">&nbsp;31-02-24</span><span className="gap-count-date">, 26-03-24</span></Tooltip>}>
                <span className="hm-menu-gap-count">2</span>
              </OverlayTrigger> */}
            </span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(10) ? "" : "d-none"}>
          <Link to="/reports" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Exports"><span className="path1"></span><span className="path2"></span></span></span>
            <span className="quick-link-text">Reports</span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(7) ? "" : "d-none"}>
          <Link to="/docbox" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Doc-box snap-side-icon" style={{ fontWeight: 600 }}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span></span>
            <span className="quick-link-text">Docbox</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/welcome" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-dashboard-1"></span></span>
            <span className="quick-link-text">Home</span>
          </Link>
        </li>
        <li>
          <Link to="/profile-management/entities" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Group-2520"></span></span>
            <span className="quick-link-text">Profile Management</span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(2) ? "" : "d-none"}>
          <Link to="/invoices" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Group-2520"></span></span>
            <span className="quick-link-text">Invoices</span>
          </Link>
        </li>
        <li className={props.module_permissions.includes(3) ? "" : "d-none"}>
          <Link to="/cheque" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Group-2520"></span></span>
            <span className="quick-link-text">Cheques</span>
          </Link>
        </li>
        <li>
          <Link to="/task-manager" className="text-decoration-none d-flex align-items-center">
            <span className="quick-icons d-flex justify-content-center"><span className="icon-Group-2520"></span></span>
            <span className="quick-link-text">Task Manager</span>
          </Link>
        </li> */}
      </ul>
    </div>
  )
}

function mapStateToProps(state) {
  const session_values = state.Session || {};
  const banking_values = state.Banking || {};
  const module_permissions = session_values.module_permissions || [];
  const features_permissions_list = session_values.features_permissions_list || [];
  const bank_gap_count_val = banking_values.bank_gap_count || null;
  return {
    module_permissions, features_permissions_list,bank_gap_count_val
  };
}
export default connect(mapStateToProps)(React.memo(TheSidebarNew));
