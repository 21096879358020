import React from 'react';
import { connect } from "react-redux";

class ShowCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_card: this.props.filter_card
    }
  }

  onCardSelected = (invoice_card_filter) => {
    if (invoice_card_filter != this.state.filter_card) {
      this.setState({
        filter_card: invoice_card_filter
      }, () => this.props.onCardSelected(this.state.filter_card))
    }
  }

  render() {
    return (
      <div className="widgets_taskin">
        <div className="row widget-filter-new sec-mb">
          <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(0)}>
            <div className={"widget-block-new " + (this.state.filter_card == 0 ? " active-widget" : "")}>
              <p className="wtitle-new">Total</p>
              <p className="wcounter-new">{this.props.task_count.total}</p>
            </div>
          </div>
          <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(1)}>
            <div className={"widget-block-new " + (this.state.filter_card == 1 ? " active-widget" : "")}>
              <p className="wtitle-new">Open</p>
              <p className="wcounter-new">{this.props.task_count.not_done}</p>
            </div>
          </div>
          <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(2)}>
            <div className={"widget-block-new " + (this.state.filter_card == 2 ? " active-widget" : "")}>
              <p className="wtitle-new">Overdue</p>
              <p className="wcounter-new">{this.props.task_count.overdue}</p>
            </div>
          </div>
          <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(3)}>
            <div className={"widget-block-new " + (this.state.filter_card == 3 ? " active-widget" : "")}>
              <p className="wtitle-new">Done</p>
              <p className="wcounter-new">{this.props.task_count.done}</p>
            </div>
          </div>
          <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(4)}>
            <div className={"widget-block-new " + (this.state.filter_card == 4 ? " active-widget" : "")}>
              <p className="wtitle-new">Assigned to me</p>
              <p className="wcounter-new">{this.props.task_count.assigned_to_me}</p>
            </div>
          </div>
          <div className="col-xl col-lg-3 col-md-4 col-sm-4 col-6" onClick={() => this.onCardSelected(5)}>
            <div className={"widget-block-new " + (this.state.filter_card == 5 ? " active-widget" : "")}>
              <p className="wtitle-new">Assigned by me</p>
              <p className="wcounter-new">{this.props.task_count.assigned_by_me}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const task_count = state.TaskManager.task_count || { assigned_by_me: 0, assigned_to_me: 0, done: 0, not_done: 0, overdue: 0, total: 0 };

  return {
    task_count,
  };
}

export default connect(mapStateToProps)(ShowCount);