import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function NotProcessedStatus(props) {
    return <div  >
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Pending</Tooltip>}>
            {/* <span className="icon-personal-icon ft-sz-30 icon-td"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span> */}

            <span className="not-processed-icon d-block"> <span className="icon-processed-icon ft-sz-30 icon-td"><span className="path1"></span><span className="path2"></span></span></span>
        </OverlayTrigger> </div> 
}
export default NotProcessedStatus;