import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import SaveChangesPopUp from '../../Components/BoilerPlate/SaveChangesPopUp';
import ChequeBottomSection from './ChequeMessageSection';
import { validateCheque } from './Validation';
import { toast } from 'react-toastify';
import ChequeService from '../../Services/Cheques/ChequeService';
import { withRouter } from 'react-router-dom';
import StorageData from '../../Helpers/StorageData';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import { changeDateFormate } from '../../Helpers/DateFormat';

class CreateChequeForm extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			show_save_popup: false,
			show_change_log: false,
			img_loading: false,
			show_delete: false,
			deleteLoading: false,

			date: '',
			type: '',
			narration: '',
			payer: '',
			payee: '',
			bank: '',
			amount: '',
			cheque: '',
			status: 2,
			files: '',
			cheque_id: props.match.params.cheque_id || '',
			cheque_no: '',
			errors: {},
			entity_name: '',
			preview: ''
		}
	}

	emptyStateValue = () => {
		this.setState({
			date: '',
			type: '',
			narration: '',
			payer: '',
			payee: '',
			bank: '',
			amount: '',
			cheque: '',
			status: 2,
			files: '',
			cheque_id: '',
			cheque_no: '',
			errors: {},
			preview: ''
		})
	}

	componentDidMount() {
		this.getEntity()
	}

	getChequeDetails = () => {
		ChequeService.getChequeDetail(this.state.cheque_id).then(
			(data) => {
				console.log('edit cheque data  ', data)
				if (data.response_code == 200) {
					this.setState({
						chequeBeforeEdit: data.result,
						amount: data.result.amount,
						bank: data.result.bank_name,
						cheque: data.result.cheque_identifier,
						files: data.result.file_url,
						narration: data.result.narration,
						status: data.result.status,
						type: data.result.type,
						date: new Date(data.result.date),
						cheque_no: data.result.cheque_no,
						payer: data.result.type == 2 ? data.result.beneficiary : this.state.entity_name,
						payee: data.result.type == 1 ? data.result.beneficiary : this.state.entity_name
					});
				}
			});
	}


	getEntity = () => {
		let entity_name = StorageData.getEntityName() || 'Temp Entity Name'
		// EntityService.getEntityDetait(5).then(data => {
		// 	if (data.response_code == 200) {
		this.setState({ entity_name: entity_name }, () => {
			if (this.state.cheque_id)
				this.getChequeDetails();
		})
		// 	}
		// })
	}

	removeImg = () => {
		this.setState({ files: '' })
	}

	handleChange = (e) => {
		const { value, name } = e.target;
		if (name == 'status') {
			if (value == 2) {
				this.setState({ [name]: value });
			} else {
				this.checkForReadyState(true)
			}
		} else if (name == 'files') {
			this.uploadImage(e.target.files[0])
		}
		else {
			this.setState({ [name]: value }, () => {
				if (name == 'type' || name == 'cheque' || name == 'bank')
					this.checkForReadyState(false);
				if (name == "type" && value == 2)
					this.setState({ payee: this.state.entity_name, payer: '' })
				if (name == "type" && value == 1)
					this.setState({ payer: this.state.entity_name, payee: '' })
			})
		}
	}

	uploadImage = (files) => {
		this.setState({ img_loading: true })
		let fd = new FormData();
		fd.append('attachment', files);
		fd.append('type', 'cheques');
		ChequeService.uploadImage(fd).then(data => {
			if (data.response_code == 200) {
				this.setState({ files: data.result.file_url, img_loading: false })
			} else {
				toast.warning("Couldn't upload image")
				this.setState({ img_loading: false })
			}
		})
	}

	checkForReadyState = (valid) => {
		let data = {
			"type": this.state.type,
			"date": changeDateFormate(this.state.date),
			"bank_name": this.state.bank,
			"cheque_identifier": this.state.cheque,
		}
		let errors = validateCheque(data);
		if (Object.keys(errors).length === 0) {
			this.setState({ status: 1 });
		} else {
			if (valid)
				toast.warning('Please fill the mandatory details')
			this.setState({
				status: 2
			});
		}
	}


	toggleSavePopup = () => {
		this.setState({ show_save_popup: !this.state.show_save_popup })
	}

	toggleChangeLog = () => {
		this.setState({ show_change_log: !this.state.show_change_log })
	}

	setDate = (date, key) => {
		this.setState({ [key]: date }, () => {
			this.checkForReadyState(false)
		})
	}

	saveCheque = () => {
		this.setState({
			loading: true
		});
		let data = {
			"type": this.state.type,
			"date": changeDateFormate(this.state.date),
			"bank_name": this.state.bank,
			"cheque_identifier": this.state.cheque,
			"beneficiary": this.state.type == 1 ? this.state.payee : this.state.payer,
			"amount": this.state.amount,
			"narration": this.state.narration,
			"file_url": this.state.files,
			"status": this.state.status
		}
		let errors = validateCheque(data);
		if (this.state.cheque_id) {
			data.id = this.state.cheque_id
		}
		if (Object.keys(errors).length === 0) {
			if (this.state.cheque_id) {
				ChequeService.updateCheque(data).then(res => {
					this.setState({
						loading: false, errors: {}
					});
					if (res.response_code == 200) {
						toast.success(res.message);
						let cheque = {
							amount: this.state.amount,
							id: this.state.cheque_id,
							payee: this.state.payee,
							payer: this.state.payer
						}
						this.props.newCheque(cheque, true);
					} else {
						toast.error(res.message);
					}
				})
			} else {
				ChequeService.createCheque(data).then(res => {
					this.setState({
						loading: false, errors: {}
					});
					if (res.response_code == 200) {
						toast.success(res.message);
						this.setState({ cheque_id: res.result.id })
						let cheque = {
							amount: this.state.amount,
							id: res.result.id,
							payee: this.state.payee,
							payer: this.state.payer
						}
						this.props.newCheque(cheque, false);
						this.props.history.push('/cheque/create/' + res.result.id)
					} else {
						toast.error(res.message);
					}
				})
			}
		} else {
			this.setState({
				loading: false,
				errors: errors
			});
		}
	}

	backToList = () => {
		this.props.history.push('/cheque')
	}
	toggleDeletePopUp = () => {
		this.setState({ show_delete: !this.state.show_delete })
	}
	deleteCheque = () => {
		this.setState({ deleteLoading: true })
		ChequeService.deleteCheque(this.state.cheque_id).then((data) => {
			if (data.response_code == 200) {
				this.setState({ deleteLoading: false })
				toast.success(data.message);
				this.toggleDeletePopUp()
				// this.emptyStateValue()
				this.props.history.push('/cheque/create')
			} else {
				toast.error(data.message);
				this.setState({
					errors: data.reason, deleteLoading: false
				});
			}
		})
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.match.params.cheque_id !== this.props.match.params.cheque_id)) {
			console.log("inside", nextProps.match.params.cheque_id, this.props.match.params.cheque_id)
			if (nextProps.match.params.cheque_id) {
				this.setState({
					cheque_id: nextProps.match.params.cheque_id
				}, () => this.getChequeDetails())
			} else {
				this.emptyStateValue()
			}
		}
	}

	render() {
		return (
			<div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
				<SaveChangesPopUp showModal={this.state.show_save_popup} toggleSavePopUp={this.toggleSavePopup}
					saveChanges={this.backToList} />
				<ChangeLog show_logs={this.state.show_change_log} toggleChangeLog={this.toggleChangeLog} />
				<DeletePopUp
					toggleDeletePopUp={this.toggleDeletePopUp}
					showModal={this.state.show_delete}
					deleteItem={this.deleteCheque}
					deleteLoading={this.state.deleteLoading} />
				<div className="inner-content-sec">
					<div className="clearfix main-title-btn-sec">
						<div className="head-title pull-left">
							<h2 className="s-sbold blu-color m-0">Create Cheque
							<span className="icon-Information green-icon help"></span></h2>
						</div>
						<div className="pull-right">

						</div>
					</div>
					<div className="main-file-item-sec">
						<div className="cross" onClick={this.toggleSavePopup}>
							<span>X</span>
						</div>
						<div className="row">
							<div className="col-xl-4 col-sm-12">
								<div className="left-inner-content-sec left-inner-cheque-sec snapshot-uplaod">
									<div className="upload-file-sec">
										<h1 className="blu-color">{this.state.files.length ? 'File Uploaded' : 'Upload your file'} </h1>
										<div className="upload-file-btn" >
											{this.state.img_loading && (
												<span className="spinner-border spinner-border-sm ml-4"></span>
											)}
											{this.state.files && <div className="cross"><span className="cross-span" onClick={this.removeImg}>X</span></div>}
											{this.state.files ?
												<img src={this.state.files} height="150px" width="100px" /> :
												this.state.img_loading === false && <span className="icon-plus"></span>}
										</div>
										<input type="file" name="files" onChange={this.handleChange} />

										<p className="blu-color s-sbold drag-text">Drag File</p>
										<p className="m-0 font-colr click-text">or click here to attach</p>
										<p className="m-0 font-colr click-text d-none">Click on file to view</p>
									</div>
								</div>
							</div>
							<div className="col-xl-8 col-sm-12">
								<div className="right-inner-content-sec">
									<div className="right-inner-content-sec-box">
										<div className="top-head clearfix">
											<p className="pull-left s-sbold ft-sz-22 blu-color m-0">Cheque Details</p>
											<p className="pull-right">
												{this.state.cheque_id ? <span className="icon-log icon-round-info-button"
													onClick={this.toggleChangeLog}></span> : null}
												<span className="id-no-text s-sbold m-0">{this.state.cheque_no ? 'ID #' + this.state.cheque_no : 'New'}</span></p>
										</div>
										<div className="row">
											<div className="col-lg-5"></div>
											<div className="col-lg-7 right-form-sec">
												<form>
													<div className="form-group">
														<p className="lable-input font-colr">Type*</p>
														<select className="form-control" name="type" value={this.state.type}
															onChange={this.handleChange} >
															<option value="" disabled selected>Select your option</option>
															<option value="1">Issued</option>
															<option value="2">Received</option>
														</select>
														{this.state.errors.type &&
															<small className="form-text text-muted">
																{this.state.errors.type}</small>}
													</div>
													<div className="form-group date-sec">
														<p className="lable-input font-colr">Cheque Date*</p>
														<div className="inner-date-sec">
															<ReactDatePicker
																dateFormat="yyyy-MM-dd"
																onChange={date => this.setDate(date, "date")}
																className="form-control"
																minDate={new Date()}
																selected={this.state.date}
																autoComplete="off"
																name="date" />
															<span className="icon-calendar"></span>
															{this.state.errors.date &&
																<small className="form-text text-muted">
																	{this.state.errors.date}</small>}
														</div>
													</div>
													<div className="form-group">
														<p className="lable-input font-colr">Bank*</p>
														{/* {this.state.type == 1 ?
															<select className="form-control blu-color input-bg-green" name="bank" value={this.state.bank}
																onChange={this.handleChange} >
																<option value="" disabled selected>Select your option</option>
																<option value="1">HDFC Bank</option>
																<option value="2">IDBI Bank</option>
																<option value="3">AXIS Bank</option>
															</select> : */}
														<input type="text" className="form-control blu-color input-bg-green"
															name="bank" value={this.state.bank} onChange={this.handleChange} />
														{/* } */}
														{this.state.errors.bank &&
															<small className="form-text text-muted">
																{this.state.errors.bank}</small>}
													</div>
													<div className="form-group">
														<p className="lable-input font-colr">Cheque#</p>
														<input type="text" className="form-control blu-color input-bg-green text-right"
															name="cheque" value={this.state.cheque} onChange={this.handleChange} />
														{this.state.errors.cheque &&
															<small className="form-text text-muted">
																{this.state.errors.cheque}</small>}
													</div>
													<div className="form-group">
														<p className="lable-input font-colr">Amount</p>
														<input type="text" className="form-control blu-color input-bg-green text-right"
															name="amount" value={this.state.amount} onChange={this.handleChange} />
													</div>
													<div className="form-group">
														<p className="lable-input font-colr">Payer</p>
														<input type="text" className={"form-control blu-color" + (this.state.type == 1 ? " input-bg-gray " : "")} placeholder=""
															name="payer" readOnly={this.state.type == 1 ? true : false} value={this.state.payer} onChange={this.handleChange} />
													</div>
													<div className="form-group">
														<p className="lable-input font-colr">Payee</p>
														<input type="text" className={"form-control blu-color" + (this.state.type == 2 ? " input-bg-gray " : "")}
															placeholder="" readOnly={this.state.type == 2 ? true : false}
															name="payee" value={this.state.payee} onChange={this.handleChange} />
													</div>
													<div className="form-group">
														<p className="lable-input font-colr">Narration</p>
														<div className="form-group">
															<textarea className="form-control" rows="4" onChange={this.handleChange}
																name="narration" value={this.state.narration} ></textarea>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div className="bottom-save-btn clearfix">
						<div className="pull-left">
							<button type="button" className="next-bottom-btn btn save-btn graybtn">Back</button>
							{this.state.cheque_id ? <button type="button" className="btn save-btn white-color drk-gry-bg"
								onClick={this.toggleDeletePopUp} >Delete </button> : null}
						</div>
						<div className="pull-right">
							<div className="ready-btn">
								<select name="status" value={this.state.status} onChange={this.handleChange}>
									<option value="2" selected>Not Ready</option>
									<option value="1">Ready</option>
								</select>
							</div>
							<button type="button" className={"btn save-btn ft-sz-22  s-bold greenbtn pull-right " + (this.state.loading ? "position-reltv" : "")}
								disabled={this.state.loading} onClick={this.saveCheque}>

								{this.state.loading && (
									<span className="spinner-border spinner-border-sm"></span>
								)}
								<span>Save</span>
							</button>
							{/* <button type="button" className="btn save-btn white-color" onClick={this.saveCheque} >Save</button> */}
						</div>
					</div>
					<ChequeBottomSection />
				</div>
			</div>

		)
	}
}

function mapStateToProps(state) {
	const { cheque } = state.cheque;
	return {
		cheque
	};
}

export default connect(mapStateToProps)(withRouter(CreateChequeForm));
