import axios, { CancelToken } from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';
import { DOCAI_URL } from '../../Utils/Config';
import { timeoutInMillis } from '../../Helpers/Constants';
class SnapshotService {

  createSnapshot(data) {
    return axios
      .post(API_URL + "snapshot", data, { headers: Header.entityHeader(), timeout: timeoutInMillis})
      .then((response) => {
        return response.data;
      }).catch((error) => {
        if (error.request) {
          return 'timeout'
        }
        return error;
      });
  }

  createSnapshotEntries(data){
    return axios
      .post(API_URL + "snapshots-voucher-entries", data , {headers: Header.entityHeader(), timeout: timeoutInMillis})
      .then((response)=> {
        return response.data;
      }).catch((error) => {
        if(error.request){
          return 'timeout'
        }
        return error;
      })
  }

  importExcelUpload(data) {
    return axios
      .post(API_URL + "snapshot-excel-upload", data, { headers: Header.entityHeader(), timeout: timeoutInMillis })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        if (error.request) {
          return 'timeout'
        }
        return error;
      });
  }

  importExcelMasterSnapshot(data){
    return axios
      .post(API_URL + "snapshot-masters", data, { headers: Header.entityHeader(), timeout: timeoutInMillis })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        if (error.request) {
          return 'timeout'
        }
        return error;
      });
  }

  importExcelEmail(data){
    return axios
      .post(API_URL + "send-snapshot-excel-report", data, { headers: Header.entityHeader(), timeout:timeoutInMillis})
      .then((response) => {
        return response.data;
      }).catch((error) => {
        if (error.request) {
          return 'timeout'
        }
        return error;
      });
  }

  updateSnapshot(data) {
    return axios
      .put(API_URL + "snapshot", data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSnapshotDetail(snap_id) {
    return axios
      .get(API_URL + "snapshot/" + snap_id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSnapshotData() {
    return axios.get(API_URL + 'snapshots-data', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSnapshotList(filter_data) {
    let headerObj =  { headers: Header.entityHeader() }
    if(filter_data.is_export == 1){
        headerObj.responseType = 'arraybuffer'
    }
    return axios.post(API_URL + 'snapshots', filter_data, headerObj)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSnapshotWidgetCount(filter_data) {
    return axios.post(API_URL + 'snapshots-count', filter_data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  makeRemoveStar(data) {
    return axios.put(API_URL + 'snapshot-star', data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getChangeLog() {
    return axios.get(API_URL + 'change-log', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getEvidenceSMS(data) {
    return axios.post(API_URL + 'snapshot-smses', data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSmsList(data) {
    return axios.post(API_URL + 'smses', data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSMSDetails(id) {
    return axios.get(API_URL + 'sms/' + id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getFinancialInstituteList() {
    return axios.get(API_URL + 'payment-details', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  deleteSMS(id) {
    return axios.delete(API_URL + 'sms/' + id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  deleteMultipleSMS(data) {
    return axios.delete(API_URL + 'smses', { data: data, headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getPredictionData(file_path = "",expense, type) {
    //console.log(type)
    let data = {
        "file_path" : file_path,
        "expense" : expense ? 1 : 0,
        "snapshot_type" : parseInt(type) || null
    };

    return axios.post(API_URL + 'snapshots-prediction', data, { headers: Header.entityHeader(), timeout: timeoutInMillis})
      .then((response) => {
        return response.data;
      }).catch((error) => {
        if (error.request) {
          return 'timeout'
        }
        return error;
      });
  }


 newGetpredictionData(formData){
  
console.log('get prediction data  from snap shot ================= >>', DOCAI_URL);
  return axios.post(DOCAI_URL + 'predict',formData,{ headers : Header.formDataHeader(), timeout : timeoutInMillis})
  .then((response)=>{
    return response.data;
  }).catch((error)=>{
    if(error.request){
      return 'timeout'
    }
    return error;
  })
 }


  deleteEvidence(id) {
    return axios.delete(API_URL + 'snapshot/' + id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }
  
  deleteBulkSnapshot(data){
    return axios.delete(API_URL + 'bulk-snapshot' , { data:{"snapshots":data}, headers: Header.entityHeader() })
      .then((response)=> {
        return response.data;
      }).catch((error)=> {
        return error;
      })
  }

  getMerchantList() {
    return axios.get(API_URL + 'snapshot-merchants', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSnapshotLedgerRecommendation = (data) => {
    return axios.post(API_URL + 'snapshot-ledger-recommendation', data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getSnapshotMasters() {
    return axios.get(API_URL + 'snapshot-masters', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getItemLedgers() {
    return axios.get(API_URL + 'item-ledgers', { headers: Header.entityHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }
}

export default new SnapshotService();