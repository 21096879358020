import axios from "axios";
import { API_URL } from '../Utils/Config';
import Header from './Header'

class AuthService {
  login(emailId, username, password, recaptchaToken, remeberMe,token) {
    // let account_id = /^\d+$/.test(emailId) ? parseInt(emailId) : emailId;
    let data = {};
    if(!!token){
      data = {
        token:token,
        password: password,
        recaptcha_token: recaptchaToken,
        remember_me: remeberMe
      };
      return axios
        .put(API_URL + "login", data, { headers: Header.withoutAuthHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
    }else{
      data = {
        account_id: emailId,
        username: username,
        password: password,
        recaptcha_token: recaptchaToken,
        remember_me: remeberMe
      };
      return axios
        .post(API_URL + "login", data, { headers: Header.withoutAuthHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
    }
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(type, full_name, email, phone_number, username, password, recaptchaToken, dial_code,token) {
    let data = {
      "account_type_id": type == "business" ? 1 : 2,
      "full_name": full_name,
      "email": email,
      "phone_number": phone_number,
      "username": username,
      "password": password,
      "recaptcha_token": recaptchaToken,
      "dialcode_id": dial_code,
      "is_promotional": type == "business" ? (token == "trail" ? 1 : 1) : null
    };
    // For now we removed trail and normal registration flow
    // below code is for trail registration and normal flow
    //  "is_promotional": type == "business" ? (token == "trail" ? 1 : 0) : null
    return axios
      .post(API_URL + "signup", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  verifyEmailOTP(id, otp,recaptchaToken,entity_request_id,emailId) {
    let data = {
      "id": id,
      "otp": parseInt(otp),
      "recaptcha_token":recaptchaToken,
      "event": "signup",
      "entity_request_id" : entity_request_id,
      "account_id" : emailId
    };
    return axios
      .post(API_URL + "verify-otp", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  resendOTP(id) {
    let data = {
      "id": id,
      "event":"signup",
    };
    return axios
      .post(API_URL + "resend-otp", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  forgetUsername(accountId, emailId, phone_number, recaptchaToken, dialCodeId) {
    let data = {
      "account_id": accountId,
      "dialcode_id": dialCodeId,
      "email": emailId,
      "phone_number": phone_number,
      "recaptcha_token" : recaptchaToken
    };
    return axios
      .post(API_URL + "forgot-username", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        console.log('response in username  ', response)
        return response.data;
      }).catch((error) => {
        console.log('errror in username  ', error)
        return error;
      });
  }

  forgotPassword(emailId, username, recaptchaToken) {
    let data = {
      account_id: emailId,
      username: username,
      recaptcha_token: recaptchaToken
    };
    return axios
      .post(API_URL + "forgot-password", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  verifyForgetOTP(id, otp,recaptcha_token) {
    let data = {
      "id": id,
      "otp": parseInt(otp),
      "event": "forgot_password",
      "recaptcha_token":recaptcha_token
    };
    return axios
      .post(API_URL + "verify-otp", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  resetPassword(token, password, confirmPassword) {
    let data = {
      "reset_token": token,
      "new_password": password,
      "confirm_password": confirmPassword,
    };
    return axios
      .post(API_URL + "reset-password", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  resendForgetOTP(id) {
    let data = {
      "id": id,
      "event":"forgot_password",
    };
    return axios
      .post(API_URL + "resend-otp", data, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }


  countryListing() {
    return axios
      .get(API_URL + "dialcodes", { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  invite(token) {
    return axios.get(API_URL + "invite/"+token, { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

}

export default new AuthService();