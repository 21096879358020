import React from 'react'
import CreateEvidenceForm from './CreateEvidenceForm';
import CreateSnapShotSideList from './CreateSnapShotSideList';

class CreateSnapshotView extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			snapshot_detail: []
		}
	}

	componentWillUnmount() {
		this.setState({snapshot_detail: []})
	}

	render() {
		return (
			<main className="content-wrapper-inner-new">
                <div className="container-fluid container-padding-new">
                    <div className="row">
						<CreateSnapShotSideList
							snapshot_list={this.props.snapshot_list}
							changeSnapshotList={this.props.changeSnapshotList}
							isSearchApplied={this.props.isSearchApplied}
							changeIsSearchApplied={this.props.changeIsSearchApplied}
							applyFilter={this.props.applyFilter}
							creaters={this.props.creaters}
							listed_tags={this.props.listed_tags}
							categories={this.props.categories}
							financial_institutes={this.props.financial_institutes}
							filter_by={this.props.filter_by}
							searchFilter={this.props.searchFilter}         
							filter_card={this.props.filter_card}
							has_next={this.props.has_next}
							has_previous={this.props.has_previous}
							per_page_items={this.props.per_page_items}
							page_no={this.props.page_no}
							handlePageChange={this.props.handlePageChange} 
							snapshot_detail={this.state.snapshot_detail}
							snapshot_types={this.props.snapshot_types}
							total_pages={this.props.total_pages}
							/>
						<CreateEvidenceForm
							removeSnapshot={this.props.removeSnapshot}
							newSnapshot={this.props.newSnapshot} 
							updateSnapshotDetail={(data) => {
								console.log(data)
								this.setState({snapshot_detail: data})
							}}
							snapshot_list={this.props.snapshot_list}
							resetSaveAndNext={this.props.resetSaveAndNext}
							/>
					</div>
				</div>
			</main>
		)
	}
}

export default CreateSnapshotView;