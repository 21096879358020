function validateMyAccount(data) {
  let errors = {};

  // if (!data.address) {
  //   errors.address = 'Address is required';
  // }

  // if (!data.email) {
  //   errors.email = 'Email required';
  // } else if (!/\S+@\S+\.\S+/.test(data.email)) {
  //   errors.email = 'Email address is invalid';
  // }

  if (!data.full_name) {
    errors.full_name = 'Name is required';
  } 
  // else if (!/^[a-zA-Z][a-zA-Z0-9.\- ]+$/.test(data.full_name)) {
  //   errors.full_name = 'Invalid Name';
  // }

  // if (!data.phone_number) {
  //   errors.phone_number = 'Phone is required';
  // }else 
  if (!!data.phone_number && data.phone_number.length != 10) {
    errors.phone_number = 'Invalid phone number';
  } else if (!/^(0|[1-9]\d*)$/.test(data.phone_number)) {
    errors.phone_number = 'Invalid phone number';
  }

  // if (!data.gst_no) {
  //   errors.gst_no = 'GST is required';
  // }else
  if (data.gst_no)
    if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(data.gst_no)) {
      errors.gst_no = 'Invalid GST number';
    }

  // if (!data.country_id) {
  //   errors.country_id = 'Country is required';
  // }

  // if (!data.state_id) {
  //   errors.state_id = 'State is required';
  // }

  // if (!data.city_id) {
  //   errors.city_id = 'City is required';
  // }

  if (data.pincode && data.pincode.length != 6) {
    errors.pincode = 'Invalid Pincode';
  }
  return errors;
}

function validateCreateEntity(data) {
  let errors = {};

  // if (!address) {
  //   errors.address = 'Address required';
  // }

  if (!data.name) {
    errors.entity_name = 'Entity name required';
  } 
  // else if (!/^[a-zA-Z][a-zA-Z0-9.\- ]+$/.test(data.name)) {
  //   errors.entity_name = 'Invalid Entity Name';
  // }

  // if (!company_type_id) {
  //   errors.company_type_id = 'Company type is required';
  // }
  // if (!pan_number) {
  //   errors.pan_number = 'PAN number is required';
  // }

  if (!!data.pan_no && !(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(data.pan_no))) {
    errors.pan_no = 'PAN NO is invalid';
  }

  if (!!data.gst_no && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst_no))) {
    errors.gst_no = 'GST NO is invalid';
  }

  if (!!data.tan_no && !(/^[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}$/.test(data.tan_no))) {
    errors.tan_no = 'TAN NO is invalid';
  }

  if (!data.country_id) {
    errors.country_id = 'Country is required';
  }

  if (!data.state_id) {
    errors.state_id = 'State is required';
  }
  
  if (!!data.forward_snapshot_email ) {
    if (!data.receiver_entity_email) {
      errors.receiver_entity_email = 'email id is required';
    } else if (!validateEmail(data.receiver_entity_email)) {
      errors.receiver_entity_email = 'Invalid email id';
    }
  }

  // if (!city_id) {
  //   errors.city_id = 'City is required';
  // }

  if (data.pincode && data.pincode.length != 6) {
    errors.pincode = 'Invalid Pincode';
  }

  /*if(pan_number && !(/^([a-zA-Z])([0-9])([a-zA-Z])?$/.test(pan_number))){
    errors.pan_number = 'Pan number is invalid';
  }*/
  return errors;
}

function validateCACreateEntity(data, entity_type, entity_id) {
  let errors = {};

  if (!data.name) {
    errors.entity_name = 'Entity name is required';
  } 
  // else if (!/^[a-zA-Z][a-zA-Z0-9.\- ]+$/.test(data.name)) {
  //   errors.entity_name = 'Invalid Entity Name';
  // }

  if (entity_type != "edit" || !!!entity_id) {
    if (!data.email) {
      errors.invite_email = 'email id is required';
    } else if (!validateEmail(data.email)) {
      errors.invite_email = 'Invalid email id';
    }
  }

  if (!!data.pan_number && !(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(data.pan_number))) {
    errors.pan_number = 'PAN NO is invalid';
  }

  if (!!data.gst_number && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst_number))) {
    errors.gst_number = 'GST NO is invalid';
  }

  if (!!data.tan_number && !(/^[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}$/.test(data.tan_number))) {
    errors.tan_number = 'TAN NO is invalid';
  }

  if ((!!entity_id || entity_type == "invite") && !!data.forward_snapshot_email ) {
    if (!data.receiver_entity_email) {
      errors.receiver_entity_email = 'email id is required';
    } else if (!validateEmail(data.receiver_entity_email)) {
      errors.receiver_entity_email = 'Invalid email id';
    }
  }

  if (data.pincode && data.pincode.length != 6) {
    errors.pincode = 'Invalid Pincode';
  }
  return errors;
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export {
  validateMyAccount,
  validateCreateEntity,
  validateCACreateEntity
}