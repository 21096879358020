import React from 'react'
import { Prompt } from 'react-router-dom';
import { history } from '../Helpers/History';
import DirtyFieldModal from '../Components/BoilerPlate/DirtyFieldModal';

export class RouteLeavingGuard extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			modalVisible: false,
			lastLocation: null,
			confirmedNavigation: false,
		}
	}	

	showModal = (location) => this.setState({
		modalVisible: true,
		lastLocation: location,
	})

	closeModal = (callback) => {
		this.props.closeAction()
		this.setState({
			modalVisible: false
		}, callback)
	}

	closeModalOnCancel = (callback) => {
		this.props.closeAction()
		if(this.props.onCancelGetDataFromStore) {
			this.props.onCancelGetDataFromStore()
		}
		this.setState({
			modalVisible: false
		}, callback)
	}

	handleBlockedNavigation = (nextLocation) => { 
		const { confirmedNavigation } = this.state
		if (!confirmedNavigation) {
			this.showModal(nextLocation)
			return false
		}
		return true
	}

	handleConfirmNavigationClick = () => this.closeModal(() => {
		const { navigate } = this.props
		const { lastLocation } = this.state

		if(this.props.resetForm) { 
			this.props.resetForm()
		}
		if (lastLocation) {
			this.setState({
				confirmedNavigation: true
			}, () => {
				// Navigate to the previous blocked location with your navigate function 
				this.setState({
					modalVisible: false,
					lastLocation: null,
					confirmedNavigation: false,
				})  
				history.push(lastLocation.pathname)
			})
		}
		
	})

	saveChanges = () => this.closeModal(() => {
		const { lastLocation } = this.state
		if (lastLocation) {
			this.props.saveChanges(lastLocation.pathname)
		}
	})

	render() {
		const { when } = this.props
		const { modalVisible, lastLocation } = this.state
		return (
			<React.Fragment>
				<Prompt
					when={when}
					message={this.handleBlockedNavigation} />
				<DirtyFieldModal
					visible={modalVisible}
					onCancel={this.closeModalOnCancel}
					onConfirm={this.saveChanges}
					onDontSave={this.handleConfirmNavigationClick}
					onClose={this.props.closed} /> 
			</React.Fragment>
		)
	}
}
export default RouteLeavingGuard;