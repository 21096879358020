function roundOffValue(amount_round_off,value){
    if(amount_round_off == 1){
       return Math.round(value)
    }
    else if(amount_round_off == 2){
        return Math.ceil(value)
    }
    else if(amount_round_off == 3){
        console.log("inside round down")
        return Math.floor(value)
    }else{
        return value.toFixed(2)
    }
    
}

export {
    roundOffValue,
}