import React, { Component } from 'react'
import BarChartDoubleBar from '../../Components/Dashboard/BarCharts/BarChartDoubleBar';
import BarChartWithList from '../../Components/Dashboard/BarCharts/BarChartWithList';

export default class BusinessSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  salesReceivables = () => {
    const business_summary_data = this.props.business_summary_data;
    console.log(this.props.business_summary_data.sales_receivables)
    return (
      business_summary_data.sales_receivables.length > 0 && business_summary_data.sequence.includes(1) && 
      <div className="col-xl-6 col-lg-12 dash-card-mb">
        <BarChartDoubleBar 
          title="Total Sales and Receivables Outstanding" 
          data={this.props.business_summary_data.sales_receivables}
          type1_label="Sales"
          type2_label="Receivables"
          color1_background={this.props.color1_background}
          color2_background={this.props.color2_background}
          split_type={this.props.split_type}
        />
      </div>
    )
  }

  expensesPayables = () => {
    const business_summary_data = this.props.business_summary_data;
    return (
      business_summary_data.purchases_payables.length > 0 && business_summary_data.sequence.includes(2) &&
      <div className="col-xl-6 col-lg-12 dash-card-mb"> 
        <BarChartDoubleBar 
          title="Total Purchases and Payables Outstanding" 
          data={this.props.business_summary_data.purchases_payables}
          type1_label="Expenses"
          type2_label="Payables"
          color1_background={this.props.color1_background}
          color2_background={this.props.color2_background}
          split_type={this.props.split_type}
        />
      </div>
    )
  }

  receivables = () => {
    const business_summary_data = this.props.business_summary_data;
    return (
      business_summary_data.sale_receivables.length > 0 && business_summary_data.sequence.includes(3) &&
      <div className="col-xl-6 col-lg-12 dash-card-mb">
        <BarChartWithList 
          title="Top 10 Sales Receivables" 
          data={this.props.business_summary_data.sale_receivables}
          color2_background={this.props.color2_background}
          keytitle="merchant"
        />
      </div>
    )
  }

  payables = () => {
    const business_summary_data = this.props.business_summary_data;
    return (
      business_summary_data.purchase_payables.length > 0 && business_summary_data.sequence.includes(4) && 
      <div className="col-xl-6 col-lg-12 dash-card-mb">
        
        <BarChartWithList 
          title="Top 10 Purchase Payables" 
          data={this.props.business_summary_data.purchase_payables}
          color2_background={this.props.color2_background}
          keytitle="merchant"
        />
      </div>
    )
  }

  expenses = () => {
    const business_summary_data = this.props.business_summary_data;

    return (
      business_summary_data.expenses.length > 0 && business_summary_data.sequence.includes(5) && 
      <div className="col-xl-6 col-lg-12 dash-card-mb">
        <BarChartWithList 
          title="Top 10 Expenses" 
          data={this.props.business_summary_data.expenses}
          color2_background={this.props.color2_background}
          keytitle="merchant"
        />
      </div>
    )
  }

  categoryWiseSpends = () => {
    const business_summary_data = this.props.business_summary_data;

    return (
      business_summary_data.category_wise_spends.length > 0 && business_summary_data.sequence.includes(6) &&
      <div className="col-xl-6 col-lg-12 dash-card-mb"> 
        <BarChartWithList 
          title="Categories Wise Spend (Top 10)" 
          data={this.props.business_summary_data.category_wise_spends}
          color2_background={this.props.color2_background}
          keytitle="category"
        />
      </div>
    )
  }

  merchantWiseSpends = () => {
    const business_summary_data = this.props.business_summary_data;
    
    return (
      business_summary_data.merchant_wise_spends.length > 0 && business_summary_data.sequence.includes(7) &&
      <div className="col-xl-6 col-lg-12 dash-card-mb">
        <BarChartWithList 
          title="Merchant Wise Spend (Top 10)" 
          data={this.props.business_summary_data.merchant_wise_spends}
          color2_background={this.props.color2_background}
          keytitle="merchant"
        />
      </div>
    )
  }

  tagWiseSpends = () => {
    const business_summary_data = this.props.business_summary_data;

    return (
      business_summary_data.tag_wise_spends.length > 0 && business_summary_data.sequence.includes(8) &&
      <div className="col-xl-6 col-lg-12 dash-card-mb">
        <BarChartWithList 
          title="Tags Wise Spend (Top 10)" 
          data={this.props.business_summary_data.tag_wise_spends}
          color2_background={this.props.color2_background}
          keytitle="tag"
        />
      </div>
    )
  }

  render() {
    const business_summary_data = this.props.business_summary_data;
    const sequence = this.props.business_summary_data.sequence;
    const order = {1: this.salesReceivables(), 2: this.expensesPayables(), 3: this.receivables(), 4: this.payables(), 5: this.expenses(), 6: this.categoryWiseSpends(), 7: this.merchantWiseSpends(), 8: this.tagWiseSpends()}
    return (
      <div className="buktec-summary-new">
        {(business_summary_data.sales_receivables.length == 0 && business_summary_data.purchases_payables.length == 0 && business_summary_data.expenses.length == 0 && business_summary_data.category_wise_spends.length == 0 && business_summary_data.merchant_wise_spends.length == 0 && business_summary_data.tag_wise_spends.length == 0) && <h2 className="no-item-list">No items to display</h2>}

        <div className="row">
          {this.props.business_summary_data.sequence && sequence.map((seq, index) => {
            return order.hasOwnProperty(seq) ? order[seq] : null
          })}
        </div>																							
      </div> 
    )
  }
}