import React from 'react';
//import ReactDOM from 'react-dom';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from 'react-bootstrap/Table';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import { toast } from 'react-toastify';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';;
import Modal from 'react-bootstrap/Modal';
import SideList from './SideList';
import AccountDetailsHeader from './AccountDetailsHeader';
import Summary from './Summary';
import AccountStatementTransactions from './AccountStatementTransactions';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import { setFIRequestId } from '../../Redux/Actions/FinancialInstitute';
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
let count = 5;
class AccountStatementDetailView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      poll_data: {},
      is_dirty: false,
      show_delete_popup: false,
      deleteLoading: false,
      save_and_redirect: false, 
			next_route: false,
			closed: false,
      transactions: this.props.transactions,
      page_no: 1,
      per_page_items: 10,
      total_count: '',
      total_pages: '',
    }
  }

  componentDidMount() {
    //this.getPollData()
    // this.getAccountStatementDetails(this.props.match.params.account_statement_id)
    //this.getAccountStatementTransactions()
  }

  handlePageChange = (pageno) => {
    
    this.setState({
      page_no: pageno
    }, () => this.getAccountStatementTransactions())
  }

  getAccountStatementTransactions = () => {
    let transactions_data = {
      //"financial_institute_id":this.props.financial_institute_id,
      "id":this.props.financial_institute_id,
      "is_export":0,
      "per_page_items":10,
      "page_no":this.state.page_no,
    }
    FinancialInstituteService.getFinancialInstituteStatements(transactions_data).then(data => {
      if(data.response_code == 200){
        
        this.setState({
          transactions: data.result.transactions,
          page_no: data.result.page_no,
          per_page_items: data.result.per_page_items,
          total_count: data.result.total_count,
          total_pages: data.result.total_pages,
        })
      }
      else{
        toast.error(data.message)
      }
    })
  }

  getAccountStatementDetails = (id) => {
    if (id !== 'new') {
      this.props.dispatch(setAppilcationLoder(true));
      let details = {
        "financial_institute_statement_id": id
      }
      FinancialInstituteService.getAccountStatementDetails(details).then(data => {
        if (data.response_code === 200) {
          this.props.dispatch(setAppilcationLoder(false));
          this.setState({ poll_data: data.result, is_dirty: false })
        } else {
          this.props.dispatch(setAppilcationLoder(false));
          toast.error(data.message)
        }
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setFIRequestId(null))
  }

  // ----- Commented by Ram ----- //

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.match.params.account_statement_id !== this.props.match.params.account_statement_id) {
  //     this.getAccountStatementDetails(nextProps.match.params.account_statement_id)
  //   }
  //   if (!nextProps.fi_details.id) {
  //     this.props.getAccountStatementList(this.props.match.params.financial_institute_id)
  //     this.props.getFinancialInstituteDetails(this.props.match.params.financial_institute_id);
  //   }
  // }

  goToList = () => {
    this.props.history.push("/financial-institutes/view/" + this.props.match.params.financial_institute_id)
  }

  saveTransactions = () => {
    let details = {
      "statement_transaction_closing_amount": this.state.poll_data.closing_balance,
      "statement_transaction_end_date": this.state.poll_data.end_date || null,
      "financial_institute_id": this.state.poll_data.financial_institute_id,
      "transactions": this.state.poll_data.formatted_data,
      "statement_transaction_opening_amount": this.state.poll_data.opening_balance,
      "statement_transaction_start_date": this.state.poll_data.start_date || null,
      "statement_file_name": this.state.poll_data.statement_file_name,
      "statement_upload_date": this.state.poll_data.statement_upload_date || null,
      "data_source": 0,
      "status": this.state.poll_data.status,
      "statement_transaction_count": this.state.poll_data.transaction_count
    }
    FinancialInstituteService.storeTransactionData(details).then(data => {
      if (data.response_code === 200) {
        this.setState({ is_dirty: false })
        toast.success(data.message)
        this.props.refreshAcountStatementList()
        if(this.state.save_and_redirect){
          this.props.history.push(this.state.next_route)
        } else {
          this.props.history.push(`/financial-institutes/view/${this.props.match.params.financial_institute_id}/account-statements/${data.result.financial_institute_statement_id}`)
        }
        

      } else {
        toast.error(data.message)
      }
    })
  }

  toggleDeletePopup = () => {
    this.setState({ show_delete_popup: !this.state.show_delete_popup })
  }

  deleteTransactions = () => {
    if(this.state.poll_data.status == 1) {
      this.toggleDeletePopup()
      toast.warning('Linked account statement cannot be deleted')
    } else {
      let details = {
        "financial_institute_statement_id": this.props.match.params.account_statement_id
      }
      FinancialInstituteService.deleteAccountStatement(details).then(data => {
        if (data.response_code === 200) {
          toast.success(data.message)
          this.toggleDeletePopup()
          this.goToList()
        } else {
          toast.error(data.message)
        }
      })
    } 
  }

  saveBeforeLeave = (nextRoute) => {
		this.setState({save_and_redirect: true, next_route: nextRoute}, function() {
			this.saveTransactions()
		});
	}

	closeAction = () => {
		this.setState({closed: false})
	}

  render() {
    return (
      <div className="content-wrapper">
        <RouteLeavingGuard
          when={this.state.is_dirty}
          saveChanges={this.saveBeforeLeave}
          closed={this.state.closed}
          closeAction={this.closeAction}
        />
        <DeletePopUp
          showModal={this.state.show_delete_popup}
          deleteLoading={this.state.deleteLoading}
          toggleDeletePopUp={this.toggleDeletePopup}
          deleteItem={this.deleteTransactions} />

        <div className="container-fluid">
          {/* Edit view  */}
          <div className="row">
            {/* <SideList
              account_statement_list={this.props.account_statement_list}

              has_next={this.props.has_next}
              has_previous={this.props.has_previous}
              page_no={this.props.page_no}
              per_page_items={this.props.per_page_items}
              total_pages={this.props.total_pages}
              setPageNo={this.props.setPageNo}
              fi_id={this.props.match.params.financial_institute_id}
            /> */}
            <div className="col-lg-12 col-md-12 col-sm-12 main-content-sec">
              <div className="inner-content-sec dev-inner-content-sec">
                
                {/* <div className="row sub-header sub-header-bg">
                  <div className="col-md-12 sleft">
                    <h2 className="s-sbold blu-color spheading m-0">Account Statement 
                    <OverlayTrigger placement={'right'} overlay={<Tooltip id="tooltip-disabled" className="s-bold">Help</Tooltip>}><span className="icon-Information green-icon help"></span></OverlayTrigger>                    
                    </h2>
                  </div>
                </div>                 */}

                <div className="clearfix"></div>
                {/* <AccountDetailsHeader
                  fi_details={this.props.fi_details}
                  show_edit={false}

                /> */}
                <div className="main-file-item-sec mt-1">
                  {/* <div className="cross" onClick={() => this.goToList()} >
                    <span>X</span>
                  </div> */}
                  {/* commented the above code by ram */}
                  <div className="row">
                    {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div className="main-file-state-upload-sec">
                        <div className="inner-file-state-upload-sec">
                          <p className="ft-sz-18 s-reg font-colr mb-0">No file on record data retrieved using real-time access. </p>
                        </div>
                        <p className="ft-sz-15 s-reg font-colr mb-0 mt-3 text-center d-none">Click on file to view</p>
                      </div>
                    </div> */}
                    {/* <Summary
                      summay={this.state.poll_data}
                    /> */}

                  </div>
                  <AccountStatementTransactions
                    transaction_list={this.state.transactions}
                    page_no={this.state.page_no}
                    per_page_items={this.state.per_page_items}
                    total_count={this.state.total_count}
                    total_pages={this.state.total_pages}
                    handlePageChange={this.handlePageChange}
                  />
                </div>
                {/* <div className="bottom-save-btn clearfix">
                  {this.props.match.params.account_statement_id !== 'new' ?
                    <div className="pull-left">
                      <button type="button" className="btn save-btn graybtn mt-1"
                        onClick={() => this.toggleDeletePopup()} >Delete </button>
                    </div> :
                    <div className="pull-right">
                      <button type="button" className="btn save-btn white-color greenbtn"
                        onClick={() => this.saveTransactions()} >Save</button>
                    </div>
                  }
                </div> */}
              </div>
            </div>
          </div>

        </div>
        <a className="scroll-to-top rounded " href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.FinancialInstitute || {};
  console.log(state.FinancialInstitute)
  const fi_request_id = all_value.fi_request_id;

  return { fi_request_id }
}


export default connect(mapStateToProps)(withRouter(AccountStatementDetailView));