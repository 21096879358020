import React, { useState, useEffect, useRef } from 'react';
import { displayDateFormate, displayTimeFormat } from '../../Helpers/DateFormat';
import StorageData from '../../Helpers/StorageData';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import SessionManagement from '../../Utils/SessionManagement';

const MessageList = (props) => {
  let myRef = React.createRef();
  let messagesEndRef = useRef(null);
  const [listItems, setListItems] = useState(Array.from(Array(30).keys(), n => n + 1));
  const [isFetching, setIsFetching] = useState(false);


  useEffect(() => {
    scrollToBottom();
  }, [props.messages_history.length]);

  const scrollToBottom = () => {
    if (!isFetching) {
      setIsFetching(false);
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
      }, 250);
    }
  }

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // function fetchMoreListItems() {
  //   setTimeout(() => {
  //     setListItems(prevState => ([...prevState, ...Array.from(Array(20).keys(), n => n + prevState.length + 1)]));
  //     setIsFetching(false);
  //   }, 2000);
  // }

  function handleScroll() {
    const scrollTop = myRef.current.scrollTop;
    if (scrollTop === 0) {
      setIsFetching(true);
      props.loadMoreMessage()
    }
  }

  function getUserName(user_id) {
    let detail = props.all_users.find((user) => user.user_id == user_id);
    if (!!detail) {
      return detail.name;
    } else {
      return "Anonymous";
    }
  }

  function getProfilePic(user_id) {
    let detail = props.all_users.find((user) => user.user_id == user_id);
    if (!!detail && !!detail.profile_picture) {
      return StorageData.getQualifiedFileUrl(detail.profile_picture);
    } else {
      return null;
    }
  }

  function getDateTime(created_at) {
    if (!!created_at) {
      return displayDateFormate(created_at.toDate()) + " " + displayTimeFormat(created_at.toDate());
    } else {
      return "Now";
    }
  }

  function showMessage(message) {

    let regex = /\@\[[A-Za-z0-9 ]*\]\([0-9]*\)/g;
    let words = message.match(regex);
    if (!!words)
      for (let i = 0; i < words.length; i++) {
        let mySubString = words[i].substring(
          words[i].indexOf("@[") + 2,
          words[i].lastIndexOf("](")
        );
        message = message.replace(words[i], "<span class='red-text'>" + mySubString + "</span>");
      }
    return message;
  }

  return (
    <>
      <div className="msg-sec-cmb" ref={myRef} onScroll={handleScroll}>

        {props.isFetching && <Spinner animation="border" variant="secondary" className="cmb-loader" />}

        {
          props.messages_history.map((data, index) => {
            return <>
              {/* <div key={index}
                  // className="messages-cmb msg-send-cmb"
                  className={"messages-cmb msg-send-cmb " + (SessionManagement.getUserDetail().account_type == 2 ? " business-notes " : "") + (SessionManagement.getUserDetail().id == data.user_id ? " float-right " : "")}
                > */}

              <div key={index}
                // className="messages-cmb msg-send-cmb"
                className={"messages-cmb msg-boxed " + (SessionManagement.getUserDetail().account_type == 2 ? " business-notes " : " ") + (SessionManagement.getUserDetail().id == data.user_id ? " msg-send-cmb float-right " : " float-left ")}
              >
                {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{getUserName(data.user_id)}</Tooltip>}> */}
                <OverlayTrigger overlay={<Tooltip>{getUserName(data.user_id)}</Tooltip>}>
                  <div className="pnd-cmb">
                    <div>
                      {!!getProfilePic(data.user_id) ?
                        <img className="profile-cmb-img" src={getProfilePic(data.user_id)} />
                        :
                        <span className="profile-cmb-letter">{getUserName(data.user_id).charAt(0)}</span>
                      }
                    </div>
                    <div className="nd-cmb">
                      <h5 className="profile-cmb-name ellipsisc ellipsisc-line-1">{getUserName(data.user_id)}</h5>
                      <span className="date-cmd">{getDateTime(data.created_at)}</span>
                    </div>
                  </div>
                </OverlayTrigger>
                <p className="msgtext-cmd"><span dangerouslySetInnerHTML={{ __html: showMessage(data.message) }} /></p>
              </div>
              <div className="clearfix"></div>
            </>
          })
        }
        <div ref={messagesEndRef} />
      </div>
    </>
  );
};

export default MessageList;