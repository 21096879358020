import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from "../../Redux/Actions/Auth";
import SessionManagement from '../../Utils/SessionManagement';
import StorageData from '../../Helpers/StorageData';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import MasterService from '../../Services/MasterService'
import { setNotificationCount } from '../../Redux/Actions/Notification';
import { Alert, Dropdown } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import firebase, { onMessageListener } from '../../Utils/firebase'
import { setSnapshotFilterData } from '../../Redux/Actions/Snapshot';

// In the notifications section there were some spana tags instead of span tags I changed them

function ShowNotificationModuleList(props) {
  if (!!!props.notification_list) {
    return true;
  }
  return <ul className="noti-drp-list p-0">
    <li className="d-flex align-items-end justify-content-between mb-3">
      <h6 className="size-xxii blue-text s-sbold mb-0">{props.module_name}</h6>
      <p className="size-xvi s-sbold mb-0 cpointer green-blue-hlink" onClick={() => props.seeAllNotification(props.module, props.module_name)}>See all</p>
    </li>
    <ShowNotificationList
      notification_list={props.notification_list.slice(0, 2)}
      markAsRead={props.markAsRead}
      redirectToSpecificModule={props.redirectToSpecificModule}
      is_loading={props.is_loading} />
  </ul>
}
function ShowNotificationList(props) {
  return <>
    {
      props.notification_list.map((module, index) => {
        return <>
          <li key={index} className={"noti-drp-li d-flex align-items-start mb-2 " + (module.is_read != 0 ? " active" : "")}>
            <div className="noti-user">
              {
                !!module.qualified_url ?
                  <>
                    <img className="noti-user-img" src={StorageData.getQualifiedFileUrl(module.qualified_url)} />
                    <span className="icon-Group-3182 noti-file-icon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                  </>
                  :
                  <>
                    <div className="noti-user-letter s-sbold d-flex align-items-center justify-content-center cap">{module.full_name.charAt(0)}</div>
                    <span className="icon-Group-3182 noti-file-icon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                  </>
              }
            </div>

            <div className="ml-4 w-100">
              <p className="noti-p" onClick={() => props.redirectToSpecificModule(module)}><span dangerouslySetInnerHTML={{ __html: module.message }} /></p>
              <div className="noti-date">
                <p className="green-text mb-0">{module.created_at}</p>
                {
                  module.is_read == 0 ?
                    <p className="s-sbold mb-0 cpointer blue-green-hlink" onClick={() => props.markAsRead(module)}>Mark as read</p>
                    : ""
                }
              </div>
              {/* <div className="noti-date">
                <p className="green-text mb-0 d-inline">{module.created_at}</p>
                {
                  module.is_read == 0 ?
                    <p className="s-sbold mb-0 d-inline float-right cpointer blue-green-hlink" onClick={() => props.markAsRead(module)}>Mark as read</p>
                    : ""
                }
              </div> */}
            </div>
          </li>
        </>
      })
    }

    {
      props.is_loading && <Spinner animation="border" variant="secondary" className="h-loader" />
    }
  </>
}

const Notification = (props) => {

  const dispatch = useDispatch()
  const [all_module_notification_list, setAllModuleNotificationList] = useState([]);
  const [all_notification_list, setAllNotificationList] = useState([]);
  const [per_page_items, setPerPageItem] = useState(0);
  const [page_no, setPageNo] = useState(1);
  const [module_per_page_items, setModulePerPageItem] = useState(0);
  const [module_page_no, setModulePageNo] = useState(1);
  const [has_next, setHasNext] = useState(true);
  const [notification_homepage, setHomePageNotification] = useState(true);
  const [module_name, setModuleName] = useState("");
  const [is_loading, setIsLoading] = useState(false);


  function getNotificationList() {
    let data = {
      module_per_page_items,
      module_page_no
    };
    setIsLoading(true);
    MasterService.getNotifications(data).then(
      (data) => {
        setIsLoading(false);
        if (data.response_code == 200) {
          let all_module_notification_list = [];
          for (let i = 0; i < data.result.preview_notifications.length; i++) {
            if (data.result.preview_notifications[i].type == 1) {
              if (!!all_module_notification_list['profile_management']) {
                all_module_notification_list['profile_management'].push(data.result.preview_notifications[i]);
              } else {
                all_module_notification_list['profile_management'] = [data.result.preview_notifications[i]];
              }
            } else {
              switch (data.result.preview_notifications[i].mod_type) {
                case 1:
                  if (!!all_module_notification_list['snapshot']) {
                    all_module_notification_list['snapshot'].push(data.result.preview_notifications[i]);
                  } else {
                    all_module_notification_list['snapshot'] = [data.result.preview_notifications[i]];
                  }
                  break;
                case 2:
                  if (!!all_module_notification_list['invoice']) {
                    all_module_notification_list['invoice'].push(data.result.preview_notifications[i]);
                  } else {
                    all_module_notification_list['invoice'] = [data.result.preview_notifications[i]];
                  }
                  break;
                case 3:
                  if (!!all_module_notification_list['cheque']) {
                    all_module_notification_list['cheque'].push(data.result.preview_notifications[i]);
                  } else {
                    all_module_notification_list['cheque'] = [data.result.preview_notifications[i]];
                  }
                  break;
                case 4:
                  if (!!all_module_notification_list['money_link']) {
                    all_module_notification_list['money_link'].push(data.result.preview_notifications[i]);
                  } else {
                    all_module_notification_list['money_link'] = [data.result.preview_notifications[i]];
                  }
                  break;
                case 6:
                  if (!!all_module_notification_list['task_manager']) {
                    all_module_notification_list['task_manager'].push(data.result.preview_notifications[i]);
                  } else {
                    all_module_notification_list['task_manager'] = [data.result.preview_notifications[i]];
                  }
                  break;
                case 7:
                  if (!!all_module_notification_list['financial_institute']) {
                    all_module_notification_list['financial_institute'].push(data.result.preview_notifications[i]);
                  } else {
                    all_module_notification_list['financial_institute'] = [data.result.preview_notifications[i]];
                  }
                  break;
              }
            }
          }

          setAllModuleNotificationList(all_module_notification_list);
        }
      });
  }

  async function loadMoreNotification(notification_data) {
    seeAllNotification(notification_data);
  }

  function seeAllNotification(notification_data, module_name = "") {
    if (!!module_name) {
      setHomePageNotification(!notification_homepage);
      setModuleName(module_name);
    }
    let data = {
      "mod_type": notification_data.type == 1 ? null : notification_data.mod_type,
      "type": notification_data.type,
      "page_no": page_no,
      "per_page_items": per_page_items
    }

    setIsLoading(true);
    MasterService.getAllNotifications(data).then(
      (data) => {
        setIsLoading(false);
        if (data.response_code == 200) {
          // setPerPageItem(data.result.per_page_items);
          setPageNo(data.result.page_no + 1);
          setHasNext(data.result.has_next);
          setAllNotificationList([...all_notification_list, ...data.result.notifications]);
        }
      });
  }

  function markAsRead(module) {
    let data = {
      "type": module.type,
      "mod_type": module.type == 1 ? null : module.mod_type,
      "id": module.id
    }
    setIsLoading(true);
    MasterService.notificationMarkAsRead(data).then(
      (data) => {
        setIsLoading(false);
        if (data.response_code == 200) {
          dispatch(setNotificationCount(data.result.count));


          if (!!module.type) {
            let notification_list = all_notification_list.map((noti) => {
              if (data.result.marked.includes(noti.id)) {
                noti.is_read = 1;
              }
              return noti;
            });
            setAllNotificationList([]);
            setAllNotificationList(notification_list);
          } else {
            for (const [key, value] of Object.entries(all_module_notification_list)) {
              let new_value = value.map((noti) => {
                if (data.result.marked.includes(noti.id)) {
                  noti.is_read = 1;
                }
                return noti;
              });
              all_module_notification_list[key] = new_value;
            }
          }
        }
      });
  }

  function redirectToSpecificModule(module) {
    if (module.is_read == 0)
      markAsRead(module);
    // if (module.type == 1) {

    // } else 
    if (!!module.mod_id) {
      switch (module.mod_type) {
        case 1: "Snapshot";
          props.history.push("/snapshot/evidence/create/" + module.mod_id);
          break;
        case 2: "Invoice";
          // props.history.push("/");
          break;
        case 3: "Cheque";
          // props.history.push("/");
          break;
        case 4: "MoneyLink";
          props.history.push("/money-link/" + module.mod_id);
          break;
        case 5: "Entities";
          props.history.push("/profile-management/entities/create/" + module.mod_id);
          break;
        case 6: "TaskManager";
          props.history.push("/task-manager/create/" + module.mod_id);
          break;
        case 7: "Financial Institute";
          props.history.push("/financial-institutes")
          break;
        case 8: "Users";
          // props.history.push("/");
          break;
        case 9: "EntityInvites";
          props.history.push("/");
          break;
      }
    } else {
      switch (module.mod_type) {
        case 1: "Snapshot";
          // 18-08-2022 @nehal #as per pratyush if mod_id is null in snapshot redirect to listing with email filter
          props.dispatch(setSnapshotFilterData({ source: 2 }));
          props.dispatch(setSnapshotFilterData({ filter_by: 2 }));
          props.history.push("/snapshot/evidence/");
          break;
      }
    }
  }

  useEffect(() => {
    getNotificationList();
  }, [props.notification_count]);


  /*function checkNotification() {
    console.log("Notification.permission", Notification.permission);
    const msg = firebase.messaging();
    msg.requestPermission().then((data) => {
      if (!!data && !!SessionManagement.getAccessToken()) {
        // CHECK PERMISSION GRANTTED
        if (Notification.permission === 'granted') {
          StorageData.setNotificationPermission("yes");
        }
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
  }*/

  return (
    <>
      {
        notification_homepage ?
          <>
            <ul className="noti-drp-list p-0 mr-1">
              <li className="d-flex align-items-center justify-content-between mb-2">
                {/* <div className="d-flex align-items-center justify-content-between mb-3"> */}
                <h4 className="size-xxiv blue-text s-sbold">Notifications</h4>
                <p className="size-xvi s-sbold mb-0 cpointer blue-green-hlink" disabled={!props.notification_count} onClick={() => { !!props.notification_count || true ? markAsRead({ "type": null, "mod_type": null, "id": null }) : "" }}>Mark all as read</p>
                {/* </div> */}
              </li>
            </ul>

            {/* Disabled notifications message */}
            {
              (StorageData.getNotificationPermision() == "yes") ? "" : <Alert variant="danger noti-alert-error"><small className="text-danger">It appears you have disabled notifications. To get all notification from <b>B</b>uktec platform, kindly enable notification by visiting you browser settings.</small></Alert>
            }
            {/* end Disabled notifications message */}

            {/* Here notification squence may change so we are not using loop */ }

            <div className="noti-max-list">
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['snapshot']}
                module={{ type: 2, mod_type: 1 }}
                module_name={"Snapshot"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['invoice']}
                module={{ type: 2, mod_type: 2 }}
                module_name={"Invoice"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['cheque']}
                module={{ type: 2, mod_type: 3 }}
                module_name={"Cheque"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['money_link']}
                module={{ type: 2, mod_type: 4 }}
                module_name={"Money Link"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['task_manager']}
                module={{ type: 2, mod_type: 6 }}
                module_name={"Task Manager"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['financial_institute']}
                module={{ type: 2, mod_type: 7 }}
                module_name={"Banking"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
              <ShowNotificationModuleList
                notification_list={all_module_notification_list['profile_management']}
                module={{ type: 1, mod_type: null }}
                module_name={"Profile Management"}
                seeAllNotification={seeAllNotification}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
            </div>
          </>
          :
          <>
            {/* See all notification */}
            <ul className="noti-drp-list p-0 mr-1">
              <li className="d-flex align-items-center justify-content-between mb-2">
                <h5 className="size-xx blue-text s-sbold">
                  <span className="icon-Slider-arrow2 size-xvi cpointer mr-3" onClick={() => { setAllNotificationList([]); setPageNo(1); setHomePageNotification(!notification_homepage) }}></span>
                  {module_name}
                </h5>
                <p className="s-sbold mb-0 d-inline float-right cpointer blue-green-hlink" onClick={() => markAsRead({ "type": all_notification_list[0].type, "mod_type": all_notification_list[0].mod_type, "id": null })}>Mark all as read</p>
              </li>
            </ul>
            <ul className="noti-drp-list noti-max-list p-0">
              <ShowNotificationList
                notification_list={all_notification_list}
                markAsRead={markAsRead}
                redirectToSpecificModule={redirectToSpecificModule}
                is_loading={is_loading} />
            </ul>

            {has_next &&
              <p className="s-sbold blue-text mt-2 mb-0 text-center cpointer" onClick={() => loadMoreNotification(all_notification_list[0])}>Load more</p>
            }
          </>
      }
      {/* End See all notification */}
    </>
  )
}

function mapStateToProps(state) {
  const all_value = state.Session || {};
  const notification__value = state.Notification || {};
  const entity_list = all_value.entity_list || [];
  const account_permissions = all_value.account_permissions || [];
  const module_permissions = all_value.module_permissions || [];
  const user_detail = all_value.user_detail || {};
  const notification_count = notification__value.count || 0;

  const snapshot_values = state.Snapshot;

  return {
    entity_list, account_permissions, module_permissions, user_detail, notification_count
  };
}
export default connect(mapStateToProps)(withRouter(Notification));