import React from 'react'
import { Modal } from 'react-bootstrap';

import welcome from '../../Assets/img/welcome.png';

class HelpModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show_help}
                onHide={() => this.props.toggleHelp()}
                backdrop="static"
                keyboard={false}
                centered
                className="changelog-model-sec helpopup"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color">Help Topic</Modal.Title>
                </Modal.Header>
                <Modal.Body className="clearfix">

                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>

                    <hr></hr>
                    
                    <h3 class="s-sbold blu-color mt-4 mb-2">Link</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
                    <img src={welcome} className="img-fluid mb-2" />
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>

                </Modal.Body>
            </Modal>

        )
    }
}

export default HelpModal