import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from 'react-bootstrap/Modal';

import { connect } from "react-redux";
import StorageData from '../../Helpers/StorageData';
import SessionManagement from '../../Utils/SessionManagement';
import { setAppilcationLoder } from '../../Redux/Actions/App';

function Sidebar(){
    const [lgShow, setLgShow] = React.useState(false);

    return <React.Fragment>
                <div className="clearfix">
                  <div className="switch-noti pull-left">
                    <div className="btn-group btn-toggle left-on-off-btn"> 
                      <button className="btn btn-lg btn-default">Users</button>
                      <button className="btn btn-lg btn-primary active">Context</button>
                    </div>
                    <div className="msg-count">
                        <span className="ft-sz-13 s-sbold white-color red-bg">20</span>
                      </div>
                  </div>
                  <div className="pull-right msg-noti-brodcast-sec">
                    
                    <div className="broadacast-sec" onClick={() => setLgShow(true)}>
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Broadcast</Tooltip>}>
                      <span className="icon-Group-45061"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <form className="search-sec clearfix">
                  <input type="text" placeholder="Search" />
                  <button type="submit"><span className="icon-loupe"></span></button>
                </form>
  
                <div className="recipt-sidebar-sec">
                  <div className="recipt-inner-div active">
                    <div className=""></div>
                    <div className="row no-gutters">
                      <div className="col-xl-3 col-sm-12 left-side-content">
                        <div className="ft-sz-33 s-sbold inner-left-side-content">M</div>
                      </div>
                      <div className="col-xl-9 col-sm-12 right-side-content">
                        <div className="ft-sz-24 s-sbold font-colr name-sec">
                        Mihir Joshi
                        </div>
                        <p className="ft-sz-13 s-sbold font-colr">Username :MihirJ</p>
                        <div className="clearfix">
                          <p className="ft-sz-13 s-sbold font-colr pull-left">Role : Secretary</p>
                          <p className="ft-sz-15 s-sbold font-colr pull-right">2:10pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="recipt-inner-div">
                    <div className="row no-gutters">
                      <div className="col-xl-3 col-sm-12 left-side-content">
                        <div className="ft-sz-33 s-sbold inner-left-side-content">R</div>
                      </div>
                      <div className="col-xl-9 col-sm-12 right-side-content">
                        <div className="ft-sz-24 s-sbold font-colr name-sec">
                        Renuka Bhande 
                        <div className="msg-count">
                          <span className="ft-sz-13 s-sbold white-color red-bg">8</span>
                        </div>
                        </div>
                        <p className="ft-sz-13 s-sbold font-colr">Username :MihirJ</p>
                        <div className="clearfix">
                          <p className="ft-sz-13 s-sbold font-colr pull-left">Role : Secretary</p>
                          <p className="ft-sz-15 s-sbold font-colr pull-right">2:10pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="recipt-inner-div">
                    <div className="row no-gutters">
                      <div className="col-xl-3 col-sm-12 left-side-content">
                        <div className="ft-sz-33 s-sbold inner-left-side-content">P</div>
                      </div>
                      <div className="col-xl-9 col-sm-12 right-side-content">
                        <div className="ft-sz-24 s-sbold font-colr name-sec">
                        Parag Landge 
                        <div className="msg-count">
                          <span className="ft-sz-13 s-sbold white-color red-bg">4</span>
                        </div>
                        </div>
                        <p className="ft-sz-13 s-sbold font-colr">Username :MihirJ</p>
                        <div className="clearfix">
                          <p className="ft-sz-13 s-sbold font-colr pull-left">Role : Secretary</p>
                          <p className="ft-sz-15 s-sbold font-colr pull-right">2:10pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div className="recipt-inner-div active">
                    <div className=""></div>
                    <div className="row">
                      <div className="col-xl-7 col-sm-12">
                        <div className="recipt-id s-sbold font-colr">
                          ID #2154
                        </div>
                        <p className="m-0 head s-sbold font-colr">Narayana Plaza</p>
                      </div>
                      <div className="col-xl-5 col-sm-12">
                        <div className="evi-icon">
                          <p className="ft-sz-13 s-sbold evi-chq-text">Cheques</p>
                          <div className="msg-count">
                            <span className="ft-sz-13 s-sbold white-color red-bg">4</span>
                          </div>
                        </div>
                        <div className="amount s-sbold blu-color">
                          <span>&#8377;2000 <span class="icon-Green-arrow"></span></span>
                        </div>
                        <div className="date s-sbold font-colr">
                          3:30pm
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div className="recipt-inner-div">
                    <div className=""></div>
                    <div className="row">
                      <div className="col-xl-7 col-sm-12">
                        <div className="recipt-id s-sbold font-colr">
                          ID #2154
                        </div>
                        <p className="m-0 head s-sbold font-colr">ICICIXXX5443</p>
                      </div>
                      <div className="col-xl-5 col-sm-12">
                        <div className="evi-icon">
                          <p className="ft-sz-13 s-sbold evi-chq-text">Transaction</p>
                          <div className="msg-count">
                            <span className="ft-sz-13 s-sbold white-color red-bg">4</span>
                          </div>
                        </div>
                        <div className="amount s-sbold blu-color">
                          <span>&#8377;2000 <span class="icon-Red-arrow"></span></span>
                        </div>
                        <div className="date s-sbold font-colr">
                          3:30pm
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
                   {/* <div className="main-pagination clearfix sidebar-pagination">
                      <nav aria-label="Page navigation example">
                        <div className="per-page-sec clearfix">
                          <ul className="pagination pull-right mb-0">
                            <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                            <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                          </ul>
                        </div>
                        <ul className="pagination pull-right mb-0">  
                          <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                          <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                          <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                          <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                          <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                        </ul>
                      </nav>
                    </div> */}
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            className="broadcast-model-sec"
            backdrop="static"
            >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg" className="ft-sz-26 s-sbold blu-color">
               Broadcast <span className="d-none">Broadcast Message</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <p className="lable-input font-colr ft-sz-18">Add Users</p>
                    <div className="form-control tag-form-control ft-sz-18 pl-3" value="" placeholder="@mention a user">
                      <span className="inner-tag">Shubham G <i class="fa fa-times" aria-hidden="true"></i></span>
                      <span className="inner-tag orang-bg">Gaurav Rane <i class="fa fa-times" aria-hidden="true"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-none">
                <div className="col-lg-7">
                  <div className="form-group">
                    <p className="lable-input font-colr ft-sz-18">Add Users</p>
                    <div className="form-control tag-form-control ft-sz-18 pl-3" value="" placeholder="@mention a user">
                      <span className="inner-tag">Shubham G <i class="fa fa-times" aria-hidden="true"></i></span>
                      <span className="inner-tag orang-bg">Gaurav Rane <i class="fa fa-times" aria-hidden="true"></i></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 or-sec"><span>OR</span></div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <p className="lable-input font-colr ft-sz-18">Hashtag</p>
                    <select className="form-control">
                      <option value="1" disabled selected>Select Hashtags</option>
                      <option value="2">GST Customer</option>
                    </select>             
                  </div>
                </div>
              </div>
              <div className="form-group">
                <p className="lable-input font-colr ft-sz-18">Message</p>
                <textarea className="form-control ft-sz-18" rows="5"></textarea>
              </div>
            </Modal.Body>
            <div className="main-broadcast-btn clearfix">
              <div className="check-box pull-left">
                <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                <label for="styled-checkbox-011" className="label-text font-colr s-reg ft-sz-18 mb-0">Also send as Email/SMS</label>
              </div>
              <button type="button" className="broadcast-btn btn ft-sz-18 white-color s-bold btn pull-right">Broadcast</button>
            </div>
             
          </Modal>
          
    </React.Fragment>

}
  
function mapStateToProps(state) {
    const all_value = state.Session || {};
    const module_permissions = all_value.module_permissions || [];
    const account_permissions = all_value.account_permissions || [];
    const acl_entities = all_value.entity_list || [];
    return {
      module_permissions, account_permissions, acl_entities
    };
  }

export default connect(mapStateToProps)(Sidebar);