import { APPLY_FILTER_DATA,TASK_COUNT_DATA,SET_TOTAL_COUNT,SET_PAGE_NO,SET_PER_PAGE_ITEM,SET_IS_TASK_LOADING,SET_TASK_STAR, SET_TM_FINANCIAL_YEAR_NAME, SET_TM_FILTER_DATE_RANGE, SET_TM_CARD_FILTER, SET_TM_TEMP_DATA } from "./Types";

export const setFilterData = (filter_data) => ({
  type: APPLY_FILTER_DATA,
  payload: filter_data,
});
  
export const setTaskCount = (task_count) => ({
  type: TASK_COUNT_DATA,
  payload: task_count,
});
  
export const setTotalCount = (total_count) => ({
  type: SET_TOTAL_COUNT,
  payload: total_count,
});

export const setPageNo = (page_no) => ({
  type: SET_PAGE_NO,
  payload: page_no,
});
  
export const setPagePerItem = (per_page_item) => ({
  type: SET_PER_PAGE_ITEM,
  payload: per_page_item,
});
  
export const setTaskLoadingStatus = (is_task_loading) => ({
  type: SET_IS_TASK_LOADING,
  payload: is_task_loading,
});
  
export const setStarTask = (task_id,changed_star) => ({
  type: SET_TASK_STAR,
  payload : {task_id:task_id,changed_star:changed_star}
});

export const setFinancialYearName = (financial_year_name) => ({
  type: SET_TM_FINANCIAL_YEAR_NAME,
  payload: financial_year_name,
});

export const setFilterDateRange = (start_date, end_date) => ({
  type: SET_TM_FILTER_DATE_RANGE,
  payload: {
    start_date: start_date,
    end_date: end_date
  },
});

export const setFilterCard = (filter_card) => ({
  type: SET_TM_CARD_FILTER,
  payload: filter_card,
});

export const setTaskTempData = (task_temp_data) => ({
  type: SET_TM_TEMP_DATA,
  payload: task_temp_data,
});
