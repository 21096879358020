import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
function messenger(){

  const [lgShow, setLgShow] = React.useState(false);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span className="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span className="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><i class="fa fa-bell-o" aria-hidden="true"></i></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span className="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4408"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span className="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-lightbulb"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4402"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5305"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span className="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-to-do-list"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-chat1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4403"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4404"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4405"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec sidebar-messenger-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Messenger</li>
              </ol>
              <hr/>
              <div className="clearfix">
                <div className="switch-noti pull-left">
                  <div className="btn-group btn-toggle left-on-off-btn"> 
                    <button className="btn btn-lg btn-default active">Users</button>
                    <button className="btn btn-lg btn-primary ">Context</button>
                  </div>
                  <div className="msg-count">
                      <span className="ft-sz-13 s-sbold white-color red-bg">20</span>
                    </div>
                </div>
                <div className="pull-right msg-noti-brodcast-sec">
                  
                  <div className="broadacast-sec" onClick={() => setLgShow(true)}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Broadcast</Tooltip>}>
                    <span className="icon-Group-45061"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <form className="search-sec clearfix">
                <input type="text" placeholder="Search" />
                <button type="submit"><span className="icon-loupe"></span></button>
              </form>

              <div className="recipt-sidebar-sec">
                <div className="recipt-inner-div active">
                  <div className=""></div>
                  <div className="row no-gutters">
                    <div className="col-xl-3 col-sm-12 left-side-content">
                      <div className="ft-sz-33 s-sbold inner-left-side-content">M</div>
                    </div>
                    <div className="col-xl-9 col-sm-12 right-side-content">
                      <div className="ft-sz-24 s-sbold font-colr name-sec">
                      Mihir Joshi
                      </div>
                      <p className="ft-sz-13 s-sbold font-colr">Username :MihirJ</p>
                      <div className="clearfix">
                        <p className="ft-sz-13 s-sbold font-colr pull-left">Role : Secretary</p>
                        <p className="ft-sz-15 s-sbold font-colr pull-right">2:10pm</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row no-gutters">
                    <div className="col-xl-3 col-sm-12 left-side-content">
                      <div className="ft-sz-33 s-sbold inner-left-side-content">R</div>
                    </div>
                    <div className="col-xl-9 col-sm-12 right-side-content">
                      <div className="ft-sz-24 s-sbold font-colr name-sec">
                      Renuka Bhande 
                      <div className="msg-count">
                        <span className="ft-sz-13 s-sbold white-color red-bg">8</span>
                      </div>
                      </div>
                      <p className="ft-sz-13 s-sbold font-colr">Username :MihirJ</p>
                      <div className="clearfix">
                        <p className="ft-sz-13 s-sbold font-colr pull-left">Role : Secretary</p>
                        <p className="ft-sz-15 s-sbold font-colr pull-right">2:10pm</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row no-gutters">
                    <div className="col-xl-3 col-sm-12 left-side-content">
                      <div className="ft-sz-33 s-sbold inner-left-side-content">P</div>
                    </div>
                    <div className="col-xl-9 col-sm-12 right-side-content">
                      <div className="ft-sz-24 s-sbold font-colr name-sec">
                      Parag Landge 
                      <div className="msg-count">
                        <span className="ft-sz-13 s-sbold white-color red-bg">4</span>
                      </div>
                      </div>
                      <p className="ft-sz-13 s-sbold font-colr">Username :MihirJ</p>
                      <div className="clearfix">
                        <p className="ft-sz-13 s-sbold font-colr pull-left">Role : Secretary</p>
                        <p className="ft-sz-15 s-sbold font-colr pull-right">2:10pm</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="recipt-inner-div active">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Narayana Plaza</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Cheques</p>
                        <div className="msg-count">
                          <span className="ft-sz-13 s-sbold white-color red-bg">4</span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 <span class="icon-Green-arrow"></span></span>
                      </div>
                      <div className="date s-sbold font-colr">
                        3:30pm
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">ICICIXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Transaction</p>
                        <div className="msg-count">
                          <span className="ft-sz-13 s-sbold white-color red-bg">4</span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 <span class="icon-Red-arrow"></span></span>
                      </div>
                      <div className="date s-sbold font-colr">
                        3:30pm
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                
                
              </div>
                 {/* <div className="main-pagination clearfix sidebar-pagination">
                    <nav aria-label="Page navigation example">
                      <div className="per-page-sec clearfix">
                        <ul className="pagination pull-right mb-0">
                          <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                          <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        </ul>
                      </div>
                      <ul className="pagination pull-right mb-0">  
                        <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                        <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div> */}
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec messenger-main-content-sec">
              <div className="inner-content-sec">
                <div className="clearfix main-title-btn-sec">
                  <div className="head-title pull-left">
                    <h2 className="s-sbold blu-color m-0">Messenger</h2>
                  </div>
                </div>
                <div className="main-file-item-sec">
                <button className="lic-bill-btn btn ft-sz-20 s-bold white-color">Go to Context</button>
                 {/* <h2 className="s-sbold blu-color m-0"><span className="icon-chat mr-3"></span> General Chats</h2> */}
                  <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
                    <Tab eventKey="general" title="General Chats">
                      <div className="chat-section">
                        <ul className="chat-text-sec">
                          <li className="s-reg other-person">
                            <div className="message">
                             <p className="ft-sz-22 s-sbold mb-2 lblu-color">Mihir Joshi</p> 
                             <span>Have you made TDS Payment? Share the details with me</span> 
                            <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                            </div>
                          </li>
                          <li className="s-reg user-type">
                            <div className="message">
                            Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                            <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                            </div>
                          </li>
                          <li className="separator ft-sz-18 s-sbold"><span>1 Unread message</span></li>
                          <li className="s-reg other-person">
                            <div className="message">
                            <p className="ft-sz-22 s-sbold mb-2 lblu-color">Renuka Bhande</p> 
                             <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                            </div>
                          </li>
                          <li className="s-reg user-type">
                            <div className="message">
                            Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                            </div>
                          </li>
                          <li className="s-reg other-person">
                            <div className="message">
                            <p className="ft-sz-22 s-sbold mb-2 lblu-color">Sameer Rahane</p> 
                            <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                            </div>
                          </li>
                        </ul>
                        <footer>
                          <div className="row">
                            <div className="chat-bottom-input"><input className="ft-sz-22 s-reg blu-color" placeholder="Type a message here" /></div>
                            <div className="chat-send-icon"><a href=""><span class="icon-send"></span></a></div>
                          </div>
                        </footer>
                      </div>
                    </Tab>
                    <Tab eventKey="private" title="Private Messages" className="private-chat-sec">
                      <div className="chat-section">
                        <ul className="chat-text-sec">
                          <li className="s-reg other-person">
                            <div className="message">
                             <p className="ft-sz-22 s-sbold mb-2 lblu-color">Mihir Joshi</p> 
                             <span>Have you made TDS Payment? Share the details with me</span> 
                            <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                            </div>
                          </li>
                          <li className="s-reg user-type">
                            <div className="message">
                            Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                            <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                            </div>
                          </li>
                          <li className="separator ft-sz-18 s-sbold"><span>1 Unread message</span></li>
                          <li className="s-reg other-person">
                            <div className="message">
                            <p className="ft-sz-22 s-sbold mb-2 lblu-color">Renuka Bhande</p> 
                             <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                            </div>
                          </li>
                          <li className="s-reg user-type">
                            <div className="message">
                            Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                            </div>
                          </li>
                          <li className="s-reg other-person">
                            <div className="message">
                            <p className="ft-sz-22 s-sbold mb-2 lblu-color">Sameer Rahane</p> 
                            <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                            </div>
                          </li>
                        </ul>
                        <footer>
                          <div className="row">
                            <div className="chat-bottom-input"><input className="ft-sz-22 s-reg blu-color" placeholder="Type a message here" /></div>
                            <div className="chat-send-icon"><a href=""><span class="icon-send"></span></a></div>
                          </div>
                        </footer>
                      </div>
                    </Tab>
                  </Tabs>
                  
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
          className="broadcast-model-sec"
          backdrop="static"
          >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="ft-sz-26 s-sbold blu-color">
             Broadcast <span className="d-none">Broadcast Message</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <p className="lable-input font-colr ft-sz-18">Add Users</p>
                  <div className="form-control tag-form-control ft-sz-18 pl-3" value="" placeholder="@mention a user">
                    <span className="inner-tag">Shubham G <i class="fa fa-times" aria-hidden="true"></i></span>
                    <span className="inner-tag orang-bg">Gaurav Rane <i class="fa fa-times" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-none">
              <div className="col-lg-7">
                <div className="form-group">
                  <p className="lable-input font-colr ft-sz-18">Add Users</p>
                  <div className="form-control tag-form-control ft-sz-18 pl-3" value="" placeholder="@mention a user">
                    <span className="inner-tag">Shubham G <i class="fa fa-times" aria-hidden="true"></i></span>
                    <span className="inner-tag orang-bg">Gaurav Rane <i class="fa fa-times" aria-hidden="true"></i></span>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 or-sec"><span>OR</span></div>
              <div className="col-lg-4">
                <div className="form-group">
                  <p className="lable-input font-colr ft-sz-18">Hashtag</p>
                  <select className="form-control">
                    <option value="1" disabled selected>Select Hashtags</option>
                    <option value="2">GST Customer</option>
                  </select>             
                </div>
              </div>
            </div>
            <div className="form-group">
              <p className="lable-input font-colr ft-sz-18">Message</p>
              <textarea className="form-control ft-sz-18" rows="5"></textarea>
            </div>
          </Modal.Body>
          <div className="main-broadcast-btn clearfix">
            <div className="check-box pull-left">
              <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
              <label for="styled-checkbox-011" className="label-text font-colr s-reg ft-sz-18 mb-0">Also send as Email/SMS</label>
            </div>
            <button type="button" className="broadcast-btn btn ft-sz-18 white-color s-bold btn pull-right">Broadcast</button>
          </div>
           
        </Modal>
        <footer className="sticky-footer d-none">
          <div className="container">
            <div className="text-center">
              <small>Copyright © Buktec 2021</small>
            </div>
          </div>
        </footer>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default messenger;