import React from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import LinkedStatus from '../../IcoonNew/LinkedStatus';
import NotReadyStatus from '../../IcoonNew/NotReadyStatus';
import ProcessedStatus from '../../IcoonNew/ProcessedStatus';
import ReadyStatus from '../../IcoonNew/ReadyStatus';
import SortingIcon from '../../IcoonNew/SortingIcon';
import SyncedStatus from '../../IcoonNew/SyncedStatus';
import Filter from '../Filter';
import FinancialYearFilter from '../FinancialYear';
import ShowWidgets from '../ShowWidgets';
import MoneyLinkService from '../../../Services/MoneyLink/MoneyLinkService';
import { connect } from 'react-redux';
import MessageIcon from '../../IcoonNew/Message';
import TaskIcon from '../../IcoonNew/Task';
import { displayDateFormate } from '../../../Helpers/DateFormat';
import IsPersonalIcon from '../../IcoonNew/IsPersonal';
import EvidenceIcon from '../../IcoonNew/EvidenceIcon';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../../Helpers/Constants';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { setBulkTransactionList } from "../../../Redux/Actions/MoneyLink";
import StorageData from "../../../Helpers/StorageData";
import { changeDateFormate, displayTimeFormat } from '../../../Helpers/DateFormat';
import NotProcessedStatus from '../../IcoonNew/NotProcessedStatus';
import BreadCrum from '../../../Components/BoilerPlate/BreadCrum';
import { showAmount, convertToCurrency, rounding, convertFloatNumberToCurrency } from '../../../Helpers/Currency';
import { checkFileExtension } from '../../../Helpers/CheckValidation';
import { Button, Dropdown } from 'react-bootstrap';
import { confirm } from '../../../Components/BoilerPlate/Confirm';
import { ArrowUpDownIcon } from '../../Icon';
import ShowWidgetsResetTransaction from '../ShowWidgetsResetTransaction';

// Intro js user guid
import { render } from "react-dom";
import ReactDOM from 'react-dom';
import { Steps } from "intro.js-react";
// End Intro js user guid 
import DataTableCheckBox from '../../../Components/BoilerPlate/DataTableCheckBox';
import ImportCostCenter from '../../../Components/BoilerPlate/ImportCostCenter';
import ImportLedger from '../../../Components/BoilerPlate/ImportLedger';
import ExportVouchers from '../../../Components/BoilerPlate/ExportVouchers';
import _ from 'lodash';
import BulkRouteLeavingGuard from '../../../Utils/BulkRouteLeavingGuard';

/* static ui elements */
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class TransactionReset extends React.Component {
	constructor(props) {
		super(props);
		this.btnRef = React.createRef();

		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);

		this.state = {
			per_page_items: 0,
			showFilter: false,
			showSearch: true,
			active_input: false,
			search: '',
			selected_items: [],
			disabled_transaction: [],
			disabled_crdr_transaction: [],
			is_permission_granted: (this.props.features_permissions_list.includes(LINKING_CODE) ||
				this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ||
				this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)),

			// Intro js user guid
			stepsEnabled: false,
			initialStep: 0,
			cr_dr_type: this.props.cr_dr_type,
			show_select_all: false,
			closed:false,
			is_dirty:false,
			steps: [
				{
					element: ".dropdown_moneyin",
					intro: "You can select a particular Financial Institute and specific time period or Select All.",
					// position: 'right',
					// hideNext: true 
				},
				{
					element: ".widgets_moneyin",
					intro: "These widgets, which also act as Filter once you click them, give high level status of the transactions based on the filter selected by you earlier."
				},
				{
					element: ".notready_usrin",
					intro: "It's like a draft mode where you have not added all the details."
				},
				{
					element: ".ready_usrin",
					intro: "This indicates that the Accountant can now pick this up for Processing."
				},
				{
					element: ".pending_usrin",
					intro: "You can see the Accounting status on this widget. Click on the right top corner to Toggle the status. **Pending – This shows transaction which are pending for accounting. **Accounted – This shows transaction for which accounting entry is done."
				},
				{
					element: ".synched_usrin",
					intro: "Once the entries are exported, they get automatically marked as synced.",
					position: 'left',
				},
				{
					element: ".linked_usrin",
					intro: "It indicated that the transaction is linked to a particular bank transaction. Use the toggle on top right to switch.",
					position: 'left',
				},
				{
					element: ".filter_moneyin",
					intro: "Use Filter to select specific types of transactions. Or do a Global search."
				},
				{
					element: ".table_moneyin",
					intro: "The table gives you a overview of the transaction along with status icon and others to indicate Evidence attachment, messages and tasks linked to it."
				}
				// {
				// 	element: ".not_processed_usrin",
				// 	intro: "Junior accountant can make the ledger entries and keep it in this status for senior’s approval."
				// },
				// {
				// 	element: ".processed_usrin",
				// 	intro: "Once accountant makes the ledger entries, he can mark it as Processed and these will be picked up for exporting to accounting software."
				// },
				// {
				// 	element: ".entry_moneyin",
				// 	intro: "By default you will be doing single ledger entries for each transaction. In case you wish to do the same ledger entries for a large number of transactions, you can select Bulk entries and save your time. You can select all debit or all credit transactions at one go and reduce your work substantially."
				// },
				// {
				// 	element: ".import_moneyin",
				// 	intro: "Sync your accounting software ledgers by importing them to Buktec. Contact us for software specific imports."
				// },
				// {
				// 	element: ".export_moneyin",
				// 	intro: "Sync your accounting software by exporting ledger entries from Buktec. Contact us for software specific exports."
				// },
			],
			// End Intro js user guid
		}

		this.filters = React.createRef();
		this.widgets = React.createRef();
	}

	// displayAmount = (cell, row) => {
	// 	return  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span className={row.type == 1 ? "green-color" : "red-color"}>{!!cell ? showAmount(cell) : ""}</span></Tooltip>}>
	// 		<span className={row.type == 1 ? "green-color" : "red-color"}>{!!cell ? showAmount(cell) : ""} {!!cell ? <ArrowUpDownIcon expense={ (row.type == 1) ? false : true} /> : ""}</span>
	// 	</OverlayTrigger>
	// }

	displayAmount = (cell, row) => {
		return  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span className={row.type == 1 ? "green-color" : "red-color"}>{!!cell ? showAmount(cell) : ""}</span></Tooltip>}>
			<span className={row.type == 1 ? "green-color" : "red-color"}>
				{!!cell ? showAmount(cell) : ""} {!!cell ? <span className={row.type == 1 ? "green-color" : "red-color"}><ArrowUpDownIcon expense={ (row.type == 1) ? false : true} /></span> : ""}
				</span>
		</OverlayTrigger>
	}

	toggleFilter = () => {
		this.setState({ showFilter: !this.state.showFilter })
	}

	makeRemoveStar = (e, row) => {
		e.stopPropagation();
		let data = {
			id: row.id,
			is_star: row.is_star !== 1 ? 1 : 0
		}
		this.props.makeRemoveStar(row.id, data.is_star)
		MoneyLinkService.makeRemoveStar(data).then(
			(data) => {
				if (data.response_code == 200) {
				} else {
					this.props.makeRemoveStar(row.id, row.is_star)
					toast.error(data.message);
				}
			});
	}

	dataFormatStar = (cell, row) => {
		switch (cell) {
			case 1:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>

			case 0:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
			default:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>

		}
	}

	dataFormatStatus = (cell, row) => {
		switch (cell) {
			// case 1:
			// 	return <ReadyStatus />
			// case 2:
			// 	return <NotReadyStatus />
			// case 3:
			// 	return <ProcessedStatus />
			// case 4:
			// 	return <LinkedStatus />
			// case 5:
			// 	return <SyncedStatus />
			// case 6:
			// 	return <NotProcessedStatus />
			// default:
			// 	return "--"
			case 1:
				return <NotReadyStatus />
			case 2:
				return <ReadyStatus />
			case 3:
				return <NotProcessedStatus />
			case 4:
				return <ProcessedStatus />
			case 5:
				return <SyncedStatus />
			default:
				return "--"
		}
	}

	displayDate = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"ID #" + row.id}</Tooltip>}>
			<span>{displayDateFormate(cell)}</span>
		</OverlayTrigger>
	}
	displayFinantial = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	displayNarration = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	dataFormatType = (cell, row) => {
		switch (cell) {
			case 1:
				return "CR"
			case 2:
				return "DR"
			default:
				return "--"
		}
	}

	dataFormatPersonal = (cell, row) => {
		return <IsPersonalIcon value={cell} />
	}

	goToEditView = (row) => {
		// if(this.state.is_permission_granted){
		// 	this.props.history.push({
		// 		pathname: `/money-link/` + row.id,
		// 		state: {
		// 			search: this.state.search
		// 		}
		// 	});
		// }

	}

	onSortClick = (sort_order, sort_by) => {
		this.props.onSortClick(sort_order, sort_by)
	}

	handleChange = (e) => {
		e.preventDefault();
		this.props.changeSearchText(e.target.value)
	}

	checkForEnterButton = (e) => {
		// e.preventDefault();
		if (e.charCode === 13 && e.target.value) {
			this.searchFilter(e)
		}
		else {
			// this.setState({ search: e.target.value })
			this.props.changeSearchText(e.target.value)
		}
	}

	searchFilter = (e) => {
		e.preventDefault();
		if (this.props.searchText) {
			this.props.searchFilter(this.props.searchText);
			this.props.changeSearchStatus(true)
			this.setState({ showSearch: false, showFilter: false })
			if (this.props.filter_by == 2) {
				toast.warning('Removed filter data and applied search filter')
			}
		}
		else {
			toast.error("Please specify search criteria")
		}
	}

	searchReset = () => {
		// this.props.changeSearchText("")
		this.setState({
			search: '',
			showSearch: true,
		})
	}

	resetFilter = (e) => {
		e.preventDefault();
		this.setState({
			search: '',
			showSearch: true
		}, () => {
			this.props.searchFilter("");
			this.props.changeSearchStatus(false)
		})
	}

	handleSizePerPageChange(sizePerPage) {
		// When changing the size per page always navigating to the first page
		this.setState({
			per_page_items: sizePerPage
		})
	}

	_setNoDataFoundOption() {
		if (!this.props.is_transaction_loading) {
			return "No Transaction found";
		} else {
			return (
				<p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
			);
		}
	}

	dataFormatTask = (cell, row) => {
		return <div>
			<EvidenceIcon active={row.has_evidence} />
			{/* <MessageIcon active={row.general_msg_status || row.private_msg_status} /> */}
			<TaskIcon active={row.tasks_status} />
		</div>
	}

	showFilter = () => {
		if (this.props.filter_by == 2) {
			this.setState({ showFilter: true })
		}
	}

	setInitialRowsValue = () => {
		// disable process and sync transaction
		// let disabled_transaction = [];
		// for (let i = 0; i <= this.props.transaction_list.length - 1; i++) {
		// 	if (this.props.transaction_list[i].status == 3 || this.props.transaction_list[i].status == 5) {
		// 		disabled_transaction.push(this.props.transaction_list[i].id)
		// 	}
		// }
		// this.setState({ disabled_transaction });

		//set preselected rows
		let selected_items = this.props.bulk_transaction_list.map(trans => trans.id);
		this.setState({ selected_items })


		// //Disable row for cr and dr
		// if (selected_items.length <= 0) {
		// 	this.setState({ disabled_crdr_transaction: [] })
		// } else {
		// 	let list = this.props.transaction_list.filter(transaction => transaction.type != this.props.bulk_transaction_list[0].type)
		// 	this.setState({ disabled_crdr_transaction: list.map(item => item.id) })
		// }


	}

	handleCheckBoxChange = (e, id) => {
		const { name } = e.target;
		if (e.target.checked) {
			// deleteSmsList.push(id)
		} else {
			// let index = deleteSmsList.indexOf(id);
			// deleteSmsList.splice(index, 1);
		}
		this.setState({
			// [name]: e.target.checked, delete_sms_list: deleteSmsList
		});
	}
	resetTransactions = async()=>{
		if (await confirm({
			confirmation: ` Do you wish to reset ${this.props.bulk_transaction_list.length} transactions ?`
		  })) {
			this.props.dispatch(setAppilcationLoder(true));
			MoneyLinkService.resetBulkTransaction(this.state.selected_items).then(async(data)=>{
				console.log("api data",data)
				this.props.dispatch(setAppilcationLoder(false));
				if(data.response_code == 200){
					const total_Count = this.props.bulk_transaction_list.length;
           			const deleted_Count =  data.result.deleted_transactions;
            		const not_deleted_Count = (total_Count - deleted_Count);

					await confirm({
						confirmation: `Successfully reseted ${deleted_Count}/${total_Count} transactions`,
						options: { type: "alert" }
					})

					if(not_deleted_Count > 0){
						await confirm({
						  confirmation: `${not_deleted_Count} Transactions were not reseted as they are either linked or you do not have permission to reset them`,
						  options: { type: "alert" }
					  })
					  }

					this.setState({selected_items : []});
					this.props.dispatch(setBulkTransactionList([]));
					this.props.refreshItem();

				}else{
					this.setState({selected_items : []});
					this.props.dispatch(setBulkTransactionList([]));
					toast.error(data.message);
				}
			});
			
		  }else {
			this.setState({selected_items : []})
			this.props.dispatch(setBulkTransactionList([]))
			return true;
		 }
	}

	displayCheckBox = (cell, row) => {
		return <div className="check-box">
			<input disabled={row.type === 1} className="styled-checkbox" id={`styled-checkbox-${row.id}`} type="checkbox"
				name={`delete${row.id}`} value={`delete${row.id}`} onChange={(e) => this.handleCheckBoxChange(e, row.id)} />
			<label htmlFor={`styled-checkbox-${row.id}`} className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
		</div>
	}

	componentDidUpdate(prevProps) {	
		if (prevProps.page_no != this.props.page_no) {
			this.setInitialRowsValue();
		}
	}

	componentDidMount() {
		this.showFilter();
		this.setInitialRowsValue();
		this.props.onRef(this);
		this.props.onRefr(this);
	}

	componentWillUnmount(){
		this.props.dispatch(setBulkTransactionList([]))
	}

	renderShowsTotal(start, to, total) {
		return (
			<p className="main-pagi-show">
				{/* Displaying from <span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span>, totals is <span className="pagi-show">{total}</span> */}

				<span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
			</p>
		);
	}

	customMultiSelect(props) {
		const { type, checked, disabled, onChange, rowIndex } = props;
		/*
		* If rowIndex is 'Header', means this rendering is for header selection column.
		*/
		if (rowIndex === 'Header') {
			return (
				<div className='checkbox-personalized'>
					<Checkbox {...props} />
					<label htmlFor={'checkbox' + rowIndex}>
						<div className='check'></div>
					</label>
				</div>);
		} else {
			return (
				<div className='checkbox-personalized'>
					<input
						type={type}
						name={'checkbox' + rowIndex}
						id={'checkbox' + rowIndex}
						checked={checked}
						disabled={disabled}
						onChange={e => onChange(e, rowIndex)}
						ref={input => {
							if (input) {
								input.indeterminate = props.indeterminate;
							}
						}} />
					<label htmlFor={'checkbox' + rowIndex}>
						<div className={'check ' + (disabled ? ' disable-bg ' : '')}></div>
					</label>
				</div>);
		}
	}

	onRowSelect = (rows, isSelected) => {
		let selected_items = this.state.selected_items;
		let bulk_transaction_list = this.props.bulk_transaction_list;
		if (isSelected) {
			selected_items.push(rows.id);
			bulk_transaction_list.push(rows);
		} else {
			selected_items = selected_items.filter(item => item != rows.id);
			bulk_transaction_list = bulk_transaction_list.filter(item => item.id != rows.id);
		}

		this.props.dispatch(setBulkTransactionList(bulk_transaction_list));
		this.setState({
			selected_items: selected_items,is_dirty:true
		});

		return selected_items;
	}

	onRowSelectAll = (is_selected, rows) => {
		if (is_selected) {
			let bulk_transaction_list = [...rows, ..._.cloneDeep(this.props.bulk_transaction_list)];
			let selected_items = bulk_transaction_list.map(data => data.id);
			this.setState({ selected_items: [...this.state.selected_items, ...selected_items], show_select_all: true,is_dirty:true });
			this.props.dispatch(setBulkTransactionList(bulk_transaction_list));
		} else {
			let selected_items = this.props.transaction_list.map(data => data.id);
			let bulk_transaction_list = this.props.bulk_transaction_list.filter(transaction => { return !selected_items.includes(transaction.id); });
			let items = this.state.selected_items.filter(id => { return !selected_items.includes(id); });
			this.setState({ selected_items: items });
			this.props.dispatch(setBulkTransactionList(bulk_transaction_list));

		}
	}

	resetSelection = () => {
		this.setState({ selected_items: [], show_select_all: false })
		this.props.dispatch(setBulkTransactionList([]));
	}


	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};

	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};

	selectedTransactions = (list) => {
		console.log(list)
		this.setState({ selected_items: list })
	}

	getTotalAmt = () => {
		let total_amount = this.props.bulk_transaction_list.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
		total_amount = Math.round((total_amount + Number.EPSILON) * 100) / 100
		return "₹ " + convertFloatNumberToCurrency(rounding(total_amount));
	}
	saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
		});
	}
	closeAction = () => {
		this.setState({ closed: false })
	}

	render() {
		console.log(this.props.transaction_list, this.props.bulk_transaction_list)

		let is_dirty = this.state.is_dirty
		if(this.state.selected_items.length == 0){
		  is_dirty = false
		}else{
		  is_dirty = true
		}
		
		// Intro js user guid 
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;
		// End Intro js user guid 

		const selectRowProp = {
			// columnWidth: '100px',
			onSelect: this.onRowSelect,
			onSelectAll: this.onRowSelectAll,
			mode: 'checkbox',
			selected: this.state.selected_items,
			clickToSelect: true,
			// onSelect: this.handleRowSelect
			customComponent: this.customMultiSelect
		};

		const options = {
			onPageChange: this.props.handlePageChange,
			onSizePerPageList: this.handleSizePerPageChange,
			page: this.props.page_no,  // which page you want to show as default
			sizePerPage: this.props.per_page_items,  // which size per page you want to locate as default
			pageStartIndex: 1, // where to start counting the pages
			paginationSize: 5,  // the pagination bar size.
			prePage: 'Prev', // Previous page button text
			nextPage: 'Next', // Next page button text
			firstPage: 'First', // First page button text
			lastPage: 'Last', // Last page button text
			//paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
			hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
			onRowClick: (row) => {
				this.goToEditView(row);
			},
			noDataText: this._setNoDataFoundOption(),
		}
		const myRef = React.createRef();
		const ref_upload_cost_center = React.createRef();

		return (
			<main className="content-wrapper-new">
				<div className="container-fluid container-padding-new">
					{/* Intro js user guid */}
					<Steps
						enabled={stepsEnabled}
						steps={steps}
						initialStep={initialStep}
						onExit={this.onExit}
					/>
					<BulkRouteLeavingGuard
					when={is_dirty}
					saveChanges={this.saveBeforeLeave}
					closed={this.state.closed}
					closeAction={this.closeAction}
					/>
					{/* End Intro js user guid */}

					{/* Breadcrumb */}
					<div className="row">
						<div className="col-12">
							<div className="main-sub-header-new">
								<div className="breadcrumb-header-new">
									<div className="row">
										<div className="col-12">
											<BreadCrum level={2} />
										</div>
									</div>
									<div className="row">
										<div className="col-8">
											<h2 className="breadcrumb-title-new">
												Money Link - Transaction Reset
												{/* <span className="help-icon" > */}
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger>
												{/* </span> */}
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Breadcrumb */}

					{/* Filter */}
					<div className="row mainFilter-new sec-mb">
						<div className="col-12">
							<div className="top-new-filter">
								<FinancialYearFilter
									financialYearChanged={this.props.financialYearChanged}
									refreshItem={this.props.refreshItem}
									freshRecommendation={this.props.freshRecommendation}
									financialInstituteChanged={this.props.financialInstituteChanged}
									recommendation_meta={this.props.recommendation_meta}
									checkBulkLedgerFlag={this.props.checkBulkLedgerFlag}
									financial_institute_list={this.props.financial_institute_list}
									onRef={ref => (this.filters = ref)}
									dateTypeChanged={this.props.dateTypeChanged}
									date_type={this.props.date_type}
								/>

								<div className="filter-search-field">
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Narration</Tooltip>}>
										{/* <form className="search-new-sec" > */}
										<form className={"search-new-sec" + (this.state.active_input ? " active-filter" : "")}>
											<input type="text"
												className={"form-control filter-btn-new " + (this.state.active_input ? " activeNew-search" : "")}
												onKeyPress={this.checkForEnterButton}
												onFocus={() => this.setState({ active_input: true })}
												// onBlur={() => this.setState({ active_input: false })}
												placeholder="Search"
												value={this.props.searchText}
												onChange={(e) => this.handleChange(e)}
												name="search" />

											{this.props.searchApplied == false ?
												<Button onClick={this.searchFilter} className={this.state.active_input ? " active-new-search-icon " : ""}>
													<span className="icon-loupe"></span>
												</Button>
												:
												<Button onClick={this.resetFilter} className={this.state.active_input ? " active-new-search-icon crosebtn " : " crosebtn "}>
													<span class="icon-cancel"></span>
												</Button>
											}
										</form>
									</OverlayTrigger>

									<Button variant={this.state.showFilter ? " outline-secondary filter-btn-new border-radius-26 active-filter filter_moneyin " : " outline-secondary filter-btn-new border-radius-26 filter_moneyin"}
										onClick={this.toggleFilter}
									>Filter <span className="icon-funnel gray-dark-icon filter-icon"></span></Button>

									{/* Actions Menu */}
									<Dropdown className='action-new-menu'>
										<Dropdown.Toggle variant="" id="dropdown-basic" className='success add-new-btn action-btn-height'>
											<span class="icon-menu-lines white-icon"></span>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{this.LEDGER_ENTRIES &&
												<>
													<button onClick={() => this.props.handleTabChange(0)} >
														<span class="action-new-icon-sec">
															<span class="icon-Group-2546 action-new-icon"></span>
														</span>
														<span>Single Entry</span>
													</button>
													<button onClick={() => this.props.handleTabChange(1)} >
														<span class="action-new-icon-sec">
															<span class="icon-Group-2546 action-new-icon"></span>
														</span>
														<span>CR Bulk Entries</span>
													</button>
													<button onClick={() => this.props.handleTabChange(2)} >
														<span class="action-new-icon-sec">
															<span class="icon-Group-2546 action-new-icon"></span>
														</span>
														<span>DR Bulk Entries</span>
													</button>
													<button onClick={() => this.props.handleTabChange(3)} >
														<span class="action-new-icon-sec">
															{/* <span class="icon-Group-2546 action-new-icon"></span> */}
															<span class="icon-Group-2516 action-new-icon"></span>
														</span>
														<span>Transaction Reset</span>
													</button>
													<hr />
												</>
											}
											{/* <button disabled={this.props.recommendation_meta.initiate_flag == 0 ? 'disabled' : ''} onClick={() => { this.props.freshRecommendation() }} > */}
											<button onClick={() => { this.props.freshRecommendation() }} >
												<span class="action-new-icon-sec">
													<span class="icon-invoice1 action-new-icon"></span>
												</span>
												<span class={this.props.recommendation_meta.initiate_flag == 0 ? 'disable-any' : ''}>Initiate Fresh Recommendation
													<span className="d-block blue-text">{displayDateFormate(this.props.recommendation_meta.last_run)} {displayTimeFormat(this.props.recommendation_meta.last_run)}</span>
												</span>
											</button>
											<hr />
											<button onClick={this.props.refreshItem}>
												<span class="action-new-icon-sec">
													<span class="icon-refresh action-new-icon"></span>
												</span>
												<span>Refresh</span>
											</button>
										</Dropdown.Menu>
									</Dropdown>
									{/* End Actions Menu */}
								</div>
							</div>

							{/* Filter Popup */}
							{this.state.showFilter && <Filter
								reset={this.searchReset}
								search={this.state.search}
								modified_by_list={this.props.modified_by_list}
								applyFilter={this.props.applyFilter}
								cr_dr_type={"cr_dr"}
								resetFinancialInstitute={() => this.filters.resetFinancialInstitute()}
								onCardSelected={(val) => this.widgets.onCardSelected(val)}
							/>}
							{/* End Filter Popup */}
						</div>
					</div>
					{/* End Filter */}

					{/* Card Filter */}
					<ShowWidgetsResetTransaction
						filter_card={this.props.filter_card}
						onCardSelected={this.props.onCardSelected}
						transaction_count={this.props.transaction_count}
						checkBulkLedgerFlag={this.props.checkBulkLedgerFlag}
						features_permissions_list={this.props.features_permissions_list}
						default_cards={this.props.default_cards}
						onRef={ref => (this.widgets = ref)}
					/>
					{/* End Card Filter */}

					{/* Table List */}
					<span className='table_moneyin'>
						<div className="row main-table-listing listing-icons">
							<div className="col-12">
								<div className="table-sec mb-0">
									{this.props.transaction_list.length === 0 && this.props.is_transaction_loading === false ?
										<h2 className="no-item-list">No items to display</h2> :
										<BootstrapTable
											ref='table'
											data={this.props.transaction_list} responsive table-bordered
											pagination={true}
											options={options}
											remote
											fetchInfo={{ dataTotalSize: this.props.total_count }}
											// remote
											// pagination
											// striped
											// hover
											condensed
											selectRow={selectRowProp}
											keyField='id'
										// tableHeaderClass='custom-select-header-class' tableBodyClass='custom-select-body-class'
										>
											<TableHeaderColumn width="" thStyle={{ textAlign: 'center', width: '100px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '100px' }} dataField='date' dataFormat={this.displayDate}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
											</TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ textAlign: 'left', width: '16%' }} tdStyle={{ ...td_style, textAlign: 'left', width: '16%' }} dataField='financial_institute' dataFormat={(cell, row) => this.displayFinantial(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Financial Institute" field_number="4" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ width: '36%' }} tdStyle={{ ...td_style, width: '36%' }} dataField='narration' dataFormat={(cell, row) => this.displayNarration(cell)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Narration" field_number="5" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ width: '15%', textAlign: 'right' }} tdStyle={{ ...td_style, width: '15%', textAlign: 'right' }} dataField='amount' dataFormat={(cell, row) => this.displayAmount(cell, row)} >
												<SortingIcon onSortClick={this.onSortClick} field_name="Amount" field_number="7" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" tdStyle={{ ...td_style, textAlign: 'center', width: '7%' }} thStyle={{ textAlign: 'center', width: '7%' }} dataField='type' dataFormat={(cell, row) => this.dataFormatType(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="CR/DR" field_number="6" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" columnClassName={'tim-0'} thStyle={{ textAlign: 'center', width: '75px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '75px' }} dataField='status' dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Status" field_number="9" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="100px" tdStyle={td_style} dataField='' dataFormat={(cell, row) => this.dataFormatTask(cell, row)} dataSort></TableHeaderColumn>
										</BootstrapTable>
									}
								</div>
							</div>
						</div>
					</span>
					{/* End Table List */}

					{/* Listing Bottom Fixed Bar */}
					{this.props.transaction_list.length != 0 &&
						<div className="fix-button-bar-bottom">
							{this.props.bulk_transaction_list.length != 0 &&
								<button type="button"
									onClick={async () => { if (await confirm({ confirmation: "This action will clear the selection. Do you want to continue?" })) { this.resetSelection() } }}
									className={"transpnt-btn " + (this.props.bulk_transaction_list.length == 0 ? " disabled" : "")}
								>Reset all</button>
							}
							{(this.state.show_select_all && this.props.total_count > 25)&&
								<button type="button" className={"transpnt-btn "} onClick={this.props.selectAllTransactions}>Select across all pages</button>
							}

							<p className="fbb-p">Selected: {this.props.bulk_transaction_list.length + "/" + this.props.total_count}</p>
							<div className="bottom-save-btn">
								{!!this.props.bulk_transaction_list.length ?
								<button type="button" class="btn btn-success apply_btn_new " onClick={this.resetTransactions}>Reset Transactions</button>
									
									:
									<button type="button" class="btn btn-success apply_btn_new disabled" disabled>Reset Transactions</button>
								}
							</div>
						</div>
					}
					{/* Listing Bottom Fixed Bar */}

				</div>
			</main>
		)
	}
}

function mapStateToProps(state) {

	const all_value = state.MoneyLink || {};
	const is_transaction_loading = all_value.is_transaction_loading;
	//const filter_by = all_value.filter_by;

	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	const system_parameter = session_value.system_parameter || {};
	const bulk_voucher_limit = system_parameter.bulk_voucher_limit || 0;

	const bulk_transaction_list = all_value.bulk_transaction_list || [];
	const cr_dr_type = all_value.cr_dr_type;

	return {
		is_transaction_loading, features_permissions_list, all_value, bulk_transaction_list, bulk_voucher_limit, cr_dr_type
	};
}

class Checkbox extends React.Component {
	componentDidMount() { this.update(this.props.checked); }
	componentWillReceiveProps(props) { this.update(props.checked); }
	update(checked) {
		ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
	}
	render() {
		return (
			<input className='react-bs-select-all'
				type='checkbox'
				name={'checkbox' + this.props.rowIndex}
				id={'checkbox' + this.props.rowIndex}
				checked={this.props.checked}
				onChange={this.props.onChange} />
		);
	}
}

export default connect(mapStateToProps)(withRouter(TransactionReset));
