import {
    SET_USER_DETAIL,
    SET_ENTITY_DETAIL,
    SET_DEFAULT_ENTITY,
    SET_ACCOUNT_PERMISSION,
    SET_ENTITY_PERMISSION,
    SET_MODULE_PERMISSION,
    SET_SYSTEM_PARAMETER,
    SET_ENTITY_CONFIG_PARAMETER
 } from "./Types";

export const setUserDetail = (user_detail) => ({
  type: SET_USER_DETAIL,
  payload: user_detail,
});
  
export const setEntityList = (entity_list) => ({
  type: SET_ENTITY_DETAIL,
  payload: entity_list,
});
  
export const setDefaultEntity = (default_entity_detail) => ({
    type: SET_DEFAULT_ENTITY,
    payload: default_entity_detail,
  }); 

  export const setAccountPermission = (account_permissions) => ({
    type: SET_ACCOUNT_PERMISSION,
    payload: account_permissions,
  }); 

  export const setEntityPermission = (features_permissions_list) => ({
      type: SET_ENTITY_PERMISSION,
      payload: features_permissions_list,
    }); 

export const setModulePermission = (module_permissions) => ({
        type: SET_MODULE_PERMISSION,
        payload: module_permissions,
      }); 

export const setEntityConfigParam = (entity_config_param) => ({
        type: SET_ENTITY_CONFIG_PARAMETER,
        payload: entity_config_param,
      }); 

export const setSystemParamenter = (system_parameter) => ({
  type: SET_SYSTEM_PARAMETER,
  payload: system_parameter,
});     