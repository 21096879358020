

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function IsPersonalIcon(props) {
    // return  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.value === 1 ? 'Personal': 'Not Personal'}</Tooltip>}>
    // <span className={"icon-personal-icon icon-dt-td "+(props.value === 1 ?" ":" gray-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
    // </OverlayTrigger>
    return  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.value === 1 ? 'Personal': 'Not Personal'}</Tooltip>}>
    <span className={"icon-personal-icon icon-dt-td "+(props.value === 1 ?" ":" ")}><span className="path1 grayc-light-icons"></span><span className="path2"></span><span className="path3"></span></span>
    </OverlayTrigger>
}
export default IsPersonalIcon;