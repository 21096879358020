import {
  SET_INVOICE_FILTER_DATA,
  INVOICE_WIDGET_DATA,
  SET_TOTAL_COUNT,
  SET_PAGE_NO,
  SET_PER_PAGE_ITEM,
  SET_IS_INVOICE_LOADING,
  SET_TASK_STAR, SET_VIEW_INVOICE
} from "../Actions/Types";

const initialState = {
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_INVOICE_FILTER_DATA:
      return {
        ...state,
        ...payload
      }
    case INVOICE_WIDGET_DATA:
      return {
        ...state,
        widget_count: payload
      }
    case SET_TOTAL_COUNT:
      return {
        ...state,
        total_count: payload
      }
    case SET_PAGE_NO:
      return {
        ...state,
        page_no: payload
      }
    case SET_PER_PAGE_ITEM:
      return {
        ...state,
        per_page_items: payload
      }
    case SET_IS_INVOICE_LOADING:
      return {
        ...state,
        is_invoice_loading: payload
      }
    default:
      return state;
  }
}