import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
function invoice(){
  const [comment,setComment]=React.useState(false)
  const [task,setTask]=React.useState(false)
  const [note,setNote]=React.useState(false)
  const [privatemessage,setPmessage]=React.useState(false)
  const [sidfltr,setSidfltr]=React.useState(false)

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold m-0 font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li className="">
                  <Dropdown>
                  <img src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Invoices</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Invoices <span className="icon-Information green-icon green-icon-head"></span></h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-date-sec">
                  <div className="">
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec">
                          <option value="1" disabled selected>Current Financial Year</option>
                          <option value="2">Last Financial Year</option>
                          <option value="3">This Month </option>
                          <option value="4">Last 3 Months</option>
                          <option value="5">Since Beginning</option>
                          <option value="6">Custom Date Range</option>
                        </select>             
                      </div>
                    </div>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <hr/>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">End Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                  </div> 
                </div>

                <div className="">
                  <div className="tab-inner-sec">
                    <div className="row">
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box active text-center">
                          <p className="title ft-sz-29 m-0">Total</p>
                          <div className="count-char s-bold">20</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-29 m-0">Not Ready</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-29 m-0">Ready</p>
                          <div className="count-char s-bold">15</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-29 m-0">Linked</p>
                          <div className="count-char s-bold">12</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-29 m-0">Processed</p>
                          <div className="count-char s-bold">2</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-29 m-0">Synched</p>
                          <div className="count-char s-bold">1</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-29 m-0">Proforma</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec">
                        <input type="text" placeholder="Search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter">
                      Filter <span className="icon-funnel"></span>
                      </div>
                      <button className="add-new-btn btn ft-sz-20 white-color s-bold"><span className="icon-plus1"></span> Create Invoice</button>
                    </div>
                  </div>
                    <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Type</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Invoice number" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Customer" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                          <div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec">
                            <div className="col">
                              <p className="mt-2 ft-sz-17 s-sbold mb-0">Amount</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control text-right" placeholder="₹ 0"/>
                                  </div>
                                </div>
                            </div>
                            <div className="col">
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control text-right" placeholder="₹ 0"/>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Payment Status</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>

                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Created By</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Star</option>
                                <option>Yes</option>
                                <option>No</option>
                                <option>Both</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>

                  
                  {/* Invoice Table list */}
                  <div className="main-table-sec">
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star</th>
                          <th>Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Type <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Invoice# <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Customer <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Amount <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Payment status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000 <span class="icon-Green-arrow"></span></td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000 <span class="icon-Red-arrow"></span></td>
                          <td>Unpaid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold sky-blu-color">Ready</Tooltip>}>
                            <span className="icon-td icon-Group-25071 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Linked</Tooltip>}>
                            <span class="icon-td icon-Group-2510 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Partially paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Not Ready</Tooltip>}>
                            <span class="icon-td icon-Group-2509 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="main-pagination clearfix">
                    <nav aria-label="Page navigation example">
                      <div class="pull-left"><button type="button" class="btn sms-delete-btn white-color drk-gry-bg">Delete </button></div>
                      <ul class="pagination pull-right mb-0">
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                        <li class="page-item item-count"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li>
                        <li class="page-item prev-sec"><a class="page-link ft-sz-20 s-reg" href="#"><span class="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li class="page-item active"><a class="page-link ft-sz-20 s-reg" href="#">1</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">3</a></li>
                        <li class="page-item next-sec"><a class="page-link ft-sz-20 s-reg" href="#">Next <span class="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>
              
              
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
              <div className="clearfix">
                <ol className="breadcrumb pull-left">
                  <li className="breadcrumb-item">
                  <Link to={'/home'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Evidence</li>
                </ol>
                <div className="pull-right d-flex new-add-filter-sec">
                  <div className="sidebar-evidence-filter" onClick={()=>setSidfltr(!sidfltr)}>
                  <span class="icon-filter"></span>
                  </div>
                  <div className="add-new-evi-sms">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
                    <span class="icon-plus"></span>
                    </OverlayTrigger>
                  </div>
                </div> 
              </div>
              <hr/>

                

                <div className="recipt-sidebar-sec">
                {
                sidfltr?<div className="main-evidence-sidefilter">
                    <div className="row">
                    <div className="col-12">
                    <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select">
                            <option>Star</option>
                            <option>2</option>
                            <option>3</option>
                            </Form.Control>
                        </Form.Group>
                        </Form>
                        </div>
                        <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select">
                            <option>Type</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        </Form>
                        </div>
                        <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select">
                            <option>Custom Date Range</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        </Form>
                        </div>
                    </div>
                    </div>    
                    <div className="row no-gutters">    
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <div className="start-end-date-sec btn">
                              <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                          </div>
                          </div>  
                      </div>
                      <div className="col">
                        <hr/>
                      </div>
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <div className="start-end-date-sec btn">
                              <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                          </div>
                          </div>
                      </div>
                    </div>  
                    <div className="row">    
                    <div className="col-12">
                        <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select">
                            <option>Payment Status</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        </Form>
                        </div>
                    </div>
                    </div>
                    <div className="row no-gutters">    
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <input type="text" className="form-control" value="" placeholder="From Amount" />  
                          </div>  
                      </div>
                      <div className="col">
                        <hr/>
                      </div>
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <input type="text" className="form-control" value="" placeholder="To Amount" /> 
                          </div>
                      </div>
                    </div> 
                    <div className="row no-gutters">    
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <input type="text" className="form-control" value="" placeholder="Invoice No." />  
                          </div>  
                      </div>
                      <div className="col">
                      </div>
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <input type="text" className="form-control" value="" placeholder="Customer" /> 
                          </div>
                      </div>
                    </div>
                    <div className="row">    
                      <div className="col-12">
                        <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="Created By" /> 
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-xs-12">
                          <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                          <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      </div>
                    </div>
                </div>:null
                }  

                 <div className="recipt-inner-div invoice-side-list empty-div active">
                    <div className=""></div>
                    <div className="row">
                     <p className="m-0 ft-sz-28 font-colr s-sbold">New Invoice</p>
                    </div>
                 </div>
                <div className="recipt-inner-div invoice-side-list active">
                    <div className=""></div>
                    <div className="row">
                    <div className="col-xl-7 col-sm-12">
                        <div className="recipt-id s-sbold font-colr">
                        ID #2154
                        </div>
                        <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                        {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                        <div className="date s-sbold font-colr">
                        11-12-2020 
                        </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-2510"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                        </div>
                        </div>
                        <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000 <span class="icon-Green-arrow"></span></span>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                    <div className="col-xl-7 col-sm-12">
                        <div className="recipt-id s-sbold font-colr">
                        ID #2154
                        </div>
                        <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                        {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                        <div className="date s-sbold font-colr">
                        11-12-2020 
                        </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-2509"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                        </div>
                        
                        </div>
                        <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000 <span class="icon-Red-arrow"></span></span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                    <div className="col-xl-7 col-sm-12">
                        <div className="recipt-id s-sbold font-colr">
                        ID #2154
                        </div>
                        <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                        {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                        <div className="date s-sbold font-colr">
                        11-12-2020 
                        </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-2508"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                        </div>
                        
                        </div>
                        <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="recipt-inner-div invoice-side-list">
                    <div className="row">
                    <div className="col-xl-7 col-sm-12">
                        <div className="recipt-id s-sbold font-colr">
                        ID #2154
                        </div>
                        <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                        {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                        <div className="date s-sbold font-colr">
                        11-12-2020 
                        </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-25071"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                        </div>
                        
                        </div>
                        <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                  <div className="main-pagination clearfix sidebar-pagination">
                    <nav aria-label="Page navigation example">
                      {/* <div className="per-page-sec clearfix">
                        <ul class="pagination pull-right mb-0">
                          <li class="page-item"><a class="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                          <li class="page-item item-count"><a class="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        </ul>
                      </div> */}
                      <ul class="pagination pull-right mb-0">  
                        <li class="page-item prev-sec"><a class="page-link ft-sz-18 s-reg" href="#"><span class="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li class="page-item active"><a class="page-link ft-sz-18 s-reg" href="#">1</a></li>
                        <li class="page-item"><a class="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-18 s-reg" href="#">3</a></li>
                        <li class="page-item next-sec"><a class="page-link ft-sz-18 s-reg" href="#">Next <span class="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
              <div className="inner-content-sec">
              <p className="head m-0 s-sbold blu-color">Invoices <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
              <span className="icon-Information green-icon help"></span></OverlayTrigger></p>
              <div className="clearfix"></div>
              <div className="main-file-item-sec">
                  <div className="cross" onClick={handleShow}>
                  <span>X</span>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-sm-12">
                     <div className="right-inner-content-sec">
                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">General</p>
                           <p className="pull-right s-sbold ft-sz-22 blu-color m-0"><span className="icon-log icon-round-info-button" onClick={logShow}></span></p>
                          </div> 
                          <div className="row">
                           <div className="col-lg-3 col-sm-12"></div>
                           <div className="col-lg-9 col-sm-12 right-form-sec">
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                      <p className="lable-input font-colr">Type*</p>
                                      <select className="form-control">
                                        <option value="1" disabled selected>Select your option</option>
                                        <option value="2">Saab</option>
                                        <option value="3">VW</option>
                                        <option value="4">Audi</option>
                                      </select>             
                                  </div>
                                </div> 
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group clearfix">
                                  <p className="lable-input font-colr pull-left">Invoice no.*</p>
                                  <p className="lable-input font-colr pull-right">(Last inv# : 1258FD5 )</p>
                                  <input type="text" className="form-control input-bg-green" value="1258FD5"/>             
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                      <p className="lable-input font-colr">Payment Status*</p>
                                      <select className="form-control">
                                        <option value="1" disabled selected>Select your option</option>
                                        <option value="2">Saab</option>
                                        <option value="3">VW</option>
                                        <option value="4">Audi</option>
                                      </select>             
                                  </div>
                                </div>
                              </div>  
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group date-sec">
                                  <p className="lable-input font-colr">Date*</p>
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" value="dd/mm/yy"/>
                                    <span class="icon-calendar"></span>
                                  </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group date-sec">
                                  <p className="lable-input font-colr">Due Date</p>
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" value="dd/mm/yy"/>
                                    <span class="icon-calendar"></span>
                                  </div>
                                  </div>
                                </div>
                              </div>
                           </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Bill To</p>
                          </div> 
                          <div className="row">
                           <div className="col-lg-3 col-sm-12"></div>
                           <div className="col-lg-9 col-sm-12 right-form-sec">
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                  <p className="lable-input font-colr">Name</p>
                                  <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                  <p className="lable-input font-colr">E-mail</p>
                                  <input type="email" className="form-control" value=""/>             
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                  <p className="lable-input font-colr">Phone</p>
                                  <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                              </div>  
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">GST Number</p>
                                    <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                                <div className="col-lg-8 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Address</p>
                                    <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">City</p>
                                    <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">State</p>
                                    <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Country</p>
                                    <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Pincode</p>
                                    <input type="text" className="form-control" value=""/>             
                                  </div>
                                </div>
                              </div>
                           </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Item Details</p>
                          </div> 
                          <div className="row">
                           <div className="col-lg-12 col-sm-12">
                            <div className="main-invoice-item-sec">
                              <Table responsive>
                                <thead>
                                  <tr className="blu-bg white-color">
                                    <th className="ft-sz-15 s-reg w-25">Item</th>
                                    <th className="ft-sz-15 s-reg">HSN /SAC</th>
                                    <th className="ft-sz-15 s-reg">Rate</th>
                                    <th className="ft-sz-15 s-reg">Quantity</th>
                                    <th className="ft-sz-15 s-reg">Discount(%)</th>
                                    <th className="ft-sz-15 s-reg">CGST (%)</th>
                                    <th className="ft-sz-15 s-reg">SGST (%)</th>
                                    <th className="ft-sz-15 s-reg">IGST (%)</th>
                                    <th className="ft-sz-15 s-reg">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><input type="text" className="form-control" value="" placeholder="Description of service or product"/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td>
                                      <div className="main-quantity-sec form-control">
                                        <span>2</span>
                                        <div className="inner-quantity-sec">
                                          <p className="m-0"><a><span className="icon-right-arrow iarrow"></span></a></p>
                                          <p className="m-0"><a><span className="icon-right-arrow-1 iarrow"></span></a></p>
                                        </div>
                                      </div>
                                    </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                  </tr> 
                                  <tr>
                                    <td><input type="text" className="form-control" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td>
                                      <div className="main-quantity-sec form-control">
                                        <span>2</span>
                                        <div className="inner-quantity-sec">
                                          <p className="m-0"><a><span className="icon-right-arrow iarrow"></span></a></p>
                                          <p className="m-0"><a><span className="icon-right-arrow-1 iarrow"></span></a></p>
                                        </div>
                                      </div>
                                    </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                  </tr>
                                  <tr>
                                    <td><input type="text" className="form-control" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td>
                                      <div className="main-quantity-sec form-control">
                                        <span>2</span>
                                        <div className="inner-quantity-sec">
                                          <p className="m-0"><a><span className="icon-right-arrow iarrow"></span></a></p>
                                          <p className="m-0"><a><span className="icon-right-arrow-1 iarrow"></span></a></p>
                                        </div>
                                      </div>
                                    </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                  </tr>
                                  <tr>
                                     <div className="item-increment">
                                      <span class="icon-plus"></span>
                                     </div>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td  className="text-right align-middle">Total</td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value="₹ 190"/> </td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value="₹ 143"/> </td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value="₹ 143"/> </td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value="₹ 0"/> </td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value="₹ 1900"/> </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>Net Amount</td>
                                    <td><input type="text" className="form-control input-bg-gray" value=""/> </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>TDS(%)</td>
                                    <td><input type="text" className="form-control" value=""/> </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>TDS(Amount)</td>
                                    <td><input type="text" className="form-control input-bg-gray" value=""/> </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>  
                           </div>
                          </div>

                          {/* Non GST Table */}
                          <div className="row d-none">
                           <div className="col-lg-12 col-sm-12">
                            <div className="main-invoice-item-sec">
                              <Table responsive>
                                <thead>
                                  <tr className="blu-bg white-color">
                                    <th className="ft-sz-15 s-reg w-50">Item</th>
                                    <th className="ft-sz-15 s-reg">Rate</th>
                                    <th className="ft-sz-15 s-reg">Quantity</th>
                                    <th className="ft-sz-15 s-reg">Discount(%)</th>
                                    <th className="ft-sz-15 s-reg">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><input type="text" className="form-control" value="" placeholder="Description of service or product" /> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td>
                                      <div className="main-quantity-sec form-control">
                                        <span>2</span>
                                        <div className="inner-quantity-sec">
                                          <p className="m-0"><a><span className="icon-right-arrow iarrow"></span></a></p>
                                          <p className="m-0"><a><span className="icon-right-arrow-1 iarrow"></span></a></p>
                                        </div>
                                      </div>
                                    </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                  </tr> 
                                  <tr>
                                    <td><input type="text" className="form-control" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td>
                                      <div className="main-quantity-sec form-control">
                                        <span>2</span>
                                        <div className="inner-quantity-sec">
                                          <p className="m-0"><a><span className="icon-right-arrow iarrow"></span></a></p>
                                          <p className="m-0"><a><span className="icon-right-arrow-1 iarrow"></span></a></p>
                                        </div>
                                      </div>
                                    </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                  </tr> 
                                  <tr>
                                    <td><input type="text" className="form-control" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td>
                                      <div className="main-quantity-sec form-control">
                                        <span>2</span>
                                        <div className="inner-quantity-sec">
                                          <p className="m-0"><a><span className="icon-right-arrow iarrow"></span></a></p>
                                          <p className="m-0"><a><span className="icon-right-arrow-1 iarrow"></span></a></p>
                                        </div>
                                      </div>
                                    </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                    <td><input type="text" className="form-control text-right" value=""/> </td>
                                  </tr> 
                                  <tr>
                                     <div className="item-increment">
                                      <span class="icon-plus"></span>
                                     </div>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td  className="text-right">Total</td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                    <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>TDS(%)</td>
                                    <td><input type="text" className="form-control" value=""/> </td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>TDS(Amount)</td>
                                    <td><input type="text" className="form-control input-bg-gray" value=""/> </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>  
                           </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Payment Details</p>
                          </div> 
                          <div className="row">
                           <div className="col-lg-3 col-sm-12"></div>
                           <div className="col-lg-9 col-sm-12 right-form-sec">
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Mode Of Payment</p>
                                    <select className="form-control">
                                      <option value="1" disabled selected>Select name</option>
                                      <option value="2">HDFC Bank</option>
                                      <option value="3">VW</option>
                                      <option value="4">Audi</option>
                                    </select>             
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                  <p className="lable-input font-colr">Account Number</p>
                                  <input type="email" className="form-control input-bg-gray" value="31547854512"/>             
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                  <p className="lable-input font-colr">IFSC Code</p>
                                  <input type="text" className="form-control input-bg-gray" value="NU124587"/>             
                                  </div>
                                </div>
                              </div>  
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">City</p>
                                    <input type="text" className="form-control input-bg-gray" value="Nagpur"/>             
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Branch</p>
                                    <input type="text" className="form-control input-bg-gray" value="Dharampeth"/>             
                                  </div>
                                </div>
                              </div>
                           </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Tags and Signature</p>
                          </div> 
                          <div className="row">
                           <div className="col-lg-3 col-sm-12"></div>
                           <div className="col-lg-9 col-sm-12 right-form-sec">
                              <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                  <div className="form-group">
                                  <p className="lable-input font-colr">Category</p>
                                  <input type="email" className="form-control" value="IT Services"/>             
                                  </div>
                                </div>
                              </div>  
                              <div className="row">
                                <div className="col-lg-5 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Tags</p><span className="s-reg ft-sz-15 sub-font-colr">Add New tag</span>
                                    <textarea class="form-control" id="" rows="3"></textarea>
                                  </div>
                                  <div className="tag ft-sz-13 s-reg"><span class="icon-tag-black-shape"></span> #RathodProject</div>
                                </div>
                                <div className="col"></div>
                                <div className="col-lg-5 col-sm-12">
                                  <div className="form-group">
                                    <p className="lable-input font-colr">Digital Signature</p>
                                    <div className="inner-digital-sign-sec"></div>
                                  </div>
                                </div>
                              </div>
                           </div>
                          </div>
                        </div>

                      </div>
                  </div>
                  </div>
              </div>
              <div className="bottom-save-btn clearfix">
                <div className="pull-left">
                  <button type="button" className="btn save-btn greenbtn mt-1">Preview and Print </button>
                </div>
                <div className="pull-right">
                  <div className="ready-btn">
                      <select>
                      <option value="audi" selected>Not Ready</option>
                      <option value="volvo">Ready</option>
                      </select> 
                  </div>  
                  <button type="button" className="btn save-btn graybtn">Save</button>
                </div>
              </div>

              <div className="bottom-message-sec">
                <div className="main-bottom-comments-note-sec">
                  {
                  comment?<div className="bottom-comments-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-chat"></span></span>
                      <span className="ft-sz-32 s-sbold">Messages</span>
                    </div>
                    <div className="inner-comment-scroll">
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="gren-color">Shubham Gupta</span>  please check the image for this evidence</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">2 days ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter green-bg s-sbold">
                          <span>S</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">just now</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }

                {
                  note?<div className="bottom-note-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-Group-2546"></span></span>
                      <span className="ft-sz-32 s-sbold">Notes</span>
                    </div>
                    <div className="inner-note-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="first-letter yellow-bg s-sbold">
                        <span>Y</span>
                      </div>
                      <div className="note-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                      </div>
                    </div>
                    <div className="inner-note-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="first-letter yellow-bg s-sbold">
                        <span>Y</span>
                      </div>
                      <div className="note-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                      </div>
                    </div>
                  </div>:null
                }  

                {
                  task?<div className="bottom-task-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-Group-2516"></span></span>
                      <span className="ft-sz-32 s-sbold">Tasks</span>
                    </div>
                    <div className="inner-task-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="check-box">
                        <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                        <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                      </div>
                      <div className="task-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                        <div className="day-reply-sec">
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          <p className="ft-sz-18 s-reg red-bg white-color color-div">Overdue</p>
                        </div>
                      </div>
                    </div>
                    <div className="inner-task-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="check-box">
                        <input className="styled-checkbox" id="styled-checkbox-24" type="checkbox" value="value011" />
                        <label for="styled-checkbox-24" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                      </div>
                      <div className="task-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                        <div className="day-reply-sec">
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          <p className="ft-sz-18 s-reg yellow-bg white-color color-div">Due in 2 days</p>
                        </div>
                      </div>
                    </div>
                    <div className="inner-task-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="check-box">
                        <input className="styled-checkbox" id="styled-checkbox-142" type="checkbox" value="value011" />
                        <label for="styled-checkbox-142" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                      </div>
                      <div className="task-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                        <div className="day-reply-sec">
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }  


                {
                  privatemessage?<div className="bottom-comments-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-chat"></span></span>
                      <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                    </div>
                    <div className="inner-comment-scroll">
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">2 days ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter red-bg s-sbold">
                          <span>S</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">just now</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }

                </div>

                <form>
                  <div className="input-group">
                    <div className="message-sec clearfix">
                      <div className="input-group">
                        <input type="text" className="form-control s-sbold ft-sz-22 font-colr pl-3" placeholder="@mention a user" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append icon-message-sec">
                          <a role="button" className="active" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                          <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                          <a role="button" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                          <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                        </div>
                      </div>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Comment</button>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Note</button>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Task</button>
                    </div>
                    <div className="message-sec note-input clearfix d-none">
                      <div className="input-group first">
                        <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Task title; @assignee" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append icon-message-sec">
                          <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                          <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                          <a role="button" className="active" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                          <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                        </div>
                      </div>
                      <div className="input-group second task-due-date">
                        {/* <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Due date" aria-label="Recipient's username" aria-describedby="basic-addon2"/> */}
                        <span className="s-reg ft-sz-22 font-colr">Due date <span className="icon-calendar"></span></span>
                      </div>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Task</button>
                    </div>
                    <div className="message-sec note-input clearfix d-none">
                      <div className="input-group first">
                        <input type="text" className="form-control s-reg ft-sz-32 font-colr" placeholder="Title" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append icon-message-sec">
                          <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                          <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                          <a role="button" className="" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                          <a role="button" className="active" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                        </div>
                      </div>
                      <div className="input-group second">
                        <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Start typing" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                      </div>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Note</button>
                    </div>
                  </div>
                </form>
              </div>

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i class="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={clog}
                  onHide={logClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="changelog-model-sec"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color"><span class="icon-round-info-button mr-4"></span> Change Log</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="clearfix">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Created</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Changed to Reimbursement</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Approved</td>
                        <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                      </tr>
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>

            </div>
          </div>
          </div>
         
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default invoice;