import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { getRotatedImage, getZoomImage } from '../../Utils/canvasUtils';
import Dropzone from "react-dropzone";
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import MasterService from '../../Services/MasterService';
import { toast } from 'react-toastify';
import StorageData from '../../Helpers/StorageData';
import pdficon from '../../Assets/img/pdficon.png'
import { SNAPSHOT_FILES_CODE } from '../../Helpers/Constants';
import { connect } from 'react-redux';
import { setAppilcationLoder } from "../../Redux/Actions/App";

import PDFViewer from 'mgr-pdf-viewer-react'


class ShowPdfPopup extends React.Component {
	file = {};
	constructor(props) {
		super(props)
		this.state = {
			file_url: this.props.file_url,
			warningMsg: "",
			show_crop_view: false,
			is_crop: false,
			crop: {
				unit: '%',
				width: 30,
			},
			loading: false,
			uploading_msg: "",
			zoom_percentage: 100,
			height: null,
			width: null,
			file_name: "",
			file_type: "",
			gcp_subpath: "",
			isFullScreen: false,
		}

		// Create reference of DOM object
		this.imgRef = React.createRef()
	}

	toggleImageFullView = () => {
		this.props.closePdfPopup();
	}

	render() {
		const imgStyle = { height: this.state.height, width: this.state.width };
		const editable = this.props.editable;
		//console.log(this.props.file_url)
		return (
			<div className={"smart-scan-popup " + (this.state.isFullScreen ? " fullscreen-img  " : " ")}>
				<div class="ssp-relative">
					<div onClick={() => this.toggleImageFullView()} className={"ss-cross  " + (this.props.closePermission ? "" : "")}>
						<span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
					</div>
					<div className="inner-smartview-sec h-100">
						{/* <PDFViewer
									document={{
										url: this.props.file_url,
									}}
								/> */}
						<embed src={this.props.file_url} width="100%" height="100%" type="application/pdf" />
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Snapshot

	return {
	};
}

export default connect(mapStateToProps)(withRouter(ShowPdfPopup));