import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';

function taskManager(){
  const [comment,setComment]=React.useState(false)
  const [task,setTask]=React.useState(false)
  const [note,setNote]=React.useState(false)
  const [privatemessage,setPmessage]=React.useState(false)
  const [sidfltr,setSidfltr]=React.useState(false)

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span class="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span class="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4408"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span class="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-lightbulb"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4402"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-5305"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span class="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-to-do-list"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-chat1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4403"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4404"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4405"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <BreadCrum level={2}/>
              {/* <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  Menu
                </li>
                <li className="breadcrumb-item">Task Manager</li> 
              </ol>*/}
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Task Manager <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
                  <span className="icon-Information green-icon green-icon-head"></span></OverlayTrigger></h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-date-sec">
                  <div className="clearfix">
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec">
                          <option value="1" disabled selected>Current Financial Year</option>
                          <option value="2">Last Financial Year</option>
                          <option value="3">This Month </option>
                          <option value="4">Last 3 Months</option>
                          <option value="5">Since Beginning</option>
                          <option value="6">Custom Date Range</option>
                        </select>             
                      </div>
                    </div>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <hr/>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">End Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <div className="ico-refresh-sec pull-right">
                      <button className="btn"><span class="icon-Group-3587"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></button>
                    </div>
                  </div> 
                </div>

                <div className="">
                  <div className="tab-inner-sec">
                    <div className="row">
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box active">
                          <p className="title ft-sz-29 m-0">Total</p>
                          <div className="count-char s-bold">47</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-29 m-0">Open </p>
                          <div className="count-char s-bold">25</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-29 m-0">Overdue</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-29 m-0">Done</p>
                          <div className="count-char s-bold">17</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-29 m-0">Assigned to me</p>
                          <div className="count-char s-bold">25</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-29 m-0">Assigned by me</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec">
                        <input type="text" placeholder="Search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter">
                      Filter <span className="icon-funnel"></span>
                      </div>
                      <button className="add-new-btn btn ft-sz-20 white-color s-bold"><span className="icon-plus1"></span> Add New Task</button>
                    </div>
                  </div>
                  <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Star</option>
                                <option>Yes</option>
                                <option>No</option>
                                <option>Both</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div> 
                        <div className="col-lg-5 col-xs-12">
                          <div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec">
                            <div className="col">
                            <p className="mt-2 ft-sz-17 s-sbold mb-0 text-center">Due Date</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" value="dd/mm/yy"/>
                                    <span class="icon-calendar"></span>
                                  </div>
                                </div>
                            </div>
                            <div className="col-lg-1">
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" value="dd/mm/yy"/>
                                    <span class="icon-calendar"></span>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Assigned By</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Assigned to</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>

                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Priority</option>
                                <option>High Priority</option>
                                <option>Normal Priority</option>
                                <option>All</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        
                      </div>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>

                  
                  {/* Invoice Table list */}
                  <div className="main-table-sec">
                  <div className="not-create-sec d-none"><p className="ft-sz-32 s-sbold blu-color m-0">No Evidences created</p></div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-124" type="checkbox" value="value124" />
                              <label for="styled-checkbox-124" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </th>
                          <th>Star</th>
                          <th>Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Assigned By <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Task <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Assigned to <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Due Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Priority <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Task Open</Tooltip>}>
                          <span class="icon-td icon-Group-3483 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr className="opacity">
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="gray-st-icon icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task Finished</Tooltip>}>
                          <span class="icon-td icon-Group-3487 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="gray-st-icon icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Task Overdue</Tooltip>}>
                          <span class="icon-td icon-Group-3488 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="gray-st-icon icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Task Overdue</Tooltip>}>
                          <span class="icon-td icon-Group-3488 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Task Overdue</Tooltip>}>
                          <span class="icon-td icon-Group-3488 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="gray-st-icon icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Task Overdue</Tooltip>}>
                          <span class="icon-td icon-Group-3488 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="check-box">
                              <input class="styled-checkbox" id="styled-checkbox-145" type="checkbox" value="value145" />
                              <label for="styled-checkbox-145" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                            </div>
                          </td>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Rakesh Sharma</td>
                          <td>Get signed copy of rent agreement</td>
                          <td>Raj Kumar</td>
                          <td>20-09-2020</td>
                          <td>
                            <span class="icon-td icon-Group-4175 ft-sz-38"><span class="path1"></span><span class="path2"></span></span>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Task Overdue</Tooltip>}>
                          <span class="icon-td icon-Group-3488 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        
                      </tbody>
                    </Table>
                  </div>

                  <div className="main-pagination clearfix">
                    <nav aria-label="Page navigation example">
                      <div class="pull-left"><button type="button" class="btn sms-delete-btn white-color fnt-gry-bg mr-4">Mark as Done </button></div>
                      <div class="pull-left"><button type="button" class="btn sms-delete-btn white-color fnt-gry-bg">Mark as Open </button></div>
                      <ul class="pagination pull-right mb-0">
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                        <li class="page-item item-count"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li>
                        <li class="page-item prev-sec"><a class="page-link ft-sz-20 s-reg" href="#"><span class="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li class="page-item active"><a class="page-link ft-sz-20 s-reg" href="#">1</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">3</a></li>
                        <li class="page-item next-sec"><a class="page-link ft-sz-20 s-reg" href="#">Next <span class="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
              <div className="clearfix">
                <BreadCrum level={2}/>
                {/* <ol className="breadcrumb pull-left">
                  <li className="breadcrumb-item">
                    Menu
                  </li>
                  <li className="breadcrumb-item">Evidence</li>
                </ol> */}
                <div className="pull-right d-flex new-add-filter-sec">
                  <div className="sidebar-evidence-filter" onClick={()=>setSidfltr(!sidfltr)}>
                  <span class="icon-filter"></span>
                  </div>
                  {/* <div className="add-new-evi-sms"> */}
                  <div className=" ">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
                    <span class="icon-plus"></span>
                    </OverlayTrigger>
                  </div>
                </div> 
              </div>
              <hr/>

                <div className="recipt-sidebar-sec">
                {
                sidfltr?<div className="main-evidence-sidefilter">
                    <div className="row">
                      <div className="col-12">
                          <div className="form-group">
                          <Form>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                              <option>Starred</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              </Form.Control>
                          </Form.Group>
                          </Form>
                          </div>
                      </div>
                    </div>    
                    <div className="row">
                      <div className="col-12">
                          <div className="form-group">
                          <Form>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                              <option>Custom Date Range</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              </Form.Control>
                          </Form.Group>
                          </Form>
                          </div>
                      </div>
                    </div>
                    <div className="row no-gutters">    
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <div className="start-end-date-sec btn">
                              <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                          </div>
                          </div>  
                      </div>
                      <div className="col">
                        <hr/>
                      </div>
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <div className="start-end-date-sec btn">
                              <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                          </div>
                          </div>
                      </div>
                    </div>  
                    <div className="row">    
                      <div className="col-12">
                          <div className="form-group">
                          <Form>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                              <option>Due Date</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              </Form.Control>
                          </Form.Group>
                          </Form>
                          </div>
                      </div>
                    </div>
                    <div className="row no-gutters">    
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <div className="start-end-date-sec btn">
                              <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                          </div>
                          </div>  
                      </div>
                      <div className="col">
                        <hr/>
                      </div>
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                          <div className="start-end-date-sec btn">
                              <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                          </div>
                          </div>
                      </div>
                    </div> 
                    <div className="row">    
                      <div className="col-12">
                        <div className="form-group">
                          <Form>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                              <option>Assigned to</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              </Form.Control>
                          </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div className="row">    
                      <div className="col-12">
                        <div className="form-group">
                          <Form>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                              <option>Assigned By</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              </Form.Control>
                          </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div className="row no-gutters">    
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control as="select">
                                <option>Status</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>  
                      </div>
                      <div className="col">
                      </div>
                      <div className="col-lg-5 col-sm-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control as="select">
                                <option>Priority</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-6 col-xs-12">
                          <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                          <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      </div>
                    </div>
                </div>:null
                }  

                <div className="main-applied-sidefilter d-none">
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Last Financial Year</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Due: 10-1-2020 - 15-1-2020</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Manoj Tiwari</span>
                    <span className="ft-sz-16 s-sbold blu-color">Rakesh Sharma</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Overdue</span>
                    <span className="ft-sz-16 s-sbold blu-color">High</span>
                  </div>
                </div>

                 <div className="recipt-inner-div task-manager-side-list empty-div active">
                    <div className=""></div>
                    <div className="row">
                     <p className="m-0 ft-sz-28 font-colr s-sbold">New Task</p>
                    </div>
                 </div>

                <div className="recipt-inner-div task-manager-side-list active">
                    <div className=""></div>
                    <div className="row">
                    <div className="col-xl-9 col-sm-12">
                        <p className="title s-sbold ft-sz-22"> Get signed copy of rent agreement </p>
                        <p className="m-0 head s-sbold">Raj Kumar</p>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-3488 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                        </div>
                        </div>
                        <div className="date s-sbold">
                        11-12-2020 
                        </div>
                    </div>
                    </div>
                </div>
                
                <div className="recipt-inner-div task-manager-side-list opacity">
                    <div className="row">
                    <div className="col-xl-9 col-sm-12">
                        <p className="title s-sbold ft-sz-22"> Initiate ISO compliance procedure </p>
                        <p className="m-0 head s-sbold">Raj Kumar</p>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-3182 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </div>
                        </div>
                        <div className="date s-sbold">
                        11-12-2020 
                        </div>
                    </div>
                    </div>
                </div>
                <div className="recipt-inner-div task-manager-side-list">
                    <div className="row">
                    <div className="col-xl-9 col-sm-12">
                        <p className="title s-sbold ft-sz-22"> Get signed copy of rent agreement </p>
                        <p className="m-0 head s-sbold">Raj Kumar</p>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-3182 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </div>
                        </div>
                        <div className="date s-sbold">
                        11-12-2020 
                        </div>
                    </div>
                    </div>
                </div>
                 <div className="recipt-inner-div task-manager-side-list">
                    <div className="row">
                    <div className="col-xl-9 col-sm-12">
                        <p className="title s-sbold ft-sz-22"> Get signed copy of rent agreement </p>
                        <p className="m-0 head s-sbold">Raj Kumar</p>
                    </div>
                    <div className="col-xl-3 col-sm-12">
                        <div className="evi-icon">
                        <div className="color-icon">
                        <span class="icon-Group-3483 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </div>
                        </div>
                        <div className="date s-sbold">
                        11-12-2020 
                        </div>
                    </div>
                    </div>
                 </div>
                </div>
                <div className="main-pagination clearfix sidebar-pagination">
                    <nav aria-label="Page navigation example">
                      {/* <div className="per-page-sec clearfix">
                        <ul class="pagination pull-right mb-0">
                          <li class="page-item"><a class="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                          <li class="page-item item-count"><a class="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        </ul>
                      </div> */}
                      <ul class="pagination pull-right mb-0">  
                        <li class="page-item prev-sec"><a class="page-link ft-sz-18 s-reg" href="#"><span class="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li class="page-item active"><a class="page-link ft-sz-18 s-reg" href="#">1</a></li>
                        <li class="page-item"><a class="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-18 s-reg" href="#">3</a></li>
                        <li class="page-item next-sec"><a class="page-link ft-sz-18 s-reg" href="#">Next <span class="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
              <div className="inner-content-sec">
              <p className="head m-0 s-sbold blu-color">Task Manager <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
              <span className="icon-Information green-icon help"></span></OverlayTrigger></p>
              <div className="clearfix"></div>
              <div className="main-file-item-sec">
                  <div className="cross" onClick={handleShow}>
                  <span>X</span>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 col-sm-12">
                     <div className="right-inner-content-sec">
                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Task Details</p>
                           <p className="pull-right"><span class="icon-log icon-round-info-button" onClick={logShow}></span> <lable className="id-date s-sbold ft-sz-18">11-2-2021</lable><lable className="id-no-text s-sbold m-0">ID #2154</lable></p>
                          </div> 
                          <div className="row">
                           <div className="col-lg-12 col-sm-12 right-form-sec">
                              <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                  <div className="form-group">
                                      <p className="lable-input font-colr">Task*</p>
                                      <textarea className="form-control" rows="3"></textarea>             
                                  </div>
                                </div> 
                              </div>  
                              <div className="row">
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group">
                                      <p className="lable-input font-colr">Assigned To*</p>
                                      <select className="form-control">
                                        <option value="1" disabled selected>Select your option</option>
                                        <option value="2">Manoj Tiwari</option>
                                        <option value="3">VW</option>
                                        <option value="4">Audi</option>
                                      </select>             
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group">
                                      <p className="lable-input font-colr">Assigned By</p>
                                      <input type="text" className="form-control input-bg-gray" value="Rakesh Sharma"/>             
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                   <div className="form-group date-sec">
                                    <p className="lable-input font-colr">Due Date</p>
                                    <div className="inner-date-sec">
                                      <input type="text" className="form-control" value="dd/mm/yy"/>
                                      <span class="icon-calendar"></span>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                  <div className="form-group high-priority-btn">
                                      <p className="lable-input font-colr"></p>
                                      <button className="btn s-bold blu-color form-control mt-4">
                                        <span class="icon-Group-4175 ft-sz-30">
                                          <span class="path1"></span><span class="path2"></span></span>
                                           <span className="text s-bold ft-sz-15">High Priority</span></button>             
                                  </div>
                                </div>
                              </div>
                           </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Task Reference</p>
                           <p className="ft-sz-14 red-color mt-2 mb-0 pull-right">No task reference available</p>
                          </div> 
                          <div className="">
                           <div className="col-lg-12 col-sm-12 right-form-sec">
                              <div className="row main-task-reference-table">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <td>
                                      <span className="ft-sz-13 s-sbold evi-chq-text">Evidence</span>
                                    </td>
                                    <td><span class="s-sbold m-0 ft-sz-18">11-01-2020</span></td>
                                    <td><span class="id-no-text s-sbold m-0 ft-sz-15">ID #2154</span></td>
                                    <td><span class="s-sbold m-0 ft-sz-18">Narayan Plaza</span></td>
                                    <td><span class="s-sbold m-0 ft-sz-18">  ₹1,850 <span class="icon-Green-arrow"></span> <span class="icon-Red-arrow"></span></span></td>
                                    <td>
                                      <span class="icon-td icon-Group-2508 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                    </td>
                                    <td>
                                      <button type="button" className="btn view-ref-btn ft-sz-15 s-sbold white-color"><span class="icon-view-details"></span> View Reference</button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              </div>  
                           </div>
                          </div>
                        </div>

                      </div>
                  </div>
                  </div>
              </div>
              <div className="bottom-save-btn clearfix">
                <div className="pull-left">
                  <button type="button" className="btn save-btn white-color graybtn">Delete </button>
                </div>
                <div className="pull-right">
                  <div className="ready-btn">
                      <select>
                      <option value="audi" selected>Mark as done</option>
                      <option value="volvo">Mark as open</option>
                      </select> 
                  </div>  
                  <button type="button" className="btn save-btn greenbtn">Save</button>
                </div>
              </div>
              <div className="bottom-message-sec">
                <div className="main-bottom-comments-note-sec">
                  {
                  comment?<div className="bottom-comments-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-chat"></span></span>
                      <span className="ft-sz-32 s-sbold">Messages</span>
                    </div>
                    <div className="inner-comment-scroll">
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="gren-color">Shubham Gupta</span>  please check the image for this evidence</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">2 days ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter green-bg s-sbold">
                          <span>S</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">just now</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }

                {
                  note?<div className="bottom-note-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-Group-2546"></span></span>
                      <span className="ft-sz-32 s-sbold">Notes</span>
                    </div>
                    <div className="inner-note-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="first-letter yellow-bg s-sbold">
                        <span>Y</span>
                      </div>
                      <div className="note-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                      </div>
                    </div>
                    <div className="inner-note-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="first-letter yellow-bg s-sbold">
                        <span>Y</span>
                      </div>
                      <div className="note-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                      </div>
                    </div>
                  </div>:null
                }  

                {
                  task?<div className="bottom-task-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-Group-2516"></span></span>
                      <span className="ft-sz-32 s-sbold">Tasks</span>
                    </div>
                    <div className="inner-task-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="check-box">
                        <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                        <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                      </div>
                      <div className="task-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                        <div className="day-reply-sec">
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          <p className="ft-sz-18 s-reg red-bg white-color color-div">Overdue</p>
                        </div>
                      </div>
                    </div>
                    <div className="inner-task-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="check-box">
                        <input className="styled-checkbox" id="styled-checkbox-24" type="checkbox" value="value011" />
                        <label for="styled-checkbox-24" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                      </div>
                      <div className="task-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                        <div className="day-reply-sec">
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          <p className="ft-sz-18 s-reg yellow-bg white-color color-div">Due in 2 days</p>
                        </div>
                      </div>
                    </div>
                    <div className="inner-task-sec clearfix">
                      <div className="cross"><span>X</span></div>
                      <div className="check-box">
                        <input className="styled-checkbox" id="styled-checkbox-142" type="checkbox" value="value011" />
                        <label for="styled-checkbox-142" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                      </div>
                      <div className="task-text">
                        <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                        <div className="day-reply-sec">
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                          <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }  


                {
                  privatemessage?<div className="bottom-comments-sec">
                    <div className="head-sec">
                      <span className="ico-div"><span className="icon-chat"></span></span>
                      <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                    </div>
                    <div className="inner-comment-scroll">
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">2 days ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter red-bg s-sbold">
                          <span>S</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                          <span>A</span>
                        </div>
                        <div className="comment-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-16 s-sbold">just now</p>
                            <p className="ft-sz-16 s-sbold">Reply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>:null
                }

                </div>

                <form>
                  <div className="input-group">
                    <div className="message-sec clearfix">
                      <div className="input-group">
                        <input type="text" className="form-control s-sbold ft-sz-22 font-colr pl-3" placeholder="@mention a user" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append icon-message-sec">
                          <a role="button" className="active" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                          <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                          <a role="button" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                          <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                        </div>
                      </div>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Comment</button>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Note</button>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Task</button>
                    </div>
                    <div className="message-sec note-input clearfix d-none">
                      <div className="input-group first">
                        <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Task title; @assignee" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append icon-message-sec">
                          <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                          <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                          <a role="button" className="active" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                          <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                        </div>
                      </div>
                      <div className="input-group second task-due-date">
                        {/* <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Due date" aria-label="Recipient's username" aria-describedby="basic-addon2"/> */}
                        <span className="s-reg ft-sz-22 font-colr">Due date <span className="icon-calendar"></span></span>
                      </div>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Task</button>
                    </div>
                    <div className="message-sec note-input clearfix d-none">
                      <div className="input-group first">
                        <input type="text" className="form-control s-reg ft-sz-32 font-colr" placeholder="Title" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        <div className="input-group-append icon-message-sec">
                          <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                          <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                          <a role="button" className="" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                          <a role="button" className="active" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                        </div>
                      </div>
                      <div className="input-group second">
                        <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Start typing" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                      </div>
                      <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Note</button>
                    </div>
                  </div>
                </form>
              </div>

              <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i class="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={clog}
                  onHide={logClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="changelog-model-sec"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color"><span class="icon-round-info-button"></span> Change Log</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="clearfix">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Created</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Changed to Reimbursement</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Approved</td>
                        <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                      </tr>
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>

            </div>
          </div>
          </div>
         
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default taskManager;