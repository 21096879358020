import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
  Link,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import { connect } from "react-redux";
import AccountDetail from '../../../Components/ProfileManagement/AccountDetail';
import CreateEntity from '../../../Components/ProfileManagement/CreateEntity';
import EntityList from '../../../Components/ProfileManagement/EntityList';

// import CreateUserView from '../UserManagement/CreateUserView';
import { toast } from 'react-toastify';

import CreateUserViewNew from '../User/CreateUserView'
import UserList from '../User/UserList';
import UserService from '../../../Services/Users/UserService';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import PendingInviteEntityList from '../../../Components/ProfileManagement/PendingInviteEntityList';
import EntityService from '../../../Services/ProfileManagement/EntityService';
import SessionManagement from '../../../Utils/SessionManagement';
import { confirm } from '../../../Components/BoilerPlate/Confirm';
import AddMultipleEntity from '../../../Components/ProfileManagement/CA/AddMultipleEntity';
import SponserEntityComponent from '../../../Components/ProfileManagement/CA/SponserEntityComponent';
import BreadCrum from '../../../Components/BoilerPlate/BreadCrum';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';

// import Tabs from 'react-bootstrap/Tabs'
// import Tab from 'react-bootstrap/Tab'; 

import StorageData from '../../../Helpers/StorageData';
import { setDefaultEntity, setEntityList } from '../../../Redux/Actions/Session';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ScrollPage from '../../../Components/BoilerPlate/ScrollPage';

// // Intro js user guid
// import { render } from "react-dom";
// import { Steps } from "intro.js-react";
// // End Intro js user guid 

class EntityListing extends Component {

  constructor(props) {
    super(props);
    this.USER_DETAIL = SessionManagement.getUserDetail();
    this.ACCOUNT_TYPE = (SessionManagement.getUserDetail()).account_type;
    this.state = {
      tab: '',
      users: [],
      entity_list: [],
      pending_entity_list: [],
      show_multiple_entity: false,
      show_sponser_entity: false,

      entityList: [],
      deleted_entities: [],
      hashtag_list: [],
      expire_filter: "",
      paid_by_me: 0,
      paid_by_others: 0,
      total: 0,
      show_accounting_rights_popup: false,
      has_accounting_rights: 0,
    }
    this.sponsorBtnRef = React.createRef();
  }

  toggleMultipleEntity = (show_multiple_entity) => {
    window.scrollTo({ behavior: 'smooth', top: 700 });
    this.setState({
      show_multiple_entity: show_multiple_entity
    })
  }

  toggleSponserEntity = (show_sponser_entity) => {
    // alert(this.sponsorBtnRef.current.offsetTop);
    // this.sponsorBtnRef.current.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"});
    // window.scrollTo({ behavior: 'smooth', top: this.sponsorBtnRef.current.offsetTop });
    window.scrollTo({ behavior: 'smooth', top: 700 });
    // console.log(this.sponsorBtnRef.current.offsetTop);
    // window.scrollTo(0, 70);

    this.setState({
      show_sponser_entity: show_sponser_entity
    })
  }

  tabSelected(route) {
    this.props.history.push(route)
    // this.setState({ tab: tab })
  }

  updateUser = (user, user_id) => {
    let user_index = this.state.users.findIndex(u => u.id == user_id);
    let users = this.state.users;
    users[user_index] = user;
  }

  newUserAdded = (new_user) => {
    this.setState({ users: [new_user, ...this.state.users] })
  }

  deleteUser = (user_id) => {
    let user_index = this.state.users.findIndex(u => u.id == user_id);
    let users = this.state.users;
    users.splice(user_index, 1);
    this.setState({ users });
  }

  addUsernameInEntity = (user_data) => {
    let entityList = this.state.entityList;
    for (let i = 0; i <= entityList.length - 1; i++) {
      if (user_data.entity_ids.includes(entityList[i].id)) {
        if (!entityList[i].users.includes(user_data.full_name))
          entityList[i].users.push(user_data.full_name);
      } else {
        const index = entityList[i].users.indexOf(user_data.full_name);
        if (index > -1) {
          entityList[i].users.splice(index, 1);
        }
      }
    }
    this.setState({ entityList: [] }, () => {
      this.setState({ entityList });
    })
  }

  refreshAllList = async () => {
    this.props.dispatch(setAppilcationLoder(true));
    if (this.props.account_permissions.includes(21)) {
      await this.getUserList();
    }
    await this.getPendingInviteEntities();
    await this.getEntityList();
    this.props.dispatch(setAppilcationLoder(false));
  }

  getEntityList = () => {
    this.props.dispatch(setAppilcationLoder(true));
    EntityService.getEntityList().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            entityList: data.result.entities,
            deleted_entities: data.result.deleted_entities,
            paid_by_me: data.result.paid_by_me,
            paid_by_others: data.result.paid_by_others,
            total: data.result.total,
            filter_data: data.result.filter,
            hashtag_list: data.result.filter.hashtags || [],
          });
        } else if (data.response_code == 400) {
          toast.error(data.message);
        }//else if(data.response_code == 403){
        //   toast.error(data.message);
        // }
        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  getUserList = () => {
    this.props.dispatch(setAppilcationLoder(true));
    UserService.getUserList().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            users: data.result.users
          });

          let user = data.result.users.find(user => user.is_owner == 1);
          if (user != undefined) {
            this.setState({ has_accounting_rights: user.has_accounting_rights || 0 })
          }

        } else if (data.response_code == 400) {
          toast.error(data.message);
        }

        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  getPendingInviteEntities = () => {
    this.props.dispatch(setAppilcationLoder(true));
    EntityService.getPendingInviteEntities().then(
      async (data) => {
        this.props.dispatch(setAppilcationLoder(false));
        if (data.response_code == 200) {
          this.setState({
            pending_entity_list: data.result.invites
          });
          if (!!!data.result.invites.length && !!!this.props.acl_entities.length && this.USER_DETAIL.is_owner == 1) {
            if (await confirm({
              confirmation: 'To use buktec platform, Please create Entity first.',
              options: { type: "alert" }
            })) {
              this.props.history.push("/profile-management/entities/create/")
            }
          } else if (!!data.result.invites.length && !!!this.props.acl_entities.length && this.USER_DETAIL.is_owner == 1) {
            if (data.result.invites.length == 1) {
              this.tabSelected("/profile-management/pending/" + data.result.invites[0].id)
              // this.props.history.push("/profile-management/entities/pending/"+data.result.invites[0].id)
            } else {
              this.props.history.push("/profile-management/pending/")
            }
          }
        }
      });
  }

  acceptPendingEntity = (entity_id) => {
    this.removePendingEntity(entity_id);
  }


  removePendingEntity = (entity_id) => {
    let pending_entity_list = this.state.pending_entity_list.filter(entity => entity.id != entity_id);
    this.setState({
      pending_entity_list
    })
  }

  removeEntityFromList = (entity_id) => {
    let entityList = this.state.entityList;
    let deleted_entities = this.state.deleted_entities;
    const index = entityList.findIndex(entity => entity.id == entity_id);
    if (index > -1) {
      deleted_entities.push({ ...entityList[index], status: 4, plan_name: "", paid_by: "", end_date: "" });
      entityList.splice(index, 1);
      this.setState({
        deleted_entities: [],
        entityList: [],
      }, () => {
        this.setState({
          deleted_entities,
          entityList
        });
      })
    }

  }

  restoreEntityInList = (updated_entity) => {
    let entityList = this.state.entityList;
    let deleted_entities = this.state.deleted_entities;
    const index = deleted_entities.findIndex(entity => entity.id == updated_entity.id);
    if (index > -1) {
      updated_entity.status = 3;
      deleted_entities.push({ ...entityList[index], ...updated_entity });
      deleted_entities.splice(index, 1);
      this.setState({
        deleted_entities: [],
        entityList: [],
      }, () => {
        this.setState({
          deleted_entities,
          entityList
        });
      })
    }

  }

  removeCAEntityFromList = (entity_id) => {
    let entityList = this.state.entityList.filter(entity => entity.id != entity_id);
    this.setState({
      entityList
    })
  }

  resendInvite = (entity_id) => {
    let pending_entity_list = this.state.pending_entity_list;
    let pending_entity_index = pending_entity_list.findIndex(entity => entity.id == entity_id);
    pending_entity_list[pending_entity_index].status = 1;
    this.setState({
      pending_entity_list
    })
  }

  updateInvitedEntity = (entity) => {
    let pending_entity_list = this.state.pending_entity_list;
    let pending_entity_index = pending_entity_list.findIndex(list_entity => list_entity.id == entity.id);
    pending_entity_list[pending_entity_index] = entity;
    this.setState({
      pending_entity_list
    })
  }
  updateEntityInList = (entity) => {
    let entityList = this.state.entityList;
    let pending_entity_index = entityList.findIndex(list_entity => list_entity.id == entity.id);
    // Ram ------> Below condition is added as it was giving error set property of undefined
    if (pending_entity_index != -1) {
      entityList[pending_entity_index].end_date = entity.end_date;
      entityList[pending_entity_index].name = entity.name;
      entityList[pending_entity_index].paid_by = entity.paid_by;
      entityList[pending_entity_index].paid_by_other = entity.paid_by_other;
      entityList[pending_entity_index].plan_name = entity.plan_name;
      entityList[pending_entity_index].status = entity.status;

      if (!!entity.type)
        entityList[pending_entity_index].type = entity.type;
    } else {
      entityList.push({
        end_date: entity.end_date,
        name: entity.name,
        paid_by: entity.paid_by,
        paid_by_other: entity.paid_by_other,
        plan_name: entity.plan_name,
        status: entity.status
      })
    }
    this.setState({
      entityList
    });

    //update enter header detail
    this.updateHeaderDetail(entity);
  }

  updateHeaderDetail = (entity) => {
    let entity_detail = StorageData.getDefaultEntity();
    if (entity_detail.id == entity.id) {
      StorageData.setDefaultEntity(entity);
      this.props.dispatch(setDefaultEntity(entity));
    }

    //change in entity listing
    let entityList = this.props.acl_entities;
    let pending_entity_index = entityList.findIndex(list_entity => list_entity.id == entity.id);
    if (entity.status == 1) {
      entity.status = 3;
    } else if (entity.status == 3) {
      entity.status = 1;
    }
    entityList[pending_entity_index] = entity;
    this.props.dispatch(setEntityList([]));
    this.props.dispatch(setEntityList(entityList));
  }

  addEntityInList = (entity) => {
    this.setState({ entityList: [entity, ...this.state.entityList] })

    //change in entity listing
    let entityList = this.props.acl_entities;
    if (entity.status == 1) {
      entity.status = 1;
    } else if (entity.status == 3) {
      entity.status = 1;
    }
    entityList = [entity, ...entityList];
    this.props.dispatch(setEntityList([]));
    this.props.dispatch(setEntityList(entityList));
  }

  addPendingEntity = (entity) => {
    this.setState({ pending_entity_list: [entity, ...this.state.pending_entity_list] })
  }

  goToLicensePage = () => {
    SessionManagement.setLastUrl(window.location.pathname)
    this.props.history.push("/licenses")
  }

  componentDidMount() {
    //console.log(this.USER_DETAIL)
    if (this.USER_DETAIL.is_owner == 1 && this.USER_DETAIL.account_type == 1) {
      document.getElementById("uncontrolled-tab-example-tab-/profile-management/user").onclick = (e) => {
        if (e.detail == 3) {
          let user = this.state.users.find(user => user.is_owner == 1);
          if (user != undefined) {
            this.setState({ has_accounting_rights: user.has_accounting_rights || 0 })
          }
          this.setState({ show_accounting_rights_popup: true })
        }
      }
    }
    this.refreshAllList();
  }

  updateAccountingRights = () => {

    this.props.dispatch(setAppilcationLoder(true));

    let detail = {
      has_accounting_rights: this.state.has_accounting_rights ? 1 : 0
    }

    UserService.updateAccountingRights(detail).then(
      (data) => {
        this.setState({ show_accounting_rights_popup: false })
        if (data.response_code == 200) {

          toast.success(data.message);
          let user = this.state.users.find(user => user.is_owner == 1);
          if (user != undefined) {
            user.has_accounting_rights = detail.has_accounting_rights;
          }
          // setTimeout(() => {
          //   window.location.reload(true); 
          // }, 2000);
        } else if (data.response_code == 400) {
          toast.error(data.message);
        }

        this.props.dispatch(setAppilcationLoder(false));
      });
  }

  render() {

    const MANAGE_ENTITY_PERMISSION = this.props.account_permissions.includes(19);
    const { location } = this.props;
    const path_array = location.pathname.split('/');
    let profile_path = path_array.splice(0, 3).join('/'); //path_array.length > 3 ? path_array.splice(0,3).join('/') : "/profile-management/entities";
    if (profile_path == "/profile-management/" || profile_path == "/profile-management") {
      profile_path = "/profile-management/entities";
    }

    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">
          <ScrollPage />

          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <BreadCrum level={3} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="breadcrumb-title-new">Profile Management</h2>
                    </div>
                    <div className="col-4">
                      <span className="breadcrumb-title-new sub-header-cicon">
                        {this.props.account_permissions.includes(20) &&
                          <Button variant="success apply_btn_new" onClick={this.goToLicensePage}>License {'&'} Billing</Button>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

          {/* Account Detail */}
          <AccountDetail />
          {/* End Account Detail */}

          {/* Tabs */}
          <div className="form-inner-blue-new">
            <div className="row">
              <div className="col-12 position-relative">
                {
                  (location.pathname == '/profile-management/user') &&
                  <React.Fragment>
                    <div className="tabs-with-btns">
                      <Link to="/profile-management/user/create" className="btn btn-success apply_btn_new"><span className="icon-plus1 pm-p-icon"></span> User</Link>

                      <button class="btn ico-refresh-sec blink-green-btn" onClick={() => this.refreshAllList()}><span className="icon-refresh"></span></button>
                    </div>
                  </React.Fragment>
                }

                {
                  (['/profile-management/entities', '/profile-management/entities/', '/profile-management/pending', '/profile-management/pending/'].includes(location.pathname)) &&
                  <div className="tabs-with-btns">
                    <React.Fragment>
                      {MANAGE_ENTITY_PERMISSION &&
                        <Link to="/profile-management/entities/create" className="btn btn-success apply_btn_new"><span className="icon-plus1 pm-p-icon"></span> Single Entity</Link>
                      }

                      {(this.ACCOUNT_TYPE == 2) &&
                        <React.Fragment>
                          <div ref={this.sponsorBtnRef}>
                            <Button type="submit" variant="success apply_btn_new" onClick={() => this.toggleMultipleEntity(!this.state.show_multiple_entity)}>
                              <span className="icon-plus1 icon-plus1 pm-p-icon"></span> Multiple Entities
                            </Button>
                            {this.state.show_multiple_entity && <AddMultipleEntity refreshInviteList={this.refreshAllList} toggleMultipleEntity={this.toggleMultipleEntity} />}
                          </div>

                          <div ref={this.sponsorBtnRef}>
                            <Button type="submit" variant="success apply_btn_new" onClick={() => this.toggleSponserEntity(!this.state.show_sponser_entity)}>
                              <span className="icon-plus1 icon-plus1 pm-p-icon"></span> Sponsor Entity
                            </Button>
                            {this.state.show_sponser_entity ? <SponserEntityComponent toggleSponserEntity={this.toggleSponserEntity} /> : ""}
                          </div>
                        </React.Fragment>
                      }

                      <button class="btn ico-refresh-sec blink-green-btn" onClick={() => this.refreshAllList()}><span className="icon-refresh"></span></button>
                    </React.Fragment>
                  </div>
                }

                <div className="tabsDesign">
                  <Tabs id="uncontrolled-tab-example" activeKey={profile_path} onSelect={route => this.tabSelected(route)}>
                    <Tab eventKey={"/profile-management/entities"} title="Entities">
                      <Switch>
                        <Route path="/profile-management/entities/create/:entity_id?"
                          render={() => {
                            return <CreateEntity
                              addPendingEntity={this.addPendingEntity}
                              updateInvitedEntity={this.updateInvitedEntity}
                              updateEntityInList={this.updateEntityInList}
                              addEntityInList={this.addEntityInList}
                              removeCAEntityFromList={this.removeCAEntityFromList}
                              removeEntityFromList={this.removeEntityFromList}
                              restoreEntityInList={this.restoreEntityInList}
                            />
                          }
                          } exact />

                        <Route path="/profile-management/entities"
                          render={() => {
                            return <EntityList
                              addPendingEntity={this.addPendingEntity}
                              updateInvitedEntity={this.updateInvitedEntity}
                              entityList={this.state.entityList}
                              deleted_entities={this.state.deleted_entities}
                              hashtag_list={this.state.hashtag_list}
                              filter_data={this.state.filter_data}
                              paid_by_me={this.state.paid_by_me}
                              paid_by_others={this.state.paid_by_others}
                              total={this.state.total}
                            />
                          }
                          } exact />

                        <Route path="/profile-management/"
                          render={() => {
                            return <EntityList
                              addPendingEntity={this.addPendingEntity}
                              updateInvitedEntity={this.updateInvitedEntity}
                              entityList={this.state.entityList}
                              deleted_entities={this.state.deleted_entities}
                              hashtag_list={this.state.hashtag_list}
                              filter_data={this.state.filter_data}
                              paid_by_me={this.state.paid_by_me}
                              paid_by_others={this.state.paid_by_others}
                              total={this.state.total}
                            />
                          }
                          } exact />
                      </Switch>
                    </Tab>

                    {
                      (this.props.account_permissions.includes(19) && ((this.ACCOUNT_TYPE == 1 && !!this.state.pending_entity_list.length) || this.ACCOUNT_TYPE == 2)) &&
                      <Tab eventKey={"/profile-management/pending"} title={"Invites " + (!!this.state.pending_entity_list.length ? `(${this.state.pending_entity_list.length})` : "")}>
                        <Switch>
                          <Route path="/profile-management/pending/:entity_id"
                            render={() => {
                              return <CreateEntity
                                pending_entity_count={this.state.pending_entity_list.length}
                                removePendingEntity={this.removePendingEntity}
                                acceptPendingEntity={this.acceptPendingEntity}
                                updateInvitedEntity={this.updateInvitedEntity}
                                resendInvite={this.resendInvite}
                                updateEntityInList={this.updateEntityInList}
                                addEntityInList={this.addEntityInList}
                                removeCAEntityFromList={this.removeCAEntityFromList}
                                removeEntityFromList={this.removeEntityFromList}
                                restoreEntityInList={this.restoreEntityInList}
                              />
                            }
                            } exact />

                          <Route path="/profile-management/pending"
                            render={() => {
                              return <PendingInviteEntityList
                                entity_list={this.state.pending_entity_list}
                                getPendingInviteEntities={this.getPendingInviteEntities}
                              />
                            }
                            } exact />
                        </Switch>
                      </Tab>
                    }

                    {this.props.account_permissions.includes(21) &&
                      <Tab eventKey="/profile-management/user" title="Users">
                        <Switch>
                          <Route path="/profile-management/user/create/:user_id?"
                            render={() => {
                              return <CreateUserViewNew
                                newUserAdded={this.newUserAdded}
                                deleteUser={this.deleteUser}
                                updateUser={this.updateUser}
                                addUsernameInEntity={this.addUsernameInEntity}
                              />
                            }
                            }
                            exact />
                          <Route path="/profile-management/user" exact
                            render={() => {
                              return <UserList
                                users={this.state.users}
                                getUserList={this.getUserList}
                                updateAccountingRights={this.updateAccountingRights}
                              />
                            }
                            } />
                        </Switch>
                      </Tab>
                    }
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {/* End Tabs */}

          {/* Accounting Rights Popup */}
          <Modal
            show={this.state.show_accounting_rights_popup}
            // show={true}
            // size="lg"
            onHide={() => this.setState({ show_accounting_rights_popup: false })}
            backdrop="static"
            keyboard={false}
            centered
            className="popup-model pm-master-user-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>Accounting Rights</Modal.Title>
            </Modal.Header>

            <Modal.Body className="clearfix pt-2 pb-2">
              <div className="row">
                <div className="col-sm-12">
                  <p className="pmsg-text">Master user details cannot be edited. But you can set the below permissions:</p>
                </div>

                <div className="col-sm-12">
                  <div className="toggle-switch-btn toggle-btn-bordered">
                    <span className="toggle-btn-sec-text mr-4">Accounting Rights</span>                    
                    <span className="toggle-btn-sec-text">No</span>
                    <label className="switch">
                      <input type="checkbox" onChange={() => this.setState({ has_accounting_rights: !this.state.has_accounting_rights })} checked={this.state.has_accounting_rights} />
                      <span className="slider round"></span>
                    </label>
                    <span className="toggle-btn-sec-text">Yes</span>
                  </div>
                </div>

                <div className="col-sm-12 mt-1">
                  <div className="reset_apply_btn_new">
                    <Button type="button" variant="outline-success reset_btn_new" onClick={() => { this.setState({ show_accounting_rights_popup: false }) }}>Cancel</Button>
                    <Button type="button" variant="success apply_btn_new" onClick={(e) => { this.setState({ show_accounting_rights_popup: false }); this.updateAccountingRights(this.state.has_accounting_rights) }}>Update</Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* End Accounting Rights Popup */}
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  const all_value = state.Session || {};
  const module_permissions = all_value.module_permissions || [];
  const account_permissions = all_value.account_permissions || [];
  const acl_entities = all_value.entity_list || [];
  return {
    module_permissions, account_permissions, acl_entities
  };
}

export default connect(mapStateToProps)(EntityListing);