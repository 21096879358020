import React from 'react';
import Card from "react-bootstrap/Card";;
import Accordion from 'react-bootstrap/Accordion';
import EntityService from '../../../Services/ProfileManagement/EntityService';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { confirm } from '../../BoilerPlate/Confirm';
import { Modal, Button } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class AddMultipleEntity extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			license_list: [],
			unused_codes: [],
			license_id: 0,
			errors: {},
			generated_code: "",
			user_name: "",
			copySuccess: false,
			lic_list_index: "",
			lic_index: ","
		};
	}

	getMultipleLicenses = () => {
		this.props.dispatch(setAppilcationLoder(true));
		EntityService.getMultipleLicenses().then(
			(data) => {
				if (data.response_code == 200) {
					this.setState({
						license_list: data.result.available_licenses,
					});
				}
				this.props.dispatch(setAppilcationLoder(false));
			});
	}

	getUnusedCodes = () => {
		EntityService.getUnusedCodes().then(
			(data) => {
				if (data.response_code == 200) {
					this.setState({
						unused_codes: data.result.codes,
					});
				}
			});
	}

	copyToClipboard = (generated_code) => {
		var textField = document.createElement('textarea')
		textField.innerText = generated_code;
		document.body.appendChild(textField)
		textField.select()
		document.execCommand('copy')
		textField.remove();

		this.setState({ copySuccess: true });
		setTimeout(() => {
			this.setState({ copySuccess: false });
		}, 3000);
	};

	createCode = () => {
		let data = {
			"id": this.state.license_id,
			"name": this.state.user_name
		}
		this.props.dispatch(setAppilcationLoder(true));
		EntityService.createCode(data).then(
			(data) => {
				if (data.response_code == 200) {
					toast.success(data.message);

					let license_list = this.state.license_list;
					let license = license_list[this.state.lic_list_index];
					license.licenses = license.licenses.filter(item => item.id != this.state.license_id);
					license_list[this.state.lic_list_index] = license;
					this.setState({
						generated_code: data.result.otp,
						license_id: 0,
						user_name: "",
						errors: {},
						license_list: []
					},
						() => { this.setState({ license_list: license_list }) }
					);

					let unused_codes = this.state.unused_codes;
					unused_codes.push(data.result);
					this.setState({ unused_codes: unused_codes })

				} else {
					toast.error(data.message);
				}
				this.props.dispatch(setAppilcationLoder(false));
			});
	}

	deleteOTPCode = async (code, index) => {
		if (!await confirm({
			confirmation: "This license is may not consumed by any business, still you want to remove it?"
		})) {
			return true;
		}
		this.props.dispatch(setAppilcationLoder(true));
		EntityService.deleteOTPCode(code.id).then(
			(data) => {
				if (data.response_code == 200) {
					let unused_codes = this.state.unused_codes;
					unused_codes.splice(index, 1);
					this.setState({ unused_codes: unused_codes })
					this.getMultipleLicenses();
				}
				else if (data.response_code == 404) {
					let unused_codes = this.state.unused_codes;
					unused_codes.splice(index, 1);
					this.setState({ unused_codes: unused_codes })
					this.getMultipleLicenses();
				}
				else {
					toast.error(data.message);
				}
				this.props.dispatch(setAppilcationLoder(false));
			});
	}

	handleOnChange = (e) => {
		const { value, name } = e.target
		this.setState({ [name]: value })
	}

	licenseSelected = (e, lic_list_index, lic_index) => {
		this.setState({
			license_id: e.target.value,
			lic_list_index,
			lic_index
		})
	}

	componentDidMount() {
		this.getMultipleLicenses();
		this.getUnusedCodes();
	}

	render() {
		return <>
			<div class="fade modal-backdrop show" style={{ zIndex: 1050 }}></div>

			<div className="multiple-entity-popup">
				<Modal.Body>
					<div className="row">
						<div className='col-10'>
							<h3 className=''>Sponsor Entity</h3>
						</div>
						<div className='col-2'>
							<h3 className='pmep-crose-icon'><span class="icon-plus1 gray-icon" onClick={() => this.props.toggleSponserEntity(false)}></span></h3>
						</div>
						<div className='col-12'>
							<p className="mb-0">To sponsor an Entity to your account, create authorization code and share it with the Business</p>
						</div>
					</div>

					{/* Licenses list */}
					<div className="row mt-3">
						<div className='col-12'>
							<h5 className="gray-text">Select License</h5>

							{/* Accordion list */}
							<div className="multipleEntityAccordion mt-1">
								<Accordion>
									{
										this.state.license_list.map((licence, lic_list_index) => {
											return <Card key={lic_list_index}>
												<Accordion.Toggle as={Card.Header} eventKey={lic_list_index + 1} >
													<span className="label-accordion">{licence.name}</span>
													<div className="iconbar-accordion">
														<span className="license-qty">Quantity</span>
														<span className="license-qty-count">{licence.licenses.length}</span>
														<span className="icon-arrow-down-sign-to-navigate down-arrow"></span>
													</div>
												</Accordion.Toggle>
												<Accordion.Collapse eventKey={lic_list_index + 1}>
													<Card.Body class="card-body">
														{licence.licenses.map((lic, lic_index) => {
															return <div className="licenses-list" key={lic_index}>
																<p className="license-name mb-0">{lic.license_name + "-" + licence.name}</p>

																<p className="licenseExpire mb-0">
																	<span className="license-expires">Expires On </span>
																	<span className="license-date">{lic.end_date}</span>
																</p>

																<div className="sup-cust-check btn-bar-save-text">
																	<input className="styled-checkbox" id={"styled-checkbox-" + lic_list_index + lic_index} name="radio" type="radio" value={lic.id} onClick={(e) => this.licenseSelected(e, lic_list_index, lic_index)} />
																	<label for={"styled-checkbox-" + lic_list_index + lic_index}></label>
																</div>
															</div>
														})}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										})
									}
								</Accordion>
							</div>
							{/* End Accordion list */}
						</div>

						<div className='col-12 mt-3'>
							{!!this.state.unused_codes.length && <h5 className="gray-text mb-0">Unaccepted Codes</h5>}
						</div>
					</div>
					{/* Licenses list */}

					{/* Sponsor Entities Card */}
					{
						this.state.unused_codes.map((code, index) => {
							return <div className="mb-3">
								<div className="sponsor-entity-cards" key={index}>
									<span class="ss-cross-md" onClick={() => this.deleteOTPCode(code, index)}>
										<span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
									</span>
									<div className="row">
										<div class="col-7">
											<div class="pmesc-name-mail">
												<p class="pmesc-name">{code.plan_name}</p>
												<p class="pmesc-mail">{code.name}</p>
												{/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Smart</Tooltip>}>
												<p class="pmec-name d-inline">Smart</p>
											</OverlayTrigger> */}
											</div>
										</div>
										<div class="col-5">
											<p class="pmesc-code">{code.otp}</p>
										</div>
									</div>
									<div className="pmesc-copy-btn">
										<button type="button" onClick={() => this.copyToClipboard(code.otp)} className="pmesc-copybtn">Copy</button>
									</div>
								</div>
							</div>
						})
					}
					{/* End Sponsor Entities Card */}

					{/* Save btn*/}
					<div className="row">
						<div className="col-12 form-group">
							<input type="text" className="form-control" placeholder="Name" value={this.state.user_name} name="user_name" onChange={this.handleOnChange} />
						</div>
						<div className="col-lg-12 d-flex justify-content-end align-items-end">
							<Button type="button" variant="success apply_btn_new" onClick={this.createCode} disabled={!(!!this.state.license_id && !!this.state.user_name)}>Create Code</Button>
						</div>
					</div>
					{/* End Save btn*/}
				</Modal.Body>

				{this.state.copySuccess && <div className="pmesc-copy-success">
					<span class="icon-personal-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
					<span className="pmesc-copied-text">Copied Successfully</span>
				</div>}
			</div>
		</>
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const module_permissions = all_value.module_permissions || [];
	const account_permissions = all_value.account_permissions || [];
	const entity_list = all_value.entity_list || [];
	return {
		module_permissions, account_permissions, entity_list
	};
}

export default connect(mapStateToProps)(AddMultipleEntity);