import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo from '../../Assets/img/logo.png';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import recipt from '../../Assets/img/recipt.png';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from 'react-router-dom';

function docbox(){
  const [smShow, setSmShow] =React.useState(false);

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold m-0 font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li className="">
                  <Dropdown>
                  <img src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>


      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Docbox</li>
              </ol>
              <div className="clearfix main-title-btn-sec docbox-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Docbox <OverlayTrigger placement={'right'} overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
                  <span className="icon-Information green-icon green-icon-head"></span></OverlayTrigger></h2>
                </div>
                <div className="pull-right d-flex align-items-center bottom-save-btn">
                  <div className="progress-storage-sec">
                    <span className="ft-sz-16 s-reg font-colr storage-count">9.8/ 13GB used</span>
                    <span className="ft-sz-25 s-sbold font-colr mr-3">Storage</span> <ProgressBar now={75} />
                  </div>                  
                  <button className="btn save-btn greenbtn ">Upgrade</button>
                  {/* <button className="lic-bill-btn btn ft-sz-22 s-bold white-color">Upgrade</button> */}
                </div>
              </div>

              <div className="main-evidence-sms-sec main-docbox-sec">
                <div className="clearfix title-search-sec">
                  <div className="head-title pull-left">
                    <h2 className="s-sbold blu-color m-0">My Docbox</h2>
                  </div>
                  <div className="pull-right">
                    <form className="search-sec">
                      <input type="text" placeholder="Search" />
                      <button type="submit"><span className="icon-loupe"></span></button>
                    </form>
                  </div>
                </div>
                <div className="inner-breadcrumb clearfix">
                  <ol className="breadcrumb pull-left">
                    <li className="breadcrumb-item">
                      My Docbox
                    </li>
                    <li className="breadcrumb-item">Loan Documents</li>
                  </ol>
                  <p className="pull-right"><span className="icon-log icon-round-info-button" onClick={logShow}></span></p>
                </div>
                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="pull-left">
                      <p className="ft-sz-25 s-sbold blu-color mb-0 pt-2">Files {'&'} Folders</p>
                    </div>
                    <div className="search-filter-sec pull-right">
                      <button className="add-new-btn btn ft-sz-20 white-color s-bold mr-3"><span className="icon-plus1"></span> Add New</button>
                      <button className="add-new-btn btn ft-sz-20 white-color s-bold" onClick={() => setSmShow(true)}><span className="icon-plus1"></span> Create Folder</button>
                    </div>
                  </div>
                  
                  <div className="main-table-sec">
                  <div className="not-create-sec d-none"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Name <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Uploaded by <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Last Modified <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>File Size <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> My Folder</td>
                          <td>Mohit Rathod</td>
                          <td>4-2-2021</td>
                          <td>226 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            {/* <span className="icon-Edit white-icon"></span> */}
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28" onClick={handleShow}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> New Docs</td>
                          <td>Mangesh Kumar</td>
                          <td>25-1-2021</td>
                          <td>450 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28" onClick={handleShow}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> Rahul T</td>
                          <td>Sarah Taylor </td>
                          <td>23-1-2021</td>
                          <td>6.23 MB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> Loan Documents</td>
                          <td>Sarah Taylor </td>
                          <td>23-1-2021</td>
                          <td>6.23 MB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Doc ft-sz-28 mr-1"></span> Loan Agreement.pdf</td>
                          <td>Mohit Rathod</td>
                          <td>4-2-2021</td>
                          <td>226 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>
                            <span className="icon-jpg blue-icon ft-sz-28 mr-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
                            Signature.jpg
                          </td>
                          <td>Mangesh Kumar</td>
                          <td>25-1-2021</td>
                          <td>450 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Doc ft-sz-28 mr-1"></span> Receipt.pdf</td>
                          <td>Sarah Taylor </td>
                          <td>23-1-2021</td>
                          <td>6.23 MB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                            <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <Table bordered responsive className="d-none">
                      <thead>
                        <tr>
                          <th>Star <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Name <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Uploaded by <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Last Modified <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>File Size <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Doc ft-sz-28 mr-1"></span> Loan Agreement.pdf</td>
                          <td>Mohit Rathod</td>
                          <td>4-2-2021</td>
                          <td>226 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>
                          <span className="icon-jpg blue-icon ft-sz-28 mr-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
                          Signature.jpg</td>
                          <td>Mangesh Kumar</td>
                          <td>25-1-2021</td>
                          <td>450 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Doc ft-sz-28 mr-1"></span> Loan Agreement.pdf</td>
                          <td>Mohit Rathod</td>
                          <td>4-2-2021</td>
                          <td>226 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td><span className="icon-jpg blue-icon ft-sz-28 mr-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
                          Signature.jpg</td>
                          <td>Mangesh Kumar</td>
                          <td>25-1-2021</td>
                          <td>450 KB</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color red-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="pull-left">
                      <p className="ft-sz-25 s-sbold blu-color mb-0 pt-2">System Folders</p>
                    </div>
                  </div>
                  
                  <div className="main-table-sec">
                  <div className="not-create-sec d-none"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Name <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Uploaded by <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>Last Modified <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                          <th>File Size <span className="icon-arrow-down-sign-to-navigate"></span><span className="icon-arrow-down-sign-to-navigate arrow-up"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* system-bg-green */}
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> Evidences</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color fnt-gry-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color fnt-gry-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> Approvals</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color fnt-gry-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color fnt-gry-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> Cheques</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                          <span className="dbox-tble-icon white-color green-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Download</Tooltip>}>
                            <span className="icon-Download white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          
                          <span className="dbox-tble-icon white-color fnt-gry-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Rename</Tooltip>}>
                            <span className="icon-Edit white-icon"></span>
                            </OverlayTrigger>
                          </span>

                          <span className="dbox-tble-icon white-color fnt-gry-bg mr-3 ft-sz-28">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Delete</Tooltip>}>
                             <span className="icon-delete white-icon"></span>
                            </OverlayTrigger>
                          </span>
                          </td>
                        </tr>
                       
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Create Folder */}
          <Modal
            size="lg" 
            show={smShow}
            backdrop="static"
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
            className="error-popup-width add-bank-model"
          >
            <Modal.Header closeButton>
              <Modal.Title  className="ft-sz-30 s-sbold blu-color">
                Folder Name
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>              
              <div className="form-group">
                <input type="text" className="form-control" value=""/>             
              </div>
              <div className="model-save-btn clearfix mt-2 mb-2 text-right">
                <Button className="btn apply-btn s-bold greenbtn pr-4 pl-4">
                  Add
                </Button>
              </div>
              <div className="clearfix"></div>
            </Modal.Body>
          </Modal>


          {/* <Modal
            size="sm"
            show={smShow}
            backdrop="static"
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
            className="add-folder-sec"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <p className="lable-input ft-sz-15 s-reg font-colr">Folder Name</p>
                <input type="text" className="form-control" value=""/>             
              </div>
              <button className="add-new-btn btn ft-sz-20 white-color s-bold "><span className="icon-plus1"></span> Add</button>
              <div className="clearfix"></div>
            </Modal.Body>
          </Modal> */}
          {/* End Create Folder */}
          

          {/* Delete Popup  */}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="save-model-sec error-popup-width er-pop"
          >
            <Modal.Body className="clearfix ">
                <div>
                  <p className="popup-blue-text text-center">
                  Delete File?
                  </p>
                  <p className="popup-gray-text text-center">
                    Loan agreement will be deleted  and you won't be able to restore it.
                  </p>
                </div> 
            </Modal.Body>

            <Modal.Footer className='bottom-save-btn'>
              <Button onClick={handleClose} variant="" className="btn model-save-btn save-btn graybtn">Cancel</Button>
              <Button  variant="" className="btn save-btn greenbtn model-save-btn">Delete</Button>
            </Modal.Footer>
          </Modal>

          {/* <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="save-model-sec"
          >                
            <Modal.Body className="clearfix">
              <div className="pull-left mr-3">
                <span className="icon-exclamation-red-sign popicon"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
              </div>
              <div className="pull-left">
                <p className="ft-sz-30 s-bold blu-color mb-1">Delete File?</p> 
                <p className="ft-sz-18 s-reg font-colr">Loan agreement will be deleted  and you won't be able to restore it.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
              <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Delete</Button>
            </Modal.Footer>
          </Modal> */}
          {/* End Delete Popup  */}



          {/* My Docbox Popup  */}
          <Modal
            show={clog}
            onHide={logClose}
            backdrop="static"
            keyboard={false}
            centered
            className="changelog-model-sec changelog-tr-brd"
          >
              <Modal.Header closeButton>
                  <Modal.Title className="ft-sz-30 s-sbold blu-color d-flex align-items-center"><span className="icon-round-info-button mr-2"></span> My Docbox</Modal.Title>
              </Modal.Header>
              <Modal.Body className="clearfix">
                <table className="table">
                  <thead>
                    <tr>
                        <th scope="col-1" className="ft-sz-22 s-sbold blu-color col-width">Date</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color w-75">Action</th>
                        <th scope="col-1" className="ft-sz-22 s-sbold blu-color col-width">User</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                      <td className="ft-sz-18 s-reg blu-color">Marked Signature as starred</td>
                      <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                    </tr>
                    <tr>
                      <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                      <td className="ft-sz-18 s-reg blu-color">Changed permission to view only</td>
                      <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                    </tr>
                    <tr>
                      <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                      <td className="ft-sz-18 s-reg blu-color">Added folder My Documents</td>
                      <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                    </tr>
                  </tbody>
                </table>
              </Modal.Body>
          </Modal>

          {/* <Modal
            show={clog}
            onHide={logClose}
            backdrop="static"
            keyboard={false}
            centered
            className="changelog-model-sec"
          >
            <Modal.Header closeButton>
              <Modal.Title className="ft-sz-30 s-sbold blu-color"><span className="icon-round-info-button mr-4"></span> My Docbox</Modal.Title>
            </Modal.Header>
            <Modal.Body className="clearfix">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                  <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                  <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                  <td className="ft-sz-18 s-reg blu-color">Marked Signature as starred</td>
                  <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                </tr>
                <tr>
                  <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                  <td className="ft-sz-18 s-reg blu-color">Changed permission to view only</td>
                  <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                </tr>
                <tr>
                  <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                  <td className="ft-sz-18 s-reg blu-color">Added folder My Documents</td>
                  <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                </tr>
              </tbody>
            </table>
            </Modal.Body>
          </Modal> */}
          {/* End - My Docbox Popup  */}
         
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

    
}











export default docbox;