import React, { Component } from 'react';
import {
  Link,
  Route,
  Switch
} from 'react-router-dom';
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import InvoiceService from '../../Services/Invoices/InvoiceService';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { changeDateFormate } from '../../Helpers/DateFormat';
import ReadyStatus from '../Icon/ReadyStatus';
import NotReadyStatus from '../Icon/NotReadyStatus';
import ProcessedStatus from '../Icon/ProcessedStatus';
import LinkedStatus from '../Icon/LinkedStatus';
import SyncedStatus from '../Icon/SyncedStatus';
import MessageIcon from '../Icon/Message';
import TaskIcon from '../Icon/Task';

class InvoiceList extends Component {


  constructor(props) {
    super(props);

    this.state = {
      invoice_list: [],
      creaters: [],
      linked: 0,
      not_ready: 0,
      page_no: 0,
      processed: 0,
      proforma: 0,
      ready: 0,
      synched: 0,
      total: 0,
      total_pages: 0,

      total_count: 0,
      per_page_items: 5,
      page_no: 1,

      year_type: 1,
      start_date: new Date("2020-01-01"),
      end_date: new Date("2021-11-01"),

      filter_card: 0,
      filter: 0,
      search: "",
      invoice_type: 0,
      invoice_number: "",
      customer_name: "",
      from_amount: 0,
      to_amount: 0,
      payment_status: 0,
      created_by: 0,
      is_star: false,
      sort_by: 1,
      sort_order: 2,

      entity_id: 1,

      show_filter_popup: false
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSizePerPageChange = this.handleSizePerPageChange.bind(this);
    this.renderShowsTotal = this.renderShowsTotal.bind(this);
  }

  toggleFilterPopup = () => {
    this.setState({
      show_filter_popup: !this.state.show_filter_popup
    })
  };

  getInvoiceList = (filter_by) => {
    this.setState({
      filter_by: filter_by,
      isloading: true,
      invoice_list: []
    })
    let filter_data = {
      "per_page_items": this.state.per_page_items,
      "page_no": this.state.page_no,
      "date_filter": {
        "type": parseInt(this.state.year_type),
        "start_date": changeDateFormate(this.state.start_date),
        "end_date": changeDateFormate(this.state.end_date)
      },
      "filter_card": this.state.filter_card,
      "filter": filter_by,
      "search": this.state.search,
      "filter_box": {
        "type": !!this.state.invoice_type ? parseInt(this.state.invoice_type) : null,
        "invoice_number": !!this.state.invoice_number ? this.state.invoice_number : null,
        "name": !!this.state.customer_name ? this.state.customer_name : null,
        "from_amount": !!this.state.from_amount ? this.state.from_amount : null,
        "to_amount": !!this.state.to_amount ? this.state.to_amount : null,
        "payment_status": !!this.state.payment_status ? parseInt(this.state.payment_status) : null,
        "created_by": !!this.state.created_by ? this.state.created_by : null,
        "is_star": this.state.is_star ? 1 : 0
      },
      "entity_id": this.state.entity_id,
      "sort_by": 1,
      "sort_order": 2
    };
    InvoiceService.getInvoiceList(filter_data).then(
      (data) => {
        this.setState({
          isloading: false
        })
        if (data.response_code == 200) {
          this.setState({
            invoice_list: data.result.invoices,
            creaters: data.result.created_by,
            page_no: data.result.page_no,
            total_count: data.result.total_count,

            linked: data.result.linked,
            not_ready: data.result.not_ready,
            processed: data.result.processed,
            proforma: data.result.proforma,
            ready: data.result.ready,
            synched: data.result.synched,
            total: data.result.total,
            total_pages: data.result.total_pages

          });
          if (this.state.year_type != 6) {
            this.setState({
              start_date: new Date(data.result.date_filter.find(x => x.id == this.state.year_type).start_date),
              end_date: new Date(data.result.date_filter.find(x => x.id == this.state.year_type).end_date),
            })
          }
        } else {
          toast.error(data.message);
        }
      });
  }


  componentDidMount() {
    this.getInvoiceList(0);
  }

  handleOnChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value })
  }

  onYearTypeChange = (e) => {
    if (e.target.value != 6) {
      this.setState({
        year_type: e.target.value
      }, () => this.getInvoiceList(this.state.filter_by))
    } else {
      this.setState({ year_type: e.target.value })
    }
  }

  setDate = (date, key) => {
    if (key == "end_date") {
      this.setState({
        [key]: date
      }, () => this.getInvoiceList(this.state.filter_by))
    } else {
      this.setState({ [key]: date })
    }
  }

  onCardSelected = (invoice_card_filter) => {
    if (invoice_card_filter !== this.state.filter_card) {
      this.setState({
        filter_card: invoice_card_filter
      }, () => this.getInvoiceList(this.state.filter_by))
    }
  }

  renderShowsTotal(start, to, total) {
    return (
      <p className="main-pagi-show">
         <span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
      </p>
    );
  }
  _setNoDataFoundOption() {
    if (!this.state.isloading) {
      return "No Invoices found";
    } else {
      return (
        <p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
      );
    }
  }

  handlePageChange(page, sizePerPage) {
    this.setState({
      page_no: page
    }, () => this.getInvoiceList(this.state.filter_by))
  }

  handleSizePerPageChange(sizePerPage) {
    // When changing the size per page always navigating to the first page
    this.setState({
      per_page_items: sizePerPage
    }, () => this.getInvoiceList(this.state.filter_by))
  }

  dataFormatStatus = (cell, row) => {
    switch (cell) {
      case 1:
        return <ReadyStatus />
      case 2:
        return <NotReadyStatus />
      case 3:
        return <ProcessedStatus />
      case 4:
        return <LinkedStatus />
      case 5:
        return <SyncedStatus />
    }
  }

  dataFormatTask = (cell, row) => {
    return <div>
      <MessageIcon />
      <TaskIcon />
    </div>
  }

  goToEditView = (row) => {
    const { dispatch, history } = this.props;
    history.push({
      pathname: `/invoices/create/` + row.id
    });
  }

  searchFilter = (e) => {
    e.preventDefault();
    this.getInvoiceList(1);
  }

  applyFilter = () => {
    this.getInvoiceList(2);
  }

  resetFilter = () => {
    this.getInvoiceList(0);
  }

  render() {

    const options = {
      onPageChange: this.handlePageChange,
      onSizePerPageList: this.handleSizePerPageChange,
      page: this.state.page_no,  // which page you want to show as default
      // sizePerPageList: [ {
      //   text: '25', value: 25
      // }, {
      //   text: '50', value: 50
      // }, {
      //   text: '100', value: 100
      // } ], // you can change the dropdown list for size per page
      sizePerPage: this.state.per_page_items,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button,
      onRowClick: (row) => {
        this.goToEditView(row);
      },
      noDataText: this._setNoDataFoundOption(),
    };



    return (
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Invoices</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Invoices</h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-date-sec">
                  <div className="">
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec"
                          onChange={(e) => this.onYearTypeChange(e)}
                          value={this.state.year_type}
                          name="year_type">
                          <option value="1" selected>Current Financial Year</option>
                          <option value="2">Last Financial Year</option>
                          <option value="3">This Month </option>
                          <option value="4">Last 3 Months</option>
                          <option value="5">Since Beginning</option>
                          <option value="6">Custom Date Range</option>
                        </select>
                      </div>
                    </div>
                    <div className="start-end-date-sec btn">
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={date => this.setDate(date, "start_date")}
                        className="form-control"
                        disabled={this.state.year_type == 6 ? "" : "disabled"}
                        selected={this.state.start_date}
                        autoComplete="off"
                        placeholderText="From Date"
                        name="start_date" />
                      {/* <span className="icon-calendar"></span> */}
                      {/* <span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span> */}
                    </div>
                    <hr />
                    <div className="start-end-date-sec btn">
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={date => this.setDate(date, "end_date")}
                        className="form-control"
                        disabled={this.state.year_type == 6 ? "" : "disabled"}
                        selected={this.state.end_date}
                        autoComplete="off"
                        placeholderText="To Date"
                        name="end_date" />
                      {/* <span className="icon-calendar"></span> */}
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="tab-inner-sec">
                    <div className="row">
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(0)}>
                        <div className={"count-box text-center" + (this.state.filter_card == 0 ? " active" : "")}>
                          <p className="title ft-sz-28 m-0">Total</p>
                          <div className="count-char s-bold">{this.state.total}</div>
                        </div>
                      </div>
                      {
                        <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(1)}>
                          <div className={"count-box text-center" + (this.state.filter_card == 1 ? " active" : "")}>
                            <p className="title ft-sz-28 m-0">Not Ready</p>
                            <div className="count-char s-bold">{this.state.not_ready}</div>
                          </div>
                        </div>
                      }
                      {
                        <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(2)}>
                          <div className={"count-box text-center" + (this.state.filter_card == 2 ? " active" : "")}>
                            <p className="title ft-sz-28 m-0">Ready</p>
                            <div className="count-char s-bold">{this.state.ready}</div>
                          </div>
                        </div>
                      }
                      {
                        <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(3)}>
                          <div className={"count-box text-center" + (this.state.filter_card == 3 ? " active" : "")}>
                            <p className="title ft-sz-28 m-0">Linked</p>
                            <div className="count-char s-bold">{this.state.linked}</div>
                          </div>
                        </div>
                      }
                      {
                        <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(4)}>
                          <div className={"count-box text-center" + (this.state.filter_card == 4 ? " active" : "")}>
                            <p className="title ft-sz-28 m-0">Processed</p>
                            <div className="count-char s-bold">{this.state.processed}</div>
                          </div>
                        </div>
                      }
                      {
                        <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(5)}>
                          <div className={"count-box text-center" + (this.state.filter_card == 5 ? " active" : "")}>
                            <p className="title ft-sz-28 m-0">Synced</p>
                            <div className="count-char s-bold">{this.state.synched}</div>
                          </div>
                        </div>
                      }
                      {
                        <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={() => this.onCardSelected(6)}>
                          <div className={"count-box text-center" + (this.state.filter_card == 6 ? " active" : "")}>
                            <p className="title ft-sz-28 m-0">Proforma</p>
                            <div className="count-char s-bold">{this.state.proforma}</div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec" onSubmit={this.searchFilter} noValidate>
                        <input type="text"
                          className="form-control"
                          placeholder="Search"
                          value={this.state.search}
                          onChange={this.handleOnChange}
                          name="search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter" onClick={this.toggleFilterPopup}>
                        Filter <span className="icon-funnel"></span>
                      </div>
                      <Link to="/invoices/create" className="add-new-btn btn ft-sz-20 white-color s-bold">
                        <span className="icon-plus1"></span> Create Invoice
                      </Link>
                    </div>
                  </div>
                  {
                    this.state.show_filter_popup && <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <select className="form-control"
                              onChange={this.handleOnChange}
                              value={this.state.invoice_type}
                              name="invoice_type" >
                              <option value="0">Select Invoice type</option>
                              <option value="1">Invoice</option>
                              <option value="2">Proforma</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text"
                              className="form-control"
                              placeholder="Invoice number"
                              value={this.state.invoice_number}
                              onChange={this.handleOnChange}
                              name="invoice_number" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text"
                              className="form-control"
                              placeholder="Customer Name"
                              value={this.state.customer_name}
                              onChange={this.handleOnChange}
                              name="customer_name" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text"
                              className="form-control"
                              placeholder="From Amount"
                              value={this.state.from_amount}
                              onChange={this.handleOnChange}
                              name="from_amount" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text"
                              className="form-control"
                              placeholder="to Amount"
                              value={this.state.to_amount}
                              onChange={this.handleOnChange}
                              name="to_amount" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <select className="form-control"
                              onChange={this.handleOnChange}
                              value={this.state.payment_status}
                              name="payment_status" >
                              <option value="0">Select Payment status</option>
                              <option value="1">Paid</option>
                              <option value="2">Unpaid</option>
                              <option value="3">Part Payment</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <select className="form-control"
                              onChange={this.handleOnChange}
                              value={this.state.created_by}
                              name="created_by" >
                              <option value="">Created by</option>
                              {
                                this.state.creaters.map((created_by, index) => {
                                  <option key={index} value={created_by.id}>{created_by.full_name}</option>
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <div className="form-control clearfix">
                              <label className="pull-left">Star</label>
                              <div className="check-box pull-right">
                                <input className="styled-checkbox" id="styled-checkbox-011"
                                  name="is_star"
                                  type="checkbox"
                                  checked={this.state.is_star}
                                  onChange={this.handleOnChange} />
                                <label htmlFor="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="button" onClick={this.applyFilter} className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      <button type="button" onClick={this.resetFilter} className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>
                  }

                  <div className="main-table-sec">
                    <BootstrapTable ref='table' data={this.state.invoice_list} responsive table-bordered
                      pagination={true}
                      options={options}
                      remote
                      fetchInfo={{ dataTotalSize: this.state.total_count }}
                      remote
                      pagination
                      striped
                      hover
                      condensed>
                      <TableHeaderColumn width="100" dataField='is_star' dataFormat={(cell, row) => { return <i className={"fa fa-star star-td " + (cell == 1 ? " active" : "")} aria-hidden="true"></i> }}>Star</TableHeaderColumn>
                      <TableHeaderColumn isKey dataField='date' dataSort={true}>Date </TableHeaderColumn>
                      <TableHeaderColumn dataField='invoice_type' dataFormat={(cell, row) => cell == 1 ? "Invoice" : "Proforma"} dataSort={true}>Type </TableHeaderColumn>
                      <TableHeaderColumn dataField='invoice_number' dataSort={true}>Invoice# </TableHeaderColumn>
                      <TableHeaderColumn width="200" dataField='name' dataSort>Customer </TableHeaderColumn>
                      <TableHeaderColumn dataField='total_after_tds' dataSort>Amount </TableHeaderColumn>
                      <TableHeaderColumn dataField='payment_status' dataFormat={(cell, row) => { return { 1: "Paid", 2: "Unpaid", 3: "Part Payment" }[cell] }} dataSort>Payment status </TableHeaderColumn>
                      <TableHeaderColumn dataField='status' dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>Status </TableHeaderColumn>
                      <TableHeaderColumn dataField='' dataFormat={(cell, row) => this.dataFormatTask(cell, row)} dataSort></TableHeaderColumn>
                    </BootstrapTable>
                  </div>


                  {/* Invoice Table list */}
                  <div className="main-table-sec d-none">
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star</th>
                          <th>Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Type <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Invoice# <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Customer <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Amount <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Payment status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>1 </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>2 </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold sky-blu-color">Ready</Tooltip>}>
                              <span className="icon-td icon-Group-25071 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>3 </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Linked</Tooltip>}>
                              <span className="icon-td icon-Group-2510 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="gray-st-icon icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>4 </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2509 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>5 </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>Proforma</td>
                          <td>A125465</td>
                          <td>Mahindra</td>
                          <td>₹ 10,000</td>
                          <td>Paid </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Not Ready</Tooltip>}>
                              <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                              <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>

                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                              <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="main-pagination clearfix d-none">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination pull-right">
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                        <li className="page-item item-count"><a className="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Prev</a></li>
                        <li className="page-item active"><a className="page-link ft-sz-20 s-reg" href="#">1</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">3</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Next</a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>

    );
  }

}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(InvoiceList);