import React from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Table } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import Button from "react-bootstrap/Button";
import { Dropdown } from 'react-bootstrap';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { displayDateFormate } from '../../Helpers/DateFormat';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
// import { Button, Dropdown } from 'react-bootstrap';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class BillWise extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allocation_data: _.cloneDeep(this.props.billwise_allocation),
            total_amount: 0,
            amount_mismatch_error: false,
            pending_bills: [],
            pending_bills_master: [],
            errors: false,
            merchant_id: null,
            //submit: false
        }
    }

    componentDidMount = () => {

        setTimeout(() => {
            if (this.props.temp_linked_evidences.length > 0) {
                let merchant_id = this.props.temp_linked_evidences[0].merchant_id;
                this.setState({ merchant_id });
                this.getPendingBills(merchant_id)
            } else {
                this.getPendingBills(null)
            }

            if (this.state.allocation_data.length == 0) {
                this.addNewRow()
            }
        }, 1000);
    }

    calculateTotalAmount = () => {
        let total_amount = 0;
        this.state.allocation_data.forEach(function (data) {
            if (data.amount)
                total_amount += parseFloat(data.amount)
        })
        return total_amount
    }

    addNewRow = () => {
        let allocation_data = this.state.allocation_data;
        let amount = parseFloat((this.props.ledger_amount - this.calculateTotalAmount()).toFixed(2));

        let data = {
            "amount": amount > 0 ? amount : 0,
            "invoice_no": "",
            "tag_type": 1,
            "transaction_voucher_ledger_billwise_allocation_id": 0,
            "type": 1,
            "type_of_ref": "",
            "merchant_id": "",
            "tag": "",
            "source": "",
            "source_id": ""
        }

        allocation_data.push(data)
        this.setState({ allocation_data })
    }

    removeRow = (index) => {
        let allocation_data = [...this.state.allocation_data];


        allocation_data.splice(index, 1);


        this.setState({ allocation_data }, () => {
            //this.calculateTotalAmount()
        });
    }

    saveData = () => {

        if ((this.calculateTotalAmount()) != (this.props.ledger_amount) && this.calculateTotalAmount() != 0) {
            this.setState({ amount_mismatch_error: true })
            return
        } else {
            this.setState({ amount_mismatch_error: false });

            let errors = false

            this.state.allocation_data.map((allocation, index) => {
                if ((allocation.type_of_ref == 1 || allocation.type_of_ref == 2) && !allocation.invoice_no) {
                    errors = true
                }
                if (!allocation.type_of_ref) {
                    errors = true
                }

                if (allocation.amount == 0) {
                    errors = true
                }

                if (!allocation.amount) {
                    errors = true
                }
            })
            this.setState({ errors })

            if (!errors) {
                this.state.allocation_data.map((allocation, index) => {
                    if (allocation.amount == 0 && allocation.type_of_ref == "") {
                        this.state.allocation_data.splice(index, 1);
                    } else {
                        //if(!allocation.invoice_no && allocation.type_of_ref == 3) {
                        if (allocation.type_of_ref == 3) {
                            allocation.tag = "Adv. (₹" + (allocation.amount) + ")";
                            allocation.tag_type = 3;
                        } else if (!allocation.invoice_no && allocation.type_of_ref == 4) {
                            allocation.tag = "On Acc. (₹" + (allocation.amount) + ")";
                            allocation.tag_type = 3;
                        } else {
                            if (allocation.type_of_ref == 1) {
                                allocation.tag = "<b>" + allocation.invoice_no + "</b> (₹" + (allocation.amount) + ")";
                            } else {
                                allocation.tag = allocation.invoice_no + " (₹" + (allocation.amount) + ")";
                            }
                            //allocation.tag = allocation.invoice_no+" (₹"+(allocation.amount)+")";
                        }

                        if (allocation.type_of_ref == 1) {
                            allocation.tag_type = 3;
                            if (this.props.temp_linked_evidences.length > 0) {
                                this.props.temp_linked_evidences.forEach(evd => {
                                    if (evd.invoice_no == allocation.invoice_no) {
                                        allocation.tag_type = 1;
                                    }
                                })
                            }
                        } else if (allocation.type_of_ref == 2 && allocation.source == 1) {
                            allocation.tag_type = 3;
                        } else if (allocation.type_of_ref == 2 && allocation.source == 2) {
                            allocation.tag_type = 1;
                            let present_in_buktec = false;
                            if (this.props.temp_linked_evidences.length > 0) {
                                this.props.temp_linked_evidences.forEach(evd => {
                                    if (evd.invoice_no == allocation.invoice_no) {
                                        //allocation.tag_type = 1;
                                        present_in_buktec = true
                                    }
                                })
                            }
                            if (!present_in_buktec) {
                                allocation.tag_type = 2;
                            }
                        } else if (allocation.type_of_ref == 3) {
                            allocation.tag_type = 3;
                        }
                    }
                })
                this.setState({ errors: false })
                this.props.saveAllocationData(this.state.allocation_data)
            }
            //console.log(this.state.allocation_data)

        }
    }

    getPendingBills = (merchant_id) => {
        let post_data = {
            merchant_id: merchant_id == 0 ? null : merchant_id,
            ledger_guid: this.props.guid,
            transaction_id: parseInt(this.props.transaction_id)
        }

        MoneyLinkService.getPendingBills(post_data).then((data) => {
            if (data.response_code === 200) {
                let invoices = this.state.allocation_data.map(function (data) {
                    return data.invoice_no
                })

                //console.log(invoices);
                let bills = []
                bills = data.result.bills_outstandings.filter(function (bill) {
                    if (!invoices.includes(bill.invoice_no)) {
                        return bill
                    }
                })
                //console.log(bills)
                this.setState({ pending_bills: bills, pending_bills_master: data.result.bills_outstandings });

            } else {
                toast.error(data.message)
            }
        })

    }


    render() {
        //console.log(this.state.allocation_tags)
        //console.log(this.props.billwise_allocation)

        let invoices = this.state.allocation_data.map(function (data) {
            if (data.type_of_ref == 2)
                return data.invoice_no
        })
        let pending_bills = []
        pending_bills = this.state.pending_bills_master.filter(function (bill) {
            if (!invoices.includes(bill.invoice_no)) {
                return bill
            }
        })

        return (
            <Modal
                size="xl"
                show={this.props.showBillWise}
                onHide={() => this.props.toggelBillWiseModal()}
                backdrop="static"
                keyboard={false}
                centered
                className="popup-model bill-wise-model"
            >
                <Modal.Header closeButton>
                    <Modal.Title>                        
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bill-wise Details for: {this.props.ledger_name}</Tooltip>}>
                            <span className="bill-name">Bill-wise Details for: {this.props.ledger_name}</span>
                        </OverlayTrigger>
                        <span className='size-xx ml-auto'>Amount (₹) : {this.props.ledger_amount}</span>
                        {/* <span className="bill-name">Bill-wise Details for: {this.props.ledger_name}</span> */}
                        {/* <span className="icon-round-info-button mp-icon"></span>Change Log */}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="clearfix mt-0 pb-0">
                    {this.state.amount_mismatch_error && <p className="inner-ready-error-text ft-sz-16 s-reg red-color mb-2">Total billwise amount should be equal to ledger amount</p>}
                    {/* <Table responsive> */}
                    <div className="table-responsive main-invoice-item-new min-invoice-height">
                        <Table className="ledger-table mb-0">
                            <thead>
                                <tr>
                                    <th>Type of Ref</th>
                                    <th className="w-50">Name</th>
                                    <th className="text-right">Amount</th>
                                    {/* <th className=""></th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.allocation_data.map((allocation, index) => {
                                    return <>
                                        <tr>
                                            <td>
                                                <div class="form-group">
                                                    <select class="form-control" name="ref_select"
                                                        value={allocation.type_of_ref}
                                                        onChange={(e) => {
                                                            let allocation_data = [...this.state.allocation_data];
                                                            let data = { ...allocation_data[index] };
                                                            data.type_of_ref = parseInt(e.target.value);
                                                            if (e.target.value == 4 || e.target.value == 2) {
                                                                data.invoice_no = "";
                                                            }
                                                            allocation_data[index] = data;
                                                            this.setState({ allocation_data });
                                                        }}>
                                                        <option value="">Select Ref</option>
                                                        <option value="3">Advance</option>
                                                        <option value="2">Agst Ref</option>
                                                        <option value="1">New Ref</option>
                                                        <option value="4">On Account</option>
                                                    </select>
                                                    {this.state.errors && allocation.type_of_ref == "" && <small className="error_right">Please select type of ref</small>}
                                                </div>
                                            </td>
                                            <td>
                                                {allocation.type_of_ref == 4 ?
                                                    <div class="form-group">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name="name"
                                                            placeholder="Name"
                                                            value={allocation.invoice_no}
                                                            disabled={true} />
                                                    </div>
                                                    :
                                                    <div class="form-group">
                                                        {allocation.type_of_ref == 2 ?
                                                            <div className="bill-wisep-agst-ref">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                                                                        <input
                                                                            type="text"
                                                                            class="form-control caret-none"
                                                                            name="name"
                                                                            placeholder="Name"
                                                                            value={allocation.invoice_no}
                                                                            autocomplete="off"
                                                                        />
                                                                        {this.state.errors && !allocation.invoice_no && <small className="error_right">Please select name</small>}
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Table>
                                                                            <thead>
                                                                                <tr className="">
                                                                                    <th className="">Name</th>
                                                                                    <th className="">Date</th>
                                                                                    <th className="text-right">Balance</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {pending_bills.length == 0 && <tr>No data available</tr>}

                                                                                {pending_bills.map((pending_bill, ind) => {
                                                                                    return <tr
                                                                                        className={pending_bill.source == 1 ? "light-blu-bg" : "white-bg"}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            //this.selectFromPendingBillList()
                                                                                            let allocation_data = [...this.state.allocation_data];
                                                                                            let data = { ...allocation_data[index] };
                                                                                            data.invoice_no = pending_bill.invoice_no;
                                                                                            data.source = pending_bill.source;
                                                                                            data.source_id = pending_bill.source_id;
                                                                                            data.type = pending_bill.type;

                                                                                            let total_amount = 0;
                                                                                            this.state.allocation_data.forEach(function (data, ind) {
                                                                                                //console.log( typeof data.amount)
                                                                                                if (index != ind) {
                                                                                                    if (data.amount)
                                                                                                        total_amount += parseFloat(data.amount)
                                                                                                }
                                                                                            })
                                                                                            let remaining_amount = parseFloat((this.props.ledger_amount - total_amount).toFixed(2));

                                                                                            //console.log(remaining_amount)
                                                                                            //if(remaining_amount )
                                                                                            if (remaining_amount < parseFloat(pending_bill.pending_amount)) {
                                                                                                data.amount = remaining_amount
                                                                                            } else {
                                                                                                data.amount = pending_bill.pending_amount;
                                                                                            }

                                                                                            //console.log(data.amount)
                                                                                            allocation_data[index] = data;
                                                                                            this.setState({ allocation_data }, () => {
                                                                                                let invoices = this.state.allocation_data.map(function (data) {
                                                                                                    return data.invoice_no
                                                                                                })

                                                                                                let bills = []
                                                                                                bills = this.state.pending_bills_master.filter(function (bill) {
                                                                                                    if (!invoices.includes(bill.invoice_no)) {
                                                                                                        return bill
                                                                                                    }
                                                                                                })
                                                                                                this.setState({ pending_bills: bills })
                                                                                            });

                                                                                        }}>

                                                                                        {/* <td className="fs14 blue-color"> */}
                                                                                        <td className={pending_bill.source == 1 ? "blue-color " : "blue-color"}>
                                                                                            {pending_bill.invoice_no}
                                                                                        </td>
                                                                                        <td className={pending_bill.source == 1 ? "blue-color " : "blue-color"}>
                                                                                            {pending_bill.date ? displayDateFormate(pending_bill.date) : ""}
                                                                                        </td>
                                                                                        {/* <td className="fs14 blue-color text-right"> */}
                                                                                        <td className={pending_bill.source == 1 ? "blue-color  text-right" : "blue-color text-right"}>
                                                                                            {pending_bill.pending_amount}
                                                                                        </td>

                                                                                    </tr>
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            :
                                                            <input
                                                                type="text"
                                                                class="form-control"
                                                                name="name"
                                                                placeholder="Name"
                                                                value={allocation.invoice_no}
                                                                onChange={(e) => {
                                                                    let allocation_data = [...this.state.allocation_data];
                                                                    let data = { ...allocation_data[index] };
                                                                    data.invoice_no = e.target.value;
                                                                    allocation_data[index] = data;
                                                                    this.setState({ allocation_data });
                                                                }}
                                                            />
                                                        }

                                                    </div>
                                                }
                                                {this.state.errors && allocation.type_of_ref == 1 && !allocation.invoice_no && <small className="error_right">Please enter name</small>}
                                            </td>


                                            <td>
                                                <div class="form-group">
                                                    <CurrencyInput
                                                        type="text"
                                                        className="form-control text-right"
                                                        name="amount"
                                                        autoComplete="off"
                                                        placeholder='₹ 0 '
                                                        value={allocation.amount}
                                                        prefix="&#8377; "
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}
                                                        onValueChange={(value) => {
                                                            let allocation_data = [...this.state.allocation_data];
                                                            let data = { ...allocation_data[index] };
                                                            data.amount = value;
                                                            //console.log(parseFloat(value))
                                                            allocation_data[index] = data;
                                                            this.setState({ allocation_data }, () => {
                                                                //this.calculateTotalAmount()
                                                            });
                                                        }}
                                                    />
                                                    {this.state.errors && (allocation.amount == 0 || !allocation.amount) && <small className="error_right">Please enter amount</small>}
                                                </div>
                                            </td>

                                            <td className="text-right croslt">
                                                <span class="icon-Cross-with-grey-circle size-xx cpointer" onClick={() => this.removeRow(index)}><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
                                            </td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="table-responsive main-invoice-item-new">
                        <Table className="ledger-table">
                            <tr>
                                <td className="va-middle">
                                    <button className="ledger-add-row" onClick={() => this.addNewRow()}>
                                        <span class="icon-plus-circled"></span> Add New Row
                                    </button>
                                </td>
                                <td className="ledger-total">
                                    Total (₹):
                                </td>
                                {/* <td className="pt-2">
                                    <div class="ft-sz-30 s-sbold blu-color w-100 d-flex align-items-center modal-title h4"><span class="ft-sz-20 ml-auto">{this.calculateTotalAmount().toFixed(2)}</span></div>
                                </td> */}
                                <td className="ledger-total">
                                    <span className="pr-1">{this.calculateTotalAmount().toFixed(2)}</span>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="3">
                                    <div className="reset_apply_btn_new mt-2">
                                        <Button variant="outline-success reset_btn_new" onClick={() => this.props.toggelBillWiseModal()}>Cancel</Button>
                                        <Button variant="success apply_btn_new" onClick={() => this.saveData()}>Save</Button>
                                    </div>
                                </td>
                            </tr>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal >
        )
    }
}

export default BillWise