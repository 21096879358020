import React from 'react'
import { Modal } from 'react-bootstrap';

class ChangeLog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <Modal
                show={this.props.show_log}
                onHide={() => this.props.toggleChangeLog()}
                backdrop="static"
                keyboard={false}
                centered
                className="popup-model change-log-model"
            >
                <Modal.Header closeButton>
                    <Modal.Title><span className="icon-round-info-button mp-icon"></span>Change Log</Modal.Title>
                    {/* <button type="button" class="close"><span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></button> */}
                </Modal.Header>
                {/* <Modal.Body className="clearfix pt-0 pb-1"> */}
                <Modal.Body>
                    <table className="table mb-0 ">
                        <thead>
                            <tr>
                                <th className="datech-width">Date</th>
                                {this.props.main && <th className="idch-width">ID</th>}
                                <th className="w-75 namech-width">Action</th>
                                <th className="namech-width">User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.props.logs).length != 0 && this.props.logs.map(item => {
                                return <tr>
                                    <td>{item.timestamp.substr(0, item.timestamp.indexOf(' '))} <span className="ml-2">{item.timestamp.substr(item.timestamp.indexOf(' ') + 1)}</span></td>
                                    {this.props.main && <td>#{item.mod_id}</td>}
                                    <td>{item.activities.join(", ")}</td>
                                    <td>{item.full_name}</td>
                                </tr>
                            })}                            
                        </tbody>
                    </table>

                    {/* <table className="table mb-0">
                        <thead>
                            <tr>
                                <th scope="col-1" className="ft-sz-22 s-sbold blu-color col-width">Date</th>
                                {this.props.main && <th scope="col" className="ft-sz-22 s-sbold blu-color">ID</th>}
                                <th scope="col" className="ft-sz-22 s-sbold blu-color w-75">Action</th>
                                <th scope="col-1" className="ft-sz-22 s-sbold blu-color col-width">User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.props.logs).length != 0 && this.props.logs.map(item => {
                                return <tr>
                                    <td className="ft-sz-18 s-reg blu-color">
                                        {item.timestamp.substr(0,item.timestamp.indexOf(' '))} <span className="ml-2">{item.timestamp.substr(item.timestamp.indexOf(' ')+1)}</span>
                                    </td>
                                    {this.props.main && <td className="ft-sz-18 s-reg blu-color">#{item.mod_id}</td>}
                                    <td className="ft-sz-18 s-reg blu-color">{item.activities.join(", ")}</td>
                                    <td className="ft-sz-18 s-reg blu-color">{item.full_name}</td>
                                </tr>                                
							})}
                        </tbody>
                    </table> */}
                </Modal.Body>
            </Modal>
        )
    }
}

export default ChangeLog