function convertToCurrency(number) {
    if(number != undefined){
        number=number.toString();
        var lastThree = number.substring(number.length-3);
        var otherNumbers = number.substring(0,number.length-3);
        if(otherNumbers != '')
            lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

        return res;
    } else {
        return number;
    }
}

function convertFloatNumberToCurrency(number) {
    if(number != undefined){
        //var number=12345652457.557;
        number=number.toString();
        var afterPoint = '';
        if(number.indexOf('.') > 0)
        afterPoint = number.substring(number.indexOf('.'),number.length);
        number = Math.floor(number);
        number=number.toString();
        var lastThree = number.substring(number.length-3);
        var otherNumbers = number.substring(0,number.length-3);
        if(otherNumbers != '')
            lastThree = ',' + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
        return res;
    } else {
        return number;
    }
}

function showAmount(n){

    if(!n){
        return "₹ 0"
    }

    if(typeof n == "string"){
        return "₹ "+parseFloat(n).toLocaleString();
    }else{
        return "₹ "+n.toLocaleString();
    }
}

function showRoundOffAmount(n){

    if(!n){
        return "₹ 0"
    }

    if(typeof n == "string"){
        return "₹ "+Math.round(parseFloat(n)).toLocaleString();
    }else{
        return "₹ "+Math.round(n).toLocaleString();
    }
}

function rounding(value) {
    let v = Math.round(value * 100) / 100;
    if(v.toFixed(2) % 1 == 0) {
        return +v.toFixed(2) 
    }
    return v.toFixed(2) 
 }

export { convertToCurrency,showAmount,showRoundOffAmount,rounding, convertFloatNumberToCurrency }