import React, { Component } from 'react';
import { connect } from "react-redux";
// import { withRouter } from 'react-router';
import { withRouter, Link } from 'react-router-dom'
import CreateInvoiceSideList from './CreateInvoiceSideList';
import CreateInvoice from './CreateInvoice';

class CreateInvoiceView extends Component{


    constructor(props) {
        super(props);
        this.state = {
          
        }
    }

    componentDidMount(){
    }


    render(){
        return ( 
         <div>    
          <div className="content-wrapper">
            <div className="container-fluid">        
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 sidebar-sec">
                  <CreateInvoiceSideList 
                      invoice_list = {this.props.invoice_list}
                      applyFilter = {this.props.applyFilter}
                      creaters = {this.props.creaters}
                      
                      has_next = {this.props.has_next}
                      has_previous = {this.props.has_previous}
                      per_page_items = {this.props.per_page_items}
                      page_no = {this.props.page_no} />
                </div>
                <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7 main-content-sec">
                  <CreateInvoice  newInvoice = {this.props.newInvoice}/>
                </div>
              </div>
            </div>
          </div>         
        </div>
    );
  }

}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(withRouter(CreateInvoiceView));