import React from 'react'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import { checkFileExtension } from '../../Helpers/CheckValidation';

class ImportCostCenters extends React.Component {
    constructor(props) {
        super(props);
        this.btnRef = React.createRef();

        this.PERMISSION_LIST = this.props.features_permissions_list;
        this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
        this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
        this.VIEW_LINKED_ENTRIES = this.PERMISSION_LIST.includes(VIEW_LINKED_ENTRIES_CODE);
        this.state = {
            per_page_items: 0,
            showFilter: false,
            showSearch: true,
            active_input: false,
            search: '',
            is_permission_granted: (this.props.features_permissions_list.includes(LINKING_CODE) ||
                this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ||
                this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)),
        }
    }

    componentDidMount() {

    }

    uploadCostCenter = (e) => {
        if (checkFileExtension(e.target.files[0].name)) {
            var XMLParser = require('react-xml-parser');
            e.preventDefault();


            const file = e.target.files[0];
            e.target.value = null;
            const reader = new FileReader();
            reader.readAsText(file);
            var cost_category_list = [];
            var cost_center_list = [];
            this.props.dispatch(setAppilcationLoder(true));
            reader.onloadend = async (evt) => {

                const readerData = evt.target.result;

                var jsonDataFromXml = new XMLParser().parseFromString(readerData);
                let cost_category_xml = await jsonDataFromXml.getElementsByTagName('COSTCATEGORY');

                cost_category_xml.forEach((cost_category, index) => {
                    var guid = cost_category.children.filter(function (value) {
                        return value.name === "GUID";
                    });
                    cost_category_list.push({ 'name': cost_category.attributes.NAME, 'guid': guid[0].value })
                })

                let cost_center_xml = await jsonDataFromXml.getElementsByTagName('COSTCENTRE');
                cost_center_xml.forEach((cost_center, index) => {
                    var guid = cost_center.children.find(function (value) {
                        return value.name === "GUID";
                    });
                    var category = cost_center.children.find(function (value) {
                        return value.name === "CATEGORY";
                    });
                    if (!cost_center_list[category.value]) {
                        cost_center_list[category.value] = [];
                    }
                    cost_center_list[category.value].push({ 'name': cost_center.attributes.NAME, 'guid': guid.value })
                })

                if (cost_category_list.length == 0) {
                    this.props.dispatch(setAppilcationLoder(false));
                    toast.error("Please upload valid cost categories file");
                } else {
                    cost_category_list.forEach((cost_category, index) => {
                        cost_category.cost_centres = cost_center_list[cost_category.name] || [];
                    })
                    const cost_categories = { "cost_categories": cost_category_list }
                    MoneyLinkService.importCostCenters(cost_categories).then((data) => {
                        if (data.response_code == 200) {
                            toast.success(data.message);
                        } else {
                            toast.error(data.message);
                        }
                        this.props.dispatch(setAppilcationLoder(false));
                    });
                }
            }
        } else {
            toast.error("Please upload valid xml file");
        }

    }



    render() {
        const ref_upload_cost_center = React.createRef();

        return (<>
            <input
                ref={ref_upload_cost_center}
                onChange={this.uploadCostCenter}
                type="file"
                style={{ display: "none" }}
                accept=".xml"
            />
            {/* <button onClick={() => ref_upload_cost_center.current.click()} className="btn ft-sz-20 s-bold green-btn mr-3">Import Cost Centre</button> */}

            <button className="" onClick={() => ref_upload_cost_center.current.click()}>
                <span class="icon-Download action-icon"></span>
                <span>Import Cost Centre</span>
            </button>
        </>
        )
    }
}

function mapStateToProps(state) {
    const all_value = state.MoneyLink || {};
    const is_transaction_loading = all_value.is_transaction_loading;
    const filter_by = all_value.filter_by
    const session_value = state.Session || {};
    const features_permissions_list = session_value.features_permissions_list || [];

    return {
        is_transaction_loading, filter_by, features_permissions_list, all_value
    };
}

export default connect(mapStateToProps)(withRouter(ImportCostCenters));
