import React from 'react';
import { Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import badge from '../../Assets/img/badge.png';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";;
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import TaskManagerService from '../../Services/TaskManager/TaskManagerService';
import { Link, useHistory } from 'react-router-dom';
import RecentActivities from '../../Services/RecentActivities';
import ReadyStatus from '../IcoonNew/ReadyStatus'
import NotReadyStatus from '../IcoonNew/NotReadyStatus'
import ProcessedStatus from '../IcoonNew/ProcessedStatus'
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus'
import LinkedStatus from '../IcoonNew/LinkedStatus'
import OpenStatus from '../IcoonNew/OpenStatus'
import OverdueStatus from '../IcoonNew/OverdueStatus'
import FinishedStatus from '../IcoonNew/FinishedStatus'
import SyncedStatus from '../IcoonNew/SyncedStatus'
import ViewStatement from '../IcoonNew/ViewStatement';
import BankIcon from '../IcoonNew/BankIcon';

import Messages from './Messages';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import StorageData from '../../Helpers/StorageData';
import { showAmount } from '../../Helpers/Currency';
import { displayDateFormate } from '../../Helpers/DateFormat';
import { confirm } from '../../Components/BoilerPlate/Confirm';

{/* Intro JS */ }
import { render } from "react-dom";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
{/* Intro JS */ }

import ScrollPage from '../../Components/BoilerPlate/ScrollPage'

function HomeView(props) {

  const [show, setShow] = React.useState(false)
  const [taskList, setTaskList] = useState([])
  const [taskCount, setTaskCount] = useState({})
  const [recentActivitiesList, setRecentActivitiesList] = useState([])
  const history = useHistory();

  useEffect(() => {
    if (!!props.location.state) {
      if (!!props.location.state.showalert) {
        confirm({
          confirmation: "You do not have required access.",
          options: { type: "alert" }
        })
        window.history.replaceState({}, document.title)
      }
    }

    //fix BU1-I287 : check for active entities only
    let entity_detail = props.entity_list.find(entity => (entity.status == 3 || entity.status == 2));

    if (!!entity_detail) {
      //getTaskCount()
      getTaskList()
      getRecentActivities()
    }
  }, [])

  const modTypeName = (mod) => {
    switch (mod) {
      case 1: return "Snapshot"

      case 4: return "Money Link"

      case 5: return "Entity"

      case 6: return "Task Manager"

      case 7: return "Banking"

      case 8: return "Users"

      case 9: return "Entity Invite"

      case 10: return "Banking"

      case 11: return "License"

      case 12: return "Account"

      default: return "Default"

    }
  }

  const flagIcon = (modName, flag) => {
    switch (modName) {
      case 'Banking': if (flag == 2) {
        //View Statement
        return <ViewStatement />
      }
      else if (flag == 1) {
        // Edit Bank
        return <BankIcon />
      }
      else
        return null

      case 'Snapshot': if (flag == 1) {
        //Ready
        return <ReadyStatus />
      }
      else if (flag == 2) {
        //Not Ready
        return <NotReadyStatus />
      }
      else if (flag == 3) {
        //Procesed
        return <ProcessedStatus />
      }
      else if (flag == 4) {
        //Linked
        return <LinkedStatus />
      }
      else if (flag == 5) {
        // Synced
        return <SyncedStatus />
      }
      else if (flag == 6) {
        // Not Processed
        return <NotProcessedStatus />
      }
      else
        return null

      case 'Money Link': if (flag == 1) {
        //Ready
        return <ReadyStatus />
      }
      else if (flag == 2) {
        //Not Ready
        return <NotReadyStatus />
      }
      else if (flag == 3) {
        //Procesed
        return <ProcessedStatus />
      }
      else if (flag == 4) {
        //Linked
        return <LinkedStatus />
      }
      else if (flag == 5) {
        // Synced
        return <SyncedStatus />
      }
      else if (flag == 6) {
        // Not Processed
        return <NotProcessedStatus />
      }
      else
        return null

      case 'Task Manager': if (flag == 1) {
        // Open
        return <OpenStatus />
      }
      else if (flag == 2) {
        // Closed
        return <FinishedStatus />
      }
      else if (flag == 3) {
        // Overdue
        return <OverdueStatus />
      }
      else
        return null

      default: return (<span style={{ cursor: 'pointer' }} className="icon-Group-25071 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>)
    }
  }

  const iconArrow = (amount_type) => {
    switch (amount_type) {
      // case 0: return "fa-long-arrow-up red-color"

      // case 1: return "fa-long-arrow-down gren-color"

      // case 2: return "fa-long-arrow-up red-color"

      // default: return "d-none"

      case 0: return "icon-Red-arrow "

      case 1: return "icon-Green-arrow "

      case 2: return "icon-Red-arrow "

      default: return "d-none"
    }
  }

  const getRecentActivities = () => {
    RecentActivities.getRecentActivities().then(
      (data) => {
        if (data.response_code == 200) {
          setRecentActivitiesList(data.result.recent_activities)
        }
        else {
          toast.error(data.message)
        }
      }
    )
  }

  const getTaskList = () => {
    TaskManagerService.getHomePageTasks().then(
      (data) => {
        if (data.response_code == 200) {
          setTaskCount(data.result.count)
          setTaskList(data.result.tasks)
        } else {
          toast.error(data.message);
        }
      });
  }

  const goToTaskManager = (e) => {
    history.push('task-manager/create/' + e.target.id)
  }

  const goToView = (item) => {
    //console.log(item)
    const mod = modTypeName(item.mod_type)
    //console.log(mod)

    switch (mod) {
      case "Snapshot": history.push(`snapshot/evidence/create/${item.mod_id}`)
        break;

      case "Money Link": history.push(`money-link/${item.mod_id}`)
        break;

      case "Entity": history.push(`profile-management/entities/create/${item.mod_id}`)
        break;

      case "Task Manager": history.push(`task-manager/create/${item.mod_id}`)
        break;

      case "Users": history.push(`profile-management/user/create/${item.mod_id}`)
        break;
      case "Entity Invite": if (item.mod_id) {
        history.push(`profile-management/pending/${item.mod_id}`)
      }
      else
        history.push(`profile-management/pending`)
        break;

      case "Banking": if (item.flag_type == 1) {
        // Edit bank
        history.push(`financial-institutes`)
      }
      else if (item.flag_type == 2) {
        // financial-institutes-statements
        history.push(`financial-institutes/view/${item.mod_id}`)
      }
      else
        return null
        break;

      case "License": history.push(`licenses`)
        break;

      case "Account": history.push(`profile-management/entities`)
        break;

      default: return null
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let info = <p style={{ textAlign: 'center' }}>
    -
  </p>


  // const {
  //   stepsEnabled,
  //   steps,
  //   initialStep,
  //   hintsEnabled,
  //   hints
  // } = this.state;

  // {/* Intro JS */} 

  const [steps, setSteps] = useState([
    {
      element: ".hello",
      intro: "Hello step"
    },
    {
      element: ".world",
      intro: "World step"
    },
    {
      element: ".alive",
      intro: "alive step"
    }
  ]);

  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);
  const [onExit, setOnExit] = useState(false);

  // const onExit = (e) =>{
  //   const [onExit, setOnExit] = useState(false); 
  // }
  // {/* Intro JS */}


  return (
    <main className="content-wrapper-new">
      <div className="container-fluid container-padding-new">

        <ScrollPage />
        {/* scroll to top bottom on page on click icon  */}
        {/* { 0 == 0 ?
          <div className="top-bottom-icon btbottom-icon cpointer" onClick={() => { window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'smooth'}); }} >
            <span className="icon-Red-arrow icon-tbwhite"></span>
          </div>
          :
          <div className="top-bottom-icon cpointer" onClick={() => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); }} >
            <span className="icon-Red-arrow icon-tbwhite"></span>
          </div>
        } */}
        {/* End  scroll to top bottom on page on click icon*/}


        {/* Profile */}
        <div className="row my-2">
          <div className="col-12">

            <div className="home-profile">
              {!!props.user_detail.qualified_url ?
                <img className="home-profile-img" src={StorageData.getQualifiedFileUrl(props.user_detail.qualified_url)} />
                :
                <div className="home-profile-img">{props.user_detail.full_name.charAt(0)}</div>
              }
              {/* {!!props.user_detail.qualified_url ?
                <div className="home-profile-img">{props.user_detail.full_name.charAt(0)}</div>
                :
                <img className="home-profile-img" src={StorageData.getQualifiedFileUrl(props.user_detail.qualified_url)} />
              } */}

              <div className="home-profile-desc">
                <h4 className="home-ph">Hello {props.user_detail.full_name}</h4>
                <p className="home-pp">Welcome back! see what we got since your last visit</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Profile */}



        {/* Three Section */}
        <div className="row mt-3 mb-">

          {/* Messages */}
          <div className="col-xl-3 col-lg-6 col-md-6 mb-xl-0 mb-4">
            <div className="home-boxs">
              <h2 className="mb-3">Messages</h2>
              <Messages />
            </div>
          </div>
          {/* End Messages */}


          {/* Tasks */}
          <div className="col-xl-3 col-lg-6 col-md-6 mb-xl-0 mb-4">
            <div className="home-boxs d-flex flex-column">
              <h2 className="mb-3">Tasks</h2>

              <div className="home-task-counts mb-2">
                <div className="hm-task-count-boxs orange-bg">
                  <p className="hm-count-title">Overdue</p>
                  <p className="hm-count-char">{taskCount.overdue}</p>
                </div>
                <div className="hm-task-count-boxs sky-blue-bg">
                  <p className="hm-count-title">Open</p>
                  <p className="hm-count-char">{taskCount.open}</p>
                </div>
                <div className="hm-task-count-boxs green-bg">
                  <p className="hm-count-title">Done</p>
                  <p className="hm-count-char">{taskCount.done}</p>
                </div>
              </div>

              {taskList.map((item) => {
                return (
                  <div className="home-task-list" key={item.id}>
                    <div className="d-flex">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.task}</Tooltip>}>
                        <p className="hm-head-title" id={item.id} onClick={goToTaskManager}>{item.task.length > 25 ? item.task.slice(0, 25).concat('...') : item.task}</p>
                      </OverlayTrigger>
                    </div>
                    <div className="hm-task-date">
                      <p className="">{item.assigned_to}</p>
                      <p className="">Due: {displayDateFormate(item.due_date)} </p>
                    </div>
                  </div>
                )
              })}

              <p className="mt-auto mb-0"><Link to="/task-manager" className="d-block blue-text s-sbold mt-3" >View All</Link></p>
            </div>
          </div>
          {/* End Tasks */}



          {/* My Recent Activities */}
          <div className="col-xl-6">
            <div className="home-boxs">
              <h2 className="mb-3">My Recent Activities</h2>

              <Table responsive className="bottonBorderTable">
                {/* <colgroup>
                  <col width="160px" />
                  <col width="" />
                  <col width="150px" />
                  <col width="50px" />
                  <col width="150px" />
                </colgroup> */}

                <colgroup>
                  <col width="20%" />
                  <col width="" />
                  <col width="20%" />
                  <col width="50px" />
                  <col width="20%" />
                </colgroup>

                <thead>
                  <tr>
                    <th scope="col" className="">Module</th>
                    <th scope="col" ></th>
                    <th scope="col" ></th>
                    <th scope="col" ></th>
                    <th scope="col" className="text-right">Last Activity</th>
                  </tr>
                </thead>

                <tbody>
                  {/* <tr className="cpointer">
                    <td scope="col" >Welcome back! see what we got since your last visit</td>
                    <td scope="col" >Welcome back! see what we got since your last visit</td>
                    <td scope="col" className="text-right">
                     <div className="tdprice">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">2131313</Tooltip>}><span className="tdprice-no">2131313</span></OverlayTrigger>
                          {item.amount_type == 1 && <span className="icon-Green-arrow"></span>}
                          {item.amount_type == 2 && <span className="icon-Red-arrow"></span>}
                        </div>
                    </td>
                    <td scope="col" className="text-right">-</td>
                    <td scope="col" className="text-right">07-03-2024 16:41</td>
                  </tr> */}

                  {recentActivitiesList.map((item, index) => {
                    return <tr className="cpointer " key={index} onClick={() => goToView(item)}>
                      <td scope="col">{modTypeName(item.mod_type)}</td>
                      <td scope="col"><span value={item} >{item.description}</span></td>

                      <td scope="col" className="text-right">
                        <div className="tdprice">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.amount ? `${showAmount(item.amount)}` : info}</Tooltip>}>
                            <span className="tdprice-no">{item.amount ? `${showAmount(item.amount)}` : info}</span>
                          </OverlayTrigger>
                          {item.amount_type == 1 && <span className="icon-Green-arrow"></span>}
                          {item.amount_type == 2 && <span className="icon-Red-arrow"></span>}
                        </div>
                      </td>

                      <td scope="col" className="text-center pl-3">
                        {item.flag_type ? flagIcon((modTypeName(item.mod_type)), item.flag_type) : info}
                      </td>
                      <td scope="col" className="text-right">{item.last_activity.split(" ")[0]} {item.last_activity.split(" ")[1]}</td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          {/* End My Recent Activities */}
        </div>
        {/* End Three Section */}
      </div>
    </main>
  );
}


function mapStateToProps(state) {
  const all_value = state.Session || {};
  const entity_list = all_value.entity_list || [];
  const account_permissions = all_value.account_permissions || [];
  const module_permissions = all_value.module_permissions || [];
  const user_detail = all_value.user_detail || {}
  return {
    entity_list, account_permissions, module_permissions, user_detail
  };
}

export default connect(mapStateToProps)(withRouter(HomeView));