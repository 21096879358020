import React from 'react'
import HorizontalBarChart from '../../../Views/ui/component/HorizontalBarChart'
import Form from 'react-bootstrap/Form';
import { Bar } from 'react-chartjs-2';
import { option } from 'commander';



const TransActivities = (props) => {
    let Entity = [];
    let Linked = [];
    let Processed = []
    let linkedCount = []
    let ProcessedCount = []

    console.log('trans', props.data);

    props.data.map((item) => {

        Entity.push(item.entity)

        item.activities.map((obj) => {
            //console.log('trans', obj);
            if (obj.type == 1) {
                Linked.push(obj.amount)
                linkedCount.push(obj.count)

            } else {
                Processed.push(obj.amount)
                ProcessedCount.push(obj.count)

            }

        })


    })

    let labels = ['Accounted/Synced', 'Pending'];
    let data = {
       //labels: ['Processed/Synced', 'Ready/Not Processed'],
       
        datasets: [
            {
                label: ['Accounted/Synced'],
                data: Linked,
                backgroundColor: props.color1_background
            },
            {
                label: ['Pending'],
                data: Processed,
                backgroundColor: props.color2_background

            },

        ],

    }

    return (
        
            <div className="main-chart-box">
                <div className="col-xl-12"><p className="ft-sz-22 s-bold blu-color">Money Link Activities</p></div>
                <div className="row">
                    <div className="col-xl-10 mb10">
                        <div className="">
                            <Bar data={data}

                                options={{
                                    legend: {
                                        display: false
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }],

                                    },
                                    tooltips: {
                                        enabled: true,
                                        mode: 'nearest',
                                        callbacks: {
                                            title: function(tooltipItem, data) {
                                                return "";
                                            },
                                      
                                        }
                                      }
                                }} />
                            <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2 mr-4 ml-3"><span className="color-code-sec pink"></span> Pending</span>
                            <span className="ft-sz-14 s-sbold blu-color mb-0 mt-2 ml-3 "><span className="color-code-sec yellow"></span> Accounted/Synced</span>
                        </div>
                    </div>
                </div>
            </div>
    
    )
}

export default TransActivities
