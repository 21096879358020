import {
  SET_SNAPSHOT_FILTER_DATA,
  SNAPSHOT_WIDGET_DATA,
  SET_PAGE_NO,
  SET_PER_PAGE_ITEM,SET_SNAP_TEMP_VOUCHER_LIST,
  SET_IS_SNAPSHOT_LOADING, SET_SNAPSHOT_SEARCHING_TEXT, SMS_SET_PAGE_NO, SMS_SET_PER_PAGE_ITEM, SMS_SET_TOTAL_COUNT, SMS_SET_PAYMENT_INFORMATION, SET_FINANCIAL_YEAR_FILTER_NAME, SET_SNAPSHOT_CARD_FILTER, SET_COMMUNICATION_BAR_DIRTY_STATUS,
  SET_SNAPSHOT_DEFAULT_WIDGET_CARDS, SET_FILTER_DATE_RANGE, SET_TEMP_INVENTORY_DATA,
  SET_BULK_SNAPSHOT_LIST
} from "../Actions/Types";

import StorageData from "../../Helpers/StorageData";

const initialState = {
  card_filter: 0,
  sms_page_no: 1,
  sms_per_page_items: 5,
  sms_total_count: 0,
  communication_bar_dirty_state: false,
  //sms_per_page_items: 0,
  sms_total_count: 0,
  default_cards: {
    accounting_card: 3,
    synched_card: 5,
    reimbursement_approval_card: 7,
    linking_card: 9
  },
  bulk_snapshot_list : [],
  page_no: StorageData.getSnapshotPageNo(),
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SNAPSHOT_FILTER_DATA:
      return {
        ...state,
        ...payload
      }
    case SNAPSHOT_WIDGET_DATA:
      return {
        ...state,
        widget_count: payload
      }
    case SET_PAGE_NO:
      StorageData.setSnapshotPageNo(payload)
      return {
        ...state,
        page_no: payload
      }
    case SET_PER_PAGE_ITEM:
      return {
        ...state,
        per_page_items: payload
      }
    case SET_SNAP_TEMP_VOUCHER_LIST:
        return {
            ...state,
            temp_snap_voucher_list: payload
        }
    case SET_IS_SNAPSHOT_LOADING:
      return {
        ...state,
        is_snapshot_loading: payload
      }
    case SET_FINANCIAL_YEAR_FILTER_NAME:
      return {
        ...state,
        financial_year_name: payload
      }
    case SMS_SET_PAGE_NO:
      return {
        ...state,
        sms_page_no: payload
      }
    case SMS_SET_PER_PAGE_ITEM:
      return {
        ...state,
        sms_per_page_items: payload
      }
    case SMS_SET_TOTAL_COUNT:
      return {
        ...state,
        sms_total_count: payload
      }
    case SMS_SET_PAYMENT_INFORMATION:
      return {
        ...state,
        payment_info: payload
      }
      case SET_SNAPSHOT_CARD_FILTER:
        return{
          ...state,
          card_filter: payload
        }
    case SET_SNAPSHOT_SEARCHING_TEXT :
      return {
        ...state,
        search_text: payload
      }
    case SET_COMMUNICATION_BAR_DIRTY_STATUS :
      return {
        ...state,
        communication_bar_dirty_state: payload
      }
    case SET_SNAPSHOT_DEFAULT_WIDGET_CARDS:
      return {
        ...state,
        default_cards: payload
    }
    case SET_FILTER_DATE_RANGE:
      return {
          ...state,
          filter_start_date: payload.start_date,
          filter_end_date: payload.end_date
      }
    case SET_TEMP_INVENTORY_DATA:
      return {
        ...state,
        temp_inventory_data: payload
      }
      case SET_BULK_SNAPSHOT_LIST:
        return{
          ...state,
          bulk_snapshot_list : payload
        }
    default:
      return state;
  }
}