import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function EvidenceIcon(props) {
    return   <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Evidence</Tooltip>}>
        <span className="icon-Group-3182 icon-dt-td"><span className={"path1 " +(props.active ? "skyblues":" grayc-light-icons")}></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>

        {/* <span className={"icon-td icon-Group-3182 "+(props.active ? "": "gray-st-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
      </OverlayTrigger>
}
export default EvidenceIcon;