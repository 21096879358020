import React from 'react';
import { connect } from 'react-redux';
import { setSMSFilterData } from '../../Redux/Actions/Snapshot';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';

class SMSFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_amount: "",
            to_amount: "",
            financial_institute_id: null,
            classification: null
        }
    }
    handleOnChange = (e) => {
		const { name, value } = e.target;
		this.setState({[name]: value})
	}

	handleClassfication = (e) => {
		let { value, name } = e.target;
		this.setState({ [name]: value });
		value = value === 'null' ? null : value;
		// this.props.handleClassification(value)
	}

	amountChange = (name,value) => {
		if(value > 999999999.99){
			return;
		}
		this.setState({ [name]: value});
	}

	applyFilter = () => {
        let filter_data = {
            from_amount: this.state.from_amount,
            to_amount: this.state.to_amount,
            financial_institute_id: this.state.financial_institute_id,
            classification: this.state.classification
        }
        if(!!filter_data.from_amount || !!filter_data.to_amount || !!filter_data.financial_institute_id || !!filter_data.classification){
            this.props.applyFilter("apply", filter_data);
        }else{
			toast.error("Please specify filter criteria");
        }
	}
    
	handleFinancialInstituteChange = (e) => {
		let filter_data = {
			from_amount: null,
			to_amount: null,
			financial_institute_id: e.target.value,
		}
		// this.props.applySMSFilter("apply", filter_data);
	}

	resetFilter = () => {
        this.setState({
            from_amount: "",
			to_amount: "",
        })
        let filter_data = {
            from_amount: "",
            to_amount: "",
        }
        this.props.applyFilter("reset", filter_data);
	
	}

    render() {
        return (
            <div className="main-filter-sec clearfix pt-3 pb-3">
                
                <div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec d-flex align-items-center sms-filter">
                    <div className="col-1  mr-3">
                        <div className="form-group mb-0">
                            <select className="form-control inner-financial-sec" name="classification" onChange={this.handleClassfication} >
                                <option value='null' >All</option>
                                <option value={1}>Business  </option>
                                <option value={2}>Personal  </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2 mr-3">
                        <div className="form-group mb-0">
                            <select className="form-control inner-financial-sec"
                                onChange={this.handleFinancialInstituteChange}
                                value={this.state.financial_institute_id}
                                name="financial_institute_id" >
                                <option value={null} selected>Financial Institute</option>
                                {this.props.financial_institute_list.map((institute, index) => {
                                    return <option value={institute.id}>{institute.bank_name}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-1 c1">
                        <p className="ft-sz-17 s-sbold mb-0">Amount</p>
                    </div>
                    <div className="col-md-2 col-xs-12 c2">
                        <div className="form-group date-sec">
                            <div className="inner-date-sec">
                                <CurrencyInput
                                    type="text"
                                    className={"form-control text-right"}
                                    name="from_amount"
                                    value={this.state.from_amount}
                                    allowNegativeValue={false}
                                    autoComplete="off"
                                    decimalsLimit={2}
                                    prefix="&#8377; "
                                    placeholder="₹ 0"
                                    onValueChange={(value, name) => this.amountChange(name,value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-1 c3">
                        <hr />
                    </div>
                    <div className="col-md-2 col-xs-12 c2">
                        <div className="form-group date-sec">
                            <div className="inner-date-sec">
                                <CurrencyInput
                                    type="text"
                                    className={"form-control text-right"}
                                    name="to_amount"
                                    value={this.state.to_amount}
                                    allowNegativeValue={false}
                                    autoComplete="off"
                                    decimalsLimit={2}
                                    prefix="&#8377; "
                                    placeholder="₹ 0"
                                    onValueChange={(value, name) => this.amountChange(name,value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col col-xs-12">
                        <button type="button" onClick={this.applyFilter} className="btn apply-btn s-bold greenbtn mb-0">Apply</button>
                        <button type="button" onClick={this.resetFilter} className="btn apply-btn s-bold graybtn mb-0">Reset</button>
                    </div>                    
                </div>
                
                
                {/* <div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec d-flex align-items-center sms-filter">
                    <div className="col-1 c1">
                        <p className="ft-sz-17 s-sbold mb-0">Amount</p>
                    </div>
                    <div className="col-lg-2 col-xs-12 c2">
                        <div className="form-group date-sec">
                            <div className="inner-date-sec">
                                <input type="text"
                                    className="form-control"
                                    placeholder="₹ 0"
                                    value={this.state.from_amount}
                                    onChange={this.handleOnChange}
                                    name="from_amount" />
                            </div>
                        </div>
                    </div>
                    <div className="col-1 c3">
                        <hr />
                    </div>
                    <div className="col-lg-2 col-xs-12 c2">
                        <div className="form-group date-sec">
                            <div className="inner-date-sec">
                                <input type="text"
                                    className="form-control"
                                    placeholder="₹ 0"
                                    value={this.state.to_amount}
                                    onChange={this.handleOnChange}
                                    name="to_amount" />
                            </div>
                        </div>
                    </div>

                    <div className="col col-xs-12">
                        <button type="button" onClick={this.applyFilter} className="btn apply-btn s-bold greenbtn mb-0">Apply</button>
                        <button type="button" onClick={this.resetFilter} className="btn apply-btn s-bold graybtn mb-0">Reset</button>
                    </div>                    
                </div> */}

                

                {/* <div className="row inner-row"> */}
                    {/* <div className="col-lg-4 col-xs-12"> */}
                    {/* </div> */}
                {/* </div> */}
                {/* <button type="button" onClick={this.applyFilter} className="btn apply-btn s-bold greenbtn">Apply</button>
                <button type="button" onClick={this.resetFilter} className="btn apply-btn s-bold graybtn">Reset</button> */}
            </div>)
    }
}
function mapStateToProps(state) {
	const all_value = state.Snapshot
	
	return {
		
	};
}

export default connect(mapStateToProps)(SMSFilter);