import React from 'react'
import { Accordion, Card, Form, OverlayTrigger, ProgressBar, Tooltip, Button, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LINKING_CODE } from '../../Helpers/Constants';
import EvidenceFilter from './EvidenceFilter';
import EvidenceList from './EvidenceList';
import Spinner from 'react-bootstrap/Spinner';
import loaderw from '../../Assets/img/buktec-preloader.gif';
import { setEvidenceFilterData } from '../../Redux/Actions/MoneyLink';

class SearchEvidenceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_filter: this.props.show_filter,
      search: '',
      showSearch: true,
      is_permission_granted: this.props.features_permissions_list.includes(LINKING_CODE),
      evidence_tab: this.props.features_permissions_list.includes(LINKING_CODE) ? true : false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.transaction_id != this.props.transaction_id) {
      setTimeout(() => {
        if (!this.props.read_only) {
          if (this.props.toggleNoSupportingDocument === false) {
            if (!this.props.features_permissions_list.includes(LINKING_CODE)) {
              //document.getElementById("searchTab").classList.remove('show');
            }
          } else {
            //document.getElementById("searchTab").classList.add('show');
          }
        } else {
          //document.getElementById("searchTab").classList.remove('show');
        }
      }, 200)
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value })
  }

  resetFilter = (e) => {
    e.preventDefault();
    this.setState({ search: '', showSearch: false }, () => {
      this.props.searchFilter(this.state.search);
    })
  }

  checkForEnterButton = (e) => {
    e.preventDefault();
    if (e.charCode === 13 && this.state.search) {
      this.searchFilter(e)
    }
  }

  searchFilter = (e) => {
    e.preventDefault();
    this.props.searchFilter(this.state.search);
    this.setState({ showSearch: false })
  }

  toggleFilter = () => {
    //this.setState({ show_filter: !this.state.show_filter })
    this.props.search_toggle();
  }

  render() {
    const is_permission_granted = this.props.features_permissions_list.includes(LINKING_CODE);
    const editable = !this.props.read_only;
    const show_filter = this.props.show_filter
   
    //console.log(this.props.evidences)
    return (
      <div className="position-relative evidences_moneyin">

        {!editable && <div className="no-evi-popup"></div>}

        {/* Loader */}
        {this.props.evidence_list_loading && <><div className='sec-loder-overlay'></div>
          <img className="sec-link-loder" src={loaderw} /></>}
        {/* <Spinner animation="border" variant="" className="money-link-loder" /> */}
        {/* Loader */}

        {/* Evidences Toggle Bar */}
        <div className="row">
          <div className="col-sm-12">
            <div className="form-main-title-new mb-2">
              <h4 className="form-title-heading-new">Evidences</h4>
              <div className="toggle-switch-btn toggle-switch-center">
                <span className="toggle-switch-center">
                  <span className="toggle-btn-sec-text">No</span>
                  <label className="switch">
                    <input type="checkbox"
                      checked={!this.props.no_supporting_document}
                      onChange={this.props.markAsNoSupportingDocument}
                      disabled={!editable}
                      name="voucher_status"
                    />
                    <span className={"slider round "}></span>
                  </label>
                  <span className="toggle-btn-sec-text">Yes</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* End Evidences Toggle Bar */}

        {this.props.no_supporting_document == 0 &&
          <>
            <div className="evi-ss-tab-filter">
              {/* evi-ss-tab */}
              <ul className="evi-ss-tab">
                <li className={(this.props.evidence_tab == 1 ? "active-evi-ss " : "")}
                  onClick={() => this.props.changeEvidenceTab(1)}>Suggested</li>
                <li className={(this.props.evidence_tab == 2 ? "active-evi-ss " : "")}
                  onClick={() => { this.props.changeEvidenceTab(2) }}>Search</li>
              </ul>
              {/* End evi-ss-tab */}

              {/* evi Filter */}
              {this.props.evidence_tab == 2 &&
                <Button variant={"outline-secondary evi-filter-btn  " + ((this.props.evidence_tab == 2 && show_filter) ? " active-filter " : "")}
                  onClick={this.toggleFilter}>
                  Filter <span className="icon-funnel gray-dark-icon filter-icon"></span>
                </Button>
              }
              {/* End evi Filter */}
            </div>
          </>
        }

        {this.props.no_supporting_document == 0 &&
          <>
            {/* <div className="evi_cards_moneyin"> */}
            {(this.props.evidence_tab == 2 && show_filter) &&
              <EvidenceFilter applyFilter={this.props.applyFilter} />
            }
            <EvidenceList
            checkReadyStatus = {this.props.checkReadyStatus}
              handleLinkEvidence={this.props.handleLinkEvidence}
              toggleSaveData={this.props.toggleSaveData}
              evidence_list={this.props.evidence_tab == 1 ? this.props.suggested_evidence_list : this.props.evidences}
              has_next={this.props.has_next}
              has_previous={this.props.has_previous}
              page_no={this.props.page_no}
              total_pages={this.props.total_pages}
              per_page_items={this.props.per_page_items}
              setPageNo={this.props.setPageNo}
              evidence_tab={this.props.evidence_tab}
              editEvidence={this.props.editEvidence}
              viewEvidence={this.props.viewEvidence}
              showLinkHistory={this.props.showLinkHistory}
              isFormDirty={this.props.isFormDirty}
              features_permissions_list={this.props.features_permissions_list}
              transactionAmount={this.props.transactionAmount}
              backup_evidences={this.props.backup_evidences}
              ledger_recommendation_loading={this.props.ledger_recommendation_loading}
              getMoneyLinkLedgerRecommendation={this.props.getMoneyLinkLedgerRecommendation}
            />
            {/* </div> */}
          </>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const evidences = all_value.evidences || [];
  const has_next = all_value.has_next || false;
  const has_previous = all_value.has_previous || false;
  const page_no = all_value.evidence_page_no || 1;
  const total_count = all_value.total_count || 0
  const total_pages = all_value.total_pages || 0;
  const suggested_evidence_list = all_value.suggested_evidence_list || []
  const no_supporting_document = all_value.no_supporting_document;
  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];

  return {
    evidences, has_next, has_previous, total_count, total_pages, page_no, features_permissions_list, suggested_evidence_list, no_supporting_document
  };
}

export default connect(mapStateToProps)(SearchEvidenceTab);