import React from 'react';
//import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from 'react-bootstrap/Table';
import hdfc_bnk_fevi from '../../Assets/img/hdfc-fevicon.png';
import { toast } from 'react-toastify';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';
import Modal from 'react-bootstrap/Modal';
import AccountDetailsHeader from './AccountDetailsHeader';
import AccountStatementList from './AccountStatementsList';
import { setFIRequestId } from '../../Redux/Actions/FinancialInstitute';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import { FI_UPLOAD_ACCOUNT_STATEMENT_CODE, FI_VIEW_ACCOUNT_STATEMENT_CODE } from '../../Helpers/Constants';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import AccountStatementTransactions from './AccountStatementTransactions';
import FinancialYear from "../../Components/BoilerPlate/FinancialYear";
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { Button, Dropdown } from 'react-bootstrap';

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid 

let count = 10;
class FIDetailPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_frame: false,
      isOpen: false,
      yodleedata: {},
      // fi_details: {},
      iframe_link: '',
      financial_institute_request_id: null,
      transactions: [],
      page_no: 1,
      per_page_items: 10,
      total_count: '',
      total_pages: '',
      sort_by: 3,
      sort_order: 2,
      fi_id: this.props.match.params.financial_institute_id,
      fi_request_id: null,

      // Intro js user guid
      // this.state = {
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".dropdown_bankin",
          intro: "You can select the time period to view transactions only for that.",
          position: 'right',
          hideNext: true
        },
        {
          element: ".widgets_bankin",
          intro: "This provided you with your bank details and balances."
        },
        {
          element: ".net_bankin",
          intro: "You can directly fetch your bank statements by clicking here and giving the required credentials.",
          position: 'left'
        },
        {
          element: ".uploadpdf_bankin",
          intro: "You also have an option of uploading the bank provided PDF bank statement here. We can read that too ! Dont worry if you have uploaded the same statement twice. We ignore the duplicates.",
          position: 'left'
        },
        {
          element: ".table_bankin",
          intro: "This table gives you all the transactions. In case you have missed to upload transactions for any interim period, you can see a red line between two transactions.",
          // position: 'bottom'
        }
      ],
      //   };
      // End Intro js user guid


    }

  }

  componentDidMount() {
    //this.props.handlePageChange(1)
    window.addEventListener("message", this.listener);
    this.props.getFinancialInstituteDetails(this.props.match.params.financial_institute_id);
    this.props.getAccountStatementTransactions(this.props.match.params.financial_institute_id, 1)

  }

  listener = (event) => {
    //console.log(event)
    if (event.data.status == "SUCCESS") {
      //console.log(event.data)
      // this.setState({ isOpen: false })
      //alert('aa')
      this.getPollData()
    }
    if (event.data.errors && event.data.errors.length !== 0) {
      this.props.dispatch(setFIRequestId(null))
      this.props.dispatch(setAppilcationLoder(false));
      toast.error('Something went wrong')
    }
  }

  componentWillUnmount() {
    this.setState({ fi_id: null, fi_request_id: null })
    window.removeEventListener('message', this.listener);
  }

  getPollData = () => {
    this.props.dispatch(setAppilcationLoder(true));
    count = count - 1
    //console.log(this.state.fi_request_id, this.state.fi_id)
    if (this.state.fi_request_id && this.state.fi_id) {
      let details = {
        "financial_institute_request_id": parseInt(this.state.fi_request_id),
        "financial_institute_id": parseInt(this.state.fi_id)
      }
      FinancialInstituteService.getPollTransactionData(details).then(async (data) => {
        if (data.response_code === 200) {
          if (data.message == 'Poll' && count > 0) {
            this.getPollData()
          } else {
            this.props.dispatch(setAppilcationLoder(false));
            if (data.message) {
              this.setState({ isOpen: false })
              toast.warning(data.message)
            }
            // message for polling
            await confirm({
              confirmation: "Your statement data is updated. Kindly ensure you are looking at appropriate date range to see the newly added statement details.",
              options: { type: "alert" }
            })

            this.props.updateFinancialInstituteInfo(data.result.response_status, null, data.result.updated_at)
            this.props.getAccountStatementTransactions(this.state.fi_id)
            this.props.getFinancialInstituteDetails(this.state.fi_id);
          }
        } else {
          this.props.updateFinancialInstituteInfo(data.reason.response_status, data.reason.errors, data.reason.updated_at)
          this.props.dispatch(setAppilcationLoder(false));
          toast.error(data.reason.errors)
        }
      })
    }
  }

  shareAccountSatement = (channel) => {
    setTimeout(
      function () {
        this.setState({ isOpen: true, });
      }
        .bind(this),
      1000
    );
    let data = {
      "account_number": this.props.fi_details.account_number,
      "financial_institute_id": this.props.fi_details.id,
      "bank_code": this.props.fi_details.bank_code,
      "aggregation_channel": channel,
      //"location_url": `http://localhost:3000/financial-institutes/view/${this.props.match.params.financial_institute_id}/account-statements/new`,
      "redirect_url": null,
      "location_url": null
    }
    FinancialInstituteService.getURL(data).then(
      (data) => {
        if (data.response_code == 200) {
          let start_index = data.result.link.indexOf("callbackParams")
          let end_index = data.result.link.indexOf("aggregationChannel")
          let id = data.result.link.slice(start_index + 15, end_index - 1);

          this.props.dispatch(setFIRequestId(id))
          this.setState({
            financial_institute_request_id: id,
            iframe_link: new URL(data.result.link),
            fi_request_id: id
          }, () => {

          })

          setInterval(() => {
            if (document.activeElement instanceof HTMLBodyElement) {
              this.setState({ isOpen: false })
              return false;
            }
          }, 800);

          setTimeout(
            function () {
              this.setState({ isOpen: true, });

            }
              .bind(this),
            1000
          );
          // window.open(data.result.link, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=500, top=50")
        } else {
          toast.error(data.message);
        }
      });
  }

  goToList = () => {
    this.props.history.push("/financial-institutes")
  }

  loadIframe = () => {
    // var postData = {
    //   status: "CUSTOM_THEME",
    //   theme: "<style>.header{background-color: #77d7b1;}</style>"
    // }
    // window.postMessage(postData, this.state.iframe_link);

    var postData = {
      status: "CUSTOM_THEME",
      theme: "<style>.header,.bg-progress,.progress{display: none;}</style>"
    }
    //window.postMessage(postData, "https://unifiedtrial.finbit.io");
    window.postMessage(postData, "https://unifiedtrial.finbit.io");

  }

  closeBankingModal = () => {
    this.setState({ isOpen: false })
  }

  // Intro js user guid   
  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  // End Intro js user guid

  getStatus = () => {
    if (this.props.financial_institute_info.response_status == undefined) {
      return ""
    } else if (this.props.financial_institute_info.response_status == 1) {
      return "SUCCESS"
    } else if (this.props.financial_institute_info.response_status == 0) {
      return "PENDING"
    } else {
      return "ERROR"
    }
  }

  render() {
    console.log(this.props)
    // Intro js user guid 
    const {
      stepsEnabled,
      steps,
      initialStep,
      hintsEnabled,
      hints
    } = this.state;
    // End Intro js user guid 
    //console.log(this.props)

    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">
          {/* Intro js user guid */}
          <Steps
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
          {/* End Intro js user guid */}

          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item-new"><Link to={'/home'}>Home</Link></li>
                        <li className="breadcrumb-item-new"><Link to='/financial-institutes'>Banking</Link></li>
                        <li className="breadcrumb-item-new">Account Statements</li>
                      </ol>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h2 className="breadcrumb-title-new">
                        Account Statements
                        {/* <span className="help-icon" > */}
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger>
                        {/* </span> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

          {/* Filter */}
          <div className="row mainFilter-new sec-mb">
            <div className="col-12 d-flex flex-wrap align-items-center gap-10">
              <div className="top-new-filter">
                <FinancialYear
                  financialYearChanged={this.props.financialYearChanged}
                  refreshItem={this.props.refreshTransactionsList}
                />
              </div>
            { this.props.financial_institute_info.gap_count != 0 && this.props.financial_institute_info.gap_count ? 
             <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled"><span className="gap-count-date">&nbsp;{this.props.financial_institute_info.gap_date}</span></Tooltip>}>
                <div className="gap-count-note">
                  <span className="gap-count-badge">{this.props.financial_institute_info.gap_count}</span>
                  <span className="gap-count-text">Gap{this.props.financial_institute_info.gap_count>1?'s':''} found in the statement</span>
                </div>
              </OverlayTrigger>:null}

            </div>
          </div>
          {/* End Filter */}

          {/* Account Details Header */}
          <AccountDetailsHeader
            ledgerNameList={this.props.ledgerNameList}
            fi_details={this.props.fi_details}
            card_details={this.props.card_details}
            show_edit={true}
            updateList={this.props.updateList}
            bank_name_list={this.props.bank_name_list}
            FIList={this.props.FIList}
            getFinancialInstituteDetails={this.props.getFinancialInstituteDetails}
            financial_institute_info={this.props.financial_institute_info}
            default_entity_detail={this.props.default_entity_detail}
          />
          {/* End Account Details Header */}

          {/* Buttons Bar */}
          <div className="row sec-mb">
            {/* Note */}
            <div className="col-xl-7 col-lg-7 col-12 d-flex align-items-center pr-0">
              <p className="size-xvi s-sbold blue-text mb-lg-0">
                {this.props.features_permissions_list.includes(FI_UPLOAD_ACCOUNT_STATEMENT_CODE) &&
                  <>
                    {this.props.fi_details.is_credit_card != undefined && this.props.fi_details.is_credit_card != 0 ? <span>Note: Kindly ensure you have uploaded all credit card statements.<br></br></span> : ""}

                    {this.props.financial_institute_info.response_status != undefined && "Last Status: " + this.getStatus() + " - " + (this.props.financial_institute_info.response_status == 1 ? "Bank statement updated successfully" : this.props.financial_institute_info.errors) + " " + "(" + this.props.financial_institute_info.updated_at + ")"}
                  </>
                }
              </p>
            </div>
            {/* End Note */}

            {/* Buttons */}
            <div className="col-xl-5 col-lg-5 col-12">
              <div className="reset_apply_btn_new">
                {this.props.fi_details.is_netbanking_supported && !this.props.card_details.is_credit_card ?
                  <Button disabled={!this.props.fi_details.is_active || this.props.fi_details.is_netbanking_supported != 1}
                    variant={"success apply_btn_new net_bankin " + (!this.props.fi_details.is_active || this.props.fi_details.is_netbanking_supported != 1 ? " disable-btn-- " : "")}
                    onClick={() => {
                      if (this.props.fi_details.is_active && this.props.fi_details.is_netbanking_supported)
                        this.shareAccountSatement('NETBANKING_AGGREGATION')
                    }}><span className="icon-plus1 size-xvi mr-1"></span> Net Banking
                  </Button>
                  : null
                }

                {this.props.fi_details.is_pdf_statement_supported ?
                  <Button disabled={!this.props.fi_details.is_active || this.props.fi_details.is_pdf_statement_supported != 1}
                    variant={"success apply_btn_new uploadpdf_bankin " + (!this.props.fi_details.is_active || this.props.fi_details.is_pdf_statement_supported != 1 ? " disable-btn-- " : "")}
                    onClick={() => {
                      if (this.props.fi_details.is_active && this.props.fi_details.is_pdf_statement_supported == 1)
                        this.shareAccountSatement('STATEMENT_UPLOAD')
                    }}>
                    Upload PDF
                  </Button>
                  : null
                }

                <Dropdown className='action-new-menu'>
                  <Dropdown.Toggle variant="" id="dropdown-basic" className='success add-new-btn action-btn-height'>
                    <span class="icon-menu-lines white-icon"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <button onClick={() => this.props.getAccountStatementTransactions(this.state.fi_id, this.props.page_no, 1)} >
                      <span class="action-new-icon-sec">
                        <span class="icon-Exports action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                      </span>
                      <span>Export List</span>
                    </button>
                    <hr />
                    {!this.props.hideRefreshItem &&
                      <button onClick={() => this.props.refreshTransactionsList()}>
                        <span class="action-new-icon-sec"><span class="icon-refresh action-new-icon"></span></span>
                        <span>Refresh</span>
                      </button>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* End Buttons */}
          </div>
          {/* Buttons Bar */}

          {/* Bank Popup */}
          <Modal show={this.state.isOpen} id="iframe" className='bank-iframe-popup'>
            <div class="closes-bankp-icon" onClick={() => this.closeBankingModal()}>
              <span class="icon-Cross-with-grey-circle"><span class="path1 bank-blue-icon"></span><span class="path2"></span><span class="path3"></span></span>
            </div>
            <iframe id="iframe1" src={this.state.iframe_link} width="100%" height="600" frameBorder="0" onLoad={() => this.loadIframe()} />
          </Modal>
          {/* End Bank Popup */}

          {/* Table List */}
          <div className="row main-table-listing sec-mb">
            <div className="col-12">
              <div className="table-sec pointer-none table_bankin">
                {this.props.transaction_list.length === 0 ?
                  <h2 className="no-item-list">No items to display</h2>
                  : <AccountStatementTransactions
                    transaction_list={this.props.transaction_list}
                    page_no={this.props.page_no}
                    per_page_items={this.props.per_page_items}
                    total_count={this.props.total_count}
                    total_pages={this.props.total_pages}
                    handlePageChange={this.props.handlePageChange}
                    onSortClick={this.props.onSortClick}
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order} />
                }
              </div>
            </div>
          </div>
          {/* End Table List */}

          <Button variant="outline-success reset_btn_new" onClick={this.props.history.goBack}>Back</Button>
        </div>
      </main >
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.Session || {};
  const features_permissions_list = all_value.features_permissions_list || [];
  const default_entity_detail = all_value.default_entity_detail || {};
  return { features_permissions_list, default_entity_detail }
}

export default connect(mapStateToProps)(withRouter(FIDetailPage));