import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function BankIcon(props) {
    return   <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Bank</Tooltip>}>
 <span className="icon-bank icon-Group-4436 icon-dt-td iconsc"><span className="path1"></span><span className="path2"></span>
                    <span className="path3"></span><span className="path4"></span></span>
      </OverlayTrigger>
}
export default BankIcon;