import React from 'react'
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { setEvidenceFilterData } from '../../Redux/Actions/MoneyLink';
import { Multiselect } from 'multiselect-react-dropdown';
import { DatePickerFormate } from '../../Helpers/DateFormat';
import { allowNumbersOnly } from '../../Utils/RegaxValidation';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Button from 'react-bootstrap/Button';

class EvidenceFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: [{ name: 'Snapshot', id: 1 }
        // , { name: 'Cheque', id: 2 }, { name: 'Invoice', id: 3 }, { name: 'All', id: [1, 2, 3] }
      ],
    }
    this.multiselectRef = React.createRef();
  }

  componentDidMount() {
    let filter_data = {
      "module": [1]
    }
    //console.log(filter_data)
    this.props.dispatch(setEvidenceFilterData(filter_data));
    //this.props.applyFilter("apply");
  }

  setDate = (date, key) => {
    let filter_data = this.props.evidence_filter_data
    filter_data[key] = date
    this.props.dispatch(setEvidenceFilterData(filter_data));
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    if ((name === "from_amount" || name === "to_amount") && allowNumbersOnly(value) === false) {
      return
    }
    let filter_data = this.props.evidence_filter_data
    filter_data[name] = value
    console.log('filter_data  ', filter_data)
    this.props.dispatch(setEvidenceFilterData(filter_data));
  }

  onMultiselectSelect(selectedList, selectedItem) {
    console.log('selectedList  ', selectedList)
    let module
    module = selectedItem.id
    module = [...this.props.module];
    module.push(selectedItem.id)
    let filter_data = {
      "module": module
    }
    //console.log(filter_data)
    this.props.dispatch(setEvidenceFilterData(filter_data));

    // this.setState({ [key]: selectedList })
  }

  onMultiselectRemove(selectedList, removedItem) {
    // this.setState({ [key]: selectedList })
    let module = [...this.props.module];
    let index = module.findIndex(id => id == removedItem.id);
    module.splice(index, 1);
    console.log('module  ', module)

    let filter_data = {
      "module": module
    }
    this.props.dispatch(setEvidenceFilterData(filter_data));
  }

  applyFilter = () => {
    // if (this.props.from_date === '' && this.props.to_date === '' &&
    //   this.props.from_amount === '' && this.props.to_amount === '' && this.props.vendor === '' && this.props.status === '') {
    //   toast.warning("Please specify filter criteria")
    // } else
    this.props.applyFilter("apply");
  }

  resetValues() {
    // By calling the belowe method will reset the selected values programatically
    this.multiselectRef.current.resetSelectedValues();
  }
  resetFilter = () => {
    this.resetValues()
    let filter_data = {
      from_date: '',
      to_date: '',
      from_amount: '',
      to_amount: '',
      module: [1],
      vendor: '',
      status: '',
      evidences: []
    }
    this.props.dispatch(setEvidenceFilterData(filter_data));
    //this.props.applyFilter("reset");
    this.props.applyFilter("apply");
  }

  amountChange = (name, value) => {
    if (value > 999999999.99) {
      return;
    }
    let filter_data = this.props.evidence_filter_data
    filter_data[name] = value;
    this.props.dispatch(setEvidenceFilterData(filter_data));
  }
  render() {
    console.log(this.state.options)
    return (
      <div className=" evi-search-filter-popup">
        {/* <div className="main-filter-popup-new evi-search-filter-popup"> */}
        <div className="row">
          <div className="col-xl-8 col-lg-8">
            <div className="row">
              <div className="col-xl col-lg">
                <div className="form-group">
                  <DatePicker
                    className="form-control datepicker-icon"
                    dateFormat={DatePickerFormate()}
                    onChange={date => this.setDate(date, "from_date")}
                    selected={this.props.from_date}
                    autoComplete="off"
                    placeholderText="From Date"
                    name="from_date" />
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 p-0 d-flex align-items-center">
                <hr className='hr-seprator' />
              </div>
              <div className="col-xl col-lg">
                <div className="form-group">
                  <DatePicker
                    className="form-control datepicker-icon"
                    dateFormat={DatePickerFormate()}
                    onChange={date => this.setDate(date, "to_date")}
                    selected={this.props.to_date}
                    autoComplete="off"
                    placeholderText="To Date"
                    name="to_date" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div className="form-group">
              <Form className="">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Control as="select" name="status" value={this.props.status} onChange={this.handleChange}>
                    <option selected value=''>Accounting Status</option>
                    {/* <option value={2}>Ready</option> */}
                    <option value={3}>Pending</option>
                    <option value={4}>Accounted</option>
                    <option value={5}>Synced</option>
                    {/* <option value={null}>All</option> */}
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
          </div>

          <div className="col-xl-8 col-lg-8">
            <div className="row">
              <div className="col-xl col-lg">
                <div className="form-group">
                  <CurrencyInput
                    type="text"
                    className="form-control text-right"
                    name="from_amount"
                    decimalsLimit={2}
                    prefix="&#8377; "
                    value={this.props.from_amount}
                    onValueChange={(value, name) => this.amountChange(name, value)}
                    allowNegativeValue={false}
                    placeholder="From Amount"
                    onChange={this.handleChange} />
                </div>
              </div>
              <div className="col-xl-1 col-lg-1 p-0 d-flex align-items-center">
                <hr className='hr-seprator' />
              </div>
              <div className="col-xl col-lg">
                <div className="form-group">
                  <CurrencyInput type="text"
                    className="form-control text-right"
                    name="to_amount"
                    decimalsLimit={2}
                    prefix="&#8377; "
                    value={this.props.to_amount}
                    onValueChange={(value, name) => this.amountChange(name, value)}
                    allowNegativeValue={false}
                    placeholder="To Amount"
                    onChange={this.handleChange} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div className="form-group">
              <input type="text" className="form-control" name="vendor"
                value={this.props.vendor} placeholder="Customer/Supplier" onChange={this.handleChange} />
            </div>
          </div>

          {/* <Form> */}
                  {/* <Form.Group controlId="exampleForm.ControlSelect1"> */}
                  {/* <div className={"form-group cursor-cmenu assignby "+(this.props.module.length>0? "assign-select": "")}>
                    <Multiselect
                      ref={this.multiselectRef}
                      placeholder={'Type'}
                      showArrow={true}
                      showCheckbox={true}
                      options={this.state.options} // Options to display in the dropdown
                      selectedValues={[{name: "Snapshot", id: 1}]} // Preselected value to persist in dropdown
                      onSelect={(selectedList, selectedItem) => this.onMultiselectSelect(selectedList, selectedItem)}
                      onRemove={(selectedList, removedItem) => this.onMultiselectRemove(selectedList, removedItem)}
                      // onSelect={this.onSelect} // Function will trigger on select event
                      // onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      disablePreSelectedValues={true}
                      closeOnSelect="false"
                    />
                  </div> */}
                  {/* </Form.Group> */}
                {/* </Form> */}

          <div className="col-12">
            <div className="reset_apply_btn_new">
              <Button type="button" variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
              <Button type="button" variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const evidence_filter_data = all_value.evidence_filter_data || {};
  const from_amount = all_value.evidence_filter_data.from_amount || '';
  const to_amount = all_value.evidence_filter_data.to_amount || '';
  const from_date = all_value.evidence_filter_data.from_date || '';
  const to_date = all_value.evidence_filter_data.to_date || '';
  const module = all_value.evidence_filter_data.module || ''
  const vendor = all_value.evidence_filter_data.vendor || '';
  const status = all_value.evidence_filter_data.status || '';

  return {
    from_date, from_amount, to_amount, to_date, module, vendor, status, evidence_filter_data
  };
}

export default connect(mapStateToProps)(EvidenceFilter);