import React, { Component } from 'react'
import BarCharts from '../../Components/Dashboard/BarCharts/BarCharts';
import BarChartSummary from '../../Components/Dashboard/BarCharts/BarChartSummary';
import DoughnutCharts1 from '../../Components/Dashboard/DoughnutCharts/DoughnutCharts1';
import DoughnutsReimCharts from '../../Components/Dashboard/DoughnutCharts/DoughnutsReimCharts';
import SnapshotMoneyLinkBarChart from '../../Components/Dashboard/BarCharts/SnapshotMoneyLinkBarChart';

export default class BuktecSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      labels: {
        2: 'Not Ready',
        1: 'Ready',
        3: 'Processed',
        4: 'Linked',
        5: 'Synced',
        6: 'Not Processed'
      }
    }
    this.div = React.createRef();
  }

  scroll = (scrollOffset) => {
    this.div.current.scrollLeft += scrollOffset;
  }

  FinanacialInstitutes = () => {
    const buktec_summary_data = this.props.buktec_summary_data;
    return (
      buktec_summary_data.financial_institutes.length != 0 && buktec_summary_data.sequence.includes(1) &&
      <div className="col-12 position-relative w-100">
        <a className="carousel-control-prev dash-carousel-icon" onClick={() => this.scroll(-250)}><span class="icon-Slider-arrow2"></span></a>
        <a className="carousel-control-next dash-carousel-icon" onClick={() => this.scroll(250)}><span class="icon-Slider-arrow-1"></span></a>

        <div className="row dash-slider-carousel-new mt-3 " ref={this.div}>
          {buktec_summary_data.financial_institutes.length > 1 ? <BarChartSummary
            data={buktec_summary_data.financial_institutes}
            color1_background={this.props.color1_background}
            color2_background={this.props.color2_background}
            split_type={this.props.split_type}
          /> : null}
          <BarCharts
            data={buktec_summary_data.financial_institutes}
            color1_background={this.props.color1_background}
            color2_background={this.props.color2_background}
            split_type={this.props.split_type}
          />
        </div>
      </div>
    )
  }

  Reimbursements = () => {
    const buktec_summary_data = this.props.buktec_summary_data;
    return (
      buktec_summary_data.reimbursements.length != 0 && buktec_summary_data.sequence.includes(2) ?
        <div div className="col-xl-6 col-lg-12 dash-card-mb">
          <DoughnutsReimCharts
            data={buktec_summary_data.reimbursements}
            headings='Reimbursements'
            labels={['Approved', 'Rejected', 'Pending']}
            color1_background={this.props.color1_background}
            color2_background={this.props.color2_background}
            color3_background={this.props.color3_background}
          />
        </div> : null
    )
  }

  Snapshots = () => {
    const buktec_summary_data = _.cloneDeep(this.props.buktec_summary_data);

    //fix for bug BU1-I279 - add condition to check data is empty or not 

    let labels = [
      'Total',
      'Draft',
      'Ready',
      'Pending',
      'Accounted',
      'Synced',
      'Linked'
    ]

    let total_amt = 0;

    if (buktec_summary_data.snapshots.length != 0) {
      buktec_summary_data.snapshots.forEach((data) => {
        if (data.status == 1 || data.status == 2) {
          total_amt = total_amt + data.amount;
        }
      })

      buktec_summary_data.snapshots.unshift({ amount: total_amt, status: 0 })
    }

    return (
      buktec_summary_data.snapshots.length != 0 && buktec_summary_data.sequence.includes(4) ?
        <div div className="col-xl-6 col-lg-12 dash-card-mb">
          <SnapshotMoneyLinkBarChart
            title="Snapshots"
            data={buktec_summary_data.snapshots}
            labels={labels}
            color1_background={this.props.color1_background}
            color2_background={this.props.color2_background}
            color3_background={this.props.color3_background}
            color4_background={this.props.color4_background}
          /></div> : null
    )
  }

  MoneyLinks = () => {
    const buktec_summary_data = _.cloneDeep(this.props.buktec_summary_data);
    //fix for bug BU1-I279 - add condition to check data is empty or not 

    let labels = [
      'Total',
      'Draft',
      'Ready',
      'Pending',
      'Accounted',
      'Synced',
      'Linked'
    ]

    let total_amt = 0;

    if (buktec_summary_data.money_links.length != 0) {

      buktec_summary_data.money_links.forEach((data) => {
        if (data.status == 1 || data.status == 2) {
          total_amt = total_amt + data.amount;
        }
      })

      buktec_summary_data.money_links.unshift({ amount: total_amt, status: 0 })
    }

    return (

      buktec_summary_data.money_links.length != 0 && buktec_summary_data.sequence.includes(4) ?
        <div div className="col-xl-6 col-lg-12 dash-card-mb"> <SnapshotMoneyLinkBarChart
          title="Money Link"
          data={buktec_summary_data.money_links}
          labels={labels}
          color1_background={this.props.color1_background}
          color2_background={this.props.color2_background}
          color3_background={this.props.color3_background}
          color4_background={this.props.color4_background}
        /></div> : null
    )
  }

  render() {
    const buktec_summary_data = this.props.buktec_summary_data;
    const sequence = this.props.buktec_summary_data.sequence;
    const order = { 1: this.FinanacialInstitutes(), 2: this.Reimbursements(), 3: this.Snapshots(), 4: this.MoneyLinks() }
    // const order = {2: this.Reimbursements(), 3: this.Snapshots(), 4: this.MoneyLinks()}
    return (
      <div className="buktec-summary-new">
        {(buktec_summary_data.financial_institutes.length == 0 && buktec_summary_data.reimbursements.length == 0 && buktec_summary_data.snapshots.length == 0 && buktec_summary_data.money_links.length == 0) && <h2 className="no-item-list">No items to display</h2>}

        {/* {this.FinanacialInstitutes()} */}

        <div className="row">
          {this.props.buktec_summary_data.sequence && sequence.map((seq, index) => {
            return order.hasOwnProperty(seq) ? order[seq] : null
          })}
        </div>
      </div>
    )
  }
}