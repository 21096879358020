import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserService from '../../../Services/Users/UserService'
import AssignEntitytoUser from './AssignEntitytoUser'
import AssignPermission from './AssignPermission';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import { setEntityListData, setFeatureList, setDefaultRoles } from "../../../Redux/Actions/Users";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { validateUser } from './Validation';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import InputNumber from '../../../Components/BoilerPlate/InputNumber';
import { toast } from 'react-toastify';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav'
import ModuleComponent from './ModuleComponent';
import AccountPermission from './AccountPermission';
import AssignEntityPermissiontoUser from './AssignEntityPermissiontoUser';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';
import DeletePopUp from '../../../Components/BoilerPlate/DeletePopUp';
import { confirm } from '../../../Components/BoilerPlate/Confirm';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid 


class CreateUserView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      default_tab: "",
      same_permission: "0",
      selected_entities: [],
      account_modules: [],
      is_dirty: false,
      user_id: !!this.props.match.params.user_id ? this.props.match.params.user_id : 0,

      full_name: "",
      email: "",
      username: "",
      phone_number: "",
      dialcode_id: "1",
      is_active: "1",
      is_verified: 0,

      selected_entity: {},
      selected_entities_count: 0,
      errors: {},
      show_delete_popup: false,
      account_checked: false,

      entity_modules: [],
      module_list: [],
      save_and_redirect: false,
      next_route: false,
      closed: false,
      search:"",

      // Intro js user guid
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".gdtl_pfin",
          intro: "Enter General Details",
          position: 'top',
        },
        {
          element: ".sep_pfin",
          intro: "Assign Entities to user",
        },
        {
          element: ".gpm_pfin",
          intro: "Assign role or permissions to user",
        },
        // {
        //   element: ".save_pfin",
        //   intro: "Save Entity ",
        // },               
      ],
      // End Intro js user guid

    };
  }

  handleStepChange = () => {
    if (this.state.step === 1)
      this.setState({ step: 2 })
    else
      this.setState({ step: 1 })
  }

  setSamePermission = (same_permission_values, e) => {
    const same_permission = e.target.value;
    this.setState({
      same_permission,
      is_dirty: true
    });

    if (same_permission == 1) {
      // let selected_entity_index = !!this.state.selected_entity_index ? this.state.selected_entity_index : 0;
      // let selected_entity = this.state.selected_entities[selected_entity_index];

      let selected_entity = this.state.selected_entities.find(x => x.checked);
      this.setState({ selected_entity })

      let selected_entities = this.state.selected_entities.map((entity) => {
        if (entity.checked) {
          entity.role_id = selected_entity.role_id;
          entity.custom_rights = selected_entity.custom_rights;
          entity.modules = selected_entity.modules;
        }
        return entity;
      });

      this.setSelectedEntities(selected_entities);
    }
  }

  setAccountModules = (account_modules) => {
    this.setState({ account_modules: [] }, () => this.setState({ account_modules }));
  }

  handleSelectAccount = (e) => {
    const checked = e.target.checked;
    this.setState({ account_checked: checked });
  }

  handleSelectEntity = (e, entity1, index) => {
    const checked = e.target.checked;

    let selected_entities = this.state.selected_entities;
    let entity = selected_entities[index];
    entity.checked = checked;

    selected_entities[index] = entity;
    this.props.dispatch(setEntityListData(selected_entities));

    let selected_entities_count = selected_entities.filter((entity) => entity.checked).length;
    this.setState({ selected_entities, selected_entities_count, is_dirty: true });
  }

  setSelectedEntities = (selected_entities) => {
    this.setState({ selected_entities: [] }, () => this.setState({ selected_entities: selected_entities }));
  }

  getUserDetail = (available_entities) => {
    UserService.getUserDetail(this.state.user_id).then(data => {
      if (data.response_code === 200) {
        this.setState({
          email: data.result.email,
          full_name: data.result.full_name,
          is_active: data.result.is_active.toString(),
          username: data.result.username,
          phone_number: data.result.phone_number,
          dialcode_id: !!data.result.dialcode_id ? data.result.dialcode_id.toString() : "1", // added this line by Ram
          same_permission: !!data.result.assign_to ? data.result.assign_to.toString() : "0",//0->no,1->yes
          is_verified: data.result.is_verified,
        });

        const account_modules = data.result.permissions.account_modules;
        if (account_modules.length) {

          for (let i = 0; i < this.state.account_modules.length; i++) {
            this.state.account_modules[i].permissions.map((perm) => {
              perm.checked = account_modules[0].permission_ids.includes(perm.permission_id);
              return perm;
            });
          }

          /*
          this.state.account_modules[1].permissions.map((perm) => {
            perm.checked = account_modules[0].permission_ids.includes(perm.permission_id);
            return perm;
          });*/
          this.setState({ account_checked: true });
        }

        const entity_modules = data.result.permissions.entity_modules;
        let selected_entities_count = 0;
        var selected_entities = JSON.parse(JSON.stringify(available_entities));
        for (var i in selected_entities) {
          const prev_entity = entity_modules.find(e => e.entity_id == selected_entities[i].id);
          if (prev_entity) {
            selected_entities_count++;
            selected_entities[i].checked = true;
            selected_entities[i].custom_rights = prev_entity.custom_rights;
            selected_entities[i].role_id = !!prev_entity.role_id ? prev_entity.role_id : 0;

            for (var j in selected_entities[i].modules) {
              for (var k in selected_entities[i].modules[j].permissions) {
                if (prev_entity.permission_ids.includes(selected_entities[i].modules[j].permissions[k].permission_id)) {
                  selected_entities[i].modules[j].permissions[k].checked = true;
                }
              }
            }
          }
        }

        this.setState({ "selected_entities": selected_entities }, () => {
          //new added to default first select entity started
          console.log("selected entiry",selected_entities)
          let default_selected = []
          selected_entities.map((item,index) => {
            if(item.checked){
              if(default_selected.length == 0){
                default_selected.push(item.id)
              }
            }
          })
          console.log("array",default_selected)
          //////ended
          let account_permission = data.result.permissions.account_modules;
          let entity_modules = data.result.permissions.entity_modules;
          console.log("entity module",entity_modules)
          let default_tab = "";
          let step = 1;
          let selected_entity = {};
          if (!!entity_modules.length) {
            // newly added to default select entity *started*
              const entity = entity_modules.filter(item => item.entity_id == default_selected[0])
              console.log(entity)
              // end*

            // default_tab = "entity_tab_" + entity_modules[0].entity_id;
            // step = 2;
            // selected_entity = entity_modules[0];
            // selected_entity.id = entity_modules[0].entity_id;
            default_tab = "entity_tab_" + entity[0].entity_id;
            step = 2;
            selected_entity = entity[0]
            selected_entity.id = entity[0].entity_id;
          } else if (!!account_permission.length) {
            default_tab = "account_permission_tab";
            step = 2;
          }
          this.setState({
            selected_entities_count,
            step: step,
            default_tab,
            selected_entity
          });
        }
        );
      }
      this.props.dispatch(setAppilcationLoder(false));
    })
  };

  getUserEntities = () => {
    UserService.getEntitiesList().then(data => {
      if (data.response_code === 200) {
        let available_entities = data.result.entities.map((entity, index) => {
          entity.checked = false;
          entity.role_id = 0;
          entity.custom_rights = 0;
          entity.modules = this.state.entity_modules;
          return entity;
        })

        //Edit view
        if (!!this.state.user_id) {
          this.getUserDetail(available_entities);
        } else {
          this.setState({ selected_entities: available_entities });
          this.props.dispatch(setAppilcationLoder(false));
        }

      }
    })
  };

  getModulePermisionList = () => {
    this.props.dispatch(setAppilcationLoder(true));
    UserService.getModulePermisionList().then(data => {
      if (data.response_code === 200) {
        let account_modules = data.result.account_modules;
        account_modules[0].permissions = account_modules[0].permissions.map(perm => {
          perm.checked = false;
          return perm;
        });

        let entity_modules = data.result.entity_modules;
        for (let i in entity_modules) {
          for (let j in entity_modules[i].permissions) {
            entity_modules[i].permissions[j].checked = false;
          }
        }

        this.props.dispatch(setFeatureList(entity_modules));

        this.setState({ entity_modules, account_modules });
        this.getUserEntities();

      }
    })
  };

  getUserRoles = () => {
    UserService.getUserRoles().then(data => {
      if (data.response_code === 200) {
        //let roles = data.result.roles;
        let roles = _.sortBy(data.result.roles, (data) => data.name.toLowerCase());
        this.props.dispatch(setDefaultRoles(roles));
      }
    })
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, is_dirty: true });
  }

  saveUser = (e) => {
    //e.preventDefault();
    this.props.dispatch(setAppilcationLoder(true));
    this.setState({ errors: {}, is_dirty: false });
    let data = {
      "full_name": this.state.full_name,
      "email": this.state.email,
      "username": this.state.username,
      "phone_number": this.state.phone_number,
      "dialcode_id": parseInt(this.state.dialcode_id),
      "is_active": parseInt(this.state.is_active),
      "assign_to": parseInt(this.state.same_permission),//0->no,1->yes
      // "entity_ids":[1,2],//0,entites id
      "permissions": {},

    }
    let errors = validateUser(data);
    if (Object.keys(errors).length === 0) {

      let entity_modules = [];
      let errors = {};
      this.state.selected_entities.forEach(function (entity) {
        if (entity.checked) {
          let new_entity = {};
          new_entity.entity_id = entity.id;
          new_entity.role_id = !!entity.role_id && entity.role_id != "0" ? entity.role_id : null;
          new_entity.custom_rights = entity.custom_rights;
          new_entity.modules = entity.modules.map((module) => {
            return {
              module_id: module.module_id,
              permission_ids: module.permissions.filter(perm => perm.checked).map(perm => perm.permission_id)
            }
          });
          entity_modules.push(new_entity);

          /* 
          * At least one permission validation
          */
          for (let i = 0; i < new_entity.modules.length; i++) {
            if (!!new_entity.modules[i].permission_ids.length) {
              break;
            }
            if ((i + 1) === new_entity.modules.length) {
              errors["entity_" + entity.id] = "Please select at least one permission";
            }
          }

        }
      });
      if (Object.keys(errors).length !== 0) {
        this.setState({ errors: errors })
        this.props.dispatch(setAppilcationLoder(false));
        return true;
      }
      data.permissions.entity_modules = entity_modules;
      data.entity_ids = entity_modules.map((entity) => entity.entity_id);


      let account_modules = this.state.account_modules.map((module) => {
        return {
          module_id: module.module_id,
          permission_ids: module.permissions.filter(perm => perm.checked).map(perm => perm.permission_id)
        }
      });
      data.permissions.account_modules = [{
        "role_id": 1,
        "custom_rights": 1,
        "modules": account_modules
      }];

      if (!!this.state.user_id) {
        let updateData = {
          "id": this.state.user_id,
          "full_name": data.full_name,
          "email": data.email,
          "username": data.username,
          "phone_number": data.phone_number,
          "dialcode_id": data.dialcode_id,
          "is_active": data.is_active,
          "assign_to": data.assign_to,
          "entity_ids": data.entity_ids,
          "permissions": data.permissions
        }
        UserService.updateUser(updateData).then(
          (resp_data) => {
            this.props.dispatch(setAppilcationLoder(false));
            this.setState({
              errors: {}
            });
            if (resp_data.response_code == 200) {
              toast.success(resp_data.message);

              let updateUser = {
                "id": this.state.user_id,
                "created_at": resp_data.result.created_at,
                "entities": resp_data.result.entities,
                "full_name": this.state.full_name,
                "username": this.state.username,
                "is_active": this.state.is_active,
                "is_owner": 0,
                "last_login": resp_data.result.last_login
              }
              this.props.updateUser(updateUser, this.state.user_id);
              this.getUserEntities();
              if (this.state.save_and_redirect) {
                //this.setState({save_and_redirect: false, next_route: false});
                this.props.history.push(this.state.next_route)
              }

              // add update in entity listing
              this.props.addUsernameInEntity(data);

            } else if (resp_data.response_code == 400) {
              toast.error(resp_data.message);
              this.setState({ errors: resp_data.reason, save_and_redirect: false, next_route: false });
            } else {
              toast.error(resp_data.message);
              this.setState({ save_and_redirect: false, next_route: false });
            }
          });

      } else {
        UserService.createUser(data).then(
          (resp_data) => {
            this.props.dispatch(setAppilcationLoder(false));
            this.setState({
              errors: {}
            });
            if (resp_data.response_code == 200) {
              toast.success(resp_data.message);
              this.setState({
                user_id: resp_data.result.id
              });

              let newuser = {
                "id": resp_data.result.id,
                "created_at": resp_data.result.created_at,
                "entities": resp_data.result.entities,
                "full_name": this.state.full_name,
                "username": this.state.username,
                "is_active": this.state.is_active,
                "is_owner": 0,
                "last_login": null
              }
              this.props.newUserAdded(newuser);

              if (this.state.save_and_redirect) {
                this.props.history.push(this.state.next_route)
              } else {
                this.props.history.push('/profile-management/user/create/' + resp_data.result.id)
              }

              // add update in entity listing
              this.props.addUsernameInEntity(data);

              this.getUserEntities();
            } else if (resp_data.response_code == 400) {
              toast.error(resp_data.message);
              this.setState({ errors: resp_data.reason, save_and_redirect: false, next_route: false });
            } else {
              toast.error(resp_data.message);
              this.setState({ save_and_redirect: false, next_route: false });
            }
          });
      }


    } else {
      this.props.dispatch(setAppilcationLoder(false));
      this.setState({
        loading: false,
        errors: errors,
        save_and_redirect: false,
        next_route: false
      });
      toast.error('Wrong input provided')
    }
  }

  toggleDeletePopup = () => {
    this.setState({ show_delete_popup: !this.state.show_delete_popup })
  }

  deleteUser = async () => {
    if (!await confirm({ confirmation: 'Are you sure, you want to delete it?' })) {
      return true;
    }
    this.props.dispatch(setAppilcationLoder(true));
    UserService.deleteUser(this.state.user_id).then(
      (resp_data) => {
        this.props.dispatch(setAppilcationLoder(false));
        this.setState({
          errors: {},
          is_dirty: false
        });
        if (resp_data.response_code == 200) {
          toast.success(resp_data.message);
          this.props.deleteUser(this.state.user_id);
        } else if (resp_data.response_code == 400) {
          toast.error(resp_data.message);
        } else {
          toast.error(resp_data.message);
        }
        this.props.history.push('/profile-management/user')
      });
  }

  resendInvite = () => {
    this.props.dispatch(setAppilcationLoder(true));
    UserService.resendInvite(this.state.user_id).then(
      (resp_data) => {
        this.props.dispatch(setAppilcationLoder(false));
        this.setState({
          errors: {}
        });
        if (resp_data.response_code == 200) {
          toast.success(resp_data.message);

        } else if (resp_data.response_code == 400) {
          toast.error(resp_data.message);
          this.setState({ errors: resp_data.reason });
        } else {
          toast.error(resp_data.message);
        }
      });
  }

  componentDidMount() {
    this.getModulePermisionList();
    this.getUserRoles();
    window.scroll(0, 400)
  }

  componentDidUpdate = (prevProps, prevState) =>{
    if(prevState.step != this.state.step){
      console.log("prevstate",prevState.step,this.state.step)
      this.setState({search:""})
    }
  }

  saveBeforeLeave = (nextRoute) => {
    this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
      this.saveUser()
    });
  }

  closeAction = () => {
    this.setState({ closed: false })
  }

  // Intro js user guid   
  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  // End Intro js user guid
   submitEntityHandler = (e)=>{
    e.preventDefault()
    if (!this.state.search) {
      toast.error("Please specify search criteria");
    }
  }
  filterEntity = (e) => {
    let search = e.target.value;
    console.log("filter",search)
    this.setState({search})
  }
  handleSelect = (selectedKey) =>{
    console.log("selected key",selectedKey)
    this.setState({default_tab:selectedKey})
  }
  render() {

    // Intro js user guid 
    const {
      stepsEnabled,
      steps,
      initialStep,
      hintsEnabled,
      hints
    } = this.state;
    // End Intro js user guid  

    return (
      <>
        {/* Intro js user guid */}
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        />
        {/* End Intro js user guid */}


        <div className="form-inner-blue-new mb-0">
          <Link to="/profile-management/user" onClick={() => this.setState({ closed: true })}>
            <span class="ss-cross"><span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>
          </Link>

          <RouteLeavingGuard
            when={this.state.is_dirty}
            saveChanges={this.saveBeforeLeave}
            closed={this.state.closed}
            closeAction={this.closeAction}
          />

          <DeletePopUp showModal={this.state.show_delete_popup} deleteLoading={this.state.deleteLoading}
            toggleDeletePopUp={this.toggleDeletePopup} deleteItem={this.User} />


          {/* General */}
          <div className="row">
            <div className="col-xl-12">
              <h3 className="pm-form-title">General</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="py-3">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <lable className="lable-input">Name<span className="red-star">*</span></lable>
                      <input type="text"
                        className="form-control"
                        // className={"form-control text-capitalize " + (this.state.errors.full_name ? " wrong-red " : "")}
                        value={this.state.full_name}
                        onChange={this.handleChange}
                        autoComplete="off"
                        name="full_name" />
                      {this.state.errors.full_name && <small className="error_right">{this.state.errors.full_name}</small>}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <lable className="lable-input">Username<span className="red-star">*</span></lable>
                      <input type="text"
                        className="form-control"
                        // className={"form-control " + (this.state.errors.username ? " wrong-red " : "")}
                        value={this.state.username}
                        // disabled={!!this.state.user_id}
                        onChange={this.handleChange}
                        name="username" />
                      {this.state.errors.username && <small className="error_right">{this.state.errors.username}</small>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="gray-bg rounded-lg p-3">
                <div className="row no-gutters ">
                  <div className="col">
                    <div className="form-group mb-0">
                      <lable className="lable-input">Email<span className="red-star">*</span></lable>
                      <input type="text"
                        className="form-control"
                        // className={"form-control " + (this.state.errors.email ? " wrong-red " : "")}
                        value={this.state.email}
                        // disabled={!!this.state.user_id}
                        onChange={this.handleChange}
                        name="email" />
                      {this.state.errors.email && <small className="error_right">{this.state.errors.email}</small>}
                    </div>
                  </div>

                  <div className="col-sm-2 d-flex align-items-center justify-content-center">
                    <p className="size-xvii s-sbold blue-text mb-0">OR</p>
                  </div>

                  <div className="col">
                    <div className="form-group mb-0">
                      <lable className="lable-input">Mobile Number<span className="red-star">*</span></lable>
                      <InputNumber type="text"
                        className="form-control"
                        // className={"form-control " + (this.state.errors.phone_number ? " wrong-red " : "")}
                        value={this.state.phone_number}
                        // disabled={!!this.state.user_id}
                        onChange={this.handleChange}
                        maxLength="10"
                        name="phone_number" />
                      {this.state.errors.phone_number && <small className="error_right">{this.state.errors.phone_number}</small>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <hr className="pm-hr"></hr>
            </div>
          </div>
          {/* End General */}


          {/* Entity & Permissions */}
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 d-flex align-items-center">
              <h3 className="pm-form-title">Entity & Permissions</h3>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12">
              {/* <ul className="process_step_bar">
                <li className="active_process">Assign Entities</li>
                <li className="dot_process">Assign Permission</li>
                <li className=" ">Assigned</li>
              </ul> */}
              <ul className="process_step_bar">
                <li className={this.state.step === 1 ? "dot_process" : "active_process"}>Assign Entities</li>
                <li className={(this.state.step === 1 ? "" : 'dot_process')}>Assign Permission</li>
              </ul>
            </div>
          </div>

          <div className="row my-3">
            {this.state.step === 1 ?
              (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center">
                    <h4 className="pm-steps-title">Step1: <span className="gray-text">Assign Entities to user</span></h4>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 text-right">
                    <Button variant="success apply_btn_new" onClick={this.handleStepChange} disabled={!this.state.account_checked && this.state.selected_entities_count == 0 ? true : false}>Next</Button>
                  </div>
                </>
              )
              :
              (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center">
                    <h4 className="pm-steps-title">Step2: <span className="gray-text">Assign Permission</span></h4>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 text-right">
                    <Button variant="success apply_btn_new" onClick={this.handleStepChange}>Back</Button>
                  </div>
                </>
              )
            }
          </div>

          {/* Tabs */}
          <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.default_tab} activeKey={this.state.default_tab} onSelect={this.handleSelect}>
            <div className="row">
              {/* Tab Left side */}
              <div className="col-xl-4 col-sm-5">
                <Nav variant="pills" className="flex-column pmgt-user-menu">
                  {/* Entity Level */}
                  {this.state.step === 1 ?
                    <React.Fragment>
                      <h3 className="pmgt-menu-heading">Entity Level</h3>
                      <div className="list-group list-group-flush">
                        <div className="pmgt-menu-list">
                          {this.state.selected_entities.map((entity, index) => {
                            // return <a className="checkbox_design checkbox_a list-group-item" key={index}>
                            return <a className="checkbox_design sup-cust-check-lg checkbox_a list-group-item" key={index}>
                              <input
                                className="styled-checkbox"
                                type="checkbox"
                                checked={entity.checked}
                                onChange={(e) => { this.handleSelectEntity(e, entity, index) }}
                                id={"styled-checkbox-8" + index}
                                name={"available_entities" + index}
                              />
                              <label htmlFor={"styled-checkbox-8" + index} className="w-100">{entity.name}</label>
                            </a>
                          })}
                        </div>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {!!this.state.selected_entities_count &&
                        <div className="pmgt-same-permission">
                          <p className="upae-text mb-0">Use same permissions for all entities?</p>
                          <ToggleButtonGroup
                            className="toogle-btn-ra-sm"
                            type="checkbox"
                            value={this.state.same_permission}
                            onChange={this.setSamePermission}
                            name="options">
                            <ToggleButton value="1">Yes</ToggleButton>
                            <ToggleButton value="0">No</ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      }
                      {
                        this.state.selected_entities_count > 4 && 
                        <div className="mainFilter-new">
                          <form onSubmit={this.submitEntityHandler} className="search-new-sec d-flex clearfix"><input type="text" onChange={this.filterEntity} placeholder="Search Entity"/>
                        <button onClick={this.submitEntityHandler} className='btn' type="button"><span className="icon-loupe"></span></button>
                      </form>
                        </div>
                      }
                      <div className="list-group list-group-flush">
                        <div className="pmgt-menu-list mt-3">
                          {(this.state.same_permission == "1") &&
                            <Nav.Item>
                              <Nav.Link eventKey={"entity_tab_" + this.state.selected_entity.id} className='list-group-item'>Entity Permissions</Nav.Link>
                            </Nav.Item>
                          }
                          {this.state.selected_entities.map((entity, index) => {
                            if (!entity.name.toLowerCase().includes(this.state.search.toLowerCase()))
                              return true;
                            if (entity.checked)
                              return <Nav.Item key={index}>
                                <Nav.Link className={this.state.errors["entity_" + entity.id] ? " entity-error-permission list-group-item " : " list-group-item "} eventKey={"entity_tab_" + entity.id} disabled={this.state.same_permission == 1}>{entity.name}</Nav.Link>
                              </Nav.Item>
                          })}
                        </div>
                      </div>
                    </React.Fragment>
                  }
                  {/* End Entity Level */}

                  {/* Account Level */}
                  {(this.state.account_checked || this.state.step === 1) && <h3 className="pmgt-menu-heading mt-3">Account Level</h3>}
                  <div className="list-group list-group-flush">
                    <div className="pmgt-menu-list">
                      {this.state.step === 1 ?
                        <a className="checkbox_design sup-cust-check-lg checkbox_a list-group-item">
                          <input
                            className="styled-checkbox"
                            type="checkbox"
                            checked={this.state.account_checked}
                            onChange={(e) => { this.handleSelectAccount(e) }}
                            id={"styled-checkbox-acc"}
                            name={"account_checkbox"}
                          />
                          <label htmlFor={"styled-checkbox-acc"} className="w-100">Account Permissions</label>
                        </a>
                        :
                        this.state.account_checked &&
                        <Nav.Item>
                          <Nav.Link eventKey="account_permission_tab" className='list-group-item'>Account Level Permissions</Nav.Link>
                        </Nav.Item>
                      }
                    </div>
                  </div>
                  {/* End Account Level */}
                </Nav>
              </div>
              {/* End Tab Left side */}

              {/* Tab Right side */}
              <div className="col-xl-8 col-sm-7 pl-sm-0">
                {this.state.step === 2 ?
                  <div className="pmgt-user-detail">
                    <Tab.Content className="tabcontent">
                      <Tab.Pane eventKey="account_permission_tab">
                        <AccountPermission
                          account_modules={this.state.account_modules}
                          setAccountModules={this.setAccountModules} />
                      </Tab.Pane>
                      {
                        this.state.selected_entities.map((entity, selected_entity_index) => {
                          if (entity.checked)
                            return <Tab.Pane eventKey={"entity_tab_" + entity.id} key={"bh" + selected_entity_index}>
                              <AssignPermission
                                selected_entity={entity}
                                selected_entity_index={selected_entity_index}
                                selected_entities={this.state.selected_entities}
                                same_permission={this.state.same_permission}
                                errors={this.state.errors}

                                handleStepChange={this.handleStepChange}
                                setSelectedEntities={this.setSelectedEntities}
                                setSamePermission={this.setSamePermission} />
                            </Tab.Pane>
                        })
                      }
                    </Tab.Content>
                  </div>
                  :
                  this.state.selected_entities_count === 0 ?
                    <div className="pmgt-user-detail pmuser-step-list">
                      <div>
                        <h2 className="pmuser-step-heading s-reg mb-3">Follow these steps to set up user permissions.</h2>
                        <ol className="pmuser-li-list">
                          <li>Select entities & account for this users.</li>
                          <li>To remove entity. Uncheck the selected entity.</li>
                          <li>Select master account to give account level permissions.</li>
                          <li>Click on "Next" to enable permission assignment.</li>
                        </ol>
                      </div>
                    </div>
                    :
                    <div className="pmgt-user-detail pmuser-step-list">
                      <div>
                        <h2 className="pmuser-step-heading s-reg mb-3">You can add or remove entities for this users.</h2>
                        <ol className="pmuser-li-list">
                          <li>Select entities & account for this users.</li>
                          <li>To remove entity. Uncheck the selected entity.</li>
                          <li>Select master account to give account level permissions.</li>
                          <li>Click on "Next" to enable permission assignment.</li>
                        </ol>
                      </div>
                    </div>
                }
              </div>
              {/* End Tab Right side */}
            </div>
          </Tab.Container>
          {/* End Tabs */}
          {/* End Entity & Permissions */}
        </div>

        {/* Buttons */}
        <div className="row profile-save-btn mt-3">
          <div className="col-sm-8">
            <div className="d-flex align-items-center gap-10">
              <div className="active-in-btn-sec">
                <ToggleButtonGroup
                  className="toogle-btn-ra"
                  type="radio"
                  value={this.state.is_active}
                  onChange={(values, e) => this.handleChange(e)}
                  name="is_active">
                  <ToggleButton value="1">Active</ToggleButton>
                  <ToggleButton value="0">Inactive</ToggleButton>
                </ToggleButtonGroup>
              </div>
              {
                !!this.state.user_id &&
                <Button type="button" variant="outline-success reset_btn_new" onClick={this.deleteUser}>Delete</Button>
              }
              {
                !!this.state.user_id && (this.state.is_verified == 0) &&
                <Button type="button" variant={"outline-success reset_btn_new " + (this.state.is_active == 1 ? " " : " d-none")} onClick={this.resendInvite}>Resend invite</Button>
              }
            </div>
          </div>

          {/* <div className="col-xl-4 col-lg-4 col-md-2 text-right d-flex justify-content-end"> */}
          <div className="col-sm-4 text-right d-flex justify-content-end">
            <Button type="button" variant={"outline-success reset_btn_new " + (this.state.step == 1 ? "d-none" : "")} onClick={this.saveUser}>Save</Button>
          </div>
        </div>
        {/* End Buttons */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const all_value = state.User || {};
  const available_entities = all_value.available_entities || [];
  const entity_modules = all_value.feature_list || [];
  const default_roles = all_value.default_roles || [];

  return {
    available_entities, entity_modules, default_roles
  };
}

export default connect(mapStateToProps)(withRouter(CreateUserView));