import React from 'react'
import { setSnapshotFilterData, setSnapshotSearchText } from "../../Redux/Actions/Snapshot";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { changeDateFormate } from '../../Helpers/DateFormat';
import { AddNew, ReadyStatus, NotReadyStatus, ProcessedStatus, LinkedStatus, SyncedStatus, ArrowUpDownIcon } from '../IcoonNew';
import StorageData from "../../Helpers/StorageData";
import ConfirmPopup from '../../Components/BoilerPlate/ConfirmPopup';
import { displayDateFormate } from '../../Helpers/DateFormat';
import _lodash from "lodash";
import { checkEmptyObject } from '../../Utils/ArrayFunction';
import AppliedFilterTag from '../../Components/BoilerPlate/AppliedFilterTag';
import { toast } from 'react-toastify';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import { showAmount } from '../../Helpers/Currency';

import {
	CREATE_BUSSINESS_SNAPSHOT_CODE, MANAGE_OTHER_USER_SNAPSHOT_CODE, VIEW_ONLY_SNAPSHOT_CODE, CREATE_REIMBUSRMENT_SNAPSHOT_CODE,
	APPROVE_REIMBURSMENT_SNAPSHOT_CODE, ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE, SMART_SCAN_SNAPSHOT_CODE
} from '../../Helpers/Constants';
import { Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import CurrencyInput from 'react-currency-input-field';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import { snapshotTypes } from '../../Helpers/SnapshotConstant';

class CreateSnapShotSideList extends React.Component {
	constructor(props) {
		super(props);

		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_BUSSINESS_SNAPSHOT_CODE);
		this.MANAGE_OTHER_USER_SNAPSHOT = this.PERMISSION_LIST.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);
		this.VIEW_ONLY_SNAPSHOT = this.PERMISSION_LIST.includes(VIEW_ONLY_SNAPSHOT_CODE);
		this.CREATE_REIMBUSRMENT_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE);
		this.APPROVE_REIMBURSMENT_SNAPSHOT = this.PERMISSION_LIST.includes(APPROVE_REIMBURSMENT_SNAPSHOT_CODE);
		this.ACCESS_BUSSINESS_SMS_SNAPSHOT = this.PERMISSION_LIST.includes(ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE);
		this.SMART_SCAN_SNAPSHOT = this.PERMISSION_LIST.includes(SMART_SCAN_SNAPSHOT_CODE);

		this.VIEW_ONLY_SNAPSHOT = this.VIEW_ONLY_SNAPSHOT &&
			!(this.CREATE_REIMBUSRMENT_SNAPSHOT || this.CREATE_BUSSINESS_SNAPSHOT || this.MANAGE_OTHER_USER_SNAPSHOT);

		let is_sms_evidence = props.match.params.evidence_type === 'sms' ? true : false
		let snapshot_id = (!is_sms_evidence && props.match.params.snapshot_id) ? props.match.params.snapshot_id : 0;
		let default_yr = StorageData.getDefaultFinancialYear()
		this.state = {
			is_money_link_flow: StorageData.getTransactionId() ? true : false,
			is_task_manager_flow: StorageData.getTaskManagerId() ? true : false,
			show_leave_task_manager_popup: false,
			show_leave_money_link_popup: false,
			last_hitted_route: '',
			showFilter: false,
			payment_details: [],
			tags: [],
			categories: [],
			error: "",
			search: "",
			snapshot_list: [],
			year_type: default_yr.year_type,
			start_date: default_yr.start_date,
			end_date: default_yr.end_date,
			snapshot_id: snapshot_id,
			is_sms_evidence: is_sms_evidence,
			snapshot_id_found: false,
			page_changed: false
		}
	}

	toggleFilterShow = () => {
		this.setState({ showFilter: !this.state.showFilter })
	}

	handleOnChange = (e) => {
		const { name, value } = e.target;
		let filter_data = {
			[name]: value
		}
		this.props.dispatch(setSnapshotFilterData(filter_data));
	}

	amountChange = (name, value) => {
		if (value > 999999999.99) {
			return;
		}
		let filter_data = {
			[name]: value
		}
		this.props.dispatch(setSnapshotFilterData(filter_data));
	}


	// ----------- Ram -------------

	getSnapshotLists = () => {
		// this.props.dispatch(setSnapshotLoadingStatus(true));
		this.props.dispatch(setAppilcationLoder(true));
		let filter_data = {
			"per_page_items": 0,
			"page_no": 1,
			"date_filter": {
				"start_date": changeDateFormate(this.state.start_date),
				"end_date": changeDateFormate(this.state.end_date)
			},
			"filter_card": 0,
			"filter": 0,
			"search": this.props.search_text,
			"filter_box": {
				"type": null,
				"snapshot_number": null,
				"supplier_name": null,
				"from_amount": null,
				"to_amount": null,
				"payment_status": null,
				"created_by": null,
				"is_star": null,
				"tags": [],
				"category": null,
				"payment_method": null
			},
			"sort_by": 1,
			"sort_order": 2
		};


		// SnapshotService.getSnapshotList(filter_data).then(
		// 	(data) => {
		// 		// this.props.dispatch(setSnapshotLoadingStatus(false));
		// 		this.props.dispatch(setAppilcationLoder(false));
		// 		if (data.response_code == 200) {
		// 			this.props.changeSnapshotList(data.result.snapshots)
		// 		} else {
		// 			// toast.error(data.message);
		// 		}
		// 	});
	}

	checkForEnterButton = (e) => {
		// e.preventDefault();
		// console.log(e)
		if (e.charCode === 13 && this.props.search_text) {
			this.searchFilter(e)
		} else {
			this.props.dispatch(setSnapshotSearchText(e.target.value))
		}
	}

	searchingText = (e) => {
		this.props.dispatch(setSnapshotSearchText(e.target.value))
		if (!e.target.value) {
			this.props.changeIsSearchApplied(false)
			this.props.searchFilter()
		}
	}

	searchFilter = (e) => {
		e.preventDefault()

		if (!this.props.search_text) {
			toast.error("Enter search text")
			return
		}
		this.props.searchFilter(this.props.search_text)
		this.props.changeIsSearchApplied(true)
		this.setState({ showFilter: false })
	}

	resetSearchFilter = (e) => {
		e.preventDefault()
		// this.setState({search: ""})
		this.props.dispatch(setSnapshotSearchText(""))
		this.props.searchFilter("")
		this.props.changeIsSearchApplied(false)
	}

	applyFilter = () => {
		let filter_data = {
			is_star: this.props.is_star,
			from_amount: this.props.from_amount,
			to_amount: this.props.to_amount,
			payment_status: this.props.payment_status,
			created_by: this.props.created_by,
			payment_method: this.props.payment_method,
			voucher_status: this.props.voucher_status,
			tags: this.props.tags,
			supplier_name: this.props.supplier_name,
			snapshot_number: this.props.snapshot_number,
			snapshot_type: this.props.snapshot_type,
			category_id: this.props.category_id,
			source: this.props.source,
			attachment_status: this.props.attachment_status,
			gst_status: this.props.gst_status,
			messages: this.props.messages,
			tds_status: this.props.tds_status,
			is_entity_mismatch: this.props.is_entity_mismatch,
		}
		// let is_empty = checkEmptyObject(filter_data);
		let empty_filter_data = {
			is_star: "", from_amount: "", to_amount: "", category_id: "",
			payment_status: "", voucher_status: "", created_by: "", payment_method: "0_0", tags: "", supplier_name: "", snapshot_number: "", snapshot_type: "", source: "", attachment_status: "",
			gst_status: "", messages: "", tds_status: "", is_entity_mismatch: ""
		}
		let is_empty = _lodash.isEqual(filter_data, empty_filter_data);

		if (!is_empty) {
			this.setState({
				error: ""
			})
			this.props.applyFilter("apply");

			// on filter page changes so apply page changed condition to avoid url redirect condition
			this.setState({ showFilter: false, page_changed: true })
		} else {
			// this.setState({
			// 	error : "Please select at least one field"
			// })
			toast.error("Please select at least one field");
		}
	}

	resetFilter = () => {
		let filter_data = {
			is_star: "", category_id: "", from_amount: "", to_amount: "",
			payment_status: "", voucher_status: "", created_by: "", payment_method: "0_0", tags: "", supplier_name: "", snapshot_number: "", snapshot_type: "", source: "", is_entity_mismatch: "", messages: "", tds_status: "", gst_status: "", customer_name: "", supplier_name: "", attachment_status: "",
		}

		// on filter page changes so apply page changed condition to avoid url redirect condition
		this.setState({ page_changed: true })
		this.props.dispatch(setSnapshotFilterData(filter_data));
		this.props.applyFilter("reset");
	}

	leaveTaskManagerFlow = (is_confirm) => {
		if (is_confirm) {
			StorageData.deleteTaskManagerId()
			this.props.history.push(this.state.last_hitted_route)
			this.setState({ show_leave_task_manager_popup: false, is_task_manager_flow: false })
		} else {
			this.setState({ show_leave_task_manager_popup: false })
		}
	}

	leaveMoneyLinkFlow = (is_confirm) => {
		if (is_confirm) {
			StorageData.deleteTransactionId()
			this.props.history.push(this.state.last_hitted_route)
			this.setState({ show_leave_money_link_popup: false, is_money_link_flow: false })
		} else {
			this.setState({ show_leave_money_link_popup: false })
		}
	}

	evidenceSelected = (snapshot) => {
		if (this.props.match.params.snapshot_id == snapshot.id) {
			return
		}
		if (this.state.is_money_link_flow) {
			this.setState({
				show_leave_money_link_popup: true,
				last_hitted_route: "/snapshot/evidence/create/" + snapshot.id
			})
		}
		else if (this.state.is_task_manager_flow) {
			this.setState({
				show_leave_task_manager_popup: true,
				last_hitted_route: "/snapshot/evidence/create/" + snapshot.id
			})
		} else
			this.props.history.push("/snapshot/evidence/create/" + snapshot.id)
	}

	newEvidence = () => {
		if (this.state.is_money_link_flow) {
			this.setState({
				show_leave_money_link_popup: true,
				last_hitted_route: "/snapshot/evidence/create/"
			})
		}
		else if (this.state.is_task_manager_flow) {
			this.setState({
				show_leave_task_manager_popup: true,
				last_hitted_route: "/snapshot/evidence/create/"
			})
		} else {
			this.props.history.push("/snapshot/evidence/create/");

		}
	}

	componentDidMount() {

		if (this.props.snapshot_list.length == 0) {
			let default_yr = StorageData.getDefaultFinancialYear()
			this.setState({
				start_date: default_yr.start_date,
				end_date: default_yr.end_date
			}, () => this.getSnapshotLists())

		}

		this.setState({ page_changed: false })

		if (this.props.match.params.snapshot_id) {
			setTimeout(() => {
				if (this.props.snapshot_list.length > 0) {

					let elem = document.getElementById("div" + this.props.match.params.snapshot_id);

					//console.log(elem)
					if (!!elem) { //console.log('aa')
						//document.getElementById("div"+this.props.match.params.snapshot_id).scrollIntoView();
						this.setState({ snapshot_id_found: true })
					} else { //console.log('bb')
						this.setState({ snapshot_id_found: false })
					}

				} else {
					this.setState({ snapshot_id_found: false })
				}
			}, 1800);
		}
	}

	componentWillReceiveProps(nextProps) {

		if ((nextProps.match.params.snapshot_id !== this.props.match.params.snapshot_id)) {

			this.setState({
				snapshot_id: nextProps.match.params.snapshot_id ? nextProps.match.params.snapshot_id : 0, page_changed: false
			})

			if (!!this.props.match.params.snapshot_id && this.props.location.state) {
				if (this.props.location.state.save_and_next) {
					setTimeout(() => {
						let elem = document.getElementById("div" + this.props.match.params.snapshot_id);
						if (!!elem) {
							//document.getElementById("div"+this.props.match.params.snapshot_id).scrollIntoView();
						}
					}, 1800);
				}
			} else if (!!this.props.match.params.snapshot_id) {
				setTimeout(() => {
					let elem = document.getElementById("div" + this.props.match.params.snapshot_id);
					if (!!elem) {
						//document.getElementById("div"+this.props.match.params.snapshot_id).scrollIntoView();
					}
				}, 1800);
			}
		}


	}
	getSnapshotList = (page_no) => {
		if (page_no)
			this.props.handlePageChange(page_no)
		this.setState({ page_changed: true })
	}

	getDateRange = () => {
		let default_yr = StorageData.getDefaultFinancialYear();
		if (this.props.financial_year_name === "Custom Date Range") {
			return displayDateFormate(default_yr.start_date) + ' to ' + displayDateFormate(default_yr.end_date)
		} else {
			return this.props.financial_year_name
		}
	}

	getWidgetFilter = () => {
		switch (this.props.filter_card) {
			case 1: return "Draft"
				break;
			case 2: return "Ready"
				break;
			case 3: return "Pending"
				break;
			case 4: return "Accounted"
				break;
			case 5: return "Synced"
				break;
			case 7: return "For Approval"
				break;
			case 8: return "Approved"
				break;
			case 9: return "Linked"
				break;
			case 10: return "Not Linked"
				break;
			default: return "";
		}
	}

	getAmountRange = () => {
		if (this.props.from_amount || this.props.to_amount) {
			let from_amount = this.props.from_amount ? ('₹' + this.props.from_amount + ' ') : 'NA '
			let to_amount = this.props.to_amount ? ('₹' + this.props.to_amount) : 'NA'
			return from_amount + '- ' + to_amount
		}
	}

	getType = () => {
		return this.dataFormatType(parseInt(this.props.snapshot_type))
	}

	getPaymentStatus = () => {
		switch (this.props.payment_status) {
			case "1" || 1: return "Paid";
			case "2" || 2: return "Unpaid";
		}
	}

	getCategory = () => {
		let category = this.props.categories.find(cat => cat.id == this.props.category_id);
		return !!category ? category.name : "";
	}

	getPaymentMethod = () => {
		let finInst = this.props.financial_institutes.find(finance => 2 + "_" + finance.id == this.props.payment_method);
		if (this.props.payment_method == '1_0') {
			return 'Cash'
		} else if (this.props.payment_method == '3_0') {
			return 'Reimbursment'
		} else {
			return !!finInst ? finInst.bank_name + " " + finInst.account_number : "";
		}
	}

	getVoucherStatus = () => {
		if (this.props.voucher_status) {
			if (this.props.voucher_status == 1) {
				return "Voucher Available";
			} else if (this.props.voucher_status == 0) {
				return "Voucher Not Available";
			} else {
				return "Sync Voucher Error";
			}
		}
	}

	getTags = () => {
		let tag = this.props.listed_tags.find(tag => tag.id == this.props.tags);
		return !!tag ? tag.name : ""
	}

	getCreatedBy = () => {
		let created_by = this.props.creaters.find(user => user.id == this.props.created_by)
		return !!created_by ? created_by.full_name : "";
	}

	getStar = () => {
		if (this.props.is_star)
			return this.props.is_star == 1 ? "Star - Yes" : "Star - No"
	}

	getSource = () => {
		if (this.props.source) {
			switch (parseInt(this.props.source)) {
				case 1:
					return 'Bulk'
				case 2:
					return 'Email'
				case 3:
					return 'Single'
			}
		}
	}

	getAttachmentStatus = () => {
		if (this.props.attachment_status)
			return this.props.attachment_status == 1 ? "Attachment - Yes" : "Attachment - No"
	}

	getEntityMismatchStatus = () => {
		if (this.props.is_entity_mismatch)
			return this.props.is_entity_mismatch == 1 ? "Entity Mismatch - Yes" : "Entity Mismatch - No"
	}

	getGstStatus = () => {
		if (this.props.gst_status)
			return this.props.gst_status == 1 ? "GST - Yes" : "GST - No"
	}
	getMessages = () => {
		if (this.props.messages)
			return this.props.messages == 1 ? "Messages - Yes" : "Messages - No"
	}
	getTdsStatus = () => {
		if (this.props.tds_status)
			return this.props.tds_status == 1 ? "TDS - Yes" : "TDS - No"
	}

	dataFormatStatus = (snapshotStatus) => {
		switch (parseInt(snapshotStatus)) {
			case 1:
				return <NotReadyStatus />
			case 2:
				return <ReadyStatus />
			case 4:
				return <ProcessedStatus />
			case 5:
				return <SyncedStatus />
		}
	}

	dataFormatType = (type, row) => {
		return snapshotTypes(type);
	}

	render() {
		//console.log(this.props.has_next, this.props.page_no, this.props.total_pages)

		let snapshot_id_found = true;

		if (this.props.match.params.snapshot_id) {
			let snapFound = this.props.snapshot_list.findIndex(snap => snap.id == this.props.match.params.snapshot_id);

			if (snapFound == -1) {
				snapshot_id_found = false
			} else {
				snapshot_id_found = true;
			}
		}
		//console.log(snapshot_id_found)
		//console.log(this.props.snapshot_list)
		//console.log(this.props.snapshot_detail)

		let elem = document.getElementById("div" + this.props.match.params.snapshot_id);
		if (!!elem) {
			document.getElementById("div" + this.props.match.params.snapshot_id).scrollIntoView();
		}

		return (
			<>
				<div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 sidebar-listing">
					<ConfirmPopup
						toggleConfirmPopup={this.state.show_leave_money_link_popup}
						message="The actions you performed on Money Link will be lost. Do you still want to continue?"
						confirmRemove={this.leaveMoneyLinkFlow}
					/>
					<ConfirmPopup
						toggleConfirmPopup={this.state.show_leave_task_manager_popup}
						message="The actions you performed on Task Manager will be lost. Do you still want to continue?"
						confirmRemove={this.leaveTaskManagerFlow}
					/>
					{/* <div className="sidebar-sec-fixed"> */}
					<div className="sidebar-fixed">
						<div className="sidebar-new-header">
							<div className="breadcrumb-header">
								<BreadCrum level={2} />
							</div>
							<h3 className="sidebar-icons">
								{(snapshot_id_found || this.state.page_changed) && <div onClick={() => this.toggleFilterShow()} className={this.props.filter_by == 2 ? 'filler_snapcr active-filter' : 'filler_snapcr'}>
									<span className="icon-filter green-icon s-filter-icon"></span>
								</div>}
								{(this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT) &&
									<span className='s-plus-icon'><AddNew addNew={this.newEvidence} /></span>
								}
							</h3>
						</div>

						{/* sidebar-new-sec */}
						<div className={"sidebar-new-sec " + (this.state.showFilter ? "remove-new-scroller " : "")}>
							{/* Filter Form */}
							{this.state.showFilter ?
								<>
									<div className="side-filter">
										<div className="row">
											<div className="col-12">
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Company, Invoice No.</Tooltip>}>
													<form className="form-group search-side-sec">
														<input type="text"
															className="form-control"
															placeholder="Search"
															name="search"
															value={this.props.search_text}
															onKeyPress={this.checkForEnterButton}
															onChange={this.searchingText}
														/>
														{!this.props.isSearchApplied ?
															<Button className="active-search-icon" onClick={this.searchFilter}>
																<span className="icon-loupe"></span>
															</Button>
															:
															<Button className="active-search-icon crosebtn" onClick={this.resetSearchFilter}>
																<span class="icon-cancel"></span>
															</Button>
														}
													</form>
												</OverlayTrigger>
											</div>

											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.is_star}
														name="is_star" >
														<option selected value="Starred">Starred</option>
														<option value={1}>Yes</option>
														<option value={0}>No</option>
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													{/* <select className="form-control"
														defaultValue=""
														value={this.props.snapshot_type}
														onChange={this.handleOnChange}
														name="snapshot_type">
														<option value="">Type</option>
														<option value="6">Expense Invoice</option>
														<option value="5">E-way bill</option>
														<option value="2">Purchase Invoice</option>
														<option value="4">Purchase order</option>
														<option value="7">Purchase Return</option>
														<option value="1">Sales Invoice</option>
														<option value="3">Sales Return</option>
														<option value="8">Other</option>
													</select> */}

													<select className="form-control"
														defaultValue=""
														value={this.props.snapshot_type}
														onChange={this.handleOnChange}
														name="snapshot_type">
														<option value="">Type</option>
														{this.props.snapshot_types.map((type, index) => {
															return <option key={index} value={type.id}>{type.name}</option>
														})}
													</select>
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<input type="text"
														className="form-control"
														placeholder="Company"
														value={this.props.supplier_name}
														onChange={this.handleOnChange}
														name="supplier_name" />
												</div>
											</div>

											<div className="col-12">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.payment_method}
														name="payment_method">
														<option value="0_0">Payment Method</option>
														<option value={1 + "_" + 0}>Cash</option>
														{
															this.props.financial_institutes.map((bank, i) => {
																return <option key={i} value={2 + "_" + bank.id}>{bank.bank_name + " " + bank.account_number}</option>;
															})
														}
													</select>
												</div>
											</div>

											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.created_by}
														name="created_by" >
														<option value="">Created By</option>
														{
															this.props.creaters.map((created_by, index) => {
																return <option key={index} value={created_by.id}>{created_by.full_name}</option>
															})
														}
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													<select className="form-control"
														value={this.props.tags}
														name="tags"
														defaultValue=""
														onChange={this.handleOnChange}>
														<option value="">Tags</option>
														{
															this.props.listed_tags.map((tag, index) => {
																return <option key={index} value={tag.id}>{tag.name}</option>
															})
														}
													</select>
												</div>
											</div>

											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<input type="text"
														className="form-control"
														placeholder="ID"
														value={this.props.snapshot_number}
														onChange={this.handleOnChange}
														name="snapshot_number" />
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													<select className="form-control"
														value={this.props.category_id}
														name="category_id"
														defaultValue=""
														onChange={this.handleOnChange}>
														<option value="">Categories</option>
														{
															this.props.categories.map((category, index) => {
																return <option key={index} value={category.id}>{category.name}</option>
															})
														}
													</select>
												</div>
											</div>

											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.payment_status}
														name="payment_status" >
														<option value="0">Payment Status</option>
														<option value="1">Paid</option>
														<option value="2">Unpaid</option>
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.voucher_status}
														name="voucher_status">
														<option value="">Voucher Status</option>
														<option value={2}>Sync Voucher Error</option>
														<option value={1}>Voucher Available</option>
														<option value={0}>Voucher Not Available</option>
													</select>
												</div>
											</div>
										</div>

										<div className="row no-gutters">
											<div className="col-lg-5 col-sm-12">
												<div className="form-group">
													<CurrencyInput type="text"
														className="form-control text-right"
														placeholder="From Amount "
														decimalsLimit={2}
														prefix="&#8377; "
														value={this.props.from_amount}
														allowNegativeValue={false}
														onValueChange={(value, name) => this.amountChange(name, value)}
														name="from_amount" />
												</div>
											</div>
											<div className="col">
												<hr className="hr-seprator" />
											</div>
											<div className="col-lg-5 col-sm-12">
												<div className="form-group">
													<CurrencyInput type="text"
														className="form-control text-right"
														placeholder="To Amount "
														decimalsLimit={2}
														prefix="&#8377; "
														value={this.props.to_amount}
														allowNegativeValue={false}
														onValueChange={(value, name) => this.amountChange(name, value)}
														name="to_amount" />
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.source}
														name="source" >
														<option value="">Source</option>
														<option value={1}>Bulk</option>
														<option value={2}>Email</option>
														<option value={3}>Single</option>
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.messages}
														name="messages" >
														<option value="">Messages</option>
														<option value={1}>Yes</option>
														<option value={0}>No</option>
														<option value={2}>Unread</option>
													</select>
												</div>
											</div>

											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.tds_status}
														name="tds_status">
														<option value="">TDS</option>
														<option value={1}>Yes</option>
														<option value={0}>No</option>
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.gst_status}
														name="gst_status" >
														<option value="">GST</option>
														<option value={1}>Yes</option>
														<option value={0}>No</option>
													</select>
												</div>
											</div>

											<div className="col-lg-6 col-sm-12 pr-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.attachment_status}
														name="attachment_status" >
														<option value="">Attachment</option>
														<option value={1}>Yes</option>
														<option value={0}>No</option>
													</select>
												</div>
											</div>
											<div className="col-lg-6 col-sm-12 pl-lg-1">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.props.is_entity_mismatch}
														name="is_entity_mismatch">
														<option value="">Entity Mismatch</option>
														<option value={1}>Yes</option>
														<option value={0}>No</option>
													</select>
												</div>
											</div>

											<div className="col-12">
												<div className="center_apply_btn_new">
													<Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
													<Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
												</div>
											</div>

											{this.state.error ?
												<div className="col-12">
													<p className="side-filter-error-msg">{this.state.error}</p>
												</div>
												:
												""
											}
										</div>
									</div>
								</>
								:
								<>
									{/* Tags */}
									<div className="sidebar-tag-list">
										<AppliedFilterTag tagValue={this.getDateRange()} />
										<AppliedFilterTag tagValue={this.getWidgetFilter()} />
										{(this.props.filter_by == 2) ?
											<>
												<AppliedFilterTag tagValue={this.props.bank_name} />
												<AppliedFilterTag tagValue={this.getAmountRange()} />
												<AppliedFilterTag tagValue={this.getCategory()} />
												<AppliedFilterTag tagValue={this.getPaymentMethod()} />
												<AppliedFilterTag tagValue={this.getVoucherStatus()} />
												<AppliedFilterTag tagValue={this.props.supplier_name} />
												<AppliedFilterTag tagValue={this.getPaymentStatus()} />
												<AppliedFilterTag tagValue={this.getTags()} />
												<AppliedFilterTag tagValue={this.getCreatedBy()} />
												<AppliedFilterTag tagValue={this.props.snapshot_number} />
												<AppliedFilterTag tagValue={this.getType()} />
												<AppliedFilterTag tagValue={this.getStar()} />
												<AppliedFilterTag tagValue={this.getSource()} />
												<AppliedFilterTag tagValue={this.getAttachmentStatus()} />
												<AppliedFilterTag tagValue={this.getGstStatus()} />
												<AppliedFilterTag tagValue={this.getMessages()} />
												<AppliedFilterTag tagValue={this.getTdsStatus()} />
												<AppliedFilterTag tagValue={this.getEntityMismatchStatus()} />
											</>
											: <AppliedFilterTag tagValue={this.props.search_text ? this.props.search_text : null} />
										}
									</div>
									{/* End Tags */}
								</>
							}
							{/* End Filter Form */}

							{/* Cards */}
							{!this.state.snapshot_id &&
								<div className="sidebar-cards active">
									<h3 className="new-evidance">New Snapshot</h3>
								</div>
							}

							{/* {this.props.snapshot_list.length == 0 ?	
								<div className="sidebar-cards">
									<h3 className="no-item">No items to display</h3>
								</div>
								:<></>
							} */}


							{/* {/ <div className="evi-chq-txt ellips-auto"> /} */}
							{/* <div className="tans-id font-gray mr-1 s-reg">
												{this.dataFormatType(this.props.snapshot_detail.type)}
											</div> */}

							{!snapshot_id_found && !this.state.page_changed ?
								<>
									{/* <div className="recipt-inner-div active" onClick={() => this.evidenceSelected(this.props.snapshot_detail)}>
										<div className="active"></div>
										<div className="clearfix side-card">
											<div className="d-flex align-items-center flex-row">
												<div className="tans-id font-gray s-reg">
													ID #{this.props.match.params.snapshot_id}
												</div>
												<div className="ml-auto d-flex align-items-center">
													
													<div className="color-icon">
														{this.dataFormatStatus(this.props.snapshot_detail.accounting_status == 3 ? this.props.snapshot_detail.entry_status : this.props.snapshot_detail.accounting_status)}
													</div>
												</div>
											</div>
											<div className="side-card-height d-flex justify-content-end flex-column">
												<div className="tans-name font-gray s-sbold ellips-auto">
													{this.props.snapshot_detail.merchant_name}
												</div>
												<div className="d-flex flex-row align-items-baseline">
													<div className="mr-auto dates font-gray s-reg">
														{displayDateFormate(this.props.snapshot_detail.date)}
													</div>
													<div className="d-flex align-items-center justify-content-end s-sbold blu-color text-right amnts">
														<span className="ellips mr-1">
															<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{!!showAmount(this.props.snapshot_detail.total_amount) ? showAmount(this.props.snapshot_detail.total_amount) : ""}</Tooltip>}>
																<span>{!!showAmount(this.props.snapshot_detail.total_amount) ? showAmount(this.props.snapshot_detail.total_amount) : ""}</span>
															</OverlayTrigger>
														</span>
														{<ArrowUpDownIcon expense={this.props.snapshot_detail.expense} />}
													</div>
												</div>
											</div>
										</div>
									</div> */}

									<div className="sidebar-cards cpointer active"
										onClick={() => this.evidenceSelected(this.props.snapshot_detail)}>
										<div className="cards-sec-center">
											<p className="datesc mr-auto ellipsisc">ID #{this.props.match.params.snapshot_id}</p>
											{this.dataFormatStatus(this.props.snapshot_detail.accounting_status == 3 ? this.props.snapshot_detail.entry_status : this.props.snapshot_detail.accounting_status)}
										</div>
										<div className="cards-sec-center">
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.snapshot_detail.merchant_name}</Tooltip>}>
												<p className="titlesc titlesc-2 ellipsisc">{this.props.snapshot_detail.merchant_name}</p></OverlayTrigger>
										</div>
										<div className="cards-sec-center">
											<p className="flex-6 datesc text-left">{displayDateFormate(this.props.snapshot_detail.date)}</p>

											<div className="flex-4 amountsc">
												<span className="ellipsisc">
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className={this.props.snapshot_detail.expense == 0 ? "green-color" : "red-color"}>{!!showAmount(this.props.snapshot_detail.total_amount) ? showAmount(this.props.snapshot_detail.total_amount) : ""}</span></Tooltip>}>
														<span className={this.props.snapshot_detail.expense == 0 ? "green-color" : "red-color"}>{!!showAmount(this.props.snapshot_detail.total_amount) ? showAmount(this.props.snapshot_detail.total_amount) : ""}</span>
													</OverlayTrigger>
												</span>
												{<ArrowUpDownIcon expense={this.props.snapshot_detail.expense} />}
												{/* <span className={this.props.snapshot_detail.expense == 0 ? "green-color" : "red-color"}></span> */}
											</div>
										</div>
									</div>
								</>

								: this.props.snapshot_list.length == 0 ?
									<div className="sidebar-cards">
										<h3 className="no-item">No items to display</h3>
									</div>
									:
									this.props.snapshot_list.map((snapshot, index) => {
										return <>
											<div
												key={index}
												id={"div" + snapshot.id}
												className={"sidebar-cards cpointer" + (this.state.snapshot_id == snapshot.id ? " active" : "")}
												// className={"sidebar-cards cpointer" + (task.id == this.state.task_id ? " active" : "") + (task.status == 2 ? " opacity" : "")} 
												onClick={() => this.evidenceSelected(snapshot)}>
												<div className="cards-sec-center">
													<p className="datesc mr-auto ellipsisc">ID #{snapshot.id}</p>
													{/* <div className="tans-id font-gray mr-1 s-reg">
													{this.dataFormatType(snapshot.type)}
												</div> */}
													{this.dataFormatStatus(snapshot.status)}
												</div>
												<div className="cards-sec-center">
													{/* <p className="titlesc titlesc-2 ellipsisc">{snapshot.name}</p> */}

													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{snapshot.name}</Tooltip>}>
														<p className="titlesc titlesc-2 ellipsisc">{snapshot.name}</p></OverlayTrigger>

												</div>
												<div className="cards-sec-center">
													<p className="flex-6 datesc text-left">{displayDateFormate(snapshot.date)}</p>

													<div className="flex-4 amountsc">
														<span className="ellipsisc">
															<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className={snapshot.expense == 0 ? "green-color" : "red-color"}>{!!showAmount(snapshot.total_amount) ? showAmount(snapshot.total_amount) : ""}</span></Tooltip>}>
																<span className={snapshot.expense == 0 ? "green-color" : "red-color"}>{!!showAmount(snapshot.total_amount) ? showAmount(snapshot.total_amount) : ""}</span>
															</OverlayTrigger>

														</span>
														{/* <span className={snapshot.expense == 0 ? "green-color" : "red-color"}></span> */}
														{<ArrowUpDownIcon expense={snapshot.expense} />}
													</div>
												</div>
											</div>
											{/* {[...Array(10)].map((elementInArray, index) => (
											<div className="" key={index}> Whatever needs to be rendered repeatedly... </div>
										)
										)} */}
										</>
									})
							}
							{/* End Cards */}

						</div>
						{/* End sidebar-sec */}

						{/* Pagination */}
						{/* <div className={(this.props.snapshot_list.length == 0 ? "d-none" : "")}> */}
						<div className={(this.props.snapshot_list.length == 0 || !snapshot_id_found && !this.state.page_changed ? "d-none" : "")}>
							<nav className="sidebar-new-pagination" aria-label="...">
								<ul className="pagination justify-content-end mb-0">
									<li className="page-item" disabled={!(this.props.has_previous && this.props.page_no > 1)} onClick={() => this.props.has_previous ? this.getSnapshotList(this.props.page_no - 1) : ""}>
										<a className={"page-link " + (this.props.has_previous && this.props.page_no > 1 ? "" : " disable-btn")}><span class="icon-Slider-arrow2 pagi-arrow mr-1"></span> Prev</a>
									</li>

									{this.props.has_previous && <li className="page-item"><a className="page-link">...</a></li>}
									<li className="page-item active"><a className="page-link">{this.props.page_no}</a></li>
									{this.props.has_next && <li className="page-item"><a className="page-link">...</a></li>}

									{/* Next */}
									<li className="page-item" disabled={!(this.props.has_next && this.props.page_no < this.props.total_pages)} onClick={() => this.props.has_next ? this.getSnapshotList(this.props.page_no + 1) : ""}>
										<a className={"page-link " + (this.props.has_next && this.props.page_no < this.props.total_pages ? "" : " disable-btn")}>Next <span class="icon-Slider-arrow-1 pagi-arrow ml-1"></span></a>
									</li>
								</ul>
							</nav>
						</div>
						{/* End Pagination */}
					</div>
				</div>
			</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Snapshot || {};
	const from_amount = all_value.from_amount || '';
	const to_amount = all_value.to_amount || '';
	const payment_status = all_value.payment_status || '';
	const voucher_status = all_value.voucher_status || '';
	const created_by = all_value.created_by || '';
	const is_star = all_value.is_star || '';
	const category_id = all_value.category_id || ''
	const search_text = all_value.search_text || "";

	const snapshot_type = all_value.snapshot_type || '';
	const snapshot_number = all_value.snapshot_number || '';
	const supplier_name = all_value.supplier_name || '';
	const tags = all_value.tags || "";
	const payment_method = all_value.payment_method || '0_0';
	const financial_year_name = all_value.financial_year_name || '';
	const card_filter = all_value.card_filter;
	const source = all_value.source || '';

	const attachment_status = all_value.attachment_status || '';
	const is_entity_mismatch = all_value.is_entity_mismatch || '';
	const gst_status = all_value.gst_status || '';
	const messages = all_value.messages || '';
	const tds_status = all_value.tds_status || '';

	const session_values = state.Session || {};
	const features_permissions_list = session_values.features_permissions_list || [];

	const filter_start_date = all_value.filter_start_date || '';
	const filter_end_date = all_value.filter_end_date || '';

	return {
		search_text, is_star, from_amount, to_amount, category_id,
		payment_status, voucher_status, created_by, payment_method, tags, supplier_name, snapshot_number, snapshot_type,
		financial_year_name, card_filter, features_permissions_list, source, attachment_status, gst_status, messages, tds_status, is_entity_mismatch, filter_start_date, filter_end_date
	};
}

export default connect(mapStateToProps)(withRouter(CreateSnapShotSideList));