import React from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
// import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import TaskManagerService from '../../Services/TaskManager/TaskManagerService';
import { setStarTask, setPageNo, setPagePerItem, setTaskLoadingStatus } from "../../Redux/Actions/TaskManager";
import SortingIcon from '../IcoonNew/SortingIcon';
import OpenStatus from '../IcoonNew/OpenStatus';
import FinishedStatus from '../IcoonNew/FinishedStatus';
import OverdueStatus from '../IcoonNew/OverdueStatus';
import DataTableCheckBox from '../../Components/BoilerPlate/DataTableCheckBox';
import { displayDateFormate } from '../../Helpers/DateFormat';
import _ from "lodash";
import Button from 'react-bootstrap/Button';
import HighPriority from '../IcoonNew/HighPriority';
import LowPriority from '../IcoonNew/LowPriority';

//const table = React.createRef()

class TaskList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			task_list: this.props.task_list,
			selected_rows: [],
		}

		this.table = React.createRef()
		this.VIEW_ONLY = _.isEqual([3, 9, 11, 14, 17, 23], this.props.features_permissions_list)
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.task_list !== nextProps.task_list) {
			this.setState({ task_list: nextProps.task_list })
		}
	}

	handlePageChange = (page, sizePerPage) => {
		this.props.dispatch(setPageNo(page))
	}

	goToEditView = (row) => {
		this.props.history.push("task-manager/create/" + row.id)
	}

	handleSizePerPageChange = (sizePerPage) => {
		// When changing the size per page always navigating to the first page
		this.props.dispatch(setPagePerItem(sizePerPage))
	}

	onRowSelect = (row, isSelected, e) => {
		if (isSelected) {
			this.setState({
				selected_rows: [...this.state.selected_rows, row.id],
			})
		} else {
			let selected_rows = this.state.selected_rows;
			selected_rows.splice(selected_rows.indexOf(row.id), 1)
			this.setState({
				selected_rows
			})
		}
	}

	// onSelectAll = (isSelected, rows) => {
	// 	if (isSelected) {
	// 		this.setState({
	// 			selected_rows: rows.map((task) => task.id),
	// 		})
	// 	} else {
	// 		this.setState({
	// 			selected_rows: [],
	// 		})
	// 	}
	// }

	changeStatus = (status) => {
		if (this.state.selected_rows.length == 0) {
			return true;
		}
		let data = {
			"id": this.state.selected_rows,
			"status": status
		}
		TaskManagerService.changeStatus(data).then(
			(data) => {
				if (data.response_code == 200) {
					toast.success('Task status updated');				
					let temp_list = this.state.task_list
					this.props.changeTaskStatus(this.state.selected_rows, status)
					this.setState({ selected_rows: [], task_list: [] }, () => {
						this.setState({ task_list: temp_list })
					});
					// this.refs.table.setState({
					// 	selectedRowKeys: []
					// });
					this.table.current.cleanSelected();
					//this.table.current.selectionContext.selected = [];
					// this.table.current.setState({
					// 	selectedRowKeys: []
					// }); 
				} else {
					toast.error(data.message);
				}
			});
	}

	customMultiSelect(props) {
		const { type, checked, disabled, onChange, rowIndex } = props;
		/*
		* If rowIndex is 'Header', means this rendering is for header selection column.
		*/
		if (rowIndex === 'Header') {
			return null
		} else {
			return (
				<div className='checkbox-personalized'>
					<input
						type={type}
						name={'checkbox' + rowIndex}
						id={'checkbox' + rowIndex}
						checked={checked}
						disabled={disabled}
						onChange={e => onChange(e, rowIndex)}
						ref={input => {
							if (input) {
								input.indeterminate = props.indeterminate;
							}
						}} />
					<label htmlFor={'checkbox' + rowIndex}>
						<div className='check'></div>
					</label>
				</div>);
		}
	}

	//  handleRowSelect = (row, isSelected, e) => {
	//   }

	selectRowProp = {
		columnWidth: '40px',
		onSelect: this.onRowSelect,
		// onSelectAll: this.onSelectAll
		mode: 'checkbox',
		unselectable: this.props.disabled_tasks,

		// unselectable: [ 1, 3 ],

		// clickToSelect: true,		

		// onSelect: this.handleRowSelect
		customComponent: this.customMultiSelect
	};

	displayDate = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"ID #" + row.id}</Tooltip>}>
			<span>{displayDateFormate(cell)}</span>
		</OverlayTrigger>
	}

	renderShowsTotal(start, to, total) {
		return (
			<p className="main-pagi-show">
				<span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
			</p>
		);
	}
	_setNoDataFoundOption() {
		if (!this.props.is_task_loading) {
			return "No Task found";
		} else {
			return (
				<p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
			);
		}
	}

	onSortClick = (sort_order, sort_by) => {
		this.props.onSortClick(sort_order, sort_by)
	}

	makeRemoveStar = (e, row) => {
		e.stopPropagation();
		let data = {
			id: row.id,
			is_star: row.is_star == 0 ? 1 : 0
		}
		this.props.dispatch(setStarTask(row.id, data.is_star))
		TaskManagerService.makeRemoveStar(data).then(
			(data) => {
				if (data.response_code == 200) {
				} else {
					this.props.dispatch(setStarTask(row.id, row.is_star))
					toast.error(data.message);
				}
			});
	}

	dataFormatStar = (cell, row) => {
		switch (cell) {
			case 1:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>

			case 0:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
		}
	}

	dataFormatPriority = (cell, row) => {
		switch (cell) {
			case 1: return <HighPriority/>
			case 2: return <LowPriority/>
			default: return "--"
		}
	}

	dataFormatStatus = (cell, row) => {
		switch (cell) {
			case 1: return <OpenStatus />
			case 2: return <FinishedStatus />
			case 3: return <OverdueStatus />
			default: return "--"
		}
	}

	displayTaskName = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	displayAssignedBy = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	displayAssignedto = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	render() {
		const { per_page_items, page_no, total_count } = this.props;
		const task_list = this.state.task_list
		const options = {
			onPageChange: this.handlePageChange,
			onSizePerPageList: this.handleSizePerPageChange,
			page: page_no,  // which page you want to show as default
			sizePerPage: per_page_items,  // which size per page you want to locate as default
			pageStartIndex: 1, // where to start counting the pages
			paginationSize: 5,  // the pagination bar size.
			prePage: 'Prev', // Previous page button text
			nextPage: 'Next', // Next page button text
			firstPage: 'First', // First page button text
			lastPage: 'Last', // Last page button text
			//paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
			// paginationPosition: 'top'  // default is bottom, top and both is all available
			hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: true,// > Hide the going to First and Last page button,
			onRowClick: (row) => {
				this.goToEditView(row);
			},
			noDataText: this._setNoDataFoundOption(),
		};

		return (
			<div className="row main-table-listing">
				<div className="col-12 sec-mb">
					<div className="table-sec">
								{task_list.length === 0 && this.props.is_task_loading === false ?
									// <div className="not-create-sec">
									<h2 className="no-item-list">No items to display</h2>
									// </div>
									:
									<BootstrapTable
										//ref='table' 
										ref={this.table}
										data={task_list} responsive table-bordered
										pagination={true}
										options={options}
										remote
										fetchInfo={{ dataTotalSize: total_count }}
										// remote
										// pagination
										striped
										hover
										condensed
										selectRow={this.selectRowProp}
										keyField='id'
										tableHeaderClass='custom-select-header-class' tableBodyClass='custom-select-body-class'>
										<TableHeaderColumn width="55" dataField='is_star' headerAlign='center' dataAlign='center' dataFormat={(cell, row) => this.dataFormatStar(cell, row)}>
											<SortingIcon onSortClick={this.onSortClick} field_name="Star" field_number="9"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

										<TableHeaderColumn width="120" dataField='date' headerAlign='center' dataAlign='center' dataFormat={this.displayDate}>
											<SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="2"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

										<TableHeaderColumn width="145" dataField='assigned_by' dataFormat={(cell, row) => this.displayAssignedBy(cell)}>
											<SortingIcon onSortClick={this.onSortClick} field_name="Assigned By" field_number="3"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

										<TableHeaderColumn thStyle={{ width: '50%' }} tdStyle={{ width: '50%' }} dataField='task' dataFormat={(cell, row) => this.displayTaskName(cell)}>
											<SortingIcon onSortClick={this.onSortClick} field_name="Task" field_number="4"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

										<TableHeaderColumn width="140" dataField='assigned_to' dataFormat={(cell, row) => this.displayAssignedto(cell)}>
											<SortingIcon onSortClick={this.onSortClick} field_name="Assigned to" field_number="5"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

										<TableHeaderColumn width="120" dataField='due_date' headerAlign='center' dataAlign='center' >
											<SortingIcon onSortClick={this.onSortClick} field_name="Due Date" field_number="6"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
										<TableHeaderColumn width="100" dataField='priority' columnClassName={'tim-0'} thStyle={{ textAlign: 'center' }} tdStyle={{ textAlign: 'center' }} dataFormat={(cell, row) => this.dataFormatPriority(cell, row)} >
											<SortingIcon onSortClick={this.onSortClick} field_name="Priority" field_number="7"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

										<TableHeaderColumn width="100" dataField='status' columnClassName={'tim-0'} thStyle={{ textAlign: 'center' }} tdStyle={{ textAlign: 'center' }} dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>
											<SortingIcon onSortClick={this.onSortClick} field_name="Status" field_number="8"
												sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
									</BootstrapTable>
								}
					</div>
				</div>

				<div className="col-12">
					{(!this.VIEW_ONLY && task_list.length !== 0 ) && <>
						<div className="d-flex gap-15">
							{/* <div className="reset_apply_btn_new"> */}
							<Button variant="outline-success reset_btn_new" onClick={() => { this.changeStatus(2) }}>Mark as Done</Button>
							<Button variant="outline-success reset_btn_new" onClick={() => { this.changeStatus(1) }}>Mark as Open</Button>
							{/* <Button variant="success apply_btn_new" onClick={() => { this.changeStatus(1) }}>Mark as Open</Button> */}
						</div></>
					}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.TaskManager;
	const total_count = all_value.total_count || 0;
	const page_no = all_value.page_no || 1;
	const per_page_items = all_value.per_page_items || 5;
	const is_task_loading = all_value.is_task_loading || false;
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	return {
		total_count, page_no, per_page_items, is_task_loading, features_permissions_list
	};
}

export default connect(mapStateToProps)(withRouter(TaskList));