import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo from '../../Assets/img/logo.png';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import recipt from '../../Assets/img/recipt.png';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
function snapshot(){
  const [comment,setComment]=React.useState(false)
  const [task,setTask]=React.useState(false)
  const [note,setNote]=React.useState(false)
  const [privatemessage,setPmessage]=React.useState(false)
  const [sidfltr,setSidfltr]=React.useState(false)
  
  const [lgShow, setLgShow] = React.useState(false);

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span className="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span className="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><i class="fa fa-bell-o" aria-hidden="true"></i></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span className="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4408"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span className="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-lightbulb"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4402"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5305"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span className="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-to-do-list"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-chat1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4403"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4404"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4405"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
            <div className="sidebar-sec-fixed"> 
              <div className="clearfix">
                <ol className="breadcrumb pull-left">
                  <li className="breadcrumb-item">
                  <Link to={'/home'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Evidence</li>
                </ol>
                <div className="pull-right d-flex new-add-filter-sec">
                  <div className="sidebar-evidence-filter" onClick={()=>setSidfltr(!sidfltr)}>
                  <span className="icon-filter"></span>
                  </div>
                  <div className="add-new-evi-sms">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
                    <span className="icon-plus"></span>
                    </OverlayTrigger>
                  </div>
                </div> 
              </div>
              <hr/>
              
              {/* <div className="btn-group btn-toggle left-on-off-btn pull-left"> 
                <button className="btn btn-lg btn-default active">Evidence</button>
                <button className="btn btn-lg btn-primary ">SMS</button>
              </div> */}
               

              

              <div className="recipt-sidebar-sec">
              {
                sidfltr?<div className="main-evidence-sidefilter">
                  <div className="row">
                    <div className="col-12">
                      
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Custom Date Range</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>    
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <div className="start-end-date-sec btn">
                            <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                        </div>
                        </div>  
                    </div>
                    <div className="col">
                      <hr/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <div className="start-end-date-sec btn">
                            <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                        </div>
                        </div>
                    </div>
                  </div>  
                  <div className="row">    
                    <div className="col-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Star</option>
                            <option>Yes</option>
                            <option>No</option>
                            <option>Both</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Receipt</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                      <div className="form-group">
                      <input type="text" className="form-control" value="" placeholder="Joshi foods" /> 
                      </div>
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Cash</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control text-right" value="" placeholder="From Amount" />  
                        </div>  
                    </div>
                    <div className="col">
                      <hr/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control text-right" value="" placeholder="To Amount" /> 
                        </div>
                    </div>
                  </div> 
                  <div className="row">    
                    <div className="col-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Unpaid</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Food & beverages</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Tags</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="ID" />  
                        </div>  
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-lg-5 col-sm-12">
                      <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Created By</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-xs-12">
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>
                    <div className="col-lg-6 col-xs-12">
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                    </div>
                  </div>
                </div>:null
              }  

                <div className="main-applied-sidefilter d-none">
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Receipt</span>
                    <span className="ft-sz-16 s-sbold blu-color">Last Financial Year</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">₹300 - ₹5000</span>
                    <span className="ft-sz-16 s-sbold blu-color">Unpaid</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Ramesh08</span>
                    <span className="ft-sz-16 s-sbold blu-color">#12345</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Food & beverages</span>
                  </div>
                </div>

                <p className="ft-sz-18 s-sbold blu-color">No Evidences yet</p>
                 <div className="recipt-inner-div invoice-side-list empty-div active">
                    <div className=""></div>
                    <div className="row">
                     <p className="m-0 ft-sz-28 font-colr s-sbold">New Evedence</p>
                    </div>
                 </div>

                <div className="recipt-inner-div active">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Cheques</p>
                        <div className="color-icon">
                        <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 <span class="icon-Green-arrow"></span></span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-2509"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 <span class="icon-Red-arrow"></span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Approvals</p>
                        <div className="color-icon">
                        <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div">
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="recipt-id s-sbold font-colr">
                        ID #2154
                      </div>
                      <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                      {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
                      <div className="date s-sbold font-colr">
                        11-12-2020 
                      </div>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-13 s-sbold evi-chq-text">Evidence</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-pagination clearfix sidebar-pagination">
                  <nav aria-label="Page navigation example">
                    {/* <div className="per-page-sec clearfix">
                      <ul className="pagination pull-right mb-0">
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                        <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                      </ul>
                    </div> */}
                    <ul className="pagination pull-right mb-0">  
                      <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                      <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                      <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                      <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                      <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
              <div className="inner-content-sec">
                <div className="clearfix main-title-btn-sec">
                  <div className="head-title pull-left">
                    <h2 className="s-sbold blu-color m-0">Evidence</h2>
                  </div>
                  <div className="pull-right">
                    
                  </div>
                </div>
                <div className="main-file-item-sec">
                  <div className="cross" onClick={handleShow}>
                    <span>X</span>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-sm-12">
                      <div className="left-inner-content-sec snapshot-uplaod">
                        <div className="main-toggle-btn-sec">
                          <div className="toggle-btn-sec">
                            <span className="toggle-btn-sec-text s-sbold">Quick</span>
                            <label className="switch">
                              <input type="checkbox" checked/>
                              <span className="slider round"></span> 
                            </label>
                            <span className="toggle-btn-sec-text s-sbold">Smart</span>
                          </div>
                          {/* <p className="m-0 ft-sz-12 font-colr text-center">Smart lorem Ipsum is simply dummy </p> */}
                        </div>
                        <div className="upload-file-sec">
                          <h1 className="blu-color">Upload your file</h1>
                          <h1 className="blu-color d-none">File Uploaded</h1>
                          <div className="upload-file-btn">
                            <div className="cross"><span className="cross-span">X</span></div>
                            <span className="icon-plus"></span>
                          </div>
                          <p className="blu-color s-sbold drag-text">Drag Evidence</p>
                          <p className="m-0 font-colr click-text">or click here to attach</p>
                          <p className="m-0 font-colr click-text d-none">Click on file to view</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-sm-12">
                      <div className="right-inner-content-sec">
                      <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Item Details</p>
                           <p className="pull-right"><span className="icon-log icon-round-info-button" onClick={logShow}></span> <span className="id-no-text s-sbold m-0">ID #2154</span> <span className="id-no-next id-no-text s-sbold m-0"  onClick={() => setLgShow(true)}>New</span></p>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                             <form>
                              <div className="form-group">
                                <div className="form-control main-expense-sec clearfix ft-sz-18">
                                  <div className="pull-left">Expense</div>             
                                  <div className="toggle-btn-sec pull-right">
                                    <span className="toggle-btn-sec-text s-sbold">No</span>
                                    <label className="switch">
                                      <input type="checkbox" checked/>
                                      <span className="slider round"></span> 
                                    </label>
                                    <span className="toggle-btn-sec-text s-sbold">Yes</span>
                                  </div>
                                </div>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Type</p>
                                <select className="form-control">
                                  <option value="1" disabled selected>Select your option</option>
                                  <option value="2">Saab</option>
                                  <option value="3">VW</option>
                                  <option value="4">Audi</option>
                                </select>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Date</p>
                                <input type="text" className="form-control input-bg-green" value="12-12-2020"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Supplier Name</p>
                                <input type="text" className="form-control" value="Joshi Foods" />             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Invoice No.</p>
                                <input type="text" className="form-control"/>             
                              </div>
                              <div className="form-group">
                              <p className="lable-input font-colr">Total Amount</p>
                                <input type="text" className="form-control blu-color text-right" />
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Category</p>
                                <select className="form-control">
                                  <option value="" disabled selected>Select your option</option>
                                  <option value="saab">Saab</option>
                                  <option value="vw">VW</option>
                                  <option value="audi">Audi</option>
                                </select>             
                              </div>
                              
                              <div className="form-group">
                                <p className="lable-input font-colr s-bold">Tags</p>
                                <p className="lable-input font-colr">Add New Tag</p>
                                <div className="form-group">
                                  <textarea className="form-control" rows="2"></textarea>            
                                </div>           
                                <div className="tag ft-sz-13 s-reg"><span className="icon-tag-black-shape"></span> #RathodProject</div>  
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Additional Information</p>
                                <input type="text" className="form-control blu-color" placeholder="Any other infromation" />
                              </div>
                            </form>  
                            </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Payment Status</p>
                           <div className="toggle-btn-sec pull-right">
                              <span className="toggle-btn-sec-text s-sbold">Unpaid</span>
                              <label className="switch">
                                <input type="checkbox" checked/>
                                <span className="slider round"></span> 
                              </label>
                              <span className="toggle-btn-sec-text s-sbold">Paid</span>
                            </div>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                             <form className="">
                              <div className="form-group">
                                <p className="lable-input font-colr">Payment Method</p>
                                <select className="form-control">
                                  <option value="" disabled selected>Select your option</option>
                                  <option value="saab">Saab</option>
                                  <option value="vw">VW</option>
                                  <option value="audi">Audi</option>
                                </select>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payment Amount</p>
                                <input type="text" className="form-control text-right blu-color" value="&#8377; 580"/>
                              </div>
                             </form>
                             <form className="main-pay-method-sec mt-4">
                              <div className="cross"><span>X</span></div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payment Method</p>
                                <select className="form-control">
                                  <option value="" disabled selected>Select your option</option>
                                  <option value="saab">Saab</option>
                                  <option value="vw">VW</option>
                                  <option value="audi">Audi</option>
                                </select>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payment Amount</p>
                                <input type="text" className="form-control text-right blu-color" value="&#8377; 580"/>
                              </div>
                             </form>  
                             <form className="main-pay-method-sec mt-4">
                              <div className="cross"><span>X</span></div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payment Method</p>
                                <select className="form-control">
                                  <option value="" disabled selected>Select your option</option>
                                  <option value="saab">Saab</option>
                                  <option value="vw">VW</option>
                                  <option value="audi">Audi</option>
                                </select>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payment Amount</p>
                                <input type="text" className="form-control text-right blu-color" value="&#8377; 580"/>
                              </div>
                             </form>
                             <div className="add-new-method-btn ft-sz-12 s-bold white-color"><span className="icon-plus1 white-color"></span> Add New Method</div>
                             <div className="clearfix"></div>
                             <div className="form-group mt-4">
                               <div className="form-control clearfix">
                                 <p className="ft-sz-18 s-bold bli-color pull-left m-0">Total</p>
                                 <p className="ft-sz-18 s-bold bli-color pull-right m-0">₹580</p>
                               </div>
                             </div>
                            </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">GST Info</p>
                           <div className="toggle-btn-sec pull-right">
                              <span className="toggle-btn-sec-text s-sbold">No</span>
                              <label className="switch">
                                <input type="checkbox" checked/>
                                <span className="slider round"></span> 
                              </label>
                              <span className="toggle-btn-sec-text s-sbold">Yes</span>
                            </div>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                             <form>
                              <div className="form-group">
                                <p className="lable-input font-colr">CGST</p>
                                <input type="text" className="form-control green-bg text-right" value="₹22"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">SGST</p>
                                <input type="text" className="form-control green-bg text-right" value="₹22"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">IGST</p>
                                <input type="text" className="form-control green-bg text-right" value="₹22"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">GST Total Amount</p>
                                <input type="text" className="form-control green-bg text-right" value="₹22"/>             
                              </div>
                            </form>  
                            </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">TDS Applicable</p>
                           <div className="toggle-btn-sec pull-right">
                              <span className="toggle-btn-sec-text s-sbold">No</span>
                              <label className="switch">
                                <input type="checkbox" checked/>
                                <span className="slider round"></span> 
                              </label>
                              <span className="toggle-btn-sec-text s-sbold">Yes</span>
                            </div>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                             <form>
                              <div className="form-group">
                                <p className="lable-input font-colr">TDS %</p>
                                <input type="text" className="form-control  text-right"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">TDS Amount</p>
                                <input type="text" className="form-control  text-right"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">PAN</p>
                                <input type="text" className="form-control  text-right"/>             
                              </div>
                            </form>  
                            </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">SMS</p>
                           {/* <div className="toggle-btn-sec pull-right">
                              <span className="toggle-btn-sec-text s-sbold">No</span>
                              <label className="switch">
                                <input type="checkbox" checked/>
                                <span className="slider round"></span> 
                              </label>
                              <span className="toggle-btn-sec-text s-sbold">Yes</span>
                            </div> */}
                            <p className="ft-sz-14 red-color mb-0 mt-2 pull-right">Select payment method & amount to SMS's</p>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                              {/* <div className="sms-details ">
                                <div className="">
                                  <div className="radio-text-right-sec">
                                    <label for="radioA"><input type="radio" name="radio1" value="A"/></label>  
                                    <div className="text-right-sec">
                                      <p className="date s-sbold font-colr">11-11-2020</p>
                                      <p className="account-text font-colr">
                                        Your a/c no. XXXXXXXXXXX5023
                                        is debited for Rs. 580.00
                                        on 29-11-2020</p>
                                    </div>
                                  </div>  
                                  <div className="sms-amount">
                                  <div className="amount s-sbold blu-color"><span>  ₹580</span></div>
                                  </div>  
                                </div>                
                              </div> */}
                              <div className="scroll-sms-details">
                                <div className="sms-details bg-color">
                                  <div className="">
                                    <div className="radio-text-right-sec">
                                      
                                      <div className="check-box">
                                        <input className="styled-checkbox" id="styled-checkbox-11" type="checkbox" value="value11" />
                                        <label for="styled-checkbox-11" className="label-text blu-color s-reg ft-sz-20 mb-0"></label>
                                      </div>

                                      <div className="text-right-sec">
                                        <p className="date s-sbold font-colr">11-11-2020</p>
                                        <p className="account-text font-colr">
                                          Your a/c no. XXXXXXXXXXX5023
                                          is debited for Rs. 580.00
                                          on 29-11-2020</p>
                                      </div>
                                    </div>  
                                    <div className="sms-amount">
                                    <div className="amount s-sbold blu-color"><span>  ₹580</span></div>
                                    </div>  
                                  </div>                
                                </div>
                                <div className="sms-details">
                                  <div className="radio-text-right-sec">
                                    <label for="radioA"><input type="radio" name="radio1" value="A"/></label>  
                                    <div className="text-right-sec">
                                      <p className="date s-sbold font-colr">11-11-2020</p>
                                      <p className="account-text font-colr">
                                        Your a/c no. XXXXXXXXXXX5023
                                        is debited for Rs. 580.00
                                        on 29-11-2020</p>
                                    </div>
                                  </div>  
                                  <div className="sms-amount">
                                   <div className="amount s-sbold blu-color"><span>  ₹580</span></div>
                                  </div>  
                                </div>
                                <div className="sms-details">
                                  <div className="radio-text-right-sec">
                                    <label for="radioA"><input type="radio" name="radio1" value="A"/></label>  
                                    <div className="text-right-sec">
                                      <p className="date s-sbold font-colr">11-11-2020</p>
                                      <p className="account-text font-colr">
                                        Your a/c no. XXXXXXXXXXX5023
                                        is debited for Rs. 580.00
                                        on 29-11-2020</p>
                                    </div>
                                  </div>  
                                  <div className="sms-amount">
                                   <div className="amount s-sbold blu-color"><span>  ₹580</span></div>
                                  </div>  
                                </div>
                                <div className="sms-details">
                                  <div className="radio-text-right-sec">
                                    <label for="radioA"><input type="radio" name="radio1" value="A"/></label>  
                                    <div className="text-right-sec">
                                      <p className="date s-sbold font-colr">11-11-2020</p>
                                      <p className="account-text font-colr">
                                        Your a/c no. XXXXXXXXXXX5023
                                        is debited for Rs. 580.00
                                        on 29-11-2020</p>
                                    </div>
                                  </div>  
                                  <div className="sms-amount">
                                   <div className="amount s-sbold blu-color"><span>  ₹580</span></div>
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Approval</p>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                             <form>
                              <div className="form-group">
                                <p className="lable-input font-colr">Approval Amount</p>
                                <input type="text" className="form-control text-right"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Remark</p>
                                <textarea className="form-control" rows="4"></textarea>               
                              </div>
                              <div className="approval-btn-sec clearfix">
                                <div className="pull-right">
                                  <button type="button" className="approval-bottom-btn btn save-btn white-color s-bold drk-gry-bg">Reject</button>
                                  <button type="button" className="approval-bottom-btn btn save-btn white-color s-bold">Approve</button>
                                </div>
                              </div>
                            </form>  
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Full screen image start*/}
                <Modal
                  size="lg"
                  show={lgShow}
                  onHide={() => setLgShow(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  centered
                  className="doc-box-file-preview"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <img className="" src={recipt}/>
                  </Modal.Body>
                </Modal>
                {/* Full screen image End*/}
               
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i className="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>



                <Modal
                  show={clog}
                  onHide={logClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="changelog-model-sec"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color"><span className="icon-round-info-button mr-4"></span> Change Log</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="clearfix">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Created</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Changed to Reimbursement</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Approved</td>
                        <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                      </tr>
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>

                <div className="bottom-save-btn clearfix">
                <div className="pull-left">
                  {/* <button className="next-bottom-btn btn ft-sz-21 s-bold white-color"> </button> */}
                  <button type="button" className="next-bottom-btn btn save-btn graybtn">Back</button>
                  <button type="button" className="btn save-btn greenbtn">Download</button>
                  <button type="button" className="btn save-btn white-color graybtn">Delete </button>
                </div>
                  <div className="pull-right">
                    <div className="ready-btn">
                      <select>
                        <option value="audi" selected>Not Ready</option>
                        <option value="volvo">Ready</option>
                      </select> 
                    </div>  
                      <button type="button" className="btn save-btn greenbtn">Save</button>
                  </div>
                </div>
                <div className="bottom-message-sec">
                  <div className="main-bottom-comments-note-sec">
                   {
                    comment?<div className="bottom-comments-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-chat"></span></span>
                        <span className="ft-sz-32 s-sbold">Messages</span>
                      </div>
                      <div className="inner-comment-scroll">
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="gren-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">2 days ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter green-bg s-sbold">
                            <span>S</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">just now</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }

                  {
                    note?<div className="bottom-note-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-Group-2546"></span></span>
                        <span className="ft-sz-32 s-sbold">Notes</span>
                      </div>
                      <div className="inner-note-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="first-letter yellow-bg s-sbold">
                          <span>Y</span>
                        </div>
                        <div className="note-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                        </div>
                      </div>
                      <div className="inner-note-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="first-letter yellow-bg s-sbold">
                          <span>Y</span>
                        </div>
                        <div className="note-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                        </div>
                      </div>
                    </div>:null
                  }  

                  {
                    task?<div className="bottom-task-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-Group-2516"></span></span>
                        <span className="ft-sz-32 s-sbold">Tasks</span>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                          <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                            <p className="ft-sz-18 s-reg red-bg white-color color-div">Overdue</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-24" type="checkbox" value="value011" />
                          <label for="styled-checkbox-24" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                            <p className="ft-sz-18 s-reg yellow-bg white-color color-div">Due in 2 days</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-142" type="checkbox" value="value011" />
                          <label for="styled-checkbox-142" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }  


                  {
                    privatemessage?<div className="bottom-comments-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-chat"></span></span>
                        <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                      </div>
                      <div className="inner-comment-scroll">
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">2 days ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter red-bg s-sbold">
                            <span>S</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">just now</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }

                  </div>

                  <form>
                    <div className="input-group">
                      <div className="message-sec clearfix">
                        <div className="input-group">
                          <input type="text" className="form-control s-sbold ft-sz-22 font-colr pl-3" placeholder="@mention a user" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" className="active" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Comment</button>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Note</button>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Task</button>
                      </div>
                      <div className="message-sec note-input clearfix d-none">
                        <div className="input-group first">
                          <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Task title; @assignee" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" className="active" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <div className="input-group second task-due-date">
                          {/* <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Due date" aria-label="Recipient's username" aria-describedby="basic-addon2"/> */}
                          <span className="s-reg ft-sz-22 font-colr">Due date <span className="icon-calendar"></span></span>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Task</button>
                      </div>
                      <div className="message-sec note-input clearfix d-none">
                        <div className="input-group first">
                          <input type="text" className="form-control s-reg ft-sz-32 font-colr" placeholder="Title" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" className="" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="active" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <div className="input-group second">
                          <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Start typing" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Note</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="main-smartview-sec d-none">
            <div className="">
              <div className="col-xl-6 col-sm-12">
                <div className="inner-smartview-sec">
                  <div className="cross"><span>X</span></div>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                  <div className="smartview-drag-sec">
                    <div className="main-zoom-roted-sec">
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-refresh"></span></button>
                        <button className="btn"><span className="icon-refresh-11"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-magnifying-glass"></span></button>
                        <span className="ft-sz-22 s-reg blu-color">100%</span>
                        <button className="btn"><span className="icon-zoom-in"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-Group-2197"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-full-size1"></span></button>
                      </div>
                      <button type="button" className="btn upload-btn white-color s-bold ft-sz-21">Upload</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="sticky-footer d-none">
          <div className="container">
            <div className="text-center">
              <small>Copyright © Buktec 2021</small>
            </div>
          </div>
        </footer>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default snapshot;