import React, { useState } from 'react'
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from "../../Redux/Actions/Auth";
import SessionManagement from '../../Utils/SessionManagement';
import StorageData from '../../Helpers/StorageData';
import { connect } from "react-redux";
import { withRouter } from 'react-router';


// In the notifications section there were some spana tags instead of span tags I changed them

const Logout = (props) => {
  const user_detail = props.user_detail;
  const default_entity_detail = StorageData.getDefaultEntity() || {};
  
  const dispatch = useDispatch()
  const history = useHistory();

//   function logoutFun() {
    dispatch(logout())
    SessionManagement.RemoveAll();
    StorageData.deleteAllStorageValue();
    StorageData.deleteAllSessionStorageValue();
    history.push("/login");
    window.location.reload();
//   }

  return (
    <div>
    </div>
  )
}


function mapStateToProps(state) {
  const all_value = state.Session || {};
  const notification__value = state.Notification || {};
  const entity_list = all_value.entity_list || [];
  const account_permissions = all_value.account_permissions || [];
  const module_permissions = all_value.module_permissions || [];
  const user_detail = all_value.user_detail || {}
  const notification_count = notification__value.count || 0;
  const default_entity_detail = all_value.default_entity_detail || {}

  return {
    entity_list, account_permissions, module_permissions, user_detail, notification_count, default_entity_detail
  };
}
export default connect(mapStateToProps)(withRouter(Logout));