import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import { Link, withRouter } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ActiveUser, InactiveUser } from '../../IcoonNew'
import UserService from '../../../Services/Users/UserService';
import TagsList from '../../../Components/BoilerPlate/ItemListingOnTable';
import MessageModal from '../../../Components/BoilerPlate/MessageModal';
import { setAppilcationLoder } from "../../../Redux/Actions/App";

/* static ui elements */
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class UserList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      my_entities: 0,
      paid_by_others: 0,
      total: 0,
      show_filter_popup: false,
      show_owner_popup: false
    };
  }

  toggleFilterPopup = () => {
    this.setState({
      show_filter_popup: !this.state.show_filter_popup
    })
  };

  // getUserList = () => {
  //   UserService.getUserList().then(
  //       (data) => {
  //         if(data.response_code == 200) {
  //             this.setState({
  //               users : data.result.users
  //             });
  //           }
  //       });
  // }

  // componentDidMount(){
  //   this.getUserList();
  // }

  toggleMessageModal = () => {
    this.setState({ show_owner_popup: !this.state.show_owner_popup })
  }

  goToEditView = (row) => {
    if (row.is_owner == 1) {
      this.toggleMessageModal()
      return true;
    }
    const { dispatch, history } = this.props;
    history.push({
      pathname: `/profile-management/user/create/` + row.id
    });
  }

  getCaret = (direction) => {
    switch (direction) {
      case 'asc':
        return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
      case 'desc':
        return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
      default:
        return (<span className="icon-Group-4449 sort-both-icon"></span>);
    }
  }

  displayEntities = (cell, row) => {
    // return <TagsList itemList={cell} list_length={20}/>
    let itemList = cell;
    if (itemList !== null && itemList.length !== 0) {
      let string_list = itemList.toString();
      if (string_list.length >= 30) {
        return <span>{string_list.substring(0, 30)} <span className='mr-1'>and</span>
          <OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled" className="tbl-user-ent">
            <div className="p-0" style={{ listStyleType: 'none' }} >
              {/* {itemList.map((item, index) => {
              return <span key={index}>{item}, </span>
            })} */}
              {itemList.join(', ')}
            </div>
          </Tooltip>}>
            <a className="td-view-all">{/* <span> {`${itemList.length} more`} </span> */}View All</a>
          </OverlayTrigger>
        </span>
      } else {
        return <span>{string_list}</span>
      }
    }
  }
  displayLastLogin = (cell, row) => {
    if (!!cell)
      return cell;
    else if (row.is_active == 1)
      return "Invite Not Accepted";
    else
      return "User Inactive";
  }

  displayUsers = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }
  displayUsername = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }

  rowStyleFormat = (row, rowIdx) => {
    if (!!row) {
      if (row.is_owner) {
        return { backgroundColor: '#dbad4054' };
      }
    }
  }

  render() {
    const options = {
      onRowClick: (row) => {
        //console.log(row)
        this.goToEditView(row);
      },
      // defaultSortName: 'created_at',  // default sort column name
      // defaultSortOrder: 'desc'  // default sort order
    };

    return (
      <>        
        <MessageModal showModal={this.state.show_owner_popup} content={"Master user details cannot be edited."} toggleMessageModal={this.toggleMessageModal} />

        <div className="row main-table-listing mt-0 listing-icons">
          <div className="col-12">
            <div className="table-sec mb-0">
              <BootstrapTable ref='table' data={this.props.users} responsive options={options} trStyle={this.rowStyleFormat}>
                <TableHeaderColumn width="150px" tdStyle={td_style} isKey dataField='full_name' dataFormat={(cell, row) => this.displayUsers(cell)} dataSort={true} caretRender={this.getCaret}>User</TableHeaderColumn>
                <TableHeaderColumn width="150px" tdStyle={td_style} dataField='username' dataFormat={(cell, row) => this.displayUsername(cell)} dataSort={true} caretRender={this.getCaret}>User Name</TableHeaderColumn>
                <TableHeaderColumn width="" tdStyle={td_style} dataField='entities' dataSort={true} caretRender={this.getCaret} dataFormat={(cell, row) => this.displayEntities(cell, row)}>Entities Assigned</TableHeaderColumn>
                <TableHeaderColumn width="13%" tdStyle={{ ...td_style, textAlign: 'center' }} thStyle={{ textAlign: 'center' }} dataField='created_at' dataSort={true} caretRender={this.getCaret}>Date Created</TableHeaderColumn>
                <TableHeaderColumn width="18%" tdStyle={{ ...td_style, textAlign: 'center' }} thStyle={{ textAlign: 'center' }} dataField='last_login' dataFormat={(cell, row) => this.displayLastLogin(cell, row)} dataSort={true} caretRender={this.getCaret}>Last Login</TableHeaderColumn>
                <TableHeaderColumn width="100px" thStyle={{ textAlign: 'center' }} tdStyle={{ ...td_style, textAlign: 'center' }} dataField='is_active' dataSort={true} caretRender={this.getCaret} dataFormat={(cell, row) => { return (cell == 1 ? <ActiveUser /> : <InactiveUser />) }}>Status</TableHeaderColumn>
                {/* <TableHeaderColumn ref='end_date' dataField='end_date'  filter={ { type: 'DateFilter'} }>Expires on</TableHeaderColumn> */}
              </BootstrapTable>
            </div>
          </div>
        </div>

        {/* <div className="list-top-right-sec pull-right d-none">
          <input type="text" />
          <button onClick={() => this.props.getUserList()} className="btn ico-refresh-sec">
            <span className="icon-refresh"></span>
          </button>
        </div> */}

        {/* <Table bordered responsive className="d-none">
          <thead>
            <tr>
              <th>User <span className="icon-arrow-down-sign-to-navigate"></span></th>
              <th>User Name <span className="icon-arrow-down-sign-to-navigate"></span></th>
              <th>Entities <span className="icon-arrow-down-sign-to-navigate"></span></th>
              <th>Date Created <span className="icon-arrow-down-sign-to-navigate"></span></th>
              <th>Last Login <span className="icon-arrow-down-sign-to-navigate"></span></th>
              <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </Table> */}
      </>
    );
  }
}

export default (withRouter(UserList));