import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function SyncedStatus(props) {
    return <div  >
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="blu-color">Synced</Tooltip>}>
            <span className="icon-synched-icon ft-sz-30 icon-td"><span className="path1 greenstatus"></span><span className="path2 whitestatus"></span><span className="path3 whitestatus"></span></span>
            {/* <span className="icon-Group-2508 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span> */}
        </OverlayTrigger> </div>
}
export default SyncedStatus;