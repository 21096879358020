import React from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import { FI_CREATE_CODE } from '../../Helpers/Constants';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';
import { allowAlphabetsOnly, allowNumbersOnly } from '../../Utils/RegaxValidation';
import { validateCardDetails } from './ValidateCard';
import { Redirect } from 'react-router-dom'
import Select from 'react-select';
import DirtyFieldModal from '../../Components/BoilerPlate/DirtyFieldModal';
import { fuzzyMatch } from '../../Helpers/HelperFunction';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { decodeHtml } from '../../Helpers/HelperFunction';
import { setAppilcationLoder } from '../../Redux/Actions/App';
const reactSelectStyles = base => ({ ...base, zIndex: 9999 })

class AddNewCardModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_permission_granted: this.props.features_permissions_list.includes(FI_CREATE_CODE),
			errors: {},
			is_editable: false,
			show_delete_popup: false,
			deleteLoading: false,
			card_id: null,
			account_name: "",
			bank_code: "",
			card_digits: "",
			is_active: 1,
			bank_name: "",
			redirect_to_main_page: false,
			is_dirty: false,
			dirtymodalVisible: false,
			form_submited: false,
			yodlee_supported_bank_id: "",
			ifsc_code: "",
			yodlee_supported_bank_id: "",
			ledger_name: "",
			guid:"",
			ledger_options:[],
			has_billwise_allocation: false,
			ledger_id:"",
			ledger_option:{}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.card_details !== this.props.card_details)
			if (nextProps.card_details !== null) {
				//console.log(nextProps.card_details)
				if (nextProps.card_details.is_editable === 0) {
					this.setState({ is_editable: true })
				} else {
					this.setState({ is_editable: false })
				}
				this.getFIDetails(nextProps.card_details.financial_institute_id || nextProps.card_details.id)
				this.getLedgerData();
			} else {
				this.clearState()
			}
	}

	getLedgerData = ()=>{
		console.log("inide ladger data")
		let options = []
		setTimeout(() => {
			let has_billwise_allocation = false;
			if(this.props.ledgerNameList.hasOwnProperty('ledger_list')){
				this.props.ledgerNameList.ledger_list.map((ledger, index) => {
					options.push({ label: decodeHtml(ledger.name), value: ledger.guid })
					if (this.state.guid == ledger.guid) {

						//check if billwise allocation flag is set for already selected ledger
						this.props.ledgerNameList.ledger_list.forEach(opt => {
							if (opt.guid == this.state.guid && opt.has_billwise == 1) {
								has_billwise_allocation = true
							}
						});

						this.setState({ ledger_option: { label: decodeHtml(ledger.name), value: ledger.guid } })
						//this.setState({ledger_id:ledger.id,ledger_name:ledger.name,guid:ledger.guid})
						console.log("iddddd")
						// let temp_ledger = {
						// 	amount: this.state.amount,
						// 	ledger_id: ledger.id,
						// 	guid: this.state.guid,
						// 	name: ledger.name,
						// 	transaction_voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
						// 	voucher_ledger_id: parseInt(this.props.transaction_voucher_ledger_id),
						// 	type: parseInt(this.state.type),
						// 	cost_categories: this.state.cost_categories,
						// 	billwise_allocation: this.props.billwise_allocation
						// }
						//this.props.updateLedger(temp_ledger, this.props.ledger_index, "first_time")

						//console.log(this.state.ledger_option)
					}

				})
				this.setState({ ledger_options: options, has_billwise_allocation })
			}
			
		}, 2000);
	}

	componentDidMount(){
		console.log("hiiiiiiiiiiiiiiiiiii");
		this.getLedgerData();
		
	}

	clearState = () => {
		this.setState({
			is_editable: false,
			errors: {},
			card_id: null,
			account_name: "",
			bank_code: "",
			card_digits: "",
			is_active: 1,
			bank_name: "",
			form_submited: false,
			yodlee_supported_bank_id: "",
			ifsc_code:"",
			ledger_id:"",
			ledger_name:"",
			guid:"",
			ledger_option:{}
		}, () => {
			console.log(this.state.form_submited)
		})

	}

	getFIDetails = (card_id) => {
		let details = {
			"financial_institute_id": card_id
		}

		this.props.dispatch(setAppilcationLoder(true))
		FinancialInstituteService.getFIData(card_id).then(data => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({
					account_name: data.result.account_name,
					bank_code: ('' + data.result.bank_code),
					card_digits: data.result.account_number,
					is_active: data.result.is_active,
					bank_name: data.result.bank_name,
					card_id: data.result.id,
					yodlee_supported_bank_id: data.result.yodlee_supported_bank_id,
					ifsc_code:data.result.ifsc_code == null ? "" : data.result.ifsc_code,
					guid:data.result.ledger_guid == null ? "":data.result.ledger_guid,
					ledger_id: data.result.ledger_id == null ? "" : data.result.ledger_id,
					ledger_name: data.result.ledger_name == null ? "" : data.result.ledger_name
				})

				if (data.result.is_editable === 0) {
					this.setState({ is_editable: true })
				} else {
					this.setState({ is_editable: false })
				}
			}
		})
	}

	handleLedgerChange = (e,type = null)=>{
		let name;
		let value;
		let ledger_id;
		let l_name;
		if (this.state.is_permission_granted ) {
			if (type == 'ledger') {
				name = 'guid';
				value = e.value;
				console.log('e,vakkdjhg',e.value)
				let ledger = this.props.ledgerNameList.ledger_list.find(x => x.guid == value);

				ledger_id = ledger.id;
				l_name = ledger.name;
				let has_billwise_allocation = ledger.has_billwise == 1 ? true : false;

				this.setState({
					ledger_option: { label: <span dangerouslySetInnerHTML={{ __html: l_name }} />, value: value },
					has_billwise_allocation
				})
				//to hide cost center on production line 199,202 are commented. later we remove required validation
			}
			else{
				name = e.target.name
				value = e.target.value

				if (this.state.guid != "") {
					ledger_id = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid).id;
					l_name = this.props.ledgerNameList.ledger_list.find(x => x.guid == this.state.guid).name;
				}
			}

			this.setState({guid:value,ledger_id:ledger_id,ledger_name:l_name,is_dirty:true})

			
		}


	}

	handleChange = (e) => {
		const { name, value } = e.target;
		
		if (name == "card_digits" && allowNumbersOnly(value) == false) {
			return
		}
		if (name === "account_name" && allowAlphabetsOnly(value) === true) {
			this.setState({})
		} else
			this.setState({ [name]: value, is_dirty: true })

	}

	updateList = (card, action) => {
		if (action === 'update')
			card.is_editable = this.props.card_details.is_editable
		else
			card.is_editable = 1
		this.props.updateList(card, action)
	}

	saveCard = async () => {
		let card_number = this.state.card_digits
		this.setState({ form_submited: true })
		let name = this.state.account_name.trim().replace(/\s+/g, ' ');
		let details = {
			"account_name": name,
			"account_number": card_number,
			"ifsc_code": this.state.ifsc_code,
			//"bank_code": this.state.bank_img_code,
			//"branch": null,
			//"city_name": null,
			"cards": [card_number],
			"is_credit_card": 1,
			"is_active": this.state.is_active,
			"bank_name": this.state.bank_name,
			"yodlee_supported_bank_id": this.state.yodlee_supported_bank_id,
			"ledger_guid":this.state.guid == "" ? null : this.state.guid,
			"ledger_name":this.state.ledger_name == "" ? null : this.state.ledger_name,
			"ledger_id":this.state.ledger_id == "" ? null : this.state.ledger_id
		}

		let errors = validateCardDetails(details, this.props.FIList);
		if (this.state.card_id !== null) {
			if (errors.account_number == 'Card number already exits.') {
				delete errors.account_number
			}
		}

		let match = fuzzyMatch(this.props.default_entity_detail.name.toString().toLowerCase(), this.state.account_name.toString().toLowerCase());
		const averageLength = (this.props.default_entity_detail.name.length + this.state.account_name.length) / 2;
		const stringMatch = match > averageLength ? 1 : match / averageLength;
		const matchPercent = (1 - stringMatch) * 100;

		if (Object.keys(errors).length === 0) {

			if (matchPercent <= 30) {
				if (!await confirm({
					confirmation: 'Entity name & Name on the Card does not match. Do you still want to continue?'
				})) {
					return true;
				}
			}

			if (this.state.card_id !== null) {
				//details.financial_institute_id = this.state.card_id
				details.id = this.state.card_id
				details.delete_cards = []
				//details.bank_code = this.state.bank_img_code;
				FinancialInstituteService.updateBankAndCard(details).then(data => {
					if (data.response_code === 200) {
						details.bank_code = this.state.bank_img_code
						this.updateList(details, 'update')
						this.props.getFIDetails(this.state.card_id)

						this.setState({
							account_name: '', bank_code: "", errors: {},
							card_digits: '', is_active: 1, bank_name: "", is_dirty: false, yodlee_supported_bank_id: "", form_submited: false,ifsc_code:"",
							guid:"",ledger_name:"",ledger_id:"",ledger_option:{}
						})

						toast.success(data.message);
						this.props.closeModal();

					} else if (data.response_code == 400) {
						this.setState({ errors: data.reason, });
					} else {
						toast.error(data.message);
					}
				})
			} else
				//console.log(details)
				//details.bank_code = this.state.bank_img_code;
				FinancialInstituteService.addNewBank(details).then(data => {
					if (data.response_code === 200) {
						details.financial_institute_id = data.result.financial_institute_id
						details.bank_code = this.state.bank_img_code
						this.updateList(details, 'add')

						this.setState({
							account_name: '', bank_code: "", errors: {},
							card_digits: '', is_active: 1, bank_name: "", is_dirty: false, yodlee_supported_bank_id: "", form_submited: false,ifsc_code:"",
							guid:"",ledger_name:"",ledger_id:"",ledger_option:{}
						})

						toast.success(data.message);
						this.closeModal()

					} else if (data.response_code == 400) {
						this.setState({ errors: data.reason, });
					} else {
						toast.error(data.message);
					}
				})
		} else {
			this.setState({
				loading: false,
				errors: errors
			});
		}
	}

	toggleDeleteFI = () => {
		this.setState({ show_delete_popup: !this.state.show_delete_popup })
	}

	deleteFI = () => {
		let details = {
			"financial_institute_id": this.state.card_id
		}
		FinancialInstituteService.deleteFinancialInstitute(details).then(data => {
			if (data.response_code === 200) {
				if (data.message == "Financial Institute Deleted") {
					this.props.updateList(parseInt(this.state.card_id), 'delete')
					this.props.closeModal()
					this.toggleDeleteFI()
					toast.success(data.message)

					if (this.props.inside_page) {
						this.setState({ redirect_to_main_page: true })
					}
				} else {
					toast.error(data.message)
					this.toggleDeleteFI()
					this.props.closeModal()
				}
			} else {
				toast.error(data.message)
				this.toggleDeleteFI()
				this.props.closeModal()
			}
		})
	}

	handleBankCodeChange = (e) => {

		if (e.value == null) {
			this.setState({ bank_code: null, bank_name: "", is_dirty: true, yodlee_supported_bank_id: null, bank_img_code: null })
		} else {
			let bank = this.props.bank_name_list.filter(function (item) {
				return item.id == e.value
			})
			this.setState({ bank_name: e.label, bank_code: e.value, is_dirty: true, yodlee_supported_bank_id: e.value, bank_img_code: bank[0].bank_code })
		}
	}

	closeModal = () => {
		if (this.state.is_dirty) {
			this.setState({ dirtymodalVisible: true })
			return false;
		} else {
			this.setState({errors : ''})
			this.props.closeModal();
		}
	}
	dirtyonDontSave = () => {
		this.setState({ dirtymodalVisible: false })
		this.props.closeModal();
		this.clearState()
	}
	closeDirtyModalOnCancel = () => {
		this.setState({ dirtymodalVisible: false })
	}
	onConfirm = () => {
		this.setState({ dirtymodalVisible: false })
		this.saveCard();
	}

	onInputChange = (event) => {

		if (event.target.name === "ifsc_code") {
			this.setState({
				ifsc_code: event.target.value.toUpperCase(),
				is_dirty: true
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value,
				is_dirty: true
			});
		}
		//this.checkForErrors();
	}

	checkForErrors = () => {
		console.log(this.state.form_submited)
		if (this.state.form_submited) {
			//let errors = {}
			let details = {
				"account_name": this.state.account_name,
				"account_number": this.state.card_digits,
				"ifsc_code": this.state.ifsc_code,
				"bank_name": this.state.bank_name,
				"yodlee_supported_bank_id": this.state.yodlee_supported_bank_id
			}

			let errors = validateCardDetails(details, this.props.FIList);
			if (this.state.card_id !== null) {
				if (errors.account_number == 'Card number already exits.') {
					delete errors.account_number
				}
			}

			if (Object.keys(errors).length != 0) {
				this.setState({ errors: errors });
			} else {
				this.setState({ errors: {} });
			}
		}
	}

	render() {
		let bank_name_list = this.props.bank_name_list.filter(bank => {
			if (bank.is_credit_card_supported == 1 || bank.id == null) {
				return bank
			}
		});

		bank_name_list = bank_name_list.map(({ id, bank_name }) => ({ label: bank_name, value: id }));

		//
		console.log(bank_name_list)
		if (this.state.redirect_to_main_page) {
			return <Redirect to='/financial-institutes' />;
		}

		return (
			<React.Fragment>

				<DirtyFieldModal
					visible={this.state.dirtymodalVisible}
					onCancel={this.closeDirtyModalOnCancel}
					onConfirm={this.onConfirm}
					onDontSave={this.dirtyonDontSave} />

				<DeletePopUp
					showModal={this.state.show_delete_popup}
					deleteLoading={this.state.deleteLoading}
					toggleDeletePopUp={this.toggleDeleteFI}
					deleteItem={this.deleteFI} />

				<Modal
					show={this.props.showModal}
					onHide={this.closeModal}
					backdrop="static"
					keyboard={false}
					centered
					className="popup-model add-new-bank-model"
					size="xl"
				// aria-labelledby="example-modal-sizes-title-lg"
				// className="add-bank-model"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{this.state.is_permission_granted ? this.state.card_id !== null ? "Edit Credit Card Details" : "Add New Credit Card" : "View Card Details"}
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className="row">
							<div className="col-xl-4 col-lg-4 col-md-4 form-group">
								<lable className="lable-input">Bank Name<span className="red-star">*</span></lable>
								{/* <Select className="Select-Search card_issued " */}
								<Select
									className="Select-Search typeed-dd "
									classNamePrefix="Select type-select "
									name="bank_code"
									menuPortalTarget={document.body}
									styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
									onChange={(e) => {
										if (this.state.is_permission_granted)
											this.handleBankCodeChange(e)
									}}
									value={bank_name_list.filter(option => option.value == this.state.yodlee_supported_bank_id)}
									// value = {this.state.yodlee_supported_bank_id}
									isDisabled={!this.state.is_permission_granted || this.state.is_editable}
									isSearchable={true}
									options={bank_name_list}
									placeholder={'Select Your Bank'}
									onBlur={this.checkForErrors}
								/>
								{(this.state.errors.bank_id) && <small className="form-error-text">{this.state.errors.bank_id}</small>}
							</div>

							<div className="col-xl-4 col-lg-4 col-md-4 form-group">
								<lable className="lable-input">Name on the Card<span className="red-star">*</span></lable>
								<input type="text" className="form-control" name="account_name"
									readOnly={this.state.is_editable || !this.state.is_permission_granted}
									value={this.state.account_name} onChange={(e) => this.handleChange(e)} onBlur={this.checkForErrors} />
								{this.state.errors.account_name && <small className="form-error-text">{this.state.errors.account_name}</small>}
							</div>

							<div className="col-xl-2 col-lg-2 col-md-2 form-group">
								<lable className="lable-input">Last 4 Digit<span className="red-star">*</span></lable>
								<input name="card_digits" type="text" style={{ letterSpacing: "5px" }} value={this.state.card_digits} onChange={(e) => this.handleChange(e)} className="form-control" maxLength="4" readOnly={this.state.is_editable || !this.state.is_permission_granted} onBlur={this.checkForErrors} />
								{this.state.errors.account_number && <small className="form-error-text">{this.state.errors.account_number}</small>}
							</div>

							{this.state.yodlee_supported_bank_id === null &&
								<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">Issuer Bank Name<span className="red-star">*</span></lable>
									<textarea className="form-control" rows="1" name="bank_name" value={this.state.bank_name}
										onChange={(e) => this.handleChange(e)} placeholder="Card issuer bank name here"
										readOnly={this.state.is_editable || !this.state.is_permission_granted} onBlur={this.checkForErrors}
									></textarea>
									{this.state.errors.bank_name && <small className="form-error-text">{this.state.errors.bank_name}</small>}
								</div>
							}

							{this.state.yodlee_supported_bank_id == null &&
								<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">IFSC Code<span className="red-star">*</span></lable>
									<input
										type="text"
										className="form-control"
										value={this.state.ifsc_code}
										onChange={this.onInputChange}
										onBlur={this.checkForErrors}
										name="ifsc_code"
										readOnly={this.state.is_editable || !this.state.is_permission_granted || (this.state.ifsc_code ? true:false)}	
									/>
									{this.state.errors.ifsc_code && <small className="form-error-text">{this.state.errors.ifsc_code}</small>}
								</div>
							}
							<div className="col-xl-4 col-lg-4 col-md-4 form-group">
									<lable className="lable-input">Ledger Name<span ></span></lable>

									{/* <Select className="Select-Search card_issued " */}
									<Select
										className="Select-Search typeed-dd "
										classNamePrefix="Select type-select "
										name="guid"
										menuPortalTarget={document.body}
										styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
										value={this.state.ledger_option}
										// isDisabled={!this.state.is_permission_granted || this.state.is_bank_editable}
										onChange = {(e) => this.handleLedgerChange(e, 'ledger')}
										isSearchable={true}
										options={this.state.ledger_options}
										placeholder={'Select Ledger'}
										onBlur={this.checkForErrors}
									/>
									{/* {this.state.errors.bank_id && <small className="form-error-text">{this.state.errors.bank_id}</small>} */}
								</div>
						</div>

						<div className="row">
							<div className="col-12 text-right mt-3">
								<ButtonGroup className="activein_btn_group" aria-label="Basic example">
									<Button variant={" apply_btn_new activein_btn_new " + (this.state.is_active ? " success " : " deactivein_btn_new ")}
										onClick={(e) => this.handleChange({ target: { name: "is_active", value: 1 } })}>Active</Button>
									<Button variant={" apply_btn_new activein_btn_new " + (this.state.is_active ? " deactivein_btn_new " : " success ")}
										onClick={(e) => this.handleChange({ target: { name: "is_active", value: 0 } })}>Inactive</Button>
								</ButtonGroup>
							</div>
						</div>

						{this.state.is_permission_granted &&
							<div className="row mt-3">
								<div className="col-6">
									{this.state.card_id &&
										<Button type="button" variant={"outline-success reset_btn_new " + (this.state.is_editable ? " disable-btn " : "")}
											onClick={() => {
												// if (this.state.is_editable === false)
												this.toggleDeleteFI()
											}}>Delete</Button>
									}
								</div>

								<div className="col-6 text-right">
									<Button type="submit" variant="success apply_btn_new" onClick={(e) => this.saveCard(e)}>Save</Button>
								</div>

								{this.state.bank_code === null && this.state.bank_name !== '' ?
									<div className="col-12">
										<div className="bank-not-support">
											<span class="icon-credit-card1 bnsp-icon mt-1"></span>
											<small>This issuer is not currently supported, will add support in next 2-3 days. Please check again.</small>
										</div>
									</div> : null
								}
							</div>
						}
					</Modal.Body>
				</Modal>
			</React.Fragment>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const features_permissions_list = all_value.features_permissions_list || [];
	return { features_permissions_list }
}

export default connect(mapStateToProps)(AddNewCardModal);