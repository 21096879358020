import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function CardIcon(props) {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Card</Tooltip>}>
        <span className="icon-bank icon-Group-4437"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
    </OverlayTrigger>
}
export default CardIcon;