import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import InvoiceService from '../../Services/Invoices/InvoiceService';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { changeDateFormate, displayDateFormate } from '../../Helpers/DateFormat';
import FinancialYear from "../../Components/BoilerPlate/FinancialYear";
import ShowWidgets from "./ShowWidgets";
import InvoiceFilter from "./InvoiceFilter"
import { setStarTask } from '../../Redux/Actions/Invoices';
import ReadyStatus from '../Icon/ReadyStatus';
import NotReadyStatus from '../Icon/NotReadyStatus';
import ProcessedStatus from '../Icon/ProcessedStatus';
import LinkedStatus from '../Icon/LinkedStatus';
import SyncedStatus from '../Icon/SyncedStatus';
import MessageIcon from '../Icon/Message';
import TaskIcon from '../Icon/Task';
import SortingIcon from '../Icon/SortingIcon';

/* static ui elements */
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class Invoices extends Component {


  constructor(props) {
    super(props);

    this.state = {

      total_count: 0,
      per_page_items: 5,
      page_no: 1,

      show_filter_popup: false
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSizePerPageChange = this.handleSizePerPageChange.bind(this);
    this.renderShowsTotal = this.renderShowsTotal.bind(this);

  }

  toggleFilterPopup = () => {
    this.setState({ show_filter_popup: !this.state.show_filter_popup })
  }


  componentDidMount() {
    // this.getInvoiceList(0);
  }

  renderShowsTotal(start, to, total) {
    return (
      <p className="main-pagi-show">
         <span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
      </p>
    );
  }
  _setNoDataFoundOption() {
    if (!this.props.is_invoice_loading) {
      return "No items to display";
    } else {
      return (
        <p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
      );
    }
  }

  handlePageChange(page, sizePerPage) {
    this.setState({
      page_no: page
    }, () => this.props.handlePageChange(page))
  }

  handleSizePerPageChange(sizePerPage) {
    // When changing the size per page always navigating to the first page
    this.setState({
      per_page_items: sizePerPage
    }, () => this.getInvoiceList(this.state.filter_by))
  }

  dataFormatStatus = (cell, row) => {
    switch (cell) {
      case 1:
        return <ReadyStatus />
      case 2:
        return <NotReadyStatus />
      case 3:
        return <ProcessedStatus />
      case 4:
        return <LinkedStatus />
      case 5:
        return <SyncedStatus />
    }



  }

  dataFormatTask = (cell, row) => {
    return <div>
      <MessageIcon />
      <TaskIcon />
    </div>
  }

  goToEditView = (row) => {
    const { dispatch, history } = this.props;
    history.push({
      pathname: `/invoices/create/` + row.id
    });
  }

  searchFilter = (e) => {
    e.preventDefault();
    this.props.searchFilter(this.state.search);
  }


  makeRemoveStar = (e, row) => {
    e.stopPropagation();
    let data = {
      id: row.id,
      is_star: row.is_star == 0 ? 1 : 0
    }
    this.props.makeRemoveStar(row.id, data.is_star)
    InvoiceService.makeRemoveStar(data).then(
      (data) => {
        if (data.response_code == 200) {
        } else {
          this.props.makeRemoveStar(row.id, row.is_star)
          toast.error(data.message);
        }
      });
  }

  dataFormatStar = (cell, row) => {
    switch (cell) {
      case 1:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>

      case 0:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
    }
  }

  render() {

    const options = {
      onPageChange: this.handlePageChange,
      onSizePerPageList: this.handleSizePerPageChange,
      page: this.state.page_no,  // which page you want to show as default
      // sizePerPageList: [ {
      //   text: '25', value: 25
      // }, {
      //   text: '50', value: 50
      // }, {
      //   text: '100', value: 100
      // } ], // you can change the dropdown list for size per page
      sizePerPage: this.state.per_page_items,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button,
      onRowClick: (row) => {
        this.goToEditView(row);
      },
      noDataText: this._setNoDataFoundOption(),
    };
		// const myRef = React.createRef()


    return (
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Invoices</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Invoices</h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">

                <FinancialYear
                  financialYearChanged={this.props.financialYearChanged}
                  refreshItem={this.props.refreshItem}
                />
                <ShowWidgets
                  onCardSelected={this.props.onCardSelected}
                  filter_card={this.props.filter_card} />

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec" onSubmit={this.searchFilter} noValidate>
                        <input type="text"
                          className="form-control"
                          placeholder="Search"
                          value={this.state.search}
                          onChange={this.handleOnChange}
                          name="search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter" onClick={() => this.toggleFilterPopup()}>
                        Filter <span className="icon-funnel"></span>
                      </div>
                      <Link to="/invoices/create" className="add-new-btn btn ft-sz-20 white-color s-bold">
                        <span className="icon-plus1"></span> Create Invoice
                      </Link>
                    </div>
                  </div>
                  {
                    this.state.show_filter_popup &&
                    <InvoiceFilter
                      applyFilter={this.props.applyFilter}
                      creaters={this.props.creaters} />
                  }

                  {/* <div className="main-table-sec">
                    <BootstrapTable ref='table' data={this.props.invoice_list}  table-bordered
                      pagination={true}
                      options={options}
                      remote
                      fetchInfo={{ dataTotalSize: this.props.total_count }}
                      remote
                      pagination
                      >
                      <TableHeaderColumn width="70" tdStyle={td_style} dataField='is_star' dataFormat={(cell, row) => this.dataFormatStar(cell, row)}>Star</TableHeaderColumn>
                      <TableHeaderColumn width="130" tdStyle={td_style} isKey dataField='date' dataFormat={(cell, row) => displayDateFormate(cell)} >Date </TableHeaderColumn>
                      <TableHeaderColumn width="120" tdStyle={td_style} dataField='invoice_type' dataFormat={(cell, row) => cell == 1 ? "Invoice" : "Proforma"} dataSort={true}>Type </TableHeaderColumn>
                      <TableHeaderColumn width="150" tdStyle={td_style} dataField='invoice_number' dataSort={true}>Invoice# </TableHeaderColumn>
                      <TableHeaderColumn width="150" tdStyle={td_style} dataField='name' dataSort>Customer </TableHeaderColumn>
                      <TableHeaderColumn width="130" tdStyle={td_style} dataField='total_after_tds' dataSort>Amount </TableHeaderColumn>
                      <TableHeaderColumn width="170" tdStyle={td_style} dataField='payment_status' dataFormat={(cell, row) => { return { 1: "Paid", 2: "Unpaid", 3: "Part Payment" }[cell] }} dataSort>Payment status </TableHeaderColumn>
                      <TableHeaderColumn width="90" tdStyle={td_style} dataField='status' dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>Status </TableHeaderColumn>
                      <TableHeaderColumn width="150" tdStyle={td_style} dataField='' dataFormat={(cell, row) => this.dataFormatTask(cell, row)} dataSort></TableHeaderColumn>
                    </BootstrapTable>
                  </div> */}

                  <div className="main-table-sec">
                    {this.props.invoice_list.length === 1 && this.props.is_invoice_loading === false ?
											<div className="not-create-sec"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>
											:
                      <BootstrapTable ref='table' data={this.props.invoice_list}  table-bordered
                        pagination={true}
                        options={options}
                        remote
                        fetchInfo={{ dataTotalSize: this.props.total_count }}
                        remote
                        pagination
                        striped
												hover
												condensed
                        >
                        <TableHeaderColumn width="60px" align="center" thStyle={{ textAlign: 'center' }}  tdStyle={{ ...td_style, textAlign: 'center' }} dataField='is_star' dataFormat={(cell, row) => this.dataFormatStar(cell, row)}>
                          <SortingIcon field_name="Star" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="130" thStyle={{ textAlign: 'center' }}  tdStyle={{ ...td_style, textAlign: 'center' }} isKey dataField='date' dataFormat={(cell, row) => displayDateFormate(cell)} >
                          <SortingIcon field_name="Date" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="120" align="center" tdStyle={{ ...td_style }} dataField='invoice_type' dataFormat={(cell, row) => cell == 1 ? "Invoice" : "Proforma"}>
                          <SortingIcon field_name="Type" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="150" tdStyle={td_style} dataField='invoice_number'>
                          <SortingIcon field_name="Invoice#" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="150" tdStyle={td_style} dataField='name'>
                          <SortingIcon field_name="Customer" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="130" tdStyle={td_style} dataField='total_after_tds'>
                          <SortingIcon field_name="Amount" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="170" tdStyle={td_style} dataField='payment_status' dataFormat={(cell, row) => { return { 1: "Paid", 2: "Unpaid", 3: "Part Payment" }[cell] }}>
                          <SortingIcon field_name="Payment Status" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="90" tdStyle={td_style} dataField='status' dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>
                          <SortingIcon field_name="Status" onSortClick={this.onSortClick} field_number="1" sort_by={1} sort_order={1} />
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn width="150" tdStyle={td_style} dataField='' dataFormat={(cell, row) => this.dataFormatTask(cell, row)}></TableHeaderColumn>
                      </BootstrapTable>
                    }
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded " href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>

    );
  }

}

function mapStateToProps(state) {
  const all_value = state.Invoices || {};
  const is_invoice_loading = all_value.is_invoice_loading || false;

  return {
    is_invoice_loading
  };
}

export default connect(mapStateToProps)(withRouter(Invoices));