import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDatepicker(props) {
  useEffect(() => {
    handleCalendarSet();
  }, []);

  useEffect(() => {
    // if (props.initialValue)
    // setStartDate(new Date(props.initialValue.split("-").reverse().join("-")));
    setStartDate(props.initialValue);
  }, [props.initialValue]);

  const handleCalendarSet = () => {
    if (!!startDate) {
      const currentDayOfMonth = startDate.getDate();
      const currentMonth = startDate.getMonth();
      const currentYear = startDate.getFullYear();
      let dateString =
        (currentDayOfMonth.toString().length < 2
          ? "0" + currentDayOfMonth
          : currentDayOfMonth) +
        "-" +
        ((currentMonth + 1).toString().length < 2
          ? "0" + (currentMonth + 1)
          : currentMonth + 1) +
        "-" +
        currentYear;
      //console.log("Calendar closed", dateString);
      props.getSeletedValue(startDate);
    } else props.getSeletedValue("");
  };

  const handleKeyUp = (event) => {
    let e = event.target.value;
    // console.log(e);
    if (e == "") {
      console.log("removed the date")
      props.todaysDate();
      setStartDate(null);
    }
    if (e.includes("-") || e.includes("/") || e.includes(".")) {
      let dateArray = [];
      let dateString = "";
      if (e.includes("-")) {
        dateArray = e.split("-");
      } else if (e.includes("/")) {
        dateArray = e.split("/");
      } else if (e.includes(".")) {
        dateArray = e.split(".");
      }
      if (dateArray.length == 2 || dateArray.length == 3) {
        if (
          dateArray.length == 2 &&
          isNaN(dateArray[0]) == false &&
          parseInt(dateArray[0]) <= 31 &&
          isNaN(dateArray[1]) == false &&
          parseInt(dateArray[1]) <= 12
        ) {
          dateString =
            dateArray[1] + "-" + dateArray[0] + "-" + new Date().getFullYear();
        } else if (
          dateArray.length == 3 &&
          isNaN(dateArray[0]) == false &&
          parseInt(dateArray[0]) <= 31 &&
          isNaN(dateArray[1]) == false &&
          parseInt(dateArray[1]) <= 12 &&
          isNaN(dateArray[2]) == false &&
          dateArray[2].length == 4
        ) {
          dateString = dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2];
        }
        let setVal = new Date(dateString);
        if (Object.prototype.toString.call(setVal) === "[object Date]") {
          if (isNaN(setVal.getTime())) {
          } else {
            setStartDate(setVal);
          }
        }
      }
    }
  };

  const [startDate, setStartDate] = useState(null);
  return (
    <>
      <DatePicker
        {...props}
        selected={startDate}
        dateFormat="dd-MM-yyyy"
        onSelect={(date) => setStartDate(date)}
        onCalendarClose={handleCalendarSet}
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={65}
        customInput={<input type="input" onKeyUp={handleKeyUp} />}
      />
    </>
  );
}
export default CustomDatepicker;
