import React from 'react'
import { Accordion, Card, Table } from 'react-bootstrap';
import recipt  from '../../Assets/img/recipt.png'

class DocBoxTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  handleTabClick = () => {
    let testElements = document.getElementsByClassName('collapse');
    if (testElements[1].classList.length === 2) {
    }else{
      document.getElementById("narattionTab").classList.remove('show');
      document.getElementById("ledgerTab").classList.remove('show');
      document.getElementById("searchTab").classList.remove('show');
    }
  }

  render() {
    return (
      <Card className="d-none">
        <Accordion.Toggle as={Card.Header} eventKey="" 
          onClick={()=>this.handleTabClick()} className="ft-sz-24 s-sbold blu-color disable-accordion">
          Doc Box
            <div className="drop-arrow">
            <span className="icon-arrow-down-sign-to-navigate"></span>
          </div>
          <div className="clearfix"></div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="" id="docboxTab">
          <Card.Body>
            <div className="clearfix">
              <div className="search-filter-sec pull-right">
                <form className="search-sec">
                  <input type="text" placeholder="Search" />
                  <button type="submit"><span className="icon-loupe"></span></button>
                </form>
              </div>
            </div>
            <div className="folder-tab-menu-sec clearfix">
              <div className="pull-left">
                <p className="ft-sz-25 s-sbold blu-color mb-0 d-none">Files {'&'} Folders</p>
                <button className="active folder-btn btn ft-sz-17 s-sbold mr-3">My Drive</button>
                <button className="folder-btn btn ft-sz-17 s-sbold mr-3">Shared With Me</button>
                <button className="folder-btn btn ft-sz-17 s-sbold mr-3">Starred</button>
              </div>
              <div className="pull-right lsit-menu-sec">
                <ul className="list-inline">
                  <li className="list-inline-item"><a href="#"><span className="icon-list"></span></a></li>
                  <li className="list-inline-item active"><a href="#"><span className="icon-menu1"></span></a></li>
                </ul>
              </div>
            </div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                My Drive
                  </li>
              <li className="breadcrumb-item">New Document</li>
            </ol>
            <div className="drive-list-sec">
              <button className="active drive-btn btn ft-sz-17 s-sbold mr-3"><span className="icon-Folder ft-sz-20 gray-icon mr-1"></span> My Folder</button>
              <button className="drive-btn btn ft-sz-17 s-sbold mr-3"><span className="icon-Folder ft-sz-20 gray-icon mr-1"></span> New Docs</button>
              <button className="drive-btn btn ft-sz-17 s-sbold mr-3"><span className="icon-Folder ft-sz-20 gray-icon mr-1"></span> Rahul T</button>
            </div>
            <div className="main-search-evidence-box-sec view-thumbail-sec">
              <div className="row">
                <div className="col-lg-4 col-md-4 box-content-sec">
                  <div className="inner-search-evidence-box-sec inner-doc-box-search-sec">
                    <div className="">
                      <div className="doc-pdf-sec">
                        <span className="icon-Doc ft-sz-30 mr-1"></span>
                      </div>
                      <div className="file-name-sec font-colr ft-sz-16">
                        <span className="icon-Doc ft-sz-28"></span><span className="icon-Doc ft-sz-28"></span> Receipt.pdf
                      </div>
                    </div>
                  </div>
                  <div className="main-bottom-view-select-sec">
                    <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr" onClick={() => this.setLgShow(true)}>
                      <span className="ico-btn icon-view-details"></span> Preview
                        </div>
                    <div className="btn-sec select-btn ft-sz-13 s-sbold">
                      <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                        </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 box-content-sec">
                  <div className="inner-search-evidence-box-sec inner-doc-box-search-sec">
                    <div className="">
                      <div className="doc-image-sec">
                        <img className="" src={recipt} />
                      </div>
                      <div className="file-name-sec font-colr ft-sz-16">
                        <span className="icon-Doc ft-sz-28"></span> Receipt.pdf
                      </div>
                    </div>
                  </div>
                  <div className="main-bottom-view-select-sec">
                    <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr" onClick={() => this.setLgShow(true)}>
                      <span className="ico-btn icon-view-details"></span> Preview
                        </div>
                    <div className="btn-sec select-btn ft-sz-13 s-sbold">
                      <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                        </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-table-sec doc-box-moneylink-table">
              <div className="main-search-evidence-box-sec view-list-sec">
                <Table bordered responsive className="">
                  <thead>
                    <tr>
                      <th>Name </th>
                      <th>Last Modified </th>
                      <th>Uploaded by </th>
                      <th>File Size </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> My Folder</td>
                      <td>12 Nov 2020</td>
                      <td>Mohit Rathod</td>
                      <td>442KB</td>
                      <td>
                        <span className="table-view-select-icon gray-ico">
                          <span className="icon-Group-4596"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                        </span>

                        <span className="table-view-select-icon gray-ico">
                          <span className="icon-Group-4598"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><span className="icon-Folder ft-sz-28 gray-icon mr-1"></span> New Document</td>
                      <td>4 jan 2021</td>
                      <td>Mangesh</td>
                      <td>442KB</td>
                      <td>
                        <span className="table-view-select-icon">
                          <span className="icon-Group-4596"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                        </span>

                        <span className="table-view-select-icon">
                          <span className="icon-Group-4598"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><span className="icon-Doc ft-sz-28"></span> Loan Agreement.pdf</td>
                      <td>4-2-2021</td>
                      <td>Mohit Rathod</td>
                      <td>226 KB</td>
                      <td>
                        <span className="table-view-select-icon">
                          <span className="icon-Group-4596"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                        </span>

                        <span className="table-view-select-icon">
                          <span className="icon-Group-4598"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            {this.props.toggleNoSupportingDocument &&
              <React.Fragment>
                <div className="no-evidence-popup "></div>
                <div className="main-no-evidence-popup clearfix row">
                  <div className="mr-2 col-1">
                    <span className="ico-sec icon-Group-4244"></span>
                  </div>
                  <div className="col">
                    <p className="ft-sz-30 s-bold blu-color mb-1">No Evidence</p>
                    <p className="ft-sz-18 s-reg font-colr">You selected No Evidence for this transaction</p>
                  </div>
                </div>

              </React.Fragment>}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

    )
  }
}

export default DocBoxTab;
