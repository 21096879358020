import React from 'react'
import { withRouter } from "react-router-dom";
import { Modal, ProgressBar } from 'react-bootstrap';
import { displayDateFormate } from '../../Helpers/DateFormat';
import { GSTIcon, TDSIcon } from '../../Views/IcoonNew';
import EditIcon from '../../Views/IcoonNew/EditIcon';
import { CREATE_BUSSINESS_SNAPSHOT_CODE, MANAGE_OTHER_USER_SNAPSHOT_CODE, SNAPSHOT_LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
import MessageModal from '../../Components/BoilerPlate/MessageModal';
import { showAmount } from '../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { snapshotTypes } from '../../Helpers/SnapshotConstant';
import StorageData from "../../Helpers/StorageData";

class EvidenceCardView extends React.Component {
	constructor(props) {
		super(props)
		this.MANAGE_OTHER_USER_SNAPSHOT = this.props.features_permissions_list.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);
		this.SNAPSHOT_LEDGER_ENTRIES_CODE = this.props.features_permissions_list.includes(SNAPSHOT_LEDGER_ENTRIES_CODE);
		this.state = {
			no_access_to_snapshot_alert: false
		}
	}

	getModule = (module) => {
		switch (module) {
			case 1:
				return "Snapshot"
			case 2:
				return "Cheque"
			case 3:
				return "Invoice"
		}
	}

	editEvidence = (module, id) => {
		if (this.props.features_permissions_list.includes(CREATE_BUSSINESS_SNAPSHOT_CODE)) {
			this.props.editEvidence(module, id)
		} else {
			this.toggleMessageModal()
		}
	}

	toggleMessageModal = () => {
		this.setState({ no_access_to_snapshot_alert: !this.state.no_access_to_snapshot_alert })
	}

	render() {
		const editable = !this.props.read_only
		return (
			<React.Fragment>

				<MessageModal showModal={this.state.no_access_to_snapshot_alert} content={"You do not have required permission to access snapshot module."} toggleMessageModal={this.toggleMessageModal} />


				<div className="evi-cards-box">
					<div className="evi-cards">
						<div className="evi-card-line-1">
							<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.evidence_type}</Tooltip>}>
								<span className="evi-name ellips-auto">{this.getModule(this.props.module)} ({this.props.evidence_type})</span>
							</OverlayTrigger>
							<span className="evi-gte-icon">
								<GSTIcon active={this.props.gst_status} />
								<TDSIcon active={this.props.tds_status} />
								{this.MANAGE_OTHER_USER_SNAPSHOT &&
									<span className="evi-edit-icon" onClick={() => {
										if ((editable && this.SNAPSHOT_LEDGER_ENTRIES_CODE) || this.props.is_new_evidence) {
											StorageData.setTransactionId(this.props.match.params.transaction_id)
											this.editEvidence(this.props.module, this.props.id)
										}
									}}><EditIcon is_disabled={(this.props.is_new_evidence) ? false : !this.SNAPSHOT_LEDGER_ENTRIES_CODE} />
									</span>
								}
							</span>
						</div>

						<div className="evi-card-line-2 ">
							<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.vendor}</Tooltip>}>
								<span className="evi-card-vendor ellips-auto">{this.props.vendor}</span>
							</OverlayTrigger>
							<span className="evi-card-date">{displayDateFormate(this.props.date)}</span>
						</div>

						<div className="evi-card-line-3">
							<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Due : {showAmount(this.props.pending_amount)}</Tooltip>}>
								<span className="evi-card-amount ellips-auto">{showAmount(this.props.total)}</span>
							</OverlayTrigger>
							<span
								// className={"evi-card-link-icon " + (this.props.link_history == 0 ? ' disable-btn ' : this.props.link_history == 1 ? ' amber' : ' active')}
								className={"evi-card-link-icon " + (this.props.link_history == 0 ? ' disable-btn ' : this.props.link_history == 1 ? ' orange-bg ' : ' sky-blue-bg ')}
								onClick={() => {
									if (this.props.link_history != 0)
										this.props.showLinkHistory(this.props.module, this.props.id)
								}}><span className="icon-Group-4604 elicon"></span>
							</span>
						</div>
						<div className="progres-bar-sec">
							{/* <ProgressBar now={60} className="progres-bar-yellow" /> */}
							<ProgressBar now={this.props.score} className="progres-bar-yellow" />
							<p className="pbt">{this.props.score ? this.props.score + '%' : 'NA'}</p>
						</div>
					</div>

					<div className="evi-card-vds-btns">
						<div className="ecvd-btns evi-view-btns" onClick={() => { this.props.viewEvidence(this.props.report_url) }}>
							<span className="icon-view-details evi-btns-icon"></span> View
						</div>
						{this.props.is_linked_evidence ?
							<div className={`ecvd-btns evi-discard-btns ${this.props.read_only ? 'cnotallowed' : ""}`} onClick={() => this.props.handleSelectEvidence(this.props.id)} >
								<span class="icon-Discard-2 evi-btns-icon"></span> Discard
							</div>
							:
							<div className="ecvd-btns evi-select-btns" onClick={() => this.props.handleSelectEvidence(this.props.id)} >
								<span className="icon-check-1 evi-btns-icon"><span className="path1"></span><span className="path2"></span></span> Select
							</div>
						}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default withRouter(EvidenceCardView);