import React from 'react'
import FinancialYear from '../../Components/BoilerPlate/FinancialYear'
import ChequeFilter from './ChequeFilter'
import ChequeList from './ChequeList'
import ShowCount from './ShowCount'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class ChequeListView extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			show_filter: false,
			search_text: ""
		}
	}

	handleChange = (e) => {
		const { value, name } = e.target;
		this.setState({ [name]: value });
	}

	toggleShowFilter = () => {
		this.setState({ show_filter: !this.state.show_filter })
	}

	render() {
		return (
			<div className="content-wrapper">
				<div className="container-fluid">
					<div className="row bg-w bg-rounded">
						<div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
								<Link to={'/home'}>Home</Link>
                      </li>
								<li className="breadcrumb-item">Cheques</li>
							</ol>
							<div className="clearfix main-title-btn-sec">
								<div className="head-title pull-left">
									<h2 className="s-sbold blu-color m-0">Cheques <span className="icon-Information green-icon green-icon-head"></span></h2>
								</div>
							</div>
							<div className="main-evidence-sms-sec">
								<FinancialYear refreshItem={this.props.refreshItem} financialYearChanged={this.props.financialYearChanged} />
								<ShowCount onCardSelected={this.props.onCardSelected} filter_card={this.props.filter_card} />
								<div className="main-evidence-sms-table-sec">
									<div className="toggle-search-sec clearfix">
										<div className="search-filter-sec pull-right">
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">
												Bank, Cheque#, Payer, Payee
        										</Tooltip>}>
												<form className="search-sec">
													<input type="text" placeholder="Search" value={this.state.search_text}
														name="search_text" onChange={(e) => this.handleChange(e)} />
													<button onClick={(e) => {
														e.preventDefault()
														this.props.handleSearch(this.state.search_text)
													}} >
														<span className="icon-loupe"  ></span>
													</button>
												</form>
											</OverlayTrigger>

											<div className="filter" onClick={this.toggleShowFilter}>
												Filter <span className="icon-funnel"></span>
											</div>
											<button className="add-new-btn btn ft-sz-20 white-color s-bold"
												onClick={() => this.props.history.push('/cheque/create')}>
												<span className="icon-plus1"></span> Add New</button>
										</div>
									</div>
									{this.state.show_filter ? <ChequeFilter applyFilter={this.props.applyFilter} /> : null}
									<ChequeList
										cheque_list={this.props.cheque_list}
										onSortClick={this.props.onSortClick}
										sort_by={this.props.sort_by}
										sort_order={this.props.sort_order}
										getChequeList={this.props.getChequeList}
										makeRemoveStar={this.props.makeRemoveStar}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}
}


function mapStateToProps(state) {
	const all_value = state.cheque

	return {
	};
}


export default connect(mapStateToProps)(withRouter(ChequeListView));