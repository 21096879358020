import Licenses from "../../Views/ProfileManagement/Licenses/Licenses";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//Cheques
export const CLEAR_CHEQUE = "CLEAR_CHEQUE";
export const SET_IS_CHEQUE_LOADING = "SET_IS_CHEQUE_LOADING";
export const CHEQUE_COUNT_DATA = "CHEQUE_COUNT_DATA";
export const CHEQUE_FILTER_DATA = "CHEQUE_FILTER_DATA";

// Task Manager
export const APPLY_FILTER_DATA = "APPLY_FILTER_DATA";
export const TASK_COUNT_DATA = "TASK_COUNT_DATA";
export const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const SET_PAGE_NO = "SET_PAGE_NO";
export const SET_PER_PAGE_ITEM = "SET_PER_PAGE_ITEM";
export const SET_IS_TASK_LOADING = "SET_IS_TASK_LOADING";
export const SET_TASK_STAR = "SET_TASK_STAR";
export const SET_TM_FINANCIAL_YEAR_NAME = "SET_TM_FINANCIAL_YEAR_NAME";
export const SET_TM_FILTER_DATE_RANGE = "SET_TM_FILTER_DATE_RANGE";
export const SET_TM_CARD_FILTER = "SET_TM_CARD_FILTER";
export const SET_TM_TEMP_DATA = "SET_TM_TEMP_DATA";

// Invoices
export const SET_INVOICE_FILTER_DATA = "SET_INVOICE_FILTER_DATA";
export const SET_IS_INVOICE_LOADING = "SET_IS_INVOICE_LOADING";
export const INVOICE_WIDGET_DATA = "INVOICE_WIDGET_DATA";

//Snapshot
export const SET_SNAPSHOT_FILTER_DATA = "SET_SNAPSHOT_FILTER_DATA";
export const SNAPSHOT_WIDGET_DATA = "SNAPSHOT_WIDGET_DATA";
export const SET_IS_SNAPSHOT_LOADING = "SET_IS_SNAPSHOT_LOADING";
//bulk action 
export const SET_BULK_SNAPSHOT_LIST = "SET_BULK_SNAPSHOT_LIST";
//export const SET_SMS_FOR_EVIDENCE = "SET_SMS_FOR_EVIDENCE";
export const SMS_SET_PAGE_NO = "SMS_SET_PAGE_NO";
export const SMS_SET_PER_PAGE_ITEM = "SMS_SET_PER_PAGE_ITEM";
export const SMS_SET_TOTAL_COUNT = "SMS_SET_TOTAL_COUNT";
export const SMS_SET_PAYMENT_INFORMATION = "SMS_SET_PAYMENT_INFORMATION";
export const SET_FINANCIAL_YEAR_FILTER_NAME = "SET_FINANCIAL_YEAR_FILTER_NAME";
export const SET_SNAPSHOT_CARD_FILTER = "SET_SNAPSHOT_CARD_FILTER";
export const SET_SNAPSHOT_SEARCHING_TEXT = "SET_SNAPSHOT_SEARCHING_TEXT";
export const SET_COMMUNICATION_BAR_DIRTY_STATUS = "SET_COMMUNICATION_BAR_DIRTY_STATUS";
export const SET_SNAP_TEMP_VOUCHER_LIST = "SET_SNAP_TEMP_VOUCHER_LIST";
export const SET_SNAPSHOT_DEFAULT_WIDGET_CARDS = "SET_SNAPSHOT_DEFAULT_WIDGET_CARDS";
export const SET_SNAPSHOT_FILTER_DATE_RANGE = "SET_FILTER_DATE_RANGE";
export const SET_TEMP_INVENTORY_DATA = "SET_TEMP_INVENTORY_DATA";

//Money Link
export const SET_TRANSACTION_FILTER_DATA = "SET_TRANSACTION_FILTER_DATA";
export const SET_IS_TRANSACTION_LOADING = "SET_IS_TRANSACTION_LOADING";
export const SET_TRANSACTION_PAGE_NO = "SET_TRANSACTION_PAGE_NO";
export const SET_FINANCIAL_INSTITUTE_NAME = "SET_FINANCIAL_INSTITUTE_NAME";
export const SET_FINANCIAL_YEAR_NAME = "SET_FINANCIAL_YEAR_NAME";
export const SET_TRANSACTIONS_FILTER_BY = "SET_TRANSACTIONS_FILTER_BY";
export const SET_EVIDENCE_LIST_DATA = "SET_EVIDENCE_LIST_DATA";
export const SET_EVIDENCE_FILTER_DATA = "SET_EVIDENCE_FILTER_DATA";
export const SET_LINKED_EVIDENCE_LIST = "SET_LINKED_EVIDENCE_LIST";
export const SET_FILTER_DATE_RANGE = "SET_FILTER_DATE_RANGE";
export const SET_FILTER_CARD = "SET_FILTER_CARD";
export const SET_NO_SUPPORTING_DOCUMENT = "SET_NO_SUPPORTING_DOCUMENT";
export const SET_TRANSACTION_STATUS = "SET_TRANSACTION_STATUS";
export const SET_ENTRY_STATUS = "SET_ENTRY_STATUS";
export const SET_ACCOUNT_STATUS = "SET_ACCOUNT_STATUS";
export const SET_BUSINESS_NARRATION = "SET_BUSINESS_NARRATION";
export const SET_IS_PERSONAL = "SET_IS_PERSONAL";
export const SET_TEMP_LINKED_EVIDENCE_LIST = "SET_TEMP_LINKED_EVIDENCE_LIST";
export const SET_MONEY_LINK_DEFAULT_WIDGET_CARDS = "SET_MONEY_LINK_DEFAULT_WIDGET_CARDS";
export const SET_MONEY_LINK_CARD_FILTER = "SET_MONEY_LINK_CARD_FILTER";
// export const SET_VOUCHER_LIST = "SET_VOUCHER_LIST";
export const SET_TEMP_VOUCHER_LIST = "SET_TEMP_VOUCHER_LIST";
export const SET_TRANSACTION_DETAILS = "SET_TRANSACTION_DETAILS";
export const SET_SUGGESTED_EVIDENCE_LIST_DATA = "SET_SUGGESTED_EVIDENCE_LIST_DATA";
export const SET_SUGGESTED_EVIDENCE_IDS = "SET_SUGGESTED_EVIDENCE_IDS";
export const SET_COST_CATEGORY_LIST = "SET_COST_CATEGORY_LIST"
//Bulk ledger entries
export const SET_BULK_TRANSACTION_LIST = "SET_BULK_TRANSACTION_LIST";
export const SET_CR_DR_TYPE = "SET_CR_DR_TYPE";
export const SET_BANK_GAP_COUNT = "SET_BANK_GAP_COUNT";



//Common
export const SET_APPLICATION_LOADER = "SET_APPLICATION_LOADER";

//Users
export const SET_ENTITY_LIST_DATA = "SET_ENTITY_LIST_DATA";
export const SET_SELECTED_ENTITY_LIST = "SET_SELECTED_ENTITY_LIST";
export const SET_FEATURE_LIST = "SET_FEATURE_LIST";
export const SET_DEFAULT_ROLES = "SET_DEFAULT_ROLES";
export const SET_PERMISSION_ID = "SET_PERMISSION_ID"

//Financial Institute
export const SET_FINANCIAL_INSTITUTE_REQUEST_ID = "SET_FINANCIAL_INSTITUTE_REQUEST_ID";
export const SET_FI_FILTER_DATE_RANGE = "SET_FILTER_DATE_RANGE";
export const SET_FI_FINANCIAL_YEAR_NAME = "SET_FINANCIAL_YEAR_NAME";

//User management
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_ENTITY_DETAIL = "SET_ENTITY_DETAIL";
export const SET_DEFAULT_ENTITY = "SET_DEFAULT_ENTITY";
export const SET_ACCOUNT_PERMISSION = "SET_ACCOUNT_PERMISSION";
export const SET_ENTITY_PERMISSION = "SET_ENTITY_PERMISSION";
export const SET_MODULE_PERMISSION = "SET_MODULE_PERMISSION";
export const SET_SYSTEM_PARAMETER = "SET_SYSTEM_PARAMETER";
export const SET_ENTITY_CONFIG_PARAMETER = "SET_ENTITY_CONFIG_PARAMETER";

//Licenses
export const SET_ORDER_DETAILS = "SET_ORDER_DETAILS";
export const SET_BUY_LICENSE_QUANTITY = "SET_BUY_LICENSE_QUANTITY";


//Notification
export const SET_EARLIER_NOTIFICATION = "SET_EARLIER_NOTIFICATION";
export const SET_NOTIFICATION_LIST = "SET_NEW_NOTIFICATION";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
