import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import component views
import LicenseList from './LicenseList';
import BuyLicenses from './BuyLicenses';
import OrderSummary from './OrderSummary';
import OrderHistory from './OrderHistory';
import UpgradeLicense from './UpgradeLicense';
import RenewLicenses from './RenewLicenses';
import ScrollPage from '../../../Components/BoilerPlate/ScrollPage';

class Licenses extends React.Component { 

          
    render() {
        return (
            <div>
            <ScrollPage />
            <Switch>
                
                <Route exact path="/licenses/buy-licenses"
                    render={() => { 
                        return <BuyLicenses/>
                    }} 
                />
                <Route exact path="/licenses/order-summary"
                    render={() => { 
                        return <OrderSummary/>
                    }} 
                />
                <Route exact path="/licenses/order-history"
                    render={() => { 
                        return <OrderHistory/>
                    }} 
                />
                <Route exact path="/licenses/upgrade-license/:account_license_id?"
                    render={() => { 
                        return <UpgradeLicense/>
                    }} 
                />
                <Route exact path="/licenses/renew-licenses"
                    render={() => { 
                        return <RenewLicenses/>
                    }} 
                />
                <Route exact path="/licenses" 
                    render={() => { 
                        return <LicenseList/>
                    }} 
                />
            </Switch>
            </div>
        )
    }
}

export default Licenses;