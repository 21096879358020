
import React, { Component } from 'react';
import { connect } from "react-redux";
// import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {
	SNAPSHOT_LEDGER_ENTRIES_CODE, LINKING_CODE, MANAGE_OTHER_USER_SNAPSHOT_CODE, VIEW_ONLY_SNAPSHOT_CODE, CREATE_REIMBUSRMENT_SNAPSHOT_CODE, APPROVE_REIMBURSMENT_SNAPSHOT_CODE,
} from '../../Helpers/Constants';

class ShowWidgets extends Component {

	constructor(props) {
		super(props);

		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.SNAPSHOT_LEDGER_ENTRIES_CODE = this.PERMISSION_LIST.includes(SNAPSHOT_LEDGER_ENTRIES_CODE);
		this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
		this.MANAGE_OTHER_USER_SNAPSHOT_CODE = this.PERMISSION_LIST.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);
		this.VIEW_ONLY_SNAPSHOT_CODE = this.PERMISSION_LIST.includes(VIEW_ONLY_SNAPSHOT_CODE);
		this.CREATE_REIMBUSRMENT_SNAPSHOT_CODE = this.PERMISSION_LIST.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE);
		this.APPROVE_REIMBURSMENT_SNAPSHOT_CODE = this.PERMISSION_LIST.includes(APPROVE_REIMBURSMENT_SNAPSHOT_CODE);

		this.state = {
			filter_card: (!!!this.props.type) ? this.props.filter_card : 3
		};
	}

	onCardSelected = async(snapshot_card_filter) => {
		if (!!this.props.checkBulkFlag) {
			if (await this.props.checkBulkFlag()) {
				return true;
			}
		}
		if (snapshot_card_filter != this.state.filter_card) {
			this.setState({
				filter_card: snapshot_card_filter
			}, () => this.props.onCardSelected(this.state.filter_card))
		}
	}

	render() {
		//console.log(this.props.default_cards)
		return (
			<>
				<div className="widgets_snapin">
					<div className="row widget-filter-new sec-mb">
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={(!!!this.props.type) ? () => this.onCardSelected(0) : () => { }}>
							<div className={"widget-block-new " + (this.state.filter_card == 0 ? " active-widget" : "") +  (!!this.props.type ? " disable-new-widgets " : " ")}>
								<p className="wtitle-new">Total</p>
								<p className="wcounter-new">{this.props.widget_count.total}</p>
							</div>
						</div>
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={(!!!this.props.type) ? () => this.onCardSelected(1) : () => { }}>
							<div className={"widget-block-new notready_usrin " + (this.state.filter_card == 1 ? " active-widget" : "") +  (!!this.props.type ? " disable-new-widgets " : " ")}>
								<p className="wtitle-new">Draft</p>
								<p className="wcounter-new">{this.props.widget_count.draft}</p>
							</div>
						</div>
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={(!!!this.props.type) ? () => this.onCardSelected(2) : () => { }}>
							<div className={"widget-block-new ready_usrin " + (this.state.filter_card == 2 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : " ")}>
								<p className="wtitle-new">Ready</p>
								<p className="wcounter-new">{this.props.widget_count.ready}</p>
							</div>
						</div>

						{/* Pending | Accounted */}
						{this.SNAPSHOT_LEDGER_ENTRIES_CODE &&
							<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 count-box-new-widget">
								<div className={"widget-block-new pending_usrin " + (this.state.filter_card == 3 || this.state.filter_card == 4 ? " active-widget" : "")} onClick={(e) => { e.preventDefault(); this.onCardSelected(this.props.default_cards.accounting_card) }}>

									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(3) }
											}><span>Pending</span> <span className="widgets-count">{this.props.widget_count.pending}</span></button>
											<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(4) : () => { } }}
												className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Accounted</span> <span className="widgets-count">{this.props.widget_count.accounted}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.accounting_card == 3 &&
										<>
											<p className="wtitle-new">Pending</p>
											<div className="wcounter-new">{this.props.widget_count.pending}</div>
										</>
									}
									{
										this.props.default_cards.accounting_card == 4 &&
										<>
											<p className="wtitle-new">Accounted</p>
											<div className="wcounter-new">{this.props.widget_count.accounted}</div>
										</>
									}
								</div>
							</div>
						}
						{/* End Pending | Accounted */}


						{this.SNAPSHOT_LEDGER_ENTRIES_CODE && <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 " onClick={(!!!this.props.type) ? () => this.onCardSelected(5) : () => { }}>
							<div className={"widget-block-new synched_usrin " + (this.state.filter_card == 5 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")}>
								<p className="wtitle-new">Synced</p>
								<p className="wcounter-new">{ this.props.widget_count.synced }</p>
							</div>
						</div>}


						{/* Synced | Synced (by user) */}
						{/* {this.SNAPSHOT_LEDGER_ENTRIES_CODE &&
							<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 count-box-new-widget">
								<div className={"widget-block-new synched_usrin " + (this.state.filter_card == 5 || this.state.filter_card == 6 ? " active-widget" : "")} onClick={() => this.onCardSelected(this.props.default_cards.synched_card)}>

									<div className="count-box-new-action">
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(5) }}><span>Synced</span> <span className="widgets-count">{this.props.widget_count.synced}</span></button>
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(6) }}><span>Synced (by user)</span> <span className="widgets-count">{this.props.widget_count.synced_by_user}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.synched_card == 5 &&
										<>
											<p className="wtitle-new">Synced</p>
											<div className="wcounter-new">{this.props.widget_count.synced}</div>
										</>
									}
									{
										this.props.default_cards.synched_card == 6 &&
										<>
											<p className="wtitle-new">Synced (by user)</p>
											<div className="wcounter-new">{this.props.widget_count.synced_by_user}</div>
										</>
									}
								</div>
							</div>
						} */}
						{/* End Synced | Synced (by user) */}

						{/* Approval | Approved */}
						{(this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.MANAGE_OTHER_USER_SNAPSHOT_CODE || this.VIEW_ONLY_SNAPSHOT_CODE || this.CREATE_REIMBUSRMENT_SNAPSHOT_CODE || this.APPROVE_REIMBURSMENT_SNAPSHOT_CODE) &&
							<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 count-box-new-widget">
								<div className={"widget-block-new fapproval_usrin " + (this.state.filter_card == 7 || this.state.filter_card == 8 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")} onClick={(!!!this.props.type) ? () => this.onCardSelected(this.props.default_cards.reimbursement_approval_card) : ()=>{}}>
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(7) : ()=>{} }} className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>For Approval</span> <span className="widgets-count">{this.props.widget_count.for_approval}</span></button>
											<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(8) : ()=>{} }} className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Approved</span> <span className="widgets-count">{this.props.widget_count.approved}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.reimbursement_approval_card == 7 &&
										<>
											<p className="wtitle-new">For Approval</p>
											<div className="wcounter-new">{this.props.widget_count.for_approval}</div>
										</>
									}
									{
										this.props.default_cards.reimbursement_approval_card == 8 &&
										<>
											<p className="wtitle-new">Approved</p>
											<div className="wcounter-new">{this.props.widget_count.approved}</div>
										</>
									}
								</div>
							</div>
						}
						{/* End Approval | Approved */}

						{/* Linked | Not Linked */}
						{this.LINKING_CODE &&
							<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 count-box-new-widget">
								<div className={"widget-block-new linked_usrin " + (this.state.filter_card == 9 || this.state.filter_card == 10 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")} onClick={(!!!this.props.type) ? () => this.onCardSelected(this.props.default_cards.linking_card) : ()=>{}}>									
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(9) : () =>{}}} className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Linked</span> <span className="widgets-count">{this.props.widget_count.linked}</span></button>
											<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(10) : ()=>{}}} className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Not Linked</span> <span className="widgets-count">{this.props.widget_count.not_linked}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.linking_card == 9 &&
										<>
											<p className="wtitle-new">Linked</p>
											<div className="wcounter-new">{this.props.widget_count.linked}</div>
										</>
									}
									{
										this.props.default_cards.linking_card == 10 &&
										<>
											<p className="wtitle-new">Not Linked</p>
											<div className="wcounter-new">{this.props.widget_count.not_linked}</div>
										</>
									}
								</div>
							</div>
						}
						{/* End Linked | Not Linked */}

						{/* <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={() => this.onCardSelected(3)}>
							<div className={"widget-block-new " + (this.state.filter_card == 3 ? " active-widget" : "")}>
								<p className="wtitle-new">Done</p>
								<p className="wcounter-new">{this.props.task_count.done}</p>
							</div>
						</div>
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={() => this.onCardSelected(4)}>
							<div className={"widget-block-new " + (this.state.filter_card == 4 ? " active-widget" : "")}>
								<p className="wtitle-new">Assigned to me</p>
								<p className="wcounter-new">{this.props.task_count.assigned_to_me}</p>
							</div>
						</div>
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={() => this.onCardSelected(5)}>
							<div className={"widget-block-new " + (this.state.filter_card == 5 ? " active-widget" : "")}>
								<p className="wtitle-new">Assigned by me</p>
								<p className="wcounter-new">{this.props.task_count.assigned_by_me}</p>
							</div>
						</div> */}
					</div>
				</div>


				{/* ----- d-none ----- */}

				<div className="d-none">
					<div className="tab-inner-sec widgets_snapin">
						<div className="row ">
							{/* Total */}
							<div className="col-xs-12 col-xl col-lg-3 col-md-3 " onClick={() => this.onCardSelected(0)}>
								<div className={"count-box text-center " + (this.state.filter_card == 0 ? " active-widget" : "")}>
									<p className="title ft-sz-28 m-0">Total</p>
									<div className="count-char s-bold">{this.props.widget_count.total}</div>
								</div>
							</div>
							{/* Total */}

							{/* Draft */}
							<div className="col-xs-12 col-xl col-lg-3 col-md-3 " onClick={() => this.onCardSelected(1)}>
								<div className={"count-box text-center notready_usrin " + (this.state.filter_card == 1 ? " active-widget" : "")}>
									<p className="title ft-sz-28 m-0">Draft</p>
									<div className="count-char s-bold">{this.props.widget_count.draft}</div>
								</div>
							</div>
							{/* Draft */}

							{/* Ready */}
							<div className="col-xs-12 col-xl col-lg-3 col-md-3 " onClick={() => this.onCardSelected(2)}>
								<div className={"count-box text-center ready_usrin " + (this.state.filter_card == 2 ? " active-widget" : "")}>
									<p className="title ft-sz-28 m-0">Ready</p>
									<div className="count-char s-bold">{this.props.widget_count.ready}</div>
								</div>
							</div>
							{/* Ready */}

							{/* Pending | Accounted */}
							{this.SNAPSHOT_LEDGER_ENTRIES_CODE && <div className="col-xs-12 col-xl col-lg-3 col-md-3 count-box-new-widget">
								<div className={"count-box text-center pending_usrin" + (this.state.filter_card == 3 || this.state.filter_card == 4 ? " active-widget" : "")} onClick={(e) => { e.preventDefault(); this.onCardSelected(this.props.default_cards.accounting_card) }}>
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(3) }
											}><span>Pending</span> <span className="widgets-count">{this.props.widget_count.pending}</span></button>
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(4) }}><span>Accounted</span> <span className="widgets-count">{this.props.widget_count.accounted}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.accounting_card == 3 &&
										<>
											<p className="title ft-sz-28 m-0">Pending</p>
											<div className="count-char s-bold">{this.props.widget_count.pending}</div>
										</>
									}

									{
										this.props.default_cards.accounting_card == 4 &&
										<>
											<p className="title ft-sz-28 m-0">Accounted</p>
											<div className="count-char s-bold">{this.props.widget_count.accounted}</div>
										</>
									}

								</div>
							</div>}
							{/* Pending | Accounted */}

							{/* Synced | Synced (by user) */}
							{this.SNAPSHOT_LEDGER_ENTRIES_CODE && <div className="col-xs-12 col-xl col-lg-3 col-md-3 count-box-new-widget">
								<div className={"count-box text-center synched_usrin " + (this.state.filter_card == 5 || this.state.filter_card == 6 ? " active-widget" : "")} onClick={() => this.onCardSelected(this.props.default_cards.synched_card)}>
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(5) }}><span>Synced</span> <span className="widgets-count">{this.props.widget_count.synced}</span></button>
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(6) }}><span>Synced (by user)</span> <span className="widgets-count">{this.props.widget_count.synced_by_user}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.synched_card == 5 &&
										<>
											<p className="title ft-sz-28 m-0">Synced</p>
											<div className="count-char s-bold">{this.props.widget_count.synced}</div>
										</>
									}

									{
										this.props.default_cards.synched_card == 6 &&
										<>
											<p className="title ft-sz-28 m-0">Synced (by user)</p>
											<div className="count-char s-bold">{this.props.widget_count.synced_by_user}</div>
										</>
									}

								</div>
							</div>}
							{/* Synced | Synced (by user) */}

							{/* For Approval | Approved */}
							{(this.SNAPSHOT_LEDGER_ENTRIES_CODE || this.MANAGE_OTHER_USER_SNAPSHOT_CODE || this.VIEW_ONLY_SNAPSHOT_CODE || this.CREATE_REIMBUSRMENT_SNAPSHOT_CODE || this.APPROVE_REIMBURSMENT_SNAPSHOT_CODE) && <div className="col-xs-12 col-xl col-lg-3 col-md-3 count-box-new-widget">
								<div className={"count-box text-center fapproval_usrin " + (this.state.filter_card == 7 || this.state.filter_card == 8 ? " active-widget" : "")} onClick={() => this.onCardSelected(this.props.default_cards.reimbursement_approval_card)}>
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(7) }}><span>For Approval</span> <span className="widgets-count">{this.props.widget_count.for_approval}</span></button>
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(8) }}><span>Approved</span> <span className="widgets-count">{this.props.widget_count.approved}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.reimbursement_approval_card == 7 &&
										<>
											<p className="title ft-sz-28 m-0">For Approval</p>
											<div className="count-char s-bold">{this.props.widget_count.for_approval}</div>
										</>
									}

									{
										this.props.default_cards.reimbursement_approval_card == 8 &&
										<>
											<p className="title ft-sz-28 m-0">Approved</p>
											<div className="count-char s-bold">{this.props.widget_count.approved}</div>
										</>
									}

								</div>
							</div>}
							{/* For Approval | Approved */}

							{/*  Linked | Not Linked */}
							{this.LINKING_CODE && <div className="col-xs-12 col-xl col-lg-3 col-md-3 count-box-new-widget">
								<div className={"count-box text-center linked_usrin " + (this.state.filter_card == 9 || this.state.filter_card == 10 ? " active-widget" : "")} onClick={() => this.onCardSelected(this.props.default_cards.linking_card)}>
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(9) }}><span>Linked</span> <span className="widgets-count">{this.props.widget_count.linked}</span></button>
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(10) }}><span>Not Linked</span> <span className="widgets-count">{this.props.widget_count.not_linked}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.linking_card == 9 &&
										<>
											<p className="title ft-sz-28 m-0">Linked</p>
											<div className="count-char s-bold">{this.props.widget_count.linked}</div>
										</>
									}

									{
										this.props.default_cards.linking_card == 10 &&
										<>
											<p className="title ft-sz-28 m-0">Not Linked</p>
											<div className="count-char s-bold">{this.props.widget_count.not_linked}</div>
										</>
									}

								</div>
							</div>}
							{/* Linked | Not Linked */}

						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	const widget_count = state.Snapshot.widget_count || { total: 0, draft: 0, ready: 0, pending: 0, accounted: 0, synced: 0, synced_by_user: 0, for_approval: 0, approved: 0, linked: 0, not_linked: 0 };

	const session_values = state.Session || {};
	const features_permissions_list = session_values.features_permissions_list || [];

	return {
		widget_count, features_permissions_list
	};
}

export default connect(mapStateToProps)(ShowWidgets);