import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import StorageData from "../../Helpers/StorageData";
import SessionManagement from '../../Utils/SessionManagement';

const PublicRoute = ({component: Component, restricted=true, ...rest}) => {
    const Authentication = SessionManagement.isLogin();
    if(!Authentication) {
      SessionManagement.RemoveAll();
      StorageData.deleteAllStorageValue();
    }
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            SessionManagement.isLogin() && restricted ?
                <Redirect to="/home" />
            : <Component {...props} />
        )} />
    );
};

export default PublicRoute;