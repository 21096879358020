import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function NotReadyStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Draft</Tooltip>}>
            <span className="icon-not-ready-icon icon-dt-td iconsc"><span className="path1 gray-icon"></span><span className="path2"></span><span className="path3"></span></span>
        </OverlayTrigger>
    </>
}
export default NotReadyStatus;