import { toUpperCase } from './Capitalization'
function changeDateFormate(date) {
  if (!date) {
    return null;
  }
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();

  let dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);

  return dateString;
}

function displayDateFormate(originaldate) {
  if (!originaldate) {
    return null;
  }
  var date = originaldate;
  if (!(originaldate instanceof Date)) {
    date = new Date(originaldate);
  }
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();

  if(isNaN(d)) {
    return originaldate;
  }
  let dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;

  return dateString;
}
function DatePickerFormate(dateFormat = "") {
  if (dateFormat) {
    return dateFormat
  }
  return "dd-MM-y";
}
function displayTimeFormat(originaldate) {
  if (!originaldate) {
    return null;
  }
  var date = originaldate;
  if (!(originaldate instanceof Date)) {
    date = new Date(originaldate);
  }
  // Hours
  var hours = date.getHours();
  
  // Minutes
  var minutes = "0" + date.getMinutes();
  
  // Seconds
  var seconds = "0" + date.getSeconds();

  return hours + ':' + minutes.substr(-2);
}




function displayDateTimeFormat(originaldate) {
  if (!originaldate) {
    return null;
  }
  var date = originaldate;
  if (!(originaldate instanceof Date)) {
    date = new Date(originaldate);
  }
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  

  if(isNaN(d)) {
    return originaldate;
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;

  let dateString = (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y + ' '+ strTime;

  return dateString;
}

function dateWithMonthName(date) {
  if (!date) {
    return null;
  }

  let d = date.getDate();
  let y = date.getFullYear();
  let monthName =  toUpperCase(date.toLocaleString('en-US', {month: 'short'}))
  let dateString = (d <= 9 ? '0' + d : d) + monthName + y;
  return dateString;
}

function changeDateTimeFormat(date) {
  if (!date) {
    return null;
  }
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  let dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + ' '+ hours + ':' + minutes + ':' + seconds;

  return dateString;
}

export {
  changeDateFormate,
  displayDateFormate,
  DatePickerFormate,
  displayTimeFormat,
  displayDateTimeFormat,
  dateWithMonthName,
  changeDateTimeFormat
}