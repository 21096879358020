import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function ProcessedStatus(props) {
    return <div  >
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Accounted</Tooltip>}>
            <span className="icon-processed-icon ft-sz-30 icon-td"><span className="path1 "></span><span className="path2 whitestatus"></span></span>
            {/* <span className="icon-td icon-Group-2510 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span> */}
        </OverlayTrigger> </div> 
}
export default ProcessedStatus;