import React, { Component } from "react";
import { Link,Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';



class YodleeCallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
            <img className="main-logo" src={logo_dash} />
        </nav>

        <div className="yodlee-error d-flex align-items-center justify-content-center p-3">
          <p className="ft-sz-22 s-sbold blu-color m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>        
      </div>

      // <div id="main">
      //         <div class="fof">
      //                 <h1>Error 404</h1>
      //                 <h4>click here to <Link className="s-bold blu-color" to="/login">Sign in</Link></h4>
      //                 <h4>click here to <Link className="s-bold blu-color" to="/register">Register</Link></h4>
      //         </div>
      // </div>
   );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(YodleeCallback);