import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import MasterService from "../../Services/MasterService";
import StorageData from "../../Helpers/StorageData";
import { toast } from 'react-toastify';
import { changeDateFormate, DatePickerFormate } from '../../Helpers/DateFormat';

import { Multiselect } from 'multiselect-react-dropdown';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class FinancialYear extends Component {


  constructor(props) {
    super(props);

    let financial_years_arr = StorageData.getFinancialYear();
    let default_yr = StorageData.getDefaultFinancialYear();

    this.state = {
      year_type: default_yr.year_type,
      financial_years_arr: financial_years_arr ? financial_years_arr : [],
      start_date: default_yr.year_type != 6 ? default_yr.start_date : "",
      end_date: default_yr.year_type != 6 ? default_yr.end_date : "",
      year_type_index: financial_years_arr ? financial_years_arr.findIndex(function (year) {
        return year.id == default_yr.year_type
      }) : 0
    }
  }

  onYearTypeChange = (year_type = 1) => {
    let start_date = this.state.financial_years_arr.find(x => x.id == year_type).start_date;
    let end_date = this.state.financial_years_arr.find(x => x.id == year_type).end_date;

    if (year_type == 6) {
      start_date = ""
      end_date = ""
    }

    this.setState({
      year_type: year_type,
      start_date: start_date ? new Date(start_date) : "",
      end_date: end_date ? new Date(end_date) : "",
    }, () => {
      if (year_type != 6) {
        StorageData.setDefaultFinacialYear(year_type);
        this.props.financialYearChanged(year_type, this.state.start_date, this.state.end_date);
      }
    }
    );
  }

  selectYearHandler = (selectedList, selectedItem) => {
    this.onYearTypeChange(selectedItem.id)
  }

  setDate = (date, key) => {
    this.setState({ [key]: date }, () => {
      if (!!this.state.start_date && !!this.state.end_date) {
        let fy = {
          id: 6,
          start_date: changeDateFormate(this.state.start_date),
          end_date: changeDateFormate(date),
          name: "Custom Date Range"
        };
        let financial_years_arr = this.state.financial_years_arr.map(fin_year => {
          return (fin_year.id == 6) ? fy : fin_year;
        });
        StorageData.setDefaultFinacialYear(6);
        StorageData.setFinancialYear(financial_years_arr);
        this.props.financialYearChanged(this.state.year_type, this.state.start_date, this.state.end_date);

      }
    });
  }

  getFinancialYear = () => {
    MasterService.getFinancialYear().then(
      (data) => {
        if (data.response_code == 200) {
          let fy = {
            id: 6,
            start_date: "",
            end_date: "",
            name: "Custom Date Range"
          };
          let financial_years_arr = [...data.result.date_filter, fy];
          this.setState({ financial_years_arr }, () => this.onYearTypeChange());
          StorageData.setFinancialYear(financial_years_arr);
        } else {
          toast.error(data.message);
        }
      });
  }


  componentDidMount() {
    let financial_years_arr = StorageData.getFinancialYear();
    //console.log(financial_years_arr)
    //let test = StorageData.getDefaultFinancialYear()
    // console.log(test)
    if (!financial_years_arr) {
      this.getFinancialYear();
    }
  }

  onChangeDateType = () => {
    this.setState({ date_type: !this.state.date_type })
  }

  render() {
    // console.log(StorageData.getDefaultFinancialYear())
    return <div className="filter-bar-new dropdown_taskin dropdown_snapin dropdown_bankin">
        <div className="global-ddown-new">
          {this.state.financial_years_arr.length != 0 ? <Multiselect
            //displayValue="key"
            onRemove={function noRefCheck() { }}
            onSearch={function noRefCheck() { }}
            onSelect={this.selectYearHandler}
            options={this.state.financial_years_arr}
            singleSelect
            placeholder="Select"
            displayValue="name"
            selectedValues={[
              this.state.financial_years_arr[this.state.year_type_index]
            ]}
          /> : null}
        </div>

        <div className={"" + ((this.state.year_type == 6 && window.location.pathname.includes('snapshot')) ? "" : " d-none ")}>
          {/* <div className="form-group"> */}
          <Select
            value={this.props.date_type}
            onChange={this.props.dateTypeChanged}
            name="year_type"
            className="global_new_dd_date">
            <MenuItem value="1">Invoice Date</MenuItem>
            <MenuItem value="2">Creation Date</MenuItem>
          </Select>
          {/* </div> */}
        </div>

        <span className={this.state.year_type == 6 ? " filter-tdates " : "d-none"}>
          <div className="start-end-date-sec">
              <DatePicker
              dateFormat={DatePickerFormate()}
              onChange={date => this.setDate(date, "start_date")}
              className="form-control field-height datepicker-icon"
              disabled={this.state.year_type == 6 ? "" : "disabled"}
              selected={this.state.start_date}
              maxDate={!!this.state.end_date ? new Date(this.state.end_date) : ""}
              autoComplete="off"
              placeholderText="From Date"
              name="start_date" />
          </div>
          <hr className="dash-flt" />
          <div className="start-end-date-sec">           
          <DatePicker
              dateFormat={DatePickerFormate()}
              onChange={date => this.setDate(date, "end_date")}
              className="form-control field-height datepicker-icon"
              disabled={(this.state.year_type == 6 ? "" : "disabled") || (!!this.state.start_date ? "" : "disabled")}
              selected={this.state.end_date}
              minDate={new Date(this.state.start_date)}
              autoComplete="off"
              placeholderText="To Date"
              name="end_date" />
          </div>
        </span>
      </div>
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(FinancialYear);