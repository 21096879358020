import React from 'react'
import { Button, Modal } from 'react-bootstrap';


class SaveChangesPopUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <Modal
                show={this.props.showModal}
                backdrop="static"
                keyboard={false}
                centered
                className="save-model-sec"
            >
                <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                        {/* <i className="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                        <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                        <p className="ft-sz-30 s-bold blu-color mb-1">Changes are not saved</p>
                        <p className="ft-sz-18 s-reg font-colr">Do you want to discard this changes ?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.toggleSavePopUp()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button onClick={() => this.props.saveChanges()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Yes</Button>
                </Modal.Footer>
            </Modal>

        )
    }
}

export default SaveChangesPopUp;