import firebase from 'firebase/app';
// import 'firebase/messaging';
import {ENV} from './Config'

var firebaseConfig = {
  apiKey: "AIzaSyAmRppcC0pe_w4hrxn1dLWZUU3-DuKAMgU",
  authDomain: "buktec-dev.firebaseapp.com",
  projectId: "buktec-dev",
  storageBucket: "buktec-dev.appspot.com",
  messagingSenderId: "156525090510",
  appId: "1:156525090510:web:b42fff5feac38cf799e29f",
  measurementId: "G-HXX1ZFL7ZK"
};
if(ENV == "qa"){
  console.log("---------ENV-----------",ENV)
  firebaseConfig = {
    apiKey: "AIzaSyCMO_VPWu58ba8C2UgaSrW9AbN2lnqrvRo",
    authDomain: "buktec-qa.firebaseapp.com",
    projectId: "buktec-qa",
    storageBucket: "buktec-qa.appspot.com",
    messagingSenderId: "361540213760",
    appId: "1:361540213760:web:e391ce09fcb02bec018ce5",
    measurementId: "G-60KHEEQG3J"
  };
}else if(ENV == "uat"){ 
  console.log("---------ENV-----------",ENV)
  firebaseConfig = {
    apiKey: "AIzaSyCs4X5wOd7hNslzKqALVgyZanhHIJ7R0xo",
    authDomain: "buktec-uat.firebaseapp.com",
    projectId: "buktec-uat",
    storageBucket: "buktec-uat.appspot.com",
    messagingSenderId: "153353168762",
    appId: "1:153353168762:web:d27d4df85d971d3f6298c3"
  };
}else if(ENV == "production"){ 
  console.log("---------ENV-----------",ENV)
  firebaseConfig = { 
    apiKey: "AIzaSyDV4U2R_vhM9FuIaNCvbld0PIwW8RG4ruo",
    authDomain: "buktec-production.firebaseapp.com",
    projectId: "buktec-production",
    storageBucket: "buktec-production.appspot.com",
    messagingSenderId: "392881084904",
    appId: "1:392881084904:web:5f6be7087d9a6a0f6cec5d"
  };
}


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();

// export const getToken = (setTokenFound) => {
//     return messaging.getToken({vapidKey: 'GENERATED_MESSAGING_KEY'}).then((currentToken) => {
//       if (currentToken) {
//         console.log('current token for client: ', currentToken);
//         setTokenFound(true);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//         setTokenFound(false);
//         // shows on the UI that permission is required 
//       }
//     }).catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//       // catch error while creating client token
//     });
//   }

  export default firebase;
