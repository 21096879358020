import SessionManagement from "../Utils/SessionManagement";
import { DOCAI_ENV_KEY, ENV } from "../Utils/Config";
class Header {

  constructor() {
  }

  authHeader() {
    const is_login = SessionManagement.isLogin();
    if (is_login && !!SessionManagement.getAccessToken()) {
      return { 'Content-Type': 'application/json', Authorization: SessionManagement.getAccessToken(),'platform':"web",'version':'2.0' };
    } else {
      return {};
    }
  }

  entityHeader() {
    const is_login = SessionManagement.isLogin();
    const entity = JSON.parse(localStorage.getItem('entity'));
    let entity_id = entity ? entity.id : null
    if (is_login && SessionManagement.getAccessToken()) {
      return { 'Content-Type': 'application/json', 'Authorization': SessionManagement.getAccessToken(), "entity-id": entity_id,'platform':"web",'version':'2.0' };
    } else {
      return {};
    }
  }

  withoutAuthHeader() {
    return { 'Content-Type': 'application/json','platform':"web",'version':"2.0" };
  }

  //Manage environment 
  
  formDataHeader() {
   
    const is_login = SessionManagement.isLogin();
    const entity = JSON.parse(localStorage.getItem('entity'))
    let entity_id = entity ? entity.id : null
    if (is_login && SessionManagement.getAccessToken()) {
      return {
        'Content-Type': 'multipart/form-data',
        Authorization: SessionManagement.getAccessToken(),
        "entity-id": entity_id,
        'platform':"web",'version':'2.0',
        'environment':DOCAI_ENV_KEY,
      };
    } else {
      return {};
    }
  }

}

export default new Header();
