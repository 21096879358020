import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class TaskManagerService {

  getTaskDetail() {
    return axios.get(API_URL + 'business_view_account', { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  updateTask(gst_no, address, city_id, state_id, country_id, pincode) {
    let data = {
      gst_no: gst_no,
      address: address,
      city_id: city_id,
      state_id: state_id,
      country_id: country_id,
      pincode: pincode
    };
    return axios
      .put(API_URL + "business_update_account", data, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getRequiredDataForTask() {
    return axios
      .get(API_URL + "required-data-for-task", { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.log('error  ', error)
        return error;
      });
  }

  createTask(data) {
    return axios
      .post(API_URL + "task", data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.log('error  ', error)
        return error;
      });
  }

  viewTask(id) {
    return axios
      .get(API_URL + "task/" + id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.log('error  ', error)
        return error;
      });
  }

  updateTask(data) {
    return axios
      .put(API_URL + "task", data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.log('error  ', error)
        return error;
      });
  }

  deleteTask(id) {
    return axios
      .delete(API_URL + "task/" + id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.log('error  ', error)
        return error;
      });
  }

  getChangeLog() {
    return axios.get(API_URL + 'change-log', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  /*
    Nehal will work on Below Service
  */

  getTaskList(filter_data) {
    return axios.post(API_URL + 'tasks',filter_data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

  getTaskCount(filter_data) {
    return axios.post(API_URL + 'tasks-count',filter_data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

  getFilterData() {
    return axios.get(API_URL + 'tasks-data', { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

  makeRemoveStar(data) {
    return axios.put(API_URL + 'task-star',data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }
  changeStatus(data) {
    return axios.put(API_URL + 'task-status',data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

  getHomePageTasks() {
    return axios.get(API_URL + 'tasks-home', { headers: Header.entityHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

}

export default new TaskManagerService();