import React from 'react'
import { Modal } from 'react-bootstrap';
import { Button, Dropdown } from 'react-bootstrap';
import DocboxService from '../../Services/Docbox/DocboxService';
import { toast } from 'react-toastify';

class AddNewFolderPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            folder_name: "",
            error: "",
        }
    }

    handleChange = (e) => {
        const folder_name = e.target.value;
        this.setState({ folder_name })
    }

    addFolderNameValidation(data) {

        // let extra_space_regex = /[\s]{2,}/
        // let number_char_regex = /[0-9\-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/

        // Empty data
        if (!data) {
            return "Folder Name is required"
        }

        // Extra white space
        // else if(data.search(extra_space_regex)>-1){
        //     return "Input field contains extra whitespace"
        // }
    }

    addNewFolder = () => {
        let validate = this.addFolderNameValidation(this.state.folder_name.trim());

        if (validate) {
            this.setState({ error: validate })
        } else {
            let detail = {
                "name": this.state.folder_name,
                "size": null,
                "crum_path": this.props.getCrumbPath(),
                "is_folder": 1,
                "file_url": "",
                "parent_id": this.props.parent_id
            }
            //console.log(detail);
            //return
            DocboxService.createFileFolder(detail)
                .then((data) => {
                    if (data.response_code == 200) {
                        this.props.addNewFolder(data.result);
                        this.props.toggleAddFolderPopup();

                        this.setState({
                            folder_name: '',
                            error: '',
                        })
                        toast.success(data.message)
                    } else {
                        if (!!data.reason) {
                            if (!!data.reason.name) {
                                this.setState({ error: data.reason.name })
                            } else {
                                if (!!data.message) {
                                    toast.error(data.message);
                                }
                            }
                        }

                    }
                })
        }
    }

    cancelHandler = () => {
        this.props.toggleAddFolderPopup();
        this.setState({ folder_name: '', error: '' })
    }

    render() {

        return (
            <Modal
                show={this.props.show_popup}
                onHide={() => {
                    this.setState({ folder_name: "", error: "" })
                    this.props.toggleAddFolderPopup()
                }}
                // size="lg"
                // aria-labelledby="example-modal-sizes-title-lg"
                centered
                className="popup-model error-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Folder Name</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-12 col-lg-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.folder_name}
                                    onChange={this.handleChange}
                                />
                            </div>
                            {this.state.error ? <small className="error_left">{this.state.error}</small> : null}
                        </div>

                        <div className="col-xs-12 col-lg-12 mt-1">
                            <div className="reset_apply_btn_new">
                                <Button variant="outline-success reset_btn_new" onClick={this.cancelHandler}>Cancel</Button>
                                <Button variant="success apply_btn_new" onClick={this.addNewFolder}>Add</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default AddNewFolderPopup;