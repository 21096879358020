import _ from "lodash"; 
import axios from 'axios';
import Header from '../Services/Header';
import { API_URL } from '../Utils/Config';

var activities = [];
var mainKeys = [];
var ikeys = []
var ignoreFinalKeys = [];

function iterate(obj, stack) {
    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == "object") {
                if(stack== ""){
                    iterate(obj[property], property);
                } else {
                    iterate(obj[property], stack + ' ' + property);
                }
            } else {
               
                if(stack== ""){
                    ikeys.push(property)
                } else {
                    ikeys.push(stack + " " + property)
                }         
            }
        }
    }
}

var formatLogData = function(data, ignoreKeys, nkey = "") {
    
    ignoreFinalKeys = ignoreKeys;
    if(nkey == ""){
        activities = [];
        ikeys = []
        iterate(data, '')
       
        ikeys.forEach((property, index) => {
            ikeys[index] = property.replace(/[0-9]/g, '');
            ikeys[index] = ikeys[index].replace('logtype', '');
            ikeys[index] = ikeys[index].replace('logdata', '');
            ikeys[index] = ikeys[index].replace(/  +/g, ' ').trim();
        });

        ikeys = _.uniq(ikeys)
      
        ikeys.forEach((property, index) => {
            ikeys[index] = property.replace(' ', ' => ');
        });
    }
    //console.log(data)
    for (var key in data) {
       
        if (data.hasOwnProperty(key)) {
            if((nkey == "" || mainKeys.includes(key)) && !ignoreKeys.includes(key)){
                nkey = key;
            } else {
                if(isNaN(key) && !ignoreKeys.includes(key)){
                    nkey = nkey + ' => ' + key
                }
            }
           
            if (Object.prototype.toString.call(data[key]) === '[object Object]' && data[key].logtype && data[key].logtype != 'unchanged') {

                if(data[key].logtype == 'Added'){
                    //console.log(data[key])
                   addActivity(data[key], nkey)
                   _.replace(nkey, key, '')
                }
                if(data[key].logtype == 'Changed' && !ignoreKeys.includes(key)){
                    //console.log(data[key].logtype+ ' --- '+ data[key])
                    addActivity(data[key], nkey)
                    _.replace(nkey, key, '')
                }
                if(data[key].logtype == 'Removed'){
                    //console.log(data[key].logtype+ ' --- '+ data[key])
                    addActivity(data[key], nkey)
                    _.replace(nkey, key, '')
                }
            } else if(!data[key].logtype && Object.prototype.toString.call(data[key]) === '[object Object]'){

                formatLogData(data[key], ignoreKeys, nkey)
            }
        }
    }
}

// var addActivity = function(data, key) {
//     var lastword = key.split(" => ").pop();
   
//     ikeys.forEach((ikey, index) => {
//         let lastkey = ikey.split(" ").pop();
       
//         if(lastkey == lastword){
//             nextkey = _.capitalize(_.replace(ikey, '_id', ''))
//             var nextkey = _.capitalize(_.replace(nextkey, '_', ' '))
//             activities.push(data.logtype + " "+nextkey)
//         }
//     });
//     //console.log(activities)
// }

var addActivity = function(data, key) {
    //console.log(data, key)
    var lastword = key.split(" => ").pop();
   
    ikeys.forEach((ikey, index) => {
        let lastkey = ikey.split(" ").pop();
       
        if(lastkey == lastword){
            nextkey = _.capitalize(_.replace(ikey, '_id', ''))
            var nextkey = _.capitalize(_.replace(nextkey, '_', ' '))
            activities.push(data.logtype + " "+_.startCase(lastkey))
        }
    });
    //console.log(activities)
}


var count_duplicate = function (a){
    let counts = {}
   
    for(let i =0; i < a.length; i++){ 
        if (counts[a[i]]){
            counts[a[i]] += 1
        } else {
            counts[a[i]] = 1
        }
    }  
    
    return counts
}

var sendLog = function(data, replace_words) {
    
    if(data.hasOwnProperty('attach_activity')) {
        if(data.attach_activity != "")
        activities.push(data.attach_activity)
    } 
    else if(data.hasOwnProperty('activity')) {
        activities = data.activity
    } 

    if(replace_words){
        activities.forEach((activity, i) => {
            for (var key in replace_words) {
              
                activity = activity.replace(key, replace_words[key])
                activities[i] = activity
            }
        })
    }
    //console.log(activities)
    if(activities.length > 0){
        var duplicates = count_duplicate(activities)
    
        activities = _.uniq(activities);
        activities.forEach((activity, index) => {
            if(duplicates[activity] > 1 && (activity.startsWith("Removed") || activity.startsWith("Added") || activity.startsWith("Changed"))){ 
                if(activity.startsWith("Removed")){
                    activities[index] = [activity.slice(0, 8), activity.slice(7)].join('');
                } else if(activity.startsWith("Added")){
                    activities[index] = [activity.slice(0, 6), activity.slice(5)].join('');
                } else {
                    activities[index] = [activity.slice(0, 8), activity.slice(7)].join('');
                }   
            } 
        });
       
        activities = activities.filter(function (e) {
            
            if(e == 'Removed Vouchers') {
                return false
            }
            return !ignoreFinalKeys.includes(e);
        });
    }

    //console.log(activities)
    
    if(activities.length > 0){
        let detail = {mod_id: data.module_id, module: data.module_type, activities: activities};
        console.log("activities----",activities)
        if(!!data.submodule){
            detail.submodule = data.submodule;
            detail.submod_id = data.submod_id;
        }
        console.log(detail);
        //return;
        axios.post(API_URL + 'change-log', JSON.stringify(detail), { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }
}

var createLog = function () {
    return {
        
        VALUE_CREATED: 'Added',
        VALUE_UPDATED: 'Changed',
        VALUE_DELETED: 'Removed',
        VALUE_UNCHANGED: 'unchanged',
        map: function(obj1, obj2, key = null) {
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw 'Invalid argument. Function given, object expected.';
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    logtype: this.compareValues(obj1, obj2, key),
                    logdata: obj1 === undefined ? obj2 : obj1
                };
            }
  
            var diff = {};
            for (var key in obj1) {
                if (this.isFunction(obj1[key])) {
                    continue;
                }
    
                var value2 = undefined;
                if (obj2[key] !== undefined) {
                    value2 = obj2[key];
                }
            
                diff[key] = this.map(obj1[key], value2, key);
            }
            for (var key in obj2) {
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                } 
        
                diff[key] = this.map(undefined, obj2[key], key);
            }
            
            return diff;
        },
        compareValues: function (value1, value2, abc) {
            console.log(value1, value2)
            
            if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            
            if(!value1 && value2){
                return this.VALUE_CREATED;
            }

            if(value1 && !value2){
                return this.VALUE_DELETED;
            }

            return this.VALUE_UPDATED;
        },
        isFunction: function (x) {
            return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray: function (x) {
            return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate: function (x) {
            return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject: function (x) {
            return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue: function (x) {
            return !this.isObject(x) && !this.isArray(x);
        }
    }
}()
  
export {createLog, formatLogData, sendLog}
