function validateReports(startDate, endDate) {
    let errors = {}

    if(!startDate) {
        errors.start_date = " Start date is required "
    }

    if(!endDate) {
        errors.end_date = " End date is required "
    }

    if(!startDate && !endDate) {
        errors.start_date = " Start date is required "
        errors.end_date = " End date is required "
    }

    return errors
}

export {
    validateReports
}