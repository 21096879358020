import axios, { CancelToken } from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class ReportService {

    getGSTR2bReport(data) {
        let headerObj =  { headers: Header.entityHeader() }
        headerObj.responseType = 'arraybuffer'

        return axios
            .post(API_URL + "gstr2b-report", data, headerObj)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
        }
    }


export default new ReportService();