import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

class PermissionComponent extends React.Component {
    constructor(props) {
        super(props)
    }

    handlePermissionChange = (e, perm_index) => {
        const checked = e.target.checked;
        this.props.handlePermissionChange(checked, perm_index, this.props.module_index)
    }

    componentDidMount() {
    }

    render() {
        const permission = this.props.permission;
        const perm_index = this.props.perm_index;

        return (
            <React.Fragment>
                <div className="col-xl-6 col-xs-12">
                    <div className="form-group-card-body">
                        <div className="sup-cust-check sup-cust-check-md">
                            <input
                                className="styled-checkbox"
                                type="checkbox"
                                checked={this.props.checked}
                                onChange={(e) => { this.handlePermissionChange(e, perm_index) }}
                                id={"perm-checkbox-" + this.props.module_index + perm_index + this.props.selected_entity_index}
                                name={"perm-checkbox-" + this.props.module_index + perm_index + this.props.selected_entity_index} />
                            <label htmlFor={"perm-checkbox-" + this.props.module_index + perm_index + this.props.selected_entity_index}
                                className="card-body-label">{permission.permission_name}</label>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const all_value = state.User || {};
    const available_entities = all_value.available_entities || [];
    const selected_entities = all_value.selected_entities || [];
    const module_list = all_value.module_list || [];
    const default_roles = all_value.default_roles || [];

    return {
        available_entities, selected_entities, module_list, default_roles,
    };
}

export default connect(mapStateToProps)(withRouter(PermissionComponent));