import { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import SortingIcon from '../Icon/SortingIcon';
import SortingIcon from '../IcoonNew/SortingIcon';
import { displayDateFormate } from '../../Helpers/DateFormat';
import ScrollPage from '../../Components/BoilerPlate/ScrollPage';
import AddNewFolderPopup from '../../Components/Docbox/AddNewFolderPopup';
import AddFilesPopup from '../../Components/Docbox/AddFilesPopup';
import { getFileSize } from '../../Helpers/HelperFunction';
import DocboxService from '../../Services/Docbox/DocboxService';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import StorageData from '../../Helpers/StorageData';
import ThreeDotsDocMenu from '../../Components/Docbox/ThreeDotsDocMenu';
import {
  CREATE_FILES_FOLDERS, VIEW_FILES_FOLDERS, MANAGE_ALL_FILES_FOLDERS
} from '../../Helpers/Constants';

class DocboxListView extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.PERMISSION_LIST = this.props.features_permissions_list;
    this.MANAGE_ALL_FILES_FOLDERS = this.PERMISSION_LIST.includes(MANAGE_ALL_FILES_FOLDERS);
    this.VIEW_FILES_FOLDERS = this.PERMISSION_LIST.includes(VIEW_FILES_FOLDERS);
    this.CREATE_FILES_FOLDERS = this.PERMISSION_LIST.includes(CREATE_FILES_FOLDERS);

    this.CREATE_FILES_FOLDERS = this.CREATE_FILES_FOLDERS && !this.MANAGE_ALL_FILES_FOLDERS;
    this.VIEW_FILES_FOLDERS = !this.MANAGE_ALL_FILES_FOLDERS && !this.CREATE_FILES_FOLDERS;

    //console.log(this.VIEW_FILES_FOLDERS, this.CREATE_FILES_FOLDERS, this.MANAGE_ALL_FILES_FOLDERS);

    this.state = {
      delete_id: null,
      bottom_reached: false,
      lazyLoadError: false,
      isCustomFolder: false
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  makeRemoveStar = (e, row) => {
    e.stopPropagation();
    console.log(row.is_star)
    let data = {
      id: row.id,
      is_star: row.is_star == 0 ? 1 : 0
    }
    this.props.makeRemoveStar(row.id, data.is_star)
    DocboxService.makeRemoveStar(data).then(
      (data) => {
        if (data.response_code == 200) {
        } else {
          this.props.makeRemoveStar(row.id, row.is_star)
          toast.error(data.message);
        }
      });
  }

  dataFormatStar = (cell, row) => {
    if (row.is_custom) return null;
    console.log(cell)
    switch (cell) {
      case 1:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>
      case 0:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
      default:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
    }
  }

  displayUploadedby = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{cell}</span></Tooltip>}>
      <span>{cell}</span>
    </OverlayTrigger>
  }

  displayFilesize = (cell, row) => {
    if (row.is_folder == 1) {
      return '-'
    } else {
      return <span>{getFileSize(cell)}</span>
    }
  }

  dataFormatStatus = (cell, row) => {
    if (row.is_custom) return null;
    if ((row.is_folder == 0 || (this.props.user_id == row.created_by && this.CREATE_FILES_FOLDERS)) || this.MANAGE_ALL_FILES_FOLDERS) {
      return <div onClick={e => e.stopPropagation()}>
        <ThreeDotsDocMenu
          is_folder={row.is_folder}
          view_only={this.VIEW_FILES_FOLDERS}
          //create={this.CREATE_FILES_FOLDERS}, this.MANAGE_ALL_FILES_FOLDERS
          MANAGE_ALL_FILES_FOLDERS={this.MANAGE_ALL_FILES_FOLDERS}
          onDownload={() => {
            console.log('row.qualified_url', row.qualified_url)
            if (row.is_folder == 0) {
              this.props.downloadFile(StorageData.getQualifiedFileUrl(row.qualified_url), row.name)
            }
          }}
          onDelete={() => {
            this.setState({ delete_id: row.id }, () => {
              this.props.toggleDeletePopUp()
            })

          }}
          created_by_self={this.props.user_id == row.created_by ? true : false}
        />
      </div>
    }
  }

  handleDownload = (row) => {
    console.log('row.qualified_url', row.qualified_url)
    this.props.downloadFile(StorageData.getQualifiedFileUrl(row.qualified_url), row.company)
  };

  renderDownloadIcon = (cell, row) => {
    return (
      <span
        className="icon-Download action-docs-icon"
        onClick={() => this.handleDownload(row)}
        title="Download"
      ></span>
    );
  };
  
  

  fileExt = (str) => {
    var file = str.split('/').pop();
    return file.substr(file.lastIndexOf('.') + 1, file.length)
  }

  displayName = (cell, row) => {
    if (row.is_custom) {
      return <span className="d-flex align-items-center custom-folder-row">
      <span className="star-td mt-0 mr-2"><span className="icon-Folder gray-folder-icon"></span></span>
      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span className='custom-folder-row'>{row.name}</span></Tooltip>}>
        <span>{row.name.length >= 45 ? `${row.name.substring(0, 45)}...` : row.name}</span>
      </OverlayTrigger>
    </span>
    }

    if (row.is_folder == 1) {
      return <span className="d-flex align-items-center">
        <span className="star-td mt-0 mr-2"><span className="icon-Folder gray-icon"></span></span>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
          <span>{row.name.length >= 45 ? `${row.name.substring(0, 45)}...` : row.name}</span>
        </OverlayTrigger>
      </span>
    } else {
      let ext = this.fileExt(row.name)
      if (ext == "pdf") {
        return <span className="d-flex align-items-center">
          <span className="star-td mt-0 mr-2"><span class="icon-pdf_file"><span class="path1"></span><span class="path2"></span></span></span>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
            <span>{row.name.length >= 55 ? `${row.name.substring(0, 55)}...` : row.name}</span>
          </OverlayTrigger>
        </span>
      } else if (ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "gif") {
        return <span className="d-flex align-items-center">
          <span className="star-td mt-0 mr-2"><span class="icon-image-file blue-icon"></span></span>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
            <span>{row.name.length >= 55 ? `${row.name.substring(0, 55)}...` : row.name}</span>
          </OverlayTrigger>
        </span>
      } else if (ext == "xlsx" || ext == "xls") {
        return <span className="d-flex align-items-center">
          <span className="star-td mt-0 mr-2"><span class="icon-excel"></span></span>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
            <span>{row.name.length >= 55 ? `${row.name.substring(0, 55)}...` : row.name}</span>
          </OverlayTrigger>
        </span>
      } else if (ext == "doc" || ext == "docm" || ext == "docx") {
        return <span className="d-flex align-items-center">
          <span className="star-td mt-0 mr-2"><span class="icon-word_file"></span></span>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
            <span>{row.name.length >= 55 ? `${row.name.substring(0, 55)}...` : row.name}</span>
          </OverlayTrigger>
        </span>
      } else if (ext == "zip" || ext == "rar" || ext == "jar") {
        return <span className="d-flex align-items-center">
          <span className="star-td mt-0 mr-2"><span class="icon-zip"></span></span>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
            <span>{row.name.length >= 55 ? `${row.name.substring(0, 55)}...` : row.name}</span>
          </OverlayTrigger>
        </span>
      }
      else {
        return <span className="d-flex align-items-center">
          <span className="star-td mt-0 mr-2"><span class="icon-file-icon blue-icon"></span></span>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span>{row.name}</span></Tooltip>}>
            <span>{row.name.length >= 55 ? `${row.name.substring(0, 55)}...` : row.name}</span>
          </OverlayTrigger>
        </span>
      }
    }
  }

  onSortClick = (sort_order, sort_by) => {
    this.props.onSortClick(sort_order, sort_by)
  }

  deleteFileFolder = () => {
    console.log(this.state.delete_id)
    this.props.deleteFileFolder(this.state.delete_id)
  }

  trackScrolling = () => {
    const { scrollTop, offsetHeight } = document.documentElement;
    const { innerHeight } = window;
    // const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;
    const bottomOfWindow = scrollTop + innerHeight >= offsetHeight - 5;

    if (bottomOfWindow) {
      // if (this.props.has_next) {
      //   this.props.handlePageChange();
      // }
      try {
        if (this.props.has_next) {
          this.props.handlePageChange();
          this.setState({ lazyLoadError: false });
        }
      } catch (error) {
        console.error("Lazy loading failed:", error);
        this.setState({ lazyLoadError: true });
      }
    } else {
      this.setState({ bottom_reached: false })
    }
  };

  handleRetry = () => {
    try {
      this.props.handlePageChange();
      this.setState({ lazyLoadError: false });
    } catch (error) {
      console.error("Retry failed:", error);
    }
  };

  onFolderClick = (folder) => {
    this.setState({ isCustomFolder: folder.is_custom });
    if (folder.is_folder === 1) {
      this.props.goInsideFolder(folder);
    }
  }

  render() {
    const { lazyLoadError } = this.state;
    const { isCustomFolder } = this.state;
    const isRootDocbox = !this.props.parent_id;

  const docboxListWithSnapshot = isRootDocbox
    ? [{ name: 'Snapshots (system folder)', is_folder: 1, is_custom: true }, ...this.props.docbox_list]
    : this.props.docbox_list;

    const snapshotData = this.props.snapshot_files.map(file => ({
      snapshot_id: `# ${file.id}`,
      date: file.date,
      ref_no: file.invoice_no,
      type: file.type,
      company: file.merchant_name,
      amount: `₹ ${file.total}`,
      uploaded_by: file.created_by,
      qualified_url:file.qualified_url,
      file_url: file.file_url
  }));

    //console.log(this.props.path_array)
    // const options = {
    //   onRowClick: (row) => {
    //     if (row.is_folder == 1)
    //       this.props.goInsideFolder(row);
    //   },
    //   //noDataText: this._setNoDataFoundOption(),
    // };

    const options = {
      onRowClick: this.onFolderClick,
    };

    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">

          <ScrollPage />

          <AddNewFolderPopup
            toggleAddFolderPopup={this.props.toggleAddFolderPopup}
            show_popup={this.props.show_add_folder_popup}
            addNewFolder={this.props.addNewFolder}
            parent_id={this.props.parent_id}
            getCrumbPath={this.props.getCrumbPath}
          />

          <AddFilesPopup
            toggleBulkUploadPopup={this.props.toggleBulkUploadPopup}
            show_bulk_upload_popup={this.props.show_bulk_upload_popup}
            finishFilesUpload={this.props.finishFilesUpload}
            parent_id={this.props.parent_id}
            getCrumbPath={this.props.getCrumbPath}
            system_parameter={this.props.system_parameter}
          ></AddFilesPopup>

          <DeletePopUp
            toggleDeletePopUp={this.props.toggleDeletePopUp}
            showModal={this.props.show_delete_popup}
            deleteItem={this.deleteFileFolder}
          />

          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item-new"><Link to={'/home'}>Home</Link></li>
                        <li className="breadcrumb-item-new" onClick={() => {this.setState({ isCustomFolder: false });
                        this.props.changePathByBreadCrum({ id: null, name: "My Docbox" })}}>My Docbox</li>
                        {
                          this.props.path_array.map((data) => {
                            return <li className="breadcrumb-item-new" onClick={() => this.props.changePathByBreadCrum(data)}>{isCustomFolder ? "Snapshots" : data.name}</li>
                          })
                        }
                      </ol>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="breadcrumb-title-new">Docbox</h2>
                    </div>
                    <div className="col-4">
                      <h2 className="breadcrumb-title-new sub-header-cicon text-right">
                        <button class="btn ico-refresh-sec" onClick={() => this.props.refresh()}><span class="icon-refresh"></span></button>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}


          {/* Title Button Bar */}
          <div className="sec-mb">
            <div className="row">
              <div className="col-4 d-flex align-items-center">
                <h3 className="mb-0">{isCustomFolder ? "Snapshots" : (this.props.current_folder_name || "My Docbox")}</h3>
              </div>

              <div className="col-8">
                {!isCustomFolder && !this.VIEW_FILES_FOLDERS &&
                  <div className="reset_apply_btn_new">
                    <Button variant="success apply_btn_new" onClick={this.props.toggleBulkUploadPopup}><span class="icon-plus1 btn-icons"></span> Add File</Button>
                    <Button variant="success apply_btn_new" onClick={this.props.toggleAddFolderPopup}><span class="icon-plus1 btn-icons"></span> Create Folder</Button>
                  </div>
                }
              </div>
            </div>
          </div>
          {/* End Title Button Bar */}


          {/* Table */}
          {/* <div className="row main-table-listing listing-icons"> */}
          <div className="row main-table-listing">
            <div className="col-sm-12">
              <div className="table-sec mb-0">
              {isCustomFolder ? (
                  <BootstrapTable
                    ref="table" table-bordered
                    data={snapshotData}
                    // options={options}
                  >
                    <TableHeaderColumn width="10%" isKey={true} dataField="snapshot_id" dataAlign="center" >
                    <SortingIcon
                    field_name="Snapshot ID"
                    field_number="1"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="10%" dataField="date" dataAlign="left" >
                    <SortingIcon
                    field_name="Date"
                    field_number="2"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="15%" dataField="ref_no" dataAlign="left" >
                    <SortingIcon
                    field_name="Ref No."
                    field_number="3"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="15%" dataField="type" dataAlign="left" >
                    <SortingIcon
                    field_name="Type"
                    field_number="4"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="30%" dataField="company" dataAlign="left" >
                    <SortingIcon
                    field_name="Company"
                    field_number="5"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="15%" dataField="amount" dataAlign="left" >
                    <SortingIcon
                    field_name="Amount"
                    field_number="6"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="15%" dataField="uploaded_by" dataAlign="left" >
                    <SortingIcon
                    field_name="Uploaded by"
                    field_number="7"
                    sort_by={this.props.sort_by}
                    sort_order={this.props.sort_order}
                    onSortClick={this.onSortClick}
                  />
                    </TableHeaderColumn>
                    <TableHeaderColumn width="80px" dataAlign="center" dataFormat={(cell, row) => this.renderDownloadIcon(cell, row)}>
                    </TableHeaderColumn>
                  </BootstrapTable>
                ) : (
                <BootstrapTable
                  ref='table' table-bordered
                  // trStyle={this.rowStyleFormat}
                  data={docboxListWithSnapshot}
                  // striped={true}
                  // hover={true}
                  options={options}
                >
                  <TableHeaderColumn width="80px" isKey={true} dataField="is_star" dataAlign="center" dataFormat={(cell, row) => this.dataFormatStar(cell, row)}><SortingIcon field_name="Star" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} onSortClick={this.onSortClick} /></TableHeaderColumn>
                  <TableHeaderColumn width="" dataField="name" dataAlign="left" dataFormat={(cell, row) => this.displayName(cell, row)}><SortingIcon field_name="Name" field_number="2" sort_by={this.props.sort_by} sort_order={this.props.sort_order} onSortClick={this.onSortClick} /></TableHeaderColumn>
                  <TableHeaderColumn width="14%" dataField="user_created_by" dataAlign="left" dataFormat={(cell, row) => this.displayUploadedby(cell, row)}><SortingIcon field_name="Uploaded by" field_number="5" sort_by={this.props.sort_by} sort_order={this.props.sort_order} onSortClick={this.onSortClick} /></TableHeaderColumn>
                  <TableHeaderColumn width="20%" dataField="last_modified" dataAlign="center" ><SortingIcon field_name="Last Modified" field_number="6" sort_by={this.props.sort_by} sort_order={this.props.sort_order} onSortClick={this.onSortClick} /></TableHeaderColumn>
                  <TableHeaderColumn width="15%" dataField="size" dataAlign="left" dataFormat={(cell, row) => this.displayFilesize(cell, row)}><SortingIcon field_name="File Size" field_number="4" sort_by={this.props.sort_by} sort_order={this.props.sort_order} onSortClick={this.onSortClick} /></TableHeaderColumn>
                  <TableHeaderColumn className="td-overflow" width="80px" dataField="status" dataAlign="right" dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}></TableHeaderColumn>
                </BootstrapTable>)}
                {/* End Table */}
              </div>
            </div>
          </div>
          {/* End Table */}

          {lazyLoadError && (
                  <div className="text-center mt-3">
                    <Button variant="danger" onClick={this.handleRetry}>
                      Try Again
                    </Button>
                  </div>
                )}

        </div>
      </main>
    )
  }
}

export default withRouter(DocboxListView);