import React from 'react'
import { Button, Modal } from 'react-bootstrap';


class DeletePopUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <Modal
                show={this.props.showModal}
                backdrop="static"
                keyboard={false}
                centered
                // className="save-model-sec error-popup-width er-pop"
                className="popup-model error-popup"
            >
                <Modal.Body className="clearfix ">
                    {this.props.message ?
                        <p className="pmsg-text pmsg-gray-center">{this.props.message}</p> :
                        <p className="pmsg-text pmsg-gray-center">Are you sure, you want to delete it?</p>
                    }

                    <div className="center_apply_btn_new">
                        <Button variant="outline-success reset_btn_new"  onClick={() => this.props.toggleDeletePopUp()}>No</Button>
                        <Button 
                            variant="success apply_btn_new" 
                            // variant={"success apply_btn_new " + (this.props.deleteLoading ? "position-reltv" : "")}
                            onClick={() => {this.props.deleteItem()}}
                            disabled={this.props.deleteLoading}                             
                        >
                            {/* {this.props.deleteLoading && (
                                <span className="spinner-border spinner-border-sm ml-4"></span>
                            )} */}
                            Yes
                        </Button>
                    </div>
                </Modal.Body>

                {/* <Modal.Body className="clearfix "> 
                    {this.props.message ? <p className="popup-gray-text text-center">{this.props.message}</p> : <p className="popup-gray-text text-center">Are you sure, you want to delete it ?</p>} 
                </Modal.Body>

                <Modal.Footer className='bottom-save-btn'>
                    <Button onClick={() => this.props.toggleDeletePopUp()} className="btn model-save-btn save-btn graybtn">No</Button>
                    <button type="button" onClick={() => {
                        this.props.deleteItem()}}
                        disabled={this.props.deleteLoading}
                        className={"btn save-btn greenbtn model-save-btn " + (this.props.deleteLoading ? "position-reltv" : "")}
                    >
                        {this.props.deleteLoading && (
                            <span className="spinner-border spinner-border-sm ml-4"></span>
                        )}
                        <span>Yes</span></button>
                </Modal.Footer> */}
            </Modal>
        )
    }
}

export default DeletePopUp;