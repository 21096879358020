import React from 'react';
import ReactDOM from 'react-dom';
import logo from '../../Assets/img/logo.png';
function registerTrial(){
    return <div className="bg-g"> 
    <div className="container-fluid">
		<div className="row">
		  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-6">
		    <div className="row main-register-trial">
		      <div className="col-lg-6 col-md-6 register-trial-left-logo">
		        <div className="login-logo-sec">
		          <img src={logo} />
		        </div>
		      </div>
		      <div className="col-lg-6 col-md-6 register-trial-right-logo">
		        <div className="register-logo-text"></div>
		      </div>
		    </div>
		  </div>
		  <div className="col-xs-12 col-sm-8 col-md-8 col-lg-6 bg-w">
		   <div className="row">
		      <div className="col-2"></div>
		      <div className="col-lg-9 col-md-9 col-xs-12">
		        <ul id="progressbar">
		          <li className="active">Register <span className="dot d-none" ></span>
		            <span className="dot-full">
		              <span>&#10003;</span>
		            </span>
		          </li>
		          <li>OTP verification</li>
		          <li>Complete</li>
		        </ul>
		      </div>
		      <div className="col"></div>
		    </div>
		    <div className="row"> 
		      <div className="col"></div>
		      <div className="col-lg-6 col-md-7 col-sm-7 col-xs-12 main-login-signin-form">
		        <div className="d-block">
		          <div className="login-signin-form">
		            <div className="form-title-head">
		              <h1 className="s-bold">Register for 30-day free trial</h1>
		            </div>
		            <form>
		              <div className="form-group">
		                <button type="button" className="btn btn-labeled">
		                 <span className="btn-label"><span className="icon-Mask-Group-1 ico-moon"></span></span>Business
		                </button>
		                <button type="button" className="btn btn-labeled button-ca">
		                 <span className="btn-label"><span className="icon-Group-2534 ico-moon"></span></span> Chartered Accountant
		                </button>
		              </div>
		              <div className="form-group">
		                <p className="m-0">Full Name*</p>
		                <input type="text" className="form-control" />
		                <small className="form-text text-muted d-none" >Please complete this required field.</small>
		              </div>
		              <div className="form-group">
		                <p className="m-0">Email*</p>
		                <input type="email" className="form-control" />
		                <small className="form-text text-muted d-none" >Please complete this required field.</small>
		              </div>
		              <div className="form-group">
		                <p className="m-0">Phone Number</p>
		                <input id="phone" name="phone" type="tel" className="form-control" />
		              </div>
		              <div className="form-group">
		                <p className="m-0">Username<span className="red-star">*</span></p>
		                <input type="text" className="form-control" />
		                <small className="form-text text-muted d-none" >Please complete this required field.</small>
		              </div>
		              <div className="form-group">
		                <p className="m-0">Password*</p>
		                <input type="password" className="form-control" />
		                <small className="form-text text-muted d-none" >Please complete this required field.</small>
		              </div>
		              <button type="submit" className="btn btn-primary"><span>Register as Business</span><span className="d-none">Register as CA</span></button>
		              <div className="login-text">
		                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
		                tempor incididunt.</p>
		              </div>
		              
		              <hr/>
		              <p>Already have account ? <a href="#" className="s-bold blu-color">Sign in</a>  </p>
		            </form>
		          </div>
				  <p className="login-captch">Invisible reCAPTCHA by Google <a href="https://thebuktec.com/new/privacy-policy/" target="_blank" className="blu-color s-sbold"> Privacy Policy</a> and <a href="https://thebuktec.com/new/terms-and-conditions/" target="_blank" className="blu-color s-sbold">Terms of Use.</a> </p> 
		        </div>  
		      </div>
		      <div className="col"></div>
		    </div>
		  </div>
		</div>
	</div>
	</div>

	
}











export default registerTrial;