import React from "react";
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal, Form, Button } from "react-bootstrap";
import CustomDatepicker from "../../Components/BoilerPlate/CustomDatepicker";
import {
  DatePickerFormate,
  displayDateTimeFormat,
  changeDateTimeFormat,
  changeDateFormate,
} from "../../Helpers/DateFormat";
import ReactSelect from "react-select";
import { TextareaAutosize } from "@material-ui/core";
import { convertFloatNumberToCurrency } from "../../Helpers/Currency";
import StorageData from "../../Helpers/StorageData";
import { decodeHtml } from "../../Helpers/HelperFunction";
import CurrencyInput from "react-currency-input-field";
import SnapshotService from "../../Services/Snapshot/SnapshotService";
import { confirm } from "../../Components/BoilerPlate/Confirm";
import Creatable from "react-select/creatable";
import DirtyFieldModal from "../../Components/BoilerPlate/DirtyFieldModal";
import { Resizable } from "re-resizable";
import DatePicker from "react-datepicker";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { toast } from "react-toastify";
import { setTempInventoryData } from "../../Redux/Actions/Snapshot";
import { roundOffValue } from "../../Views/Snapshot/Roundoff";

class InventoryAccounting extends React.Component {
  constructor(props) {
    super(props);

    let inventory_details = _.cloneDeep(this.props.inventory_details);
    //console.log(this.props)
    console.log("props in inve", inventory_details);
    this.state = {
      snapshot_id: this.props.snapshot_id,
      is_gst_override: false,
      sgst_amount_prediction_status: this.props.sgst_amount_prediction_status,
      cgst_amount_prediction_status: this.props.cgst_amount_prediction_status,
      gst_amount_prediction_status: this.props.gst_amount_prediction_status,
      tds_percentage_prediction_status:
        this.props.tds_percentage_prediction_status,
      errors: this.props.errors,
      line_item_errors: this.props.line_item_errors,
      ledger_errors: this.props.ledger_errors,
      show_edit_popup: false,
      ledger_options: [],
      is_igst: this.props.is_igst,
      sgst_amount: this.props.sgst_amount,
      cgst_amount: this.props.cgst_amount,
      igst_amount: this.props.igst_amount,
      gst_total_amount: this.props.gst_total_amount,
      is_gst_claim_eligible: this.props.is_gst_claim_eligible,
      tds_status: this.props.tds_status,
      tds_details: this.props.tds_details,
      tds_amount: this.props.tds_amount,
      total_amount: this.props.total_amount,
      accounting_narration: this.props.narration,
      merchant_option: this.props.merchant_option,
      line_items_list: this.props.line_items_list,
      disable_igst: false,
      inventory_voucher_id: inventory_details.inventory_voucher_id,
      line_item_total: inventory_details.line_item_total,
      line_item_gst_total: inventory_details.line_item_gst_total,
      line_item_discount_total: inventory_details.line_item_discount_total,
      inventory_voucher_status: inventory_details.inventory_voucher_status,
      is_common_item_ledger:
        inventory_details.is_common_item_ledger === ""
          ? this.props.is_common_item_ledger
          : inventory_details.is_common_item_ledger,
      accounting_narration:
        inventory_details.accounting_narration ||
        this.props.accounting_narration,
      sync_datetime: inventory_details.sync_datetime,
      sync_error: inventory_details.sync_error,
      sync_status: inventory_details.sync_status || 0,
      voucher_total_amount: inventory_details.voucher_total_amount,
      voucher_creation_date: inventory_details.voucher_creation_date
        ? new Date(inventory_details.voucher_creation_date)
        : this.props.date,
      voucher_creation_date_prediction_status:
        inventory_details.voucher_creation_date_prediction_status || 0,
      voucher_type: inventory_details.voucher_type,
      party_ledger_guid: inventory_details.party_ledger_guid,
      party_ledger_guid_prediction_status:
        inventory_details.party_ledger_guid_prediction_status || 0,
      party_ledger_id: inventory_details.party_ledger_id,
      party_ledger_id_prediction_status:
        inventory_details.party_ledger_id_prediction_status || 0,
      party_ledger_name: inventory_details.party_ledger_name,
      party_ledger_name_prediction_status:
        inventory_details.party_ledger_name_prediction_status || 0,
      common_item_ledger_guid: inventory_details.common_item_ledger_guid,
      common_item_ledger_guid_prediction_status:
        inventory_details.common_item_ledger_guid_prediction_status || 0,
      common_item_ledger_id: inventory_details.common_item_ledger_id,
      common_item_ledger_id_prediction_status:
        inventory_details.common_item_ledger_id_prediction_status || 0,
      common_item_ledger_name: inventory_details.common_item_ledger_name,
      common_item_ledger_name_prediction_status:
        inventory_details.common_item_ledger_name_prediction_status || 0,
      item_ledger_list: [],
      //line_items: inventory_details.line_items,
      ledgers:
        inventory_details.ledgers.length == 0
          ? [
            {
              amount: "",
              rate: "",
              guid: "",
              ledger_id: "",
              name: "",
            },
          ]
          : inventory_details.ledgers,
      line_items:
        inventory_details.line_items.length == 0
          ? [
            {
              line_item_id: "",
              line_item_id_prediction_status: 0,
              line_item_name: "",
              line_item_name_prediction_status: 0,
              hsn_sac: "",
              hsn_sac_prediction_status: 0,
              rate: "",
              rate_prediction_status: 0,
              per: "",
              per_prediction_status: 0,
              quantity: "",
              quantity_prediction_status: 0,
              gst_rate: "",
              gst_rate_prediction_status: 0,
              discount_rate: "",
              discount_rate_prediction_status: 0,
              amount: "",
              amount_prediction_status: 0,
              item_ledger_guid: "",
              item_ledger_guid_prediction_status: 0,
              item_ledger_id: null,
              item_ledger_id_prediction_status: 0,
              item_ledger_name: "",
              item_ledger_name_prediction_status: 0,
              common_item_ledger_guid: "",
              common_item_ledger_guid_prediction_status: 0,
              common_item_ledger_id: "",
              common_item_ledger_id_prediction_status: 0,
              common_item_ledger_name: "",
              common_item_ledger_name_prediction_status: 0,
              line_item_update: 0,
            },
          ]
          : inventory_details.line_items,
      remove_line_items: [],
      remove_ledgers: [],
      is_dirty: false,
      dirty_popup_visible: false,
      scale: 1,
      show_summary_section: false,
      is_base_amt_changed: this.props.is_base_amt_changed,
      voucher_type_guid:"",
      parent_voucher_type_id:"",
      voucher_type_name:"",
      voucher_type_id:"",
      check_sales_purchase:this.props.type == 1 ? 5 : 6,
    };
  }

  componentDidMount() {
    this.getItemLedgers();

    if (this.props.ledger_data.hasOwnProperty("ledger_list")) {
      let options = [];
      this.props.ledger_data.ledger_list.map((ledger, index) => {
        options.push({ label: decodeHtml(ledger.name), value: ledger.guid });
      });
      this.setState({ ledger_options: options });
    }

    this.props.onRef(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.tds_details, this.props.tds_details)) {
      this.setState({
        tds_details: nextProps.tds_details
      });
    }

    if (!_.isEqual(nextProps.ledger_data, this.props.ledger_data)) {
      if (nextProps.ledger_data.hasOwnProperty("ledger_list")) {
        let options = [];
        nextProps.ledger_data.ledger_list.map((ledger, index) => {
          options.push({ label: decodeHtml(ledger.name), value: ledger.guid });
        });
        this.setState({ ledger_options: options });
      }
    }

    if (!_.isEqual(nextProps.errors, this.props.errors)) {
      this.setState({
        errors: nextProps.errors,
        line_item_errors: nextProps.line_item_errors,
      });
    }

    if (!_.isEqual(nextProps.line_item_errors, this.props.line_item_errors)) {
      this.setState({ line_item_errors: nextProps.line_item_errors });
    }

    if (!_.isEqual(nextProps.ledger_errors, this.props.ledger_errors)) {
      this.setState({ ledger_errors: nextProps.ledger_errors });
    }

    if (
      nextProps.inventory_details.inventory_voucher_status !=
      this.props.inventory_details.inventory_voucher_status
    ) {
      //alert('ddd')
    }

    if (!_.isEqual(nextProps.item_ledger_list, this.props.item_ledger_list)) {
      //alert('dddt')
      this.setState({ item_ledger_list: nextProps.item_ledger_list });
    }
  }

  componentDidUpdate(prevProps, prevState) { }

  getItemLedgers = async () => {
    SnapshotService.getItemLedgers().then((data) => {
      if (data.response_code == 200) {
        this.setState({
          item_ledger_list: data.result.item_ledgers,
        });
      }
    });
  };

  //get sync voucher sync status icons according to conditions
  getVoucherSyncStatusOuter = (status, sync_datetime, sync_error) => {
    console.log(status);
    if (status == 0) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Not Synced</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon ledger-ls-icon">
            <span class="path1 gray-icon"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    } else if (status == 1) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Send to Sync</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon ledger-ls-icon">
            <span class="path1 blue-icon"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    } else if (status == 2) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Synced</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon ledger-ls-icon">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    } else if (status == 3) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Sync Error</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
              <br />
              Error: {sync_error}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon ledger-ls-icon">
            <span class="path1 red-icon"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    }
  };

  //get sync voucher sync status icons according to conditions
  getVoucherSyncStatus = (status, sync_datetime, sync_error) => {
    console.log(status);
    if (status == 0) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Not Synced</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon gray-dark-white-icon ledger-trs-icon">
            <span class="path1 gray-icon"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    } else if (status == 1) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Send to Sync</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon ledger-trs-icon">
            <span class="path1 blue-icon"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    } else if (status == 2) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Synced</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon  ledger-trs-icon">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    } else if (status == 3) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <span className="s-sbold">Sync Error</span>
              <br />
              {displayDateTimeFormat(sync_datetime)}
              <br />
              Error: {sync_error}
            </Tooltip>
          }
        >
          <span class="icon-personal-icon ledger-trs-icon">
            <span class="path1 red-icon"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </OverlayTrigger>
      );
    }
  };

  //get sync ledger list sync status icons according to conditions
  getLedgerSyncStatus = (status, sync_error) => {
    switch (status) {
      case 0:
        return (
          <OverlayTrigger overlay={<Tooltip>Not Synced</Tooltip>}>
            <span class="icon-personal-icon gray-dark-white-icon ledger-trs-icon">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>
          </OverlayTrigger>
        );
      case 1:
        return (
          <OverlayTrigger overlay={<Tooltip>Send to Sync</Tooltip>}>
            <span class="icon-personal-icon gray-dark-white-icon ledger-trs-icon">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>
          </OverlayTrigger>
        );
      case 2:
        return (
          <OverlayTrigger overlay={<Tooltip>Synced</Tooltip>}>
            <span class="icon-personal-icon ledger-trs-icon">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>
          </OverlayTrigger>
        );
      case 3:
        return (
          <OverlayTrigger
            overlay={
              <Tooltip>
                Sync Error
                <br />
                Error: {sync_error}
              </Tooltip>
            }
          >
            <span class="icon-personal-icon gray-dark-white-icon ledger-trs-icon">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
            </span>
          </OverlayTrigger>
        );
    }
  };

  amountChange = (name, value, ind = 0) => {
    if (value > 999999999.99) {
      return;
    }
    if (value == undefined) {
      value = "";
    }
    this.setState({ [name]: value, is_dirty: true });
    if (name == "sgst_amount") {
      this.setState({ sgst_amount_prediction_status: 0 });
    }
    if (name == "cgst_amount") {
      this.setState({ cgst_amount_prediction_status: 0 });
    }
    if (name == "igst_amount") {
      this.setState({ igst_amount_prediction_status: 0 });
    }
    // if (name == "tds_percentage") {
    //   this.setState({ tds_percentage_prediction_status: 0 });
    // }
    if (name == "tds_sub_amount") {
      let tds_arr = [...this.state.tds_details];
      tds_arr[ind].tds_amount = value;
      this.setState({ tds_details: tds_arr, tds_percentage_prediction_status: 0 }, () => {
        // this.calculateTDS();
      });
    }
    if (name == "base_amount") {
      let tds_arr = [...this.state.tds_details];
      tds_arr[ind].base_amount = value;
      if (ind == 0)
        this.setState({ is_base_amt_changed: true });
      console.log("index is_base_amt_changed --- ", ind, this.state.is_base_amt_changed);
      this.setState({ tds_details: tds_arr, tds_percentage_prediction_status: 0 }, () => {
        // this.calculateTDS();
      });
    }
    if (name == "tds_percentage") {
      let tds_arr = [...this.state.tds_details];
      tds_arr[ind].tds_percentage = value;
      this.setState({ tds_details: tds_arr, tds_percentage_prediction_status: 0 }, () => {
        // this.calculateTDS();
      });
    }
  };

  calculateTotalGst = () => {
    let gst_total_amount =
      parseFloat(this.state.sgst_amount || 0) +
      parseFloat(this.state.cgst_amount || 0) +
      parseFloat(this.state.igst_amount || 0);
    this.setState(
      {
        gst_total_amount :  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,gst_total_amount) : gst_total_amount,
      },
      () => {
        this.calculateTDS(0);
      }
    );
  };
  calculateTdsTotal = (ind) => {
		let tds_arr = [...this.state.tds_details];
		let tdsTotalAmt = 0;
		tds_arr = tds_arr.map((item, index) => {
			if(ind === index){
				console.log("same index --------------",ind , item , this.props.amount_round_off,(((item.tds_amount || 0) * 100 )/(item.base_amount || 0)).toFixed(2));
				if(item.base_amount)
				  item.tds_percentage =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(((item.tds_amount || 0) * 100 )/(item.base_amount || 0))):(((item.tds_amount || 0) * 100 )/(item.base_amount || 0)).toFixed(2);
				else if(!item.base_amount)
				  item.tds_amount = 0; 
				else if(item.tds_percentage && item.base_amount && !item.tds_amount)
				item.tds_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(((item.base_amount || 0) * parseFloat(item.tds_percentage || 0)) / 100)):(((item.base_amount || 0) * parseFloat(item.tds_percentage || 0)) / 100).toFixed(2);
			  }
		  tdsTotalAmt += parseFloat(item.tds_amount || 0);
		  return item;
		});
		this.setState({ tds_amount: this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tdsTotalAmt):tdsTotalAmt.toFixed(2)}, () => {});
	  };
  calculateTDS = (ind =  null) => {
		  console.log("calculate dts ------------------" ,this.state.tds_details);
		let tds_arr = [...this.state.tds_details];
		let tdsTotalAmt = 0;
		tds_arr = tds_arr.map((item, index) => {
      let total_amt = 0;
			if (!this.state.is_base_amt_changed && index == 0)
        total_amt = parseFloat(this.state.total_amount || 0) - ( parseFloat(this.state.gst_total_amount || 0));
			else total_amt = parseFloat(item.base_amount || 0);

      if(total_amt < 0) total_amt = 0;

			let tds_amount = (total_amt * parseFloat(item.tds_percentage || 0)) / 100;
			if (tds_amount < 0) tds_amount = 0;
			tdsTotalAmt += tds_amount;
	  
			total_amt = total_amt == 0?"":total_amt;
		if(index != null && ind == index){
			if (this.state.snapshot_id)
			  return {
				id: item.id,
				tds_percentage: item.tds_percentage?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,parseFloat(item.tds_percentage || 0)):parseFloat(item.tds_percentage || 0).toFixed(2):"",
				base_amount: total_amt? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,total_amt) : total_amt.toFixed(2):"", //item.base_amount,
				tds_amount: tds_amount?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(tds_amount||0)):(tds_amount||0).toFixed(2):"",
			  };
			else
			  return {
				tds_percentage: item.tds_percentage?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,parseFloat(item.tds_percentage || 0)):parseFloat(item.tds_percentage || 0).toFixed(2):"",
				// base_amount: total_amt?total_amt:"", //item.base_amount,
        base_amount: total_amt? this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,total_amt) : total_amt.toFixed(2):"", //item.base_amount,
				tds_amount: tds_amount?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,(tds_amount||0)):(tds_amount||0).toFixed(2):"",
			};	
		}
		else {
      console.log("tds else",item)
          if(item.base_amount < 0){
            item.base_amount = 0;
          }
          return item;
    }
		
		});
		console.log("tds array --------------", tds_arr);
	
		this.setState({ tds_details: tds_arr, tds_amount:tdsTotalAmt?this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tdsTotalAmt):tdsTotalAmt.toFixed(2):"" }, () => {
		 // this.checkForReadyState();
		});
	};

  //remove line item row if more than one rows are present and clear row if only one row present
  removeLineItem = (index, id) => {
    let remove_line_items = this.state.remove_line_items;
    if (id) {
      remove_line_items.push(id);
    }
    //console.log(remove_line_items)
    if (index == 0 && Object.keys(this.state.line_items).length == 1) {
      this.setState({
        line_items: [
          {
            // "id": 0,
            // "line_item_id": "",
            // "line_item_name": "",
            // "hsn_sac": "",
            // "rate": "",
            // "per": "",
            // "quantity": "",
            // "gst_rate": "",
            // "discount_rate": "",
            // "amount": "",
            // "item_ledger_guid": "",
            // "item_ledger_id": "",
            // "item_ledger_name": "",
            // "common_item_ledger_guid": "",
            // "common_item_ledger_id": "",
            // "common_item_ledger_name": ""
            id: 0,
            line_item_id: "",
            line_item_id_prediction_status: 0,
            line_item_name: "",
            line_item_name_prediction_status: 0,
            hsn_sac: "",
            hsn_sac_prediction_status: 0,
            rate: "",
            rate_prediction_status: 0,
            per: "",
            per_prediction_status: 0,
            quantity: "",
            quantity_prediction_status: 0,
            gst_rate: "",
            gst_rate_prediction_status: 0,
            discount_rate: "",
            discount_rate_prediction_status: 0,
            amount: "",
            amount_prediction_status: 0,
            item_ledger_guid: "",
            item_ledger_guid_prediction_status: 0,
            item_ledger_id: "",
            item_ledger_id_prediction_status: 0,
            item_ledger_name: "",
            item_ledger_name_prediction_status: 0,
            common_item_ledger_guid: "",
            common_item_ledger_guid_prediction_status: 0,
            common_item_ledger_id: "",
            common_item_ledger_id_prediction_status: 0,
            common_item_ledger_name: "",
            common_item_ledger_name_prediction_status: 0,
          },
        ],
        remove_line_items,
        is_dirty: true,
      });
    } else {
      let line_items = this.state.line_items;
      line_items.splice(index, 1);
      this.setState({ line_items, remove_line_items, is_dirty: true });
    }
  };

  updateLineItemData = (e, name, index) => {
    let line_items = [...this.state.line_items];

    if (name == "item_ledger_guid") {
      let item_ledger = this.state.item_ledger_list.filter(
        (item_ledger) => item_ledger.guid == e.value
      );

      if (item_ledger.length > 0) {
        line_items[index][name] = e.value;
        //prediction status
        line_items[index].item_ledger_guid_prediction_status = 0;

        //set other data for api
        line_items[index].item_ledger_name = item_ledger[0].name;
        line_items[index].item_ledger_id = item_ledger[0].id;
        line_items[index].item_ledger_guid = item_ledger[0].guid;
        //line_items[index].line_item_guid = item_ledger[0].guid;

        // prediction status

        line_items[index].item_ledger_name_prediction_status = 0;
        line_items[index].item_ledger_id_prediction_status = 0;
        line_items[index].item_ledger_guid_prediction_status = 0;

        //prefill line items list according to selected and if one option is filter out then preselect it in item dropdown
        let item = this.state.line_items_list.filter(
          (item) => item.item_ledger_guid == item_ledger[0].guid
        );

        //console.log(item)
        if (item.length == 1 && !line_items[index].line_item_name) {
          //set other data for api
          line_items[index].line_item_name = item[0].name;
          line_items[index].line_item_id = item[0].id;

          //prediction status
          line_items[index].line_item_name_prediction_status = 0;
          line_items[index].line_item_id_prediction_status = 0;

          //prefill data
          line_items[index].item_data = item[0];
          line_items[index].rate = item[0].rate;

          //prediction status
          line_items[index].rate_prediction_status = 0;

          if (item[0].hsn_sac && !line_items[index].hsn_sac) {
            line_items[index].hsn_sac = item[0].hsn_sac;
            //prediction status
            line_items[index].hsn_sac_prediction_status = 0;
          }

          if (item[0].gst_rate && !line_items[index].gst_rate) {
            line_items[index].gst_rate = item[0].gst_rate;
            //prediction status
            line_items[index].gst_rate_prediction_status = 0;
          }

          if (item[0].per && !line_items[index].per) {
            line_items[index].per = item[0].per;
            //prediction status
            line_items[index].per_prediction_status = 0;
          }

          if (
            this.state.is_common_item_ledger == 0 &&
            this.props.type == 1 &&
            item[0].sales_ledger_guid
          ) {
            let ledger = this.props.ledger_data.ledger_list.filter(
              (item_ledger) => item_ledger.guid == item[0].sales_ledger_guid
            );

            if (
              ledger.length > 0 &&
              !line_items[index].common_item_ledger_guid
            ) {
              line_items[index].common_item_ledger_guid = ledger[0].guid;
              line_items[index].common_item_ledger_id = ledger[0].id;
              line_items[index].common_item_ledger_name = ledger[0].name;
              //prediction status
              line_items[index].common_item_ledger_guid_prediction_status = 0;
              line_items[index].common_item_ledger_id_prediction_status = 0;
              line_items[index].common_item_ledger_name_prediction_status = 0;
            }
          } else if (
            this.state.is_common_item_ledger == 0 &&
            this.props.type == 2 &&
            item[0].purchase_ledger_guid
          ) {
            let ledger = this.props.ledger_data.ledger_list.filter(
              (item_ledger) => item_ledger.guid == item[0].purchase_ledger_guid
            );

            if (
              ledger.length > 0 &&
              !line_items[index].common_item_ledger_guid
            ) {
              line_items[index].common_item_ledger_guid = ledger[0].guid;
              line_items[index].common_item_ledger_id = ledger[0].id;
              line_items[index].common_item_ledger_name = ledger[0].name;
              //prediction status
              line_items[index].common_item_ledger_guid_prediction_status = 0;
              line_items[index].common_item_ledger_id_prediction_status = 0;
              line_items[index].common_item_ledger_name_prediction_status = 0;
            }
          }
        } else {
          // if (!line_items[index].line_item_name) {
          // 	line_items[index].line_item_name = "";
          // 	line_items[index].line_item_id = "";
          // }
        }

        //prefill data only if values are not filled
        if (item_ledger[0].hsn_sac && !line_items[index].hsn_sac) {
          line_items[index].hsn_sac = item_ledger[0].hsn_sac;
          //prediction status
          line_items[index].hsn_sac_prediction_status = 0;
        }

        if (item_ledger[0].gst_rate && !line_items[index].gst_rate) {
          line_items[index].gst_rate = item_ledger[0].gst_rate;
          //prediction status
          line_items[index].gst_rate_prediction_status = 0;
        }

        if (item_ledger[0].per && !line_items[index].per) {
          line_items[index].per = item_ledger[0].per;
          //prediction status
          line_items[index].per_prediction_status = 0;
        }
      }
    } else if (name == "line_item_id") {
      line_items[index][name] = e.value;
      //prediction status
      line_items[index].line_item_id_prediction_status = 0;

      let item = this.state.line_items_list.filter(
        (item) => item.id == e.value
      );
      //console.log(item, this.state.line_items_list,)
      if (item.length > 0) {
        //set other data for api
        line_items[index].line_item_name = item[0].name;
        line_items[index].item_data = item[0];
        //prediction status
        line_items[index].line_item_name_prediction_status = 0;

        //prefill data
        if (item[0].hsn_sac && !line_items[index].hsn_sac) {
          line_items[index].hsn_sac = item[0].hsn_sac;
          //prediction status
          line_items[index].hsn_sac_prediction_status = 0;
        }
        if (item[0].gst_rate && !line_items[index].gst_rate) {
          line_items[index].gst_rate = item[0].gst_rate;
          //prediction status
          line_items[index].gst_rate_prediction_status = 0;
        }
        if (item[0].per && !line_items[index].per) {
          line_items[index].per = item[0].per;
          //prediction status
          line_items[index].per_prediction_status = 0;
        }
        if (item[0].rate && !line_items[index].rate) {
          line_items[index].rate = item[0].rate;
          //prediction status
          line_items[index].rate_prediction_status = 0;
        }
        if (
          this.state.is_common_item_ledger == 0 &&
          this.props.type == 1 &&
          item[0].sales_ledger_guid
        ) {
          let ledger = this.props.ledger_data.ledger_list.filter(
            (item_ledger) => item_ledger.guid == item[0].sales_ledger_guid
          );

          if (ledger.length > 0 && !line_items[index].common_item_ledger_guid) {
            line_items[index].common_item_ledger_guid = ledger[0].guid;
            line_items[index].common_item_ledger_id = ledger[0].id;
            line_items[index].common_item_ledger_name = ledger[0].name;
            //prediction status
            line_items[index].common_item_ledger_guid_prediction_status = 0;
            line_items[index].common_item_ledger_id_prediction_status = 0;
            line_items[index].common_item_ledger_name_prediction_status = 0;
          }
        } else if (
          this.state.is_common_item_ledger == 0 &&
          this.props.type == 2 &&
          item[0].purchase_ledger_guid
        ) {
          let ledger = this.props.ledger_data.ledger_list.filter(
            (item_ledger) => item_ledger.guid == item[0].purchase_ledger_guid
          );

          if (ledger.length > 0 && !line_items[index].common_item_ledger_guid) {
            line_items[index].common_item_ledger_guid = ledger[0].guid;
            line_items[index].common_item_ledger_id = ledger[0].id;
            line_items[index].common_item_ledger_name = ledger[0].name;
            //prediction status
            line_items[index].common_item_ledger_guid_prediction_status = 0;
            line_items[index].common_item_ledger_id_prediction_status = 0;
            line_items[index].common_item_ledger_name_prediction_status = 0;
          }
        }

        let item_ledger = this.state.item_ledger_list.filter(
          (item_ledger) => item_ledger.guid == item[0].item_ledger_guid
        );
        //console.log(item_ledger)
        if (item_ledger.length == 1 && !line_items[index].item_ledger_guid) {
          let line_items = [...this.state.line_items];
          line_items[index].item_ledger_name = item_ledger[0].name;
          line_items[index].item_ledger_id = item_ledger[0].id;
          line_items[index].item_ledger_guid = item_ledger[0].guid;
          // prediction status

          line_items[index].item_ledger_name_prediction_status = 0;
          line_items[index].item_ledger_id_prediction_status = 0;
          line_items[index].item_ledger_guid_prediction_status = 0;

          //prefill data
          if (item_ledger[0].hsn_sac && !line_items[index].hsn_sac) {
            line_items[index].hsn_sac = item_ledger[0].hsn_sac;
            //prediction status
            line_items[index].hsn_sac_prediction_status = 0;
          }
          if (item_ledger[0].gst_rate && !line_items[index].gst_rate) {
            line_items[index].gst_rate = item_ledger[0].gst_rate;
            //prediction status
            line_items[index].gst_rate_prediction_status = 0;
          }
          if (item_ledger[0].per && !line_items[index].per) {
            line_items[index].per = item_ledger[0].per;
            //prediction status
            line_items[index].per_prediction_status = 0;
          }
        }

        //console.log(line_items)
      }
    } else if (name == "common_item_ledger_guid") {
      let item_ledger = this.props.ledger_data.ledger_list.filter(
        (item_ledger) => item_ledger.guid == e.value
      );

      if (item_ledger.length > 0) {
        line_items[index].common_item_ledger_guid = item_ledger[0].guid;
        line_items[index].common_item_ledger_id = item_ledger[0].id;
        line_items[index].common_item_ledger_name = item_ledger[0].name;
        //prediction status
        line_items[index].common_item_ledger_guid_prediction_status = 0;
        line_items[index].common_item_ledger_id_prediction_status = 0;
        line_items[index].common_item_ledger_name_prediction_status = 0;
      }
    } else {
      line_items[index][name] = e.target.value;
      if (name == "hsn_sac") {
        //prediction status
        line_items[index].hsn_sac_prediction_status = 0;
      }
      if (name == "quantity") {
        //prediction status
        line_items[index].quantity_prediction_status = 0;
        line_items[index].amount_prediction_status = 0;
      }
      if (name == "per") {
        //prediction status
        line_items[index].per_prediction_status = 0;
      }
    }
    this.setState({ line_items, is_dirty: true }, () => {
      this.calculateLineItemAmount(index);
    });
  };
  handleVoucherChange = (e) =>{
    if(e.target.name == 'voucher_type'){
      if(e.target.value !== ""){
        let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.guid == e.target.value)
        console.log("voucher ",voucher_details)
        this.setState({voucher_type_guid:voucher_details[0].guid,parent_voucher_type_id:voucher_details[0].parent_voucher_type_id,
              voucher_type_id:voucher_details[0].id,voucher_type_name:voucher_details[0].name
        })
    }
  }}
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    console.log("value override", value, name);
    this.setState({ is_gst_override: parseInt(value), is_dirty: true });
    if (name == "is_gst_override" && value) {
      this.setState({ disable_igst: false });
    }
    if (name == "is_gst_override" && value == 0) {
      let customer_gst = this.props.gst_no
        ? this.props.gst_no.slice(0, 2)
        : null || null;
      let entity_gst = !!this.props.entity_gst_no
        ? this.props.entity_gst_no.slice(0, 2)
        : null;
      console.log(customer_gst, entity_gst);
      if (customer_gst == entity_gst && customer_gst && entity_gst) {
        this.setState(
          { is_igst: false, igst_amount: "", disable_igst: true },
          () => {
            this.calculateTotalGst();
          }
        );
      } else if (!customer_gst || !entity_gst) {
        this.setState(
          {
            is_igst: true,
            sgst_amount: "",
            cgst_amount: "",
            disable_igst: false,
          },
          () => {
            this.calculateTotalGst();
          }
        );
      } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
        this.setState(
          {
            is_igst: true,
            disable_igst: true,
            sgst_amount: "",
            cgst_amount: "",
          },
          () => {
            this.calculateTotalGst();
          }
        );
      } else {
        this.setState(
          { is_igst: false, igst_amount: "", disable_igst: false },
          () => {
            this.calculateTotalGst();
          }
        );
      }
    }
  };
  addNewLineItem = () => {
    let line_items = [...this.state.line_items];

    line_items.push({
      // "id": 0,
      // "line_item_id": "",
      // "line_item_name": "",
      // "hsn_sac": "",
      // "rate": "",
      // "per": "",
      // "quantity": "",
      // "gst_rate": "",
      // "discount_rate": "",
      // "amount": "",
      // "item_ledger_guid": "",
      // "item_ledger_id": "",
      // "item_ledger_name": "",
      // "common_item_ledger_guid": "",
      // "common_item_ledger_id": "",
      // "common_item_ledger_name": ""
      id: 0,
      line_item_id: "",
      line_item_id_prediction_status: 0,
      line_item_name: "",
      line_item_name_prediction_status: 0,
      hsn_sac: "",
      hsn_sac_prediction_status: 0,
      rate: "",
      rate_prediction_status: 0,
      per: "",
      per_prediction_status: 0,
      quantity: "",
      quantity_prediction_status: 0,
      gst_rate: "",
      gst_rate_prediction_status: 0,
      discount_rate: "",
      discount_rate_prediction_status: 0,
      amount: "",
      amount_prediction_status: 0,
      item_ledger_guid: "",
      item_ledger_guid_prediction_status: 0,
      item_ledger_id: "",
      item_ledger_id_prediction_status: 0,
      item_ledger_name: "",
      item_ledger_name_prediction_status: 0,
      common_item_ledger_guid: "",
      common_item_ledger_guid_prediction_status: 0,
      common_item_ledger_id: "",
      common_item_ledger_id_prediction_status: 0,
      common_item_ledger_name: "",
      common_item_ledger_name_prediction_status: 0,
    });
    //console.log(line_items)
    this.setState({ line_items });
  };

  addNewAdditionalLedger = () => {
    let ledgers = [...this.state.ledgers];
    ledgers.push({
      id: 0,
      amount: "",
      guid: "",
      ledger_id: "",
      name: "",
      rate: "",
    });
    this.setState({ ledgers });
  };

  //remove additional ledger row if more than one rows are present and clear row if only one row present
  removeAdditionalLedger = (index, id) => {
    let remove_ledgers = this.state.remove_ledgers;
    if (id) {
      remove_ledgers.push(id);
    }
    if (index == 0 && Object.keys(this.state.ledgers).length == 1) {
      this.setState({
        ledgers: [
          {
            id: 0,
            amount: "",
            guid: "",
            ledger_id: "",
            name: "",
            rate: "",
          },
        ],
        remove_ledgers,
        is_dirty: true,
      });
    } else {
      let ledgers = this.state.ledgers;
      ledgers.splice(index, 1);
      //console.log(ledgers)
      this.setState({ ledgers, remove_ledgers, is_dirty: true });
    }
  };

  //calculate line item wise total amt
  calculateLineItemAmount = (index) => {
    let amt =
      parseFloat(
        (this.state.line_items[index].quantity || 0) *
        (this.state.line_items[index].rate || 0)
      ) *
      (1 - (this.state.line_items[index].discount_rate || 0) / 100);

    let line_items = [...this.state.line_items];
    line_items[index].amount = parseFloat(amt).toFixed(2);

    this.setState({ line_items }, () => {
      this.calculateAllLedgerAmount();
    });
  };

  calculateAllLedgerAmount = () => {
    let total_of_line_items = this.calculateTotalLineItemAmt() || 0;
    let ledgers = [...this.state.ledgers];

    ledgers.forEach((led, i) => {
      let rate = led.rate || 0;
      let amt = parseFloat(total_of_line_items * (rate / 100));
      ledgers[i].amount = amt.toFixed(2);
    });

    this.setState({ ledgers });
  };

  //calculate additional ledger wise total amt
  calculateLedgerAmount = (index) => {
    ///if(!!this.state.ledgers[index]){
    let total_of_line_items = this.calculateTotalLineItemAmt() || 0;

    let rate = this.state.ledgers[index].rate || 0;

    let amt = parseFloat(total_of_line_items * (rate / 100));

    let ledgers = [...this.state.ledgers];
    ledgers[index].amount = parseFloat(amt.toFixed(2));

    this.setState({ ledgers });
    //}
  };

  //calculate total line items combined gst amt
  calculateTotalLineItemGST = () => {
    let line_items = [...this.state.line_items];
    let total_gst_amt = 0;

    line_items.forEach((item, index) => {
      if (item.gst_rate && item.amount)
        total_gst_amt +=
          (parseFloat(item.gst_rate) / 100) * parseFloat(item.amount);
    });
    //this.setState({line_item_gst_total: total_gst_amt.toFixed(2)})
    return total_gst_amt.toFixed(2);
  };

  //calculate total line items combined discounted amt
  calculateTotalLineItemDisc = () => {
    let line_items = [...this.state.line_items];
    let total_disc_amt = 0;

    line_items.forEach((item, index) => {
      if (item.rate && item.discount_rate && item.quantity)
        total_disc_amt +=
          item.quantity *
          parseFloat(item.rate) *
          (parseFloat(item.discount_rate) / 100);
    });
    //this.setState({line_item_discount_total: total_disc_amt.toFixed(2)})
    return total_disc_amt.toFixed(2);
  };

  //calculate total line items combined total amt
  calculateTotalLineItemAmt = () => {
    let line_items = [...this.state.line_items];
    let amt = 0;

    line_items.forEach((item, index) => {
      if (item.amount) amt += parseFloat(item.amount);
    });

    //this.setState({line_item_total: amt.toFixed(2)})
    return amt.toFixed(2);
  };

  //calculate total additioanl ledger combined total amt
  calculateTotalLedgersAmt = () => {
    let total_line_item_amt = parseFloat(this.calculateTotalLineItemAmt());

    let ledgers = [...this.state.ledgers];
    let amt = total_line_item_amt || 0;

    ledgers.forEach((ledger, index) => {
      if (ledger.amount) amt += parseFloat(ledger.amount);
    });

    return amt.toFixed(2);
  };

  clearLedgerData = () => {
    console.log("clearledger");
    // this.setState({
    //   voucher_creation_date: "",
    //   party_ledger_guid: "",
    //   party_ledger_id: "",
    //   party_ledger_name: "",
    //   common_item_ledger_guid: "",
    //   common_item_ledger_id: "",
    //   common_item_ledger_name: "",
    //   accounting_narration: "",
    //   party_ledger_guid_prediction_status: 0,
    //   party_ledger_id_prediction_status: 0,
    //   party_ledger_name_prediction_status: 0,
    //   common_item_ledger_guid_prediction_status: 0,
    //   common_item_ledger_id_prediction_status: 0,
    //   common_item_ledger_name_prediction_status: 0,
    //   voucher_creation_date_prediction_status: 0,
    //   ledgers: [
    //     {
    //       amount: "",
    //       rate: "",
    //       guid: "",
    //       ledger_id: "",
    //       name: "",
    //     },
    //   ],
    // });

    // let line_items = [...this.state.line_items];

    // line_items.forEach((item, index) => {
    //   line_items[index].party_ledger_guid = "";
    //   line_items[index].party_ledger_id = "";
    //   line_items[index].party_ledger_name = "";
    //   line_items[index].common_item_ledger_guid = "";
    //   line_items[index].common_item_ledger_id = "";
    //   line_items[index].common_item_ledger_name = "";
    // });

    // this.setState({ line_items });
  };

  checkForErrors = () => {
    this.setState({ errors: {}, line_item_errors: [], ledger_errors: [] });
    let errors = {};
    let line_item_errors = [];
    let ledger_errors = [];

    //SOC::mandatory fields for accounting status
    if (
      this.props.accounting_status != 3 &&
      this.state.inventory_voucher_status &&
      this.props.SNAPSHOT_LEDGER_ENTRIES_CODE
    ) {
      //console.log(this.state.voucher_creation_date)
      if (!this.state.voucher_creation_date) {
        errors.voucher_creation_date = "Please select Voucher Date";
      }
      if (!this.state.party_ledger_guid) {
        errors.party_ledger_guid = "Please select Party Ledger";
      }
      if (
        !this.state.common_item_ledger_guid &&
        this.state.is_common_item_ledger == 1
      ) {
        if (this.props.type == 1) {
          errors.common_item_ledger_guid = "Please select Sales Ledger";
        } else {
          errors.common_item_ledger_guid = "Please select Purchase Ledger";
        }
      }

      let line_item_err = 0;
      this.state.line_items.forEach((item, index) => {
        if (
          !item.line_item_name &&
          !item.hsn_sac &&
          !item.rate &&
          !item.per &&
          !item.quantity &&
          !item.gst_rate &&
          !item.discount_rate &&
          !item.item_ledger_guid &&
          !item.common_item_ledger_guid
        ) {
          line_item_err += 1;
        }
      });

      if (this.state.line_items.length == line_item_err) {
        errors.line_item_err = true;
      }
    }
    //EOC::mandatory fields for accounting status

    //SOC::mandatory fields for entry status
    if (this.props.entry_status != 1 || this.props.accounting_status != 3) {
      this.state.line_items.forEach((item, index) => {
        if (
          item.line_item_name ||
          item.hsn_sac ||
          item.rate ||
          item.per ||
          item.quantity ||
          item.gst_rate ||
          item.discount_rate ||
          item.item_ledger_guid ||
          item.common_item_ledger_guid
        ) {
          let line_item_name_error,
            quantity_error,
            rate_error,
            amount_error,
            item_ledger_guid_error,
            common_item_ledger_guid_error,
            per_error = false;

          if (!item.line_item_name) line_item_name_error = true;

          if (!item.quantity || parseFloat(item.quantity) <= 0)
            quantity_error = true;

          if (!item.rate) rate_error = true;

          if (!item.amount) amount_error = true;

          if (
            this.state.inventory_voucher_status &&
            this.props.SNAPSHOT_LEDGER_ENTRIES_CODE &&
            this.props.accounting_status != 3
          ) {
            if (!item.item_ledger_guid) {
              item_ledger_guid_error = true;
            }
            if (
              !item.common_item_ledger_guid &&
              !this.state.is_common_item_ledger
            ) {
              common_item_ledger_guid_error = true;
            }
            if (!item.per) per_error = true;
          }

          if (
            line_item_name_error ||
            quantity_error ||
            rate_error ||
            amount_error ||
            item_ledger_guid_error ||
            common_item_ledger_guid_error ||
            per_error
          ) {
            line_item_errors[index] = {};

            if (line_item_name_error)
              line_item_errors[index].line_item_name = true;

            if (quantity_error) line_item_errors[index].quantity = true;

            if (rate_error) line_item_errors[index].rate = true;

            if (amount_error) line_item_errors[index].amount = true;

            if (item_ledger_guid_error)
              line_item_errors[index].item_ledger_guid = true;

            if (common_item_ledger_guid_error)
              line_item_errors[index].common_item_ledger_guid = true;

            if (per_error) line_item_errors[index].per = true;
          }
        }
      });

      this.state.ledgers.forEach((led, index) => {
        console.log(led.amount, led.rate, led.guid);
        if (parseFloat(led.amount) > 0 || led.rate || led.guid) {
          let rate_error,
            amount_error,
            guid_error = false;

          if (
            this.state.inventory_voucher_status &&
            this.props.SNAPSHOT_LEDGER_ENTRIES_CODE &&
            this.props.accounting_status != 3
          ) {
            if (!led.amount) {
              amount_error = true;
            }
            if (!led.guid) {
              guid_error = true;
            }

            if (amount_error || guid_error) {
              ledger_errors[index] = {};

              if (amount_error) ledger_errors[index].amount = true;

              if (guid_error) ledger_errors[index].guid = true;
            }
          }
        }
      });

      if (this.state.is_igst) {
        if (Number.isNaN(this.state.igst_amount)) {
          errors.igst_amount = "Please fill IGST";
        }
      } else {
        if (
          this.state.cgst_amount &&
          !this.state.sgst_amount &&
          this.state.entry_status != 1
        ) {
          errors.sgst_amount = "Please fill SGST";
        }

        if (
          !this.state.cgst_amount &&
          this.state.sgst_amount &&
          this.state.entry_status != 1
        ) {
          errors.cgst_amount = "Please fill CGST";
        }
      }

      if (
        (this.state.cgst_amount || this.state.sgst_amount) &&
        this.state.igst_amount
      ) {
        errors.igst_amount = "Please fill either IGST or CGST & SGST";
      }
      //console.log(this.state.tds_status, this.state.tds_percentage, this.state.tds_amount)
      //this.state.tds_status && 
    
    }
    if(this.state.tds_details.length > 0){
      const totalBaseAmt = this.state.tds_details.reduce((accumulator, item) => {
        return accumulator + (item.base_amount || 0);
      }, 0);

      console.log("::total",typeof totalBaseAmt,typeof this.state.gst_total_amount)
      if(parseFloat(this.state.gst_total_amount) > parseFloat(totalBaseAmt) && parseFloat(totalBaseAmt) != 0){
        console.log(":::",'here')
        errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
      }
      // if(this.state.gst_total_amount > totalBaseAmt && totalBaseAmt != 0){
      //   console.log(":::",'here')
      //   errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
      // }
    }
    else{
      if(this.state.gst_total_amount > (this.state.total_amount - this.state.gst_total_amount)){
        errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
      }
    }

    if (!this.state.is_igst) {
      if (
        this.state.cgst_amount &&
        this.state.sgst_amount &&
        this.state.cgst_amount != this.state.sgst_amount
      ) {
        errors.same_amount = "CGST & SGST amounts must match";
      }
    }

    if (line_item_errors.length > 0) {
      errors.line_item_errors = line_item_errors;
    }

    console.log("tds details percentage errror ----------------------",this.state.tds_details);
    if (this.state.tds_details.length > 1) {
      var tdsError = this.state.tds_details.map((item, index) => {
        let err = {};
        console.log("tds item while errro ----------", item);
        if (!item.tds_percentage) {
          err.tds_percentage = 'TDS % is required';
        }
        if (!item.tds_amount) {
          err.tds_amount = 'TDS amount is required';
        }

        if (!item.base_amount) {
          err.base_amount = 'TDS base amount is required';
        }

        if(item.base_amount && item.base_amount >  (this.state.total_amount - this.state.gst_total_amount).toFixed(2))
          err.base_amount = 'TDS base amount should not be greater than Base Amount';
    
        // if(index == 0 && item.tds_amount && item.tds_amount > (this.state.total_amount - this.state.gst_total_amount)){
        //   err.tds_amount = 'TDS amount should not be higher than Base Amount';
        // }
        return err;
      });

      const totalBaseAmt = this.state.tds_details.reduce((accumulator, item) => {
        return accumulator + (item.base_amount || 0);
      }, 0);
      
      console.log("total base amount ---" ,(this.state.total_amount - this.state.gst_total_amount).toFixed(2), totalBaseAmt ,  (this.state.total_amount - this.state.gst_total_amount));
      if(totalBaseAmt > (this.state.total_amount - this.state.gst_total_amount))
        errors.total_base_amount = 'Sum of TDS Base Amount should not be greater than Base Amount (Total Amount - GST Total)';
  console.log("tds array length > 1",totalBaseAmt , this.calculateTotalLedgersAmt())
      if(parseFloat(totalBaseAmt) > parseFloat(this.calculateTotalLedgersAmt())){
        errors.total_base_amount = 'Sum of TDS Base Amount should not be greater than line item total';
      }
      let istdsDetailsErr = tdsError.every((item) =>
        Object.keys(item).length === 0
      );
      
      if (!istdsDetailsErr) {
        errors.tds_details = tdsError;
      }
      console.log("tds detail arrow -----------------", errors);
    }
    else{
      var tdsError = this.state.tds_details.map((item, index) => {
        let err = {};
        console.log("tds item while errro ----------",(this.state.total_amount - this.state.gst_total_amount).toFixed(2), item);
        if (!!item.tds_percentage) {
          if (!item.tds_amount) {
            err.tds_amount = 'TDS amount is required';
          }
  
          if (!item.base_amount) {
            err.base_amount = 'TDS base amount is required';
          }

          if(item.base_amount && item.base_amount > (this.state.total_amount - this.state.gst_total_amount).toFixed(2))
            err.base_amount = 'TDS base amount should not be greater than Base Amount';
        }
        console.log("tds array length  1",typeof item.base_amount , typeof this.calculateTotalLedgersAmt())
        if(parseFloat(item.base_amount) > parseFloat(this.calculateTotalLedgersAmt())){
          err.base_amount = 'Sum of TDS Base Amount should not be greater than line item total';
        }  
        return err;
      });
      let istdsDetailsErr = tdsError.every((item) =>
        Object.keys(item).length === 0
      );
      
      if (!istdsDetailsErr) {
        errors.tds_details = tdsError;
      }
      console.log("tds detail arrow -----------------", errors);
    }

    // if(ledger_errors.length > 0) {
    // 	errors.ledger_errors = ledger_errors
    // }

    let final_errors = {};

    final_errors.errors = errors;
    final_errors.line_item_errors = line_item_errors;
    final_errors.ledger_errors = ledger_errors;

    return final_errors;
  };

  downloadFile = (file) => {
    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", "Snapshot-#S" + file.name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  newLineItemsPrediction = async () => {
    await this.props.newLineitemPrediction();
    let inventory_details = _.cloneDeep(this.props.inventory_details);
    console.log("inside pridei", inventory_details);

    // this.setState(
    //   {
    //     line_item_total: inventory_details.line_item_total,
    //     line_item_gst_total: inventory_details.line_item_gst_total,
    //     line_item_discount_total: inventory_details.line_item_discount_total,
    //     inventory_voucher_status: inventory_details.inventory_voucher_status,
    //     is_common_item_ledger:
    //       inventory_details.is_common_item_ledger === "" ||
    //         (inventory_details.is_common_item_ledger != 0 &&
    //           inventory_details.is_common_item_ledger != 1)
    //         ? this.props.is_common_item_ledger
    //         : inventory_details.is_common_item_ledger,
    //     accounting_narration:
    //       inventory_details.accounting_narration === "" ||
    //         !inventory_details.accounting_narration
    //         ? this.props.narration
    //         : inventory_details.accounting_narration,
    //     sync_error: inventory_details.sync_error,
    //     sync_status: inventory_details.sync_status || 0,
    //     sync_datetime:
    //       inventory_details.sync_datetime || changeDateTimeFormat(new Date()),
    //     voucher_creation_date: this.props.inventory_voucher_creation_date
    //       ? this.props.inventory_voucher_creation_date
    //       : this.props.date,
    //     //voucher_creation_date : this.props.date,
    //     voucher_creation_date_prediction_status:
    //       this.props.inventory_voucher_creation_date_prediction || 0,
    //     voucher_total_amount: inventory_details.voucher_total_amount,
    //     voucher_type: inventory_details.voucher_type,
    //     party_ledger_guid: this.props.inventory_party_ledger_guid,
    //     party_ledger_guid_prediction_status:
    //       this.props.inventory_party_ledger_guid_prediction || 0,
    //     party_ledger_id: this.props.inventory_party_ledger_id,
    //     party_ledger_id_prediction_status:
    //       this.props.inventory_party_ledger_id_prediction || 0,
    //     party_ledger_name: this.props.inventory_party_ledger_name,
    //     party_ledger_name_prediction_status:
    //       this.props.inventory_party_ledger_name_prediction || 0,
    //     common_item_ledger_guid: this.props.inventory_common_item_ledger_guid,
    //     common_item_ledger_guid_prediction_status:
    //       this.props.inventory_common_item_ledger_guid_prediction || 0,
    //     common_item_ledger_id: this.props.inventory_common_item_ledger_id,
    //     common_item_ledger_id_prediction_status:
    //       this.props.inventory_common_item_ledger_id_prediction || 0,
    //     common_item_ledger_name: this.props.inventory_common_item_ledger_name,
    //     common_item_ledger_name_prediction_status:
    //       this.props.inventory_common_item_ledger_name_prediction || 0,
    //     line_items:
    //       this.props.combined_inventory_array.length == 0
    //         ? [
    //           {
    //             // "line_item_id": "",
    //             // "line_item_name": "",
    //             // "hsn_sac": "",
    //             // "rate": "",
    //             // "per": "",
    //             // "quantity": "",
    //             // "gst_rate": "",
    //             // "discount_rate": "",
    //             // "amount": "",
    //             // "item_ledger_guid": "",
    //             // "item_ledger_id": null,
    //             // "item_ledger_name": "",
    //             // "common_item_ledger_guid": "",
    //             // "common_item_ledger_id": "",
    //             // "common_item_ledger_name": "",
    //             // "line_item_update": 0
    //             line_item_id: "",
    //             line_item_id_prediction_status: 0,
    //             line_item_name: "",
    //             line_item_name_prediction_status: 0,
    //             hsn_sac: "",
    //             hsn_sac_prediction_status: 0,
    //             rate: "",
    //             rate_prediction_status: 0,
    //             per: "",
    //             per_prediction_status: 0,
    //             quantity: "",
    //             quantity_prediction_status: 0,
    //             gst_rate: "",
    //             gst_rate_prediction_status: 0,
    //             discount_rate: "",
    //             discount_rate_prediction_status: 0,
    //             amount: "",
    //             amount_prediction_status: 0,
    //             item_ledger_guid: "",
    //             item_ledger_guid_prediction_status: 0,
    //             item_ledger_id: null,
    //             item_ledger_id_prediction_status: 0,
    //             item_ledger_name: "",
    //             item_ledger_name_prediction_status: 0,
    //             common_item_ledger_guid: "",
    //             common_item_ledger_guid_prediction_status: 0,
    //             common_item_ledger_id: "",
    //             common_item_ledger_id_prediction_status: 0,
    //             common_item_ledger_name: "",
    //             common_item_ledger_name_prediction_status: 0,
    //             line_item_update: 0,
    //           },
    //         ]
    //         : this.props.combined_inventory_array,
    //     ledgers:
    //       inventory_details.ledgers.length == 0
    //         ? [
    //           {
    //             amount: "",
    //             rate: "",
    //             guid: "",
    //             ledger_id: "",
    //             name: "",
    //           },
    //         ]
    //         : inventory_details.ledgers,
    //     tds_status: this.props.tds_status,
    //     tds_details: this.props.tds_details,
    //     tds_amount: this.props.tds_amount,
    //     total_amount: this.props.total_amount,
    //     //accounting_narration: this.props.narration,
    //     // is_igst: this.props.igst_amount ? true : false,
    //     sgst_amount_prediction_status: this.props.sgst_amount_prediction_status,
    //     cgst_amount_prediction_status: this.props.cgst_amount_prediction_status,
    //     igst_amount_prediction_status: this.props.igst_amount_prediction_status,
    //     tds_percentage_prediction_status:
    //       this.props.tds_percentage_prediction_status,
    //     sgst_amount: this.props.sgst_amount,
    //     cgst_amount: this.props.cgst_amount,
    //     igst_amount: this.props.igst_amount,
    //     gst_total_amount: this.props.gst_total_amount,
    //     is_gst_claim_eligible: this.props.is_gst_claim_eligible,
    //     remove_line_items: [],
    //     remove_ledgers: [],
    //     is_dirty: true,
    //     dirty_popup_visible: false,
    //     line_items_list: this.props.line_items_list,
    //     errors: this.props.errors,
    //     line_item_errors: this.props.line_item_errors,
    //     ledger_errors: this.props.ledger_errors,
    //     disable_igst: this.props.disable_igst,
    //     is_igst: this.props.is_igst == undefined ? true : this.props.is_igst,
    //     gst_summary: this.props.gst_summary,
    //     hsn_sac_summary: this.props.hsn_sac_summary,
    //     show_summary_section: false,
    //     is_gst_override: this.props.is_gst_override,
    //   },
    //   () => {
    //     console.log(this.state.is_common_item_ledger);
    //     if (
    //       Object.keys(this.props.merchant_option).length != 0 &&
    //       this.props.accounting_status == 3
    //     ) {
    //       //alert('ddddd')
    //       let ledger = this.props.ledger_data.ledger_list.filter(
    //         (ledger) =>
    //           ledger.guid ==
    //           (this.props.type == 1
    //             ? this.props.merchant_option.sales_ledger_guid
    //             : this.props.merchant_option.purchase_ledger_guid)
    //       );
    //       //console.log(ledger, this.props.type, this.props.merchant_option.sales_ledger_guid, this.props.merchant_option.purchase_ledger_guid, this.props.merchant_option, this.state.common_item_ledger_guid, this.state.party_ledger_guid);
    //       if (
    //         ledger.length > 0 &&
    //         this.state.is_common_item_ledger == 1 &&
    //         !this.state.common_item_ledger_guid
    //       ) {
    //         this.setState({
    //           common_item_ledger_guid: ledger[0].guid,
    //           common_item_ledger_id: ledger[0].id,
    //           common_item_ledger_name: ledger[0].name,
    //         });
    //       }

    //       let party_ledger = this.props.ledger_data.ledger_list.filter(
    //         (ledger) =>
    //           ledger.guid == this.props.merchant_option.party_ledger_guid
    //       );
    //       //console.log(party_ledger);
    //       if (party_ledger.length > 0 && !this.state.party_ledger_guid) {
    //         this.setState({
    //           party_ledger_guid: party_ledger[0].guid,
    //           party_ledger_id: party_ledger[0].id,
    //           party_ledger_name: party_ledger[0].name,
    //         });
    //       }
    //     }
    //   }
    // );
  this.setState({
    
  })

    this.setState({
      show_edit_popup: true,
      inventory_voucher_status: this.props.SNAPSHOT_LEDGER_ENTRIES_CODE
        ? true
        : inventory_details.inventory_voucher_status,
    });
  };

  openInventoryEditView = () => {
    console.log("open inventory");
    this.props.closePreviewPopup();
    let inventory_details = _.cloneDeep(this.props.inventory_details);
    console.log(inventory_details);

    console.log(
      "partyyyyyy",
      inventory_details.party_ledger_guid
        ? inventory_details.party_ledger_guid
        : this.props.inventory_party_ledger_guid
          ? this.props.inventory_party_ledger_guid
          : inventory_details.party_ledger_guid
    );
    //console.log(inventory_details.accounting_narration || this.props.accounting_narration)
    console.log("date", inventory_details.voucher_creation_date);
    this.setState(
      {
        line_item_total: inventory_details.line_item_total,
        line_item_gst_total: inventory_details.line_item_gst_total,
        line_item_discount_total: inventory_details.line_item_discount_total,
        inventory_voucher_status: inventory_details.inventory_voucher_status,
        is_common_item_ledger:
          inventory_details.is_common_item_ledger === "" ||
            (inventory_details.is_common_item_ledger != 0 &&
              inventory_details.is_common_item_ledger != 1)
            ? this.props.is_common_item_ledger
            : inventory_details.is_common_item_ledger,
        accounting_narration:
          inventory_details.accounting_narration === "" ||
            !inventory_details.accounting_narration
            ? this.props.narration
            : inventory_details.accounting_narration,
        sync_error: inventory_details.sync_error,
        sync_status: inventory_details.sync_status || 0,
        sync_datetime:
          inventory_details.sync_datetime || changeDateTimeFormat(new Date()),
        voucher_creation_date: inventory_details.voucher_creation_date
          ? new Date(inventory_details.voucher_creation_date)
          : this.props.inventory_voucher_creation_date
            ? this.props.inventory_voucher_creation_date
            : this.props.date,
        voucher_creation_date_prediction_status:
          inventory_details.voucher_creation_date_prediction_status
            ? inventory_details.voucher_creation_date_prediction_status
            : this.props.inventory_voucher_creation_date_prediction || 0,
        voucher_total_amount: inventory_details.voucher_total_amount,
        voucher_type: inventory_details.voucher_type,
        party_ledger_guid: inventory_details.party_ledger_guid
          ? inventory_details.party_ledger_guid
          : this.props.inventory_party_ledger_guid
            ? this.props.inventory_party_ledger_guid
            : inventory_details.party_ledger_guid,
        party_ledger_guid_prediction_status:
          inventory_details.party_ledger_guid_prediction_status
            ? inventory_details.party_ledger_guid_prediction_status
            : this.props.inventory_party_ledger_guid_prediction || 0,
        party_ledger_id: inventory_details.party_ledger_id
          ? inventory_details.party_ledger_id
          : this.props.inventory_party_ledger_id
            ? this.props.inventory_party_ledger_id
            : inventory_details.party_ledger_id,
        party_ledger_id_prediction_status:
          inventory_details.party_ledger_id_prediction_status
            ? inventory_details.party_ledger_id_prediction_status
            : this.props.inventory_party_ledger_id_prediction || 0,
        party_ledger_name: inventory_details.party_ledger_name
          ? inventory_details.party_ledger_name
          : this.props.inventory_party_ledger_name
            ? this.props.inventory_party_ledger_name
            : inventory_details.party_ledger_name,
        party_ledger_name_prediction_status:
          inventory_details.party_ledger_name_prediction_status
            ? inventory_details.party_ledger_name_prediction_status
            : this.props.inventory_party_ledger_name_prediction || 0,
        common_item_ledger_guid: inventory_details.common_item_ledger_guid
          ? inventory_details.common_item_ledger_guid
          : this.props.inventory_common_item_ledger_guid
            ? this.props.inventory_common_item_ledger_guid
            : inventory_details.common_item_ledger_guid,
        common_item_ledger_guid_prediction_status:
          inventory_details.common_item_ledger_guid_prediction_status
            ? inventory_details.common_item_ledger_guid_prediction_status
            : this.props.inventory_common_item_ledger_guid_prediction || 0,
        common_item_ledger_id: inventory_details.common_item_ledger_id
          ? inventory_details.common_item_ledger_id
          : this.props.inventory_common_item_ledger_id
            ? this.props.inventory_common_item_ledger_id
            : inventory_details.common_item_ledger_id,
        common_item_ledger_id_prediction_status:
          inventory_details.common_item_ledger_id_prediction_status
            ? inventory_details.common_item_ledger_id_prediction_status
            : this.props.inventory_common_item_ledger_id_prediction || 0,
        common_item_ledger_name: inventory_details.common_item_ledger_name
          ? inventory_details.common_item_ledger_name
          : this.props.inventory_common_item_ledger_name
            ? this.props.inventory_common_item_ledger_name
            : inventory_details.common_item_ledger_name,
        common_item_ledger_name_prediction_status:
          inventory_details.common_item_ledger_name_prediction_status
            ? inventory_details.common_item_ledger_name_prediction_status
            : this.props.inventory_common_item_ledger_name_prediction || 0,
        line_items:
          inventory_details.line_items.length == 0
            ? this.props.combined_inventory_array.length == 0
              ? [
                {
                  // "line_item_id": "",
                  // "line_item_name": "",
                  // "hsn_sac": "",
                  // "rate": "",
                  // "per": "",
                  // "quantity": "",
                  // "gst_rate": "",
                  // "discount_rate": "",
                  // "amount": "",
                  // "item_ledger_guid": "",
                  // "item_ledger_id": null,
                  // "item_ledger_name": "",
                  // "common_item_ledger_guid": "",
                  // "common_item_ledger_id": "",
                  // "common_item_ledger_name": "",
                  // "line_item_update": 0
                  line_item_id: "",
                  line_item_id_prediction_status: 0,
                  line_item_name: "",
                  line_item_name_prediction_status: 0,
                  hsn_sac: "",
                  hsn_sac_prediction_status: 0,
                  rate: "",
                  rate_prediction_status: 0,
                  per: "",
                  per_prediction_status: 0,
                  quantity: "",
                  quantity_prediction_status: 0,
                  gst_rate: "",
                  gst_rate_prediction_status: 0,
                  discount_rate: "",
                  discount_rate_prediction_status: 0,
                  amount: "",
                  amount_prediction_status: 0,
                  item_ledger_guid: "",
                  item_ledger_guid_prediction_status: 0,
                  item_ledger_id: null,
                  item_ledger_id_prediction_status: 0,
                  item_ledger_name: "",
                  item_ledger_name_prediction_status: 0,
                  common_item_ledger_guid: "",
                  common_item_ledger_guid_prediction_status: 0,
                  common_item_ledger_id: "",
                  common_item_ledger_id_prediction_status: 0,
                  common_item_ledger_name: "",
                  common_item_ledger_name_prediction_status: 0,
                  line_item_update: 0,
                },
              ]
              : this.props.combined_inventory_array
            : inventory_details.line_items,
        ledgers:
          inventory_details.ledgers.length == 0
            ? [
              {
                amount: "",
                rate: "",
                guid: "",
                ledger_id: "",
                name: "",
              },
            ]
            : inventory_details.ledgers,
        tds_status: this.props.tds_status,
        tds_details: this.props.tds_details,
        tds_amount: this.props.tds_amount,
        total_amount: this.props.total_amount,
        //accounting_narration: this.props.narration,
        // is_igst: this.props.igst_amount ? true : false,
        sgst_amount_prediction_status: this.props.sgst_amount_prediction_status,
        cgst_amount_prediction_status: this.props.cgst_amount_prediction_status,
        igst_amount_prediction_status: this.props.igst_amount_prediction_status,
        tds_percentage_prediction_status:
          this.props.tds_percentage_prediction_status,
        sgst_amount: this.props.sgst_amount,
        cgst_amount: this.props.cgst_amount,
        igst_amount: this.props.igst_amount,
        gst_total_amount: this.props.gst_total_amount,
        is_gst_claim_eligible: this.props.is_gst_claim_eligible,
        remove_line_items: [],
        remove_ledgers: [],
        is_dirty: true,
        dirty_popup_visible: false,
        line_items_list: this.props.line_items_list,
        errors: this.props.errors,
        line_item_errors: this.props.line_item_errors,
        ledger_errors: this.props.ledger_errors,
        disable_igst: this.props.disable_igst,
        is_igst: this.props.is_igst == undefined ? true : this.props.is_igst,
        gst_summary: this.props.gst_summary,
        hsn_sac_summary: this.props.hsn_sac_summary,
        show_summary_section: false,
        is_gst_override: this.props.is_gst_override,
        voucher_type_guid:this.props.voucher_type_guid ? this.props.parent_voucher_type_id == (this.props.type == 1 ? 5 : 6) ? this.props.voucher_type_guid : "" : inventory_details.parent_voucher_type_id == (this.props.type == 1 ? 5:6) ? inventory_details.voucher_type_guid : "",
        parent_voucher_type_id:this.props.parent_voucher_type_id ? this.props.parent_voucher_type_id == (this.props.type == 1 ? 5 : 6) ? this.props.parent_voucher_type_id : "" : inventory_details.parent_voucher_type_id == (this.props.type == 1 ? 5:6) ? inventory_details.parent_voucher_type_id : "",
        voucher_type_name:  this.props.voucher_type_name ? this.props.parent_voucher_type_id == (this.props.type == 1 ? 5 : 6) ?this.props.voucher_type_name : "" : inventory_details.parent_voucher_type_id == (this.props.type == 1 ? 5:6) ?inventory_details.voucher_type_name : "",
        voucher_type_id: this.props.voucher_type_id ? this.props.parent_voucher_type_id == (this.props.type == 1 ? 5 : 6) ?this.props.voucher_type_id : "" : inventory_details.parent_voucher_type_id == (this.props.type == 1 ? 5:6) ?inventory_details.voucher_type_id : "",
        check_sales_purchase:this.props.type == 1 ? 5 : 6,
      },
      () => {
        console.log(this.state.is_common_item_ledger,this.props.accounting_status);
        if (
          Object.keys(this.props.merchant_option).length != 0 &&
          this.props.accounting_status == 3 || this.props.accounting_status == 4
        ) {
          //alert('ddddd')
          let ledger = this.props.ledger_data.ledger_list.filter(
            (ledger) =>
              ledger.guid ==
              (this.props.type == 1
                ? this.props.merchant_option.sales_ledger_guid
                  ? this.props.merchant_option.sales_ledger_guid
                  : this.props.prediction_sales_ledger_guid
                : this.props.merchant_option.purchase_ledger_guid
                  ? this.props.merchant_option.purchase_ledger_guid
                  : this.props.prediction_purchase_ledger_guid)
          );
          //console.log(ledger, this.props.type, this.props.merchant_option.sales_ledger_guid, this.props.merchant_option.purchase_ledger_guid, this.props.merchant_option, this.state.common_item_ledger_guid, this.state.party_ledger_guid);
          if (
            ledger.length > 0 &&
            this.state.is_common_item_ledger == 1 &&
            !this.state.common_item_ledger_guid
          ) {
            this.setState({
              common_item_ledger_guid: ledger[0].guid,
              common_item_ledger_id: ledger[0].id,
              common_item_ledger_name: ledger[0].name,
            });
          }

          let party_ledger = this.props.ledger_data.ledger_list.filter(
            (ledger) =>
              ledger.guid ==
              (this.props.merchant_option.party_ledger_guid
                ? this.props.merchant_option.party_ledger_guid
                : this.props.prediction_party_ledger_guid)
          );
          //console.log(party_ledger);
          if (party_ledger.length > 0 && !this.state.party_ledger_guid) {
            this.setState({
              party_ledger_guid: party_ledger[0].guid,
              party_ledger_id: party_ledger[0].id,
              party_ledger_name: party_ledger[0].name,
            });
          }
          if(this.props.merchant_option.sales_voucher_type_guid || this.props.merchant_option.purchase_voucher_type_guid || this.props.prediction_voucher_type_guid){
              let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.guid == (this.props.type == 1 ? this.props.merchant_option.sales_voucher_type_guid ? this.props.merchant_option.sales_voucher_type_guid : this.props.prediction_voucher_type_guid : this.props.merchant_option.purchase_voucher_type_guid ? this.props.merchant_option.purchase_voucher_type_guid : this.props.prediction_voucher_type_guid));
              console.log("inside voucher ",voucher_details.length)
              if(voucher_details.length > 0 && !this.state.voucher_type_guid){
                console.log("here",voucher_details)
                
                  this.setState({
                    voucher_type_guid:voucher_details[0].guid,
                    voucher_type_id:voucher_details[0].id,
                    voucher_type_name:voucher_details[0].name,
                    parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
                  })
              }
          }
          
          if(this.props.type == 1 && (this.props.merchant_option.sales_voucher_type_guid == "" || this.props.merchant_option.sales_voucher_type_guid == null &&  this.props.prediction_voucher_type_guid == null) && !this.state.voucher_type_guid){
            let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.name == (this.props.type == 1 ? 'Sales' : 'Purchase') && voucher.is_master == 1)
              if(voucher_details.length > 0){
                this.setState({
                  voucher_type_guid:voucher_details[0].guid,
                  voucher_type_id:voucher_details[0].id,
                  voucher_type_name:voucher_details[0].name,
                  parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
                })
              }
          }
          if(this.props.type == 2 && (this.props.merchant_option.purchase_voucher_type_guid == "" || this.props.merchant_option.purchase_voucher_type_guid == null && this.props.prediction_voucher_type_guid == null) && !this.state.voucher_type_guid){
            let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.name == (this.props.type == 1 ? 'Sales' : 'Purchase') && voucher.is_master == 1)
              if(voucher_details.length > 0){
                console.log("here in json")
                this.setState({
                  voucher_type_guid:voucher_details[0].guid,
                  voucher_type_id:voucher_details[0].id,
                  voucher_type_name:voucher_details[0].name,
                  parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
                })
              }
          }
          if(inventory_details.voucher_type_guid){
            let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.guid == inventory_details.voucher_type_guid)
            if(voucher_details.length > 0){
              this.setState({
                voucher_type_guid:voucher_details[0].guid,
                voucher_type_id:voucher_details[0].id,
                voucher_type_name:voucher_details[0].name,
                parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
              })
            }
          }
          if(inventory_details.voucher_type_name){
            let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.name == inventory_details.voucher_type_name)
            if(voucher_details.length > 0){
                this.setState({
                  voucher_type_guid:voucher_details[0].guid,
                  voucher_type_id:voucher_details[0].id,
                  voucher_type_name:voucher_details[0].name,
                  parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
                })
            }
          }
        }else{
          if(!this.state.voucher_type_guid){
          let voucher_details = this.props.voucherTypeList.filter(voucher => voucher.name == (this.props.type == 1 ? 'Sales' : 'Purchase') && voucher.is_master == 1)
          if(voucher_details.length > 0){
            console.log("without merchnat")
            this.setState({
              voucher_type_guid:voucher_details[0].guid,
              voucher_type_id:voucher_details[0].id,
              voucher_type_name:voucher_details[0].name,
              parent_voucher_type_id:voucher_details[0].parent_voucher_type_id
            })
          }
        }
      }
      }
    );

    this.setState({
      show_edit_popup: true,
      // inventory_voucher_status: this.props.SNAPSHOT_LEDGER_ENTRIES_CODE
      //   ? true
      //   : inventory_details.inventory_voucher_status,
    });
  };

  saveLineItemData = async () => {
    let line_items = [...this.state.line_items];
    let hsn_sac_codes = [];
    let show_hsn_sac_warning = false;

    console.log("line items", line_items);
    line_items.forEach((item, index) => {
      if (item.line_item_id) {
        let item_data = this.state.line_items_list.filter(
          (i) => i.id == item.line_item_id
        );
        line_items[index].line_item_update = 0;
        if (item_data.length > 0) {
          if (
            this.state.inventory_voucher_status &&
            (item_data[0].item_ledger_guid != item.item_ledger_guid ||
              (!this.state.is_common_item_ledger &&
                this.state.type == 1 &&
                item_data[0].sales_ledger_guid !=
                item.common_item_ledger_guid) ||
              (!this.state.is_common_item_ledger &&
                this.state.type == 2 &&
                item_data[0].purchase_ledger_guid !=
                item.common_item_ledger_guid))
          ) {
            line_items[index].line_item_update = 1;
          }
          if (
            item_data[0].gst_rate != item.gst_rate ||
            item_data[0].per != item.per ||
            item_data[0].hsn_sac != item.hsn_sac ||
            item_data[0].rate != item.rate ||
            item.name != item_data[0].name
          ) {
            line_items[index].line_item_update = 1;
          }
          //if()
        }
      }

      if (item.gst_rate && item.hsn_sac) {
        if (!hsn_sac_codes[item.hsn_sac]) {
          hsn_sac_codes[item.hsn_sac] = [];
        }
        hsn_sac_codes[item.hsn_sac].push(item.gst_rate);
      }
    });
    //console.log(hsn_sac_codes)

    let numeric_hsn_array = new Array();
    for (var items in hsn_sac_codes) {
      numeric_hsn_array.push(hsn_sac_codes[items]);
    }
    //console.log(numeric_hsn_array)
    numeric_hsn_array.forEach((hsn_sac_code, numeric_hsn_array) => {
      console.log(hsn_sac_code);
      let all_equal = hsn_sac_code.every(
        (val) => parseFloat(val) == parseFloat(hsn_sac_code[0])
      );
      console.log(hsn_sac_code, all_equal);
      if (!all_equal) {
        show_hsn_sac_warning = true;
      }
    });

    if (show_hsn_sac_warning)
      toast.warning("Same HSN/SAC codes have different GST%");
    //return;

    let merchant_update = 0;
    if (
      this.state.inventory_voucher_status &&
      (this.props.merchant_option.party_ledger_guid !=
        this.state.party_ledger_guid ||
        (this.state.is_common_item_ledger &&
          this.state.type == 1 &&
          this.props.merchant_option.sales_ledger_guid !=
          this.state.common_item_ledger_guid) ||
        (this.state.is_common_item_ledger &&
          this.state.type == 2 &&
          this.props.merchant_option.sales_ledger_guid !=
          this.state.common_item_ledger_guid))
    ) {
      merchant_update = 1;
    }

    if((this.state.voucher_type_guid != (this.props.merchant_option.sales_voucher_type_guid || "") && this.props.type == 1)){
      merchant_update = 1
    }
    if((this.state.voucher_type_guid != (this.props.merchant_option.purchase_voucher_type_guid || "") && this.props.type == 2)){
      merchant_update = 1
    }

    console.log("save line item data ----------", this.state.is_base_amt_changed);
    this.setState(
      {
        line_items,
        merchant_update,
        is_base_amt_changed: this.state.is_base_amt_changed,
        // gst_summary: gst_total > 0 ? {
        // 	"cgst_total": this.state.is_igst ? null : total_cgst_amount,
        // 	"sgst_total": this.state.is_igst ? null : total_sgst_amount,
        // 	"igst_total": this.state.is_igst ? total_igst_amount : null,
        // 	"gst_total": gst_total,
        // 	"gst_details": gst_details
        // } : null,
        // hsn_sac_summary: hsn_sac_gst_total > 0 ? {
        // 	"basic_total": hsn_sac_basic_total,
        // 	"cgst_total": this.state.is_igst ? null : hsn_sac_cgst_total,
        // 	"sgst_total": this.state.is_igst ? null : hsn_sac_sgst_total,
        // 	"igst_total": this.state.is_igst ? hsn_sac_igst_total : null,
        // 	"gst_total": hsn_sac_gst_total,
        // 	"hsn_sac_details": hsn_details
        // }: null,
      },
      () => {
        console.log(this.state);
        this.props.saveLineItemData(this.state);
      }
    );
  };

  calculateGSTSummary = () => {
    let gst_summary = [];
    let gst_details = [];

    this.state.line_items.forEach((item, index) => {
      if (!gst_summary[item.gst_rate]) {
        gst_summary[item.gst_rate] = 0;
      }

      if (item.gst_rate)
        gst_summary[item.gst_rate] += parseFloat(item.amount) || 0;
    });

    gst_summary.forEach((amt, index) => {
      gst_details.push({
        gst_rate: index,
        gst_amount: amt,
        cgst_amount: !this.state.this.state.is_igst ? amt * 0.5 : null,
        sgst_amount: !this.state.this.state.is_igst ? amt * 0.5 : null,
        igst_amount: this.state.this.state.is_igst ? amt : null,
      });
    });

    console.log(gst_summary);
  };

  getGSTSummaryTotals = (gst_details, amount) => {
    let total = 0;

    if (amount == "total") {
      let total_cgst_amount = 0,
        total_sgst_amount = 0,
        total_igst_amount = 0;
      gst_details.map((data, index) => {
        total_cgst_amount += parseFloat(data.cgst_amount) || 0;
        total_sgst_amount += parseFloat(data.sgst_amount) || 0;
        total_igst_amount += parseFloat(data.igst_amount) || 0;
      });

      total = total_cgst_amount + total_sgst_amount + total_igst_amount;
    } else {
      gst_details.map((data, index) => {
        total += parseFloat(data[amount]) || 0;
      });
    }

    return (
      <td className="td-width-100 text-right">
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
            </Tooltip>
          }
        >
          <div className="d-inline-flex td-width-100">
            <span className="ellipsisc ellipsisc-line-1 w-100">
              ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
            </span>
          </div>
        </OverlayTrigger>
      </td>
    );
  };

  getHSNSACSummaryTotals = (hsn_details, amount) => {
    let total = 0;

    hsn_details.map((data, index) => {
      total += parseFloat(data[amount]) || 0;
    });

    if (total) {
      return (
        <td className="td-width-100 text-right">
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
              </Tooltip>
            }
          >
            <div className="d-inline-flex td-width-100">
              <span className="ellipsisc ellipsisc-line-1 w-100">
                ₹ {convertFloatNumberToCurrency(parseFloat(total).toFixed(2))}
              </span>
            </div>
          </OverlayTrigger>
        </td>
      );
    } else {
      return <td className="td-width-100 text-right"></td>;
    }
  };

  renderGSTSummary = () => {
    let gst_summary = [];
    let hsn_summary = [];
    let gst_details = [];
    let hsn_details = [];

    this.state.line_items.forEach((item, index) => {
      console.log(item.gst_rate);
      if (item.gst_rate) {
        if (!gst_summary[item.gst_rate]) {
          gst_summary[item.gst_rate] = 0;
        }
        gst_summary[item.gst_rate] += parseFloat(item.amount) || 0;
      }

      if (item.hsn_sac) {
        let hsn_sac = item.hsn_sac;
        if (!hsn_summary[item.hsn_sac]) {
          hsn_summary[hsn_sac] = {};
          hsn_summary[hsn_sac].amt = 0;
          // hsn_summary[hsn_sac].cgst_rate = 0;
          // hsn_summary[hsn_sac].sgst_rate = 0;
          // hsn_summary[hsn_sac].igst_rate = 0;
        }
        console.log(item.gst_rate);
        hsn_summary[hsn_sac].amt += parseFloat(item.amount) || 0;

        // hsn_summary[hsn_sac].cgst_rate += this.state.is_igst ? 0 : item.gst_rate*0.5;
        // hsn_summary[hsn_sac].sgst_rate += this.state.is_igst ? 0 : item.gst_rate*0.5;
        // hsn_summary[hsn_sac].igst_rate += this.state.is_igst ? item.gst_rate*1 : 0;
        if (!hsn_summary[hsn_sac].cgst_rate)
          hsn_summary[hsn_sac].cgst_rate = this.state.is_igst
            ? 0
            : item.gst_rate * 0.5;

        if (!hsn_summary[hsn_sac].sgst_rate)
          hsn_summary[hsn_sac].sgst_rate = this.state.is_igst
            ? 0
            : item.gst_rate * 0.5;

        if (!hsn_summary[hsn_sac].igst_rate)
          hsn_summary[hsn_sac].igst_rate = this.state.is_igst
            ? item.gst_rate * 1
            : 0;

        hsn_summary[hsn_sac].hsn_sac = item.hsn_sac;

        console.log(hsn_summary);
      }
    });

    let numeric_hsn_array = new Array();
    for (var items in hsn_summary) {
      numeric_hsn_array.push(hsn_summary[items]);
    }
    //console.log(gst_summary, hsn_summary, numeric_hsn_array)

    gst_summary.forEach((amt, index) => {
      let cgst_amount = !this.state.is_igst ? amt * 0.5 : null;
      let sgst_amount = !this.state.is_igst ? amt * 0.5 : null;
      let igst_amount = this.state.is_igst ? amt : null;

      gst_details.push({
        gst_rate: index,
        gst_amount: amt,
        cgst_amount: cgst_amount || null,
        sgst_amount: sgst_amount || null,
        igst_amount: igst_amount || null,
      });
    });
    //console.log(numeric_hsn_array)
    numeric_hsn_array.forEach((data, index) => {
      let gst_amount = 0;
      let cgst_amount = !this.state.is_igst
        ? (data.amt * data.cgst_rate) / 100
        : null;
      let sgst_amount = !this.state.is_igst
        ? (data.amt * data.sgst_rate) / 100
        : null;
      let igst_amount = this.state.is_igst
        ? (data.amt * data.igst_rate) / 100
        : null;

      (gst_amount += this.state.is_igst
        ? igst_amount || 0
        : (cgst_amount || 0) + (sgst_amount || 0)),
        hsn_details.push({
          cgst_rate: data.cgst_rate || null,
          sgst_rate: data.sgst_rate || null,
          igst_rate: data.igst_rate || null,
          gst_amount: parseFloat(gst_amount).toFixed(2),
          cgst_amount: cgst_amount ? parseFloat(cgst_amount).toFixed(2) : null,
          sgst_amount: sgst_amount ? parseFloat(sgst_amount).toFixed(2) : null,
          igst_amount: igst_amount ? parseFloat(igst_amount).toFixed(2) : null,
          hsn_sac: data.hsn_sac,
          basic_amount: parseFloat(data.amt).toFixed(2),
        });
    });

    //console.log(gst_details, hsn_details)

    return (
      <>
        <div className="col-xl-5">
          <table className="table borderTable">
            <thead>
              <tr>
                <th className="td-width-50 text-center">Type</th>
                {gst_details.map((data, index) => {
                  return (
                    <th className="td-width-100 text-right">
                      {this.state.is_igst
                        ? data.gst_rate + "%"
                        : data.gst_rate * 0.5 + "% (" + data.gst_rate + "%)"}
                    </th>
                  );
                })}
                <th className="td-width-100 text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-width-50 text-center">CGST</td>
                {this.state.is_igst
                  ? gst_details.map((data, index) => {
                    return <td></td>;
                  })
                  : gst_details.map((data, index) => {
                    return (
                      <td className="td-width-100 text-right">
                        {data.cgst_amount ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.cgst_amount
                                )}
                              </Tooltip>
                            }
                          >
                            <div className="d-inline-flex td-width-100">
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.cgst_amount
                                )}
                              </span>
                            </div>
                          </OverlayTrigger>
                        ) : null}
                      </td>
                    );
                  })}
                {this.state.is_igst ? (
                  <td className="td-width-100 text-right"></td>
                ) : (
                  this.getGSTSummaryTotals(gst_details, "cgst_amount")
                )}
              </tr>
              <tr>
                <td className="td-width-50 text-center">SGST</td>
                {this.state.is_igst
                  ? gst_details.map((data, index) => {
                    return <td></td>;
                  })
                  : gst_details.map((data, index) => {
                    return (
                      <td className="td-width-100 text-right">
                        {data.sgst_amount ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.sgst_amount
                                )}
                              </Tooltip>
                            }
                          >
                            <div className="d-inline-flex td-width-100">
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.sgst_amount
                                )}
                              </span>
                            </div>
                          </OverlayTrigger>
                        ) : null}
                      </td>
                    );
                  })}
                {this.state.is_igst ? (
                  <td className="td-width-100 text-right"></td>
                ) : (
                  this.getGSTSummaryTotals(gst_details, "sgst_amount")
                )}
              </tr>
              <tr>
                <td className="td-width-50 text-center">IGST</td>

                {this.state.is_igst
                  ? gst_details.map((data, index) => {
                    return (
                      <td className="td-width-100 text-right">
                        {data.igst_amount ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.igst_amount
                                )}
                              </Tooltip>
                            }
                          >
                            <div className="d-inline-flex td-width-100">
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(
                                  data.igst_amount
                                )}
                              </span>
                            </div>
                          </OverlayTrigger>
                        ) : null}
                      </td>
                    );
                  })
                  : gst_details.map((data, index) => {
                    return <td className="td-width-100 text-right"></td>;
                  })}
                {this.state.is_igst ? (
                  this.getGSTSummaryTotals(gst_details, "igst_amount")
                ) : (
                  <td className="td-width-100 text-right"></td>
                )}
              </tr>
              <tr className="gray-dark-bg">
                <td className="td-width-50 text-center blue-text">Total (₹)</td>
                {gst_details.map((data, index) => {
                  return (
                    <td className="td-width-100 text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </Tooltip>
                        }
                      >
                        <div className="d-inline-flex td-width-100">
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                  );
                })}
                {this.getGSTSummaryTotals(gst_details, "total")}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-xl-7">
          <table className="table borderTable">
            <thead>
              <tr>
                <th width="150px" className="text-center" rowSpan={2}>
                  HSN/SAC
                </th>
                <th width="130px" className="text-right" rowSpan={2}>
                  Taxable Value
                </th>
                <th className="text-center" colSpan={2}>
                  CGST
                </th>
                <th className="text-center" colSpan={2}>
                  SGST
                </th>
                <th className="text-center" colSpan={2}>
                  IGST
                </th>
                <th className="text-right">GST Total</th>
              </tr>
              <tr>
                <th width="80px" className="text-right">
                  Rate
                </th>
                <th className="text-right">Amount</th>
                <th width="80px" className="text-right">
                  Rate
                </th>
                <th className="text-right">Amount</th>
                <th width="80px" className="text-right">
                  Rate
                </th>
                <th className="text-right">Amount</th>
                <th className=""></th>
              </tr>
            </thead>
            <tbody>
              {hsn_details.map((data, index) => {
                return (
                  <tr>
                    <td className="text-center">{data.hsn_sac}</td>
                    <td className="text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹ {convertFloatNumberToCurrency(data.basic_amount)}
                          </Tooltip>
                        }
                      >
                        <div className="d-inline-flex">
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹ {convertFloatNumberToCurrency(data.basic_amount)}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td className="text-right">
                      {data.cgst_rate}
                      {data.cgst_rate ? "%" : null}
                    </td>
                    <td className="text-right">
                      {data.cgst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹ {convertFloatNumberToCurrency(data.cgst_amount)}
                            </Tooltip>
                          }
                        >
                          <div className="d-inline-flex">
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹ {convertFloatNumberToCurrency(data.cgst_amount)}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-right">
                      {data.sgst_rate}
                      {data.sgst_rate ? "%" : null}
                    </td>
                    <td className="text-right">
                      {data.sgst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹ {convertFloatNumberToCurrency(data.sgst_amount)}
                            </Tooltip>
                          }
                        >
                          <div className="d-inline-flex">
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹ {convertFloatNumberToCurrency(data.sgst_amount)}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-right">
                      {data.igst_rate}
                      {data.igst_rate ? "%" : null}
                    </td>
                    <td className="text-right">
                      {data.igst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹ {convertFloatNumberToCurrency(data.igst_amount)}
                            </Tooltip>
                          }
                        >
                          <div className="d-inline-flex">
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹ {convertFloatNumberToCurrency(data.igst_amount)}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td className="text-right">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </Tooltip>
                        }
                      >
                        <div className="d-inline-flex">
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹ {convertFloatNumberToCurrency(data.gst_amount)}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}

              <tr className="gray-dark-bg">
                <td className="text-center blue-text">Total (₹)</td>
                {this.getHSNSACSummaryTotals(hsn_details, "basic_amount")}
                <td className="text-right"></td>
                {this.getHSNSACSummaryTotals(hsn_details, "cgst_amount")}
                <td className="text-right"></td>
                {this.getHSNSACSummaryTotals(hsn_details, "sgst_amount")}
                <td className="text-right"></td>
                {this.getHSNSACSummaryTotals(hsn_details, "igst_amount")}
                {this.getHSNSACSummaryTotals(hsn_details, "gst_amount")}
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  showWarningStyle = (index, name) => {
    if (
      this.props.SNAPSHOT_LEDGER_ENTRIES_CODE &&
      this.state.inventory_voucher_status == true
    ) {
      //console.log(name, this.state.line_items[index].item_ledger_guid)
      let warning = false;

      this.state.line_items[index][name + "_warning"] = false;
      this.state.line_items[index][name + "_warning_mismatch"] = false;
      if (this.state.line_items[index].item_ledger_guid) {
        let item = this.state.item_ledger_list.filter(
          (item_ledger) =>
            item_ledger.guid == this.state.line_items[index].item_ledger_guid
        );
        //console.log(item)
        if (item.length > 0) {
          //console.log(item[0][name], this.state.line_items[index][name])
          if (!item[0][name]) {
            let warning_field = name + "_warning";
            warning = true;
            this.state.line_items[index][warning_field] = true;
          }
          if (
            item[0][name] &&
            this.state.line_items[index][name] != item[0][name]
          ) {
            let warning_field = name + "_warning_mismatch";
            warning = true;
            this.state.line_items[index][warning_field] = true;
          }
        }
      }

      if (warning) {
        return " item-warning-field";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  clearCommonItemData = (is_common_item_ledger) => {
    if (!is_common_item_ledger) {
      this.setState({
        common_item_ledger_guid: "",
        common_item_ledger_id: "",
        common_item_ledger_name: "",
      });
    } else {
      let line_items = [...this.state.line_items];

      line_items.forEach((item, index) => {
        line_items[index].common_item_ledger_guid = "";
        line_items[index].common_item_ledger_id = "";
        line_items[index].common_item_ledger_name = "";
      });

      this.setState({ line_items });
    }
  };

  addTdsRow = () => {
    console.log(" clieck on add buttion ", this.state.tds_details, this.state.snapshot_id);
    let tds_information = [...this.state.tds_details];
    if (this.state.snapshot_id)
      tds_information.push({ id: 0, base_amount: "", tds_percentage: "", tds_amount: "" });
    else
      tds_information.push({ base_amount: "", tds_percentage: "", tds_amount: "" });

    console.log("addd row ----", tds_information);
    this.setState(
      { tds_details: tds_information },
      () => {
        // this.payment_focus.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "nearest",
        // });
      }
    );
  };

  removeTds = (index) => {
    console.log("index ------------------- ", index);
    let tds_information = [...this.state.tds_details];
    const isEmpty = Object.values(tds_information[index]).every(x => (x === 0 || x === ''));
    if (isEmpty) {
      tds_information.splice(index, 1);
    } else {
      if (!!tds_information[index].id) {
        if (tds_information[index].id != 0) {
          let to_be_remove = this.props.remove_tds_details;
          to_be_remove.push(tds_information[index].id)
          this.props.setRemoveTdsInformation(to_be_remove)
        }
      }

      tds_information.splice(index, 1);
    }
    this.setState({ tds_details: tds_information }, () => {
      this.calculateTDS();
    });
  };
  render() {
    console.log("vocuer", this.props.date);
    console.log(
      "props",
      this.props.line_items_prediction_status,
      this.state.line_items
    );
    console.log(this.props);
    console.log(this.state.voucher_type_name,this.state.voucher_type_guid)
    //console.log(this.state)

    let item_ledger_list = this.state.item_ledger_list.map((item) => {
      return { value: item.guid, label: item.name };
    });

    let line_items_list = this.state.line_items_list.map((item) => {
      return { value: item.id, label: item.name };
    });

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
      condition ? wrapper(children) : children;

    return (
      <React.Fragment>
        <DirtyFieldModal
          visible={this.state.dirty_popup_visible}
          onCancel={() => this.setState({ dirty_popup_visible: false })}
          onDontSave={() => this.setState({ show_edit_popup: false })}
          onClose={() => { }}
        />
        <div className="row">
          <div className="col-sm-12">
            <div className="form-main-title-new border-0 mb-2 pb-0">
              <h4 className="form-title-heading-new size-xviii">
                Item Details
                {(!!Object.keys(this.state.errors).length ||
                  this.state.line_item_errors.length != 0 ||
                  this.state.ledger_errors.length != 0) && (
                    <span className="red-text error_right mb-0">
                      {this.state.errors.inventory_error_status}
                    </span>
                  )}
              </h4>

              <span
                className={
                  "cpointer size-xxii " +
                  (this.props.VIEW_ONLY_SNAPSHOT || !this.props.editable
                    ? "icon-view "
                    : "icon-edit")
                }
                onClick={() => this.openInventoryEditView()}
              ></span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <table className="table borderTable mb-2">
              <thead>
                <tr>
                  <th width="280px">Item</th>
                  <th
                    width="120px"
                    style={{ maxWidth: "120px" }}
                    className="text-center"
                  >
                    Quantity
                  </th>
                  <th
                    width="150px"
                    style={{ maxWidth: "150px" }}
                    className="text-right"
                  >
                    Rate
                  </th>
                  <th
                    width="150px"
                    style={{ maxWidth: "150px" }}
                    className="text-right"
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.inventory_details.line_items.length == 0 ? (
                  <tr>
                    <td width="280px"></td>
                    <td
                      width="120px"
                      style={{ maxWidth: "120px" }}
                      className="text-center"
                    ></td>
                    <td
                      width="150px"
                      style={{ maxWidth: "150px" }}
                      className="text-right"
                    ></td>
                    <td
                      width="150px"
                      style={{ maxWidth: "150px" }}
                      className="text-right"
                    ></td>
                  </tr>
                ) : (
                  this.props.inventory_details.line_items.map((item, index) => {
                    return (
                      <tr>
                        <td width="280px">{item.line_item_name || ""}</td>
                        <td
                          width="120px"
                          style={{ maxWidth: "120px" }}
                          className="text-center"
                        >
                          {(item.quantity || 0) + " " + (item.per || "")}
                        </td>
                        <td
                          width="150px"
                          style={{ maxWidth: "150px" }}
                          className="text-right"
                        >
                          ₹ {convertFloatNumberToCurrency(item.rate) || 0}
                        </td>
                        <td
                          width="150px"
                          style={{ maxWidth: "150px" }}
                          className="text-right"
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ₹{" "}
                                {convertFloatNumberToCurrency(item.amount) || 0}
                              </Tooltip>
                            }
                          >
                            <div
                              className="d-inline-flex"
                              width="150px"
                              style={{ maxWidth: "150px" }}
                            >
                              <span className="ellipsisc ellipsisc-line-1 w-100">
                                ₹{" "}
                                {convertFloatNumberToCurrency(item.amount) || 0}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })
                )}
                <tr className="gray-dark-bg">
                  <th className="text-center border-right-0"></th>
                  <th className="text-center border-left-0">Total (₹)</th>
                  {/* <td width="150px" style={{ maxWidth: "150px" }} className="text-center green-white-bgt"> */}
                  <td
                    width="150px"
                    style={{ maxWidth: "150px" }}
                    className="text-right"
                  >
                    {/* {this.props.inventory_details.line_item_gst_total && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">₹ {convertFloatNumberToCurrency(this.props.line_item_gst_total) || 0}</Tooltip>}>
											<div className="d-inline-flex" width="150px" style={{ maxWidth: "150px" }}>
												<span className="ellipsisc ellipsisc-line-1 w-100">
													₹ {convertFloatNumberToCurrency((this.props.inventory_details.line_items.reduce((a, value) => a = (parseFloat(a) || 0) + (parseFloat(value.rate) || 0), 0))) || 0}
												</span>
											</div>
										</OverlayTrigger>} */}
                    {1 && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹{" "}
                            {convertFloatNumberToCurrency(
                              this.props.line_item_gst_total
                            ) || 0}
                          </Tooltip>
                        }
                      >
                        <div
                          className="d-inline-flex"
                          width="150px"
                          style={{ maxWidth: "150px" }}
                        >
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹{" "}
                            {convertFloatNumberToCurrency(
                              this.props.inventory_details.line_items
                                .reduce(
                                  (a, value) =>
                                  (a =
                                    (parseFloat(a) || 0) +
                                    (parseFloat(value.rate) || 0)),
                                  0
                                )
                                .toFixed(2)
                            ) || 0}
                          </span>
                        </div>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td
                    width="150px"
                    style={{ maxWidth: "150px" }}
                    className="text-right"
                  >
                    {/* {this.props.inventory_details.line_item_total && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">₹ {convertFloatNumberToCurrency(this.props.inventory_details.line_item_total) || 0}</Tooltip>}>
											<div className="d-inline-flex" width="150px" style={{ maxWidth: "150px" }}>
												<span className="ellipsisc ellipsisc-line-1 w-100">
													₹ {convertFloatNumberToCurrency(this.props.inventory_details.line_item_total) || 0}
												</span>
											</div>
										</OverlayTrigger>} */}
                    {1 && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ₹{" "}
                            {convertFloatNumberToCurrency(
                              this.props.inventory_details.line_item_total
                            ) || 0}
                          </Tooltip>
                        }
                      >
                        <div
                          className="d-inline-flex"
                          width="150px"
                          style={{ maxWidth: "150px" }}
                        >
                          <span className="ellipsisc ellipsisc-line-1 w-100">
                            ₹{" "}
                            {convertFloatNumberToCurrency(
                              this.props.inventory_details.line_items
                                .reduce(
                                  (a, value) =>
                                  (a =
                                    (parseFloat(a) || 0) +
                                    (parseFloat(value.amount) || 0)),
                                  0
                                )
                                .toFixed(2)
                            ) || 0}
                          </span>
                        </div>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {this.props.gst_total_amount ? (
            <div className="col-lg-12">
              <table className="table borderTable mb-2">
                <thead>
                  <tr>
                    <th
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      CGST
                    </th>
                    <th
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      SGST
                    </th>
                    <th
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      IGST
                    </th>
                    <th
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      GST Total
                    </th>
                    <th
                      width="150px"
                      style={{ maxWidth: "150px" }}
                      className="text-center"
                    >
                      GST Eligible
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      {this.props.cgst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.cgst_amount
                              )}
                            </Tooltip>
                          }
                        >
                          <div
                            className="d-inline-flex"
                            width="200px"
                            style={{ maxWidth: "200px" }}
                          >
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.cgst_amount
                              )}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      {this.props.sgst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.sgst_amount
                              )}
                            </Tooltip>
                          }
                        >
                          <div
                            className="d-inline-flex"
                            width="200px"
                            style={{ maxWidth: "200px" }}
                          >
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.sgst_amount
                              )}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      {this.props.igst_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.igst_amount
                              )}
                            </Tooltip>
                          }
                        >
                          <div
                            className="d-inline-flex"
                            width="200px"
                            style={{ maxWidth: "200px" }}
                          >
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.igst_amount
                              )}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td
                      width="200px"
                      style={{ maxWidth: "200px" }}
                      className="text-right"
                    >
                      {this.props.gst_total_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.gst_total_amount
                              )}
                            </Tooltip>
                          }
                        >
                          <div
                            className="d-inline-flex"
                            width="200px"
                            style={{ maxWidth: "200px" }}
                          >
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.gst_total_amount
                              )}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                    <td
                      width="150px"
                      style={{ maxWidth: "150px" }}
                      className="text-center"
                    >
                      {this.props.is_gst_claim_eligible ? "Yes" : "No"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}

          {this.props.tds_status == 1 || this.props.tds_status ? (
            <div className="col-lg-8 pr-lg-0">
              <table className="table borderTable mb-2">
                <thead>
                  <tr>
                    <th
                      className="text-right"
                      width="150px"
                      style={{ maxWidth: "150px" }}
                    >
                      TDS Base Amount
                    </th>
                    <th
                      className="text-right"
                      width="150px"
                      style={{ maxWidth: "150px" }}
                    >
                      TDS %
                    </th>
                    <th
                      className="text-right"
                      width="150px"
                      style={{ maxWidth: "150px" }}
                    >
                      TDS Amount
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {this.props.tds_details.map(
                    (tds, index) => {
                      return (
                        <tr>
                          <td
                            className="text-right"
                            width="150px"
                            style={{ maxWidth: "150px" }}
                          >
                            {tds.base_amount}
                          </td>
                          <td
                            className="text-right"
                            width="150px"
                            style={{ maxWidth: "150px" }}
                          >
                            {tds.tds_percentage}
                          </td>
                          <td
                            className="text-right"
                            width="150px"
                            style={{ maxWidth: "150px" }}
                          >
                            {tds.tds_amount}
                          </td>
                        </tr>
                      )
                    })}
                  <tr>                    
                    <td
                      className="text-right"
                      width="150px"
                      style={{ maxWidth: "150px" }}
                    >
                    </td>
                    <th
                      className="text-right"
                      width="150px"
                      style={{ maxWidth: "150px" }}
                    >
                      TDS Total (₹)
                    </th>
                    <td
                      className="text-right"
                      width="150px"
                      style={{ maxWidth: "150px" }}
                    >
                      {this.props.tds_amount && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.tds_amount
                              )}
                            </Tooltip>
                          }
                        >
                          <div
                            className="d-inline-flex"
                            width="150px"
                            style={{ maxWidth: "150px" }}
                          >
                            <span className="ellipsisc ellipsisc-line-1 w-100">
                              ₹{" "}
                              {convertFloatNumberToCurrency(
                                this.props.tds_amount
                              )}
                            </span>
                          </div>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}

          <div className="col-lg-4">
            {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
              this.props.VIEW_ONLY_SNAPSHOT) && (
                <table className="table borderTable">
                  <thead>
                    <tr>
                      <th className="text-center">Ledger Entries</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          {this.props.inventory_details
                            .inventory_voucher_status ? (
                            <span>Yes</span>
                          ) : (
                            <span>No</span>
                          )}

                          {this.props.inventory_details
                            .inventory_voucher_status == true &&
                            this.getVoucherSyncStatusOuter(
                              this.props.inventory_details.sync_status,
                              this.props.inventory_details.sync_datetime,
                              this.props.inventory_details.sync_error
                            )}
                        </div>

                        {/* {this.props.inventory_details.inventory_voucher_status == true && <span class="icon-personal-icon ml-2 size-xviii"><span class="path1 gray-icon"></span><span class="path2"></span><span class="path3"></span></span>} */}
                        {/* <span class="icon-personal-icon gray-white-icon size-xviii"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
          </div>
        </div>

        <Modal show={this.state.show_edit_popup} className="overlay-black">
          {/* <Modal show={'show'} className="overlay-black"> */}
          <div className="inventory_popup">
            {/* <div
							className="bg-image-wrapper"
							style={{ transform: `scale(${this.state.scale})`, backgroundImage : `url(https://thumbs.dreamstime.com/b/illustration-receipt-template-black-white-vector-129915676.jpg)` }}
							// backgroundImage: `url(${Background})`
						/> */}

            {/* <img src="https://thumbs.dreamstime.com/b/illustration-receipt-template-black-white-vector-129915676.jpg" class="img-fluid" style={{ transform: `scale(${this.state.scale})`}}/> */}
            {/* <div>
							<div className="bg-image-container">
								<img src="https://thumbs.dreamstime.com/b/illustration-receipt-template-black-white-vector-129915676.jpg" height="100%" style={{ transform: `scale(${this.state.scale})` }} />
							</div>
							<div className="content">
								<button onClick={this.zoomOut}>Zoom out</button>
								<button onClick={this.zoomIn}>Zoom in</button>
							</div>
						</div> */}
            {/* <div id="react"></div> */}

            {/* PDF */}
            {this.props.qualified_url ? (
              <div className="inve_pdf">
                {this.props.qualified_url.indexOf(".jpg") != -1 ||
                  this.props.qualified_url.indexOf(".jpeg") != -1 ||
                  this.props.qualified_url.indexOf(".png") != -1 ||
                  this.props.qualified_url.indexOf(".pdf") != -1 ? (
                  this.props.qualified_url.indexOf(".pdf") != -1 ? (
                    <iframe
                      src={StorageData.getQualifiedFileUrl(
                        this.props.qualified_url
                      )}
                      className="iframe_pdf"
                    />
                  ) : (
                    <div className="inve-zoom-img-sec">
                      <div className="inve-zoom-img-div">
                        <TransformWrapper
                          initialScale={1}
                          initialPositionX={0}
                          initialPositionY={0}
                        >
                          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                              <div className="tools">
                                <button onClick={() => zoomIn()}>+</button>
                                <button onClick={() => zoomOut()}>-</button>
                                <button onClick={() => resetTransform()}>
                                  x
                                </button>
                              </div>
                              <TransformComponent>
                                <img
                                  src={StorageData.getQualifiedFileUrl(
                                    this.props.qualified_url
                                  )}
                                  className="h-100"
                                />
                              </TransformComponent>
                            </React.Fragment>
                          )}
                        </TransformWrapper>
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    <p className="white-text">
                      Looks like this file doesn't have a preview. You can
                      download the file by clicking dowload button.
                    </p>
                    <Button
                      variant="outline-success reset_btn_new"
                      onClick={() =>
                        this.downloadFile(
                          StorageData.getQualifiedFileUrl(
                            this.props.qualified_url
                          )
                        )
                      }
                    >
                      Download
                    </Button>
                  </div>
                )}
              </div>
            ) : this.props.file_url && this.props.file_type ? (
              <div className="inve_pdf">
                {this.props.file_type == "application/pdf" ||
                  this.props.file_type == "image/jpeg" ||
                  this.props.file_type == "image/png" ||
                  this.props.file_type == "image/jpg" ? (
                  this.props.file_type == "application/pdf" ? (
                    <iframe src={this.props.file_url} className="iframe_pdf" />
                  ) : (
                    <div className="inve-zoom-img-sec">
                      <div className="inve-zoom-img-div">
                        <TransformWrapper
                          initialScale={1}
                          initialPositionX={0}
                          initialPositionY={0}
                        >
                          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                              <div className="tools">
                                <button onClick={() => zoomIn()}>+</button>
                                <button onClick={() => zoomOut()}>-</button>
                                <button onClick={() => resetTransform()}>
                                  x
                                </button>
                              </div>
                              <TransformComponent>
                                <img
                                  src={this.props.file_url}
                                  className="h-100"
                                />
                              </TransformComponent>
                            </React.Fragment>
                          )}
                        </TransformWrapper>
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    <p className="white-text">
                      Looks like this file doesn't have a preview. You can
                      download the file by clicking dowload button.
                    </p>
                    <a href={this.props.file_url} target="_blank" download>
                      <Button variant="outline-success reset_btn_new">
                        Download
                      </Button>
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div className="inve_pdf">
                <p className="white-text mb-0 ss-bold size-xxii">
                  File not uploaded
                </p>
              </div>
            )}

            {!this.props.file_url && !this.props.qualified_url && (
              <div className="inve_pdf">
                <p className="white-text mb-0 ss-bold size-xxii">
                  File not uploaded
                </p>
              </div>
            )}
            {/* End PDF */}

            {/* Form Resizable */}
            <div className="inventory_form_sec">
              <div className="inv-white-bg ">
                <Resizable
                  className="resizable-tag"
                  defaultSize={{ width: "100%", height: "40vh" }}
                  handleClasses={{
                    top: "",
                    right: "pointer-events-none",
                    bottom: "pointer-events-none",
                    left: "pointer-events-none",
                    topRight: "pointer-events-none",
                    topLeft: "pointer-events-none",
                    bottomRight: "pointer-events-none",
                    bottomLeft: "pointer-events-none",
                  }}
                >
                  {/* <span class="icon-synched-icon size-xxx resizable-icon"><span class="path1 green-icon"></span><span class="path2 white-icon"></span><span class="path3 white-icon"></span></span> */}

                  {/* width="35" height="35" */}

                  <svg
                    class="resizable-icon"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 24" id="Layer_24">
                      <path
                        fill="#ffffff"
                        d="M17.59,12.93a.92.92,0,0,0,0-1.32L12.93,6.94a.87.87,0,0,0-.31-.2.84.84,0,0,0-.35-.07h0a.84.84,0,0,0-.35.07.83.83,0,0,0-.3.2L6.94,11.61a.93.93,0,1,0,1.32,1.32l3.07-3.08V24.4a.94.94,0,0,0,1.87,0V9.85l3.07,3.08A.94.94,0,0,0,17.59,12.93Z"
                      />
                      <path
                        fill="#ffffff"
                        d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26.13A12.13,12.13,0,1,1,28.13,16,12.14,12.14,0,0,1,16,28.13Z"
                      />
                      <path
                        fill="#ffffff"
                        d="M25.06,19.07a.94.94,0,0,0-1.32,0l-3.07,3.08V7.6a.94.94,0,0,0-1.87,0V22.15l-3.07-3.08a.94.94,0,0,0-1.32,0,.92.92,0,0,0,0,1.32l4.66,4.67a.87.87,0,0,0,.31.2.84.84,0,0,0,.35.07h0a.84.84,0,0,0,.35-.07.83.83,0,0,0,.3-.2l4.67-4.67A.94.94,0,0,0,25.06,19.07Z"
                      />
                    </g>
                  </svg>

                  <div className="inv-mp ">
                    <div className="inv-scroll ">
                      {/* Item Details */}
                      <div className="inv-frm-bar gap-15">
                        {/* Left  */}
                        <div className="d-xl-flex align-items-center gap-10">
                          <div className="inv-frm-bar-icon gap-10">
                            <h4 className="mb-0 mr-2">Item Details</h4>

                            {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                              this.props.VIEW_ONLY_SNAPSHOT) && (
                                <div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center">
                                  <span className="toggle-btn-sec-text mr-4">
                                    Ledgers
                                  </span>
                                  <span className="toggle-switch-center">
                                    <span className="toggle-btn-sec-text">
                                      No
                                    </span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="ledgers_status"
                                        checked={
                                          this.state.inventory_voucher_status
                                        }
                                        disabled={
                                          this.props.VIEW_ONLY_SNAPSHOT ||
                                          !this.props.editable
                                        }
                                        onChange={async (e) => {
                                          const target = e.target;
                                          const value = target.checked;
                                          console.log("target", value);

                                          if (!value) {
                                            if (
                                              !(await confirm({
                                                confirmation:
                                                  "Ledger data will be lost if any present related to ledgers. Do you want to continue?",
                                              }))
                                            ) {
                                              return true;
                                            }
                                          }
                                          this.setState(
                                            {
                                              inventory_voucher_status: value,
                                              is_dirty: true,
                                            },
                                            () => {
                                              // this.clearLedgerData();
                                            }
                                          );
                                          if (!value) {
                                            this.clearLedgerData();
                                          }
                                          if (value) {
                                            if (
                                              this.props
                                                .line_items_prediction_status ||
                                              this.props.bulk_prediction_status
                                            ) {
                                              this.newLineItemsPrediction();
                                            }
                                          }
                                        }}
                                      />
                                      <span
                                        className={
                                          "slider round " +
                                          (this.props.VIEW_ONLY_SNAPSHOT ||
                                            !this.props.editable
                                            ? "disable-opacity"
                                            : "")
                                        }
                                      ></span>
                                    </label>
                                    <span className="toggle-btn-sec-text">
                                      Yes
                                    </span>
                                  </span>
                                </div>
                              )}

                            {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                              this.props.VIEW_ONLY_SNAPSHOT) &&
                              this.state.inventory_voucher_status == true &&
                              this.getVoucherSyncStatus(
                                this.state.sync_status,
                                this.state.sync_datetime,
                                this.state.sync_error
                              )}

                            {/* <OverlayTrigger overlay={<Tooltip>Refresh Recommendation</Tooltip>}>
															<button class="btn refresh-icon-new-round"><span class="icon-invoice1 white-icon"></span></button>
														</OverlayTrigger> */}

                            {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                              this.props.VIEW_ONLY_SNAPSHOT) &&
                              this.state.inventory_voucher_status == true && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>Refresh Recommendation</Tooltip>
                                  }
                                >
                                  {/* <button class="btn refresh-icon-new-round blink-green-btn" disabled={this.props.VIEW_ONLY_SNAPSHOT} onClick={() => {
																this.getItemLedgers();
																this.props.getLedgerData()
															}}>
																<span class="icon-refresh"></span>
															</button> */}

                                  <span
                                    class="icon-refresh-iconn refresh-licon blink-green-btn"
                                    disabled={this.props.VIEW_ONLY_SNAPSHOT}
                                    onClick={() => {
                                      this.getItemLedgers();
                                      this.props.getLedgerData();
                                    }}
                                  >
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                    <span class="path3"></span>
                                    <span class="path4"></span>
                                  </span>
                                </OverlayTrigger>
                              )}

                            {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                              this.props.VIEW_ONLY_SNAPSHOT) &&
                              this.state.inventory_voucher_status == true &&
                              this.getLedgerSyncStatus(
                                this.props.ledger_data.sync_status,
                                this.props.ledger_data.sync_error
                              )}
                          </div>

                          {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                            this.props.VIEW_ONLY_SNAPSHOT) &&
                            this.state.inventory_voucher_status == true && (
                              <p className="ledger-bar-sync-text mb-0">
                                Last Sync:{" "}
                                {this.props.ledger_data.last_updated_at}
                              </p>
                            )}

                          {!!Object.keys(this.state.errors).length && (
                            <span className="red-text error_right mb-0">
                              {this.state.errors.inventory_error_status}
                            </span>
                          )}
                        </div>

                        {/* Right  */}

                        {/* <div className="d-flex align-items-center "> */}
                        <div className="inv-frm-bar-icon gap-10 ml-auto">
                          {/* <p className='size-xvii s-sbold mb-0'>Common Item Ledger For Allocation</p> */}
                          {/* <div className="toggle-switch-btn toggle-switch-center"> */}
                          {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                            this.props.VIEW_ONLY_SNAPSHOT) &&
                            this.state.inventory_voucher_status == true && (
                              <div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center">
                                <span className="toggle-btn-sec-text mr-4">
                                  Common Item Ledger For Allocation
                                </span>
                                <span className="toggle-switch-center">
                                  <span className="toggle-btn-sec-text">
                                    No
                                  </span>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      name="ledgers_status"
                                      disabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                      checked={this.state.is_common_item_ledger}
                                      onChange={async (e) => {
                                        const target = e.target;
                                        const value = target.checked;
                                        this.setState(
                                          {
                                            is_common_item_ledger: value,
                                            is_dirty: true,
                                          },
                                          () => {
                                            this.clearCommonItemData(value);
                                          }
                                        );
                                      }}
                                    />
                                    <span
                                      className={
                                        "slider round " +
                                        (this.props.VIEW_ONLY_SNAPSHOT ||
                                          !this.props.editable
                                          ? "disable-opacity"
                                          : "")
                                      }
                                    ></span>
                                  </label>
                                  <span className="toggle-btn-sec-text">
                                    Yes
                                  </span>
                                </span>
                              </div>
                            )}

                          <span
                            class="icon-Cross-with-grey-circle cpointer size-xxvii"
                            onClick={async () => {
                              if (this.state.is_dirty) {
                                if (
                                  await confirm({
                                    confirmation:
                                      "Changes are not saved. Do you still want to leave?",
                                  })
                                ) {
                                  // this.props.emptyPredictionLineItems();
                                  this.setState({ show_edit_popup: false });
                                }
                              } else {
                                this.setState({ show_edit_popup: false });
                              }
                            }}
                          >
                            <span class="path1"></span>
                            <span class="path2 cpointer"></span>
                            <span class="path3"></span>
                          </span>
                        </div>
                      </div>

                      <div className="inventory-scrolling">
                        {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                          this.props.VIEW_ONLY_SNAPSHOT) &&
                          this.state.inventory_voucher_status == true && (
                            <div className="row">
                              <div className="col-xl-2 col-lg-3">
                                <div className="form-group">
                                  <lable className="lable-input">
                                    Invoice#
                                  </lable>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="invoice_id"
                                    value={this.props.invoice_number}
                                    disabled
                                  />
                                  {/* <small className="form-error-text">error</small> */}
                                </div>
                              </div>
                              <div className="col-xl-2 col-lg-3">
                                <div className="form-group">
                                  <lable className="lable-input">
                                    Voucher Type
                                  </lable>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    name="invoice_voucher_type"
                                    value={
                                      this.props.type == 1
                                        ? "Sales"
                                        : "Purchase"
                                    }
                                    disabled
                                  /> */}
                                   <Form>
                                      <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                                        <Form.Control as="select" disabled={(this.props.ledger_data.ledger_list !== undefined && this.props.ledger_data.ledger_list.length === 0) ? true : false} name="voucher_type" value={this.state.voucher_type_guid}
                                          onChange={this.handleVoucherChange} >
                                          <option selected value="">Voucher Type</option>
                                          {
                                                  this.props.voucherTypeList.filter((value) => value.parent_voucher_type_id == this.state.check_sales_purchase
                                                  ).map((value,index)=>{
                                                    return (
                                                      <option key={index} value={value.guid}>
                                                      {value.name}
                                                      </option>
                                                      );
                                                  })
                                                }
                                        </Form.Control>
                                        {this.props.voucherTypeList.length == 0 && (
                                      <small className="error_right">
                                        Please sync with tally to get Voucher Types
                                      </small>
                                    )}
                                      </Form.Group>
                                    </Form>
                                    
                                </div>
                              </div>
                              <div className="col-xl-2 col-lg-3">
                                <div className="form-group">
                                  <lable className="lable-input">
                                    Voucher Date
                                    <span className="red-star">*</span>
                                  </lable>
                                  <DatePicker
                                    className={
                                      "form-control datepicker-icon" +
                                      (this.state.errors.voucher_creation_date
                                        ? " item-error-field"
                                        : "") +
                                      (this.props
                                        .line_items_prediction_status &&
                                        this.state
                                          .voucher_creation_date_prediction_status
                                        ? " input-bg-green "
                                        : "")
                                    }
                                    dateFormat={DatePickerFormate()}
                                    onChange={(date) => {
                                      this.setState({
                                        voucher_creation_date: date,
                                        is_dirty: true,
                                        voucher_creation_date_prediction_status: 0,
                                      });
                                    }}
                                    disabled={
                                      this.props.VIEW_ONLY_SNAPSHOT ||
                                      !this.props.editable
                                    }
                                    selected={this.state.voucher_creation_date}
                                    autoComplete="off"
                                    name="voucher_creation_date"
                                  />
                                  {this.state.errors.voucher_creation_date && (
                                    <small className="error_right">
                                      {this.state.errors.voucher_creation_date}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-3 col-lg-3">
                                <div className="form-group">
                                  <lable className="lable-input">
                                    Party Ledger
                                    <span className="red-star">*</span>
                                  </lable>
                                  <Form>
                                    <ReactSelect
                                      className={
                                        "Select-Search typeed-dd" +
                                        (this.state.errors.party_ledger_guid
                                          ? " error-border "
                                          : "") +
                                        (this.props
                                          .line_items_prediction_status &&
                                          this.state
                                            .party_ledger_guid_prediction_status
                                          ? " input-bg-green "
                                          : "")
                                      }
                                      classNamePrefix="Select type-select "
                                      name="party_ledger"
                                      options={this.state.ledger_options}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={true}
                                      value={this.state.ledger_options.filter(
                                        (option) =>
                                          option.value ==
                                          this.state.party_ledger_guid
                                      )}
                                      onChange={(option) => {
                                        let ledger =
                                          this.props.ledger_data.ledger_list.filter(
                                            (ledger) =>
                                              ledger.guid == option.value
                                          );

                                        this.setState({
                                          party_ledger_guid: ledger[0].guid,
                                          party_ledger_id: ledger[0].id,
                                          party_ledger_name: ledger[0].name,
                                          party_ledger_guid_prediction_status: 0,
                                          party_ledger_id_prediction_status: 0,
                                          party_ledger_name_prediction_status: 0,
                                          is_dirty: true,
                                        });
                                      }}
                                      isDisabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                    />
                                    {this.state.errors.party_ledger_guid && (
                                      <small className="error_right">
                                        {this.state.errors.party_ledger_guid}
                                      </small>
                                    )}
                                  </Form>
                                </div>
                              </div>
                              {this.state.is_common_item_ledger == 1 && (
                                <div className="col-xl-2 col-lg-3">
                                  <div className="form-group">
                                    <lable className="lable-input">
                                      {this.props.type == 1
                                        ? "Sales Ledger"
                                        : "Purchase Ledger"}{" "}
                                      <span className="red-star">*</span>
                                    </lable>
                                    <Form>
                                      <ReactSelect
                                        className={
                                          "Select-Search typeed-dd" +
                                          (this.state.errors
                                            .common_item_ledger_guid
                                            ? " error-border "
                                            : "") +
                                          (this.props
                                            .line_items_prediction_status &&
                                            this.state
                                              .common_item_ledger_guid_prediction_status
                                            ? " input-bg-green "
                                            : "")
                                        }
                                        classNamePrefix="Select type-select "
                                        name="purchase_ledger"
                                        options={this.state.ledger_options}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        value={this.state.ledger_options.filter(
                                          (option) =>
                                            option.value ==
                                            this.state.common_item_ledger_guid
                                        )}
                                        onChange={(option) => {
                                          let ledger =
                                            this.props.ledger_data.ledger_list.filter(
                                              (ledger) =>
                                                ledger.guid == option.value
                                            );

                                          this.setState({
                                            common_item_ledger_guid:
                                              ledger[0].guid,
                                            common_item_ledger_id: ledger[0].id,
                                            common_item_ledger_name:
                                              ledger[0].name,
                                            common_item_ledger_guid_prediction_status: 0,
                                            common_item_ledger_id_prediction_status: 0,
                                            common_item_ledger_name_prediction_status: 0,
                                            is_dirty: true,
                                          });
                                        }}
                                        isDisabled={
                                          this.props.VIEW_ONLY_SNAPSHOT ||
                                          !this.props.editable
                                        }
                                      />
                                      {this.state.errors
                                        .common_item_ledger_guid && (
                                          <small className="error_right">
                                            {
                                              this.state.errors
                                                .common_item_ledger_guid
                                            }
                                          </small>
                                        )}
                                    </Form>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                        {/* <div className="inventory-filter"> */}
                        <div className="row">
                          <div className="col-xl-4 col-lg-6">
                            <div className="field-border">
                              <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                  <div className="form-group">
                                    <lable className="lable-input">CGST</lable>
                                    <CurrencyInput
                                      type="text"
                                      className={
                                        "form-control text-right" +
                                        (this.props
                                          .line_items_prediction_status &&
                                          this.state.cgst_amount_prediction_status
                                          ? " input-bg-green "
                                          : "")
                                      }
                                      name="cgst_amount"
                                      value={this.state.cgst_amount}
                                      allowNegativeValue={false}
                                      autoComplete="off"
                                      onBlur={() => {
                                        this.setState({cgst_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.cgst_amount) : this.state.cgst_amount})
                                        this.calculateTotalGst();
                                      }}
                                      disabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable ||
                                        this.state.is_igst
                                      }
                                      decimalsLimit={2}
                                      prefix="&#8377; "
                                      placeholder="&#8377; 0"
                                      onValueChange={(value, name) =>
                                        this.amountChange(name, value)
                                      }
                                    />
                                    {this.state.errors.cgst_amount && (
                                      <small className="error_right">
                                        {this.state.errors.cgst_amount}
                                      </small>
                                    )}
                                    {this.state.errors.same_amount && (
                                      <small className="error_right">
                                        {this.state.errors.same_amount}
                                      </small>
                                    )}
                                  </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                  <div className="form-group">
                                    <lable className="lable-input">SGST</lable>
                                    <CurrencyInput
                                      type="text"
                                      className={
                                        "form-control text-right" +
                                        (this.props
                                          .line_items_prediction_status &&
                                          this.state.sgst_amount_prediction_status
                                          ? " input-bg-green "
                                          : "")
                                      }
                                      value={this.state.sgst_amount}
                                      allowNegativeValue={false}
                                      decimalsLimit={2}
                                      prefix="&#8377; "
                                      placeholder="&#8377; 0"
                                      onValueChange={(value, name) =>
                                        this.amountChange(name, value)
                                      }
                                      is_amount={1}
                                      onBlur={() => {
                                        this.setState({sgst_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.sgst_amount) : this.state.sgst_amount})
                                        this.calculateTotalGst();
                                      }}
                                      disabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable ||
                                        this.state.is_igst
                                      }
                                      name="sgst_amount"
                                    />
                                    {this.state.errors.sgst_amount && (
                                      <small className="error_right">
                                        {this.state.errors.sgst_amount}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-2 col-lg-3">
                            <div className="form-group ">
                              <div className="field-border">
                                {/* <label class="lable-input cpointer d-inline-flex align-items-center" disabled={this.props.VIEW_ONLY_SNAPSHOT || !this.props.editable || this.state.disable_igst}> */}
                                <label
                                  class="lable-input cpointer d-flex "
                                  disabled={
                                    this.props.VIEW_ONLY_SNAPSHOT ||
                                    !this.props.editable ||
                                    this.state.disable_igst
                                  }
                                >
                                  {/* <div className="sup-cust-check lable-check-box "> */}
                                  <div className="sup-cust-check ">
                                    <input
                                      className="styled-checkbox"
                                      // id="styled-checkbox-igst"
                                      type="checkbox"
                                      name="is_igst"
                                      checked={this.state.is_igst}
                                      disabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable ||
                                        this.state.disable_igst
                                      }
                                      onChange={(e) => {
                                        const target = e.target;
                                        const value = target.checked;
                                        this.setState(
                                          { is_igst: value, is_dirty: true },
                                          () => {
                                            if (value) {
                                              this.setState(
                                                {
                                                  cgst_amount: "",
                                                  sgst_amount: "",
                                                },
                                                () => {
                                                  this.calculateTotalGst();
                                                }
                                              );
                                            } else {
                                              this.setState(
                                                { igst_amount: "" },
                                                () => {
                                                  this.calculateTotalGst();
                                                }
                                              );
                                            }
                                          }
                                        );
                                      }}
                                    />
                                    {/* <label class="lable-input" for="styled-checkbox-igst"
																			className={((this.props.VIEW_ONLY_SNAPSHOT || !this.props.editable || this.state.disable_igst) ? "disable-before-opacity" : " ")}
																		></label> */}
                                    IGST
                                  </div>
                                </label>
                                <CurrencyInput
                                  type="text"
                                  // disabled={(!!this.state.cgst_amount || !!this.state.sgst_amount) ? "disabled" : ""}
                                  className={
                                    "form-control text-right" +
                                    (this.props.line_items_prediction_status &&
                                      this.state.igst_amount_prediction_status
                                      ? " input-bg-green "
                                      : "")
                                  }
                                  value={this.state.igst_amount}
                                  allowNegativeValue={false}
                                  decimalsLimit={2}
                                  prefix="&#8377; "
                                  placeholder="&#8377; 0"
                                  onValueChange={(value, name) =>
                                    this.amountChange(name, value)
                                  }
                                  onBlur={() => {
                                    this.setState({igst_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.igst_amount) : this.state.igst_amount})
                                    this.calculateTotalGst();
                                    //this.checkForReadyState()
                                  }}
                                  disabled={
                                    this.props.VIEW_ONLY_SNAPSHOT ||
                                    !this.props.editable ||
                                    !this.state.is_igst
                                  }
                                  name="igst_amount"
                                />
                                {this.state.errors.igst_amount && (
                                  <small className="error_right">
                                    {this.state.errors.igst_amount}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-2 col-lg-3">
                            <div className="form-group">
                              <lable className="lable-input">GST Total</lable>
                              {/* <input type="text" className="form-control green-white-bgt" name="invoice_id" value="₹ 96,642" /> */}
                              <CurrencyInput
                                type="text"
                                className="form-control text-right"
                                value={this.state.gst_total_amount}
                                allowNegativeValue={false}
                                decimalsLimit={2}
                                prefix="&#8377; "
                                placeholder="&#8377; 0"
                                disabled={true}
                                name="gst_total_amount"
                              />
                              {this.state.errors.gst_total_amount && (
                                  <small className="error_right">
                                    {this.state.errors.gst_total_amount}
                                  </small>
                                )}
                            </div>
                          </div>

                          <div className="col-xl-1 col-lg-3">
                            <div className="form-group mb-xl-0 mb-lg-2">
                              <lable className="lable-input">
                                GST Eligible
                              </lable>
                              <select
                                className="form-control"
                                name="is_igst_eligible"
                                value={
                                  this.state.is_gst_claim_eligible ? "1" : "0"
                                }
                                onChange={(e) => {
                                  const target = e.target;
                                  const value = target.value;
                                  this.setState({
                                    is_gst_claim_eligible: parseInt(value),
                                    is_dirty: true,
                                  });
                                }}
                                disabled={
                                  this.props.VIEW_ONLY_SNAPSHOT ||
                                  !this.props.editable
                                }
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-1 col-lg-3">
                            <div className="form-group mb-xl-0 mb-lg-2">
                              <lable className="lable-input">
                                GST Override
                              </lable>
                              <select
                                className="form-control"
                                name="is_gst_override"
                                value={this.state.is_gst_override ? "1" : "0"}
                                onChange={this.handleChange}
                                disabled={
                                  this.props.VIEW_ONLY_SNAPSHOT ||
                                  !this.props.editable ||
                                  this.props.hide_gst_override
                                }
                              >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}

                        {/* Ledger Table */}
                        <div className="row">
                          <div className="col-12">
                            {/* <table className="table borderTable mb-0"> */}
                            <div className="main-invoice-item-new inventory-filter mt-0 p-2">
                              <div className="table-responsive overflowx-xl-inherit">
                                {/* <div className="table-responsive"> */}
                                <table className="table ledger-table invent-table mb-0">
                                  <thead>
                                    <tr>
                                      {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.inventory_voucher_status ==
                                        true && (
                                          <th
                                            style={{
                                              minWidth: "180px",
                                              maxWidth: "180px",
                                            }}
                                          >
                                            Item Ledger
                                            <span className="red-star">*</span>
                                          </th>
                                        )}
                                      <th
                                        style={{
                                          minWidth: "220px",
                                          maxWidth: "220px",
                                        }}
                                      >
                                        Item<span className="red-star">*</span>
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "100px",
                                          width: "100px",
                                        }}
                                        className="text-left"
                                      >
                                        HSN/SAC
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "80px",
                                          width: "80px",
                                        }}
                                        className="text-center"
                                      >
                                        Quantity
                                        <span className="red-star">*</span>
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "80px",
                                          width: "80px",
                                        }}
                                        className="text-right"
                                      >
                                        Rate<span className="red-star">*</span>
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "80px",
                                          width: "80px",
                                        }}
                                        className="text-center"
                                      >
                                        Per<span className="red-star">*</span>
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "80px",
                                          width: "80px",
                                        }}
                                        className="text-right"
                                      >
                                        GST %
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "80px",
                                          width: "80px",
                                        }}
                                        className="text-right"
                                      >
                                        Disc %
                                      </th>
                                      <th
                                        style={{
                                          minWidth: "180px",
                                          maxWidth: "180px",
                                        }}
                                        className=" text-right"
                                      >
                                        Amount
                                        <span className="red-star">*</span>
                                      </th>
                                      {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.is_common_item_ledger == 0 &&
                                        this.state.inventory_voucher_status ==
                                        true && (
                                          <th
                                            style={{
                                              minWidth: "160px",
                                              maxWidth: "160px",
                                            }}
                                          >
                                            {this.props.type == 1
                                              ? "Sales Ledger"
                                              : "Purchase Ledger"}
                                            <span className="red-star">*</span>
                                          </th>
                                        )}
                                      <th
                                        style={{
                                          minWidth: "20px",
                                          maxWidth: "20px",
                                        }}
                                      ></th>
                                    </tr>
                                  </thead>
                                  {/* <tbody className='d-none'> */}
                                  <tbody>
                                    {this.state.line_items.map(
                                      (item, index) => {
                                        return (
                                          <tr>
                                            {(this.props
                                              .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                              this.props.VIEW_ONLY_SNAPSHOT) &&
                                              this.state
                                                .inventory_voucher_status ==
                                              true && (
                                                <td className="">
                                                  <div className="form-group">
                                                    <Form>
                                                      <ReactSelect
                                                        className={
                                                          "Select-Search typeed-dd" +
                                                          (this.state
                                                            .line_item_errors[
                                                            index
                                                          ] &&
                                                            this.state
                                                              .line_item_errors[
                                                              index
                                                            ].item_ledger_guid
                                                            ? " error-border "
                                                            : "") +
                                                          (this.props
                                                            .line_items_prediction_status &&
                                                            this.state.line_items[
                                                              index
                                                            ]
                                                              .item_ledger_guid_prediction_status
                                                            ? " input-bg-green "
                                                            : "")
                                                        }
                                                        classNamePrefix="Select type-select "
                                                        name="item_ledger_guid"
                                                        options={
                                                          item_ledger_list
                                                        }
                                                        onChange={(e) => {
                                                          this.updateLineItemData(
                                                            e,
                                                            "item_ledger_guid",
                                                            index
                                                          );
                                                        }}
                                                        value={item_ledger_list.filter(
                                                          (option) =>
                                                            option.value ==
                                                            this.state
                                                              .line_items[index]
                                                              .item_ledger_guid
                                                        )}
                                                        isDisabled={
                                                          this.props
                                                            .VIEW_ONLY_SNAPSHOT ||
                                                          !this.props.editable
                                                        }
                                                      />
                                                    </Form>
                                                  </div>
                                                </td>
                                              )}

                                            <td className="">
                                              <div className="form-group">
                                                <Form>
                                                  {this.state.line_items[index]
                                                    .is_edit ? (
                                                    <div className="form-group fieldp-icon">
                                                      <input
                                                        type="text"
                                                        className={
                                                          "form-control w-100 mr-auto " +
                                                          (this.state
                                                            .line_item_errors[
                                                            index
                                                          ] &&
                                                            this.state
                                                              .line_item_errors[
                                                              index
                                                            ].line_item_name
                                                            ? " item-error-field "
                                                            : "")
                                                        }
                                                        name="hsn_sac"
                                                        value={
                                                          this.state.line_items[
                                                            index
                                                          ].line_item_name
                                                        }
                                                        onChange={(e) => {
                                                          let line_items = [
                                                            ...this.state
                                                              .line_items,
                                                          ];
                                                          line_items[
                                                            index
                                                          ].line_item_name =
                                                            e.target.value;
                                                          line_items[
                                                            index
                                                          ].line_item_update = 1;
                                                          line_items[
                                                            index
                                                          ].line_item_name_prediction_status = 0;
                                                          this.setState({
                                                            line_items,
                                                            is_dirty: true,
                                                          });
                                                        }}
                                                        disabled={
                                                          this.props
                                                            .VIEW_ONLY_SNAPSHOT ||
                                                          !this.props.editable
                                                        }
                                                      />
                                                      <span
                                                        className="dbox-tble-icon fieldp-icon-bg"
                                                        onClick={() => {
                                                          let line_items =
                                                            this.state
                                                              .line_items;
                                                          line_items[
                                                            index
                                                          ].is_edit = false;

                                                          line_items.forEach(
                                                            (item, i) => {
                                                              if (
                                                                this.state
                                                                  .line_items[
                                                                  index
                                                                ]
                                                                  .line_item_id ==
                                                                item.line_item_id
                                                              ) {
                                                                line_items[
                                                                  i
                                                                ].line_item_name =
                                                                  this.state.line_items[
                                                                    index
                                                                  ].line_item_name;
                                                                line_items[
                                                                  i
                                                                ].line_item_update = 1;
                                                              }
                                                            }
                                                          );

                                                          const newItems =
                                                            this.state.line_items_list.map(
                                                              (item, i) => {
                                                                if (
                                                                  item.id ==
                                                                  this.state
                                                                    .line_items[
                                                                    index
                                                                  ].line_item_id
                                                                ) {
                                                                  return {
                                                                    ...item,
                                                                    name: this
                                                                      .state
                                                                      .line_items[
                                                                      index
                                                                    ]
                                                                      .line_item_name,
                                                                  };
                                                                } else {
                                                                  return item;
                                                                }
                                                              }
                                                            );

                                                          this.setState({
                                                            line_items,
                                                            line_items_list:
                                                              newItems,
                                                          });
                                                        }}
                                                      >
                                                        <OverlayTrigger
                                                          overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                              Done
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span className="icon-check white-icon"></span>
                                                        </OverlayTrigger>
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div className="fieldp-icon">
                                                      <Creatable
                                                        className={
                                                          "Select-Search typeed-dd w-100 mr-auto " +
                                                          (this.state
                                                            .line_item_errors[
                                                            index
                                                          ] &&
                                                            this.state
                                                              .line_item_errors[
                                                              index
                                                            ].line_item_name
                                                            ? " error-border"
                                                            : "") +
                                                          (this.props
                                                            .line_items_prediction_status &&
                                                            this.state.line_items[
                                                              index
                                                            ]
                                                              .line_item_name_prediction_status
                                                            ? " input-bg-green "
                                                            : "")
                                                        }
                                                        classNamePrefix="Select type-select "
                                                        name="line_item_id"
                                                        options={
                                                          this.state.line_items[
                                                            index
                                                          ].item_ledger_guid
                                                            ? this.state.line_items_list
                                                              .filter(
                                                                (item) =>
                                                                  item.item_ledger_guid ==
                                                                  this.state
                                                                    .line_items[
                                                                    index
                                                                  ]
                                                                    .item_ledger_guid
                                                              )
                                                              .map((item) => {
                                                                return {
                                                                  value:
                                                                    item.id,
                                                                  label:
                                                                    item.name,
                                                                };
                                                              })
                                                            : line_items_list
                                                        }
                                                        onChange={(e) => {
                                                          this.updateLineItemData(
                                                            e,
                                                            "line_item_id",
                                                            index
                                                          );
                                                        }}
                                                        value={{
                                                          value:
                                                            this.state
                                                              .line_items[index]
                                                              .line_item_id,
                                                          label:
                                                            this.state
                                                              .line_items[index]
                                                              .line_item_name,
                                                        }}
                                                        isDisabled={
                                                          this.props
                                                            .VIEW_ONLY_SNAPSHOT ||
                                                          !this.props.editable
                                                        }
                                                        onCreateOption={(
                                                          val
                                                        ) => {
                                                          let line_items = [
                                                            ...this.state
                                                              .line_items,
                                                          ];
                                                          line_items[
                                                            index
                                                          ].line_item_name = val;
                                                          line_items[
                                                            index
                                                          ].line_item_id = 0;
                                                          line_items[
                                                            index
                                                          ].line_item_update = 1;

                                                          let line_items_list =
                                                            [
                                                              ...this.state
                                                                .line_items_list,
                                                            ];
                                                          line_items_list.push({
                                                            name: val,
                                                            id: 0,
                                                          });

                                                          this.setState({
                                                            line_items,
                                                            line_items_list,
                                                          });
                                                          line_items[
                                                            index
                                                          ].line_item_name_prediction_status = 0;

                                                          this.setState({
                                                            line_items,
                                                            is_dirty: true,
                                                          });
                                                        }}
                                                      />
                                                      {this.state.line_items[
                                                        index
                                                      ].line_item_id &&
                                                        this.props.editable ==
                                                        true ? (
                                                        <span
                                                          className={
                                                            "dbox-tble-icon fieldp-icon-bg" +
                                                            (this.props
                                                              .VIEW_ONLY_SNAPSHOT ||
                                                              !this.props.editable
                                                              ? "disable-opacity"
                                                              : "")
                                                          }
                                                          onClick={() => {
                                                            let line_items = [
                                                              ...this.state
                                                                .line_items,
                                                            ];
                                                            line_items[
                                                              index
                                                            ].is_edit = true;
                                                            this.setState({
                                                              line_items,
                                                            });
                                                          }}
                                                        >
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip id="tooltip-disabled">
                                                                Edit
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <span className="icon-Edit white-icon"></span>
                                                          </OverlayTrigger>
                                                        </span>
                                                      ) : null}
                                                    </div>
                                                  )}
                                                </Form>
                                              </div>
                                            </td>
                                            <td className=" text-center">
                                              <div
                                                className={
                                                  "form-group" +
                                                  this.showWarningStyle(
                                                    index,
                                                    "hsn_sac"
                                                  )
                                                }
                                              >
                                                <div class="a-tooltip">
                                                  <input
                                                    type="text"
                                                    className={
                                                      "form-control text-left" +
                                                      this.showWarningStyle(
                                                        index,
                                                        "hsn_sac"
                                                      ) +
                                                      (this.props
                                                        .line_items_prediction_status &&
                                                        this.state.line_items[
                                                          index
                                                        ]
                                                          .hsn_sac_prediction_status
                                                        ? " input-bg-green "
                                                        : "")
                                                    }
                                                    name="hsn_sac"
                                                    value={
                                                      this.state.line_items[
                                                        index
                                                      ].hsn_sac
                                                    }
                                                    onChange={(e) =>
                                                      this.updateLineItemData(
                                                        e,
                                                        "hsn_sac",
                                                        index
                                                      )
                                                    }
                                                    id={"lineitem" + index}
                                                    disabled={
                                                      this.props
                                                        .VIEW_ONLY_SNAPSHOT ||
                                                      !this.props.editable
                                                    }
                                                  />
                                                  {(this.state.line_items[index]
                                                    .hsn_sac_warning == true ||
                                                    this.state.line_items[index]
                                                      .hsn_sac_warning_mismatch ==
                                                    true) && (
                                                      <span class="a-tooltiptext">
                                                        {this.state.line_items[
                                                          index
                                                        ].hsn_sac_warning
                                                          ? "HSN data not available in Item Ledger"
                                                          : "HSN data mismatched with Item Ledger data"}
                                                      </span>
                                                    )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className=" text-center">
                                              <div className="form-group">
                                                <input
                                                  type="input"
                                                  className={
                                                    "form-control form-control text-center " +
                                                    (this.state
                                                      .line_item_errors[
                                                      index
                                                    ] &&
                                                      this.state.line_item_errors[
                                                        index
                                                      ].quantity
                                                      ? " item-error-field "
                                                      : "") +
                                                    (this.props
                                                      .line_items_prediction_status &&
                                                      this.state.line_items[index]
                                                        .quantity_prediction_status
                                                      ? " input-bg-green "
                                                      : "")
                                                  }
                                                  name="quantity"
                                                  value={
                                                    this.state.line_items[index]
                                                      .quantity
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value < 0 ||
                                                      isNaN(e.target.value)
                                                    ) {
                                                      return;
                                                    } else {
                                                      this.updateLineItemData(
                                                        e,
                                                        "quantity",
                                                        index
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  //min="0"
                                                  onBlur={() =>
                                                    this.calculateLineItemAmount(
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td className=" text-right">
                                              <div className="form-group">
                                                <CurrencyInput
                                                  type="text"
                                                  className={
                                                    "form-control text-right" +
                                                    (this.state
                                                      .line_item_errors[
                                                      index
                                                    ] &&
                                                      this.state.line_item_errors[
                                                        index
                                                      ].rate
                                                      ? " item-error-field "
                                                      : "") +
                                                    (this.props
                                                      .line_items_prediction_status &&
                                                      this.state.line_items[index]
                                                        .rate_prediction_status
                                                      ? " input-bg-green "
                                                      : "")
                                                  }
                                                  name="rate"
                                                  value={
                                                    this.state.line_items[index]
                                                      .rate
                                                  }
                                                  allowNegativeValue={false}
                                                  autoComplete="off"
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  decimalsLimit={2}
                                                  prefix="&#8377; "
                                                  placeholder="&#8377; 0"
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) => {
                                                    let line_items = [
                                                      ...this.state.line_items,
                                                    ];
                                                    line_items[index][name] =
                                                      value;
                                                    line_items[
                                                      index
                                                    ].rate_prediction_status = 0;
                                                    line_items[
                                                      index
                                                    ].amount_prediction_status = 0;
                                                    this.setState({
                                                      line_items,
                                                      is_dirty: true,
                                                    });
                                                  }}
                                                  onBlur={() =>
                                                    this.calculateLineItemAmount(
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td className=" text-center">
                                              <div
                                                className={
                                                  "form-group" +
                                                  this.showWarningStyle(
                                                    index,
                                                    "per"
                                                  )
                                                }
                                              >
                                                <div class="a-tooltip">
                                                  <input
                                                    type="text"
                                                    className={
                                                      "form-control text-center" +
                                                      (this.state
                                                        .line_item_errors[
                                                        index
                                                      ] &&
                                                        this.state
                                                          .line_item_errors[index]
                                                          .per
                                                        ? " item-error-field "
                                                        : "") +
                                                      this.showWarningStyle(
                                                        index,
                                                        "per"
                                                      ) +
                                                      (this.props
                                                        .line_items_prediction_status &&
                                                        this.state.line_items[
                                                          index
                                                        ].per_prediction_status
                                                        ? " input-bg-green "
                                                        : "")
                                                    }
                                                    name={`per${index}`}
                                                    key={`per${index}`}
                                                    value={
                                                      this.state.line_items[
                                                        index
                                                      ].per
                                                    }
                                                    onChange={(e) =>
                                                      this.updateLineItemData(
                                                        e,
                                                        "per",
                                                        index
                                                      )
                                                    }
                                                    disabled={
                                                      this.props
                                                        .VIEW_ONLY_SNAPSHOT ||
                                                      !this.props.editable
                                                    }
                                                  />
                                                  {(this.state.line_items[index]
                                                    .per_warning == true ||
                                                    this.state.line_items[index]
                                                      .per_warning_mismatch ==
                                                    true) && (
                                                      <span class="a-tooltiptext">
                                                        {this.state.line_items[
                                                          index
                                                        ].per_warning
                                                          ? "Per data not available in Item Ledger"
                                                          : "Per data mismatched with Item Ledger data"}
                                                      </span>
                                                    )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className=" text-right">
                                              <div
                                                className={
                                                  "form-group" +
                                                  this.showWarningStyle(
                                                    index,
                                                    "gst_rate"
                                                  )
                                                }
                                              >
                                                <div class="a-tooltip">
                                                  <CurrencyInput
                                                    type="text"
                                                    className={
                                                      "form-control text-right" +
                                                      this.showWarningStyle(
                                                        index,
                                                        "gst_rate"
                                                      ) +
                                                      (this.props
                                                        .line_items_prediction_status &&
                                                        this.state.line_items[
                                                          index
                                                        ]
                                                          .gst_rate_prediction_status
                                                        ? " input-bg-green "
                                                        : "")
                                                    }
                                                    name="gst_rate"
                                                    value={
                                                      this.state.line_items[
                                                        index
                                                      ].gst_rate
                                                    }
                                                    allowNegativeValue={false}
                                                    decimalsLimit={2}
                                                    onValueChange={(
                                                      value,
                                                      name
                                                    ) => {
                                                      if (value > 100) {
                                                        return;
                                                      } else {
                                                        let line_items = [
                                                          ...this.state
                                                            .line_items,
                                                        ];
                                                        line_items[index][
                                                          name
                                                        ] = value;
                                                        line_items[
                                                          index
                                                        ].gst_rate_prediction_status = 0;
                                                        this.setState({
                                                          line_items,
                                                          is_dirty: true,
                                                        });
                                                      }
                                                    }}
                                                    is_amount={1}
                                                    disabled={
                                                      this.props
                                                        .VIEW_ONLY_SNAPSHOT ||
                                                      !this.props.editable
                                                    }
                                                  />
                                                  {(this.state.line_items[index]
                                                    .gst_rate_warning == true ||
                                                    this.state.line_items[index]
                                                      .gst_rate_warning_mismatch ==
                                                    true) && (
                                                      <span class="a-tooltiptext">
                                                        {this.state.line_items[
                                                          index
                                                        ].gst_rate_warning
                                                          ? "GST% data not available in Item Ledger"
                                                          : "GST% data mismatched with Item Ledger data"}
                                                      </span>
                                                    )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className=" text-right">
                                              <div className="form-group">
                                                <CurrencyInput
                                                  type="text"
                                                  className={
                                                    "form-control text-right" +
                                                    (this.props
                                                      .line_items_prediction_status &&
                                                      this.state.line_items[index]
                                                        .discount_rate_prediction_status
                                                      ? " input-bg-green "
                                                      : "")
                                                  }
                                                  name="discount_rate"
                                                  value={
                                                    this.state.line_items[index]
                                                      .discount_rate
                                                  }
                                                  allowNegativeValue={false}
                                                  decimalsLimit={2}
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) => {
                                                    if (value > 100) {
                                                      return;
                                                    } else {
                                                      let line_items = [
                                                        ...this.state
                                                          .line_items,
                                                      ];
                                                      line_items[index][name] =
                                                        value;
                                                      line_items[
                                                        index
                                                      ].discount_rate_prediction_status = 0;
                                                      line_items[
                                                        index
                                                      ].amount_prediction_status = 0;
                                                      this.setState({
                                                        line_items,
                                                        is_dirty: true,
                                                      });
                                                    }
                                                  }}
                                                  is_amount={1}
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  onBlur={() =>
                                                    this.calculateLineItemAmount(
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>

                                            <td className=" text-right">
                                              <div className="form-group">
                                                <CurrencyInput
                                                  type="text"
                                                  className={
                                                    "form-control text-right" +
                                                    (this.state
                                                      .line_item_errors[
                                                      index
                                                    ] &&
                                                      this.state.line_item_errors[
                                                        index
                                                      ].amount
                                                      ? " item-error-field "
                                                      : "") +
                                                    (this.props
                                                      .line_items_prediction_status &&
                                                      this.state.line_items[index]
                                                        .amount_prediction_status
                                                      ? " input-bg-green "
                                                      : "")
                                                  }
                                                  name="amount"
                                                  value={
                                                    this.state.line_items[index]
                                                      .amount
                                                  }
                                                  allowNegativeValue={false}
                                                  autoComplete="off"
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  decimalsLimit={2}
                                                  prefix="&#8377; "
                                                  placeholder="&#8377; 0"
                                                />
                                              </div>
                                            </td>
                                            {(this.props
                                              .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                              this.props.VIEW_ONLY_SNAPSHOT) &&
                                              this.state
                                                .is_common_item_ledger == 0 &&
                                              this.state
                                                .inventory_voucher_status ==
                                              true && (
                                                <td className="">
                                                  <div className="form-group">
                                                    <Form>
                                                      <ReactSelect
                                                        className={
                                                          "Select-Search typeed-dd" +
                                                          (this.state
                                                            .line_item_errors[
                                                            index
                                                          ] &&
                                                            this.state
                                                              .line_item_errors[
                                                              index
                                                            ]
                                                              .common_item_ledger_guid
                                                            ? " error-border "
                                                            : "") +
                                                          (this.props
                                                            .line_items_prediction_status &&
                                                            this.state.line_items[
                                                              index
                                                            ]
                                                              .common_item_ledger_guid_prediction_status
                                                            ? " input-bg-green "
                                                            : "")
                                                        }
                                                        classNamePrefix="Select type-select "
                                                        name="common_item_ledger_guid"
                                                        options={
                                                          this.state
                                                            .ledger_options
                                                        }
                                                        onChange={(e) => {
                                                          this.updateLineItemData(
                                                            e,
                                                            "common_item_ledger_guid",
                                                            index
                                                          );
                                                        }}
                                                        value={this.state.ledger_options.filter(
                                                          (option) =>
                                                            option.value ==
                                                            this.state
                                                              .line_items[index]
                                                              .common_item_ledger_guid
                                                        )}
                                                        isDisabled={
                                                          this.props
                                                            .VIEW_ONLY_SNAPSHOT ||
                                                          !this.props.editable
                                                        }
                                                      />
                                                    </Form>
                                                  </div>
                                                </td>
                                              )}
                                            {this.props.VIEW_ONLY_SNAPSHOT ||
                                              !this.props.editable ? null : (
                                              <td className="text-right croslt ">
                                                <span
                                                  onClick={() =>
                                                    this.removeLineItem(
                                                      index,
                                                      this.state.line_items[
                                                        index
                                                      ].id || null
                                                    )
                                                  }
                                                  className="icon-Cross-with-grey-circle size-xx cpointer"
                                                >
                                                  <span className="path1"></span>
                                                  <span className="path2 cpointer"></span>
                                                  <span className="path3"></span>
                                                </span>
                                              </td>
                                            )}
                                          </tr>
                                        );
                                      }
                                    )}

                                    {/* <tr className="gray-dark-bg"> */}
                                    <tr>
                                      <td className=" va-middle d-flex align-items-center gap-10">
                                        {this.props.VIEW_ONLY_SNAPSHOT ||
                                          !this.props.editable ? null : (
                                          <button
                                            onClick={() =>
                                              this.addNewLineItem()
                                            }
                                            class="ledger-add-row"
                                          >
                                            <span class="icon-plus-circled"></span>
                                          </button>
                                        )}
                                        {this.state.errors.line_item_err && (
                                          <span class="red-text error_right mb-0">
                                            Please fill atleast one Line Item
                                          </span>
                                        )}
                                      </td>

                                      {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.inventory_voucher_status ==
                                        true && <td></td>}

                                      <td></td>
                                      <td></td>
                                      <td></td>

                                      <td className="ledger-total text-center">
                                        Total (₹)
                                      </td>
                                      <td className="text-right">
                                        <CurrencyInput
                                          type="text"
                                          className="form-control text-right green-white-bgt"
                                          value={this.calculateTotalLineItemGST()}
                                          allowNegativeValue={false}
                                          autoComplete="off"
                                          disabled={true}
                                          decimalsLimit={2}
                                          prefix="&#8377; "
                                          placeholder="&#8377; 0"
                                        />
                                      </td>
                                      <td className="text-right">
                                        <CurrencyInput
                                          type="text"
                                          className="form-control text-right green-white-bgt"
                                          value={this.calculateTotalLineItemDisc()}
                                          allowNegativeValue={false}
                                          autoComplete="off"
                                          disabled={true}
                                          decimalsLimit={2}
                                          prefix="&#8377; "
                                          placeholder="&#8377; 0"
                                        />
                                      </td>
                                      <td className="text-right">
                                        <CurrencyInput
                                          type="text"
                                          className="form-control text-right green-white-bgt"
                                          value={this.calculateTotalLineItemAmt()}
                                          allowNegativeValue={false}
                                          autoComplete="off"
                                          disabled={true}
                                          decimalsLimit={2}
                                          prefix="&#8377; "
                                          placeholder="&#8377; 0"
                                        />
                                      </td>
                                      {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.is_common_item_ledger == 0 &&
                                        this.state.inventory_voucher_status ==
                                        true && <td></td>}
                                      <td></td>
                                    </tr>

                                    <tr className="separator-border">
                                      {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.inventory_voucher_status ==
                                        true && <td></td>}
                                      <td colSpan={9}></td>
                                      {(this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                        this.state.is_common_item_ledger == 0 &&
                                        this.state.inventory_voucher_status ==
                                        true && <td></td>}
                                    </tr>

                                    {this.state.inventory_voucher_status ==
                                      true &&
                                      (this.props
                                        .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                        this.props.VIEW_ONLY_SNAPSHOT) &&
                                      this.state.ledgers.map(
                                        (ledger, index) => {
                                          return (
                                            <tr className="ledger-second-table">
                                              <td className="">
                                                <Form className="form-group">
                                                  <ReactSelect
                                                    //className="Select-Search typeed-dd"
                                                    className={
                                                      "Select-Search typeed-dd" +
                                                      (this.state.ledger_errors[
                                                        index
                                                      ] &&
                                                        this.state.ledger_errors[
                                                          index
                                                        ].guid
                                                        ? " error-border "
                                                        : "")
                                                    }
                                                    classNamePrefix="Select type-select "
                                                    name="guid"
                                                    options={
                                                      this.state.ledger_options
                                                    }
                                                    //onBlur={() => this.calculateLedgerAmount(index)}
                                                    onChange={(e) => {
                                                      let ledger_data =
                                                        this.props.ledger_data.ledger_list.filter(
                                                          (ledger) =>
                                                            ledger.guid ==
                                                            e.value
                                                        );

                                                      let ledgers = [
                                                        ...this.state.ledgers,
                                                      ];
                                                      ledgers[index].guid =
                                                        ledger_data[0].guid;
                                                      ledgers[index].name =
                                                        ledger_data[0].name;
                                                      ledgers[index].ledger_id =
                                                        ledger_data[0].id;

                                                      if (!ledgers[index].rate)
                                                        ledgers[index].rate =
                                                          ledger_data[0].rate_of_calc;

                                                      this.setState(
                                                        {
                                                          ledgers,
                                                          is_dirty: true,
                                                        },
                                                        () => {
                                                          this.calculateLedgerAmount(
                                                            index
                                                          );
                                                        }
                                                      );
                                                    }}
                                                    value={this.state.ledger_options.filter(
                                                      (option) =>
                                                        option.value ==
                                                        this.state.ledgers[
                                                          index
                                                        ].guid
                                                    )}
                                                    isDisabled={
                                                      this.props
                                                        .VIEW_ONLY_SNAPSHOT ||
                                                      !this.props.editable
                                                    }
                                                  />
                                                </Form>
                                              </td>
                                              <td className=""></td>
                                              <td className=""></td>
                                              <td className=""></td>
                                              <td className="">
                                                <CurrencyInput
                                                  type="text"
                                                  className="form-control text-right"
                                                  name="rate"
                                                  value={
                                                    this.state.ledgers[index]
                                                      .rate
                                                  }
                                                  allowNegativeValue={false}
                                                  autoComplete="off"
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  decimalsLimit={2}
                                                  //prefix="&#8377; "
                                                  //placeholder='&#8377; 0'
                                                  onBlur={() =>
                                                    this.calculateLedgerAmount(
                                                      index
                                                    )
                                                  }
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) => {
                                                    if (value > 100) {
                                                      return;
                                                    } else {
                                                      let ledgers = [
                                                        ...this.state.ledgers,
                                                      ];
                                                      ledgers[index][name] =
                                                        value;
                                                      this.setState({
                                                        ledgers,
                                                        is_dirty: true,
                                                      });
                                                    }
                                                  }}
                                                />
                                              </td>
                                              <td className=" text-center">
                                                <input
                                                  type="text"
                                                  className={
                                                    "form-control text-center"
                                                  }
                                                  name="per"
                                                  value="%"
                                                />
                                              </td>
                                              <td className=""></td>
                                              <td className=""></td>
                                              <td className=" text-right">
                                                <CurrencyInput
                                                  type="text"
                                                  //className="form-control text-right"
                                                  className={
                                                    "form-control text-right " +
                                                    (this.state.ledger_errors[
                                                      index
                                                    ] &&
                                                      this.state.ledger_errors[
                                                        index
                                                      ].amount
                                                      ? " item-error-field "
                                                      : "")
                                                  }
                                                  name="amount"
                                                  value={
                                                    this.state.ledgers[index]
                                                      .amount
                                                  }
                                                  allowNegativeValue={true}
                                                  autoComplete="off"
                                                  disabled={
                                                    this.props
                                                      .VIEW_ONLY_SNAPSHOT ||
                                                    !this.props.editable
                                                  }
                                                  decimalsLimit={2}
                                                  prefix="&#8377; "
                                                  placeholder="&#8377; 0"
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) => {
                                                    let ledgers = [
                                                      ...this.state.ledgers,
                                                    ];
                                                    ledgers[index][name] =
                                                      value;
                                                    this.setState({
                                                      ledgers,
                                                      is_dirty: true,
                                                    });
                                                  }}
                                                />
                                              </td>
                                              {(this.props
                                                .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                                this.props
                                                  .VIEW_ONLY_SNAPSHOT) &&
                                                this.state
                                                  .is_common_item_ledger ==
                                                0 && <td className=""></td>}
                                              <td className="text-right croslt">
                                                {this.props
                                                  .VIEW_ONLY_SNAPSHOT ||
                                                  !this.props.editable ? null : (
                                                  <span
                                                    onClick={() =>
                                                      this.removeAdditionalLedger(
                                                        index,
                                                        this.state.ledgers[
                                                          index
                                                        ].id
                                                      )
                                                    }
                                                    className="icon-Cross-with-grey-circle size-xx cpointer"
                                                  >
                                                    <span className="path1"></span>
                                                    <span className="path2 cpointer"></span>
                                                    <span className="path3"></span>
                                                  </span>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}

                                    {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                      this.props.VIEW_ONLY_SNAPSHOT) &&
                                      this.state.inventory_voucher_status ==
                                      true && (
                                        <tr>
                                          <td className=" va-middle">
                                            {this.props.VIEW_ONLY_SNAPSHOT ||
                                              !this.props.editable ? null : (
                                              <button
                                                onClick={() =>
                                                  this.addNewAdditionalLedger()
                                                }
                                                class="ledger-add-row"
                                              >
                                                <span class="icon-plus-circled"></span>
                                              </button>
                                            )}
                                          </td>
                                          {(this.props
                                            .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                            this.props.VIEW_ONLY_SNAPSHOT) &&
                                            this.state
                                              .inventory_voucher_status ==
                                            true && <td></td>}
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td className="ledger-total text-center">
                                            Total (₹)
                                          </td>
                                          <td className=" text-right">
                                            {/* <input type="text" class="form-control text-right green-white-bgt" name="zzz" value="₹ 5,36,000" /> */}
                                            <CurrencyInput
                                              type="text"
                                              className="form-control text-right green-white-bgt"
                                              value={this.calculateTotalLedgersAmt()}
                                              allowNegativeValue={false}
                                              autoComplete="off"
                                              disabled={true}
                                              decimalsLimit={2}
                                              prefix="&#8377; "
                                              placeholder="&#8377; 0"
                                            />
                                          </td>

                                          {(this.props
                                            .SNAPSHOT_LEDGER_ENTRIES_CODE ||
                                            this.props.VIEW_ONLY_SNAPSHOT) &&
                                            this.state.is_common_item_ledger ==
                                            0 &&
                                            this.state
                                              .inventory_voucher_status ==
                                            true && <td></td>}

                                          <td></td>
                                        </tr>
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Ledger Table */}

                        {/* <div className="inventory-filter"> */}
                        <div className="row">
                          <div className="col-xl-5 col-lg-6">
                            {/* <div className="inventory-filter d-inline-block"> */}
                            {/* <div className="inventory-filter d-flex align-items-center"> */}
                            <div className="inventory-filter ">
                              {/* <div className="row w-100"> */}
                                {/* <div className="col-xl-2 col-sm-3">
                                  <div className="form-group">
                                    <lable className="lable-input">TDS</lable>
                                    <select
                                      className="form-control"
                                      name="is_tds"
                                      value={this.state.tds_status ? 1 : 0}
                                      onChange={(e) => {
                                        const target = e.target;
                                        const value = target.value;

                                        let tds_details =
                                          this.state.tds_details;
                                        let tds_amount = this.state.tds_amount;

                                        if (target.value == 0) {
											                  tds_details = [];
                                          tds_amount = "";
                                        }
                                        this.setState({
                                          tds_status: parseInt(value),
                                          is_dirty: true,
                                          tds_details,
                                          tds_amount,
                                        });
                                      }}
                                      disabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                    >
                                      <option value={1}>Yes</option>
                                      <option value={0}>No</option>
                                    </select>
                                  </div>
                                </div> */}

                                {/* {this.state.tds_status == 1  || this.props.tds_status? (  */}
                                {this.state.tds_details.length > 0 ? (
                                  <>
                                    {/* New Design TDS */}
                                    {/* <div className="table-responsive overflowx-xl-inherit"> */}
                                    <div className="table-responsive main-invoice-item-new mt-0">
                                      <table className="ledger-table table mb-0">
                                        <thead>
                                          <tr>
                                            <th className="text-right">
                                              TDS Base Amount
                                            </th>
                                            <th className="text-right w-25">
                                              TDS %
                                              {/* <span className="red-star">
                                                *
                                              </span> */}
                                            </th>
                                            <th className="text-right">
                                              TDS Amount
                                              {/* <span className="red-star">
                                                *
                                              </span> */}
                                            </th>
                                            {this.state.tds_details.length == 1 && <th></th>}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.tds_details.map(
                                            (tds, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td>
                                                      {/* <input
                                                        type="text"
                                                        className={
                                                          "form-control form-text-right"
                                                        }
                                                        value="₹ 123"
                                                        prefix="&#8377; "
                                                        placeholder="&#8377; 0"
                                                        name=""
                                                      /> */}
                                                      <CurrencyInput
                                                        type="text"
                                                        className={"form-control form-text-right"}
                                                        value={tds.base_amount}
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}
                                                        prefix="&#8377; "
                                                        placeholder="&#8377; 0"
                                                        onValueChange={(value, name) =>
                                                          this.amountChange(name, value, index)
                                                        }
                                                        onBlur={() => {
                                                          let tds_arr = [...this.state.tds_details];
                                                          tds_arr[index].base_amount =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds.base_amount):tds.base_amount;
                                                          this.setState({ tds_details: tds_arr},()=>{
                                                              this.calculateTDS(index);
                                                          });
                                                        }}
                                                        is_amount={1}
                                                        // disabled={this.VIEW_ONLY_SNAPSHOT || editable}
                                                        name="base_amount"
                                                      />
                                                      {this.state.errors.tds_details && <small className="error_right">{this.state.errors?.tds_details[index]?.base_amount}</small>}

                                                    </td>
                                                    <td>
                                                      {/* <input
                                                        type="text"
                                                        className={
                                                          "form-control form-text-right"
                                                        }
                                                        value="2"
                                                        name=""
                                                      /> */}
                                                      <CurrencyInput
                                                        type="text"
                                                        className={
                                                          "form-control form-text-right" +
                                                          (this.state.errors.tds_details && this.state.errors?.tds_details[index]?.tds_percentage
                                                            ? " wrong-red "
                                                            : "") +
                                                          (this.state.global_preidction_status &&
                                                            !!this.state
                                                              .tds_percentage_prediction_status
                                                            ? " input-bg-green "
                                                            : "")
                                                        }
                                                        value={tds.tds_percentage}
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}
                                                        onValueChange={(value, name) => {
                                                          value > 100
                                                            ? ""
                                                            : this.amountChange(name, value, index);
                                                        }}
                                                        onBlur={() => {
                                                          let tds_arr = [...this.state.tds_details];
                                                          tds_arr[index].tds_percentage =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds.tds_percentage):tds.tds_percentage;
                                                          this.setState({ tds_details: tds_arr},()=>{
                                                            
                                                          let tdsP = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,((((this.state.tds_details[index].tds_amount || 0) * 100 )/(this.state.tds_details[index].base_amount || 0)).toFixed(2))):((((this.state.tds_details[index].tds_amount || 0) * 100 )/(this.state.tds_details[index].base_amount || 0)).toFixed(2));
                                                          console.log("prev after ----------------------------------" , this.state.tds_details[index].tds_percentage,tdsP )
                                                            if(this.state.tds_details[index].tds_percentage != tdsP)
                                                            this.calculateTDS(index);
                                                          });	
                                                        }}
                                                        is_amount={1}
                                                        disabled={
                                                          this.props.VIEW_ONLY_SNAPSHOT || !this.props.editable
                                                        }
                                                        name="tds_percentage"
                                                      />
                                                      {this.state.errors.tds_details && <small className="error_right">{this.state.errors?.tds_details[index]?.tds_percentage}</small>}

                                                    </td>
                                                    <td>
                                                      {/* <input
                                                        type="text"
                                                        className={
                                                          "form-control form-text-right"
                                                        }
                                                        value="₹ 123"
                                                        prefix="&#8377; "
                                                        placeholder="&#8377; 0"
                                                        name=""
                                                      /> */}
                                                      <CurrencyInput
                                                        type="text"
                                                        className={
                                                          "form-control form-text-right" +
                                                          (this.state.errors.tds_details && this.state.errors.tds_details[index]?.tds_amount
                                                            ? " wrong-red "
                                                            : "")
                                                        }
                                                        value={tds?.tds_amount}
                                                        allowNegativeValue={false}
                                                        decimalsLimit={2}
                                                        prefix="&#8377; "
                                                        placeholder="&#8377; 0"
                                                        onValueChange={(value, name) =>
                                                          this.amountChange(name, value, index)
                                                        }
                                                        is_amount={1}
                                                        onBlur={() => {
                                                          let tds_arr = [...this.state.tds_details];
                                                          tds_arr[index].tds_amount =  this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,tds.tds_amount):tds.tds_amount;
                                                          this.setState({ tds_details: tds_arr},()=>{
                                                              this.calculateTdsTotal(index);
                                                          });
                                                        }}
                                                        name="tds_sub_amount"
                                                      />
                                                      {this.state.errors.tds_details && this.state.errors?.tds_details[index]?.tds_amount && (
                                                        <small className="error_right">
                                                          {this.state.errors.tds_details[index].tds_amount}
                                                        </small>
                                                      )}
                                                    </td>

                                                    {index != 0 && (
                                                      <td className="text-right croslt ">
                                                        <span
                                                          class="icon-Cross-with-grey-circle size-xx cpointer"
                                                          onClick={() =>
                                                            this.removeTds(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <span class="path1"></span>
                                                          <span class="path2 cpointer"></span>
                                                          <span class="path3"></span>
                                                        </span>
                                                      </td>
                                                    )}
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )}

                                          <tr>
                                            <td>
                                              <td className=" va-middle d-flex align-items-center gap-10">
                                                <button class="ledger-add-row">
                                                  <span
                                                    disabled={this.props.VIEW_ONLY_SNAPSHOT}
                                                    class="icon-plus-circled" onClick={() => this.addTdsRow()}></span>
                                                </button>
                                              </td>
                                            </td>
                                            <td className="ledger-total">
                                              TDS Total (₹)
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className={"form-control form-text-right"}
                                                value={this.state.tds_amount}
                                                decimalsLimit={2}
                                                prefix="&#8377; "
                                                placeholder="&#8377; 0"
                                                name="tds_total"
                                                disabled
                                              />
                                            </td>
                                          </tr>
                                          {this.state.errors.total_base_amount &&<tr><td colSpan={3}> <small className="error_right">{this.state.errors.total_base_amount}</small></td></tr>}
                                          </tbody>                               
                                      </table>
                                    </div>
                                    {/* end New Design TDS */}
                                    {/* <div className="col-xl-2 col-sm-2">
																			<div className="form-group">
																				<lable className="lable-input">TDS %</lable>
																				<CurrencyInput type="text"
																					className={"form-control text-right" + (this.props.line_items_prediction_status && this.state.tds_percentage_prediction_status ? " input-bg-green " : "")}
																					value={this.state.tds_percentage}
																					allowNegativeValue={false}
																					decimalsLimit={2}
																					onValueChange={(value, name) => { value > 100 ? "" : this.amountChange(name, value) }}
																					onBlur={() => {
																						this.calculateTDS()
																					}}
																					is_amount={1}
																					disabled={this.props.VIEW_ONLY_SNAPSHOT || !this.props.editable}
																					name="tds_percentage"
																				/>
																				{this.state.errors.tds_percentage && <small className="error_right">{this.state.errors.tds_percentage}</small>}
																			</div>
																		</div>
																		<div className="col-xl-4 col-sm-6">
																			<div className="form-group">
																				<lable className="lable-input">TDS Amount</lable>
																				<CurrencyInput
																					type="text"
																					className="form-control text-right"
																					value={this.state.tds_amount}
																					allowNegativeValue={false}
																					decimalsLimit={2}
																					prefix="&#8377; "
																					placeholder='&#8377; 0'
																					is_amount={1}
																					disabled
																					name="tds_amount"
																				/>
																				{this.state.errors.tds_amount && <small className="error_right">{this.state.errors.tds_amount}</small>}
																			</div>
																		</div> */}
                                  </>
                                ) : (
                                  <></>
                                )}
                              {/* </div> */}
                            </div>
                          </div>

                          {(this.props.SNAPSHOT_LEDGER_ENTRIES_CODE ||
                            this.props.VIEW_ONLY_SNAPSHOT) &&
                            this.state.inventory_voucher_status == true && (
                              <div className="col-xl-7 col-lg-6">
                                <div className="inventory-filter d-flex align-items-center w-100">
                                  <div className="form-group w-100">
                                    <lable className="lable-input">
                                      Accounting Narration
                                    </lable>
                                    <TextareaAutosize
                                      type="text"
                                      maxLength="1000"
                                      className="form-control"
                                      name="accounting-narration"
                                      minRows={1}
                                      value={this.state.accounting_narration}
                                      onChange={(e) =>
                                        this.setState({
                                          accounting_narration: e.target.value,
                                          is_dirty: true,
                                        })
                                      }
                                      disabled={
                                        this.props.VIEW_ONLY_SNAPSHOT ||
                                        !this.props.editable
                                      }
                                    ></TextareaAutosize>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>

                        {/* End Item Details */}

                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-main-title-new pb-2">
                              <h4 className="form-title-heading-new">
                                GST Summary
                              </h4>
                              <div>
                                {this.state.show_summary_section ? (
                                  <span
                                    class="icon-Cross-with-grey-circle size-xxvii cpointer"
                                    onClick={() =>
                                      this.setState({
                                        show_summary_section: false,
                                      })
                                    }
                                  >
                                    <span class="path1"></span>
                                    <span class="path2 cpointer"></span>
                                    <span class="path3"></span>
                                  </span>
                                ) : (
                                  <span
                                    class="icon-view-details size-xxv blue-icon cpointer"
                                    onClick={() =>
                                      this.setState({
                                        show_summary_section: true,
                                      })
                                    }
                                  ></span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          {this.state.show_summary_section
                            ? this.renderGSTSummary()
                            : null}
                        </div>

                        {this.props.VIEW_ONLY_SNAPSHOT ||
                          !this.props.editable ? null : (
                          <div className="row pb-4">
                            <div className="col-12">
                              <div className="reset_apply_btn_new">
                                <Button
                                  variant="outline-success reset_btn_new"
                                  onClick={async () => {
                                    if (this.state.is_dirty) {
                                      if (
                                        await confirm({
                                          confirmation:
                                            "Changes are not saved. Do you still want to leave?",
                                        })
                                      ) {
                                        // this.props.emptyPredictionLineItems();
                                        this.setState({
                                          show_edit_popup: false,
                                        });
                                      }
                                    } else {
                                      this.setState({ show_edit_popup: false });
                                    }
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="success apply_btn_new"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        line_item_discount_total:
                                          this.calculateTotalLineItemDisc(),
                                        line_item_gst_total:
                                          this.calculateTotalLineItemGST(),
                                        line_item_total:
                                          this.calculateTotalLineItemAmt(),
                                        voucher_total_amount:
                                          this.calculateTotalLedgersAmt(),
                                      },
                                      () => {
                                        let errors = this.checkForErrors();
                                        console.log('errors -----------',errors);

                                        if (
                                          Object.keys(errors.errors).length ==
                                          0 &&
                                          errors.line_item_errors.length == 0 &&
                                          errors.ledger_errors.length == 0
                                        ) {
                                          // alert('done')
                                          // return;

                                          this.saveLineItemData();
                                          this.props.emptyPredictionLineItems();
                                          this.setState({
                                            show_edit_popup: false,
                                          });
                                        } else {
                                          this.setState({
                                            errors: {
                                              ...errors.errors,
                                              inventory_error_status:
                                                "Please fill required mandatory fields.",
                                            },
                                            line_item_errors:
                                              errors.line_item_errors,
                                            ledger_errors: errors.ledger_errors,
                                          });
                                        }
                                      }
                                    );
                                  }}
                                >
                                  Done
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Resizable>
              </div>
            </div>
            {/* End Form */}
          </div>
          {/* End inventory_popup */}
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const session_values = state.Session || {};
  const features_permissions_list =
    session_values.features_permissions_list || [];

  const all_value = state.Snapshot;
  const payment_information =
    all_value.payment_info ||
      (features_permissions_list.includes(4) &&
        !features_permissions_list.includes(1))
      ? [{ method: 3, id: 0, amount: 0, payment_id: 0 }]
      : [{ method: 1, id: 0, amount: 0, payment_id: 0 }];

  return {
    features_permissions_list,
  };
}

export default connect(mapStateToProps)(InventoryAccounting);
