import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo from '../../Assets/img/logo.png';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import recipt from '../../Assets/img/recipt.png';
import invoice from '../../Assets/img/invoice.jpeg';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar'
function moneylink(){
  const [comment,setComment]=React.useState(false);
  const [task,setTask]=React.useState(false);
  const [note,setNote]=React.useState(false);
  const [privatemessage,setPmessage]=React.useState(false);
  const [sidfltr,setSidfltr]=React.useState(false);

  const [lgShow, setLgShow] = React.useState(false);

  const [show,setShow]=React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false);
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span className="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span className="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span className="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><i class="fa fa-bell-o" aria-hidden="true"></i></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span className="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4408"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span className="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-lightbulb"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4402"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5305"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span className="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-to-do-list"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-chat1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4403"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4404"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="icon-sec icon-Group-4405"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Money Link</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Money Link <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
                  <span className="icon-Information green-icon green-icon-head"></span></OverlayTrigger></h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-date-sec">
                  <div className="clearfix">
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec">
                          <option value="1" disabled selected>Financial Institute</option>
                          <option value="2">All</option>
                          <option value="3">HDFCXXX5443</option>
                          <option value="4">ICICIXXX5233</option>
                          <option value="5">SBIXXX5123</option>
                        </select>             
                      </div>
                    </div>
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec">
                          <option value="1" disabled selected>Current FY</option>
                          <option value="2">Last FY</option>
                          <option value="3">Since Beginning</option>
                          <option value="4">Custom date range</option>
                        </select>             
                      </div>
                    </div>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <hr/>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">End Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <div className="pull-right list-top-right-sec">
                      <button className="initiate-btn btn ft-sz-22 s-bold white-color">Initiate Fresh Recommendation</button>
                      <button className="btn ico-refresh-sec">
                        <span className="icon-refresh"></span>
                      </button>
                    </div>
                  </div> 
                </div>

                <div className="">
                  <div className="tab-inner-sec">
                    <div className="row">
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box active">
                          <p className="title ft-sz-28 m-0">Total</p>
                          <div className="count-char s-bold">47</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-28 m-0">Not Ready</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-28 m-0">Ready</p>
                          <div className="count-char s-bold">42</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-28 m-0">Processed</p>
                          <div className="count-char s-bold">40</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box">
                          <p className="title ft-sz-28 m-0">Synched</p>
                          <div className="count-char s-bold">2</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec">
                        <input type="text" placeholder="Search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter">
                      Filter <span className="icon-funnel"></span>
                      </div>
                      {/* <button className="add-new-btn btn ft-sz-20 white-color s-bold"><span className="icon-plus1"></span> Add New</button> */}
                    </div>
                  </div>
                    <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="ID" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>CR /DR</option>
                                <option>dddf</option>
                                <option>fdd</option>
                                <option>ffdf</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                          <div className="row main-due-date-sec evidence-filter no-gutters">
                            <div className="col-lg-5 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" placeholder="From Amount"/>
                                  </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                              <hr/>
                            </div>
                            <div className="col-lg-5 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" placeholder="To Amount"/>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Status</option>
                                <option>dddf</option>
                                <option>fdd</option>
                                <option>ffdf</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Last Modified By</option>
                                <option>dddf</option>
                                <option>fdd</option>
                                <option>ffdf</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>

                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Starred</option>
                                <option>Yes</option>
                                <option>No</option>
                                <option>Both</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>

                  
                  <div className="main-table-sec">
                    <div className="not-create-sec d-none"><p className="ft-sz-32 s-sbold blu-color m-0">No items to display</p></div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Financial Institute <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Narration <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">CR/DR <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">Amount <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">Personal <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th className="text-center">Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>05-09-2020</td>
                          <td>ICICI xxxx4578</td>
                          <td>₹2000 Paid to Joshi Foods</td>
                          <td className="text-center">DR</td>
                          <td className="text-right">₹2000</td>
                          <td className="text-center"> 
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span class="icon-td icon-personal-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                          </OverlayTrigger>
                          </td>
                          <td className="text-center">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                         
                      </tbody>
                    </Table>
                  </div>

                  <div className="main-pagination clearfix">
                    <nav aria-label="Page navigation example">
                      <div className="pull-left"><button type="button" className="btn sms-delete-btn white-color drk-gry-bg d-none">Delete </button></div>
                      <ul className="pagination pull-right mb-0">
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                        <li className="page-item item-count"><a className="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li>
                        <li className="page-item prev-sec"><a className="page-link ft-sz-20 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li className="page-item active"><a className="page-link ft-sz-20 s-reg" href="#">1</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-20 s-reg" href="#">3</a></li>
                        <li className="page-item next-sec"><a className="page-link ft-sz-20 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
         </div>  

          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
              <div className="clearfix">
                <ol className="breadcrumb pull-left">
                  <li className="breadcrumb-item">
                  <Link to={'/home'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Money Link</li>
                </ol>
                <div className="pull-right d-flex new-add-filter-sec">
                  <div className="sidebar-evidence-filter" onClick={()=>setSidfltr(!sidfltr)}>
                  <span className="icon-filter"></span>
                  </div>
                  {/* <div className="add-new-evi-sms">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
                    <span className="icon-plus"></span>
                    </OverlayTrigger>
                  </div> */}
                </div> 
              </div>
              <hr/>
              
              {/* <div className="btn-group btn-toggle left-on-off-btn pull-left"> 
                <button className="btn btn-lg btn-default active">Evidence</button>
                <button className="btn btn-lg btn-primary ">SMS</button>
              </div> */}
               

              

              <div className="recipt-sidebar-sec">
              <p className="ft-sz-24 s-sbold blu-color">Transactions</p>
              {
                sidfltr?<div className="main-evidence-sidefilter">
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="ID" />  
                        </div>  
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-lg-5 col-sm-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Star</option>
                            <option>Yes</option>
                            <option>No</option>
                            <option>Both</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>CR/DR</option>
                            <option>CR</option>
                            <option>DR</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>     
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control text-right" value="" placeholder="From Amount" />  
                        </div>  
                    </div>
                    <div className="col">
                      <hr/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control text-right" value="" placeholder="To Amount" /> 
                        </div>
                    </div>
                  </div> 
                  <div className="row">    
                    <div className="col-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Status</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Last Modified By</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-lg-6 col-xs-12">
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>
                    <div className="col-lg-6 col-xs-12">
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                    </div>
                  </div>
                </div>:null
              }  

                <div className="main-applied-sidefilter d-none">
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">HDFCXXX5443</span>
                    <span className="ft-sz-16 s-sbold blu-color">Last Financial Year</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">₹300 - ₹5000</span>
                    <span className="ft-sz-16 s-sbold blu-color">Unpaid</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Ramesh08</span>
                    <span className="ft-sz-16 s-sbold blu-color">#12345</span>
                  </div>
                </div>

                

                <div className="recipt-inner-div transactions-inner-div active">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">DR</p>
                        <div className="color-icon">
                        <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-2509"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div transactions-inner-div">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-7 col-sm-12">
                      <div className="date ft-sz-13 s-sbold font-colr">11-12-2020 </div>
                      <p className="m-0 head ft-sz-20 s-sbold font-colr">HDFCXXX5443</p>
                    </div>
                    <div className="col-xl-5 col-sm-12">
                      <div className="evi-icon">
                        <p className="ft-sz-20 s-sbold evi-chq-text">CR</p>
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                 <div className="main-pagination clearfix sidebar-pagination">
                    <nav aria-label="Page navigation example">
                      {/* <div className="per-page-sec clearfix">
                        <ul className="pagination pull-right mb-0">
                          <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                          <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        </ul>
                      </div> */}
                      <ul className="pagination pull-right mb-0">  
                        <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> </a></li>
                        <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                        <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#"> <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
              <div className="inner-content-sec">
                <div className="clearfix main-title-btn-sec">
                  <div className="head-title pull-left">
                    <h2 className="s-sbold blu-color m-0">Link Transactions <span className="icon-Information green-icon help"></span></h2>
                  </div>
                  <div className="pull-right">
                    
                  </div>
                </div>
                <div className="main-file-item-sec">
                  <div className="cross" onClick={handleShow}>
                    <span>X</span>
                  </div>
                  <div className="main-top-link-tran-sec">
                    <div className="row">
                      <div className="col-lg-2 col-md-4">
                        <p className="ft-sz-15 s-reg font-colr mb-1">Date</p>
                        <p className="ft-sz-22 s-sbold blu-color">30-6-2020</p>
                      </div>
                      <div className="col-lg-2 col-md-4">
                        <p className="ft-sz-15 s-reg font-colr mb-1">Financial Institute</p>
                        <p className="ft-sz-22 s-sbold blu-color">HDFC Bank</p>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <p className="ft-sz-15 s-reg font-colr mb-1">Narration</p>
                        <p className="ft-sz-22 s-sbold blu-color">Paytm Order #10685899408</p>
                      </div>
                      <div className="col-lg-1 col-md-4">
                        <p className="ft-sz-15 s-reg font-colr mb-1">CR/DR</p>
                        <p className="ft-sz-22 s-sbold blu-color">CR</p>
                      </div>
                      <div className="col-lg-2 text-center col-md-4">
                        <p className="ft-sz-15 s-reg font-colr mb-1">Amount</p>
                        <p className="ft-sz-22 s-sbold blu-color">₹5000</p>
                      </div>
                      <div className="col-lg-1 text-center col-md-4">
                        <p className="ft-sz-15 s-reg font-colr mb-1">Status</p>
                        <span className="icon-Group-2508 ft-sz-30"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="ft-sz-15 s-reg font-colr mb-1">SMS</p>
                        <p className="ft-sz-16 s-sbold blu-color mb-0">Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 2000.00  on 30-6-2020 08:51 and credited to a/c no. XXXXXXXX5290</p>
                      </div>
                      <div className="col-lg-6">
                        <button type="button" className="btn btn-sec white-color s-bold ft-sz-20 mr-2"><span className="icon-Group--1"></span> No Supporting Document </button> {/* add active class */}
                        <button type="button" className="btn btn-sec white-color s-bold ft-sz-20"><span className="icon-check-1"><span className="path1"></span><span className="path2"></span></span> Mark as personal </button>
                      </div>
                    </div>
                  </div>
                  <div className="main-link-search-evidence-sec">
                    <div className="row">
                      <div className="col-xl-3 col-md-3 col-sm-12">
                        <div className="left-link-search-evidence-sec">
                        <div className="no-evidence-popup d-none"></div>
                          <p className="link-evi-head mb-0 ft-sz-24 s-sbold bg-g white-color">Linked Evidences</p>
                          <div className="main-link-drag-sec">
                            <div className="box-content-sec">
                              <div className="inner-search-evidence-box-sec">
                                <div className="row">
                                  <div className="col-lg-5 col-xs-12">
                                    <p className="evi-chq-text mb-0 ft-sz-13 s-sbold">Cheques</p>
                                    <div className="date s-sbold font-colr">11-12-2020</div>
                                  </div>
                                  <div className="col-lg-7 col-xs-12 icon-right-sec">
                                    <ul className="list-inline">
                                      <li className="list-inline-item"><span className="icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></li>
                                      <li className="list-inline-item"><span className="icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span></li>
                                      <li className="list-inline-item">
                                        <div className="edit-btn-sec"><span className="icon-edit"></span></div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="amount-link-progress-sec">
                                  <p className="ft-sz-17 s-bold font-colr text-center mb-0">Narayan Plaza</p>
                                  <div className="amount-link-sec">
                                    <div className="box-amount-sec ft-sz-20 s-sbold blu-color">₹1,500.00</div>
                                    <div className="box-link-icon-sec"><span className="icon-Group-4604"></span></div>
                                  </div>
                                  <div className="progressbar-sec">
                                    <ProgressBar now={75} className="green" />
                                    <p className="ft-sz-13 s-reg mb-0 text-center font-colr"> <span className="s-bold pp-green">75%</span> Confidence Score</p>
                                  </div>
                                </div>
                              </div>
                              <div className="main-bottom-view-select-sec">
                                <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr">
                                  <span className="ico-btn icon-view-details"></span> View Evidence
                                </div>
                                <div className="btn-sec select-btn ft-sz-13 s-sbold discard-btn"> {/* add class discard-btn */}
                                  {/* <span className="select-text"><span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select</span> */}
                                  <span className="discard-text d-none"><span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span>  Discard</span> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="main-smartview-sec main-list-history-popup-sec d-none">
                          <div className="cross"><span>X</span></div>
                            <div className="inner-smartview-sec">
                              <p className="mb-0 ft-sz-24 s-sbold blu-color">Link History</p>
                              <p className="sub-head ft-sz-18 s-reg font-colr">This evidence is already linked with below Transactions </p>
                              <div className="right-link-search-evidence-sec list-history-popup-sec">
                                <Accordion defaultActiveKey="4">
                                  <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="4" className="">
                                      <div className="pull-left head-list-right-sec">
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Date</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">30-6-2020</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Financial Institute</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">ICICI Bank</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Narration</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">Paytm Order #10685899408</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">CR/DR</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">CR</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Amount </p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">₹2000</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Status</p>
                                          <p className="ico-sec mb-0 text-center">
                                          <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                          </p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Personal</p>
                                          <p className="ico-sec mb-0 text-center">
                                          <span className="icon-check-1"><span className="path1"></span><span className="path2"></span></span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="drop-arrow">
                                      <span className="icon-arrow-down-sign-to-navigate"></span>
                                      </div>
                                      <div className="clearfix"></div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                      <Card.Body>
                                        <div className="main-ledger-entery-sec">
                                          <div className="sms-text-top-sec">
                                           <p className="s-sbold ft-sz-18 blu-color">SMS</p>
                                           <p className="s-reg ft-sz-16 font-colr">Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 2000.00  on 30-6-2020 08:51 and credited to a/c no. XXXXXXXX5290</p>
                                          </div>
                                          <div className="main-accounting-narration-sec">
                                            <div className="top-head clearfix">
                                              <p className="pull-left s-sbold ft-sz-18 blu-color m-0">Business Narration</p>
                                            </div>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                              <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                          </div>
                                          <div className="clearfix">
                                            <p className="ft-sz-16 s-sbold blu-color mb-0 pull-left">Ledger Details</p>
                                            <p className="ft-sz-16 s-sbold blu-color mb-0 pull-right">Ledger Uploaded: 10-5-2020</p>
                                          </div>
                                          <div className="main-invoice-item-sec">
                                            <Table responsive>
                                              <thead>
                                                <tr className="blu-bg white-color">
                                                  <th className="ft-sz-15 s-reg text-center">CR / DR</th>
                                                  <th className="ft-sz-15 s-reg w-50">Ledger</th>
                                                  <th className="ft-sz-15 s-reg text-center">Debit</th>
                                                  <th className="ft-sz-15 s-reg text-center">Credit</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                                  <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹0"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                                </tr> 
                                                <tr>
                                                  <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                                  <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹0"/> </td>
                                                </tr> 
                                                <tr>
                                                  <td></td>
                                                  <td  className="text-right s-bold">Total</td>
                                                  <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                                  <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                                </tr>
                                              </tbody>
                                            </Table>
                                          </div> 
                                          <div className="main-accounting-narration-sec">
                                            <div className="top-head clearfix">
                                              <p className="pull-left s-sbold ft-sz-18 blu-color m-0">Accounting Narration</p>
                                            </div>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                              <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>

                                  <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="5" className="">
                                      <div className="pull-left head-list-right-sec">
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Date</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">30-6-2020</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Financial Institute</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">ICICI Bank</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Narration</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">Paytm Order #10685899408</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">CR/DR</p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">CR</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Amount </p>
                                          <p className="ft-sz-16 s-sbold blu-color mb-0">₹2000</p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Status</p>
                                          <p className="ico-sec mb-0 text-center">
                                          <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                          </p>
                                        </div>
                                        <div className="inner-head-list-right">
                                          <p className="ft-sz-10 mb-0 font-colr">Personal</p>
                                          <p className="ico-sec mb-0 text-center">
                                          <span className="icon-check-1"><span className="path1"></span><span className="path2"></span></span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="drop-arrow">
                                      <span className="icon-arrow-down-sign-to-navigate"></span>
                                      </div>
                                      <div className="clearfix"></div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="5">
                                      <Card.Body>
                                        <div className="main-ledger-entery-sec">
                                          <div className="sms-text-top-sec">
                                           <p className="s-sbold ft-sz-18 blu-color">SMS</p>
                                           <p className="s-reg ft-sz-16 font-colr">Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 2000.00  on 30-6-2020 08:51 and credited to a/c no. XXXXXXXX5290</p>
                                          </div>
                                          <div className="main-accounting-narration-sec">
                                            <div className="top-head clearfix">
                                              <p className="pull-left s-sbold ft-sz-18 blu-color m-0">Business Narration</p>
                                            </div>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                              <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                          </div>
                                          <div className="clearfix">
                                            <p className="ft-sz-16 s-sbold blu-color mb-0 pull-left">Ledger Details</p>
                                            <p className="ft-sz-16 s-sbold blu-color mb-0 pull-right">Ledger Uploaded: 10-5-2020</p>
                                          </div>
                                          <div className="main-invoice-item-sec">
                                            <Table responsive>
                                              <thead>
                                                <tr className="blu-bg white-color">
                                                  <th className="ft-sz-15 s-reg text-center">CR / DR</th>
                                                  <th className="ft-sz-15 s-reg w-50">Ledger</th>
                                                  <th className="ft-sz-15 s-reg text-center">Debit</th>
                                                  <th className="ft-sz-15 s-reg text-center">Credit</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                                  <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹0"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                                </tr> 
                                                <tr>
                                                  <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                                  <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                                  <td><input type="text" className="form-control text-right" value="₹0"/> </td>
                                                </tr> 
                                                <tr>
                                                  <td></td>
                                                  <td  className="text-right s-bold">Total</td>
                                                  <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                                  <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                                </tr>
                                              </tbody>
                                            </Table>
                                          </div> 
                                          <div className="main-accounting-narration-sec">
                                            <div className="top-head clearfix">
                                              <p className="pull-left s-sbold ft-sz-18 blu-color m-0">Accounting Narration</p>
                                            </div>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                              <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="col-xl-9 col-md-9 col-sm-12">
                        <div className="right-link-search-evidence-sec">
                          <Accordion defaultActiveKey="0">
                            <Card className="">
                              <Accordion.Toggle as={Card.Header} eventKey="0" className="ft-sz-24 s-sbold blu-color">
                              Evidences
                              <div className="drop-arrow">
                               <span className="icon-arrow-down-sign-to-navigate"></span>
                              </div>
                              <div className="clearfix"></div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                  <div className="clearfix">
                                    <div className="pull-left">
                                      <span className="mb-0 ft-sz-18 s-sbold blu-color mt-1 cursor-point">Suggested </span>
                                      <span className="mb-0 ft-sz-18 s-sbold blu-color mt-1 ml-2 mr-2">\</span>
                                      <span className="mb-0 ft-sz-18 s-sbold blu-color mt-1 cursor-point">Search </span>
                                    </div>
                                    <div className="search-filter-sec pull-right">
                                      <form className="search-sec">
                                        <input type="text" placeholder="Search" />
                                        <button type="submit"><span className="icon-loupe"></span></button>
                                      </form>
                                      <div className="filter">
                                      Filter <span className="icon-funnel"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="main-filter-sec clearfix d-none">
                                    <div className="row inner-row mb-3">
                                      <div className="col-lg-6 col-xs-12">
                                        <div className="row main-due-date-sec evidence-filter no-gutters">
                                          <div className="col-lg-5 col-xs-12">
                                            <div className="form-group date-sec">
                                                <div className="inner-date-sec">
                                                  <input type="text" className="form-control" placeholder="dd/mm/yyyy"/>
                                                  <span className="icon-calendar"></span>
                                                </div>
                                              </div>
                                          </div>
                                          <div className="col-lg-2">
                                            <hr/>
                                          </div>
                                          <div className="col-lg-5 col-xs-12">
                                            <div className="form-group date-sec">
                                                <div className="inner-date-sec">
                                                  <input type="text" className="form-control" placeholder="dd/mm/yyyy"/>
                                                  <span className="icon-calendar"></span>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-12">
                                        <div className="row main-due-date-sec evidence-filter no-gutters">
                                          <div className="col-lg-5 col-xs-12">
                                            <div className="form-group date-sec">
                                              <div className="inner-date-sec">
                                                <input type="text" className="form-control" placeholder="From Amount"/>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-2">
                                            <hr/>
                                          </div>
                                          <div className="col-lg-5 col-xs-12">
                                            <div className="form-group date-sec">
                                                <div className="inner-date-sec">
                                                  <input type="text" className="form-control" placeholder="To Amount"/>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row inner-row">
                                      <div className="col-lg-3 col-xs-12">
                                        <div className="form-group">
                                          <Form>
                                          <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Control as="select">
                                              <option>Type</option>
                                              <option>Yes</option>
                                              <option>No</option>
                                              <option>Both</option>
                                            </Form.Control>
                                          </Form.Group>
                                          </Form>
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-xs-12">
                                        <div className="form-group">
                                          <input type="text" className="form-control" value="" placeholder="Company" />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-xs-12">
                                        <div className="form-group">
                                          <Form>
                                          <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Control as="select">
                                              <option>Status</option>
                                              <option>Yes</option>
                                              <option>No</option>
                                              <option>Both</option>
                                            </Form.Control>
                                          </Form.Group>
                                          </Form>
                                        </div>
                                      </div>
                                    </div>
                                    <button type="button" className="btn apply-btn ft-sz-20 white-color s-bold">Apply</button>
                                    <button type="button" className="btn apply-btn ft-sz-20 white-color s-bold drk-gry-bg">Reset</button>
                                  </div>
                                  <div className="main-search-evidence-box-sec">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec">
                                          <div className="row">
                                            <div className="col-lg-5 col-xs-12">
                                              <p className="evi-chq-text mb-0 ft-sz-13 s-sbold">Cheques</p>
                                              <div className="date s-sbold font-colr">11-12-2020</div>
                                            </div>
                                            <div className="col-lg-7 col-xs-12 icon-right-sec">
                                              <ul className="list-inline">
                                                <li className="list-inline-item"><span className="icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></li>
                                                <li className="list-inline-item"><span className="icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span></li>
                                                <li className="list-inline-item">
                                                  <div className="edit-btn-sec"><span className="icon-edit"></span></div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="amount-link-progress-sec">
                                            <p className="ft-sz-17 s-bold font-colr text-center mb-0">Narayan Plaza</p>
                                            <div className="amount-link-sec">
                                              <div className="box-amount-sec ft-sz-20 s-sbold blu-color">₹1,500.00</div>
                                              <div className="box-link-icon-sec"><span className="icon-Group-4604"></span></div>
                                            </div>
                                            <div className="progressbar-sec">
                                              <ProgressBar now={53} className="yellow" />
                                              <p className="ft-sz-13 s-reg mb-0 text-center font-colr"> <span className="s-bold pp-yellow">43%</span> Confidence Score</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr">
                                            <span className="ico-btn icon-view-details"></span> View Evidence
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec">
                                          <div className="row">
                                            <div className="col-lg-5 col-xs-12">
                                              <p className="evi-chq-text mb-0 ft-sz-13 s-sbold">Cheques</p>
                                              <div className="date s-sbold font-colr">11-12-2020</div>
                                            </div>
                                            <div className="col-lg-7 col-xs-12 icon-right-sec">
                                              <ul className="list-inline">
                                                <li className="list-inline-item"><span className="icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></li>
                                                <li className="list-inline-item"><span className="icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span></li>
                                                <li className="list-inline-item">
                                                  <div className="edit-btn-sec"><span className="icon-edit"></span></div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="amount-link-progress-sec">
                                            <p className="ft-sz-17 s-bold font-colr text-center mb-0">Narayan Plaza</p>
                                            <div className="amount-link-sec">
                                              <div className="box-amount-sec ft-sz-20 s-sbold blu-color">₹1,500.00</div>
                                              <div className="box-link-icon-sec"><span className="icon-Group-4604"></span></div>
                                            </div>
                                            <div className="progressbar-sec">
                                              <ProgressBar now={25} className="red" />
                                              <p className="ft-sz-13 s-reg mb-0 text-center font-colr"> <span className="s-bold pp-red">25%</span> Confidence Score</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr">
                                            <span className="ico-btn icon-view-details"></span> View Evidence
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec  active">
                                          <div className="row">
                                            <div className="col-lg-5 col-xs-12">
                                              <p className="evi-chq-text mb-0 ft-sz-13 s-sbold">Cheques</p>
                                              <div className="date s-sbold font-colr">11-12-2020</div>
                                            </div>
                                            <div className="col-lg-7 col-xs-12 icon-right-sec">
                                              <ul className="list-inline">
                                                <li className="list-inline-item"><span className="icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></li>
                                                <li className="list-inline-item"><span className="icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span></li>
                                                <li className="list-inline-item">
                                                  <div className="edit-btn-sec"><span className="icon-edit"></span></div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="amount-link-progress-sec">
                                            <p className="ft-sz-17 s-bold font-colr text-center mb-0">Narayan Plaza</p>
                                            <div className="amount-link-sec">
                                              <div className="box-amount-sec ft-sz-20 s-sbold blu-color">₹1,500.00</div>
                                              <div className="box-link-icon-sec active"><span className="icon-Group-4604"></span></div>
                                            </div>
                                            <div className="progressbar-sec">
                                              <ProgressBar now={75} className="green" />
                                              <p className="ft-sz-13 s-reg mb-0 text-center font-colr"> <span className="s-bold pp-green">75%</span> Confidence Score</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr">
                                            <span className="ico-btn icon-view-details"></span> View Evidence
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                   
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec">
                                          <div className="row">
                                            <div className="col-lg-5 col-xs-12">
                                              <p className="evi-chq-text mb-0 ft-sz-13 s-sbold">Cheques</p>
                                              <div className="date s-sbold font-colr">11-12-2020</div>
                                            </div>
                                            <div className="col-lg-7 col-xs-12 icon-right-sec">
                                              <ul className="list-inline">
                                                <li className="list-inline-item"><span className="icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></li>
                                                <li className="list-inline-item"><span className="icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span></li>
                                                <li className="list-inline-item">
                                                  <div className="edit-btn-sec"><span className="icon-edit"></span></div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="amount-link-progress-sec">
                                            <p className="ft-sz-17 s-bold font-colr text-center mb-0">Narayan Plaza</p>
                                            <div className="amount-link-sec">
                                              <div className="box-amount-sec ft-sz-20 s-sbold blu-color">₹1,500.00</div>
                                              <div className="box-link-icon-sec"><span className="icon-Group-4604"></span></div>
                                            </div>
                                            <div className="progressbar-sec">
                                              <ProgressBar now={53} className="yellow" />
                                              <p className="ft-sz-13 s-reg mb-0 text-center font-colr"> <span className="s-bold pp-yellow">43%</span> Confidence Score</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr">
                                            <span className="ico-btn icon-view-details"></span> View Evidence
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec">
                                          <div className="row">
                                            <div className="col-lg-5 col-xs-12">
                                              <p className="evi-chq-text mb-0 ft-sz-13 s-sbold">Cheques</p>
                                              <div className="date s-sbold font-colr">11-12-2020</div>
                                            </div>
                                            <div className="col-lg-7 col-xs-12 icon-right-sec">
                                              <ul className="list-inline">
                                                <li className="list-inline-item"><span className="icon-Group-3283-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></li>
                                                <li className="list-inline-item"><span className="icon-Group-3181"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span></li>
                                                <li className="list-inline-item">
                                                  <div className="edit-btn-sec"><span className="icon-edit"></span></div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="amount-link-progress-sec">
                                            <p className="ft-sz-17 s-bold font-colr text-center mb-0">Narayan Plaza</p>
                                            <div className="amount-link-sec">
                                              <div className="box-amount-sec ft-sz-20 s-sbold blu-color">₹1,500.00</div>
                                              <div className="box-link-icon-sec"><span className="icon-Group-4604"></span></div>
                                            </div>
                                            <div className="progressbar-sec">
                                              <ProgressBar now={25} className="red" />
                                              <p className="ft-sz-13 s-reg mb-0 text-center font-colr"> <span className="s-bold pp-red">25%</span> Confidence Score</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr">
                                            <span className="ico-btn icon-view-details"></span> View Evidence
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec add-active">
                                          <div className="amount-link-progress-sec">
                                            <div className="add-new-evi-sms text-center add-evidence-box-sec"><span className="icon-plus"></span></div>
                                            <p className="ft-sz-18 s-sbold font-colr text-center add-evi-text-box">Add Evidence</p>
                                            <div className="amount-link-sec">
                                              <div className="add-select-sec select-btn">
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                  <Form.Control as="select">
                                                    <option>Select</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                  </Form.Control>
                                                </Form.Group>
                                              </div>
                                              <div className="add-select-sec add-btn">
                                                <button type="button" className="btn apply-btn ft-sz-16 white-color s-sbold drk-gry-bg">Add</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="main-pagination clearfix">
                                    <nav aria-label="Page navigation example">
                                      <div className="pull-left"><button type="button" className="btn sms-delete-btn white-color drk-gry-bg d-none">Delete </button></div>
                                      <ul className="pagination pull-right mb-0">
                                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Displaying</a></li>
                                        <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                                        <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                                        <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                                        <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                                      </ul>
                                    </nav>
                                  </div>
                                  <div className="no-evidence-popup d-none"></div>
                                  <div className="main-no-evidence-popup clearfix row d-none">
                                    <div className="mr-2 col-1">
                                       <span className="ico-sec icon-Group-4244"></span>
                                    </div>
                                    <div className="col">
                                      <p className="ft-sz-30 s-bold blu-color mb-1">No Evidence</p> 
                                      <p className="ft-sz-18 s-reg font-colr">You selected No Evidence for this transaction</p>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1" className="ft-sz-24 s-sbold blu-color">
                              Doc Box 
                              <div className="drop-arrow">
                               <span className="icon-arrow-down-sign-to-navigate"></span>
                              </div>
                              <div className="clearfix"></div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                  <div className="clearfix">
                                    <div className="search-filter-sec pull-right">
                                      <form className="search-sec">
                                        <input type="text" placeholder="Search" />
                                        <button type="submit"><span className="icon-loupe"></span></button>
                                      </form>
                                    </div>
                                  </div>
                                  <div className="folder-tab-menu-sec clearfix">
                                    <div className="pull-left">
                                      <p className="ft-sz-25 s-sbold blu-color mb-0 d-none">Files {'&'} Folders</p>
                                      {/* <button className="active folder-btn btn ft-sz-17 s-sbold mr-3">My Drive</button>
                                      <button className="folder-btn btn ft-sz-17 s-sbold mr-3">Shared With Me</button>
                                      <button className="folder-btn btn ft-sz-17 s-sbold mr-3">Starred</button> */}
                                    </div>
                                    <div className="pull-right lsit-menu-sec">
                                      <ul className="list-inline">
                                        <li className="list-inline-item"><a href="#"><span className="icon-list"></span></a></li>
                                        <li className="list-inline-item active"><a href="#"><span className="icon-menu1"></span></a></li>
                                      </ul>
                                    </div>
                                  </div>
                                  <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                     My Drive
                                    </li>
                                    <li className="breadcrumb-item">New Document</li>
                                  </ol>
                                  <div className="drive-list-sec">
                                      <button className="active drive-btn btn ft-sz-17 s-sbold mr-3"><span class="icon-Folder ft-sz-20 gray-icon mr-1"></span> My Folder</button>
                                      <button className="drive-btn btn ft-sz-17 s-sbold mr-3"><span class="icon-Folder ft-sz-20 gray-icon mr-1"></span> New Docs</button>
                                      <button className="drive-btn btn ft-sz-17 s-sbold mr-3"><span class="icon-Folder ft-sz-20 gray-icon mr-1"></span> Rahul T</button>
                                    </div>
                                  <div className="main-search-evidence-box-sec view-thumbail-sec">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec inner-doc-box-search-sec">
                                          <div className="">
                                            <div className="doc-pdf-sec">
                                             <span className="icon-Doc ft-sz-30 mr-1"></span>
                                            </div>
                                            <div className="file-name-sec font-colr ft-sz-16">
                                              <span className="icon-Doc ft-sz-28 mr-1"></span> Receipt.pdf
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr" onClick={() => setLgShow(true)}>
                                            <span className="ico-btn icon-view-details"></span> Preview
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4 box-content-sec">
                                        <div className="inner-search-evidence-box-sec inner-doc-box-search-sec">
                                          <div className="">
                                            <div className="doc-image-sec">
                                              <img className="" src={recipt}/>
                                            </div>
                                            <div className="file-name-sec font-colr ft-sz-16">
                                              <span className="icon-Doc ft-sz-28 mr-1"></span> Receipt.pdf
                                            </div>
                                          </div>
                                        </div>
                                        <div className="main-bottom-view-select-sec">
                                          <div className="btn-sec view-btn ft-sz-13 s-sbold font-colr" onClick={() => setLgShow(true)}>
                                            <span className="ico-btn icon-view-details"></span> Preview
                                          </div>
                                          <div className="btn-sec select-btn ft-sz-13 s-sbold">
                                            <span className="ico-btn icon-check-1"><span className="path1"></span><span className="path2"></span></span> Select
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="main-table-sec doc-box-moneylink-table">
                                    <div className="main-search-evidence-box-sec view-list-sec">
                                      <Table bordered responsive className="">
                                        <thead>
                                          <tr>
                                            <th>Name </th>
                                            <th>Last Modified </th>
                                            <th>Uploaded by </th>
                                            <th>File Size </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td><span class="icon-Folder ft-sz-28 gray-icon mr-1"></span> My Folder</td>
                                            <td>12 Nov 2020</td>
                                            <td>Mohit Rathod</td>
                                            <td>442KB</td>
                                            <td>
                                            <span className="table-view-select-icon gray-ico">
                                              <span className="icon-Group-4596"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                            </span>
                                            
                                            <span className="table-view-select-icon gray-ico">
                                             <span className="icon-Group-4598"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                            </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td><span class="icon-Folder ft-sz-28 gray-icon mr-1"></span> New Document</td>
                                            <td>4 jan 2021</td>
                                            <td>Mangesh</td>
                                            <td>442KB</td>
                                            <td>
                                            <span className="table-view-select-icon">
                                              <span className="icon-Group-4596"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                            </span>
                                            
                                            <span className="table-view-select-icon">
                                             <span className="icon-Group-4598"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                            </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td><span className="icon-Doc ft-sz-28 mr-1"></span> Loan Agreement.pdf</td>
                                            <td>4-2-2021</td>
                                            <td>Mohit Rathod</td>
                                            <td>226 KB</td>
                                            <td>
                                            <span className="table-view-select-icon">
                                              <span className="icon-Group-4596"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                            </span>
                                            
                                            <span className="table-view-select-icon">
                                             <span className="icon-Group-4598"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                                            </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>

                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2" className="ft-sz-24 s-sbold blu-color">
                                 Business Narration
                                <div className="drop-arrow">
                                <span className="icon-arrow-down-sign-to-navigate"></span>
                                </div>
                                <div className="clearfix"></div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  {/* <p className="ft-sz-18 s-sbold blu-color pull-left mt-1">Business Notes</p> */}
                                  <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={2} />
                                  </Form.Group>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                            <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3" className="ft-sz-24 s-sbold blu-color">
                                Ledger
                                <div className="drop-arrow">
                                <span className="icon-arrow-down-sign-to-navigate"></span>
                                </div>
                                <div className="clearfix"></div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                  
                                  <div className="clearfix">
                                    <p className="ft-sz-16 s-reg blu-color mb-0 pull-left">*Type ledger entries directly in the table</p>
                                    <p className="ft-sz-16 s-sbold blu-color mb-0 pull-right">Ledger list as of: 10-5-2020</p>
                                  </div>
                                  <div className="main-ledger-entery-sec">
                                    <div className="cross"><span>X</span></div>
                                    <div className="clearfix">
                                      <p className="ft-sz-16 s-sbold blu-color mb-0 pull-left">Ledger Details (Voucher Type1: 20-6-2020)</p>
                                      <p className="ft-sz-16 s-reg red-color mb-0 pull-right">*This is a generic error message</p>
                                    </div>
                                    <div className="main-invoice-item-sec">
                                      <Table responsive>
                                        <thead>
                                          <tr className="blu-bg white-color">
                                            <th className="ft-sz-15 s-reg text-center">CR / DR</th>
                                            <th className="ft-sz-15 s-reg w-50">Ledger</th>
                                            <th className="ft-sz-15 s-reg text-center">Debit</th>
                                            <th className="ft-sz-15 s-reg text-center">Credit</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                            <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                            <td><input type="text" className="form-control text-right" value=""/> </td>
                                            <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                            <td>X</td>
                                          </tr> 
                                          <tr>
                                            <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                            <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                            <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                            <td><input type="text" className="form-control text-right" value=""/> </td>
                                            <td>X</td>
                                          </tr> 
                                          <tr>
                                            <div className="item-increment">
                                              <span className="icon-plus"></span>
                                            </div>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td  className="text-right s-bold">Total</td>
                                            <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                            <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div> 
                                    <div className="main-accounting-narration-sec">
                                      <div className="top-head clearfix">
                                        <p className="pull-left s-sbold ft-sz-20 blu-color m-0">Accounting Narration</p>
                                        <div className="toggle-btn-sec pull-right">
                                          <span className="toggle-btn-sec-text s-sbold">No</span>
                                          <label className="switch">
                                            <input type="checkbox" checked/>
                                            <span className="slider round"></span> 
                                          </label>
                                          <span className="toggle-btn-sec-text s-sbold">Yes</span>
                                        </div>
                                      </div>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={3} />
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <div className="main-ledger-entery-sec">
                                    <div className="cross"><span>X</span></div>
                                    <div className="clearfix">
                                      <p className="ft-sz-16 s-sbold blu-color mb-0 pull-left">Ledger Details (Voucher Type1: 20-6-2020)</p>
                                      <p className="ft-sz-16 s-reg red-color mb-0 pull-right">*This is a generic error message</p>
                                    </div>
                                    <div className="main-invoice-item-sec">
                                      <Table responsive>
                                        <thead>
                                          <tr className="blu-bg white-color">
                                            <th className="ft-sz-15 s-reg text-center">CR / DR</th>
                                            <th className="ft-sz-15 s-reg w-50">Ledger</th>
                                            <th className="ft-sz-15 s-reg text-center">Debit</th>
                                            <th className="ft-sz-15 s-reg text-center">Credit</th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                            <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                            <td><input type="text" className="form-control text-right" value=""/> </td>
                                            <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                            <td>X</td>
                                          </tr> 
                                          <tr>
                                            <td><input type="text" className="form-control text-center" value="CR"  /> </td>
                                            <td><input type="text" className="form-control text-left" value="ICICI Bank"/> </td>
                                            <td><input type="text" className="form-control text-right" value="₹4500"/> </td>
                                            <td><input type="text" className="form-control text-right" value=""/> </td>
                                            <td>X</td>
                                          </tr> 
                                          <tr>
                                            <div className="item-increment">
                                              <span className="icon-plus"></span>
                                            </div>
                                          </tr>
                                          <tr>
                                            <td></td>
                                            <td  className="text-right s-bold">Total</td>
                                            <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                            <td><input type="text" className="form-control text-right input-bg-gray" value=""/> </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div> 
                                    <div className="main-accounting-narration-sec">
                                      <div className="top-head clearfix">
                                        <p className="pull-left s-sbold ft-sz-20 blu-color m-0">Accounting Narration</p>
                                        <div className="toggle-btn-sec pull-right">
                                          <span className="toggle-btn-sec-text s-sbold">No</span>
                                          <label className="switch">
                                            <input type="checkbox" checked/>
                                            <span className="slider round"></span> 
                                          </label>
                                          <span className="toggle-btn-sec-text s-sbold">Yes</span>
                                        </div>
                                      </div>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={3} />
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <div className="clearfix">
                                    <div className="top-typedate-sec pull-right">
                                      <div className="form-group vtype-sec">
                                        <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                          <Form.Control as="select">
                                            <option>Voucher Type</option>
                                            <option>Voucher Type 1</option>
                                            <option>Voucher Type 2</option>
                                            <option>Voucher Type 3</option>
                                          </Form.Control>
                                        </Form.Group>
                                        </Form>
                                      </div>
                                      {/* <div className="form-group date-sec">
                                        <div className="inner-date-sec">
                                          <input type="text" className="form-control" placeholder="dd/mm/yyyy"/>
                                          <span className="icon-calendar"></span>
                                        </div>
                                      </div> */}
                                      <button type="button" className="btn add-btn ft-sz-20 white-color s-bold">Add</button>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                        </div>
                      </div>
                      <div className="main-smartview-sec main-list-history-popup-sec main-view-evidence-popup-sec d-none">
                          <div className="cross"><span>X</span></div>
                            <div className="inner-smartview-sec">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                  <img className="evidence-popup-img" src={invoice}/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12 text-table-sec">
                                  <div className="top-head clearfix">
                                    <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Item Details</p>
                                    <p className="pull-right mb-0"> <span className="id-no-text s-sbold m-0">ID #2154</span></p>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Type</p>
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">Receipt</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Date</p>
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">11-12-2020</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Supplier Name</p>
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">Joshi Foods</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Invoice No.</p>
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">2158</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Total Amount</p>
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹580</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Category</p>
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">Food {'&'} Beverages</p>
                                    </div>
                                  </div>
                                  <div className="clearfix tag-pop-sec">
                                    <div className="left-sec-text">
                                      <p className="ft-sz-14 s-bold font-colr mb-1">Tags</p>
                                    </div>
                                    <span>#RathodProject</span>
                                    <span>#NewProductLaunch</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12 text-table-sec">
                                  <div className="top-head clearfix">
                                    <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Payment Status</p>
                                    <p className="pull-right mb-0 ft-sz-22 s-bold gren-color"> Paid</p>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">Payment Method</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">Cash</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹200</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">ICICI XX254 </p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹380</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">Total</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹580</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12 text-table-sec">
                                  <div className="top-head clearfix">
                                    <p className="pull-left s-sbold ft-sz-22 blu-color m-0">GST Info</p>
                                    <p className="pull-right mb-0 ft-sz-22 s-bold gren-color"> Yes</p>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">GST Total Amount</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹59</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">CGST</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹14</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">SGST</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹23</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">IGST</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹22</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12 text-table-sec">
                                  <div className="top-head clearfix">
                                    <p className="pull-left s-sbold ft-sz-22 blu-color m-0">TDS Applicable</p>
                                    <p className="pull-right mb-0 ft-sz-22 s-bold gren-color"> Yes</p>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">TDS %</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">10</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">TDS Amount </p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">₹300</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">PAN</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">BHJ44456G</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12 text-table-sec">
                                  <div className="top-head clearfix">
                                    <p className="pull-left s-sbold ft-sz-22 blu-color m-0">SMS</p>
                                    <p className="pull-right mb-0 ft-sz-22 s-bold gren-color"> Yes</p>
                                  </div>
                                  <div className="clearfix">
                                    <div className="left-sec-text pull-left">
                                      <p className="ft-sz-17 s-reg font-colr mb-1">ICIC XXXX</p>
                                    </div>
                                    <div className="right-sec-text pull-right">
                                      <p className="ft-sz-20 s-sbold blu-color mb-2">11-11-2020</p>
                                    </div>
                                  </div>
                                  <div className="clearfix">  
                                    <p className="ft-sz-16 font-colr s-reg mb-0 mt-2">Your a/c no. XXXXXXXXXXX5023 is debited for Rs. 580.00 on 29-11-2020 </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                  </div> 
                </div>

                <Modal
                  size="lg"
                  show={lgShow}
                  onHide={() => setLgShow(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  centered
                  className="doc-box-file-preview"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <img className="" src={recipt}/>
                  </Modal.Body>
                </Modal>
              
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i className="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={clog}
                  onHide={logClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="changelog-model-sec"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color"><span className="icon-round-info-button mr-4"></span> Change Log</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="clearfix">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Created</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Changed to Reimbursement</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Approved</td>
                        <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                      </tr>
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>

                <div className="bottom-save-btn clearfix">
                <div className="pull-left">
                  {/* <button className="next-bottom-btn btn ft-sz-21 s-bold white-color"> </button>
                  <button type="button" className="next-bottom-btn btn save-btn white-color">Back</button>
                  <button type="button" className="btn save-btn white-color">Download</button>
                  <button type="button" className="btn save-btn white-color drk-gry-bg">Delete </button> */}
                  <div className="check-box mt-3">
                    <input className="styled-checkbox" id="styled-checkbox-11" type="checkbox" value="value11" />
                    <label for="styled-checkbox-11" className="label-text blu-color s-reg ft-sz-20 mb-0">Save and move to next transaction</label>
                  </div>
                </div>
                  <div className="pull-right">
                    <div className="ready-btn">
                      <select>
                        <option value="audi" selected>Not Ready</option>
                        <option value="volvo">Ready</option>
                      </select> 
                    </div>  
                      <button type="button" className="btn save-btn white-color">Save</button>
                  </div>
                </div>
                <div className="bottom-message-sec">
                  <div className="main-bottom-comments-note-sec">
                   {
                    comment?<div className="bottom-comments-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-chat"></span></span>
                        <span className="ft-sz-32 s-sbold">Messages</span>
                      </div>
                      <div className="inner-comment-scroll">
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="gren-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">2 days ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter green-bg s-sbold">
                            <span>S</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">just now</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }

                  {
                    note?<div className="bottom-note-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-Group-2546"></span></span>
                        <span className="ft-sz-32 s-sbold">Notes</span>
                      </div>
                      <div className="inner-note-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="first-letter yellow-bg s-sbold">
                          <span>Y</span>
                        </div>
                        <div className="note-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                        </div>
                      </div>
                      <div className="inner-note-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="first-letter yellow-bg s-sbold">
                          <span>Y</span>
                        </div>
                        <div className="note-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                        </div>
                      </div>
                    </div>:null
                  }  

                  {
                    task?<div className="bottom-task-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-Group-2516"></span></span>
                        <span className="ft-sz-32 s-sbold">Tasks</span>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                          <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                            <p className="ft-sz-18 s-reg red-bg white-color color-div">Overdue</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-24" type="checkbox" value="value011" />
                          <label for="styled-checkbox-24" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                            <p className="ft-sz-18 s-reg yellow-bg white-color color-div">Due in 2 days</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-142" type="checkbox" value="value011" />
                          <label for="styled-checkbox-142" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }  


                  {
                    privatemessage?<div className="bottom-comments-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-chat-1"></span></span>
                        <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                      </div>
                      <div className="inner-comment-scroll">
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">2 days ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter red-bg s-sbold">
                            <span>S</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">just now</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }

                  </div>

                  <form>
                    <div className="input-group">
                      <div className="message-sec clearfix">
                        <div className="input-group">
                          <input type="text" className="form-control s-sbold ft-sz-22 font-colr pl-3" placeholder="@mention a user" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" className="active" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Comment</button>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Note</button>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Task</button>
                      </div>
                      <div className="message-sec note-input clearfix d-none">
                        <div className="input-group first">
                          <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Task title; @assignee" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" className="active" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <div className="input-group second task-due-date">
                          {/* <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Due date" aria-label="Recipient's username" aria-describedby="basic-addon2"/> */}
                          <span className="s-reg ft-sz-22 font-colr">Due date <span className="icon-calendar"></span></span>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Task</button>
                      </div>
                      <div className="message-sec note-input clearfix d-none">
                        <div className="input-group first">
                          <input type="text" className="form-control s-reg ft-sz-32 font-colr" placeholder="Title" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" className="" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="active" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <div className="input-group second">
                          <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Start typing" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Note</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="main-smartview-sec d-none">
            <div className="">
              <div className="col-xl-6 col-sm-12">
                <div className="inner-smartview-sec">
                  <div className="cross"><span>X</span></div>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                  <div className="smartview-drag-sec">
                    <div className="main-zoom-roted-sec">
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-refresh"></span></button>
                        <button className="btn"><span className="icon-refresh-11"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-magnifying-glass"></span></button>
                        <span className="ft-sz-22 s-reg blu-color">100%</span>
                        <button className="btn"><span className="icon-zoom-in"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-Group-2197"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-full-size1"></span></button>
                      </div>
                      <button type="button" className="btn upload-btn white-color s-bold ft-sz-21">Upload</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="sticky-footer d-none">
          <div className="container">
            <div className="text-center">
              <small>Copyright © Buktec 2021</small>
            </div>
          </div>
        </footer>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default moneylink;