import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function AddNew(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
            {/* <span className="icon-plus s-add-icon green-white-all-icon" onClick={() => props.addNew()}></span> */}
            <span class="icon-plus-circled cpointer add-plus" onClick={() => props.addNew()}></span>
            {/* <span class="icon-plus s-add-icon green-white-all-icon" onClick={() => props.addNew()}></span> */}
        </OverlayTrigger>
    </>
}
export default AddNew;