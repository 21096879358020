import React, { Suspense, useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory
} from 'react-router-dom';
import { connect } from "react-redux";
import Mousetrap from 'mousetrap';

// routes config
import routes from '../../Helpers/Routes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const TheContent = (props) => {
  const history = useHistory();
  // shortcut key function
  useEffect(()=>{
  
    Mousetrap.bind('ctrl+alt+h', () => {
       history.push('/dashboard')});
 
       Mousetrap.bind('ctrl+alt+c',()=>{
         history.push('/snapshot/evidence/create')});
 
         Mousetrap.bind('ctrl+alt+n', () => {
           history.push('/snapshot/evidence')});
 
           Mousetrap.bind('ctrl+alt+b', () => {
             history.push('/financial-institutes')});
 
             Mousetrap.bind('ctrl+alt+m', () => {
               history.push('/money-link')});
 
               Mousetrap.bind('ctrl+alt+r', () => {
                 history.push('/reports')});
 
                 Mousetrap.bind('ctrl+alt+x', () => {
                   history.push('/docbox')});
 
       return () => {
         Mousetrap.unbind('ctrl+alt+h');
         Mousetrap.unbind('ctrl+alt+c');
         Mousetrap.unbind('ctrl+alt+n');
         Mousetrap.unbind('ctrl+alt+b');
         Mousetrap.unbind('ctrl+alt+m');
         Mousetrap.unbind('ctrl+alt+r');
         Mousetrap.unbind('ctrl+alt+x');
       };
 
 },[history])

  const permit_route = routes.filter((route) => {
    /*
      check key is present in locastorage or not
    */
    if(route.module_id == 0){
      return true;
    }else if(route.module_id == 6){
      if(route.path.includes('/licenses')){
        return props.account_permissions.includes(20);
      }else if(route.path.includes('/profile-management')){
        return !!props.account_permissions.length;
      }
      return true;
    }else{
      return props.module_permissions.includes(route.module_id);
    }
  });
  return (
    // <main className="c-main">
      // <main className="content-wrapper">      
        <Suspense fallback={loading}>
          <Switch>
            {permit_route.map((route, idx) => {
                return route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      // <div>
                        <route.component {...props} />
                      // </div>
                    )} >
                  </Route>
                )
            })}
            <Redirect
              from="/"
              to={{
                pathname: "/home",
                state: { showalert: true }
              }}
            />
            {/* <Redirect from="/" to="/home" /> */}
          </Switch>
        </Suspense>      
    // </main>
  )
}



function mapStateToProps(state) {
  const all_value = state.Session || {};
  const module_permissions = all_value.module_permissions || [];
  const account_permissions = all_value.account_permissions || [];
  return {
    module_permissions,account_permissions
  };
}
export default connect(mapStateToProps)(React.memo(TheContent));