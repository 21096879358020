import React from 'react';
import Card from "react-bootstrap/Card";;
import Accordion from 'react-bootstrap/Accordion';
import EntityService from '../../../Services/ProfileManagement/EntityService';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { Modal, Button } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class AddMultipleEntity extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			license_list: [],
			available_licenses: [],
			entities: [],
			name: "",
			email: "",
			license_id: "",
			errors: {},
			used_license_ids: [],
			available_license_ids: [],
			lic_list_index: "",
			lic_index: ""
		};
	}


	getMultipleLicenses = () => {
		this.props.dispatch(setAppilcationLoder(true));
		EntityService.getMultipleLicenses().then(
			(data) => {
				if (data.response_code == 200) {
					let available_license_ids = [];

					for (let i in data.result.available_licenses) {
						for (let j in data.result.available_licenses[i].licenses) {
							available_license_ids.push(data.result.available_licenses[i].licenses[j].id);
						}
					}
					this.setState({
						license_list: data.result.available_licenses,
						available_licenses: data.result.available_licenses,
						available_license_ids
					});
				}
				this.props.dispatch(setAppilcationLoder(false));
			});
	}

	handleOnChange = (e) => {
		const { value, name } = e.target
		this.setState({ [name]: value })
	}

	licenseSelected = (e, lic_list_index, lic_index) => {
		this.setState({
			license_id: e.target.value,
			lic_list_index,
			lic_index
		})
	}

	addEntity = () => {
		if (!/\S+@\S+\.\S+/.test(this.state.email)) {
			this.setState({ errors_email: 'Email address is invalid' });
			return true;
		} else {
			this.setState({ errors_email: '' });
		}
		let entity = {
			"name": this.state.name,
			"email": this.state.email,
			"account_license_id": this.state.license_id,
			"end_date": this.state.license_list[this.state.lic_list_index].licenses[this.state.lic_index].end_date,
			"license_name": this.state.license_list[this.state.lic_list_index].licenses[this.state.lic_index].license_name,
			"plan_name": this.state.license_list[this.state.lic_list_index].name
		}
		let entities = this.state.entities;
		entities.push(entity);

		let license_list = this.state.license_list;
		let license = license_list[this.state.lic_list_index];
		license.licenses = license.licenses.filter(item => item.id != this.state.license_id);
		license_list[this.state.lic_list_index] = license;

		this.setState({
			"name": "",
			"email": "",
			"license_id": "",
			license_list: []
		},
			() => { this.setState({ license_list: license_list }) }
		)
	}

	removeEntity = (entity_index, account_license_id, is_license_consumed) => {
		let entities = this.state.entities;
		entities.splice(entity_index, 1);
		if (is_license_consumed) {
			/*
			revert add licenses from available licenses
			*/
		}
		this.setState({
			entities
		})
	}

	createMultipleEntity = () => {
		if (this.state.entities.length == 0) {
			return true;
		}
		this.props.dispatch(setAppilcationLoder(true));
		let data = {
			entities: this.state.entities.map(entity => {
				let new_entity = {
					"name": entity.name,
					"email": entity.email,
					"account_license_id": entity.account_license_id
				}
				return new_entity;
			})
		};
		EntityService.addMultipleEntity(data).then(
			(data) => {
				if (data.response_code == 200) {
					toast.success(data.message);
					this.setState({
						entities: []
					})
				} else if (data.response_code == 400) {
					this.getMultipleLicenses();
				} else {
					toast.error(data.message);
				}
				this.props.dispatch(setAppilcationLoder(false));
				this.props.refreshInviteList()
			});
	}

	componentDidMount() {
		this.getMultipleLicenses();
	}

	render() {
		return <>
			{/* <Modal
                show="show"
                onHide={() => this.props.toggleMultipleEntity(false)}
                backdrop="static"
                // keyboard={false}
                centered
                className="popup-model change-log-model"
            > */}

			<div class="fade modal-backdrop show" style={{ zIndex: 1050 }}></div>

			<div className="multiple-entity-popup">
				{/* <Modal.Header closeButton onHide={() => this.props.toggleMultipleEntity(false)}>
					<Modal.Title>Multiple Entities</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					<div className="row">
						<div className='col-10'>
							<h3 className='mb-0'>Multiple Entities</h3>
						</div>
						<div className='col-2'>
							{/* <h3 className='mb-0'><span class="icon-Cross-with-grey-circle" onClick={() => this.props.toggleMultipleEntity(false)}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></h3> */}
 							<h3 className='mb-0 pmep-crose-icon'><span class="icon-plus1 gray-icon" onClick={() => this.props.toggleMultipleEntity(false)}></span></h3>
						</div>
					</div>

					<div className="row mt-3">
						<div className="col-12 form-group">
							<input type="text" className="form-control" placeholder="Entity Name" value={this.state.name} name="name" onChange={this.handleOnChange} />
						</div>
						<div className="col-12 form-group mb-0">
							<input type="email" className="form-control" placeholder="E-mail" value={this.state.email} name="email" onChange={this.handleOnChange} />
							<small className="form-text text-muted">{this.state.errors_email}</small>
						</div>
					</div>

					{/* Licenses list */}
					<div className="row mt-3">
						<div className='col-12'>
							<h5 className="gray-text">Select License</h5>
							<p className="mb-0">{!this.state.license_list.length ? "License not available" : ""}</p>
						</div>

						<div className='col-12 mt-1'>
							{/* Accordion list */}
							<div className="multipleEntityAccordion">
								<Accordion>
									{
										this.state.license_list.map((licence, lic_list_index) => {
											return <Card key={lic_list_index}>
												<Accordion.Toggle as={Card.Header} eventKey={lic_list_index + 1}>
													<span className="label-accordion">{licence.name}</span>
													<div className="iconbar-accordion">
														<span className="license-qty">Quantity</span>
														<span className="license-qty-count">{licence.licenses.length}</span>
														<span className="icon-arrow-down-sign-to-navigate down-arrow"></span>
													</div>
												</Accordion.Toggle>

												<Accordion.Collapse eventKey={lic_list_index + 1}>
													<Card.Body class="card-body">
														{licence.licenses.map((lic, lic_index) => {
															if (this.state.used_license_ids.includes(lic.id)) {
																return "";
															}

															return <div className="licenses-list" key={"_" + lic_list_index + "_" + lic_index}>
																<p className="license-name mb-0">{lic.license_name + "-" + licence.name}</p>

																<p className="licenseExpire mb-0">
																	<span className="license-expires">Expires On </span>
																	<span className="license-date">{lic.end_date}</span>
																</p>

																<div className="sup-cust-check btn-bar-save-text">
																	<input className="styled-checkbox" id={"styled-checkbox-" + lic_list_index + lic_index} name="radio" type="radio" value={lic.id} onClick={(e) => this.licenseSelected(e, lic_list_index, lic_index)} />
																	<label for={"styled-checkbox-" + lic_list_index + lic_index}></label>
																</div>
																{/* <div className="check-box">
																	<input className="styled-checkbox" id={"styled-checkbox-" + lic_list_index + lic_index} name="radio" type="radio" value={lic.id} onClick={(e) => this.licenseSelected(e, lic_list_index, lic_index)} />
																	<label htmlFor={"styled-checkbox-" + lic_list_index + lic_index}></label>
																</div> */}
															</div>
														})}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										})
									}
								</Accordion>
							</div>
							{/* End Accordion list */}

							<div class="text-right my-2">
								<Button type="button" variant={"success apply_btn_new " + (this.state.license_list.length ? "" : " d-none ")} disabled={!(!!this.state.name && !!this.state.email && !!this.state.license_id)} onClick={this.addEntity}>Add</Button>
							</div>

							<h5 className="gray-text mb-0">Added Entities</h5>
						</div>
					</div>
					{/* End Licenses list */}


					{/* Added Entities Card*/}
					{
						this.state.entities.map((entity, index) => {
							return <>
								<div className={"multiple-entity-cards " + (this.state.available_license_ids.includes(parseInt(entity.account_license_id)) ? " " : "reb-card")} key={index}>
									<span class="ss-cross-md" onClick={() => this.removeEntity(index, entity.account_license_id, this.state.available_license_ids.includes(parseInt(entity.account_license_id)))}>
										<span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
									</span>

									<div className="row">
										<div class="col-6">
											<div class="pmec-name-mail">
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{entity.name}</Tooltip>}>
													<p class="pmec-name">{entity.name}</p>
												</OverlayTrigger>
												<p class="pmec-mail">{entity.email}</p>
											</div>
										</div>
										<div class="col-6">
											<div class="pmec-l-edate">
												<p class="pmec-l">{entity.license_name + "-" + entity.plan_name}</p>
												<p class="pmec-edate"><span>Expires On: </span>{entity.end_date}</p>
											</div>
										</div>
									</div>

									{!this.state.available_license_ids.includes(parseInt(entity.account_license_id)) &&
										<small className="size-xiii error_red mt-1">This license is already consumed. Please remove entity and select new license</small>
									}
								</div>
							</>
						})
					}
					{/* End Added Entities Card*/}


					{/* Note and Save btn*/}
					<div className="row mt-3">
						<div className="col-lg-8">
							<small className="size-xiv shadow-light-box mb-0">Note: Once you save multiple entities an invite shall be sent to the user on email.</small>
						</div>
						<div className="col-lg-4 d-flex justify-content-end align-items-end">
							<Button type="button" variant="success apply_btn_new" disabled={this.state.entities.length == 0} onClick={this.createMultipleEntity}>Save</Button>
						</div>
					</div>
					{/* End Note and Save btn*/}
				</Modal.Body>
			</div>
			{/* </Modal> */}
		</>
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const module_permissions = all_value.module_permissions || [];
	const account_permissions = all_value.account_permissions || [];
	const entity_list = all_value.entity_list || [];
	return {
		module_permissions, account_permissions, entity_list
	};
}

export default connect(mapStateToProps)(AddMultipleEntity);