import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import logo from '../../Assets/img/logo.png';

import { connect } from "react-redux";
import { register } from "../../Redux/Actions/Auth";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../../Services/AuthService";

import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../Utils/Config';
import NumberTypeInputBox from "../../Components/BoilerPlate/InputNumber";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const re = /^[0-9\b]+$/;

const re_account_email = /^[ A-Za-z0-9.@-]+$/;

class ForgetUsername extends Component {
  constructor(props) {
    super(props);
    this.handleForgetUsername = this.handleForgetUsername.bind(this);
    this.onChangeEmailId = this.onChangeEmailId.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);

    this.state = {
      accountId: "",
      username: "",
      emailId: "",
      number: "",
      dialCode: "+91",
      flag: "https://storage.googleapis.com/buktec_static/flags/IN.png",
      incorrect_email: false,
      incorrect_account_id: false,
      required_account_id: false,
      required_email_id: false,
      reason: {},
      incorrect_no: false,
      loading: false,
      completePage: false,
      timer: 5,

      registerPage: true,
      otpPage: false,
      registerComplete: false,
      showdialCode: false,

      dialCodeId: 1,
      dialCode: "+91",
      countryListing: []
    };
  }

  onChangeEmailId(e) {
    this.setState({
      emailId: e.target.value,
    });
  }

  onChangeAccountId = (e) => {
    if (e.target.value === "" || re_account_email.test(e.target.value)) {
      this.setState({
        accountId: e.target.value
      });
    }
    else {
      toast.error("Please enter A-Z 0-9 @ - only")
    }
  }

  onChangeNumber(e) {
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        number: e.target.value,
      });
    }
  }

  toggleDialCode = () => {
    this.setState({
      showdialCode: !this.state.showdialCode,
    });
  }

  handleForgetUsername(e) {
    e.preventDefault();
    this.setState({
      loading: false,
      required_account_id: false,
      required_email_id: false,
      incorrect_account_id: false,
      incorrect_no: false,
      incorrect_email: false
    });
    if (this.state.accountId === "") {
      this.setState({ required_account_id: true })
      return;
    }

    if (!isEmail(this.state.accountId)) {
      if (isNaN(this.state.accountId)) {
        this.setState({
          incorrect_account_id: true,
        });
        return false;
      } else if (this.state.accountId.length != 8) {
        this.setState({
          incorrect_account_id: true,
        });
        return false;
      }
    }

    if (this.state.emailId === "" && this.state.number === "") {
      this.setState({ required_email_id: true })
      return false;
    }


    if (this.state.number.length != 0 && this.state.number.length != 10) {
      this.setState({
        incorrect_no: true,
      });
      return false;
    }

    if (this.state.emailId.length != 0 && !isEmail(this.state.emailId)) {
      this.setState({
        incorrect_email: true,
      });
      return false;
    }

    if (this.state.number.length == 0 && this.state.emailId.length == 0) {
      return false;
    }


    this.setState({
      loading: true
    });

    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }


  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({
      recaptchaToken: recaptchaToken,
    });
    if (this.state.loading) {
      this.callForgetUNAPI();
    }

  }

  callForgetUNAPI = () => {
    const { dispatch, history } = this.props;
    AuthService.forgetUsername(this.state.accountId, this.state.emailId, this.state.number, this.state.recaptchaToken, this.state.dialCodeId).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({ completePage: true }, () => {
            this.countDown()
          })
          // toast.success(data.message);
          // history.push("/login");
        } else {
          toast.error(data.message)
          // toast.error(data.reason.email ? data.reason.email : data.reason.mobile_number);
          this.setState({
            loading: false,
            reason: data.reason
          });
        }
      });
  }

  tick = () => {
    if (this.state.timer > 0) {
      this.setState({ timer: this.state.timer - 1 }, () => {
        this.countDown()
      })
    } else {
      this.props.history.push("/login");
    }
  }

  countDown = () => {
    setTimeout(this.tick, 1000);
  }

  getCountryListing = () => {
    AuthService.countryListing().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            countryListing: data.result.dialcodes
          });
        }
      });
  }

  componentDidMount() {
    this.getCountryListing();
    loadReCaptcha(RECAPTCHA_SITE_KEY);
  }

  setDailCode = (country) => {
    this.setState({
      dialCodeId: country.id,
      dialCode: country.dialCode,
      flag: country.flag,
      showdialCode: !this.state.showdialCode,
    });
  }

  render() {
    const { message } = this.props;

    return (<>
      <div className="container-fluid login-container">
        <div className="row no-gutters">

          {/* login-logo */}
          <div className="col-lg-6 col-md-6">
            <div className="login-logo">
              <img src={logo} className="img-fluid onboarding-logo" alt="logo" />
            </div>
          </div>
          {/* End login-logo */}

          {/* Form */}
          <div className="col-lg-6 col-md-6">
            <div className="onboarding-form">
              <div className="onboarding-form-width">

                <div className="d-flex">
                  <ul className="process_step_bar progres-li-sm mb-3">
                    <li className={!this.state.completePage ? "dot_process" : "active_process"}>Retrieve Username</li>
                    <li className={this.state.completePage ? "active_process" : ""}>Complete</li>
                    {/* <li className="active_process">Complete</li> */}
                  </ul>
                </div>

                {/* Retrieve Username form  */}
                {!this.state.completePage ?
                  <>
                    <h3 className="login-title">Forgot Username</h3>

                    <form>
                      <div className="form-group">
                        <lable className="lable-input">Account Code / Account Email<span className="red-star">*</span></lable>
                        <input type="text"
                          className={"form-control " + ((this.state.required_account_id || this.state.incorrect_account_id) ? "wrong-red" : "")}
                          name="accountId"
                          value={this.state.accountId}
                          onChange={(e) => this.onChangeAccountId(e)} />
                        {this.state.required_account_id && <small className="form-error-text">Account Code / Account Email ID is required.</small>}
                        {this.state.incorrect_account_id && <small className="form-error-text">Incorrect Account Code / Account Email ID</small>}
                      </div>

                      <div className="form-group">
                        <lable className="lable-input">User Email ID<span className="red-star">*</span></lable>
                        <input type="text"
                          className={"form-control " + ((this.state.incorrect_email || this.state.required_email_id) ? "wrong-red" : "")}
                          name="emailId"
                          value={this.state.emailId}
                          onChange={this.onChangeEmailId} />
                        {this.state.required_email_id && <small className="form-error-text">Email ID or Mobile Number required.</small>}
                        {this.state.incorrect_email && <small className="form-error-text">Incorrect Email ID</small>}
                      </div>


                      {/* <div className="or-text">
                        <span>OR</span>
                      </div> */}

                      <div className="or-text">
                        <div className="or-hr-line"></div>
                        {/* <div className="or-hr-text">OR</div> */}
                        <lable className="lable-input or-hr-text">OR</lable>
                        <div className="or-hr-line"></div>
                      </div>

                      {/* Mobile Number */}
                      <div className="form-group">
                        <lable className="lable-input">User Mobile Number</lable>

                        <div className="reg_mob_no">

                          <div className="flag-toggle">
                            <div className="form-control btn dropdown-toggle flag-dropdown" data-toggle="dropdown" onClick={this.toggleDialCode} aria-haspopup="true" aria-expanded="true">
                              <span><img src={this.state.flag} /> {this.state.dialCode}</span>
                            </div>

                            <ul className={"dropdown-menu " + (this.state.showdialCode ? "show" : "")}>
                              {
                                this.state.countryListing.map((country) =>
                                  <li key={country.isoCode} onClick={() => this.setDailCode(country)} className={"dropdown-item " + (country.dialCode == this.state.dialCode ? "active" : "")}><img src={country.flag} /> {country.name} {country.dialCode}</li>
                                )
                              }
                            </ul>
                          </div>

                          <div className="flex-fill">
                            <input id="phone"
                              name="phone"
                              type="tel"
                              className={"form-control " + (this.state.incorrect_no ? "wrong-red" : "")}
                              name="number"
                              value={this.state.number}
                              onChange={this.onChangeNumber} />
                          </div>
                        </div>

                        {this.state.required_email_id && <small className="form-error-text">Email ID or Mobile Number required.</small>}
                        {this.state.incorrect_no && <small className="form-error-text">Incorrect Mobile Number</small>}
                      </div>




                      <button type="submit" onClick={this.handleForgetUsername} className={"btn btn-success btn-block apply_btn_new position-relative mt-4" + (this.state.loading ? "" : "")}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}Retrieve Username
                      </button>

                      <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={RECAPTCHA_SITE_KEY}
                        action='action_name'
                        verifyCallback={this.verifyCallback}
                      />

                      <div className="login-p-text">
                        <p>Back to <Link className="s-sbold blue-text" to="login">Login </Link></p>
                      </div>

                    </form>
                  </>
                  : null
                }
                {/* End - Retrieve Username form  */}


                {/* Complete */}
                {this.state.completePage ?
                  <div className="complet-form">
                    <span className="icon-check-mark-1 done-all-icon"></span>
                    <h3>All done!</h3>

                    <p className="mt-3 mb-0">You will be re directed to <span className="s-sbold blue-text">login in {this.state.timer} seconds</span></p>
                    <p>if not redirected please <a className="s-sbold blue-text" href="">click here</a></p>
                  </div>
                  : null
                }
                {/* End - Complete */}

              </div>
            </div>
          </div>
          {/* End Form */}
        </div>
      </div>
    </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(ForgetUsername);