import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import InvoiceService from '../../Services/Invoices/InvoiceService';
import { changeDateFormate } from '../../Helpers/DateFormat';
import Invoices from './Invoices';
import CreateInvoiceView from './CreateInvoiceView';
import { setInvoiceLoadingStatus, setInvoiceWidgets } from "../../Redux/Actions/Invoices";

class InvoiceView extends Component {


  constructor(props) {
    super(props);

    this.state = {
      invoice_list: [],
      creaters: [],

      filter_by: 0,


      linked: 0,
      not_ready: 0,
      page_no: 0,
      processed: 0,
      proforma: 0,
      ready: 0,
      synched: 0,
      total: 0,
      total_pages: 0,

      total_count: 0,
      per_page_items: 5,
      page_no: 1,

      year_type: 1,
      start_date: new Date("2020-01-01"),
      end_date: new Date("2021-11-01"),

      filter_card: 0,
      filter: 0,
      search: "",
      invoice_type: 0,
      invoice_number: "",
      customer_name: "",
      from_amount: 0,
      to_amount: 0,
      payment_status: 0,
      created_by: 0,
      is_star: false,
      sort_by: 1,
      sort_order: 2,

      entity_id: 1,

      show_filter_popup: false
    };
  }

  onCardSelected = (task_card_filter) => {
    this.setState({
      filter_card: task_card_filter,
      page_no: 1
    }, () => this.getInvoiceList())
  }

  handlePageChange = (page_no) => {
    this.setState({
      page_no: page_no
    }, () => this.getInvoiceList())
  }

  financialYearChanged = (year_type, start_date, end_date) => {
    this.setState({
      year_type,
      start_date,
      end_date,
      page_no: 1
    }, () => {
      this.getInvoiceList();
      this.getInvoiceWidgetCount();
    })
  }

  refreshItem = () => {
    this.getInvoiceList();
    this.getInvoiceWidgetCount();
  }

  onCardSelected = (task_card_filter) => {
    this.setState({
      filter_card: task_card_filter,
      page_no: 1
    }, () => this.getInvoiceList())
  }

  onSortClick = (sort_order, sort_by) => {
    this.setState({
      sort_order,
      sort_by
    }, () => this.getInvoiceList())
  }
  searchFilter = (searchtxt) => {
    this.setState({
      search: searchtxt,
      page_no: 1
    }, () => this.getInvoiceList(1))
  }

  applyFilter = (type) => {
    this.setState({
      page_no: 1
    }, () => {
      type == "apply" ? this.getInvoiceList(2) : this.getInvoiceList(0);
    })
  }

  getInvoiceData = () => {
    InvoiceService.getInvoiceData().then(
      (data) => {
        this.setState({
          isloading: false
        })
        if (data.response_code == 200) {
          this.setState({
            creaters: data.result.created_by
          });
        } else {
          toast.error(data.message);
        }
      });
  }


  getInvoiceWidgetCount = () => {
    let filter_data = {
      "start_date": changeDateFormate(this.state.start_date),
      "end_date": changeDateFormate(this.state.end_date)
    };
    this.props.dispatch(setInvoiceLoadingStatus(true));
    InvoiceService.getInvoiceWidgetCount(filter_data).then(
      (data) => {
        this.props.dispatch(setInvoiceLoadingStatus(false));
        if (data.response_code == 200) {
          this.props.dispatch(
            setInvoiceWidgets(data.result.invoice_count)
          );

        } else {
          toast.error(data.message);
        }
      });
  }

  getInvoiceList = (filter_by = this.state.filter_by) => {
    this.setState({
      filter_by: filter_by,
      invoice_list: []
    })
    this.props.dispatch(setInvoiceLoadingStatus(true));
    let filter_data = {
      "per_page_items": this.state.per_page_items,
      "page_no": this.state.page_no,
      "date_filter": {
        "start_date": changeDateFormate(this.state.start_date),
        "end_date": changeDateFormate(this.state.end_date)
      },
      "filter_card": this.state.filter_card,
      "filter": filter_by,
      "search": this.state.search,
      "filter_box": {
        "type": !!this.props.invoice_type ? parseInt(this.props.invoice_type) : null,
        "invoice_number": !!this.props.invoice_number ? this.props.invoice_number : null,
        "name": !!this.props.customer_name ? this.props.customer_name : null,
        "from_amount": !!this.props.from_amount ? this.props.from_amount : null,
        "to_amount": !!this.props.to_amount ? this.props.to_amount : null,
        "payment_status": !!this.props.payment_status ? parseInt(this.props.payment_status) : null,
        "created_by": !!this.props.created_by ? this.props.created_by : null,
        "is_star": this.props.is_star !== '' ? parseInt(this.props.is_star) : null
      },
      "sort_by": 1,
      "sort_order": 2
    };
    InvoiceService.getInvoiceList(filter_data).then(
      (data) => {
        this.props.dispatch(setInvoiceLoadingStatus(false));
        if (data.response_code == 200) {
          this.setState({
            invoice_list: data.result.invoices,

            has_next: data.result.has_next,
            has_previous: data.result.has_previous,

            page_no: data.result.page_no,
            total_count: data.result.total_count,
            total_pages: data.result.total_pages

          });
        } else {
          toast.error(data.message);
        }
      });
  }


  componentDidMount() {
    this.getInvoiceData();
    this.getInvoiceWidgetCount();
    this.getInvoiceList();
  }

  makeRemoveStar = (invoice_id, changed_star) => {
    let invoice_list = this.state.invoice_list;
    let index = invoice_list.findIndex(invoice => invoice.id == invoice_id);
    let invoice = invoice_list[index];
    invoice.is_star = changed_star;
    invoice_list[index] = invoice;
    this.setState({
      invoice_list
    })
  }

  newInvoice = (invoice, is_update) => {
    if (is_update) {
      let invoice_list = this.state.invoice_list;
      let index = invoice_list.findIndex(inv => inv.id == invoice.id);
      invoice_list[index] = invoice;
      this.setState({
        invoice_list: invoice_list
      })
    } else
      this.setState({
        invoice_list: [invoice, ...this.state.invoice_list]
      })
  }
  render() {
    return (
      <div>
        <Switch>
          <Route path="/invoices/create/:invoice_id?"
            render={() => {
              return <CreateInvoiceView
                invoice_list={this.state.invoice_list}
                applyFilter={this.applyFilter}
                creaters={this.state.creaters}
                handlePageChange={this.handlePageChange}

                newInvoice={this.newInvoice}
                has_next={this.state.has_next}
                has_previous={this.state.has_previous}
                per_page_items={this.state.per_page_items}
                page_no={this.state.page_no}
              />
            }
            }
            exact />
          <Route path="/invoices"
            render={() => {
              return <Invoices
                invoice_list={this.state.invoice_list}
                applyFilter={this.applyFilter}
                creaters={this.state.creaters}
                total_count={this.state.total_count}
                financialYearChanged={this.financialYearChanged}
                onCardSelected={this.onCardSelected}
                searchFilter={this.searchFilter}
                makeRemoveStar={this.makeRemoveStar}
                refreshItem={this.refreshItem}
                handlePageChange={this.handlePageChange}

                sort_by={this.state.sort_by}
                sort_order={this.state.sort_order}
                onSortClick={this.onSortClick}
                filter_card={this.state.filter_card}

                changeTaskStatus={this.changeTaskStatus} />
              }
            }
            exact />
        </Switch>
      </div>
    );
  }

}

function mapStateToProps(state) {
  const all_value = state.Invoices || {};
  const invoice_type = all_value.invoice_type || '';
  const invoice_number = all_value.invoice_number || '';
  const customer_name = all_value.customer_name || '';
  const from_amount = all_value.from_amount || '';
  const to_amount = all_value.to_amount || '';
  const payment_status = all_value.payment_status || '';
  const created_by = all_value.created_by || '';
  const is_star = all_value.is_star || '';
  const changed_star = all_value.changed_star || 0;
  const invoice_id = all_value.invoice_id || null;
  return {
    is_star, invoice_type, invoice_number, customer_name, from_amount, to_amount,
    payment_status, created_by, changed_star, invoice_id
  };
}
export default connect(mapStateToProps)(InvoiceView);