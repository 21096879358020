import React from 'react';
import { Dropdown,Button } from 'react-bootstrap';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";;
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal'; 
import { Link } from 'react-router-dom'; 

function profileentityCa(){
   const [status,setStatus]=React.useState(false)
   const [sponser,setSponser]=React.useState(false)

   const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold m-0 font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li className="">
                  <Dropdown>
                  <img src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
      <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
        <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Profile Management</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Profile Management <span className="icon-Information green-icon green-icon-head"></span></h2>
                </div>
                <div className="pull-right">
                  <button className="lic-bill-btn btn ft-sz-22 s-bold white-color">License {'&'} Billing</button>
                </div>
              </div>

              
              {/* CA Profile */}
              <div className="main-my-account-sec">
                <h2 className="s-sbold blu-color d-inline m-0 align-middle">My Account</h2>
                <button type="button" class="btn btn-labeled ft-sz-18 s-reg"><span class="btn-label"><span class="icon-Group-2534"></span></span>CA Ayush Pal</button>
                <a href="" className="edit-btn"><span class="icon-edit"></span></a>
                <a href="" className="cross"><span className="cross-btn">X</span></a>
                <hr/>
                <div className="my-account-form">
                  <div className="row">
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Account ID</p>
                        <input type="text" className="form-control input-bg-gray" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Account email</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Firm Name</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Phone number</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">GST number</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-5 col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Account Address</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">City</p>
                        <select className="form-control">
                          <option value="volvo">Nagpur</option>
                          <option value="saab">Saab</option>
                          <option value="vw">VW</option>
                          <option value="audi">Audi</option>
                        </select>             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">State</p>
                        <select className="form-control">
                          <option value="volvo">Maharashtra</option>
                          <option value="saab">Saab</option>
                          <option value="vw">VW</option>
                          <option value="audi">Audi</option>
                        </select>             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Country</p>
                        <select className="form-control">
                          <option value="volvo">India</option>
                          <option value="saab">Saab</option>
                          <option value="vw">VW</option>
                          <option value="audi">Audi</option>
                        </select>             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Pincode</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-3 col-md-5">
                      <div className="main-details-upload-sec">
                        <p className="lable-input font-colr m-0 s-sbold">Logo</p>
                        <div className="inner-details-upload-sec">
                        <div class="cross"><span>X</span></div>
                          <span class="icon-Group-2328"></span>
                          <p className="font-colr ft-sz-18 upload-text">Upload Your Logo</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <p class="ft-sz-18 blu-color prefer-img-size mt-2">Preferred image size; 240px x 240px 272 DPI Maximum Size of 1MB.</p> */}
                </div>
              </div>



              <div className="profile-save-btn clearfix">
                <button type="button" className="btn save-btn ft-sz-22 white-color s-bold pull-right btn-ani">Save</button>
              </div>
              

              {/* CA Profile */}
              <div className="main-entity-user-sec">
                  <div className="main-add-entity-btn">
                   <div className="inner-add-entity-btn">
                   <button type="button" className="btn add-entity-btn ft-sz-22 s-bold white-color"><span class="icon-plus1 white-color"></span> Single Entity</button>
                   </div>
                   <div className="inner-add-entity-btn">
                    <button type="button" className="btn add-entity-btn ft-sz-22 s-bold white-color" onClick={()=>setStatus(true)}><span class="icon-plus1 white-color"></span> Multiple Entities</button>
                    {
                    status? <div className="main-add-multiple-entity-sec">
                      <div className="cross" onClick={()=>setStatus(false)}><span class="icon-plus1"></span></div>
                      <p className="s-bold ft-sz-22 blu-color head">Multiple Entities</p>
                      {/* <p className="sub-head ft-sz-17 s-reg font-colr">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Entity Name" value="" />
                      </div>
                      <div class="form-group">
                        <input type="email" class="form-control" placeholder="E-mail" value="" />
                      </div>
                      <div className="main-select-licence-sec">
                        <p className="ft-sz-18 s-bold font-colr">Select License</p>
                        <Accordion>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="select-header clearfix">
                             <div className="head ft-sz-22 s-sbold white-color pull-left">Basic</div> 
                             <div className="select-header-right-side pull-right">
                               <span className="ft-sz-13 s-reg white-color">Quantity</span>
                               <span className="select-count blu-color s-bold ft-sz-24">3</span>
                               <span class="icon-down icon-arrow-down-sign-to-navigate"></span>
                             </div> 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div className="inner-card-sec select">
                                  <div className="head-sec clearfix">
                                    <div className="head pull-left">
                                      <span className="ft-sz-26 s-sbold blu-color">Basic</span>
                                      <span className="month-yer-sec ft-sz-17 s-sbold font-colr">Monthly</span>
                                    </div>
                                    <div className="check-box pull-right">
                                     <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                     <label for="styled-checkbox-1"></label>
                                    </div>
                                  </div>
                                  <div className="main-addon-expire-sec">
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12 addon-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Add Ons</div>
                                        <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                        <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 expire-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Expires On</div>
                                        <div className="date-expire blu-color s-sbold font-colr">23-02-2021</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="inner-card-sec">
                                  <div className="head-sec clearfix">
                                    <div className="head pull-left">
                                      <span className="ft-sz-26 s-sbold blu-color">Basic</span>
                                      <span className="month-yer-sec ft-sz-17 s-sbold font-colr">Monthly</span>
                                    </div>
                                    <div className="check-box pull-right">
                                     <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
                                     <label for="styled-checkbox-2"></label>
                                    </div>
                                  </div>
                                  <div className="main-addon-expire-sec">
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12 addon-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Add Ons</div>
                                        <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                        <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 expire-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Expires On</div>
                                        <div className="date-expire blu-color s-sbold font-colr">23-02-2021</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="select-header clearfix">
                             <div className="head ft-sz-22 s-sbold white-color pull-left">Premium</div> 
                             <div className="select-header-right-side pull-right">
                               <span className="ft-sz-13 s-reg white-color">Quantity</span>
                               <span className="select-count blu-color s-bold ft-sz-24">2</span>
                               <span class="icon-down icon-arrow-down-sign-to-navigate"></span>
                             </div> 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <div className="inner-card-sec">
                                  <div className="head-sec clearfix">
                                    <div className="head pull-left">
                                      <span className="ft-sz-26 s-sbold blu-color">Basic</span>
                                      <span className="month-yer-sec ft-sz-17 s-sbold font-colr">Monthly</span>
                                    </div>
                                    <div className="check-box pull-right">
                                     <input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3" />
                                     <label for="styled-checkbox-3"></label>
                                    </div>
                                  </div>
                                  <div className="main-addon-expire-sec">
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12 addon-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Add Ons</div>
                                        <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                        <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 expire-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Expires On</div>
                                        <div className="date-expire blu-color s-sbold font-colr">23-02-2021</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                        <button type="button" class="add-btn btn apply-btn ft-sz-22 white-color s-bold">Add</button>
                        <div className="clearfix"></div>
                        <div className="main-added-entities-sec">
                          <p class="ft-sz-18 s-bold font-colr">Added Entities</p>
                          <div className="inner-added-entities">
                            <div className="row">
                              <div className="col-lg-8 col-xs-12 name-sec">
                                <div className="head">
                                  <span className="ft-sz-26 s-sbold font-colr">RK Pharma</span>
                                  <p className="ft-sz-14 s-sbold font-colr">rkpharma@gmail.com</p>
                                </div>
                                <div className="addon-sec mb-1">
                                  <p className="head ft-sz-12 s-sbold font-colr m-0 d-inline-block">Add Ons</p>
                                  <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                  <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                </div>
                                <div className="expire-sec">
                                  <p className="head ft-sz-12 s-sbold font-colr m-0 d-inline-block">Expires On:</p>
                                  <span className="ft-sz-14 s-sbold font-colr ml-2">23-02-2021</span>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xs-12">
                                <div className="pkg-sec">
                                  <p className="ft-sz-15 s-bold font-colr mb-0">Premium</p>
                                  <span className="month-yer-sec s-reg white-color">Annual</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inner-added-entities">
                            <div className="row">
                              <div className="col-lg-8 col-xs-12 name-sec">
                                <div className="head">
                                  <span className="ft-sz-26 s-sbold font-colr">RK Pharma</span>
                                  <p className="ft-sz-14 s-sbold font-colr">rkpharma@gmail.com</p>
                                </div>
                                <div className="addon-sec mb-1">
                                  <p className="head ft-sz-12 s-sbold font-colr m-0 d-inline-block">Add Ons</p>
                                  <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                  <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                </div>
                                <div className="expire-sec">
                                  <p className="head ft-sz-12 s-sbold font-colr m-0 d-inline-block">Expires On:</p>
                                  <span className="ft-sz-14 s-sbold font-colr ml-2">23-02-2021</span>
                                </div>
                              </div>
                              <div className="col-lg-4 col-xs-12">
                                <div className="pkg-sec">
                                  <p className="ft-sz-15 s-bold font-colr mb-0">Premium</p>
                                  <span className="month-yer-sec s-reg white-color">Annual</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="main-note-save-sec">
                          <div className="row">
                            <div className="col-lg-8 col-xs-12">
                              <div className="note-sec ft-sz-13 font-colr s-reg">Note: Once you save multiple entities an invite shall be sent to the user on email.</div>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <button type="button" class="save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }
                   </div>
                   
                  <div className="inner-add-entity-btn">
                   <button type="button" className="btn add-entity-btn ft-sz-22 s-bold white-color" onClick={()=>setSponser(true)}> Sponsor Entity</button>
                   {
                    sponser? <div className="main-add-multiple-entity-sec sponser-entity-sec">
                      <div className="cross" onClick={()=>setSponser(false)}><span class="icon-plus1"></span></div>
                      <p className="s-bold ft-sz-22 blu-color head">Sponsor Entity</p>
                      <p className="sub-head ft-sz-17 s-reg font-colr">To sponsor an Entity to your account, create authorization code and share it with the Business</p>
                      
                      <div className="main-select-licence-sec">
                        <p className="ft-sz-18 s-bold font-colr">Select License</p>
                        <Accordion>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" className="select-header clearfix">
                             <div className="head ft-sz-22 s-sbold white-color pull-left">Basic</div> 
                             <div className="select-header-right-side pull-right">
                               <span className="ft-sz-13 s-reg white-color">Quantity</span>
                               <span className="select-count blu-color s-bold ft-sz-24">3</span>
                               <span class="icon-down icon-arrow-down-sign-to-navigate"></span>
                             </div> 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div className="inner-card-sec select">
                                  <div className="head-sec clearfix">
                                    <div className="head pull-left">
                                      <span className="ft-sz-26 s-sbold blu-color">Basic</span>
                                      <span className="month-yer-sec ft-sz-17 s-sbold font-colr">Monthly</span>
                                    </div>
                                    <div className="check-box pull-right">
                                     <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                     <label for="styled-checkbox-1"></label>
                                    </div>
                                  </div>
                                  <div className="main-addon-expire-sec">
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12 addon-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Add Ons</div>
                                        <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                        <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 expire-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Expires On</div>
                                        <div className="date-expire blu-color s-sbold font-colr">23-02-2021</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="inner-card-sec">
                                  <div className="head-sec clearfix">
                                    <div className="head pull-left">
                                      <span className="ft-sz-26 s-sbold blu-color">Basic</span>
                                      <span className="month-yer-sec ft-sz-17 s-sbold font-colr">Monthly</span>
                                    </div>
                                    <div className="check-box pull-right">
                                     <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
                                     <label for="styled-checkbox-2"></label>
                                    </div>
                                  </div>
                                  <div className="main-addon-expire-sec">
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12 addon-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Add Ons</div>
                                        <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                        <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 expire-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Expires On</div>
                                        <div className="date-expire blu-color s-sbold font-colr">23-02-2021</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="select-header clearfix">
                             <div className="head ft-sz-22 s-sbold white-color pull-left">Premium</div> 
                             <div className="select-header-right-side pull-right">
                               <span className="ft-sz-13 s-reg white-color">Quantity</span>
                               <span className="select-count s-bold blu-color ft-sz-24">2</span>
                               <span class="icon-down icon-arrow-down-sign-to-navigate"></span>
                             </div> 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <div className="inner-card-sec">
                                  <div className="head-sec clearfix">
                                    <div className="head pull-left">
                                      <span className="ft-sz-26 s-sbold blu-color">Basic</span>
                                      <span className="month-yer-sec ft-sz-17 s-sbold font-colr">Monthly</span>
                                    </div>
                                    <div className="check-box pull-right">
                                     <input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3" />
                                     <label for="styled-checkbox-3"></label>
                                    </div>
                                  </div>
                                  <div className="main-addon-expire-sec">
                                    <div className="row">
                                      <div className="col-lg-6 col-xs-12 addon-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Add Ons</div>
                                        <span className="ft-sz-12 s-sbold font-colr">100 MB</span>
                                        <span className="ft-sz-12 s-sbold font-colr">50 Scan</span>
                                      </div>
                                      <div className="col-lg-6 col-xs-12 expire-sec">
                                        <div className="head ft-sz-12 s-sbold font-colr">Expires On</div>
                                        <div className="date-expire s-sbold blu-color">23-02-2021</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                        <div className="clearfix"></div>
                        <div className="main-added-entities-sec">
                          <p class="ft-sz-18 s-bold font-colr">Unaccepted Codes</p>
                          <div className="inner-added-entities">
                            <div className="row">
                              <div className="col-lg-4 col-xs-12 name-sec">
                                <div className="head">
                                  <span className="ft-sz-20 s-sbold font-colr">Premium</span>
                                  <p className="ft-sz-14 s-reg font-colr mb-1">(Monthly)</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-xs-12 name-sec">
                                <div className="head">
                                  <p className="ft-sz-16 s-reg font-colr m-0">Ramesh Kanerkar</p>
                                </div>
                              </div>
                              <div className="col-lg-5 col-xs-12 main-code-sec">
                                <div className="code-sec">
                                  <span className="ft-sz-25 s-bold">HGD186</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inner-added-entities">
                            <div className="row">
                              <div className="col-lg-4 col-xs-12 name-sec">
                                <div className="head">
                                  <span className="ft-sz-20 s-sbold font-colr">Premium</span>
                                  <p className="ft-sz-14 s-reg font-colr mb-1">(Monthly)</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-xs-12 name-sec">
                                <div className="head">
                                  <p className="ft-sz-16 s-reg font-colr m-0">Ramesh Kanerkar</p>
                                </div>
                              </div>
                              <div className="col-lg-5 col-xs-12 main-code-sec">
                                <div className="code-sec">
                                  <span className="ft-sz-25 s-bold">HGD186</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="main-name-input-sec">
                          <div class="form-group mb-0">
                            <input type="text" class="form-control" placeholder="Name" value="" />
                          </div>
                        </div>
                        <button type="button" class="creat-btn btn apply-btn ft-sz-22 white-color s-bold">Create code</button>
                        <div className="clearfix"></div>
                        <div className="main-code-copy-sec">
                          <div className="row">
                            <div className="col-lg-9 col-xs-12">
                              <div className="code-copy">
                                <span className="s-bold">AKL584</span>
                              </div>
                            </div>
                            <div className="col-lg-3 col-xs-12">
                              <button type="button" class="copy-btn btn apply-btn ft-sz-22 white-color s-bold">Copy</button>  {/* Add class for background .gry-bg */}
                            </div>
                          </div>
                        </div>
                        <div className="main-copy-succes-sec">
                          <div className="succes-check">
                          </div>
                          <span className="s-sbold copied-text">Copied successfully</span>
                        </div>
                      </div>
                    </div>:null
                  }
                   </div>
                  </div>
                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" defaultActiveKey="home">
                  <Tab eventKey="home" title="Entities">
                    
                    <div className="main-single-entity-add-form d-none">
                      <div class="cross" onClick={handleShow}><span>X</span></div>
                      <div className="main-entity-basic-sec">
                        <div class="row no-gutters">
                          <div class="col-md-6 col-xs-12">
                            <div className="entity-company-name">
                              <p className="sub-head ft-sz-20 s-reg">Entity</p>
                              <div className="head ft-sz-30 s-sbold blu-color">Tecture Business Solutions pvt. ltd.</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12">
                            <div className="plan-expire-sec">
                              <div className="inner-plan-expire-sec font-colr">
                                Your Current License
                                <div className="button-sec ft-sz-20 s-bold white-color"><span>Basic</span></div>
                              </div>
                              <p className="m-0 s-reg date-sec">Expires on 15-3-2021</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">General</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Name</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Type</p>
                                  <select className="form-control">
                                    <option value="volvo" selected></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-5 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Account Address</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">City</p>
                                  <select className="form-control">
                                    <option value="volvo" selected></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">State</p>
                                  <select className="form-control">
                                    <option value="volvo" selected></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Country</p>
                                  <select className="form-control">
                                    <option value="volvo" selected></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Pincode</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">Taxation Details</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">PAN number</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">GST number</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">TAN number</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head pull-left">Paid By</p>
                        <button type="button" class="btn buy-lic-btn ft-sz-22 s-bold white-color pull-right">Buy License</button>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-md-4">
                                <div className="btn-group btn-toggle left-on-off-btn paid-by-toggle"> 
                                  <button className="btn btn-lg btn-default active">Business</button>
                                  <button className="btn btn-lg btn-primary ">Self</button>
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Subscription</p>
                                  <select className="form-control">
                                    <option value="" selected>Basic (Monthly) Subscription</option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>              
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head pull-left">Users</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-12 col-xs-12">
                            <div className="main-table-sec">
                              <Table bordered responsive>
                                <thead>
                                  <tr>
                                    <th>User <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>User Name <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Role <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Last Login <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Date Created <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Status <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Accountant</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                                      <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Staff</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                                      <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Accountant</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                                      <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Staff</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                                      <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head">Additional Details</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-lg-1 col-md-5">
                                <div className="main-details-upload-sec">
                                  <p className="lable-input font-colr m-0 s-sbold">Digital Signature</p>
                                  <div className="inner-details-upload-sec">
                                    <span class="icon-Group-2328"></span>
                                    <p className="font-colr ft-sz-18 upload-text">Upload Your Image</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-1 col-md-5">
                                <div className="main-details-upload-sec">
                                  <p className="lable-input font-colr m-0 s-sbold">Digital Signature</p>
                                  <div className="inner-details-upload-sec">
                                    <span class="icon-Group-2328"></span>
                                    <p className="font-colr ft-sz-18 upload-text">Upload Your Image</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <p className="ft-sz-18 blu-color prefer-img-size">Preferred image size; 240px x 240px 272 DPI Maximum Size of 1MB</p> */}
                            <div className="main-hash-tag">
                              <div className="row">
                                <div className="col-lg-10 col-xs-12">
                                 <p className="lable-input font-colr m-0 s-sbold">Hashtags</p>
                                 <input type="text" className="form-control" value="" /></div>      
                              </div>
                            
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">Send Invite</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-md-4 col-xs-12">
                                <div className="form-group">
                                  <input type="text" className="form-control" value="" placeholder="Business account email"/>       
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-3 col-xs-12">
                                  <div class="form-check">
                                    <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                    <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Send Invite</label>
                                  </div>
                                  {/* <p class="form-check-label">Send Invite</p> */}
                                </div> 
                                <button type="button" class="btn invite-btn ft-sz-20 white-color s-bold graybtn d-none">Resend Invite</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="tab-inner-sec">
                      <div className="row">
                        <div className="col-xs-12 col-lg-2 col-md-4 col-sm-4">
                          <div className="count-box">
                            <p className="ft-sz-29 blu-color m-0">Total</p>
                            <div className="count-char s-bold">5</div>
                            {/* <p className="count-bottim-text ft-sz-14 font-colr">lorem ipsum text</p> */}
                          </div>
                        </div>
                        <div className="col-xs-12 col-lg-2 col-md-4 col-sm-4">
                          <div className="count-box">
                            <p className="ft-sz-29 blu-color m-0">Paid By Me</p>
                            <div className="count-char s-bold">3</div>
                            {/* <p className="count-bottim-text ft-sz-14 font-colr">lorem ipsum text</p> */}
                          </div>
                        </div>
                        <div className="col-xs-12 col-lg-2 col-md-4 col-sm-4">
                          <div className="count-box">
                            <p className="ft-sz-29 blu-color m-0">Paid By Others</p>
                            <div className="count-char s-bold">2</div>
                            {/* <p className="count-bottim-text ft-sz-14 font-colr">lorem ipsum text</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="col-xs-12">
                        <p className="count-bottim-text ft-sz-14 font-colr mt-3 mb-0">lorem ipsum text</p>
                        </div>
                      </div>
                    </div>

                    <div className="main-profile-entity-user-sec">     
                      <div className="toggle-search-sec clearfix">
                        <div className="search-filter-sec pull-right">
                          <div className="filter">
                          Filter <span className="icon-funnel"></span>
                          </div>
                        </div>
                      </div>
                      <div className="main-filter-sec clearfix">
                        <div className="row inner-row">
                          <div className="col-lg col-xs-12">
                            <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Entity" />
                            </div>
                          </div>
                          <div className="col-lg col-xs-12">
                            <div className="form-group">
                              <select className="form-control" required>
                                <option value="" selected disabled>Type</option>
                                <option value="saab">Saab</option>
                                <option value="vw">VW</option>
                                <option value="audi">Audi</option>
                              </select>             
                            </div>
                          </div>
                          <div className="col-lg col-xs-12">
                            <div className="form-group">
                              <select className="form-control" required>
                                <option value="" selected disabled>License</option>
                                <option value="saab">Saab</option>
                                <option value="vw">VW</option>
                                <option value="audi">Audi</option>
                              </select>             
                            </div>
                          </div>
                          <div className="col-lg col-xs-12">
                            <div className="form-group">
                              <select className="form-control" required>
                                <option value="" selected disabled>Paid By</option>
                                <option value="saab">Saab</option>
                                <option value="vw">VW</option>
                                <option value="audi">Audi</option>
                              </select>             
                            </div>
                          </div>
                          <div className="col-lg col-xs-12">
                            <div className="form-group">
                              <input type="text" className="form-control" value="" placeholder="Users" />               
                            </div>
                          </div>
                        </div>
                        <div className="row inner-row">
                          <div className="col-lg-3 col-xs-12">
                            <div className="form-group date-sec">
                              <input type="text" className="form-control" placeholder="Expiring Before"/>
                              <span class="icon-calendar"></span>
                            </div>
                          </div>
                          <div className="col-lg-3 col-xs-12">
                            <div className="form-group">
                              <select className="form-control" required>
                                <option value="" selected disabled>Hashtags</option>
                                <option value="saab">Saab</option>
                                <option value="vw">VW</option>
                                <option value="audi">Audi</option>
                              </select>             
                            </div>
                          </div>
                        </div>
                        <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                        <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                      </div>

                      <div className="main-table-sec">
                        <Table bordered responsive>
                          <thead>
                            <tr>
                              <th>Entity <span class="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Type <span class="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>License <span class="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Expires on <span class="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Paid By <span class="icon-arrow-down-sign-to-navigate"></span></th>
                              <th>Users <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>RK Pharma INC</td>
                              <td>LLP</td>
                              <td>Basic (Add ons)</td>
                              <td>15 May 2021</td>
                              <td>CA Ramesh Pande</td>
                              <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                            </tr>
                            <tr>
                              <td>RK Pharma INC</td>
                              <td>LLP</td>
                              <td>Basic (Add ons)</td>
                              <td>15 May 2021</td>
                              <td>CA Ramesh Pande</td>
                              <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                            </tr>
                            <tr>
                              <td>RK Pharma INC</td>
                              <td>LLP</td>
                              <td>Basic (Add ons)</td>
                              <td>15 May 2021</td>
                              <td>CA Ramesh Pande</td>
                              <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                            </tr>
                            <tr>
                              <td>RK Pharma INC</td>
                              <td>LLP</td>
                              <td>Basic (Add ons)</td>
                              <td>15 May 2021</td>
                              <td>CA Ramesh Pande</td>
                              <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                            </tr>
                            <tr>
                              <td>RK Pharma INC</td>
                              <td>LLP</td>
                              <td>Basic (Add ons)</td>
                              <td>15 May 2021</td>
                              <td>CA Ramesh Pande</td>
                              <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                            </tr>
                            <tr>
                              <td>RK Pharma INC</td>
                              <td>LLP</td>
                              <td>Basic (Add ons)</td>
                              <td>15 May 2021</td>
                              <td>CA Ramesh Pande</td>
                              <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div> 
                  </Tab>
                  {/* <Tab eventKey="users" title="Users" >
                    <div className="main-single-entity-add-form">
                      <div class="cross"><span>X</span></div>
                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">General</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-6 col-xs-12 inner-gnrl-sec">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Name<span className="red-star">*</span></p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Username<span className="red-star">*</span></p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-xs-12 inner-gnrl-sec">
                            <div className="row no-gutters">
                              <div className="col-sm-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Email*</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-sm-2"><p className="or-text-sec s-bold ft-sz-18 blu-color m-0 text-center">OR</p></div>
                              <div className="col-sm-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Mobile Number*</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <br/>

                      <div className="title-head-sec clearfix">
                        <div className="row justify-content-between">
                          <div className="col-lg-6 col-md-6">
                            <p className="ft-sz-30 s-sbold blu-color title-head">Entities {'&'} Permissions</p>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="user-progressbar">
                              <ul id="progressbar">
                                <li className="active">Assign Entities <span className="dot d-none"></span>
                                  <span className="dot-full">
                                    <span>&#10003;</span>
                                  </span>
                                </li>
                                <li>Assign Permission <span className="dot d-none"></span></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        
                        
                      </div>
                      <div className="assign-right-btton-sec clearfix">
                        <p className="ft-sz-30 s-bold blu-color title-btn-sec pull-left">Assign Entities to user</p>
                        <p className="ft-sz-30 s-bold blu-color title-btn-sec pull-left d-none">Assign Permission</p>
                        <button type="button" class="next-btn btn apply-btn ft-sz-22 white-color s-bold pull-right"><span>Next</span><span className="d-none">Back</span></button>
                      </div>
                      <div className="main-user-entity-select-sec">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-xs-12">
                            <div className="same-permission-text">
                              <span className="blu-color s-sbold ft-sz-16">Use same permissions for all entities?</span>
                              <ButtonGroup aria-label="Basic example">
                                <Button variant="secondary switch-yes-no">Yes</Button>
                                <Button variant="secondary switch-yes-no">No</Button>
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-xs-12">
                            <div className="user-entity-select-sec">
                              <p className="s-sbold ft-sz-28 blu-color">Available Entities</p>
                              <p className="s-sbold ft-sz-28 blu-color d-none">Selected Entities</p>
                              <ul className="user-select-entities d-none">
                                <li className="active">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">CPA Technologies</p>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">RK Pharma INC</p>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">ABC Technologies</p>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">Tecture Pvt.Ltd</p>
                                  </div>
                                </li>
                              </ul>
                              <ul className="user-available-entities">
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-11" type="checkbox" value="value11" />
                                    <label for="styled-checkbox-11" className="label-text blu-color s-sbold ft-sz-20 mb-0">CPA Technologies</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-21" type="checkbox" value="value21" />
                                    <label for="styled-checkbox-21" className="label-text blu-color s-sbold ft-sz-20 mb-0">RK Pharma INC</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3" />
                                    <label for="styled-checkbox-3" className="label-text blu-color s-sbold ft-sz-20 mb-0">ABC Technologies</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4" />
                                    <label for="styled-checkbox-4" className="label-text blu-color s-sbold ft-sz-20 mb-0">Tecture Pvt.Ltd</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value5" />
                                    <label for="styled-checkbox-5" className="label-text blu-color s-sbold ft-sz-20 mb-0">CKL Technologies</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value6" />
                                    <label for="styled-checkbox-6" className="label-text blu-color s-sbold ft-sz-20 mb-0">Joshi Foods</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-7" type="checkbox" value="value7" />
                                    <label for="styled-checkbox-7" className="label-text blu-color s-sbold ft-sz-20 mb-0">Radha Developers </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-12 col-xs-12">
                            <div className="main-user-permission-sec">
                              <div className="user-copy-custom-sec">
                                <div className="row">
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                      <p className="lable-input font-colr m-0">User role</p>
                                      <select className="form-control">
                                        <option value="volvo">Power User</option>
                                        <option value="saab">Staff</option>
                                        <option value="vw">In-house Accountant</option>
                                        <option value="audi">External Accountant/Chartered Accountant</option>
                                      </select>             
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                      <p className="lable-input font-colr m-0">Copy From other entity?</p>
                                      <select className="form-control">
                                        <option value="volvo">Power User</option>
                                        <option value="saab">Staff</option>
                                        <option value="vw">In-house Accountant</option>
                                        <option value="audi">External Accountant/Chartered Accountant</option>
                                      </select>             
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                      <p className="lable-input font-colr m-0">Custom Rights</p>
                                      <span className="custom-right-text s-bold ft-sz-32 gren-color">Yes</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="main-card-checkbox-sec">
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Evidences</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Create Business Snapshot</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">Manage all other user snapshots</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-33" type="checkbox" value="value33" />
                                          <label for="styled-checkbox-33" className="label-text blu-color s-reg ft-sz-15 mb-0">View Only</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-34" type="checkbox" value="value34" />
                                          <label for="styled-checkbox-34" className="label-text blu-color s-reg ft-sz-15 mb-0">Create Reimbursement Snapshot</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-35" type="checkbox" value="value35" />
                                          <label for="styled-checkbox-35" className="label-text blu-color s-reg ft-sz-15 mb-0">Approve Reimbursement </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Invoices</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Invoice Creation</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">View only Invoice</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Approvals</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Create expense for approval</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">View Only expense for approval</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-33" type="checkbox" value="value33" />
                                          <label for="styled-checkbox-33" className="label-text blu-color s-reg ft-sz-15 mb-0">Approve Expense</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Cheques</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Upload Cheques</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">View Cheques</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Financial Institution (and Account statement)</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Creation of Financial Institution</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">Uploading & View Account statement</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-33" type="checkbox" value="value33" />
                                          <label for="styled-checkbox-33" className="label-text blu-color s-reg ft-sz-15 mb-0">View only Account statement</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab> */}
                </Tabs>
                <div className="main-note-active-btn-sec clearfix d-none">
                  <div className="note-active-sec pull-left">
                    <p className="ft-sz-18 s-reg blu-color mb-0">Note: Once you save new user an invite shall be sent to the user on email or mobile.</p>
                  </div>
                  <div className="active-in-btn-sec pull-right">
                    <ButtonGroup aria-label="Basic example">
                      <Button variant="secondary active-in">Active</Button>
                      <Button variant="secondary active-in">Inactive</Button>
                    </ButtonGroup>
                  </div>
                </div>
                <div class="profile-save-btn clearfix">
                  <button type="button" class="btn save-btn s-bold pull-left graybtn">Delete</button>
                  <button type="button" class="btn save-btn s-bold pull-right greenbtn">Save</button>
                </div>
              </div>
               <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i class="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>


            </div>
          </div>
        </div>
        <footer className="sticky-footer d-none">
          <div className="container">
            <div className="text-center">
              <small>Copyright © Buktec 2021</small>
            </div>
          </div>
        </footer>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
      <div className="blackbody"></div>
    </div>     

	
}











export default profileentityCa;