import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function LinkedStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" >Linked</Tooltip>}>
            <span className="icon-linked-icon icon-dt-td iconsc"><span className={"path1 " + (props.active ? "skyblue-icon" : " grayc-light-icons")}></span><span className="path2 white-icon"></span><span className="path3 white-icon"></span><span className="path4 white-icon"></span><span className="path5 white-icon"></span><span className="path6 white-icon"></span><span className="path7 white-icon"></span><span className="path8"></span><span className="path9"></span></span>
        </OverlayTrigger>
    </>
}
export default LinkedStatus;