import React from 'react'
// import firebase from 'firebase/app';
import firebase from '../../Utils/firebase'
import 'firebase/firestore';
import { MentionsInput, Mention } from 'react-mentions';
import MessageList from '../../Components/CommunicationBar/MessageList';
import CommunicationBar from '../../Services/CommunicationBar/CommunicationBar';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import _lodash from "lodash";
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const db = firebase.firestore();
class MessageBox extends React.Component {
	constructor(props) {
		super(props);
		this.messagesEndRef = React.createRef();
		this.messagesRef = db.collection(this.props.context_type).doc(this.props.context_name).collection(this.props.context_id + "");
		this.state = {
			messages_history: [],
			all_users: [],
			mention_users: [],
			all_mention_users: [],
			// new_message: "nehal dsfdsfdsfdsfdsfdsf @[Nehal Karanjekar](Nehal)",
			new_message: "",
			isFetching: true,
			lastVisible: "",
			disableSendMsg: false
		}
		this.readMessage();
	}

	readMessage = async () => {

		this.groupRefRead = db.collection("context_group");
		await this.groupRefRead.where("context_id", '==', this.props.context_id)
			.where("context_type", '==', this.props.context_type)
			.where("members", 'array-contains', this.props.user_detail.id)
			.get()
			.then(
				(data) => {
					if (!data.empty) {
						let grp_detail = {
							"readers": firebase.firestore.FieldValue.arrayUnion(this.props.user_detail.id)
						}
						this.groupRefRead.doc(data.docs[0].id).update(grp_detail).then((data) => { });
					}
				});
	}

	getAllUsers = async () => {
		/*const UserRef = db.collection('users');
		await UserRef.get().then((querySnapshot) => {
			const data = querySnapshot.docs.map(doc => ({
				...doc.data()
			}));
			this.setState({ all_users: data });
		});*/
		CommunicationBar.getAllUser().then((data) => {
			this.setState({ all_users: data.result.users })
		})
	}

	loadMoreMessage = async (isFirstTime = false) => {
		this.setState({ isFetching: true })
		let lastVisible = this.state.lastVisible;
		try {
			const data = await this.messagesRef
				.orderBy("created_at", "desc")
				.startAfter(this.state.lastVisible)
				.limit(5)
				.get();
			let posts = [];
			data.forEach((doc) => {
				posts.push({
					...doc.data(),
					id: doc.id
				});
				lastVisible = doc;
			});
			this.setState({ isFetching: false })
			this.setState({ messages_history: [...posts.reverse(), ...this.state.messages_history], lastVisible }, () => {
				if (isFirstTime)
					this.getSnapshots();
			});
		} catch (e) {
			console.error(e);
		}
	}

	getSnapshots = () => {
		// Subscribe to query with onSnapshot
		var query = this.messagesRef.orderBy('created_at', 'desc')
			// .startAfter(this.state.lastVisible)
			.limit(1)
		const unsubscribe = query.onSnapshot(querySnapshot => {
			querySnapshot.docChanges().forEach((change) => {
				if (change.type === "added") {
					if (this.state.messages_history.length) {
						if (!_lodash.isEqual(this.state.messages_history[this.state.messages_history.length - 1].created_at, change.doc.data().created_at)) {
							this.setState({ messages_history: [...this.state.messages_history, change.doc.data()] });
							this.readMessage();
						}
					} else {
						this.setState({ messages_history: [...this.state.messages_history, change.doc.data()] });
					}
					// this.scrollToBottom();
				}
				if (change.type === "modified") {
					let index = this.state.messages_history.findIndex(msg => msg.message == change.doc.data().message);
					if (index != -1) {
						let messages_history = this.state.messages_history;
						messages_history[index] = change.doc.data();
						this.setState({ messages_history: this.state.messages_history });
					}
				}
				if (change.type === "removed") {
					// console.log("Removed city: ", change.doc.data());
				}
			});
			// // Get all documents from collection - with IDs
			// const data = querySnapshot.docs.map(doc => ({
			// 	...doc.data(),
			// 	id: doc.id
			// }));
			// // Update state
			// var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
			// this.setState({ messages_history: [...data.reverse(), ...this.state.messages_history],lastVisible });
		});
		// this.loadMoreMessage();
		return unsubscribe;
	}

	getACLEntityUsers = () => {
		CommunicationBar.getACLEntityUsers().then(
			(data) => {
				if (data.response_code == 200) {
					const mention_users = [];
					const all_mention_users = [];
					data.result.private_mention_users.forEach((user) => {
						if (user.id != this.props.user_detail.id)
							mention_users.push({
								id: user.id,
								display: user.full_name,
							})
					});
					data.result.general_mention_users.forEach((user) => {
						if (user.id != this.props.user_detail.id)
							all_mention_users.push({
								id: user.id,
								display: user.full_name,
							})
					});
					const mention_users_ids = (this.props.context_type == "public_context" ? all_mention_users : mention_users).map((user) => { return user.id })
					this.setState({
						mention_users: this.props.context_type == "public_context" ? all_mention_users : mention_users,
						all_mention_users,
						mention_users_ids
					});
				}
			});
	}
	async componentDidMount() {
		this.getACLEntityUsers();
		this.getAllUsers();
		this.loadMoreMessage(true);
	}
	componentWillReceiveProps(nextProps) {
		if ((nextProps.context_id !== this.props.context_id)) {
			this.setState({
				messages_history: [],
				isFetching: true,
				lastVisible: "",
			})
			this.messagesRef = db.collection(this.props.context_type).doc(this.props.context_name).collection(nextProps.context_id + "");
			this.loadMoreMessage();
		}
	}

	componentDidUpdate() {
		this.props.onRef(this)
		//   this.scrollToBottom();
	}

	handleChange = (e) => {
		const target = e.target;
		const value = target.value;
		const name = target.name;
		this.setState({ [name]: value });
	}
	handleChangeValue = (e) => {
		const target = e.target;
		const new_message = target.value;
		this.setState({ new_message });

		if (this.props.context_type == 'public_context') {
			this.props.update_communication_bar_msgs_dirty(true)
		} else {
			this.props.update_communication_bar_private_msgs_dirty(true)
		}
	}

	onReply = (user_id) => {
		let detail = this.state.all_mention_users.find((user) => user.id == user_id);
		if (!!detail) {
			this.setState({ new_message: this.state.new_message + " @[" + detail.full_name + "](" + detail.id + ") " })
		}
	}

	getUserIds = (message) => {
		let regex = /\@\[[A-Za-z0-9 ]*\]\([0-9]*\)/g;
		let words = message.match(regex);
		let user_ids = [];
		if (words != null) {
			user_ids = words.map((msg) => {
				return parseInt(msg.substring(
					msg.indexOf("](") + 2,
					msg.lastIndexOf(")"))
				)
			})
		}
		return user_ids;
	}

	sendMessage = async (nextRoute) => {
		let new_message = this.state.new_message.trim();
		this.setState({ new_message: "" })
		if (!this.state.messages_history.length) {
			this.storeMessageInDB();
		}
		//e.preventDefault();
		this.setState({ disableSendMsg: true })
		if (!!new_message) {
			/*
			* Add / update group for context meesage
			*/
			//this.messagesRef = db.collection(this.props.context_type).doc(this.props.context_name).collection(nextProps.context_id + "");

			this.groupRef = db.collection("context_group");
			await this.groupRef.where("context_id", '==', this.props.context_id)
				.where("context_type", '==', this.props.context_type)
				.get()
				.then(
					(data) => {

						if (this.props.context_type == 'public_context') {
							this.props.update_communication_bar_msgs_dirty(false)
						} else {
							this.props.update_communication_bar_private_msgs_dirty(false)
						}

						if (data.empty) {
							let grp_detail = {
								"context_id": this.props.context_id,
								"entity_id": this.props.default_entity_detail.id,
								"context_name": this.props.context_name,
								"created_at": firebase.firestore.FieldValue.serverTimestamp(),
								"updated_at": firebase.firestore.FieldValue.serverTimestamp(),
								"created_by": this.props.user_detail.id,
								"members": [this.props.user_detail.id, ...this.state.mention_users_ids],//add all members here
								"readers": [this.props.user_detail.id],
								"context_type": this.props.context_type,
								"recent_message": {
									user_detail: this.props.user_detail,
									message: new_message
								}
							}
							this.groupRef.add(grp_detail).then((data) => { });
						} else {
							let members = data.docs[0].data().members;
							members = members.includes(this.props.user_detail.id) ? members :
								[...members, this.props.user_detail.id];
							let grp_detail = {
								"updated_at": firebase.firestore.FieldValue.serverTimestamp(),
								"members": members,
								"readers": [this.props.user_detail.id],
								"recent_message": {
									user_detail: this.props.user_detail,
									message: new_message
								}
							}
							this.groupRef.doc(data.docs[0].id).update(grp_detail).then((data) => { });

						}
					});
			/* 
			* Add single  message to server
			*/
			let message = {
				message: new_message,
				user_id: this.props.user_detail.id,
				created_at: firebase.firestore.FieldValue.serverTimestamp()
			};
			await this.messagesRef.add(message).then((data) => {
				/*
				if mentioned send notification
				regex - \@\[[A-Za-z0-9 ]*\]\([0-9]*\)
				*/
				let user_ids = this.getUserIds(new_message);
				let noti_data = {
					"mod_type": this.props.context_no,
					"mod_id": this.props.context_id,
					"submod_type": this.props.context_type == "public_context" ? 2 : 3,//2 private & 3 bussines + ca
					"submod_id": null,
					"user_ids": user_ids
				}
				if (!!user_ids.length) {
					CommunicationBar.sendMentionUserNotification(noti_data).then((data) => {
						this.setState({ ...this.state, notes: data, isLoading: false })
					})
				}
				// alert(nextRoute)
				if (nextRoute == 'tasks' || nextRoute == 'private_msgs' || nextRoute == 'notes' || nextRoute == 'msgs') {
					this.props.onTabChange(nextRoute)
				} else if (nextRoute) {
					this.props.history.push(nextRoute)
				}
			}).catch((error) => {
				console.error("Error writing document: ", error);
			});
			this.setState({ new_message: "", disableSendMsg: false })
			this.props.setAvailableMsgFlag();
		}
	}

	storeMessageInDB = () => {
		let data = {
			"ref_id": this.props.context_id,
			"mod_type": this.props.context_no,
			"general_msg_status": this.props.context_type == "public_context" ? 1 : null,
			"notes_status": null,
			"private_msg_status": (this.props.context_type == "private_context_buss" || this.props.context_type == "private_context_ca") ? 1 : null,
			"tasks_status": null
		}
		CommunicationBar.storeMessageInDB(data).then(
			(data) => {

			});
	}

	enterPressed = (event) => {
		var code = event.keyCode || event.which;
		if (code === 13 && !event.shiftKey) {
			//13 is the enter keycode
			this.sendMessage()
		}
	}

	scrollToBottom = () => {
		this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
	}

	render() {
		return (
			<>	{/* Messages section --- Comment Popup Box */}
				<div className="comment-cmb-box">
					<h3 className="cmb-heading">
						<span class="icon-chat mr-2"></span>
						<span className={(this.props.context_type == "public_context" ? "" : "red-text")}>{this.props.title}</span>
					</h3>
					<>
						<MessageList
							loadMoreMessage={this.loadMoreMessage}
							messages_history={this.state.messages_history}
							all_users={this.state.all_users}
							onReply={this.onReply}
							isFetching={this.state.isFetching} />

						<div ref={this.messagesEndRef} />
					</>
				</div>
				{/* End Messages section */}


				<form onSubmit={(e) => { e.preventDefault(); this.sendMessage() }}>
					<div className="bottom-cmb">
						<div className="bottom-inner-cmb">
							{/* <div className="user-comment-cmb disable">
								<p className="click-msg">@mention a user</p>
								<p className="click-msg">Click the on icons on right side to use the communication bar</p>
							</div> */}
							<div className="user-comment-cmb">
								<MentionsInput
									value={this.state.new_message}
									name="value"
									disabled={this.props.view_only}
									allowSuggestionsAboveCursor={true}
									onChange={this.handleChangeValue}
									placeholder="@mention a user"
									aria-label="Recipient's username"
									aria-describedby="basic-addon2"
									// style={default_css}
									// className="form-control s-sbold messages-box font-colr h-auto"
									// className="form-control s-sbold messages-box font-colr h-auto overflow-unset pl-3"
									className="form-control mention-a-user"
									onKeyPress={(e) => this.enterPressed(e)}
								>
									<Mention
										trigger="@"
										data={this.state.mention_users}
										// renderSuggestion={this.renderUserSuggestion}
										appendSpaceOnAdd={true}
										renderSuggestion={(
											suggestion,
											search,
											highlightedDisplay,
											index,
											focused
										) => (
											<div className={`user ${focused ? 'focused' : ''}`}>
												{highlightedDisplay}
											</div>
										)}
										// style={{ backgroundColor: '#cee4e5', paddingRight: 3, marginRight: 5 }}
									/>
								</MentionsInput>
							</div>

							{/* --icons-- */}
							<div className="icon-cmb">
								<a role="button"
									className={this.props.title == "Messages" ? " active " : ""}
									onClick={() => this.props.onTabChange('msgs')} >
									{this.state.public_messages ? <span className="active-dot"></span> : ""}
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Messages</Tooltip>}>
										<span className="icon-chat"></span>
									</OverlayTrigger>
								</a>
								{
									!this.props.hide_task_manager &&
									<a role="button" onClick={() => this.props.onTabChange('tasks')}>
										<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tasks</Tooltip>}>
											<span className="icon-Group-2516"></span>
										</OverlayTrigger>
									</a>
								}

								{/* <a role="button" className="active-msg active-dot"> */}
								{/* <a role="button" className="active-msg">
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tasks</Tooltip>}>
										<span className="icon-Group-2516"></span>
									</OverlayTrigger>
								</a> */}
								<a role="button" onClick={() => this.props.onTabChange('notes')}>
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Notes</Tooltip>}>
										<span className="icon-Group-2546"></span>
									</OverlayTrigger>
								</a>
								<a role="button"
									className={this.props.title == "Private Messages" ? " active " : ""}
									onClick={() => this.props.onTabChange('private_msgs')} >
									{this.state.private_messages ? <span className="active-dot"></span> : ""}
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Private Messages</Tooltip>}>
										<span className="icon-chat"></span>
									</OverlayTrigger>
								</a>
							</div>
						</div>

						<input type="hidden" />
						
						<div className="right_side_btn_new">
							<Button type="submit" variant="success apply_btn_new ml-auto" disabled={this.state.disableSendMsg || this.state.new_message.trim().length == 0}>Send</Button>

							{/* <button type="submit" className="btn comment-btn s-bold greenbtn pull-right "
								disabled={this.state.disableSendMsg || this.state.new_message.trim().length == 0}>Send</button> */}
						</div>
					</div>
				</form>
			</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const entity_list = all_value.entity_list || [];
	const account_permissions = all_value.account_permissions || [];
	const module_permissions = all_value.module_permissions || [];
	const user_detail = all_value.user_detail || {};
	const default_entity_detail = all_value.default_entity_detail || {};
	return {
		entity_list, account_permissions, module_permissions, user_detail, default_entity_detail
	};
}
export default connect(mapStateToProps)(withRouter(MessageBox));