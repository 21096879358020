import axios from 'axios';
import Header from '../Header';
import {API_URL} from '../../Utils/Config';

class DashboardService {

  getBuktecSummaryData(data) {
    return axios.post(API_URL + 'buktec-summary',data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

  getDateFilter(data) {
    return axios.get(API_URL + 'date-filter',data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }
  getBoardData(data) {
    return axios.post(API_URL + 'ca-board',data, { headers: Header.entityHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  updateAccount(data){
    return axios
    .put(API_URL + "account", data,  { headers: Header.entityHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  getBusinessSummaryData(data) {
    return axios.post(API_URL + 'business-summary',data, { headers: Header.entityHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }
}

export default new DashboardService();