import React, { useState } from 'react'
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import logo_tally from '../../Assets/img/logo-tally.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import gplay from '../../Assets/img/gplay.png';
import appstore from '../../Assets/img/appstore.png';
import googleplay from '../../Assets/img/googleplay.png';
import accountp from '../../Assets/img/accountp.png';
import downloadicon from '../../Assets/img/downloadicon.svg';
import { Dropdown } from 'react-bootstrap';
import { logout } from "../../Redux/Actions/Auth";
import SetEntity from '../../Components/BoilerPlate/SetEntityModal';
import SessionManagement from '../../Utils/SessionManagement';
import StorageData from '../../Helpers/StorageData';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from 'react-router';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Notification from './Notification';
import { toast } from 'react-toastify';
import MasterService from '../../Services/MasterService';

// import "../../App.css";

// In the notifications section there were some spana tags instead of span tags I changed them

const TheHeader = (props) => {
  const user_detail = props.user_detail;
  const default_entity_detail = StorageData.getDefaultEntity() || {};
  var entity_list = props.entity_list;
  const [showEntityModal, setEntityModal] = useState(false)
  const [search, setSearch] = useState("")
  const dispatch = useDispatch()
  const history = useHistory();

  function logoutFun() {
    // dispatch(logout())
    // SessionManagement.RemoveAll();
    // StorageData.deleteAllStorageValue();
    history.push("/logout");
    // window.location.reload();
  }

  function setDefaultEntity(entity) {
    StorageData.setDefaultEntity(entity);
    StorageData.setDefaultFI(null);
    history.push("/set-default-entity");
    // window.location.reload();
  }

  function toggleSetEntity() {
    setEntityModal(!showEntityModal)
  }

  function firstLetter(entity) {
    if (entity) {
      entity.trim();
      return entity.charAt(0)
    }
  }

  function filterEntity(e) {
    let search = e.target.value;
    setSearch(search);
  }

  function submitEntityHandler(e) {
    e.preventDefault()
    if (!search) {
      toast.error("Please specify search criteria");
    }
  }

  function downloadTallyBridge() {
    MasterService.downloadTallyBridge().then((data) => {
      //console.log(data)
        const link = document.createElement('a');
        link.href = StorageData.getQualifiedFileUrl(data.result.setup_url);
        link.setAttribute(
            'download',
            `Buktec_Release.zip`,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        //Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
  }
  
  return (
    <div>
      {showEntityModal && <SetEntity toggleSetEntity={toggleSetEntity} />}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark-gray fixed-top" id="mainNav">
        <div className="row">
          {/* <div className="col-lg-4 col-sm-4 col-2"> */}
          <div className="col-xl-2 col-lg-2 col-md-2 d-flex align-items-center">
            {Object.keys(props.default_entity_detail).length != 0 ? <Link className="d-none d-sm-block" to="/home"><img className="main-logo" src={logo_dash} /></Link> : <Link className="d-none d-sm-block" to="/profile-management"><img className="main-logo" src={logo_dash} /></Link>}
            <a className="d-block d-sm-none" href="#"><img className="mob-logo" src={logo_fevi} /></a>
          </div>
          {/* <div className="main-company-name-sectop col-lg-4 col-sm-4 text-center d-md-none d-lg-block d-none d-sm-block"> */}
          <div className="main-company-name-sectop col-xl-8 col-lg-7 col-md-7 text-center d-lg-block d-none d-sm-block">
            <p className="company-name-sec ft-sz-32 s-reg blue-color d-flex align-items-baseline justify-content-center cursor-menu">
              {/* <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled"><span>{props.default_entity_detail.name}</span></Tooltip>}>
                <span className="ellips-auto header-title">{props.default_entity_detail.name}</span>
              </OverlayTrigger> */}
              {/* {props.default_entity_detail.name} */}
              <span className="ellips-auto header-title">{props.default_entity_detail.name}</span>
              <span className="linese-name ml-1">
                {!!props.default_entity_detail.plan_name && <span> {props.default_entity_detail.status == 2 ? <span className="red-text">License Expired</span> : " (" + props.default_entity_detail.plan_name + ")" }</span>}
                {/* (A/c #10000006)*/}
              </span>
            </p>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-3 d-flex align-items-center justify-content-end">
            <ul className="top-menu-right align-items-center">
              {/* 
                Search icon
              <li>
                  <a href="#"><span className="icon-loupe"></span></a>
              </li> */}
              {/* <li>
                className="ft-sz-6"
                <a href="#">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Download Buktec Software Bridge for Tally</Tooltip>}>
                    <img className="downloadicon" src={downloadicon}/>
                    <span className="icon-Download gray-icon"></span>
                    <span className="icon-download1 icon-dwn-fs gray-icon"></span>
                  </OverlayTrigger>
                </a>
              </li> */}              

              <li className="main-gift-sec d-none">
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    {/* <a href="#"><span className="icon-gift"></span></a> */}
                    <a href="#"><span className="icon-gift1 gray-icon"></span></a>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="d-none">
                    <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                    <div className="inner-main-gift-sec">
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-Mask-Group-4"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-invoice1"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-documents"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-Group-4398"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-Mask-Group-4"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-Mask-Group-4"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-Mask-Group-4"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-gift-sec">
                        <div className="row">
                          <div className="col-lg-2 col-md-2 col-xs-2">
                            <span className="icon-sec icon-Mask-Group-4"></span>
                          </div>
                          <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                            <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                            <p className="para-sec ft-sz-16 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              {/* <li>
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Play Store</Tooltip>}>
                    <a href="https://play.google.com/store/apps/details?id=com.buktec" class="dwnldh-icon" target="_blank">                      
                    <span class="icon-googleplay "><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </a>
                  </OverlayTrigger>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">App Store</Tooltip>}>
                    <a href="https://apps.apple.com/in/app/buktec/id1603235288" class="dwnldh-icon" target="_blank">                      
                    <span class="icon-appstore black-icon"></span>
                    </a>
                  </OverlayTrigger>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>               */}

              <li>
                {/* <a href="#">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Download Buktec Software Bridge for Tally</Tooltip>}>
                  <img onClick={downloadTallyBridge} className="logo_tally" src={logo_tally} />
                  </OverlayTrigger>
                </a> */}
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Tally Bridge Installer</Tooltip>}>
                    <a class="m-1 dwnldh-icon" target="_blank" onClick={downloadTallyBridge}>                      
                    <span class="icon-download1 gray-icon"></span>
                    </a>
                  </OverlayTrigger>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>

              <li className="main-noti-sec">
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" className="s-sbold">Help</Tooltip>}>
                    <a href="https://help.thebuktec.com/" class="mr-0" target="_blank">                      
                      <span className="grayall-icon"><span class="icon-Help"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span></span>
                    </a>
                  </OverlayTrigger>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>

              <li className="main-noti-sec">
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a className="position-relative">
                      {/* <i className="fa fa-bell-o" aria-hidden="true"></i> */}
                      <span className="icon-bell gray-icon"></span>
                      {
                        !!props.notification_count &&
                        <span className="bell-notifi-count">{props.notification_count}</span>
                      }
                    </a>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Notification />
                  </Dropdown.Menu>

                </Dropdown>
              </li>
              <li className="profile-menu">
                <Dropdown className="d-flex align-items-center">

                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    {
                      !!user_detail.qualified_url ?
                        <img className="profile-small-img " src={StorageData.getQualifiedFileUrl(user_detail.qualified_url)} />
                        :
                        <span className="profile-h-name profile-namehf">
                          <span>{user_detail.full_name.charAt(0)}</span>
                        </span>
                    }

                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <span className="triangle-3"></span>
                    <div className="main-profile-sec">
                      <div className="profile-img-name-sec">
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-xs-12">
                            {
                              !!user_detail.qualified_url ?
                                <img className="mx-auto d-block" src={StorageData.getQualifiedFileUrl(user_detail.qualified_url)} /> :
                                <div className="profile-h-name profile-namehdf">
                                  <span>{user_detail.full_name.charAt(0)}</span>
                                </div>
                            }




                          </div>
                          <div className="col-lg-9 col-md-9 col-xs-12 pl-0 text-sec clearfix">
                            <div className="edit-sec">
                              <Dropdown.Item className="remove-padding">
                                <Link to="/my-profile">
                                  {/* <span className="icon-edit"></span> */}
                                  <span class="icon-view-details blue-icon"></span>
                                </Link>
                              </Dropdown.Item>
                            </div>
                            <p className="ft-sz-22 s-reg blu-color profile-user-name mb-0">{
                              user_detail.full_name.length >= 25 ? `${user_detail.full_name.substring(0, 25)}...` : user_detail.full_name}
                            </p>
                            <p className="ft-sz-14 s-reg blu-color mb-0 wordbreak">{user_detail.email}</p>
                            <p className="ft-sz-14 s-reg blu-color mb-0">{user_detail.phone_number}</p>
                            <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17" onClick={logoutFun}>Logout</button>
                            {/* <button type="button" className="btn s-bold greenbtn float-right" onClick={logoutFun}>Logout</button> */}
                          </div>
                        </div>
                      </div>
                      {
                        (entity_list.length > 10) &&
                        <form onSubmit={submitEntityHandler} className="search-sec d-flex clearfix"><input type="text" onChange={filterEntity} placeholder="Search Entity" />
                          <button onClick={submitEntityHandler} type="button"><span className="icon-loupe"></span></button>
                        </form>
                      }
                      <div className={"profile-entities-list" + (entity_list.length != 0 ? "" : " d-none")}>
                        <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                        <ul>
                          {
                            entity_list.map((entity, index) => {
                              if (!entity.name.toLowerCase().includes(search.toLowerCase()))
                                return true;
                              return <li key={index} onClick={() => { entity.status == 1 ? "" : setDefaultEntity(entity) }} className={entity.status == 1 ? " de-active " : ""}>
                                <div className={"inner-entity-sec " + (default_entity_detail.id == entity.id ? " active " : "")}>

                                  {/* <OverlayTrigger overlay={<Tooltip id={"tooltip-disabled_" + index} className="s-sbold red-color">{entity.status == 1 ? "No License Attached" : "License : " + entity.plan_name}</Tooltip>}> */}
                                    <div className="row entity-pdd">
                                      <div className="col-lg-3 col-md-3 col-xs-3 d-flex align-items-center">

                                        {entity.entity_logo ? <div className='profile-imghf'><img className="img-fluid mx-auto d-block" src={StorageData.getQualifiedFileUrl(entity.entity_logo)} /></div>
                                          :
                                          <div className="profile-h-name drop-d-entity">
                                            <span>{firstLetter(entity.name)}</span>
                                          </div>}

                                      </div>
                                      <div className="col-lg-7 col-md-7 col-xs-7 p-0 d-flex align-items-center">
                                        <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">{entity.name} </p>
                                      </div>
                                      {
                                        (entity.status != 1 && default_entity_detail.id != entity.id) &&
                                        <div className="col-lg-2 col-md-2 col-xs-2 p-0 d-flex align-items-center justify-content-end">
                                          {/* <span class="icon-Group-4601 fs8">{entity.count}</span> */}
                                          <div className="entity-count d-flex align-items-center justify-content-center">{entity.count || 0}</div>
                                        </div>
                                      }

                                    </div>
                                  {/* </OverlayTrigger> */}
                                </div>
                              </li>
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                <Dropdown>
                  <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic-menu">
                    <a><span className="icon-menu"></span></a>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="row main-menu">
                    <div className={"col-lg-6 col-md-6" + (entity_list.length != 0 ? "" : " d-none")}>
                        {!!props.module_permissions.length && <div className="menu-title"><span className="title-name">Main</span></div>}

                        {/* <div className="menu-title"><span className="icon-FI icon-sec"><span className="path1"></span><span className="path2"></span></span> <span className="title-name">Main</span></div> */}

                        <ul className="d-flex flex-column">
                          {!!props.module_permissions.length &&
                            <li>
                              <Dropdown.Item className="remove-padding">
                                <Link to="/home" className="font-colr">                                  
                                  <span class="icon-sec"><span class="icon-Group-2453"></span></span> <span className="text-name">Home</span>
                                </Link>
                              </Dropdown.Item>
                            </li>
                          }
                          {!!props.module_permissions.length &&
                            <li>
                              <Dropdown.Item className="remove-padding">
                                <Link to="/dashboard" className="font-colr">
                                  <span className="icon-Dashboard icon-sec"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Dashboard</span>
                                </Link>
                              </Dropdown.Item>
                            </li>
                          }
                         
                        </ul>


                        <div className={"menu-title mt-2 " + (props.module_permissions.includes(4) ? "" : "d-none")}><span className="title-name">Master Data</span></div>
                        <ul className="d-flex flex-column">
                          <li className={props.module_permissions.includes(4) ? "mb-0" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/financial-institutes" className="font-colr">
                                {/* <span className="icon-FI icon-sec"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Banking</span> */}
                                <span className="icon-FI icon-sec"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Banking</span>
                              </Link>
                            </Dropdown.Item>
                          </li>
                        </ul>
                        <div className={"menu-title mt-2 " + ((props.module_permissions.includes(1) || props.module_permissions.includes(3) || props.module_permissions.includes(3)) ? "" : "d-none")}><span className="title-name">Capture Evidence</span></div>
                        <ul className="d-flex flex-column">
                          <li className={props.module_permissions.includes(1) ? "" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/snapshot/evidence" className="font-colr">
                                <span className="icon-Evidence icon-sec"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span> <span className="text-name">Snapshot </span>
                              </Link>
                            </Dropdown.Item>
                          </li>
                          <li className={props.module_permissions.includes(2) ? "d-none" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/invoices" className="font-colr"><span className="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></Link>
                            </Dropdown.Item >
                          </li>
                          <li className={props.module_permissions.includes(3) ? "d-none" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/cheque" className="font-colr"><span className="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></Link>
                            </Dropdown.Item>
                          </li>
                        </ul>

                        <div className={"menu-title mt-2 " + (props.module_permissions.includes(5) ? "mb-0" : "d-none")}><span className="title-name">Link Evidence</span></div>
                        <ul className="d-flex flex-column">
                          <li className={props.module_permissions.includes(5) ? "mb-0" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/money-link" className="font-colr">
                                <span className="icon-sec"><span className="icon-Link-icon"></span></span> <span className="text-name">Money Link</span></Link>
                            </Dropdown.Item>
                          </li>
                          {/* <li className={ props.module_permissions.includes(5) ? "mb-0" : "d-none"}>
                              <Dropdown.Item className="remove-padding">
                                <Link to="/money-link" className="font-colr"><span className="icon-Dashboard icon-sec"><span className="path1"></span><span className="path2"></span></span>  <span className="text-name">Link Evidence</span></Link>
                              </Dropdown.Item>
                            </li> */}
                        </ul>
                      </div>


                      <div className="col-lg-6 col-md-6">
                        <div className="menu-title"><span className="title-name">Tools</span></div>
                        <ul className="d-flex flex-column">
                          {!!props.module_permissions.length &&
                            <li>
                              <Dropdown.Item className="remove-padding">
                                <Link to="/task-manager" className="s-sbold font-colr">
                                  <span className="icon-Task-manager icon-sec"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span> <span className="text-name">Task Manager</span>
                                </Link>
                              </Dropdown.Item>
                            </li>
                          }
                          
                          <li className={props.module_permissions.includes(10) ? "mb-0" : "d-none"}>
                            <Link to="/reports" className="s-sbold font-colr">
                              <span className="icon-Exports icon-sec"><span className="path1"></span><span className="path2"></span></span> <span className="text-name">Reports</span>
                            </Link>
                          </li>
                          <li className={!!props.account_permissions.length ? "" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/profile-management/entities" className="s-sbold font-colr">
                                <span className="icon-Profile-management icon-sec"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> <span className="text-name">Profile Management</span>
                              </Link>
                            </Dropdown.Item>
                          </li>

                          <li className={props.module_permissions.includes(7) ? "mb-0" : "d-none"}>
                            <Dropdown.Item className="remove-padding">
                              <Link to="/docbox" className="s-sbold font-colr">
                              <span class="icon-Doc-box icon-sec"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Docbox</span>
                              </Link>
                            </Dropdown.Item>
                          </li>
                        </ul>

                        {
                          (user_detail.account_type == 1 && !!props.default_entity_detail.account_name) &&
                          <div className="account-partner-sec menu-title text-center mt-2 mb-0">
                            <p className="title-name s-sbold blu-color mb-0">Accounting Partner</p>
                            <div className="ac-partner-sec d-flex align-items-center justify-content-center">
                              {!!props.default_entity_detail.account_logo ? <img className="img-fluid ac-partner" src={StorageData.getQualifiedFileUrl(props.default_entity_detail.account_logo)} /> :
                                <div className="profile-h-name drop-d-entity">
                                  <span>{firstLetter(props.default_entity_detail.account_name)}</span>
                                </div>
                              }
                            </div>
                            <p className="title-name s-sbold blu-color mb-0 mt-0">{props.default_entity_detail.account_name}</p>
                          </div>
                        }

                        {/* <div className="app-imgs mt-2 d-flex">
                          <a href="https://play.google.com/store/apps/details?id=com.buktec" target="_blank" className='ml-0 mr-2'><img className="img-fluid applogo" src={googleplay} /></a>
                          <a href="https://apps.apple.com/in/app/buktec/id1603235288" target="_blank" className='ml-2'><img className="img-fluid applogo" src={appstore} /></a>
                        </div> */}

                      </div>
                    </div>

                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </div>
  )
}


function mapStateToProps(state) {
  const all_value = state.Session || {};
  const notification__value = state.Notification || {};
  const entity_list = all_value.entity_list || [];
  const account_permissions = all_value.account_permissions || [];
  const module_permissions = all_value.module_permissions || [];
  const user_detail = all_value.user_detail || {}
  const notification_count = notification__value.count || 0;
  const default_entity_detail = all_value.default_entity_detail || {}

  return {
    entity_list, account_permissions, module_permissions, user_detail, notification_count, default_entity_detail
  };
}
export default connect(mapStateToProps)(withRouter(TheHeader));