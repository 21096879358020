
import React, { Component } from 'react';
import { connect } from "react-redux";

class ShowWidgets extends Component{


  constructor(props) {
    super(props);

    this.state = {
			filter_card : this.props.filter_card
    };
  }

	onCardSelected = (invoice_card_filter) => {
		if(invoice_card_filter != this.state.filter_card)
		{
			this.setState({
				filter_card : invoice_card_filter
			},() => this.props.onCardSelected(this.state.filter_card))
		}
	}

  render(){
    return (
					<div className="">
						<div className="tab-inner-sec">
							<div className="row">
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(0) }>
									<div className={"count-box text-center" + (this.state.filter_card == 0 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Total</p>
										<div className="count-char s-bold">{ this.props.widget_count.total }</div>
									</div>
								</div>
								{ 
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(1) }>
									<div className={"count-box text-center" + (this.state.filter_card == 1 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Not Ready</p>
										<div className="count-char s-bold">{ this.props.widget_count.not_ready }</div>
									</div>
								</div>
								}
								{
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(2) }>
									<div className={"count-box text-center" + (this.state.filter_card == 2 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Ready</p>
										<div className="count-char s-bold">{ this.props.widget_count.ready }</div>
									</div>
								</div>
								}
								{
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(3) }>
									<div className={"count-box text-center" + (this.state.filter_card == 3 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Linked</p>
										<div className="count-char s-bold">{ this.props.widget_count.linked }</div>
									</div>
								</div>
								}
								{
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(4) }>
									<div className={"count-box text-center" + (this.state.filter_card == 4 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Processed</p>
										<div className="count-char s-bold">{ this.props.widget_count.processed }</div>
									</div>
								</div>
								}
								{
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(5) }>
									<div className={"count-box text-center" + (this.state.filter_card == 5 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Synced</p>
										<div className="count-char s-bold">{ this.props.widget_count.synced }</div>
									</div>
								</div>
								}
								{
								<div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3" onClick={ () => this.onCardSelected(6) }>
									<div className={"count-box text-center" + (this.state.filter_card == 6 ? " active" : "")}>
										<p className="title ft-sz-28 m-0">Proforma</p>
										<div className="count-char s-bold">{ this.props.widget_count.proforma }</div>
									</div>
								</div>
								}
							</div>
						</div>
					</div>
    );
  }

}

function mapStateToProps(state) {
  const widget_count = state.Invoices.widget_count || {total : 0, not_ready : 0, ready : 0, linked : 0, processed : 0, synced : 0, proforma : 0};
  
  return {
    widget_count,
  };
}

export default connect(mapStateToProps)(ShowWidgets);