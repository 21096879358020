import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function MessageIcon(props) {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Messages</Tooltip>}>
        <span className={"icon-td icon-messages-icon  "+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>

        {/* <span className="icon-messages-icon icon-td gray-st-icon"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span> */}

        {/* <span className=" gray-st-icon icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span> */}
    </OverlayTrigger>
}
export default MessageIcon;