import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FinancialInstitute from '../../Services/FinancialInstitutes/FinancialInstituteService';
import { validateAddNewBank } from './Validation';
import { toast } from 'react-toastify';
import AddNewCardModal from './AddCardModal';
import ActiveCardOrBankView from '../../Components/BoilerPlate/ActiveCardOrBankView';
import FinancialInstituteService from '../../Services/FinancialInstitutes/FinancialInstituteService';
import DeletePopUp from '../../Components/BoilerPlate/DeletePopUp';
import AddBankModal from './AddBankModal';
import { FI_CREATE_CODE } from '../../Helpers/Constants';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import {setEntityPermission, setModulePermission, setEntityConfigParam} from "../../Redux/Actions/Session";
import {setBankGapCount} from "../../Redux/Actions/Banking";

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
import MasterService from '../../Services/MasterService';
// End Intro js user guid 

class FinancialInstitutesList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			show_add_card: false,
			card_details: null,
			show_add_bank_form: false,

			// Intro js user guid
			// this.state = {
			stepsEnabled: false,
			initialStep: 0,
			steps: [
				{
					element: ".addbank_bankin",
					intro: "You can add a new bank from here by giving required details. It also gives you the option of adding debit cards linked to that bank account. Once it gets added, it will start reflecting in the Snapshot payment status dropdown and money link.",
					position: 'left',
					// hideNext: true 
				},
				{
					element: ".addcard_bankin",
					intro: "You can also add a credit card here for your business entity.",
					position: 'left',
				},
				{
					element: ".clickbc_bankin",
					intro: "You can click on these cards to add bank statements",
				},
				{
					element: ".editbc_bankin",
					intro: "Use this edit function to change any bank details",
				}
			],
			//   };
			// End Intro js user guid
		}
	}

	toggleAddCard = () => {
		if (this.state.show_add_card) {
			this.setState({ card_details: null })
		}
		this.setState({ show_add_card: !this.state.show_add_card })
	}

	toggleAddBankModal = async () => {
		if (this.state.show_add_bank_form) {
			this.setState({ card_details: null })
		}
		this.setState({ show_add_bank_form: !this.state.show_add_bank_form },async()=>{
			if(!this.state.show_add_bank_form){
				console.log("on modal close....................");
				this.props.refreshFIList();

				await MasterService.getEntityPermission(this.props.default_entity_detail.id).then(data => {
					if (data.response_code === 200) {
					  const module_permissions = data.result.modules;
					  const features_permissions_list = data.result.permissions;
					  const entity_config_param = data.result.configuration;
					 
					  this.props.dispatch(setBankGapCount(entity_config_param.banking_gap_count));
					 // this.setState({ is_loading: false })
					}
				  });
			}
		})
	}
	editInstitute = (institute) => {
		if (institute.is_credit_card === 0) {
			this.setState({ card_details: institute }, () => {
				this.toggleAddBankModal()
			})
		}
		if (institute.is_credit_card === 1) {
			this.setState({ card_details: institute }, () => {
				this.toggleAddCard()
			})
		}
	}

	callCardComponent = (list) => {
		return list.map((financial_institute) => {
			return <ActiveCardOrBankView
				financial_institute={financial_institute}
				editInstitute={this.editInstitute}
			/>
		})
	}

	getActiveBankList = () => {
		let list = this.props.FIList.filter(institute => {
			if (institute.bank_code !== null && institute.is_active === 1 && institute.is_credit_card === 0)
				return institute

		})
		return this.callCardComponent(list)
	}

	getActiveCardList = () => {
		let list = this.props.FIList.filter(institute => {
			if (institute.bank_code !== null && institute.is_active === 1 && institute.is_credit_card === 1)
				return institute
		})
		return this.callCardComponent(list)
	}

	notSupportingBankList = () => {
		let list = this.props.FIList.filter(institute => {
			if (institute.bank_code === null && institute.is_credit_card === 0)
				return institute
		})
		return this.callCardComponent(list)
	}

	notSupportingCardList = () => {
		let list = this.props.FIList.filter(institute => {
			if (institute.bank_code === null && institute.is_credit_card === 1)
				return institute
		})
		return this.callCardComponent(list)
	}

	getInActiveBankList = () => {
		let list = this.props.FIList.filter(institute => {
			if (institute.bank_code !== null && institute.is_active === 0 && institute.is_credit_card === 0)
				return institute
		})
		return this.callCardComponent(list)
	}

	getInActiveCardList = () => {
		let list = this.props.FIList.filter(institute => {
			if (institute.bank_code !== null && institute.is_active === 0 && institute.is_credit_card === 1)
				return institute
		})
		return this.callCardComponent(list)
	}

	// Intro js user guid   
	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};
	// End Intro js user guid 

	render() {

		// Intro js user guid 
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;
		// End Intro js user guid 

		return (
			<main className="content-wrapper-new pb-0">
				<div className="container-fluid container-padding-new">

					<DeletePopUp
						showModal={this.state.show_delete_popup}
						deleteLoading={this.state.deleteLoading}
						toggleDeletePopUp={this.toggleDeleteFI}
						deleteItem={this.deleteFI} />

					{/* Intro js user guid  */}
					<Steps
						enabled={stepsEnabled}
						steps={steps}
						initialStep={initialStep}
						onExit={this.onExit}
					/>

					{/* Breadcrumb */}
					<div className="row">
						<div className="col-12">
							<div className="main-sub-header-new">
								<div className="breadcrumb-header-new">
									<div className="row">
										<div className="col-12">
											<ol className="breadcrumb">
												<li className="breadcrumb-item-new"><Link to={'/home'}>Home</Link></li>
												<li className="breadcrumb-item-new"><Link to='/financial-institutes'>Banking</Link></li>
											</ol>
										</div>
									</div>
									<div className="row">
										<div className="col-8">
											<h2 className="breadcrumb-title-new">
												Banking
												{/* <span className="help-icon" > */}
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger>
												{/* </span> */}
											</h2>
										</div>
										<div className="col-4">
											<h2 className="breadcrumb-title-new sub-header-cicon text-right">
												{/* <span class="icon-Group-3587 cpointer" onClick={() => this.props.refreshFIList()}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> */}
												<button class="btn ico-refresh-sec blink-green-btn" onClick={() => this.props.refreshFIList()}><span class="icon-refresh"></span></button>
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Breadcrumb */}

					{/* Bank Cards  */}
					<div className="row">
						{(this.getActiveBankList())}
						{(this.getActiveCardList())}
						{(this.notSupportingBankList())}
						{(this.notSupportingCardList())}
						{(this.getInActiveBankList())}
						{(this.getInActiveCardList())}

						{this.props.features_permissions_list.includes(FI_CREATE_CODE) &&
							<React.Fragment>
								<div className="col-xl-3 col-lg-4 col-md-4">
									<div className="bank-card add-bank-card addbank_bankin" onClick={() => this.toggleAddBankModal()}>
										<h3 className="add-bank-text">
											<span class="icon-Group-2453 add-bank-icon"></span>
											<span className="d-block">Add New Bank</span>
										</h3>
									</div>
								</div>
								<div className="col-xl-3 col-lg-4 col-md-4">
									<div className="bank-card add-bank-card addcard_bankin" onClick={() => this.toggleAddCard()}>
										<h3 className="add-bank-text">
											<span class="icon-credit-card1 add-bank-icon"></span>
											<span className="d-block">Add New Card</span>
										</h3>
									</div>
								</div>
							</React.Fragment>
						}
					</div>
					{/* End Bank Cards  */}

					<AddNewCardModal
					ledgerNameList ={this.props.ledgerNameList}
						bank_name_list={this.props.bank_name_list || []}
						showModal={this.state.show_add_card}
						closeModal={this.toggleAddCard}
						card_details={this.state.card_details}
						updateList={this.props.updateList}
						FIList={this.props.FIList}
						getFIDetails={this.props.getFinancialInstituteDetails}
						default_entity_detail={this.props.default_entity_detail}
					/>
					<AddBankModal
						ledgerNameList = {this.props.ledgerNameList}
						showModal={this.state.show_add_bank_form}
						closeModal={this.toggleAddBankModal}
						card_details={this.state.card_details}
						updateList={this.props.updateList}
						FIList={this.props.FIList}
						getFIDetails={this.props.getFinancialInstituteDetails}
						default_entity_detail={this.props.default_entity_detail}
					/>
				</div>
			</main>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const features_permissions_list = all_value.features_permissions_list || [];
	const default_entity_detail = all_value.default_entity_detail || {};
	return { features_permissions_list, default_entity_detail }
}

export default connect(mapStateToProps)(withRouter(FinancialInstitutesList));