import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import ChequeService from '../../Services/Cheques/ChequeService';
import { setChequeFilter, setPageNo } from '../../Redux/Actions/Cheque';
import { withRouter } from 'react-router-dom';
import AddNew from '../Icon/AddNew';

class ChequeSideList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show_filter: false,
      uploaded_by_list: [],

      cheque_id: props.match.params.cheque_id ? props.match.params.cheque_id : 0,

      from_date: null,
      to_date: null,
      is_star: null,
      uploaded_by: null,
      cheque_no: null,
      bank_name: null,
      cheque_identifier: null,
      payer: null,
      payee: null,
      from_amount: null,
      to_amount: null,


    }
  }
  componentDidMount() {
    ChequeService.getUploadByList().then(data => {
      if (data.response_code == 200) {
        this.setState({ uploaded_by_list: data.result.uploaded_by })
      }
    })
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }

  toggleSideFilter = () => {
    this.setState({ show_filter: !this.state.show_filter })
  }

  apply = () => {
    let data = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      is_star: this.state.is_star,
      uploaded_by: this.state.uploaded_by,
      cheque_no: this.state.cheque_no,
      bank_name: this.state.bank_name,
      cheque_identifier: this.state.cheque_identifier,
      payer: this.state.payer,
      payee: this.state.payee,
      from_amount: this.state.from_amount,
      to_amount: this.state.to_amount
    }
    this.props.dispatch(setChequeFilter(data));
    this.props.applyFilter("apply");
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.match.params.cheque_id !== this.props.match.params.cheque_id)) {
      this.setState({
        cheque_id: nextProps.match.params.cheque_id ? nextProps.match.params.cheque_id : 0
      })
    }
  }

  chequeSelected = (id) => {
    this.props.history.push('/cheque/create/' + id)
  }

  newCheque = () => {
    this.props.history.push('/cheque/create')
  }

  setDate = (date, key) => {
    this.setState({ [key]: date })
  }
  applyFilter = () => {
    let data = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      is_star: this.state.is_star,
      uploaded_by: this.state.uploaded_by,
      cheque_no: this.state.cheque_no,
      bank_name: this.state.bank_name,
      cheque_identifier: this.state.cheque_identifier,
      payer: this.state.payer,
      payee: this.state.payee,
      from_amount: this.state.from_amount,
      to_amount: this.state.to_amount
    }
    this.props.dispatch(setChequeFilter(data));
    this.props.applyFilter("apply");
  }
  resetFilter = () => {
    let filter_data = {
      from_date: null,
      to_date: null,
      is_star: null,
      uploaded_by: null,
      cheque_no: null,
      bank_name: null,
      cheque_identifier: null,
      payer: null,
      payee: null,
      from_amount: null,
      to_amount: null
    }
    this.setState({
      from_date: null,
      to_date: null,
      is_star: "",
      uploaded_by: "",
      cheque_no: "",
      bank_name: "",
      cheque_identifier: "",
      payer: "",
      payee: "",
      from_amount: "",
      to_amount: ""
    })
    this.props.dispatch(setChequeFilter(filter_data));
    this.props.applyFilter("reset");
  }

  getChequeList = (page_no) => {
    this.props.dispatch(setPageNo(page_no))
  }
  render() {
    return (
      <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
        <div className="clearfix">
          <ol className="breadcrumb pull-left">
            <li className="breadcrumb-item">
            <Link to={'/home'}>Home</Link>
                </li>
            <li className="breadcrumb-item">Cheques</li>
          </ol>
          <div className="pull-right d-flex new-add-filter-sec">
            <div className="sidebar-evidence-filter"
              onClick={() => this.toggleSideFilter()}
            >
              <span className="icon-filter"></span>
            </div>
            <AddNew addNew={this.newCheque} />
            {/* <div className="add-new-evi-sms" >
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
                <span className="icon-plus" onClick={() => this.newCheque()}></span>
              </OverlayTrigger>
            </div> */}
          </div>
        </div>
        <hr />

        {/* <div className="btn-group btn-toggle left-on-off-btn pull-left"> 
              <button className="btn btn-lg btn-default active">Evidence</button>
              <button className="btn btn-lg btn-primary ">SMS</button>
            </div> */}




        <div className="recipt-sidebar-sec">
          {
            this.state.show_filter ? <div className="main-evidence-sidefilter">
              <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control as="select" name="is_star" value={this.state.is_star} onChange={this.handleChange}  >
                        <option value="">Starred</option>
                        <option value="1" >Yes</option>
                        <option value="0">No</option>
                        <option value={null}>Both</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </div>
                <div className="col">
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <input type="text" className="form-control" name="cheque_identifier"
                      value={this.state.cheque_identifier} placeholder="ID" onChange={this.handleChange} />
                  </div>
                </div>

              </div>
              {/* <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control as="select">
                          <option>Custom Date Range</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <div className="start-end-date-sec btn">
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={date => this.setDate(date, "from_date")}
                        className="form-control"
                        selected={this.state.from_date}
                        autoComplete="off"
                        placeholder="From Date"
                        name="from_date" />
                      <span className="icon-calendar"></span>
                      <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <hr />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <div className="start-end-date-sec btn">
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={date => this.setDate(date, "to_date")}
                        className="form-control"
                        selected={this.state.to_date}
                        autoComplete="off"
                        placeholder="To Date"
                        name="to_date" />
                      <span className="icon-calendar"></span>
                      <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                    </div>
                  </div>
                </div>
              </div>
              */}
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Form>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control as="select" disabled >
                          <option>Cheque Date</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    {/* <div className="start-end-date-sec btn"> */}
                    <div className="form-group date-sec">
                      <div className="inner-date-sec">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          onChange={date => this.setDate(date, "from_date")}
                          className="form-control"
                          selected={this.state.from_date}
                          autoComplete="off"
                          placeholderText="From Date"
                          name="from_date" />
                        <span className="icon-calendar"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <hr />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <div className="form-group date-sec">
                      {/* <div className="start-end-date-sec btn"> */}
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={date => this.setDate(date, "to_date")}
                        className="form-control"
                        selected={this.state.to_date}
                        autoComplete="off"
                        placeholderText="To Date"
                        name="to_date" />
                      <span className="icon-calendar"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input type="text" className="form-control" value={this.state.bank_name}
                      name="bank_name" placeholder="Bank" onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <input type="text" className="form-control text-right" name="from_amount"
                      value={this.state.from_amount} placeholder="From Amount" onChange={this.handleChange} />
                  </div>
                </div>
                <div className="col">
                  <hr />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <input type="text" className="form-control text-right" name="to_amount"
                      value={this.state.to_amount} placeholder="To Amount" onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input type="text" className="form-control" value={this.state.payer}
                      name="payer" placeholder="Payer" onChange={this.handleChange} />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" value={this.state.payee}
                      name="payee" placeholder="Payee" onChange={this.handleChange} />
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <input type="text" className="form-control" value={this.state.cheque_no}
                      name="cheque_no" placeholder="Cheque#" onChange={this.handleChange} />
                  </div>
                </div>
                <div className="col">
                </div>
                <div className="col-lg-5 col-sm-12">
                  <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control as="select" name="uploaded_by" value={this.state.uploaded_by} onChange={this.handleChange}>
                        <option value="">Uploaded By</option>
                        {this.state.uploaded_by_list.map((item, index) => {
                          return <option value="1">fsf</option>
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg" onClick={this.resetFilter}>Reset</button>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold" onClick={this.applyFilter} >Apply</button>
                </div>
              </div>
            </div> : null
          }

          {/* <div className="main-applied-sidefilter d-none">
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">star</span>
              <span className="ft-sz-16 s-sbold blu-color">id</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">Last Financial Year</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
              <span className="ft-sz-16 s-sbold blu-color">to</span>
              <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">cheque  date</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
              <span className="ft-sz-16 s-sbold blu-color">to</span>
              <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">bank</span>
              <span className="ft-sz-16 s-sbold blu-color">₹300 - ₹5000</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">payer</span>
              <span className="ft-sz-16 s-sbold blu-color">payee</span>
            </div>
            <div className="row">
              <span className="ft-sz-16 s-sbold blu-color">cheque</span>
              <span className="ft-sz-16 s-sbold blu-color">uploaded by</span>
            </div>
          </div> */}

          {this.props.cheque_list.length === 0 && <p className="ft-sz-18 s-sbold blu-color">No Cheques yet</p>}
          {this.state.cheque_id == 0 && <div className="recipt-inner-div invoice-side-list empty-div active">
            <div className=""></div>
            <div className="row">
              <p className="m-0 ft-sz-28 font-colr s-sbold">New Cheque</p>
            </div>
          </div>
          }

          {this.props.cheque_list.map((cheque, index) => {
            return <div className={"recipt-inner-div invoice-side-list"+ (cheque.id == this.state.cheque_id ? ' active':'') }
             onClick={() => this.chequeSelected(cheque.id)}>
              <div className=""></div>
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <div className="main-payer-name">
                    <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p>
                    <p className="m-0 ft-sz-17 s-sbold font-colr">{cheque.payer}</p>
                  </div>

                  <div className="main-payer-name">
                    <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p>
                    <p className="mb-1 ft-sz-17 s-sbold font-colr">{cheque.payee}</p>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="evi-icon">
                    <div className="color-icon">
                      <span className="icon-Group-2509">
                        <span className="path1"></span><span className="path2"></span>
                        <span className="path3"></span><span className="path4"></span>
                        <span className="path5"></span><span className="path6"></span>
                        <span className="path7"></span><span className="path8"></span>
                        <span className="path9"></span>
                      </span>
                    </div>
                  </div>
                  <div className="amount s-sbold blu-color">
                    <span>&#8377;{cheque.amount} <span class="icon-Red-arrow tblarow red-color"></span></span>
                  </div>
                </div>
              </div>
            </div>

          })}


        </div>
        <div className="main-pagination clearfix sidebar-pagination">
          <nav aria-label="Page navigation example">
            <div className="per-page-sec clearfix">
              <ul className="pagination pull-right mb-0">
                <li className="page-item"><a className="page-link ft-sz-18 s-reg">Items per Page</a></li>
                <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg">{this.props.per_page_items}</a></li>
              </ul>
            </div>
            <ul className="pagination pull-right mb-0">
              {this.props.has_previous && <li className="page-item prev-sec" onClick={() => this.getChequeList(this.props.page_no - 1)}
              ><a className="page-link ft-sz-18 s-reg"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>}
              <li className="page-item active"><a className="page-link ft-sz-18 s-reg" >{this.props.page_no}</a></li>
              {this.props.has_next && <li className="page-item next-sec" onClick={() => this.getChequeList(this.props.page_no + 1)}
              ><a className="page-link ft-sz-18 s-reg" >Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>}
            </ul>
          </nav>
        </div>
      </div>

    )
  }
}

function mapStateToProps(state) {
  const all_value = state.cheque
  const total_count = all_value.total_count;
  const page_no = all_value.page_no;
  const per_page_items = all_value.per_page_items;
  return {
    total_count, page_no, per_page_items
  };
}


export default connect(mapStateToProps)(withRouter(ChequeSideList));
