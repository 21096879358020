import React from 'react';
import Table from 'react-bootstrap/Table';
import LicenseService from '../../../Services/Licenses/LicenseService';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import { convertToCurrency } from '../../../Helpers/Currency';
import MessageModal from '../../../Components/BoilerPlate/MessageModal';
import { setBuyLicenseQuantity } from '../../../Redux/Actions/Licenses';
import SessionManagement from '../../../Utils/SessionManagement';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';
import { Button } from 'react-bootstrap';

class BuyLicenses extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            license_details: [],
            final_price: 0,
            final_quantity: 0,
            offer_text: "",
            closed: true,
            is_dirty: false,
            promo_code: null,
            discount: null
        }
    }

    componentDidMount() {
        const orderData = JSON.parse(localStorage.getItem('order_data'));

        if (localStorage.getItem('order_data') && orderData.final_quantity > 0) {
            this.setState({
                license_details: orderData.license_details,
                final_price: orderData.final_price,
                final_quantity: orderData.final_quantity,
                offer_text: orderData.offer_text,
                promo_code: orderData.promo_code
            })
        } else {
            this.getLicensePackageDetails();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        console.log(nextState)
        localStorage.setItem('order_data', JSON.stringify(nextState));
    }

    getLicensePackageDetails = () => {
        this.props.dispatch(setAppilcationLoder(true));
        LicenseService.getLicensePackageDetails()
            .then(data => {
                if (data.response_code === 200) {

                    data.result.license.forEach(function (element) {
                        element.quantity = 0;
                        element.total_rate = 0;
                    });
                    this.setState({ license_details: data.result.license, offer_text: data.result.offer_text, promo_code: data.result.promo_code || "" })
                } else {
                    toast.error(data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    calculateFinalPrice = () => {
        this.setState({ is_dirty: true })
        let selectedList = this.state.license_details;

        const finalPrice = selectedList.reduce(
            (prevValue, currentValue) => prevValue + currentValue.total_rate,
            0
        );

        const finalQuantity = selectedList.reduce(
            (prevValue, currentValue) => prevValue + currentValue.quantity,
            0
        );

        this.setState({
            final_price: finalPrice,
            final_quantity: finalQuantity || 0
        })
    }

    incrementItem = (index) => {
        let packages = [...this.state.license_details];
        var licensePackage = { ...packages[index] };

        licensePackage.quantity = (licensePackage.quantity + 1);

        if (licensePackage.licence_price_discounted) {
            licensePackage.total_rate = (licensePackage.quantity * licensePackage.licence_price_discounted);
        } else {
            licensePackage.total_rate = (licensePackage.quantity * licensePackage.licence_price);
        }
        packages[index] = licensePackage;

        this.setState({ license_details: packages }, () => {
            this.calculateFinalPrice();
        });

        //this.props.dispatch(setBuyLicenseQuantity(packages))
    }

    decrementItem = (index) => {
        let packages = [...this.state.license_details];
        var licensePackage = { ...packages[index] };

        if (licensePackage.quantity !== 0) {
            licensePackage.quantity = licensePackage.quantity - 1;

            if (licensePackage.licence_price_discounted) {
                licensePackage.total_rate = (licensePackage.quantity * licensePackage.licence_price_discounted);
            } else {
                licensePackage.total_rate = (licensePackage.quantity * licensePackage.licence_price);
            }

            packages[index] = licensePackage;

            this.setState({ license_details: packages }, () => {
                this.calculateFinalPrice();
            });
        }

        //this.props.dispatch(setBuyLicenseQuantity(packages))
    }

    quantityItemChange = (e, index) => {
        let packages = [...this.state.license_details];
        var licensePackage = { ...packages[index] };
        e.target.value = e.target.value.replace(/^0+/, '');
        if (!e.target.value) {
            e.target.value = 0;
        }
        licensePackage.quantity = parseInt(e.target.value);

        if (licensePackage.licence_price_discounted) {
            licensePackage.total_rate = (licensePackage.quantity * licensePackage.licence_price_discounted);
        } else {
            licensePackage.total_rate = (licensePackage.quantity * licensePackage.licence_price);
        }
        packages[index] = licensePackage;

        this.setState({ license_details: packages }, () => {
            this.calculateFinalPrice();
        });

        //this.props.dispatch(setBuyLicenseQuantity(packages))
    }

    goToLicenseListingPage = () => {
        this.setState({ is_dirty: false }, function () {
            localStorage.removeItem("order_data");
            localStorage.removeItem("upgrade_order_data")
            localStorage.removeItem("renew_order_data")
            localStorage.removeItem("bill_to_data")

            this.props.history.push("/licenses");
        })
    }

    goToOrderSummaryPage = () => {
        if (this.state.final_quantity > 0) {
            var details = this.state.license_details;

            //this.props.dispatch(setBuyLicenseQuantity(details));
            this.setState({ is_dirty: false }, function () {
                const license_details = this.state.license_details;
                license_details.promocode = this.state.promo_code

                this.props.history.push({
                    pathname: '/licenses/order-summary',
                    state: { order_data: license_details, type: "new", promo_code: this.state.promo_code, discount: this.state.discount }
                })
            })
        } else {
            this.toggleMessageModal();
        }
    }

    toggleMessageModal = () => {
        this.setState({ quantity_alert: !this.state.quantity_alert })
    }

    goToProfileManagement = () => {
        this.props.history.push("/profile-management/entities/");
    }

    closeAction = () => {
        this.setState({ closed: true })
    }

    render() {
        return (
            <main className="content-wrapper-new">
                <div className="container-fluid container-padding-new">

                    <RouteLeavingGuard
                        when={this.state.is_dirty}
                        closed={this.state.closed}
                        closeAction={this.closeAction}
                    />

                    {/* Breadcrumb */}
                    <div className="row">
                        <div className="col-12">
                            <div className="main-sub-header-new">
                                <div className="breadcrumb-header-new">
                                    <div className="row">
                                        <div className="col-5 pr-0">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item-new"><Link to={'/home'}>Home</Link></li>
                                                <li className="breadcrumb-item-new" onClick={this.goToProfileManagement}>Profile Management</li>
                                                <li className="breadcrumb-item-new" onClick={this.goToLicenseListingPage}>My Licenses</li>
                                                <li className="breadcrumb-item-new">Buy Licenses</li>
                                            </ol>
                                            <h2 className="breadcrumb-title-new align-items-baseline">Buy Licenses <a href="https://thebuktec.com/pricing/" target="_blank" className="green-link size-xvi ml-2">View all licenses</a></h2>
                                        </div>

                                        <div className="col-7 pr-0 align-self-center text-right">
                                            <ul className="process_step_bar">
                                                <li className="active_process">Select Licenses for Buying</li>
                                                <li className="">Order Summary</li>
                                                <li className="">Make Payment</li>
                                                {/* <li className="dot_process">Assign</li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrumb */}


                    {/* Message Modal Popup */}
                    <MessageModal showModal={this.state.quantity_alert} content={"Please select atleast one License."} toggleMessageModal={this.toggleMessageModal} />
                    {/* Message Modal Popup */}


                    {/* Table */}
                    <div className="row">
                        <div className="col-12">
                            <Table responsive className="licensesTable">
                                <colgroup>
                                    <col width="" />
                                    <col width="20%" />
                                    <col width="15%" />
                                    <col width="15%" />
                                </colgroup>
                                <tr>
                                    <th scope="col" className="pl-0">License Type</th>
                                    <th scope="col" className="text-center">Rate/Annum*</th>
                                    <th scope="col" className="text-center">Quantity</th>
                                    <th scope="col" className="pr-0 text-right">Total</th>
                                </tr>

                                {this.state.license_details.map((detail, index) => {
                                    return <tr key={index}>
                                        <td scope="col" className="pl-0">
                                            <div className="licence_name">
                                                {detail.licence_name} {detail.recommended && <span className="recomtext">Recommended</span>}
                                            </div>
                                            <ul className="licence-list">
                                                {detail.features.map((feature, index1) => {
                                                    return <li key={index1} className="">{feature.quantity} {feature.feature_name}</li>
                                                })}
                                            </ul>
                                        </td>

                                        {/* <td className="text-center">
                                            <div className={`rate-annum-sec ft-sz-30 s-sbold bg-g white-color m-0 ${detail.licence_price_discounted ? "line-through" : ""}`}>₹{convertToCurrency(detail.licence_price)} { detail.licence_price_discounted && <div className="rate-noti-sec ft-sz-20 s-sbold">₹{convertToCurrency(detail.licence_price_discounted)}</div> } </div>
                                        </td> */}
                                        <td scope="col" className="text-center">
                                            <span className={`${detail.licence_price ? "blue-text" : ""}`}>₹{convertToCurrency(detail.licence_price)}</span>
                                        </td>
                                        <td scope="col" className="text-center">
                                            <input type="number" min="0" class="form-control qty-lt text-center" value={detail.quantity} onChange={(e) => this.quantityItemChange(e, index)}></input>
                                        </td>
                                        <td scope="col" className="pr-0 text-right">
                                            <span className="total_rate">₹{convertToCurrency(detail.total_rate)}</span>
                                        </td>
                                    </tr>
                                })}

                                <tr className="tr-total">
                                    <td className="pl-0">
                                        {/* <textarea readOnly className="form-control" rows="2" placeholder="Offer text here" value={this.state.offer_text}></textarea>   */}
                                        {this.state.offer_text}
                                    </td>
                                    <td className="text-center">Total</td>
                                    <td className="text-center">{this.state.final_quantity}</td>
                                    <td className="pr-0 text-right"><span className="total_rate">₹{convertToCurrency(this.state.final_price)}</span></td>
                                </tr>
                            </Table>
                        </div>
                    </div>
                    {/* End Table */}

                    <div className="row">
                        <div className="col-sm-12 text-right">
                            <p className="p-big mb-3">* Prices inclusive of GST</p>
                        </div>
                        <div className="col-sm-6 ">
                            <div className={(!!SessionManagement.getLastUrl(false) ? "" : " d-none")}>
                                <Link className="btn btn-outline-success reset_btn_new" to={SessionManagement.getLastUrl(false)} onClick={() => SessionManagement.removeLastUrl()}>Back</Link>
                            </div>
                        </div>
                        <div className="col-sm-6 text-right ">
                            <div className="reset_apply_btn_new mt-0">
                                <Button variant="outline-success reset_btn_new" onClick={this.goToLicenseListingPage}>Cancel</Button>
                                <Button variant="success apply_btn_new" onClick={this.goToOrderSummaryPage}>Next</Button>
                            </div>
                        </div>
                    </div>  
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const all_value = state.BuyLicenses || {};
    const license_details = all_value.license_details || {};

    // return {
    //     license_details
    // };

    return {}
}

export default connect(mapStateToProps)(withRouter(BuyLicenses));
//export default withRouter(BuyLicenses);