import React, { Component } from 'react';
import AccountService from '../../Services/ProfileManagement/AccountService';
import MasterService from '../../Services/MasterService';
import { validateMyAccount } from './Validation'
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import SessionManagement from '../../Utils/SessionManagement';
import ImageUpload from '../BoilerPlate/ImageUpload';
import { PROFILE_MANAEGEMT_FILE_CODE } from '../../Helpers/Constants';
import StorageData from '../../Helpers/StorageData';
import { Image, Button } from 'react-bootstrap';
import NumberTypeInputBox from '../BoilerPlate/InputNumber';


class AccountDetail extends Component {

  constructor(props) {
    super(props);
    this.ACCOUNT_TYPE = (SessionManagement.getUserDetail()).account_type;

    this.state = {
      selected_file: null,
      logo_files: [],
      account_logo: "",
      file_name: "",
      file_type: "",
      warningMsg: "",
      profile_changed: false,

      account_code: "",
      address: "",
      name: "",
      email: "",
      full_name: "",
      dialcode_id: "1",
      phone_number: "",
      gst_no: "",
      country_id: "1",
      state_id: "",
      city_id: "",
      pincode: "",

      countries: [],
      states: [],
      cities: [],

      errors: {},
      is_view_only: true,

      is_emailid: false,
      is_password: false,
      is_username: false,
      loading: false,
    };
  }


  addFile = (file, uploadtype) => {
    this.setState({
      is_dirty: true,
      profile_changed: true,
      logo_files: file.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    });
  };

  removeFile = (uploadtype) => {
    this.setState({
      logo_files: [],
      account_logo: "",
    });
  }

  onFileChange = event => {
    let file = event.target.files[0];
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/JPG', 'image/PNG'];
    if (!acceptedImageTypes.includes(file.type)) {
      const message = "Please select image file jpeg,jpg,png";
      this.setState({ warningMsg: message });
    } else if (file.size > 1024 * 1000) {
      const message = "Please select file below 1MB";
      this.setState({ warningMsg: message });
      return true
    } else {
      this.setState({ warningMsg: "" });
      // Update the state
      const file_name = file.name;
      const file_type = file.type;
      this.setState({ selected_file: file, file_name, file_type });
    }
  };

  toggleEditAccount = () => {
    this.setState({
      is_view_only: !this.state.is_view_only
    });
  }

  handleOnChange = (e) => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  onCountryChange = (e) => {
    let country_id = e.target.value;
    if (country_id) {
      this.setState({
        country_id: country_id,
        state_id: "",
        city_id: ""
      });
      this.getStates(country_id);
    }
  }

  onStateChange = (e) => {
    let state_id = e.target.value;
    if (state_id) {
      this.setState({
        state_id: state_id,
        city_id: ""
      });
      this.getCities(state_id);
    }
  }

  onCityChange = (e) => {
    let city_id = e.target.value;
    if (city_id) {
      this.setState({
        city_id: city_id
      });
    }
  }

  getAccountDetail = () => {
    AccountService.getAccountDetail().then(
      (data) => {
        if (data.response_code == 200) {
          let account_detail = data.result;
          this.setState({
            account_code: account_detail.account_code,
            address: account_detail.address,
            email: account_detail.email,
            full_name: account_detail.full_name,
            dialcode_id: account_detail.dialcode_id,
            phone_number: account_detail.phone_number,
            gst_no: account_detail.gst_no || "",
            country_id: !!account_detail.country_id ? account_detail.country_id : "",
            state_id: account_detail.state_id || "",
            city_id: account_detail.city_id || "",
            pincode: account_detail.pincode || "",
            account_detail: account_detail,
          });
          if (this.ACCOUNT_TYPE == 2) {
            this.setState({
              logo_files: account_detail.qualified_url ? [{ preview: StorageData.getQualifiedFileUrl(account_detail.qualified_url) }] : [],
              account_logo: account_detail.account_logo
            })
          }
          if (account_detail.country_id)
            this.getStates(account_detail.country_id);
          if (account_detail.state_id)
            this.getCities(account_detail.state_id);
        }
      });
  }

  getCountries = () => {
    MasterService.getCountries().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            countries: data.result.countries
          });
        }
      });
  }
  getStates = (country_id) => {
    MasterService.getStates(country_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            states: data.result.states
          });
        }
      });
  }
  getCities = (state_id) => {
    MasterService.getCities(state_id).then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            cities: data.result.cities
          });
        }
      });
  }

  componentDidMount() {
    this.getCountries();
    this.getAccountDetail();
  }


  updateAccountDetail = async (e) => {
    e.preventDefault();

    this.setState({
      loading: true
    });

    let data = {
      gst_no: this.state.gst_no,
      address: this.state.address,
      city_id: !!this.state.city_id ? this.state.city_id : null,
      state_id: !!this.state.state_id ? this.state.state_id : null,
      country_id: !!this.state.country_id ? this.state.country_id : null,
      pincode: this.state.pincode,
      full_name: this.state.full_name,
      dialcode_id: this.state.dialcode_id,
      phone_number: this.state.phone_number
    };

    let errors = validateMyAccount(data);

    // Code added by Ram
    if (Object.keys(errors).length >= 1) {
      toast.error("Wrong input provided !!")
    }
    // end

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {}
      })
      if (this.ACCOUNT_TYPE == 2) {
        if (this.state.logo_files.length && this.state.profile_changed) {
          const logo_files = this.state.logo_files[0];
          const file_name = logo_files.name;
          const file_type = logo_files.type;
          const signed_url_data = await MasterService.getSignedUploadFile(logo_files, file_name, file_type, PROFILE_MANAEGEMT_FILE_CODE)
          if (signed_url_data) {
            data.account_logo = signed_url_data.gcp_subpath;
          }
        } else {
          data.account_logo = this.state.account_logo;
        }
      }
      console.log("data.account_logo", data.account_logo);
      AccountService.updateAccount(data).then(
        (data) => {
          this.setState({
            loading: false
          });
          if (data.response_code == 200) {
            this.toggleEditAccount();
            toast.success(data.message);
          } else {
            toast.error(data.message);
            this.setState({ errors: data.reason });
          }
        });
    } else {
      this.setState({
        loading: false,
        errors: errors
      });
    }

  }

  render() {
    return (
      <form onSubmit={this.updateAccountDetail} className='form' noValidate>
        {/* My Account */}
        <div className="form-inner-blue-new sec-mb">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center gap-10 heading-blue-border">
                <h2 className="mb-0">My Account</h2>
                <Button variant="outline-success account_btn">
                  <span className="account_btn_icon"><span className="icon-Mask-Group-1 ico-moon"></span></span>
                  <span className="account_btn_text">{this.ACCOUNT_TYPE == 1 ? "Business Account" : "Accounting Firm"}</span>
                </Button>
                {this.props.account_permissions.includes(18) &&
                  (this.state.is_view_only ?
                    <h2 className="cpointer mb-0" onClick={this.toggleEditAccount}><span class="icon-edit" ></span></h2>
                    :
                    <h2 className="cpointer mb-0" onClick={this.toggleEditAccount}><span class="icon-Cross-with-grey-circle" ><span class="path1"></span><span class="path2"></span><span class="path3"></span></span></h2>
                  )
                }
              </div>
            </div>
          </div>

          {/* Form Fields */}
          <div className="row">
            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">Account ID</lable>
                <input type="text"
                  // className={"form-control input-bg-gray " + (this.state.errors.account_code ? " wrong-red " : "")}
                  className="form-control"
                  value={this.state.account_code}
                  onChange={this.handleOnChange}
                  disabled="disabled"
                  name="account_code" />
              </div>
            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">Account Email</lable>
                <input type="text"
                  // className={"form-control input-bg-gray " + (this.state.errors.email ? " wrong-red " : "") + (this.state.is_view_only ? "input-bg-gray" : "")}
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleOnChange}
                  disabled={this.state.is_view_only ? "disabled" : "disabled"}
                  name="email" />
              </div>
            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">Account Name<span className="red-star">*</span></lable>
                <input type="text"
                  // className={"form-control " + (this.state.errors.full_name ? " wrong-red " : "") + (this.state.is_view_only ? "input-bg-gray" : "")}
                  className="form-control"
                  value={this.state.full_name}
                  onChange={this.handleOnChange}
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  name="full_name" />
                {this.state.errors.full_name && <small className="form-text text-muted">{this.state.errors.full_name}</small>}
              </div>
            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">Mobile Number<span className="red-star">*</span></lable>
                <NumberTypeInputBox type="text"
                  // className={"form-control " + (this.state.errors.phone_number ? " wrong-red " : "") + (this.state.is_view_only ? " input-bg-gray " : "")}
                  className="form-control"
                  value={this.state.phone_number}
                  onChange={this.handleOnChange}
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  maxLength={10}
                  name="phone_number" />
                {this.state.errors.phone_number && <small className="form-error-text">{this.state.errors.phone_number}</small>}
              </div>
            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">GST Number</lable>
                <input type="text"
                  // className={"form-control " + (this.state.errors.gst_no ? " wrong-red " : "") + (this.state.is_view_only ? "input-bg-gray" : "")}
                  className="form-control"
                  value={this.state.gst_no}
                  onChange={this.handleOnChange}
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  name="gst_no" />
                {this.state.errors.gst_no && <small className="form-error-text">{this.state.errors.gst_no}</small>}
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-xl-5 col-md-4">
              <div className="form-group">
                <lable className="lable-input">Address</lable>
                <input type="text"
                  // className={"form-control " + (this.state.errors.address ? " wrong-red " : "") + (this.state.is_view_only ? " input-bg-gray" : "")}
                  className="form-control"
                  value={this.state.address}
                  onChange={this.handleOnChange}
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  name="address" />
                {this.state.errors.address && <small className="form-error-text">{this.state.errors.address}</small>}
              </div>
            </div>


            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">Country</lable>
                <select
                  // className={"form-control " + (this.state.errors.country_id ? " wrong-red " : "") + (this.state.is_view_only ? "input-bg-gray" : "")}
                  className="form-control"
                  value={this.state.country_id}
                  onChange={this.onCountryChange}
                  disabled={this.state.is_view_only ? "disabled" : ""}>
                  <option value="">Select Country</option>
                  {
                    this.state.countries.map((country, i) => {
                      return <option key={i} value={country.id}>{country.name}</option>
                    })
                  }
                </select>
                {this.state.errors.country_id && <small className="form-error-text">{this.state.errors.country_id}</small>}
              </div>

            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">State<span className="red-star d-none">*</span></lable>
                <select
                  // className={"form-control " + (this.state.errors.state_id ? " wrong-red " : "") + (this.state.is_view_only ? "input-bg-gray" : "")}
                  className="form-control"
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  value={this.state.state_id}
                  onChange={this.onStateChange} >
                  <option value="">Select State</option>
                  {
                    this.state.states.map((state, i) => {
                      return <option key={i} value={state.id}>{state.name}</option>
                    })
                  }
                </select>
                {this.state.errors.state_id && <small className="form-error-text">{this.state.errors.state_id}</small>}
              </div>

            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">City</lable>
                <select
                  // className={"form-control " + (this.state.errors.city_id ? " wrong-red " : "") + (this.state.is_view_only ? " input-bg-gray" : "")}
                  className="form-control"
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  value={this.state.city_id}
                  onChange={this.onCityChange} >
                  <option value="">Select City</option>
                  {
                    this.state.cities.map((city, i) => {
                      return <option key={i} value={city.id}>{city.name}</option>
                    })
                  }
                </select>
                {this.state.errors.city_id && <small className="form-error-text">{this.state.errors.city_id}</small>}
              </div>

            </div>

            <div className="col-xl col-md-4">
              <div className="form-group">
                <lable className="lable-input">Pincode</lable>
                <NumberTypeInputBox type="text"
                  // className={"form-control " + (this.state.errors.pincode ? "wrong-red" : "") + (this.state.is_view_only ? " input-bg-gray" : "")}
                  className="form-control"
                  disabled={this.state.is_view_only ? "disabled" : ""}
                  value={this.state.pincode}
                  onChange={this.handleOnChange}
                  maxLength={6}
                  name="pincode" />
                {this.state.errors.pincode && <small className="form-error-text">{this.state.errors.pincode}</small>}
              </div>
            </div>
          </div>

          {this.ACCOUNT_TYPE == 2 ?
            <React.Fragment>
              <div className="row">
                <div className="col-xl-2">
                  <div className="form-group mb-0">
                    <lable className="lable-input">Logo</lable>
                    <div className="mt-2" style={{width:"170px"}}>
                      {this.state.is_view_only ?
                        !!this.state.logo_files.length ? <Image src={this.state.logo_files[0].preview} thumbnail className="pm_thumbnail_img" /> :
                          <div className="profile-upload-logo">
                            <span className="icon-Group-2328 upload-icon"></span>
                            <p className="profile-p mb-0">Upload Your Logo</p>
                          </div>
                        : <React.Fragment>
                          <ImageUpload addFile={this.addFile} removeFile={this.removeFile} uploadtype="account_logo" files={this.state.logo_files} />
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <small className="form-error-text">{this.state.warningMsg}</small>
            </React.Fragment>
            :
            ""
          }
          {/* End Form Fields */}
        </div>
        {/* End My Account */}

        {/* Update btn */}
        {!this.state.is_view_only &&
          <div className="text-right sec-mb">
            <Button type="submit" variant={"success apply_btn_new  " + (this.state.loading ? "position-reltv" : "")} disabled={this.state.loading}>Update</Button>
          </div>
        }
        {/* {this.state.loading && ( <span className="spinner-border spinner-border-sm"></span> )} */}
      </form>
    );
  }
}

function mapStateToProps(state) {
  const session_value = state.Session || {};
  const account_permissions = session_value.account_permissions || [];
  return {
    account_permissions,
  };
}

export default connect(mapStateToProps)(AccountDetail);