import React, { Component } from 'react';
import { setInvoiceFilterData } from "../../Redux/Actions/Invoices";
import { connect } from "react-redux";
import CurrencyInput from 'react-currency-input-field';


class InvoiceFilter extends Component{

	constructor(props) {
		super(props);
		this.state = {
		}
}

	handleOnChange = (e) => {
		const {name,value} = e.target;
		let filter_data = {
			[name] : value
		}
		this.props.dispatch(setInvoiceFilterData(filter_data));
	}

	applyFilter = () => {
		this.props.applyFilter("apply");
	}

	resetFilter = () => {
		let filter_data = {
			invoice_type:0,
			invoice_number:"",
			customer_name:"",
			from_amount : "",
			to_amount : "",
			payment_status : "",
			created_by:"",
			is_star:"",
		}
		this.props.dispatch(setInvoiceFilterData(filter_data));
		this.props.applyFilter("reset");
	}

  render(){
		return <div className="main-filter-sec clearfix">
			<div className="row inner-row">				
				<div className="col-xl-2 col-lg-3 col-md-4 col-xs-12">
					<div className="form-group">
						<select className="form-control"
							onChange={this.handleOnChange}
							value={this.state.is_star} 
							name="is_star" >
							<option value="">Stared</option>
							<option value="1">Yes</option>
							<option value="0">No</option>
						</select>  
					</div>
				</div>
				<div className="col-xl-2 col-lg-3 col-md-4 col-xs-12">
					<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.invoice_type} 
								name="invoice_type" >
								<option value="0">Type</option>
								<option value="1">Invoice</option>
								<option value="2">Proforma</option>
							</select>  
					</div>
				</div>
				<div className="col-xl-2 col-lg-3 col-md-4 col-xs-12">
					<div className="form-group">
						<input type="text"
							className="form-control"
							placeholder="Invoice Number"
							value={this.props.invoice_number}
							onChange={this.handleOnChange}
							name="invoice_number" />  
					</div>
				</div>
				<div className="col-xl-2 col-lg-3 col-md-4 col-xs-12">
					<div className="form-group">
						<input type="text"
							className="form-control"
							placeholder="Customer Name"
							value={this.props.customer_name}
							onChange={this.handleOnChange}
							name="customer_name" />  
					</div>
				</div>
				<div className="col-lg-4 col-md-6 col-xs-12">
					<div className="row main-due-date-sec evidence-filter no-gutters">
						<div className="col-lg col-md col-xs-12">
							<div className="form-group">
								<CurrencyInput type="text"
									className="form-control text-right"
									placeholder="From Amount "
									decimalsLimit={2}
									prefix="&#8377; "
									// onValueChange={(value, name) => this.amountChange(name, value)}
									// value={this.props.from_amount}
									allowNegativeValue={false}
									name="from_amount"/>
							</div>
						</div>
						<div className="col-lg-1 col-md-1">
							<hr />
						</div>
						<div className="col-lg col-md col-xs-12">
							<div className="form-group">
							<CurrencyInput type="text"
								className="form-control text-right"
								placeholder="To Amount "
								decimalsLimit={2}
								prefix="&#8377; "
								// onValueChange={(value, name) => this.amountChange(name, value)}
								// value={this.props.to_amount}
								allowNegativeValue={false}
								name="to_amount"/>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-2 col-lg-3 col-md-4 col-xs-12">
					<div className="form-group">
							<select className="form-control"
								onChange={this.handleOnChange}
								value={this.props.payment_status} 
								name="payment_status" >
								<option value="0">Payment Status</option>
								<option value="1">Paid</option>
								<option value="2">Unpaid</option>
								<option value="3">Part Payment</option>
							</select>  
					</div>
				</div>
			{/* </div>
			<div className="row inner-row"> */}
				<div className="col-xl-2 col-lg-3 col-lg-3 col-md-4 col-xs-12">
					<div className="form-group">
						<select className="form-control"
							onChange={this.handleOnChange}
							value={this.props.created_by} 
							name="created_by" >
							<option value="">Created By</option>
							{
								this.props.creaters.map((created_by,index)=>{
									<option key={index} value={created_by.id}>{created_by.full_name}</option>
								})
							}
						</select>  
					</div>
				</div>
				<div className="col-xl-8 col-lg-12 col-md-12 col-xs-12">
					<button type="button" onClick={this.applyFilter} className="btn apply-btn s-bold greenbtn mr-0">Apply</button>
					<button type="button" onClick={this.resetFilter} className="btn apply-btn s-bold graybtn">Reset</button>
				</div>
			</div>
			{/* <button type="button" onClick={ this.applyFilter } className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
			<button type="button" onClick={ this.resetFilter } className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button> */}
		</div>
  }
		
}

function mapStateToProps(state) {
	const all_value = state.Invoices || {};
	const invoice_type = all_value.invoice_type || 0;
	const invoice_number = all_value.invoice_number || '';
	const customer_name = all_value.customer_name || '';
	const from_amount = all_value.from_amount || '';
	const to_amount = all_value.to_amount || '';
	const payment_status = all_value.payment_status || '';
	const created_by = all_value.created_by || '';
	const is_star = all_value.is_star || '';
	
	return {
		is_star,invoice_type,invoice_number,customer_name,from_amount,to_amount,
		payment_status,created_by
	};
}

export default connect(mapStateToProps)(InvoiceFilter);