import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { setEntityListData, setSelectedEntities, setmoduleList, setDefaultRoles, setPermissionIds } from "../../../Redux/Actions/Users";
import ModuleComponent from './ModuleComponent';

class AssignPermission extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      account_modules: this.props.account_modules,
    }
  }


  componentDidMount() {
  }

  handlePermissionChange = (checked, perm_index, module_index) => {
    let account_modules = this.props.account_modules;
    let module = account_modules[module_index - 1001];

    let module_permission = module.permissions[perm_index];
    module_permission.checked = checked;
    module.permissions[perm_index] = module_permission;
    account_modules[module_index - 1001] = module;


    this.props.setAccountModules(account_modules);

  }

  render() {
    return (
      <React.Fragment>
        <div className="pmgt-user-cards-checkbox">
          {
            this.props.account_modules.map((module, module_indexed) => {
              return <ModuleComponent
                key={"account_" + module_indexed + 1001}
                module_nmae="accountpermision"
                module={module}
                module_index={module_indexed + 1001}
                handlePermissionChange={this.handlePermissionChange} />
            })
          }
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.User || {};
  const available_entities = all_value.available_entities || [];
  const module_list = all_value.entity_module || [];
  const default_roles = all_value.default_roles || [];

  return {
    available_entities, module_list, default_roles,
  };
}

export default connect(mapStateToProps)(withRouter(AssignPermission));