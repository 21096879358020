import { combineReducers } from "redux";
import auth from "./Auth";
import message from "./Message";
import cheque from './Cheque'
import TaskManager from './TaskManager';
import Invoices from './Invoices';
import Snapshot from './Snapshot';
import Banking from './Banking';
import MoneyLink from './MoneyLink';
import App from './App';
import User from './Users';
import Session from './Session';
import FinancialInstitute from './FinancialInstitute';
import Notification from './Notification';

export default combineReducers({
  auth,
  message,
  cheque,
  TaskManager,
  Invoices,
  Snapshot,
  Banking,
  MoneyLink,
  App,
  User,
  Session,
  FinancialInstitute,
  Notification
});