import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function FinishedStatus(props) {
    return <div  >
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Done</Tooltip>}>
            <span className="icon-td icon-tasks-icon ft-sz-38 task-done"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>

            {/* <span className="icon-td icon-Group-3182 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
        </OverlayTrigger>
    </div>
}
export default FinishedStatus;