import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import Table from 'react-bootstrap/Table';
import LicenseService from '../../../Services/Licenses/LicenseService';
import { toast } from 'react-toastify';
import { convertToCurrency } from '../../../Helpers/Currency';
import MessageModal from '../../../Components/BoilerPlate/MessageModal';
import RouteLeavingGuard from '../../../Utils/RouteLeavingGuard';

class UpgradeLicense extends React.Component { 

    constructor(props) {
        super(props)
        this.state = {
            account_license_id: parseInt(props.match.params.account_license_id),
            upgrade_list: [],
            current_license: this.props.location.state,
            order_data: null,
            validation_alert: false,
            upgrade_details: {},
            is_dirty: false,
            closed: true
        }
    }

    componentDidMount() {
        const upgradeData = JSON.parse(localStorage.getItem('upgrade_order_data'));
        
        if (localStorage.getItem('upgrade_order_data')) {
            
            this.setState({
                account_license_id: upgradeData.account_license_id,
                upgrade_list: upgradeData.upgrade_list,
                current_license: upgradeData.current_license,
                order_data: upgradeData.order_data,
                validation_alert: upgradeData.validation_alert,
                upgrade_details: upgradeData.upgrade_details
            })

        } else {

            if(this.props.location.state === undefined) {
                this.props.history.push("/licenses");
            }

            const orderDate = new Date(this.state.current_license.order_date);
            let d = orderDate.getDate();
            let m = orderDate.toLocaleString('default', { month: 'short' })
            let y = orderDate.getFullYear().toString().substr(-2);

            const orderValidDate = new Date(this.state.current_license.valid_till);
            let validTillD = orderValidDate.getDate();
            let validTillM = orderValidDate.toLocaleString('default', { month: 'short' })
            let validTillY = orderValidDate.getFullYear().toString().substr(-2)

            this.setState(prevState => {
                let upgrade_details = Object.assign({}, prevState.upgrade_details);  
                upgrade_details.order_date = this.state.current_license.order_date; 
                upgrade_details.valid_till = this.state.current_license.valid_till; 
                // upgrade_details.amount =  this.state.current_license.licence_price_discounted ? detail.licence_price_discounted : detail.licence_price;  
                upgrade_details.order_id = this.state.current_license.order_id;
                return { upgrade_details };                                 
            })

            let proratedMonth = this.getMonthDiff(new Date(this.state.current_license.order_date), new Date(this.state.current_license.valid_till))

            this.setState(prevState => {
                let current_license = Object.assign({}, prevState.current_license);  
                current_license.order_date = (d <= 9 ? '0' + d : d) + '-' + m + '-' + y; 
                current_license.valid_till = (validTillD <= 9 ? '0' + validTillD : validTillD) + '-' + validTillM + '-' + validTillY;       
                current_license.prorated_months = proratedMonth;                 
                return { current_license };                                 
            })
        
            this.getUpgradeLicenseList();
        }
    }

    getUpgradeLicenseList = () => {
        this.props.dispatch(setAppilcationLoder(true));

        let data = {account_license_id: this.state.account_license_id}

        LicenseService.getUpgradeLicenseList(data)
        .then(data => {
            if (data.response_code === 200) {
                data.result.forEach(function (license) {
                    license.checked = false;
                });
                console.log(data.result)
                this.setState({upgrade_list: data.result})
            } else {
                toast.error(data.message);
            }
            this.props.dispatch(setAppilcationLoder(false));
        })
    }

    getMonthDiff = (d1, d2) => {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    onSelect = (e) => {
        const target = e.target;
        const name = target.name;

        let index = name.replace('select-','');
        var selectedLicense = null;
        this.state.upgrade_list.forEach(function (license, index1) {
            license.checked = false;
            if(index == index1){
                license.checked = true;
                selectedLicense = license;
            }
        });

        this.setState({order_data: selectedLicense, is_dirty: true})

        this.setState(prevState => {
            let upgrade_details = Object.assign({}, prevState.upgrade_details);  
            upgrade_details.amount =  selectedLicense.upgrade_price; 
            upgrade_details.assigned_to = this.state.current_license.assigned_to;
            upgrade_details.bill_to = this.state.current_license.billed_to;
            return { upgrade_details };                                 
        })
        // this.setState({selected_license_id: selectedLicenseId})

        // console.log(this.state.selected_license_id)
    }

    goToOrderSummaryPage = () => {

        if(this.state.order_data == null) {
            this.toggleMessageModal();
        } else {
            this.setState({is_dirty: false}, function() {
                localStorage.setItem('upgrade_order_data', JSON.stringify(this.state));
                this.props.history.push({
                    pathname: '/licenses/order-summary',
                    state: {order_data: this.state.order_data, type: "upgrade", account_license_id: this.state.account_license_id, upgrade_details: this.state.upgrade_details} 
                }) 
            })
        }         
    }

    toggleMessageModal = () => {
        this.setState({validation_alert : !this.state.validation_alert})
    }

    goToLicenseListingPage = () => {
        this.setState({is_dirty: false}, function() {
            localStorage.removeItem("order_data");
            localStorage.removeItem("upgrade_order_data")
            localStorage.removeItem("renew_order_data")
            localStorage.removeItem("bill_to_data")
            
            this.props.history.push("/licenses");
        })
    }
    
    closeAction = () => {
		this.setState({closed: true})
	}

    render() {
        return ( 
            <div className="content-wrapper">
                <RouteLeavingGuard
					when={this.state.is_dirty}
					closed={this.state.closed}
                    closeAction={this.closeAction}
				/>
                <div className="container-fluid">
                    <div className="row bg-w bg-rounded">
                        <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={'/home'}>Home</Link>
                                </li>
                                <li onClick={this.goToProfileManagement} style={{cursor: "pointer"}} className="breadcrumb-item">Profile Management</li>
                                <li onClick={this.goToLicenseListingPage} style={{cursor: "pointer"}} className="breadcrumb-item">My Licenses</li>
                                <li className="breadcrumb-item">Upgrade License</li>
                            </ol>
                            <div className="clearfix main-title-btn-sec">
                                <div className="head-title pull-left">
                                    <h2 className="s-sbold blu-color m-0">Upgrade License</h2>
                                </div>
                                <div className="col-xl-6 pull-right licenses-progressbar">
                                    <ul id="progressbar">
                                        <li className="active">Select License for Upgrade<span className="dot"></span>
                                            <span className="dot-full">
                                                <span>&#10003;</span>
                                            </span>
                                        </li>
                                        <li>Order Summary</li>
                                        <li>Make Payment</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="main-evidence-sms-sec">
                                <MessageModal showModal={this.state.validation_alert} content={"Please select License to upgrade"} toggleMessageModal={this.toggleMessageModal} />
                                <div className="main-evidence-sms-table-sec">
                                    <div className="upgrade-license-sec ">
                                        <div className="main-buy-license-table main-upgrade-license-sec current-lice-sec mt-4">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="w-25">Current License</th>
                                                        <th className="text-center w-25">Rate/ Annum*</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <p className="head-title ft-sz-30 s-sbold blu-color">{this.state.current_license.license_type}</p>
                                                            <ul className="list-inline">
                                                                <li className="list-inline-item ft-sz-18 s-reg font-colr">Evidences, Link evidences, exports</li>
                                                            </ul>
                                                        </td>
                                                        <td className="text-center">
                                                            <p className="rate-annum-sec ft-sz-30 s-sbold bg-g white-color m-0">₹{convertToCurrency(this.state.current_license.amount)}</p>
                                                        </td>
                                                        <td>
                                                            <div className="validity-sec"> 
                                                                <p className="ft-sz-18 s-reg blu-color mb-2">Validity</p> 
                                                                <p className="ft-sz-24 s-sbold blu-color m-0 d-inline">{this.state.current_license.order_date} to {this.state.current_license.valid_till}</p> <span className="remain-sec ft-sz-16 s-reg font-colr">{this.state.current_license.prorated_months} months remaining</span>
                                                            </div>
                                                            <div className="clearfix row m-0">
                                                                <div className="col-md-6 pl-0">
                                                                    <p className="ft-sz-18 s-reg blu-color mb-2">Assigned To</p>
                                                                    <p className="ft-sz-24 s-sbold blu-color m-0 d-inline">{this.state.current_license.assigned_to ? this.state.current_license.assigned_to: '-'} </p>
                                                                </div>
                                                                <div className="col-md-6 pl-0">
                                                                    <p className="ft-sz-18 s-reg blu-color mb-2">Billed To</p>
                                                                    <p className="ft-sz-24 s-sbold blu-color m-0 d-inline">{this.state.current_license.billed_to}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>

                                        <div className="main-buy-license-table main-upgrade-license-sec upgrade-lice-sec mt-4">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="w-25">Upgrade To</th>
                                                        <th className="text-center w-25"></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.state.upgrade_list.map((detail, ind) => {

                                                        let isChecked = (this.state.upgrade_list[ind].checked  == true ) ? 1 : 0
                                                        
                                                    return <tr key={ind}>
                                                        <td className="mt-3">
                                                            <div className="check-box"> 
                                                                <input className="styled-checkbox" id={`select-${ind}`} type="checkbox" checked={isChecked} name={`select-${ind}`} onChange={e => this.onSelect(e)}  />

                                                                <label for={`select-${ind}`} className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                                                            </div>
                                                            <div className="list-sec">
                                                            {detail.checked}
                                                                <p className="head-title ft-sz-30 s-sbold blu-color">{detail.licence_name}</p>
                                                                <ul className="list-inline mb-0">
                                                                { detail.features.map((feature, index1) => {
                                                                    return <li key={index1} className="list-inline-item ft-sz-18 s-reg font-colr">{feature.quantity} {feature.feature_name}</li>
                                                                })}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <p className="rate-annum-sec ft-sz-30 s-sbold bg-g white-color m-0">₹{convertToCurrency(detail.upgrade_price)}</p>
                                                        </td>
                                                        <td>
                                                            <p className="ft-sz-18 s-reg blu-color">{detail.licence_price_discounted ? convertToCurrency(detail.licence_price_discounted) : convertToCurrency(detail.licence_price)} Rs/Annum - Prorated for {this.state.current_license.prorated_months} months</p>
                                                        </td>
                                                    </tr>
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="clearfix">
                                            <p className="pull-right ft-sz-18 s-reg font-colr pgst">* Prices inclusive of GST</p>
                                        </div>
                                    </div> 
                                    <div className="profile-save-btn clearfix">
                                        <button type="button" className="btn save-btn s-bold greenbtn pull-right" onClick={this.goToOrderSummaryPage}>Next</button>
                                        <button onClick={this.goToLicenseListingPage} type="button" className="btn save-btn s-bold pull-right graybtn mr-3">Cancel</button>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="scroll-to-top rounded" href="#page-top">
                        <span className="icon-right-arrow white-icon fs10"></span>
                    </a>
                </div>
            </div>                 
        )
    }
}


export default connect()(withRouter(UpgradeLicense));