import React from 'react'
import ChequeListView from './ChequeListView'
import CreateChequeView from './CreateChequeView'
import ChequeService from '../../Services/Cheques/ChequeService';
import { toast } from 'react-toastify'
import { setChequeCount, setChequeLoadingStatus, setPageNo, setTotalCount } from '../../Redux/Actions/Cheque'
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeDateFormate } from '../../Helpers/DateFormat';

class Cheques extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cheque_list: [],
            filter_by: 1,
            start_date: "",
            end_date: "",
            page_no: 1,
            filter_card: 0,
            search: "",
            sort_order: 2,
            sort_by: 2,
            has_next: false,
            has_previous: false
        }
    }
    
    componentDidMount() {
        this.getChequeList(0)
        this.getChequeCount()
    }

    getChequeList = (filter_by = this.state.filter_by) => {
        this.setState({
            filter_by: filter_by,
            cheque_list: []
        })
        this.props.dispatch(setChequeLoadingStatus(true));
        let filter_data = {
            "per_page_items": this.props.per_page_items,
            "page_no": this.state.page_no,
            "date_filter": {
                "start_date": changeDateFormate(this.state.start_date),
                "end_date": changeDateFormate(this.state.end_date)
            },
            "filter_card": this.state.filter_card,
            "filter": filter_by,
            "search": this.state.search,
            "filter_box": {
                "from_date": changeDateFormate(this.props.filter_box.from_date),
                "to_date": changeDateFormate(this.props.filter_box.to_date),
                "is_star": this.props.filter_box.is_star,
                "uploaded_by": this.props.filter_box.uploaded_by,
                "cheque_no": this.props.filter_box.cheque_no,
                "bank_name": this.props.filter_box.bank_name,
                "cheque_identifier": this.props.filter_box.cheque_identifier,
                "payer": this.props.filter_box.payer,
                "payee": this.props.filter_box.payee,
                "from_amount": this.props.filter_box.from_amount,
                "to_amount": this.props.filter_box.to_amount
            },
            "sort_by": this.state.sort_by,
            "sort_order": this.state.sort_order
        };
        ChequeService.getChequeList(filter_data).then(data => {
            this.props.dispatch(setChequeLoadingStatus(false));
            if (data.response_code == 200) {
                this.setState({
                    cheque_list: data.result.cheques,
                    page_no: data.result.page_no,
                    total_count: data.result.total_count,
                    total_pages: data.result.total_pages,
                    has_next: data.result.has_next,
                    has_previous: data.result.has_previous
                });
                this.props.dispatch(setTotalCount(data.result.total_count))
                this.props.dispatch(setPageNo(data.result.page_no))

            } else {
                toast.error(data.message);
            }
        })
    }

    applyFilter = (type) => {
        this.setState({
            page_no: 1
        }, () => {
            type == "apply" ? this.getChequeList(2) : this.getChequeList(0);
        })
    }

    handleSearch = (value) => {
        // alert
        this.setState({ search: value }, () => {
            this.getChequeList(1)
        })
    }

    getChequeCount = () => {
        let filter_data = {
            "start_date": changeDateFormate(this.state.start_date),
            "end_date": changeDateFormate(this.state.end_date)
        };
        this.props.dispatch(setChequeLoadingStatus(true));
        ChequeService.getChequeCount(filter_data).then(
            (data) => {
                this.props.dispatch(setChequeLoadingStatus(false));
                if (data.response_code == 200) {
                    this.props.dispatch(
                        setChequeCount(data.result.cheque_count)
                    );

                } else {
                    toast.error(data.message);
                }
            });
    }


    refreshItem = () => {
        this.getChequeList();
        this.getChequeCount();
    }

    financialYearChanged = (year_type, start_date, end_date) => {
        this.setState({
            year_type,
            start_date,
            end_date,
            page_no: 1
        }, () => {
            this.getChequeList();
            this.getChequeCount();
        })
    }

    onCardSelected = (task_card_filter) => {
        this.setState({
            filter_card: task_card_filter,
            page_no: 1
        }, () => this.getChequeList())
    }


    onSortClick = (sort_order, sort_by) => {
        this.setState({
            sort_order,
            sort_by
        }, () => this.getChequeList())
    }

    changeTaskStatus = (selected_rows, status) => {
        let cheque_list = this.state.cheque_list.map((cheque) => {
            if (selected_rows.indexOf(cheque.id) != -1) {
                cheque.status = status;
            }
            return cheque;
        });
        this.setState({
            cheque_list
        });
    }

    makeRemoveStar = () => {
        let cheque_list = this.state.cheque_list;
        let index = cheque_list.findIndex(cheque => cheque.id === this.props.cheque_id);
        let cheque = cheque_list[index];
        cheque.is_star = this.props.changed_star;
        cheque_list[index] = cheque;
        this.setState({
            cheque_list: cheque_list
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.page_no != this.props.page_no) {
            this.setState({
                page_no: this.props.page_no
            }, () => this.getChequeList())
        }
        if (prevProps.cheque_id != this.props.cheque_id || prevProps.changed_star != this.props.changed_star) {
            this.makeRemoveStar();
        }
    }

    newCheque = (cheque, is_update) => {
        if (is_update) {
            let cheque_list = this.state.cheque_list;
            let index = cheque_list.findIndex(item => item.id == cheque.id);
            cheque_list[index] = cheque;
            this.setState({ cheque_list })
        } else
            this.setState({
                cheque_list: [cheque, ...this.state.cheque_list]
            })
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/cheque/create/:cheque_id?"
                        render={() => {
                            return <CreateChequeView
                                cheque_list={this.state.cheque_list}
                                applyFilter={this.applyFilter}
                                newCheque={this.newCheque}
                            // has_next={this.state.has_next}
                            // has_previous={this.state.has_previous}
                            // per_page_items={this.props.per_page_items}
                            //     page_no={this.state.page_no}
                            />
                        }
                        }
                        exact />
                    <Route path="/cheque"
                        render={() => {
                            return <ChequeListView
                                cheque_list={this.state.cheque_list}
                                onSortClick={this.onSortClick}
                                sort_by={this.state.sort_by}
                                sort_order={this.state.sort_order}
                                getChequeList={this.getChequeList}
                                makeRemoveStar={this.makeRemoveStar}
                                financialYearChanged={this.financialYearChanged}
                                onCardSelected={this.onCardSelected}
                                applyFilter={this.applyFilter}
                                handleSearch={this.handleSearch}
                            />
                        }
                        }
                        exact />
                </Switch>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const all_value = state.cheque
    const filter_box = all_value.filter_box;
    const total_count = all_value.total_count;
    const page_no = all_value.page_no;
    const per_page_items = all_value.per_page_items;
    const cheque_id = all_value.cheque_id;
    const changed_star = all_value.changed_star;
    return {
        filter_box, total_count, page_no, per_page_items, cheque_id, changed_star
    };
}


export default connect(mapStateToProps)(Cheques);