import React from 'react'
import { withRouter } from 'react-router-dom';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
class ShowWidgetsResetTransaction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter_card: (!!!this.props.type) ? this.props.filter_card : 3

		}
		this.LEDGER_ENTRIES_CODE = this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE);
	}

	componentDidMount() {
		this.props.onRef(this);
	}
	onCardSelected = async (snapshot_card_filter) => {
		if (!!this.props.type && (snapshot_card_filter == 0 || snapshot_card_filter == 3 || snapshot_card_filter == 4)) {
			return true;
		}
		if (snapshot_card_filter != this.state.filter_card) {
			let redirect = false;
			if (!!this.props.checkBulkLedgerFlag) {
				if (await this.props.checkBulkLedgerFlag()) {
					return true;
				}
            }
			this.setState({ filter_card: snapshot_card_filter },
				() => {
					this.props.onCardSelected(this.state.filter_card)
					if (redirect) {
						// this.props.history.push({ pathname: `/money-link/` });
					}
				})
		}
	}
	render() {
		return (
			<>
				<div className="widgets_moneyin">
					<div className="row widget-filter-new sec-mb">
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={(!!!this.props.type) ? () => this.onCardSelected(0) : () => { }}>
							<div className={"widget-block-new" + (this.state.filter_card == 0 ? " active-widget " : "") + (!!this.props.type ? " disable-new-widgets " : " ")}>
								<p className="wtitle-new">Total</p>
								<p className="wcounter-new">{this.props.transaction_count.total || 0}</p>
							</div>
						</div>
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={(!!!this.props.type) ? () => this.onCardSelected(1) : () => { }}>
							<div className={"widget-block-new notready_usrin" + (this.state.filter_card == 1 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")}>
								<p className="wtitle-new">Draft</p>
								<p className="wcounter-new">{this.props.transaction_count.draft || 0}</p>
							</div>
						</div>
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6" onClick={(!!!this.props.type) ? () => this.onCardSelected(2) : () => { }}>
							<div className={"widget-block-new ready_usrin" + (this.state.filter_card == 2 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")}>
								<p className="wtitle-new">Ready</p>
								<p className="wcounter-new">{this.props.transaction_count.ready || 0}</p>
							</div>
						</div>

						{/* Pending | Accounted */}
						{this.LEDGER_ENTRIES_CODE &&
							<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 count-box-new-widget">
								<div className={"widget-block-new pending_usrin" + (this.state.filter_card == 3 || this.state.filter_card == 4 ? " active-widget" : "")} onClick={(e) => { e.preventDefault(); this.onCardSelected(this.props.default_cards.accounting_card) }}>
									<div className="count-box-new-action">
										{/* <span className="icon-menu1 iconwhite widget-icon"></span> */}
										<span class="icon-menu-lines ifsize"></span>
										<div className="widget-dropdown-menu">
											<button onClick={(e) => { e.stopPropagation(); this.onCardSelected(3) }}><span>Pending</span> <span className="widgets-count">{this.props.transaction_count.pending || 0}</span></button>
											<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(4) : () => { } }}
												className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Accounted</span> <span className="widgets-count">{this.props.transaction_count.accounted || 0}</span></button>
										</div>
									</div>
									{
										this.props.default_cards.accounting_card == 3 &&
										<>
											<p className="wtitle-new">Pending</p>
											<div className="wcounter-new">{this.props.transaction_count.pending || 0}</div>
										</>
									}
									{
										this.props.default_cards.accounting_card == 4 &&
										<>
											<p className="wtitle-new">Accounted</p>
											<div className="wcounter-new">{this.props.transaction_count.accounted || 0}</div>
										</>
									}
								</div>
							</div>
						}
						{/* End Pending | Accounted */}

						{
							this.LEDGER_ENTRIES_CODE && <div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 " onClick={(!!!this.props.type) ? () => this.onCardSelected(5) : () => { }}>
								<div className={"widget-block-new synched_usrin" + (this.state.filter_card == 5 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")}>
									<p className="wtitle-new">Synced</p>
									<p className="wcounter-new">{this.props.transaction_count.synced || 0}</p>
								</div>
							</div>
						}

						{/* Linked | Not Linked */}
						<div className="col-xl col-lg-3 col-md-3 col-sm-3 col-6 count-box-new-widget">
							<div className={"widget-block-new linked_usrin" + (this.state.filter_card == 7 || this.state.filter_card == 8 ? " active-widget" : "") + (!!this.props.type ? " disable-new-widgets " : "")} onClick={(!!!this.props.type) ? () => this.onCardSelected(this.props.default_cards.linking_card) : () => { }}>
								<div className="count-box-new-action">
									<span class="icon-menu-lines ifsize"></span>
									<div className="widget-dropdown-menu">
										<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(7) : () => { } }} className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Linked</span> <span className="widgets-count">{this.props.transaction_count.linked || 0}</span></button>
										<button onClick={(e) => { e.stopPropagation(); (!!!this.props.type) ? this.onCardSelected(8) : () => { } }} className={(!!!this.props.type) ? "" : "disable-new-widgets"}><span>Not Linked</span> <span className="widgets-count">{this.props.transaction_count.not_linked || 0}</span></button>
									</div>
								</div>
								{
									this.props.default_cards.linking_card == 7 &&
									<>
										<p className="wtitle-new">Linked</p>
										<div className="wcounter-new">{this.props.transaction_count.linked || 0}</div>
									</>
								}
								{
									this.props.default_cards.linking_card == 8 &&
									<>
										<p className="wtitle-new">Not Linked</p>
										<div className="wcounter-new">{this.props.transaction_count.not_linked || 0}</div>
									</>
								}
							</div>
						</div>
						{/* End Linked | Not Linked */}
					</div>
				</div>
			</>
		)
	}
}

export default withRouter(ShowWidgetsResetTransaction);