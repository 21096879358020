import React from 'react'
import { Accordion, Card, Form, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import EvidenceCardView from '../../Components/BoilerPlate/EvidenceCardView';
import { LINKING_CODE } from '../../Helpers/Constants';
import { setEvidenceListData, setLinkedEvidenceList, setTempLinkedEvidenceList, setSuggestedEvidenceListData, setSuggestedEvidenceIds } from '../../Redux/Actions/MoneyLink';

class LinkedEvidences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  handleDiscardEvidence = (evidence_id) => {
    if (this.props.features_permissions_list.includes(LINKING_CODE) && !this.props.read_only) {

      let evidence_list = [...this.props.evidence_list];
      let suggested_evidence_list = [...this.props.suggested_evidence_list];
      let suggested_evidence_ids = [...this.props.suggested_evidence_ids];
      let temp_linked_evidences = [...this.props.temp_linked_evidences];
      let index = temp_linked_evidences.findIndex(evidence => (evidence.id || evidence.mod_id) == evidence_id);
      let evidence = temp_linked_evidences[index];

      let backup_evidences = _.cloneDeep(this.props.backup_evidences);
      console.log(backup_evidences)
      let backup_index = backup_evidences.findIndex(evidence => (evidence.id || evidence.mod_id) == evidence_id);

      if (backup_index != -1) {
        evidence = backup_evidences[backup_index]
      } else {
        evidence.linked_amount = parseFloat((evidence.linked_amount - evidence.transaction_linked_amount).toFixed(2));
        evidence.pending_amount = parseFloat((evidence.pending_amount + evidence.transaction_linked_amount).toFixed(2));
        evidence.transaction_linked_amount = 0;
      }

      console.log(evidence)

      evidence_list.push(evidence)
      let suggested_index = suggested_evidence_ids.findIndex(id => id == evidence_id);

      if (suggested_index !== -1) {
        suggested_evidence_list.push(evidence)
        this.props.dispatch(setSuggestedEvidenceListData(suggested_evidence_list));
      }
      temp_linked_evidences.splice(index, 1);
      let list_data = {
        "evidences": evidence_list
      }
      this.props.isFormDirty()


      console.log(backup_evidences)
      //calculate linked and pending amount according to sort by date
      let sum_of_all_evidences = 0;
      let transaction_amount = this.props.transaction_amount;
      let transaction_pending_amt = 0;


      let sorted_linked_evidences = temp_linked_evidences.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });


      let evidence_linked_data = [];

      console.log(sorted_linked_evidences)

      sorted_linked_evidences = sorted_linked_evidences.map(function (evidence) {
        if (evidence.transaction_linked_amount == undefined) {
          evidence.transaction_linked_amount = 0;
        }
        console.log('relocate evidence.transaction_linked_amount ===' + evidence.transaction_linked_amount)
        console.log('relocate evidence.pending_amount ===' + evidence.pending_amount)
        console.log('relocate evidence.linked_amount ===' + evidence.linked_amount)

        if (evidence.transaction_linked_amount != 0) {

          evidence.linked_amount = parseFloat((evidence.linked_amount - evidence.transaction_linked_amount).toFixed(2));
          evidence.pending_amount = parseFloat((evidence.pending_amount + evidence.transaction_linked_amount).toFixed(2));
          evidence.transaction_linked_amount = 0;
          // evidence.linked_amount = evidence.linked_amount - evidence.transaction_linked_amount;
          // evidence.pending_amount = evidence.pending_amount + evidence.transaction_linked_amount;
        } else {


          let index = backup_evidences.findIndex(evi => evi.id == evidence.id || evidence.mod_id);
          //console.log(index);
          //console.log()
          if (index != -1) {
            console.log(backup_evidences[index])
            evidence = backup_evidences[index]
            evidence.transaction_linked_amount = 0;
          }
        }

        console.log('after relocate evidence.linked_amount ===' + evidence.linked_amount)
        console.log('after relocate evidence.pending_amount ===' + evidence.pending_amount)
        return evidence
      })
      console.log(sorted_linked_evidences);
      sorted_linked_evidences.map(function (evidence) {
        sum_of_all_evidences = 0;
        evidence_linked_data.map(function (data) {
          //console.log(data.linked_amount)
          sum_of_all_evidences = parseFloat(sum_of_all_evidences + data.transaction_linked_amount);
        })


        transaction_pending_amt = transaction_amount - sum_of_all_evidences;

        if (transaction_pending_amt < 0) {
          transaction_pending_amt = 0
        }

        console.log('sum_of_all_evidences ===' + sum_of_all_evidences)
        console.log('transaction_pending_amt ===' + transaction_pending_amt)
        console.log('evidence.pending_amount ===' + evidence.pending_amount)

        evidence.transaction_linked_amount = 0;
        if (transaction_pending_amt == 0) {
          evidence.transaction_linked_amount = 0;
        }
        //case 1
        else if (evidence.pending_amount > transaction_pending_amt) {
          evidence.transaction_linked_amount = transaction_pending_amt;
          evidence.linked_amount = evidence.linked_amount + transaction_pending_amt;
          evidence.pending_amount = evidence.pending_amount - transaction_pending_amt;

        }
        //case 2
        else if (evidence.pending_amount < transaction_pending_amt) {
          evidence.transaction_linked_amount = evidence.pending_amount;
          evidence.linked_amount = evidence.linked_amount + evidence.pending_amount;
          evidence.pending_amount = 0;
        }
        //case 3
        else if (evidence.pending_amount = transaction_pending_amt) {
          evidence.transaction_linked_amount = transaction_pending_amt;
          evidence.linked_amount = evidence.linked_amount + transaction_pending_amt
          evidence.pending_amount = 0;
        }

        console.log('evidence.transaction_linked_amount ===' + evidence.transaction_linked_amount)
        console.log('evidence.pending_amount ===' + evidence.pending_amount)

        evidence_linked_data.push(evidence)
      })

      this.props.dispatch(setEvidenceListData(list_data));

      this.props.dispatch(setTempLinkedEvidenceList(evidence_linked_data));
    }
  }


  onDrop = (ev, cat) => {
    let backup_evidences = _.cloneDeep(this.props.backup_evidences);
    let evidence_id = ev.dataTransfer.getData("linked-id");
    console.log(this.props.temp_linked_evidences)
    //return;
    if (evidence_id) {
      let evidence_list = [...this.props.evidence_list];
      //console.log(evidence_list)
      let suggested_evidence_list = [...this.props.suggested_evidence_list];
      let temp_linked_evidences = [...this.props.temp_linked_evidences];
      let already_pushed = false;

      if (evidence_list.length != 0) {
        let index = evidence_list.findIndex(evidence => evidence.id == evidence_id || evidence.mod_id == evidence_id),
          evidence = evidence_list[index];
        temp_linked_evidences.push(evidence)
        already_pushed = true;
        evidence_list.splice(index, 1);

        let list_data = {
          "evidences": evidence_list
        }

        this.props.dispatch(setEvidenceListData(list_data));
      }

      if (suggested_evidence_list.length != 0) {
        let index = suggested_evidence_list.findIndex(evidence => evidence.id == evidence_id || evidence.mod_id == evidence_id),
          evidence = suggested_evidence_list[index];
        if (!already_pushed) {
          temp_linked_evidences.push(evidence)
        }
        suggested_evidence_list.splice(index, 1);

        this.props.dispatch(setSuggestedEvidenceListData(suggested_evidence_list));
      }

      //calculate linked and pending amount according to sort by date
      let sum_of_all_evidences = 0;
      let transaction_amount = this.props.transaction_amount;
      let transaction_pending_amt = 0;

      let sorted_linked_evidences = temp_linked_evidences.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });


      let evidence_linked_data = [];

      //console.log(sorted_linked_evidences)

      sorted_linked_evidences = sorted_linked_evidences.map(function (evidence) {
        if (evidence.transaction_linked_amount == undefined) {
          evidence.transaction_linked_amount = 0;
        }
        console.log('relocate evidence.transaction_linked_amount ===' + evidence.transaction_linked_amount)
        console.log('relocate evidence.pending_amount ===' + evidence.pending_amount)
        //console.log('relocate evidence.linked_amount ==='+evidence.linked_amount)

        if (evidence.transaction_linked_amount != 0) {

          evidence.linked_amount = parseFloat((evidence.linked_amount - evidence.transaction_linked_amount).toFixed(2));
          evidence.pending_amount = parseFloat((evidence.pending_amount + evidence.transaction_linked_amount).toFixed(2));
          evidence.transaction_linked_amount = 0;
          console.log(evidence)
        } else {


          let index = backup_evidences.findIndex(evi => evi.id == evidence.id || evidence.mod_id);
          //console.log(index)
          if (index != -1) {
            console.log(backup_evidences[index])
            evidence = backup_evidences[index]
            evidence.transaction_linked_amount = 0;
          }
        }

        console.log('after relocate evidence.linked_amount ===' + evidence.transaction_linked_amount)
        console.log('after relocate evidence.pending_amount ===' + evidence.pending_amount)
        console.log(evidence)
        return evidence
      })
      //console.log(sorted_linked_evidences);
      sorted_linked_evidences.map(function (evidence) {
        sum_of_all_evidences = 0;
        evidence_linked_data.map(function (data) {
          //console.log(data.linked_amount)
          sum_of_all_evidences = parseFloat(sum_of_all_evidences + data.transaction_linked_amount); // transaction_linked_amount
        })

        //let newly_added_evidence_linked_amt = 0;
        //let pending_amt = 0;
        transaction_pending_amt = transaction_amount - sum_of_all_evidences;

        if (transaction_pending_amt < 0) {
          transaction_pending_amt = 0
        }

        // console.log('sum_of_all_evidences ==='+sum_of_all_evidences)
        // console.log('transaction_pending_amt ==='+transaction_pending_amt)
        // console.log('evidence.pending_amount ==='+evidence.pending_amount)

        evidence.transaction_linked_amount = 0;
        if (transaction_pending_amt == 0) {
          evidence.transaction_linked_amount = 0;
        }
        //case 1
        else if (evidence.pending_amount > transaction_pending_amt) {
          evidence.transaction_linked_amount = transaction_pending_amt;
          evidence.linked_amount = evidence.linked_amount + transaction_pending_amt;
          evidence.pending_amount = evidence.pending_amount - transaction_pending_amt;
          //transaction_pending_amt = 0;
        }
        //case 2
        else if (evidence.pending_amount < transaction_pending_amt) {
          evidence.transaction_linked_amount = evidence.pending_amount;
          //transaction_pending_amt = transaction_pending_amt - evidence.pending_amount;
          evidence.linked_amount = evidence.linked_amount + evidence.pending_amount;
          evidence.pending_amount = 0;
        }
        //case 3
        else if (evidence.pending_amount = transaction_pending_amt) {
          evidence.transaction_linked_amount = transaction_pending_amt;
          evidence.linked_amount = evidence.linked_amount + transaction_pending_amt
          evidence.pending_amount = 0;
        }

        // console.log('evidence.transaction_linked_amount ==='+evidence.transaction_linked_amount)
        // console.log('evidence.pending_amount ==='+evidence.pending_amount)

        evidence_linked_data.push(evidence)
        console.log(evidence)
      })


      this.props.dispatch(setTempLinkedEvidenceList(evidence_linked_data));
      this.props.checkReadyStatus(evidence_linked_data)
      this.props.isFormDirty()

      //alert('fff')
      setTimeout(() => {
        if (evidence_linked_data.length == 1) {
          this.props.getMoneyLinkLedgerRecommendation()
        }
      }, 1500);
    }
    ev.preventDefault();
  }

  onDragOver = (ev) => {
    ev.preventDefault();
  }

  onDragStart = (ev, evidence_id, mod_id) => {
    let id;
    if (evidence_id)
      id = evidence_id;
    if (mod_id)
      id = mod_id;

    ev.dataTransfer.setData("id", id);
  }

  render() {
    // console.log(this.props.toggleNoSupportingDocument)
    return (
      <>
        {/* <div className="col-xl-4 evi-left-col"> */}
        <div className="form-sec-inner-new position-relative evi-left-col">
          {/* <div className="form-sec-inner-new position-relative"> */}

          {this.props.no_supporting_document == 1 && <div className="no-evi-popup"></div>}

          <div className="evi-card-sec-new">
            <h4 className="link-ev-h">Linked Evidences</h4>
            <div className="evi-cards-list" onDragOver={(e) => this.onDragOver(e)} onDrop={(e) => this.onDrop(e, "complete")}>
              <div className="row no-gutters ec-list-row">
                {this.props.temp_linked_evidences.map((evidence, index) => {
                  let id
                  if (evidence.id)
                    id = evidence.id
                  if (evidence.mod_id)
                    id = evidence.mod_id
                  return <div key={index} className="col-xl-12 col-lg-4 ec-list-col " draggable onDragStart={(e) => this.onDragStart(e, evidence.id, evidence.mod_id)}>
                    {/* <div className="col-xl-12 col-lg-4"> */}
                    <EvidenceCardView
                      is_linked_evidence={true}
                      id={id}
                      module={evidence.module}
                      evidence_type={evidence.evidence_type}
                      date={evidence.date}
                      gst_status={evidence.gst_status}
                      tds_status={evidence.tds_status}
                      vendor={evidence.vendor}
                      total={evidence.total}
                      status={evidence.status}
                      link_history={evidence.link_history}
                      pending_amount={evidence.pending_amount}
                      report_url={evidence.report_url || evidence.report}
                      handleSelectEvidence={this.handleDiscardEvidence}
                      editEvidence={this.props.editEvidence}
                      viewEvidence={this.props.viewEvidence}
                      showLinkHistory={this.props.showLinkHistory}
                      read_only={this.props.read_only}
                      features_permissions_list={this.props.features_permissions_list}
                      score={evidence.score}
                      is_new_evidence={!!evidence.id}
                    />
                    {/* </div> */}
                  </div>
                })}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const linked_evidences = all_value.linked_evidences;
  const temp_linked_evidences = all_value.temp_linked_evidences || [];
  const evidence_list = all_value.evidences || []
  const suggested_evidence_list = all_value.suggested_evidence_list || []
  const suggested_evidence_ids = all_value.suggested_evidence_ids || []
  const no_supporting_document = all_value.no_supporting_document;
  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];

  return {
    linked_evidences, evidence_list, temp_linked_evidences, features_permissions_list, suggested_evidence_list, suggested_evidence_ids, no_supporting_document
  };
}

export default connect(mapStateToProps)(LinkedEvidences); 