import React from 'react'
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import StorageData from "../../Helpers/StorageData";
import { changeDateFormate } from '../../Helpers/DateFormat';

/* static ui elements */
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class ExportVouchers extends React.Component {
	constructor(props) {
		super(props);
		this.btnRef = React.createRef();

		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
		this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
		this.VIEW_LINKED_ENTRIES = this.PERMISSION_LIST.includes(VIEW_LINKED_ENTRIES_CODE);
		this.state = {
			per_page_items: 0,
			showFilter: false,
			showSearch: true,
			active_input: false,
			search: '',
			is_permission_granted: (this.props.features_permissions_list.includes(LINKING_CODE) ||
				this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ||
				this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)),
		}
	}


	componentDidMount() {

	}

	downloadVouchers = () => {
		let defaultYr = StorageData.getDefaultFinancialYear();

		let dateRange = {
			"date_filter": {
				start_date: changeDateFormate(defaultYr.start_date),
				end_date: changeDateFormate(defaultYr.end_date)
			},
			"module": this.props.module
		}

		this.props.dispatch(setAppilcationLoder(true));
		MoneyLinkService.downloadVouchers(dateRange).then((blob) => {
			// if(blob.response_code == 500){
			// 	toast.error(blob.message);
			// 	this.props.dispatch(setAppilcationLoder(false));
			// 	return;
			// }
			if (blob != 404) {
				var dataa = new Blob([blob], { type: 'text/xml' });
				let url = window.URL.createObjectURL(dataa);
				let a = document.createElement('a');
				a.href = url;
				a.download = "vouchers.xml";
				a.click();

				this.props.refreshItem()
			} else {
				toast.error("No vouchers found");
				this.props.refreshItem()
			}
			this.props.dispatch(setAppilcationLoder(false));
		});
	}

	render() {

		return (
            // <button onClick={() => this.downloadVouchers()} className="btn ft-sz-20 s-bold green-btn mr-3 export_snapin export_moneyin">Export Entries</button>

			<button className="mt-0" onClick={() => this.downloadVouchers()}>
				<span class="icon-Exports action-icon"><span class="path1"></span><span class="path2"></span></span>
				<span>Export Entries</span>
			</button> 
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const is_transaction_loading = all_value.is_transaction_loading;
	const filter_by = all_value.filter_by
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];

	return {
		is_transaction_loading, filter_by, features_permissions_list, all_value
	};
}

export default connect(mapStateToProps)(withRouter(ExportVouchers));
