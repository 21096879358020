import {
  SET_USER_DETAIL,
  SET_ENTITY_DETAIL,
  SET_DEFAULT_ENTITY,
  SET_ACCOUNT_PERMISSION,
  SET_ENTITY_PERMISSION,
  SET_MODULE_PERMISSION,
  SET_SYSTEM_PARAMETER,
  SET_ENTITY_CONFIG_PARAMETER
} from "../Actions/Types";

const initialState = {
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_DETAIL:
      return {
        ...state,
        user_detail: payload
      }
    case SET_ENTITY_DETAIL:
      return {
        ...state,
        entity_list: payload
      }
    case SET_DEFAULT_ENTITY:
    return {
        ...state,
        default_entity_detail: payload
    }
    case SET_ACCOUNT_PERMISSION:
    return {
        ...state,
        account_permissions: payload
    }
    case SET_ENTITY_PERMISSION:
    return {
        ...state,
        features_permissions_list: payload
    }
    case SET_MODULE_PERMISSION:
    return {
        ...state,
        module_permissions: payload
    }
    case SET_SYSTEM_PARAMETER:
    return {
        ...state,
        system_parameter: payload
    }
    case SET_ENTITY_CONFIG_PARAMETER:
    return {
        ...state,
        entity_config_param: payload
    }
      
    default:
      return state;
  }
}