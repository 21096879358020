function validateCreateInvoice(data)
 {
  let errors = {};

  if (!data.invoice_type) {
    errors.invoice_type = 'Invoice Type is required';
  }

  if(data.phone_number && data.phone_number.length != 10){
    errors.phone_number = 'Please provide valid phone number';
  }

  if (!data.invoice_number && data.invoice_type != 2) {
    errors.invoice_number = 'Invoice number is required';
  }

  if (!data.payment_status) {
    errors.payment_status = 'Payment type is required';
  }

  if (!data.date) {
    errors.date = 'Date is required';
  }

  if (!data.name) {
    errors.name = 'Name is required';
  }

  return errors;
}


export {
  validateCreateInvoice
}