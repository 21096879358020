import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import TaskService from '../../Services/TaskManager/TaskManagerService'
import CommunicationBar from '../../Services/CommunicationBar/CommunicationBar'
import MasterService from '../../Services/MasterService'
import { DatePickerFormate, displayDateFormate, changeDateFormate } from '../../Helpers/DateFormat';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { TextareaAutosize } from '@material-ui/core';
import { createLog, formatLogData, sendLog } from '../../Helpers/CreateLogs';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import { setCommunicationBarDirtyStatus } from "../../Redux/Actions/Snapshot";
import Button from 'react-bootstrap/Button';

class TaskBox extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			counter: 0,
			error: '',
			is_error: false,
			assign_to_list: [],
			task_list: [],
			date: '',
			due_date: '',
			task: '',
			task_refnumber: '',
			id: 0,
			showForm: true,
			isLoading: true,
			is_edit_action: false,
			assigned_by: '',
			assigned_to: '',
			is_dirty: false,
			priority: 2,
			old_data: {},
			logs_list: [],
			show_logs: false
		}
		this.getTasksBody = {
			ref_id: this.props.context_id,
			mod_type: this.props.context_no,
		}
	}

	setPriority = () => {
		this.state.priority === 1 ? this.setState({ priority: 2 }) : this.setState({ priority: 1 })
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
		this.props.update_communication_bar_tasks_dirty(true)
	}

	handleChange = (e) => {
		const { value, name } = e.target;
		this.setState({ [name]: value });
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
		this.props.update_communication_bar_tasks_dirty(true)
	}

	setDate = (date, key) => {
		this.setState({ [key]: date })
		if (this.state.is_dirty === false)
			this.setState({ is_dirty: true })
		this.props.update_communication_bar_tasks_dirty(true)
	}

	getInitialData = () => {
		TaskService.getRequiredDataForTask().then(data => {
			if (data.response_code == 200) {
				this.setState({
					assigned_by: data.result.assigned_by,
					assign_to_list: data.result.assigned_to,
					date: data.result.date
				})
			} else {
				toast.error(data.message);
			}
		})
		CommunicationBar.getTaskList(this.getTasksBody).then(data => {
			if (data.response_code == 200) {
				console.log(data)
				this.setState({ task_list: data.result.data, isLoading: false })
				// if(data.result.data.length>0){
				// 	this.props.setAvailableTaskFlag(true)
				// }
			}
			else {
				toast.error(data.message);
				console.log(data)
			}
		})
	}


	getLogs = (submod_id) => {
		this.props.dispatch(setAppilcationLoder(true));
		let detail = {
			mod_id: this.props.context_id,
			module: this.props.context_no,
			submodule: 1,
			submod_id: submod_id
		}
		MasterService.getLogs(detail).then((data) => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({ logs_list: data.result.change_logs })
			} else {
				toast.error(data.message)
			}
			this.setState({ show_log: true })
		})
	}

	toggleChangeLog = () => {
		this.setState({ show_log: !this.state.show_log })
	}


	validateInput(data) {
		let counter = 0
		if (!data.task) {
			counter++
		}
		else if (!data.assigned_to) {
			counter++
		}
		else if (!data.due_date) {
			counter++
		}
		return counter;
	}

	findAssigneeName(data) {
		let personObject = this.state.assign_to_list.find(item => item.id == data)
		if (personObject !== undefined) {
			return personObject.name;
		}

	}

	taskSubmitHandler = async (nextRoute) => {

		if (!this.state.is_edit_action) {
			if (!this.state.showForm) {
				this.setState({ showForm: true })
			}
			else {
				let createData = {
					"ref_id": this.props.context_id,
					"mod_type": this.props.context_no,
					"task": this.state.task,
					"assigned_to": this.state.assigned_to,
					// "date": this.state.date,
					"due_date": changeDateFormate(this.state.due_date),
					"priority": this.state.priority,
				}
				let errors = this.validateInput(createData);
				if (errors >= 1) {
					toast.error("Invalid input provided")
					this.setState({ is_error: true })
				}
				else {
					this.props.dispatch(setAppilcationLoder(true));
					await CommunicationBar.createTask(createData).then((data) => {
						this.props.dispatch(setAppilcationLoder(false));
						if (data.response_code == 200) {

							this.props.update_communication_bar_tasks_dirty(false)

							// add task in db
							if (this.state.task_list.length <= 1) {
								this.storeMessageInDB(this.state.task_list.length);
							}

							toast.success("Task added successfully")

							// send log code
							sendLog({
								module_type: this.props.context_no, module_id: this.props.context_id, activity: ['Created New Task'],
								submodule: 1, submod_id: data.result.id
							});
							// end log code



							let newTask = {
								"id": data.result.id,
								"assigned_to": this.state.assigned_to,
								// "date": this.state.date,
								"due_date": changeDateFormate(this.state.due_date),
								"priority": this.state.priority,
								"is_star": 0,
								"ref_no": data.result.task_no,
								"task_name": this.state.task,
								"status": 1,
							}
							let newList = this.state.task_list
							newList.push(newTask)
							this.setState({
								is_edit_action: true,
								task_list: newList,
								is_error: false,
								id: data.result.id,
							})

							if (newList.length > 0) {
								this.props.setAvailableTaskFlag(true)
							}



							if (nextRoute == 'msgs' || nextRoute == 'private_msgs' || nextRoute == 'notes') {
								this.props.onTabChange(nextRoute)
							} else if (nextRoute) {
								this.props.history.push(nextRoute)
							}
						}
						else {
							toast.error(data.message)
						}
					})
				}
			}
		}
		else {
			let updateIdx = this.state.task_list.findIndex(task => task.id == this.state.id)
			let updateData = {
				"id": this.state.task_list[updateIdx].id,
				"task": this.state.task,
				"assigned_to": this.state.assigned_to,
				// "date": this.state.date,
				"due_date": changeDateFormate(this.state.due_date),
				"priority": this.state.priority,
				"status": this.state.task_list[updateIdx].status,
				"ref_id": this.props.context_id,
				"mod_type": this.props.context_no,
			}

			let errors = this.validateInput(updateData);
			if (errors >= 1) {
				toast.error("Invalid input provided")
				this.setState({ is_error: true })
			}
			else {
				if (
					updateData.task == this.state.task_list[updateIdx].task_name &&
					updateData.assigned_to == this.state.task_list[updateIdx].assigned_to &&
					updateData.priority == this.state.task_list[updateIdx].priority &&
					updateData.due_date == this.state.task_list[updateIdx].date
				) {
					toast.error("Same data can't be updated")
				}
				else {
					this.props.dispatch(setAppilcationLoder(true));
					await CommunicationBar.updateTask(updateData).then(data => {
						this.props.dispatch(setAppilcationLoder(false));
						if (data.response_code == 200) {
							toast.success("Task updated successfully");
							this.props.update_communication_bar_tasks_dirty(false)

							// send logs code
							let createdLog = createLog.map(this.state.old_data, updateData)
							formatLogData(createdLog, ["id", "ref_id", "mod_type"], "");
							sendLog({ module_type: this.props.context_no, module_id: this.props.context_id, submodule: 1, submod_id: updateData.id });


							// store old data for log
							this.setState({ old_data: updateData })
							// store old data for log
							// send logs code


							let newData = {
								"assigned_to": updateData.assigned_to,
								// "date": updateData.date,
								"due_date": updateData.due_date,
								"id": this.state.task_list[updateIdx].id,
								"is_star": this.state.task_list[updateIdx].is_star,
								"priority": updateData.priority,
								"ref_number": this.state.task_list[updateIdx].ref_number,
								"status": this.state.task_list[updateIdx].status,
								"task_name": updateData.task
							}
							let newList = this.state.task_list
							newList[updateIdx] = newData
							this.setState({
								// due_date: '',
								// task: '',
								// priority: 2,
								// assigned_to: '',
								// is_edit_action: false,
								task_list: newList,
								is_error: false,
							})

							if (nextRoute == 'msg' || nextRoute == 'private_msg' || nextRoute == 'notes') {
								this.props.onTabChange(nextRoute)
							} else if (nextRoute) {
								this.props.history.push(nextRoute)
							}
						}
						else {
							toast.error(data.message)
						}
					})
				}
			}

		}
	}

	// changeStatus = (e) => {
	// 	let statusData = {
	// 		...this.getTasksBody,
	// 		"id": [e.target.value]
	// 	}

	// 	let statusChangedData = this.state.task_list.find(task => task.id == statusData.id)
	// 	let newIdx = this.state.task_list.findIndex(task => task.id == statusData.id)

	// 	let newList = this.state.task_list

	// 	if (e.target.checked) {
	// 		statusData = { ...statusData, "status": 2 }
	// 	}
	// 	else {
	// 		statusData = { ...statusData, "status": 1 }
	// 	}
	// 	CommunicationBar.markDoneTask(statusData).then((data) => {
	// 		if (data.response_code == 200) {
	// 			statusChangedData = {
	// 				...statusChangedData,
	// 				"status": statusData.status
	// 			}

	// 			newList[newIdx] = statusChangedData

	// 			this.setState({ task_list: newList })
	// 			toast.success("Task status updated")
	// 		}
	// 	})
	// }

	editButtonHandler = (e) => {
		console.log(e.target.id)
		let taskObj = this.state.task_list.find(item => item.id == e.target.id)
		// console.log(taskObj)
		let due_date = taskObj.due_date.split("-")

		if (due_date[0].length <= 2) {
			due_date.reverse()
			let new_due_date = due_date[0] + "-" + due_date[1] + "-" + due_date[2]
			taskObj.due_date = new_due_date
		}

		console.log(taskObj)
		this.setState({
			is_edit_action: true,
			showForm: true,
			task: taskObj.task_name,
			id: e.target.id,
			priority: taskObj.priority,
			due_date: new Date(taskObj.due_date),
			assigned_to: taskObj.assigned_to,
		});

		// store old data for log
		const oldData = { ...this.state.old_data };
		oldData.task = taskObj.task_name;
		oldData.assigned_to = taskObj.assigned_to;
		oldData.priority = taskObj.priority;
		// oldData.date = taskObj.date;
		oldData.due_date = taskObj.due_date;
		oldData.status = taskObj.status;
		oldData.id = taskObj.id;
		oldData.ref_id = this.props.context_id;
		oldData.mod_type = this.props.context_no;
		this.setState({ old_data: oldData })
		// store old data for log
	}

	validateInput(data) {
		let counter = 0
		if (!data.task) {
			counter++
		}
		else if (!data.assigned_to) {
			counter++
		}
		else if (!data.due_date) {
			counter++
		}
		return counter;
	}

	findAssigneeName(data) {
		let personObject = this.state.assign_to_list.find(item => item.id == data)
		if (personObject !== undefined) {
			return personObject.name;
		}

	}

	// taskSubmitHandler = async () => {
	// 	if (!this.state.showForm && !this.state.is_edit_action) {
	// 		this.setState({ showForm: true })
	// 	}
	// 	else if (!this.state.is_edit_action) {
	// 		let createData = {
	// 			"ref_id": this.props.context_id,
	// 			"mod_type": this.props.context_no,
	// 			"task": this.state.task,
	// 			"assigned_to": this.state.assigned_to,
	// 			"due_date": changeDateFormate(this.state.due_date),
	// 			"priority": this.state.priority,
	// 		}
	// 		let errors = this.validateInput(createData);
	// 		if (errors >= 1) {
	// 			toast.error("Invalid input provided")
	// 			this.setState({
	// 				error: 'Field is required'
	// 			})
	// 		}
	// 		else {
	// 			await CommunicationBar.createTask(createData).then((data) => {
	// 				if (data.response_code == 200) {
	// 					let newTask = {
	// 						"id": data.result.id,
	// 						"assigned_to": this.state.assigned_to,
	// 						"date": changeDateFormate(this.state.due_date),
	// 						"priority": this.state.priority,
	// 						"is_star": 0,
	// 						"ref_no": data.result.task_no,
	// 						"task_name": this.state.task,
	// 						"status": 1,
	// 					}

	// 					let newList = this.state.task_list

	// 					toast.success("Task added successfully")
	// 					this.setState({
	// 						// due_date: '',
	// 						// task: '',
	// 						// priority: 2,
	// 						// assigned_to: '',
	// 						// task_refnumber: newTask.ref_no,
	// 						is_edit_action: true,
	// 						error: '',
	// 						task_list: newList,
	// 						id: data.result.id,
	// 					})

	// 					newList.push(newTask)
	// 				}
	// 				else {
	// 					toast.error(data.message)
	// 				}
	// 			})
	// 		}
	// 	}
	// 	else {
	// 		let updateIdx = this.state.task_list.findIndex(task => task.id == this.state.id)
	// 		let updateData = {
	// 			"id": this.state.task_list[updateIdx].id,
	// 			"task": this.state.task,
	// 			"assigned_to": this.state.assigned_to,
	// 			"due_date": changeDateFormate(this.state.due_date),
	// 			"priority": this.state.priority,
	// 			"status": this.state.task_list[updateIdx].status,
	// 			"ref_id": this.props.context_id,
	// 			"mod_type": this.props.context_no,
	// 		}
	// 		console.log(updateData)
	// 		// console.log(this.state.task_list[updateIdx].id)
	// 		// console.log(this.state.task_list[updateIdx].status)

	// 		let errors = this.validateInput(updateData);
	// 		if (errors >= 1) {
	// 			toast.error("Invalid input provided")
	// 		}
	// 		else {
	// 			if (
	// 				updateData.task == this.state.task_list[updateIdx].task_name &&
	// 				updateData.assigned_to == this.state.task_list[updateIdx].assigned_to &&
	// 				updateData.priority == this.state.task_list[updateIdx].priority &&
	// 				updateData.due_date == this.state.task_list[updateIdx].date
	// 			) {
	// 				// toast.error("Same data can't be updated")
	// 				this.setState({
	// 					error: 'Change required'
	// 				})
	// 			}
	// 			else {
	// 				await CommunicationBar.updateTask(updateData).then(data => {
	// 					if (data.response_code == 200) {
	// 						toast.success("Task updated successfully")
	// 						let newData = {
	// 							"assigned_to": updateData.assigned_to,
	// 							"date": updateData.due_date,
	// 							"id": this.state.task_list[updateIdx].id,
	// 							"is_star": this.state.task_list[updateIdx].is_star,
	// 							"priority": updateData.priority,
	// 							"ref_number": this.state.task_list[updateIdx].ref_number,
	// 							"status": this.state.task_list[updateIdx].status,
	// 							"task_name": updateData.task
	// 						}
	// 						let newList = this.state.task_list
	// 						newList[updateIdx] = newData
	// 						this.setState({
	// 							// due_date: '',
	// 							// task: '',
	// 							// priority: 2,
	// 							// assigned_to: '',
	// 							// task_refnumber: newData.ref_number,
	// 							is_edit_action: true,
	// 							error: '',
	// 							task_list: newList,
	// 						})
	// 					}
	// 					else {
	// 						toast.error(data.message)
	// 					}
	// 				})
	// 			}
	// 		}

	// 	}
	// }

	changeStatus = (e) => {
		let statusData = {
			id: [e.target.value]
		}
		// console.log(statusData)

		let statusChangedData = this.state.task_list.find(task => task.id == statusData.id)
		let newIdx = this.state.task_list.findIndex(task => task.id == statusData.id)

		let newList = this.state.task_list

		if (statusChangedData.status == 1) {
			statusData = { ...statusData, "status": 2 }
		}
		else {
			statusData = { ...statusData, "status": 1 }
		}

		// if (e.target.checked) {
		// 	statusData = { ...statusData, "status": 1 }
		// }
		// else {
		// 	console.log(statusData)
		// 	statusData = { ...statusData, "status": 2 }
		// }
		CommunicationBar.markDoneTask(statusData).then((data) => {
			if (data.response_code == 200) {
				statusChangedData = {
					...statusChangedData,
					"status": statusData.status
				}

				newList[newIdx] = statusChangedData

				this.setState({ task_list: newList })
				toast.success("Task status updated")
			}
		})
	}

	// editButtonHandler = (e) => {
	// 	//console.log(e.target.id)
	// 	let taskObj = this.state.task_list.find(item => item.id == e.target.id)
	// 	this.setState({
	// 		is_edit_action: true,
	// 		showForm: true,
	// 		task: taskObj.task_name,
	// 		id: e.target.id,
	// 		// task_refnumber: taskObj.ref_number,
	// 		priority: taskObj.priority,
	// 		due_date: new Date(taskObj.due_date),
	// 		assigned_to: taskObj.assigned_to,
	// 	})
	// }

	cancelUpdateHandler = () => {
		this.setState({
			is_edit_action: false,
			task: '',
			id: 0,
			priority: 2,
			due_date: '',
			assigned_to: '',
		})
	}

	deleteTaskHandler = async () => {
		let deleteData = {
			...this.getTasksBody,
			"id": this.state.id
		}
		if (!await confirm({ confirmation: " Are you sure to delete? " })) {
			return true;
		}
		await CommunicationBar.deleteTask(deleteData).then((data) => {
			if (data.response_code == 200) {
				let taskList = this.state.task_list
				let index = taskList.findIndex(task => task.id == this.state.id)
				taskList.splice(index, 1)
				this.setState({
					is_edit_action: false,
					task: '',
					error: '',
					is_dirty: false,
					id: 0,
					priority: 2,
					due_date: '',
					assigned_to: '',
				})
				if (taskList.length < 1) {
					this.props.setAvailableTaskFlag(false)
				}
				toast.success("Task deleted successfully")
				this.props.update_communication_bar_tasks_dirty(false)
			}
			else {
				toast.error(data.message)
			}
		})
	}

	formCloseHandler = () => {
		this.setState({
			showForm: false,
			is_edit_action: false,
			due_date: '',
			task: '',
			error: '',
			is_dirty: false,
			// task_refnumber: '',
			priority: 2,
			assigned_to: '',
			is_error: false,
		})
		this.props.update_communication_bar_tasks_dirty(false)
	}

	componentDidMount() {
		this.props.onRef(this)
		this.getInitialData()
	}

	storeMessageInDB = (tasks_list_length) => {
		let data = {
			"ref_id": this.props.context_id,
			"mod_type": this.props.context_no,
			"general_msg_status": null,
			"notes_status": null,
			"private_msg_status": null,
			"tasks_status": tasks_list_length == 0 ? 1 : 0
		}
		CommunicationBar.storeMessageInDB(data).then(
			(data) => {

			});
	}

	render() {
		// console.log(this.state.task_refnumber)
		return (
			<>
				<ChangeLog
					show_log={this.state.show_log}
					toggleChangeLog={this.toggleChangeLog}
					logs={this.state.logs_list}
				/>

				<div className="comment-cmb-box">
					{this.state.isLoading ? <Spinner animation="border" variant="secondary" className="cmb-loader" /> : ""}

					{this.state.isLoading &&
						<Spinner animation="border" variant="secondary" className="cmb-loader" />
						// <h2 style={{ textAlign: "center", fontSize: "2em" }}>Loading....</h2>
					}
					<h3 className="cmb-heading"><span className="icon-Group-2516 mr-2"></span>Tasks</h3>

					<div className="msg-sec-cmb">
						{this.state.task_list.map((task) => {
							return (
								<div key={task.ref_number} className="messages-cmb taskc-box">
									<div className="pnd-cmb">
										{/* <div> */}
										{/* <div className="check-box mt-1"> */}
										<div className="sup-cust-check mt-1">
											<input checked={task.status == 2 ? true : false} className="styled-checkbox" id={task.id} type="checkbox" onChange={this.changeStatus} value={task.id} />
											{/* <label for={task.id} className="label-text blu-color s-sbold ft-sz-18 mb-0"></label> */}
											<label for={task.id} className=""></label>
										</div>
										{/* </div> */}
									</div>
									<div className="notes-msg-box">
										<div className="nd-cmb">
											<p className={"taskc-name " + (task.status == 2 ? " line-through" : " ")}>{task.task_name}</p>

											<div class="day-reply-sec">
												<p><span class="day-reply-text">Assignee:</span> {this.findAssigneeName(task.assigned_to)}</p>
												<p><span class="day-reply-text">Due Date:</span> {displayDateFormate(task.due_date)}</p>
											</div>
										</div>
										<div className="notesc-icons">
											<OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
												<span id={task.id} onClick={this.editButtonHandler} class="icon-edit cpointer mb-2"></span>
											</OverlayTrigger>

											<OverlayTrigger overlay={<Tooltip>Change Log</Tooltip>}>
												<span onClick={() => this.getLogs(task.id)} class="icon-round-info-button cpointer"></span>
											</OverlayTrigger>
										</div>
									</div>
								</div>
							)
						})}
					</div>

					{/* <form> */}
					<div className="bottom-cmb position-relative">
						{/* Crose icon */}
						{/* {this.state.showForm ?
							<div className="pull-right">
								<span class="icon-Cross-with-grey-circle tccrose cpointer" onClick={this.formCloseHandler}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
							</div>
							: null
						} */}
						{this.state.showForm ?
							<span class="ss-cross-md" onClick={this.formCloseHandler}>
								<span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
							</span>
							: null
						}

						<div className="">
							<lable className="lable-add-note">Task<span className="red-star">*</span></lable>
							<div className="bottom-inner-cmb">
								{/* Task */}
								<div className="user-comment-cmb task-notes-comment-cmb">
									<div className="form-group mb-0 w-100">
										<TextareaAutosize
											className="form-control task-placeholder"
											minRows={2}
											disabled={this.props.view_only}
											maxLength="1000"
											value={this.state.task}
											name="task"
											onChange={(e) => { this.handleChange(e) }}
											placeholder=" Enter task details" />
									</div>
								</div>

								{/* Icons */}
								<div className="icon-cmb">
									<a role="button" onClick={() => this.props.onTabChange('msgs')}>
										{this.state.public_messages ? <span className="active-dot"></span> : ""}
										<OverlayTrigger overlay={<Tooltip>Messages</Tooltip>}>
											<span className="icon-chat"></span>
										</OverlayTrigger>
									</a>
									{
										!this.props.hide_task_manager &&
										<a role="button" className="active" onClick={() => this.props.onTabChange('tasks')}>
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tasks</Tooltip>}>
												<span className="icon-Group-2516"></span>
											</OverlayTrigger>
										</a>
									}
									<a role="button" onClick={() => this.props.onTabChange('notes')}>
										<OverlayTrigger overlay={<Tooltip>Notes</Tooltip>}>
											<span className="icon-Group-2546"></span>
										</OverlayTrigger>
									</a>
									<a role="button" onClick={() => this.props.onTabChange('private_msgs')}>
										{this.state.private_messages ? <span className="active-dot"></span> : ""}
										<OverlayTrigger overlay={<Tooltip>Private Messages</Tooltip>}>
											<span className="icon-chat"></span>
										</OverlayTrigger>
									</a>
								</div>
							</div>

							{/* {(this.state.is_error && !this.state.task) ?
								<small className="form-text text-muted position-relative text-left">
									Task is required</small> : ""} */}
							{(this.state.is_error && !this.state.task) ?
								<small className="form-text red-text text-left">
									Task is required</small> : ""}


							<div className={"row mt-2 mb-1" + (!this.state.showForm ? " d-none" : "")}>
								<div className="col-xl-3 col-lg-6 col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Assigned To<span class="red-star">*</span></lable>
										<select
											name="assigned_to"
											value={this.state.assigned_to}
											onChange={(e) => this.handleChange(e)}
											className="form-control"
											disabled={this.props.view_only}>
											<option value="" selected disabled>Select User</option>
											{this.state.assign_to_list.map((item, index) => {
												return <option key={index} value={item.id}>{item.name}</option>
											})}
										</select>
										{(this.state.is_error && !this.state.assigned_to) ?
											<small className="form-text text-muted">
												Assigned to is required</small> : ""}
									</div>
								</div>

								<div className="col-xl-3 col-lg-6 col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Assigned By</lable>
										<input type="text" className="form-control input-bg-gray h-auto readonly" name="assigned_by" value={this.state.assigned_by} readOnly />
									</div>
								</div>

								<div className="col-xl-3 col-lg-6 col-sm-12">
									<div className="form-group">
										<lable className="lable-input">Due Date<span className="red-star">*</span></lable>
										<ReactDatePicker
											dateFormat={DatePickerFormate()}
											onChange={date => this.setDate(date, "due_date")}
											disabled={this.props.view_only}
											// className="form-control h-auto datep"
											className="form-control datepicker-icon"
											minDate={new Date()}
											selected={this.state.due_date}
											autoComplete="off"
											name="date"
											showMonthDropdown
											showYearDropdown
											scrollableYearDropdown
										/>
										{(this.state.is_error && !this.state.due_date) ?
											<small className="form-text text-muted">
												Due date is required</small> : ""}
									</div>
								</div>

								<div className="col-xl-3 col-lg-6 col-sm-12 d-flex align-items-end">
									{/* <lable className="lable-input d-lg-block d-md-block d-sm-none">&nbsp;</lable> */}
									<div className="form-group">
										{this.state.priority === 1 ?
											<button className="btn s-bold blu-color high-priority active" name="highPriority"
												disabled={this.props.view_only} onClick={() => { this.setPriority() }}>
												<span className='d-flex align-items-center justify-content-center'>
													<span className="icon-exclamation-red-sign white-red-icon icon-h">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</span>
													<span className="ml-2 high-priority-text">High Priority</span>
												</span>
											</button>
											:
											<button className="btn s-bold blu-color high-priority " name="highPriority"
												disabled={this.props.view_only} onClick={() => { this.setPriority() }}>
												<span className='d-flex align-items-center justify-content-center'>
													<span className="icon-exclamation-red-sign gray-white-icon icon-h">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</span>
													<span className="ml-2 high-priority-text">High Priority</span>
												</span>
											</button>
										}
									</div>
								</div>
							</div>

							{/* Button */}
							{this.state.is_edit_action == true ?
								(
									<>
										<div className="text-right">
											<Button type="button" variant="btn-outline-success reset_btn_new mr-3"
												onClick={this.deleteTaskHandler}>Delete</Button>

											<Button type="button" variant={"success apply_btn_new " + (!this.state.is_dirty ? "disabled" : "success apply_btn_new ")} onClick={this.taskSubmitHandler}>Update Task</Button>
										</div>
									</>
								)
								:
								<div className="text-right">
									<Button type="button"// variant="success apply_btn_new" 
										variant={"success apply_btn_new mt-1" + (!this.state.is_dirty ? " disabled" : "")}
										disabled={this.props.view_only} onClick={this.taskSubmitHandler}>{this.state.showForm ? "Save" : "Add"} Task</Button> 
								</div>
							}
						</div>
					</div>
				</div>
				{/* </form> */}
			</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const entity_list = all_value.entity_list || [];
	const account_permissions = all_value.account_permissions || [];
	const module_permissions = all_value.module_permissions || [];
	const user_detail = all_value.user_detail || {}
	return {
		entity_list, account_permissions, module_permissions, user_detail
	};
}
export default connect(mapStateToProps)(withRouter(TaskBox));