import Store from '../Stores/Store';

import EntityListing from '../Views/ProfileManagement/Business/EntityListing';
import SnapshotView from '../Views/Snapshot/SnapshotView';
// import { InvoiceList,CreateInvoiceView } from '../Views/Invoices';
import TaskManagerView from '../Views/TaskManager/TaskManagerView';
import InvoiceView from '../Views/Invoices/InvoiceView';
import CreateInvoiceView from '../Views/Invoices/CreateInvoiceView';
import { InvoiceList } from '../Views/Invoices/InvoiceList';
import CreateCheque from '../Views/Cheques/CreateChequeView';
import TaskManager from '../Views/TaskManager/TaskManagerView';
import CreateTask from '../Views/TaskManager/CreateTask';
import ChequeListView from '../Views/Cheques/ChequeListView';
import Cheques from '../Views/Cheques/Cheques';
import Invoices from '../Views/Invoices/Invoices';
import SMSView from '../Views/Snapshot/SMSView';
import MoneyLinkView from '../Views/MoneyLink/MoneyLinkView';
import FinancialInstitutes from '../Views/FinancialInstitutes/FinancialInstitutes';
import Licenses from '../Views/ProfileManagement/Licenses/Licenses';
//import DashboardViewss from '../Views/Dashboard2/DashboardViewss';
import DashboardView from '../Views/Dashboard/DashboardView';
import MyProfile from '../Views/ProfileManagement/MyProfile/MyProfile'
import HomeView from '../Views/Home/HomeView';
import ReportsView from '../Views/Reports/ReportsView';
import MessengerView from '../Views/Messenger/MessengerView'; 
import WelcomeView from '../Views/LandingScreen/WelcomeView'; 
import Docbox from "../Views/DocBox/DocboxView";
//import MyComponent from '../Views/FinancialInstitutes/MyComponent';
// const Toaster = React.lazy(() => import('./views/notifications/toaster/Toaster'));
// const Tables = React.lazy(() => import('./views/base/tables/Tables'));

// const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'));i
// const EntityListing = React.lazy(() => import('../Views/ProfileManagement/EntityListing'));
  // { path: '/users/:id', exact: true, name: 'User Details', component: User }



const routes = [
  { path: '/my-profile/', name: 'My Profile', component: MyProfile, module_id: 0 },
  { path: '/home/', name: 'Home', component: HomeView, module_id: 0 },
  { path: '/dashboard/', name: 'Dashboard', component: DashboardView, module_id: 0 },
  { path: '/profile-management/', name: 'Profile Management', component: EntityListing, module_id: 6 },
  { path: '/invoices', exact: true, name: 'Invoices', component: InvoiceView, module_id: 2 },
  { path: '/invoices/create/:invoice_id?', name: 'Create Invoice', component: InvoiceView, module_id: 2 },
  { path: '/cheque/create/:cheque_id?', name: 'Create Cheques', component: Cheques, module_id: 3 },
  { path: '/cheque', name: 'Cheque List', component: Cheques, module_id: 3 },
  { path: '/task-manager', name: 'Task Manager', component: TaskManager, module_id: 0 },
  { path: '/snapshot/:evidence_type?', name: 'Snapshot', component: SnapshotView, module_id: 1 },
  { path: '/money-link', name: 'Money Link', component: MoneyLinkView, module_id: 5 },
  { path: '/financial-institutes', name: 'Financial Institutes And Account Statement', component: FinancialInstitutes, module_id: 4 },
  { path: '/licenses', name: 'My Licenses', component: Licenses, module_id: 6 },
  { path: '/reports', name: 'Reports', component: ReportsView, module_id: 10 },
  { path: '/messenger', name: 'Messenger', component: MessengerView, module_id: 0 },
  { path: '/welcome', name: 'Messenger', component: WelcomeView, module_id: 0 },
  { path: '/DashBar', name: 'Messenger', component: WelcomeView, module_id: 0 },
  { path: '/docbox', name: 'Docbox', component: Docbox, module_id: 0 },
  { path: '/su/:evidence_type?', name: 'Snapshot', component: SnapshotView, module_id: 1 },
  { path: '/mu/:transaction_id?', name: 'Money Link', component: MoneyLinkView, module_id: 5 },
];

export default routes;
