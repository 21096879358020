import { APPLY_FILTER_DATA, TASK_COUNT_DATA, SET_TOTAL_COUNT, SET_PAGE_NO, SET_PER_PAGE_ITEM, SET_IS_TASK_LOADING, SET_TASK_STAR, SET_TM_FINANCIAL_YEAR_NAME, SET_TM_FILTER_DATE_RANGE, SET_TM_CARD_FILTER, SET_TM_TEMP_DATA } from "../Actions/Types";

const initialState = {
  financial_year_name: '',
  filter_start_date: null,
  filter_end_date: null,
  filter_card: 0,
  task_temp_data:{}
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case APPLY_FILTER_DATA:
      return {
        ...state,
        filter_data: payload,
        is_star: payload.is_star,
        due_start_date: payload.due_start_date,
        due_end_date: payload.due_end_date,
        assigned_by: payload.assigned_by,
        assigned_to: payload.assigned_to,
        priority: payload.priority
      }
    case TASK_COUNT_DATA:
      return {
        ...state,
        task_count: payload
      }
    case SET_TOTAL_COUNT:
      return {
        ...state,
        total_count: payload
      }
    case SET_PAGE_NO:
      return {
        ...state,
        page_no: payload
      }
    case SET_PER_PAGE_ITEM:
      return {
        ...state,
        per_page_items: payload
      }
    case SET_IS_TASK_LOADING:
      return {
        ...state,
        is_task_loading: payload
      }
    case SET_TASK_STAR:
      return {
        ...state,
        task_id: payload.task_id,
        changed_star: payload.changed_star
      }
    case SET_TM_FINANCIAL_YEAR_NAME:
      return {
        ...state,
        financial_year_name: payload
      }
    case SET_TM_FILTER_DATE_RANGE:
      return {
        ...state,
        filter_start_date: payload.start_date,
        filter_end_date: payload.end_date
      }
    case SET_TM_CARD_FILTER:
      return {
        ...state,
        filter_card: payload
      }
    case SET_TM_TEMP_DATA:
      return {
        ...state,
        task_temp_data: payload
      }
    default:
      return state;
  }
}