import axios from 'axios';
import Header from './Header';
import {API_URL} from '../Utils/Config';
import { timeoutInMillis } from '../Helpers/Constants';

class MasterService {

  getCountries() {
    return axios
      .get(API_URL + 'countries', { headers: Header.withoutAuthHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }  

  getStates(country_id) {
    return axios.get(API_URL + 'states/'+country_id, { headers: Header.withoutAuthHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  getCities(state_id) {
    return axios.get(API_URL + 'cities/' +state_id, { headers: Header.withoutAuthHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }


  createUser(full_name,username,email,phone_number,dialcode_id,status) {
    let data = {
        "full_name": full_name,
        "username": username,
        "email":email,
        "phone_number":phone_number,
        "dialcode_id":dialcode_id,
        "status":status
      };
    return axios
      .post(API_URL + "create_user", data,  { headers: {'Content-Type': 'application/json'} })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }


  getFinancialYear() {
    return axios.get(API_URL + 'date-filter', { headers: Header.authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  getTags(entity_id = 1) {
    return axios.get(API_URL + 'tags', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getPaymentDetail() {
    return axios.get(API_URL + 'payment-details', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getCategryList() {
    return axios.get(API_URL + 'categories', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  addCategory(category) {
    let addData = {
      "name": category
    }
    return axios.post(API_URL + 'category', addData, { headers: Header.entityHeader() })
      .then((response) => {
        console.log(response)
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  
  getSignedUrl(file_name,file_type,module,prefix="") {
    let req_data = {
      "filename" : file_name,
      "prefix":"",
      "mimetype":file_type,
      "module":module
  }
  return axios.post(API_URL + 'request-signed-url',req_data, { headers: Header.entityHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  async uploadFileFromBlobUrl(file,file_name,file_type) {
    return await fetch(file).then(r => r.blob());
    /*return axios.put(signed_url, blob1 , { headers: {  'Content-Type': file_type } })
      .then((response) => {
        return response;
      }).catch((error) => {
        return error;
      });*/
  }

  async uploadFile(signed_url,file,file_name,file_type) {
    return axios.put(signed_url, file , { headers: {  'Content-Type': file_type }, timeout: timeoutInMillis})
      .then((response) => {
        return response;
      }).catch((error) => {
        console.log(error)
        if (error.request){
          return 'timeout'
        }
        return error;
      });
  }

  async getSignedUploadFile(file,file_name,file_type,module,prefix="") {
    const signed_url_response = await this.getSignedUrl(file_name,file_type,module,prefix="");
    // Ram------> condition to check if the user is providing valid image
    if(signed_url_response.response_code==200){
      const signed_url_data=signed_url_response.result;
      const gcp_subpath = signed_url_data.gcp_subpath;
      const link = signed_url_data.link;
      
      const gcp_response = await this.uploadFile(link,file,file_name,file_type);
      if(gcp_response == 'timeout') {
        return gcp_response;
      }
      if (gcp_response.status == 200) {
        return signed_url_data;
      }else{
        return null;
      }
    }else{
      return null
    }
  }

  getblobImage = async (file_url) => {
    return await fetch(file_url).then(r => r.blob());;
  } 

  getUserEntities() {
    return axios.get(API_URL + 'acl-entities', { headers: Header.authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }
  
  getEntityPermission(entity_id) {
    return axios.get(API_URL + 'entity-permissions/'+entity_id, { headers: Header.authHeader() })
    .then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  }

  getLogs = (detail) => {
    let data = JSON.stringify(detail);
    return axios.post(API_URL + 'change-logs', data, { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

  getNotifications = (data) => {
    return axios.get(API_URL + 'notifications', { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

  getAllNotifications = (data) => {
    return axios.post(API_URL + 'notifications',data, { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

  notificationMarkAsRead = (data) => {
    return axios.put(API_URL + 'notifications',data, { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

  getNotificationCount = () => {
    return axios.get(API_URL + 'notifications-count', { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

  sendFCMToken = (data) => {
    return axios.post(API_URL + 'fcm-user',data, { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

  downloadTallyBridge = () => {
    return axios.get(API_URL + 'tally-bridge-download', { headers: Header.entityHeader() })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return error;
    });
  }

}

export default new MasterService();