import React from 'react'

class Pagination extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		return (<>
				<nav className="sidebar-new-pagination" aria-label="...">
					{/* <div className="per-page-sec clearfix">
							<ul className="pagination pull-right mb-0">
								<li className="page-item"><a className="page-link ft-sz-18 s-reg">Items per Page</a></li>
								<li className="page-item item-count"><a className="page-link ft-sz-18 s-reg">{this.props.per_page_items}</a></li>
							</ul>
						</div> */}
					{/* <ul class="pagination pagination-sm justify-content-end"> */}
					<ul className="pagination justify-content-end mb-0">

						<li className="page-item" disabled={!(this.props.has_previous && this.props.page_no > 1)} onClick={() => {
							if (this.props.has_previous && this.props.page_no > 1) {
								this.props.getList(this.props.page_no - 1)
							}
						}}>
							<a className={"page-link " + (this.props.has_previous && this.props.page_no > 1 ? "" : " disable-btn")}><span class="icon-Slider-arrow2 pagi-arrow mr-1"></span> Prev</a>
						</li>

						
						{this.props.has_previous && <li className="page-item"><a className="page-link">...</a></li>}
						<li className="page-item active"><a className="page-link">{this.props.page_no}</a></li>
						{this.props.has_next && <li className="page-item"><a className="page-link">...</a></li>}

						{/* Next */}
						<li className="page-item" disabled={!(this.props.has_next && this.props.page_no < this.props.total_pages)} onClick={() => {
							if (this.props.has_next && this.props.page_no < this.props.total_pages) {
								this.props.getList(this.props.page_no + 1)
							}
						}}>
							<a className={"page-link " + (this.props.has_next && this.props.page_no < this.props.total_pages ? "" : " disable-btn")}>Next <span class="icon-Slider-arrow-1 pagi-arrow ml-1"></span></a>
						</li>
					</ul>
				</nav>

			</>
		)
	}
}

export default Pagination;