import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function OverdueStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Overdue</Tooltip>}>
        <span className="icon-clocki icon-dt-td iconsc"></span>
            {/* <span className="icon-td icon-icon-clock ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span> */}            
        </OverlayTrigger>
    </>
}
export default OverdueStatus;