import React from 'react'

class AppliedFilterTag extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            this.props.tagValue ?
                // <span className="ft-sz-16 s-sbold blu-color sidebar_tagname">{this.props.tagValue}</span>
                <span className="sidebar-tagname">{this.props.tagValue}</span>
                :<></>
        )
    }
}

export default AppliedFilterTag;