import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function NotProcessedStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Pending</Tooltip>}>
            {/* <span className="not-processed-icon d-block"> <span className="icon-processed-icon ft-sz-30 icon-td"><span className="path1"></span><span className="path2"></span></span></span> */}
            <span className="icon-processed-icon icon-dt-td iconsc"><span className="path1"></span><span className="path2"></span></span>
        </OverlayTrigger>
    </>
}
export default NotProcessedStatus;