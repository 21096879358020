function validateUser(data)
{
  let errors = {};

  if (!data.full_name) {
    errors.full_name = 'Full Name is required';
  }

  if (!data.username) {
    errors.username = 'Username is required';
  }else if(!/^[a-zA-Z][a-zA-Z0-9.\-]+$/.test(data.username)){
    errors.username = 'Invalid Username';
  }

  // if(!validateEmail(data.email) && (!data.phone_number)){
  //   errors.email = 'Invalid email id';
  // }

  if (!data.email && (!data.phone_number)) {
    errors.email = 'Email is required';
  }else if (!validateEmail(data.email) && data.email) {
    errors.email = 'Invalid Email';
  }

  if (!data.phone_number && (!data.email)) {
    errors.phone_number = 'Phone number is required';
  }else if (data.phone_number.length != 10 && data.phone_number) {
    errors.phone_number = 'Invalid Phone number';
  }
  // else if(!/^(\(?[1-9]{3}\)?)((\s|\-){1})?[0-9]{3}((\s|\-){1})?[0-9]{4}$/.test(data.phone_number) && data.phone_number){
  //   errors.phone_number = 'Invalid phone number';
  // }


  return errors;
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export {
    validateUser
}