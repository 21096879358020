import React from 'react'
import { Form } from 'react-bootstrap';

import { connect } from "react-redux";
import { setFilterData } from "../../Redux/Actions/TaskManager";
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import { DatePickerFormate } from '../../Helpers/DateFormat';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';

class TaskFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_star: null,
      due_start_date: "",
      due_end_date: "",
      assigned_by: [],
      assigned_to: [],
      priority: null,
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  setDate = (date, key) => {
    this.setState({ [key]: date })
  }

  onMultiselectSelect(selectedList, selectedItem, key) {
    this.setState({ [key]: selectedList })
  }

  onMultiselectRemove(selectedList, removedItem, key) {
    this.setState({ [key]: selectedList })
  }


  applyFilter = (e) => {
    if((this.state.is_star == null || this.state.is_star == "") && this.state.due_start_date == "" && this.state.due_end_date == "" && this.state.assigned_by.length == 0 && this.state.assigned_to.length == 0 && (this.state.priority == null || this.state.priority == "")){
        toast.error("Please specify filter criteria")
    } else {
        let filter_data = {
            is_star: this.state.is_star,
            due_start_date: this.state.due_start_date||null,
            due_end_date: this.state.due_end_date||null,
            assigned_by: this.state.assigned_by,
            assigned_to: this.state.assigned_to,
            priority: this.state.priority||null,
        }
        this.setState({
            ...filter_data
        })
        this.props.dispatch(setFilterData(filter_data));
        this.props.applyFilter("apply");
        if(this.props.search){
          this.props.reset()
          toast.warning("Removed search filter and applied filter data")
        }     
    }
  }

  resetFilter = () => {
    if(this.props.filter_by === 2){
			if(
				(this.state.is_star == null || this.state.is_star == "") &&
				this.state.due_start_date=="" &&
				this.state.due_end_date=="" &&
				this.state.assigned_by.length==0 && 
				this.state.assigned_to.length==0 &&
				this.state.priority=="" 
			){
				toast.error("No filter applied to reset")
			}
      else{
				let filter_data = {
					is_star: '',
					due_start_date: "",
					due_end_date: "",
					assigned_by: [],
					assigned_to: [],
					priority: "",
				}
				this.setState({
					...filter_data
				})
				this.props.dispatch(setFilterData(filter_data));
				this.props.applyFilter("reset");
				// this.state.show_side_filter(false)
			}
    }  
    else{
      let filter_data = {
        is_star: '',
        due_start_date: "",
        due_end_date: "",
        assigned_by: [],
        assigned_to: [],
        priority: "",
      }
      this.setState({
        ...filter_data
      })
      this.props.dispatch(setFilterData(filter_data));
      this.props.applyFilter("reset");
      // this.state.show_side_filter(false)
    }
  }

  componentDidUpdate(prevProps) {
    if (!(JSON.stringify((this.props.filter_data)) === JSON.stringify(prevProps.filter_data))) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      this.setState({
        ...this.props.filter_data
      })
    }
  }

  componentDidMount(){
    console.log('mounted')
    if(this.props.filter_data){
      this.setState({
        is_star: this.props.filter_data.is_star,
        due_start_date: this.props.filter_data.due_start_date,
        due_end_date: this.props.filter_data.due_end_date,
        assigned_by: this.props.filter_data.assigned_by,
        assigned_to: this.props.filter_data.assigned_to,
        priority: this.props.filter_data.priority,
      })
    }
  }

  render() {
    return (
      <div className="main-filter-popup-new">
        <div className="row">          
          <div className="col-xl-2 col-lg-3 col-md-4 col-12">
            <div className="form-group">
              <select className="form-control"
                onChange={this.handleOnChange}
                value={this.state.is_star}
                name="is_star" >
                <option value="" selected>Starred</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-8 col-12">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-2 col-12 due-date-new">
                <label className="s-sbold">Due Date</label>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                <div className="form-group cursor-cmenu">
                  <DatePicker
                    dateFormat={DatePickerFormate()}
                    onChange={date => this.setDate(date, "due_start_date")}
                    className="form-control datepicker-icon"
                    selected={this.state.due_start_date}
                    maxDate={!!this.state.due_end_date ? new Date(this.state.due_end_date): ""}
                    autoComplete="off"
                    placeholderText="From Date"
                    name="due_start_date" />
                  {/* <span class="icon-calendar"></span> */}
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                <div className="form-group cursor-cmenu">
                  <DatePicker
                    dateFormat={DatePickerFormate()}
                    onChange={date => this.setDate(date, "due_end_date")}
                    className="form-control datepicker-icon"
                    selected={this.state.due_end_date}
                    minDate={new Date(this.state.due_start_date)}
                    autoComplete="off"
                    placeholderText="To Date"
                    name="due_end_date" />
                  {/* <span class="icon-calendar"></span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-4 col-12">
            <div className={"form-group cursor-cmenu assignedby-icon assign-by "+(this.state.assigned_by.length===0?"":" assign-select")}>
              <Multiselect
                className="form-control"
                options={this.props.assigned_by_arr}
                selectedValues={this.state.assigned_by}
                displayValue="full_name"
                onSelect={(selectedList, selectedItem) => this.onMultiselectSelect(selectedList, selectedItem, "assigned_by")}
                onRemove={(selectedList, removedItem) => this.onMultiselectRemove(selectedList, removedItem, "assigned_by")}
                placeholder="Assigned By"
                closeOnSelect="false"
                id="search_input1"
                showCheckbox={true}
              />
              {/* <span class="icon-right-arrow-1 dropdown_arrow"></span> */}
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-4 col-12">
            <div className={"form-group cursor-cmenu assignedby-icon assign-by "+(this.state.assigned_to.length===0?"":" assign-select")}>
              <Multiselect
                className="form-control"
                options={this.props.assigned_to_arr}
                selectedValues={this.state.assigned_to}
                onSelect={(selectedList, selectedItem) => this.onMultiselectSelect(selectedList, selectedItem, "assigned_to")}
                onRemove={(selectedList, removedItem) => this.onMultiselectRemove(selectedList, removedItem, "assigned_to")}
                displayValue="full_name"
                placeholder="Assigned To"
                closeOnSelect="false"
                id="search_input2"
                showCheckbox={true}
              />
              {/* <span class="icon-right-arrow-1 dropdown_arrow"></span> */}
            </div>
          </div>

          <div className="col-xl-2 col-lg-3 col-md-4 col-12">
            <div className="form-group">
              <select className="form-control"
                onChange={this.handleOnChange}
                value={this.state.priority}
                name="priority"
                placeholder="Select priority">
                <option selected disabled value="" >Priority</option>
                <option value="">All</option>
                <option value="1">High Priority</option>
                <option value="2">Normal Priority</option>
                
              </select>
            </div>
          </div>
         
          <div className="col-xl-10 col-lg-6 col-12">
            <div className="reset_apply_btn_new">
              <Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
              <Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { filter_data } = state.TaskManager || {};
  return {
    filter_data,
  };
}

export default connect(mapStateToProps)(TaskFilter);