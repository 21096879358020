import React from 'react'
import { Accordion, Card, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { showAmount } from '../../Helpers/Currency';
import { showAliasAcctNo } from '../../Helpers/HelperFunction';
import { displayDateFormate } from '../../Helpers/DateFormat';
import { GSTIcon, LinkedStatus, NotReadyStatus, ProcessedStatus, ReadyStatus, SyncedStatus, TDSIcon } from '../../Views/IcoonNew';
import IsPersonalIcon from '../../Views/IcoonNew/IsPersonal';
import ReactDatePicker from "react-datepicker";
import _ from 'lodash';

class LinkHistoryView extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			vochers_type: { 1: 'Contra', 2: 'Payment', 3: 'Receipt', 4: 'Journal', 5: 'Sales', 6: 'Purchase' },
		}
	}

	getStatus = (status) => {
		// switch (status) {
		// 	case 1:
		// 		return <ReadyStatus />
		// 	case 2:
		// 		return <NotReadyStatus />
		// 	case 3:
		// 		return <ProcessedStatus />
		// 	case 4:
		// 		return <LinkedStatus />
		// 	case 5:
		// 		return <SyncedStatus />
		// 	default:
		// 		return "--"
		// }

		switch (status) {
			case 1:
				return <NotReadyStatus />
			case 2:
				return <ReadyStatus />
			case 4:
				return <ProcessedStatus />
			case 5:
				return <SyncedStatus />
			default:
				return "--"
		}
	}

	getSnapshotType = (cell) => {
		let type = "";
		switch (cell) {
			case 1: type = "Sales Invoice"; break;
			case 2: type = "Purchase Bill"; break;
			case 3: type = "Sales Return"; break;
			case 4: type = "Purchase Order"; break;
			case 5: type = "Purchase E-way Bill"; break;
			case 6: type = "Expense Bill"; break;
			case 7: type = "Purchase Return"; break;
			case 8: type = "Other Outflow"; break;
			case 9: type = "Sales Order"; break;
			case 10: type = "Sales E-way Bill"; break;
			case 11: type = "Other Inflow"; break;
		}
		return type;
	}

	getType = (type) => {
		switch (type) {
			case 1: return "CR"
			case 2: return "DR"
			default: return "--"
		}
	}

	lastThreeDigits = (account_number) => {
		if (account_number.length > 3) {
			return account_number.substring(account_number.length - 3)
		}
		else {
			return account_number
		}
	}

	render() {

		const link_history_evidence = this.props.link_history_evidence;
		//console.log(link_history_evidence)
		return (
			// <div className={"link-history-new-popup " + (this.props.show_modal ? "" : "d-none")}>
			<div className={"list-history-new-popup " + (this.props.show_modal ? "" : "d-none")}>
				<div className="list-history-content">
					<span class="popup-cross" onClick={() => this.props.closeModal()}>
						<span class="icon-Cross-with-grey-circle popup-cross-icon"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
					</span>

					<h3 className="heading-list">Link History</h3>
					<div className="myAccordion">
						<Accordion defaultActiveKey={0}>
							<>
								{!_.isEmpty(link_history_evidence) &&
									<Card>
										<Accordion.Toggle as={Card.Header} eventKey={1234}>
											<span className="label-accordion">
												Evidences (Snapshot)
											</span>
											<div className="iconbar-accordion">
												{this.getStatus(link_history_evidence.snapshot_status)}
												<GSTIcon active={link_history_evidence.gst_status} />
												<TDSIcon active={link_history_evidence.tds_status} />
												<span className="icon-arrow-down-sign-to-navigate down-arrow"></span>
											</div>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey={1234}>
											<Card.Body class="card-body">
												{/* <div className="list-history-new-popup d-none">
														<p className="">SMS</p>
														<p className="">{transaction.sms||"NA"}</p>
													</div> */}
												<table className="table borderTable">
													<thead>
														<tr>
															{/* <th width="120px">Date</th> */}
															<th class="td-date-120">Date</th>
															<th width="150px">Type</th>
															<th width="">Party Name</th>
															<th width="100px">Expense</th>
															<th width="150px" className="text-right">Amount</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{displayDateFormate(link_history_evidence.snapshot_date)}</td>
															<td>{this.getSnapshotType(link_history_evidence.snapshot_type)}</td>
															<td>{link_history_evidence.merchant_name}</td>
															<td>{link_history_evidence.snapshot_type ? "Yes" : "No"}</td>
															<td className="text-right">
																<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{showAmount(link_history_evidence.total_amount) || 0}</span></Tooltip>}>
																	<span>{showAmount(link_history_evidence.total_amount) || 0}</span>
																</OverlayTrigger>
															</td>
														</tr>
													</tbody>
												</table>

												<h5>Additional Information <hr className='hr-gray-line' /></h5>
												{link_history_evidence.additional_information ?
													<p>{link_history_evidence.additional_information}</p>
													:
													<p>NA</p>
												}

												<h5>Ledger Details</h5>
												{link_history_evidence.vouchers && link_history_evidence.vouchers.map((voucher, index) => {
													return <React.Fragment>
														<div className="ledger-entery-new mb-0">
															<div class="row">
																<div class="col-xl-6 col-lg-6">
																	<p class="mb-0">Voucher Type: <span class="s-sbold blue-text">{this.state.vochers_type[voucher.voucher_type]} (Simple)</span></p>
																</div>
																<div class="col-xl-6 col-lg-6">
																	<p class="mb-0 text-right">Voucher Date: <span class="s-sbold blue-text">{displayDateFormate(voucher.voucher_creation_date) || "NA"}</span></p>
																</div>
															</div>

															<table className="table borderTable mt-2">
																<thead>
																	<tr className="ladger-table">
																		<th width="60px">CR/DR</th>
																		<th className="w-50">Ledger</th>
																		<th className="text-right">Debit</th>
																		<th className="text-right">Credit</th>
																	</tr>
																</thead>

																<tbody>
																	{
																		voucher.ledgers.map((ledger, index) => {
																			return <>
																				<tr>
																					<td>{this.getType(ledger.type)}</td>
																					<td className="w-50"> <span dangerouslySetInnerHTML={{ __html: ledger.name }} /></td>
																					<td className="text-right">
																						<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{(ledger.type === 2 ? showAmount(ledger.amount) : '₹ 0')}</span></Tooltip>}>
																							<span>{(ledger.type === 2 ? showAmount(ledger.amount) : '₹ 0')}</span>
																						</OverlayTrigger>
																					</td>
																					<td className="text-right">
																						<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{(ledger.type === 1 ? showAmount(ledger.amount) : '₹ 0')}</span></Tooltip>}>
																							<span>{(ledger.type === 1 ? showAmount(ledger.amount) : '₹ 0')}</span>
																						</OverlayTrigger>
																					</td>
																				</tr>
																				{ledger.cost_categories.map((category, index) => {
																					return <>
																						<tr>
																							<td className=""></td>
																							<td className="w-50" colSpan={2} style={{ background: '#f3f3f3' }}> <span dangerouslySetInnerHTML={{ __html: category.name }} /></td>
																							<td className="" style={{ background: '#f3f3f3' }}></td>
																						</tr>
																						{category.cost_centres.map((cost_center, index) => {
																							return <tr>
																								<td className=""></td>
																								<td className="w-50" colSpan={2} style={{ background: '#f3f3f3' }}> <span dangerouslySetInnerHTML={{ __html: cost_center.name }} /></td>
																								<td className="text-right" style={{ background: '#f3f3f3' }}>{showAmount(cost_center.amount)}</td>
																							</tr>
																						})
																						}
																					</>
																				})
																				}
																			</>
																		})
																	}

																	<tr>
																		<td colspan="2" className="blue-text text-right">Total (₹)</td>
																		<td className="text-right">
																			<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{showAmount(voucher.total_debit_amount)}</span></Tooltip>}>
																				<span>{showAmount(voucher.total_debit_amount)}</span>
																			</OverlayTrigger>
																		</td>
																		<td className="text-right">
																			<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{showAmount(voucher.total_credit_amount)}</span></Tooltip>}>
																				<span>{showAmount(voucher.total_credit_amount)}</span>
																			</OverlayTrigger>
																		</td>
																	</tr>
																</tbody>
															</table>

															<h5>Accounting Narration <hr className='hr-gray-line' /></h5>
															{voucher.accounting_narration ? <p className='mb-0'>{voucher.accounting_narration}</p> : <p className='mb-0'>NA</p>}
														</div>
													</React.Fragment>
												})}
											</Card.Body>
										</Accordion.Collapse>
									</Card >
								}
							</>

							{this.props.link_history_money_link.map((transaction, index) => {
								return <Card>
									<Accordion.Toggle as={Card.Header} eventKey={index + 1}>
										<span className="label-accordion">
											Money link
										</span>
										<div className="iconbar-accordion">
											{this.getStatus(transaction.status)}
											<IsPersonalIcon value={transaction.is_personal} />
											<span className="icon-arrow-down-sign-to-navigate down-arrow"></span>
										</div>
									</Accordion.Toggle>

									<Accordion.Collapse eventKey={index + 1}>
										<Card.Body class="card-body">
											<table className="table borderTable">
												<thead>
													<tr>
														{/* <th width="120px">Date</th> */}
														<th class="td-date-120">Date</th>
														<th width="180px">Financial Institute</th>
														<th width="">Narration</th>
														<th width="60px">CR/DR</th>
														<th width="150px" className="text-right">Amount</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>{displayDateFormate(transaction.date)}</td>
														<td>{transaction.financial_institute + " " + (transaction.is_credit_card == 0 ? "- Acct" : "- Card") + " XX" + this.lastThreeDigits(transaction.account_number)}</td>
														<td>{transaction.narration}</td>
														<td>{this.getType(transaction.type)}</td>
														<td className="text-right">
															<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{showAmount(transaction.transaction_amount) || 0}</span></Tooltip>}>
																<span>{showAmount(transaction.transaction_amount) || 0}</span>
															</OverlayTrigger>
														</td>
													</tr>
												</tbody>
											</table>

											{/* <div className="main-accounting-narration-sec">
													<div className="top-head bottomborder-light clearfix">
														<p className=" m-0">Business Narration</p>
													</div>
													{transaction.business_narration ? <p className="">{transaction.business_narration}</p> : <p className="">NA</p>}
												</div> */}

											<h5>Ledger Details</h5>
											{transaction.vouchers && transaction.vouchers.map((voucher, index) => {
												return <React.Fragment>
													<div className="ledger-entery-new mb-0">
														<div class="row">
															<div class="col-xl-6 col-lg-6">
																<p class="mb-0">Voucher Type: <span class="s-sbold blue-text">{this.state.vochers_type[voucher.voucher_type]}</span></p>
															</div>
															<div class="col-xl-6 col-lg-6">
																<p class="mb-0 text-right">Voucher Date: <span class="s-sbold blue-text">{displayDateFormate(voucher.voucher_creation_date) || "NA"}</span></p>
															</div>
														</div>

														<table className="table borderTable mt-2">
															<thead>
																<tr className="ladger-table">
																	<th width="60px">CR/DR</th>
																	<th className="w-50">Ledger</th>
																	<th className="text-right">Debit</th>
																	<th className="text-right">Credit</th>
																</tr>
															</thead>

															<tbody>
																{voucher.ledgers.map((ledger, index) => {
																	return <>
																		<tr>
																			<td>{this.getType(ledger.type)}</td>
																			<td className="w-50"><span dangerouslySetInnerHTML={{ __html: ledger.name }} /></td>
																			<td className="text-right">
																				<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{(ledger.type === 2 ? showAmount(ledger.amount) : '₹ 0')}</span></Tooltip>}>
																					<span>{(ledger.type === 2 ? showAmount(ledger.amount) : '₹ 0')}</span>
																				</OverlayTrigger>
																			</td>
																			<td className="text-right">
																				<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{(ledger.type === 1 ? showAmount(ledger.amount) : '₹ 0')}</span></Tooltip>}>
																					<span>{(ledger.type === 1 ? showAmount(ledger.amount) : '₹ 0')}</span>
																				</OverlayTrigger>
																			</td>
																		</tr>

																		{
																			ledger.cost_categories.map((category, index) => {
																				return <>
																					<tr>
																						<td></td>
																						<td className="w-50" colSpan={2}> <span dangerouslySetInnerHTML={{ __html: category.name }} /></td>
																						<td></td>
																					</tr>
																					{
																						category.cost_centres.map((cost_center, index) => {
																							return <tr>
																								<td></td>
																								<td className="w-50" colSpan={2}> <span dangerouslySetInnerHTML={{ __html: cost_center.name }} /></td>
																								<td className="text-right">₹ {cost_center.amount}</td>
																							</tr>
																						})
																					}
																				</>
																			})
																		}

																		{
																			ledger.billwise_allocation.map((allocation, index) => {
																				let types = []

																				types[1] = 'New Ref';
																				types[2] = 'Agst Ref';
																				types[3] = 'Advance';
																				types[4] = 'On Account';

																				let name = ""

																				if (allocation.bill_type == 1 || allocation.bill_type == 2) {
																					name = types[allocation.bill_type] + " - " + allocation.invoice_no + " (₹" + (allocation.amount) + ")";
																				} else {
																					name = types[allocation.bill_type] + " (₹" + (allocation.amount) + ")";
																				}

																				return <>
																					<tr>
																						<td></td>
																						<td className="w-50" colSpan={2}> <span dangerouslySetInnerHTML={{ __html: name }} /></td>
																						<td></td>
																					</tr>
																				</>
																			})
																		}
																	</>
																})}

																<tr>
																	<td colspan="2" className="blue-text text-right">Total (₹)</td>
																	<td className="text-right">
																		<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{showAmount(voucher.total_debit_amount)}</span></Tooltip>}>
																			<span>{showAmount(voucher.total_debit_amount)}</span>
																		</OverlayTrigger>
																	</td>
																	<td className="text-right">
																		<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>{showAmount(voucher.total_credit_amount)}</span></Tooltip>}>
																			<span>{showAmount(voucher.total_credit_amount)}</span>
																		</OverlayTrigger>
																	</td>
																</tr>
															</tbody>
														</table>

														<h5>Accounting Narration <hr className='hr-gray-line' /></h5>
														{voucher.accounting_narration ? <p className='mb-0'>{voucher.accounting_narration}</p> : <p className='mb-0'>NA</p>}
													</div>
												</React.Fragment>
											})}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							})}
						</Accordion>
					</div>
				</div>
			</div>
		)
	}
}

export default LinkHistoryView;