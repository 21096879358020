import React from 'react'
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import LinkedStatus from '../IcoonNew/LinkedStatus';
import NotReadyStatus from '../IcoonNew/NotReadyStatus';
import ProcessedStatus from '../IcoonNew/ProcessedStatus';
import ReadyStatus from '../IcoonNew/ReadyStatus';
import SortingIcon from '../IcoonNew/SortingIcon';
import SyncedStatus from '../IcoonNew/SyncedStatus';
import Filter from '../MoneyLink/Filter';
import FinancialYearFilter from '../MoneyLink/FinancialYear';
import ShowWidgets from '../MoneyLink/ShowWidgets';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { connect } from 'react-redux';
import MessageIcon from '../IcoonNew/Message';
import TaskIcon from '../IcoonNew/Task';
import { displayDateFormate, displayTimeFormat } from '../../Helpers/DateFormat';
import IsPersonalIcon from '../IcoonNew/IsPersonal';
import EvidenceIcon from '../IcoonNew/EvidenceIcon';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import StorageData from "../../Helpers/StorageData";
import { changeDateFormate } from '../../Helpers/DateFormat';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import { showAmount } from '../../Helpers/Currency';
import { checkFileExtension } from '../../Helpers/CheckValidation';
import ImportCostCenter from '../../Components/BoilerPlate/ImportCostCenter';
import ImportLedger from '../../Components/BoilerPlate/ImportLedger';
import ExportVouchers from '../../Components/BoilerPlate/ExportVouchers';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import MasterService from '../../Services/MasterService';
import { Button, Dropdown } from 'react-bootstrap';
import { ArrowUpDownIcon } from '../IcoonNew';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import { decodeHtml } from '../../Helpers/HelperFunction';
// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import { changeDateTimeFormat } from '../../Helpers/DateFormat';
import { SET_BULK_SNAPSHOT_LIST } from '../../Redux/Actions/Types';
import { setBulkSnapshotList } from '../../Redux/Actions/Snapshot';
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import ReactSelect from 'react-select';

// End Intro js user guid 


class SnapshotQuickView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show_gst_toggle : false,
			ledger_list : [],
			ledger_options : [],
			sgst_ledger_guid : "",
			sgst_ledger_name:"",
			sgst_ledger_id:"",
			cgst_ledger_guid:"",
			cgst_ledger_name:"",
			cgst_ledger_id:"",
			igst_ledger_guid:"",
			igst_ledger_name:"",
			igst_ledger_id:"",
			gst_ledger :{},
			is_gst_ledger:this.props.is_gst_ledger ? true : false,
			all_merchant_list:[],
			snapshot_types : [],
			snapshot_list:[],
			sales_purchase_ledger:"",
			party_ledger:"",
			is_gst_ledger_update:0,
			is_dirty:false,
			next_route:false,
			closed:false,
			save_and_redirect:false,
			scrollStep: 100, // Adjust this value as needed
			scrollTop: 0,
			scrollLeft: 0,
			voucherTypeList:[],
			voucher_option:[],
			duplicate_cgst:false,
			duplicate_sgst:false,
			duplicate_igst:false,

		}
		this.scrollRef = React.createRef();
	}

	saveBeforeLeave = (nextRoute) => {
		console.log("next",nextRoute)
		this.setState({ save_and_redirect: true, next_route: nextRoute },  () => {
			if (this.state.is_dirty) {
				 this.saveVoucher();
				this.setState({is_dirty:false},()=>{
					 this.props.history.push(nextRoute)
				})
				
			}
		});
	}
	closeAction = () => {
		this.setState({ closed: false })
	}

			componentDidMount = async () =>{
				if(this.props.bulk_snapshot_list.length == 0){
					this.props.handleQuickEntryTabChange(1);
				}else{
					this.props.dispatch(setAppilcationLoder(true))
					await this.getSnapshotMasters();
				}
			}
			componentWillUnmount = () =>{
				//  this.props.dispatch(setBulkSnapshotList([]))
			}

			getLedgerNameList = async() =>{
				
				await MoneyLinkService.getLedgerNameList().then(data => {
					if (data.response_code == 200) {
						let ledger_options = data.result.ledger_list.map((ledger, index) => {
							return { label: decodeHtml(ledger.name), value: ledger.guid }
						})
						this.setState({
							ledger_list: data.result.ledger_list,
							ledger_options: ledger_options,
						},()=>{
							this.getVoucherTypeList();
						})
					}
				})
			
		}
		getVoucherTypeList = async () =>{
			await MoneyLinkService.getVoucherTypeList().then(data => {
				if(data.response_code == 200){
					let voucher_options = data.result.voucher_types.map((ledger, index) => {
						return { label: decodeHtml(ledger.name), value: ledger.guid , is_master : ledger.is_master , parent_voucher_type_id:ledger.parent_voucher_type_id}
					})
					this.setState({voucherTypeList:data.result.voucher_types,
						voucher_option:voucher_options
					},()=>{
						this.prefillGstLedger();
					})
				}
			})
		}

		getSnapshotMasters = () => {
			SnapshotService.getSnapshotMasters().then((data) => {
				if (data.response_code == 200) {
	
					let merchants = data.result.merchants.map(supp => {
						return {
							value: supp.id, label: supp.name, gst_no: supp.gst_no || "", pan_number: supp.pan_number || "", tds_percentage: supp.tds_percentage, snapshot_type: supp.snapshot_type, is_gst_claim_eligible: supp.is_gst_claim_eligible, is_msme: supp.is_msme, is_perquisite: supp.is_perquisite, type: supp.type, category_id: supp.category_id, tags: supp.tags || [], party_ledger_guid: supp.party_ledger_guid || "", purchase_ledger_guid: supp.purchase_ledger_guid || "", sales_ledger_guid: supp.sales_ledger_guid || "",
							purchase_voucher_type_guid: supp.purchase_voucher_type_guid || "", sales_voucher_type_guid:supp.sales_voucher_type_guid || ""
					
						}
					})
	
					this.setState({
						all_merchant_list: merchants,
						snapshot_types: data.result.snapshot_types,
					},()=>{
						this.getLedgerNameList();
					});
				}
			});
		}

		prefillGstLedger = () =>{
			
				if(this.props.sgst_ledger_guid){
					const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == this.props.sgst_ledger_guid);
						console.log(ledgers)
						if(ledgers != undefined){
							this.setState({sgst_ledger_guid:ledgers.guid,sgst_ledger_name:ledgers.name,sgst_ledger_id:ledgers.id})
						}else{
							this.setState({sgst_ledger_guid:"",sgst_ledger_name:"",sgst_ledger_id:""})
						}
				}else{
					this.setState({sgst_ledger_guid:"",sgst_ledger_name:"",sgst_ledger_id:""})
				}
				if(this.props.cgst_ledger_guid){
					const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == this.props.cgst_ledger_guid);
						console.log(ledgers)
						if(ledgers != undefined){
							this.setState({cgst_ledger_guid:ledgers.guid,cgst_ledger_name:ledgers.name,cgst_ledger_id:ledgers.id})
						}else{
							this.setState({cgst_ledger_guid:"",cgst_ledger_name:"",cgst_ledger_id:""})
						}
						
				}else{
					this.setState({cgst_ledger_guid:"",cgst_ledger_name:"",cgst_ledger_id:""})
				}
				if(this.props.igst_ledger_guid){
					const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == this.props.igst_ledger_guid);
						console.log(ledgers)
						if(ledgers != undefined){
							this.setState({igst_ledger_guid:ledgers.guid,igst_ledger_name:ledgers.name,igst_ledger_id:ledgers.id})
						}else{
							this.setState({igst_ledger_guid:"",igst_ledger_name:"",igst_ledger_id:""})
						}
						
				}else{
					this.setState({igst_ledger_guid:"",igst_ledger_name:"",igst_ledger_id:""})
				}	
			
			this.setState({is_gst_ledger:this.props.is_gst_ledger,show_gst_toggle:true},()=> {
				this.duplicateGstLedger();
				if(this.props.bulk_snapshot_list.length > 0){
					 this.preFillSnapshotData();
					 this.props.dispatch(setAppilcationLoder(false))
					console.log("inside prefilling")
				}
			})
			
		}

			handleGstLedger = (e) =>{
				
				if(e.target.name == 'select_sgst_ledger'){
					if(e.target.value !== ""){
						const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == e.target.value);
						console.log(ledgers)
							if(ledgers != undefined){
								this.setState({sgst_ledger_guid:ledgers.guid,sgst_ledger_name:ledgers.name,sgst_ledger_id:ledgers.id})
							}else{
								this.setState({sgst_ledger_guid:"",sgst_ledger_name:"",sgst_ledger_id:""})
							}
							this.gstuniqueGuid(e.target.value,'select_sgst_ledger');
					}else{
						this.setState({sgst_ledger_guid:"",sgst_ledger_name:"",sgst_ledger_id:"",duplicate_sgst:false})
					}	
					
				}
				if(e.target.name == 'select_cgst_ledger'){
					if(e.target.value !== ""){
						const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == e.target.value);
						console.log(ledgers)
							if(ledgers != undefined){
								this.setState({cgst_ledger_guid:ledgers.guid,cgst_ledger_name:ledgers.name,cgst_ledger_id:ledgers.id})
							}else{
								this.setState({cgst_ledger_guid:"",cgst_ledger_name:"",cgst_ledger_id:""})
							}
							this.gstuniqueGuid(e.target.value,'select_cgst_ledger');
					}else{
						this.setState({cgst_ledger_guid:"",cgst_ledger_name:"",cgst_ledger_id:"",duplicate_cgst:false})
					}
					
				}
				if(e.target.name == 'select_igst_ledger'){
					if(e.target.value !== ""){
						const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == e.target.value);
						console.log(ledgers)
							if(ledgers != undefined){
								this.setState({igst_ledger_guid:ledgers.guid,igst_ledger_name:ledgers.name,igst_ledger_id:ledgers.id})
							}else{
								this.setState({igst_ledger_guid:"",igst_ledger_name:"",igst_ledger_id:""})
							}
							this.gstuniqueGuid(e.target.value,'select_igst_ledger');
					}else{
						this.setState({igst_ledger_guid:"",igst_ledger_name:"",igst_ledger_id:"",duplicate_igst:false})
					}
					
				}

				this.setState({is_gst_ledger_update : 1})
			}

			saveGstLedger = () =>{
				let snapshot_list = [...this.state.snapshot_list];
				let gst_ledger = {};
				gst_ledger.sgst_ledger_guid = this.state.sgst_ledger_guid;
				gst_ledger.sgst_ledger_name = this.state.sgst_ledger_name;
				gst_ledger.sgst_ledger_id = this.state.sgst_ledger_id
				gst_ledger.cgst_ledger_guid = this.state.cgst_ledger_guid;
				gst_ledger.cgst_ledger_name = this.state.cgst_ledger_name;
				gst_ledger.cgst_ledger_id = this.state.cgst_ledger_id
				gst_ledger.igst_ledger_guid = this.state.igst_ledger_guid;
				gst_ledger.igst_ledger_name = this.state.igst_ledger_name;
				gst_ledger.igst_ledger_id = this.state.igst_ledger_id;

				gst_ledger.is_gst_ledger = this.state.is_gst_ledger


				snapshot_list.map((item)=>{
					item.duplicate_ledger = this.duplicateLedgerIndexFoundSaveGst(item.party_ledger_guid,item.purchase_sales_ledger_guid,item.sgst_amount,item.cgst_amount,item.igst_amount)
					return item;
				})
				console.log("snapshot list",snapshot_list)
				this.setState({gst_ledger,show_gst_toggle:!this.state.show_gst_toggle,snapshot_list})

				console.log("ledger gst",gst_ledger)
			}

			toggleImportExcelPopup = () =>{
				this.setState({show_gst_toggle : !this.state.show_gst_toggle})
			}

			preFillSnapshotData = ()=>{

				// let snapshot_list = [...this.state.snapshot_list];				
				let snapshot_list = [...this.props.bulk_snapshot_list]
				const newData = snapshot_list.map((item,index)=>{
					  item.uploaded = false;
					  item.merchant_update = 0;
					console.log("item",item)
					const snapshot_type = this.state.snapshot_types.find((snaptype)=> snaptype.id == item.snapshot_type_id);
					console.log("snapshot types",snapshot_type)
					const expense = snapshot_type.expense;
							let voucher_type = expense ? 6 : 5;
							item.voucher_type = expense ? 6 : 5;
					const basic_amount = (item.total_amount - (((item.sgst_amount || 0) + (item.cgst_amount || 0)) || (item.igst_amount || 0)));
						item.basic_amount = basic_amount.toFixed(2);
					console.log("basic amount",basic_amount)
					console.log("snapshot type",item.voucher_type)
					const merchant_data = this.state.all_merchant_list.find((merchant,index)=> merchant.value == item.merchant_id);
					console.log("merchant data",merchant_data)
					console.log("snapshot type",this.state.snapshot_types)
					if(merchant_data !== undefined){
						item.merchant_id = merchant_data.value;
						item.name = merchant_data.label;
						item.party_gst_in = merchant_data.gst_no;
						const party_ledger = this.state.ledger_list.find((ledger,index)=> ledger.guid == merchant_data.party_ledger_guid)
						console.log("party",party_ledger)
							if(party_ledger !== undefined){
								item.party_ledger_guid = party_ledger.guid;
								item.party_ledger_name = party_ledger.name;
								item.party_ledger_id = party_ledger.id
							}else{
								item.party_ledger_guid = "";
								item.party_ledger_name = "";
								item.party_ledger_id = ""
							}
						
							
						if(voucher_type == 5){	
							if(merchant_data.sales_voucher_type_guid == "" || merchant_data.sales_voucher_type_guid == null){
								const voucher_type_details = this.state.voucherTypeList.filter(voucher => (voucher.parent_voucher_type_id == 5) && (voucher.name == 'Sales') && voucher.is_master == 1)
								if(voucher_type_details.length > 0){
									item.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id
									item.voucher_type_guid = voucher_type_details[0].guid
									item.voucher_type_name = voucher_type_details[0].name
									item.voucher_type_id =voucher_type_details[0].id
								}
							}
							else{
								const voucher_type_details = this.state.voucherTypeList.filter(voucher => (voucher.parent_voucher_type_id == 5) && (voucher.guid == merchant_data.sales_voucher_type_guid))
								if(voucher_type_details.length > 0){
									item.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id
									item.voucher_type_guid = voucher_type_details[0].guid
									item.voucher_type_name = voucher_type_details[0].name
									item.voucher_type_id =voucher_type_details[0].id
								}else{
									const voucher_type_details = this.state.voucherTypeList.filter(voucher => (voucher.parent_voucher_type_id == 5) && (voucher.name == 'Sales') && voucher.is_master == 1)
									if(voucher_type_details.length > 0){
										item.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id
										item.voucher_type_guid = voucher_type_details[0].guid
										item.voucher_type_name = voucher_type_details[0].name
										item.voucher_type_id =voucher_type_details[0].id
									}else{
										item.parent_voucher_type_id = 5
										item.voucher_type_guid = ""
										item.voucher_type_name = ""
										item.voucher_type_id = ""
									}
								}
							}
											
							const purchase_sales_ledger = this.state.ledger_list.find((ledger,index)=> ledger.guid ==  merchant_data.sales_ledger_guid)
							console.log("purchase sales",purchase_sales_ledger)
							if(purchase_sales_ledger !== undefined){
								item.purchase_sales_ledger_guid = purchase_sales_ledger.guid;
								item.purchase_sales_ledger_name = purchase_sales_ledger.name;
								item.purchase_sales_ledger_id = purchase_sales_ledger.id;
							}else{
								item.purchase_sales_ledger_guid = "";
								item.purchase_sales_ledger_name = "";
								item.purchase_sales_ledger_id = "";
							}
							
						}
						if(voucher_type == 6){
							if(merchant_data.purchase_voucher_type_guid == "" || merchant_data.purchase_voucher_type_guid == null){
								const voucher_type_details = this.state.voucherTypeList.filter(voucher => (voucher.parent_voucher_type_id == 6) && (voucher.name == 'Purchase') && voucher.is_master == 1)
								if(voucher_type_details.length > 0){
									item.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id
									item.voucher_type_guid = voucher_type_details[0].guid
									item.voucher_type_name = voucher_type_details[0].name
									item.voucher_type_id =voucher_type_details[0].id
								}
							}
							else{
								console.log("hereer")
								const voucher_type_details = this.state.voucherTypeList.filter(voucher => (voucher.parent_voucher_type_id == 6) && (voucher.guid == merchant_data.purchase_voucher_type_guid))
								if(voucher_type_details.length > 0){
									item.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id
									item.voucher_type_guid = voucher_type_details[0].guid
									item.voucher_type_name = voucher_type_details[0].name
									item.voucher_type_id =voucher_type_details[0].id
								}else{
									const voucher_type_details = this.state.voucherTypeList.filter(voucher => (voucher.parent_voucher_type_id == 6) && (voucher.name == 'Purchase') && voucher.is_master == 1)
									if(voucher_type_details.length > 0){
										item.parent_voucher_type_id = voucher_type_details[0].parent_voucher_type_id
										item.voucher_type_guid = voucher_type_details[0].guid
										item.voucher_type_name = voucher_type_details[0].name
										item.voucher_type_id =voucher_type_details[0].id
									}else{
										item.parent_voucher_type_id = 6
										item.voucher_type_guid = ""
										item.voucher_type_name = ""
										item.voucher_type_id = ""
									}
								}
							}
							const purchase_sales_ledger = this.state.ledger_list.find((ledger,index)=> ledger.guid ==  merchant_data.purchase_ledger_guid)
							console.log("purchase sales",purchase_sales_ledger)
							if(purchase_sales_ledger !== undefined){
								item.purchase_sales_ledger_guid = purchase_sales_ledger.guid;
								item.purchase_sales_ledger_name = purchase_sales_ledger.name;
								item.purchase_sales_ledger_id = purchase_sales_ledger.id;
							}else{
								item.purchase_sales_ledger_guid = "";
								item.purchase_sales_ledger_name = "";
								item.purchase_sales_ledger_id = "";
							}
						}
							
						
					}else{
								item.merchant_id = "";
								item.name = "";
								item.party_gst_in = "";
								item.party_ledger_guid = "";
								item.party_ledger_name = "";
								item.party_ledger_id = "";
								item.purchase_sales_ledger_guid = "";
								item.purchase_sales_ledger_name = "";
								item.purchase_sales_ledger_id = "";
					}

					
					item.duplicate_ledger = this.duplicateLedgerIndexFound(item.party_ledger_guid,item.purchase_sales_ledger_guid,item.sgst_amount,item.cgst_amount,item.igst_amount)
					console.log("item list",item)
					return item;
					
				})

				this.setState({snapshot_list:newData})
			}
			handleVoucherChnage = (e,index) =>{
				let snapshot_list = [...this.state.snapshot_list];
				if(e.target.name == 'select_voucher_guid'){
					if(e.target.value !== ""){
						let voucher_details = this.state.voucherTypeList.filter(voucher => voucher.guid == e.target.value)
						if(voucher_details.length > 0){
							snapshot_list[index].parent_voucher_type_id = voucher_details[0].parent_voucher_type_id
							snapshot_list[index].voucher_type_guid = voucher_details[0].guid
							snapshot_list[index].voucher_type_id =  voucher_details[0].id
							snapshot_list[index].voucher_type_name = voucher_details[0].name
							snapshot_list[index].merchant_update = 1
						}
					}
				}
				this.setState({snapshot_list,is_dirty:true})
			}
			newHandleVoucherChange = (e,name,index) =>{
				let snapshot_list = [...this.state.snapshot_list];
				if(name == 'select_voucher_guid'){
					if(e.value !== ""){
						let voucher_details = this.state.voucherTypeList.filter(voucher => voucher.guid == e.value)
						if(voucher_details.length > 0){
							snapshot_list[index].parent_voucher_type_id = voucher_details[0].parent_voucher_type_id
							snapshot_list[index].voucher_type_guid = voucher_details[0].guid
							snapshot_list[index].voucher_type_id =  voucher_details[0].id
							snapshot_list[index].voucher_type_name = voucher_details[0].name
							snapshot_list[index].merchant_update = 1


							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.parent_voucher_type_id = voucher_details[0].parent_voucher_type_id
								item.voucher_type_guid = voucher_details[0].guid
								item.voucher_type_id = voucher_details[0].id
								item.voucher_type_name = voucher_details[0].name
								item.merchant_update = 1
		
								return item;
							})
						}else{
							let voucher = []
							if(snapshot_list[index].parent_voucher_type_id == 5){
								voucher = this.state.voucherTypeList.filter(voucher => voucher.name == "Sales" && voucher.is_master == 1)
							}
							if(snapshot_list[index].parent_voucher_type_id == 6){
								voucher = this.state.voucherTypeList.filter(voucher => voucher.name == "Purchase" && voucher.is_master == 1)
							}
							
							if(voucher.length > 0){
								snapshot_list[index].parent_voucher_type_id = voucher[0].parent_voucher_type_id
								snapshot_list[index].voucher_type_guid = voucher[0].guid
								snapshot_list[index].voucher_type_id =  voucher[0].id
								snapshot_list[index].voucher_type_name = voucher[0].name
								snapshot_list[index].merchant_update = 1


								snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
									item.parent_voucher_type_id = voucher[0].parent_voucher_type_id
									item.voucher_type_guid = voucher[0].guid
									item.voucher_type_id = voucher[0].id
									item.voucher_type_name = voucher[0].name
									item.merchant_update = 1
			
									return item;
								})
							}
						}
						
					}
				}
				this.setState({snapshot_list,is_dirty:true})
			}

			updateLedgerLineData = (e,index) => {
				let snapshot_list = [...this.state.snapshot_list];
				console.log("update",e.target.name,e.target.value,index)
				if(e.target.name == "party_ledger"){
					if(e.target.value !== ""){
							const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == e.target.value);
							console.log("updateline",ledgers)
							if(ledgers !== undefined){
								snapshot_list[index].party_ledger_guid = ledgers.guid;
								snapshot_list[index].party_ledger_name = ledgers.name;
								snapshot_list[index].party_ledger_id = ledgers.id;
								snapshot_list[index].merchant_update = 1;
								snapshot_list[index].duplicate_ledger = this.duplicateLedgerIndexFound(ledgers.guid,snapshot_list[index].purchase_sales_ledger_guid,snapshot_list[index].sgst_amount,snapshot_list[index].cgst_amount,snapshot_list[index].igst_amount)
							}else{
								snapshot_list[index].party_ledger_guid = "";
								snapshot_list[index].party_ledger_name = "";
								snapshot_list[index].party_ledger_id = "";
								snapshot_list[index].merchant_update = 1;
							}
		
							// const data = snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id)
							// console.log("data",data)
		
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.party_ledger_guid = ledgers.guid ? ledgers.guid : "";
								item.party_ledger_name = ledgers.name ? ledgers.name : "";
								item.party_ledger_id = ledgers.id ? ledgers.id : "";
								item.merchant_update = 1
		
								return item;
							})
					}else{
							snapshot_list[index].party_ledger_guid = "";
							snapshot_list[index].party_ledger_name = "";
							snapshot_list[index].party_ledger_id = "";
							snapshot_list[index].merchant_update = 1
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.party_ledger_guid = "";
								item.party_ledger_name = "";
								item.party_ledger_id = "";
								item.merchant_update = 1;
		
								return item;
							})
					}	
				}
				if(e.target.name == "sales_purchase_ledger"){
					if(e.target.value !== ""){
							const ledgers = this.state.ledger_list.find((ledger,index)=> ledger.guid == e.target.value);
							console.log("updateline",ledgers)
							if(ledgers !== undefined){
								snapshot_list[index].purchase_sales_ledger_guid = ledgers.guid;
								snapshot_list[index].purchase_sales_ledger_name = ledgers.name;
								snapshot_list[index].purchase_sales_ledger_id = ledgers.id;
								snapshot_list[index].merchant_update = 1;
								snapshot_list[index].duplicate_ledger = this.duplicateLedgerIndexFound(snapshot_list[index].party_ledger_guid,ledgers.guid,snapshot_list[index].sgst_amount,snapshot_list[index].cgst_amount,snapshot_list[index].igst_amount)

							}else{
								snapshot_list[index].purchase_sales_ledger_guid = "";
								snapshot_list[index].purchase_sales_ledger_name = "";
								snapshot_list[index].purchase_sales_ledger_id= "";
								snapshot_list[index].merchant_update = 1;
							}
		
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.purchase_sales_ledger_guid = ledgers.guid ? ledgers.guid : "";
								item.purchase_sales_ledger_name = ledgers.name ? ledgers.name : "";
								item.purchase_sales_ledger_id = ledgers.id ? ledgers.id : "";
								item.merchant_update = 1;
		
								return item;
							})
					}else{
							snapshot_list[index].purchase_sales_ledger_guid = "";
							snapshot_list[index].purchase_sales_ledger_name = "";
							snapshot_list[index].purchase_sales_ledger_id= "";
							snapshot_list[index].merchant_update = 1;
		
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.purchase_sales_ledger_guid = "";
								item.purchase_sales_ledger_name = "";
								item.purchase_sales_ledger_id = "";
								item.merchant_update = 1;
		
								return item;
							})
					}	
				}

				this.setState({snapshot_list,is_dirty:true})
			}

			newUpdateLedgerLineData = (e,name,index)=>{
				console.log("value",e.value,index,name)
				let snapshot_list = [...this.state.snapshot_list];
				if(name == "party_ledger"){
					if(e.value !== ""){
							const ledgers = this.state.ledger_list.find((ledger)=> ledger.guid == e.value);
							console.log("updateline",ledgers)
							if(ledgers !== undefined){
								snapshot_list[index].party_ledger_guid = ledgers.guid;
								snapshot_list[index].party_ledger_name = ledgers.name;
								snapshot_list[index].party_ledger_id = ledgers.id;
								snapshot_list[index].merchant_update = 1;
								snapshot_list[index].duplicate_ledger = this.duplicateLedgerIndexFound(e.value,snapshot_list[index].purchase_sales_ledger_guid,snapshot_list[index].sgst_amount,snapshot_list[index].cgst_amount,snapshot_list[index].igst_amount)
								console.log("::duplicate",this.duplicateLedgerIndexFound(ledgers.guid,snapshot_list[index].purchase_sales_ledger_guid,snapshot_list[index].sgst_amount,snapshot_list[index].cgst_amount,snapshot_list[index].igst_amount))
							}else{
								snapshot_list[index].party_ledger_guid = "";
								snapshot_list[index].party_ledger_name = "";
								snapshot_list[index].party_ledger_id = "";
								snapshot_list[index].merchant_update = 1;
							}
		
							// const data = snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id)
							// console.log("data",data)
		
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.party_ledger_guid = ledgers.guid ? ledgers.guid : "";
								item.party_ledger_name = ledgers.name ? ledgers.name : "";
								item.party_ledger_id = ledgers.id ? ledgers.id : "";
								item.merchant_update = 1
								item.duplicate_ledger = this.duplicateLedgerIndexFound(ledgers.guid,item.purchase_sales_ledger_guid,item.sgst_amount,item.cgst_amount,item.igst_amount)
		
								return item;
							})
					}else{
							snapshot_list[index].party_ledger_guid = "";
							snapshot_list[index].party_ledger_name = "";
							snapshot_list[index].party_ledger_id = "";
							snapshot_list[index].merchant_update = 1
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.party_ledger_guid = "";
								item.party_ledger_name = "";
								item.party_ledger_id = "";
								item.merchant_update = 1;
								item.duplicate_ledger = this.duplicateLedgerIndexFound("",item.purchase_sales_ledger_guid,item.sgst_amount,item.cgst_amount,item.igst_amount)
		
								return item;
							})
					}	
				}
				if(name == "sales_purchase_ledger"){
					if(e.value !== ""){
							const ledgers = this.state.ledger_list.find((ledger)=> ledger.guid == e.value);
							console.log("updateline",ledgers)
							if(ledgers !== undefined){
								snapshot_list[index].purchase_sales_ledger_guid = ledgers.guid;
								snapshot_list[index].purchase_sales_ledger_name = ledgers.name;
								snapshot_list[index].purchase_sales_ledger_id = ledgers.id;
								snapshot_list[index].merchant_update = 1;
								snapshot_list[index].duplicate_ledger = this.duplicateLedgerIndexFound(snapshot_list[index].party_ledger_guid,e.value,snapshot_list[index].sgst_amount,snapshot_list[index].cgst_amount,snapshot_list[index].igst_amount)
							}else{
								snapshot_list[index].purchase_sales_ledger_guid = "";
								snapshot_list[index].purchase_sales_ledger_name = "";
								snapshot_list[index].purchase_sales_ledger_id= "";
								snapshot_list[index].merchant_update = 1;
							}
		
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.purchase_sales_ledger_guid = ledgers.guid ? ledgers.guid : "";
								item.purchase_sales_ledger_name = ledgers.name ? ledgers.name : "";
								item.purchase_sales_ledger_id = ledgers.id ? ledgers.id : "";
								item.duplicate_ledger = this.duplicateLedgerIndexFound(item.party_ledger_guid,ledgers.guid,item.sgst_amount,item.cgst_amount,item.igst_amount)
								item.merchant_update = 1;
		
								return item;
							})
					}else{
							snapshot_list[index].purchase_sales_ledger_guid = "";
							snapshot_list[index].purchase_sales_ledger_name = "";
							snapshot_list[index].purchase_sales_ledger_id= "";
							snapshot_list[index].merchant_update = 1;
		
							snapshot_list.filter((item)=> item.merchant_id == snapshot_list[index].merchant_id).map((item)=>{
								item.purchase_sales_ledger_guid = "";
								item.purchase_sales_ledger_name = "";
								item.purchase_sales_ledger_id = "";
								item.merchant_update = 1;
								item.duplicate_ledger = this.duplicateLedgerIndexFound(item.party_ledger_guid,"",item.sgst_amount,item.cgst_amount,item.igst_amount)
		
								return item;
							})
					}	
				}

				this.setState({snapshot_list,is_dirty:true})
			}


			isValidRecord = (record) =>{
				let ledger_duplicate = true;

				if(((record.sgst_amount !== "" && record.sgst_amount !== 0 && record.sgst_amount !== null)&& (this.state.gst_ledger.sgst_ledger_guid !== null && this.state.gst_ledger.sgst_ledger_guid !== "")) && ((record.cgst_amount !== "" && record.cgst_amount !== 0 && record.cgst_amount !== null)&& (this.state.gst_ledger.cgst_ledger_guid !== null && this.state.gst_ledger.cgst_ledger_guid !== ""))){
									if(this.state.gst_ledger.sgst_ledger_guid != this.state.gst_ledger.cgst_ledger_guid){
										ledger_duplicate =  true;
									}else{
										ledger_duplicate  = false;
									}
								}
				return record.party_ledger_guid && record.party_ledger_name && record.party_ledger_id && 
					   record.purchase_sales_ledger_guid && record.purchase_sales_ledger_name && record.purchase_sales_ledger_id
					   && record.total_amount && record.voucher_type_guid && (record.duplicate_ledger == false) && (ledger_duplicate == true)

			}
			checkGstValidRecord = (record) =>{
				if(((record.sgst_amount !== "" && record.sgst_amount !== 0 && record.sgst_amount !== null)&& (this.state.gst_ledger.sgst_ledger_guid !== null && this.state.gst_ledger.sgst_ledger_guid !== "")) && ((record.cgst_amount !== "" && record.cgst_amount !== 0 && record.cgst_amount !== null)&& (this.state.gst_ledger.cgst_ledger_guid !== null && this.state.gst_ledger.cgst_ledger_guid !== ""))){
					if(this.state.gst_ledger.sgst_ledger_guid != this.state.gst_ledger.cgst_ledger_guid){
						return true;
					}else{
						return false;
					}
				}

				return true;
			}
			createEntries = async() =>{
				let snapshot_list = [...this.state.snapshot_list];
				let remaining_id = [];
				let voucher = [];
				this.props.dispatch(setAppilcationLoder(true));
				let request_data = {
					"gst_ledger": {
								"sgst_ledger_guid": this.state.gst_ledger.sgst_ledger_guid,
								"sgst_ledger_name": this.state.gst_ledger.sgst_ledger_name,
								"cgst_ledger_guid": this.state.gst_ledger.cgst_ledger_guid,
								"cgst_ledger_name": this.state.gst_ledger.cgst_ledger_name,
								"igst_ledger_guid": this.state.gst_ledger.igst_ledger_guid,
								"igst_ledger_name": this.state.gst_ledger.igst_ledger_name,
								"is_gst_ledger_update":this.state.is_gst_ledger_update,
								"is_gst_ledger":this.state.is_gst_ledger ? 1 : 0,
							},
				}
				for(let i = 0 ; i < snapshot_list.length ; ++i){
					if(this.isValidRecord(snapshot_list[i])){
						let gst_ledgers = [];
						if(((this.state.gst_ledger.sgst_ledger_guid !== null && this.state.gst_ledger.sgst_ledger_guid !== "") && (snapshot_list[i].sgst_amount !== null && snapshot_list[i].sgst_amount !== 0 && snapshot_list[i].sgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 5) && 
						((this.state.gst_ledger.cgst_ledger_guid !== null && this.state.gst_ledger.cgst_ledger_guid !== "") && (snapshot_list[i].cgst_amount !== null && snapshot_list[i].cgst_amount !== 0 && snapshot_list[i].cgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 5) && this.state.is_gst_ledger){
							console.log(snapshot_list[i].sgst_amount)
							gst_ledgers = [{
										"amount": snapshot_list[i].sgst_amount,
										"guid": this.state.gst_ledger.sgst_ledger_guid,
										"name": this.state.gst_ledger.sgst_ledger_name,
										"type": 1,
										"ledger_id": this.state.gst_ledger.sgst_ledger_id,
										"cost_categories": []
							},{
									"amount": snapshot_list[i].cgst_amount,
									"guid": this.state.gst_ledger.cgst_ledger_guid,
									"name": this.state.gst_ledger.cgst_ledger_name,
									"type": 1,
									"ledger_id": this.state.gst_ledger.cgst_ledger_id,
									"cost_categories": []
							}]

						}
						if(((this.state.gst_ledger.sgst_ledger_guid !== null && this.state.gst_ledger.sgst_ledger_guid !== "") && (snapshot_list[i].sgst_amount !== null && snapshot_list[i].sgst_amount !== 0 && snapshot_list[i].sgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 6) && 
						((this.state.gst_ledger.cgst_ledger_guid !== null && this.state.gst_ledger.cgst_ledger_guid !== "") && (snapshot_list[i].cgst_amount !== null && snapshot_list[i].cgst_amount !== 0 && snapshot_list[i].cgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 6) && this.state.is_gst_ledger){
							console.log(snapshot_list[i].sgst_amount)
							gst_ledgers = [{
										"amount": snapshot_list[i].sgst_amount,
										"guid": this.state.gst_ledger.sgst_ledger_guid,
										"name": this.state.gst_ledger.sgst_ledger_name,
										"type": 2,
										"ledger_id": this.state.gst_ledger.sgst_ledger_id,
										"cost_categories": []
							},{
									"amount": snapshot_list[i].cgst_amount,
									"guid": this.state.gst_ledger.cgst_ledger_guid,
									"name": this.state.gst_ledger.cgst_ledger_name,
									"type": 2,
									"ledger_id": this.state.gst_ledger.cgst_ledger_id,
									"cost_categories": []
							}]

						}
						if(((this.state.gst_ledger.igst_ledger_guid !== null && this.state.gst_ledger.igst_ledger_guid !== "") && (snapshot_list[i].igst_amount !== null && snapshot_list[i].igst_amount !== 0 && snapshot_list[i].igst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 5) && this.state.is_gst_ledger){
							gst_ledgers = [{
										"amount": snapshot_list[i].igst_amount,
										"guid": this.state.gst_ledger.igst_ledger_guid,
										"name": this.state.gst_ledger.igst_ledger_name,
										"type": 1,
										"ledger_id": this.state.gst_ledger.igst_ledger_id,
										"cost_categories": []
										}]
						}
						if(((this.state.gst_ledger.igst_ledger_guid !== null && this.state.gst_ledger.igst_ledger_guid !== "") && (snapshot_list[i].igst_amount !== null && snapshot_list[i].igst_amount !== 0 && snapshot_list[i].igst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 6) && this.state.is_gst_ledger){
							gst_ledgers = [{
								"amount": snapshot_list[i].igst_amount,
								"guid": this.state.gst_ledger.igst_ledger_guid,
								"name": this.state.gst_ledger.igst_ledger_name,
								"type": 2,
								"ledger_id": this.state.gst_ledger.igst_ledger_id,
								"cost_categories": []
								}]
						}
						
						 console.log("data",gst_ledgers.length)
						let main_ledgeres = snapshot_list[i].parent_voucher_type_id == 5 ? [
								{
									"amount":snapshot_list[i].total_amount,
									"guid": snapshot_list[i].party_ledger_guid,
									"name": snapshot_list[i].party_ledger_name,
									"type": 2,
									"ledger_id": snapshot_list[i].party_ledger_id,
									"cost_categories": []
								},
								{
									"amount": gst_ledgers.length > 0 ? snapshot_list[i].basic_amount : snapshot_list[i].total_amount,
									"guid": snapshot_list[i].purchase_sales_ledger_guid,
									"name": snapshot_list[i].purchase_sales_ledger_name,
									"type": 1,
									"ledger_id": snapshot_list[i].purchase_sales_ledger_id,
									"cost_categories": []
								}
							] : [
								{
									"amount": snapshot_list[i].total_amount,
									"guid": snapshot_list[i].party_ledger_guid,
									"name": snapshot_list[i].party_ledger_name,
									"type": 1,
									"ledger_id": snapshot_list[i].party_ledger_id,
									"cost_categories": []
								},
								{
									"amount": gst_ledgers.length > 0 ? snapshot_list[i].basic_amount : snapshot_list[i].total_amount,
									"guid": snapshot_list[i].purchase_sales_ledger_guid,
									"name": snapshot_list[i].purchase_sales_ledger_name,
									"type": 2,
									"ledger_id": snapshot_list[i].purchase_sales_ledger_id,
									"cost_categories": []
								}
							]
							main_ledgeres = [...main_ledgeres,...gst_ledgers]
							console.log("main",main_ledgeres)
							let vouher_array = {
										"accounting_narration": `Invoice#: ${snapshot_list[i].invoice_no} Date: ${snapshot_list[i].date} | Quick Ledger Entry`,
										"parent_voucher_type_id":snapshot_list[i].parent_voucher_type_id,
										"voucher_type_guid":snapshot_list[i].voucher_type_guid,
										"voucher_type_name":snapshot_list[i].voucher_type_name,
										"voucher_type_id":snapshot_list[i].voucher_type_id,
										"voucher_creation_date": snapshot_list[i].date,
										"total_credit_amount":snapshot_list[i].total_amount,
										"total_debit_amount":snapshot_list[i].total_amount,
										"sync_datetime": changeDateTimeFormat(new Date()),
										"sync_status":0,
										"ledgers":main_ledgeres,
										"snapshot_id":snapshot_list[i].id,
										"merchant_id":snapshot_list[i].merchant_id,
										"merchant_update":snapshot_list[i].merchant_update,
										"common_ledger_guid":snapshot_list[i].purchase_sales_ledger_guid,
										"party_ledger_guid" : snapshot_list[i].party_ledger_guid
							}

							const merchant_data = this.state.all_merchant_list.find((merchant,index)=> merchant.value == snapshot_list[i].merchant_id);
							if(vouher_array.voucher_type_guid != (vouher_array.parent_voucher_type_id == 5 ? merchant_data.sales_voucher_type_guid : merchant_data.purchase_voucher_type_guid)){
								vouher_array.merchant_update = 1
								console.log("here in update")
							}
							voucher.push(vouher_array);

						console.log("validate",voucher)
						remaining_id.push(snapshot_list[i].id)
						
					}else{
						// console.log("unvalidated",snapshot_list[i])
						if(snapshot_list[i].total_amount == null || snapshot_list[i].total_amount == "" || snapshot_list[i].total_amount == 0){
							console.log("amount is null",snapshot_list[i])
						}
					}
				}
				request_data.vouchers = voucher;
				console.log("request data",request_data)		
				if(request_data.vouchers.length > 0){
					SnapshotService.createSnapshotEntries(request_data).then( async(resp_data) => {
						this.props.dispatch(setAppilcationLoder(false));
						if (resp_data.response_code == 200) {
							toast.success('Snapshot ledger entries saved successfully');

							const idsToRemove = new Set(remaining_id.map(item => item));
   				 			// Filter the main array to exclude items with ids present in idsToRemove
							const data =  snapshot_list.filter(item => !idsToRemove.has(item.id));
							console.log("remaining id",data)
							this.setState({snapshot_list:data})
							this.props.dispatch(setBulkSnapshotList(data))
							this.setState({is_dirty:false})
							if(data.length == 0){
								console.log("zeroooooooooooo");
								await this.props.refreshItem();
								setTimeout(() => {
									this.props.handleQuickEntryTabChange(1);
								}, 1000);

							}
							
						} else {
							toast.error(resp_data.message);
						}
					})
					
				
				}else{
					this.setState({save_and_redirect: false,next_route: false})
					
				
					this.props.dispatch(setAppilcationLoder(false))
				}
					

				
			}

			saveVoucher = async () =>{

				let snapshot_list = [...this.state.snapshot_list];
				let remaining_id = [];
				let voucher = [];
				this.props.dispatch(setAppilcationLoder(true));
				let request_data = {
					"gst_ledger": {
								"sgst_ledger_guid": this.state.gst_ledger.sgst_ledger_guid,
								"sgst_ledger_name": this.state.gst_ledger.sgst_ledger_name,
								"cgst_ledger_guid": this.state.gst_ledger.cgst_ledger_guid,
								"cgst_ledger_name": this.state.gst_ledger.cgst_ledger_name,
								"igst_ledger_guid": this.state.gst_ledger.igst_ledger_guid,
								"igst_ledger_name": this.state.gst_ledger.igst_ledger_name,
								"is_gst_ledger_update":this.state.is_gst_ledger_update,
								"is_gst_ledger":this.state.is_gst_ledger ? 1 : 0,
							},
				}
				for(let i = 0 ; i < snapshot_list.length ; ++i){
					if(this.isValidRecord(snapshot_list[i])){
						let gst_ledgers = [];
						if(((this.state.gst_ledger.sgst_ledger_guid !== null && this.state.gst_ledger.sgst_ledger_guid !== "") && (snapshot_list[i].sgst_amount !== null && snapshot_list[i].sgst_amount !== 0 && snapshot_list[i].sgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 5) && 
						((this.state.gst_ledger.cgst_ledger_guid !== null && this.state.gst_ledger.cgst_ledger_guid !== "") && (snapshot_list[i].cgst_amount !== null && snapshot_list[i].cgst_amount !== 0 && snapshot_list[i].cgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 5) && this.state.is_gst_ledger){
							console.log(snapshot_list[i].sgst_amount)
							gst_ledgers = [{
										"amount": snapshot_list[i].sgst_amount,
										"guid": this.state.gst_ledger.sgst_ledger_guid,
										"name": this.state.gst_ledger.sgst_ledger_name,
										"type": 1,
										"ledger_id": this.state.gst_ledger.sgst_ledger_id,
										"cost_categories": []
							},{
									"amount": snapshot_list[i].cgst_amount,
									"guid": this.state.gst_ledger.cgst_ledger_guid,
									"name": this.state.gst_ledger.cgst_ledger_name,
									"type": 1,
									"ledger_id": this.state.gst_ledger.cgst_ledger_id,
									"cost_categories": []
							}]

						}
						if(((this.state.gst_ledger.sgst_ledger_guid !== null && this.state.gst_ledger.sgst_ledger_guid !== "") && (snapshot_list[i].sgst_amount !== null && snapshot_list[i].sgst_amount !== 0 && snapshot_list[i].sgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 6) && 
						((this.state.gst_ledger.cgst_ledger_guid !== null && this.state.gst_ledger.cgst_ledger_guid !== "") && (snapshot_list[i].cgst_amount !== null && snapshot_list[i].cgst_amount !== 0 && snapshot_list[i].cgst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 6) && this.state.is_gst_ledger){
							console.log(snapshot_list[i].sgst_amount)
							gst_ledgers = [{
										"amount": snapshot_list[i].sgst_amount,
										"guid": this.state.gst_ledger.sgst_ledger_guid,
										"name": this.state.gst_ledger.sgst_ledger_name,
										"type": 2,
										"ledger_id": this.state.gst_ledger.sgst_ledger_id,
										"cost_categories": []
							},{
									"amount": snapshot_list[i].cgst_amount,
									"guid": this.state.gst_ledger.cgst_ledger_guid,
									"name": this.state.gst_ledger.cgst_ledger_name,
									"type": 2,
									"ledger_id": this.state.gst_ledger.cgst_ledger_id,
									"cost_categories": []
							}]

						}
						if(((this.state.gst_ledger.igst_ledger_guid !== null && this.state.gst_ledger.igst_ledger_guid !== "") && (snapshot_list[i].igst_amount !== null && snapshot_list[i].igst_amount !== 0 && snapshot_list[i].igst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 5) && this.state.is_gst_ledger){
							gst_ledgers = [{
										"amount": snapshot_list[i].igst_amount,
										"guid": this.state.gst_ledger.igst_ledger_guid,
										"name": this.state.gst_ledger.igst_ledger_name,
										"type": 1,
										"ledger_id": this.state.gst_ledger.igst_ledger_id,
										"cost_categories": []
										}]
						}
						if(((this.state.gst_ledger.igst_ledger_guid !== null && this.state.gst_ledger.igst_ledger_guid !== "") && (snapshot_list[i].igst_amount !== null && snapshot_list[i].igst_amount !== 0 && snapshot_list[i].igst_amount !== "") && snapshot_list[i].parent_voucher_type_id == 6) && this.state.is_gst_ledger){
							gst_ledgers = [{
								"amount": snapshot_list[i].igst_amount,
								"guid": this.state.gst_ledger.igst_ledger_guid,
								"name": this.state.gst_ledger.igst_ledger_name,
								"type": 2,
								"ledger_id": this.state.gst_ledger.igst_ledger_id,
								"cost_categories": []
								}]
						}
						
						 console.log("data",gst_ledgers.length)
						let main_ledgeres = snapshot_list[i].parent_voucher_type_id == 5 ? [
								{
									"amount":snapshot_list[i].total_amount,
									"guid": snapshot_list[i].party_ledger_guid,
									"name": snapshot_list[i].party_ledger_name,
									"type": 2,
									"ledger_id": snapshot_list[i].party_ledger_id,
									"cost_categories": []
								},
								{
									"amount": gst_ledgers.length > 0 ? snapshot_list[i].basic_amount : snapshot_list[i].total_amount,
									"guid": snapshot_list[i].purchase_sales_ledger_guid,
									"name": snapshot_list[i].purchase_sales_ledger_name,
									"type": 1,
									"ledger_id": snapshot_list[i].purchase_sales_ledger_id,
									"cost_categories": []
								}
							] : [
								{
									"amount": snapshot_list[i].total_amount,
									"guid": snapshot_list[i].party_ledger_guid,
									"name": snapshot_list[i].party_ledger_name,
									"type": 1,
									"ledger_id": snapshot_list[i].party_ledger_id,
									"cost_categories": []
								},
								{
									"amount": gst_ledgers.length > 0 ? snapshot_list[i].basic_amount : snapshot_list[i].total_amount,
									"guid": snapshot_list[i].purchase_sales_ledger_guid,
									"name": snapshot_list[i].purchase_sales_ledger_name,
									"type": 2,
									"ledger_id": snapshot_list[i].purchase_sales_ledger_id,
									"cost_categories": []
								}
							]
							main_ledgeres = [...main_ledgeres,...gst_ledgers]
							console.log("main",main_ledgeres)
							let vouher_array = {
										"accounting_narration": `Invoice#: ${snapshot_list[i].invoice_no} Date: ${snapshot_list[i].date} | Quick Ledger Entry`,
										"parent_voucher_type_id":snapshot_list[i].parent_voucher_type_id,
										"voucher_type_guid":snapshot_list[i].voucher_type_guid,
										"voucher_type_name":snapshot_list[i].voucher_type_name,
										"voucher_type_id":snapshot_list[i].voucher_type_id,
										"voucher_creation_date": snapshot_list[i].date,
										"total_credit_amount":snapshot_list[i].total_amount,
										"total_debit_amount":snapshot_list[i].total_amount,
										"sync_datetime": changeDateTimeFormat(new Date()),
										"sync_status":0,
										"ledgers":main_ledgeres,
										"snapshot_id":snapshot_list[i].id,
										"merchant_id":snapshot_list[i].merchant_id,
										"merchant_update":snapshot_list[i].merchant_update,
										"common_ledger_guid":snapshot_list[i].purchase_sales_ledger_guid,
										"party_ledger_guid" : snapshot_list[i].party_ledger_guid
							}
							const merchant_data = this.state.all_merchant_list.find((merchant,index)=> merchant.value == snapshot_list[i].merchant_id);
							if(vouher_array.voucher_type_guid != (vouher_array.parent_voucher_type_id == 5 ? merchant_data.sales_voucher_type_guid : merchant_data.purchase_voucher_type_guid)){
								vouher_array.merchant_update = 1
								console.log("here in update")
							}
							voucher.push(vouher_array);

						console.log("validate",voucher)
						remaining_id.push(snapshot_list[i].id)
						
					}else{
						// console.log("unvalidated",snapshot_list[i])
						if(snapshot_list[i].total_amount == null || snapshot_list[i].total_amount == "" || snapshot_list[i].total_amount == 0){
							console.log("amount is null",snapshot_list[i])
						}
					}
				}
				request_data.vouchers = voucher;
				console.log("request data",request_data)		
				if(request_data.vouchers.length > 0){
					SnapshotService.createSnapshotEntries(request_data).then( async(resp_data) => {
						this.props.dispatch(setAppilcationLoder(false));
						this.setState({is_dirty:false})
						if (resp_data.response_code == 200) {
							toast.success('Snapshot ledger entries saved successfully');

							

							// const idsToRemove = new Set(remaining_id.map(item => item));
   				 			// // Filter the main array to exclude items with ids present in idsToRemove
							// const data =  snapshot_list.filter(item => !idsToRemove.has(item.id));
							// console.log("remaining id",data)
							// this.setState({snapshot_list:data})
							// this.props.dispatch(setBulkSnapshotList(data))
							// this.setState({is_dirty:false})
							// if(data.length == 0){
							// 	console.log("zeroooooooooooo");
							// 	await this.props.refreshItem();
							// 	setTimeout(() => {
							// 		this.props.handleQuickEntryTabChange(1);
							// 	}, 1000);

							// }
							this.setState({snapshot_list:[]})
							this.props.dispatch(setBulkSnapshotList([]))
							await this.props.refreshItem();
							
						} else {
							this.setState({snapshot_list:[]})
							this.props.dispatch(setBulkSnapshotList([]))
							toast.error(resp_data.message);
						}
					})
					
				
				}else{
					this.setState({save_and_redirect: false,next_route: false})
					
				
					this.props.dispatch(setAppilcationLoder(false))
				}
					

				
			

			}
			closeTransactionLinking = () =>{
				 this.props.history.goBack();
				//  this.props.dispatch(setBulkSnapshotList([]))
			}

			  // shortcut key scroll function
		scroll(direction) {
			const { scrollStep, scrollTop, scrollLeft } = this.state;
			const element = this.scrollRef.current;

			let newScrollTop = scrollTop;
			let newScrollLeft = scrollLeft;
			if (direction === "up") {
			newScrollTop = Math.max(scrollTop - scrollStep, 0);
			} else if (direction === "down") {
			newScrollTop = scrollTop + scrollStep;
			} else if (direction === "left") {
			newScrollLeft = Math.max(scrollLeft - scrollStep, 0);
			} else if (direction === "right") {
			newScrollLeft = scrollLeft + scrollStep;
			}

			// Update state and scroll position
			this.setState(
			{ scrollTop: newScrollTop, scrollLeft: newScrollLeft },
			() => {
				if (element) {
				element.scrollTop = newScrollTop;
				element.scrollLeft = newScrollLeft;
				}
			}
			);
		}
		gstuniqueGuid = (value,name) =>{
			let snapshot_list = [...this.state.snapshot_list];
			const list = snapshot_list.filter((item) => ((item.party_ledger_guid == value) || (item.purchase_sales_ledger_guid == value)))
			console.log("Lll",list)

			// if(name == 'select_sgst_ledger' && value !== ""){
			// 	if((value == this.state.cgst_ledger_guid) || (value == this.state.igst_ledger_guid) || (list.length > 0 && list.filter((item) => item.sgst_amount !== null).length > 0)){
					
			// 			this.setState({duplicate_sgst:true})
				
					
			// 	}else{
			// 		this.setState({duplicate_sgst:false},()=>{
			// 			// if(this.state.cgst_ledger_guid !== this.state.igst_ledger_guid){
			// 			// 	this.setState({duplicate_igst:false,duplicate_cgst:false})
			// 			// }
			// 			if(this.state.sgst_ledger_guid != this.state.cgst_ledger_guid && this.state.sgst_ledger_guid != this.state.igst_ledger_guid){
			// 				const cgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.cgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.cgst_ledger_guid)))
			// 				console.log("LLLL",cgst_data.filter(item => item.cgst_amount != null).length)
			// 				if(cgst_data.length > 0 && (cgst_data.filter(item => item.cgst_amount != null).length  == 0)){
			// 					this.setState({duplicate_cgst:false})
			// 				}
			// 				if(cgst_data.length == 0){
			// 					this.setState({duplicate_cgst:false})
			// 				}
			// 				const igst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.igst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.igst_ledger_guid)))
			// 				if(igst_data.length > 0 && (igst_data.filter(item => item.igst_amount != null).length  == 0)){
			// 					this.setState({duplicate_igst:false})
			// 				}
			// 				if(igst_data.length == 0){
			// 					this.setState({duplicate_igst:false})
			// 				}
			// 			}
			// 		})
			// 	}
			// }
			if(name == 'select_sgst_ledger' && value !== ""){
				if((value == this.state.cgst_ledger_guid) || (list.length > 0 && list.filter((item) => item.sgst_amount !== null).length > 0)){
					
						this.setState({duplicate_sgst:true})
				
					
				}else{
					this.setState({duplicate_sgst:false},()=>{
						// if(this.state.cgst_ledger_guid !== this.state.igst_ledger_guid){
						// 	this.setState({duplicate_igst:false,duplicate_cgst:false})
						// }
						if(this.state.sgst_ledger_guid != this.state.cgst_ledger_guid && (this.state.sgst_ledger_guid != this.state.igst_ledger_guid ||this.state.sgst_ledger_guid == this.state.igst_ledger_guid )){
							const cgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.cgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.cgst_ledger_guid)))
							console.log("LLLL",cgst_data.filter(item => item.cgst_amount != null).length)
							if(cgst_data.length > 0 && (cgst_data.filter(item => item.cgst_amount != null).length  == 0)){
								this.setState({duplicate_cgst:false})
							}
							if(cgst_data.length == 0){
								this.setState({duplicate_cgst:false})
							}
							const igst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.igst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.igst_ledger_guid)))
							if(igst_data.length > 0 && (igst_data.filter(item => item.igst_amount != null).length  == 0)){
								this.setState({duplicate_igst:false})
							}
							if(igst_data.length == 0){
								this.setState({duplicate_igst:false})
							}
						}
					})
				}
			}
			// if(name == 'select_cgst_ledger' && value !== ""){
			// 	if((value == this.state.sgst_ledger_guid) || (value == this.state.igst_ledger_guid) || (list.length > 0 && list.filter((item) => item.cgst_amount !== null).length > 0)){
					
			// 			this.setState({duplicate_cgst:true})
					
					
			// 	}else{
			// 		this.setState({duplicate_cgst:false},()=>{
			// 			if(this.state.cgst_ledger_guid !== this.state.sgst_ledger_guid && this.state.cgst_ledger_guid !== this.state.igst_ledger_guid){
			// 				// this.setState({duplicate_igst:false,duplicate_sgst:false})
			// 				const sgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.sgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.sgst_ledger_guid)))
							
			// 				if(sgst_data.length > 0 && (sgst_data.filter(item => item.sgst_amount != null).length  == 0)){
			// 					this.setState({duplicate_sgst:false})
			// 				}
			// 				if(sgst_data.length == 0){
			// 					this.setState({duplicate_sgst:false})
			// 				}
			// 				const igst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.igst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.igst_ledger_guid)))
			// 				if(igst_data.length > 0 && (igst_data.filter(item => item.igst_amount != null).length  == 0)){
			// 					this.setState({duplicate_igst:false})
			// 				}
			// 				if(igst_data.length == 0){
			// 					this.setState({duplicate_igst:false})
			// 				}
			// 			}
			// 		})
			// 	}
			// }
			if(name == 'select_cgst_ledger' && value !== ""){
				if((value == this.state.sgst_ledger_guid)  || (list.length > 0 && list.filter((item) => item.cgst_amount !== null).length > 0)){
					
						this.setState({duplicate_cgst:true})
					
					
				}else{
					this.setState({duplicate_cgst:false},()=>{
						if(this.state.cgst_ledger_guid !== this.state.sgst_ledger_guid && (this.state.cgst_ledger_guid !== this.state.igst_ledger_guid || this.state.cgst_ledger_guid == this.state.igst_ledger_guid)){
							// this.setState({duplicate_igst:false,duplicate_sgst:false})
							const sgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.sgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.sgst_ledger_guid)))
							
							if(sgst_data.length > 0 && (sgst_data.filter(item => item.sgst_amount != null).length  == 0)){
								this.setState({duplicate_sgst:false})
							}
							if(sgst_data.length == 0){
								this.setState({duplicate_sgst:false})
							}
							const igst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.igst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.igst_ledger_guid)))
							if(igst_data.length > 0 && (igst_data.filter(item => item.igst_amount != null).length  == 0)){
								this.setState({duplicate_igst:false})
							}
							if(igst_data.length == 0){
								this.setState({duplicate_igst:false})
							}
						}
					})
				}
			}
			// if(name == 'select_igst_ledger' && value !== ""){
			// 	if((value == this.state.sgst_ledger_guid) || (value == this.state.cgst_ledger_guid) || (list.length > 0 && list.filter((item) => item.igst_amount !== null).length > 0)){
			// 		this.setState({duplicate_igst:true})
			// 	}else{
			// 		this.setState({duplicate_igst:false},()=>{
			// 			if(this.state.igst_ledger_guid !== this.state.sgst_ledger_guid && this.state.igst_ledger_guid !== this.state.cgst_ledger_guid){
			// 				// this.setState({duplicate_sgst:false,duplicate_cgst:false})
			// 				const cgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.cgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.cgst_ledger_guid)))
			// 				console.log("LLLL",cgst_data.filter(item => item.cgst_amount != null).length)
			// 				if(cgst_data.length > 0 && (cgst_data.filter(item => item.cgst_amount != null).length  == 0)){
			// 					this.setState({duplicate_cgst:false})
			// 				}
			// 				if(cgst_data.length == 0){
			// 					this.setState({duplicate_cgst:false})
			// 				}
			// 				const sgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.sgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.sgst_ledger_guid)))
			// 				if(sgst_data.length > 0 && (sgst_data.filter(item => item.sgst_amount != null).length  == 0)){
			// 					this.setState({duplicate_sgst:false})
			// 				}
			// 				if(sgst_data.length == 0){
			// 					this.setState({duplicate_sgst:false})
			// 				}
			// 			}
			// 		})
			// 	}
			// }
			if(name == 'select_igst_ledger' && value !== ""){
				if((list.length > 0 && list.filter((item) => item.igst_amount !== null).length > 0)){
					this.setState({duplicate_igst:true})
				}else{
					this.setState({duplicate_igst:false},()=>{
						if((this.state.igst_ledger_guid !== this.state.sgst_ledger_guid || this.state.igst_ledger_guid == this.state.sgst_ledger_guid) && (this.state.igst_ledger_guid !== this.state.cgst_ledger_guid || this.state.igst_ledger_guid == this.state.cgst_ledger_guid)){
							// this.setState({duplicate_sgst:false,duplicate_cgst:false})
							const cgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.cgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.cgst_ledger_guid)))
							console.log("LLLL",cgst_data.filter(item => item.cgst_amount != null).length)
							if(cgst_data.length > 0 && (cgst_data.filter(item => item.cgst_amount != null).length  == 0)){
								this.setState({duplicate_cgst:false})
							}
							if(cgst_data.length == 0){
								this.setState({duplicate_cgst:false})
							}
							const sgst_data = snapshot_list.filter((item) => ((item.party_ledger_guid == this.state.sgst_ledger_guid) || (item.purchase_sales_ledger_guid == this.state.sgst_ledger_guid)))
							if(sgst_data.length > 0 && (sgst_data.filter(item => item.sgst_amount != null).length  == 0)){
								this.setState({duplicate_sgst:false})
							}
							if(sgst_data.length == 0){
								this.setState({duplicate_sgst:false})
							}
						}
						if(this.state.sgst_ledger_guid == this.state.cgst_ledger_guid){
							this.setState({duplicate_cgst:true,duplicate_sgst:true})
						}
					})
				}
			}
			
		}
		checkGstLedgerUpdatePartyPurchase = (value,name) =>{
			let snapshot_list = [...this.state.snapshot_list];
			const list = snapshot_list.filter((item) => ((item.party_ledger_guid == value) || (item.purchase_sales_ledger_guid == value)))
			console.log("Lll",list)

			// if(name == 'select_sgst_ledger' && value !== ""){
			// 	if((value == this.state.cgst_ledger_guid) || (value == this.state.igst_ledger_guid) || (list.length > 0 && list.filter((item) => item.sgst_amount !== null).length > 0)){
					
			// 			this.setState({duplicate_sgst:true})
				
					
			// 	}else{
			// 		this.setState({duplicate_sgst:false},()=>{
			// 			// if(this.state.cgst_ledger_guid !== this.state.igst_ledger_guid){
			// 			// 	this.setState({duplicate_igst:false,duplicate_cgst:false})
			// 			// }
			// 		})
			// 	}
			// }
			if(name == 'select_sgst_ledger' && value !== ""){
				if((value == this.state.cgst_ledger_guid)  || (list.length > 0 && list.filter((item) => item.sgst_amount !== null).length > 0)){
					
						this.setState({duplicate_sgst:true})
				
					
				}else{
					this.setState({duplicate_sgst:false},()=>{
						// if(this.state.cgst_ledger_guid !== this.state.igst_ledger_guid){
						// 	this.setState({duplicate_igst:false,duplicate_cgst:false})
						// }
					})
				}
			}
			// if(name == 'select_cgst_ledger' && value !== ""){
			// 	if((value == this.state.sgst_ledger_guid) || (value == this.state.igst_ledger_guid) || (list.length > 0 && list.filter((item) => item.cgst_amount !== null).length > 0)){
					
			// 			this.setState({duplicate_cgst:true})
					
					
			// 	}else{
			// 		this.setState({duplicate_cgst:false},()=>{
			// 			// if(this.state.sgst_ledger_guid !== this.state.igst_ledger_guid){
			// 			// 	this.setState({duplicate_igst:false,duplicate_sgst:false})
			// 			// }
			// 		})
			// 	}
			// }

			if(name == 'select_cgst_ledger' && value !== ""){
				if((value == this.state.sgst_ledger_guid)  || (list.length > 0 && list.filter((item) => item.cgst_amount !== null).length > 0)){
					
						this.setState({duplicate_cgst:true})
					
					
				}else{
					this.setState({duplicate_cgst:false},()=>{
						// if(this.state.sgst_ledger_guid !== this.state.igst_ledger_guid){
						// 	this.setState({duplicate_igst:false,duplicate_sgst:false})
						// }
					})
				}
			}
		// 	if(name == 'select_igst_ledger' && value !== ""){
		// 		if((value == this.state.sgst_ledger_guid) || (value == this.state.cgst_ledger_guid) || (list.length > 0 && list.filter((item) => item.igst_amount !== null).length > 0)){
		// 			this.setState({duplicate_igst:true})
		// 		}else{
		// 			this.setState({duplicate_igst:false},()=>{
		// 				// if(this.state.sgst_ledger_guid !== this.state.cgst_ledger_guid){
		// 				// 	this.setState({duplicate_sgst:false,duplicate_cgst:false})
		// 				// }
		// 			})
		// 		}
		// 	}
			
		// }
			if(name == 'select_igst_ledger' && value !== ""){
				if((list.length > 0 && list.filter((item) => item.igst_amount !== null).length > 0)){
					this.setState({duplicate_igst:true})
				}else{
					this.setState({duplicate_igst:false},()=>{
						// if(this.state.sgst_ledger_guid !== this.state.cgst_ledger_guid){
						// 	this.setState({duplicate_sgst:false,duplicate_cgst:false})
						// }
					})
				}
			}
			
		}
		duplicateGstLedger = () =>{
			// if(this.state.cgst_ledger_guid !== ""){
			// 	if((this.state.cgst_ledger_guid == this.state.sgst_ledger_guid) || (this.state.cgst_ledger_guid == this.state.igst_ledger_guid)){
			// 		this.setState({duplicate_cgst:true})
			// 	}
			// }
			if(this.state.cgst_ledger_guid !== ""){
				if((this.state.cgst_ledger_guid == this.state.sgst_ledger_guid)){
					this.setState({duplicate_cgst:true})
				}
			}
			// if(this.state.sgst_ledger_guid !== ""){
			// 	if((this.state.sgst_ledger_guid == this.state.cgst_ledger_guid) || (this.state.sgst_ledger_guid == this.state.igst_ledger_guid)){
			// 		this.setState({duplicate_sgst:true})
			// 	}
			// }
			if(this.state.sgst_ledger_guid !== ""){
				if((this.state.sgst_ledger_guid == this.state.cgst_ledger_guid)){
					this.setState({duplicate_sgst:true})
				}
			}
			if(this.state.igst_ledger_guid !== ""){
				// if((this.state.igst_ledger_guid == this.state.sgst_ledger_guid) || (this.state.igst_ledger_guid == this.state.cgst_ledger_guid)){
				// 	this.setState({duplicate_igst:true})
				// }
			}
			
			
		}
		
		duplicateLedgerIndexFound = (party,purchase_sales,sgst_amount,cgst_amount,igst_amount) =>{
			
			console.log(":::::::::",party,purchase_sales,sgst_amount,cgst_amount,igst_amount)
			let snapshot_list = [...this.state.snapshot_list];
			let sgst_ledger ;
			let cgst_ledger ;
			let igst_leger ;
			if(this.state.is_gst_ledger){
				if(sgst_amount){
					sgst_ledger = this.state.sgst_ledger_guid
				}
				if(cgst_amount){
					cgst_ledger = this.state.cgst_ledger_guid
				}
				if(igst_amount){
					igst_leger = this.state.igst_ledger_guid
				}
			}
			console.log(igst_leger)
			
			if(sgst_ledger != "" && sgst_ledger != undefined){
				if(party == sgst_ledger || purchase_sales == sgst_ledger){
					console.log("::::sgst true")
					if(this.state.duplicate_sgst != true){
						this.setState({duplicate_sgst:true},()=>{
						
						})
					}
					
				}
			}
			if(sgst_ledger != "" && sgst_ledger != undefined){
				if(party != sgst_ledger && purchase_sales != sgst_ledger ){
					console.log("false sgst")
					if(this.state.duplicate_sgst != false){
						this.setState({duplicate_sgst:false},()=>{
							this.checkGstLedgerUpdatePartyPurchase(sgst_ledger,'select_sgst_ledger')
						})
					}
					
				}
			}
			
			if(cgst_ledger != "" && cgst_ledger != undefined){
				if(party == cgst_ledger || purchase_sales == cgst_ledger){
					console.log("inside true cgst")
					if(this.state.duplicate_cgst != true){
						this.setState({duplicate_cgst:true},()=>{
						
						})
					}
					
				}
			}
			if(cgst_ledger != "" && cgst_ledger != undefined){
				if(party != cgst_ledger && purchase_sales != cgst_ledger){
					console.log("inside false cgst")
					if(this.state.duplicate_cgst != false){
						this.setState({duplicate_cgst:false},()=>{
							this.checkGstLedgerUpdatePartyPurchase(cgst_ledger,'select_cgst_ledger')
						})
					}
					
				}
			}
			
			if(igst_leger != "" &&  igst_leger != undefined){
				if(party == igst_leger || purchase_sales == igst_leger){
					console.log("hereeeee")
					if(this.state.duplicate_igst != true){
						this.setState({duplicate_igst:true},()=>{
						
						})
					}
					
				}
			}
			if( igst_leger != "" && igst_leger != undefined){
				if(party != igst_leger && purchase_sales != igst_leger){
					console.log(party,"::",igst_leger,"::",purchase_sales)
					if(this.state.duplicate_igst != false){
						this.setState({duplicate_igst:false},()=>{
							this.checkGstLedgerUpdatePartyPurchase(igst_leger,'select_igst_ledger')
						})
					}
					
				}
			}

			// if(sgst_ledger != "" && sgst_ledger != undefined){
			// 	if(party == sgst_ledger || purchase_sales == sgst_ledger){
			// 		console.log("::::sgst true")
					
			// 			this.setState({duplicate_sgst:true},()=>{
						
			// 			})
					
					
			// 	}
			// }
			// if(sgst_ledger != "" && sgst_ledger != undefined){
			// 	if(party != sgst_ledger && purchase_sales != sgst_ledger ){
			// 		console.log("false sgst")
					
			// 			this.setState({duplicate_sgst:false},()=>{
						
			// 			})
					
					
			// 	}
			// }
			
			// if(cgst_ledger != "" && cgst_ledger != undefined){
			// 	if(party == cgst_ledger || purchase_sales == cgst_ledger){
			// 		console.log("inside true cgst")
				
			// 			this.setState({duplicate_cgst:true},()=>{
						
			// 			})
					
					
			// 	}
			// }
			// if(cgst_ledger != "" && cgst_ledger != undefined){
			// 	if(party != cgst_ledger && purchase_sales != cgst_ledger){
			// 		console.log("inside false cgst")
					
			// 			this.setState({duplicate_cgst:false},()=>{
						
			// 			})
					
					
			// 	}
			// }
			
			// if(igst_leger != "" &&  igst_leger != undefined){
			// 	if(party == igst_leger || purchase_sales == igst_leger){
			// 		console.log("hereeeee")
					
			// 			this.setState({duplicate_igst:true},()=>{
						
			// 			})
					
					
			// 	}
			// }
			// if( igst_leger != "" && igst_leger != undefined){
			// 	if(party != igst_leger && purchase_sales != igst_leger){
			// 		console.log(party,"::",igst_leger,"::",purchase_sales)
					
			// 			this.setState({duplicate_igst:false},()=>{
						
			// 			})
					
					
			// 	}
			// }
			
			
			if(party !== ""){
				if((party === purchase_sales)|| (party === sgst_ledger) || (party === cgst_ledger) || (party === igst_leger)){
					console.log("similar part")
					
					return true;
				}
			}
			if(purchase_sales !== ""){
				if((purchase_sales === party) || (purchase_sales === sgst_ledger) || (purchase_sales === cgst_ledger) || (purchase_sales === igst_leger)){
					console.log("similar purchase")
					
					return true;
				}
			}
			console.log("when empty party",party)

			
			return false;
			
		}
		
		duplicateLedgerIndexFoundSaveGst = (party,purchase_sales,sgst_amount,cgst_amount,igst_amount) =>{
			
			console.log(":::::::::",party,purchase_sales,sgst_amount,cgst_amount,igst_amount)
			let snapshot_list = [...this.state.snapshot_list];
			let sgst_ledger ;
			let cgst_ledger ;
			let igst_leger ;
			if(this.state.is_gst_ledger){
				if(sgst_amount){
					sgst_ledger = this.state.sgst_ledger_guid
				}
				if(cgst_amount){
					cgst_ledger = this.state.cgst_ledger_guid
				}
				if(igst_amount){
					igst_leger = this.state.igst_ledger_guid
				}
			}
			console.log(sgst_ledger,cgst_ledger,igst_leger)
			
		
			if(party !== ""){
				if((party === purchase_sales)|| (party === sgst_ledger) || (party === cgst_ledger) || (party === igst_leger)){
					console.log("similar part")
					
					return true;
				}
			}
			if(purchase_sales !== ""){
				if((purchase_sales === party) || (purchase_sales === sgst_ledger) || (purchase_sales === cgst_ledger) || (purchase_sales === igst_leger)){
					console.log("similar purchase")
					
					return true;
				}
			}
			
			return false;
			
		}

	render() {
		console.log("show gst",this.state.gst_ledger)
		console.log("show gst",this.state.all_merchant_list)
		console.log("show gst",this.state.snapshot_types)
		console.log("show gst",this.state.snapshot_list)
		console.log(this.props.cgst_ledger_guid)
		console.log("length",this.state.snapshot_list.filter((item) => this.isValidRecord(item)).length)
		console.log("voucher list",this.state.voucher_option)
		let voucher_option = this.state.voucher_option.filter(voucher => voucher.is_master == 1)
		console.log("igst::::::",this.state.duplicate_igst)
		return (
			<main className="content-wrapper-new">
			<RouteLeavingGuard
			when={this.state.is_dirty}
			saveChanges={this.saveBeforeLeave}
			closed={this.state.closed}
			closeAction={this.closeAction}
			resetForm={this.emptyStateValue}
			/>
				<div className="container-fluid container-padding-new">
					
					{/* Breadcrumb */}
					<div className="row">
						<div className="col-12">
							<div className="main-sub-header-new">
								<div className="breadcrumb-header-new">
									<div className="row">
										<div className="col-12">
											<BreadCrum level={2} />
										</div>
									</div>
									<div className="row">
										<div className="col-3">
											<h2 className="breadcrumb-title-new">
												Snapshot
												{/* <span className="help-icon" > */}
												{/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger> */}
												{/* </span> */}
											</h2>
										</div>
										<div className="col-9 d-flex align-items-center justify-content-end gap-10">
										{(this.state.duplicate_cgst || this.state.duplicate_sgst || this.state.duplicate_igst)&&<span className='error_right mb-2'>Duplicate ledger(s) found. Please check snapshot entries and GST Ledgers.</span>}
											<h2 className="breadcrumb-title-new sub-header-cicon">
												{/* <a href=""> */}
												{/* <span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> */}
												{/* </a> */}
												<Link onClick={() => this.closeTransactionLinking()}>
													<span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
												</Link>
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Breadcrumb */}

					{/* Table Design  */}
					{/* <div className="form-content-sec-new mt-0"> */}
						<div className="form-sec-inner-new">
							<div className="row">
								<div className="col-sm-12">
									{/* class import-excel */}
									<div className="import-excel-popup">
										<div className="import-excel-border my-0">
											<div className="import-excel-table" ref={this.scrollRef}>
												<table className="table snap-excel-table">
													{/* <colgroup>
														<col></col>
														<col></col>
														<col></col>
														<col style={{ minWidth: '250px' }}></col>
														<col style={{ minWidth: '150px' }}></col>
														<col style={{ minWidth: '200px' }}></col>
														<col style={{ minWidth: '200px' }}></col>
														<col style={{ minWidth: '120px' }}></col>
														<col style={{ minWidth: '100px' }}></col>
														<col style={{ minWidth: '100px' }}></col>
														<col style={{ minWidth: '100px' }}></col>
														<col style={{ minWidth: '120px' }}></col>
													</colgroup> */}
													<colgroup>
													<col></col>
													<col></col>
													<col style={{ minWidth: '150px', maxWidth: '150px' }}></col>
													<col style={{ minWidth: '250px', maxWidth: '250px' }}></col>
													<col className="td_set_party_gstsie"></col>
													<col className="td_set_selectie"></col>
													<col className="td_set_selectie"></col>
													<col className="td_set_amountie"></col>
													<col className="td_set_gstsie"></col>
													<col className="td_set_gstsie"></col>
													<col className="td_set_gstsie"></col>
													<col className="td_set_amountie"></col>
												</colgroup>

												<thead>
													<tr>
														<th className="text-center">Ref. Date</th>
														<th>Ref. No.</th>
														<th>{
																this.state.voucherTypeList.length == 0 ? <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className='red-color'>Please sync with tally to get Voucher Types</span></Tooltip>}>
																<div>Voucher Type</div>
																</OverlayTrigger> : "Voucher Type"
															}
															{
																this.state.voucherTypeList.length == 0 && <span className="ie_error_dot ie_red_dot"></span>
															}
															</th>
														<th style={{ minWidth: '250px', maxWidth: '250px' }}>Party Name</th>
														<th>Party GSTIN</th>
														<th>Party Ledger</th>
														<th>Sales/Purchase Ledger</th>
														<th className="text-right">Basic Amount</th>
														<th className="text-right">

															{
																this.state.sgst_ledger_name ?  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.sgst_ledger_name}</Tooltip>}>
																<div>SGST</div>
																</OverlayTrigger> : "SGST"
															}
															{
																this.state.sgst_ledger_name && <span className="ie_error_dot ie_yellow_dot"></span>
															}
															{/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">SGST Message</Tooltip>}>
																	<span className="ie_error_dot ie_yellow_dot"></span>
																</OverlayTrigger> */}
														</th>
														<th className="text-right">

															{
																this.state.cgst_ledger_name ? <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.cgst_ledger_name}</Tooltip>}>
																<div>CGST</div>
																</OverlayTrigger> : "CGST"
															}
															{
																this.state.cgst_ledger_name && <span className="ie_error_dot ie_yellow_dot"></span>
															}
															{/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">CGST Message</Tooltip>}>
																	<span className="ie_error_dot ie_red_dot"></span>
																</OverlayTrigger> */}
														</th>
														<th className="text-right">

															{
																this.state.igst_ledger_name ? <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.igst_ledger_name ? this.state.igst_ledger_name : "IGST"}</Tooltip>}>
																<div>IGST</div>
																</OverlayTrigger> : "IGST"
															}
															{
																this.state.igst_ledger_name && <span className="ie_error_dot ie_yellow_dot"></span>
															}
															
															{/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">IGST Message</Tooltip>}>
																	<span className="ie_error_dot ie_red_dot"></span>
																</OverlayTrigger> */}
														</th>
														<th className="text-right">Total Amount</th>
													</tr>
												</thead>

													<tbody>
														{this.state.snapshot_list.map((item,index) => {
															return(
															<tr key={index}>
																<td className="ie_td_disabled text-center">{this.state.snapshot_list[index].date}</td>
																<td className="ie_td_disabled">
																	<div className="ellipsisc">
																		<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.snapshot_list[index].invoice_no}</Tooltip>}>
																			<span>{this.state.snapshot_list[index].invoice_no}</span>
																		</OverlayTrigger>
																	</div>
																</td>
																{/* <td className="ie_td_disabled">{this.state.snapshot_list[index].voucher_type == 6 ? "Purchase" : "Sales"}</td> */}
																<td className='p-0'>
																	<div className="form-froup mb-0">
																	{/* <select name="select_voucher_guid" className="form-control" 
																		value={this.state.snapshot_list[index].voucher_type_guid}
																		// disabled = {!this.state.is_gst_ledger}
																		onChange={(e) => this.handleVoucherChnage(e,index)} >
																		<option value="">Select Voucher</option>
																		{
																			this.state.voucherTypeList.filter(value => value.parent_voucher_type_id == this.state.snapshot_list[index].parent_voucher_type_id).map((value,ind)=>{
																				return (
																					<option key={ind} value={value.guid}>
																				{value.name}
																					</option>
																				);
																			})
																		}
																	</select> */}
																	<Form>
																			<ReactSelect
																				className="Select-Search typeed-dd " 
																				classNamePrefix="Select type-select "
																				name="select_voucher_guid"
																				options={this.state.voucher_option.filter(voucher => voucher.parent_voucher_type_id == this.state.snapshot_list[index].parent_voucher_type_id)}
																				isClearable={false}
																				isRtl={false}
																				isSearchable={true}
																				value={this.state.voucher_option.filter((option)=> option.value == this.state.snapshot_list[index].voucher_type_guid)}
																				onChange={(e)=>
																					this.newHandleVoucherChange(e,"select_voucher_guid",index)
																				}
																				
																			/>

																			
																		</Form>
																	</div>

																</td>
																<td className="ie_td_disabled" style={{ minWidth: '250px', maxWidth: '250px' }}>
																	<div className="ellipsisc">
																		<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.snapshot_list[index].name}</Tooltip>}>
																			<span>{this.state.snapshot_list[index].name}</span>
																		</OverlayTrigger>
																	</div>
																</td>
																<td className="ie_td_disabled">{this.state.snapshot_list[index].party_gst_in}</td>

																<td className='p-0'>
																	<div className='form-group mb-0'>
																		<Form>
																			<ReactSelect
																				className="Select-Search typeed-dd " 
																				classNamePrefix="Select type-select "
																				name="party_ledger"
																				options={[{value:"",label:"Select"},...this.state.ledger_options]}
																				isClearable={false}
																				isRtl={false}
																				isSearchable={true}
																				value={this.state.ledger_options.filter((option)=> option.value == this.state.snapshot_list[index].party_ledger_guid)}
																				onChange={(e)=>
																					this.newUpdateLedgerLineData(e,"party_ledger",index)
																				}
																				
																			/>

																			
																		</Form>
																		<small className="error_right">{ this.state.snapshot_list[index].duplicate_ledger ? "Duplicate ledger found":""}</small>
																	</div>
																</td>

																
																<td className='p-0'>
																	<div className='form-group mb-0'>
																		<Form>
																			<ReactSelect
																				className="Select-Search typeed-dd " 
																				classNamePrefix="Select type-select "
																				name="sales_purchase_ledger"
																				options={[{value:"",label:"Select"},...this.state.ledger_options]}
																				isClearable={false}
																				isRtl={false}
																				isSearchable={true}
																				value={this.state.ledger_options.filter((option)=> option.value == this.state.snapshot_list[index].purchase_sales_ledger_guid)}
																				onChange={(e)=>
																					this.newUpdateLedgerLineData(e,"sales_purchase_ledger",index)
																				}
																				
																			/>

																			
																		</Form>
																		<small className="error_right">{ this.state.snapshot_list[index].duplicate_ledger ? "Duplicate ledger found":""}</small>
																	</div>
																</td>

																<td className="ie_td_disabled text-right">{this.state.snapshot_list[index].basic_amount ? `₹ ${this.state.snapshot_list[index].basic_amount}` : ""}</td>
																<td className="ie_td_disabled text-right">{this.state.snapshot_list[index].sgst_amount ? `₹ ${this.state.snapshot_list[index].sgst_amount}` : ""}</td>
																<td className="ie_td_disabled text-right">{this.state.snapshot_list[index].cgst_amount ? `₹ ${this.state.snapshot_list[index].cgst_amount}` : ""}</td>
																<td className="ie_td_disabled text-right">{this.state.snapshot_list[index].igst_amount ? `₹ ${this.state.snapshot_list[index].igst_amount}` : ""}</td>
																<td className="ie_td_disabled text-right">{this.state.snapshot_list[index].total_amount ? `₹ ${this.state.snapshot_list[index].total_amount}` : ""}</td>
															</tr>
														)})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									{/* end class import-excel */}
								</div>
							</div>

							<div className="row mt-2">
								<div className="col-sm-6 ie_arrows">
									{/* Arrows */}
									{/* <div className="ie_arrows"> */}
										<span className="icon-right-arrow ie_arrows_icon ie_left"  onClick={() => this.scroll("left")}></span>
										<span className="icon-right-arrow-1 ie_arrows_icon ie_right"  onClick={() => this.scroll("right")}></span>
										<span className="icon-right-arrow-1 ie_arrows_icon pt-1" onClick={() => this.scroll("down")}></span>
										<span className="icon-right-arrow ie_arrows_icon"  onClick={() => this.scroll("up")}></span>
									{/* </div> */}
									{/* End Arrows */}
								</div>
								<div className="col-sm-6">
									<div className="reset_apply_btn_new">
										<Button variant="outline-success reset_btn_new" onClick={()=> this.setState({show_gst_toggle:true})}>GST Ledger</Button>
										<Button variant="success apply_btn_new" onClick={this.createEntries} disabled={this.state.snapshot_list.filter((item) => this.isValidRecord(item)).length == 0}>Create Entries</Button>
									</div>
								</div>
							</div>
						</div>
					{/* </div> */}
					{/* End Table Design  */}


					{/* Model Popup */}
					<Modal
						// show={true}
						// show={false}
						show={this.state.show_gst_toggle}
						onHide={() => this.toggleImportExcelPopup()}
						backdrop="static"
						keyboard={false}
						centered
						// size="lg"
						className="popup-model snap_quick_popup"
					>
						{/* <Modal.Header closeButton> */}
						<Modal.Header>
							<Modal.Title className="d-flex align-items-center justify-content-between gap-10 w-100">
								<span>GST Ledgers</span>

								<div className="toggle-switch-btn toggle-switch-center">
									<span className="toggle-switch-center">
										<span className="toggle-btn-sec-text">No</span>
										<label className="switch">
											<input type="checkbox"
												// className={"form-control " + (this.state.errors.show_email_snapshot_config ? " wrong-red " : "")}
												className="form-control "
												 checked={this.state.is_gst_ledger}
												onChange={(e)=>{
													console.log(e.target.checked)
													if(!e.target.checked){
														this.setState({sgst_ledger_guid:"",sgst_ledger_name:"",
															cgst_ledger_guid:"",
															cgst_ledger_name:"",
															igst_ledger_guid:"",
															igst_ledger_name:"",
															duplicate_cgst:false,
															duplicate_sgst:false,
															duplicate_igst:false
														})
													}
													this.setState({is_gst_ledger:e.target.checked,is_gst_ledger_update:1})
												}}
												// disabled={!MANAGE_ENTITY_PERMISSION}
												autoComplete="off"
												autoFocus={true}
												name="is_gst_ledger" />
											<span className="slider round"></span>
										</label>
										<span className="toggle-btn-sec-text">Yes</span>
									</span>
								</div>
							</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							{/* <div className="ledger-entery-new my-0 p-2"> */}
							<div className="table-responsive main-invoice-item-new mt-0">
								<table className="ledger-table table mb-0">
									<colgroup>
										<col style={{ width: '60px', verticalAlign: 'middle' }}></col>
										<col></col>
										<col></col>
									</colgroup>

									{/* <thead>
										<tr>
											<th className="">Tax Ledgers</th>
											<th className="">Select Ledger<span className="red-star">*</span></th>
											<th className="">Ledger Narration<span className="red-star">*</span></th>
										</tr>
									</thead> */}

									<tbody>
										<tr>
											<td className="va-middle">SGST</td>
											<td className="">
												{/* <select name="select_ledger" className="form-control" readonly=""> */}
												<select name="select_sgst_ledger" className="form-control" 
													value={this.state.sgst_ledger_guid}
													disabled = {!this.state.is_gst_ledger}
													onChange={this.handleGstLedger}
												>
													<option value="">Select Ledger</option>
													{
														this.state.ledger_options.map((value,index)=>{
															return (
																<option key={index} value={value.value}>
															  {value.label}
																</option>
															  );
														})
													}
												</select>
												<small className="error_right">{ this.state.duplicate_sgst ? "Duplicate ledger found":""}</small>
											</td>
											{/* <td className="">
												<select name="select_narration" className="form-control" >
													<option value="">Select Ledger</option>
													<option value="">Ledger 1</option>
													<option value="">Ledger 2</option>
												</select>
											</td> */}
										</tr>
										<tr>
											<td className="va-middle">CGST</td>
											<td className="">
												<select name="select_cgst_ledger" className="form-control" 
													value={this.state.cgst_ledger_guid}
													disabled={!this.state.is_gst_ledger}
													onChange={this.handleGstLedger}
												>
													<option value="">Select Ledger</option>
													{
														this.state.ledger_options.map((value,index)=>{
															return (
																<option key={index} value={value.value}>
															  {value.label}
																</option>
															  );
														})
													}
												</select>
												<small className="error_right">{ this.state.duplicate_cgst ? "Duplicate ledger found":""}</small>
											</td>
										</tr>
										<tr>
											<td className="va-middle">IGST</td>
											<td className="">
												<select name="select_igst_ledger" className="form-control" 
													value={this.state.igst_ledger_guid}
													disabled={!this.state.is_gst_ledger}
													onChange={this.handleGstLedger}
												>
													<option value="">Select Ledger</option>
													{
														this.state.ledger_options.map((value,index)=>{
															return (
																<option key={index} value={value.value}>
															  {value.label}
																</option>
															  );
														})
													}
												</select>
												<small className="error_right">{ this.state.duplicate_igst ? "Duplicate ledger found":""}</small>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							{/* </div> */}

							<div className="reset_apply_btn_new mt-2">
								{/* <Button variant="outline-success reset_btn_new" >Cancel</Button> */}
								<Button variant="success apply_btn_new" onClick={this.saveGstLedger}>Save</Button>
							</div>
						</Modal.Body>
					</Modal>
					{/*End  Model Popup */}

				</div>
			</main >
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Snapshot || {};
	const session_values = state.Session || {};
	const bulk_snapshot_list = all_value.bulk_snapshot_list || [];
	const cgst_ledger_guid = session_values.entity_config_param.cgst_ledger_guid
	const sgst_ledger_guid = session_values.entity_config_param.sgst_ledger_guid
	const igst_ledger_guid = session_values.entity_config_param.igst_ledger_guid
	const is_gst_ledger =  session_values.entity_config_param.is_gst_ledger

	return {
		 all_value, bulk_snapshot_list,session_values,cgst_ledger_guid,sgst_ledger_guid,igst_ledger_guid,is_gst_ledger
		};
}
  export default connect(mapStateToProps)(withRouter(SnapshotQuickView));