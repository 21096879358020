import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class UserService {
    getEntitiesList() {
        return axios.get(API_URL + 'user-entities', { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getUserRoles() {
        return axios.get(API_URL + 'roles', { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getModulePermisionList() {
        return axios.get(API_URL + 'modules', { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }
    createUser(data) {
        return axios.post(API_URL + 'user',data, { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    updateUser(data) {
        return axios.put(API_URL + 'user',data, { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getUserList() {
        return axios.get(API_URL + 'users', { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }
    getUserDetail(user_id){
        return axios.get(API_URL + 'user/'+user_id, { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    deleteUser(user_id){
        return axios.delete(API_URL + 'user/'+user_id, { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    resendInvite(user_id){
        let data = {
            "id":parseInt(user_id),
            "event":"user_invite"            
        }
        return axios.post(API_URL + 'resend-invite/', data,{ headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    updateAccountingRights(data){
		return axios
        .put(API_URL + "accounting-permissions", data, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
	}
}

export default new UserService();