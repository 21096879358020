function addCategoryValidation(data){

    let extra_space_regex = /[\s]{2,}/
    let number_char_regex = /[0-9\-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/

    // Empty data

    if(!data){
        return "Field is required"
    }

    // Extra white space
    else if(data.search(extra_space_regex)>-1){
        return "Input field contains extra whitespace"
    }

    // special characters and numbers
    // else if(data.search(number_char_regex)>-1){
    //     return "Please provide letters only"
    // }
}

export {
    addCategoryValidation
}