import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import LicenseService from '../../../Services/Licenses/LicenseService';
import { toast } from 'react-toastify';
import { displayDateFormate } from '../../../Helpers/DateFormat';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button, Modal } from 'react-bootstrap';

import { setOrderDetails } from '../../../Redux/Actions/Licenses';
import SessionManagement from '../../../Utils/SessionManagement';

class LicenseList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            licenses: [],
            search: "",
            active_search: false,
            sort_by: 1,
            sort_order: 1,
            total_licenses: 0,
            assigned_licenses: 0,
            unassigned_licenses: 0,
            basic_licenses: 0,
            advanced_licenses: 0,
            ultimate_licenses: 0,
            filter_card: 1,
            scroll: this.props.location.scroll,
            buktec_order_id: this.props.location.buktec_order_id,
            show_in_process_modal: false
        }
    }

    componentDidMount() {
        console.log('getLicenseList')
        this.setState({ licenses: [] })
        this.getLicenseList();
    }

    scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    getLicenseList = () => {
        this.props.dispatch(setAppilcationLoder(true));
        LicenseService.getLicenseList()
            .then(data => {
                if (data.response_code === 200) {
                    let assignedCount = 0;
                    let basicLicenses = 0;
                    let advancedLicenses = 0;
                    let ultimateLicenses = 0;

                    var licenseTypes = [];

                    data.result.forEach(function (element) {
                        element.remaining_days = element.expires_in.replace(/.days/, '');
                        if (element.remaining_days == 0) {
                            element.expires_in = 'Today';
                        } else if (element.remaining_days == 1) {
                            element.expires_in = 'Tomorrow';
                        }

                        let licenseName = element.licence_name.split("-");
                        let licenseType = licenseName[licenseName.length - 1];
                        element.license_type = licenseType;

                        if (element.assigned_to != null) {
                            assignedCount++;
                        }

                        //licenseTypes[licenseType]
                        if (element.license_id == 2) {
                            basicLicenses++;
                        } else if (element.license_id == 3) {
                            advancedLicenses++;
                        } else if (element.license_type == 1) { // starter
                            ultimateLicenses++;
                        }

                    });

                    this.setState({
                        licenses: data.result,
                        total_licenses: data.result.length,
                        assigned_licenses: assignedCount,
                        unassigned_licenses: (data.result.length - assignedCount),
                        basic_licenses: basicLicenses,
                        advanced_licenses: advancedLicenses,
                        ultimate_licenses: ultimateLicenses
                    })

                    if (this.state.scroll) {
                        if (this.state.buktec_order_id) {
                            //this.scrollToBottom();
                        } else {
                            this.setState({ show_in_process_modal: true })
                        }
                    }

                } else {
                    toast.error(data.message);
                }
                this.props.dispatch(setAppilcationLoder(false));
            })
    }

    goToBuyLicensePage = () => {
        localStorage.removeItem("order_data")
        localStorage.removeItem("bill_to_data")

        this.props.history.push("/licenses/buy-licenses");
    }

    goToOrderHistoryPage = () => {
        this.props.history.push("/licenses/order-history");
    }

    goToRenewLicensePage = () => {
        localStorage.removeItem("renew_order_data")
        localStorage.removeItem("bill_to_data")
        this.props.history.push("/licenses/renew-licenses");
    }

    goToUpgradeLicensePage = (row) => {
        localStorage.removeItem("upgrade_order_data")
        localStorage.removeItem("bill_to_data")

        let account_license_id = row.account_license_id.replace('A-', '');
        this.props.history.push({
            pathname: '/licenses/upgrade-license/' + account_license_id,
            state: row,
        })
    }

    getCaret = (direction) => {
        switch (direction) {
            case 'asc':
                return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
            case 'desc':
                return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
            default:
                return (<span className="icon-Group-4449 sort-both-icon"></span>);
        }
    }


    setNoDataFoundOption = () => {
        return "No items to display";
    }

    dataFormatAssignedTo = (cell, row) => {
        return <span className={row.remaining_days <= 15 && row.assigned_to ? "red-text" : ""}>{row.assigned_to ? row.assigned_to : '-'}</span>;
    }

    dataFormatExpiresIn = (cell, row) => {
        return <span className={row.remaining_days <= 15 ? "red-text" : ""}>{row.expires_in}</span>;
    }

    dataFormatAmount = (cell) => {
        return '₹' + cell;
    }

    dataFormatAction = (cell, row) => {
        return <button disabled={!row.is_upgradable} className={row.is_upgradable ? 'create-evid-btn btn ft-sz-14 white-color s-bold' : 'create-evid-btn btn ft-sz-14 white-color s-bold fnt-gry-bg'} onClick={() => this.goToUpgradeLicensePage(row)}>Upgrade</button>
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
        e.preventDefault()
    }

    resetFilter = () => {
        this.setState({ search: '' })
    }

    searchFilter = (licenses, query, filter) => {
        if (!query && filter == 1) {
            return licenses;
        }
        return licenses.filter(license => {

            if (!query) {
                if ((filter == 1) || (filter == 2 && license.license_id == 2) || (filter == 3 && license.license_id == 3) || (filter == 4 && license.license_type == 'Ultimate') || (filter == 5 && license.assigned_to != null) || (filter == 6 && license.assigned_to == null)) {
                    return true;
                }
            }

            if (query) {
                if (((filter == 1) || (filter == 2 && license.license_id == 2) || (filter == 3 && license.license_id == 3) || (filter == 4 && license.license_type == 'Ultimate') || (filter == 5 && license.assigned_to != null) || (filter == 6 && license.assigned_to == null)) && (license.billed_to != null && license.billed_to.toLowerCase().includes(this.state.search.toLowerCase()) || (license.assigned_to != null && license.assigned_to.toLowerCase().includes(this.state.search.toLowerCase())))) {
                    return true;
                }
            }
        })
    }


    onCardSelected = (filter_card) => {
        this.setState({ filter_card: filter_card }, () => {
            this.searchFilter(this.state.licenses, this.state.search, filter_card);
        });
    }

    goToProfileManagement = () => {
        this.props.history.push("/profile-management/entities/");
    }

    showOrderId = (cell, row) => {
        return "Order Id : " + row.order_id;
    }

    numericSortFunc = (a, b, order) => {
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;

        var aA = a.licence_name.replace(reA, "");
        var bA = b.licence_name.replace(reA, "");

        if (order == 'desc') {
            if (aA === bA) {
                var aN = parseInt(a.licence_name.replace(reN, ""), 10);
                var bN = parseInt(b.licence_name.replace(reN, ""), 10);
                return aN === bN ? 0 : aN < bN ? 1 : -1;
            } else {
                return aA < bA ? 1 : -1;
            }
        } else {
            if (aA === bA) {
                var aN = parseInt(a.licence_name.replace(reN, ""), 10);
                var bN = parseInt(b.licence_name.replace(reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        }
    }

    render() {

        const options = {
            noDataText: this.setNoDataFoundOption(),
        };

        const filteredLicenses = this.searchFilter(this.state.licenses, this.state.search, this.state.filter_card);

        const rowStyleFormat = (row, rowIdx) => {
            if (row !== undefined) {
                return { backgroundColor: row.order_id == 'A-' + this.state.buktec_order_id ? '#c4f5e1' : null };
            }
        }

        let order_summary_type = "buying"
        if (this.props.location.order_summary_type == 'renew') {
            let order_summary_type = "renewing"
        } else if (this.props.location.order_summary_type == 'upgrade') {
            let order_summary_type = "upgrading"
        }

        return (
            <main className="content-wrapper-new">
                <div className="container-fluid container-padding-new">

                    {/* Breadcrumb */}
                    <div className="row">
                        <div className="col-12">
                            <div className="main-sub-header-new">
                                <div className="breadcrumb-header-new">
                                    <div className="row">
                                        <div className="col-12">
                                            {/* <BreadCrum level={3} /> */}
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item-new">
                                                    <Link to={'/home'}>Home</Link>
                                                </li>
                                                <li className="breadcrumb-item-new" onClick={this.goToProfileManagement}>Profile Management</li>
                                                <li className="breadcrumb-item-new">My Licenses</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <h2 className="breadcrumb-title-new">My Licenses</h2>
                                        </div>
                                        <div className="col-4">
                                            <span className="breadcrumb-title-new sub-header-cicon">
                                                {/* <button onClick={this.goToRenewLicensePage} className="lic-bill-btn btn greenbtn s-bold ">Renew Licenses</button> */}
                                                {/* <button onClick={this.goToBuyLicensePage} className="lic-bill-btn btn greenbtn s-bold ">Buy Licenses</button> */}
                                                <Button variant="success apply_btn_new" onClick={this.goToBuyLicensePage}>Buy Licenses</Button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrumb */}

                    {/* Widget */}
                    <div className="row widget-filter-new sec-mb">
                        <div className="col-xl-2 col-lg col-md-4 col-sm col-6" onClick={() => this.onCardSelected(1)}>
                            <div className={"widget-block-new " + (this.state.filter_card == 1 ? " active-widget" : "")}>
                                <p className="wtitle-new">Total</p>
                                <p className="wcounter-new">{this.state.total_licenses}</p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg col-md-4 col-sm col-6" onClick={() => this.onCardSelected(2)}>
                            <div className={"widget-block-new notready_usrin " + (this.state.filter_card == 2 ? " active-widget" : "")}>
                                <p className="wtitle-new">Smart</p>
                                <p className="wcounter-new">{this.state.basic_licenses}</p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg col-md-4 col-sm col-6" onClick={() => this.onCardSelected(3)}>
                            <div className={"widget-block-new ready_usrin " + (this.state.filter_card == 3 ? " active-widget" : "")}>
                                <p className="wtitle-new">Superb</p>
                                <p className="wcounter-new">{this.state.advanced_licenses}</p>
                            </div>
                        </div>

                        {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm col-6" onClick={() => this.onCardSelected(4)}>
                            <div className={"widget-block-new ready_usrin " + ((this.state.filter_card == 4 ? " active-widget" : "")}>
                                <p className="wtitle-new">Ultimate</p>
                                <p className="wcounter-new">{this.state.ultimate_licenses}</p>
                            </div>
                        </div> */}
                        <div className="col-xl-2 col-lg col-md-4 col-sm col-6" onClick={() => this.onCardSelected(5)}>
                            <div className={"widget-block-new ready_usrin " + (this.state.filter_card == 5 ? " active-widget" : "")}>
                                <p className="wtitle-new">Assigned</p>
                                <p className="wcounter-new">{this.state.assigned_licenses}</p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg col-md-4 col-sm col-6" onClick={() => this.onCardSelected(6)}>
                            <div className={"widget-block-new ready_usrin " + (this.state.filter_card == 6 ? " active-widget" : "")}>
                                <p className="wtitle-new">Unassigned</p>
                                <p className="wcounter-new">{this.state.unassigned_licenses}</p>
                            </div>
                        </div>
                        {/* <div className="col-xl d-xl-block d-none">
                        </div> */}
                    </div>
                    {/* End Widget */}

                    {/* Search Filter */}
                    <div className="row mainFilter-new sec-mb">
                        <div className="col-12">
                            <div className="top-new-filter">
                                <div className="filter-search-field">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Assigned To, Billed To</Tooltip>}>
                                        <form className={"search-new-sec " + (this.state.search.length == 0 ? "" : " active-filter")} onBlur={() => this.setState({ active_search: false })} onFocus={() => this.setState({ active_search: true })} onSubmit={(event) => event.preventDefault()}>
                                            <input type="text"
                                                className={"form-control filter-btn-new big-filter-btn " + (this.state.active_search ? " activeNew-search" : "")}
                                                placeholder="Search"
                                                value={this.state.search}
                                                onChange={this.handleChange}
                                                name="search"
                                            />
                                            {this.state.search.length == 0 ?
                                                <Button type="button" className={this.state.active_search ? " active-new-search-icon " : ""}>
                                                    <span className="icon-loupe"></span>
                                                </Button>
                                                :
                                                <Button className={this.state.active_search ? " active-new-search-icon crosebtn " : " crosebtn "} onClick={this.resetFilter}>
                                                    <span class="icon-cancel"></span>
                                                </Button>
                                            }
                                        </form>
                                    </OverlayTrigger>

                                    <Button onClick={this.goToOrderHistoryPage} className="success apply_btn_new big-filter-btn"> Order History</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Search Filter */}

                    {/* Table */}
                    <div className="row main-table-listing listing-icons sec-mb">
                        <div className="col-12">
                            <div className="table-sec pointer-none">
                                <BootstrapTable
                                    ref={(el) => { this.messagesEnd = el; }}
                                    data={filteredLicenses}
                                    table-bordered
                                    options={options}
                                    responsive
                                    trStyle={rowStyleFormat}>
                                    <TableHeaderColumn width="110px" isKey headerAlign='left' dataAlign='left' dataField='licence_name' dataSort columnTitle={this.showOrderId} sortFunc={this.numericSortFunc} caretRender={this.getCaret}>
                                        License
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="32%" headerAlign='left' dataAlign='left' dataField='billed_to' field_name="Billed To" columnTitle={this.showOrderId} dataSort={true} caretRender={this.getCaret}>
                                        Billed To
                                    </TableHeaderColumn>
                                    {/* thStyle={{ minWidth: '25%' }} tdStyle={{ minWidth: '25%' }} */}
                                    <TableHeaderColumn width="32%" headerAlign='left' dataAlign='left' dataField='assigned_to' columnTitle={this.showOrderId} dataFormat={(cell, row) => this.dataFormatAssignedTo(cell, row)} dataSort={true} caretRender={this.getCaret} >
                                        {/* className="w-10-pr" */}
                                        Assigned To
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="20%" headerAlign='center' dataAlign='center' dataField='order_id' dataSort={true} caretRender={this.getCaret} sortFunc={this.numericSortFunc}>
                                        Order ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="130px" headerAlign='center' dataAlign='center' dataField='valid_from' columnTitle={this.showOrderId} dataFormat={(cell, row) => displayDateFormate(cell)} dataSort={true} caretRender={this.getCaret}>
                                        Valid From
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="130px" headerAlign='center' dataAlign='center' dataField='valid_till' columnTitle={this.showOrderId} dataFormat={(cell, row) => displayDateFormate(cell)} dataSort={true} caretRender={this.getCaret}>
                                        Valid To
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width="130px" headerAlign='center' dataAlign='center' columnTitle={this.showOrderId} dataFormat={(cell, row) => this.dataFormatExpiresIn(cell, row)} dataField='expires_in' dataSort={true} caretRender={this.getCaret}>
                                        Expires In
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                    {/* End Table */}

                    {/* Back Button */}
                    <div className={"row " + (!!SessionManagement.getLastUrl(false) ? "" : " d-none")}>
                        <div className="col-12">
                            <Link to={SessionManagement.getLastUrl(false)} className="btn btn-success apply_btn_new" onClick={() => SessionManagement.removeLastUrl()}>Back</Link>
                        </div>
                    </div>
                    {/* End Back Button */}

                    {/* Modal Popup */}
                    <Modal
                        show={this.state.show_in_process_modal}
                        // show={true}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className="popup-model error-popup"
                    >
                        <Modal.Body className="text-center">
                            <p className="pmsg-text text-left">Thank you for {order_summary_type} Buktec licence. Kindly note your payment is being processed and we shall update your licenses soon.</p>

                            <Button className="success apply_btn_new" onClick={() => this.setState({ show_in_process_modal: false })}>Okay</Button>
                        </Modal.Body>
                    </Modal>
                    {/* End Modal Popup */}
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    // const all_value = state.Licenses || {};
    // const order_details = all_value.order_details


    // return {
    // 	from_date, from_amount, to_amount, to_date, module, vendor, status, evidences,
    // 	linked_evidences, is_personal, no_supporting_document, business_narration,
    // 	transaction_status, temp_linked_evidences, voucher_list, temp_voucher_list, transaction_details
    // };

    return {}
}

export default connect(mapStateToProps)(withRouter(LicenseList));