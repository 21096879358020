import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class LicenseService {

    getLicensePackageDetails() {
        return axios.get(API_URL + 'license-features',{ headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getBillToList() {
        return axios.get(API_URL + 'bill-to-license', { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    applyPromoCode(promoCode) {
        return axios.post(API_URL + 'promo-code', {"promo_code": promoCode}, { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    createOrder(data) {
        return axios.post(API_URL + 'order', data, { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getLicenseList() {
        return axios.get(API_URL + 'licenses', { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getOrderList() {
        return axios.get(API_URL + 'orders', { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getOrderSummaryDetail(data) {
        return axios.post(API_URL + 'order-summary', data, { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    deleteOrder(id) {
        var data = {order_id: id}
        //return axios.delete(API_URL + "order", data, { headers: Header.authHeader() })
        return axios.delete(API_URL + 'order', {data:data,headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }
    
    getUpgradeLicenseList(data) {
        return axios.post(API_URL + 'licenses-upgrade', data, { headers: Header.authHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }
}

export default new LicenseService();