function snapshotTypes(type) {
    switch (type) {
        case 1: return "Sales Invoice";
        case 2: return "Purchase Invoice";
        case 3: return "Sales Return";
        case 4: return "Purchase order";
        case 5: return "E-way bill";
        case 6: return "Expense Invoice";
        case 7: return "Purchase Return";
        case 8: return "Other";
    }
}


export { snapshotTypes }