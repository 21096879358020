import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function ProcessedStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Accounted</Tooltip>}>
            <span className="icon-processed-icon icon-dt-td iconsc"><span className="path1 "></span><span className="path2 white-icon"></span></span>
        </OverlayTrigger>
    </>
}
export default ProcessedStatus;