// IMAGE UPLOAD CONSTANTS
export const PROFILE_MANAEGEMT_FILE_CODE = 6;
export const SNAPSHOT_FILES_CODE = 2;
export const DOCBOX_FILES_CODE = 11;

/*
*   PERMISSION CONSTANT
*/
export const FI_CREATE_CODE = 12;
export const FI_UPLOAD_ACCOUNT_STATEMENT_CODE = 13;
export const FI_VIEW_ACCOUNT_STATEMENT_CODE = 14;


export const timeoutInMillis = (5 * 60) * 1000; //1000 * 30 

//MONEY LINK
export const LINKING_CODE = 15;
export const LEDGER_ENTRIES_CODE = 16;
export const VIEW_LINKED_ENTRIES_CODE = 17;


// SNAPSHOT
export const CREATE_BUSSINESS_SNAPSHOT_CODE = 1;
export const MANAGE_OTHER_USER_SNAPSHOT_CODE = 2;
export const VIEW_ONLY_SNAPSHOT_CODE = 3;
export const CREATE_REIMBUSRMENT_SNAPSHOT_CODE = 4;
export const APPROVE_REIMBURSMENT_SNAPSHOT_CODE = 5;
export const ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE = 6;
export const SMART_SCAN_SNAPSHOT_CODE = 7;
export const SNAPSHOT_LEDGER_ENTRIES_CODE = 32;

// Docbox
export const CREATE_FILES_FOLDERS = 22;
export const VIEW_FILES_FOLDERS = 23;
export const MANAGE_ALL_FILES_FOLDERS = 24;