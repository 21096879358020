import React from 'react';
import logo_dash from '../../Assets/img/logo-dash.jpeg';

function PageNotFound() {
  return <>
      <div className="container-fluid d-flex flex-column p-0" style={{ height: '90vh' }}>
        <div className="" style={{background: '#f4f5f8'}}>
          <div className="col-xl-2 col-lg col-md-2 d-flex align-items-center pt-3 pb-3">
            <img className="main-logo" src={logo_dash} />
          </div>
        </div>
        <div className="flex-column bg-w ml-1 mr-1 text-center d-flex align-items-center justify-content-center" style={{ flex: 1 }}>
          <h1 className='s-bold blu-color' style={{ fontSize: '7rem' }}>404</h1>
          <h4 className='s-bold blu-color mb-3'>Oops... Page Not Found!</h4>
          <h6 className='font-colr'>The invitation has expired. Please ask your accountant to resend invite.</h6>
        </div>
      </div>
  </>
}

export default PageNotFound;