import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class ChequeService {
	uploadImage(data) {
		return axios
			.post(API_URL + "attachment-upload", data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	createCheque(data) {
		return axios
			.post(API_URL + "cheque", data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	updateCheque(data) {
		return axios
			.put(API_URL + "cheque", data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	getChequeDetail(id) {
		return axios
			.get(API_URL + "cheque/" + id, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	getChequeList(filter_data) {
		let data = JSON.stringify(filter_data);
		return axios.post(API_URL + 'cheques', data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	getChequeCount(filter_data) {
		return axios.post(API_URL + 'cheques-count', filter_data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}
	makeRemoveStar(data) {
		return axios.put(API_URL + 'cheque-star', data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}
	changeStatus(data) {
		return axios.put(API_URL + 'cheque-status', data, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	deleteCheque(id) {
		return axios
			.delete(API_URL + "cheque/" + id, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				console.log('error  ', error)
				return error;
			});
	}
	getUploadByList() {
		return axios.get(API_URL + 'cheques-data', { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}
}

export default new ChequeService();