import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import EntityService from '../../Services/ProfileManagement/EntityService';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DatePicker from "react-datepicker";
import { Link, withRouter } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddMultipleEntity from './CA/AddMultipleEntity';
import SponserEntityComponent from './CA/SponserEntityComponent'

function dateFormatter(cell, row) {
  cell = new Date(cell);
  return `${('0' + cell.getDate()).slice(-2)}/${('0' + (cell.getMonth() + 1)).slice(-2)}/${cell.getFullYear()}`;
} 

class PendingInviteEntityList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      entityList: [],
      expire_filter: "",
      my_entities: 0,
      paid_by_others: 0,
      total: 0,
      show_filter_popup: false,
    };
  }

  toggleFilterPopup = () => {
    this.setState({
      show_filter_popup: !this.state.show_filter_popup
    })
  };

  toggleMultipleEntity = (show_multiple_entity) => {
    this.setState({
      show_multiple_entity: show_multiple_entity
    })
  }

  toggleSponserEntity = (show_sponser_entity) => {
    this.setState({
      show_sponser_entity: show_sponser_entity
    })
  }

  componentDidMount() {
  }

  handleOnChange = (e) => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  setDate = (date) => {
    this.setState({ expire_filter: date })
  }

  applyFilter = () => {
    this.refs.table.handleFilterData({
      name: !!this.state.name_filter ? this.state.name_filter : "",
      type: !!this.state.type_filter ? this.state.type_filter : "",
      plan_name: !!this.state.license_filter ? this.state.license_filter : "",
      paid_by: !!this.state.paid_by_filter ? this.state.paid_by_filter : "",
    });
    if (!!this.state.expire_filter) {
      this.refs.end_date.applyFilter({
        date: new Date(this.state.expire_filter),
        comparator: '<='
      });
    }
  }

  goToEditView = (row) => {
    const { dispatch, history } = this.props;
    history.push({
      pathname: `/profile-management/pending/` + row.id
    });
  }
  getCaret = (direction) => {
    switch (direction) {
      case 'asc':
        return (<span className="icon-right-arrow-1 sort-topbottom-icon"></span>);
      case 'desc':
        return (<span className="icon-right-arrow sort-topbottom-icon"></span>);
      default:
        return (<span className="icon-Group-4449 sort-both-icon"></span>);
    }
  }

  displayEntities = (cell, row) => {
    // return <TagsList itemList={cell} list_length={20}/>
    let itemList = cell;
    if (itemList !== null && itemList.length !== 0) {
      let string_list = itemList.toString();
      if (string_list.length >= 30) {
        return <span>{string_list.substring(0, 30)} and
          <OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled" className="tbl-user-ent">
            <ul style={{ listStyleType: 'none' }} >
              {itemList.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          </Tooltip>}>
            <a className="td-more-text s-sbold font-colr">
              <span> {`${itemList.length} more`} </span>
            </a>
          </OverlayTrigger>
        </span>
      } else {
        return <span>{string_list}</span>
      }
    }
  }

  resetFilter = () => {
    this.setState({
      name_filter: "", type_filter: "", license_filter: "", paid_by_filter: "",
      paid_by_filter: "", expire_filter: ""
    }, () => this.applyFilter())
  }

  displayStatus = (cell, row) => {
    switch (cell) {
      case 1: return "Pending";
      case 2: return "Pending";//"License Expired";
      case 3: return "Pending";//"No license";
      case 4: return "Invite Expired";
      case 5: return "Rejected";
      default: return "Pending";
    }
  }

  displayEntitieName = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }
  displayType = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }
  displayPaidby = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{cell}</Tooltip>}>
      <div className='ellips-auto'>{cell}</div>
    </OverlayTrigger>
  }

  render() {

    const options = {
      onRowClick: (row) => {
        this.goToEditView(row);
      }
    };

    return (
      <>
        <div className="row main-table-listing mt-0 listing-icons">
          <div className="col-12">
            <div className="table-sec mb-0">
              {/* {this.props.entity_list.length === 0 && this.props.is_entity_loading===false ? */}
              {this.props.entity_list.length === 0 ?
                <h2 className="no-item-list">No items to display</h2>
                :
                <BootstrapTable ref='table' data={this.props.entity_list} responsive options={options}>
                  <TableHeaderColumn width="25%" isKey dataFormat={(cell, row) => !!cell ? this.displayEntitieName(cell, row) : "N/A"} dataField='name' dataSort={true} caretRender={this.getCaret}>Name </TableHeaderColumn>
                  <TableHeaderColumn width="18%" dataFormat={(cell, row) => !!cell ? this.displayType(cell, row) : "N/A"} dataField='type' dataSort={true} caretRender={this.getCaret}>Type </TableHeaderColumn>
                  <TableHeaderColumn width="9%" dataFormat={(cell) => !!cell ? cell : "N/A"} dataField='plan_name' dataSort={true} caretRender={this.getCaret}>License </TableHeaderColumn>
                  <TableHeaderColumn dataFormat={(cell, row) => !!cell ? this.displayPaidby(cell, row) : "N/A"} dataField='paid_by' dataSort caretRender={this.getCaret}>Paid By </TableHeaderColumn>
                  {/* <TableHeaderColumn dataFormat={(cell) => !!cell? cell : "N/A"} dataField='users' dataFormat={(cell, row) => this.displayEntities(cell, row)} dataSort columnTitle={(cell, row) => "\n\n\n\n"+cell.toString()} caretRender={ this.getCaret }>Users </TableHeaderColumn> */}
                  {/* <TableHeaderColumn dataFormat={(cell) => !!cell? cell : "N/A"} dataField='end_date'>Expires on </TableHeaderColumn> */}
                  <TableHeaderColumn width="180px" dataFormat={(cell) => !!cell ? cell : "N/A"} thStyle={{ textAlign: 'center' }} tdStyle={{ textAlign: 'center' }} ref='end_date' dataField='end_date' filter={{ type: 'DateFilter' }} dataSort={true} caretRender={this.getCaret}>License Expires</TableHeaderColumn>
                  <TableHeaderColumn width="160px" dataField='status' dataFormat={(cell, row) => this.displayStatus(cell, row)} >Status</TableHeaderColumn>
                </BootstrapTable>
              }
            </div>
          </div>
        </div>

        {/* <div className="list-top-right-sec pull-right refreshIconab d-none">
          <button onClick={() => this.props.refreshItem()} className="btn  d-none "><span className="icon-Group-3587"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span></button>
          <button onClick={() => this.props.getPendingInviteEntities()} className="btn ico-refresh-sec">
            <span className="icon-refresh"></span>
          </button>
        </div> */}
      </>
    );
  }
} 

export default withRouter(PendingInviteEntityList);