import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class CommunicationBar {

    getAllUser(){
        return axios.get(API_URL + 'all-mention-users', { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getACLEntityUsers() {
        return axios.get(API_URL + 'mention-users', { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    sendMentionUserNotification(data) {
        return axios.post(API_URL + 'notification',data, { headers: Header.authHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    storeMessageInDB(data){
        return axios.put(API_URL + 'communication-bar',data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getCommunicationbarStatus(data){
        return axios.post(API_URL + 'communication-bar',data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    // Ram changes ------------------------------------------------>>>>>

    // get notes
    getNotesList(data){
        return axios
        .post(API_URL + 'notes', data, {headers: Header.entityHeader()})
        .then((res)=>{
            return res.data.result.notes
        }).catch((err)=>{
        return err
        })
    }

    // create note
    createNote(note){
        return axios
        .post(API_URL + 'note', note, {headers: Header.entityHeader()})
        .then((res)=>{
        return res.data
        })
        .catch((error)=>{
        return error
        })
    }

    // update note
    updateNote(updatedNote){
        console.log(updatedNote)
        return axios
        .put(API_URL + `note`, updatedNote, {headers: Header.entityHeader()})
        .then((res)=>{
        console.log(res)
        return res.data
        })
        .catch((err)=>{
        return err
        })
    }

    // delete note
    deleteNote(deleteData){
        console.log(deleteData)
        return axios
        .delete(API_URL + "note" , { data: deleteData, headers: Header.entityHeader()})
        .then((res)=>{
            console.log(res)
            return res.data
        })
        .catch((err)=>{
            return err
        })
    }

    // get task
    getTaskList(data){
        return axios
        .post(API_URL + 'tasks', data, {headers: Header.entityHeader()})
        .then((res)=>{
            //console.log(res.data)
            return res.data
        }).catch((err)=>{
        return err
        })
    }

    // create task
    createTask(createData){
        return axios
        .post(API_URL + 'task', createData, {headers: Header.entityHeader()})
        .then((res)=>{
            console.log(res.data)
            return res.data
        }).catch((err)=>{
        return err
        })
    }

    // update task
    updateTask(updateData){
        return axios
        .put(API_URL + 'task', updateData, {headers: Header.entityHeader()})
        .then((res)=>{
            console.log(res.data)
            return res.data
        }).catch((err)=>{
        return err
        })
    }

    // mark done task
    markDoneTask(statusData){
        return axios
        .put(API_URL + 'task-status', statusData, {headers: Header.entityHeader()})
        .then((res)=>{
            console.log(res.data)
            return res.data
        }).catch((err)=>{
        return err
        })
    }

    // delete task
    deleteTask(deleteData){
        return axios
        .delete(API_URL + 'task', { data: deleteData, headers: Header.entityHeader()})
        .then((res)=>{
            console.log(res.data)
            return res.data
        }).catch((err)=>{
        return err
        })
    }

    // <<<----------------------------------------------------------------------->>>
}

export default new CommunicationBar();