import axios from 'axios';
import Header from '../Header';
import {API_URL} from '../../Utils/Config';

class AccountService {

  getAccountDetail() {
    return axios.get(API_URL + 'account', { headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

  updateAccount(data){
    return axios
      .put(API_URL + "account", data,  { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }
}

export default new AccountService();