export const allowNumbersOnly = (value) => {
    let numbers = /^[0-9]+$/;
    if ((value.match(numbers)) || value == "") {
        return true
    } else
        return false
}

export const allowAlphabetsOnly = (value) => {
    let alphabets = /^[a-zA-Z ]+$/;
    console.log("alphabets",alphabets.test(value))
    if (alphabets.test(value) || value == "") {
        return false
    } else
        return true
}

export const allowNumbersAndDecimalsOnly = (value) => {
    let numbers = /^[0-9]+$/;
    if ((value.match(numbers)) || value == "" || value.match(/^\d+(\.\d{1,2})?$/)) {
        return true
    } else
        return false
}

