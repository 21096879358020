import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function TDSIcon(props) {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">TDS</Tooltip>}>

        <span className="icon-TDS-icon icon-dt-td"><span className={"path1 " +(props.active ? "skyblue-icon " : " grayc-light-icons")}></span><span class="path2"></span><span class="path3 "></span><span class={"path4 " +(props.active ? " green-white-icon":" grayc-light-icons")}></span></span>

        {/* <span className={"icon-TDS-icon icon-td"+(props.active ? " ":" gray-st-icon")}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> */}

        {/* <span className={"icon-Group-3181 icon-td"+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span> */}
   </OverlayTrigger>

}
export default TDSIcon;