import React from 'react'
import DoughnutChart from '../../../Views/ui/component/DoughnutChart'
import HorizontalBarChart from '../../../Views/ui/component/HorizontalBarChart'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { indexOf, lowerFirst } from 'lodash';
import { Doughnut } from 'react-chartjs-2';
import _ from "lodash";

const EntitiesBoard = (props) => {

  let invites = []
  let data = [];
  let sent = 0;
  let onBoard = [];
  let pending = []
  let account = []
  let count = []
  let totalCount = []
  let noHashtags = 0
  let coloR = []
  let uniquehastagsEntities = [];

  for (let i in props.data['entity_invites']) {
    // console.log('propsboard',i);
    invites.push(props.data["entity_invites"][i])
  }


  invites.map((item) => {
    // if (item.type == 1) {
    //   sent.push(item.count)
    if (item.type == 2) {
      onBoard.push(item.count)
    } else if (item.type == 0) {
      pending.push(item.count)
    }
    //sent.push(item.count)
    sent = sent + item.count
  })

  for (let i in props.data['hashtag_entities']) {
    data.push(props.data['hashtag_entities'][i])
  }

  var dynamicColors = function () {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  for (var i in data) {
    coloR.push(dynamicColors());
  }
  data.map((elem) => {
    return (
      account.push(elem.account_hashtag != null ? elem.account_hashtag : 'No Hashtag'),
      count.push(elem.entities_arr.length),
      totalCount.push(elem.account_hashtag_id),
      noHashtags = elem.account_hashtag == null ? noHashtags + 1 : 0,
      uniquehastagsEntities.push(..._.uniq(elem.entities_arr, 'entity_id'))
    )
  })

  var tcounts = {};
  for (var i = 0; i < totalCount.length; i++) {
    tcounts[totalCount[i]] = 1 + (tcounts[totalCount[i]] || 0);
  }

  let newArr = []
  for (var i = 0; i < totalCount.length; i++) {
    if (newArr.indexOf(totalCount[i]) === -1) {
      newArr.push(totalCount[i]);
    }
  }

  let chartData = {
    labels: account,
    datasets: [{
      data: count,
      // backgroundColor: [
      //   'rgba(0,174,239,0.8)',
      //   'rgba(255,205,86,0.8)',
      //   'rgba(255,99,132,0.8)'
      // ]
      backgroundColor: coloR
    }],
    centerText: {
      display: true,
      text: "280"
    }
  }

  const plugins = [{
    beforeDraw: function (chart) {
      //let x = count.reduce((partial_sum, a) => partial_sum + a, 0)
      let x = uniquehastagsEntities.length//newArr.length + noHashtags
      var width = chart.width,
        height = chart.height,

        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";

      var text = x,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.fillText('#', textX, textY - 18);
      ctx.save();
    }
  }]

  const options = {
    plugins: {
      legend: false
    }
  }



  return (
    <div className="chart-box-new">
      {/* <div className="chart-box-new  d-flex flex-column justify-content-between"> */}
      <div className="row">
        {props.data['hashtag_entities'].length > 0 &&
          <div className="col-xl-6 col-lg-12">
            <h4 className="chart-box-heading">Entities</h4>

            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="chart-div">
                  <Doughnut data={chartData} plugins={plugins} options={options} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 pl-0">
                <div className="chart-content">
                  <div className="accordion-chart-scroll">
                    <div className="acch-chart-list">
                      <Accordion defaultActiveKey="">
                        {data.map((item, index) => {
                          return (
                            <Card className="acc-chart-card">
                              <Accordion.Toggle className="acch-card-header" as={Card.Header} eventKey={item.account_hashtag_id != null ? item.account_hashtag_id : data.length}>
                                {/* <div className="acch-label"> */}
                                <span className="chart-tag-color" style={{ background: coloR[index] }}></span>
                                <span className="label-acch"> {item.account_hashtag != null ? item.account_hashtag : "No Hashtag"}</span>
                                {/* </div> */}
                                {/* <div className="acch-iconbar"> */}
                                <span className="acch-chart-count">{item.entities_arr.length}</span>
                                <span className="icon-arrow-down-sign-to-navigate acch-down-arrow"></span>
                                {/* </div> */}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={item.account_hashtag_id != null ? item.account_hashtag_id : data.length}>
                                <Card.Body className="acch-card-body">
                                  <ul className="acch-entity-ul" >
                                    {
                                      item.entities_arr.map((obj, index) => {
                                        return <li key={index} className="acch-entity-li"> {obj.entity}</li>
                                      })
                                    }
                                  </ul>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          )
                        })}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="col-xl-6 col-lg-12">
          <h4 className="chart-box-heading">Invites</h4>
          <div className="row no-gutters invitesc">
            <div className="col-4 sky-blue-bg invitesc-card">
              <p>Sent</p>
              <p>{sent}</p>
            </div>
            <div className="col-4 green-bg invitesc-card">
              <p>Onboarded</p>
              <p>{onBoard.length > 0 ? onBoard : 0}</p>
            </div>
            <div className="col-4 orange-bg invitesc-card">
              <p>Pending for action</p>
              <p>{pending.length > 0 ? pending : 0}</p>
            </div>
          </div>
        </div>
      </div>
      {/* end row */}
      {/* <div></div>
      <div></div> */}
    </div>
  )
}

export default EntitiesBoard 