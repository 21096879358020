import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function AddNew(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
            {/* <span className="icon-plus s-add-icon green-white-all-icon" onClick={() => props.addNew()}></span> */}
            <div class="add-new-evi-sms"><span class="icon-plus"></span></div>
        </OverlayTrigger>
    </>
    // return <div className="add-new-evi-sms" onClick={() => props.addNew()} >
    //     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
    //         <span className="icon-plus"></span>
    //     </OverlayTrigger>
    // </div>
}
export default AddNew;