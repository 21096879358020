import { 
    SET_ENTITY_LIST_DATA,
    SET_SELECTED_ENTITY_LIST,
    SET_FEATURE_LIST,
    SET_DEFAULT_ROLES,
    SET_PERMISSION_ID,
 } from "./Types";

export const setEntityListData = (available_entities) => ({
  type: SET_ENTITY_LIST_DATA,
  payload: available_entities,
});
  
export const setSelectedEntities = (selected_entities) => ({
  type: SET_SELECTED_ENTITY_LIST,
  payload: selected_entities,
});
  
export const setFeatureList = (feature_list) => ({
    type: SET_FEATURE_LIST,
    payload: feature_list,
  }); 

export const setDefaultRoles = (default_roles) => ({
    type: SET_DEFAULT_ROLES,
    payload: default_roles,
  });

export const setPermissionIds = (permission_ids) => ({
    type: SET_PERMISSION_ID,
    payload: permission_ids,
  });

