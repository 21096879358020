import React from 'react'
import { Form, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setTransactionFilterData } from '../../Redux/Actions/MoneyLink';
import { allowNumbersOnly } from '../../Utils/RegaxValidation';
import CurrencyInput from 'react-currency-input-field';
import Button from 'react-bootstrap/Button';

class Filter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			// transaction_id_error: ''
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		if ((name === "from_amount" || name === "to_amount") && allowNumbersOnly(value) === false) {
			return
		} else if ((name === "snapshot_id") && allowNumbersOnly(value) === false) {
			return
		} else if ((name === "transaction_id") && allowNumbersOnly(value) === false) {
			return
		}
		let filter_data = {
			[name]: value
		}
		this.props.dispatch(setTransactionFilterData(filter_data));
	}

	amountChange = (name, value) => {
		if (value > 999999999.99) {
			return;
		}
		let filter_data = {
			[name]: value
		}
		this.props.dispatch(setTransactionFilterData(filter_data));
	}


	applyFilter = () => {
		this.props.applyFilter("apply");
		if (this.props.search) {
			this.props.reset()
			toast.warning("Removed search filter and applied filter data")
		}
		
		const { transaction_id, type, from_amount, to_amount, modified_by, is_star, voucher_status, is_evidence_linked, is_personal_filter, snapshot_id, messages } = this.props
		if (
			transaction_id === "" && type === "" && from_amount === "" && to_amount === "" && modified_by === "" && is_star === "" && voucher_status === "" && is_evidence_linked === "" && is_personal_filter === "" && snapshot_id === "" && messages === ""
		) {
			toast.error("Please specify filter criteria")
		}
		else {
			if (snapshot_id !== "") {
				this.props.onCardSelected(0)
				this.props.resetFinancialInstitute()
			}
			this.props.applyFilter("apply");
		}
	}

	resetFilter = () => {
		let filter_data = {
			transaction_id: '',
			type: '',
			from_amount: '',
			to_amount: '',
			modified_by: '',
			is_star: '',
			voucher_status: '',
			is_evidence_linked: '',
			is_personal_filter: '',
			snapshot_id: '',
			messages: ''
		}
		this.props.dispatch(setTransactionFilterData(filter_data));
		this.props.applyFilter("reset");
	}

	render() {
		return (
			<div className="main-filter-popup-new">
				<div className="row">
					<div className="col-lg-2 col-md-3">
						<div className="form-group">
							<input type="text" className="form-control" value={this.props.transaction_id} placeholder="ID" name="transaction_id" onChange={this.handleChange} />
						</div>
					</div>
					<div className="col-lg-2 col-md-3">
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" name="is_star" value={this.props.is_star} onChange={this.handleChange}>
										<option defaultValue value="Starred" >Starred</option>
										<option value={1}>Yes</option>
										<option value={0}>No</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec">
							<div className="col-lg col-md">
								<div className="form-group date-sec">
									<div className="inner-date-sec">
										<CurrencyInput type="text"
											className="form-control text-right"
											placeholder="From Amount "
											decimalsLimit={2}
											prefix="&#8377; "
											onValueChange={(value, name) => this.amountChange(name, value)}
											value={this.props.from_amount}
											allowNegativeValue={false}
											name="from_amount" />
									</div>
								</div>
							</div>
							<div className="col-lg-1 col-md-1">
								<hr className='hr-seprator'/>
							</div>
							<div className="col-lg col-md">
								<div className="form-group date-sec">
									<div className="inner-date-sec">
										<CurrencyInput type="text"
											className="form-control text-right"
											placeholder="To Amount "
											decimalsLimit={2}
											prefix="&#8377; "
											onValueChange={(value, name) => this.amountChange(name, value)}
											value={this.props.to_amount}
											allowNegativeValue={false}
											name="to_amount" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={"col-lg-2 col-md-3 " + (!!this.props.cr_dr_type ? " d-none " : "")}>
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" value={this.props.type}
										name="type" onChange={this.handleChange}>
										<option defaultValue value="CR/DR" >CR/DR</option>
										<option value={1}>CR</option>
										<option value={2}>DR</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 pl-lg-1 pl-xl-2">
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" name="modified_by" value={this.props.modified_by} onChange={this.handleChange} >
										<option value="" >Last Modified By</option>
										{this.props.modified_by_list.map((modified_by, index) => {
											return <option key={index} value={modified_by.id}>{modified_by.full_name}</option>
										})}
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div>
					<div className="col-lg-2 col-md-3">
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" value={this.props.voucher_status}
										name="voucher_status" onChange={this.handleChange}>
										<option defaultValue value="" >Voucher Info</option>
										<option value={4}>Advance</option>
										<option value={3}>Sent to Sync</option>
										<option value={2}>Sync Voucher Error</option>
										<option value={1}>Voucher Available</option>
										<option value={0}>Voucher Not Available</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div>
					{/* <div className="col-lg-2 col-md-3 ">
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" value={this.props.is_evidence_linked}
										name="is_evidence_linked" onChange={this.handleChange}>
										<option defaultValue value="" >Linked</option>
										<option value={1}>Yes</option>
										<option value={0}>No</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div> */}
					<div className="col-lg-2 col-md-3 ">
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" value={this.props.is_personal_filter}
										name="is_personal_filter" onChange={this.handleChange}>
										<option defaultValue value="" >Personal</option>
										<option value={1}>Yes</option>
										<option value={0}>No</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 ">
						<div className="form-group">
							<Form>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Control as="select" value={this.props.messages}
										name="messages" onChange={this.handleChange}>
										<option defaultValue value="" >Messages</option>
										<option value={1}>Yes</option>
										<option value={0}>No</option>
										<option value={2}>Unread</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
					</div>
					<div className="col-lg-2 col-md-3">
						<div className="form-group">
							<input type="text" className="form-control" value={this.props.snapshot_id} disabled={(!!this.props.cr_dr_type) ? "disabled" : ""} placeholder="Snapshot ID" name="snapshot_id" onChange={this.handleChange} />
						</div>
					</div>

					<div className={(!!this.props.cr_dr_type ? " col-xl-4 col-lg-4 col-md-6 " : " col-xl-2 col-lg-12 col-md-12 ")}>
						<div className="reset_apply_btn_new">
							<Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
							<Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
						</div>
					</div>
				</div>				
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const from_amount = all_value.from_amount || '';
	const to_amount = all_value.to_amount || '';
	const modified_by = all_value.modified_by || '';
	const is_star = all_value.is_star || '';
	const voucher_status = all_value.voucher_status || '';
	const transaction_id = all_value.transaction_id || ''
	const type = all_value.type || '';
	const is_evidence_linked = all_value.is_evidence_linked || '';

	// change filter name for is_personal as it is conflicting with form is_personal
	// const is_personal = all_value.is_personal || '';
	const is_personal_filter = all_value.is_personal_filter || '';
	const messages = all_value.messages || '';
	const snapshot_id = all_value.snapshot_id || '';

	//console.log(all_value)
	return {
		is_star, from_amount, to_amount, transaction_id, modified_by, type, voucher_status, is_evidence_linked, is_personal_filter, snapshot_id, messages
	};
}

export default connect(mapStateToProps)(Filter);
