import React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Button } from 'react-bootstrap';

// for profile picture
class ImageUpload extends React.Component {
  state = { warningMsg: "" };

  onDrop = (accepted, rejected) => {
    let file = accepted[0];
    console.log("file", ['.png', '.jpg', '.jpeg', '.PNG', '.JPG', '.JPEG'].indexOf(file.name))
    console.log("file", file.name)
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/JPG', 'image/PNG'];
    if (!acceptedImageTypes.includes(file.type)) {
      const message = "Please select image file jpeg,jpg,png";
      this.setState({ warningMsg: message });
      return true;
    } else if (!(file.name.includes('png') || file.name.includes('jpg') || file.name.includes('jpeg')
      || file.name.includes('PNG') || file.name.includes('JPG') || file.name.includes('JPEG'))) {
      const message = "Please select image file jpeg,jpg,png";
      this.setState({ warningMsg: message });
      return true;
    } else if (Object.keys(rejected).length !== 0) {
      const message = "Please select valid file type";
      this.setState({ warningMsg: message });
      return true;
    } else {
      if (accepted[0].size > 1024 * 1000 * this.props.profile_mgmt_filesize) {
        const message = "Please select file below 1MB";
        this.setState({ warningMsg: message });
        return true;
      }

      this.props.addFile(accepted, this.props.uploadtype);
      this.setState({ warningMsg: "" });

      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then(value => {
        // console.log(value);
      });
    }
  };

  onFileRemove = () => {
    this.props.removeFile(this.props.uploadtype);
  }

  render() {
    const { files } = this.props;
    const thumbsContainer = {
      maxWidth: "140px",
      maxHeight: "140px",
      objectFit: "cover",
      objectPosition: "center"
    };

    // const thumbs = files.map((file, index) => (
    //   <div key={index}>
    //     <div className="main-add-detail-image">
    //       <img style={thumbsContainer} src={file.preview} alt="profile" />
    //       <div className={"cross " + (this.props.editMode ? " d-none" : "")} onClick={() => this.onFileRemove()}><span>X</span></div>
    //     </div>
    //     <Dropzone
    //       multiple={false}
    //       accept="image/*"
    //       disabled={!!this.props.editMode}
    //       onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
    //     >
    //       {({ getRootProps, getInputProps }) => (
    //         <div {...getRootProps()}>
    //           <input {...getInputProps()} />
    //           <button type="button" className={"re-upload-btn btn save-btn ft-sz-18 white-color s-sbold " + (this.props.editMode ? " d-none" : "")}>Change Image</button>
    //         </div>
    //       )}
    //     </Dropzone>
    //   </div>
    // ));

    const thumbs = files.map((file, index) => (
      <div className="d-flex flex-column ">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div key={index} className="profile-upload-logo position-relative">
            <span class={"ss-cross-md " + (this.props.editMode ? " d-none" : "")} onClick={() => this.onFileRemove()}><span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span></span>
            <img style={thumbsContainer} src={file.preview} alt="profile" />
          </div>
          <Dropzone
            multiple={false}
            accept="image/*"
            disabled={!!this.props.editMode}
            onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="d-inline-block mt-2 ml-1 focus-none" {...getRootProps()}>
                <input {...getInputProps()} />
                <Button variant={"success apply_btn_new " + (this.props.editMode ? " d-none" : "")}>Change Image</Button>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    ));

    // const render =
    //   Object.keys(files).length !== 0 ? (
    //     files.map((file,i) => <aside key={i}>{thumbs}</aside>)
    //   ) : (
    //     <div className={"inner-details-upload-sec d-flex align-items-center justify-content-center " + (!!this.props.editMode ? "disable-bg" : "")}>
    //       <div>
    //        <span className="icon-Group-2328"></span>
    //        <p className="font-colr fs15 upload-text">Upload Your Image Maximum Size 1MB</p>
    //       </div>
    //     </div>
    //   );

    const render =
      Object.keys(files).length !== 0 ? (
        files.map((file, i) => <aside key={i}>{thumbs}</aside>)
      ) : (
        <div className={"profile-upload-logo " + (!!this.props.editMode ? "disable-bg" : "")}>
          <span className="icon-Group-2328 upload-icon"></span>
          <p className="profile-pi-size mb-0">Upload Your Image Maximum Size 1MB</p>
        </div>
      );

    return (
      <>
        {/* <small className="d-block error_left mb-2">{this.state.warningMsg}</small> */}
        {
          Object.keys(files).length == 0 ?
            <Dropzone
              style={{
                maxWidth: "25px",
                maxHeight: "240px",
                objectFit: "cover",
                objectPosition: "center",
                border: " 1px dashed"
              }}
              className="mt-2"
              multiple={false}
              accept="image/*"
              disabled={!!this.props.editMode}
              onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
            >
              {/* {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
              // for drag and drop warning statement
              if (isDragReject) return "Please submit a valid file";
              return render;
            }} */}

              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {render}
                </div>
              )}
            </Dropzone>
            :
            <aside>{thumbs}</aside>
        }
        <small className="d-block error-msgs mt-1">{this.state.warningMsg}</small>
      </>
    );
  }
}

function mapStateToProps(state) {
  const session_value = state.Session || {};
  const account_permissions = session_value.account_permissions || [];
  const system_parameter = session_value.system_parameter || {};
  const profile_mgmt_filesize = system_parameter.profile_mgmt_filesize || 0;
  return {
    account_permissions, profile_mgmt_filesize
  };
}

export default connect(mapStateToProps)(ImageUpload);
