import { CHEQUE_COUNT_DATA, CHEQUE_FILTER_DATA, CHEQUE_LIST, SET_IS_CHEQUE_LOADING, SET_PAGE_NO, SET_PER_PAGE_ITEM, SET_TASK_STAR, SET_TOTAL_COUNT } from "./Types";

export const setChequeLoadingStatus = (is_cheque_loading) => ({
  type: SET_IS_CHEQUE_LOADING,
  payload: is_cheque_loading,
});

export const setChequeCount = (cheque_count) => ({
  type: CHEQUE_COUNT_DATA,
  payload: cheque_count,
});

export const setTotalCount = (total_count) => ({
  type: SET_TOTAL_COUNT,
  payload: total_count,
});

export const setPageNo = (page_no) => ({
  type: SET_PAGE_NO,
  payload: page_no,
});

export const setStarTask = (cheque_id, changed_star) => ({
  type: SET_TASK_STAR,
  payload: { cheque_id: cheque_id, changed_star: changed_star }
});

export const setPagePerItem = (per_page_item) => ({
  type: SET_PER_PAGE_ITEM,
  payload: per_page_item,
});
export const setChequeFilter = (data) => ({
  type: CHEQUE_FILTER_DATA,
  payload: data,
});