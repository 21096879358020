import React from 'react'
import { Button, Form, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { setFilterData, setPageNo } from "../../Redux/Actions/TaskManager";
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
// import AddNew from '../IcoonNew/AddNew';
import { AddNew } from '../IcoonNew';
import OpenStatus from '../IcoonNew/OpenStatus';
import FinishedStatus from '../IcoonNew/FinishedStatus';
import OverdueStatus from '../IcoonNew/OverdueStatus';
import Pagination from '../../Components/BoilerPlate/Pagination';
import { changeDateFormate, DatePickerFormate, displayDateFormate } from '../../Helpers/DateFormat';
import StorageData from '../../Helpers/StorageData';
import AppliedFilterTag from '../../Components/BoilerPlate/AppliedFilterTag';
import { toast } from 'react-toastify';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import { valuesIn } from 'lodash';
import _ from "lodash";

class CreateTaskSideList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show_side_filter: false,
			task_id: props.match.params.task_id ? props.match.params.task_id : 0,

			is_star: this.props.is_star,
			due_start_date: this.props.due_start_date,
			due_end_date: this.props.due_end_date,
			assigned_by: this.props.assigned_by,
			assigned_to: this.props.assigned_to,
			priority: this.props.priority,
			page_changed : false,
		}
		this.VIEW_ONLY = _.isEqual([3, 9, 11, 14, 17, 23], this.props.features_permissions_list)
	}

	toggleFilter = () => {
		this.setState({ show_side_filter: !this.state.show_side_filter })
	}


	handleOnChange = (e) => {
		const { name, value } = e.target;
		if (name == "search") {
			this.props.changeSearchText(value)
		}
		this.setState({ [name]: value })
	}

	setDate = (date, key) => {
		this.setState({ [key]: date })
	}

	onMultiselectSelect(selectedList, selectedItem, key) {
		this.setState({ [key]: selectedList })
	}

	onMultiselectRemove(selectedList, removedItem, key) {
		this.setState({ [key]: selectedList })
	}

	// -------------------> Ram

	searchFilter = (e) => {
		e.preventDefault()
		if (this.props.searchText) {
			this.props.searchFilter(this.props.searchText);
			this.props.changeSearchStatus(true)
			this.setState({ show_side_filter: false })
		}
		else {
			toast.error("Please specify search criteria")
		}
	}

	resetSearchFilter = (e) => {
		e.preventDefault();
		this.props.changeSearchText("")
		this.props.changeSearchStatus(false)
		this.props.searchFilter("")
	}

	checkForEnterButton = (e) => {
		if (e.charCode === 13) {
			if (this.props.searchText) {
				this.searchFilter(e)
			} else {
				e.preventDefault();
			}
		} else {
			// this.setState({ search: e.target.value })
			this.props.changeSearchText(e.target.value)
		}
	}

	// ---------------------> Ram

	applyFilter = () => {
		if (
			(this.state.is_star == null || this.state.is_star == "") &&
			this.state.due_start_date == "" &&
			this.state.due_end_date == "" &&
			this.state.assigned_by.length == 0 &&
			this.state.assigned_to.length == 0 &&
			this.state.priority == ""
		) {
			toast.error("Please specify filter criteria")
		}
		else {
			if (this.props.filter_by === 1) {
				toast.error("Removed search filter and applied filter data")
				this.props.changeSearchStatus(false)
				this.props.changeSearchText("")
			}
			let filter_data = {
				is_star: this.state.is_star,
				due_start_date: this.state.due_start_date || null,
				due_end_date: this.state.due_end_date || null,
				assigned_by: this.state.assigned_by,
				assigned_to: this.state.assigned_to,
				priority: this.state.priority || null,
			}
			this.setState({
				...filter_data,
				show_side_filter: false,page_changed:true
			})
			this.props.dispatch(setFilterData(filter_data));
			this.props.applyFilter("apply");
			// this.state.show_side_filter(false)
		}
	}

	resetFilter = () => {
		if (this.props.filter_by === 2) {
			if (
				(this.state.is_star == null || this.state.is_star == "") &&
				this.state.due_start_date == "" &&
				this.state.due_end_date == "" &&
				this.state.assigned_by.length == 0 &&
				this.state.assigned_to.length == 0 &&
				this.state.priority == ""
			) {
				toast.error("No filter applied to reset")
			}
			else {
				let filter_data = {
					is_star: '',
					due_start_date: "",
					due_end_date: "",
					assigned_by: [],
					assigned_to: [],
					priority: "",
				}
				this.setState({
					...filter_data,
					// show_side_filter: false,
					page_changed:true
				})
				this.props.dispatch(setFilterData(filter_data));
				this.props.applyFilter("reset");
			}
		}

		// if(this.props.filter_by === 1){
		// 	toast.error(" Search filter is applied ")
		// }

	}

	// componentDidUpdate(prevProps) {
	// 	if (!(JSON.stringify((this.props.filter_data)) === JSON.stringify(prevProps.filter_data))) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
	// 	{
	// 		this.setState({
	// 			...this.props.filter_data
	// 		})
	// 	}
	// }

	taskSelected = (task) => {
		this.props.history.push("/task-manager/create/" + task.id)
	}
	taskSelectedSingle(id){
		this.props.history.push("/task-manager/create/" + id)
	}

	newTask = (task) => {
		this.props.history.push("/task-manager/create/")
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.match.params.task_id !== this.props.match.params.task_id)) {
			this.setState({
				task_id: nextProps.match.params.task_id , page_changed :false
			})
		}
	}

	getTaskList = (page_no) => {
		
			this.props.dispatch(setPageNo(page_no))
			this.setState({ page_changed: true })
	}

	taskStatus = (status) => {
		switch (status) {
			case 1:
				return <OpenStatus />
			case 2:
				return <FinishedStatus />
			case 3:
				return <OverdueStatus />
		}
	}

	getCardFilterName = () => {
		switch (this.props.filter_card) {
			case 0:
				return "Total"
			case 1:
				return "Open"
			case 2:
				return "Overdue"
			case 3:
				return "Finished"
			case 4:
				return "Assigned to me"
			case 5:
				return "Assigned by me"
			default:
				return ""
		}
	}

	getDueDateRange = () => {
		if (this.props.due_start_date || this.props.due_end_date)
			return 'Due: ' + changeDateFormate(this.props.due_start_date) + ' to ' + changeDateFormate(this.props.due_end_date)

	}

	getAssignedBy = () => {
		let temp = this.props.assigned_by.map(item => {
			return item.full_name
		})
		if (temp.toString())
			return 'Assigned By: ' + temp.toString()
	}

	getAssignedTo = () => {
		let temp = this.props.assigned_to.map(item => {
			return item.full_name
		})
		if (temp.toString())
			return 'Assigned To: ' + temp.toString()
	}

	getStared = () => {
		if (this.props.is_star)
			return this.props.is_star == 1 ? "Star - Yes" : "Star - No"
	}


	getPriority = () => {
		switch (this.props.priority) {
			case '1':
				return "High Priority"
			case '2':
				return "Normal Priority"
			default:
				return null
		}

	}

	// getDateRange = (financial_year_id = this.props.financial_year_name) => {
	// 	if (financial_year_id == 6) {
	// 		return changeDateFormate(this.props.filter_start_date) + ' to ' + changeDateFormate(this.props.filter_end_date)
	// 	} else {
	// 		if (financial_year_id === '' || typeof financial_year_id === 'undefined')
	// 			financial_year_id = StorageData.getDefaultFinancialYear().year_type
	// 		let arr = StorageData.getFinancialYear();

	// 		let financial_year_name = arr.find((year) => {
	// 			if (year.id == financial_year_id)
	// 				return year
	// 		})
	// 		return financial_year_name.name
	// 	}
	// }

	getDateRange = () => {
		let default_yr = StorageData.getDefaultFinancialYear();
		console.log(":props",default_yr)
		// if (this.props.financial_year_name === "Custom Date Range") {
		// 	return displayDateFormate(default_yr.start_date) + ' to ' + displayDateFormate(default_yr.end_date)
		// } else {
		// 	return this.props.financial_year_name
		// }

		
		switch(default_yr.year_type){
			case '1' :
				return "Current Financial Year"
			case '2' :
				return "Last Financial Year"
			case '3' : 
				return "This Month"
			case '4' :
				return "Last 3 Months"
			case '5' :
				return "Since Beginning"
			case '6' :
				return displayDateFormate(default_yr.start_date) + ' to ' + displayDateFormate(default_yr.end_date)
			default :
				return ""

		}
	}

	render() {
		console.log("task details::::",this.props.task_detail)
		let task_id_found = true;
		let taskFound = this.props.task_list.findIndex(task => task.id == this.props.match.params.task_id);
		if (taskFound == -1) {
			task_id_found = false
		  } else {
			task_id_found = true;
		  }
		return (
			<>
				<div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 sidebar-listing">
					<div className="sidebar-fixed">
						{/* <div className="row">
							<div className="col-sm-12"> */}
						<div className="sidebar-new-header">
							<div className="breadcrumb-header">
								<BreadCrum level={2} />
							</div>
							<h3 className="sidebar-icons">
								{/* <div className="sidebar-evidence-filter" onClick={() => this.toggleFilter()}> */}
								<div className={this.props.filter_by == 2 ? 'filler_snapcr active-filter' : 'filler_snapcr'} onClick={() => this.toggleFilter()} >
									<span className="icon-filter green-icon s-filter-icon"></span>
								</div>
								<span className='s-plus-icon'>{!this.VIEW_ONLY && <AddNew addNew={this.newTask} />}</span>
							</h3>
							{/* <div className="sbleft">
								<h3 className="heading-shi">Task Manager </h3>
							</div>
							<div className="sright">
								<h3 className="heading-shi mb-0">
									<div className="sidebar-evidence-filter" onClick={() => this.toggleFilter()}>
										<span className="icon-filter green-icon"></span>
									</div>
									{!this.VIEW_ONLY && <AddNew addNew={this.newTask} />}
								</h3>
							</div> */}
						</div>
						{/* </div>
						</div> */}
						
						{/* {this.state.show_side_filter == '0' ? */}

						{/* <div className="sidebar-sec"> */}
						{/* sidebar-sec */}
						<div className={"sidebar-new-sec " + (this.state.show_side_filter ? "remove-new-scroller " : "")}>
							{this.state.show_side_filter ?
								<>
									{/* Filter Form */}
									<div className="side-filter">
										<div className="row">
											<div className="col-12">
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Task</Tooltip>}>
													<form className="form-group search-side-sec">
														<input type="text"
															className="form-control"
															placeholder="Search"
															name="search"
															onKeyPress={this.checkForEnterButton}
															value={this.props.searchText}
															onChange={(e) => this.handleOnChange(e)}
														/>
														{this.props.isSearchApplied === false ?
															<Button className="active-search-icon" onClick={this.searchFilter}>
																<span className="icon-loupe"></span>
															</Button>
															:
															<Button className="active-search-icon crosebtn" onClick={this.resetSearchFilter}>
																<span class="icon-cancel"></span>
															</Button>
														}
													</form>
												</OverlayTrigger>
											</div>

											<div className="col-12">
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.state.is_star}
														name="is_star" >
														<option selected value="" >Starred</option>
														<option value="1">Yes</option>
														<option value="0">No</option>
														{/* <option value="">All</option> */}
													</select>
												</div>
											</div>

											{/* <div className="col-lg-12 col-sm-12">
												<div className="form-group">
													<p class="blu-color s-sbold mb-1">Due Date</p>
												</div>
											</div> */}

											<div className="col-lg-12 col-sm-12">
												<div className="form-group">
													<p class="blu-color s-sbold mb-1">Due Date</p>
													<DatePicker
														dateFormat={DatePickerFormate()}
														onChange={date => this.setDate(date, "due_start_date")}
														className="form-control datepicker-icon"
														selected={this.state.due_start_date}
														maxDate={!!this.state.due_end_date ? new Date(this.state.due_end_date) : ""}
														autoComplete="off"
														placeholderText="From Date"
														name="due_start_date" />
												</div>
											</div>

											<div className="col-lg-12 col-sm-12">
												<div className="form-group ">
													<DatePicker
														dateFormat={DatePickerFormate()}
														onChange={date => this.setDate(date, "due_end_date")}
														className="form-control datepicker-icon"
														selected={this.state.due_end_date}
														minDate={new Date(this.state.due_start_date)}
														autoComplete="off"
														placeholderText="To Date"
														name="due_end_date" />
												</div>
											</div>

											<div className="col-12">
												<div className={"form-group cursor-cmenu assignedby-icon assign-by " + (this.state.assigned_by.length >= 1 ? "assign-select" : "")}>
													<Multiselect
														className="form-control"
														options={this.props.assigned_by_arr}
														selectedValues={this.state.assigned_by}
														displayValue="full_name"
														onSelect={(selectedList, selectedItem) => this.onMultiselectSelect(selectedList, selectedItem, "assigned_by")}
														onRemove={(selectedList, removedItem) => this.onMultiselectRemove(selectedList, removedItem, "assigned_by")}
														placeholder="Assigned By"
														closeOnSelect="false"
														id="search_input1"
														showCheckbox={true}
													/>
												</div>
											</div>
											<div className="col-12">
												<div className={"form-group cursor-cmenu assignedby-icon assign-by " + (this.state.assigned_to.length >= 1 ? "assign-select" : "")}>
													<Multiselect
														className="form-control"
														options={this.props.assigned_to_arr}
														selectedValues={this.state.assigned_to}
														onSelect={(selectedList, selectedItem) => this.onMultiselectSelect(selectedList, selectedItem, "assigned_to")}
														onRemove={(selectedList, removedItem) => this.onMultiselectRemove(selectedList, removedItem, "assigned_to")}
														displayValue="full_name"
														placeholder="Assigned To"
														closeOnSelect="false"
														id="search_input2"
														showCheckbox={true}
													/>
												</div>
											</div>

											<div className="col-12">
												{/* <div className={"form-group " + (this.state.assigned_by.length >= 1 ? "assign-select" : "")}> */}
												<div className="form-group">
													<select className="form-control"
														onChange={this.handleOnChange}
														value={this.state.priority}
														name="priority"
														placeholder="Select priority">
														<option selected disabled value="">Priority</option>
														<option value="1">High Priority</option>
														<option value="2">Normal Priority</option>
														<option value="">All</option>
													</select>
												</div>
												{/* </div> */}
											</div>

											<div className="col-12">
												<div className="center_apply_btn_new">
													<Button variant="outline-success reset_btn_new" onClick={this.resetFilter}>Reset</Button>
													<Button variant="success apply_btn_new" onClick={this.applyFilter}>Apply</Button>
												</div>
											</div>
										</div>
									</div>
									{/* End Filter Form */}
								</>
								:
								<>
									{/* Tags */}
									<div className="sidebar-tag-list">
										{/* <span className="sidebar-tagname">Current Financial Year</span> */}
										<AppliedFilterTag tagValue={this.getDateRange()} />
										<AppliedFilterTag tagValue={this.getDueDateRange()} />
										<AppliedFilterTag tagValue={this.getCardFilterName()} />
										<AppliedFilterTag tagValue={this.getPriority()} />
										<AppliedFilterTag tagValue={this.getAssignedBy()} />
										<AppliedFilterTag tagValue={this.getAssignedTo()} />
										<AppliedFilterTag tagValue={this.getStared()} />
									</div>
									{/* End Tags */}
								</>
							}

							{/* Cards */}
							{/* <div className="sidebar-cards active">
								<div className="d-flex">
									<p className="titlesc ellipsisc">Test task</p>
									<div className="iconsc">
										icon
									</div>
								</div>
								<div className="d-flex">
									<p className="flex-6 namesc ellipsisc">Pratyush J. Accountant</p>
									<p className="flex-4 datesc">14-09-2022</p>
								</div>
							</div> */}

							{this.state.task_id == 0 &&
								<div className="sidebar-cards active">
									<h3 className="new-evidance">New Task</h3>
								</div>
							}

							{/* <div className="sidebar-cards">
								<h3 className="new-evidance">No items to display</h3>
							</div> */}

							{!task_id_found && !this.state.page_changed ?

							<div className={"sidebar-cards cpointer" + (this.props.task_detail.task_id == this.state.task_id ? " active" : "") + (this.props.task_detail.status == 2 ? " card-opacity" : "")} onClick={() => this.taskSelectedSingle(this.props.task_detail.task_id)}>
							<div className="cards-sec-center">
								{/* <p className="titlesc ellipsisc">{task.task}</p> */}
								<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.task_detail.task}</Tooltip>}>
								<p className="titlesc ellipsisc">{this.props.task_detail.task}</p></OverlayTrigger>

								{this.taskStatus(this.props.task_detail.status)}
								{/* <div className="iconsc"></div> */}
							</div>
							<div className="cards-sec-center">
								<p className="flex-6 namesc ellipsisc">{this.props.task_detail.assigned_by_name}</p>
								<p className="flex-4 datesc text-right">{this.props.task_detail.due_date}</p>
							</div>
						</div>

							 : this.props.task_list.length === 0 ?
								<div className="sidebar-cards">
									<h3 className="no-item">No items to display</h3>
								</div>
								:
								this.props.task_list.map((task, index) => {
									return <>
										<div className={"sidebar-cards cpointer" + (task.id == this.state.task_id ? " active" : "") + (task.status == 2 ? " card-opacity" : "")} onClick={() => this.taskSelected(task)}>
											<div className="cards-sec-center">
												{/* <p className="titlesc ellipsisc">{task.task}</p> */}
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{task.task}</Tooltip>}>
												<p className="titlesc ellipsisc">{task.task}</p></OverlayTrigger>

												{this.taskStatus(task.status)}
												{/* <div className="iconsc"></div> */}
											</div>
											<div className="cards-sec-center">
												<p className="flex-6 namesc ellipsisc">{task.assigned_by}</p>
												<p className="flex-4 datesc text-right">{task.due_date}</p>
											</div>
										</div>

										{/* {[...Array(10)].map((elementInArray, index) => (
											<div className="" key={index}> Whatever needs to be rendered repeatedly... </div>
										)
										)} */}
									</>
								})
							}
							{/* End Cards */}
						</div>{/* End sidebar-sec */}
						{!task_id_found && !this.state.page_changed ? <></> :
						<Pagination
							getList={this.getTaskList}
							has_previous={this.props.has_previous}
							has_next={this.props.has_next}
							page_no={this.props.page_no}
							per_page_items={this.props.per_page_items}
							total_pages={this.props.total_pages}
						/>
						}
					</div>
				</div>{/* End Column */}

				{/* <div className="sidebar-new-header">
					<div className="breadcrumb-header">
						<BreadCrum level={2} />
					</div>
					<div className="">
						<div className="sidebar-evidence-filter" onClick={() => this.toggleFilter()}>
							<span className="icon-filter"></span>
						</div>
						{!this.VIEW_ONLY && <AddNew addNew={this.newTask} />}
					</div>
				</div> */}
			</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.TaskManager

	const filter_data = all_value.filter_data || {};
	const assigned_by = all_value.assigned_by || [];
	const assigned_to = all_value.assigned_to || [];
	const is_star = all_value.is_star || '';
	const due_start_date = all_value.due_start_date || null;
	const due_end_date = all_value.due_end_date || null;
	const priority = all_value.priority || null;

	const financial_year_name = all_value.financial_year_name;
	const filter_start_date = all_value.filter_start_date;
	const filter_end_date = all_value.filter_end_date;
	const filter_card = all_value.filter_card;

	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	return {
		filter_data, is_star, due_start_date, due_end_date, assigned_by, assigned_to, priority,
		financial_year_name, filter_start_date, filter_end_date, filter_card, features_permissions_list
	};
}

export default connect(mapStateToProps)(withRouter(CreateTaskSideList));