import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { displayDateFormate } from '../../../Helpers/DateFormat';
import { ArrowUpDownIcon, ReadyStatus, NotReadyStatus, ProcessedStatus, SyncedStatus, LinkedStatus } from '../../../Views/IcoonNew';
import NotProcessedStatus from '../../IcoonNew/NotProcessedStatus'
import { showAmount, rounding } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from "lodash";
import { setBulkTransactionList } from '../../../Redux/Actions/MoneyLink';

class TransactionCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}

	getStatus = (status) => {
		switch (status) {
			// case 1:
			// 	return <ReadyStatus />
			// case 2:
			// 	return <NotReadyStatus />
			// case 3:
			// 	return <ProcessedStatus />
			// case 4:
			// 	return <LinkedStatus />
			// case 5:
			// 	return <SyncedStatus />
			// case 6:
			// 	return <NotProcessedStatus />
			// default:
			// 	return "--"
			case 1:
				return <NotReadyStatus />
			case 2:
				return <ReadyStatus />
			case 3:
				return <NotProcessedStatus />
			case 4:
				return <ProcessedStatus />
			case 5:
				return <SyncedStatus />
			default:
				return "--"
		}
	}

	removeTransaction = () => {
		let bulk_transaction_list = this.props.bulk_transaction_list.filter(transaction => transaction.id != this.props.transaction.id);
		this.props.dispatch(setBulkTransactionList(bulk_transaction_list));

		let total_amount = bulk_transaction_list.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
		this.props.removeTransaction(rounding(total_amount), bulk_transaction_list);
	}

	render() {
		return (
			<div className="sidebar-cards">
				<span class="ss-cross" onClick={() => this.removeTransaction()}>
					<span class="icon-Cross-with-grey-circle"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
				</span>

				<div className="cards-sec-center">
					<p className="datesc mr-auto ellipsisc">ID #{this.props.transaction.id}</p>
					{this.getStatus(this.props.transaction.status)}
				</div>
				<div className="cards-sec-center">
					<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.transaction.financial_institute}</Tooltip>}>
						<p className="titlesc titlesc-2 ellipsisc">{this.props.transaction.financial_institute}</p>
					</OverlayTrigger>
				</div>
				<div className="cards-sec-center">
					<p className="flex-6 datesc text-left">{displayDateFormate(this.props.transaction.date)}</p>

					<div className="flex-4 amountsc">
						<span className="ellipsisc">
							<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span className={this.props.transaction.type === 1 ? "green-color" : "red-color"}>{showAmount(this.props.transaction.amount)}</span></Tooltip>}>
								<span className={this.props.transaction.type === 1 ? "green-color" : "red-color"}>{showAmount(this.props.transaction.amount)}</span>
							</OverlayTrigger>
						</span>
						<ArrowUpDownIcon expense={this.props.transaction.type == 1 ? false : true} />
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const is_transaction_loading = all_value.is_transaction_loading;
	const filter_by = all_value.filter_by
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	const bulk_transaction_list = all_value.bulk_transaction_list || []

	return {
		is_transaction_loading, filter_by, features_permissions_list, all_value, bulk_transaction_list
	};
}

export default connect(mapStateToProps)(withRouter(TransactionCard));