import React from "react";
// import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import ProgressBar from 'react-bootstrap/ProgressBar';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import { changeDateFormate, DatePickerFormate } from '../../Helpers/DateFormat';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import StorageData from "../../Helpers/StorageData";
import MasterService from "../../Services/MasterService";
import RecentActivities from "../../Services/RecentActivities";
import ReportService from "../../Services/Reports/ReportService";
import { toast } from "react-toastify";
import { setAppilcationLoder } from "../../Redux/Actions/App";
import { validateReports } from "./ReportsValidation";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, Modal } from 'react-bootstrap';
import OtpInput from '../../Components/BoilerPlate/OtpInput';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import axios from 'axios';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


class ReportsView extends React.Component {

  constructor(props) {
    super(props)
    let financial_years_arr = StorageData.getFinancialYear();
    let default_yr = StorageData.getDefaultFinancialYear();
    this.state = {
      year_type: default_yr.year_type,
      financial_years_arr: financial_years_arr ? financial_years_arr : [],
      start_date: default_yr.start_date,
      end_date: default_yr.end_date,
      year_type_index: financial_years_arr ? financial_years_arr.findIndex((year) => year.id == default_yr.year_type) : 0,
      export_type: 'ule',
      export_doc_url: '',
      is_loading: false,
      error: false,
      errors: {},
      financial_institute_list: [],
      financial_institute_id: 0,
      exportTitle: '',
      showOTPPopup: false,
      otp: null,
      gstr2b_id: null,
      gstr2b_date: null,
      gstr2b_show_date: null,
      gstr2b_error: null,
      ip_address: "127.0.0.0",
      previous_return_period: null
    }

    this.otpbox = React.createRef();
  }

  handleExportType = (e) => {
    console.log(e.target.id)
    this.setState({ export_type: e.target.id, gstr2b_show_date: null, otp: null, gstr2b_id: null, gstr2b_error: null })
    this.state.exportTitle = e.target.title
  }

  handleExportDownload = async () => {
    this.setState({ gstr2b_error: null })
    if (this.state.export_type == "gstr2b") {

      if (!this.state.gstr2b_show_date) {
        await confirm({
          confirmation: "Please select date",
          options: { type: "alert" }
        })
        return;
      }

      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      if (this.state.previous_return_period) {
        let selected_date = new Date(this.state.previous_return_period);
        const year = selected_date.getFullYear();
        const month = selected_date.getMonth();

        this.props.dispatch(setAppilcationLoder(true, "Fetching data for " + months[month] + " " + year));
      } else {
        let selected_date = new Date(this.state.gstr2b_date);
        const year = selected_date.getFullYear();
        const month = selected_date.getMonth();

        this.props.dispatch(setAppilcationLoder(true, "Fetching data for " + months[month] + " " + year));
      }

      let detail = {
        "return_period": this.state.gstr2b_date,
        "otp": this.state.otp,
        "id": this.state.gstr2b_id,
        "ip_address": this.state.ip_address,
        "previous_return_period": this.state.previous_return_period
      }

      // let data = {message: "OTP Request Sent", response_code: 200, result: {id: 4}, status: "success"}
      //console.log(detail)
      //return
      ReportService.getGSTR2bReport(detail).then(
        async (data) => {
          //console.log(data)
          this.props.dispatch(setAppilcationLoder(false));
          this.setState({ gstr2b_error: null })
          let default_entity_detail = StorageData.getDefaultEntity() || {};
          try {
            let arrayBufferConverted = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data)));
            if (arrayBufferConverted.response_code == 400) {
              let message = "Some error occured. Please try again.";
              if (!!arrayBufferConverted.reason.previous_return_period) {
                this.setState({ previous_return_period: arrayBufferConverted.reason.previous_return_period }, () => {
                  //this.props.dispatch(setAppilcationLoder(true, "Fetching prediction data.."));
                  this.handleExportDownload();
                  return;
                })
              } else if (!!arrayBufferConverted.reason.otp_request) {
                message = arrayBufferConverted.reason.otp_request;
                this.setState({ gstr2b_error: message, showOTPPopup: false })
                return;
              } else if (!!arrayBufferConverted.reason.authtoken) {

                this.setState({ gstr2b_error: arrayBufferConverted.reason.authtoken })
                this.otpbox.current.clearOTP()
                return;
              } else if (!!arrayBufferConverted.reason.gstr2b) {
                message = arrayBufferConverted.reason.gstr2b
                this.setState({ gstr2b_error: message, showOTPPopup: false, otp: null, gstr2b_id: null })
                return;
              } else if (!!arrayBufferConverted.reason.gst_username) {
                message = arrayBufferConverted.reason.gst_username
                this.setState({ gstr2b_error: message, showOTPPopup: false, otp: null, gstr2b_id: null })
                return;
              } else if (!!arrayBufferConverted.reason.gst_no) {
                message = arrayBufferConverted.reason.gst_no
                this.setState({ gstr2b_error: message, showOTPPopup: false, otp: null, gstr2b_id: null })
                return;
              } else {
                toast.error(message)
              }
              this.setState({ previous_return_period: null })

            } else if (!!arrayBufferConverted.result) {

              if (!!arrayBufferConverted.result.id) {
                this.setState({ showOTPPopup: true, gstr2b_id: arrayBufferConverted.result.id })
              }
            }
          } catch {

            //console.log()
            var blobdata = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(blobdata);
            let a = document.createElement('a');
            a.href = url;
            //let entityName = toTitleCase(this.props.entity_name)


            let selected_date = new Date(this.state.gstr2b_show_date);
            //console.log(selected_date)
            const year = selected_date.getFullYear();
            const month = selected_date.getMonth();

            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            a.download = "Buktec-" + default_entity_detail.name + "-GSTR 2B Report-" + months[month] + " " + year + ".xlsx";
            a.click();

            //this.refreshItem()
            this.setState({ showOTPPopup: false, gstr2b_id: null, otp: null })
          }



        })
    } else {
      let validation = validateReports(this.state.start_date, this.state.end_date)
      if (Object.keys(validation).length > 0) {
        this.setState({
          error: true,
          errors: validation
        })
      } else {
        this.props.dispatch(setAppilcationLoder(true));
        let start_date = changeDateFormate(this.state.start_date)
        let end_date = changeDateFormate(this.state.end_date)
        RecentActivities.getExportDoc(this.state.export_type, start_date, end_date, this.state.financial_institute_id).then(
          (data) => {
            if (data.response_code == 200) {
              // console.log(data)
              // console.log(start_date, end_date)

              if (data.result.url) {
                // console.log("----url----",data.result_url)
                window.open(data.result.url, '_blank')
                this.setState({ export_doc_url: data.result.url, error: false })
                this.props.dispatch(setAppilcationLoder(false));
              }
              else {
                this.props.dispatch(setAppilcationLoder(false));
                toast.error("No data to export")
              }
            }
            else {
              this.props.dispatch(setAppilcationLoder(false));
              toast.error(data.message)
            }
          }
        )
      }
    }
  }

  selectYearHandler = (selectedList, selectedItem) => {
    this.onYearChange(selectedItem.id)
  }

  setDate = (date, key) => {
    this.setState({ [key]: date }, () => {
      if (!!this.state.start_date && !!this.state.end_date) {
        let fy = {
          id: 6,
          start_date: changeDateFormate(this.state.start_date),
          end_date: changeDateFormate(date),
          name: "Custom Date Range"
        };
        let financial_years_arr = this.state.financial_years_arr.map(fin_year => {
          return (fin_year.id == 6) ? fy : fin_year;
        });
        StorageData.setDefaultFinacialYear(6);
        StorageData.setFinancialYear(financial_years_arr);
      }
    });


    /* old code
    console.log(date)
    if(key == "start_date"){
      this.setState({ [key]: date, errors: {...this.state.errors, start_date: ""}})
    }
    
    if(key == "end_date"){
      this.setState({ [key]: date, errors: {...this.state.errors, end_date: ""}})
    }*/
  }

  onYearChange = (year_type = 1) => {
    let start_date = this.state.financial_years_arr.find(x => x.id == year_type).start_date;
    let end_date = this.state.financial_years_arr.find(x => x.id == year_type).end_date;
    // console.log(year_type)
    this.setState({
      year_type: year_type,
      start_date: start_date ? new Date(start_date) : "",
      end_date: end_date ? new Date(end_date) : "",
      error: false,
    }, () => {
      if (year_type != 6) {
        //   let year_name = this.state.financial_years_arr.find(x => x.id == year_type).name
        //   console.log(year_name)
        //   this.props.dispatch(setFinancialYearName(year_name))
        StorageData.setDefaultFinacialYear(year_type);
      }
    })
  }

  getFinancialYear = () => {
    MasterService.getFinancialYear().then(
      (data) => {
        if (data.response_code == 200) {
          let fy = {
            id: 6,
            start_date: "",
            end_date: "",
            name: "Custom Date Range"
          };
          let financial_years_arr = [...data.result.date_filter, fy];
          this.setState({ financial_years_arr }, () => this.onYearChange());
          StorageData.setFinancialYear(financial_years_arr);

          let default_yr = StorageData.getDefaultFinancialYear();
          this.setState({
            start_date: default_yr.start_date,
            end_date: default_yr.end_date,
          });
        }
      });
  }

  onFinancialInstituteChange = (e) => {
    this.setState({ financial_institute_id: e.target.value })
  }

  getFinancialInstituteList = () => {
    MasterService.getPaymentDetail().then(
      (data) => {
        if (data.response_code == 200) {
          let dataList = data.result.payment_details.filter((item) => {
            if (item.is_active === 1) {
              return item
            }
          })
          let newList = dataList.map((item) => {
            return (
              { ...item, "view_Name": `${item.bank_name} ${item.account_number}` }
            )
          })
          let firstItem = {
            account_number: null,
            bank_name: null,
            branch: null,
            city_name: null,
            id: 0,
            ifsc_code: "SBIN00059677",
            view_Name: "All"
          }
          newList.unshift(firstItem)
          this.setState({
            financial_institute_list: newList,
          });
        } else {
          toast.error(data.message);
        }
      });
  }

  componentDidMount = () => {
    let financial_years_arr = StorageData.getFinancialYear();
    if (!financial_years_arr) {
      this.getFinancialYear();
    }
    this.getFinancialInstituteList();
    this.getIP()
  }

  getIP = async () => {
    let ip = "127.0.0.0"
    await axios.get("https://api.ipify.org/?format=json").then((res) => {
      ip = res.data.ip
    }).catch((error) => {

    });

    this.setState({ ip_address: ip })
  }

  getGSTR2BMaxDate = () => {
    let current_date = new Date();
    let current_year = current_date.getFullYear();
    let current_month = current_date.getMonth() + 1;
    let compare_date = new Date(current_year + "-" + current_month + "-" + "14 12:00:00");

    if (current_date > compare_date) {
      current_date.setMonth(current_date.getMonth() - 1);
    } else {
      current_date.setMonth(current_date.getMonth() - 2);
    }
    current_date.setDate(1)
    return current_date
  }

  getOTP = (otp) => {
    this.setState({ otp }, () => {
      this.handleExportDownload()
    })
  }

  render() {
    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">
          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <BreadCrum level={2} />
                  <h2 className="breadcrumb-title-new">Reports</h2>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5">
              <div className="report-menu-card">
                <h3 className="heading-shi">Exports</h3>
                <hr className="m-0"></hr>
                <div className="list-group list-group-flush report-card-menu-body">
                  <a className={"list-group-item d-block" + (this.state.export_type == "ule" ? " active" : "")} id="ule" onClick={this.handleExportType} title="Unlinked Elements">Unlinked Elements</a>
                  <a className={"list-group-item d-block" + (this.state.export_type == "tds" ? " active" : "")} id="tds" onClick={this.handleExportType} title="TDS Details">TDS Details</a>
                  <a className={"list-group-item d-block" + (this.state.export_type == "gst" ? " active" : "")} id="gst" onClick={this.handleExportType} title="GST Details">GST Details</a>
                  {/* <a class="list-group-item d-block" id="tds" onClick={this.handleExportType}>Cheques Details</a> */}
                  <a className={"list-group-item d-block" + (this.state.export_type == "cash" ? " active" : "")} id="cash" onClick={this.handleExportType} title="Cash Expense Details">Cash Expense Details</a>
                  <a className={"list-group-item d-block" + (this.state.export_type == "bst" ? " active" : "")} id="bst" onClick={this.handleExportType} title="Bank Statements">Bank Statements</a>
                  <a className={"list-group-item d-block mb-0" + (this.state.export_type == "gstr2b" ? " active" : "")} id="gstr2b" onClick={this.handleExportType} title="GSTR 2B">GSTR 2B</a>
                </div>
              </div>
            </div>

            <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7 pl-1">
              <div className="report-detail-card">
                <h3 className="heading-shi">{this.state.exportTitle ? this.state.exportTitle : "Unlinked Elements"}</h3>
                <hr className="m-0 hr-blue-line"></hr>
                <div className="report-card-detail-body sec-my">
                  <div className="mainFilter-new">
                    <div className="top-new-filter">
                      {this.state.export_type == "bst" && this.state.financial_institute_list.length != 0 ?
                        <Select
                          value={this.state.financial_institute_id}
                          onChange={this.onFinancialInstituteChange}
                          name="financial_institute_id"
                          className="global_new_dd_date">
                          {this.state.financial_institute_list.map((finance) => {
                            return <MenuItem value={finance.id}>{finance.view_Name}</MenuItem>
                          })}
                        </Select>
                        :
                        null
                      }

                      {this.state.export_type == "gstr2b" ?
                        <div className="filter-bar-new">
                          <div className="start-end-date-sec">
                            <DatePicker
                              dateFormat="MMM yyyy"
                              showMonthYearPicker
                              className="form-control field-height datepicker-icon"
                              // style={{minWidth:"180px", maxWidth:"180px"}}
                              minDate={new Date("2023-04-01")}
                              maxDate={this.getGSTR2BMaxDate()}
                              selected={this.state.gstr2b_show_date}
                              onChange={date => {
                                let selectedDate = new Date(date);
                                //console.log(selectedDate)
                                //const year = selectedDate.getFullYear();
                                //const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                                //this.setState({gstr2b_date: month+year, gstr2b_show_date: date, gstr2b_error: null})

                                this.setState({ gstr2b_date: changeDateFormate(selectedDate), gstr2b_show_date: date, gstr2b_error: null })
                              }}
                            />
                          </div>
                        </div>
                        :
                        <div className="filter-bar-new">
                          {this.state.financial_years_arr.length != 0 ?
                            <div className="global-ddown-new">
                              <Multiselect
                                onRemove={function noRefCheck() { }}
                                onSearch={function noRefCheck() { }}
                                onSelect={this.selectYearHandler}
                                options={this.state.financial_years_arr}
                                singleSelect
                                placeholder="Select"
                                displayValue="name"
                                // selectedValues={[
                                //   this.state.financial_years_arr[this.state.financial_years_arr.findIndex((item)=>item.id = this.state.year_type)]
                                // ]}
                                selectedValues={[this.state.financial_years_arr.find((x) => x.id == this.state.year_type)]}
                              />
                            </div>
                            :
                            null
                          }

                          <span className={this.state.year_type != 6 ? " d-none" : "filter-tdates"}>
                            <div className="start-end-date-sec">
                              <DatePicker
                                dateFormat={DatePickerFormate()}
                                onChange={date => this.setDate(date, "start_date")}
                                className="form-control field-height datepicker-icon"
                                disabled={this.state.year_type == 6 ? "" : "disabled"}
                                selected={this.state.start_date}
                                maxDate={!!this.state.end_date ? new Date(this.state.end_date) : ""}
                                autoComplete="off"
                                placeholderText="From Date"
                                name="start_date"
                              />
                            </div>
                            <hr className="dash-flt" />
                            <div className="start-end-date-sec">
                              <DatePicker
                                dateFormat={DatePickerFormate()}
                                onChange={date => this.setDate(date, "end_date")}
                                className="form-control field-height datepicker-icon"
                                disabled={(this.state.year_type == 6 ? "" : "disabled") || (!!this.state.start_date ? "" : "disabled")}
                                selected={this.state.end_date}
                                minDate={new Date(this.state.start_date)}
                                autoComplete="off"
                                placeholderText="To Date"
                                name="end_date"
                              />
                            </div>
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row sec-mt">
                <div className="col-sm-8">
                  {this.state.gstr2b_error && <small className="error_left">{this.state.gstr2b_error}</small>}
                </div>
                <div className="col-sm-4 text-right">
                  <Button variant="success apply_btn_new" onClick={() => { this.setState({ previous_return_period: null }); this.handleExportDownload() }} >Export</Button>
                </div>
              </div>

              {/* Confirm OTP Popup  */}
              <Modal
                size="md"
                show={this.state.showOTPPopup}
                onHide={() => this.setState({ showOTPPopup: false, otp: null, gstr2b_id: null, gstr2b_error: null })}
                // aria-labelledby="contained-modal-title-vcenter" 
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backdrop="static"
                keyboard={false}
                className="popup-model "
              >
                <Modal.Header closeButton>
                  <Modal.Title className="d-flex align-items-baseline justify-content-between w-100">
                    Confirm OTP {this.state.gstr2b_error && <p className="size-vi mb-0">Use Resend OTP or try again later</p>}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <OtpInput
                    ref={this.otpbox}
                    handleCancel={() => {
                      this.setState({ showOTPPopup: false, otp: null, gstr2b_id: null, gstr2b_error: null })
                    }}
                    getOTP={this.getOTP}
                    handleResendOTP={() => {
                      this.setState({ otp: null, gstr2b_id: null }, () => {
                        this.handleExportDownload()
                      })
                    }}
                    gstr2b_error={this.state.gstr2b_error}
                  ></OtpInput>
                  {this.state.gstr2b_error && <small className="error_right mt-2">{this.state.gstr2b_error}</small>}
                </Modal.Body>
              </Modal>
              {/* End Confirm OTP Popup  */}
            </div>
          </div>
          {/* End Row  */}
        </div>
      </main>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.TaskManager
  const assigned_by = all_value.assigned_by || [];
  const assigned_to = all_value.assigned_to || [];
  const is_star = all_value.is_star || '';
  const due_start_date = all_value.due_start_date || null;
  const due_end_date = all_value.due_end_date || null;
  const priority = all_value.priority || null;

  const total_count = all_value.total_count || 0;
  const page_no = all_value.page_no || 1;
  const per_page_items = all_value.per_page_items || 5;

  const task_id = all_value.task_id || null;
  const changed_star = all_value.changed_star || 0;
  return {
    is_star, due_start_date, due_end_date, assigned_by, assigned_to, priority,
    total_count, page_no, per_page_items, task_id, changed_star
  };
}

export default connect(mapStateToProps)(ReportsView); 