import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import bank_img from '../../Assets/img/HDFC_Bank_logo.jpg';
import bankic_img from '../../Assets/img/icici_bank.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import bankkm_img from '../../Assets/img/Kotak_Mahindra.png';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
function financial(){
  const [sidfltr,setSidfltr]=React.useState(false)
  const [lgShow, setLgShow] =React.useState(false);
  const [creditcard, setCreditCard] =React.useState(false);

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span class="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div><h3>dg</h3></div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper bg-w">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item"><Link to='/financial-institutes'>Banking</Link></li>
                {/* <li className="breadcrumb-item">Financial Institute & Account Statements</li> */}
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Banking <OverlayTrigger overlay={<Tooltip  id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
                  <span className="icon-Information green-icon green-icon-head"></span></OverlayTrigger></h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-inst-card-sec">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec">
                                <div className="img-ico-sec clearfix">
                                  <div className="pull-left bank-img-sec">
                                    <img className="img-fluid" src={bank_img} />
                                  </div>
                                  <div className="pull-right bank-img-icon-sec">
                                    <span class="icon-bank icon-Group-4436"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                                  </div>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Name</p>
                                  <p className="ft-sz-30 s-bold blu-color mb-0">HDFC Bank</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">A/C No. </p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">3524599847548</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">IFSC Code</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">HD458764</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Last Updated Date</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Closing Balance</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-bottom-sec clearfix">
                                  <div className="pull-left edit-btn-sec" onClick={() => setLgShow(true)}>
                                    <span class="icon-edit"></span>
                                  </div>
                                  <div className="pull-right">
                                    <button type="button" className="btn view-more-btn white-color s-bold ft-sz-22">View More</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec">
                                <div className="img-ico-sec clearfix">
                                  <div className="pull-left bank-img-sec">
                                    <img className="img-fluid" src={bankic_img} />
                                  </div>
                                  <div className="pull-right bank-img-icon-sec">
                                    <span class="icon-bank icon-Group-4437"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                                  </div>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Name on Card</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">Ramesh B Patil</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Card issued by</p>
                                  <p className="ft-sz-30 s-bold blu-color mb-0">ICICI Bank</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Last 4 Digit of card No.</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">8 7 6 4</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Last Updated Date</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Closing Balance</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-bottom-sec clearfix">
                                  <div className="pull-left edit-btn-sec" onClick={() => setCreditCard(true)}>
                                    <span class="icon-edit"></span>
                                  </div>
                                  <div className="pull-right">
                                    <button type="button" className="btn view-more-btn white-color s-bold ft-sz-22">View More</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec not-support-bank">
                                <div className="img-ico-sec clearfix">
                                  <div className="pull-left bank-img-sec">
                                    <img className="img-fluid" src={bank_img} />
                                  </div>
                                  <div className="pull-right bank-img-icon-sec">
                                    <span class="icon-bank icon-Group-4436"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                                  </div>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Name</p>
                                  <p className="ft-sz-30 s-bold blu-color mb-0">Kotak Mahindra Bank</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">A/C No. </p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">4254558785654</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">IFSC Code</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">KT486764</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Last Updated Date</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Closing Balance</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-bottom-sec clearfix">
                                  <div className="pull-left edit-btn-sec">
                                    <span class="icon-edit"></span>
                                  </div>
                                </div>
                                <div className="bottom-not-support-bank">
                                  <p className="inner-bottom-not-support-bank ft-sz-14 s-red white-color mb-0">Not Supported Currently</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec not-support-bank">
                                <div className="img-ico-sec clearfix">
                                  <div className="pull-left bank-img-sec">
                                    <img className="img-fluid" src={bankic_img} />
                                  </div>
                                  <div className="pull-right bank-img-icon-sec">
                                    <span class="icon-bank icon-Group-4437"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                                  </div>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Name on Card</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">Ramesh B Patil</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Card issued by</p>
                                  <p className="ft-sz-30 s-bold blu-color mb-0">ICICI Bank</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Last 4 Digit of card No.</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">8 7 6 4</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Last Updated Date</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Closing Balance</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-bottom-sec clearfix">
                                  <div className="pull-left edit-btn-sec">
                                    <span class="icon-edit"></span>
                                  </div>
                                </div>
                                <div className="bottom-not-support-bank">
                                  <p className="inner-bottom-not-support-bank ft-sz-14 s-red white-color mb-0">Not Supported Currently</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                         <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec opacity">
                                <div className="img-ico-sec clearfix">
                                  <div className="pull-left bank-img-sec">
                                    <img className="img-fluid" src={bank_img} />
                                  </div>
                                  <div className="pull-right bank-img-icon-sec">
                                    <span class="icon-bank icon-Group-4436"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                                  </div>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">Name</p>
                                  <p className="ft-sz-30 s-bold blu-color mb-0">HDFC Bank</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">A/C No. </p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">3524599847548</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <p className="ft-sz-16 s-reg font-color mb-0">IFSC Code</p>
                                  <p className="ft-sz-25 s-sbold blu-color mb-0">HD458764</p>
                                </div>
                                <div className="main-card-text-sec">
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Last Updated Date</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">14-11-2020</p>
                                    </div>
                                    <div className="col-lg-6 col-xs-12">
                                      <p className="ft-sz-16 s-reg font-color mb-0">Closing Balance</p>
                                      <p className="ft-sz-25 s-sbold blu-color mb-0">₹ 1,54,658.00</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-bottom-sec clearfix">
                                  <div className="pull-left edit-btn-sec">
                                    <span class="icon-edit"></span>
                                  </div>
                                  <div className="pull-right">
                                    <button type="button" className="btn view-more-btn white-color s-bold ft-sz-22">View More</button>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec main-inner-inst-card-sec" onClick={() => setLgShow(true)}>
                                <div><span class="main-icon-bnk-crd icon-Group-2453"></span>
                                <p className="bnk-crd-text ft-sz-30 s-sbold blu-color mb-0">Add New Bank</p></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-12 inner-inner-inst-card-sec">
                            <div className="inner-inst-card-sec main-inner-inst-card-sec" onClick={() => setCreditCard(true)}>
                                <div><span class="main-icon-bnk-crd icon-credit-card1"></span>
                                <p className="bnk-crd-text ft-sz-30 s-sbold blu-color mb-0">Add New Card</p></div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    centered
                    backdrop="static"
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="add-bank-model"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" className="ft-sz-30 s-sbold blu-color">
                      Add New Bank
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-4">
                            <div className="col-xs-12 col-lg-4">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Account Name / Description</p>
                                  <input type="text" className="form-control" value=""/>             
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-4">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">IFSC Code</p>
                                  <input type="text" className="form-control" value=""/>             
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-4">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Account Number</p>
                                  <input type="text" className="form-control" value=""/>             
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-lg-4">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Bank Name</p>
                                  <input type="text" className="form-control input-bg-gray" value=""/>             
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-4">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">City</p>
                                  <input type="text" className="form-control input-bg-gray" value=""/>             
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-4">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Branch</p>
                                  <input type="text" className="form-control input-bg-gray" value=""/>             
                                </div>
                            </div>
                        </div>
                        <div className="row add-confirm-sec">
                            <div className="col-lg-4 col-xs-12">
                              <div className="">  
                                <span class="icon-sec icon-Group-4440 mr-2"><span class="path1"></span><span class="path2"></span></span>
                                <span className="ft-sz-18 s-sbold blu-color">Add Debit Card</span>
                              </div>
                              <div className="digit-no-sec d-none">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">( Card Number Last 4 Digits )</p>
                                 <span><input type="text" className="form-control ft-sz-18 s-sbold" value=""/> </span> 
                                 <button type="button" className="btn save-btn ft-sz-22 white-color s-bold pull-right">Confirm</button>
                              </div>
                            </div>
                            <div className="col-lg-8 col-xs-12">
                              <div className="form-group d-none">
                                <div className="form-control digit-list">
                                  <div className="cross"><span>X</span></div>
                                  <span className="icon-credit-list"><span class="icon-credit-card"></span></span>
                                  <span className="ft-sz-18 s-sbold blu-color">Card ending in  4587</span>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="model-active-in-btn mt-4">
                            <div className="col-xs-12">
                                <div className="clearfix">
                                    <div className="active-in-btn-sec pull-right">
                                        <ButtonGroup aria-label="Basic example">
                                        <Button variant="secondary active-in">Active</Button>
                                        <Button variant="secondary active-in">Inactive</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="model-save-btn clearfix">
                            <button type="button" className="btn save-btn ft-sz-22 white-color s-bold pull-right">Save</button>
                            <div className="not-support-sec pull-right d-none">
                                <span class="icon-span icon-Group-3553"></span>
                                <p className="text-p ft-sz-14 s-reg red-color mb-0">This bank is not currently supported, will add support in next 2-3 days. Please check again</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={creditcard}
                    onHide={() => setCreditCard(false)}
                    centered
                    backdrop="static"
                    aria-labelledby="example-modal-sizes-title-lg"
                    className="add-bank-model"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" className="ft-sz-30 s-sbold blu-color">
                      Add New Credit Card
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-4">
                            <div className="col-xs-12 col-lg-6">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2 lable-reg">Name on the Card</p>
                                  <input type="text" className="form-control" value=""/>             
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-6">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Last 4 digit</p>
                                  <div className="digit-input-sec">
                                    <input type="text" className="form-control s-sbold blu-color" value=""/> 
                                    <input type="text" className="form-control s-sbold blu-color" value=""/> 
                                    <input type="text" className="form-control s-sbold blu-color" value=""/> 
                                    <input type="text" className="form-control s-sbold blu-color" value=""/> 
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-lg-6">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Card issued by</p>
                                  <select className="form-control">
                                    <option value="1" disabled selected>Select your bank</option>
                                    <option value="2">HDFC</option>
                                    <option value="3">ICICI</option>
                                    <option value="4">Any Other</option>
                                  </select>           
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-6">
                                <div className="form-group">
                                  <p className="ft-sz-15 s-reg font-colr mb-2">Issuer Bank Name</p>
                                  <textarea class="form-control" rows="2" placeholder="Card issuer bank name here"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="model-active-in-btn mt-4">
                            <div className="col-xs-12">
                                <div className="clearfix">
                                    <div className="active-in-btn-sec pull-right">
                                        <ButtonGroup aria-label="Basic example">
                                        <Button variant="secondary active-in">Active</Button>
                                        <Button variant="secondary active-in">Inactive</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="model-save-btn clearfix">
                            <button type="button" className="btn save-btn ft-sz-22 white-color s-bold pull-right">Save</button>
                            <div className="not-support-sec pull-right d-none">
                                <span class="icon-span icon-Group-4409"></span>
                                <p className="text-p ft-sz-14 s-reg red-color mb-0">This issuer is not currently supported, will add support in next 2-3 days. Please check again</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                
              </div>
            </div>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     
	
}






export default financial;