import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function AttachmentIcon(props) {
  return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Attachments</Tooltip>}>
    <span className="icon-attachment-icon icon-dt-td"><span className={"path1 " +(props.active ? "skyblue-icon":" grayc-light-icons")}></span><span className="path2"></span></span>
    {/* <span className={"icon-td icon-attachment-icon "+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span></span> */}
    {/* <span className={"icon-td icon-Group-3284 "+(props.active ? " ":" gray-st-icon")}><span className="path1"></span><span className="path2"></span></span> */}
  </OverlayTrigger>
}
export default AttachmentIcon;