import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class EntityService {

  getCompanyType() {
    return axios.get(API_URL + 'company-types', { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  updateAccount(gst_no, address, city_id, state_id, country_id, pincode) {
    let data = {
      gst_no: gst_no,
      address: address,
      city_id: city_id,
      state_id: state_id,
      country_id: country_id,
      pincode: pincode
    };
    return axios
      .put(API_URL + "account", data, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getAvailableLicense() {
    return axios.get(API_URL + 'available-licenses', { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  verfifySponser(otp) {
    let data = {
      otp: otp
    }
    return axios.post(API_URL + 'verify-sponsor', data, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  createEntity(data){
    return axios
      .post(API_URL + "entity", data, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  updateEntity(data,entity_type="edit") {
    
    if(entity_type == "invite"){
      return axios.put(API_URL + 'entity-invite', data, { headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
    }else{
      return axios.put(API_URL + 'entity', data, { headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
    }
  }
  getEntityList() {
    return axios.get(API_URL + 'entities', { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getEntityDetail(entity_id,entity_type) {
    if(entity_type == "invite"){
    return axios.get(API_URL + 'entity-invite/' + entity_id, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
    }else{
        return axios.get(API_URL + 'entity/' + entity_id, { headers: Header.authHeader() })
          .then((response) => {
            return response.data;
          }).catch((error) => {
            return error;
          });
        }

  }

  getMultipleLicenses() {
    return axios.get(API_URL + 'multiple-licenses', { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getUnusedCodes() {
    return axios.get(API_URL + 'unused-codes', { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  createCode(data) {
    return axios.post(API_URL + 'sponsor-license',data, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
    }

  deleteOTPCode(id) {
    return axios.delete(API_URL + 'sponsor-license/'+id, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
    }
  
  addMultipleEntity(data) {
    return axios.post(API_URL + 'entities',data, { headers: Header.authHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
    }

    getHashTags() {
      return axios.get(API_URL + 'hashtags',{ headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
      }
    
    resendEntityInvite(data) {
      return axios.post(API_URL + 'resend-invite/',data, { headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
    }

    getPendingInviteEntities() {
      return axios.get(API_URL + 'entity-invites',{ headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
      }

    deleteEntity(entity_id,entity_type) {
      if(entity_type == "invite"){
        return axios.delete(API_URL + 'entity-invite/'+entity_id,{ headers: Header.authHeader() })
          .then((response) => {
            return response.data;
          }).catch((error) => {
            return error;
          });
      }else{
        return axios.delete(API_URL + 'entity/'+entity_id,{ headers: Header.authHeader() })
          .then((response) => {
            return response.data;
          }).catch((error) => {
            return error;
          });
      }
    }

    AcceptEntityInvite(data) {
      return axios.put(API_URL + 'entity-invite',data, { headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
    }

    RejectEntityInvite(entity_id) {
      return axios.delete(API_URL + 'entity-invite/'+entity_id,{ headers: Header.authHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
      }
    
}

export default new EntityService();
