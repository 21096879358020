function validateCheque(data) {
  let errors = {};

  if (!data.type) {
    errors.type = 'Type Name is required';
  } else {
    // errors.type = '';
  }
  if (!data.date) {
    errors.date = 'Date is required';
  } else {
    // errors.date = '';
  }
  if (!data.bank_name) {
    errors.bank = 'Bank Name is required';
  } else {
    // errors.bank = '';
  }
  if (!data.cheque_identifier) {
    errors.cheque = 'Cheque is required';
  } else {
    // errors.cheque = '';
  }
  return errors;
}
export {
  validateCheque
}