import React from 'react';
import { connect } from "react-redux";
import StorageData from '../../Helpers/StorageData';
import SessionManagement from '../../Utils/SessionManagement';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import Sidebar from './Sidebar';
import ChattingWindow from './ChattingWindow';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';

function MessengerView(){
  
      return <div>
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec sidebar-messenger-sec mt-4">
                <BreadCrum level={2}/> 
                {/* <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    Menu
                  </li>
                  <li className="breadcrumb-item">Messenger</li>
                </ol> */}
                <hr/>
                  <Sidebar/>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec messenger-main-content-sec">
                  <ChattingWindow />
              </div>
            </div>
          </div>
        </div>
      </div>     
  
      
  }
  
  
function mapStateToProps(state) {
    const all_value = state.Session || {};
    const module_permissions = all_value.module_permissions || [];
    const account_permissions = all_value.account_permissions || [];
    const acl_entities = all_value.entity_list || [];
    return {
      module_permissions, account_permissions, acl_entities
    };
  }

export default connect(mapStateToProps)(MessengerView);