import React from 'react';
import { connect } from 'react-redux';
import { setPaymentInformation } from '../../Redux/Actions/Snapshot';
import CurrencyInput from 'react-currency-input-field';
import { showAmount } from '../../Helpers/Currency';;
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LEDGER_ENTRIES_CODE, LINKING_CODE } from '../../Helpers/Constants';
import { showAliasAcctNo } from '../../Helpers/HelperFunction';

class PaymentMethod extends React.Component {

	constructor(props) {
		super(props);

		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
		this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
		let initialPaymentObj = [{ method: 0, id: 0, amount: 0, payment_id: 0 }];

		this.state = {
			payment_total: "",
			payment_information: initialPaymentObj,
			errors: {},
		}
		this.payment_focus = React.createRef();
	}

	amountChange = (name, value, index) => {
		if (value > 999999999.99) {
			return;
		}
		let payment_information = [...this.state.payment_information];
		payment_information[index] = { ...payment_information[index], [name]: !!value ? value : "" };
		this.setState({ payment_information });
		this.props.setIsdirty();
	}

	handleOnPaymentChange = (e, index) => {
		const { value, name } = e.target;
		let payment_information = [...this.state.payment_information];

		if (name == "amount") {
			if (value > 999999999.99) {
				return;
			}
			payment_information[index] = { ...payment_information[index], [name]: !!value ? value : "" };
		} else {
			let value = e.target.value.split("_");
			payment_information[index] = { ...payment_information[index], method: parseInt(value[0]), id: parseInt(value[1]) };
		}

		this.setState({ payment_information });
		this.props.setIsdirty();
		this.props.changePaymentMethod(payment_information)
	}

	setPaymentTotal = () => {
		let payment_total = this.state.payment_information.reduce((a, b) => a + parseFloat(b.amount || 0), 0);
		let payment_information = this.state.payment_information.map((method) => {
			if (!!method.amount)
				method.amount = parseFloat(method.amount);
			return method;
		});
		this.setState({ payment_total, payment_information });
		this.props.setPaymentDetail(payment_information, payment_total);
		// this.props.dispatch(setPaymentInformation(payment_information));
	}

	addPaymentRow = () => {
		//START - commented this line - as in update case -> method were setting up by first value of previous methods, that what no letting show is validation, if we were did not select any method
		// let payment = { method: this.state.payment_information[0].method, id: 0, amount: 0, payment_id: 0 }
		//START -New code
		let payment = { method: 0, id: 0, amount: 0, payment_id: 0 }

		this.setState({ payment_information: [...this.state.payment_information, payment] },
			() => {
				this.payment_focus.current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
				this.setPaymentTotal();
			});
		this.props.setIsdirty();
	}

	removePayment = (index) => {
		let payment_information = [...this.state.payment_information];

		const isEmpty = Object.values(payment_information[index]).every(x => (x === 0 || x === ''));
		if (isEmpty) {
			payment_information.splice(index, 1);
		} else {

			if (!!payment_information[index].payment_id) {
				if (payment_information[index].payment_id != 0) {
					let to_be_remove = this.props.remove_payment_information;
					to_be_remove.push(payment_information[index].payment_id)
					this.props.setRemovePaymentInformation(to_be_remove)
				}
			}
			payment_information.splice(index, 1);
		}

		this.setState({ payment_information }, () => {
			this.setPaymentTotal();
		});
		this.props.setIsdirty();
	}

	setChildPaymentDetail = (payment_information) => {
		this.setState({ payment_information: payment_information })
	}

	setChildPaymentTotal = (payment_total) => {
		this.setState({ payment_total })
	}

	componentDidMount() {
		this.props.onRef(this);
	}

	componentDidUpdate(nextProps) {
		// let diff = _.intersectionWith(nextProps.payment_information, this.props.payment_information);
		if (nextProps.payment_information !== this.props.payment_information) {
			this.setState({ payment_information: this.props.payment_information }, () => {
				this.setPaymentTotal();
			})
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.payment_information !== this.props.payment_information) {
			this.setState({ payment_information: this.props.payment_information }, () => {
				this.setPaymentTotal();
			})
		}
	}

	render() {
		if ((this.LINKING_CODE || this.LEDGER_ENTRIES) && this.state.payment_information.length === 0 && this.props.db_payment_status_val == 1 && this.props.is_linked == 1) {
			return (<>
				<React.Fragment>
					<div className="row mb-2">
						<div className="col-lg-12">
							<div className="form-group">
								{/* <lable className="lable-input">Linked</lable> */}
								<div className="form-control d-flex justify-content-between">
									<div className="d-flex align-items-center gap-10">
										<span className="size-xviii mb-1">Linked</span>
										<span>
											{(this.props.is_linked == 1) && <> <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Snapshot is linked with Money Link ID(s): {this.props.transaction_ids.map((item, i, row) => (<>#{item} {(i + 1 === row.length) ? '' : ','}</>))} </Tooltip>}>
												<span className="icon-linked-icon form-icon-log" onClick={(this.props.is_linked == 1) ? this.props.snapshotFilterOnMoneyLink : () => { }}><span className="path1 blue-icon"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
											</OverlayTrigger></>
											}
										</span>
									</div>
									{showAmount((this.props.is_linked == 1) ? this.props.linked_amount : 0)}
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
				{/* <React.Fragment>
					<div className="form-group mt-3 mb-0">
						<div className="form-control">
							<p className="blue-text size-xviii s-sbold pull-left m-0 d-flex align-items-center">
								Linked
								{(this.props.is_linked == 1) &&
									<>
										<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Snapshot is linked with Money Link ID(s): {this.props.transaction_ids.map((item, i, row) => (<>#{item} {(i + 1 === row.length) ? '' : ','}</>))} </Tooltip>}>
											<span className="icon-linked-icon form-icon-log ml-2" onClick={(this.props.is_linked == 1) ? this.props.snapshotFilterOnMoneyLink : () => { }}><span className="path1 blue-icon"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
										</OverlayTrigger>
									</>
								}
							</p>
							<p className="ft-sz-18 s-bold bli-color pull-right m-0">{showAmount((this.props.is_linked == 1) ? this.props.linked_amount : 0)}</p>
						</div>
					</div>
				</React.Fragment> */}
			</>
			)
		}

		if (this.state.payment_information.length === 0)
			return null
		const payment_info_first = this.state.payment_information[0];
		const payment_info_other = this.state.payment_information.filter((data, idx) => idx !== 0) || [];
		const editable = this.props.editable;

		return <React.Fragment>
			<div className="payment-status-scroll">
				<div className="main-pay-method-new bordered-form">
					<div className="row">
						<div className="col-6 form-group">
							<lable className="lable-input">Method<span className="red-star">*</span></lable>
							<select className="form-control"
								value={payment_info_first.method + "_" + payment_info_first.id}
								name="method"
								disabled={this.props.is_sms_evidence || this.props.VIEW_ONLY_SNAPSHOT || !editable}
								onChange={(e) => this.handleOnPaymentChange(e, 0)}
								onBlur={(e) => {
									this.setPaymentTotal()
								}}
							>
								<option value={0 + "_" + 0}>Select Payment Method</option>
								{
									(this.props.CREATE_BUSSINESS_SNAPSHOT || this.props.VIEW_ONLY_SNAPSHOT) && <option key="cash"
										disabled={((payment_info_first.method == 3 && payment_info_other.length) || this.props.is_sms_evidence) || !(this.props.CREATE_BUSSINESS_SNAPSHOT)}
										value={1 + "_" + 0}>Cash</option>
								}		
								{
									(this.props.CREATE_BUSSINESS_SNAPSHOT || this.props.VIEW_ONLY_SNAPSHOT) && this.props.payment_details.map((bank, i) => {
										return <option key={"fi_" + i} value={2 + "_" + bank.id}
											disabled={((payment_info_first.method == 3 && payment_info_other.length) || this.props.is_sms_evidence) || !(this.props.CREATE_BUSSINESS_SNAPSHOT)}
										>{bank.bank_name + " " + showAliasAcctNo(bank.account_number.length > 2 ? 0 : 1, bank.account_number)}</option>;
									})
								}
							</select>
							{this.props.errors.payment_information_method_0 && <small className="error_right">{this.props.errors.payment_information_method_0}</small>}
						</div>

						<div className="col-6 form-group mb-0">
							<lable className="lable-input">Payment Amount<span className="red-star">*</span></lable>
							<CurrencyInput
								className={"form-control text-right " + (this.props.errors.payment_information_0 ? " wrong-red " : "")}
								value={payment_info_first.amount == 0 ? "" : payment_info_first.amount}
								allowNegativeValue={false}
								disabled={this.props.is_sms_evidence || this.props.VIEW_ONLY_SNAPSHOT || !editable}
								is_amount={1}
								decimalsLimit={2}
								prefix="&#8377; "
								placeholder='&#8377; 0'
								onValueChange={(value, name) => this.amountChange(name, value, 0)}
								// onChange={(e) => this.handleOnPaymentChange(e, 0)}
								onBlur={(e) => {
									this.setPaymentTotal()
								}}
								name="amount"
							/>
							{/* <NumberTypeInputBox number={payment_info_first.amount} index={0}
							handleOnPaymentChange={this.handleOnPaymentChange}
							setPaymentTotal={this.setPaymentTotal} /> */}
							{this.props.errors.payment_information_0 && <small className="error_right">{this.props.errors.payment_information_0}</small>}
						</div>
					</div>
				</div>

				{
					payment_info_other.map((payment, index) => {
						return <div className="main-pay-method-new bordered-form" key={index}>
							<div className="row">
								{/* <div onClick={() => this.removePayment(index + 1)} className="cross"><span>X</span></div> */}

								<span class="ss-cross" onClick={() => this.removePayment(index + 1)}>
									<span class="icon-Cross-with-grey-circle size-xxii"><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
								</span>

								<div className="col-6 form-group">
									<lable className="lable-input">Method<span className="red-star">*</span></lable>
									<select className="form-control"
										value={payment.method + "_" + payment.id}
										name="method"
										disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable}
										onChange={(e) => this.handleOnPaymentChange(e, index + 1)}
										onBlur={(e) => {
											this.setPaymentTotal()
										}}>
										<option key={"select_" + index} disabled={(payment_info_first.method == 3) || !(this.props.CREATE_BUSSINESS_SNAPSHOT)} value={0 + "_" + 0} bank={0}>Select Payment Method</option>
										{
											<option key={"cash_" + index} disabled={(payment_info_first.method == 3) || !(this.props.CREATE_BUSSINESS_SNAPSHOT)} value={1 + "_" + 0} bank={0}>Cash</option>
										}
										{
											<option key={"reim_" + index} disabled={(payment_info_first.method != 3) || !(this.props.CREATE_REIMBUSRMENT_SNAPSHOT)} value={3 + "_" + 0} bank={0}>Reimbursement</option>
										}
										{
											this.props.payment_details.map((bank, i) => {
												return <option key={"fi_" + index + i} disabled={(payment_info_first.method == 3) || !(this.props.CREATE_BUSSINESS_SNAPSHOT)} value={2 + "_" + bank.id} >{bank.bank_name + " " + showAliasAcctNo(bank.account_number.length > 2 ? 0 : 1, bank.account_number)}</option>;
											})
										}
									</select>
									{this.props.errors['payment_information_method_' + (index + 1)] && <small className="error_right">{this.props.errors['payment_information_method_' + (index + 1)]}</small>}
								</div>

								<div className="col-6 form-group mb-0">
									<lable className="lable-input">Payment Amount<span className="red-star">*</span></lable>
									<CurrencyInput
										className={"form-control text-right " + (this.props.errors['payment_information_' + (index + 1)] ? " wrong-red " : "")}
										value={payment.amount == 0 ? "" : payment.amount}
										allowNegativeValue={false}
										disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable}
										// onChange={(e) => this.handleOnPaymentChange(e, index + 1)}
										onValueChange={(value, name) => this.amountChange(name, value, index + 1)}
										onBlur={(e) => {
											this.setPaymentTotal()
										}}
										is_amount={1}
										decimalsLimit={2}
										prefix="&#8377; "
										placeholder='&#8377; 0'
										name="amount" />
									{/* <NumberTypeInputBox number={payment.amount} index={index + 1}
									handleOnPaymentChange={this.handleOnPaymentChange}
									setPaymentTotal={this.setPaymentTotal} /> */}
									{this.props.errors['payment_information_' + (index + 1)] && <small className="error_right">{this.props.errors['payment_information_' + (index + 1)]}</small>}
								</div>
							</div>
						</div>
					})
				}
				<div ref={this.payment_focus} />
			</div>

			{/* {
				(editable) &&
				<div className="text-right">
					<button type="button" disabled={(this.props.VIEW_ONLY_SNAPSHOT)} onClick={() => this.addPaymentRow()} className={"btn btn-success apply_btn_new btn-small" + (payment_info_first.method == 3 ? " d-none " : "")}>
						<span><span className="icon-plus1 white-icon mr-1"></span> Add New Method</span>
					</button>
				</div>
			} */}

			{editable && <div className="clearfix"></div>}


			<div className="row justify-content-end align-items-end mb-2">
				<div className="col-lg-4 form-group">
					<lable className="lable-input">Total</lable>
					<div className="form-control text-right">{showAmount(this.state.payment_total)}</div>
					{this.state.errors.payment_total && <small className="error_right">{this.state.errors.payment_total}</small>}
				</div>

				{(this.LINKING_CODE || this.LEDGER_ENTRIES) &&
					<div className="col-lg-4 form-group">
						<lable className="lable-input">Linked</lable>

						<div className="form-control d-flex justify-content-between">
							{(this.props.is_linked == 1) && <> <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Snapshot is linked with Money Link ID(s): {this.props.transaction_ids.map((item, i, row) => (<>#{item} {(i + 1 === row.length) ? '' : ','}</>))} </Tooltip>}>
								<span className="icon-linked-icon form-icon-log" onClick={(this.props.is_linked == 1) ? this.props.snapshotFilterOnMoneyLink : () => { }}><span className="path1 blue-icon"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
							</OverlayTrigger></>
							}

							{((this.props.is_linked == 0) && (this.props.snapshot_id > 0)) && <> <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Snapshot is not linked with Money Link ID(s)</Tooltip>}>
								<span class="icon-linked-icon form-icon-log"><span class="path1 grayc-light-icons"></span><span class="path2 white-icon"></span><span class="path3 white-icon"></span><span class="path4 white-icon"></span><span class="path5 white-icon"></span><span class="path6 white-icon"></span><span class="path7 white-icon"></span><span class="path8"></span><span class="path9"></span></span>
							</OverlayTrigger> </>
							}

							<span className="ml-auto">{showAmount((this.props.is_linked == 1) ? this.props.linked_amount : 0)}</span>
						</div>
					</div>
				}

				{
					(editable) &&
					<div className="col-lg-1 form-group text-right">
						<div class={(payment_info_first.method == 3 ? " d-none " : "")}>
							<span class="icon-plus-circled size-xxx cpointer " disabled={(this.props.VIEW_ONLY_SNAPSHOT)} onClick={() => this.addPaymentRow()}></span>
						</div>
						{/* <button type="button" disabled={(this.props.VIEW_ONLY_SNAPSHOT)} onClick={() => this.addPaymentRow()} className={"btn btn-success apply_btn_new btn-small" + (payment_info_first.method == 3 ? " d-none " : "")}>
							<span><span className="icon-plus1 white-icon mr-1"></span> Add New Method</span>							
						</button> */}
					</div>
				}
			</div>
		</React.Fragment>
	}
}

function mapStateToProps(state) {

	const session_values = state.Session || {};
	const features_permissions_list = session_values.features_permissions_list || [];

	const all_value = state.Snapshot
	const payment_information = all_value.payment_info || (features_permissions_list.includes(4) && !features_permissions_list.includes(1)) ? [{ method: 3, id: 0, amount: 0, payment_id: 0 }] : [{ method: 1, id: 0, amount: 0, payment_id: 0 }];

	return {
		features_permissions_list
	};
}

export default connect(mapStateToProps)(PaymentMethod);