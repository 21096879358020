import { CHEQUE_COUNT_DATA, CHEQUE_FILTER_DATA, CLEAR_CHEQUE, SET_IS_CHEQUE_LOADING, SET_PAGE_NO, SET_PER_PAGE_ITEM, SET_TASK_STAR, SET_TOTAL_COUNT } from "../Actions/Types";

const initialState = {
  cheque_count: {
    linked: 0,
    not_ready: 0,
    processed: 0,
    ready: 0,
    synced: 0,
    total: 0,
  },
  total_count: 0,
  page_no: 1,
  per_page_items: 5,
  changed_star: 0,
  cheque_id: null,
  is_star: null,
  is_cheque_loading: false,
  cheque_count: 0,
  filter_box: {
    from_date: null,
    to_date: null,
    is_star: null,
    uploaded_by: null,
    cheque_no: null,
    bank_name: null,
    cheque_identifier: null,
    payer: null,
    payee: null,
    from_amount: null,
    to_amount: null
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_CHEQUE_LOADING:
      return {
        ...state,
        is_cheque_loading: payload
      }
    case CHEQUE_COUNT_DATA:
      return {
        ...state,
        cheque_count: payload
      }
    case SET_TOTAL_COUNT:
      return {
        ...state,
        total_count: payload
      }
    case SET_PAGE_NO:
      return {
        ...state,
        page_no: payload
      }
    case SET_PER_PAGE_ITEM:
      return {
        ...state,
        per_page_items: payload
      }
    case SET_TASK_STAR:
      return {
        ...state,
        cheque_id: payload.cheque_id,
        changed_star: payload.changed_star
      }
   
    case CHEQUE_FILTER_DATA:
      return {
        ...state,
        filter_box: payload,
      }
    default:
      return state;
  }
}