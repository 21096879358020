import React from 'react';
import { setEntityListData } from "../../../Redux/Actions/Users";
import { connect } from "react-redux";
import { withRouter } from 'react-router';


class AssignEnitytoUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  
  render() {
    return (
      <div className="main-user-entity-select-sec">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-xs-12">
            <div className="user-entity-select-sec">
              <form>
              <p className="s-sbold ft-sz-28 blu-color">Available Entities</p>
              <ul className="user-available-entities">
                {this.props.available_entities.map((entity, index) => {
                  return <li key={index}>
                    <div className="check-box">
                      <input 
                        className="styled-checkbox" 
                        type="checkbox"
                        checked={entity.checked}
                        onChange={(e) => {this.props.handleSelectEntity(e,entity,index)}}
                        id={"styled-checkbox-8"+index}
                        name={"available_entities"+index}
                      />
                      <label htmlFor={"styled-checkbox-8"+index} className="label-text blu-color s-sbold ft-sz-20 mb-0">{entity.name}</label>
                    </div>
                  </li>
                })}
              </ul>
              {/* <div className="main-user-select-entity-btn clearfix">
                <button type="button" className="btn inner-entity-btn ft-sz-22 s-bold white-color pull-right"><span className="icon-plus1 white-color"></span> Add Entities</button>
              </div> */}
              </form>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-xs-12">
            <div className="main-user-permission-sec">
              {this.props.selected_entities.length === 0 ?
                <div className="main-follow-steps-sec">
                  <p className="ft-sz-30 s-bold blu-color">Follow these steps to set up user permissions </p>
                  <div className="step-list-sec">
                    <ol>
                      <li>Assign Entities to user</li>
                      <li>Click on <span className="s-bold">Next</span> to enable permission assignment.</li>
                      <li>Select an Entity to assign role or permissions to user.</li>
                    </ol>
                  </div>
                </div>
                :
                <div className="main-follow-steps-sec">
                  <p className="ft-sz-30 s-bold blu-color">You can add or remove entities for this users.</p>
                  <div className="step-list-sec">
                    <ol>
                      <li>To add entity click on checkbox.</li>
                      <li>To remove entity. Uncheck the selected entity.</li>
                      <li>Click on <span className="s-bold">Next</span> to enable permission assignment.</li>
                    </ol>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
	const all_value = state.User || {};
	const available_entities = all_value.available_entities || [];
	const selected_entities = all_value.selected_entities || [];
  const feature_list = all_value.feature_list || [];

	return {
		available_entities,selected_entities,feature_list
	};
}
export default connect(mapStateToProps)(withRouter(AssignEnitytoUser));