import React, { useState, useEffect } from 'react'

const UserWiseTask = (props) => {
    const [selected, setSelected] = useState([])
    const [filter, setFilter] = useState('')

    const handleClick = (obj) => {
        setSelected(obj)
    }

    useEffect(() => {
        handleClick(props.data[0])
    }, []);

    // useEffect(() => {
    //     handleClick(props.data[0])
    // }) 

    return (
        <div className="chart-box-new users-wise-tasks-dash">
            <div className="row">
                <div className="col-12">
                    <h4 className="chart-box-heading">Users Wise Tasks</h4>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 pr-0">
                    <div className="users-task-list-dash">
                        <div class="search-filter-uwtl">
                            <form onSubmit={e => { e.preventDefault(); }}>
                                <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Search User" />
                            </form>
                            <p>Tasks</p>
                        </div>

                        <ul className="list-group list-group-flush uwtl-menu-dash">
                            {props.data.filter((item => {
                                if (filter == '') {
                                    return item
                                } else if (item.fullname.toLowerCase().includes(filter.toLowerCase())) {
                                    return item
                                }
                            })).map((item) => {
                                return (
                                    <li className={`list-group-item uwtl-menu-li  ${selected.user_id == item.user_id ? 'active-uwtl' : ''}`} onClick={() => handleClick(item)}>
                                        <p className="uwtl-menu-name">{item.fullname}</p>
                                        <p className="uwtl-menu-count" title={item.entities.reduce((accumulator, current) => accumulator + current.count, 0)}>{item.entities.reduce((accumulator, current) => accumulator + current.count, 0)} </p>
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </div>


                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="my-uwtl-profile-new ">
                        <div className="my-uwtl-profile">
                            <div className="myuw-profile">{selected.fullname ? selected.fullname[0] : ''}</div>
                            <div className="myuw-profile-list">
                                <h5 className="myuw-ph">{selected.fullname}</h5>
                                <div className="d-flex align-items-center justify-content-between gap-10">
                                    <p className="myuw-pp">Pending Tasks</p>
                                    <p className="myuw-pp-count">{selected.entities ? selected.entities.reduce((accumulator, current) => accumulator + current.count, 0) : null}</p>
                                </div>
                            </div>
                        </div>

                        <div className="myuw-profile-list-sec">
                        {selected.entities && selected.entities.map((item) => {
                            return (
                                <div className="myuw-profile-list">
                                    <p className="myuw-list-pp">{item.entity}</p>
                                    <p className="myuw-list-pp-count">{item.count}</p>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
            {/* end row */}
        </div>
    )
}

export default UserWiseTask 