import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { setEntityListData, setSelectedEntities, setmoduleList, setDefaultRoles, setPermissionIds } from "../../../Redux/Actions/Users";
import ModuleComponent from './ModuleComponent';

class AssignPermission extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      role_id: this.props.selected_entity.role_id,
      selected_entity_index: this.props.selected_entity_index,
      copy_from_entity_id: "",
      permissions_ids: [],
      module_list: [],
    }
    // console.log("construct----------------",this.props.selected_entities)
  }


  getmodules = () => {
    /*let role = this.props.default_roles.find(role => role.id == this.state.role_id);

    let selected_entities = this.props.selected_entities;*/
    // console.log("selected_entities----------------",selected_entities)
    let entity = JSON.parse(JSON.stringify(this.props.selected_entities[this.state.selected_entity_index]));

    // let entity = this.props.selected_entity;
    let module_list = entity.modules.map((module) => {
      // console.log("module",module)
      // module.permissions = module.permissions.map((perm) => {
      //   perm.checked = !!role ? role.permission_ids.includes(perm.permission_id) || perm.checked : false;
      //   return perm;
      // });
      return module;
    });
    /*entity.role_id = this.state.role_id;
    entity.custom_rights = 0;
    entity.modules = module_list;
    selected_entities[this.state.selected_entity_index] = entity;*/

    this.setState({ module_list: [] }, () => { this.setState({ module_list: module_list }); });


    /*if (this.props.same_permission == 1) {
      let selected_entities = this.props.selected_entities.map((entity) => {
        entity.role_id = entity.role_id;
        entity.custom_rights = 1;
        entity.modules = module_list;
        return entity;
      });
      this.props.setSelectedEntities(selected_entities);
    }*/

  }

  componentDidMount() {
    this.getmodules();
  }

  roleChanged = (e) => {
    const role_id = e.target.value;
    this.setState({ role_id: role_id });
    /*
      below code
    */
    let role = this.props.default_roles.find(role => role.id == role_id);

    let selected_entities = this.props.selected_entities;
    // console.log("selected_entities----------------",selected_entities)
    let entity = JSON.parse(JSON.stringify(this.props.selected_entities[this.state.selected_entity_index]));

    // let entity = this.props.selected_entity;
    let module_list = entity.modules.map((module) => {
      module.permissions = module.permissions.map((perm) => {
        perm.checked = !!role ? role.permission_ids.includes(perm.permission_id) : false;
        return perm;
      });
      return module;
    });
    entity.role_id = role_id;
    entity.custom_rights = 0;
    entity.modules = module_list;
    selected_entities[this.state.selected_entity_index] = entity;

    this.setState({ module_list: [] }, () => { this.setState({ module_list: module_list }); });

    /*if (this.props.same_permission == 1) {
      let selected_entities = this.props.selected_entities.map((entity) => {
        entity.role_id = entity.role_id;
        entity.custom_rights = 1;
        entity.modules = module_list;
        return entity;
      });
      this.props.setSelectedEntities(selected_entities);
    }*/



  }

  handlePermissionChange = (checked, perm_index, module_index) => {
    let module_list = this.state.module_list;

    let module = module_list[module_index];
    //permission
    let module_permission = module.permissions[perm_index];
    module_permission.checked = checked;
    module.permissions[perm_index] = module_permission;
    module_list[module_index] = module;

    let selected_entities = this.props.selected_entities;
    let entity = selected_entities[this.state.selected_entity_index];
    // let entity = this.props.selected_entity;
    entity.custom_rights = 1;
    entity.modules = module_list;
    selected_entities[this.state.selected_entity_index] = entity;
    // this.props.setSelectedEntities(selected_entities);

    this.setState({ module_list: [], }, () => {
      this.setState({ module_list: module_list });
    });

    if (this.props.same_permission == 1) {
      let selected_entities = this.props.selected_entities.map((entity) => {
        if (entity.checked) {
          entity.custom_rights = 1;
          entity.modules = module_list;
        }
        return entity;
      });
      //this.props.setSelectedEntities(selected_entities);
    }

  }


  copyEntitymodule = (e) => {
    let copy_from_entity_id = e.target.value;
    this.setState({ copy_from_entity_id: copy_from_entity_id })

    let copy_from_entity = this.props.selected_entities.find((entity) => {
      return copy_from_entity_id == entity.id;
    });

    let selected_entities = this.props.selected_entities;
    let entity = selected_entities[this.state.selected_entity_index];
    this.setState({ role_id: copy_from_entity.role_id });
    entity.role_id = copy_from_entity.role_id;
    entity.custom_rights = copy_from_entity.custom_rights;
    entity.modules = copy_from_entity.modules;

    this.setState({ module_list: [], }, () => {
      this.setState({ module_list: entity.modules });
    });

  }

  render() {
    let selected_entity = this.props.selected_entity;
    let roles = _.sortBy(this.props.default_roles, (data) => data.name.toLowerCase());

    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-lg-4">
            <div className="form-group">
              <lable className="lable-input">User role</lable>
              <select className="form-control"
                name="role_id"
                value={this.state.role_id}
                onChange={this.roleChanged}>
                <option key={0} value="0">Select Role</option>
                {roles.map((roles, index) => {
                  return <option key={roles.id + index} value={roles.id}>{roles.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-1 d-flex align-items-center justify-content-center">
            <p className="size-xvii mb-0">OR</p>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="form-group">
              <lable className="lable-input">Copy From other entity?</lable>
              <select className="form-control"
                // name="copy_from_entity_id"
                name={this.state.copy_from_entity_id}
                onChange={this.copyEntitymodule}>
                <option value="">Select Entity</option>
                {this.props.selected_entities.map((entity, index) => {
                  if (index != this.state.selected_entity_index && entity.checked)
                    return <option key={index} value={entity.id}>{entity.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="col-12">
            {this.props.errors["entity_" + selected_entity.id] &&
               <small className="error_left ">{this.props.errors["entity_" + selected_entity.id]}</small> 
            }
          </div>
          {/* <div className="col-lg-4 col-xs-12">
            <div className="form-group">
              <p className="lable-input font-colr m-0">Custom Rights</p>
              {!!selected_entity.custom_rights ?
                  <span className="custom-right-text s-bold ft-sz-32 gren-color">Yes</span>
                  :
                  <span className="custom-right-text s-bold ft-sz-32 gren-color">No</span>
              }
            </div>
          </div> */}
        </div>

        {/* Cards Sections */}
        <div className="pmgt-user-cards-checkbox">
          {
            this.state.module_list.map((module_t, module_index) => {
              return <ModuleComponent
                key={"module_" + module_index + this.state.selected_entity_index}
                module_nmae={"entitypermision_" + this.state.selected_entity_index}
                module={module_t}
                module_index={module_index}
                selected_entity_index={this.state.selected_entity_index}
                handlePermissionChange={this.handlePermissionChange}
              />
            })
          }
        </div>
        {/* End Cards Sections */}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.User || {};
  const available_entities = all_value.available_entities || [];
  const module_list = all_value.entity_module || [];
  const default_roles = all_value.default_roles || [];

  return {
    available_entities, module_list, default_roles,
  };
}

export default connect(mapStateToProps)(withRouter(AssignPermission));