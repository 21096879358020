import React from 'react'

class PrivateMessageBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="bottom-comments-sec">
                <div className="head-sec">
                    <span className="ico-div"><span className="icon-chat"></span></span>
                    <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                </div>
                <div className="inner-comment-scroll">
                    <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                        </div>
                        <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                                <p className="ft-sz-16 s-sbold">2 days ago</p>
                                <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                        </div>
                    </div>
                    <div className="inner-comment-sec clearfix">
                        <div className="first-letter red-bg s-sbold">
                            <span>S</span>
                        </div>
                        <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                                <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                                <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                        </div>
                    </div>
                    <div className="inner-comment-sec clearfix">
                        <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                        </div>
                        <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                                <p className="ft-sz-16 s-sbold">just now</p>
                                <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrivateMessageBox;