import React from 'react';
import { Dropdown,Button } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import profile_sm from '../../Assets/img/profile-sm.png';
import entity1 from '../../Assets/img/entity1.png';
import entity2 from '../../Assets/img/entity2.png';
import entity3 from '../../Assets/img/entity3.png';
import profilem from '../../Assets/img/profilem.png';
import gplay from '../../Assets/img/gplay.png';
import accountp from '../../Assets/img/accountp.png';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from "react-bootstrap/Card";;
import Accordion from 'react-bootstrap/Accordion';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'; 

function profileentity(){

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li>
                  <a href="#"><span class="icon-loupe"></span></a>
              </li>
              <li className="main-gift-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-1"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">What's new?</p>
                      <div className="inner-main-gift-sec">
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                               <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-invoice1"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Add Multiple Evindences</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-documents"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Improved Docbox</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Group-4398"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Customize Licenses</p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities!  </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-gift-sec">
                          <div className="row">
                            <div className="col-lg-2 col-md-2 col-xs-2">
                              <span class="icon-sec icon-Mask-Group-4"></span>
                            </div>
                            <div className="col-lg-10 col-md-10 col-xs-10 p-0">
                              <p className="ft-sz-20 s-sbold blu-color mb-2">Now Share Your Entities! </p>
                              <p className="para-sec ft-sz-18 s-reg blu-color mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="main-noti-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    <a href="#"><span class="icon-gift"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-2"></span> */}
                      <p className="head-sec ft-sz-26 s-bold blu-color">Notifications</p>
                      <div className="inner-main-noti-sec">
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">New</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Amit Bhattacharya</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Eden Hazard</spana> mentioned you in a comment on Evidence <spana className="s-sbold">ID#184</spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      
                        <p className="ft-sz-22 s-sbold blu-color mb-1 sub-head">EARLIER</p>
                        <ul>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span className="icon-bottom icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1"><spana className="s-sbold">Pankaj Thote</spana> assigned you a task</p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">Yesterday at 6:30 pm</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="inner-noti-sec">
                              <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-3">
                                  <div className="noti-cap ft-sz-30 s-sbold white-color"><span>A</span>
                                    <span class="icon-bottom icon-Group-3180"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-xs-9 p-0 para-sec">
                                  <p className="ft-sz-18 s-reg blu-color mb-1">Your trial license <spana className="s-sbold">expires in 2 days! </spana></p>
                                  <p className="ft-sz-18 s-bold gren-color mb-0">2 hours ago</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
              <li className="profile-menu">
                  <Dropdown>
                  <img className="profile-small-img" src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <span className="triangle-3"></span>
                        <div className="main-profile-sec">
                          <div className="profile-img-name-sec">
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-xs-12">
                                <img className="img-fluid mx-auto d-block" src={profile_sm} />
                              </div>
                              <div className="col-lg-8 col-md-9 col-xs-12 text-sec clearfix">
                                <div className="edit-sec">
                                  <span className="icon-edit"></span>
                                </div>
                                <p className="ft-sz-22 s-bold blu-color mb-0">Mahesh Raje</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">maheshraje@gmail.com</p>
                                <p className="ft-sz-18 s-reg blu-color mb-0">+91 9875487548</p>
                                <button type="button" className="btn sign-out-btn white-color s-bold ft-sz-17">Sign out</button>
                              </div>
                            </div>
                          </div>
                          <form className="search-sec clearfix">
                            <input type="text" placeholder="Search Entity" />
                            <button type="submit"><span className="icon-loupe"></span></button>
                          </form>
                          <div className="profile-entities-list">
                            <p className="top-head ft-sz-22 s-sbold white-color m-0 green-bg">Entities</p>
                            <ul>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">ABC Technologies </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity1} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Oil and Natural Gas Corporation Limited.</Tooltip>}>
                                         <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Oil and Natural Gas Corporation Limited.</p>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity2} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">R K Pharma Limited </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="inner-entity-sec">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xs-3">
                                      <img className="img-fluid mx-auto d-block" src={entity3} />
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-xs-9 p-0">
                                      <p className="ft-sz-20 s-sbold blu-color mb-0 entity-name">Shri krishna Devlopers</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
              <li className="main-receipts-menu-sec">
                  <Dropdown>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                     <a href="#"><span class="icon-menu"></span></a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <span className="triangle-4"></span> */}
                      <p className="top-title ft-sz-26 s-reg blu-color">Now you can manage all your receipts with a click of button!</p>
                      <div className="download-mob-sec">
                        <p className="ft-sz-18 s-sbold font-colr">Download our mobile app</p>
                        <img className="" src={gplay}/>
                      </div>
                      <div className="account-partner-sec">
                        <p className="ft-sz-20 s-sbold blu-color mbb-2">Accounting Partner</p>
                        <img className="" src={accountp}/>
                        <p className="ft-sz-22 s-sbold blu-color mb-0 mt-2">Clarity Account {'&'} Taxation</p>
                      </div>
                      <hr/>
                      <div className="das-link-sec">
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4408"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Dashboard</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span className="text-name"><span class="icon-Group-5303"></span> Link Evidence</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-lightbulb"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> <span className="text-name">Help</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="cap-evi-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Capture Evidence</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4402"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> <span className="text-name">Evidences </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-bill"></span> <span className="text-name">Invoices</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-cheque-2"></span> <span className="text-name">Cheques</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Master Data</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-5305"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Financial Institutes & Account Statement</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-5304"></span> <span className="text-name">Master Accounting Data</span></a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div className="master-data-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase"><span class="icon-Group-5303 mr-3"></span> Link evidence</p>
                      </div>
                      <hr/>
                      <div className="tools-sec">
                        <p className="ft-sz-22 s-sbold blu-color text-uppercase">Tools</p>
                        <ul>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-to-do-list"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span> <span className="text-name">Task Manager </span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-chat1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Messenger</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4403"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Doc Box</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4404"><span class="path1"></span><span class="path2"></span></span> <span className="text-name">Export and Reports</span></a>
                          </li>
                          <li>
                            <a className="s-sbold font-colr"><span class="icon-sec icon-Group-4405"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> <span className="text-name">Profile Management</span></a>
                          </li>
                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
      <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Profile Management</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Profile Management <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
                  <span className="icon-Information green-icon green-icon-head"></span></OverlayTrigger></h2>
                  
                </div>
                <div className="pull-right">
                  <button className="lic-bill-btn btn ft-sz-22 s-bold white-color">License {'&'} Billing</button>
                </div>
              </div>
              <div className="main-my-account-sec">
                <h2 className="s-sbold blu-color d-inline m-0 align-middle">My Account</h2>
                <button type="button" class="btn btn-labeled ft-sz-18 s-reg"><span class="btn-label"><span class="icon-Mask-Group-1 ico-moon"></span></span>Business Account</button>
                <a href="" className="edit-btn"><span class="icon-edit"></span></a>
                <a href="" class="cross"><span class="cross-btn">X</span></a>
                <hr/>
                <div className="my-account-form">
                  <div className="row">
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Account ID</p>
                        <input type="text" className="form-control input-bg-gray" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Account Email</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Name</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Mobile Number</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">GST Number</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-5 col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Address</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">City</p>
                        <select className="form-control" required>
                          <option value="" selected disabled></option>
                          <option value="saab">Saab</option>
                          <option value="vw">VW</option>
                          <option value="audi">Audi</option>
                        </select>             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">State</p>
                        <select className="form-control" required>
                          <option value="" selected disabled></option>
                          <option value="saab">Saab</option>
                          <option value="vw">VW</option>
                          <option value="audi">Audi</option>
                        </select>             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Country</p>
                        <select className="form-control" required>
                          <option value="" selected disabled></option>
                          <option value="saab">Saab</option>
                          <option value="vw">VW</option>
                          <option value="audi">Audi</option>
                        </select>             
                      </div>
                    </div>
                    <div className="col-xl col-md-4">
                      <div className="form-group">
                        <p className="lable-input font-colr m-0">Pincode</p>
                        <input type="text" className="form-control" value="" />             
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-save-btn clearfix">
                <button type="button" className="btn save-btn ft-sz-22 white-color s-bold pull-right">Save</button>
              </div>

              <div className="main-entity-user-sec">
                  <div className="main-add-entity-btn">
                   <button type="button" className="btn add-entity-btn ft-sz-22 s-bold white-color"><span class="icon-plus1 white-color"></span> Add Single Entity</button>
                   <button type="button" className="btn add-entity-btn ft-sz-22 s-bold white-color d-none"><span class="icon-plus1 white-color"></span> Add User</button>
                  </div>
                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" defaultActiveKey="home">
                  <Tab eventKey="home" title="Entities">
                    {/* start Entities */}
                    <div className="main-single-entity-add-form d-none">
                      <div class="cross" onClick={handleShow}><span>X</span></div>
                      <div className="main-entity-basic-sec">
                        <div class="row no-gutters">
                          <div class="col-md-6 col-xs-12">
                            <div className="entity-company-name">
                              <p className="sub-head ft-sz-25 s-reg">Entity</p>
                              <div className="head ft-sz-30 s-sbold blu-color">Tecture Business Solutions pvt. ltd.</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-xs-12">
                            <div className="plan-expire-sec">
                              <div className="inner-plan-expire-sec font-colr">
                                Your Current License
                                <div className="button-sec ft-sz-20 s-bold white-color"><span>Basic</span></div>
                              </div>
                              <p className="m-0 s-reg date-sec">Expires on 15-3-2021</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">General</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Name</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Type</p>
                                  <select className="form-control" required>
                                    <option value="" selected disabled></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-5 col-md-4">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Account Address</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">City</p>
                                  <select className="form-control" required>
                                    <option value="" selected disabled></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">State</p>
                                  <select className="form-control" required>
                                    <option value="" selected disabled></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Country</p>
                                  <select className="form-control" required>
                                    <option value="" selected disabled></option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>             
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Pincode</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">Taxation Details</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-3 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">PAN number*</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">GST number</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">TAN number</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head pull-left">Paid By</p>
                        <button type="button" class="btn buy-lic-btn ft-sz-22 s-bold white-color pull-right">Buy License</button>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-5 col-md-5">
                                <div className="btn-group btn-toggle left-on-off-btn paid-by-toggle"> 
                                  <button className="btn btn-lg btn-default active">Business (Self)</button>
                                  <button className="btn btn-lg btn-primary ">Sponsored</button>
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Subscription</p>
                                  <select className="form-control" required>
                                    <option value="" selected>Basic (Monthly) Subscription</option>
                                    <option value="saab">Saab</option>
                                    <option value="vw">VW</option>
                                    <option value="audi">Audi</option>
                                  </select>              
                                </div>
                              </div>
                              <div className="col-xl-4 col-md-5  d-none">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Enter code</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">CA Parag Joshi (A/C ID 4153692)</p>
                                  <input type="text" className="form-control blu-color" value="Basic ( Monthly Subscription )" />             
                                </div>
                              </div>
                              <div className="col-2  d-none">
                              <button type="button" class="btn buy-lic-btn ft-sz-20 s-bold white-color pull-right">Modify</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head pull-left">Users</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-12 col-xs-12">
                            <div className="main-table-sec">
                              <Table bordered responsive>
                                <thead>
                                  <tr>
                                    <th>User <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>User Name <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Role <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Last Login <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Date Created <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                    <th>Status <span class="icon-arrow-down-sign-to-navigate"></span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Accountant</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                                      <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Staff</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                                      <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Accountant</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                                      <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Manoj Tiwari</td>
                                    <td>ManojT</td>
                                    <td>Staff</td>
                                    <td>5 Oct 2020 | 10:30 am</td>
                                    <td>15 May 2020</td>
                                    <td> 
                                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                                      <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head pull-left">Sharing</p>
                        <div className="toggle-btn-sec pull-right">
                          <span className="toggle-btn-sec-text s-sbold">No Sharing</span>
                          <label className="switch">
                            <input type="checkbox" checked/>
                            <span className="slider round"></span> 
                          </label>
                          <span className="toggle-btn-sec-text s-sbold">Share Entity</span>
                        </div>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-xl-4 col-md-5">
                                <div className="form-group">
                                  <input type="text" className="form-control ft-sz-15" value="" placeholder="Account code/ registered email ID"/>             
                                </div>
                              </div>
                              <div className="col-xl-6 col-md-7">
                                <div className="main-shar-btn">
                                 <div class="shar-btn ft-sz-22 s-bold blu-color">Modify Rights</div>      
                                 <div class="shar-btn ft-sz-22 s-bold blu-color">+ Add</div>      
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-10 col-xs-12">
                                <div className="main-share-edit-sec">
                                  <p className="lable-input font-colr m-0">Shared with</p>
                                  <div className="share-edit-sec">
                                    <div className="row">
                                      <div className="col-xl-4 col-xs-12">
                                        <div className="inner-share-edit-sec">
                                          <span class="icon-edit"></span>
                                          <p className="head-title ft-sz-18 blu-color s-bold">Tecture Pvt.Ltd</p>
                                          <p className="ft-sz-16 s-sbold blu-color m-0">A100125801</p>
                                          <p className="ft-sz-16 s-sbold blu-color m-0">tecture@gmail.com</p>
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-xs-12">
                                        <div className="inner-share-edit-sec">
                                          <span class="icon-edit"></span>
                                          <p className="head-title ft-sz-18 blu-color s-bold">Tecture Pvt.Ltd</p>
                                          <p className="ft-sz-16 s-sbold blu-color m-0">A100125801</p>
                                          <p className="ft-sz-16 s-sbold blu-color m-0">tecture@gmail.com</p>
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-xs-12">
                                        <div className="inner-share-edit-sec">
                                          <span class="icon-edit"></span>
                                          <p className="head-title ft-sz-18 blu-color s-bold">Tecture Pvt.Ltd</p>
                                          <p className="ft-sz-16 s-sbold blu-color m-0">A100125801</p>
                                          <p className="ft-sz-16 s-sbold blu-color m-0">tecture@gmail.com</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>  
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="title-head-sec clearfix">
                        <p className="ft-sz-30 s-sbold blu-color title-head">Additional Details</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-3 col-xs-12"></div>
                          <div className="col-xl-9 col-xs-12">
                            <div className="row">
                              <div className="col-lg-4 col-md-5">
                                <div className="main-details-upload-sec">
                                  <p className="lable-input font-colr m-0 s-sbold">Digital Signature</p>
                                  <div className="inner-details-upload-sec">
                                    <span class="icon-Group-2328"></span>
                                    <p className="font-colr ft-sz-18 upload-text">Upload Your Image</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-5">
                                <div className="main-details-upload-sec">
                                  <p className="lable-input font-colr m-0 s-sbold">Digital Signature</p>
                                  <div className="inner-details-upload-sec">
                                    <span class="icon-Group-2328"></span>
                                    <p className="font-colr ft-sz-18 upload-text">Upload Your Image</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <p className="ft-sz-18 blu-color prefer-img-size">Preferred image size; 240px x 240px 272 DPI Maximum Size of 1MB</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                      {/* End start Entities */}

                    <div className="tab-inner-sec">
                      <div className="row">
                        <div className="col-xs-12 col-lg-2 col-md-4 col-sm-4">
                          <div className="count-box">
                            <p className="ft-sz-29 blu-color m-0">Total</p>
                            <div className="count-char s-bold">5</div>
                            {/* <p className="count-bottim-text ft-sz-14 font-colr">lorem ipsum text</p> */}
                          </div>
                        </div>
                        <div className="col-xs-12 col-lg-2 col-md-4 col-sm-4">
                          <div className="count-box">
                            <p className="ft-sz-29 blu-color m-0">Paid By Me</p>
                            <div className="count-char s-bold">3</div>
                            {/* <p className="count-bottim-text ft-sz-14 font-colr">lorem ipsum text</p> */}
                          </div>
                        </div>
                        <div className="col-xs-12 col-lg-2 col-md-4 col-sm-4">
                          <div className="count-box">
                            <p className="ft-sz-29 blu-color m-0">Paid By Others</p>
                            <div className="count-char s-bold">2</div>
                            {/* <p className="count-bottim-text ft-sz-14 font-colr">lorem ipsum text</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="col-xs-12">
                        <p className="count-bottim-text ft-sz-14 font-colr mt-3 mb-0">lorem ipsum text</p>
                        </div>
                      </div>
                    </div>

                  <div className="main-profile-entity-user-sec">     
                    <div className="toggle-search-sec clearfix">
                      <div className="search-filter-sec pull-right">
                        <div className="filter">
                        Filter <span className="icon-funnel"></span>
                        </div>
                      </div>
                    </div>
                    <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg col-xs-12">
                          <div className="form-group">
                           <input type="text" className="form-control" value="" placeholder="Entity" />
                          </div>
                        </div>
                        <div className="col-lg col-xs-12">
                          <div className="form-group">
                            <select className="form-control" required>
                              <option value="" selected disabled>Type</option>
                              <option value="saab">Saab</option>
                              <option value="vw">VW</option>
                              <option value="audi">Audi</option>
                            </select>             
                          </div>
                        </div>
                        <div className="col-lg col-xs-12">
                          <div className="form-group">
                            <select className="form-control" required>
                              <option value="" selected disabled>License</option>
                              <option value="saab">Saab</option>
                              <option value="vw">VW</option>
                              <option value="audi">Audi</option>
                            </select>             
                          </div>
                        </div>
                        <div className="col-lg col-xs-12">
                          <div className="form-group">
                            <select className="form-control" required>
                              <option value="" selected disabled>Paid By</option>
                              <option value="saab">Saab</option>
                              <option value="vw">VW</option>
                              <option value="audi">Audi</option>
                            </select>             
                          </div>
                        </div>
                        <div className="col-lg col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Users" />               
                          </div>
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-lg-3 col-xs-12">
                          <div className="form-group date-sec">
                            <input type="text" className="form-control" placeholder="Expiring Before"/>
                            <span class="icon-calendar"></span>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                    </div>

                    <div className="main-table-sec">
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th>Entity <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Type <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>License <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Expires on <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Paid By <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Users <span class="icon-arrow-down-sign-to-navigate"></span></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>RK Pharma INC</td>
                            <td>LLP</td>
                            <td>Basic (Add ons)</td>
                            <td>15 May 2021</td>
                            <td>CA Ramesh Pande</td>
                            <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                          </tr>
                          <tr>
                            <td>RK Pharma INC</td>
                            <td>LLP</td>
                            <td>Basic (Add ons)</td>
                            <td>15 May 2021</td>
                            <td>CA Ramesh Pande</td>
                            <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                          </tr>
                          <tr>
                            <td>RK Pharma INC</td>
                            <td>LLP</td>
                            <td>Basic (Add ons)</td>
                            <td>15 May 2021</td>
                            <td>CA Ramesh Pande</td>
                            <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                          </tr>
                          <tr>
                            <td>RK Pharma INC</td>
                            <td>LLP</td>
                            <td>Basic (Add ons)</td>
                            <td>15 May 2021</td>
                            <td>CA Ramesh Pande</td>
                            <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                          </tr>
                          <tr>
                            <td>RK Pharma INC</td>
                            <td>LLP</td>
                            <td>Basic (Add ons)</td>
                            <td>15 May 2021</td>
                            <td>CA Ramesh Pande</td>
                            <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                          </tr>
                          <tr>
                            <td>RK Pharma INC</td>
                            <td>LLP</td>
                            <td>Basic (Add ons)</td>
                            <td>15 May 2021</td>
                            <td>CA Ramesh Pande</td>
                            <td>Manoj Tiwari, Rohit Mishra, Ramesh Pathak <a className="ft-sz-18 s-sbold blu-colr">5 more</a></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>  
                  </Tab>
                  <Tab eventKey="users" title="Users" >
                    <div className="main-table-sec">
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th>User <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>User Name <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Entities <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Date Created <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Last Login <span class="icon-arrow-down-sign-to-navigate"></span></th>
                            <th>Status <span class="icon-arrow-down-sign-to-navigate"></span></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Manoj Tiwari</td>
                            <td>ManojT</td>
                            <td>RK Pharma INC, CPA Technologies, Tecture Pvt.Ltd, and  
                              <OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled" className="tbl-user-ent">
                                <ul>
                                  <li>RK Pharma</li>
                                  <li>CPA Technologies</li>
                                  <li>CPA Technologies RK Pharma</li>
                                  <li>RK Pharma</li>
                                </ul>
                              </Tooltip>}>
                               <a className="td-more-text s-sbold font-colr"> 20 more</a>
                              </OverlayTrigger>
                            </td>
                            <td>15 May 2020</td>
                            <td>5 Oct 2020 | 10:30 am</td>
                            <td> 
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                              <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>Manoj Tiwari</td>
                            <td>ManojT</td>
                            <td>RK Pharma INC, CPA Technologies, Tecture Pvt.Ltd, and  
                              <OverlayTrigger placement={'top'} overlay={<Tooltip id="tooltip-disabled" className="tbl-user-ent">
                                <ul>
                                  <li>RK Pharma</li>
                                  <li>CPA Technologies</li>
                                  <li>CPA Technologies RK Pharma</li>
                                  <li>RK Pharma</li>
                                </ul>
                              </Tooltip>}>
                                <a className="td-more-text s-sbold font-colr">20 more</a>
                              </OverlayTrigger>
                            </td> 
                            <td>15 May 2020</td>
                            <td>5 Oct 2020 | 10:30 am</td>
                            <td> 
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                              <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>Manoj Tiwari</td>
                            <td>ManojT</td>
                            <td>RK Pharma INC, CPA Technologies, Tecture Pvt.Ltd, and  <a href="#" className="s-sbold font-colr">20 more</a></td>
                            <td>15 May 2020</td>
                            <td>5 Oct 2020 | 10:30 am</td>
                            <td> 
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                              <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>Manoj Tiwari</td>
                            <td>ManojT</td>
                            <td>RK Pharma INC, CPA Technologies, Tecture Pvt.Ltd, and  <a href="#" className="s-sbold font-colr">20 more</a></td>
                            <td>15 May 2020</td>
                            <td>5 Oct 2020 | 10:30 am</td>
                            <td> 
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                              <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>Manoj Tiwari</td>
                            <td>ManojT</td>
                            <td>RK Pharma INC, CPA Technologies, Tecture Pvt.Ltd, and  <a href="#" className="s-sbold font-colr">20 more</a></td>
                            <td>15 May 2020</td>
                            <td>5 Oct 2020 | 10:30 am</td>
                            <td> 
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Active</Tooltip>}>
                              <span class="icon-td icon-Group-3216"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>Manoj Tiwari</td>
                            <td>ManojT</td>
                            <td>RK Pharma INC, CPA Technologies, Tecture Pvt.Ltd, and  <a href="#" className="s-sbold font-colr">20 more</a></td>
                            <td>15 May 2020</td>
                            <td>5 Oct 2020 | 10:30 am</td>
                            <td> 
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Inactive</Tooltip>}>
                              <span class="icon-td icon-Group-3218"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>


                    <Modal
                      show={show}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                      centered
                      className="save-model-sec"
                    >
                      
                      <Modal.Body className="clearfix">
                        <div className="pull-left mr-3">
                          {/* <i class="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                          <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </div>
                        <div className="pull-left">
                          <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                          <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                        <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                      </Modal.Footer>
                    </Modal>  

                    {/* start Users */}
                    <div className="main-single-entity-add-form">
                      <div class="cross" onClick={handleShow}><span>X</span></div>
                      <div className="title-head-sec">
                        <p className="ft-sz-30 s-sbold blu-color title-head">General</p>
                      </div>
                      <div className="inner-entity-add-form">
                        <div className="row">
                          <div className="col-xl-6 col-xs-12 inner-gnrl-sec">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Name<span className="red-star">*</span></p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Username<span className="red-star">*</span></p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-xs-12 inner-gnrl-sec">
                            <div className="row no-gutters">
                              <div className="col-sm-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Email*</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                              <div className="col-sm-2"><p className="or-text-sec s-bold ft-sz-18 blu-color m-0 text-center">OR</p></div>
                              <div className="col-sm-5">
                                <div className="form-group">
                                  <p className="lable-input font-colr m-0">Mobile Number*</p>
                                  <input type="text" className="form-control" value="" />             
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <br/>

                      <div className="title-head-sec clearfix">
                        <div className="row justify-content-between">
                          <div className="col-lg-6 col-md-6">
                            <p className="ft-sz-30 s-sbold blu-color title-head">Entities {'&'} Permissions</p>
                          </div>
                          <div className="col-lg-4 col-md-6">
                            <div className="user-progressbar">
                              <ul id="progressbar">
                                <li className="active">Assign Entities <span className="dot d-none"></span>
                                  <span className="dot-full">
                                    <span>&#10003;</span>
                                  </span>
                                </li>
                                <li>Assign Permission <span className="dot d-none"></span></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        
                        
                      </div>
                      <div className="assign-right-btton-sec clearfix">
                        <p className="ft-sz-30 s-bold blu-color title-btn-sec pull-left d-none">Assign Entities to user</p>

                        <div className="step-one-two-sec s-sbold font-colr pull-left">
                           <span>Assign Entities to user</span> 
                           <span className="d-none">Assign permission</span> 
                          <div className="button-sec ft-sz-20 s-bold white-color cursor-menu"><span>Step1:</span><span className="d-none">Step2:</span></div>
                        </div>

                        <p className="ft-sz-30 s-bold blu-color title-btn-sec pull-left d-none">Assign Permission</p>
                        <button type="button" class="next-btn btn apply-btn ft-sz-22 white-color s-bold pull-right"><span>Next</span><span className="d-none">Back</span></button>
                      </div>
                      <div className="main-user-entity-select-sec">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-xs-12">
                            <div className="same-permission-text">
                              <span className="blu-color s-sbold ft-sz-16 mr-auto">Use same permissions for all entities?</span>
                              {/* <ButtonGroup aria-label="Basic example">
                                <Button variant="secondary switch-yes-no">Yes</Button>
                                <Button variant="secondary switch-yes-no">No</Button>
                              </ButtonGroup> */}
                              <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                                <ToggleButton value={1}>Yes</ToggleButton>
                                <ToggleButton value={2}>No</ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-xs-12">
                            <div className="user-entity-select-sec">
                              <p className="s-sbold ft-sz-28 blu-color">Available Entities</p>
                              <p className="s-sbold ft-sz-28 blu-color d-none">Selected Entities</p>
                              
                              <ul className="user-available-entities">
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-11" type="checkbox" value="value11" />
                                    <label for="styled-checkbox-11" className="label-text blu-color s-sbold ft-sz-20 mb-0">CPA Technologies</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-21" type="checkbox" value="value21" />
                                    <label for="styled-checkbox-21" className="label-text blu-color s-sbold ft-sz-20 mb-0">RK Pharma INC</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3" />
                                    <label for="styled-checkbox-3" className="label-text blu-color s-sbold ft-sz-20 mb-0">ABC Technologies</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4" />
                                    <label for="styled-checkbox-4" className="label-text blu-color s-sbold ft-sz-20 mb-0">Tecture Pvt.Ltd</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value5" />
                                    <label for="styled-checkbox-5" className="label-text blu-color s-sbold ft-sz-20 mb-0">CKL Technologies</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value6" />
                                    <label for="styled-checkbox-6" className="label-text blu-color s-sbold ft-sz-20 mb-0">Joshi Foods</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-7" type="checkbox" value="value7" />
                                    <label for="styled-checkbox-7" className="label-text blu-color s-sbold ft-sz-20 mb-0">Radha Developers </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box">
                                    <input class="styled-checkbox" id="styled-checkbox-8" type="checkbox" value="value8" />
                                    <label for="styled-checkbox-8" className="label-text blu-color s-sbold ft-sz-20 mb-0">Star Marketing</label>
                                  </div>
                                </li>
                              </ul>

                              <ul className="user-select-entities">
                                <li className="active">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">CPA Technologies</p>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">RK Pharma INC</p>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">ABC Technologies</p>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="check-box">
                                    <p className="label-text blu-color s-sbold ft-sz-20 mb-0">Tecture Pvt.Ltd</p>
                                  </div>
                                </li>
                              </ul>
                              <div className="main-user-select-entity-btn clearfix">
                                <button type="button" className="btn inner-entity-btn ft-sz-22 s-bold white-color pull-right"><span class="icon-plus1 white-color"></span> Assign Entities</button>
                              </div>

                            </div>
                          </div>
                          <div className="col-lg-8 col-md-12 col-xs-12">
                            <div className="main-user-permission-sec">
                              <div className="main-follow-steps-sec d-none">
                                <p className="ft-sz-30 s-bold blu-color">Follow these steps to set up user permissions </p>
                                <div className="step-list-sec">
                                  <ol>
                                    <li>Assign Entities to user</li>
                                    <li>Click on <span className="s-bold">Next</span> to enable permission assignment.</li>
                                    <li>Select an Entity to assign role or permissions to user.</li>
                                  </ol>
                                </div>
                              </div>

                              {/* User edit section */}
                              <div className="main-follow-steps-sec d-none">
                                <p className="ft-sz-30 s-bold blu-color">You can add or remove entities for this users.</p>
                                <div className="step-list-sec">
                                  <ol>
                                    <li>To add entity click on checkbox.</li>
                                    <li>To remove entity. Uncheck the selected entity.</li>
                                    <li>Click on <span className="s-bold">Next</span> to enable permission assignment.</li>
                                  </ol>
                                </div>
                              </div>
                              {/* End User edit section */}

                              <div className="user-copy-custom-sec">
                                <div className="row">
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                      <p className="lable-input font-colr m-0">User role</p>
                                      <select className="form-control" required>
                                        <option value="">Power User</option>
                                        <option value="saab">Staff</option>
                                        <option value="vw">In-house Accountant</option>
                                        <option value="audi">External Accountant/Chartered Accountant</option>
                                      </select>             
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                      <p className="lable-input font-colr m-0">Copy From other entity?</p>
                                      <select className="form-control" required>
                                        <option value="">Power User</option>
                                        <option value="saab">Staff</option>
                                        <option value="vw">In-house Accountant</option>
                                        <option value="audi">External Accountant/Chartered Accountant</option>
                                      </select>             
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-xs-12">
                                    <div className="form-group">
                                      <p className="lable-input font-colr m-0">Custom Rights</p>
                                      <span className="custom-right-text s-bold ft-sz-32 gren-color">Yes</span>
                                      <span className="custom-right-text s-bold ft-sz-32 gren-color">No</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="main-card-checkbox-sec">
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Evidences</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Create Business Snapshot</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">Manage all other user snapshots</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-33" type="checkbox" value="value33" />
                                          <label for="styled-checkbox-33" className="label-text blu-color s-reg ft-sz-15 mb-0">View Only</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-34" type="checkbox" value="value34" />
                                          <label for="styled-checkbox-34" className="label-text blu-color s-reg ft-sz-15 mb-0">Create Reimbursement Snapshot</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-35" type="checkbox" value="value35" />
                                          <label for="styled-checkbox-35" className="label-text blu-color s-reg ft-sz-15 mb-0">Approve Reimbursement </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Invoices</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Invoice Creation</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">View only Invoice</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Approvals</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Create expense for approval</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">View Only expense for approval</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-33" type="checkbox" value="value33" />
                                          <label for="styled-checkbox-33" className="label-text blu-color s-reg ft-sz-15 mb-0">Approve Expense</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Cheques</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Upload Cheques</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">View Cheques</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card">
                                  <div class="card-header">
                                    <div className="check-box">
                                      <input class="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                                      <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0">Financial Institution (and Account statement)</label>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div className="row">
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-31" type="checkbox" value="value31" />
                                          <label for="styled-checkbox-31" className="label-text blu-color s-reg ft-sz-15 mb-0">Creation of Financial Institution</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-32" type="checkbox" value="value32" />
                                          <label for="styled-checkbox-32" className="label-text blu-color s-reg ft-sz-15 mb-0">Uploading & View Account statement</label>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xs-12">
                                        <div className="check-box">
                                          <input class="styled-checkbox" id="styled-checkbox-33" type="checkbox" value="value33" />
                                          <label for="styled-checkbox-33" className="label-text blu-color s-reg ft-sz-15 mb-0">View only Account statement</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End start users */}
                  </Tab>
                </Tabs>
                <div className="main-note-active-btn-sec clearfix">
                  <div className="note-active-sec pull-left">
                    <p className="ft-sz-18 s-reg blu-color mb-0">Note: Once you save new user an invite shall be sent to the user on email or mobile.</p>
                  </div>
                  <div className="active-in-btn-sec pull-right">
                    {/* <ButtonGroup aria-label="Basic example">
                      <Button variant="secondary active-in">Active</Button>
                      <Button variant="secondary active-in">Inactive</Button>
                    </ButtonGroup> */}
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                      <ToggleButton value={1}>Active</ToggleButton>
                      <ToggleButton value={2}>Inactive</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div class="profile-save-btn clearfix">
                  <button type="button" class="btn save-btn s-bold pull-left mr-3 graybtn">Delete</button>
                  <button type="button" class="btn save-btn s-bold pull-left graybtn d-none">Resend invite</button>
                  <button type="button" class="btn save-btn s-bold greenbtn pull-right">Save</button>
                </div>
              </div>

               <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i class="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>

            </div>
          </div>
        </div>
        <footer className="sticky-footer d-none">
          <div className="container">
            <div className="text-center">
              <small>Copyright © Buktec 2021</small>
            </div>
          </div>
        </footer>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default profileentity;