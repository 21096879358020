import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function EditIcon(props) {
    if(props.is_disabled){
        return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">This record is read only. Contact admin if you wish to edit.</Tooltip>}>
            <div className="edit-btn-sec"><span className="icon-edit gray-st-icon gray-icon"></span></div>
        </OverlayTrigger>
    }
    else{
        return <div className="edit-btn-sec"><span className="icon-edit"></span></div>
    }
    // return <div className="edit-btn-sec"><span className="icon-edit"></span></div>
}
export default EditIcon;