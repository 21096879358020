import axios from 'axios'
import React from 'react'
import { Prompt } from 'react-router'
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { TextareaAutosize } from '@material-ui/core';

import CommunicationBar from '../../Services/CommunicationBar/CommunicationBar'
import MasterService from '../../Services/MasterService'
import StorageData from '../../Helpers/StorageData';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { createLog, formatLogData, sendLog } from '../../Helpers/CreateLogs';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import Button from 'react-bootstrap/Button';

class NotesBox extends React.Component {
	constructor(props) {
		super(props)
		this.notesEndRef = React.createRef()
		this.state = {
			notes: [],
			id: 0,
			inputBoxValue: "",
			editNoteData: "",
			counter: 0,
			showForm: true,
			//updateNote: true,
			updateNote: false,
			provideInput: false,
			isLoading: true,
			inputRows: 2,
			old_data: {},
			logs_list: [],
			show_logs: false,
			is_dirty: false,
			is_error: false,
		}
		this.getNotesBody = {
			ref_id: this.props.context_id,
			mod_type: this.props.context_no,
		}
	}

	componentDidMount = async () => {
		this.props.onRef(this)
		await CommunicationBar.getNotesList(this.getNotesBody).then((data) => {
			this.setState({ ...this.state, notes: data, isLoading: false })
			// if(data.length > 0){
			// 	this.props.setAvailableNotesFlag(true)
			// }
		})
	}

	componentDidUpdate() {
		this.props.onRef(this)
	}

	getLogs = (submod_id) => {
		this.props.dispatch(setAppilcationLoder(true));
		let detail = {
			mod_id: this.props.context_id,
			module: this.props.context_no,
			submodule: 4,
			submod_id: submod_id
		}
		MasterService.getLogs(detail).then((data) => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({ logs_list: data.result.change_logs })
			} else {
				toast.error(data.message)
			}
			this.setState({ show_log: true })
		})
	}

	toggleChangeLog = () => {
		this.setState({ show_log: !this.state.show_log })
	}

	inputChangeHandler = (event) => {
		this.setState({ ...this.state, inputBoxValue: event.target.value, provideInput: false, counter: event.target.value.length })

		this.props.update_communication_bar_notes_dirty(true)

	}

	commentsSectionSubmitHandler = async (nextRoute) => {
		if (this.state.updateNote) {
			if (this.state.inputBoxValue === this.state.editNoteData) {
				toast.error("Same data can't be updated")
			}
			else if (!this.state.inputBoxValue) {
				toast.error("No value found to update")
				this.setState({ ...this.state, provideInput: true, is_error: true })
			}
			else {
				const updateData = {
					"id": this.state.id,
					"note": this.state.inputBoxValue,
					"ref_id": this.props.context_id,
					"mod_type": this.props.context_no
				}

				let updatedNotesList = [...this.state.notes]
				let newData = updatedNotesList.find(element => element.id == updateData.id)
				let removeIndex = updatedNotesList.findIndex(element => element.id == updateData.id)

				updatedNotesList.splice(removeIndex, 1)

				await CommunicationBar.updateNote(updateData).then((data) => {
					if (data.response_code === 200) {

						// send logs code
						let createdLog = createLog.map(this.state.old_data, updateData)
						formatLogData(createdLog, ["id", "ref_id", "mod_type"], "");
						sendLog({ module_type: this.props.context_no, module_id: this.props.context_id, submodule: 4, submod_id: updateData.id });

						// store old data for log
						this.setState({ old_data: updateData })

						newData = { ...newData, id: data.result.id, last_updated_at: data.result.last_updated_at, note: updateData.note }
						updatedNotesList.push(newData)

						this.setState({ ...this.state, notes: [...updatedNotesList], is_error: false, provideInput: false, editNoteData: updateData.note, counter: updateData.note.length, updateNote: false, inputBoxValue: "" })
						this.scrollToBottom()
						toast.success("Note updated successfully")

						this.props.update_communication_bar_notes_dirty(false)

						if (nextRoute == 'msgs' || nextRoute == 'private_msgs' || nextRoute == 'tasks') {
							this.props.onTabChange(nextRoute)
						} else if (nextRoute) {
							this.props.history.push(nextRoute)
						}
					}
				})
			}
		} else {
			if (!this.state.showForm && !this.state.updateNote) {
				this.setState({ showForm: true })
			}
			else if (!this.state.updateNote) {
				if (this.state.inputBoxValue) {
					const createData = {
						"note": this.state.inputBoxValue,
						"ref_id": this.props.context_id,
						"mod_type": this.props.context_no
					}

					await CommunicationBar.createNote(createData).then((data) => {
						if (data.response_code === 200) {
							// add task in db
							if (this.state.notes.length <= 1) {
								this.storeMessageInDB(this.state.notes.length);
							}


							// send log code
							sendLog({
								module_type: this.props.context_no, module_id: this.props.context_id, activity: ['Created New Notes'],
								submodule: 4, submod_id: data.result.id
							});
							// end log code

							const appendData = {
								"qualified_url": this.props.userDetail.qualified_url,
								"full_name": this.props.userDetail.full_name,
								"id": data.result.id,
								"last_updated_at": data.result.last_updated_at,
								"note": createData.note
							}
							let temp_notes = this.state.notes // ------------> to check if already notes are present
							this.setState({ ...this.state, id: 0, inputBoxValue: "", is_error: false, editNoteData: '', updateNote: false, notes: [...this.state.notes, appendData] 
							})
							this.scrollToBottom()
							toast.success("Note created successfully")

							this.props.setAvailableNotesFlag(true)

							this.props.update_communication_bar_notes_dirty(false)

							if (nextRoute == 'msgs' || nextRoute == 'private_msgs' || nextRoute == 'tasks') {
								this.props.onTabChange(nextRoute)
							} else if (nextRoute) {
								this.props.history.push(nextRoute)
							}
						}
					})
				}
				else {
					toast.error('Please provide a note')
					this.setState({ ...this.state, provideInput: true, is_error: true })
				}
			}
		}
	}

	deleteNoteHandler = async () => {
		const deleteData = {
			"id": this.state.id,
			"mod_type": this.props.context_no,
			"ref_id": this.props.context_id
		}

		if (!await confirm({ confirmation: "Are you sure to delete?" })) {
			return true;
		}
		await CommunicationBar.deleteNote(deleteData).then((data) => {
			if (data.response_code === 200) {
				const newNotesList = this.state.notes.filter((note) => {
					if (this.state.id != note.id) {
						return note
					}
				})

				if (newNotesList.length < 1) {
					this.props.setAvailableNotesFlag(false)
				}

				this.setState({ ...this.state, notes: [...newNotesList], updateNote: false, provideInput: false, id: 0, inputBoxValue: "", counter: 0 })
				toast.success("Note deleted successfully")
				console.log(data)
			}
		})
	}

	editInputBoxDataHandler = (event) => {
		const data = this.state.notes.filter((note) => {
			if (note.id == event.target.id) {
				return note
			}
		})
		this.setState({ ...this.state, showForm: true, inputBoxValue: data[0].note, editNoteData: data[0].note, updateNote: true, counter: data[0].note.length, id: data[0].id })
		let old_data = {
			"id": data[0].id,
			"note": data[0].note,
			"ref_id": this.props.context_id,
			"mod_type": this.props.context_no
		}
		this.setState({ old_data });
	}

	cancelUpdateHandler = () => {
		this.setState({ ...this.state, updateNote: false, inputBoxValue: "", editNoteData: "", provideInput: false, counter: 0 })
	}

	formCloseHandler = () => {
		this.setState({ showForm: false, inputBoxValue: '', counter: 0, updateNote: false, is_error: false })
		this.props.update_communication_bar_notes_dirty(false)
	}

	storeMessageInDB = (notes_list_length) => {
		let data = {
			"ref_id": this.props.context_id,
			"mod_type": this.props.context_no,
			"general_msg_status": null,
			"notes_status": notes_list_length == 0 ? 1 : 0,
			"private_msg_status": null,
			"tasks_status": null
		}
		CommunicationBar.storeMessageInDB(data).then(
			(data) => {

			});
	}

	scrollToBottom = () => {
		this.notesEndRef.current.scrollIntoView({ behavior: "smooth" })
	}

	render() {

		return (
			<>
				<ChangeLog
					show_log={this.state.show_log}
					toggleChangeLog={this.toggleChangeLog}
					logs={this.state.logs_list}
				/>

				<div className="comment-cmb-box">
					{this.state.counter >= 1000 ? toast.error("Characer limit reached") : null}
					{/* <Prompt when={this.state.inputBoxValue ? true : false} message="Are u sure u want to leave?" /> */}
					{this.state.isLoading ? <Spinner animation="border" variant="secondary" className="cmb-loader" /> : ""}

					<h3 className="cmb-heading"><span className="icon-Group-2546 mr-2"></span>Notes</h3>

					<div className="msg-sec-cmb">
						{this.state.notes.map((noteData) => {
							return (
								<div key={noteData.id} className="messages-cmb notes-box">
									<OverlayTrigger overlay={<Tooltip>{noteData.full_name}</Tooltip>}>
										<div className="pnd-cmb">
											<div>
												{/* <span className="profile-cmb-letter">P</span>
												<img className="profile-cmb-img" src={testimg} /> */}

												{(noteData.qualified_url && !(noteData.qualified_url == 'https://devapi.buktec.co.in/api/v1/request-signed-url-get?file=')) ?
													<img className="profile-cmb-img" src={StorageData.getQualifiedFileUrl(noteData.qualified_url)} />
													:
													<span className="profile-cmb-letter">{noteData.full_name.charAt(0)}</span>
												}
											</div>
											<div className="nd-cmb">
												<h5 className="profile-cmb-name ellipsisc ellipsisc-line-1">{noteData.full_name}</h5>
												<span className="date-cmd">{noteData.last_updated_at}</span>
											</div>
										</div>
									</OverlayTrigger>
									<div className="notes-msg-box">
										<p className="msgtext-cmd">{noteData.note}</p>
										<div className="notesc-icons">
											<OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
												<span id={noteData.id} onClick={this.editInputBoxDataHandler} class="icon-edit cpointer mb-2"></span>
											</OverlayTrigger>

											<OverlayTrigger overlay={<Tooltip>Change Log</Tooltip>}>
												<span onClick={() => this.getLogs(noteData.id)} class="icon-round-info-button cpointer"></span>
											</OverlayTrigger>
										</div>
									</div>
								</div>
							)
						})}
						<div ref={this.notesEndRef} />
					</div>
				</div>
				{/* End Notes section */}

				<form>
					<div className="bottom-cmb">
						<div className={!this.state.showForm ? "d-none" : ""}>
							<lable className="lable-add-note">Add Note<span className="red-star">*</span></lable>

							<div className="bottom-inner-cmb">
								{/* Note */}
								<div className="user-comment-cmb task-notes-comment-cmb">
									<div className="form-group mb-0 w-100">
										{/* <textarea className="form-control" rows="2" name="task" placeholder=" Enter notes details"></textarea> */}
										<TextareaAutosize
											// style={{ fontSize: "1em" }}
											minRows={this.state.inputRows}
											value={this.state.inputBoxValue}
											onChange={this.inputChangeHandler}
											maxLength="1000"
											disabled={this.props.view_only}
											// className={this.state.provideInput ? "form-control s-sbold ft-sz-22 font-colr wrong-red notes-border h-auto"
											// 	: "form-control s-sbold ft-sz-22 font-colr notes-border"
											className={this.state.provideInput ? "form-control task-placeholder " : "form-control task-placeholder"}
											placeholder="Enter notes details" aria-label="Recipient's username" aria-describedby="basic-addon2"
										/>
									</div>
								</div>

								<div className="icon-cmb">
									<a role="button" onClick={() => this.props.onTabChange('msgs')}>
										{this.state.public_messages ? <span className="active-dot"></span> : ""}
										<OverlayTrigger overlay={<Tooltip>Messages</Tooltip>}>
											<span className="icon-chat"></span>
										</OverlayTrigger>
									</a>
									{
										!this.props.hide_task_manager &&
										<a role="button" onClick={() => this.props.onTabChange('tasks')}>
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tasks</Tooltip>}>
												<span className="icon-Group-2516"></span>
											</OverlayTrigger>
										</a>
									}
									<a role="button" className="active" onClick={() => this.props.onTabChange('notes')}>
										<OverlayTrigger overlay={<Tooltip>Notes</Tooltip>}>
											<span className="icon-Group-2546"></span>
										</OverlayTrigger>
									</a>
									<a role="button" onClick={() => this.props.onTabChange('private_msgs')}>
										{this.state.private_messages ? <span className="active-dot"></span> : ""}
										<OverlayTrigger overlay={<Tooltip>Private Messages</Tooltip>}>
											<span className="icon-chat"></span>
										</OverlayTrigger>
									</a>
								</div>
							</div>
						</div>
						{/* <div className="right-side-btn"> */}
						<div className="d-lg-flex justify-content-between gap-15">
							<div>
								<div className={"character-limit " + (!this.state.showForm ? "d-none" : "character-limit")}>
									Character limit: <span className="s-sbold">{this.state.counter}/1000</span>
									<span className={this.state.counter >= 1000 ? "s-sbold red-text" : "d-none"}> | Character Limit Reached</span>
									{(this.state.is_error && !this.state.inputBoxValue) ? <small className="s-sbold red-text d-block">Note is required</small> : ""}
								</div>
							</div>

							{/* <Button type="button" variant="success apply_btn_new" >Add Note</Button> */}
							{/* {
								this.state.updateNote ? (<div className="pull-right">
									<button type="button" onClick={this.deleteNoteHandler} className="btn comment-btn s-bold  graybtn mr-1">Delete</button>
									<button type="button" onClick={this.commentsSectionSubmitHandler} className={!this.state.inputBoxValue ? "btn comment-btn s-bold greenbtn pull-right disabled" : "btn comment-btn s-bold greenbtn pull-right"}>Update Note</button>
								</div>) :
									<button type="button" disabled={this.props.view_only} onClick={this.commentsSectionSubmitHandler} className={!this.state.showForm ? "btn comment-btn s-bold greenbtn pull-right disabled" : "btn comment-btn s-bold greenbtn pull-right "}>Add Note</button>
							} */}

							{
								this.state.updateNote ?
									<div className="text-right">
										<Button type="button" variant="btn-outline-success reset_btn_new mr-3" onClick={this.deleteNoteHandler}>Delete</Button>
										<Button type="button"variant={"success apply_btn_new " + (!this.state.inputBoxValue ? "disabled" : "success apply_btn_new ")}
											onClick={this.commentsSectionSubmitHandler}>Update Note</Button>
									</div> :
									<Button type="button"// variant="success apply_btn_new" 
										variant={"success apply_btn_new" + (!this.state.showForm ? " disabled" : "")}
										disabled={this.props.view_only} onClick={this.commentsSectionSubmitHandler}>Add Note</Button>
							}
						</div>
					</div>
				</form>
			</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const entity_list = all_value.entity_list || [];
	const account_permissions = all_value.account_permissions || [];
	const module_permissions = all_value.module_permissions || [];
	const user_detail = all_value.user_detail || {}
	return {
		entity_list, account_permissions, module_permissions, user_detail
	};
}
export default connect(mapStateToProps)(withRouter(NotesBox));
