import React, { Component } from 'react';
import { connect } from "react-redux";
import { setInvoiceFilterData } from "../../Redux/Actions/Invoices";
// import { withRouter } from 'react-router';
import { withRouter, Link } from 'react-router-dom'
import AddNew from '../Icon/AddNew';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import { Button, Form, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

class CreateInvoiceSideList extends Component {


  constructor(props) {
    super(props);
    this.state = {
      show_side_fltr: false,
      invoice_id: props.match.params.invoice_id ? props.match.params.invoice_id : 0,
    }
  }

  toggleSideFilter = () => {
    this.setState({
      show_side_fltr: !this.state.show_side_fltr
    });
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    let filter_data = {
      [name]: value
    }
    this.props.dispatch(setInvoiceFilterData(filter_data));
  }

  applyFilter = () => {
    this.props.applyFilter("apply");
  }

  resetFilter = () => {
    let filter_data = {
      invoice_type: 0,
      invoice_number: "",
      customer_name: "",
      from_amount: "",
      to_amount: "",
      payment_status: "",
      created_by: "",
      is_star: "",
    }
    this.props.dispatch(setInvoiceFilterData(filter_data));
    this.props.applyFilter("reset");
  }


  componentWillReceiveProps(nextProps) {
    if ((nextProps.match.params.invoice_id !== this.props.match.params.invoice_id)) {
      this.setState({
        invoice_id: nextProps.match.params.invoice_id ? nextProps.match.params.invoice_id : 0
      })
    }
  }


  invoiceSelected = (invoice) => {
    this.props.history.push("/invoices/create/" + invoice.id)
  }

  newInvoice = () => {
    this.props.history.push("/invoices/create")
  }

  render() {
    const { invoice_list } = this.props;

    return (

      <div className="sidebar-sec-fixed">

          <div className="clearfix sidebar-header side-breadcrumbs"> 
						<div className="row">
							<div className="col-7 d-flex align-items-center pr-0">
								<BreadCrum level={2}/> 
							</div>
							<div className="col-5 text-right d-flex align-items-center justify-content-end">
								<div className="sidebar-evidence-filter" onClick={() => this.toggleSideFilter()}>
									<span className="icon-filter"></span>
								</div>
								<AddNew addNew={this.newInvoice} />
							</div>
						</div>
					</div>

          {/* <div className="clearfix">
            <ol className="breadcrumb pull-left">
              <li className="breadcrumb-item">
              <Link to={'/home'}>Home</Link>
                  </li>
              <li className="breadcrumb-item">Invoices</li>
            </ol>
            <div className="pull-right d-flex new-add-filter-sec">
              <div className="sidebar-evidence-filter" onClick={this.toggleSideFilter}>
                <span className="icon-filter"></span>
              </div>
              <AddNew addnew = {this.newInvoice} />
            </div>
          </div> 
          <hr />*/}

        <div className="recipt-sidebar-sec">
          {
            this.state.show_side_fltr ? <div className="main-evidence-sidefilter">
              
              <div className="row">
                <div className="col-lg-12 col-xs-12 side-search d-flex">
                  {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">
                    Narration
                      </Tooltip>}> */}
                    <form className="form-group search-sec bottom-seprator w-100">
                      <input type="text"
                      onKeyPress={this.checkForEnterButton}
                      className="form-control"
                      placeholder="Search"
                      value={this.props.searchText}
                      onChange={(e) => this.handleOnChange(e)}
                      name="search" />
                      {this.props.isSearchApplied === false ?
                      <button onClick={this.searchFilter}>
                        <span className="icon-loupe"></span>
                      </button>
                      :
                      <button onClick={this.resetSearchFilter}>
                        <span className="icon-cancel ft-sz-14"></span>
                      </button>
                      }
                    </form>
                  {/* </OverlayTrigger> */}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <select className="form-control"
                      onChange={this.handleOnChange}
                      value={this.props.invoice_type}
                      name="invoice_type" >
                      <option value="0">Type</option>
                      <option value="1">Invoice</option>
                      <option value="2">Proforma</option>
                    </select>
                  </div>
                  {/* <div className="form-group">
                    <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control as="select">
                        <option>Custom Date Range</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        </Form.Control>
                    </Form.Group>
                    </Form>
                    </div> */}
                </div>
              </div>
              {/* <div className="row no-gutters">    
                  <div className="col-lg-5 col-sm-12">
                      <div className="form-group">
                      <div className="start-end-date-sec btn">
                          <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                      </div>
                      </div>  
                  </div>
                  <div className="col">
                    <hr/>
                  </div>
                  <div className="col-lg-5 col-sm-12">
                      <div className="form-group">
                      <div className="start-end-date-sec btn">
                          <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                      </div>
                      </div>
                  </div>
                </div>   */}
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <select className="form-control"
                      onChange={this.handleOnChange}
                      value={this.props.payment_status}
                      name="payment_status" >
                      <option value="0">Payment Status</option>
                      <option value="1">Paid</option>
                      <option value="2">Unpaid</option>
                      <option value="3">Part Payment</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <input type="text"
                      className="form-control"
                      placeholder="From Amount"
                      value={this.props.from_amount}
                      onChange={this.handleOnChange}
                      name="from_amount" />
                  </div>
                </div>
                <div className="col">
                  <hr />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <input type="text"
                      className="form-control"
                      placeholder="to Amount"
                      value={this.props.to_amount}
                      onChange={this.handleOnChange}
                      name="to_amount" />
                  </div>
                </div>
              </div> */}

              <div className="row no-gutters">
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <CurrencyInput type="text"
                      className="form-control text-right"
                      placeholder="₹ 0"
                      decimalsLimit={2}
                      prefix="&#8377; "
                      // value={this.props.from_amount}
                      allowNegativeValue={false}
                      // onValueChange={(value, name) => this.amountChange(name, value)}
                      name="from_amount" />
                  </div>
                </div>
                <div className="col">
                  <hr />
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="form-group">
                    <CurrencyInput type="text"
                      className="form-control text-right"
                      placeholder="₹ 0"
                      decimalsLimit={2}
                      prefix="&#8377; "
                      // value={this.props.to_amount}
                      allowNegativeValue={false}
                      // onValueChange={(value, name) => this.amountChange(name, value)}
                      name="to_amount" />
                  </div>
                </div>
              </div>

              {/* <div className="row no-gutters"> */}
              <div className="row">
                <div className="col-lg-6 col-sm-12 pr-1">
                  <div className="form-group">
                    <input type="text"
                      className="form-control"
                      placeholder="Invoice Number"
                      value={this.props.invoice_number}
                      onChange={this.handleOnChange}
                      name="invoice_number" />
                  </div>
                </div>
                {/* <div className="col">
                </div> */}
                <div className="col-lg-6 col-sm-12 pl-1">
                  <div className="form-group">
                    <select className="form-control"
                      onChange={this.handleOnChange}
                      value={this.props.created_by}
                      name="created_by" >
                      <option value="">Created By</option>
                      {
                        this.props.creaters.map((created_by, index) => {
                          <option key={index} value={created_by.id}>{created_by.full_name}</option>
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input type="text"
                      className="form-control"
                      placeholder="Customer Name"
                      value={this.props.customer_name}
                      onChange={this.handleOnChange}
                      name="customer_name" />
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <button type="button" onClick={this.resetFilter} className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                </div>
                <div className="col-lg-6 col-xs-12">
                  <button type="button" onClick={this.applyFilter} className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                </div>
              </div> */}

              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <button type="button" className="btn apply-btn s-bold graybtn" onClick={this.resetFilter}>Reset</button>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <button type="button" className="btn apply-btn s-bold greenbtn" onClick={this.applyFilter}>Apply</button>
                </div>
                <div><p className="mb-2 ft-sz-12 font-colr text-center text-danger">{this.state.error}</p></div>
              </div>

            </div> : null
          }

          <div className="main-applied-sidefilter">
            <div class="row">
              <span class="ft-sz-16 s-sbold blu-color">Invoice</span>
              <span class="ft-sz-16 s-sbold blu-color">Last Financial Year</span>
              <span class="ft-sz-16 s-sbold blu-color">₹300 - ₹500</span>
              <span class="ft-sz-16 s-sbold blu-color">Paid</span>
              <span class="ft-sz-16 s-sbold blu-color">Ramesh08</span>
              <span class="ft-sz-16 s-sbold blu-color">#12345</span>
            </div>
          </div>


          {this.state.invoice_id == 0 &&
            <div className="recipt-inner-div invoice-side-list empty-div active">
              <div className=""></div>
              <div className="row">
                <p className="m-0 ft-sz-28 font-colr s-sbold">New Invoice</p>
              </div>
            </div>
          }
          
          {
            invoice_list.map((invoice, index) => {

              // return <div key={index} className={"recipt-inner-div " + (this.state.invoice_id == invoice.id ? " active" : "")}>
              //   <div className="clearfix" onClick={() => this.invoiceSelected(invoice)} >
              //     <div className="pull-left">
              //       <div className="recipt-id s-sbold font-colr">
              //         ID #{invoice.id}
              //       </div>
              //       <p className="m-0 head s-sbold font-colr">{invoice.name}</p>
              //       {/* <p className="recipt-text s-reg font-colr m-0">Recipt</p> */}
              //       <div className="date s-sbold font-colr">
              //         {invoice.date}
              //       </div>
              //     </div>
              //     <div className="pull-right">
              //       <div className="evi-icon">
              //         <div className="color-icon">
              //           <span className="icon-Group-2509"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
              //         </div>
              //       </div>
              //       <div className="amount s-sbold blu-color">
              //         <span>  &#8377;{invoice.total_after_tds}</span>
              //       </div>
              //     </div>
              //   </div>
              // </div>

              return <div key={index}
									className={"recipt-inner-div " + (this.state.invoice_id == invoice.id ? " active " : "")}
									onClick={() => this.evidenceSelected(invoice)}>

									<div className="active"></div>

									<div className="clearfix side-card">
										<div className="d-flex align-items-center flex-row">
											<div className="tans-id font-gray s-reg">
                        ID #{invoice.id}
											</div>
											<div className="ml-auto d-flex align-items-center">												
												<div className="color-icon">
													{this.dataFormatStatus(invoice.status)}
												</div>
											</div>
										</div>

										<div className="side-card-height d-flex justify-content-end flex-column">
											<div className="tans-name font-gray s-sbold ellips-auto">
												{invoice.name}
											</div>
											<div className="d-flex flex-row align-items-baseline">
												<div className="mr-auto dates font-gray s-reg">
													{displayDateFormate(invoice.date)}
												</div>
												<div className="d-flex align-items-center justify-content-end s-sbold blu-color text-right amnts">
													<span className="ellips mr-1">
														<OverlayTrigger overlay={<Tooltip id="tooltip-disabled"></Tooltip>}>
															<span>{!!showAmount(invoice.total_after_tds) ? showAmount(invoice.total_after_tds) : ""}</span>
														</OverlayTrigger>
													</span>
													{<ArrowUpDownIcon expense={invoice.expense} />}
												</div>
											</div>
										</div>

									</div>
								</div>

            })
          }

          {/* <div className="recipt-inner-div">

            <div className="active"></div>

            <div className="clearfix side-card">
              <div className="d-flex align-items-center flex-row">
                <div className="tans-id font-gray s-reg">
                  ID #13245
                </div>
                <div className="ml-auto d-flex align-items-center">												
                  <div className="color-icon">
                    @
                  </div>
                </div>
              </div>

              <div className="side-card-height d-flex justify-content-end flex-column">
                <div className="tans-name font-gray s-sbold ellips-auto">
                  Deepka
                </div>
                <div className="d-flex flex-row align-items-baseline">
                  <div className="mr-auto dates font-gray s-reg">
                  1-3-2021
                  </div>
                  <div className="d-flex align-items-center justify-content-end s-sbold blu-color text-right amnts">
                    <span className="ellips mr-1">
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"></Tooltip>}>
                        <span>1000</span>
                      </OverlayTrigger>
                    </span>
                    
                  </div>
                </div>
              </div>

            </div>
          </div> */}


          {/*
            <div className="recipt-inner-div">
                <div className="row">
                <div className="col-xl-7 col-sm-12">
                    <div className="recipt-id s-sbold font-colr">
                    ID #2154
                    </div>
                    <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                    <div className="date s-sbold font-colr">
                    11-12-2020 
                    </div>
                </div>
                <div className="col-xl-5 col-sm-12">
                    <div className="evi-icon">
                    <div className="color-icon">
                    <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    </div>
                    </div>
                    <div className="amount s-sbold blu-color">
                    <span>  &#8377;2000</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="recipt-inner-div">
                <div className="row">
                <div className="col-xl-7 col-sm-12">
                    <div className="recipt-id s-sbold font-colr">
                    ID #2154
                    </div>
                    <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                    <div className="date s-sbold font-colr">
                    11-12-2020 
                    </div>
                </div>
                <div className="col-xl-5 col-sm-12">
                    <div className="evi-icon">
                    <div className="color-icon">
                    <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                    </div>
                    
                    </div>
                    <div className="amount s-sbold blu-color">
                    <span>  &#8377;2000</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="recipt-inner-div">
                <div className="row">
                <div className="col-xl-7 col-sm-12">
                    <div className="recipt-id s-sbold font-colr">
                    ID #2154
                    </div>
                    <p className="m-0 head s-sbold font-colr">Airtel Brodband</p>
                    <div className="date s-sbold font-colr">
                    11-12-2020 
                    </div>
                </div>
                <div className="col-xl-5 col-sm-12">
                    <div className="evi-icon">
                    <div className="color-icon">
                    <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                    </div>
                    
                    </div>
                    <div className="amount s-sbold blu-color">
                    <span>  &#8377;2000</span>
                    </div>
                </div>
                </div>
            </div> */}          

        </div>

        <div className="main-pagination clearfix sidebar-pagination ">
						<nav aria-label="Page navigation example">
							<ul className="pagination pull-right mb-0">
								<li className="page-item prev-sec" disabled={this.props.has_previous} onClick={() => this.props.has_previous ? this.getSnapshotList(this.props.page_no - 1) : ""}><a className="page-link ft-sz-18 s-reg"><span className="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
								{
									this.props.has_previous && <li className="page-item"><a className="page-link ft-sz-18 s-reg">...</a></li>
								}
								<li className="page-item active"><a className="page-link ft-sz-18 s-reg">{this.props.page_no}</a></li>
								{
									this.props.has_next && <li className="page-item"><a className="page-link ft-sz-18 s-reg">...</a></li>
								}
								<li className="page-item next-sec" disabled={this.props.has_next} onClick={() => this.props.has_next ? this.getSnapshotList(this.props.page_no + 1) : ""}><a className="page-link ft-sz-18 s-reg">Next <span className="icon-arrow-down-sign-to-navigate"></span></a></li>
							</ul>
						</nav>
					</div>

      </div>
    );
  }

}

function mapStateToProps(state) {
  const all_value = state.Invoices || {};
  const invoice_type = all_value.invoice_type || 0;
  const invoice_number = all_value.invoice_number || '';
  const customer_name = all_value.customer_name || '';
  const from_amount = all_value.from_amount || '';
  const to_amount = all_value.to_amount || '';
  const payment_status = all_value.payment_status || '';
  const created_by = all_value.created_by || '';
  const is_star = all_value.is_star || '';

  return {
    is_star, invoice_type, invoice_number, customer_name, from_amount, to_amount,
    payment_status, created_by
  };
}

export default connect(mapStateToProps)(withRouter(CreateInvoiceSideList));