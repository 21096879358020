import React from 'react'
import { Accordion, Card, Form, Table, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import VoucherCardView from '../../Components/BoilerPlate/VoucherCardView';
import { changeDateFormate, DatePickerFormate, displayDateTimeFormat, changeDateTimeFormat } from '../../Helpers/DateFormat';
import { setTempVoucherList } from '../../Redux/Actions/MoneyLink';
//import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import { LINKING_CODE, LEDGER_ENTRIES_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { confirm } from '../../Components/BoilerPlate/Confirm';
import Spinner from 'react-bootstrap/Spinner';
import loaderw from '../../Assets/img/buktec-preloader.gif';

class LedgerTab extends React.Component {
  constructor(props) {
    super(props);

    this.PERMISSION_LIST = this.props.features_permissions_list;
    this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
    this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
    this.VIEW_LINKED_ENTRIES = this.PERMISSION_LIST.includes(VIEW_LINKED_ENTRIES_CODE);

    this.state = {
      voucher_type_guid:'',
      voucher_creation_date: "",
      is_permission_granted: this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE),
      ledger_tab: this.LEDGER_ENTRIES ? true : false
    }
  }

  componentDidUpdate = () => {
    if (this.props.transactionDate != undefined && this.state.voucher_creation_date == null) {
      setTimeout(() => {
        this.setState({ voucher_creation_date: new Date(DatePickerFormate(this.props.transactionDate)) })
      }, 600);
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.transaction_id !== nextProps.transaction_id)) {
      this.setState({ ledger_tab: this.LEDGER_ENTRIES ? true : false ,voucher_type_guid:''});
    }

    if (this.props.transactionDate != nextProps.voucher_creation_date) {
      if (this.props.transactionDate != undefined) {
        this.setState({ ledger_tab: this.LEDGER_ENTRIES ? true : false });
        setTimeout(() => {
          this.setState({ voucher_creation_date: new Date(DatePickerFormate(this.props.transactionDate)) }, () => {
          })
        }, 600);
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setTempVoucherList([]));
    this.setState({
      voucher_type_guid: '',
      voucher_creation_date: ""
    })
  }

  /* 
  owner : Nehal 07-07-2022
  comment : This function is use to set date if not set in datepicker while creating voucher 
  */
  setDateIfnotSet = () => {
    if (this.props.transactionDate != undefined && !!!this.state.voucher_creation_date) {
      setTimeout(() => { }, 600);
      this.setState({ voucher_creation_date: new Date(DatePickerFormate(this.props.transactionDate)) }, () => {

      });
    }
  }

  handleChange = async (e) => {
    this.setState({ voucher_type_guid: e.target.value })
    this.setDateIfnotSet();

    this.props.isFormDirty();

    if (this.props.old_account_status == 3) {

      let expense_no = ["3", "5"];
      let expense_yes = ["2", "6"];

      let voucher_types = [];
      voucher_types.push(e.target.value);

      this.props.temp_voucher_list.forEach(async (voucher) => {
        voucher_types.push(voucher.voucher_type_guid);
        if (voucher.voucher_type_guid == e.target.value) {
          await confirm({
            confirmation: "Duplicate voucher type.",
            options: { type: "alert" }
          })
        }
      })

      let expense_yes_present = false;
      let expense_no_present = false;

      voucher_types.forEach(async (type) => {
        const voucher_details  = this.props.voucherTypeList.filter(voucher => voucher.guid == type)
        const parent_voucher_type_id = voucher_details[0].parent_voucher_type_id
        if (expense_no.includes(parent_voucher_type_id.toString())) {
          expense_no_present = true;
        }

        if (expense_yes.includes(parent_voucher_type_id.toString())) {
          expense_yes_present = true;
        }
      })
      //console.log(voucher_types, expense_yes_present, expense_no_present)

      //console.log(this.props.transaction_details)
      const voucher_details  = this.props.voucherTypeList.filter(voucher => voucher.guid == e.target.value)
      console.log(expense_yes_present,expense_no_present)

      if (voucher_details[0].parent_voucher_type_id != 1 && voucher_details[0].parent_voucher_type_id != 4) {
        if (expense_yes_present && expense_no_present || (this.props.transaction_details.type == 1 && expense_yes.includes(voucher_details[0].parent_voucher_type_id.toString())) || (this.props.transaction_details.type == 2 && expense_no.includes(voucher_details[0].parent_voucher_type_id.toString()))) {
          await confirm({
            confirmation: "Conflicting voucher types found.",
            options: { type: "alert" }
          })
          return
        }
      }
    }
  }

  setDate = (date, key) => {
    this.setState({ [key]: date })
    this.props.isFormDirty()
  }

  editVoucherDate = (date, voucher_index) => {
    let temp_voucher_list = this.props.temp_voucher_list;
    temp_voucher_list[voucher_index].voucher_creation_date = changeDateFormate(date)

    this.props.dispatch(setTempVoucherList(temp_voucher_list))
    this.props.isFormDirty()
  }

  editVouchertype = (voucher_details, voucher_index,type=null) => {
    console.log("edit voucher ",voucher_details,voucher_index)
    let temp_voucher_list = this.props.temp_voucher_list;
    console.log("edit",temp_voucher_list.length)
    if(temp_voucher_list.length > 0){
        temp_voucher_list[voucher_index].voucher_type_guid = voucher_details.guid
        temp_voucher_list[voucher_index].parent_voucher_type_id = voucher_details.parent_voucher_type_id
        temp_voucher_list[voucher_index].voucher_type_name = voucher_details.name
        temp_voucher_list[voucher_index].voucher_type_id = voucher_details.id

        this.props.dispatch(setTempVoucherList(temp_voucher_list))
        if(type != 'first_time'){
          this.props.isFormDirty()
        }
    }
    // temp_voucher_list[voucher_index].voucher_type_guid = voucher_details.guid
    //   temp_voucher_list[voucher_index].parent_voucher_type_id = voucher_details.parent_voucher_type_id
    //   temp_voucher_list[voucher_index].voucher_type_name = voucher_details.name
    //   temp_voucher_list[voucher_index].voucher_type_id = voucher_details.id

    //   this.props.dispatch(setTempVoucherList(temp_voucher_list))
    //   if(type != 'first_time'){
    //     this.props.isFormDirty()
    //   }
  }

  validateVoucherType = () => {
    if (this.state.voucher_type_guid !== "" && !!this.state.voucher_creation_date && this.state.voucher_creation_date != 'Invalid Date') {
      return true;
    } else {
      toast.warning('Please select voucher type & date')
      return false;
    }
  }

  removeVoucher = (voucher_id, index) => {
    let temp_voucher_list = [...this.props.temp_voucher_list];
    if (voucher_id !== 0) {
      let temp_index = temp_voucher_list.findIndex(voucher => voucher.voucher_id == voucher_id);
      if (temp_index !== -1) {
        temp_voucher_list.splice(temp_index, 1);
      } else {
      }
    } else {
      temp_voucher_list.splice(index, 1);
    }
    this.props.isFormDirty()
    this.props.dispatch(setTempVoucherList(temp_voucher_list))
    if (temp_voucher_list.length == 0) {
      this.props.checkAccountedStatus(false);
    }
  }

  addVoucher = () => {

    if (this.validateVoucherType()) {
      let temp_voucher_list = [...this.props.temp_voucher_list];
      let voucher_details = this.props.voucherTypeList.filter((voucher) => voucher.guid == this.state.voucher_type_guid)
      let temp_voucher = {
        "accounting_narration": !!this.props.transaction_narration ? this.props.transaction_narration : "",
        "parent_voucher_type_id":voucher_details[0].parent_voucher_type_id,
        "voucher_type_guid":voucher_details[0].guid,
        "voucher_type_name":voucher_details[0].name,
        "voucher_type_id":voucher_details[0].id,
        "voucher_creation_date": changeDateFormate(this.state.voucher_creation_date),
        "total_credit_amount": "",
        "total_debit_amount": "",
        "voucher_id": 0,
        "ledgers": [],
        // ==============
        "sync_datetime": changeDateTimeFormat(new Date()),
        "sync_status": 0
        // ==============
      }
      temp_voucher_list.push(temp_voucher)
      this.props.dispatch(setTempVoucherList(temp_voucher_list))
      this.props.isFormDirty()
      this.setState({ voucher_type_guid: '', voucher_creation_date: '' })
      this.scrollToBottom();
      this.props.checkAccountedStatus(true);
    }
  }
  updateVoucher = (temp_ledger_list, voucher_id, voucher_index, type) => {
    //console.log(temp_ledger_list)
    let temp_voucher_list = this.props.temp_voucher_list;
    if (!!temp_voucher_list) {
      if (voucher_id !== 0) {
        let index = temp_voucher_list.findIndex((voucher) => voucher.voucher_id == voucher_id)
        if (index == -1)
          return true;
        temp_voucher_list[index].ledgers = temp_ledger_list
      } else {
        temp_voucher_list[voucher_index].ledgers = temp_ledger_list
      }

      this.props.dispatch(setTempVoucherList(temp_voucher_list))
      this.setState({ voucher_type_guid: '' })

      if (type != "first_time") this.props.isFormDirty()
    }
  }

  updateTotal = (cr_total, dr_total, voucher_id, voucher_index, type = null) => {
    let temp_voucher_list = this.props.temp_voucher_list;
    if (voucher_id !== 0) {
      let index = temp_voucher_list.findIndex((voucher) => voucher.voucher_id == voucher_id)
      if (index == -1)
        return true;
      temp_voucher_list[index].total_debit_amount = dr_total
      temp_voucher_list[index].total_credit_amount = cr_total
    } else {
      temp_voucher_list[voucher_index].total_debit_amount = dr_total
      temp_voucher_list[voucher_index].total_credit_amount = cr_total
    }
    this.props.dispatch(setTempVoucherList(temp_voucher_list))
    this.setState({ voucher_type_guid: '' })
    if (type != "first_time") this.props.isFormDirty()
  }

  updateAccountingNarration = (narration, voucher_id, voucher_index) => {
    let temp_voucher_list = this.props.temp_voucher_list;
    if (voucher_id !== 0) {
      let index = temp_voucher_list.findIndex((voucher) => voucher.voucher_id == voucher_id)
      if (index == -1)
        return true;
      temp_voucher_list[index].accounting_narration = narration
    } else {
      temp_voucher_list[voucher_index].accounting_narration = narration
    }
    this.props.dispatch(setTempVoucherList(temp_voucher_list))
    this.setState({ voucher_type_guid: '' })
    this.props.isFormDirty()
  }

  getLedgerList = (ledgers) => {
    return ledgers.map((ledger, index) => {
      delete ledger.ledger_name
      return ledger
    })
  }

  position = (obj) => {
    let currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop - 200];
    }
  }

  scrollToBottom = () => {
    // fix for BU1-I248
    setTimeout(() => {
      window.scrollTo({ top: this.position(document.getElementById(`vouchercardref_${(this.props.temp_voucher_list.length - 1)}`)), behavior: 'smooth' })
    }, 500);
  }

  toggleTab = async () => {

    if (this.state.ledger_tab && this.props.temp_voucher_list.length) {
      if (await confirm({
        confirmation: 'Do you want to remove ledger data?'
      })) {
        this.props.checkAccountedStatus(false);
        this.props.dispatch(setTempVoucherList([]))
      } else {
        return true;
      }
    }

    if (!this.state.ledger_tab) {
      setTimeout(async () => {

        if (!this.props.temp_voucher_list.length) {
          if (this.props.ledger_recommendation_param == 1) {
            this.props.getMoneyLinkLedgerRecommendation();
          } else if (this.props.ledger_recommendation_param == 2) {

            if (await confirm({
              confirmation: 'Would you like ledger recommendation?'
            })) {
              this.props.getMoneyLinkLedgerRecommendation();
            }
          }

        }

      }, 1000)
    }

    this.setState({ ledger_tab: !this.state.ledger_tab,voucher_type_guid:'' })
  }

  getSyncStatus = (status) => {
    if (status == 0) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Not Synced</span></Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1 gray-icon"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    } else if (status == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Send to Sync</span></Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1 blue-icon"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    } else if (status == 2) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Synced</span></Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    } else if (status == 3) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Sync Error</span><br />Error: {this.props.ledgerNameList.sync_error}</Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1 red-icon"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    }
  }

  render() {
    const is_permission_granted = this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE);
    const read_only = this.props.read_only;
    const voucher_creation_date = this.state.voucher_creation_date
    //console.log(this.props.ledgerNameList)
    console.log(this.props.old_account_status)
    this.props.temp_voucher_list.forEach((voucher, indx) => {
      this[`${indx}__vouchercardref`] = React.createRef()
    });

    return (
      is_permission_granted &&
      <div className="ledger_moneyin position-relative">
        {/* Loader */}
        {this.props.ledger_recommendation_loading && <><div className='sec-loder-overlay'></div>
          <img className="sec-link-loder" src={loaderw} /></>}
        {/* End Loader */}
        <div className="row">
          <div className="col-sm-12">
            <div className="form-main-title-new">
              <h4 className="form-title-heading-new">Ledger</h4>
              <div className="toggle-switch-btn toggle-switch-center">
                <span className="toggle-switch-center">
                  <span className="toggle-btn-sec-text">No</span>
                  <label className="switch">
                    <input type="checkbox"
                      checked={this.state.ledger_tab}
                      onChange={this.toggleTab}
                      //disabled={this.VIEW_ONLY_SNAPSHOT && !this.SNAPSHOT_LEDGER_ENTRIES_CODE}
                      name="voucher_status"
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="toggle-btn-sec-text">Yes</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        {this.state.ledger_tab &&
          <>
            {this.props.ledgerNameList.last_updated_at === null ?
              <p className="ledger-bar-import-text">Please import ledger to use this section</p>
              :
              <p className="ledger-icon-bar">
                <span className="ledger-left-bar">
                  {/* <OverlayTrigger overlay={<Tooltip>Refresh Ledgers</Tooltip>}>
                    <button class="btn refresh-icon-new-round blink-green-btn mr-2" onClick={() => this.props.getLedgerMaster()}>
                      <span class="icon-refresh"></span>
                    </button>
                  </OverlayTrigger> */}
                  <OverlayTrigger overlay={<Tooltip>Refresh Ledgers</Tooltip>}>
                    <span class="icon-refresh-iconn refresh-licon blink-green-btn" onClick={() => {
                      this.props.getVoucherTypeList();
                      this.props.getLedgerMaster()}}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                  </OverlayTrigger>
                  {/* <OverlayTrigger overlay={<Tooltip>Synced</Tooltip>}>
                    <span class="icon-personal-icon size-xxvii mr-2"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                  </OverlayTrigger> */}
                  {this.getSyncStatus(this.props.ledgerNameList.sync_status)}
                  {this.props.ledgerNameList.last_updated_at && 'Last Sync: ' + (this.props.ledgerNameList.last_updated_at)}
                </span>

                {/* <span>
                  <OverlayTrigger overlay={<Tooltip className="">Refresh Recommendation</Tooltip>}>
                    <button onClick={() => this.props.getMoneyLinkLedgerRecommendation()} class="btn refresh-icon-new-round"><span class="icon-invoice1 white-icon"></span></button>
                  </OverlayTrigger>
                </span> */}
                <span className='ledger-right-bar'>
                  <OverlayTrigger overlay={<Tooltip className="">Refresh Recommendation</Tooltip>}>
                    <span class="icon-Group-4507 refresh-recom-icon ledger-trs-icon mr-0" onClick={() => this.props.getMoneyLinkLedgerRecommendation()}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>
                  </OverlayTrigger>
                </span>
              </p>
            }

            {this.props.temp_voucher_list.length === 0 ?
              <div className="no-voucher">
                <p>No Voucher</p>
              </div> :
              this.props.temp_voucher_list.map((voucher, index) => {
                return <div id={`vouchercardref_${index}`}>
                  <VoucherCardView
                    key={"voucher_card_view_" + index + voucher.voucher_id}
                    ref={this[`${index}__vouchercardref`]}
                    //id={`vouchercardref_${index}`}
                    index={index}
                    voucher_type_guid={voucher.voucher_type_guid}
                    parent_voucher_type_id = {voucher.parent_voucher_type_id}
                    voucher_name={voucher.voucher_type_name}
                    accounting_narration={voucher.accounting_narration}
                    ledgers={this.getLedgerList(voucher.ledgers)}
                    total_credit_amount={voucher.total_credit_amount}
                    total_debit_amount={voucher.total_debit_amount}
                    voucher_creation_date={voucher.voucher_creation_date}
                    voucher_id={voucher.voucher_id}
                    removeVoucher={this.removeVoucher}
                    ledgerNameList={this.props.ledgerNameList}
                    updateVoucher={this.updateVoucher}
                    updateTotal={this.updateTotal}
                    updateAccountingNarration={this.updateAccountingNarration}
                    removeLedger={this.props.removeLedger}
                    is_valid_voucher={this.props.is_valid_voucher}
                    is_valid_ledger={this.props.is_valid_ledger}
                    read_only={this.props.read_only || !this.LEDGER_ENTRIES}
                    transactionAmount={this.props.transactionAmount}
                    transactionType={this.props.transactionType}
                    voucherNos={this.props.temp_voucher_list.length}
                    editVoucherDate={this.editVoucherDate}
                    editVouchertype = {this.editVouchertype}
                    submit_click={this.props.submit_click}
                    sync_status={voucher.sync_status}
                    sync_datetime={voucher.sync_datetime}
                    sync_error={voucher.sync_error}
                    voucher={voucher}
                    is_leder_recommended={!!voucher.is_leder_recommended}
                    isFormDirty={this.props.isFormDirty}
                    saveAllocationData={(allocation_data) => this.props.saveAllocationData(allocation_data)}
                    account_status={this.props.account_status}
                    old_account_status={this.props.old_account_status}
                    transaction_id={this.props.transaction_id}
                    voucherTypeList={this.props.voucherTypeList}
                    vaild_voucher = {this.props.vaild_voucher}
                    handleAccountingStatusChangeSyncToNotSync = {this.props.handleAccountingStatusChangeSyncToNotSync}
                  />
                </div>
              })
            }
            <div ref={this.btnRef} />

            {(is_permission_granted && !read_only) &&
              <div className="row">
                <div class="col-xl-3 col-lg-4"></div>
                <div className="col-xl-9 col-lg-12 right-form-sec ledger-type-date">
                  {/* <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                      <Form.Control as="select" disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false} name="voucher_type" value={this.state.voucher_type}
                        onChange={this.handleChange} >
                        <option selected value="">Voucher Type</option>
                        <option value={1}>Contra</option>
                        <option value={4}>Journal</option>
                        <option value={2}>Payment</option>
                        <option value={6}>Purchase</option>
                        <option value={3}>Receipt</option>
                        <option value={5}>Sales</option>
                      </Form.Control>
                    </Form.Group>
                  </Form> */}
                    {(this.props.voucherTypeList.length == 0) && <small className="error_right">Please sync with tally to get Voucher Types</small>}
                  <Form>
                <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                  <Form.Control as="select" disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false} name="voucher_type" value={this.state.voucher_type_guid}
                    onChange={this.handleChange} >
                    <option selected value="">Voucher Type</option>
                    {
														this.props.voucherTypeList.map((value,index)=>{
															return (
																<option key={index} value={value.guid}>
															  {value.name}
																</option>
															  );
														})
													}
                  </Form.Control>
                </Form.Group>
              </Form>

                  <div className="form-group mb-0 date-width-130">
                    <DatePicker
                      disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false}
                      dateFormat={DatePickerFormate()}
                      onChange={date => this.setDate(date, "voucher_creation_date")}
                      className="form-control datepicker-icon"
                      selected={Date.parse(voucher_creation_date)}
                      autoComplete="off"
                      placeholder="Voucher Date"
                      name="voucher_creation_date"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown />
                  </div>

                  <Button variant="success apply_btn_new"
                    disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false}
                    onClick={this.addVoucher}
                  >Add</Button>
                </div>
              </div>
            }
          </>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const voucher_list = all_value.voucher_list;
  const temp_voucher_list = all_value.temp_voucher_list;
  const transaction_details = all_value.transaction_details

  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];
  const ledger_recommendation_param = session_value.entity_config_param.ledger_recommendation || 0;

  return {
    voucher_list, temp_voucher_list, features_permissions_list, ledger_recommendation_param, transaction_details
  };
}

export default connect(mapStateToProps)(LedgerTab);