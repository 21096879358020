import React from 'react'
import { useState, useEffect } from 'react';

/*class Timer extends React.Component {
    constructor(props) {
        super();
        this.state = { time: {}, seconds: props.initialTime };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  componentDidUpdate(prevProps) {
      console.log("prevProps",prevProps);
    if(prevProps.initialTime !== this.props.initialTime) {
      this.setState({initialTime: this.props.initialTime});
    }
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(this.timer);
    }
  }

  render() {
    this.startTimer();
    return(
    //   <div>
    //     <button onClick={this.startTimer}>Start</button>
    //     m: {this.state.time.m} s: {this.state.time.s}
    //   </div>
        <span>
        { this.state.time.m === 0 && this.state.time.s === 0
            ? <span>00:00</span>
            : <span> {this.state.time.m}:{this.state.time.s < 10 ?  `0${this.state.time.s}` : this.state.time.s}</span> 
        }
        </span>
    );
  }
}*/









































const Timer = (props) => {
    const {initialMinute = 0,initialSeconds = 0, onExpire } = props;
    const [ minutes, setMinutes ] = useState(initialMinute);
    // useEffect(() => { setMinutes(props.initialMinute) }, [props.initialMinute]);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    if (onExpire) {
                      onExpire();
                    }
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    }, [seconds, minutes, onExpire]);

    return (
        <span>
        { minutes === 0 && seconds === 0
            ? <span>00:00</span>
            : <span> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> 
        }
        </span>
    )
}

export default Timer;