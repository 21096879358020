import React, { useState } from 'react'
import { withRouter } from 'react-router';
import { useHistory } from "react-router";


// In the notifications section there were some spana tags instead of span tags I changed them

const setDefaultEntity = (props) => {
    /*
    * THis route is create to solve the issue of dirty popup Please do not remove
    */
  const history = useHistory();
    history.push("/home");
    window.location.reload();

  return (
    <div>
    </div>
  )
}

export default withRouter(setDefaultEntity);