import React from 'react'
import ShowCount from './ShowCount';
import TaskFilter from './TaskFilter';
import TaskList from './TaskList';
import CreateTask from './CreateTask';
// import FinancialYear from "../../Components/BoilerPlate/FinancialYear";
import FinancialYearNew from "../../Components/BoilerPlate/FinancialYearNew";
import { connect } from "react-redux";
import { setFilterData } from "../../Redux/Actions/TaskManager";
import { withRouter } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import _ from "lodash";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid

import {
	TheHeaderNew,
	TheSidebarNew,  
} from '../Containers/index';

class TaskManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilter: false,
			active_input: false,
			search: "",
			showCross: false,

			// Intro js user guid
			// this.state = {
			stepsEnabled: false,
			initialStep: 0,

			steps: [
				{
					element: ".dropdown_taskin",
					intro: "You can select your focus ‘Time period’ from the dropdown menu.",
					// position: 'right',
					// hideNext: true 
				},
				{
					element: ".widgets_taskin",
					intro: "These widgets not only give you the high level numerical work status but also act as a filter after clicking."
				},
				{
					element: ".filter_taskin",
					intro: "You can filter the table based on different criteria.",
					position: 'left',
				},
				{
					element: ".table_taskin",
					intro: "The table gives you the data for the selected time period. You can click on any of them to view / edit."
				}
			],
			//   };
			// End Intro js user guid

		}
		this.VIEW_ONLY = _.isEqual([3, 9, 11, 14, 17, 23], this.props.features_permissions_list);
	}

	handleOnChange = (e) => {
		const { name, value } = e.target;
		if (name == "search") {
			this.props.changeSearchText(value)
		}
		// this.setState({ [name]: value })
	}

	checkForEnterButton = (e) => {
		if (e.charCode === 13) {
			if (this.props.searchText) {
				this.searchFilter(e)
			} else {
				e.preventDefault();
			}
		} else {
			// this.setState({ search: e.target.value })
			this.props.changeSearchText(e.target.value)
		}
	}

	searchFilter = (e) => {
		e.preventDefault();
		if (this.props.searchText) {
			this.props.searchFilter(this.props.searchText);
			this.props.changeSearchStatus(true)
			//this.resetFilterData()
			this.setState({ showFilter: false, showCross: true })
			if (this.props.filter_data) {
				const { is_star, assigned_by, assigned_to, due_end_date, due_start_date, priority } = this.props.filter_data
				if (
					is_star != "" ||
					assigned_by.length > 0 ||
					assigned_to.length > 0 ||
					due_end_date != "" ||
					due_start_date != "" ||
					priority != ""
				) {
					toast.warning("Removed filter data and applied search filter")
					console.log(this.props.filter_data)
				}
			}
		}
		else {
			toast.error("Please specify search criteria")
		}
	}

	resetFilter = (e) => {
		e.preventDefault();
		// this.setState({
		// 	search: '',
		// 	showCross: false,
		// }, () => {
		// 	this.props.searchFilter(this.state.search);
		// })
		this.props.changeSearchText("")
		this.props.changeSearchStatus(false)
		this.props.searchFilter("")
		this.setState({active_input:false})
	}

	resetFilterData = () => {
		if (this.props.filter_by === 2) {
			if (
				(this.state.is_star == null || this.state.is_star == "") &&
				this.state.due_start_date == "" &&
				this.state.due_end_date == "" &&
				this.state.assigned_by.length == 0 &&
				this.state.assigned_to.length == 0 &&
				this.state.priority == ""
			) {
				toast.error("No filter applied to reset")
			}
			else {
				let filter_data = {
					is_star: '',
					due_start_date: "",
					due_end_date: "",
					assigned_by: [],
					assigned_to: [],
					priority: '',
				}
				this.props.dispatch(setFilterData(filter_data));
				this.props.applyFilter("reset");
			}
		}
	}

	searchReset = () => {
		this.setState({
			search: '',
			showCross: false,
		})
	}

	showFilter = () => {
		if (this.props.filter_by == 2) {
			this.setState({ showFilter: true })
		}
	}

	searchFocus = () => {
		// if(this.state.showFilter){
		// 	this.setState({
		// 		//showFilter: false,
		// 		active_input: true
		// 	}, ()=>{
		// 		this.props.searchFilter(this.state.search)
		// 	})
		// }
		// else{
		// 	this.setState({active_input: true})
		// }
		this.setState({ active_input: true })
	}

	componentDidMount() {
		this.showFilter()
	}

	componentDidUpdate(prevProps) {
		/*if(prevProps.filter_data != undefined)
		if(!(JSON.stringify((this.props.filter_data)) === JSON.stringify(prevProps.filter_data))) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
		{
			console.log("--filter_data--",this.props.filter_data);
		}*/
	}

	// Intro js user guid   
	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};
	// End Intro js user guid 

	render() {

		// Intro js user guid 
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;
		// End Intro js user guid 

		return (<>
			{/* <TheHeaderNew />
          	<TheSidebarNew /> */}
			<main className="content-wrapper-new">
				<div className="container-fluid container-padding-new">
					{/* Intro js user guid  */}
					<Steps
						enabled={stepsEnabled}
						steps={steps}
						initialStep={initialStep}
						onExit={this.onExit}
					/>
					{/* End Intro js user guid  */}

					{/* Breadcrumb */}
					<div className="row">
						<div className="col-12">
							<div className="main-sub-header-new">
								<div className="breadcrumb-header-new">
									<BreadCrum level={2} />
									<h2 className="breadcrumb-title-new">
										Task Manager
										{/* <span className="help-icon" > */}
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger>
										{/* </span> */}
									</h2>
								</div>
							</div>
						</div>
					</div>
					{/* End Breadcrumb */}

					{/* Filter */}
					<div className="row mainFilter-new sec-mb">
						<div className="col-12">
							{/* <div className="top-filter d-flex flex-wrap align-items-center justify-content-between "> */}
							{/* <div className="top-filter d-flex flex-wrap align-items-center gap-flt dropdown_taskin"> */}
							{/* <div className="top-new-filter d-flex flex-wrap align-items-center"> */}
							<div className="top-new-filter">
								{/* <div className="filter-bar-new dropdown_taskin"> */}
								<FinancialYearNew
									financialYearChanged={this.props.financialYearChanged}
									refreshItem={this.props.refreshItem}
								/>
								{/* </div> */}
								{/* <div className="gap-flt d-flex align-items-center ml-lg-auto"> */}
								<div className="filter-search-field">
								<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Task</Tooltip>}>
									{/* <form className="search-new-sec ml-lg-auto" > */}
									{/* active-filter */}
									<form className="search-new-sec  " >
										<input type="text"
											onKeyPress={(e) => this.checkForEnterButton(e)}
											className={"form-control filter-btn-new " + (this.state.active_input ? " activeNew-search" : "")}
											onFocus={this.searchFocus}
											// onBlur={() => this.setState({ active_input: false })}
											placeholder="Search"
											value={this.props.searchText}
											onChange={(e) => this.handleOnChange(e)}
											name="search"
										/>
										{!this.props.isSearchApplied ?
											<Button onClick={(e) => this.searchFilter(e)} className={this.state.active_input ? " active-new-search-icon " : ""}>
												<span className="icon-loupe"></span>
											</Button>
											:
											<Button onClick={(e) => this.resetFilter(e)} className={this.state.active_input ? " active-new-search-icon crosebtn " : " crosebtn "}>
												<span class="icon-cancel"></span>
											</Button>
										}
									</form>
								</OverlayTrigger>

								<Button variant={this.props.filter_by == 2 ? " outline-secondary filter-btn-new border-radius-26 active-filter filter_taskin " : " outline-secondary filter-btn-new border-radius-26 filter_taskin"}
									onClick={() => this.setState({ showFilter: !this.state.showFilter })}
								>Filter <span className="icon-funnel gray-dark-icon filter-icon"></span></Button>

								{!this.VIEW_ONLY &&
									<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add New</Tooltip>}>
										<Button variant="success add-new-btn add-btn-height "
											onClick={() => this.props.history.push('/task-manager/create')}><span className="icon-plus1"></span></Button>
									</OverlayTrigger>
								}
								</div>
							</div>

							{/* Filter Popup */}
							{!!this.state.showFilter &&
								<TaskFilter
									filter_by={this.props.filter_by}
									applyFilter={this.props.applyFilter}
									assigned_by_arr={this.props.assigned_by_arr}
									assigned_to_arr={this.props.assigned_to_arr}
									reset={this.searchReset}
									search={this.state.search} />
							}
							{/* End Filter Popup */}
						</div>
					</div>
					{/* End Filter */}

					{/* Card Filter */}
					<ShowCount onCardSelected={this.props.onCardSelected} filter_card={this.props.filter_card} />
					{/* End Card Filter */}

					{/* Task List */}
					<span className='table_taskin'>
						<TaskList
							filter_data={this.state.filter_data}
							task_list={this.props.task_list}
							onSortClick={this.props.onSortClick}
							sort_by={this.props.sort_by}
							sort_order={this.props.sort_order}
							changeTaskStatus={this.props.changeTaskStatus}
							disabled_tasks={this.props.disabled_tasks} />
					</span>
					{/* End Task List */}

				</div>{/* End container-fluid */}
			</main>
		</>
		)
	}
}

function mapStateToProps(state) {
	const { filter_data } = state.TaskManager;
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	return {
		filter_data, features_permissions_list
	};
}

export default connect(mapStateToProps)(withRouter(TaskManager));