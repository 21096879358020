import {
  SET_BUSINESS_NARRATION, SET_EVIDENCE_FILTER_DATA, SET_EVIDENCE_LIST_DATA,
  SET_FILTER_CARD, SET_FILTER_DATE_RANGE, SET_FINANCIAL_INSTITUTE_NAME, SET_FINANCIAL_YEAR_NAME,
  SET_IS_PERSONAL, SET_IS_TRANSACTION_LOADING, SET_LINKED_EVIDENCE_LIST, SET_NO_SUPPORTING_DOCUMENT,
  SET_TRANSACTION_STATUS, SET_ENTRY_STATUS, SET_ACCOUNT_STATUS, SET_TRANSACTIONS_FILTER_BY, SET_TRANSACTION_FILTER_DATA,
  SET_TRANSACTION_PAGE_NO, SET_TEMP_LINKED_EVIDENCE_LIST, SET_TEMP_VOUCHER_LIST, SET_VOUCHER_LIST, 
  SET_TRANSACTION_DETAILS, SET_SUGGESTED_EVIDENCE_LIST_DATA, SET_SUGGESTED_EVIDENCE_IDS,
  SET_BULK_TRANSACTION_LIST,SET_COST_CATEGORY_LIST,SET_CR_DR_TYPE, SET_MONEY_LINK_DEFAULT_WIDGET_CARDS, SET_MONEY_LINK_CARD_FILTER
} from "./Types";


export const setTransactionFilterData = (filter_data) => ({
  type: SET_TRANSACTION_FILTER_DATA,
  payload: filter_data,
});

export const setTransactionLoadingStatus = (is_transaction_loading) => ({
  type: SET_IS_TRANSACTION_LOADING,
  payload: is_transaction_loading,
});

export const setPageNo = (page_no) => ({
  type: SET_TRANSACTION_PAGE_NO,
  payload: page_no,
});

export const setFinancialInstituteName = (bank_name, bank_value,account_number) => ({
  type: SET_FINANCIAL_INSTITUTE_NAME,
  payload: {
    bank_value:bank_value,
    bank_name:bank_name,
    account_number:account_number
  },
});

export const setFinancialYearName = (financial_year_name) => ({
  type: SET_FINANCIAL_YEAR_NAME,
  payload: financial_year_name,
});

export const setFilterDateRange = (start_date, end_date) => ({
  type: SET_FILTER_DATE_RANGE,
  payload: {
    start_date: start_date,
    end_date: end_date
  },
});

export const setFilterCard = (filter_card) => ({
  type: SET_FILTER_CARD,
  payload: filter_card,
});

export const setEvidenceFilterData = (filter_data) => ({
  type: SET_EVIDENCE_FILTER_DATA,
  payload: filter_data,
});

export const setFilterBy = (filter_by) => ({
  type: SET_TRANSACTIONS_FILTER_BY,
  payload: filter_by,
});

export const setEvidenceListData = (list_data) => ({
  type: SET_EVIDENCE_LIST_DATA,
  payload: list_data,
});

export const setLinkedEvidenceList = (list) => ({
  type: SET_LINKED_EVIDENCE_LIST,
  payload: list,
});

export const setTempLinkedEvidenceList = (list) => ({
  type: SET_TEMP_LINKED_EVIDENCE_LIST,
  payload: list,
});

export const setIsPersonal = (is_personal) => ({
  type: SET_IS_PERSONAL,
  payload: is_personal,
});


export const setNoSupportingDocument = (no_supporting_document) => ({
  type: SET_NO_SUPPORTING_DOCUMENT,
  payload: no_supporting_document,
});

export const setTransactionStatus = (transaction_status) => ({
  type: SET_TRANSACTION_STATUS,
  payload: transaction_status,
});

export const setEntryStatus = (entry_status) => ({
  type: SET_ENTRY_STATUS,
  payload: entry_status,
});

export const setAccountStatus = (account_status) => ({
  type: SET_ACCOUNT_STATUS,
  payload: account_status,
});

export const setBusinessNarration = (business_narration) => ({
  type: SET_BUSINESS_NARRATION,
  payload: business_narration,
});

// export const setVoucherList = (list) => ({
//   type: SET_VOUCHER_LIST,
//   payload: list,
// });

export const setTempVoucherList = (list) => ({
  type: SET_TEMP_VOUCHER_LIST,
  payload: list,
});

export const setTransactionDetails = (transaction_details) => ({
  type: SET_TRANSACTION_DETAILS,
  payload: transaction_details,
});

export const setSuggestedEvidenceListData = (suggested_list_data) => ({
  type: SET_SUGGESTED_EVIDENCE_LIST_DATA,
  payload: suggested_list_data,
});

export const setSuggestedEvidenceIds = (suggested_evidence_ids) => ({
  type: SET_SUGGESTED_EVIDENCE_IDS,
  payload: suggested_evidence_ids,
});

export const setBulkTransactionList = (bulk_transaction_list) => ({
  type: SET_BULK_TRANSACTION_LIST,
  payload: bulk_transaction_list,
});

export const setCostCategoryList = (cost_category_list) => ({
  type: SET_COST_CATEGORY_LIST,
  payload: cost_category_list,
});

export const setCrDrType = (cr_dr_type) => ({
  type: SET_CR_DR_TYPE,
  payload: cr_dr_type,
});

export const setMoneyLinkDefaultWidgets = (default_cards) => ({
  type: SET_MONEY_LINK_DEFAULT_WIDGET_CARDS,
  payload: default_cards,
});

export const setMoneyLinkCardFilter = (card_name) => ({
  type: SET_MONEY_LINK_CARD_FILTER,
  payload: card_name,
});
