import React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

import { displayDateFormate, displayTimeFormat } from '../../Helpers/DateFormat';
// import firebase from 'firebase/app';
import firebase from '../../Utils/firebase'
import 'firebase/firestore';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const db = firebase.firestore();

class Messages extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			messages: [],
			all_users: []
		}

		if (!!this.props.default_entity_detail.id) {
			this.group_reference = db.collection("context_group");
			const data = this.group_reference
				// .orderBy("created_at", "desc")
				.where("members", 'array-contains', this.props.user_detail.id)
				.where("entity_id", '==', this.props.default_entity_detail.id)
				.limit(10)
				.get();
			data.then(
				(data) => {
					let messages = [];
					data.docs.forEach((doc) => {
						if (!doc.data().readers.includes(this.props.user_detail.id))
							messages.push(doc.data());
					});
					this.setState({ messages });
				});
		}
	}

	getDateTime = (created_at) => {
		if (!!created_at) {
			return displayDateFormate(created_at.toDate()) + " " + displayTimeFormat(created_at.toDate());
		} else {
			return "Now";
		}
	}

	redirectToModule = (message_data) => {
		this.readMessage(message_data);
		switch (message_data.context_name) {
			case "snapshot":
				this.props.history.push("/snapshot/evidence/create/" + message_data.context_id)
				break;
			case "money_link":
				this.props.history.push("/money-link/" + message_data.context_id)
				break;
		}
	}

	readMessage = async (message_data) => {

		this.groupRefRead = db.collection("context_group");
		await this.groupRefRead.where("context_id", '==', message_data.context_id)
			.where("context_type", '==', message_data.context_type)
			.where("members", 'array-contains', this.props.user_detail.id)
			.get()
			.then(
				(data) => {
					if (!data.empty) {
						let grp_detail = {
							"readers": firebase.firestore.FieldValue.arrayUnion(this.props.user_detail.id)
						}
						this.groupRefRead.doc(data.docs[0].id).update(grp_detail).then((data) => { });
					}
				});
	}

	showMessage = (message) => {

		let regex = /\@\[[A-Za-z0-9 ]*\]\([0-9]*\)/g;
		let words = message.match(regex);
		if (!!words)
			for (let i = 0; i < words.length; i++) {
				let mySubString = words[i].substring(
					words[i].indexOf("@[") + 2,
					words[i].lastIndexOf("](")
				);
				message = message.replace(words[i], "<span class='red-text'>" + mySubString + "</span>");
			}
		return message;
	}

	render() {
		return (
			<>
				{
					this.state.messages.map((message_data, index) => {
						return <div className="message-cards cpointer" onClick={() => this.redirectToModule(message_data)} key={index}>
							<div className="msg-card-heading">
								<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{message_data.recent_message.user_detail.full_name}</Tooltip>}>
									<p>{message_data.recent_message.user_detail.full_name}</p>
								</OverlayTrigger>
								<p><span class="icon-icon-clock red-icon"></span></p>
							</div>
							<p className="msg-card-desc" dangerouslySetInnerHTML={{ __html: this.showMessage(message_data.recent_message.message) }}></p>
							<p className="msg-card-date">{this.getDateTime(message_data.updated_at)}</p>
						</div>
					})
				}

				{
					!this.state.messages.length && <p className="p-big">No new Messages available</p>
				}


				{/* <p>No new Messages available</p> */}
				{/* <div className="message-cards" >
					<div className="msg-card-heading">
						<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">User Name</Tooltip>}>
							<p>User Name</p>
						</OverlayTrigger>
						<p><span class="icon-icon-clock red-icon"></span></p>
					</div>
					<p className="msg-card-desc">
						<span class="red-text">Name</span> Welcome back! see what we got since your last visit. See what we got since your last
					</p>
					<p className="msg-card-date">09-02-2024 17:02</p>
				</div> */}
			</>
		);
	}
}

function mapStateToProps(state) {
	const all_value = state.Session || {};
	const entity_list = all_value.entity_list || [];
	const account_permissions = all_value.account_permissions || [];
	const module_permissions = all_value.module_permissions || [];
	const user_detail = all_value.user_detail || {};
	const default_entity_detail = all_value.default_entity_detail || {};
	return {
		entity_list, account_permissions, module_permissions, user_detail, default_entity_detail
	};
}

export default connect(mapStateToProps)(withRouter(Messages));