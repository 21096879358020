
export const checkFileExtension = ( parafilevalue ) => {
		var file = parafilevalue.split('.');
		var validType = false;
	  
		if( file.length < 2 ){
		 	return false;
		}
	  
		var fileext = file[1].toLowerCase();
		if( fileext == 'xml' ) {
		 	validType = true;
		}
	  
	  	return validType;
	}