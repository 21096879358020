import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import { showRoundOffAmount } from '../../../Helpers/Currency';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class BarChartWithList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chart_data: null,
      show_modal: false,
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.data != prevProps.data) {
      let chart_data = {
        labels: this.props.data.map((item) => {
          return item[this.props.keytitle]
        }),
        datasets: [{
          data: this.props.data.map((item) => {
            return item.amount
          }),
          backgroundColor: this.props.color2_background,
        }]
      }

      this.setState({ chart_data: chart_data })
    }
  }

  componentDidMount = () => {
    let chart_data = {
      labels: this.props.data.map((item) => {
        return item[this.props.keytitle]
      }),
      datasets: [{
        data: this.props.data.map((item) => {
          return item.amount
        }),
        backgroundColor: this.props.color2_background,
      }]
    }

    this.setState({ chart_data: chart_data })
  }

  chartComponent = () => {
    let options = {
      plugins: {
        legend: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6
          }
        }],
        xAxes: [
          {
            ticks: {
              callback: (label, index, labels) => {
                return label && label.length > 8 ? label.substr(0, 8) + '...' : label;
              },
            },
          }
        ]
      },
      tooltips: {
        enabled: true,
        mode: 'label',
      },
    }
    //console.log(this.state.chart_data)
    return (
      <div className="chart-box-new">
        <h4 className="chart-box-heading">{this.props.title}</h4>

        {/* row */}
        <div className="row">
          <div className="col-xl-7 col-lg-8 col-md-7">
            <div className="chart-div" onClick={() => this.setState({ show_modal: true })}>
              <Bar data={this.state.chart_data} options={options}></Bar>
            </div>
          </div>

          <div className="col-xl-5 col-lg-4 col-md-5 pl-0">
            <div className="chart-content chart-content-big">

              <div className="chart-scroll-list">
                <div className="chart-name-list cl-list-scroll">
                  {
                    this.props.data.map((item) => {
                      return (
                        <div className="row">
                          <div className="col-7 pr-0">
                            <p className="cl-keytitle">
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item[this.props.keytitle]}</Tooltip>}>
                                <span>{item[this.props.keytitle]}</span>
                              </OverlayTrigger>
                            </p>
                          </div>
                          <div className="col-5">
                            <p className="cl-amount">
                              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(item.amount)}</Tooltip>}>
                                <span>{showRoundOffAmount(item.amount)}</span>
                              </OverlayTrigger>
                            </p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end row */}
      </div>
    )
  }


  popChartComponent = () => {
    let options = {
      plugins: {
        legend: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6
          }
        }],
        xAxes: [
          {
            ticks: {
              callback: (label, index, labels) => {
                return label && label.length > 8 ? label.substr(0, 8) + '...' : label;
              },
            },
          }
        ]
      },
      tooltips: {
        enabled: true,
        mode: 'label',
      },
    }
    //console.log(this.state.chart_data)
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="chart-box-new model-chart-box-new">

            {/* row */}
            <div className="row">
              <div className="col-xl-9 col-lg-8 col-md-7 ">
                <div className="chart-div" onClick={() => this.setState({ show_modal: true })}>
                  <Bar data={this.state.chart_data} options={options}></Bar>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-5">
                <div className="chart-content">
                  <div className="chart-scroll-list">
                    <div className="chart-name-list model-cl-list-scroll">
                      {
                        this.props.data.map((item) => {
                          return (
                            <div className="row">
                              <div className="col-7">
                                <p className="cl-keytitle">
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item[this.props.keytitle]}</Tooltip>}>
                                    <span>{item[this.props.keytitle]}</span>
                                  </OverlayTrigger>
                                </p>
                              </div>
                              <div className="col-5">
                                <p className="cl-amount">
                                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showRoundOffAmount(item.amount)}</Tooltip>}>
                                    <span>{showRoundOffAmount(item.amount)}</span>
                                  </OverlayTrigger>
                                </p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
          </div>
        </Modal.Body>
      </>
    )
  }

  render() {
    return (
      <>
        {this.chartComponent()}
        <Modal
          show={this.state.show_modal}
          // size="lg"
          onHide={() => this.setState({ show_modal: false })}
          // dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          backdrop="static"
          centered
          className="popup-model chart-box-popup"
        >
          {this.popChartComponent()}
        </Modal>
      </>
    )
  }
}