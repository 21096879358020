function validateSaveBulkLedger(cr_ledger_id, dr_ledger_id, voucher_type,
    is_accounting_narration, accounting_narration) {
    let errors = {};
  
    if (!voucher_type) {
      errors.voucher_type = 'Voucher Type is required';
    }
  
    if (!cr_ledger_id.value) {
      errors.cr_ledger_id = 'Please select CR ledger';
    }

    if (!dr_ledger_id.value) {
      errors.dr_ledger_id = 'Please select DR ledger';
    }

    if(cr_ledger_id.value == dr_ledger_id.value){
      errors.dr_ledger_id = "Duplicate ledger found"; //for money link bulk entry
    }
    // if (is_accounting_narration && !accounting_narration) {
    //   errors.accounting_narration = 'Please enter account narration';
    // }
  
  
    return errors;
  }
  
  export {
    validateSaveBulkLedger,
    
  }