import { SET_EARLIER_NOTIFICATION,SET_NOTIFICATION_COUNT } from "./Types";

export const setAppilcationLoder = (notification_list) => ({
    type: SET_EARLIER_NOTIFICATION,
    payload: notification_list,
  });

  export const setNotificationCount = (count) => ({
    type: SET_NOTIFICATION_COUNT,
    payload: count,
  });
