import React from 'react'
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import EvidenceCardView from '../../Components/BoilerPlate/EvidenceCardView';
import Pagination from '../../Components/BoilerPlate/Pagination';
import { setEvidenceListData, setLinkedEvidenceList, setTempLinkedEvidenceList, setSuggestedEvidenceListData } from '../../Redux/Actions/MoneyLink';
import AddNew from '../IcoonNew/AddNew';
import MessageModal from '../../Components/BoilerPlate/MessageModal';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { CREATE_BUSSINESS_SNAPSHOT_CODE, MANAGE_OTHER_USER_SNAPSHOT_CODE } from '../../Helpers/Constants';


class EvidenceList extends React.Component {
  constructor(props) {
    super(props)

    this.PERMISSION_LIST = this.props.features_permissions_list;
    this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
    this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
    this.VIEW_LINKED_ENTRIES = this.PERMISSION_LIST.includes(VIEW_LINKED_ENTRIES_CODE);
    this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_BUSSINESS_SNAPSHOT_CODE);
    this.MANAGE_OTHER_USER_SNAPSHOT = this.PERMISSION_LIST.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);

    this.state = {
      add_module: '1',
      no_access_to_snapshot_alert: false,
    }
    //console.log(this.props)
  }

  getEvidenceList = (page_no) => {
    this.props.setPageNo(page_no)
  }

  handleSelectEvidence = (evidence_id) => {
    let backup_evidences = _.cloneDeep(this.props.backup_evidences);
    let evidence_list_select = [...this.props.evidence_list];
    let temp_linked_evidences_select = [...this.props.temp_linked_evidences];

    var index_select = evidence_list_select.findIndex(evidence => (evidence.id || evidence.mod_id) == evidence_id);

    var evidence_select = evidence_list_select[index_select];

    evidence_list_select.splice(index_select, 1);

    temp_linked_evidences_select.push(evidence_select)

    if (this.props.evidence_tab == 1) {

      let list_data = {
        "evidences": evidence_list_select
      }
      this.props.dispatch(setSuggestedEvidenceListData(evidence_list_select));
      this.props.dispatch(setEvidenceListData(list_data));
    } else {
      let list_data = {
        "evidences": evidence_list_select
      }

      this.props.dispatch(setEvidenceListData(list_data));

      let suggested_evidence_list = [...this.props.suggested_evidence_list]
      let index = suggested_evidence_list.findIndex(evidence => (evidence.id || evidence.mod_id) == evidence_id);

      if (index !== -1) {
        //let evidence = suggested_evidence_list[index];
        suggested_evidence_list.splice(index, 1);
      }

      this.props.dispatch(setSuggestedEvidenceListData(suggested_evidence_list));
    }

    this.props.isFormDirty();

    //calculate linked and pending amount according to sort by date
    let sum_of_all_evidences = 0;
    let transaction_amount = this.props.transactionAmount;
    let transaction_pending_amt = 0;


    let sorted_linked_evidences = temp_linked_evidences_select.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });


    let evidence_linked_data = [];

    console.log(sorted_linked_evidences)

    sorted_linked_evidences = sorted_linked_evidences.map(function (evidence) {
      if (evidence.transaction_linked_amount == undefined) {
        evidence.transaction_linked_amount = 0;
      }
      //console.log('relocate evidence.transaction_linked_amount ==='+evidence.transaction_linked_amount)
      //console.log('relocate evidence.pending_amount ==='+evidence.pending_amount)
      //console.log('relocate evidence.linked_amount ==='+evidence.transaction_linked_amount)

      if (evidence.transaction_linked_amount != 0) {

        evidence.linked_amount = parseFloat((evidence.linked_amount - evidence.transaction_linked_amount).toFixed(2));
        evidence.pending_amount = parseFloat((evidence.pending_amount + evidence.transaction_linked_amount).toFixed(2));
        evidence.transaction_linked_amount = 0;
      } else {


        let index = backup_evidences.findIndex(evi => evi.id == evidence.id);
        if (index != -1) {
          console.log(backup_evidences[index])
          evidence = backup_evidences[index]
          evidence.transaction_linked_amount = 0;
        }
      }

      //console.log('after relocate evidence.linked_amount ==='+evidence.transaction_linked_amount)
      //console.log('after relocate evidence.pending_amount ==='+evidence.pending_amount)
      return evidence
    })
    console.log(sorted_linked_evidences);
    //return
    sorted_linked_evidences.map(function (evidence) {
      sum_of_all_evidences = 0;
      evidence_linked_data.map(function (data) {
        //console.log(data.linked_amount)
        sum_of_all_evidences = parseFloat(sum_of_all_evidences + data.transaction_linked_amount);
      })

      let newly_added_evidence_linked_amt = 0;
      let pending_amt = 0;
      console.log(transaction_amount)
      transaction_pending_amt = transaction_amount - sum_of_all_evidences;

      if (transaction_pending_amt < 0) {
        transaction_pending_amt = 0
      }

      console.log('sum_of_all_evidences ===' + sum_of_all_evidences)
      console.log('transaction_pending_amt ===' + transaction_pending_amt)
      console.log('evidence.pending_amount ===' + evidence.pending_amount)

      evidence.transaction_linked_amount = 0;
      if (transaction_pending_amt == 0) {
        evidence.transaction_linked_amount = 0;
      }
      //case 1
      else if (evidence.pending_amount > transaction_pending_amt) {
        evidence.transaction_linked_amount = transaction_pending_amt;
        evidence.linked_amount = evidence.linked_amount + transaction_pending_amt;
        evidence.pending_amount = evidence.pending_amount - transaction_pending_amt;
        //transaction_pending_amt = 0;
      }
      //case 2
      else if (evidence.pending_amount < transaction_pending_amt) {
        evidence.transaction_linked_amount = evidence.pending_amount;
        //transaction_pending_amt = transaction_pending_amt - evidence.pending_amount;
        evidence.linked_amount = evidence.linked_amount + evidence.pending_amount;
        evidence.pending_amount = 0;
      }
      //case 3
      else if (evidence.pending_amount = transaction_pending_amt) {
        evidence.transaction_linked_amount = transaction_pending_amt;
        evidence.linked_amount = evidence.linked_amount + transaction_pending_amt
        evidence.pending_amount = 0;
      }
      console.log('evidence.transaction_linked_amount ===' + evidence.transaction_linked_amount)
      console.log('evidence.pending_amount ===' + evidence.pending_amount)
      evidence_linked_data.push(evidence)

      console.log(evidence)
    })
    //console.log(evidence_linked_data)
    //console.log(sum_of_all_evidences)

    console.log(temp_linked_evidences_select)
    this.props.dispatch(setTempLinkedEvidenceList(evidence_linked_data));
    this.props.checkReadyStatus(evidence_linked_data);

    setTimeout(() => {
      if (evidence_linked_data.length == 1) {
        this.props.getMoneyLinkLedgerRecommendation()
      }
    }, 1500);
  }

  handleAddModule = (e) => {
    this.setState({ add_module: e.target.value })
  }

  addModule = () => {
    if (this.state.add_module !== '') {
      if (this.state.add_module == 1 && !this.props.features_permissions_list.includes(CREATE_BUSSINESS_SNAPSHOT_CODE)) {
        this.setState({ add_module: "" })
        this.toggleMessageModal()
      } else {
        this.props.toggleSaveData(this.state.add_module)
      }
    } else {
      toast.warning('Please select evidence type')
    }
  }

  onDragStart = (ev, evidence_id, mod_id) => {
    let id;
    if (evidence_id)
      id = evidence_id;
    if (mod_id)
      id = mod_id;

    ev.dataTransfer.setData("linked-id", id);
  }

  onDrop = (ev, cat) => {

    let evidence_id = ev.dataTransfer.getData("id");

    if (evidence_id) {
      let temp_linked_evidences = [...this.props.temp_linked_evidences],
        evidence_list = [...this.props.evidence_list];
      let index = temp_linked_evidences.findIndex(evidence => (evidence.id || evidence.mod_id) == evidence_id);
      let evidence = temp_linked_evidences[index];
      let backup_evidences = _.cloneDeep(this.props.backup_evidences);

      let backup_index = backup_evidences.findIndex(evidence => (evidence.id || evidence.mod_id) == evidence_id);

      if (backup_index != -1) {
        evidence = backup_evidences[backup_index]
      } else {
        evidence.linked_amount = parseFloat((evidence.linked_amount - evidence.transaction_linked_amount).toFixed(2));
        evidence.pending_amount = parseFloat((evidence.pending_amount + evidence.transaction_linked_amount).toFixed(2));
        evidence.transaction_linked_amount = 0;
        console.log(evidence)
      }


      evidence_list.push(evidence)
      temp_linked_evidences.splice(index, 1);
      let list_data = {
        "evidences": evidence_list
      }
      this.props.isFormDirty()

      //calculate linked and pending amount according to sort by date
      let sum_of_all_evidences = 0;
      let transaction_amount = this.props.transactionAmount;
      let transaction_pending_amt = 0;


      let sorted_linked_evidences = temp_linked_evidences.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });


      let evidence_linked_data = [];

      //console.log(sorted_linked_evidences)

      sorted_linked_evidences = sorted_linked_evidences.map(function (evidence) {
        if (evidence.transaction_linked_amount == undefined) {
          evidence.transaction_linked_amount = 0;
        }
        console.log('relocate evidence.transaction_linked_amount ===' + evidence.transaction_linked_amount)
        console.log('relocate evidence.pending_amount ===' + evidence.pending_amount)
        //console.log('relocate evidence.linked_amount ==='+evidence.linked_amount)

        if (evidence.transaction_linked_amount != 0) {

          evidence.linked_amount = parseFloat((evidence.linked_amount - evidence.transaction_linked_amount).toFixed(2));
          evidence.pending_amount = parseFloat((evidence.pending_amount + evidence.transaction_linked_amount).toFixed(2));
          evidence.transaction_linked_amount = 0;
          console.log(evidence)
        } else {


          let index = backup_evidences.findIndex(evi => evi.id == evidence.id);
          if (index != -1) {
            evidence = backup_evidences[index]
            evidence.transaction_linked_amount = 0;
          }
        }

        console.log('after relocate evidence.linked_amount ===' + evidence.linked_amount)
        console.log('after relocate evidence.pending_amount ===' + evidence.pending_amount)
        return evidence
      })
      console.log(sorted_linked_evidences);
      //return
      sorted_linked_evidences.map(function (evidence) {
        sum_of_all_evidences = 0;
        evidence_linked_data.map(function (data) {
          console.log(data.transaction_linked_amount)
          sum_of_all_evidences = parseFloat(sum_of_all_evidences + data.transaction_linked_amount);
        })


        transaction_pending_amt = transaction_amount - sum_of_all_evidences;

        if (transaction_pending_amt < 0) {
          transaction_pending_amt = 0
        }

        console.log('sum_of_all_evidences ===' + sum_of_all_evidences)
        console.log('transaction_pending_amt ===' + transaction_pending_amt)
        console.log('evidence.pending_amount ===' + evidence.pending_amount)

        evidence.transaction_linked_amount = 0;
        if (transaction_pending_amt == 0) {
          evidence.transaction_linked_amount = 0;
        }
        //case 1
        else if (evidence.pending_amount > transaction_pending_amt) {
          evidence.transaction_linked_amount = transaction_pending_amt;
          evidence.linked_amount = evidence.linked_amount + transaction_pending_amt;
          evidence.pending_amount = evidence.pending_amount - transaction_pending_amt;
          //transaction_pending_amt = 0;
        }
        //case 2
        else if (evidence.pending_amount < transaction_pending_amt) {
          evidence.transaction_linked_amount = evidence.pending_amount;
          evidence.linked_amount = evidence.linked_amount + evidence.pending_amount;
          evidence.pending_amount = 0;
        }
        //case 3
        else if (evidence.pending_amount = transaction_pending_amt) {
          evidence.transaction_linked_amount = transaction_pending_amt;
          evidence.linked_amount = evidence.linked_amount + transaction_pending_amt
          evidence.pending_amount = 0;
        }

        console.log('evidence.transaction_linked_amount ===' + evidence.transaction_linked_amount);
        console.log('evidence.pending_amount ===' + evidence.pending_amount);
        console.log(evidence)
        evidence_linked_data.push(evidence)
      })

      this.props.dispatch(setEvidenceListData(list_data));
      //this.props.dispatch(setTempLinkedEvidenceList(temp_linked_evidences));
      this.props.dispatch(setTempLinkedEvidenceList(evidence_linked_data));
      this.props.checkReadyStatus(evidence_linked_data)

    }
    ev.preventDefault();
  }

  onDragOver = (ev) => {
    ev.preventDefault();
  }

  toggleMessageModal = () => {
    this.setState({ no_access_to_snapshot_alert: !this.state.no_access_to_snapshot_alert })
  }

  render() {
    console.log(this.props.backup_evidences)
    return (
      <React.Fragment>
        <MessageModal showModal={this.state.no_access_to_snapshot_alert} content={"You do not have required permission to access snapshot module."} toggleMessageModal={this.toggleMessageModal} />

        <div className="evi-card-sec-new mb-3">
          {/* row no-gutters */}
          {/* col-xl-4 col-lg-6 col-md-12 */}
          {/* col-xl-4 col-lg-6 col-md-12 */}
          <div className="evi-card-list" onDragOver={(e) => this.onDragOver(e)} onDrop={(e) => this.onDrop(e, "complete")}>
            {this.props.evidence_list.map((evidence, index) => {
              return <div className="evi-list-card" key={index} draggable onDragStart={(e) => this.onDragStart(e, evidence.id, evidence.mod_id)}>
                <EvidenceCardView
                  is_linked_evidence={false}
                  id={evidence.id || evidence.mod_id}
                  module={evidence.module}
                  date={evidence.date}
                  evidence_type={evidence.evidence_type}
                  gst_status={evidence.gst_status}
                  tds_status={evidence.tds_status}
                  pending_amount={evidence.pending_amount}
                  vendor={evidence.vendor}
                  total={evidence.total}
                  report_url={evidence.report_url || evidence.report}
                  status={evidence.status}
                  link_history={evidence.link_history}
                  handleSelectEvidence={this.handleSelectEvidence}
                  editEvidence={this.props.editEvidence}
                  viewEvidence={this.props.viewEvidence}
                  showLinkHistory={this.props.showLinkHistory}
                  features_permissions_list={this.props.features_permissions_list}
                  score={evidence.score}
                />
              </div>
            })}

            <div className="evi-list-card evi-add-new-card">
              <div className="evi-cards">
                <span onClick={() => this.addModule()}><AddNew addNew={() => { }} /></span>
                <p className="">Add Evidence</p>
              </div>
            </div>
          </div>
        </div>

        {
          this.props.evidence_tab == 2 && this.props.evidence_list.length != 0 && <Pagination
            getList={this.getEvidenceList}
            has_next={this.props.has_next}
            has_previous={this.props.has_previous}
            page_no={this.props.page_no}
            total_pages={this.props.total_pages}
            per_page_items={this.props.per_page_items} />
        }
      </React.Fragment >
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const linked_evidences = all_value.linked_evidences;
  const temp_linked_evidences = all_value.temp_linked_evidences;
  //const evidence_list = all_value.evidences || [];
  const suggested_evidence_list = all_value.suggested_evidence_list || []
  const suggested_evidence_ids = all_value.suggested_evidence_ids || []


  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];

  return {
    linked_evidences, temp_linked_evidences, features_permissions_list, suggested_evidence_list, suggested_evidence_ids
  };
}

export default connect(mapStateToProps)(EvidenceList);