import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class InvoiceService {

  getInitialdata(entity_id = 1) {
    return axios.get(API_URL + 'required-data-for-invoice', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  createInvoice(data) {
    return axios
      .post(API_URL + "invoice", data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  updateInvoice(data) {
    return axios
      .put(API_URL + "invoice", data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getInvoiceDetail(item_id) {
    return axios
      .get(API_URL + "invoice/" + item_id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getInvoiceData() {
    return axios.get(API_URL + 'invoices-data', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getInvoiceWidgetCount(filter_data) {
    return axios.post(API_URL + 'invoices-count',filter_data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }


  getInvoiceList(filter_data) {
    return axios.post(API_URL + 'invoices', filter_data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getInvoiceFilterList(year_type, per_page_items, page_no, entity_id) {

    return axios.get(API_URL + 'invoices?type=' + year_type + '&per_page_items=' + per_page_items + '&page_no=' + page_no + '&entity=' + entity_id, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getInvoicesCount(data) {
    return axios.post(API_URL + 'invoices-count',data, { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  getChangeLog() {
    return axios.get(API_URL + 'change-log', { headers: Header.entityHeader() })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
  }

  makeRemoveStar(data) {
    return axios.put(API_URL + 'invoice-star',data, { headers: Header.entityHeader() })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error;
        });
  }

}

export default new InvoiceService();