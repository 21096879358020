import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { render } from 'react-dom';
import EntityService from '../../Services/ProfileManagement/EntityService';
import StorageService from '../../Helpers/StorageData';

class SetEntity extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			entity_id: null,
			entityList: []
		}
	}

	componentDidMount() {
		const entity = JSON.parse(localStorage.getItem('entity'));
		if (entity)
			this.setState({ entity_id: entity.id })
		EntityService.getEntityList().then(
			(data) => {
				if (data.response_code == 200) {
					this.setState({
						entityList: data.result.entities,
					});
				}
			});
	}

	handleChange = (e) => {
		const { value, name } = e.target;
		this.setState({ [name]: value });
	}

	setEntity = () => {
		let entityList = this.state.entityList;
		let index = entityList.findIndex(entity => entity.id == this.state.entity_id);
		let entity = entityList[index];
		StorageService.setDefaultEntity(entity)
		this.props.toggleSetEntity()
	}

	render() {
		return (
			<Modal
				show={true}
				onHide={this.props.toggleSetEntity}
				backdrop="static"
				keyboard={false}
				centered
				className="changelog-model-sec"
			>
				<Modal.Header closeButton>
					<Modal.Title className="ft-sz-30 s-sbold blu-color"><span className="icon-round-info-button mr-4"></span> Set Entity</Modal.Title>
				</Modal.Header>
				<Modal.Body className="clearfix">
					<div className="form-group">
						<p className="lable-input font-colr">Select entity*</p>
						<select className="form-control"
							onChange={this.handleChange}
							value={this.state.entity_id}
							name="entity_id" >
							<option value="">Select Entity</option>
							{this.state.entityList.map((item, index) => {
								return <option key={index} value={item.id}>{item.name}</option>
							})}
						</select>
					</div>
					<Modal.Footer>
						<Button onClick={this.setEntity} >Set Entity</Button>
					</Modal.Footer>
				</Modal.Body>
			</Modal>

		)
	}
}

export default SetEntity