import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';



function ReadyStatus(props) {
    return <>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ready</Tooltip>}>
            <span className="icon-ready-icon icon-dt-td iconsc"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
        </OverlayTrigger>
    </>
}
export default ReadyStatus;