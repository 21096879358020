import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo from '../../Assets/img/logo.png';
import logo_dash from '../../Assets/img/logo-dash.jpeg';
import logo_fevi from '../../Assets/img/bucktec-fevicon.png';
import user_img from '../../Assets/img/user-img.png';
import recipt from '../../Assets/img/recipt.png';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
function cheques(){
  const [comment,setComment]=React.useState(false)
  const [task,setTask]=React.useState(false)
  const [note,setNote]=React.useState(false)
  const [privatemessage,setPmessage]=React.useState(false)
  const [sidfltr,setSidfltr]=React.useState(false)
  

  const [show,setShow]=React.useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [clog,setClog]=React.useState(false)
  const logClose = () => setClog(false);
  const logShow = () => setClog(true);

    return <div className="main-body fixed-nav bg-dark sidenav-toggled" id="page-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainNav">
        {/* <a className="navbar-brand d-none d-sm-block" href="#"><img src={logo_dash}/></a>
        <a className="navbar-brand d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="icon-menu"></span>
        </button>
        <ul className="top-menu-right">
            <li className="">
                <Dropdown>
                <img src={user_img}/>
                    <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                    Mahesh Raje
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
            </li>
        </ul> */}
        <div className="row w-100">
          <div className="col-lg-4 col-sm-4">
            <a className="d-none d-sm-block" href="#"><img className="main-logo" src={logo_dash}/></a>
            <a className="d-block d-sm-none" href="#"><img src={logo_fevi}/></a>
          </div>
          <div className="col-lg-4 col-sm-4 text-center">
            <p className="company-name-sec ft-sz-32 s-sbold m-0 font-colr">ABC Technologies </p>
          </div>
          <div className="col-lg-4 col-sm-4">
            <ul className="top-menu-right">
              <li className="">
                  <Dropdown>
                  <img src={user_img}/>
                      <Dropdown.Toggle variant="user-acount-arrow" id="dropdown-basic">
                      Mahesh Raje
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <ul className="main-sidebar">
          <li>
            <a href="#" className="s-sbold blu-color active">
            <span className="side-icon icon-dashboard-1"></span>
              <span className="side-link s-sbold d-none">Dashboard</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2520"></span>
              <span className="side-link s-sbold d-none">Capture</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2519"></span>
              <span className="side-link s-sbold d-none">Financial Institutes & Account Statement</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-Group-2518"></span>
              <span className="side-link s-sbold d-none">Money Link</span>
            </a>
          </li>
          <li>
            <a href="#" className="s-sbold blu-color">
            <span className="side-icon icon-XMLID_223"></span>
              <span className="side-link s-sbold d-none">Export and Reports</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row bg-w bg-rounded">
            <div className="col-lg-12 col-md-12 col-sm-12 sidebar-sec">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to={'/home'}>Home</Link>
                </li>
                <li className="breadcrumb-item">Cheques</li>
              </ol>
              <div className="clearfix main-title-btn-sec">
                <div className="head-title pull-left">
                  <h2 className="s-sbold blu-color m-0">Cheques <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Help</Tooltip>}>
                  <span className="icon-Information green-icon help"></span></OverlayTrigger></h2>
                </div>
              </div>
              <div className="main-evidence-sms-sec">
                <div className="main-date-sec">
                  <div className="">
                    <div className="last-month-sec">
                      <div className="form-group">
                        <select className="form-control inner-last-month-sec">
                          <option value="1" disabled selected>Last 3 Months</option>
                          <option value="2">Last Financial Year</option>
                          <option value="3">This Month </option>
                          <option value="4">Last 3 Months</option>
                          <option value="5">Since Beginning</option>
                          <option value="6">Custom Date Range</option>
                        </select>             
                      </div>
                    </div>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">Start Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                    <hr/>
                    <div className="start-end-date-sec btn">
                      <span className="ft-sz-20 s-reg blu-color">End Date</span> <span className="icon-date icon-calendar"></span>
                    </div>
                  </div> 
                </div>

                <div className="">
                  <div className="tab-inner-sec">
                    <div className="row">
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box active text-center">
                          <p className="title ft-sz-28 m-0">Total</p>
                          <div className="count-char s-bold">20</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Not Ready</p>
                          <div className="count-char s-bold">5</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Ready</p>
                          <div className="count-char s-bold">15</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Linked</p>
                          <div className="count-char s-bold">12</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Processed</p>
                          <div className="count-char s-bold">2</div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-xl col-lg-3 col-md-3 mb-3">
                        <div className="count-box text-center">
                          <p className="title ft-sz-28 m-0">Synched</p>
                          <div className="count-char s-bold">1</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main-evidence-sms-table-sec">
                  <div className="toggle-search-sec clearfix">
                    <div className="search-filter-sec pull-right">
                      <form className="search-sec">
                        <input type="text" placeholder="Search" />
                        <button type="submit"><span className="icon-loupe"></span></button>
                      </form>
                      <div className="filter">
                      Filter <span className="icon-funnel"></span>
                      </div>
                      <button className="add-new-btn btn ft-sz-20 white-color s-bold"><span className="icon-plus1"></span> Add New</button>
                    </div>
                  </div>
                    <div className="main-filter-sec clearfix">
                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Starred</option>
                                <option>Yes</option>
                                <option>No</option>
                                <option>Both</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                        <div className="col-lg-1 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="ID" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Bank" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Cheque#" />
                          </div>
                        </div>
                        <div className="col-lg-5 col-xs-12">
                          <div className="row main-due-date-sec evidence-filter no-gutters">
                            <div className="col">
                              <p className="mt-2 ft-sz-17 s-sbold mb-0 text-right mr-4">Cheque Date</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" placeholder="From"/>
                                    <span class="icon-calendar"></span>
                                  </div>
                                </div>
                            </div>
                            <div className="col-lg-1">
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control" placeholder="to"/>
                                    <span class="icon-calendar"></span>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row inner-row">
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Payer" />
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <input type="text" className="form-control" value="" placeholder="Payee" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-xs-12">
                          <div className="row main-due-date-sec evidence-filter no-gutters main-amount-sec">
                            <div className="col">
                              <p className="mt-2 ft-sz-17 s-sbold mb-0 text-right mr-3">Amount</p>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control text-right" placeholder="₹ 0"/>
                                  </div>
                                </div>
                            </div>
                            <div className="col">
                              <hr/>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="form-group date-sec">
                                  <div className="inner-date-sec">
                                    <input type="text" className="form-control text-right" placeholder="₹ 0"/>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-xs-12">
                          <div className="form-group">
                            <Form>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Control as="select">
                                <option>Uploaded By</option>
                                <option>fsf</option>
                                <option>gtg</option>
                                <option>ttt</option>
                              </Form.Control>
                            </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                      <button type="button" class="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>

                  
                  {/* Invoice Table list */}
                  <div className="main-table-sec">
                  <div className="not-create-sec"><p className="ft-sz-32 s-sbold blu-color m-0">No Cheques</p></div>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th>Star <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Cheque Date <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Bank <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Cheque# <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Payer <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Payee <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Amount <span className="icon-arrow-down-sign-to-navigate"></span></th>
                          <th>Status <span className="icon-arrow-down-sign-to-navigate"></span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>20-09-2020</td>
                          <td>ICICI Bank</td>
                          <td>225465</td>
                          <td>Mahindra</td>
                          <td>ABC Technologies</td>
                          <td>₹ 10,000 <span class="icon-Red-arrow"></span></td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td "></span></td>
                          <td>05-09-2020</td>
                          <td>IDBI Bank</td>
                          <td>125465</td>
                          <td>TATA Motors</td>
                          <td>ABC Technologies</td>
                          <td>₹ 1,10,000 <i class="fa  gren-color" aria-hidden="true"></i></td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold sky-blu-color">Ready</Tooltip>}>
                            <span className="icon-td icon-Group-25071 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td"></span></td>
                          <td>08-09-2020</td>
                          <td>ICICI Bank</td>
                          <td>156451</td>
                          <td>ABC Technologies</td>
                          <td>MAHADISCOM</td>
                          <td>₹ 90,000 <span class="icon-Red-arrow"></span></td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Linked</Tooltip>}>
                            <span class="icon-td icon-Group-2510 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>17-08-2020</td>
                          <td>HDFC Bank</td>
                          <td>111111</td>
                          <td>ABC Technologies</td>
                          <td>Dell Computers</td>
                          <td>₹ 1,00,440 <span class="icon-Green-arrow"></span></td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold red-color">Not Ready</Tooltip>}>
                            <span class="icon-td icon-Group-2509 ft-sz-38"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td><span className="icon-Star star-td active"></span></td>
                          <td>25-07-2020</td>
                          <td>ICICI Bank</td>
                          <td>145638</td>
                          <td>TATA Motors</td>
                          <td>ABC Technologies</td>
                          <td>₹ 1,18,000 <span class="icon-Green-arrow"></span></td>
                          <td>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold yellow-color">Not Ready</Tooltip>}>
                            <span className="icon-td icon-Group-2508 ft-sz-38"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                            </OverlayTrigger>
                          </td>
                          <td>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Messages</Tooltip>}>
                          <span className="icon-td icon-Group-3180"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="ft-sz-15 s-bold">Task</Tooltip>}>
                          <span className="gray-st-icon icon-td icon-Group-3182"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                          </OverlayTrigger>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="main-pagination clearfix">
                    <nav aria-label="Page navigation example">
                      <div class="pull-left"><button type="button" class="btn sms-delete-btn white-color drk-gry-bg d-none">Delete </button></div>
                      <ul class="pagination pull-right mb-0">
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Displaying</a></li>
                        <li class="page-item item-count"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        {/* <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">Items per Page</a></li> */}
                        <li class="page-item prev-sec"><a class="page-link ft-sz-20 s-reg" href="#"><span class="icon-arrow-down-sign-to-navigate"></span> Prev</a></li>
                        <li class="page-item active"><a class="page-link ft-sz-20 s-reg" href="#">1</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">2</a></li>
                        <li class="page-item"><a class="page-link ft-sz-20 s-reg" href="#">3</a></li>
                        <li class="page-item next-sec"><a class="page-link ft-sz-20 s-reg" href="#">Next <span class="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 sidebar-sec">
              <div className="clearfix">
                <ol className="breadcrumb pull-left">
                  <li className="breadcrumb-item">
                  <Link to={'/home'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">Cheques</li>
                </ol>
                <div className="pull-right d-flex new-add-filter-sec">
                  <div className="sidebar-evidence-filter" onClick={()=>setSidfltr(!sidfltr)}>
                  <span className="icon-filter"></span>
                  </div>
                  <div className="add-new-evi-sms">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Add New</Tooltip>}>
                    <span className="icon-plus"></span>
                    </OverlayTrigger>
                  </div>
                </div> 
              </div>
              <hr/>
              
              {/* <div className="btn-group btn-toggle left-on-off-btn pull-left"> 
                <button className="btn btn-lg btn-default active">Evidence</button>
                <button className="btn btn-lg btn-primary ">SMS</button>
              </div> */}
               

              

              <div className="recipt-sidebar-sec">
              {
                sidfltr?<div className="main-evidence-sidefilter">
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                      <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Star</option>
                            <option>Yes</option>
                            <option>No</option>
                            <option>Both</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="ID" />  
                        </div>  
                    </div>
                    
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Custom Date Range</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>    
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <div className="start-end-date-sec btn">
                            <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                        </div>
                        </div>  
                    </div>
                    <div className="col">
                      <hr/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <div className="start-end-date-sec btn">
                            <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                        </div>
                        </div>
                    </div>
                  </div>  
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Cheque Date</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>    
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <div className="start-end-date-sec btn">
                            <span className="ft-sz-16 s-sbold blu-color">1-4-2019</span> <span className="icon-date icon-calendar"></span>
                        </div>
                        </div>  
                    </div>
                    <div className="col">
                      <hr/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <div className="start-end-date-sec btn">
                            <span className="ft-sz-16 s-sbold blu-color">31-3-2020</span> <span className="icon-date icon-calendar"></span>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="row">    
                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="Bank" />  
                        </div>
                    </div>
                  </div>
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control text-right" value="" placeholder="From Amount" />  
                        </div>  
                    </div>
                    <div className="col">
                      <hr/>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control text-right" value="" placeholder="To Amount" /> 
                        </div>
                    </div>
                  </div> 
                  <div className="row">    
                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="Payer" />  
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="Payee" />  
                      </div>
                    </div>
                  </div>
                  
                  <div className="row no-gutters">    
                    <div className="col-lg-5 col-sm-12">
                        <div className="form-group">
                        <input type="text" className="form-control" value="" placeholder="Cheque#" />  
                        </div>  
                    </div>
                    <div className="col">
                    </div>
                    <div className="col-lg-5 col-sm-12">
                      <Form>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control as="select">
                            <option>Uploaded By</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                        </Form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-xs-12">
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Reset</button>
                    </div>
                    <div className="col-lg-6 col-xs-12">
                      <button type="button" className="btn apply-btn ft-sz-22 white-color s-bold">Apply</button>
                    </div>
                  </div>
                </div>:null
              }  

                <div className="main-applied-sidefilter d-none">
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">star</span>
                    <span className="ft-sz-16 s-sbold blu-color">id</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">Last Financial Year</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
                    <span className="ft-sz-16 s-sbold blu-color">to</span>
                    <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">cheque  date</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
                    <span className="ft-sz-16 s-sbold blu-color">to</span>
                    <span className="ft-sz-16 s-sbold blu-color">1-01-2021</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">bank</span>
                    <span className="ft-sz-16 s-sbold blu-color">₹300 - ₹5000</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">payer</span>
                    <span className="ft-sz-16 s-sbold blu-color">payee</span>
                  </div>
                  <div className="row">
                    <span className="ft-sz-16 s-sbold blu-color">cheque</span>
                    <span className="ft-sz-16 s-sbold blu-color">uploaded by</span>
                  </div>
                </div>

                <p className="ft-sz-18 s-sbold blu-color">No Cheques yet</p>
                 <div className="recipt-inner-div invoice-side-list empty-div active">
                    <div className=""></div>
                    <div className="row">
                     <p className="m-0 ft-sz-28 font-colr s-sbold">New Cheque</p>
                    </div>
                 </div>

                <div className="recipt-inner-div invoice-side-list active">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-2510"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;20000 <span class="icon-Green-arrow"></span></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-2509"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 <span class="icon-Red-arrow"></span></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-2508"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                        <span>&#8377;2000 <span class="icon-Red-arrow"></span></span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                       <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
               
                
                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                       <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                       <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                       <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                       <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recipt-inner-div invoice-side-list">
                  <div className=""></div>
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payer :</p> 
                       <p className="m-0 ft-sz-17 s-sbold font-colr">Mahindra</p>
                      </div>
                      
                      <div className="main-payer-name">
                       <p className="ft-sz-13 s-reg font-colr m-0">Payee :</p> 
                       <p className="mb-1 ft-sz-17 s-sbold font-colr">ABC Technologies</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="evi-icon">
                        <div className="color-icon">
                        <span className="icon-Group-25071"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
                        </div>
                      </div>
                      <div className="amount s-sbold blu-color">
                       <span>  &#8377;2000</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                 <div className="main-pagination clearfix sidebar-pagination">
                    <nav aria-label="Page navigation example">
                      {/* <div className="per-page-sec clearfix">
                        <ul className="pagination pull-right mb-0">
                          <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">Items per Page</a></li>
                          <li className="page-item item-count"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        </ul>
                      </div> */}
                      <ul className="pagination pull-right mb-0">  
                        <li className="page-item prev-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                        <li className="page-item active"><a className="page-link ft-sz-18 s-reg" href="#">1</a></li>
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">2</a></li>
                        <li className="page-item"><a className="page-link ft-sz-18 s-reg" href="#">3</a></li>
                        <li className="page-item next-sec"><a className="page-link ft-sz-18 s-reg" href="#"><span className="icon-arrow-down-sign-to-navigate"></span></a></li>
                      </ul>
                    </nav>
                  </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 main-content-sec">
              <div className="inner-content-sec">
                <div className="clearfix main-title-btn-sec">
                  <div className="head-title pull-left">
                    <h2 className="s-sbold blu-color m-0">Create Cheque <span className="icon-Information green-icon help"></span></h2>
                  </div>
                  <div className="pull-right">
                    
                  </div>
                </div>
                <div className="main-file-item-sec">
                  <div className="cross" onClick={handleShow}>
                    <span>X</span>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-sm-12">
                      <div className="left-inner-content-sec left-inner-cheque-sec snapshot-uplaod">
                        <div className="upload-file-sec">
                          <h1 className="blu-color">Upload your file</h1>
                          <h1 className="blu-color d-none">File Uploaded</h1>
                          <div className="upload-file-btn">
                          <div className="cross d-none"><span className="cross-span">X</span></div>
                            <span className="icon-plus"></span>
                          </div>
                          <p className="blu-color s-sbold drag-text">Drag File</p>
                          <p className="m-0 font-colr click-text">or click here to attach</p>
                          <p className="m-0 font-colr click-text d-none">Click on file to view</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-sm-12">
                      <div className="right-inner-content-sec">
                      <div className="right-inner-content-sec-box">
                          <div className="top-head clearfix">
                           <p className="pull-left s-sbold ft-sz-22 blu-color m-0">Cheque Details</p>
                           <p className="pull-right"><span className="icon-log icon-round-info-button" onClick={logShow}></span> <span className="id-no-text s-sbold m-0">ID #2154</span> <span className="id-no-next id-no-text s-sbold m-0">New</span></p>
                          </div> 
                          <div className="row">
                            <div className="col-lg-5"></div>
                            <div className="col-lg-7 right-form-sec">
                             <form>
                              <div className="form-group">
                                <p className="lable-input font-colr">Type*</p>
                                <select className="form-control">
                                  <option value="1" disabled selected>Select your option</option>
                                  <option value="2">Saab</option>
                                  <option value="3">VW</option>
                                  <option value="4">Audi</option>
                                </select>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Cheque Date*</p>
                                {/* input-bg-green */}
                                <input type="text" className="form-control " value="12-12-2020"/>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Bank*</p>
                                <input type="text" className="form-control blu-color " value="" />             
                              </div>
                              <div className="form-group d-none">
                                <p className="lable-input font-colr">Bank*</p>
                                <select className="form-control ">
                                  <option value="1" disabled selected>Select your bank</option>
                                  <option value="2">HDFC</option>
                                  <option value="3">SBI</option>
                                  <option value="4">ICICI</option>
                                </select>             
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Cheque#</p>
                                <input type="text" className="form-control blu-color  text-right"/>             
                              </div>
                              <div className="form-group">
                              <p className="lable-input font-colr">Amount</p>
                                <input type="text" className="form-control blu-color  text-right" />
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payer</p>
                                <input type="text" className="form-control blu-color input-bg-gray" placeholder="" />
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Payee</p>
                                <input type="text" className="form-control blu-color" placeholder="" />
                              </div>
                              <div className="form-group">
                                <p className="lable-input font-colr">Narration</p>
                                <div className="form-group">
                                  <textarea className="form-control" rows="4"></textarea>            
                                </div>           
                              </div>
                            </form>  
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
               
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="save-model-sec"
                >
                  
                  <Modal.Body className="clearfix">
                    <div className="pull-left mr-3">
                      {/* <i className="fa fa-exclamation-circle red-color" aria-hidden="true"></i> */}
                      <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                    </div>
                    <div className="pull-left">
                      <p className="ft-sz-30 s-bold blu-color mb-1">Save Changes?</p> 
                      <p className="ft-sz-18 s-reg font-colr">If you close without saving,the changes you have made will be lost</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
                    <Button variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Save</Button>
                  </Modal.Footer>
                </Modal>



                <Modal
                  show={clog}
                  onHide={logClose}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="changelog-model-sec"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="ft-sz-30 s-sbold blu-color"><span className="icon-round-info-button mr-4"></span> Change Log</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="clearfix">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Date </th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">Action</th>
                        <th scope="col" className="ft-sz-22 s-sbold blu-color">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Created</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Changed to Reimbursement</td>
                        <td className="ft-sz-18 s-reg blu-color">Rakesh Sharma</td>
                      </tr>
                      <tr>
                        <td className="ft-sz-18 s-reg blu-color">18/05/2020 <br/> 10:00 AM</td>
                        <td className="ft-sz-18 s-reg blu-color">Approved</td>
                        <td className="ft-sz-18 s-reg blu-color">Raj Kumar</td>
                      </tr>
                    </tbody>
                  </table>
                  </Modal.Body>
                </Modal>

                <div className="bottom-save-btn clearfix">
                <div className="pull-left">
                  <button type="button" className="next-bottom-btn btn save-btn greenbtn">Back</button>
                  <button type="button" className="btn save-btn white-color graybtn">Delete </button>
                </div>
                  <div className="pull-right">
                    <div className="ready-btn">
                      <select>
                        <option value="audi" selected>Not Ready</option>
                        <option value="volvo">Ready</option>
                      </select> 
                    </div>  
                      <button type="button" className="btn save-btn greenbtn">Save</button>
                  </div>
                </div>
                <div className="bottom-message-sec">
                  <div className="main-bottom-comments-note-sec">
                   {
                    comment?<div className="bottom-comments-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-chat"></span></span>
                        <span className="ft-sz-32 s-sbold">Messages</span>
                      </div>
                      <div className="inner-comment-scroll">
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="gren-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">2 days ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter green-bg s-sbold">
                            <span>S</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">just now</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }

                  {
                    note?<div className="bottom-note-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-Group-2546"></span></span>
                        <span className="ft-sz-32 s-sbold">Notes</span>
                      </div>
                      <div className="inner-note-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="first-letter yellow-bg s-sbold">
                          <span>Y</span>
                        </div>
                        <div className="note-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                        </div>
                      </div>
                      <div className="inner-note-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="first-letter yellow-bg s-sbold">
                          <span>Y</span>
                        </div>
                        <div className="note-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Your Title </span> Shubham Gupta  please check the image for this evidence</p>
                        </div>
                      </div>
                    </div>:null
                  }  

                  {
                    task?<div className="bottom-task-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-Group-2516"></span></span>
                        <span className="ft-sz-32 s-sbold">Tasks</span>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-011" type="checkbox" value="value011" />
                          <label for="styled-checkbox-011" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                            <p className="ft-sz-18 s-reg red-bg white-color color-div">Overdue</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-24" type="checkbox" value="value011" />
                          <label for="styled-checkbox-24" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                            <p className="ft-sz-18 s-reg yellow-bg white-color color-div">Due in 2 days</p>
                          </div>
                        </div>
                      </div>
                      <div className="inner-task-sec clearfix">
                        <div className="cross"><span>X</span></div>
                        <div className="check-box">
                          <input className="styled-checkbox" id="styled-checkbox-142" type="checkbox" value="value011" />
                          <label for="styled-checkbox-142" className="label-text blu-color s-sbold ft-sz-18 mb-0"></label>
                        </div>
                        <div className="task-text">
                          <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Add proper payment method</span></p>
                          <div className="day-reply-sec">
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold blu-color">Assignee:</span>Shubham G</p>
                            <p className="ft-sz-18 s-reg"><span className="ft-sz-20 s-sbold  blu-color">Due Date:</span>15-09-2020</p>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }  


                  {
                    privatemessage?<div className="bottom-comments-sec">
                      <div className="head-sec">
                        <span className="ico-div"><span className="icon-chat"></span></span>
                        <span className="ft-sz-32 s-sbold red-color">Private Messages</span>
                      </div>
                      <div className="inner-comment-scroll">
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> <span className="red-color">Shubham Gupta</span>  please check the image for this evidence</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">2 days ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter red-bg s-sbold">
                            <span>S</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Shubham G:</span> I have done the needful</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">32 minutes ago</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                        <div className="inner-comment-sec clearfix">
                          <div className="first-letter blu-bg s-sbold">
                            <span>A</span>
                          </div>
                          <div className="comment-text">
                            <p className="ft-sz-18 s-reg font-colr mb-1"><span className="ft-sz-25 s-sbold blu-color">Anurag C:</span> Ok Thanks for the update. I will update</p>
                            <div className="day-reply-sec">
                              <p className="ft-sz-16 s-sbold">just now</p>
                              <p className="ft-sz-16 s-sbold">Reply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }

                  </div>

                  <form>
                    <div className="input-group">
                      <div className="message-sec clearfix">
                        <div className="input-group">
                          <input type="text" className="form-control s-sbold ft-sz-22 font-colr pl-3" placeholder="@mention a user" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" className="active" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Comment</button>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Note</button>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right d-none">Add Task</button>
                      </div>
                      <div className="message-sec note-input clearfix d-none">
                        <div className="input-group first">
                          <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Task title; @assignee" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" className="active" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <div className="input-group second task-due-date">
                          {/* <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Due date" aria-label="Recipient's username" aria-describedby="basic-addon2"/> */}
                          <span className="s-reg ft-sz-22 font-colr">Due date <span className="icon-calendar"></span></span>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Task</button>
                      </div>
                      <div className="message-sec note-input clearfix d-none">
                        <div className="input-group first">
                          <input type="text" className="form-control s-reg ft-sz-32 font-colr" placeholder="Title" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                          <div className="input-group-append icon-message-sec">
                            <a role="button" onClick={()=>setComment(!comment)}><span className="icon-chat"></span></a>
                            <a role="button" onClick={()=>setTask(!task)}><span className="icon-Group-2516"></span></a>
                            <a role="button" className="" onClick={()=>setNote(!note)}><div className="bottom-notification"></div><span className="icon-Group-2546"></span></a>
                            <a role="button" className="active" onClick={()=>setPmessage(!privatemessage)}><span className="icon-chat-1"></span></a>
                          </div>
                        </div>
                        <div className="input-group second">
                          <input type="text" className="form-control s-reg ft-sz-22 font-colr" placeholder="Start typing" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        </div>
                        <button type="button" className="btn comment-btn white-color s-bold ft-sz-22 pull-right">Add Note</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="main-smartview-sec d-none">
            <div className="">
              <div className="col-xl-6 col-sm-12">
                <div className="inner-smartview-sec">
                  <div className="cross"><span>X</span></div>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                  <div className="smartview-drag-sec">
                    <div className="main-zoom-roted-sec">
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-refresh"></span></button>
                        <button className="btn"><span className="icon-refresh-11"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-magnifying-glass"></span></button>
                        <span className="ft-sz-22 s-reg blu-color">100%</span>
                        <button className="btn"><span className="icon-zoom-in"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-Group-2197"></span></button>
                      </div>
                      <div className="rotation-sec">
                        <button className="btn"><span className="icon-full-size1"></span></button>
                      </div>
                      {/* <button type="button" className="btn upload-btn white-color s-bold ft-sz-21">Upload</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
					<span className="icon-right-arrow white-icon fs10"></span>
				</a>
      </div>
    </div>     

	
}











export default cheques;