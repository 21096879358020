
import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { connect } from "react-redux";
import StorageData from '../../Helpers/StorageData';
import SessionManagement from '../../Utils/SessionManagement';
import { setAppilcationLoder } from '../../Redux/Actions/App';

function ChattingWindow(){

    return <React.Fragment>
    <div className="inner-content-sec">
      <div className="clearfix main-title-btn-sec">
        <div className="head-title pull-left">
          <h2 className="s-sbold blu-color m-0">Messenger</h2>
        </div>
      </div>
      <div className="main-file-item-sec">
      <button className="lic-bill-btn btn ft-sz-20 s-bold white-color">Go to Context</button>
       {/* <h2 className="s-sbold blu-color m-0"><span className="icon-chat mr-3"></span> General Chats</h2> */}
        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
          <Tab eventKey="general" title="General Chats">
            <div className="chat-section">
              <ul className="chat-text-sec">
                <li className="s-reg other-person">
                  <div className="message">
                   <p className="ft-sz-22 s-sbold mb-2 lblu-color">Mihir Joshi</p> 
                   <span>Have you made TDS Payment? Share the details with me</span> 
                  <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                  </div>
                </li>
                <li className="s-reg user-type">
                  <div className="message">
                  Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                  <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                  </div>
                </li>
                <li className="separator ft-sz-18 s-sbold"><span>1 Unread message</span></li>
                <li className="s-reg other-person">
                  <div className="message">
                  <p className="ft-sz-22 s-sbold mb-2 lblu-color">Renuka Bhande</p> 
                   <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                  </div>
                </li>
                <li className="s-reg user-type">
                  <div className="message">
                  Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                  </div>
                </li>
                <li className="s-reg other-person">
                  <div className="message">
                  <p className="ft-sz-22 s-sbold mb-2 lblu-color">Sameer Rahane</p> 
                  <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                  </div>
                </li>
              </ul>
              <footer>
                <div className="row">
                  <div className="chat-bottom-input"><input className="ft-sz-22 s-reg blu-color" placeholder="Type a message here" /></div>
                  <div className="chat-send-icon"><a href=""><span class="icon-send"></span></a></div>
                </div>
              </footer>
            </div>
          </Tab>
          <Tab eventKey="private" title="Private Messages" className="private-chat-sec">
            <div className="chat-section">
              <ul className="chat-text-sec">
                <li className="s-reg other-person">
                  <div className="message">
                   <p className="ft-sz-22 s-sbold mb-2 lblu-color">Mihir Joshi</p> 
                   <span>Have you made TDS Payment? Share the details with me</span> 
                  <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                  </div>
                </li>
                <li className="s-reg user-type">
                  <div className="message">
                  Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                  <span className="time-sec ft-sz-15 s-sbold">3:09pm</span>
                  </div>
                </li>
                <li className="separator ft-sz-18 s-sbold"><span>1 Unread message</span></li>
                <li className="s-reg other-person">
                  <div className="message">
                  <p className="ft-sz-22 s-sbold mb-2 lblu-color">Renuka Bhande</p> 
                   <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                  </div>
                </li>
                <li className="s-reg user-type">
                  <div className="message">
                  Not yet… Will be doing it in next 1-2 days. Thanks for reminder 
                  </div>
                </li>
                <li className="s-reg other-person">
                  <div className="message">
                  <p className="ft-sz-22 s-sbold mb-2 lblu-color">Sameer Rahane</p> 
                  <span>Evidence  ID 458  is wrong. Correct it and let me know.</span>
                  </div>
                </li>
              </ul>
              <footer>
                <div className="row">
                  <div className="chat-bottom-input"><input className="ft-sz-22 s-reg blu-color" placeholder="Type a message here" /></div>
                  <div className="chat-send-icon"><a href=""><span class="icon-send"></span></a></div>
                </div>
              </footer>
            </div>
          </Tab>
        </Tabs>
        
      </div>
      
    </div>
    </React.Fragment>

}
  
function mapStateToProps(state) {
    const all_value = state.Session || {};
    const module_permissions = all_value.module_permissions || [];
    const account_permissions = all_value.account_permissions || [];
    const acl_entities = all_value.entity_list || [];
    return {
      module_permissions, account_permissions, acl_entities
    };
  }

export default connect(mapStateToProps)(ChattingWindow);